import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions } from '@material-ui/core';

export default function ConfirmDialog({ id, openDialog, description, confirmHandler, closeHandler }) {
  return (
    <React.Fragment>
      <Dialog open={openDialog} onClose={closeHandler} aria-labelledby="form-dialog-title" className={'pop_wrap publish_pop'}>
        <Dialog open={openDialog} aria-labelledby="confirm-dialog">
          <div className="pop_size_small">
              <React.Fragment>
                <div className="ico_condition1"></div>
                <p className="pop_comment" >{description}</p>
                <DialogActions>
                  <div className="btn_set_wrap alC">
                    <Button color="primary" onClick={() => confirmHandler(id)} className="btn_color2">OK</Button>
                    <Button autoFocus color="primary" onClick={closeHandler} className="btn_color1">Cancel</Button>
                  </div>
                </DialogActions>
              </React.Fragment>
          </div>
        </Dialog>
      </Dialog>
    </React.Fragment>
  );
}

ConfirmDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  description: PropTypes.string,
  confirmHandler: PropTypes.func,
  closeHandler: PropTypes.func,
};
