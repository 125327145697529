import * as actions from '../../actions/actionTypes';

const initialState = {};
// {
//   afterFlag: false,
//   lastPublishDate: null,
//   crawlerExecuteTime: '',
//   lastUpdateDate: null,
//   beforeFlag: false,
//   confList: [],
// };

export default function precheck(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PRECHECK:
      return action.payload;
    case actions.SET_SAVE_FLAG:
      return initialState;
    default:
      return state;
  }
}
