import { API_BASE_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';

const URL = API_BASE_URL + '/pushalarm';

export const getPushAlarmList = (title, countryCode, state) => dispatch => {
  if (countryCode === 'undefined' || countryCode === null || countryCode === 'All') countryCode = '';
  if (state === 'undefined' || state === null || state === 'All') state = '';
  if (title === 'undefined' || title === null) title = '';
  if (state === 'Publish') state = '2';
  if (state === 'Draft') state = '1';

  // console.log('[title]:',title,'[countryCode]:',countryCode,'[state]:',state,);

  let apiUrl = `${URL}/list?title=${title}&countrycode=${countryCode}&state=${state}`;

  dispatch({
    type: 'SET_PUSH_FETCHING',
    payload: true,
  });
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
  .then(res => res.json())
  .then(body =>
    dispatch({
      type: 'SET_PUSH_ALARM',
      payload: body,
    }),
  ) //include 400Error
  .catch(ex =>
    dispatch({
      type: 'SET_PUSH_ALARM',
      payload: ex,
    }),
  );
};

export const updatePushAlarm = data => dispatch => {
  const pushAlarm = { ...data };
  // console.log('updatePushAlarm:', pushAlarm);

  dispatch({
    type: 'SET_PUSH_FETCHING',
    payload: true,
  });
  return fetch(`${URL}/update`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(pushAlarm),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        if(pushAlarm.state === '1'){
          alert('Successfully Draft !');
        }else{
          alert('Successfully Publish !');
        }
        dispatch(getPushAlarmList('', '', ''));
      }
    })
    .catch(() => {});
};

