import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { request } from '../../util/request';

export default function AccountDelete({ reloadAction, requestPath, isRoleAdmin }) {
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const handleSuccessSnackbarClose = () => {
    //(event, reason) => {
    //console.log('[handleSuccessSnackbarClose][reason]', reason); //timeout
    // if (reason === 'clickaway') return;
    setSuccessSnackbarOpen(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    alert('Are you sure to delete this?');
    const isSuccess = await request(requestPath, 'DELETE'); // '/admin/users/' + item['ID'], 'DELETE');
    //console.log('[isSuccess]', isSuccess); //await 없으면 Promise {<pending>}//request내부await 없으면 무조건 기본값리턴
    if (isSuccess) {
      setSuccessSnackbarOpen(true);
      reloadAction(isRoleAdmin);
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleSubmit} className={'btn_size_tbl btn_color3'}>
        DELETE
      </Button>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={1000} //1000=1sec
        onClose={handleSuccessSnackbarClose} //없으면 없어지지않음
        message="Successfully Doned !"
        style={{ backgroundColor: 'black' }}
      />
    </React.Fragment>
  );
}
AccountDelete.propTypes = {
  reloadAction: PropTypes.func.isRequired,
  requestPath: PropTypes.string.isRequired,
  isRoleAdmin: PropTypes.bool,
};
