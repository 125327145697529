const initialState = {
    loading: false,
    data: {},
};

export default function monitorPreview(state = initialState, action) {
    switch (action.type) {
        case 'SET_MONITOR_PREVIEW_DATA_INIT':
            return { ...state, loading: true };
        case 'SET_MONITOR_PREVIEW_DATA_SUCCESS':
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        default:
            return state;
    }
}
