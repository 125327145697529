import React, { useState, useEffect } from 'react';

function IndicatorsPopup(props) {
  const { allComponents, isDivideSection, onCancel, onApply } = props;
  const [selectedComponentIds, setSelectedComponentIds] = useState([]);
  const [groupedComponents, setGroupedComponents] = useState({});
  const [sortedTypes, setSortedTypes] = useState([]);
  const [visibility, setVisibility] = useState({});
  const [isIndicatorDivideSection, setIndicatorDivideSection] = useState(false);

  useEffect(() => {
    const initialSelectedComponentIds = allComponents.filter((component) => component.checked).map((component) => component.id);
    setSelectedComponentIds(initialSelectedComponentIds);

    const groupedItems = allComponents.reduce((acc, item) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }
      acc[item.type].push(item);
      return acc;
    }, {});
    setGroupedComponents(groupedItems);

    const tempSortedTypes = Object.keys(groupedItems).sort((a, b) => {
      const aPriority = groupedItems[a][0].priority;
      const bPriority = groupedItems[b][0].priority;
      return aPriority - bPriority;
    });
    setSortedTypes(tempSortedTypes);

    const initialVisibility = {};
    tempSortedTypes.forEach(type => {
      initialVisibility[type] = true;
    });
    setVisibility(initialVisibility);

    setIndicatorDivideSection(isDivideSection);
  }, [allComponents]);

  useEffect(() => {
    if (selectedComponentIds && selectedComponentIds.length > 0) {
      const updateGroupComponent = {};

      sortedTypes.map(type => {
        const updateGroup = groupedComponents[type].map(item => {
          if (selectedComponentIds && selectedComponentIds.includes(item.id)) {
            return {...item, checked:true};
          } else {
            return {...item, checked:false};
          }
        });

        updateGroupComponent[type] = updateGroup;
      });

      setGroupedComponents(updateGroupComponent);
    }
  }, [selectedComponentIds]);

  const handleCheckboxChange = (id) => {
    setSelectedComponentIds((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((item) => item !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const allComponentIds = allComponents.map((component) => component.id);
      setSelectedComponentIds(allComponentIds);
    } else {
      setSelectedComponentIds((prevSelected) =>
        prevSelected.filter((id) => allComponents.find((component) => component.id === id).selected)
      );
    }
  };

  const handleApply = () => {
    onApply(selectedComponentIds, isIndicatorDivideSection);
  };

  const handleToggleVisibility = (type) => {
    setVisibility(prevVisibility => ({
      ...prevVisibility,
      [type]: !prevVisibility[type]
    }));
  };

  const handleTypeAllChange = (e, type) => {
    const isChecked = e.target.checked;
    const typeAllComponentIds = allComponents.filter(item => item.type === type).map((component) => component.id);
    if (isChecked) {
      setSelectedComponentIds([...new Set([...selectedComponentIds, ...typeAllComponentIds])]);
    } else {
      setSelectedComponentIds((prevSelected) =>
        prevSelected.filter((id) => !typeAllComponentIds.includes(id) ||
          allComponents.find((component) => component.id === id).selected
        )
      );
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-header">
        <div> Select Indicators to view </div>
        <div className="close" onClick={onCancel}> </div>
      </div>
      <div className="separator"></div>
      <div className="popup-body">
        <div className="section-header">
          <div className="select-all">
            <input
              type="checkbox"
              id="select-all"
              checked={selectedComponentIds.length === allComponents.length}
              onChange={handleSelectAllChange}
            />
            <label htmlFor="select-all">All</label>
          </div>
          <div className='divide-section'>
            <input
              type="checkbox"
              checked={isIndicatorDivideSection}
              onChange={(e) => setIndicatorDivideSection(e.target.checked)}
            />
            <label htmlFor="divide-section">Divide Section</label>
          </div>
        </div>
        <div className="separator"></div>
        {sortedTypes.map(type => (
          <div className="section">
            <>
              <div className="section-header">
                <div className='label'>
                  <div
                    className="dash-image"
                    onClick={() => handleToggleVisibility(type)}
                  >
                  </div>
                  <input
                    type="checkbox"
                    checked={groupedComponents[type].length === groupedComponents[type].filter(item => item.checked).length}
                    onChange={(e) => handleTypeAllChange(e, type)} />
                  <div> {type} </div>
                </div>
                <button
                  className={`toggle-button ${visibility[type] ? 'open' : 'close'}`}
                  onClick={() => handleToggleVisibility(type)}
                >
                </button>
              </div>
            </>
            <div className={`section-content ${visibility[type] ? 'visible' : ''}`}>
              {groupedComponents[type].map((component) => (
                <div key={component.id} className="checkbox-item">
                  <input
                    type="checkbox"
                    id={`component-${component.id}`}
                    checked={selectedComponentIds.includes(component.id)}
                    onChange={() => handleCheckboxChange(component.id)}
                    disabled={component.selected}
                  />
                  <label htmlFor={`component-${component.id}`}>{component.name}</label>
                </div>
              ))}
            </div>
            <div className="separator"></div>
          </div>
        ))}
      </div>
      <div className="popup-footer">
        <button onClick={onCancel}>Cancel</button>
        <button onClick={handleApply}>Apply</button>
      </div>
    </div>
  );
}

export default IndicatorsPopup;