import React, { useEffect, useState } from 'react';
import annotationPlugin from 'chartjs-plugin-annotation';
import { getHeaders } from '../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../constants/index';
import Loading from '../../common/Loading';
import Alert from '../../modal/Alert';
import LineChart from '../../common/LineChart';
import BarChart from '../../common/BarChart';
import { CHART_COLORS } from '../../common/ChartConsts';
import { dailyStatisticsErrorAccumulatedTotal, dailyStatisticsErrorOccurrenceTotal, dailyStatisticsErrorStatusTotal } from './IssueHistoryStatisticsData';

function IssueHistoryStatisticsTotal({ currentFilter, downloadDataType, chartType }) {
  const plugin = [annotationPlugin];
  const [rangeDate, setRangeDate] = useState([]);
  const [isHistoryDisplay, setHistoryDisplay] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const lineChartType = 'Line';

  useEffect(() => {
    if (currentFilter && currentFilter.date.length > 0 && currentFilter.subType === 'All') {
      setRangeDate([currentFilter.date[0], currentFilter.date[1]]);  // [startDate, endDate]
    }
  }, [currentFilter]);

  useEffect(() => {
    if (currentFilter && (currentFilter.subType === undefined || currentFilter.subType === 'All')) {
      defaultRangeDate();
    }
  }, []);

  useEffect(() => {
    if (rangeDate && rangeDate.length > 0) {
      setHistoryDisplay(false);
      getStatisticsHistoryInfo(currentFilter);
    }
  }, [rangeDate]);

  const getStatisticsHistoryInfo = currentFilter => {

    let barChartBody = {
      startDate: rangeDate[0],
      endDate: rangeDate[1],
      groupList: [],
      cpIdList: [],
      countryCodeList: [],
      detectionItemList: [],
      typeList: [],
    };

    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/historyAllChart`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(barChartBody),
    })
    .then(res => res.json())
    .then(body => {
      if (body) {
        const dailyIssueData = [];
        const accumulatedResolveRate = [];
        let accumulatedIssueCount = 0;
        let accumulatedResolvedIssueCount = 0;
        const dailyResolveRate = [];

        const beaconDailyIssueData = [];
        const beaconAccumulatedResolveRate = [];
        let beaconAccumulatedIssueCount = 0;
        let beaconAccumulatedResolvedIssueCount = 0;
        const beaconDailyResolveRate = [];
        const dates = Object.keys(body).sort();
        const convertDates = dates.map(v => {return v.substring(5,10)});

        for (let date of dates) {
          dailyIssueData.push(body[date]['raisedIssueCount']);
          accumulatedIssueCount += body[date]['raisedIssueCount'];
          accumulatedResolvedIssueCount += body[date]['resolvedIssueCount'];
          accumulatedResolveRate.push(accumulatedIssueCount === 0 ? 100 :
            Math.round( accumulatedResolvedIssueCount / accumulatedIssueCount * 100));
          dailyResolveRate.push(body[date]['raisedIssueCount'] === 0 ? 100:
            Math.round( body[date]['resolvedIssueCount']/ body[date]['raisedIssueCount'] * 100));

          beaconDailyIssueData.push(body[date]['raisedIssueBeaconCount']);
          beaconAccumulatedIssueCount += body[date]['raisedIssueBeaconCount'];
          beaconAccumulatedResolvedIssueCount += body[date]['resolvedIssueBeaconCount'];
          beaconAccumulatedResolveRate.push(beaconAccumulatedIssueCount === 0 ? 100 :
            Math.round( beaconAccumulatedResolvedIssueCount / beaconAccumulatedIssueCount * 100));
            beaconDailyResolveRate.push(body[date]['raisedIssueBeaconCount'] === 0 ? 100:
            Math.round( body[date]['resolvedIssueBeaconCount']/ body[date]['raisedIssueBeaconCount'] * 100));
        }
        let maxVal = 0;
        const statMaxVal = getMultipleOf100GreaterThan(Math.max.apply(null, dailyIssueData));
        const beaconMaxVal = getMultipleOf100GreaterThan(Math.max.apply(null, beaconDailyIssueData));
        statMaxVal > beaconMaxVal ? maxVal = statMaxVal : maxVal = beaconMaxVal;

        dailyStatisticsErrorOccurrenceTotal.data.labels = convertDates;
        dailyStatisticsErrorOccurrenceTotal.data.datasets[0].data = dailyIssueData;
        dailyStatisticsErrorOccurrenceTotal.data.datasets[1].data = beaconDailyIssueData;
        if(dailyStatisticsErrorOccurrenceTotal.options.scales.y.max != null && dailyStatisticsErrorOccurrenceTotal.options.scales.y.max < maxVal) {
          dailyStatisticsErrorOccurrenceTotal.options.scales.y.max = maxVal;
          dailyStatisticsErrorOccurrenceTotal.options.scales.y.ticks.stepSize = maxVal / 2;
        }

        dailyStatisticsErrorAccumulatedTotal.data.labels = convertDates;
        dailyStatisticsErrorAccumulatedTotal.data.datasets[0].data = accumulatedResolveRate;
        dailyStatisticsErrorAccumulatedTotal.data.datasets[1].data = beaconAccumulatedResolveRate;
        dailyStatisticsErrorStatusTotal.data.labels = convertDates;
        dailyStatisticsErrorStatusTotal.data.datasets[0].data = dailyResolveRate;
        dailyStatisticsErrorStatusTotal.data.datasets[1].data = beaconDailyResolveRate;
        setHistoryDisplay(true);

      } else {
        setHistoryDisplay(false);
        setAlertTitle('Error');
        setAlertMessage(`IssueHistoryStatisticsTotal error`);
        onOpenAlert();
      }
    })
    .catch(error => {
      console.log(`IssueHistoryStatisticsTotal error: `, error);
    }).finally(() => {});
  };

  // Default date는 30일
  const defaultRangeDays = 30; // 달력에 날짜를 보여 주기 위한 default 일자, 30일
  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());
    const thirtyDaysAgo = new Date(utcToday);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);
    const utcTodayString = utcToday.toISOString().substring(0, 10);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0, 10);
    setRangeDate([thirtyDaysAgoString, utcTodayString]); // [startDate, endDate]
  };

  const getMultipleOf100GreaterThan = (num) => {
    return num + (100 - num % 100);
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
      {isHistoryDisplay ? (
        <div className="grid-row">
          <div className="row">
            <div className="box-total">
              <div className="head">
                <div className="title">
                  <h2>Number of issues (based on occurrence date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Beacon</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Statistics</span>
                    </li>
                  </ul>
                </div>
                {chartType === lineChartType ? (
                  <LineChart id={'daily_total_error_occurrence'} data={dailyStatisticsErrorOccurrenceTotal.data}
                            options={dailyStatisticsErrorOccurrenceTotal.options} plugins={plugin}/>
                  ) : (
                  <BarChart id={'daily_total_error_occurrence'} data={dailyStatisticsErrorOccurrenceTotal.data}
                  options={dailyStatisticsErrorOccurrenceTotal.options} plugins={plugin}/>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-total">
              <div className="head">
                <div className="title">
                  <h2>Issue resolution rate (based on occurrence date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Beacon</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Statistics</span>
                    </li>
                  </ul>
                </div>
                {chartType === lineChartType ? (
                  <LineChart id={'daily_total_error_resolution'} data={dailyStatisticsErrorAccumulatedTotal.data}
                            options={dailyStatisticsErrorAccumulatedTotal.options} plugins={plugin}/>
                  ) : (
                  <BarChart id={'daily_total_error_resolution'} data={dailyStatisticsErrorAccumulatedTotal.data}
                  options={dailyStatisticsErrorAccumulatedTotal.options} plugins={plugin}/>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-total">
              <div className="head">
                <div className="title">
                  <h2>Issue resolution rate (based on cumulative daily date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Beacon</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Statistics</span>
                    </li>
                  </ul>
                </div>
                {chartType === lineChartType ? (
                  <LineChart id={'daily_total_error_cumulative_resolution'} data={dailyStatisticsErrorStatusTotal.data}
                            options={dailyStatisticsErrorStatusTotal.options} plugins={plugin}/>
                  ) : (
                  <BarChart id={'daily_total_error_cumulative_resolution'} data={dailyStatisticsErrorStatusTotal.data}
                  options={dailyStatisticsErrorStatusTotal.options} plugins={plugin}/>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )}
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default IssueHistoryStatisticsTotal;
