import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MaterialTable from 'material-table';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { getChannelmapsNamesAction, getChannelsAction } from '../../../actions/channelChannelActions';
function Channel({ channelMapIds, channels, getChannelmapsNamesAction, getChannelsAction, ricP, systemP, channelMapIdP, chanNameP, chanIdP, chanNoP }) {
  const [businessArea, setBusinessArea] = useState(ricP === 'EIC' ? 'EU' : ricP === 'AIC' ? 'NA/SCA' : 'KR');
  const [system, setSystem] = useState(systemP || 'cdp');
  const [channelMapId, setChannelMapId] = useState(channelMapIdP || '');
  const [chanCode, setChanCode] = useState(!chanIdP || chanIdP === '^' ? '' : chanIdP);
  const [chanName, setChanName] = useState(!chanNameP || chanNameP === '^' ? '' : chanNameP);
  const [chanNo, setChanNo] = useState(!chanNoP || chanNoP === '^' ? '' : chanNoP);
  const orderby = '';
  const pageNo = 1;
  const rowPerPage = 10000;
  const ric = businessArea === 'KR' ? 'KIC' : businessArea === 'EU' ? 'EIC' : 'AIC';

  useEffect(() => {
    if (!ricP) getChannelsAction();
  }, []);

  useEffect(() => {
    getChannelmapsNamesAction(ric, system);
  }, [businessArea, system]);

  const clickSearch = () => {
    if (!channelMapId) alert('Please select ChannelMapId !');
    else getChannelsAction(system, ric, channelMapId, chanCode, chanName, chanNo, orderby, pageNo, rowPerPage);
  };

  return (
    <div>
      <div className="business_area">
        <span className="business_area_tit">Business Area</span>
        <FormControl variant="outlined">
          <Select value={businessArea} onChange={e => setBusinessArea(e.target.value)} id="bussinessSelectArea">
            <MenuItem value={'NA/SCA'}>NA/SCA</MenuItem>
            <MenuItem value={'EU'}>EU</MenuItem>
            <MenuItem value={'KR'}>KR</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="filter_area">
        <FormControl variant="outlined">
          <InputLabel id="typeInput">ChannelMapId</InputLabel>
          <Select value={channelMapId} onChange={e => setChannelMapId(e.target.value)} labelId="typeSelect" id="type" label="type">
            {channelMapIds.map(n => (
              <MenuItem key={n.code} value={n.code}>
                {n.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField value={chanName} onChange={e => setChanName(e.target.value)} id="outlined-helperText" label="Ch.Name" variant="outlined" />
        <TextField value={chanCode} onChange={e => setChanCode(e.target.value)} id="outlined-helperText" label="Ch.ID" variant="outlined" />
        <TextField value={chanNo} onChange={e => setChanNo(e.target.value)} id="outlined-helperText" label="Ch.No." variant="outlined" />
      </div>
      <div className="btn_set_wrap search_wrap">
        <Button variant="contained" className={'btn_color2'} onClick={clickSearch}>
          {' '}
          Search{' '}
        </Button>
      </div>
      <div className="tbl_wrap">
        <div className="btn_set_wrap tbl_top1"></div>
        {
          <>
            <MaterialTable
              title=""
              columns={[
                {
                  title: 'Logo',
                  field: 'logoUrl',
                  render: n => {
                    let img = '';
                    if (n.admLogoUrl) img = n.admLogoUrl;
                    else if (n.logoUrl) img = n.logoUrl;

                    return <img alt="invalid" src={img} title={img} style={{ width: 70, backgroundColor: 'black' }} />;
                  },
                },
                {
                  title: 'Ch. ID',
                  field: 'chanCode',
                  render: n => {
                    return (
                      <Link className="link" to={`/CHANNEL/channel/channelView/${ric}/${system}/${channelMapId}/${chanName || '^'}/${chanCode || '^'}/${n.chanCode}/${chanNo || '^'}`}>
                        {n.chanCode}
                      </Link>
                    );
                  },
                },
                {
                  title: 'Ch. No.',
                  field: 'chanNo',
                },
                {
                  title: 'Ch. Name',
                  field: 'chanStdName',
                },
                {
                  title: 'CP Name',
                  field: 'providerName',
                },
                {
                  title: 'Last Updated',
                  field: 'lastChgDate',
                },
              ]}
              data={channels}
              options={{
                showTextRowsSelected: false,
                selection: false,
                pageSize: 10,
                rowStyle: { backgroundColor: '#fff' },
              }}
            />
          </>
        }
      </div>
    </div>
  );
}
Channel.propTypes = {
  channelMapIds: PropTypes.array.isRequired,
  channels: PropTypes.array.isRequired,
  getChannelmapsNamesAction: PropTypes.func.isRequired,
  getChannelsAction: PropTypes.func.isRequired,
  ricP: PropTypes.string,
  systemP: PropTypes.string,
  channelMapIdP: PropTypes.string,
  chanNameP: PropTypes.string,
  chanIdP: PropTypes.string,
  chanNoP: PropTypes.string,
};
const mapStateToProps = state => ({
  channelMapIds: state.channelChannel.channelMapIds,
  channels: state.channelChannel.channels,
});
const mapDispatchToProps = dispatch => ({
  getChannelmapsNamesAction(ric, system) {
    dispatch(getChannelmapsNamesAction(ric, system));
  },
  getChannelsAction(system, ric, channelMapId, chanCode, chanName, chanNo, orderby, pageNo, rowPerPage) {
    dispatch(getChannelsAction(system, ric, channelMapId, chanCode, chanName, chanNo, orderby, pageNo, rowPerPage));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Channel));
