import * as actions from '../../actions/actionTypes';

const initialState = {
  reservedList: [],
};

export default function channelMapReservationByIdType(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CHANNEL_MAPPING_RESERVATION_BY_ID_TYPE:
      return action.payload;
    default:
      return state;
  }
}
