import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import GridItem from '../../../common/Grid/GridItem.js';
import GridContainer from '../../../common/Grid/GridContainer.js';

const styles = theme => ({
  root: {
    maxWidth: 520,
    margin: '0 auto',
    marginTop: 40,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 3, 3),
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(8, 6),
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

function NotFound({ classes }) {
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              404
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              {"The Page you're looking for was not found."}
            </Typography>
            <div className={classes.heroButtons}>
              <GridContainer justify="center">
                <GridItem>
                  <Link to="/" className={classes.link}>
                    <Button data-testid="goBackButton" variant="contained" color="secondary">
                      Go Back
                    </Button>
                  </Link>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);
