import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TimerDialog } from '../../../common/dialog';

const calculateTimeLeft = totalSeconds => ({
  minutes: Math.floor(totalSeconds / 60),
  seconds: totalSeconds % 60 < 10 ? '0' + (totalSeconds % 60) : totalSeconds % 60,
});

export default function HeaderTime({ handleSignout }) {
  const [totalSecondsLeft, setTotalSecondsLeft] = useState(1800);
  const timeLeft = calculateTimeLeft(totalSecondsLeft);
  const [showLeftTime, setShowLeftTime] = useState(false);
  const [showTimeout, setShowTimeout] = useState(false);

  let timer;

  useEffect(() => {
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    if (totalSecondsLeft === 300) {
      setShowLeftTime(true);
      if (!showLeftTime) {
        decreaseTime();
      }
    } else if (totalSecondsLeft === 0) {
      setShowTimeout(true);
    } else {
      decreaseTime();
    }

    for (let i in events) {
      if (!showLeftTime) {
        window.addEventListener(events[i], handleReset);
      }
    }
    return () => {
      for (let i in events) {
        if (!showLeftTime) {
          window.removeEventListener(events[i], handleReset);
          clearTimeout(timer);
        }
      }
    }
  }, [totalSecondsLeft]);

  const handleReset = () => {
    clearTimeout(timer);
    setTotalSecondsLeft(1800);
    decreaseTime();
  };
  const decreaseTime = () => {
    timer = setTimeout(() => {
      setTotalSecondsLeft(totalSecondsLeft - 1);
    }, 1000);
  };

  return (
    <div className={'time_info'}>
      <button type={'button'} title="Click this to reset time !" onClick={handleReset}>
        <span>
          {timeLeft.minutes}:{timeLeft.seconds}
        </span>
      </button>
      {showLeftTime && <TimerDialog open={showLeftTime} handleClose={() => setShowLeftTime(false)} title={'Do you want to extend the session?'} description={'If you do nothing, you will be logged out in 5 minutes.'} handleReset={handleReset} />}
      {showTimeout && <TimerDialog open={showTimeout} handleClose={() => setShowTimeout(false)} title={'Session Timeout'} description={'Your session timed out. Please login again.'} handleSignout={handleSignout} />}
    </div>
  );
}

HeaderTime.propTypes = {
  handleSignout: PropTypes.func.isRequired,
};
