import React, { useMemo, useCallback, memo, useRef } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './QuillEditor.css'
import axios from 'axios';
import { ACCESS_TOKEN, API_BASE_URL } from '../../constants';

const QuillEditor = memo(({ quillRef, id, htmlContent, setHtmlContent, api, readOnly}) => {
    if (quillRef === undefined) {
        quillRef = useRef();
    }
    const imageHandler = useCallback(() => {
        const formData = new FormData();
        let url = "";
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.setAttribute("name", "image");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            formData.append("multipartFiles", file);
            const quill = quillRef.current.getEditor();
            const range = quill.getSelection(true);
            //console.log('id:', id,', api:', api,', quillRef:', quillRef, ', range.index:', range.index);

            //s3 image upload
            const res = await axios.post(API_BASE_URL + '/pushalarm/upload', formData, {
                headers: {
                "Authorization": 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
                "Content-Type": 'multipart/form-data',
                },
            })
            .then(res => {
                console.log('Image Url:', res.data);
                url = res.data;
                if(url === null || url === ''){
                    alert("Image Upload Fail.");
                }
              });

            quill.setSelection(range.index + 1);
            quill.insertEmbed(range.index, 'image', url);
        }
    }, [api, quillRef]);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] }, { 'background': [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                        { align: [] },
                    ],
                    ["image"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
        }), [imageHandler]);

    return (
        <>
            <ReactQuill
                ref={quillRef}
                value={htmlContent}
                onChange={setHtmlContent}
                modules={modules}
                theme="snow"
                id={id}
                className={styles.quillEditor}
                readOnly={readOnly}
            />
        </>
    )
})

export default QuillEditor