import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ProgramList from './components/program/ProgramList';
import ScheduleTab from './components/schedule/ScheduleTab';
import Temp from './components/schedule/channelList';
import Program from './components/program/Program';
import ChannelMapping from './components/channel/ChannelMapping';
import Channel from './components/channel/Channel';
import ChannelView from './components/channel/Channel/ChannelView';
import ChannelEdit from './components/channel/Channel/ChannelEdit';
// import ChannelFeed from './components/channel/ChannelFeed/ChannelFeed';
// import ChannelFeedView from './components/channel/ChannelFeed/ChannelFeedView';
// import ChannelFeedEdit from './components/channel/ChannelFeed/ChannelFeedEdit';
// import ChannelFeedAdd from './components/channel/ChannelFeed/ChannelFeedAdd';
import ChannelCount from './components/channel/ChannelCount/ChannelCount';
import Summary from './components/statistics/Summary';
import Channel2 from './components/statistics/Channel';
import Vod from './components/statistics/Vod';
import HomeApp from './components/statistics/HomeApp';
import DisplayRules from './components/promotion/DisplayRules';
import RSSFeed from './components/promotion/homeShelf/RSSFeed';
import RssFeedEdit from './components/promotion/homeShelf/RSSFeed/RSSFeedEdit';
import RssFeedDetail from './components/promotion/homeShelf/RSSFeed/RSSFeedDetail';
import RssFeedNew from './components/promotion/homeShelf/RSSFeed/RSSFeedNew';
import RSSCollectionLogs from './components/promotion/homeShelf/RSSCollectionLogs';
import Dashboard from './components/dashboard';
import Profile from './components/user/Profile';
import AdminList from './components/account/admin';
import CpCode from './components/account/cpCode';
import CpMember from './components/account/cpMember';
import Notification from './components/system/notification';
import NotificationDetail from './components/system/notification/NotificationDetail';
import NotificationCreate from './components/system/notification/NotificationCreate';
import ApiCallLogs from './components/system/apiCallLogs';
import WhitelistIpList from './components/whitelistip/WhitelistIpList';
import Signin from './components/user/Signin';
import ScrollToTop from './common/ScrollTop';
import Main from './components/layout/Main';
import AppHeader from './components/layout/AppHeader';
import AppDrawer from './components/layout/AppDrawer';
import NotFound from './components/layout/NotFound';
import PageTitle from './components/layout/PageTitle';
import Region from './components/homeApp/Region';
import TabCategory from './components/homeApp/TabCategory';
import TabCategoryDetail from './components/homeApp/TabCategoryDetail';
import Auto from './components/homeApp/organize/Auto';
import Manual from './components/homeApp/organize/Manual';
import Carousel from './components/homeApp/organize/Carousel';
import Slider from './components/homeApp/organize/Slider';
import Genre from './components/homeApp/Genre';
import GenreDetail from './components/homeApp/GenreDetail';
import Emulate from './components/homeApp/Emulate';
import PageLoader from './common/PageLoader';
import Monitor from './components/homeApp/Monitor';
import TermsofUse from './components/web/TermsofUse';
import PrivacyPolicy from './components/web/PrivacyPolicy';
import HomeAppNotification from './components/homeApp/notification/Notification';
import AppVersion from './components/mobile/appVersion/AppVersion';
import PushAlarm from './components/mobile/pushAlarm';
import TabCategorySchedule from './components/homeApp/TabCategorySchedule';
import ABTest from './components/recommend/antest/ABTest';
import AddABTest from './components/recommend/antest/AddABTest';
import ABTestResult from './components/recommend/antest/ABTestResult';
import EditABTest from './components/recommend/antest/EditABTest';
import AddSegment from './components/recommend/antest/AddSegment';
import OrganizeSegment from './components/recommend/antest/OrganizeSegment';

import ANService from './components/recommend/anservice/ABTest';

import ANServiceEditABTest from './components/recommend/anservice/EditABTest';
import AddANTestService from './components/recommend/anservice/AddANTestService';
import OrganizeService from './components/recommend/anservice/OrganizeService';
import AddServiceSegment from './components/recommend/anservice/AddServiceSegment';
import OrganizeServiceSegment from './components/recommend/anservice/OrganizeServiceSegment';
import ANServiceABTestResult from './components/recommend/anservice/ANServiceResult';
import OrganizeANTestSegment from './components/recommend/anservice/OrganizeSegment';
import InteractiveDock from './components/liveTv/interactiveDock/InteractiveDock';

import IssDashboard from './components/iss/IssDashboard';
import ServiceDetail from './components/iss/ServiceDetail';
import ContentsDetail from './components/iss/ContentsDetail';
import StatisticsDetail from './components/iss/StatisticsDetail';
import FeatureBat from './components/iss/FeatureBat';
import IssueHistory from './components/iss/IssueHistory';
import AssignHistory from './components/iss/AssignHistory';
import IssueTrend from './components/iss/IssueTrend';

import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import WatchLive from './components/promotion/watchLive/WatchLive';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, BarElement, Tooltip, Legend } from 'chart.js';
import FreeOnLgChannels from './components/promotion/freeOnLgChannel/FreeOnLgChannels';
import AdDetail from "./components/iss/AdDetail";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, BarElement, Tooltip, Legend, annotationPlugin, ChartDataLabels);

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  main: {
    flexGrow: 1,
    overflow: 'auto',
  },
});
function Routes({ classes, isAuthenticated, ui }) {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <div className={classes.root}>
          <PageLoader />
          <CssBaseline />
          <AppHeader />
          <AppDrawer />
          <PageTitle />
          <main className={classes.main + ' main2 test'} id={(ui && ui.headerMenu !== 'ISS') ? 'contents' : 'iss-contents'} >
            <div className={classes.appBarSpacer} />
            {isAuthenticated ? (
              <Switch>
                <Route exact path="/" component={Main} />
                <Route exact path="/ISS/IssDashboard" component={IssDashboardMatch} />
                <Route exact path="/ISS/IssDashboard/:issueId/0" component={IssDashboardMatch} />
                <Route exact path="/ISS/ServiceDetail" component={ServiceDetail} />
                <Route exact path="/ISS/ContentsDetail" component={ContentsDetail} />
                <Route exact path="/ISS/StatisticsDetail" component={StatisticsDetail} />
                <Route exact path="/ISS/AdDetail" component={AdDetail} />
                <Route exact path="/ISS/FeatureBat" component={FeatureBat} />
                <Route exact path="/ISS/IssueHistory" component={IssueHistory} />
                <Route exact path="/ISS/AssignHistory" component={AssignHistory} />
                <Route exact path="/ISS/IssueTrend" component={IssueTrend} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/PROGRAM/schedulelist" component={ScheduleTab} />
                <Route exact path="/PROGRAM/programlist" component={ProgramList} />
                <Route exact path="/PROGRAM/temp" component={Temp} />
                <Route exact path="/PROGRAM/program/:providerId/:programId" component={ProgramMatch} />
                <Route exact path="/CHANNEL/channelMapping" component={ChannelMapping} />
                <Route exact path="/CHANNEL/channel" component={Channel} />
                <Route exact path="/CHANNEL/channel/:ric/:system/:channelMapId/:chanName/:chanId/:chanNo" component={ChannelMach} />
                <Route exact path="/CHANNEL/channel/channelView/:ric/:system/:channelMapId/:chanName/:chanIdSearch/:chanId/:chanNo" component={ChannelViewMach} />
                <Route exact path="/CHANNEL/channel/channelEdit/:ric/:system/:channelMapId/:chanName/:chanIdSearch/:chanId/:chanNo" component={ChannelEditMach} />
                {/* <Route exact path="/CHANNEL/channelFeed" component={ChannelFeed} />
                <Route exact path="/CHANNEL/channelFeed/:params" component={ChannelFeedMach} />
                <Route exact path="/CHANNEL/channelFeed/channelFeedView/:params" component={ChannelFeedViewMach} />
                <Route exact path="/CHANNEL/channelFeed/channelFeedEdit/:params" component={ChannelFeedEditMach} />
                <Route exact path="/CHANNEL/channelFeed/channelFeedAdd/:params" component={ChannelFeedAddMach} /> */}
                <Route exact path="/CHANNEL/channelCount" component={ChannelCount} />
                <Route exact path="/STATISTICS/summary" component={Summary} />
                <Route exact path="/STATISTICS/channel" component={Channel2} />
                <Route exact path="/STATISTICS/program" component={Vod} />
                <Route exact path="/STATISTICS/lgChannelsHome" component={HomeApp} />
                <Route exact path="/PROMOTION/displayRules" component={DisplayRules} />
                <Route exact path="/PROMOTION/homeShelf/rssFeed" component={RSSFeedMatch} />
                <Route exact path="/PROMOTION/homeShelf/rssFeed/detail/:feedIdx" component={RSSFeedDetailMatch} />
                <Route exact path="/PROMOTION/homeShelf/rssFeed/edit/:feedIdx" component={RSSFeedEditMatch} />
                <Route exact path="/PROMOTION/homeShelf/rssFeed/new" component={RssFeedNew} />
                <Route exact path="/PROMOTION/homeShelf/rssCollectionLogs" component={RSSCollectionLogs} />
                <Route exact path="/PROMOTION/watchLive" component={WatchLive} />
                <Route exact path="/ACCOUNT/admin" component={AdminList} />
                <Route exact path="/ACCOUNT/cpCode" component={CpCode} />
                <Route exact path="/ACCOUNT/cpMember" component={CpMember} />
                <Route exact path="/SYSTEM/notification" component={Notification} />
                <Route exact path="/SYSTEM/notification/:id" component={NotificationMatch} />
                <Route exact path="/SYSTEM/notificationCreate" component={NotificationCreate} />
                <Route exact path="/SYSTEM/apiCallLogs" component={ApiCallLogs} />
                <Route exact path="/HOME-APP/region" component={Region} />
                <Route exact path="/HOME-APP/tab" component={TabCategory} />
                <Route exact path="/HOME-APP/detail/:status/:type/:tabId/:categoryId" component={TabCategoryMatch} />
                <Route exact path="/HOME-APP/automaticOrganizing/:id" component={AutomaticOrganizingMatch} />
                <Route exact path="/HOME-APP/manualOrganizing/:id" component={ManualOrganizingMatch} />
                <Route exact path="/HOME-APP/carouselOrganizing/:id" component={CarouselOrganizingMatch} />
                <Route exact path="/HOME-APP/sliderOrganizing/:id" component={SliderOrganizingMatch} />
                <Route exact path="/HOME-APP/emulate" component={Emulate} />
                <Route exact path="/HOME-APP/monitor" component={Monitor} />
                <Route exact path="/HOME-APP/notification" component={HomeAppNotification} />
                <Route exact path="/HOME-APP/:tabId/schedule" component={TabCategoryScheduleMatch} />
                <Route exact path="/HOME-APP/genre" component={Genre} />
                <Route exact path="/HOME-APP/genre/genreDetail/:genreCode" component={HomeAppGenreDetailMach} />
                <Route exact path="/WEB/termsofuse" component={TermsofUse} />
                <Route exact path="/WEB/privacypolicy" component={PrivacyPolicy} />
                <Route exact path="/LIVE-TV/interactiveDock" component={InteractiveDock} />
                <Route exact path="/MOBILE/appversion" component={AppVersion} />
                <Route exact path="/MOBILE/pushAlarm" component={PushAlarm} />
                <Route exact path="/signin" component={Signin} />
                <Route exact path="/RECOMMEND/antest" component={ABTest} />
                <Route exact path="/RECOMMEND/antest/abtestresult/:id/segment/:segMentId" component={ABTestResultMatch} />
                <Route exact path="/RECOMMEND/antest/addabtest" component={AddABTest} />
                <Route exact path="/RECOMMEND/antest/editabtest/:id" component={EditABTestMatch} />
                <Route exact path="/RECOMMEND/antest/addsegment/:id" component={AddSegmentMatch} />
                <Route exact path="/RECOMMEND/antest/organize/abtest/:id/segment/:segMentId" component={OrganizeSegmentMatch} />

                <Route exact path="/RECOMMEND/anservice" component={ANService} />
                <Route exact path="/RECOMMEND/anservice/addantestservice" component={AddServiceMatch} />
                <Route exact path="/RECOMMEND/anservice/addantestservice/:id/segment/:segMentId" component={AddServiceMatch} />
                <Route exact path="/RECOMMEND/anservice/organizeservice/service/:serviceId" component={OrganizeServiceMatch} />
                <Route exact path="/RECOMMEND/anservice/abtestresult/:id/segment/:segMentId" component={ABTestResultMatch} />


                <Route exact path="/RECOMMEND/anservice/serviceresult/service/:serviceId" component={ANServiceABTestResultMatch} />
                <Route exact path="/PROMOTION/freeOnLGChannels" component={FreeOnLgChannels} />


                <Route exact path="/RECOMMEND/anservice/addsegment/:serviceId" component={AddServiceSegmentMatch} />
                <Route exact path="/RECOMMEND/anService/organize/service/:id/segment/:segMentId" component={OrganizeServiceSegmentMatch} />
                <Route exact path="/RECOMMEND/anservice/result/:id/segment/:segMentId" component={ANServiceABTestResultMatch} />
                <Route exact path="/RECOMMEND/anservice/organize/abtest/:id/segment/:segMentId" component={OrganizeANTestSegmentMatch} />

                <Route path="/whitelistiplist" component={WhitelistIpList} />
                <Route component={NotFound} />
              </Switch>
            ) : (
              <Switch>
                <Route exact path="*" component={Main} />
              </Switch>
            )}
            {
              ui && ui.headerMenu !== 'ISS' ? (
                <center className="footer">Copyright © LG Electronics. All Rights Reserved.</center>
              ) : (
                <></>
              )
            }
          </main>
        </div>
      </ScrollToTop>
    </BrowserRouter>
  );
}
Routes.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.currentUser.isAuthenticated,
  ui: state.ui
});

export default connect(mapStateToProps)(withStyles(styles)(Routes));
//export default withStyles(styles)(Routes);

function ProgramMatch({ match }) {
  return <Program providerId={match.params.providerId} programId={match.params.programId} />;
}
ProgramMatch.propTypes = {
  match: PropTypes.object,
};

function NotificationMatch({ match }) {
  return <NotificationDetail id={match.params.id} />;
}
NotificationMatch.propTypes = {
  match: PropTypes.object,
};
ProgramMatch.propTypes = {
  match: PropTypes.object,
};
ProgramMatch.propTypes = {
  match: PropTypes.object,
};
function TabCategoryMatch({ match }) {
  return <TabCategoryDetail status={match.params.status} type={match.params.type} tabId={match.params.tabId} categoryId={match.params.categoryId} />;
}
TabCategoryMatch.propTypes = {
  match: PropTypes.object,
};

function AutomaticOrganizingMatch({ match }) {
  return <Auto id={match.params.id} />;
}
AutomaticOrganizingMatch.propTypes = {
  match: PropTypes.object,
};
function ManualOrganizingMatch({ match }) {
  return <Manual id={match.params.id} />;
}
ManualOrganizingMatch.propTypes = {
  match: PropTypes.object,
};
function CarouselOrganizingMatch({ match }) {
  return <Carousel id={match.params.id} />;
}
CarouselOrganizingMatch.propTypes = {
  match: PropTypes.object,
};
function SliderOrganizingMatch({ match }) {
  return <Slider id={match.params.id} />;
}
SliderOrganizingMatch.propTypes = {
  match: PropTypes.object,
};
function HomeAppGenreDetailMach({ match }) {
  return <GenreDetail genreCode={match.params.genreCode} />;
}
HomeAppGenreDetailMach.propTypes = {
  match: PropTypes.object,
};
function ChannelMach({ match }) {
  return <Channel ricP={match.params.ric} systemP={match.params.system} channelMapIdP={match.params.channelMapId} chanNameP={match.params.chanName} chanIdP={match.params.chanId} chanNoP={match.params.chanNo} />;
}
ChannelMach.propTypes = {
  match: PropTypes.object,
};

function ChannelViewMach({ match }) {
  return <ChannelView ric={match.params.ric} system={match.params.system} channelMapId={match.params.channelMapId} chanName={match.params.chanName} chanIdSearch={match.params.chanIdSearch} chanId={match.params.chanId} chanNo={match.params.chanNo} />;
}
ChannelViewMach.propTypes = {
  match: PropTypes.object,
};

function ChannelEditMach({ match }) {
  return <ChannelEdit ric={match.params.ric} system={match.params.system} channelMapId={match.params.channelMapId} chanName={match.params.chanName} chanIdSearch={match.params.chanIdSearch} chanId={match.params.chanId} chanNo={match.params.chanNo} />;
}
ChannelEditMach.propTypes = {
  match: PropTypes.object,
};

function TabCategoryScheduleMatch({ match }) {
  return <TabCategorySchedule tabId={match.params.tabId} />;
}
TabCategoryScheduleMatch.propTypes = {
  match: PropTypes.object,
};

/*
function ChannelFeedMach({ match }) {
  return <ChannelFeed params={match.params.params} />;
}
ChannelFeedMach.propTypes = {
  match: PropTypes.object,
};
function ChannelFeedViewMach({ match }) {
  return <ChannelFeedView params={match.params.params} />;
}
ChannelFeedViewMach.propTypes = {
  match: PropTypes.object,
};
function ChannelFeedEditMach({ match }) {
  return <ChannelFeedEdit params={match.params.params} />;
}
ChannelFeedEditMach.propTypes = {
  match: PropTypes.object,
};
function ChannelFeedAddMach({ match }) {
  return <ChannelFeedAdd params={match.params.params} />;
}
ChannelFeedAddMach.propTypes = {
  match: PropTypes.object,
};*/

// RSSFeedMatch
function RSSFeedMatch({ match }) {
  return <RSSFeed feedIdx={match.params.feedIdx} />;
}
RSSFeedMatch.propTypes = {
  match: PropTypes.object,
};
// RSSFeedMatch
function RSSFeedDetailMatch({ match }) {
  return <RssFeedDetail feedIdx={match.params.feedIdx} />;
}
RSSFeedDetailMatch.propTypes = {
  match: PropTypes.object,
};
// RSSFeedMatch
function RSSFeedEditMatch({ match }) {
  return <RssFeedEdit feedIdx={match.params.feedIdx} />;
}
RSSFeedEditMatch.propTypes = {
  match: PropTypes.object,
};
function EditABTestMatch({ match }) {
  return <EditABTest id={match.params.id} />;
}
function ABTestResultMatch({ match }) {
  return <ABTestResult id={match.params.id} segMentId={match.params.segMentId} />;
}
EditABTestMatch.propTypes = {
  match: PropTypes.object,
};

function IssDashboardMatch({ match }) {
  return <IssDashboard issueId={match.params.issueId} />
}
IssDashboardMatch.propTypes = {
  match: PropTypes.object,
};

function AddSegmentMatch({ match }) {
  return <AddSegment id={match.params.id} />;
}
AddSegmentMatch.propTypes = {
  match: PropTypes.object,
};
function OrganizeSegmentMatch({ match }) {
  return <OrganizeSegment id={match.params.id} segMentId={match.params.segMentId} />;
}
OrganizeSegmentMatch.propTypes = {
  match: PropTypes.object,
};


function AddServiceMatch({ match }) {
  return <AddANTestService id={match.params.id} segMentId={match.params.segMentId} />;
}
AddServiceMatch.propTypes = {
  match: PropTypes.object,
};
function OrganizeServiceMatch({ match }) {
  return <OrganizeService id={match.params.id} segmentSubId={match.params.segmentSubId} serviceId={match.params.serviceId} />;
}
OrganizeServiceMatch.propTypes = {
  match: PropTypes.object,
};


function OrganizeServiceSegmentMatch({ match }) {
  return <OrganizeServiceSegment id={match.params.id} segMentId={match.params.segMentId} />;
}
OrganizeServiceSegmentMatch.propTypes = {
  match: PropTypes.object,
};
function ANServiceABTestResultMatch({ match }) {
  return <ANServiceABTestResult id={match.params.id} segMentId={match.params.segMentId} />;
}
ANServiceABTestResultMatch.propTypes = {
  match: PropTypes.object,
};

function AddServiceSegmentMatch({ match }) {
  return <AddServiceSegment id={match.params.id} segmentSubId={match.params.segmentSubId} serviceId={match.params.serviceId} />;
}
AddServiceSegmentMatch.propTypes = {
  match: PropTypes.object,
};

function OrganizeANTestSegmentMatch({ match }) {
  return <OrganizeANTestSegment id={match.params.id} segMentId={match.params.segMentId} />;
}
OrganizeANTestSegmentMatch.propTypes = {
  match: PropTypes.object,
};