import dayjs from 'dayjs';

export const getDurationString = seconds => {
  let days = parseInt(seconds / (60 * 60 * 24), 10);
  let hours = parseInt((seconds % (60 * 60 * 24)) / (60 * 60), 10);
  return `${days} days, ${hours} hours`;
};

export const getISO8601String = dateString => {
  return dayjs(dateString).format();
};

//true: 0, 1, 2, 3... positive integer
//false: -1, 2.0, 01, '1 ', '5a' , ....
export const isNormalInteger = str => {
  return /^\+?(0|[1-9]\d*)$/.test(str);
};

//true: 1, 2, 3... natualNumber
//false: 0, -1, 2.0, 01, '1 ', '5a' , ....
export const isNaturalNum = str => {
  return /^\+?([1-9]\d*)$/.test(str);
};

// how different with Number.isInteger(), !isNaN(), Number.isNaN()
// true: ...-1, 0, 1, 2, 3...
// false: 2.0, 01, '1 ', '5a' , ....
export const isInteger = str => {
  return /^-?\d+$/.test(str);
};

export const isNumeric = str => {
  return !isNaN(Number(str));
};

//console.log('parse', parseInt('5a')); //automatically extract 5 from 5a
//Number.isInteger('5') //false because it's type checking

export const sortAndCompareArrays = (array1, array2) => {
  const array1Sorted = array1.sort();
  const array2Sorted = array2.sort();
  return (
    array1.length === array2.length &&
    array1Sorted.every(function (value, index) {
      return value === array2Sorted[index];
    })
  );
};

export const compareArrays = (array1, array2) => {
  return (
    array1.length === array2.length &&
    array1.every(function (value, index) {
      return value === array2[index];
    })
  );
};

export const isChannelListDiff = (list1, list2) => {
  // any length diffrences
  if (list1.length !== list2.length) {
    return true;
  }
  //  length same
  if (list1.length === list2.length) {
    //length same and channels id's diffent
    let list1ChannelIdList = list1.map(x => x.channelId);
    let list2ChannelIdList = list2.map(x => x.channelId);
    // sort
    const list1ChannelIdListSorted = list1ChannelIdList.sort();
    const list2ChannelIdListSorted = list2ChannelIdList.sort();

    if (!compareArrays(list1ChannelIdListSorted, list2ChannelIdListSorted)) {
      return true;
    }
    //length same and channels are are same and keys diffrent
    //console.log(compareArrays(list1ChannelIdList, list2ChannelIdList));
    if (compareArrays(list1ChannelIdListSorted, list2ChannelIdListSorted)) {
      //console.log(array1Sorted,array2Sorted );
      return list1ChannelIdListSorted.some(function (value, index) {
        // console.log(value);
        let obj1 = list1.find(x => x.channelId === value);
        let obj2 = list2.find(x => x.channelId === list2ChannelIdListSorted[index]);
        //console.log(obj1, obj2);
        return obj1.manualFlag !== obj2.manualFlag || obj1.resultOrder !== parseInt(obj2.resultOrder);
      });
    }
  }

  return false;
};
