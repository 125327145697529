////////////////////////////////////////////////////////////////////////////////
// import
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// import basic
////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from 'react'; // React
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; // connect
////////////////////////////////////////////////////////////////////////////////
// import material
////////////////////////////////////////////////////////////////////////////////
import { Button, TextField, Paper, TableContainer, TableRow, TableCell, Table } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableHead from '@material-ui/core/TableHead';
import { createRssFeed } from '../../../../actions/rssFeedActions';
import { setDetailPageCountries } from '../../../../actions/rssFilterActions';
////////////////////////////////////////////////////////////////////////////////
// RSSFeed
////////////////////////////////////////////////////////////////////////////////
function RSSFeedNew({ history, detailcountries, createRssFeed, setDetailPageCountries }) {
  // useEffect(() => {
  //   setDetailPageCountries();
  // }, [feedlist, setDetailPageCountries]);
  useEffect(() => {
    setDetailPageCountries();
  }, [setDetailPageCountries]);
  const [country, setCountry] = useState([]);
  const [cpName, setCpName] = useState('');
  const [path, setPath] = useState('');
  const newFeedSave = () => {
    if (cpName && path) {
      createRssFeed(
        {
          feedIdx: '',
          cntryCode: country.join(','),
          cpName: cpName,
          rssPath: path,
        },
        history,
      );
    }
  };
  const nonSubmit = e => {
    e.preventDefault();
    return false;
  };

  return (
    <div>
      <form onSubmit={nonSubmit}>
        <TableContainer component={Paper} className={'tbl_wrap'}>
          <Table className="tbl_row_typ1 tbl_rssfeed none_bar">
            <colgroup>
              <col style={{ width: '15%' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '35%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Country</TableCell>
                <td>
                  <Autocomplete
                    id="country-select-demo"
                    multiple
                    options={detailcountries}
                    value={country}
                    defaultValue={[]}
                    autoHighlight
                    onChange={(e, value) => setCountry(value)}
                    getOptionLabel={option => option}
                    renderInput={params => <TextField {...params} label="Country" variant="outlined" />}
                  />
                </td>
              </TableRow>
              <TableRow>
                <TableCell>
                  <label htmlFor="cpName" className="is_required">
                    CP Name
                  </label>
                </TableCell>
                <td>
                  <TextField required id="cpName" variant="outlined" fullWidth onChange={e => setCpName(e.target.value)} className="" />
                </td>
              </TableRow>
              <TableRow>
                <TableCell>
                  <label htmlFor="path" className="is_required">
                    Path
                  </label>
                </TableCell>
                <td colSpan={3}>
                  <TextField fullWidth required onChange={e => setPath(e.target.value)} id="path" variant="outlined" className="" />
                </td>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <div className="btn_set_wrap">
          <Button
            variant="contained"
            onClick={() => {
              history.push('/PROMOTION/homeShelf/rssFeed');
            }}
            className="btn_color1">
            Cancel
          </Button>
          <Button variant="contained" type="submit" onClick={newFeedSave} className="btn_color2">
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}
RSSFeedNew.propTypes = {
  goToList: PropTypes.func,
  history: PropTypes.object,
  detailcountries: PropTypes.array,
  createRssFeed: PropTypes.func,
  setDetailPageCountries: PropTypes.func,
};
const mapStateToProps = state => ({
  detailcountries: state.rssFeed.detailcountries,
});
const mapDispatchToProps = dispatch => ({
  setDetailPageCountries: () => dispatch(setDetailPageCountries()),
  createRssFeed: (param, isRoleAdmin) => dispatch(createRssFeed(param, isRoleAdmin)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RSSFeedNew);
