import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PrimaryButton from '../common/PrimaryButton';
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function Info(props) {
  const { isOpen, onClose, title, message, className } = props;
  const [open, setOpen] = useState(isOpen);
  const [infoMessage, setInfoMessage] = useState('')

  const additionalClassName = className ? ' ' + className : '';

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      onClose();
    }
    return () => {
      setOpen(false);
    };
  }, [open]);

  const [selectedValue, setSelectedValue] = useState(message[0].name)
  const handleChange = (e) => {
    setSelectedValue(e.target.value)
  }

  useEffect(() => {
    const tab = message.find(m => m.name === selectedValue)
    setInfoMessage(tab ? tab.message : '')
  }, [selectedValue]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={'modal-alert'+additionalClassName}>
        <DialogTitle id="alert-dialog-title" className="modal-title">
          {title}
          <button className="btn-close" onClick={handleClose}>
            close
          </button>
        </DialogTitle>
        <DialogContent className="modal-contents">
          <FormControl>
            <RadioGroup row onChange={handleChange} value={selectedValue} style={{color:"white"}}>
              {message.map(m =>
                  <FormControlLabel control={<Radio style={{color:"#c5bbff"}}/>} label={m.name} value={m.name}/>
              )}
              </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogContent className="modal-contents">
          <DialogContentText id="alert-dialog-description">{infoMessage}</DialogContentText>
        </DialogContent>
        <DialogActions className="modal-buttons">
          <PrimaryButton type={'button'} text={'Ok'} btnClass={'btn-primary-accent'} onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Info;
