import { combineReducers } from 'redux';
//import email from './email';
import isAuthenticated from './isAuthenticated';
import isRoleAdmin from './isRoleAdmin';
import isSuperAdmin from './isSuperAdmin';
import name from './name';
import username from './username';
import apikey from './apikey';
import apikeyId from './apikeyId';
import providerId from './providerId';
import role from './role';
import userRole from './userRole';
import userAuth from './userAuth';

export default combineReducers({
  //email,
  isAuthenticated,
  isRoleAdmin,
  isSuperAdmin,
  name,
  username,
  apikey,
  apikeyId,
  providerId,
  role,
  userRole,
  userAuth
});
