import { combineReducers } from 'redux';
import providers from './providers';
import providerIds from './providerIds';
import fetching from './fetching';
import regionProvider from './regionProvider';
//import isAdded from './isAdded';
// import last from './last';
// import page from './page';
// import size from './size';
// import totalElements from './totalElements';
// import totalPages from './totalPages';

export default combineReducers({
  providers,
  providerIds,
  fetching,
  regionProvider,
  //isAdded,
  // last,
  // page,
  // size,
  // totalElements,
  // totalPages
});
