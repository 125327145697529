import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import { DialogContent, DialogTitle, IconButton, Dialog, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TabDetailSkinPreview from './TabDetailSkinPreview';

function TabDetailSkin({ isEdit, content, setContent }) {
  const [previewStatus, setPreviewStatus] = React.useState(false);
  const [opacityImg, setOpacityImg] = React.useState('');
  const [opacityInfo, setOpacityInfo] = React.useState({ w: '', h: '' });
  const [bgImg, setBgImg] = React.useState('');
  const [bgInfo, setBgInfo] = React.useState({ w: '', h: '' });
  const [tabImg, setTabImg] = React.useState('');
  const [tabInfo, setTabInfo] = React.useState({ w: '', h: '' });
  const [previewImg, setPreviewImg] = React.useState('');
  const [previewInfo, setPreviewInfo] = React.useState({ w: '', h: '' });
  const [buttonImg, setButtonImg] = React.useState('');
  const [buttonInfo, setButtonInfo] = React.useState({ w: '', h: '' });
  const prefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;

  const style = {
    color : 'red',
  };

  React.useEffect(() => {
    setBgImg(content.bgImg ? `${prefix}${content.bgImg}` : '');
    setPreviewImg(content.previewImg ? `${prefix}${content.previewImg}` : '');
    setButtonImg(content.buttonImg ? `${prefix}${content.buttonImg}` : '');
    setTabImg(content.tabImg ? `${prefix}${content.tabImg}` : '');
    setOpacityImg(content.opacityImg ? `${prefix}${content.opacityImg}` : '');
  }, [content]);

  const getColorPickerData = name => {
    return (content || { [name]: '#ffffff' })[name] || '#ffffff';
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>Tab Image</TableCell>
        <td colSpan="3">
          {tabImg && (
            <img
              alt="invalid"
              src={tabImg}
              title={tabImg}
              style={{ maxWidth: '100%', backgroundColor: 'black' }}
              onLoad={e => {
                setTabInfo({ w: e.target.naturalWidth, h: e.target.naturalHeight });
              }}
            />
          )}
          <Grid container style={{ marginTop: '5px' }}>
            <Grid>
              <p className="col-md-9">Recommended Size: 78 X 78</p>
              {tabImg && (
                <p className="col-md-9">
                  Actual Image Size: {tabInfo.w} X {tabInfo.h}
                </p>
              )}
            </Grid>
          </Grid>
          {isEdit && <TabEditImg img={tabImg} setImg={setTabImg} type="tab" />}
          {isEdit && tabImg === '' && (content.tabImg = '')}
        </td>
      </TableRow>
      <TableRow>
        <TableCell>3.0 Theme</TableCell>
        <td colSpan="3">
          <TableRow>
            <TableCell>opacity Image</TableCell>
            <td colSpan="3">
              {opacityImg && (
                <img
                  alt="invalid"
                  src={opacityImg}
                  title={opacityImg}
                  style={{ maxWidth: '100%', backgroundColor: 'black' }}
                  onLoad={e => {
                    setOpacityInfo({ w: e.target.naturalWidth, h: e.target.naturalHeight });
                  }}
                />
              )}
              <Grid container style={{ marginTop: '5px' }}>
                <Grid>
                  <p className="col-md-9">Recommended Size: 1920 X 1080</p>
                  {opacityImg && (
                    <p className="col-md-9">
                      Actual Image Size: {opacityInfo.w} X {opacityInfo.h}
                    </p>
                  )}
                </Grid>
              </Grid>
              {isEdit && <TabEditImg img={opacityImg} setImg={setOpacityImg} type="opacity" />}
              {isEdit && opacityImg === '' && (content.opacityImg = '')}
              <h6 style={style}>홈앱 3.0이 적용된 LG채널의 BG(Thema)에는 opacity된 이미지를 업로드 해야되고, Preview image 및 button 이미지 등록이 필요 없습니다.</h6>
              <h6 style={style}>LG Channel's BG (Thema) with Home App 3.0 must upload an opacity image, and Preview image and button image registration are not required.</h6>
            </td>
          </TableRow>
        </td>
      </TableRow>
      <TableRow>
        <TableCell>Theme</TableCell>
        <td colSpan="3">
          <TableRow>
            <TableCell>Theme Apply</TableCell>
            <td colSpan="3">
              <Switch
                checked={content.themeApply === 'Y'}
                onChange={() =>
                  isEdit
                    ? setContent({
                        ...content,
                        themeApply: content.themeApply === 'Y' ? 'N' : 'Y',
                      })
                    : alert('Click Edit button to change theme apply status')
                }
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </td>
          </TableRow>
          <TableRow>
            <TableCell>Background Image</TableCell>
            <td colSpan="3">
              {bgImg && (
                <img
                  alt="invalid"
                  src={bgImg}
                  title={bgImg}
                  style={{ maxWidth: '100%', backgroundColor: 'black' }}
                  onLoad={e => {
                    setBgInfo({ w: e.target.naturalWidth, h: e.target.naturalHeight });
                  }}
                />
              )}
              <Grid container style={{ marginTop: '5px' }}>
                <Grid>
                  <p className="col-md-9">Recommended Size: 1920 X 1080</p>
                  {bgImg && (
                    <p className="col-md-9">
                      Actual Image Size: {bgInfo.w} X {bgInfo.h}
                    </p>
                  )}
                </Grid>
              </Grid>
              {isEdit && <TabEditImg img={bgImg} setImg={setBgImg} type="bg" />}
            </td>
          </TableRow>
          <TableRow>
            <TableCell>Preview Image</TableCell>
            <td colSpan="3">
              {previewImg && (
                <img
                  alt="invalid"
                  src={previewImg}
                  title={previewImg}
                  style={{ maxWidth: '100%', backgroundColor: 'black' }}
                  onLoad={e => {
                    setPreviewInfo({ w: e.target.naturalWidth, h: e.target.naturalHeight });
                  }}
                />
              )}
              <Grid container style={{ marginTop: '5px' }}>
                <Grid>
                  <p className="col-md-9">Recommended Size: 1920 X 1080</p>
                  {previewImg && (
                    <p className="col-md-9">
                      Actual Image Size: {previewInfo.w} X {previewInfo.h}
                    </p>
                  )}
                </Grid>
              </Grid>
              {isEdit && <TabEditImg img={previewImg} setImg={setPreviewImg} type="preview" />}
            </td>
          </TableRow>
          <TableRow>
            <TableCell>Button Image</TableCell>
            <td colSpan="3">
              {buttonImg && (
                <img
                  alt="invalid"
                  src={buttonImg}
                  title={buttonImg}
                  style={{ maxWidth: '100%', backgroundColor: 'black' }}
                  onLoad={e => {
                    setButtonInfo({ w: e.target.naturalWidth, h: e.target.naturalHeight });
                  }}
                />
              )}
              <Grid container style={{ marginTop: '5px' }}>
                <Grid>
                  <p className="col-md-9">Recommended Size: 1920 X 1080</p>
                  {buttonImg && (
                    <p className="col-md-9">
                      Actual Image Size: {buttonInfo.w} X {buttonInfo.h}
                    </p>
                  )}
                </Grid>
              </Grid>
              {isEdit && <TabEditImg img={buttonImg} setImg={setButtonImg} type="button" />}
            </td>
          </TableRow>
          <TableRow>
            <TableCell>Title</TableCell>
            <td colSpan="3">
              {isEdit ? (
                <TextField
                  margin="dense"
                  variant="outlined"
                  value={(content || { title: '' }).title}
                  onChange={e => {
                    setContent({ ...content, title: e.target.value });
                  }}
                />
              ) : (
                (content || { title: '' }).title
              )}
            </td>
          </TableRow>
          <TableRow>
            <TableCell>Description</TableCell>
            <td colSpan="3">
              {isEdit ? (
                <TextField
                  margin="dense"
                  variant="outlined"
                  value={(content || { description: '' }).description}
                  onChange={e => {
                    setContent({ ...content, description: e.target.value });
                  }}
                />
              ) : (
                (content || { description: '' }).description
              )}
            </td>
          </TableRow>
          {/*
          <TableRow>
            <TableCell>Font Color</TableCell>
            <td colSpan="1">
              <div>
                <h4>Tab </h4>
                <CustomColorPicker id={'tab-color-picker'} edit={isEdit} color={getColorPickerData('tabColor')} handleColor={color => setContent({ ...content, tabColor: color.rgb })} />
              </div>
            </td>
            <td colSpan="1">
              <div>
                <h4>Category</h4>
                <CustomColorPicker id={'category-color-picker'} edit={isEdit} color={getColorPickerData('categoryColor')} handleColor={color => setContent({ ...content, categoryColor: color.rgb })} />
              </div>
            </td>
            <td colSpan="1">
              <div>
                <h4>Title</h4>
                <CustomColorPicker id={'title-color-picker'} edit={isEdit} color={getColorPickerData('titleColor')} handleColor={color => setContent({ ...content, titleColor: color.rgb })} />
              </div>
            </td>
            <td colSpan="1">
              <div>
                <h4>Plot</h4>
                <CustomColorPicker id={'plot-color-picker'} edit={isEdit} color={getColorPickerData('plotColor')} handleColor={color => setContent({ ...content, plotColor: color.rgb })} />
              </div>
            </td>
          </TableRow>
          */}
          <TableRow>
            <td colSpan="4">
              <center>
                <Button
                  variant="contained"
                  onClick={() => {
                    setPreviewStatus(true);
                  }}>
                  Preview
                </Button>
              </center>
            </td>
          </TableRow>
        </td>
      </TableRow>
      <Dialog open={previewStatus} className={'pop_wrap'}>
        <div className="tab_content">
          <DialogTitle id="form-dialog-title" className={'pop_head'}>
            {'Preview of HomeAPP'}
            <IconButton
              aria-label="close"
              onClick={() => {
                setPreviewStatus(false);
              }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={'pop_body'}>
            <TabDetailSkinPreview
              bgImg={bgImg}
              tabName={content && content.tabName}
              previewImg={previewImg}
              buttonImg={buttonImg}
              tabColorPicker={getColorPickerData('tabColor')}
              categoryColorPicker={getColorPickerData('categoryColor')}
              titleColorPicker={getColorPickerData('titleColor')}
              plotColorPicker={getColorPickerData('plotColor')}
              title={content.title}
              description={content.description}
            />
          </DialogContent>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
TabDetailSkin.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
  setContent: PropTypes.func.isRequired,
};
export default connect()(TabDetailSkin);

function TabEditImg({ img, setImg, type }) {
  const [imgName, setImgName] = React.useState(img);
  return (
    <div className="logo_preview_add">
      {/* <p className="logo_preview_comment">※ Recommended: ?PX height and ?PX width</p> */}
      <input
        accept="image/*"
        id={`input_${type}`}
        type="file"
        className="logo_preview_input"
        onChange={e => {
          if (e.target.files && e.target.files.length >= 1) {
            const reader = new FileReader();
            const img = e.target.files[0];
            reader.readAsDataURL(img);
            reader.onload = e2 => {
              setImg(e2.target.result);
              setImgName(img.name);
            };
          }
        }}
      />
      <Grid container spacing={3} style={{ marginTop: '5px' }}>
        <Grid item sm={9}>
          <p className="col-md-9">{imgName}</p>
        </Grid>
        <Grid item sm={3}>
          <label htmlFor={`input_${type}`}>
            <Button component="span" className="btn_color4" style={{ width: 110 }}>
              Upload File
            </Button>
          </label>
          <label>
            {img && (type === 'tab' || type === 'opacity') && (
              <Button
                component="span"
                className="btn_color4"
                style={{ width: 110, marginLeft: 10 }}
                onClick={() => {
                  setImg('');
                  setImgName('');
                  document.getElementById(`input_${type}`).value = '';
                }}>
                Delete File
              </Button>
            )}
          </label>
        </Grid>
      </Grid>
    </div>
  );
}
