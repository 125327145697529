import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import TutorialController from './TutorialController';
import {TutorialItems} from './TutorialData';
import { setDrawerOpenAction } from '../../../actions/uiActions';

const TutorialDiv = styled.div`
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const TutorialPage = (props) => {
  const {data, isActive, onClose, setDrawerOpenAction} = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (isActive) {
      setDrawerOpenAction(false);
    }
  }, [isActive]);

  const components = TutorialItems[data];
  const CurrentComponent = components[currentIndex];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex(
        (prevIndex) => Math.min(prevIndex + 1, components.length - 1));
  };

  const handleClose = () => {
    window.scrollTo(0, 0);
    setCurrentIndex(0);
    onClose();
  };

  return (
      <>
        <TutorialDiv
            className={`tutorial-container ${isActive ? 'active' : ''}`}>
          <CurrentComponent/>
        </TutorialDiv>

        <TutorialController
            isActive={isActive}
            count={currentIndex + 1}
            total={components.length}
            onPrev={handlePrev}
            onNext={handleNext}
            onClose={handleClose}
        />
      </>
  );
};

TutorialPage.propTypes = {
  setDrawerOpenAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  setDrawerOpenAction(value) {
    dispatch(setDrawerOpenAction(value));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorialPage);