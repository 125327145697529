//import React from 'react';
//import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const LightTooltip = withStyles(() => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    // color: 'rgba(0, 0, 0, 0.87)',
    // boxShadow: theme.shadows[1],
    fontSize: 20,
  },
}))(Tooltip);
