import { combineReducers } from 'redux';
//
import cm0001 from './cm0001';
import cm0002 from './cm0002';
import cm0003 from './cm0003';
import cm0005 from './cm0005';
import cm0006 from './cm0006';
import cm0007 from './cm0007';
import cmSdepth0001 from './cmSdepth0001';
import cmSdepth0002 from './cmSdepth0002';
import cmSdepth0003 from './cmSdepth0003';
import cmSdepth0004 from './cmSdepth0004';
import ch0301 from './ch0301';
import ch0302 from './ch0302';
//
export default combineReducers({
  cm0001,
  cm0002,
  cm0003,
  cm0005,
  cm0006,
  cm0007,
  cmSdepth0001,
  cmSdepth0002,
  cmSdepth0003,
  cmSdepth0004,
  ch0301,
  ch0302,
});
