import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function channels(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CHANNELS:
      if (action.payload.list) {
        //console.log('[success]channel.list=', action.payload.list);
        return [...state, ...action.payload.list];
      } else {
        //console.log('[fail]channel.list=', action.payload.list);
        return state;
      }
    case actions.SET_EDIT_CHANNEL:
      if (action.payload) {
        //console.log('[success]channel edit=', action.payload);
        return [...state, ...action.payload.list];
      } else {
        //console.log('[fail]channel edit=', action.payload);
        return state;
      }
    case actions.SET_REMOVE_CHANNEL:
      if (action.payload) {
        //console.log('[success]channel remove=', action.payload);
        let newChannels = state.filter(n => n.channelId !== action.payload.channelId);
        //console.log('[success]newChannels=', newChannels);
        return newChannels;
      } else {
        //console.log('[fail]channel remove=', action.payload);
        return state;
      }
    default:
      return state;
  }
}
