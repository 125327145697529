//import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function countKeyword(state = initialState, action) {
  switch (action.type) {
    case 'SET_COUNT_KEYWORD':
      //console.log('[admins][payload]', action.payload);
      return action.payload;
    default:
      return state;
  }
}
