import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import NoData from '../../common/NoData';

function SimpleBarContainer({ graph, menu }) {
  let chart = menu === 'program' ? { title: 'Top 10 Programs by Total Duration', type: 'totalDuration' } : { title: 'Top 10 TV Show by Duration (hr)', type: 'tvshow' };
  let chart2 = menu === 'program' ? { title: 'Top 10 Programs by Unique Viewer', type: 'uniqueViewer' } : { title: 'Top 10 Movies by Duration (hr)', type: 'movie' };

  let _chart1 = [];
  let _chart2 = [];

  graph && graph.length > 0 && graph.forEach(n => {
    let _data = {};
    if (n.type === 'totalDuration' || n.type === 'tvshow') {
      _data.name = n.name;
      _data.value = parseFloat(n.type === 'tvshow' ? (n.value / 3600).toFixed(2) : n.value);
      _chart1.push(_data);
    } else if (n.type === 'uniqueViewer' || n.type === 'movie') {
      _data.name = n.name;
      _data.value = parseFloat(n.type === 'movie' ? (n.value / 3600).toFixed(2) : n.value);
      _chart2.push(_data);
    }
  });

  return graph && graph.length > 0 ? (
    <div className="chart_card">
      <div className="chart_row line_chart">
        <div className="chart_unit">
          <span className="tit">{chart.title}</span>
          <br />
          <br />
          <center>
            <SimpleBarChart bar={_chart1} type={chart.type} />
          </center>
        </div>
      </div>
      <div className="chart_row line_chart">
        <div className="chart_unit">
          <span className="tit">{chart2.title}</span>
          <br />
          <br />
          <center>
            <SimpleBarChart bar={_chart2} type={chart2.type} />
          </center>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}
SimpleBarContainer.propTypes = {
  graph: PropTypes.object,
  menu: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  graph: state.statistic.graph,
});
export default connect(mapStateToProps)(SimpleBarContainer);

function SimpleBarChart({ bar, type }) {
  //static jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';
  let color;
  if (type === 'totalDuration' || type === 'tvshow') color = '#8884d8';
  else color = '#82ca9d';

  return bar && bar.length > 0 ? (
    <div>
      <BarChart
        width={1100}
        height={380}
        data={bar}
        margin={{
          top: 5,
          //right: 300,
          //left: 10,
          bottom: 5,
        }}
        layout="vertical">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" width={270} /*orientation="right"*/ />
        <Tooltip formatter={n => new Intl.NumberFormat('en').format(n)} />
        <Bar dataKey="value" fill={color} label={{ position: 'right', formatter: n => new Intl.NumberFormat('en').format(n) /*,fill:'red', width:'300' */ }} /*allowDataOverflow={true}*/ />
      </BarChart>
    </div>
  ) : (
    <NoData message="No Data Found" />
  );
}
SimpleBarChart.propTypes = {
  bar: PropTypes.array,
  type: PropTypes.string.isRequired,
};
