import React from 'react';
import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { BizAreaFilter } from '../../common/filter';
import ScheduleGrid from './scheduleGrid/scheduleGrid/scheduleGrid';
import ScheduleList from './scheduleList/ScheduleList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ScheduleTab() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [biz, setBiz] = React.useState('ALL');
  const handleChangeBiz = e => {
    setBiz(e.target.value);
  };

  return (
    <div>
      <BizAreaFilter biz={biz} handleChangeBiz={handleChangeBiz} />
      <div id={'scheduleTab'}>
        <AppBar position="static" id={'scheduleTabHead'}>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Grid" {...a11yProps(0)} />
            <Tab label="List" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <ScheduleGrid biz={biz} />
          {/* {ScheduleGrid} prop이 없을 때만 가능*/}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ScheduleList biz={biz} />
        </TabPanel>
      </div>
    </div>
  );
}
