import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePickerDefault from '../../common/DatePickerDefault';
import IssTab from '../../common/IssTab';
import IssTabs from '../../common/IssTabs';
import IssueHistoryServiceCommon from './IssueHistoryServiceCommon';
import IconButton from '../../common/IconButton';
import DropDown from '../../common/DropDown';
import DropDownForSearch from '../../common/DropDownForSearch';
import RadioDropDown from '../../common/RadioDropDown';
import DefaultButton from '../../common/DefaultButton';
import Alert from '../../modal/Alert';
import IssueHistoryServiceTotal from './IssueHistoryServiceTotal';
import { ISS_BASE_URL } from '../../../../constants';
import { getHeaders } from '../../../../util/actionUtil';
import RadioChartFilter from '../../common/RadioChartFilter';

const IssueHistoryService = ({ username, filterCallback }) => {
  const [pageCurrentTab, pageClickTab] = useState(0);
  const [regions, setRegions] = useState({'dropdownList': [], 'checkedList' : []});
  const [countries, setCountries] = useState({'dropdownList': [], 'checkedList' : []});
  const [providers, setProviders] = useState({'dropdownList': [], 'checkedList' : []});
  const [platforms, setPlatforms] = useState({'dropdownList': [] });
  const [paramRegions, setParamRegions] = useState([]);
  const [paramCountries, setParamCountries] = useState([]);
  const [paramProviders, setParamProviders] = useState([]);
  const [paramPlatforms, setParamPlatforms] = useState([]);
  const [currentFilter, setCurrentFilter] = useState({ subType:'', date: '', country: '', provider: '', platform: '', source: '', state: '', assign: '', contentsType: '', searchTextType: '', searchText: '' });
  const [searchFilter, setSearchFilter] = useState({});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [calendarValue, setCalendarValue] = useState([]);
  const [isDateShow, setDateShow] = useState(true);
  const [isDownloadFlag, setDownloadFlag] = useState(false);
  const [defaultDate, setDefaultDate] = useState([]);
  const [chartType, setChartType] = useState('Line');
  const [searchSourceType] = useState({ dropdownList: ['CMP', 'beacon', 'telemetry', 'AutoTest'] });
  const [searchStatusType] = useState({ dropdownList: ['Unaddressed', 'Processing', 'Resolve'] });
  const [searchAssignType, setSearchAssignType] = useState({ dropdownList: [] });
  const [searchContentsType] = useState({ dropdownList: ['tab', 'channel', 'vod', 'season'] });
  const [isIssueView, setIsIssueView] = useState(false);
  const searchTypeList = [
    { type: 'contentName', value: 'Content Name' },
    { type: 'issueId', value: 'Issue Id' },
    { type: 'contentID', value: 'Content ID' },
    { type: 'errorCode', value: 'Error Code' }
  ];
  const [searchType] = useState({ dropdownList: searchTypeList.map(m => m.value ) });
  const [paramSource, setParamSource] = useState(['CMP', 'beacon', 'telemetry', 'AutoTest']);
  const [paramState, setParamState] = useState(['Unaddressed', 'Processing', 'Resolve']);
  const [paramAssign, setParamAssign] = useState([]);
  const [paramContentsType, setParamContentsType] = useState(['tab', 'channel', 'vod', 'season']);
  const [paramSearchType, setParamSearchType] = useState('Content Name');
  const [paramSearchText, setParamSearchText] = useState('');

  const subTypeName = ['All', 'app', 'play', 'schedule'];

  const tabMenus = [
    {
      name: 'All',
      tooltip: '',
    },
    {
      name: 'LG Channels HomeApp',
      tooltip: '',
    },
    {
      name: 'Playback',
      tooltip: '',
    },
    {
      name: 'Schedule',
      tooltip: '',
    },
  ];

  const excelTooltip = 'If the count of Platform is only one, the Excel file include beacon information.';

  useEffect(() => {
    defaultRangeDate();
    getSearchFilter();
  }, []);

  useEffect(() => {
    if ( searchFilter.countryByRegion ) {
      let dropdownCountries = [];
      // List to display in dropdown box
      if (paramRegions && paramRegions.length > 0){
        dropdownCountries = searchFilter.countryByRegion.filter(f => paramRegions.includes(f.region)).map(region =>
          region.countryList.map(map => ({ id: map.countryCode, value : map.fullName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownCountries.sort((a, b) => a.value.localeCompare(b.value));
      }
      setCountries({'dropdownList': dropdownCountries, 'checkedList' : [] });
    }
  }, [ paramRegions ]);

  useEffect(() => {
    if ( searchFilter.countryByRegion &&  searchFilter.providerByCountry) {
      let dropdownProviders = [];
      // List to display in dropdown box
      if (paramCountries && paramCountries.length > 0){
        let ProviderList = searchFilter.providerByCountry.filter(f => paramCountries.includes(f.countryCode)).map(map =>
          map.providerList.map(pr => ({ id: pr.providerId, value : pr.providerName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownProviders = [...new Set(ProviderList.map((list) => JSON.stringify(list)))].map((list) => JSON.parse(list));
      }
      setProviders({ 'dropdownList': dropdownProviders, 'checkedList' : [] });
    }
  }, [ paramCountries ]);

  useEffect(() => {
    if (currentFilter.date.length > 0) {
      filterCallback(currentFilter);
    }
  }, [currentFilter]);

  useEffect(() => {
    setDateShow(true);
    currentFilter.subType = subTypeName[pageCurrentTab]
    currentFilter.date = calendarValue
    setCurrentFilter({ ...currentFilter})
  }, [pageCurrentTab]);

  useEffect(() => {
    setDownloadFlag(false);
  }, [isDownloadFlag]);

  const getSearchFilter = () => {
    setIsIssueView(false);
    fetch(`${ISS_BASE_URL}/api/common/search-filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSearchFilter({
          countryByRegion : body.countryByRegion
          , providerByCountry : body.providerByCountry
          , platform: body.platform
        })
        let dropdownRegion = [];
        // countryByRegion
        if ( body.countryByRegion ) {
          dropdownRegion = body.countryByRegion.map(map =>({ id: map.region, value : map.region }));
          dropdownRegion.sort((a, b) => a.value.localeCompare(b.value));
        }
        setRegions({ 'dropdownList': dropdownRegion, 'checkedList' : [] });

        let platforms = [];
        let platformCodes = [];
        if ( body.platform ) {
          platforms = body.platform.map(map => map.productPlatform);
          body.platform.map(map => {
            platformCodes.push(map.productPlatform);
          })
          setPlatforms({ 'dropdownList': platforms });
        }

        let assignee = [];
        if ( body.management ) {
          assignee = body.management.assignee.map(map => map.groupName);
          setSearchAssignType({ 'dropdownList': assignee });
        }
        setIsIssueView(true);
      } else {
        setAlertTitle('Error');
        setAlertMessage('[getSearchFilter : error]');
        setIsAlert(true);
      }
    })
    .catch(error => {
      console.log("getSearchFilter - error: " , error);
      setAlertTitle('Error');
      setAlertMessage('[getSearchFilter : error]');
      setIsAlert(true);
    }).finally(() => {});
  };

  const defaultRangeDays = 30; // 달력에 날짜를 보여 주기 위한 default 일자, 30일
  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());

    const thirtyDaysAgo = new Date(utcToday);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);

    const utcTodayString = utcToday.toISOString().substring(0, 10);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0, 10);

    setDefaultDate([thirtyDaysAgoString, utcTodayString]); // [startDate, endDate]
  }

  const tabChangeHandler = (selectedIdx, _data) => {
    if (pageCurrentTab !== selectedIdx) {
      setDateShow(false);
      pageClickTab(selectedIdx);
    }
  }

  const issueSearchByButton = () => {
    if (pageCurrentTab === 0) {
      setCurrentFilter({ subType: subTypeName[pageCurrentTab], date: calendarValue, country: '', provider: '', platform: '', source: '', state: '', assign: '', contentsType: '', searchTextType: '', searchText: '' });
    } else {
      if (paramRegions.length === 0 || paramCountries.length === 0 || paramProviders.length === 0 || paramPlatforms.length === 0) {
        const showMessage = paramRegions.length === 0 ? 'region' : paramCountries.length === 0 ? 'country' : paramProviders.length === 0 ? 'cp' : 'platform';
        setAlertTitle('Warning');
        setAlertMessage(`Please select any ${showMessage}`);
        onOpenAlert();
      } else {
        const selectedType = searchTypeList.filter(f => f.value === paramSearchType).map(m => m.type);
        const platformCodes = searchFilter.platform.filter(item => paramPlatforms.includes(item.productPlatform)).map(item => item.productPlatform);
        setCurrentFilter({ subType: subTypeName[pageCurrentTab], date: calendarValue, country: paramCountries, provider: paramProviders, platform: platformCodes, source: paramSource, state: paramState, assign: paramAssign, contentsType: paramContentsType, searchTextType: selectedType, searchText: (paramSearchText ? paramSearchText.trim() : '') });
      }
    }
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  async function getExcelDownload(filter, username) {
    let fileName = '';
    const userInfo = username === 'admin' ? 'cmpuser' : username;
    await fetch(`${ISS_BASE_URL}/api/service/excel/download?dates=${filter.date}&countries=${filter.country}&platforms=${filter.platform}&cps=${filter.provider}&type=${filter.subType}&issueRoot=${filter.source}&issueState=${filter.state}&assignGroup=${filter.assign}&contentsType=${filter.contentsType}&searchTextType=${filter.searchTextType}&searchText=${filter.searchText}&userInfo=${userInfo}`, {
      method: 'GET',
      responseType: 'blob',
      headers: getHeaders()
    })
      .then(response => {
        fileName = response.headers.get('Content-Disposition').split('filename=')[1].slice(1, -1);
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(
          new Blob([blob])
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(err => {
        alert('error')
      });
  };

  const excelDownload = async () => {
    let changeFilter = currentFilter;
    if (changeFilter.date === '' || changeFilter.date.length === 0) {
      changeFilter = { subType: subTypeName[pageCurrentTab], date: calendarValue, country: '', provider: '', platform: '', source: '', state: '', assign: '', contentsType: '', searchTextType: '', searchText: '' };
    }
    await getExcelDownload(changeFilter, username);
  };

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      issueSearchByButton();
    }
  };

  const handleTextInputChange = event => {
    setParamSearchText(event.target.value);
  };

  const chartChangeHandler = value => {
    setChartType(value);
  };

  return (
    <>
      <div className="ui-components">
        <div className="box-left">
          {pageCurrentTab === 0 ? (
            <>
              <div className="ui-datepicker-box">
              {
                isDateShow === true ? (
                  <DatePickerDefault dateChangeHandler={(newDate) => setCalendarValue(newDate)} />
                ) : (
                  <></>
              )}
              </div>
              <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={issueSearchByButton} />
              <div style ={{ padding:'0px 0px 0px 15px', border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px' }}>
                <RadioChartFilter chartType={chartType} onChangeRadio={chartChangeHandler} />
              </div>
            </>
          ) : (
            <div className="ui-dropdown-box">
              <div style ={{ padding:'5px 20px 5px 20px', border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px' }}>
                <div style={{ height : '32px', display: 'flex', gap:'8px' }}>
                  {
                    isDateShow === true ? (
                      <DatePickerDefault dateChangeHandler={(newDate) => setCalendarValue(newDate)} />
                    ) : (
                      <></>
                  )}
                  <DropDownForSearch dropDownItem={regions} title="Region" onChangeDropDown={setParamRegions} />
                  <DropDownForSearch dropDownItem={countries} title="Countries" onChangeDropDown={setParamCountries} />
                  <DropDownForSearch dropDownItem={providers} title="CPs" onChangeDropDown={setParamProviders} />
                  <DropDown item={platforms} title="Platforms" onChangeDropDown={setParamPlatforms} />
                </div>
                <div style={{ height : '37px', display: 'flex', gap:'8px', padding:'5px 0 0 0' }}>
                  <DropDown item={searchSourceType} title="Source" onChangeDropDown={setParamSource} />
                  <DropDown item={searchStatusType} title="Status" onChangeDropDown={setParamState} />
                  <DropDown item={searchAssignType} title="Assign" onChangeDropDown={setParamAssign} />
                  {( pageCurrentTab !== 3) && (
                    <DropDown item={searchContentsType} title="Contents Type" onChangeDropDown={setParamContentsType} checkedList={paramContentsType} />
                  )}
                  <RadioDropDown item={searchType} onChangeDropDown={setParamSearchType} />
                  <div className="content-name">
                    <input type="text" value={paramSearchText} placeholder={paramSearchType}
                      onChange={event => handleTextInputChange(event)}
                      onKeyPress={handleOnKeyPress}
                      style={{ width: '200px', height: '32px', padding: '0 8px', border: '1px solid rgba(255, 255, 255, 0.2)', backgroundColor: 'transparent', color: '#fff', fontSize: '14px' }}
                    />
                  </div>
                  <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={issueSearchByButton}/>
                  <div style ={{ padding:'0px 0px 0px 15px', border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px' }}>
                    <RadioChartFilter chartType={chartType} onChangeRadio={chartChangeHandler} />
                  </div>
                </div>
              </div>
          </div>
          )}
        </div>
      </div>
      <div className="tab-container">
        <div className="box-btns">
          {pageCurrentTab === 2 ?
            <IconButton type={'button'} text={'Excel Download'} btnClass={'btn-icon-download'} clickEvent={excelDownload} tooltip={excelTooltip}/>
            : <IconButton type={'button'} text={'Excel Download'} btnClass={'btn-icon-download'} clickEvent={excelDownload}/>
          }
        </div>
        <IssTabs tabMenus={tabMenus} currentTab={pageCurrentTab} className={'ui-tab-type03'} onChange={tabChangeHandler}>
          <IssTab isActive={true}>
            {isDownloadFlag === false ? pageCurrentTab === 0 ?
              <IssueHistoryServiceTotal currentFilter={currentFilter} chartType={chartType} />
              : <IssueHistoryServiceCommon pageCurrentTab={pageCurrentTab} currentFilter={currentFilter} chartType={chartType} />
            : <></>}
          </IssTab>
        </IssTabs>
      </div>
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  )
};

IssueHistoryService.propTypes = {
  username: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  username: state.currentUser.username,
});

export default connect(mapStateToProps) (IssueHistoryService);