import React from 'react';
//import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export default function FreeOnLgChannelDisplay({ masBatchTime, masBatchFlag, utcHour }) {
  var now = new Date();
  var year = now.getUTCFullYear();
  var month = now.getUTCMonth() + 1 > 9 ? now.getUTCMonth() + 1 : '0' + (now.getUTCMonth() + 1);
  var day = now.getUTCDate() > 9 ? now.getUTCDate() : '0' + now.getUTCDate();
  var hours = now.getUTCHours() > 9 ? now.getUTCHours() : '0' + now.getUTCHours();
  var minutes = now.getUTCMinutes() > 9 ? now.getUTCMinutes() : '0' + now.getUTCMinutes();
  var seconds = now.getUTCSeconds() > 9 ? now.getUTCSeconds() : '0' + now.getUTCSeconds();

  function availableCMPBatch() {
    var cmpNow = new Date();
    year = cmpNow.getUTCFullYear();
    month = cmpNow.getUTCMonth() + 1 > 9 ? cmpNow.getUTCMonth() + 1 : '0' + (cmpNow.getUTCMonth() + 1);
    day = cmpNow.getUTCDate() > 9 ? cmpNow.getUTCDate() : '0' + cmpNow.getUTCDate();
    hours = utcHour > 9 ? utcHour : '0' + utcHour;
    if (cmpNow.getUTCHours() > utcHour) {
      cmpNow.setUTCDate(cmpNow.getUTCDate() + 1);
      cmpNow.setUTCHours(utcHour)
      cmpNow.setUTCMinutes(0)
      cmpNow.setUTCSeconds(0)
      year = cmpNow.getUTCFullYear();
      month = cmpNow.getUTCMonth() + 1 > 9 ? cmpNow.getUTCMonth() + 1 : '0' + (cmpNow.getUTCMonth() + 1);
      day = cmpNow.getUTCDate() > 9 ? cmpNow.getUTCDate() : '0' + cmpNow.getUTCDate();
      hours = '0' + cmpNow.getUTCHours();
    }
    minutes = '00';
    seconds = '00';
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  function availableMassBatch() {
    var maasNow = new Date();
    year = maasNow.getUTCFullYear();
    month = maasNow.getUTCMonth() + 1 > 9 ? maasNow.getUTCMonth() + 1 : '0' + (maasNow.getUTCMonth() + 1);
    day = maasNow.getUTCDate() > 9 ? maasNow.getUTCDate() : '0' + maasNow.getUTCDate();
    hours = masBatchTime > 9 ? masBatchTime : '0' + masBatchTime;
    if (maasNow.getUTCHours() > masBatchTime) {
      maasNow.setUTCDate(maasNow.getUTCDate() + 1);
      maasNow.setUTCHours(utcHour)
      maasNow.setUTCMinutes(0)
      maasNow.setUTCSeconds(0)
      year = maasNow.getUTCFullYear();
      month = maasNow.getUTCMonth() + 1 > 9 ? maasNow.getUTCMonth() + 1 : '0' + (maasNow.getUTCMonth() + 1);
      day = maasNow.getUTCDate() > 9 ? maasNow.getUTCDate() : '0' + maasNow.getUTCDate();
      hours = masBatchTime > 9 ? masBatchTime : '0' + masBatchTime;
    }
    minutes = '00';
    seconds = '00';
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <Typography style={{ fontSize: '1.5rem' }}>
      {' '}
      Current Time:{' '}
      <Typography component="span" variant="h5">
        {`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`}
      </Typography>{' '}
      &nbsp;
      <br /> { }
      Contents Preparing Batch Time:{' '}
      <Typography component="span" variant="h5">
        {utcHour && availableCMPBatch()}
      </Typography>{' '}
      &nbsp;
      <br />
      {masBatchFlag && (
        <>
          Contents Displaying Batch Time:{' '}
          <Typography component="span" variant="h5" >
            {masBatchTime && availableMassBatch()}
          </Typography>{' '}
        </>
      )}
    </Typography>
  );
}

FreeOnLgChannelDisplay.propTypes = {};
