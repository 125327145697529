import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, FormControl, InputLabel, Select, MenuItem, TextField, TablePagination } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { getWatchLiveChannelList } from '../../../actions/watchLiveMappingAction';
import { getRegionProvidersAdminAction } from '../../../actions/providerActions';
import { getHeaders } from '../../../util/actionUtil';
import { API_URL } from '../../../constants';
import WatchLiveTable from './WatchLiveTable';

function WatchLiveSearch({ tableRef, selectSearch, cntry, region, getWatchLiveChannelList, sourceChannel, rsDataLoaded, rsChannelIds, configData, selectStatus , handlePageChange , isLastMonth}) {
  const [providerId, setProviderId] = useState('All');
  const [chId, setChId] = useState('');
  const [chName, setChName] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    sourceChannel.channellist = [];
  }, [cntry, selectStatus]);

  const clickSearch = () => {
    getWatchLiveChannelList(cntry, providerId, chId, chName, selectStatus);
    handlePageChange();
  };
  return (
    <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list'} id={'searchfolgc'} style={{ marginTop: '40px' }}>
      <CpFilter2 cntry={cntry} providerId={providerId} setProviderId={setProviderId} chId={chId} setChId={setChId} chName={chName} setChName={setChName} region={region} selectStatus={selectStatus} />
      <div className="btn_set_wrap search_wrap manual" style={{ marginLeft: '95%' }}>
        <Button className="btn_color2" onClick={clickSearch} disabled={isLastMonth}>
          search
        </Button>
      </div>

      <WatchLiveTable
        data={data}
        sourceChannel={sourceChannel}
        rsDataLoaded={rsDataLoaded}
        rsChannelIds={rsChannelIds}
        tableRef={tableRef}
        select={selectSearch}
        options={{
          selection: true,
          showTextRowsSelected: false,
          pageSize: 15,
          pageSizeOptions: [5, 15, 30, 50, 100],
          doubleHorizontalScroll: true,
          maxBodyHeight: 500,
          minBodyHeight: 500,
          rowStyle: rowData => ({
            backgroundColor: rsDataLoaded && !rsChannelIds.includes(rowData.channelId) ? '#7FB3D5' : (configData || []).find(x => x.channelId === rowData.channelId && x.cpId === rowData.cpId) ? '#e0e0e0' : '#FFF',
            color: rowData.tableData.checked || rowData.isModify ? '#d8295c' : '#484848',
            pointerEvents: (!rowData.tableData.checked && (configData || []).find(x => x.channelId === rowData.channelId && x.cpId === rowData.cpId)) || (rsDataLoaded && !rsChannelIds.includes(rowData.channelId)) ? 'none' : 'initial',
          }),
          selectionProps: rowData => {
            const findCurrentRow = (configData || []).find(x => x.channelId === rowData.channelId && x.cpId === rowData.cpId);
            rowData.tableData.disabled = (rsDataLoaded && !rsChannelIds.includes(rowData.channelId)) || findCurrentRow ? true : false;
            if (rowData.tableData.disabled) {
              return {
                disabled: true,
                checked: false,
              };
            }
          },
        }}
      />
    </div>
  );
}

WatchLiveSearch.propTypes = {
  sourceChannel: PropTypes.object,
  getWatchLiveChannelList: PropTypes.func.isRequired,
};

function CpFilter({ cntry, providerId, setProviderId, chId, setChId, chName, setChName, getRegionProvidersAdminAction, region, selectStatus, setSelectStatus }) {
  const [cpDetail, setCPDetails] = useState('All');

  useEffect(() => {
    getRegionProvidersAdminAction(region);
  }, []);

  const selectStatusChange = value => {
    setSelectStatus(value);
  };

  useEffect(() => {
    if (cntry) {
      const apiUrl = `/sdpapi/rssfeed/${cntry}/feeds?cpName=`;
      fetch(`${API_URL}${apiUrl}`, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then(res => res.json())
        .then(data => {
          const result = (data || []).map(data => data.cpName);
          setCPDetails(result);
        });
    }
  }, [cntry]);

  const AllCountry = ['All'];
  const cpNameList = [...cpDetail];

  return (
    <div className="filter_area row manualTop">
      <div className="filter_row manualTop">
        <FormControl variant="outlined">
          <InputLabel>CP</InputLabel>
          <Select
            value={providerId}
            onChange={e => {
              setProviderId(e.target.value);
            }}>
            {[...AllCountry, ...cpNameList.sort()].map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="id"
          value={chId}
          onChange={e => {
            setChId(e.target.value);
          }}
        />
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="name"
          value={chName}
          onChange={e => {
            setChName(e.target.value);
          }}
        />
      </div>
    </div>
  );
}

CpFilter.propTypes = {
  sourceChannel: PropTypes.object,
  regionProvider: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  regionProvider: state.providers.regionProvider,
  sourceChannel: state.channelMapping.sourceChannel,
  rsChannelIds: state.channelMapping.rsChannelIds.list,
  rsDataLoaded: state.channelMapping.rsChannelIds.isDataLoad,
});

const mapDispatchToProps = dispatch => ({
  getRegionProvidersAdminAction(region) {
    dispatch(getRegionProvidersAdminAction(region));
  },
  getWatchLiveChannelList: (countryCode, providerId, chId, chName, selectStatus) => dispatch(getWatchLiveChannelList(countryCode, providerId, chId, chName, selectStatus)),
});

const CpFilter2 = connect(mapStateToProps, mapDispatchToProps)(CpFilter);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WatchLiveSearch));
