import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
//import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import ProgramDialog from '../../program/Program/ProgramDialog';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { ScheduleFilter } from '../../../common/filter';
import { getSchedulesAction } from '../../../actions/scheduleActions';

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      //width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

function ScheduleList({ classes, biz, fetching, getSchedulesAction, schedules, isRoleAdmin, cntryCpResponse }) {
  const bizArea = cntryCpResponse.find(item => item.biz === biz);
  const [cntry, setCntry] = React.useState('US');
  const [cp, setCp] = React.useState('All');
  const [channels, setChannels] = React.useState([]);
  const [channel, setChannel] = React.useState('');
  const [date, setDate] = React.useState(new Date());

  const handleChangeCntry = event => {
    const cps = bizArea ? bizArea.area.find(item => item.countryCode === event.target.value) : null;

    setCntry(event.target.value);
    if (cps && cps.providers.length === 1) {
      setCp(cps.providers[0]);
    } else if (cps && cps.providers.length > 1) {
      setCp('All');
    }
  };

  useEffect(() => {
    getSchedulesAction(cntry, cp, date.toISOString().slice(0, 11) + '00:00:00Z', isRoleAdmin);
  }, [cntry, cp, date]);

  useEffect(() => {
    setChannels(schedules.map(item => item.channelName));
  }, [schedules]);

  useEffect(() => {
    setChannel(channels[0]);
  }, [channels]);

  const [programDialogOpen, setProramDialogOpen] = useState(false);
  const [providerId, setProviderId] = useState();
  const [programId, setProgramId] = useState();
  const schedule = channel ? schedules.find(item => item.channelName === channel) : {};

  return (
    <div className={classes.layout}>
      <ScheduleFilter
        bizArea={bizArea}
        cntry={cntry}
        handleChangeCntry={handleChangeCntry}
        cp={isRoleAdmin ? cp : null}
        handleChangeCp={e => setCp(e.target.value)}
        channels={channels}
        channel={channel}
        handleChangeChannel={e => setChannel(e.target.value)}
        date={date}
        handleChangeDate={setDate}
        handleClickToday={() => setDate(new Date())}
      />
      {fetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        schedules.length > 0 &&
        channel && (
          <div>
            {schedule && schedule.channelName && (
              <div className="channel_tit">
                <span className="channel_logo bg_dark">
                  {/* 로고가 흰색일경우에만 bg_dark 클래스 추가 */}
                  <img src={schedule.channelLogo} title="로고" />
                </span>
                <span className="channel_name">{schedule.channelName}</span>
              </div>
            )}
            <MaterialTable
              title=""
              columns={[
                { title: 'Time', field: 'start' },
                {
                  title: 'Program ID',
                  render: rowData => (
                    <button
                      onClick={() => {
                        setProviderId(rowData.providerId);
                        setProgramId(rowData.programId);
                        setProramDialogOpen(rowData.providerId);
                      }}>
                      <span className="link">{rowData.programId}</span>
                    </button>
                  ),
                },
                { title: 'Title', field: 'title' },
              ]}
              data={
                schedule && schedule.schedules
                  ? schedule.schedules.map(each => {
                      let newData = {};
                      //console.log(schedule);
                      newData['providerId'] = schedule.providerId;
                      newData['programId'] = each.programId;
                      newData['title'] = each.title;
                      newData['start'] = each.start.slice(11, 16);
                      return newData;
                    })
                  : []
              }
              options={{
                pageSize: 10,
                rowStyle: { backgroundColor: '#fff' },
              }}
            />
            <ProgramDialog programId={programId} providerId={providerId} programDialogOpen={programDialogOpen} closeProgramDialog={() => setProramDialogOpen(false)} />
          </div>
        )
      )}
    </div>
  );
}

ScheduleList.propTypes = {
  classes: PropTypes.object.isRequired,
  biz: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  getSchedulesAction: PropTypes.func.isRequired,
  schedules: PropTypes.array.isRequired,
  isRoleAdmin: PropTypes.bool,
  cntryCpResponse: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  schedules: state.schedules.schedules,
  fetching: state.schedules.fetching,
  isRoleAdmin: state.currentUser.isRoleAdmin,
  cntryCpResponse: state.cntry.countries,
});

const mapDispatchToProps = dispatch => ({
  getSchedulesAction(cntry, cp, date, isRoleAdmin) {
    dispatch(getSchedulesAction(cntry, cp, date, isRoleAdmin));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScheduleList));
