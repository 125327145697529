import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import AppVersionForm from './AppVersionForm';
import { request } from '../../../util/request';
import { getAppVersionAction, requestAction } from '../../../actions/appVersionActions';

function AppVersionAdd({ getAppVersionAction }) {
  const [filter, setFilter] = useReducer((state, newState) => ({ ...state, ...newState }), {
    countryCode: {
      value: '',
      errorMsg: 'Please select a country.',
    },
    platformName: {
      value: '',
      errorMsg: 'Device OS(Android, IOS)',
    },
    reqUpdate: {
      value: '',
      errorMsg: 'Update Required Flag(Y-required, N-optional)',
    },
    versionName: {
      value: '',
      errorMsg: 'Version Name(1.0.0, 1.0.1, 1.0.2 ...)',
    },
    versionCode: {
      value: '',
      errorMsg: 'Version Step(1,2,3...)',
    },
  });

  const [open, setOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const handleInputChange = e => {
    const inputId = e.target.name;
    setFilter({
      [inputId]: {
        value: e.target.value,
        errorMsg: filter[inputId].errorMsg,
      },
    });
  };

  const handlePublish = async event => {
    event.preventDefault();
    alert('Do you want to deploy?');

    const isSuccess = await requestAction('/admin/app-version/deploy', 'POST', null);
    if(isSuccess.returnValue) {
      getAppVersionAction('All', 'All');
      alert(isSuccess.message);
    } else {
      alert(isSuccess.message);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const newItem = {
      countryCode: filter.countryCode.value,
      platformName: filter.platformName.value,
      reqUpdate: filter.reqUpdate.value === 'Y' ? 1 : 0,
      versionName: filter.versionName.value,
      versionCode: filter.versionCode.value,
    };
    alert('Are you sure to add new App Version?');
    console.log('countryCode : ' + newItem.countryCode);
    console.log('platformName : ' + newItem.platformName);
    console.log('reqUpdate : ' + newItem.reqUpdate);
    console.log('versionName : ' + newItem.versionName);
    console.log('versionCode : ' + newItem.versionCode);

    const isSuccess = await requestAction('/admin/app-version', 'POST', newItem);
    console.log('isSuccess : ' + isSuccess.returnValue);
    if (isSuccess.returnValue) {
      setSuccessSnackbarOpen(true);
      getAppVersionAction('All', 'All');
      setOpen(false);
      alert(isSuccess.message);
    } else {
      alert(isSuccess.message);
    }
  };

  return (
    <React.Fragment>
      <Button color="secondary" variant="contained" onClick={() => setOpen(true)} className={'btn_color2'}>
        Add
      </Button>
      <Button color="secondary" variant="contained" className={'btn_color2'} onClick={handlePublish}>
          Publish
        </Button>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={1000} //1000=1sec
        onClose={() => setSuccessSnackbarOpen(false)}
        message="Successfully Doned !"
        style={{ backgroundColor: 'black' }}
      />
      <Dialog open={open} className={'pop_wrap'}>
        <AppVersionForm title="Add App Version Code" isAppVersionVisible={true} filter={filter} handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={() => setOpen(false)}/>
      </Dialog>
    </React.Fragment>
  );
}

AppVersionAdd.propTypes = {
  getAppVersionAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getAppVersionAction(osName, cntry) {
    dispatch(getAppVersionAction(osName, cntry));
  },
});
export default connect(null, mapDispatchToProps)(AppVersionAdd);