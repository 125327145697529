import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import { saveSegmentConfiguration, setABTestParamAction, searchTbTests, endAbTest, getABTest, getABTestTabCategoryAction, getSegment } from '../../../actions/homeAppActions';
import LoadingIndicator from '../../../common/LoadingIndicator';
import ABTestManualResult from './ABTestManualResult';
import ABTestManualSearch from './ABTestManualSearch';
import { isNormalInteger } from '../../../util/util';
import { request } from '../../../util/request';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import AddSegmentFilter from './AddSegmentFilter';

function AddSegment({ saveSegmentConfiguration, getCountAction, history, abTestTabCategory, id, abTestParam, fetching, setABTestParamAction, regionList, regionPlatformList, getABTestTabCategoryAction, getSegment, segment }) {
  const today = new Date();
  const yestreday = new Date();
  const nextMonthDate = new Date();
  yestreday.setDate(today.getDate() - 1);
  nextMonthDate.setMonth(today.getMonth() + 1);
  const monthAgoDate = new Date();
  monthAgoDate.setMonth(monthAgoDate.getMonth() - 1);
  const [cat, setCat] = React.useState(abTestTabCategory.find(n => n.categoryId == id) || {});
  const [result, setResult] = React.useState([]);
  const [addData, setAddData] = React.useState([]);
  const [deleteData, setDeleteData] = React.useState([]);
  const [time, setTime] = useState();
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = React.useState(today.toISOString().slice(0, 10));
  const [endDate, setEndDate] = React.useState(monthAgoDate.toISOString().slice(0, 10));
  const [startTimeAnt, setStartTimeAnt] = useState(0);
  const [endTimeAnt, setEndTimeAnt] = React.useState(0);
  const [tabCode, setTabCode] = React.useState('');
  const [categoryCode, setCategoryCode] = React.useState('');
  const [mappingType, setMappingType] = React.useState('manual');
  const [segmentId, setSegmentId] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');
  const selectedTableRef = useRef();
  const [deviceType, setDeviceType] = React.useState('');
  const [platformVersion, setPlatformVersion] = React.useState('');
  const [endDateCheck, setEndDateCheck] = React.useState();
  const [validToUpdate, setValidToUpdate] = React.useState(true);
  const [eligeble, setEligeble] = React.useState(true);
  const [abtestId, setAbtestId] = React.useState('');
  const [segmentType, setSegmentType] = React.useState('');
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth() + 1 > 9 ? now.getUTCMonth() + 1 : '0' + (now.getUTCMonth() + 1);
  const day = now.getUTCDate() > 9 ? now.getUTCDate() : '0' + now.getUTCDate();
  const hours = now.getUTCHours() > 9 ? now.getUTCHours() : '0' + now.getUTCHours();
  const minutes = now.getUTCMinutes() > 9 ? now.getUTCMinutes() : '0' + now.getUTCMinutes();
  const seconds = now.getUTCSeconds() > 9 ? now.getUTCSeconds() : '0' + now.getUTCSeconds();
  const [defaultContent, setDefaultContent] = React.useState('N');
  const [defaultGroupContent, setDefaultGroupContent] = React.useState(false);
  const [sampleRatePer, setSampleRatePer] = useState();
  const [contentPolicy, setContentPolicy] = useState('');
  const [region, setRegion] = useState('');
  const [comparativeSegmentType, setComparativeSegmentType] = React.useState('');
  const [comparativeSegmentId, setComparativeSegmentId] = React.useState('');

  React.useEffect(() => {
    setTime(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
    fetchTbTestData();
  }, [id]);

  const fetchTbTestData = async () => {
    const tbTestData = await request(`/api/abtest/${id}`, 'GET', null, true);
    setTitle(tbTestData.title);
    setStartDate(tbTestData.startTime.split(' ')[0]);
    let stime = tbTestData.startTime.split(' ')[1].split(':')[0];
    stime = stime.startsWith(0) ? stime.substring(1, 2) : stime;
    setStartTimeAnt(stime);
    setEndDate(tbTestData.endTime.split(' ')[0]);
    let etime = tbTestData.endTime.split(' ')[1].split(':')[0];
    etime = etime.startsWith(0) ? etime.substring(1, 2) : etime;
    setEndTimeAnt(etime);
    setDeviceType(tbTestData.deviceType);
    setCountryCode(tbTestData.countryCode);
    setRegion(tbTestData.region);
    setPlatformVersion(tbTestData.platformVersion);
    setTabCode(tbTestData.tabCode);
    setCategoryCode(tbTestData.categoryCode);
    setSegmentId(tbTestData.segmentId);
    setEndDateCheck(tbTestData.endTime);
    setDefaultGroupContent(tbTestData.defaultContent === 'Y' ? true : false);
    setValidToUpdate(new Date(formatDate(new Date())) >= new Date(tbTestData.forcedEndTime));
    setAbtestId(tbTestData.abtestId);
    setSegmentType(tbTestData.segmentType);
    setSegmentId(tbTestData.segmentId);
    var startTime = new Date(tbTestData.startTime);
    startTime.setDate(startTime.getDate() - 1);
    let utcHour = await getUTCHour();
    startTime.setHours(utcHour);
    var todayDateUTC = new Date(formatDate(new Date()));
    setEligeble(todayDateUTC > startTime);
  };

  async function getUTCHour() {
    let utcHour = await fetch(API_BASE_URL + '/abtest/utchour', {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        return body;
      });
    return utcHour;
  }

  const clickSave = async e => {
    e.preventDefault();
    const uniqueValues = new Set(result.map(v => parseInt(v.result_order)));
    if (!segmentType) alert('Please select the segment type');
    else if (!segmentId) alert('Please select the sengment id');
    else if (!sampleRatePer) alert('Please enter a sample rate');
    else if (defaultContent === 'Y' && (!comparativeSegmentType || !comparativeSegmentId)) alert('Please select CGroup Segment');
    else if (uniqueValues.size < result.length) {
      const duplicateCounts = result.reduce((acc, item) => {
        const order = item.result_order;
        acc[order] = (acc[order] || 0) + 1;
        return acc;
      }, {});
      const duplicates = Object.keys(duplicateCounts)
        .filter(order => duplicateCounts[order] > 1)
        .map(order => parseInt(order));
      alert(`Some order is duplicated ! Please remove duplicate order (${duplicates.join(', ')})`);
    }
    else if (window.confirm('Are you sure to save the result?')) {
      let startTime = startTimeAnt > 9 ? startDate + ' ' + startTimeAnt + ':00:00' : startDate + ' 0' + startTimeAnt + ':00:00';
      var startTimeNow = new Date(startTime);
      startTimeNow.setDate(startTimeNow.getDate() - 1);
      let utcHour = await getUTCHour();
      startTimeNow.setHours(utcHour);
      var todayDateUTC = new Date(formatDate(new Date()));
      /* if (todayDateUTC > startTimeNow) {
         alert('AB Test Update is not possible!');
         return false;
       } */
      let endTime = endTimeAnt > 9 ? endDate + ' ' + endTimeAnt + ':00:00' : endDate + ' 0' + endTimeAnt + ':00:00';
      let idx = id;
      const newResult = result.map(n => {
        const { country_code, cp_idx, provider_idx, type, content_id, result_order, genre } = n;
        let categoryResult = { categoryIdx: id, countryCode: country_code };
        categoryResult.providerIdx = cp_idx || provider_idx;
        categoryResult.contentType = type;
        categoryResult.contentId = content_id;
        categoryResult.resultOrder = result_order;
        categoryResult.genreCode = genre;
        return categoryResult;
      });

      const sampleRate = parseInt(sampleRatePer, 10);
      const addSegmentData = {
        idx,
        title,
        startTime,
        endTime,
        tabCode,
        categoryCode,
        segmentId,
        countryCode,
        abtestId,
        segmentType,
        comparativeSegmentId,
        comparativeSegmentType,
        defaultContent,
        contentPolicy,
        mappingType,
        sampleRate,
        ...abTestParam,
        categoryResult: mappingType === 'auto' ? [] : newResult,
      };
      saveSegmentConfiguration(addSegmentData);
    }
  };

  const defaultSearchData = {
    deviceType: '',
    countryCodes: [],
    channelMapList: [],
    date: '',
  };
  const [searchData, setSearchData] = useState(defaultSearchData);

  const selectSearch = rows => {
    if (rows.find(n => result.find(n2 => n2.content_id === n.id && n2.cp_idx == n.cp_idx))) {
      alert('content is already exist !');
    } else {
      let startOrder = Math.max(...result.map(n => n.result_order)) + 1;
      startOrder = startOrder === -Infinity ? 1 : startOrder;

      setAddData(
        rows.map((n, i) => {
          const { cp_idx, cp, id, name, image, type, series_id, genre } = n;
          return { cp_idx: cp_idx, cp_id: cp, name: name, image: image, country_code: countryCode, type: type, content_id: id, result_order: startOrder + i, series_id: series_id, genre: genre };
        }),
      );
    }
  };

  const selectResult = rows => {
    setDeleteData(
      rows.map(n => {
        const { cp_idx, content_id, name, type, genre } = n;
        return { cp_idx: cp_idx, content_id: content_id, name: name, type: type, genre: genre };
      }),
    );
  };

  const addResult = () => {
    if (addData.find(n => result.find(n2 => n2.content_id === n.content_id && n2.cp_idx === n.cp_idx))) {
      alert('content is already exist !');
    } else if (checkTvShowSearies(addData) || checkDuplicateSeriesList(addData)) {
      alert('Only one season allowed per series!');
      selectedTableRef.current.onAllSelected(false);
    } else {
      selectedTableRef.current.onAllSelected(false);
      setResult(result.concat(addData));
      setAddData([]);
    }
  };

  const removeResult = () => {
    setResult(result.filter(n => !deleteData.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id)));
    setDeleteData([]);
  };

  React.useEffect(() => {
    abTestParam.deviceType && abTestParam.region && abTestParam.platformVersion && getABTestTabCategoryAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.platformVersion, tabCode, categoryCode);
  }, [abTestParam]);

  React.useEffect(() => {
    if (platformVersion.length > 0) {
      getSegment(countryCode, platformVersion);
    }
  }, [segmentId]);

  const renderOrder = n => (
    <TextField
      fullWidth
      margin="dense"
      value={n.result_order}
      onChange={e => {
        if (e.target.value === '0') alert('The order must be greater than 0 ! Please re-enter !');
        else if (!isNormalInteger(e.target.value) && e.target.value !== '') alert('The order is not natural number ! Please enter natural number !');
        else {
          const newResult = result.slice();
          newResult.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id).result_order = e.target.value;
          setResult(newResult);
        }
      }}
    />
  );

  const checkTvShowSearies = rows => {
    return rows.find(
      n =>
        result.filter(r => {
          if (r.category_idx) {
            return r.provider_idx === n.cp_idx && r.series_id === n.series_id && n.type === 'tvshow-series';
          } else {
            return r.cp_idx === n.cp_idx && r.series_id === n.series_id && n.type === 'tvshow-series';
          }
        })[0],
    );
  };

  const checkDuplicateSeriesList = data => {
    const tvShowData = data.filter(n => n.type === 'tvshow-series');
    const lookup = tvShowData.reduce((a, e) => {
      a[e.series_id] = ++a[e.series_id] || 0;
      return a;
    }, {});

    return tvShowData.filter(e => lookup[e.series_id]).length >= 2;
  };

  const getCountryCode = value => {
    gePtlatformVersion(value);
    return regionList.find(n => n.deviceType === value).list[0].cntry;
  };

  const gePtlatformVersion = (deviceType, region) => {
    if (regionPlatformList.find(n => n.deviceType === deviceType && n.region === region)) {
      return regionPlatformList.find(n => n.deviceType === deviceType && n.region === region).platformVersion;
    }
    return '';
  };

  const handleSampleRateChange = e => {
    const reg = /^[0-9\b]+$/;
    let preval = e.target.value;
    if (preval > 100) {
      e.target.value = 100;
    }
    if (/^0/.test(e.target.value)) {
      e.target.value = e.target.value.replace(/^0/, '');
    }
    if (e.target.value === '' || reg.test(e.target.value)) {
      setSampleRatePer(e.target.value);
      return true;
    } else e.target.value = preval.substring(0, preval.length - 1);
  };

  const getRegion = (deviceType, countryCode) => {
    abTestParam.region = '';
    (regionList.find(n => n.deviceType === deviceType) || { list: [] }).list.forEach(a => {
      if (a.cntry.includes(countryCode)) {
        abTestParam.region = a.region;
      }
    });
    return abTestParam.region;
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [date.getFullYear(), padTo2Digits(date.getUTCMonth() + 1), padTo2Digits(date.getUTCDate())].join('-') + ' ' + [padTo2Digits(date.getUTCHours()), padTo2Digits(date.getUTCMinutes()), padTo2Digits(date.getUTCSeconds())].join(':');
  }

  return (
    <Fragment>
      <form id="addRowFrm" autoComplete="off">
        <h5>Current Time (UTC): {time}</h5>
        <AddSegmentFilter
          title={title}
          handleChangeTitle={e => {
            setTitle(e.target.value);
          }}
          startDate={startDate}
          handleChangeStartDate={e => {
            setStartDate(e.target.value);
            setEndDate('');
          }}
          startTimeAnt={startTimeAnt}
          handleChangeStartTime={e => {
            setStartTimeAnt(e.target.value);
          }}
          endDate={endDate}
          handleChangeEndDate={e => {
            setEndDate(e.target.value);
          }}
          endTimeAnt={endTimeAnt}
          handleChangeEndTime={e => {
            setEndTimeAnt(e.target.value);
          }}
          regionList={regionList}
          deviceType={deviceType}
          handleChangeDeviceType={e => {
            setABTestParamAction(e.target.value, getCountryCode(e.target.value), regionList.find(n => n.deviceType === e.target.value).list[0].region, gePtlatformVersion(e.target.value, regionList.find(n => n.deviceType === e.target.value).list[0].region), abTestParam.mappingType);
          }}
          countryCode={countryCode}
          region={abTestParam.region}
          handleChangeCountry={e => {
            setABTestParamAction(abTestParam.deviceType, e.target.value, getRegion(abTestParam.deviceType, e.target.value), gePtlatformVersion(abTestParam.deviceType, e.target.value), abTestParam.mappingType);
          }}
          regionPlatformList={regionPlatformList}
          platformVersion={platformVersion}
          abTestTabCategory={abTestTabCategory}
          handleChangePlatformVersion={e => {
            setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, e.target.value, abTestParam.mappingType);
          }}
          tabCode={tabCode}
          handleChangeTab={e => {
            setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, abTestParam.mappingType);
            setTabCode(e.target.value);
          }}
          categoryCode={categoryCode}
          handleChangeCategory={e => {
            setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, abTestParam.mappingType);
            setCategoryCode(e.target.value);
          }}
          segmentType={segmentType}
          handleChangeSegmentType={e => {
            setSegmentType(e.target.value);
            setSegmentId('');
          }}
          segmentId={segmentId}
          handleChangeSegment={e => {
            setSegmentId(e.target.value);
          }}
          mappingType={mappingType}
          handleChangeMappingType={e => {
            setMappingType(e.target.value);
          }}
          contentPolicy={contentPolicy}
          handleContentPolicy={e => {
            setContentPolicy(e.target.value);
          }}
          isItEdit={false}
          defaultContent={defaultGroupContent}
          onChangeDefaultGroup={e => {
            setDefaultGroupContent(e.target.checked);
            setDefaultContent(e.target.checked ? 'Y' : 'N');
            if (!e.target.checked){
              setComparativeSegmentType('');
              setComparativeSegmentId('');
            }
          }}
          comparativeSegmentType={comparativeSegmentType}
          handleChangeComparativeSegmentType={e => {
            setComparativeSegmentType(e.target.value);
            var segDetails = segment.filter(seg => seg.segmentType === e.target.value && seg.segmentGroup === 'C').map(segtype => segtype.segmentId);
            segDetails.length == 1 ? setComparativeSegmentId(segDetails[0]) : setComparativeSegmentId('')
          }}
          comparativeSegmentId={comparativeSegmentId}
          handleChangeComparativeSegmentId={e => {
            setComparativeSegmentId(e.target.value);
          }}
          sampleRate={sampleRatePer}
          handleSampleRate={handleSampleRateChange}
        />
        {/* befor > <div className={eligeble ? 'channel_mapping_wrap disable-div' : 'channel_mapping_wrap'}> */}
        <div className="channel_mapping_wrap" style={{ pointerEvents: mappingType === 'auto' ? 'none' : '' }}>
          <ABTestManualSearch
            getCountAction={getCountAction}
            tabCode={tabCode}
            abTestTabCategory={abTestTabCategory}
            categoryCode={categoryCode}
            countryCode={countryCode}
            tableRef={selectedTableRef}
            cat={cat}
            selectSearch={selectSearch}
            status={abTestParam.status}
            categoryResultData={result}
            region={region}
            mappingType={mappingType}
          />
          {fetching ? (
            <div className="loading_wrap">
              <LoadingIndicator />
            </div>
          ) : (
            <ABTestManualResult id={id} countryCode={countryCode} cat={cat} result={mappingType === 'auto' ? [] : result} setResult={setResult} renderOrder={renderOrder} selectResult={selectResult} onClickAdd={addResult} onClickDelete={removeResult} isItEdit={false} />
          )}
        </div>
        <div className="btn_set_wrap">
          <Button disabled={eligeble} className="btn_color2" onClick={clickSave}>
            Save
          </Button>
          <Button
            className="btn_color2"
            onClick={() => {
              history.push('/RECOMMEND/antest');
            }}>
            Back
          </Button>
        </div>
      </form>
    </Fragment>
  );
}

AddSegment.propTypes = {
  notifications: PropTypes.array.isRequired,
  abTestTabCategory: PropTypes.array.isRequired,
  segment: PropTypes.array.isRequired,
  abTestParam: PropTypes.object.isRequired,
  getNotifications: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  getABTestTabCategoryAction: PropTypes.func.isRequired,
  updateNotification: PropTypes.func.isRequired,
  publishNotification: PropTypes.func.isRequired,
  getSupportLanguages: PropTypes.func.isRequired,
  searchNotification: PropTypes.func.isRequired,
  regionList: PropTypes.array.isRequired,
  regionPlatformList: PropTypes.array.isRequired,
  getABTest: PropTypes.func.isRequired,
  endAbTest: PropTypes.func.isRequired,
  searchTbTests: PropTypes.array.isRequired,
  saveSegmentConfiguration: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notifications: state.homeApp.notifications,
  abTestParam: state.homeApp.abTestParam,
  abTestTabCategory: state.homeApp.abTestTabCategory,
  segment: state.homeApp.segment,
  regionList: state.homeApp.regionList,
  regionPlatformList: state.homeApp.regionPlatformList,
  fetching: state.homeApp.manualResultFetching,
});

const mapDispatchToProps = dispatch => ({
  setABTestParamAction(deviceType, countryCode, region, platformVersion, mappingType, status) {
    dispatch(setABTestParamAction(deviceType, countryCode, region, platformVersion, mappingType, status));
  },
  getSegment(region, platformVersion) {
    dispatch(getSegment(region, platformVersion));
  },
  getABTestTabCategoryAction(deviceType, countryCode, platformVersion, tabCode, categoryCode, mappingType, status) {
    dispatch(getABTestTabCategoryAction(deviceType, countryCode, platformVersion, tabCode, categoryCode, mappingType, status));
  },
  getABTest(id) {
    dispatch(getABTest(id));
  },
  endAbTest: id => dispatch(endAbTest(id)),
  searchTbTests: (abTestTitle, startDate, endDate, tabCode, categoryCode, deviceType, region, platformVersion, status) => dispatch(searchTbTests(abTestTitle, startDate, endDate, tabCode, categoryCode, deviceType, region, platformVersion, status)),
  saveSegmentConfiguration(addAbTestData) {
    dispatch(saveSegmentConfiguration(addAbTestData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddSegment));
