//import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function abTestTabCategory(state = initialState, action) {
  switch (action.type) {
    case 'SET_AB_TEST_TAB_CATEGORY':
      return action.payload;
    case 'RESET_AB_TEST_TAB_CATEGORY':
      return initialState;
    default:
      return state;
  }
}
