import React from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 18px;
    --y: 298px;
    --width: 299px;
    --height: 56px;

    top: 0;
    left: 0;
    width: 333px;
    height: 1080px;
  }

  #clip-02 {
    --x: 1px;
    --y: 88px;
    --width: 1046px;
    --height: 49px;

    top: 0;
    left: 333px;
    width: 1587px;
    height: 142px;
  }

  #clip-03 {
    --x: 1px;
    --y: 2px;
    --width: 1046px;
    --height: 620px;

    top: 142px;
    left: 333px;
    width: 1587px;
    height: 938px;
  }

  #text-01 {
    top: 250px;
    left: 18px;
  }

  #text-02 {
    top: 800px;
    left: 333px;
  }

  #line-01 {
    top: 102px;
    left: 78px;
  }
`;

const ContentDashboard01 = () => {
  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" className="border" />
        </div>

        <div id="clip-02" className="clip-box">
          <div id="clip-02-hole" className="hole" />
          <div id="clip-02-border" className="border" />
        </div>

        <div id="clip-03" className="clip-box">
          <div id="clip-03-hole" className="hole" />
          <div id="clip-03-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">Content tab</div>
          <div id="text-02" className="text">
            You can check LG Channels Data Flow status and details that grouped by metadata(CP, CMP, CMP API)<br/>
            And if you click metadata, you can go to the issue detail page.
          </div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_6154_line_01.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default ContentDashboard01;