import * as actions from '../../actions/actionTypes';

const initialState = false;

export default function saved(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SAVE_FLAG:
      return action.payload;
    default:
      return state;
  }
}
