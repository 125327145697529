import React, { useRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

const ShowChannelList = (props) => {
  const showTableRef = useRef();

  const { isDisplayChannel
        , channelList
        , callbackSelectedChannel
        , callbackChangeChannelList
        , callbackChangeChannelRule
      } = props;

  const [relatedRule, setRelatedRule] = useState(['auto', 'manual']);
  const [changeChannelList, setChangeChannelList] = useState([]);
  const [selectedChannelId, setSelectedChannelId] = useState('');
  const [selectedChannelIds, setSelectedChannelIds] = useState([]);
  const [isChangeSelectBox, setChangeSelectBox] = useState(false);

  useEffect(() => {
    setSelectedChannelId('');
  }, [isDisplayChannel]);

  useEffect(() => {
    setChangeChannelList([...channelList]);
    callbackChangeChannelRule(false);
    setSelectedChannelId('');
    setSelectedChannelIds([]);
    setChangeSelectBox(false);
  }, [channelList]);

  useEffect(() => {
    if (changeChannelList) {
      callbackChangeChannelList(changeChannelList);
    }
  }, [changeChannelList]);

  useEffect(() => {
    if (isChangeSelectBox) {
      const updatedData = changeChannelList.map(item => {
        if (item.relatedRule === 'cp') {
          return { ...item, relatedFlag: 'on' };
        } else if (item.relatedRule === 'block') {
          return { ...item, relatedFlag: 'off' };
        } else {
          const isSelected = selectedChannelIds.includes(item.channelId);
          return { ...item, relatedFlag: isSelected ? 'on' : 'off' };
        }
      });
      setChangeChannelList(updatedData);
      setChangeSelectBox(false);
    }
  }, [selectedChannelIds]);

  const handleRelatedRule = (channelId, value) => {
    setChangeChannelList(prevData => prevData.map(item => item.channelId === channelId ? {...item, relatedRule: value} : item));
    callbackChangeChannelRule(true);
  };

  const handleAction = (item) => {
    if (item) {
      setSelectedChannelId(item.channelId);
      callbackSelectedChannel(item.channelId);
    }
  };

  const handleSelectionChange = (rows) => {
    const newSelectedChannelIds = rows.map(row => row.channelId);
    const allChannelIds = changeChannelList.map(item => item.channelId);
    const otherChannelIds = changeChannelList
      .filter(item => item.relatedRule !== 'cp' && item.relatedRule !== 'block')
      .map(item => item.channelId);

    const isAllSelected = allChannelIds.length === newSelectedChannelIds.length;

    if (isAllSelected) {
      const shouldSelectAllOthers = otherChannelIds.some(channelId => !selectedChannelIds.includes(channelId));

      if (shouldSelectAllOthers) {
        setSelectedChannelIds([...allChannelIds]);
      } else {
        setSelectedChannelIds([]);
      }
    } else {
      setSelectedChannelIds(newSelectedChannelIds.filter(channelId => otherChannelIds.includes(channelId)));
    }

    callbackChangeChannelRule(true);
    setChangeSelectBox(true);
  };

  return (
    <React.Fragment>
      {
        isDisplayChannel ? (
        <div className='live_tv_channel_list'>
          <MaterialTable
            title=""
            tableRef={showTableRef}
            columns={[
              {
                title: 'Service',
                field: '',
                align: 'left',
                width: '4%',
                sorting: false,
                render: rowData => <div> {rowData.tableData.checked ? 'on' : 'off'} </div>
              },
              {
                title: 'Manual/Auto',
                field: 'relatedRule',
                align: 'center',
                width: '10%',
                render: rowData =>
                <FormControl
                  variant="outlined"
                  disabled={rowData.relatedRule === 'cp' ||
                            rowData.relatedRule === 'block' ? true : false}
                >
                  <Select labelId="RelatedRule" id="RelatedRule" value={rowData.relatedRule}
                    onChange={e => handleRelatedRule(rowData.channelId, e.target.value)}
                  >
                    {(rowData.relatedRule === 'cp' ? ['cp', ...relatedRule] :
                      rowData.relatedRule === 'block' ? ['block', ...relatedRule] : [...relatedRule]).map(item => (
                      <MenuItem key={item} value={item}>
                        {rowData.relatedRule === 'cp' ? 'cp' :
                         rowData.relatedRule === 'block' ? 'block' : item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              },
              {
                title: 'Logo',
                field: 'logoUrl',
                align: 'center',
                width: '10%',
                render: rowData => <img src={rowData.logoUrl} style={{ width: 70, backgroundColor: 'black' }} />,
              },
              {
                title: 'Ch. NO',
                field: 'channelNumber',
                align: 'center',
                width: '10%',
              },
              {
                title: 'Ch. name',
                field: 'channelName',
                align: 'center',
                width: '18%',
              },
              {
                title: 'Ch. ID',
                field: 'channelId',
                align: 'center',
                width: '18%',
              },
              {
                title: 'CP Name',
                field: 'providerName',
                align: 'center',
                width: '10%',
              },
              { title: 'Genre',
                field: 'genreName',
                align: 'center',
                width: '10%',
              },
              {
                title: 'Action',
                field: '',
                align: 'center',
                width: '10%',
                render: rowData => (
                  rowData.relatedRule === 'cp' || rowData.relatedRule === 'block' ? (
                    <></>
                  ) : (
                    <Button
                      disabled={rowData.relatedRule === 'auto' ? true : false}
                      className="btn_size_tbl btn_color3"
                      variant="contained"
                      onClick={() => handleAction(rowData)}
                    >
                      Organize
                    </Button>
                  )
                ),
              },
            ]}
            data={changeChannelList.map(item => ({
              ...item,
              tableData: {
                ...item.tableData,
                checked: item.relatedFlag === 'on',
              },
            }))}
            options={{
              pageSize: 5,
              pageSizeOptions: [5, 10, 20, 50],
              sorting: true,
              selection: true,
              maxBodyHeight: 510,
              rowStyle: rowData => ({
                backgroundColor: rowData.relatedRule === 'cp' || rowData.relatedRule === 'block' ? '#808080' :
                  rowData.relatedRule === 'manual' && rowData.contentCount === 0 ? '#FFBF7C' : rowData.channelId === selectedChannelId ? '#FFFFE0' : '',
                pointerEvents: rowData.relatedRule === 'cp' || rowData.relatedRule === 'block' ? 'none' : 'auto',
              })
            }}

            onSelectionChange={handleSelectionChange}
          />
        </div>
        ) : (
          <></>
        )
      }
    </React.Fragment>
  );
}

export default ShowChannelList;