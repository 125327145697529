import { combineReducers } from 'redux';
import regionList from './regionList';
import regionPlatformList from './regionPlatformList';
import tabCategory from './tabCategory';
import categoryRule from './categoryRule';
import param from './param';
import tabExpand from './tabExpand';
import countGenre from './countGenre';
import countKeyword from './countKeyword';
import monitorServiceData from './serviceBaseData';
import supportLanguages from './supportLanguages';
import manualResultList from './manualResultList';
import manualResultFetching from './manualResultFetching';
import sliderResultList from './sliderResultList';
import sliderResultFetching from './sliderResultFetching';
import genres from './genres';
import monitor from './monitor';
import monitorPreview from './monitorPreview';
import { notifications } from './notifications';
import { notificationsChannelMapList } from './notificationsChannelMapList';
import { notificationsHistory } from './notificationsHistory';
import { tabCategoryList, categoryScheduleList } from './categorySchedule';
import abTestTabCategory from './abTestTabCategory';
import abTestDetails from './abTestDetails';
import segment from './segment';
import abTestParam from './abTestParam';
import abTestDailyData from './abTestDailyData';
import anTestServiceDetails from './anTestServiceDetails';
import anServiceDetails from './anServiceDetails';

export default combineReducers({
  regionList,
  regionPlatformList,
  tabCategory,
  categoryRule,
  param,
  segment,
  tabExpand,
  countGenre,
  countKeyword,
  monitorServiceData,
  supportLanguages,
  monitor,
  monitorPreview,
  notifications,
  notificationsChannelMapList,
  notificationsHistory,
  tabCategoryList,
  categoryScheduleList,
  manualResultList,
  manualResultFetching,
  sliderResultList,
  sliderResultFetching,
  genres,
  abTestTabCategory,
  abTestDetails,
  abTestParam,
  abTestDailyData,
  anTestServiceDetails,
  anServiceDetails,
});
