import React from 'react';

function Loading(props) {
  const { isVisible } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="ui-loading">
        <div className="dim"></div>
        <div className="loading-img">loading..</div>
      </div>
    </>
  );
}

export default Loading;
