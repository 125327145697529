import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API_BASE_URL } from '../../../../constants';
import { getHeadersFormData } from '../../../../util/actionUtil';
import CommonDialog from '../../../../common/dialog/CommonDialog';
import { connect } from 'react-redux';
import { Button, DialogContent, DialogTitle, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ImageEdit from './ImageEdit';

function CarouselResultViewForm({ resultView, tabCategory, handleClose, carouselResult, setCarouselResult, resetResultCheckBox }) {
  const prefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;

  const [isSave, setIsSave] = useState(false);
  //const [eventBannerTilte, setEventBannerTilte] = useState('');
  const [defaultImage, setDefaultImage] = useState('');
  const [defaultImageName, setDefaultImageName] = useState('');
  const [defaultImageInfo, setDefaultImageInfo]  = useState({ w: '', h: '' });
  const [resultHorizontalImage, setResultHorizontalImage] = useState('');
  const [resultHorizontalImageName, setResultHorizontalImageName] = useState('');
  const [resultHorizontalImageInfo, setResultHorizontalImageInfo] = useState({ w: '', h: '' });
  const [resultVerticalImage, setResultVerticalImage] = useState('');
  const [resultVerticalImageName, setResultVerticalImageName] = useState('');
  const [resultVerticalImageInfo, setResultVerticalImageInfo] = useState({ w: '', h: '' });
  const [carouselType, setCarouselType] = useState('');
  const [contentId, setContentId] = useState('');
  const [contentName, setContentName] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [country, setCountry] = useState('');
  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    if (resultView){
      setCountry(resultView.countryCode);
      setCarouselType(resultView.carouselType);
      setContentId(resultView.contentId);
      setContentName(resultView.contentName);
      setDefaultImage(resultView.image ? (resultView.image.indexOf('http') > -1 ? `${resultView.image}` :`${prefix}${resultView.image}`) : '');
      setResultHorizontalImage(resultView.resultHorizontalImage ? (resultView.resultHorizontalImage.indexOf('http') > -1 ? `${resultView.resultHorizontalImage}` :`${prefix}${resultView.resultHorizontalImage}`) : '');
      setResultVerticalImage(resultView.resultVerticalImage ? (resultView.resultVerticalImage.indexOf('http') > -1 ? `${resultView.resultVerticalImage}` :`${prefix}${resultView.resultVerticalImage}`) : ''); 
      if (resultView.carouselType === 'notification' ){
        setIsRequired(true);
      }
    }
  }, [resultView]);

  const handleSubmit = e => {
    e.preventDefault();
    setIsSave(true);
  };

  const AddImageForcarouselResult = (resultHorizontalImagePath, resultVerticalImagePath) => {
    const updateList = [];
    carouselResult.map(element => {
      if (element.contentId === resultView.contentId){
        element.resultHorizontalImage = resultHorizontalImagePath;
        element.resultVerticalImage = resultVerticalImagePath;
      }
      updateList.push(element);
    });
    setCarouselResult(updateList);
  };

  const saveCarouselResultImage = ()  => {
    setIsSave(false);
    if (!resultView || !resultView.contentId){
      alert('Please select a content!');
      return;
    }
    if (isRequired) {
      if (resultHorizontalImage == null || resultHorizontalImage ==''){
        alert('Please select a horizontal image !');
        return;
      }
      if (resultVerticalImage == null || resultVerticalImage ==''){
        alert('Please select a vertical image !');
        return;
      }
    }
    setIsDisabled(true);
    const resultHorizontalImageFile = document.getElementById('resultHorizontalImage_file').files[0]; //undefined if not uploaded
    const resultVerticalImageFile = document.getElementById('resultVerticalImage_file').files[0]; //undefined if not uploaded
    let horizontalImage = resultView.resultHorizontalImage;
    let verticalImage = resultView.resultVerticalImage;
    if (!resultHorizontalImage){
      horizontalImage = '';
    }
    if (!resultVerticalImage){
      verticalImage = '';
    }
    let formData = new FormData();
    if (resultHorizontalImageFile) formData.append('resultHorizontalImage', resultHorizontalImageFile);
    if (horizontalImage) formData.append('horizontalImage', horizontalImage);
    if (resultVerticalImageFile) formData.append('resultVerticalImage', resultVerticalImageFile);
    if (verticalImage) formData.append('verticalImage', verticalImage);
    fetch(`${API_BASE_URL}/carousel/result/image`, {
      method: 'POST',
      headers: getHeadersFormData(),
      body: formData,
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'ok') {
        AddImageForcarouselResult(body.resultHorizontalImagePath, body.resultVerticalImagePath);
        alert('Successfully !');
        resetResultCheckBox();
        handleClose();
      } else if (body.result === 'fail') {
        alert('Upload failed !');
      } else {
        alert('Some Error Occured !');
      }
    })
    .catch(() => {})
    .finally(() => {
      setIsDisabled(false);
    });
  };

  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        Content Image Upload
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={'pop_body'}>
          <div>
            <TableContainer className={'tbl_wrap'}>
              <Table className={'tbl_row_typ1'}>
                <tbody>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="">Tab/Category</label>
                    </TableCell>
                    <TableCell colSpan={2}>
                      {tabCategory}
                    </TableCell>
                    <TableCell>
                      <label className="">Country</label>
                    </TableCell>
                    <TableCell colSpan={2}>
                      {country}
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="">Content Id</label>
                    </TableCell>
                    <TableCell colSpan={2}>
                      {contentId}
                    </TableCell>
                    <TableCell>
                      <label className="">Content Name</label>
                    </TableCell>
                    <TableCell colSpan={2}>
                      {contentName}
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="">Carousel Type</label>
                    </TableCell>
                    <TableCell colSpan={5} className="normal">
                      {carouselType}
                    </TableCell>
                  </TableRow>
                  {/* {resultView.image && (
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="">Default Image</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <ImageEdit
                        image={defaultImage}
                        setImage={setDefaultImage}
                        imageName={defaultImageName}
                        setImageName={setDefaultImageName}
                        imageInfo={defaultImageInfo}
                        setImageInfo={setDefaultImageInfo}
                        inputType="defaultImage"
                        guideText=""
                        isEdit={false}
                      />
                    </TableCell>
                  </TableRow>
                  )} */}
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className={isRequired ? 'is_required': ''}>Horizontal Image</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <ImageEdit
                        image={resultHorizontalImage}
                        setImage={setResultHorizontalImage}
                        imageName={resultHorizontalImageName}
                        setImageName={setResultHorizontalImageName}
                        imageInfo={resultHorizontalImageInfo}
                        setImageInfo={setResultHorizontalImageInfo}
                        inputType="resultHorizontalImage"
                        guideText="Recommended Size: 1280 x 968px"
                        isEdit={true}
                        isRequired={isRequired}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className={isRequired ? 'is_required': ''}>Vertical Image</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <ImageEdit
                        image={resultVerticalImage}
                        setImage={setResultVerticalImage}
                        imageName={resultVerticalImageName}
                        setImageName={setResultVerticalImageName}
                        imageInfo={resultVerticalImageInfo}
                        setImageInfo={setResultVerticalImageInfo}
                        inputType="resultVerticalImage"
                        guideText="Recommended Size: 610 x 968px"
                        isEdit={true}
                        isRequired={isRequired}
                      />
                    </TableCell>
                  </TableRow>
                </tbody>
              </Table>
            </TableContainer>
          </div>
          <div className={'btn_set_wrap'}>
            <Button color="primary" onClick={handleClose} className={'btn_color1'} disabled={isDisabled}>
              Cancel
            </Button>
            <Button type="submit" color="primary" className={'btn_color2'} disabled={isDisabled}>
              Save
            </Button>
          </div>
        </DialogContent>
      </form>
      <CommonDialog
          open={isSave}
          description="Do you want to upload an image of that content?"
          handleCancel={() => {
            setIsSave(false);
          }}
          handleOk={() => {
            saveCarouselResultImage();
          }}
        />
    </Fragment>
  );
}

CarouselResultViewForm.propTypes = {
  resultView: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  carouselResult: PropTypes.array.isRequired,
  setCarouselResult: PropTypes.func.isRequired,
  resetResultCheckBox: PropTypes.func.isRequired,
};

export default connect()(CarouselResultViewForm);
