import React from 'react';
import styled from 'styled-components';

function tutorialController(props) {
  const {isActive, count, total, onPrev, onNext, onClose} = props;

  const flex = obj => `
    display: flex;
    flex-direction: ${(obj && obj.direction) ? obj.direction : 'row'};
    justify-content: ${(obj && obj.justify) ? obj.justify : 'flex-start'};
    align-items: ${(obj && obj.align) ? obj.align : 'flex-start'};
`;

  const font = obj => `
    font-size: ${obj.size};
    font-weight: ${obj.weight};
    color: ${obj.color};
    line-height: ${(obj && obj.line) ? obj.line : 'normal'};
  `;

  const Controller = styled.div`
    .tutorial-close-box {
      .btn-close {
        z-index: 9999;
        position: fixed;
        top: 104px;
        right: 24px;
        width: 40px;
        height: 40px;
        border: 0;
        background: url('/common/images/iss/icons/icon_close_20.png') no-repeat center / 40px;
        box-shadow: none;
      }
    }

    .tutorial-button-box {
      ${font(
          {
            size: '12px',
            weight: 400,
            color: '#fff'
          }
      )}
      .btn-arrow {
        z-index: 9999;
        position: fixed;
        top: 50%;
        width: 60px;
        height: 60px;
        padding: 0;
        transform: translateY(-50%);
        border: 0;
        box-shadow: none;

        &.btn-prev {
          left: 24px;
          background: url('/common/images/iss/icons/ic_arrow_left_60.png') no-repeat center / contain;
        }

        &.btn-next {
          right: 24px;
          background: url('/common/images/iss/icons/ic_arrow_right_60.png') no-repeat center / contain;
        }

        &:disabled {
          opacity: 0.6;
        }
      }
    }

    .tutorial-pagination-box {
      z-index: 9999;
      position: fixed;
      bottom: 3rem;
      left: 50%;
      ${
          flex(
              {
                direction: 'row',
                justify: 'center',
                align: 'center'
              }
          )
      }
      padding: 6px 20px 4px;
      border-radius: 50px;
      background-color: #55565A;
      transform: translateX(-50%);

      .text-box {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        line-height: 23px;

        &.total {
          color: #bbb;
        }
      }
    }
  `;
  return (
      <>
        <Controller
            className={`tutorial-controller-box ${isActive ? 'active' : ''}`}>
          <div className='tutorial-close-box'>
            <button type='button' className='btn-close' title='튜토리얼 닫기'
                    onClick={onClose}></button>
          </div>
          <div className='tutorial-button-box'>
            <button
                type='button'
                className='btn-arrow btn-prev'
                disabled={count <= 1}
                title='이전'
                onClick={onPrev}
            />
            <button
                type='button'
                className='btn-arrow btn-next'
                disabled={count === total}
                title='다음'
                onClick={onNext}
            />
          </div>
          <div className='tutorial-pagination-box'>
            <span className='text-box count'>{count}</span>
            <span className='text-box separator'>/</span>
            <span className='text-box total'>{total}</span>
          </div>
        </Controller>
      </>
  );
}

export default tutorialController;