import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DatePickerDefault from '../common/DatePickerDefault';
import DefaultButton from '../common/DefaultButton';
import { AdDetailData, impressionCountDataset, impressionSuccessRateDataset, requestCountDataset } from './AdDetailData';
import IssTable from '../common/IssTable';
import { CHART_COLORS } from '../common/ChartConsts';
import LineChart from '../common/LineChart';
import PrimaryButton from '../common/PrimaryButton';
import { ISS_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import DropDown from '../common/DropDown';
import Alert from "../modal/Alert";
import CreateJiraModal from "../modal/CreateJira";
import ModalPopup from "../modal/ModalPopup";
import {connect} from "react-redux";
import TutorialPage from '../TutorialPage';

const AdDetail = ({ username }) => {
  const DATE_RANGE = 14;

  const [filterDates, setFilterDates] = useState([])
  const [adIssues, setAdIssues] = useState(AdDetailData.detailTable)
  const [dropdownFilters, setDropdownFilters] = useState({
    appName: { dropdownList: [], checkedList: [] },
    bannerType: { dropdownList: [], checkedList: [] },
    countryCode: { dropdownList: [], checkedList: [] },
    inventoryName: { dropdownList: [], checkedList: [] },
    platform: { dropdownList: [], checkedList: [] },
    adType: { dropdownList: [], checkedList: [] },
  })
  const [paramPlatforms, setParamPlatforms] = useState([])
  const [paramCountries, setParamCountries] = useState([])
  const [paramAppNames, setParamAppNames] = useState([])
  const [paramInventoryNames, setParamInventoryNames] = useState([])
  const [paramBannerTypes, setParamBannerTypes] = useState([])
  const [paramAdTypes, setParamAdTypes] = useState([]);
  const [countChartData, setCountChartData] = useState(AdDetailData.detailGraph.requestImpressionCount.data)
  const [rateChartData, setRateChartData] = useState(AdDetailData.detailGraph.impressionSuccessRate.data)
  const countChartOptions = AdDetailData.detailGraph.requestImpressionCount.options
  const rateChartOptions = AdDetailData.detailGraph.impressionSuccessRate.options
  const [errorCount, setErrorCount] = useState(0)
  const [isAlert, setIsAlert] = useState(false)
  const [alertTitle, setAlertTitle] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [pageRendered, setPageRendered] = useState(false)
  const [isShowCreateJiraPopup, setShowCreateJiraPopup] = useState(false);
  const [isModalPopup, setModalPopup] = useState(false)
  const [modalPopupTitle, setModalPopupTitle] = useState('')
  const [updatingIssueList, setUpdatingIssueList] = useState([])
  const [issueIdList, setIssueIdList] = useState([])
  const [actionDetails, setActionDetails] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [filterFetched, setFilterFetched] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getSearchFilter();
    getAssignGroupList();
    setPageRendered(true);
  }, []);

  useEffect(() => {
    if (adIssues.rowdata.length > 0) {
      onClickRow(adIssues.rowdata[0]);
    }
  }, [adIssues]);

  const handleBackButton = () => {
    history.push('/ISS/IssDashboard', {
      param: JSON.stringify({ type: 'Advertisement' }),
    });
  };

  const defaultRangeDate = () => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - DATE_RANGE);
    return [startDate.toISOString().substring(0, 10), new Date().toISOString().substring(0, 10)];
  };

  const yyyymmddAddDash = date => {
    return date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8);
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const updateActionDetails = () => {
    if (actionDetails === null || actionDetails.length === 0) {
      setAlertMessage(`Please input any message`);
      onOpenAlert();
    } else if (updatingIssueList.length === 0) {
      setAlertMessage(`Please select updating checkbox`);
      onOpenAlert();
    } else {
      const requestBody = {
        user: username === 'admin' ? 'cmpuser' : username,
        actionDetails: actionDetails,
        issueUpdateItems: updatingIssueList
      }

      fetch(`${ISS_BASE_URL}/api/ad/dashboard/issues/actionDetail`, {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify(requestBody)
      })
        .then(res => res.json())
        .then(body => {
          if (body.result === 'SUCCESS') {
            searchIssue();
            setUpdatingIssueList([])
            setActionDetails("")
            setAlertTitle(`Info`);
            setAlertMessage(`Issue update success`);
            onOpenAlert();
          } else {
            setAlertTitle('Error')
            setAlertMessage('Failed to update issues')
          }
        })
    }
  }

  const openCreateJira = () => {
    if (updatingIssueList.length > 0) {

      let existJiraUrlList = updatingIssueList
        .filter(data => adIssues.rowdata.find(item => item.issueId === data.issueId && item.jiraUrl))
        .map(item => item.groupId);

      if (existJiraUrlList.length > 0) {
        setAlertMessage(`IssueId:(${existJiraUrlList}) is already registered in jira`);
        onOpenAlert();
      } else {
        let issueIds = updatingIssueList.map(item => item.issueId);

        setIssueIdList(issueIds);
        setShowCreateJiraPopup(true);
      }
    } else {
      setAlertMessage(`Please select any checkbox`);
      onOpenAlert();
    }
  }

  const handleJiraCallback = data => {
    if (data.result === 'SUCCESS') {
      setAlertTitle(`Info`);
      setAlertMessage(`Jira creation success`);
      onOpenAlert();
    } else {
      setAlertMessage(`Jira creation failure, reason: ` + data.reason);
      onOpenAlert();
    }
  };

  const updateCheckedList = items => {

    if (items.length > 0) {
      const checkedIssueList = items.map(item => {
        return {issueId: item.issueId, issueStatus: item.status, assignGroup: item.assignGroup};
      });
      setUpdatingIssueList(checkedIssueList);
    } else {
      setUpdatingIssueList([]);
    }
  };

  const updateDropDownList = data => {
    if (updatingIssueList.length > 0) {
      const modifyUpdatingIssueList = updatingIssueList.map(item => {
        return { issueId: item.issueId, issueStatus: data.status, assignGroup: item.assignGroup };
      });
      setUpdatingIssueList(modifyUpdatingIssueList);
    }
  };

  const updateAssignDropDownList = data => {
    if (updatingIssueList.length > 0) {
      const modifyUpdatingIssueList = updatingIssueList.map(item => {
        return { issueId: item.issueId, issueStatus: item.status, assignGroup: data.assignGroup };
      });
      setUpdatingIssueList(modifyUpdatingIssueList);
    }
  };

  const changeDropDownAlert = () => {
    setAlertTitle('Warning');
    setAlertMessage('Select the checkbox to change the Dropdown box.');
    onOpenAlert();
  };

  const handleJiraStatusCallback = status => {
    if (status === 'processing') {
      setModalPopupTitle('Create Jira');
      setModalPopup(true);
    } else {
      setModalPopup(false);
    }
  };


  const OnCloseCreateJiraPopup = () => {
    setShowCreateJiraPopup(false);
  };

  const getSearchFilter = () => {
    fetch(`${ISS_BASE_URL}/api/ad/dashboard/filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          const data = body.data
          setParamCountries(data.countryCode)
          setParamPlatforms(data.platform)
          setParamAppNames(data.appName)
          setParamInventoryNames(data.inventoryName)
          setParamBannerTypes(data.bannerType)
          setParamAdTypes(data.adType)
          setDropdownFilters({
            appName: { dropdownList: data.appName, checkedList: [] },
            bannerType: { dropdownList: data.bannerType, checkedList: [] },
            countryCode: { dropdownList: data.countryCode, checkedList: [] },
            inventoryName: { dropdownList: data.inventoryName, checkedList: [] },
            platform: { dropdownList: data.platform, checkedList: [] },
            adType: { dropdownList: data.adType, checkedList: [] },
          });
          setFilterFetched(true)
        } else {
          setAlertTitle('Error')
          setAlertMessage('Failed to get filter')
        }
      });
  };

  const checkEmptyParameter = () => {
    let emptyParam = ''
    if (paramBannerTypes.length === 0) emptyParam = 'Banner Type'
    if (paramInventoryNames.length === 0) emptyParam = 'Inventory Name'
    if (paramAppNames.length === 0) emptyParam = 'App Name'
    if (paramPlatforms.length === 0) emptyParam = 'Platform'
    if (paramCountries.length === 0) emptyParam = 'Country'
    if (paramAdTypes.length === 0) emptyParam = 'Ad Type'
    if (emptyParam !== '') {
      setAlertTitle('Warning')
      setAlertMessage(`Please select any ${emptyParam}`)
      onOpenAlert()
      return false
    }
    return true
  }

  const searchIssue = () => {
    if (pageRendered && checkEmptyParameter() === false) {
      return
    }
    const startDate = filterDates.length > 0 ? filterDates[0] : defaultRangeDate()[0];
    const endDate = filterDates.length > 0 ? filterDates[1] : defaultRangeDate()[1];
    const params = {
      startDate: startDate.replaceAll('-', ''),
      endDate: endDate.replaceAll('-', ''),
      country: paramCountries,
      platform: paramPlatforms,
      appName: paramAppNames,
      inventoryName: paramInventoryNames,
      bannerType: paramBannerTypes,
      adType: paramAdTypes,
    };

    fetch(`${ISS_BASE_URL}/api/ad/dashboard/issues?${new URLSearchParams(params)}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          const data = body.data
          adIssues.rowdata = data.map(issue => ({
            row: [yyyymmddAddDash(issue.date), issue.issueId, issue.adType, issue.platform, issue.appName,
              issue.inventoryName, issue.bannerType, issue.renderRate + '%', issue.changeRate + '%', issue.actionDetail,
              issue.status, issue.assignGroup, issue.jiraUrl],
            date: issue.date,
            countryCode: issue.countryCode,
            platform: issue.platform,
            appName: issue.appName,
            inventoryName: issue.inventoryName,
            bannerType: issue.bannerType,
            adType: issue.adType,
            issueId: issue.issueId,
            assignGroup: issue.assignGroup,
            issueStatus: issue.status,
            jiraUrl: issue.jiraUrl
          }));
          setAdIssues({ ...adIssues, rowdata: [...adIssues.rowdata] });
        } else {
          setAlertTitle('Error')
          setAlertMessage('Failed to get issues')
        }
      });

    fetch(`${ISS_BASE_URL}/api/common/ad-issue/count?${new URLSearchParams(params)}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        setErrorCount(body.dataSet.issueCount);
      });
  };

  const onClickRow = item => {
    const params = {
      date: item.date,
      countryCode: item.countryCode,
      platform: item.platform,
      appName: item.appName,
      inventoryName: item.inventoryName,
      bannerType: item.bannerType,
      adType: item.adType,
      searchCount: 15,
      selectedCountries: paramCountries,
      issueId: item.issueId,
    };
    const requestUrl = `${ISS_BASE_URL}/api/ad/dashboard/chart?${new URLSearchParams(params)}`;

    fetch(requestUrl, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          const data = body.data
          let maxRenderRate = parseFloat(data[0].maxRenderRate);
          let minRenderRate = parseFloat(data[0].minRenderRate);
          countChartData.labels = data.map(item => item.date.substring(4, 6) + '-' + item.date.substring(6));
          rateChartData.labels = data.map(item => item.date.substring(4, 6) + '-' + item.date.substring(6));
          requestCountDataset.data = data.map(item => item.reqCnt);
          impressionCountDataset.data = data.map(item => item.impCnt);
          countChartOptions.scales.y.max = Math.max(...requestCountDataset.data, ...impressionCountDataset.data);
          impressionSuccessRateDataset.data = data.map(item => item.renderRate);
          rateChartOptions.baselineSubMax.value = parseFloat(maxRenderRate);
          rateChartOptions.baselineSubMin.value = parseFloat(minRenderRate);

          setCountChartData({ ...countChartData, datasets: [...countChartData.datasets] });
          setRateChartData({ ...rateChartData, datasets: [...rateChartData.datasets] });
        } else {
          setAlertTitle('Error')
          setAlertMessage('Failed to get chart data')
        }
      });
  };

  const getAssignGroupList = () => {
    fetch(`${ISS_BASE_URL}/api/common/issue/management/group`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        const groupNameList = [...new Set(body.dataSet.map(item => item.groupName))];
        const assignGroups = groupNameList.map(item => {
          return { index: item, name: item };
        });
        AdDetailData.detailTable.assignDropdownItem = assignGroups
        setAdIssues({ ...adIssues, assignDropdownItem: [...assignGroups] })
      });
  };

  const handleOpen = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  useEffect(() => {
    if (filterFetched && location && location.state) {
      const selectedItem = JSON.parse(location.state.param);
      if (selectedItem.paramFilter) {
        setParamCountries(selectedItem.paramFilter.country);
        setParamPlatforms(selectedItem.paramFilter.platform);
        setParamAppNames(selectedItem.paramFilter.appName);
        setParamInventoryNames(selectedItem.paramFilter.inventoryName);
        setParamBannerTypes(selectedItem.paramFilter.bannerType);
        setFilterDates([selectedItem.paramFilter.startDate, selectedItem.paramFilter.endDate]);
        setDropdownFilters({
          appName: { ...dropdownFilters.appName, checkedList: selectedItem.paramFilter.appName },
          bannerType: { ...dropdownFilters.bannerType, checkedList: selectedItem.paramFilter.bannerType },
          countryCode: { ...dropdownFilters.countryCode, checkedList: selectedItem.paramFilter.country },
          inventoryName: { ...dropdownFilters.inventoryName, checkedList: selectedItem.paramFilter.inventoryName },
          platform: { ...dropdownFilters.platform, checkedList: selectedItem.paramFilter.platform },
          adType: { ...dropdownFilters.adType, checkedList: selectedItem.paramFilter.adType },
        });
        if (selectedItem.issueId && selectedItem.rowData) {
          const rowdata =  selectedItem.rowData.map(issue => ({
            row: [yyyymmddAddDash(issue.date), issue.issueId, issue.adType, issue.platform, issue.appName,
              issue.inventoryName, issue.bannerType, issue.renderRate + '%', issue.changeRate + '%', issue.actionDetail,
              issue.status, issue.assignGroup, issue.jiraUrl],
            date: issue.date,
            countryCode: issue.countryCode,
            platform: issue.platform,
            appName: issue.appName,
            inventoryName: issue.inventoryName,
            bannerType: issue.bannerType,
            adType: issue.adType,
            issueId: issue.issueId,
            assignGroup: issue.assignGroup,
            issueStatus: issue.status,
            jiraUrl: issue.jiraUrl,
          }));
          adIssues.rowdata = [rowdata.find(issue => issue.issueId === selectedItem.issueId),
            ...rowdata.filter(issue => issue.issueId !== selectedItem.issueId)]
          if (adIssues.rowdata.length > 0) {
            adIssues.rowdata[0].deeplinkFlag = true
          }
          setAdIssues({ ...adIssues, rowdata: [...adIssues.rowdata] });
          setErrorCount(selectedItem.rowData.length);
        }
      }
    } else if (filterFetched) {
      searchIssue()
    }
  }, [location, filterFetched]);


  return (
    <>
      <div className="iss-main">
        <div className="main-container">
          <div className="inner">
            <div className="sub-detail-wrapper sub-detail-statistics">
              <div className="head">
                <div className="title">
                  <button className="btn-tutorial-2" onClick={handleOpen}/>
                  <h1>Advertisement Detail (Count : {errorCount})</h1>
                </div>
                <div className="back" onClick={handleBackButton}>
                  <button />
                </div>
              </div>
              <div style={{ display: 'flex', marginBottom: '11px', gap: '8px' }}>
                <DatePickerDefault rangeDays={DATE_RANGE} dateChangeHandler={newDate => setFilterDates(newDate)} />
                <DropDown title="Countries" item={dropdownFilters.countryCode} onChangeDropDown={setParamCountries} scroll={true} uiClassName={'ui-dropdown-type08'} checkedList={dropdownFilters.countryCode.checkedList} />
                <DropDown title="Platforms" item={dropdownFilters.platform} onChangeDropDown={setParamPlatforms} checkedList={dropdownFilters.platform.checkedList} />
                <DropDown title="App Name" item={dropdownFilters.appName} onChangeDropDown={setParamAppNames} checkedList={dropdownFilters.appName.checkedList} />
                <DropDown title="Inventory Name" item={dropdownFilters.inventoryName} onChangeDropDown={setParamInventoryNames} checkedList={dropdownFilters.inventoryName.checkedList} />
                <DropDown title="Banner Type" item={dropdownFilters.bannerType} onChangeDropDown={setParamBannerTypes} checkedList={dropdownFilters.bannerType.checkedList} />
                <DropDown title="Ad Type" item={dropdownFilters.adType} onChangeDropDown={setParamAdTypes} checkedList={dropdownFilters.adType.checkedList} />
                <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={searchIssue} />
              </div>
              <div style={{ gap: '24px', display: 'flex', flexDirection: 'column' }}>
                <div className="box-adIssue" style={{ border: '1px solid rgba(255,255,255,0.2)', borderRadius: '10px' }}>
                  <div style={{ padding: '20px', height: '100%' }}>
                    <IssTable pagination={true}
                              data={adIssues}
                              isCheckboxValueInit={true}
                              handleClickItem={item => onClickRow(item)}
                              handleCheckedList={item => updateCheckedList(item)}
                              handleDropDownList={item => updateDropDownList(item)}
                              handleAssignDropDownList={item => updateAssignDropDownList(item)}
                              changeDropDownAlert={changeDropDownAlert}
                              sortingItem={{enable: true, sortColumn: 1, sortDirection: 'desc'}}
                              selectFirstRow={true}
                    />
                  </div>
                </div>
                <div style={{ border: '1px solid rgba(255,255,255,0.2)', borderRadius: '10px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                    <div className="box-value-detail">
                      <div className="head">
                        <div className="title">
                          <h2>Ad Request / Impression Count</h2>
                        </div>
                      </div>
                      <div className="ui-chart-container">
                        <div className="chart-line-legend">
                          <ul>
                            <li>
                              <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }} />
                              <span>Request Count</span>
                            </li>
                            <li>
                              <i style={{ backgroundColor: `${CHART_COLORS.PURPLE}` }} />
                              <span>Impression Count</span>
                            </li>
                          </ul>
                        </div>
                        <LineChart data={countChartData} options={countChartOptions} />
                      </div>
                    </div>
                    <div className="box-value-detail">
                      <div className="head">
                        <div className="title">
                          <h2>Render Rate</h2>
                        </div>
                      </div>
                      <div className="ui-chart-container">
                        <div className="chart-line-legend">
                          <ul>
                            <li>
                              <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }} />
                              <span>Rate</span>
                            </li>
                          </ul>
                        </div>
                        <LineChart data={rateChartData} options={rateChartOptions} />
                      </div>
                    </div>
                    <div className="box-actionDetail">
                      <div className="form-container box-action">
                        <h3>Action Details (Count : {updatingIssueList.length})</h3>
                        <textarea placeholder="Enter action details" value={actionDetails}
                                  onChange={(e) => setActionDetails(e.target.value)} />
                        <div style={{ display: 'flex', gap: '12px', marginTop: '8px' }}>
                          <PrimaryButton type={'button'} text={'Save'} btnClass={'btn-primary-gray'} onClick={updateActionDetails} />
                          <PrimaryButton type={'button'} text={'Create Jira'} btnClass={'btn-primary-accent'} onClick={openCreateJira} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowCreateJiraPopup && <CreateJiraModal type={'AD'} issueIdList={issueIdList} isOpen={isShowCreateJiraPopup}
         onClose={OnCloseCreateJiraPopup} jiraCallback={handleJiraCallback} jiraStatusCallback={handleJiraStatusCallback} />}
      {isModalPopup && <ModalPopup isOpen={isModalPopup} title={modalPopupTitle} />}
      {isAlert && <Alert isOpen={isAlert} onClose={onCloseAlert} title={alertTitle} message={alertMessage}/>}
      {isActive && <TutorialPage data={'advertisementDetail'} isActive={isActive} onClose={handleClose} />}
    </>
  );
};

const mapStateToProps = state => ({
  username: state.currentUser.username,
});

export default connect(mapStateToProps)(AdDetail);