import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { getFeedInfoList } from '../../../actions/channelMappingActions';
import { request } from '../../../util/request';

function FeedInfoEdit({ item, getFeedInfoList }) {
  const [filter, setFilter] = useReducer((state, newState) => ({ ...state, ...newState }), {
    host: item.host,
    port: item.port,
    user: item.user,
    //password: item.password,
    path: item.Buttonpath,
    pattern: item.pattern,
  });

  React.useEffect(() => {
    setFilter({
      host: item.host,
      port: item.port,
      user: item.user,
      //password: item.password,
      path: item.path,
      pattern: item.pattern,
    });
  }, [item]);

  const [open, setOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const handleInputChange = e => {
    setFilter({
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const newItem = {
      host: filter.host,
      port: filter.port,
      user: filter.user,
      //password: filter.password,
      path: filter.path,
      pattern: filter.pattern,
    };

    alert('Are you sure to edit this Feed Info?');
    //alert('blocked');
    const isSuccess = await request(`/sdpapi/sdp-feed?cntry=${item.cntryCode}&cp=${item.cpName}`, 'PATCH', newItem);
    //console.log('[isSuccess]', isSuccess); //await 없으면 Promise {<pending>}//request내부await 없으면 무조건 기본값리턴
    if (isSuccess) {
      setSuccessSnackbarOpen(true);
      getFeedInfoList('sdp', item.cntryCode);
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={() => setOpen(true)} className={'btn_color3 btn_size_tbl'}>
        Edit
      </Button>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={1000} //1000=1sec
        onClose={() => setSuccessSnackbarOpen(false)} //없으면 없어지지않음
        message="Successfully Doned !"
        style={{ backgroundColor: 'black' }}
      />
      <Dialog open={open} className={'pop_wrap'}>
        <div>
          <DialogTitle id="form-dialog-title" className={'pop_head'}>
            Edit Feed Info
            <IconButton aria-label="close" onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={'pop_body'}>
            <form onSubmit={handleSubmit}>
              <div className={'tbl_wrap'}>
                <table className={'tbl_row_typ1'}>
                  <colgroup>
                    <col style={{ width: '200px' }} />
                    <col style={{ width: 'auto' }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <label htmlFor={'providerId'} className={'is_required'}>
                          Host
                        </label>
                      </th>
                      <td>
                        <TextField fullWidth margin="dense" variant="outlined" name="host" id="providerId" value={filter.host} onChange={e => handleInputChange(e)} required autoFocus />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label htmlFor={'providerName'} className={'is_required'}>
                          Port
                        </label>
                      </th>
                      <td>
                        <TextField fullWidth margin="dense" variant="outlined" name="port" id="providerName" value={filter.port} onChange={e => handleInputChange(e)} required />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label htmlFor={'interactiveUrl'} className={'is_required'}>
                          User
                        </label>
                      </th>
                      <td>
                        <TextField fullWidth margin="dense" variant="outlined" name="user" id="interactiveUrl" value={filter.user} onChange={e => handleInputChange(e)} required />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label htmlFor={'interactiveUrl'} className={'is_required'}>
                          Path
                        </label>
                      </th>
                      <td>
                        <TextField fullWidth margin="dense" variant="outlined" name="path" id="interactiveUrl" value={filter.path} onChange={e => handleInputChange(e)} required />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label htmlFor={'interactiveUrl'} className={'is_required'}>
                          Pattern
                        </label>
                      </th>
                      <td>
                        <TextField fullWidth margin="dense" variant="outlined" name="pattern" id="interactiveUrl" value={filter.pattern} onChange={e => handleInputChange(e)} required />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={'btn_set_wrap'}>
                <Button data-testid="addButton" color="primary" onClick={() => setOpen(false)} className={'btn_color1'}>
                  Cancel
                </Button>
                <Button data-testid="addButton" type="submit" color="secondary" className={'btn_color2'}>
                  Save
                </Button>
              </div>
            </form>
          </DialogContent>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

FeedInfoEdit.propTypes = {
  item: PropTypes.object.isRequired,
  getFeedInfoList: PropTypes.func.isRequired,
};
// const mapStateToProps = (state) => ({
//   isAdded: state.providers.isAdded,
// });
const mapDispatchToProps = dispatch => ({
  getFeedInfoList(system, cntry, cp) {
    dispatch(getFeedInfoList(system, cntry, cp));
  },
});
export default connect(null, mapDispatchToProps)(FeedInfoEdit);
