import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import { Link as RouterLink, withRouter } from 'react-router-dom';
//import { Route, MemoryRouter } from 'react-router';
//import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { BreadcrumbMap } from './AppHeader/MenuList';
//import List from '@material-ui/core/List';
//import Link from '@material-ui/core/Link';
//import ListItem from '@material-ui/core/ListItem';
//import Collapse from '@material-ui/core/Collapse';
//import ListItemText from '@material-ui/core/ListItemText';
//import ExpandLess from '@material-ui/icons/ExpandLess';
//import ExpandMore from '@material-ui/icons/ExpandMore';
// function ListItemLink(props) {
//   const { to, open, ...other } = props;
//   const primary = breadcrumbNameMap[to];
//   return (
//     <li>
//       <ListItem button component={RouterLink} to={to} {...other}>
//         <ListItemText primary={primary} />
//         {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//       </ListItem>
//     </li>
//   );
// }
// ListItemLink.propTypes = {
//   open: PropTypes.bool,
//   to: PropTypes.string.isRequired,
// };
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 360,
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  breadcrumb: {
    top: '56px',
    left: '200px',
    height: '40px',
    boxShadow: '0 0 0 0',
  },
}));
//const LinkRouter = props => <Link {...props} component={RouterLink} />;
function Breadcrumb({ location }) {
  const classes = useStyles();
  const pathnames = location.pathname.split('/').slice(1);
  //const pathnames = location.pathname.split('/').filter((x) => x); //[SYSTEM,notification]
  //filter((x) => x) ''제외
  // {/*position:명시안함- fixed랑 동일효과
  // 					fixed-헤더에가려서안보임
  // 					relative-높이엄청두꺼워짐
  // 					static-높이엄청두꺼워짐*/}
  return (
    <AppBar position="absolute" color="default" className={classes.breadcrumb} id={'page_location'}>
      <Toolbar>
        <Typography variant="h6" color="inherit">
          {/* <MemoryRouter initialEntries={['/']} initialIndex={0}> */}
          {/* <MemoryRouter initialIndex={0}> */}
          <div className={classes.root + ' page_location'}>
            <div>
              {/*</div><Route>*/}
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="inherit">HOME</Typography>
                {/* <LinkRouter color="inherit" to="/">
                      HOME
                    </LinkRouter> */}
                {pathnames.slice(0,3).map((value, index) => {
                  const last = index === pathnames.length - 1;
                  const maxPathIndex = Math.min(pathnames.length - 1, 2); // Take only up to the 4th value (index 3)
                 const to = `/${pathnames.slice(0, Math.min(index + 1, maxPathIndex + 1)).join('/')}`;
                  return last ? (
                    <Typography color="textPrimary" key={to}>
                      {BreadcrumbMap[to]}
                    </Typography>
                  ) : (
                    <Typography color="inherit" key={to}>
                      {BreadcrumbMap[to]}
                    </Typography>
                    // <LinkRouter color="inherit" key={to}>
                    //   {BreadcrumbMap[to]}
                    // </LinkRouter>
                  );
                })}
              </Breadcrumbs>
            </div>
            {/*</Route>*/}
          </div>
          {/* </MemoryRouter> */}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
Breadcrumb.propTypes = {
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  isAuthenticated: state.currentUser.isAuthenticated,
});
// const mapDispatchToProps = dispatch => ({
//   setAuthenticatedAction(value) {
//     dispatch(setAuthenticatedAction(value));
//   },
// });
export default withRouter(connect(mapStateToProps, null)(Breadcrumb));
