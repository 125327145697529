import React, { useEffect } from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 12px;
    --y: 95px;
    --width: 613px;
    --height: 52px;

    top: 0;
    left: 0;
    width: 1920px;
    height: 157px;
  }

  #clip-02 {
    --x: 12px;
    --y: 1px;
    --width: 1304px;
    --height: 116px;

    top: 157px;
    left: 0;
    width: 1920px;
    height: 1600px;
  }

  #text-01 {
    top: 308px;
    left: 189px;
  }
`;

const ServiceDetail01 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" className="border" />
        </div>

        <div id="clip-02" className="clip-box">
          <div id="clip-02-hole" className="hole" />
          <div id="clip-02-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">You can set conditions for the filter and check service details meet that conditions</div>
        </div>
      </TutorialArea>
    </>
  );
};

export default ServiceDetail01;