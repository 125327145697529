import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-multi-date-picker';
import DefaultButton from '../common/DefaultButton';
import Alert from '../modal/Alert';

function DatePickerTrend(props) {
  const { rangeDays, dateChangeHandler, selectedDate } = props;

  const [visibleCal, setVisibleCal] = useState(false);                    // 달력을 화면에 보여 주기 위한 용도
  const [showStartDate, setShowStartDate] = useState('');                 // 화면에 보이는 start date
  const [showEndDate, setShowEndDate] = useState('');                     // 화면에 보이는 end date
  const [showCalendarDate, setShowCalendarDate] = useState('');           // 달력에 보이는 date
  const [isDateChange, setDateChange] = useState(false);                  // 날짜가 변경되었을 때 callback을 보내 주기 위한 용도
  const [backupStartDate, setBackupStartDate] = useState('');             // 화면에 보이는 start date backup
  const [backupEndDate, setBackupEndDate] = useState('');                 // 화면에 보이는 end date backup
  const [utcToday, setUtcToday] = useState('');                           // UTC 기준 today
  const [isSelectedDateChange, setSelectedDateChange] = useState(false);  // 변경된 date 값을 유지하기 위한 용도

  const [currentTab, setCurrentTab] = useState('');
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');

  const defaultRangeDays = 7;

  useEffect(() => {
    defaultRangeDate();
  }, []);

  useEffect(() => {
    if (selectedDate && selectedDate.length > 0) {
      setShowStartDate(selectedDate[0]);
      setShowEndDate(selectedDate[1]);

      setSelectedDateChange(true);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (isDateChange || isSelectedDateChange) {
      const changeDate = [showStartDate, showEndDate];
      dateChangeHandler(changeDate);
      if (isDateChange) {
        setDateChange(false);
      }
      if (isSelectedDateChange) {
        setSelectedDateChange(false);
      }
    }
  }, [isDateChange, isSelectedDateChange]);

  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());

    const yesterday = new Date(utcToday);
    yesterday.setDate(utcToday.getDate() - 1);

    const period = rangeDays ? rangeDays : defaultRangeDays;
    const pastDay = new Date(utcToday);

    pastDay.setDate(yesterday.getDate() - period);

    const todayString = today.toISOString().substring(0, 10);
    const yesterdayString = yesterday.toISOString().substring(0, 10);
    const pastDayString = pastDay.toISOString().substring(0, 10);

    setUtcToday(todayString);
    setShowStartDate(pastDayString);
    setShowEndDate(yesterdayString);

    setDateChange(true);
  }

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const onVisibleCal = tab => {
    if (tab === 'startCal') {
      setCurrentTab('startCal');
      setShowCalendarDate(showStartDate);
      setBackupStartDate(showStartDate);
    } else if (tab === 'endCal') {
      setCurrentTab('endCal');
      setShowCalendarDate(showEndDate);
      setBackupEndDate(showEndDate)
    }

    setVisibleCal(true);
  };

  const onSetChange = selectedDate => {
    if (currentTab === 'startCal') {
      setShowStartDate(typeof (selectedDate) === 'object' ? selectedDate.toString() : selectedDate);
    } else {
      setShowEndDate(typeof (selectedDate) === 'object' ? selectedDate.toString() : selectedDate);
    }
  };

  const onSelect = () => {
    const maxCompareDay = 90;

    const startDate = new Date(showStartDate);
    const endDate = new Date(showEndDate);
    const differenceInTime = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    const todayDate = new Date(utcToday);

    // End data 가 Today 보다 클 경우에 에러 팝업을 보여 줌
    if (endDate > todayDate) {
      setAlertMessage(`End date is greater than Today, please re-select the date`);
      onOpenAlert();
    }
    // End date 가 start date 보다 작거나 start date 와 end date 간 차이가 90을 초과할 때 에러 팝업을 보여 줌
    else if (startDate > endDate) {
      setAlertMessage(`Start date is greater than end date, please re-select the date`);
      onOpenAlert();
    } else if (differenceInDays > maxCompareDay) {
      setAlertMessage(`You can only look it up for 3 months`);
      onOpenAlert();
    } else {
      setDateChange(true);
      setVisibleCal(false);
    }
  };

  const onCancel = () => {
    if (backupStartDate) {
      setShowStartDate(backupStartDate);
    }

    if (backupEndDate) {
      setShowEndDate(backupEndDate);
    }

    setVisibleCal(false);
  };

  return (
    <>
      <div className="ui-datepicker trend">
        <div className="datepick-container">
          <div className="datepick-box">
            <span className="value-date" onClick={() => onVisibleCal('startCal')}>
              {showStartDate}
            </span>
          </div>
          <span className="text">~</span>
          <div className="datepick-box">
            <span className="value-date" onClick={() => onVisibleCal('endCal')}>
              {showEndDate}
            </span>
          </div>
          {visibleCal && (
            <div className="calendar-container">
              <Calendar highlightToday={false} value={showCalendarDate} onChange={onSetChange} format="YYYY-MM-DD">
                <div className="button-container">
                  <DefaultButton type={'button'} text={'Cancel'} btnClass={'trend-btn-search'} clickEvent={onCancel} />
                  <DefaultButton type={'button'} text={'Select'} btnClass={'trend-btn-search'} clickEvent={onSelect} />
                </div>
              </Calendar>
            </div>
          )}
        </div>
      </div>
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default DatePickerTrend;
