import React, { useEffect, useState } from 'react';

const ChannelDataFlow = props => {
  const { key, cp, imageUrl, data, isActive, clickCpIcon } = props;
  const [onFlag, setOn] = useState(isActive ? true : false);

  const handleClickIcon = () => {
    if (clickCpIcon) {
      clickCpIcon(cp);
    }
  };

  return (
    <>
      <li className={onFlag ? 'on' : ''} onClick={() => handleClickIcon()}>
        <span className="logo" style={{ backgroundImage: `url(${imageUrl})` }}></span>
        <span className="num">{data}</span>
      </li>
    </>
  );
};

export default ChannelDataFlow;
