import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 34px;
    --y: 689px;
    --width: 722px;
    --height: 454px;

    top: 0;
    left: 0;
    width: 764px;
    height: 2000px;
  }

  #clip-02 {
    --x: 1px;
    --y: 689px;
    --width: 708px;
    --height: 454px;

    top: 0;
    left: 764px;
    width: 717px;
    height: 2000px;
  }

  #clip-03 {
    --x: 1px;
    --y: 689px;
    --width: 389px;
    --height: 454px;

    top: 0;
    left: calc(100% - 439px);
    width: 439px;
    height: 2000px;
  }

  #text-01 {
    top: 619px;
    left: 1446px;
  }

  #line-01 {
    top: 632px;
    left: 1796px;
  }
`;

const StatisticsDetail02 = () => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      window.scrollTo(0, elementRef.current.offsetTop - 90); // GNB height
    }
  }, []);

  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" className="border" />
        </div>

        <div id="clip-02" className="clip-box">
          <div id="clip-02-hole" className="hole" />
          <div id="clip-02-border" className="border" />
        </div>

        <div id="clip-03" className="clip-box">
          <div id="clip-03-hole" className="hole" />
          <div id="clip-03-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" ref={elementRef} className="text">You can see action details</div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_6140_line_01.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default StatisticsDetail02;