/**
 * Calculate the increase rate of content two compared to contents one
 *
 * @param {*} content_one
 * @param {*} content_two
 * @returns increate rate
 */
export function calImprovedRate(content_one, content_two) {
  if (isNaN(content_one) || isNaN(content_two) || content_one <= 0 || content_two <= 0) {
    return 'N/A';
  }
  const improvedRate = (content_one - content_two) * 100 / content_two === 0 ? 0 : ((content_one - content_two) * 100 / content_two).toFixed(1);
  if (isNaN(improvedRate)) {
    return '';
  }
  return improvedRate.toString() + '%';
}
