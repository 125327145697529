import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Typography } from '@material-ui/core';
import { request } from '../../util/request';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';

function GenreDetail({ history, genreCode }) {

  const [content, setContent] = React.useState({});
  const [img, setImg] = React.useState('');
  const [isEdit, setIsEdit] = React.useState(false);
  const prefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;

  const fetchContent = async code => {
    const { genreName, genreCode, genreImage, lastChgDate, subGenreInfo } = await request(`/api/home-app/genre/${code}`, 'GET', null, true);
    const cont = { genreName, genreCode, genreImage, lastChgDate, subGenreInfo };
    setContent(cont);
    setImg(cont.genreImage);
  };

  React.useEffect(() => {
    fetchContent(genreCode);
  }, [genreCode]);

  const handleClickList = () => {
    history.push(`/HOME-APP/genre`);
  };

  const clickSave = async event => {
    event.preventDefault();

    alert('Are you sure to save this?');
    let requestBody = { ...content };
    const genreImage = document.getElementById('hidden_file').files[0];
    requestBody = new FormData();
    requestBody.append('genreImage', genreImage);

    let isSuccess = await request(`/api/home-app/genre/${genreCode}`, 'PATCH', requestBody, false, false, true);

    if (isSuccess) {
      fetchContent(genreCode);
      setIsEdit(false);
      alert('Successfully saved !');
    }
  };

  return (
    <div>
      <form noValidate autoComplete="off">
        <div className="tbl_wrap tbl_radius">
          <table className="tbl_row_typ1 tbl_channel">
            <colgroup>
              <col style={{ width: '13%' }} />
              <col style={{ width: '47%' }} />
            </colgroup>
            <thead>
              <tr>
                <th>Genre Name</th>
                <td>{content.genreName}</td>
              </tr>
              <tr>
                <th>Genre Code</th>
                <td>{content.genreCode}</td>
              </tr>
              <tr>
                <th>Sub Genre Info</th>
                <td>{content.subGenreInfo}</td>
              </tr>
              <tr>
                <th rowSpan={3}>Genre Image</th>
                <td rowSpan={3}>
                  {img && (
                  <img
                    alt="invalid"
                    src={isEdit === true ? img : prefix + img}
                    title=''
                    style={{ maxWidth: '100%', backgroundColor: 'black' }}
                    onLoad={e => {
                      //setBgInfo({ w: e.target.naturalWidth, h: e.target.naturalHeight });
                    }}
                  />
                   )}
                  <Grid container style={{ marginTop: '5px' }}>
                    <Grid>
                      <p className="col-md-9">Recommended Size: 560 X 560</p>
                    </Grid>
                  </Grid>
                  {<EditImg img={img} setImg={setImg} isEdit={isEdit} setIsEdit={setIsEdit} type="genre" />}
                </td>
              </tr>
              <tr>
              </tr>
              <tr>
              </tr>
              <tr>
                <th>Image URL</th>
                <td>
                  {content.genreImage ? prefix + content.genreImage : ''}
                </td>
              </tr>
              <tr>
                <th>Last Updated</th>
                <td>{content.lastChgDate}</td>
              </tr>
            </thead>
          </table>
        </div>
      </form>
      <div className="btn_set_wrap">
        <div className="btn_set_left_cont">
          <Button variant="contained" className={'btn_color3'} onClick={handleClickList}>
            {' '}
            List{' '}
          </Button>
        </div>
        { isEdit &&
          (
            <Button variant="contained" className={'btn_color2'} onClick={async e => {clickSave(e)}}>
              {' '}
              Save{' '}
            </Button>
          )
        }
      </div>
    </div>
  );
}
GenreDetail.propTypes = {
};
const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GenreDetail));

function EditImg({ img, setImg, isEdit, setIsEdit, type }) {

  const [imgName, setImgName] = React.useState(img);
  return (
    <div className="logo_preview_add">
      {/* <p className="logo_preview_comment">※ Recommended: ?PX height and ?PX width</p> */}
      <input
        accept="image/*"
        id={`input_${type}`}
        type="file"
        className="logo_preview_input"
        onChange={e => {
          if (e.target.files && e.target.files.length >= 1) {
            const reader = new FileReader();
            const img = e.target.files[0];
            reader.readAsDataURL(img);
            reader.onload = e2 => {
              document.getElementById('hidden_file').files = document.getElementById(`input_${type}`).files;
              setImg(e2.target.result);
              setImgName(img.name);
              setIsEdit(true);
            };
          }
        }}
      />
      <input type="file" id="hidden_file" hidden ></input>
      <Grid container spacing={3} style={{ marginTop: '5px' }}>
        <Grid item sm={9}>
          <p className="col-md-9">{imgName}</p>
        </Grid>
        <Grid item sm={3}>
          <label htmlFor={`input_${type}`}>
            <Button component="span" className="btn_color4" style={{ width: 110 }}>
              Upload File
            </Button>
          </label>
          {(isEdit || img !== '') && (
            <label>
              {img && type === 'genre' && (
                <Button
                  component="span"
                  className="btn_color4"
                  style={{ width: 110, marginLeft: 10 }}
                  onClick={() => {
                    setImg('');
                    setImgName('');
                    document.getElementById('hidden_file').value = '';
                    setIsEdit(true);
                  }}>
                  Delete File
                </Button>
              )}
            </label>
          )}
        </Grid>
      </Grid>
    </div>
  );
}