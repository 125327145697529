import * as actions from '../../actions/actionTypes';

const initialState = 0;

export default function page(state = initialState, action) {
  switch (action.type) {
    case actions.SET_RSS_LOG:
      return action.payload.pageable.pageNumber;
    default:
      return state;
  }
}
