import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const DEFAULT_TITLE = 'selected';
function DropDown( props ) {
  const { item, title, onChangeDropDown, checkedList, checkedItem, uiClassName, scroll } = props;
  const [open, setOpen] = useState(false);
  const [checkedItemsList, setCheckedItemsList] = useState([]);
  const defineClass = uiClassName ? uiClassName : "ui-dropdown-type01";

  useEffect(() => {
    if (item && item.dropdownList){
      if (item.initCheckedList && item.initCheckedList.length > 0){
        setCheckedItemsList(item.initCheckedList);
      } else {
        onCheckedAll(true);
      }
    }
  }, [item]);

  useEffect(() => {
    if (checkedList && checkedList.length > 0 ){
      setCheckedItemsList(checkedList);
    }
  }, [checkedList]);

  useEffect(() => {
    if (checkedItem && checkedItem.length > 0 ){
      setCheckedItemsList([checkedItem]);
    }
  }, [checkedItem]);

  const onCheckedAll = useCallback(
    checked => {
      if (checked) {
        const checkedListArray = [];
        item.dropdownList.forEach(list => checkedListArray.push(list));
        setCheckedItemsList(checkedListArray);
      } else {
        setCheckedItemsList([]);
      }
    },
    [item],
  );

  const onCheckedEl = useCallback(
    (checked, list) => {
      if (checked) {
        setCheckedItemsList([...checkedItemsList, list]);
      } else {
        setCheckedItemsList(checkedItemsList.filter(el => el !== list));
      }
    },
    [checkedItemsList],
  );

  useEffect(() => {
    if (onChangeDropDown){
      onChangeDropDown(checkedItemsList);
    }
  }, [checkedItemsList]);

  const onBlur = () => {
    setOpen(false);
  };

  const onClick = e => {
    setOpen(!open);
  };

  return (
    <>
      <div tabIndex={0} className={`${defineClass} ${scroll ? 'scroll' : ''}`} onBlur={onBlur}>
        <div className="label" onClick={e => onClick(e)}>
          {checkedItemsList.length} {title || DEFAULT_TITLE}
        </div>
        <ul className={open ? 'on' : ''}>
          <li>
            <label>
              <input type="checkbox" name="region2" onChange={e => onCheckedAll(e.target.checked)} checked={checkedItemsList.length === 0 ? false : checkedItemsList.length === item.dropdownList.length ? true : false} />
              <span>Select All</span>
            </label>
          </li>
          {item &&
            item.dropdownList &&
            item.dropdownList.map((items, index) => (
              <li key={index}>
                <label>
                  <input type="checkbox" name="region2" onChange={e => onCheckedEl(e.target.checked, items)} checked={checkedItemsList.includes(items) ? true : false} />
                  <span>{items}</span>
                </label>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
DropDown.propTypes = {
  title: PropTypes.string.isRequired,
  onChangeDropDown: PropTypes.func,
};
export default DropDown;
