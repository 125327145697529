import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import NoData from '../../common/NoData';

function SimplePieContainer({ graph }) {
  //static jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';
  let _channelGenre = [];
  let _channel = [];

  graph && graph.length > 0 && graph.forEach(n => {
    let _data = {};
    if (n.type === 'channelGenre') {
      _data.name = n.name;
      _data.value = n.value;
      _channelGenre.push(_data);
    } else if (n.type === 'channel') {
      _data.name = n.name;
      _data.value = n.value;
      _channel.push(_data);
    }
  });

  return graph && _channelGenre.length > 0 && _channel.length > 0 ? (
    <div className="chart_card">
      <div className="chart_row line_chart">
        <div className="chart_unit">
          <span className="tit">Usability by Channel</span>
          <br />
          <br />
          <br />
          <center>
            <SimplePieChart data={_channel} />
          </center>
        </div>
        <div className="chart_unit">
          <span className="tit">Usability by Channel Genre</span>
          <br />
          <br />
          <br />
          <center>
            <SimplePieChart data={_channelGenre} />
          </center>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}
SimplePieContainer.propTypes = {
  graph: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  graph: state.statistic.graph,
});

export default connect(mapStateToProps)(SimplePieContainer);

const COLORS = ['#fa5252', '#ffa8a8', '#ffa94d', '#ffd43b', '#a9e34b', '#2f9e44', '#20c997', '#1c7ed6', '#6741d9', '#da77f2', '#f06595', '#adb5bd'];
const RADIAN = Math.PI / 180;
function SimplePieChart({ data }) {
  //static jsfiddleUrl = 'https://jsfiddle.net/alidingling/3Leoa7f4/';
  return (
    <React.Fragment>
      {data && data.length > 0 ? (
        <PieChart width={800} height={400}>
          <Pie
            data={data}
            dataKey="value"
            cx={370} //center x-coordinate position
            cy={180} //center y-coordinate position
            innerRadius={60}
            outerRadius={160}
            //fill="#8884d8"//color, cell안에서 다른 색으로 채우지므로 필요없음
            // paddingAngle={5}//파이 그래프 항목사이에 5도씩 공간비워둠
            label={renderCustomizedLabel}
            //label
            labelLine={false}
            isAnimationActive={false} // 그래프 그릴 때 애니메이션처럼 서서히 그려지는 효과없앰
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              //<Cell key={`cell-${index}`} fill={'#' + Math.random().toString(16).substr(2, 6)} />가끔 같은컬러 나오기도함
              //<Cell key={`cell-${index}`} fill={'#' + Math.floor(Math.random() * 16777215).toString(16)} />가끔 같은컬러 나오기도함
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      ) : (
        <NoData message="No Data Found" />
      )}
    </React.Fragment>
  );
}
SimplePieChart.propTypes = {
  data: PropTypes.array.isRequired,
};

const renderCustomizedLabel = props => {
  let { cx, cy, midAngle, outerRadius, percent, name } = props;
  const radius = outerRadius * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const mx = cx + (outerRadius + 0) * cos;
  const my = cy + (outerRadius + 0) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={x} /*dx="10"*/ y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize="20">
        {`${(percent * 100).toFixed(0)}%`}
      </text>

      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" fontSize="17">
        {name}
      </text>
    </g>
  );
};