import { ROWHEADER_TYPE } from '../../../consts/IssConsts';

export const feed_basic_trend_data = {
  title: 'Feed Count Trend',
  header: ['Date', 'Total', 'Fail'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['34%', '33%', '33%'],
  rowdata: [],
};

export const feed_basic_trend_data_by_provider = {
  title: 'Feed Count Trend',
  header: ['ProviderId', 'Date', 'Total', 'Fail'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['25%', '25%', '25%', '25%'],
  rowdata: [],
};

export const feed_basic_trend_data_by_country = {
  title: 'Feed Count Trend',
  header: ['Country', 'Date', 'Total', 'Fail'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['25%', '25%', '25%', '25%'],
  rowdata: [],
};

export const feed_duplicated_error_trend_data = {
  title: 'Feed Error Code Trend',
  header: ['Provider Id', 'Error Code', 'Error Count'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['34%', '33%', '33%'],
  rowdata: [],
};

export const feed_top_trend_data = {
  title: 'Feed Top Trend',
  header: ['Provider Id', 'Total', 'Fail'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['34%', '33%', '33%'],
  rowdata: [],
};

export const feed_top_trend_data_by_country = {
  title: 'Feed Top Trend',
  header: ['Country', 'Provider Id', 'Total', 'Fail'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['25%', '25%', '25%', '25%'],
  rowdata: [],
};

export const feed_basic_trend_resolve_count = {
  title: 'Feed Resolve Trend',
  header: ['Date', 'Total Resolve', 'Auto Resolve', 'Manual Resolve'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['25%', '25%', '25%', '25%'],
  rowdata: [],
};

export const CHART_COLORS_BY_ERROR = {
  CDP_FEED_400_01: '#69B0EE',
  CDP_FEED_400_02: '#719BF4',
  CDP_FEED_400_03: '#EFA1F1',
  CDP_FEED_400_04: '#FCF057',
  CDP_FEED_400_05: '#89D35C',
  CDP_FEED_FILE_01: '#E47874',
  CDP_FEED_FILE_02: '#6387D5',
  CDP_FEED_FILE_03: '#D4AA00',
  CDP_SVC_4003: '#FF9DB0',
  CDP_SVC_4004: '#77B2E0',
  CDP_SVC_4005: '#8E9FFF',
  CDP_SVC_4042: '#723939',
  CDP_SVC_4045: '#E78B48',
  CDP_SVC_4046: '#FFBF7C',
  CDP_SVC_4047: '#74D1A0',
  CDP_SVC_5021: '#FE83A8',
  CDP_SVC_5022: '#5390DC',
  CDP_OTHERS: '#FFD700',
};

export const ERROR_TOOLTIP_INFO = {
  CDP_FEED_400_01: 'Json format is not normal',
  CDP_FEED_400_02: 'JSON data type error',
  CDP_FEED_400_03: 'Required value of channel is missing',
  CDP_FEED_400_04: 'Required value of programschedule is missing',
  CDP_FEED_400_05: 'Required value of vod is missing',
  CDP_FEED_FILE_01: 'Channel Feed file does not exist',
  CDP_FEED_FILE_02: 'ProgramSchedule Feed file does not exist',
  CDP_FEED_FILE_03: 'VOD Feed file does not exist',
  CDP_SVC_4003: 'Server error occurred while saving the feed.',
  CDP_SVC_4004: 'An error caused by a duplicate unique value',
  CDP_SVC_4005: 'The data of some programs is not integrity.',
  CDP_SVC_4042: 'There is no program information in the DB',
  CDP_SVC_4045: 'There is no channel information in the DB',
  CDP_SVC_4046: 'There is no program information in the DB',
  CDP_SVC_4047: 'The value of the date in the schedule is invalid',
  CDP_SVC_5021: 'Server error occurred while saving the feed',
  CDP_SVC_5022: 'Feed registration traffic increased',
  CDP_OTHERS: 'Unknown Error',
};