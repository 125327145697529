import { combineReducers } from 'redux';
import sd0201 from './sd0201';
import sd0101 from './sd0101';
import sd0103 from './sd0103';
import sd0104 from './sd0104';
import sd0105 from './sd0105';
import sd0106 from './sd0106';
import sd0107 from './sd0107';
import sd0108 from './sd0108';
import sd0109 from './sd0109';
import displayRulesMainStore from './displayRulesMainStore';
import rulesStore from './rulesStore';
import sdkVersionSettingStore from './sdkVersionSettingStore';

import last from './last';
import page from './page';
import size from './size';
import totalElements from './totalElements';
import totalPages from './totalPages';
import depthByRegion from './depthByRegion';

//
export default combineReducers({
  sd0201,
  sd0101,
  sd0103,
  sd0104,
  sd0105,
  sd0106,
  sd0107,
  sd0108,
  sd0109,
  sdkVersionSettingStore,
  displayRulesMainStore,
  rulesStore,
  last,
  page,
  size,
  totalElements,
  totalPages,
  depthByRegion
});
