import React, { useEffect, useState } from 'react';
import annotationPlugin from 'chartjs-plugin-annotation';
import Alert from '../../../modal/Alert';
import { CHART_COLORS } from '../../../common/ChartConsts';
import { getHeaders } from '../../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../../constants/index'
import { Bar } from 'react-chartjs-2';
import {
  CHART_COLORS_BY_COUNTRY,
  CHART_COLORS_BY_PROVIDER,
  CHART_COLORS_BY_PLATFORM
} from '../../../common/ChartConsts';
import {
  duration_access_bar_trend_data,
  duration_access_country_bar_trend_data,
  duration_access_platform_bar_trend_data,
  duration_access_cp_bar_trend_data
 } from './StatisticsTrendData';

function DurationAccessBarTrend(props) {
  const { height, title, originalFilter, currentFilter, updateTable, size } = props;
  const [trendData, setTrendData] = useState({data: {}, options: {}});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [isCompareDisplay, setCompareDisplay] = useState(false);
  const plugin = [annotationPlugin];


  useEffect(() => {
    if (currentFilter.date) {
      const radioFilter = currentFilter.customRadioFilter;
      const checkboxFilter = currentFilter.customCheckboxFilter;
      const tempProvider = currentFilter.providers ? currentFilter.providers : [];
      const tempCountry = currentFilter.country ? currentFilter.country : [];
      const tempPlatforms = currentFilter.platforms ? currentFilter.platforms
      .map(map => map.replace('webOS', 'webOSTV').replace('webOSTV 22', 'webOSTV 7.0')) : [];
      const compareType = currentFilter.compareType;
      getDataTrend({startDate: currentFilter.date[0], endDate: currentFilter.date[1], countryCodeList: tempCountry,
        platformList: tempPlatforms, cpIdList: tempProvider, indicator: radioFilter ? radioFilter[0].api : 'duration',
        dataType: checkboxFilter && checkboxFilter[0].type.length === 1 ? checkboxFilter[0].type.toString() : 'All',
        asc: false, compareType: compareType})
    }
  }, [currentFilter, size]);


  const hexToRgba = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  function getColor(compareType, item) {
    if (compareType === 'Country') {
      return CHART_COLORS_BY_COUNTRY[item] || CHART_COLORS_BY_COUNTRY['OTHERS'];
    } else if (compareType === 'Platform') {
      const convertItem = item.replace('webOSTV', 'webOS').replace('webOS 7.0','webOS 22');
      return CHART_COLORS_BY_PLATFORM[convertItem] || CHART_COLORS_BY_PLATFORM['OTHERS'];
    } else {
      return CHART_COLORS_BY_PROVIDER[item] || CHART_COLORS_BY_PROVIDER['OTHERS'];
    }
  }

  const processData = (data) => {
    const groupedData = data.reduce((acc, cur) => {
      if (!acc[cur.item]) {
        acc[cur.item] = [];
      }
      acc[cur.item].push(cur);
      return acc;
    }, {});
    return groupedData;
  };

  const getDataTrend = (bodyFilter) => {
    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/trend/rank`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(bodyFilter),
    })
      .then(res => res.json())
      .then(body => {
        const compareData = {
          labels: [],
          datasets: [],
        };
        const compareOptions = {
          maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: false,
              },
            },
            indexAxis: 'x',
            responsive: true,
            scales: {
              x: {
                ticks: {
                  callback: function(value, index, values) {
                    const label = this.getLabelForValue(value);
                    if (compareData.labels[index]) {
                      if (index > 0 && (compareData.labels[index - 1]).split('_')[0] ===
                        (compareData.labels[index]).split('_')[0]) {
                        return '';
                      }
                      return (compareData.labels[index]).split('_')[0];
                    } else {
                      return label;
                    }
                  }
                }
              },
              y: {
                ticks: {
                  stepSize: 2,
                },
                border: {
                  display: false,
                },
              },
            },
        };
        const compareType = bodyFilter.compareType;
        if(compareType && compareType != 'None'){
          let contentBody = body.map(map => ({ contentName: map.contentName,
            country: map.countryCode, platform: map.platform, cpId: map.cpId,
            nativeValue: map.nativeValue }));

            const compareList = [... new Set(contentBody.map(item =>
              compareType === 'Country' ? item.country :
              compareType === 'Platform' ? item.platform : item.cpId))];

            let displayTypeData = [];
            compareList.forEach((compareItem) => {
              const findContent = contentBody.filter(item => (compareType === 'Country' ? item.country :
              compareType === 'Platform' ? item.platform : item.cpId) === compareItem).map(item => ({
                contentName: item.contentName,
                item: compareType === 'Country' ? item.country :
                compareType === 'Platform' ? item.platform : item.cpId,
                value: bodyFilter.indicator === 'duration' ? Math.floor((item.nativeValue/60)/60) :
                Math.floor(item.nativeValue)
              }));
              displayTypeData.push(...findContent);
            });

            const groupedData = processData(displayTypeData);
            const labels = Object.keys(groupedData).flatMap(item => groupedData[item].map(cur => `${cur.item}_${cur.contentName}`));
            const datasets = Object.keys(groupedData).map(curItem => ({
              label: curItem,
              data: labels.map(label => {
                const [labelCountry, labelId] = label.split('_');
                if (labelCountry === curItem) {
                  const item = groupedData[curItem].find(item => item.contentName === labelId);
                  return item ? item.value : null;
                }
                return null;
              }),
              backgroundColor: hexToRgba(getColor(compareType, curItem), 1),
              borderColor: hexToRgba(getColor(compareType, curItem), 1),
              borderWidth: 1
            }));
            if(compareType && compareType === 'Country'){
              duration_access_country_bar_trend_data.rowdata = body.map(item => ({
                row: [
                  item.countryCode,
                  item.countOrder,
                  item.contentName,
                  bodyFilter.indicator === 'duration' ? Math.floor((item.nativeValue/60)/60) :
                  Math.floor(item.nativeValue)
                ],
              }));
              updateTable(duration_access_country_bar_trend_data);
            } else if(compareType && compareType === 'Platform'){
              duration_access_platform_bar_trend_data.rowdata = body.map(item => ({
                row: [
                  item.platform,
                  item.countOrder,
                  item.contentName,
                  bodyFilter.indicator === 'duration' ? Math.floor((item.nativeValue/60)/60) :
                  Math.floor(item.nativeValue)
                ],
              }));
              updateTable(duration_access_platform_bar_trend_data);
            } else if(compareType && compareType === 'CP'){
              duration_access_cp_bar_trend_data.rowdata = body.map(item => ({
                row: [
                  item.cpId,
                  item.countOrder,
                  item.contentName,
                  bodyFilter.indicator === 'duration' ? Math.floor((item.nativeValue/60)/60) :
                  Math.floor(item.nativeValue)
                ],
              }));
              updateTable(duration_access_cp_bar_trend_data);
            }
            compareData.labels = labels;
            compareData.datasets = datasets;
            setTrendData({data: compareData, options: compareOptions});
            setCompareDisplay(true);
        } else {
          let valueList = [];
          let labelList = [];
          if (body && body.length > 0) {
            valueList = body.map(map => bodyFilter.indicator === 'duration' ?
            Math.floor((map.nativeValue/60)/60) : Math.floor(map.nativeValue));
            labelList = body.map(item => item.contentName);
          } else {
            for ( let i = 0; i < 10; i++) {
              valueList.push(0.0);
              labelList.push('No data');
            }
          }
          const data = {
            labels: labelList,
            datasets: [
              {
                label: bodyFilter.indicator === 'duration' ? 'Duration Top 10 (hr)' : 'Access Top 10',
                fill: false,
                pointRadius: 3,
                backgroundColor: bodyFilter.indicator === 'duration' ? `${CHART_COLORS.BLUE}` : `${CHART_COLORS.PINK}`,
                borderColor: bodyFilter.indicator === 'duration' ? `${CHART_COLORS.BLUE}` : `${CHART_COLORS.PINK}`,
                pointBorderColor: '#FFFFFF',
                pointBackgroundColor: bodyFilter.indicator === 'duration' ? `${CHART_COLORS.BLUE}` : `${CHART_COLORS.PINK}`,
                pointBorderWidth: 2,
                order: 2,
                data: valueList,
              }
            ]
          };
          duration_access_bar_trend_data.rowdata = body.map(item => ({
            row: [
              item.countOrder,
              item.contentName,
              bodyFilter.indicator === 'duration' ? Math.floor((item.nativeValue/60)/60) :
              Math.floor(item.nativeValue)
            ],
          }));
          updateTable(duration_access_bar_trend_data);
          setTrendData({data: data, options: compareOptions});
          setCompareDisplay(true);
        }
      })
      .catch(error => {
        setAlertMessage(`trend ${bodyFilter.indicator} catch: `, error);
        onOpenAlert();
      })
      .finally(() => {});
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
    <div style={{ height: height }}>
      <div className='trend-head'>
      <div className="title" style={{top: "-30px"}}>
          <div> {title} </div>
        </div>
      </div>
      { isCompareDisplay ? (
        <Bar data={trendData.data} options={trendData.options} height='100%' />
        ) : (
          <></>
        )
      }
    </div>
    {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default DurationAccessBarTrend;