import React from 'react';
import { FormControl, Select, MenuItem, TextField, Button, InputLabel } from '@material-ui/core';

const MultiLangEdit = (props) => {
  const { editLangList
    , setEditLangList
    , setEditLang
    , allLangList
  } = props;

  return editLangList.map((n, i) => (
    <div key={`${n.langCode}${i}`} style={{ marginBottom: '5px' }}>
      <div className="edit_mulyi_lang_row" style={{ display: 'flex' }}>
        <FormControl
          variant="outlined"
          style={{
            marginRight: '5px',
            marginTop: '3px',
            width: '18%',
          }}
        >
          <InputLabel>Language</InputLabel>
          <Select
            value={n.langCode}
            onChange={e => {
              setEditLang(i, 'langCode', e.target.value);
            }}>
            {allLangList.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          variant="outlined"
          value={n.name}
          style={{
            marginRight: '10px',
            marginTop: '3px',
            width: '50%',
          }}
          onChange={e => {
            setEditLang(i, 'name', e.target.value);
          }}
        />
        <Button
          variant="contained"
          className="btn_color3"
          style={{
            maxHeight: '37px',
            marginTop: '3px',
            width: '16%',
          }}
          onClick={() => {
            setEditLangList(editLangList.filter((n2, i2) => i2 !== i));
          }}>
          delete
        </Button>
        <Button
          variant="contained"
          className="btn_color3"
          disabled={editLangList.length >= allLangList.length}
          style={{
            maxHeight: '37px',
            marginTop: '3px',
            width: '16%',
          }}
          onClick={() => {
            setEditLangList(editLangList.concat({ langCode: '', name: '' }));
          }}>
          add
        </Button>
      </div>
    </div>
  ));
}

export default MultiLangEdit;