import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchFilter from './SearchFilter';
import ShowChannelList from './ShowChannelList';
import ShowContentList from './ShowContentList';
import ShowCategoryName from './ShowCategoryName';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import AlertDialog from '../../../common/dialog/AlertDialog';
import LoadingIndicator from '../../../common/LoadingIndicator';

const InteractiveDock = ({ username }) => {
  const [channelList, setChannelList] = useState([]);
  const [country, setCountry] = useState('');
  const [providerList, setProviderList] = useState([]);
  const [status, setStatus] = useState('');
  const [isDisplayChannel, setIsDisplayChannel] = useState(false);  // Show Channel List
  const [isDisplayContent, setIsDisplayContent] = useState(false);  // Show Content List
  const [savedRelatedContent, setSavedRelatedContent] = useState([]);
  const [currentChannelId, setCurrentChannelId] = useState('');
  const [changedChannelList, setChangedChannelList] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [displayWarningList, setDisplayWarningList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [initPublishingFlag, setInitPulishingFlag] = useState('');
  const [savedSearchFilter, setSavedSearchFilter] = useState({});
  const [isChannelListChanged, setIsChannelListChanged] = useState(false);
  const [langList, setLangList] = useState([]);
  const [relatedContentCategory, setRelatedContentCategory] = useState([]);
  const [isDisplayCategoryTitle, setIsDisplayCategoryTitle] = useState(false);  // Show Category Title

  useEffect(() => {
    setInitPulishingFlag('');
  }, [initPublishingFlag]);

  const handleSearchFilter = (result) => {
    setSavedSearchFilter(result);
    getChannelList(result);
  };

  const getChannelList = (result) => {
    handleDisplayInit();

    const param = `country=${result.country}&providerName=${result.providerName}&relatedFlag=${result.relatedFlag}&mappingRule=${result.mappingRule}&genreName=${encodeURIComponent(result.genreName)}&genreType=${result.genreType}&status=${result.status}`;

    setIsFetching(true);
    fetch(`${API_BASE_URL}/admin/livetv/channel-list?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          // 중복되는 channel id를 제거하기 위해서
          const modifyChannelList = body.channelList.reduce((acc, cur) => {
            if (!acc.find(obj => obj.channelId === cur.channelId)) {
              acc.push(cur);
            }
            return acc;
          }, []);

          setChannelList([...modifyChannelList]);
        } else {
          setAlertDescription('channel-list request fail');
          setIsAlert(true);
        }
      })
      .catch(error => {
        setAlertDescription('channel-list request error: ', error);
        setIsAlert(true);
      }).finally(() => {
        setIsDisplayChannel(true);
        setIsFetching(false);
      });
  };

  const handleSelectedChannel = (channelId) => {
    setCurrentChannelId(channelId);

    let currentStatus = '';
    if (status === 'Testing') {
      currentStatus = 'T';
    } else if (status === 'Published') {
      currentStatus = 'P';
    } else if (status === 'SavedTesting') {
      currentStatus = 'ST';
    } else {
      currentStatus = 'SP';
    }

    const param = `country=${country}&channelId=${channelId}&status=${currentStatus}`;
    setIsFetching(true);
    setIsDisplayContent(false);

    fetch(`${API_BASE_URL}/admin/livetv/saved-related-content?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          setSavedRelatedContent(body.savedRelatedContentList);
        } else {
          setAlertDescription('saved-related-content request fail');
          setIsAlert(true);
        }
      })
      .catch(error => {
        setAlertDescription('saved-related-content request error: ', error);
        setIsAlert(true);
      }).finally(() => {
        setIsFetching(false);
        setIsDisplayContent(true);
      });
  };

  const handleChangeChannelList = (result) => {
    setChangedChannelList(result);
  };

  const handleSavedRelatedContent = (result) => {
    const relatedContentLists = result.relatedContent && result.relatedContent.map(item => {
      return {
        contentOrder: item.contentOrder,
        contentType: item.contentType,
        contentId: item.contentId,
      }
    });

    const channelLists = changedChannelList.map(item => {
      if (result.channelId === 'allAuto' || result.channelId === 'allManual') {
        return {
          ...item,
          relatedRule: item.relatedRule === 'cp' || item.relatedRule === 'block' ? item.relatedRule :
            result.channelId === 'allAuto' ? 'auto' : 'manual',
          relatedContentCategoryType: item.relatedRule === 'cp' ?
            (item.channelId.toLowerCase().includes('shoptime') ? 'cp_shoptime' : 'cp_normal') :
            item.relatedRule === 'block' ? 'none' :
            result.channelId === 'allManual' ? 'manual' : 'auto',
          relatedContentLists: item.relatedRule === 'cp'
            || item.relatedRule === 'block'
            || result.channelId === 'allAuto' ? [] : relatedContentLists,
        }
      } else {
        if (item.channelId === result.channelId) {
          return {
            ...item,
            relatedContentCategoryType: item.relatedRule === 'cp' ?
              (item.channelId.toLowerCase().includes('shoptime') ? 'cp_shoptime' : 'cp_normal') :
              item.relatedRule === 'block' ? 'none' :
              item.relatedRule === 'manual' ? 'manual' : 'auto',
            relatedContentLists: relatedContentLists
          }
        } else {
          return {
            ...item,
            relatedContentCategoryType: item.relatedRule === 'cp' ?
              (item.channelId.toLowerCase().includes('shoptime') ? 'cp_shoptime' : 'cp_normal') :
              item.relatedRule === 'block' ? 'none' :
              item.relatedRule === 'manual' ? 'manual' : 'auto',
          }
        }
      }
    });

    const requestData = {
      user: username,
      country: country,
      status: status === 'Testing' || status === 'SavedTesting' ? 'T' : 'P',
      saveChannelId: result.channelId === 'allAuto' || result.channelId === 'allManual' ? 'all' : result.channelId,
      channelLists: channelLists
    };

    setIsFetching(true);
    fetch(`${API_BASE_URL}/admin/livetv/saved-related-content`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(requestData)
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          if (status === 'Testing') {
            setInitPulishingFlag('SavedTesting');
          } else if (status === 'Published') {
            setInitPulishingFlag('SavedPublished');
          }

          const param = {
            country: savedSearchFilter.country,
            providerName: savedSearchFilter.providerName,
            relatedFlag: savedSearchFilter.relatedFlag,
            mappingRule: savedSearchFilter.mappingRule,
            genreName: savedSearchFilter.genreName,
            genreType: savedSearchFilter.genreType,
            status: savedSearchFilter.status === 'T' || savedSearchFilter.status === 'ST' ? 'ST' : 'SP',
          };

          getChannelList(param);
        } else {
          setAlertDescription('saved-related-content post request fail');
          setIsAlert(true);
          setIsFetching(false);
        }
      })
      .catch(error => {
        setAlertDescription('saved-related-content post request error: ', error);
        setIsAlert(true);
        setIsFetching(false);
      }).finally(() => { });
  };

  const handleDeploy = () => {
    const tempStatus = status === 'Testing' || status === 'SavedTesting' ? 'T' : 'P';
    const param = `country=${country}&status=${tempStatus}&user=${username}`

    setIsFetching(true);
    fetch(`${API_BASE_URL}/admin/livetv/publish-related-content?${param}`, {
      method: 'POST',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          if (status === 'SavedTesting') {
            setInitPulishingFlag('Testing');
          } else if (status === 'SavedPublished') {
            setInitPulishingFlag('Published');
          }

          const param = {
            country: savedSearchFilter.country,
            providerName: savedSearchFilter.providerName,
            relatedFlag: savedSearchFilter.relatedFlag,
            mappingRule: savedSearchFilter.mappingRule,
            genreName: savedSearchFilter.genreName,
            genreType: savedSearchFilter.genreType,
            status: savedSearchFilter.status === 'T' || savedSearchFilter.status === 'ST' ? 'T' : 'P',
          };

          getChannelList(param);
        } else {
          setAlertDescription('testing-related-content post request fail');
          setIsAlert(true);
          setIsFetching(false);
        }
      })
      .catch(error => {
        setAlertDescription('testing-related-content post request error: ', error);
        setIsAlert(true);
        setIsFetching(false);
      }).finally(() => { });
  };

  const handleCategoryName = () => {
    const param = `country=${country}`;

    setIsDisplayCategoryTitle(false);
    fetch(`${API_BASE_URL}/admin/livetv/related-content-category?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          setRelatedContentCategory(body.relatedContentCategory);
          setIsDisplayCategoryTitle(true);
        } else {
          setAlertDescription(`[LiveTV] related-content-category request fail`);
          setIsAlert(true);
        }
      })
      .catch(error => {
        setAlertDescription(`[LiveTV] related-content-category request error: `, error);
        setIsAlert(true);
      }).finally(() => { });
  };

  const handleDisplayInit = () => {
    setIsDisplayChannel(false);
    setIsDisplayContent(false);
    setIsChannelListChanged(false);
    setIsDisplayCategoryTitle(false);
  };

  return (
    <React.Fragment>
      <SearchFilter
        callbackSearchFilter={handleSearchFilter}
        callbackSelectedCountry={setCountry}
        callbackSelectedProviders={setProviderList}
        callbackSelectedStatus={setStatus}
        initPublishingFlag={initPublishingFlag}
        callbackLangList={setLangList}
        callbackSearchFilterChange={handleDisplayInit}
      />
      <ShowChannelList
        isDisplayChannel={isDisplayChannel}
        channelList={channelList}
        callbackSelectedChannel={handleSelectedChannel}
        callbackChangeChannelList={handleChangeChannelList}
        callbackChangeChannelRule={setIsChannelListChanged}
      />
      <ShowContentList
        isDisplayContent={isDisplayContent}
        isDisplayChannel={isDisplayChannel}
        country={country}
        providerList={providerList}
        status={status}
        savedRelatedContent={savedRelatedContent}
        callbackSavedRelatedContent={handleSavedRelatedContent}
        currentChannelId={currentChannelId}
        isChannelListChanged={isChannelListChanged}
        callbackDeploy={handleDeploy}
        callbackCategoryTitle={handleCategoryName}
      />
      <ShowCategoryName
        isDisplayCategoryTitle={isDisplayCategoryTitle}
        langList={langList}
        relatedContentCategory={relatedContentCategory}
        country={country}
        callbackClose={() => setIsDisplayCategoryTitle(false)}
      />
      <AlertDialog
        openAlert={isAlert}
        description={alertDescription}
        warningList={displayWarningList}
        closeHandler={() => { setIsAlert(false); }}
      />
      {isFetching && (
        <div className="loading_wrap" style={{ position: 'absolute', top: 300, left: 0, right: 0, zIndex: 999 }}>
          <LoadingIndicator />
        </div>
      )}
    </React.Fragment>
  );
}

InteractiveDock.propTypes = {
  username: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  username: state.currentUser.username,
});

export default connect(mapStateToProps)(InteractiveDock);