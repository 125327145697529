import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  card: {
    backgroundColor: 'white',
    color: 'black',
    padding: '20px',
    margin: '50px',
    border: '1px solid gray',
    borderRadius: '5px',
    boxShadow: '10px 10px 10px gray',
  }
});

const SimpleCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography gutterBottom variant="h1" component="h1" align="center">
          INFO
        </Typography>
        <Typography variant="h2" color="textSecondary" component="h2" align="center">
          Statistics aggregation has not yet completed
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SimpleCard;
