import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Typography } from '@material-ui/core';
import {FormControl,InputLabel,Select,MenuItem} from '@material-ui/core';
import { getHomeAppGenreAction } from '../../actions/homeAppActions';

function Genre({ getHomeAppGenreAction, genres }) {

  React.useEffect(() => {
    getHomeAppGenreAction();
  }, []);
  const prefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;

  return (
    <div>
      <React.Fragment>
        <div className={'tbl_wrap'}>
          <MaterialTable
            title=" " //없으면 Table Title이 default title로 나옴
            columns={[
              {
                title: 'Genre Image',
                field: 'genreImage',
                render: n => {
                  let img = prefix + n.genreImage;
                  return <img alt="invalid" src={img} title={img} style={{ width: 70, backgroundColor: 'black' }} />;
                },
              },
              {
                title: 'Genre Name',
                field: 'genreName',
                render: n => {
                  return (
                    <Link className="link" to={`/HOME-APP/genre/genreDetail/${n.genreCode}`}>
                      {n.genreName}
                    </Link>
                  );
                },
              },
              {
                title: 'Genre Code',
                field: 'genreCode',
              },
              {
                title: 'Last Change Date',
                field: 'lastChgDate',
              },
            ]}
            data={genres}
            options={{
              paging: false,
              search: false,
              rowStyle: { backgroundColor: '#fff' },
            }}
          />
        </div>
      </React.Fragment>
    </div>
  );
}
Genre.propTypes = {
  genres: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  genres: state.homeApp.genres,
});
const mapDispatchToProps = dispatch => ({
  getHomeAppGenreAction() {
    dispatch(getHomeAppGenreAction());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Genre));