import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export function BizAreaFilter({ biz, handleChangeBiz, cntryCpResponse }) {
  const list = cntryCpResponse.map(item =>
    item.biz ? (
      <MenuItem key={item.biz} value={item.biz}>
        {item.biz}
      </MenuItem>
    ) : null,
  );
  //console.log('list', list, 'cntryCpResponse', cntryCpResponse);

  return (
    <div className="business_area">
      <span className="business_area_tit">Business Area</span>
      <FormControl variant="outlined">
        <Select value={biz} onChange={handleChangeBiz}>
          {list}
        </Select>
      </FormControl>
    </div>
  );
}

BizAreaFilter.propTypes = {
  cntryCpResponse: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  cntryCpResponse: state.cntry.countries,
});
// const mapDispatchToProps = (dispatch) => ({
//   getProvidersAdminAction() {
//     dispatch(getProvidersAdminAction());
//   },
// });
export default connect(mapStateToProps)(BizAreaFilter);
