import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function programs(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PROGRAMS:
      if (action.payload.status) return [];
      else if (action.payload) return action.payload;
      else return [];
    default:
      return []; //return state; {program: Array(0)}???
  }
}
