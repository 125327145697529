import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
//import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { setDrawerOpenAction } from '../../../actions/uiActions';
import ChangePw from '../../account/ChangePw';
import { getCurrentUserAction } from '../../../actions/userActions';
import ChangeApikey from './ChangeApikey';

const styles = () => ({
  root: {
    maxWidth: 600,
    margin: '0 auto',
    marginTop: 40,
  },
  // paper: {
  //   marginTop: theme.spacing(8),
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   padding: theme.spacing(2, 3, 3),
  // },
  // heroUnit: {
  //   backgroundColor: theme.palette.background.paper,
  // },
  // heroContent: {
  //   maxWidth: 600,
  //   margin: '0 auto',
  //   padding: theme.spacing(8, 6),
  // },
  // link: {
  //   textDecoration: 'none',
  //   color: 'inherit',
  // },
});

function Profile({ classes, username, isRoleAdmin, apikey, apikeyId, setDrawerOpenAction, getCurrentUserAction }) {
  useEffect(() => {
    setDrawerOpenAction(false);

    return () => {
      //console.log('[profile]cleanup');
      setDrawerOpenAction(true);
    };
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer component={Paper} className={'tbl_wrap'}>
          <Table className={'tbl_row_typ1'} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell /*style={{ width: '20%' }}*/>ID</TableCell>
                <td /*style={{ width: '35%' }}*/>{username}</td>
              </TableRow>
              <TableRow>
                <TableCell /*style={{ width: '15%' }}*/>Password</TableCell>
                <td /*style={{ width: '30%' }}*/>
                  <ChangePw reloadAction={getCurrentUserAction} item={{ ID: username }} />
                </td>
              </TableRow>
              {!isRoleAdmin && (
                <TableRow>
                  <TableCell>API Key</TableCell>
                  <td>
                    {apikey ? apikey : 'No API Key'}&nbsp;
                    <ChangeApikey reloadAction={getCurrentUserAction} apikeyId={apikeyId} />
                  </td>
                </TableRow>
              )}
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  isRoleAdmin: PropTypes.bool.isRequired,
  apikey: PropTypes.string,
  apikeyId: PropTypes.number,
  setDrawerOpenAction: PropTypes.func.isRequired,
  getCurrentUserAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  username: state.currentUser.username,
  isRoleAdmin: state.currentUser.isRoleAdmin,
  apikey: state.currentUser.apikey,
  apikeyId: state.currentUser.apikeyId,
});

const mapDispatchToProps = dispatch => ({
  setDrawerOpenAction(value) {
    dispatch(setDrawerOpenAction(value));
  },
  getCurrentUserAction() {
    dispatch(getCurrentUserAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile));
//export default withStyles(styles)(Profile);
