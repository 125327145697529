import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { convertToGraphData } from './SimpleLineContainer';

function TableChartDialog({ open, onClose, data }) {
  const [indicator, setIndicator] = React.useState(data.data[0].item);
  const lineData = convertToGraphData(data, indicator);

  return (
    <div>
      <Dialog open={open} className={'pop_wrap'} /*onClose={handleClose} aria-labelledby="form-dialog-title"*/>
        <DialogTitle className="pop_head" id="form-dialog-title">
          Channel Detail
          <IconButton
            aria-label="close" //없으면 closeIcon위치가 오른쪽이 아니라 제목바로 옆,색깔도 검은색
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={'pop_body'}>
          <div className="filter_area">
            <FormControl variant="outlined">
              <InputLabel>Indicator</InputLabel>
              <Select
                value={indicator}
                onChange={e => {
                  setIndicator(e.target.value);
                }}>
                {data.data.map(n => (
                  <MenuItem key={n.item} value={n.item}>
                    {n.item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* CP: All, Country: US */}
          </div>
          <div className={'btn_set_wrap'}>
            <LineChart
              width={1000}
              height={320}
              data={lineData.list.reverse()}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis allowDataOverflow={false} domain={[0, lineData.max]} yAxisId="left" />
              <Tooltip formatter={n => new Intl.NumberFormat('en').format(n)} />
              <Legend />
              <Line type="monotone" yAxisId="left" dataKey={indicator} stroke="#82ca9d" />
            </LineChart>
            <Button
              data-testid="addButton"
              //type="cancel"//error
              color="primary"
              onClick={onClose}
              className={'btn_color1'}>
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

TableChartDialog.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  data: state.statistic.data,
});

export default connect(mapStateToProps)(TableChartDialog);
