import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function countries(state = initialState, action) {
  switch (action.type) {
    case actions.GET_COUNTRY:
      return action.payload.length ? action.payload : state;
    default:
      return state;
  }
}
