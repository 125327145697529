import React, { useEffect, useState } from 'react';
import DatePickerDefault from '../../common/DatePickerDefault';
import DefaultButton from '../../common/DefaultButton';
import IssTable from '../../common/IssTable';
import {
  AdDashboardData,
  impressionCountDataset,
  impressionSuccessRateDataset,
  requestCountDataset
} from './AdDashboardData';
import { CHART_COLORS } from '../../common/ChartConsts';
import LineChart from '../../common/LineChart';
import { ISS_BASE_URL } from '../../../../constants';
import { getHeaders } from '../../../../util/actionUtil';
import DropDown from '../../common/DropDown';
import DetailIconButton from '../StatisticsDashboard/DetailIconButton';
import { useHistory } from 'react-router-dom';
import Alert from "../../modal/Alert";
import TutorialPage from '../../TutorialPage';

const AdDashboard = ({ filterCallback }) => {
  const DATE_RANGE = 14;
  const [filterDates, setFilterDates] = useState([]);
  const [adIssues, setAdIssues] = useState(AdDashboardData.dashboardTable);
  const [dropdownFilters, setDropdownFilters] = useState({
    appName: { dropdownList: [] },
    bannerType: { dropdownList: [] },
    countryCode: { dropdownList: [] },
    inventoryName: { dropdownList: [] },
    platform: { dropdownList: [] },
    adType: { dropdownList: [] },
  });
  const [paramPlatforms, setParamPlatforms] = useState([]);
  const [paramCountries, setParamCountries] = useState([]);
  const [paramAppNames, setParamAppNames] = useState([]);
  const [paramInventoryNames, setParamInventoryNames] = useState([]);
  const [paramBannerTypes, setParamBannerTypes] = useState([]);
  const [paramAdTypes, setParamAdTypes] = useState([]);
  const history = useHistory();
  const [countChartData, setCountChartData] = useState(AdDashboardData.dashboardGraph.requestImpressionCount.data)
  const [rateChartData, setRateChartData] = useState(AdDashboardData.dashboardGraph.impressionSuccessRate.data)
  const countChartOptions = AdDashboardData.dashboardGraph.requestImpressionCount.options;
  const rateChartOptions = AdDashboardData.dashboardGraph.impressionSuccessRate.options
  const [currentFilter, setCurrentFilter] = useState({
    startDate: '',
    endDate: '',
    country: paramCountries,
    platform: paramPlatforms,
    appName: paramAppNames,
    inventoryName: paramInventoryNames,
    bannerType: paramBannerTypes,
    adType: paramAdTypes,
  });
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('')
  const [alertMessage, setAlertMessage] = useState('');
  const [pageRendered, setPageRendered] = useState(false)
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    getSearchFilter();
    searchIssue();
    setPageRendered(true)
  }, []);

  useEffect(() => {
    if (adIssues.rowdata.length > 0) {
      onClickRow(adIssues.rowdata[0]);
    }
  }, [adIssues]);

  useEffect(() => {
    filterCallback(currentFilter)
  }, [currentFilter]);

  const defaultRangeDate = () => {
    const startDate = new Date()
    startDate.setDate(startDate.getDate() - DATE_RANGE)
    return [startDate.toISOString().substring(0, 10), new Date().toISOString().substring(0, 10)]
  }

  const yyyymmddAddDash = (date) => {
    return date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8)
  }

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const checkEmptyParameter = () => {
    let emptyParam = ''
    if (paramBannerTypes.length === 0) emptyParam = 'Banner Type'
    if (paramInventoryNames.length === 0) emptyParam = 'Inventory Name'
    if (paramAppNames.length === 0) emptyParam = 'App Name'
    if (paramPlatforms.length === 0) emptyParam = 'Platform'
    if (paramCountries.length === 0) emptyParam = 'Country'
    if (paramAdTypes.length === 0) emptyParam = 'Ad Type'
    if (emptyParam !== '') {
      setAlertTitle('Warning')
      setAlertMessage(`Please select any ${emptyParam}`)
      onOpenAlert()
      return false
    }
    return true
  }

  const searchIssue = () => {
    if (pageRendered && !checkEmptyParameter()) {
      return
    }

    const startDate = filterDates.length > 0 ? filterDates[0] : defaultRangeDate()[0]
    const endDate = filterDates.length > 0 ? filterDates[1] : defaultRangeDate()[1]
    const params = {
      startDate: startDate.replaceAll('-', ''),
      endDate: endDate.replaceAll('-', ''),
      country: paramCountries,
      platform: paramPlatforms,
      appName: paramAppNames,
      inventoryName: paramInventoryNames,
      bannerType: paramBannerTypes,
      adType: paramAdTypes,
    };
    setCurrentFilter(params)
    const requestUrl = `${ISS_BASE_URL}/api/ad/dashboard/issues?${new URLSearchParams(params)}`;

    fetch(requestUrl, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          const data = body.data
          adIssues.rowdata = data.map(issue => ({
            row: [yyyymmddAddDash(issue.date), issue.issueId, issue.adType, issue.platform, issue.appName, issue.inventoryName,
              issue.bannerType, issue.impCnt, issue.reqCnt, issue.renderRate + '%', issue.changeRate + '%',
              <DetailIconButton type={'button'} text={'Detail'} btnClass={'btn-icon-detail'} clickEvent={onClickDetail}
                                paramFilter={params} category={'ad'} issueId={issue.issueId} rowData={body.data} />],
            date: issue.date,
            countryCode: issue.countryCode,
            platform: issue.platform,
            appName: issue.appName,
            inventoryName: issue.inventoryName,
            bannerType: issue.bannerType,
            adType: issue.adType,
            issueId: issue.issueId,
          }));
          setAdIssues({ ...adIssues, rowdata: [...adIssues.rowdata] });
        } else {
          setAlertTitle('Error')
          setAlertMessage('Failed to get issues')
        }
      });
  };

  const onClickDetail = (selectedData) => {
    history.push(`/ISS/AdDetail`, {
      param: JSON.stringify(selectedData),
    });
  };

  const getSearchFilter = () => {
    fetch(`${ISS_BASE_URL}/api/ad/dashboard/filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          const data = body.data
          setDropdownFilters({
            appName: { dropdownList: data.appName },
            bannerType: { dropdownList: data.bannerType },
            countryCode: { dropdownList: data.countryCode },
            inventoryName: { dropdownList: data.inventoryName },
            platform: { dropdownList: data.platform.map(p => p.replace('webOSTV 7.0', 'webOSTV 22')) },
            adType: { dropdownList: data.adType },
          });
        } else {
          setAlertTitle('Error')
          setAlertMessage('Failed to get filter')
        }
      });
  };

  const onClickRow = item => {
    const params = {
      date: item.date,
      countryCode: item.countryCode,
      platform: item.platform,
      appName: item.appName,
      inventoryName: item.inventoryName,
      bannerType: item.bannerType,
      adType: item.adType,
      searchCount: 15,
      selectedCountries: paramCountries,
      issueId: item.issueId,
    };
    const requestUrl = `${ISS_BASE_URL}/api/ad/dashboard/chart?${new URLSearchParams(params)}`;

    fetch(requestUrl, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          const data = body.data
          let maxRenderRate = parseFloat(data[0].maxRenderRate);
          let minRenderRate = parseFloat(data[0].minRenderRate);

          countChartData.labels = data.map(item => item.date.substring(4,6)+'-'+item.date.substring(6));
          rateChartData.labels = data.map(item => item.date.substring(4,6)+'-'+item.date.substring(6));
          requestCountDataset.data = data.map(item => item.reqCnt);
          impressionCountDataset.data = data.map(item => item.impCnt);
          countChartOptions.scales.y.max = Math.max(...requestCountDataset.data, ...impressionCountDataset.data);
          impressionSuccessRateDataset.data = data.map(item => item.renderRate)
          rateChartOptions.baselineSubMax.value = parseFloat(maxRenderRate);
          rateChartOptions.baselineSubMin.value = parseFloat(minRenderRate);

          setCountChartData({ ...countChartData, datasets: [...countChartData.datasets] });
          setRateChartData({ ...rateChartData, datasets: [...rateChartData.datasets] });
        } else {
          setAlertTitle('Error')
          setAlertMessage('Failed to get chart data')
        }
      });
  };

  const handleOpen = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <>
      <div className="head ad-filter">
        <div className="title">
          <button className="btn-tutorial-2" onClick={handleOpen}/>
          <h1>Advertisement</h1>
        </div>
      </div>
      <div className="head ad-filter">
        <div className="ui-dropdown-box">
          <DatePickerDefault rangeDays={DATE_RANGE} dateChangeHandler={newDate => setFilterDates(newDate)} />
          <DropDown title="Countries" item={dropdownFilters.countryCode} onChangeDropDown={setParamCountries} scroll={true} uiClassName={'ui-dropdown-type08'}/>
          <DropDown title="Platforms" item={dropdownFilters.platform} onChangeDropDown={setParamPlatforms} />
          <DropDown title="App Name" item={dropdownFilters.appName} onChangeDropDown={setParamAppNames} />
          <DropDown title="Inventory Name" item={dropdownFilters.inventoryName} onChangeDropDown={setParamInventoryNames} />
          <DropDown title="Banner Type" item={dropdownFilters.bannerType} onChangeDropDown={setParamBannerTypes} />
          <DropDown title="Ad Type" item={dropdownFilters.adType} onChangeDropDown={setParamAdTypes} />
          <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={searchIssue} />
        </div>
      </div>
      <div className="grid-row-2">
        <div className="grid-single-row">
          <div className="box-adIssue">
            <IssTable pagination={true} data={adIssues}
                      handleClickItem={item => onClickRow(item)}
                      sortingItem={{enable: true, sortColumn: 1, sortDirection: 'desc'}}
                      selectFirstRow={true}
            />
          </div>
        </div>
          <div className="grid-column-2">
            <div className="grid-left box-value">
              <div className="head">
                <div className="title">
                  <h2>Ad Request / Impression Count</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }} />
                      <span>Request Count</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PURPLE}` }} />
                      <span>Impression Count</span>
                    </li>
                  </ul>
                </div>
                <LineChart data={countChartData} options={countChartOptions} />
              </div>
            </div>
            <div className="grid-right box-value">
              <div className="head">
                <div className="title">
                  <h2>Render Rate</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }} />
                      <span>Rate</span>
                    </li>
                  </ul>
                </div>
                <LineChart data={rateChartData} options={rateChartOptions} />
              </div>
            </div>
          </div>
      </div>
      {isAlert && <Alert isOpen={isAlert} onClose={onCloseAlert} title={alertTitle} message={alertMessage}/>}
      {isActive && <TutorialPage data={'advertisementDashboard'} isActive={isActive} onClose={handleClose} />}
    </>
  );
};

export default AdDashboard;