import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { getABTestTabCategoryAction, resetABTestTabCategoryAction ,getSegmentDetails} from '../../../actions/homeAppActions';

function AddABTestFilter({
  countryCode,
  handleChangeCountry,
  regionList,
  regionPlatformList,
  handleChangeTab,
  handleChangeCategory,
  tabCode,
  categoryCode,
  abTestTabCategory,
  deviceType,
  handleChangeDeviceType,
  region,
  platformVersion,
  handleChangePlatformVersion,
  title,
  startDate,
  startTime,
  endDate,
  endTime,
  handleChangeTitle,
  handleChangeStartDate,
  handleChangeStartTime,
  handleChangeEndDate,
  handleChangeEndTime,
  isItEdit,
  getABTestTabCategoryAction,
  resetABTestTabCategoryAction,
  disableUpdate,
  abtestId
}) {
  const today = new Date();
  const yestreday = new Date();
  const nextMonthDate = new Date();
  yestreday.setDate(today.getDate() - 1);
  nextMonthDate.setMonth(today.getMonth() + 1);
  const [startTimeLimit, setStartTimeLimit] = React.useState('');
  const [isItEditSegment, setIsItEditSegment] = React.useState(false);
  const hourFomatList = [
    { value: 0, label: '00:00' },
    { value: 1, label: '1:00' },
    { value: 2, label: '2:00' },
    { value: 3, label: '3:00' },
    { value: 4, label: '4:00' },
    { value: 5, label: '5:00' },
    { value: 6, label: '6:00' },
    { value: 7, label: '7:00' },
    { value: 8, label: '8:00' },
    { value: 9, label: '9:00' },
    { value: 10, label: '10:00' },
    { value: 11, label: '11:00' },
    { value: 12, label: '12:00' },
    { value: 13, label: '13:00' },
    { value: 14, label: '14:00' },
    { value: 15, label: '15:00' },
    { value: 16, label: '16:00' },
    { value: 17, label: '17:00' },
    { value: 18, label: '18:00' },
    { value: 19, label: '19:00' },
    { value: 20, label: '20:00' },
    { value: 21, label: '21:00' },
    { value: 22, label: '22:00' },
    { value: 23, label: '23:00' },
  ];

  const sampleRateList = [
    { value: 0, label: '0%' },
    { value: 10, label: '10%' },
    { value: 20, label: '20%' },
    { value: 30, label: '30%' },
    { value: 40, label: '40%' },
    { value: 50, label: '50%' },
    { value: 60, label: '60%' },
    { value: 70, label: '70%' },
    { value: 80, label: '80%' },
    { value: 90, label: '90%' },
    { value: 100, label: '100%' },
  ];
  function startTimeChange(e) {
    handleChangeStartTime(e)
    setStartTimeLimit(e.target.value)
  }

React.useEffect(() => {
  getSegmentDetails(abtestId).then(response => {
    setIsItEditSegment(response.length > 0 ? true : false);
  });
}, [abtestId]);

  React.useEffect(() => {
    resetABTestTabCategoryAction()
    if (deviceType !== '' && countryCode !== '' && platformVersion !== '') {
      let regionCode;
      regionList.map(n2 => {
        n2.list.map(n3 => {
          if (n3.cntry.includes(countryCode)) {
            regionCode = n3.region;
          }
        });
      });
      getABTestTabCategoryAction(deviceType, regionCode, platformVersion);
    }

  }, [deviceType, countryCode, platformVersion]);

  return (
    <Fragment>
      <div className={'tbl_wrap'}>
        <table className={'tbl_row_typ1'}>
          <colgroup>
            <col style={{ width: '150px' }} />
            <col style={{ width: '300px' }} />
          </colgroup>
          <colgroup>
            <col style={{ width: '150px' }} />
            <col style={{ width: '300px' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <InputLabel> Title </InputLabel>
              </th>
              <td>
                <TextField id="abTestTitle" name="abTestTitle" placeholder="Title" variant="outlined" className=" adtest_add" value={title} onChange={handleChangeTitle} />
              </td>

              <th>
                <InputLabel> Start Date (UTC)</InputLabel>
              </th>
              <td>
                <TextField
                  className="adtest_add"
                  variant="outlined"
                  name="startDate"
                  id="startDate"
                  type="date"
                  value={startDate ? startDate : ''}
                  onChange={handleChangeStartDate}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  disabled={isItEdit}
                  inputProps={{ min: today.toISOString().slice(0, 10), max: nextMonthDate.toISOString().slice(0, 10) }}
                />
              </td>
            </tr>
            <tr>
              <th>
                <InputLabel>Start Time</InputLabel>
              </th>
              <td>
                <Select variant="outlined" className="adtest_add" id="startTime" name="startTime" value={startTime} onChange={e => startTimeChange(e)} disabled={isItEdit || !startDate} inputProps={{ min: yestreday.toISOString().slice(0, 10) }} required>
                  {hourFomatList.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </td>
              <th>
                <InputLabel> End Date (UTC)</InputLabel>
              </th>
              <td>
                <TextField
                  className="adtest_add"
                  variant="outlined"
                  name="endDate"
                  id="endDate"
                  type="date"
                  value={endDate ? endDate : ''}
                  onChange={handleChangeEndDate}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  disabled={disableUpdate}
                  inputProps={{ min: startDate }}
                  required
                />
              </td>
            </tr>
            <tr>
              <th>
                <InputLabel>End Time </InputLabel>
              </th>
              <td>
                <Select variant="outlined" className="adtest_add" id="endTime" name="endTime" value={endTime} onChange={handleChangeEndTime} disabled={disableUpdate || !endDate} required>
                  {hourFomatList.map(item => (
                    <MenuItem key={item.value} value={item.value} disabled={startDate === endDate && startTimeLimit >= item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </td>
              {abTestTabCategory && (
                <React.Fragment>
                  <th>
                    <InputLabel>Device Type</InputLabel>
                  </th>
                  <td>
                    <Select disabled={isItEdit} variant="outlined" value={deviceType} onChange={handleChangeDeviceType} className="adtest_add">
                      {regionList &&
                        regionList.map(n => (
                          <MenuItem key={n.deviceType} value={n.deviceType}>
                            {n.deviceType}
                          </MenuItem>
                        ))}
                    </Select>
                  </td>
                </React.Fragment>
              )}
            </tr>
            <tr>
              {(
                <React.Fragment>
                  <th>
                    <InputLabel> Country </InputLabel>
                  </th>
                  <td>
                    <Select disabled={isItEdit || isItEditSegment} variant="outlined" className="adtest_add" value={countryCode} onChange={handleChangeCountry}>
                      {regionList &&
                        (regionList.find(n => n.deviceType === deviceType) || { list: [] }).list
                          .reduce((ac, a) => {
                            ac = [...ac, ...a.cntry];
                            return ac;
                          }, [])
                          .map(n2 => (
                            <MenuItem key={n2} value={n2}>
                              {n2}
                            </MenuItem>
                          ))}
                    </Select>
                  </td>
                </React.Fragment>
              )}
              {(
                <React.Fragment>
                  <th>
                    <InputLabel>platform version</InputLabel>
                  </th>
                  <td>
                    <Select disabled={isItEdit} variant="outlined" className="adtest_add" value={platformVersion} onChange={handleChangePlatformVersion}>
                      {regionPlatformList &&
                        regionPlatformList
                          .filter(n => n.deviceType === deviceType && n.region === region)
                          .map(n2 => (
                            <MenuItem key={n2.platformVersion} value={n2.platformVersion}>
                              {n2.platformVersion}
                            </MenuItem>
                          ))}
                    </Select>
                  </td>
                </React.Fragment>
              )}
            </tr>
            <tr>
              <th>
                <InputLabel> Tab </InputLabel>
              </th>
              {abTestTabCategory && (
                <td>
                  <Select disabled={isItEdit} variant="outlined" className="adtest_add" name="channelMapList" id="search_channelMapList" value={tabCode} onChange={handleChangeTab}>
                    {abTestTabCategory.map((n, idx) => (
                      <MenuItem key={`${n.tabCode}_${idx}`} value={n.tabCode}>
                        {n.tabCode + ' ' + n.tabName}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              )}

              <th>
                <InputLabel> Category</InputLabel>
              </th>
              {abTestTabCategory && (
                <td>
                  <Select disabled={isItEdit} variant="outlined" className="adtest_add" name="channelMapList" id="search_channelMapList" value={categoryCode} onChange={handleChangeCategory}>
                    {tabCode &&
                      (abTestTabCategory.find(n => n.tabCode == tabCode) || { categoryDetails: [] }).categoryDetails.map(n => (
                        <MenuItem key={n.categoryCode} value={n.categoryCode}>
                          {n.categoryCode + ' ' + n.categoryName}
                        </MenuItem>
                      ))}
                  </Select>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}
AddABTestFilter.propTypes = {
  abTestTabCategory: PropTypes.array.isRequired,
  regionCountries: PropTypes.array.isRequired,
  searchData: PropTypes.object.isRequired,
  handleSearchDataChange: PropTypes.func.isRequired,
  channelMapList: PropTypes.array.isRequired,
  requiredField: PropTypes.bool.isRequired,
  regionList: PropTypes.array.isRequired,
  getABTestTabCategoryAction: PropTypes.func.isRequired,
  resetABTestTabCategoryAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  regionCountries: state.cntry.regionCountries,
  segment: state.homeApp.segment,
  channelMapList: state.homeApp.notificationsChannelMapList,
});

const mapDispatchToProps = dispatch => ({
  getABTestTabCategoryAction(deviceType, region, platformVersion) {
    dispatch(getABTestTabCategoryAction(deviceType, region, platformVersion));
  },
  resetABTestTabCategoryAction() {
    dispatch(resetABTestTabCategoryAction());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AddABTestFilter);
