import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import ContentFilter from './ContentFilter';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import AlertDialog from '../../../common/dialog/AlertDialog';

const SearchedContentList = (props) => {
  const { selectedTableRef
        , country
        , providerList
        , status
        , isDisplayContent
        , callbackSelectedContent
        , viewContentIds
      } = props;

  const [contentList, setContentList] = useState([]);
  const [selectedContentList, setSelectedContentList] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [displayWarningList, setDisplayWarningList] = useState([]);

  useEffect(() => {
    setContentList([]);
  }, [isDisplayContent]);

  useEffect(() => {
    callbackSelectedContent(JSON.parse(JSON.stringify(selectedContentList.map(({tableData, ...rest}) => rest))));
  }, [selectedContentList]);

  const handleContentFilter = (result) => {
    let tempStatus = '';
    if (status === 'Testing') {
      tempStatus = 'T';
    } else if (status === 'Published') {
      tempStatus = 'P';
    } else if (status === 'SavedTesting') {
      tempStatus = 'ST';
    } else {
      tempStatus = 'SP';
    }

    const genreType = country === 'KR' ? 'channel_kr' : 'channel';
    const param = `contentType=${result.contentType}&country=${country}&providerName=${result.providerName}&status=${tempStatus}&genreType=${genreType}&contentId=${result.contentId}&contentName=${result.contentName}`;

    fetch(`${API_BASE_URL}/admin/livetv/content-list?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        // 중복되는 content id를 제거하기 위해서
        const modifyContentList = body.contentList.reduce((acc, cur) => {
          if (!acc.find(obj => obj.contentId === cur.contentId)) {
            acc.push(cur);
          }
          return acc;
        }, []);

        setContentList(modifyContentList);
      } else {
        setAlertDescription(`[LiveTV] content-list request fail`);
        setIsAlert(true);
      }
    })
    .catch(error => {
      setAlertDescription(`[LiveTV] content-list request error: `, error);
      setIsAlert(true);
    }).finally(() => {});
  };

  const handleSelectionChange = (rows) => {
    const filterContents = rows && rows.filter(item => item.providerName !== 'Pluto TV');

    const newSelectedChannelIds = rows.map(row => row.contentId);
    const selectedContentIds = selectedContentList.map(row => row.contentId);

    const allChannelIds = contentList.map(item => item.contentId);
    const otherChannelIds = contentList
      .filter(item => item.providerName !== 'Pluto TV')
      .map(item => item.contentId);

    const isAllSelected = allChannelIds.length === newSelectedChannelIds.length;

    if (isAllSelected) {
      const shouldSelectAllOthers = otherChannelIds.some(contentId => !selectedContentIds.includes(contentId));

      if (shouldSelectAllOthers) {
        setSelectedContentList([...filterContents]);
      } else {
        setSelectedContentList([]);
      }
    } else {
      setSelectedContentList([...filterContents]);
    }
  };

  return (
    <React.Fragment>
      {
        isDisplayContent ? (
        <div>
          <ContentFilter providerList={providerList} callbackContentFilter={handleContentFilter} />
          <MaterialTable
            tableRef={selectedTableRef}
            selectedTableRef
            title=''
            columns={[
              {
                title: 'Type',
                field: 'contentType',
                align: 'center',
                width: '10%',
              },
              {
                title: 'CP',
                field: 'providerName',
                align: 'center',
                width: '10%',
              },
              {
                title: 'ID',
                field: 'contentId',
                align: 'center',
                width: '25%',
              },
              {
                title: 'name',
                field: 'contentName',
                align: 'center',
                width: '25%',
              },
              {
                title: 'Genre',
                field: 'genre',
                align: 'center',
                width: '20%',
              },
              {
                title: 'Image',
                field: 'imageUrl',
                align: 'center',
                width: '10%',
                render: rowData => <img src={rowData.imageUrl} style={{ width: 70, backgroundColor: 'black' }} />,
              },
            ]}
            data={contentList.map(item => ({
              ...item,
              tableData: {
                ...item.tableData,
                checked: selectedContentList.some(selectedRow => selectedRow.contentId === item.contentId),
              },
            }))}
            options={{
              pageSize: 5,
              pageSizeOptions: [5, 10, 20, 50],
              sorting: true,
              selection: true,
              minBodyHeight: 100,
              maxBodyHeight: 500,
              rowStyle: rowData => ({
                backgroundColor: rowData.providerName === 'Pluto TV' ? '#808080' :
                  rowData.tableData.checked ? '#FFFFE0' : viewContentIds.includes(rowData.contentId) ? '#808080' : '',
              }),
              selectionProps: rowData => ({
                disabled: rowData.providerName === 'Pluto TV',
                checked: selectedContentList.some(selectedRow => selectedRow.contentId === rowData.contentId)
              }),
            }}

            onSelectionChange={handleSelectionChange}
          />
        </div>
        ) : (
          <></>
        )
      }

      <AlertDialog
        openAlert={isAlert}
        description={alertDescription}
        warningList={displayWarningList}
        closeHandler={() => { setIsAlert(false); }}
      />
    </React.Fragment>
  );
}

export default SearchedContentList;