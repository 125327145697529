import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { Fragment } from 'react';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

function PageLoader({ classes, loading }) {
  if (!loading) return null;
  return (
    <Fragment>
      <Backdrop className={classes.backdrop} open={true}>
        {/* <div className={'page-loader ' + classes.progress}></div> */}
        <CircularProgress style={{ color: '#ae1a49' }} className={'page-loader ' + classes.progress} />
      </Backdrop>
    </Fragment>
  );
}

PageLoader.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  loading: state.loader.loading,
});

export default connect(mapStateToProps)(withStyles(styles)(PageLoader));
