import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing && theme.spacing(2),
  },
});

export function DeleteAlert({ classes, onClose, onConfirm, open }) {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className={classes.root}>
      <DialogTitle id="alert-dialog-title">{'Do you really want to delete?'}</DialogTitle>
      <DialogActions>
        <Button data-testid="noButton" onClick={onClose} color="secondary" autoFocus>
          No
        </Button>
        <Button data-testid="yesButton" onClick={onConfirm} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DeleteAlert);
