import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PrimaryButton from '../common/PrimaryButton';

function Confirm(props) {
  const { isOpen, onApprove, onClose, title, message } = props;
  const [open, setOpen] = useState(isOpen);
  const [isApprove, setIsApprove] = useState(false);
  const alertTitle = title ? title : 'Title';
  const alertMessage = message ? message : '';

  const handleApprove = () => {
    setIsApprove(true);
    onApprove();
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      onClose();
    }
    return () => {
      setOpen(false);
      setIsApprove(false);
    };
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modal-confirm">
        <DialogTitle id="alert-dialog-title" className="modal-title">
          {alertTitle}
          <button className="btn-close" onClick={handleClose}>
            close
          </button>
        </DialogTitle>
        <DialogContent className="modal-contents">
          <DialogContentText id="alert-dialog-description">{alertMessage}</DialogContentText>
        </DialogContent>
        { !isApprove &&  (
          <DialogActions className="modal-buttons">
            <PrimaryButton type={'button'} text={'Ok'} btnClass={'btn-primary-accent'} onClick={handleApprove} />
            <PrimaryButton type={'button'} text={'CANCEL'} btnClass={'btn-primary-accent'} onClick={handleClose} />
          </DialogActions>
        )
      }
      </Dialog>
    </>
  );
}

export default Confirm;
