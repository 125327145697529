const initialState = [];

export default function supportLanguages(state = initialState, action) {
    switch (action.type) {
        case 'SET_SUPPORT_LANGUAGES':
            return action.payload;
        default:
            return state;
    }
}
