import React from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 18px;
    --y: 298px;
    --width: 299px;
    --height: 56px;

    top: 0;
    left: 0;
    width: 333px;
    height: 1080px;
  }

  #clip-02 {
    --x: 1px;
    --y: 96px;
    --width: 1566px;
    --height: 46px;

    top: 0;
    left: 333px;
    width: 1587px;
    height: 660px;
  }

  #clip-03 {
    --x: 1px;
    --y: 12px;
    --width: 783px;
    --height: 455px;

    top: 642px;
    left: 333px;
    width: 783px;
    height: 497px;
  }

  #clip-04 {
    --x: 12px;
    --y: 12px;
    --width: 772px;
    --height: 455px;

    top: 642px;
    left: 1116px;
    width: 804px;
    height: 497px;
  }

  #text-01 {
    top: 250px;
    left: 18px;
  }

  #text-02 {
    top: 175px;
    left: 537px;
  }

  #text-03 {
    top: 662px;
    left: 32px;
  }

  #line-01 {
    top: 102px;
    left: 78px;
  }

  #line-02 {
    top: 150px;
    left: 468px;
  }

  #line-03 {
    top: 828px;
    left: 178px;
  }
`;

const AdvertisementDashboard01 = () => {
  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" className="border" />
        </div>

        <div id="clip-02" className="clip-box">
          <div id="clip-02-hole" className="hole" />
          <div id="clip-02-border" className="border" />
        </div>

        <div id="clip-03" className="clip-box">
          <div id="clip-03-hole" className="hole" />
          <div id="clip-03-border" className="border" />
        </div>

        <div id="clip-04" className="clip-box">
          <div id="clip-04-hole" className="hole" />
          <div id="clip-04-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">Advertisement tab</div>
          <div id="text-02" className="text">
            You can set conditions for the filter.
          </div>
          <div id="text-03" className="text">
            You can check details<br/>
            about advertisement<br/>
            data issues from today<br/>
            to 14 days ago.
          </div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_6153_line_01.png" alt='Line' />
          </div>
          <div id="line-02" className="line">
            <img src="/common/images/iss/tutorial/t_6153_line_02.png" alt='Line' />
          </div>
          <div id="line-03" className="line">
            <img src="/common/images/iss/tutorial/t_6153_line_03.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default AdvertisementDashboard01;