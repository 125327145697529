import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingIndicator from '../../common/LoadingIndicator';
import { LightTooltip } from '../../common/Tooltip';
import { comment } from './comment/comment';

function CardChart({ data, dataFetching, date, date2 }) {

  const cardData = data && data.data.length > 0 && data.data.map(n => (
    <div key={n.item} className={`card_unit ${(n[date] - n[date2] >= 0 ? 'is_plus' : 'is_minus')}`}>
      <LightTooltip arrow title={comment[n.item]}>
        <span className="tit">{n.item === 'totalDuration' || n.item === 'durationPerUd' ? n.item + ' (hr)' : n.item}</span>
      </LightTooltip>
      <span className="count">
        <span>
          {n[date] && n[date].toLocaleString('en')}
          {n.item.includes('%') ? '%' : ''}
        </span>
      </span>

      {<div className={n[date] - n[date2] >= 0 ? 'summary_up' : 'summary_down'}>
      {
        n[date2] ? Math.round(((n[date] - n[date2]) / n[date2]) * 100) : 0
         + '%'}</div>
      }
    </div>
  ));

  const card = cardData && cardData
    .concat(
      dataFetching ? (
        <div key="2nd" className="card_unit">
          <LoadingIndicator />
        </div>
      ) : (
        <React.Fragment key="2ndFetchingEnd" />
      ),
    ).reduce((accum, current, i) => {
      if (i % 4 === 0) {
        return [...accum, [current]];
      } else {
        accum[accum.length - 1].push(current);
        return accum;
      }
    }, []);

  const cardRow = card && card.map((n, i) => (
    <div key={n[i] && n[i][0] ? n[i][0].key : i} className="card_row">
      {n}
    </div>
  ));
  return <div className="summary_card">{cardRow}</div>;
}

CardChart.propTypes = {
  data: PropTypes.object.isRequired,
  dataFetching: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  data: state.statistic.data,
  dataFetching: state.statistic.dataFetching,
});

export default connect(mapStateToProps)(CardChart);
