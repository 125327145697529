import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function channels(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CHANNEL_CHANNEL:
      if (action.payload.list) return action.payload.list;
      else return state;
    default:
      return state;
  }
}
