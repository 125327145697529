import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingIndicator from '../../../common/LoadingIndicator';
import MaterialTable from 'material-table';
import CPCodeAdd from './CPCodeAdd';
import CpCodeEdit from './CpCodeEdit';
import AccountDelete from '../AccountDelete';
import { getProvidersAdminAction } from '../../../actions/providerActions';

export function CpCode({ getProvidersAdminAction, fetching, providers }) {
  useEffect(() => {
    if (providers.length === 0) getProvidersAdminAction();
  }, []);

  return (
    <div>
      {fetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        <div className={'tbl_wrap'}>
          <div className={'btn_set_wrap tbl_top1'}>
            <CPCodeAdd />
          </div>
          <MaterialTable
            title=" " //없으면 Table Title이 default title로 나옴
            columns={[
              {
                title: 'Logo',
                field: 'Logo',
                width: '10%',
                render: rowData => {
                  if (rowData.Logo) return <img alt="invalid" src={rowData.Logo} title={rowData.Logo} style={{ width: 70, backgroundColor: 'black' }} />;
                },
              },
              {
                title: 'CP Code',
                field: 'CP Code',
                width: '10%',
              },
              {
                title: 'Name',
                field: 'Name',
                width: '10%',
              },
              {
                title: 'Use LGPlayer',
                field: 'Use LGPlayer',
                width: '10%',
              },
              {
                title: 'Interative URL',
                field: 'Interative URL',
                width: 'auto',
              },
              {
                title: 'Action',
                field: 'Action',
                width: '15%',
                render: rowData => (
                  <React.Fragment>
                    <div className={'btn_set_wrap_tbl'}>
                      <CpCodeEdit item={rowData} />
                      <AccountDelete requestPath={`/api/admin/providers/${rowData['CP Code']}`} reloadAction={getProvidersAdminAction} />
                    </div>
                  </React.Fragment>
                ),
              },
            ]}
            data={providers.map(n => {
              let newData = {};
              newData['CP Code'] = n.providerId;
              newData['Name'] = n.providerName;
              newData['Use LGPlayer'] = n.lgPlayerFlag ? 'Y' : 'N';
              newData['Interative URL'] = n.interactiveUrl;
              newData['Logo'] = n.logoUrl;
              return newData;
            })}
            options={{
              //selection: true,
              pageSize: 10,
              pageSizeOptions: [5, 10, 30, 50, 100],
              rowStyle: { backgroundColor: '#fff' },
            }}
            // onSelectionChange={(rows) => {
            //   selectedItem = rows;
            //   console.log('[cpCode][onSelectionChange][rows]', rows);
            // }}
          />
        </div>
      )}
    </div>
  );
}

CpCode.propTypes = {
  fetching: PropTypes.bool.isRequired,
  getProvidersAdminAction: PropTypes.func.isRequired,
  providers: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  providers: state.providers.providers,
  fetching: state.providers.fetching,
});
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  getProvidersAdminAction() {
    dispatch(getProvidersAdminAction());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CpCode));

// Editable???
// providerId: "PLT"
// providerName: null
// appId: "1u"
// interactiveUrl: "https://lgwebos1.plutopreprod.tv/"
// logoUrl: "https://images.pluto.tv/assets/images/default/PlutoTV_Logo_300x300_BlackOnWhite.jpg"
// logoWidth: 300
// logoHeight: 300
// lgPlayerFlag: false
// useFlag: true
