import React, { useEffect, useState } from 'react';
import Loading from '../../../common/Loading';
import { Line } from 'react-chartjs-2';
import Alert from '../../../modal/Alert';
import { getHeaders } from '../../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../../constants/index';
import { feed_basic_trend_resolve_count } from './FeedTrendData';

function FeedResolveTrend(props) {
  const { height
        , title
        , currentFilter
        , updateTable
      } = props;
  const [trendData, setTrendData] = useState({data: {}, options: {}});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [isTrendDisplay, setTrendDisplay] = useState(false);

  useEffect(() => {
    if (currentFilter) {
      console.log(`[ISS] FeedResolveTrend currentFilter: `, currentFilter);
      setTrendDisplay(false);
      setTrendData({});
      getTrendFeedResolveCount(currentFilter);
    }
  }, [currentFilter]);

  const getTrendFeedResolveCount = (filter) => {
    const tempProvider = filter.providers ? filter.providers : '';

    const param = `date=${filter.date}&provider=${tempProvider}`;

    fetch(`${ISS_BASE_URL}/api/application/dashboard/trend/feed/resolve/count?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          const issueDate = body.dataSet.map(item => item.issueDate.substring(5));

          const chartData = {
            labels: issueDate,
            datasets: [
              {
                label: 'Total Resolve Count',
                data: body.dataSet.map(item => item.totalResolveCnt),
                fill: false,
                pointBorderColor: '#FFFFFF',
                borderColor: '#4E75CE',
                backgroundColor: '#4E75CE',
                pointBackgroundColor: '#4E75CE',
                pointBorderWidth: 2,
                pointRadius: 4,
                pointStyle: 'circle',
              },
              {
                label: 'Auto Resolve Count',
                data: body.dataSet.map(item => item.autoResolveCnt),
                fill: false,
                pointBorderColor: '#FFFFFF',
                borderColor: '#BE6F75',
                backgroundColor: '#BE6F75',
                pointBackgroundColor: '#BE6F75',
                pointBorderWidth: 2,
                pointRadius: 4,
                pointStyle: 'triangle',
              },
              {
                label: 'Manual Resolve Count',
                data: body.dataSet.map(item => item.manualResolveCnt),
                fill: false,
                pointBorderColor: '#FFFFFF',
                borderColor: '#D4AA00',
                backgroundColor: '#D4AA00',
                pointBackgroundColor: '#D4AA00',
                pointBorderWidth: 2,
                pointRadius: 4,
                pointStyle: 'rect',
              },
            ],
          };

          const options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                min: 0,
                ticks: {
                  stepSize: 10,
                },
              },
            },
            plugins: {
              datalabels: {
                display: false,
              },
              legend: {
                display: true,
                labels: {
                  usePointStyle: true,
                  boxWidth: 10,
                  boxHeight: 10,
                  color: '#fff',
                  font: {
                    size: 12,
                  },
                },
              },
            },
          };

          setTrendData({data: chartData, options: options});

          // update table
          feed_basic_trend_resolve_count.rowdata = body.dataSet.map(item => ({
            row: [
              item.issueDate,
              item.totalResolveCnt,
              item.autoResolveCnt,
              item.manualResolveCnt
            ],
          }));
          updateTable(feed_basic_trend_resolve_count);
          // update table

          setTrendDisplay(true);
        } else {
          setAlertMessage(`getTrendFeedResolveCount error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`getTrendFeedResolveCount error: `, error);
        onOpenAlert();
      })
      .finally(() => {});
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
    {
      isTrendDisplay ? (
        <div style={{ height: height }}>
          <div className='trend-head'>
            <div className="title" style={{top: "-30px"}}>
              <div> {title} </div>
            </div>
          </div>
          <Line data={trendData.data} options={trendData.options} />
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )
    }
    {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default FeedResolveTrend;