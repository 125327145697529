import React, { useEffect } from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 10px;
    --y: 93px;
    --width: 300px;
    --height: 275px;

    top: 0;
    left: 0;
    width: 1920px;
    height: 2000px;
  }

  #text-01 {
    top: 394px;
    left: 162px;
  }

  #line-01 {
    top: 367px;
    left: 90px;
  }
`;

const IssueHistory01 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">
            You can select the major issue info tab to display the number<br/>
            of issues and the number of completed issues for the relevant tab items.
          </div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_6145_line_01.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default IssueHistory01;