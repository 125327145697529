import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { setPrecheckList, setPublish, setExportFalse } from '../../../actions/channelMappingActions';
import PublishCheckBeforeNAfter from './PublishBeforeNAfter';
import PublishButtons from './PublishButtons';
import LoadingIndicator from '../../../common/LoadingIndicator';

function PublishDialog({ system, ric, cntry, channelMapId, onClose, publishType, setPrecheckList, precheck, setPublish, pbresult, exports, setExportFalse }) {
  React.useEffect(() => {
    if (channelMapId) setPrecheckList(system, ric, cntry, channelMapId, publishType); //fetch preview to check before test/publish
  }, [system, ric, cntry, channelMapId, publishType, setPrecheckList]);

  React.useEffect(() => {
    if (exports.result) {
      setFetch(false);
      setResultAlert(true);
    } else {
      setFetch(false);
      setResultAlert(pbresult);
    }
  }, [exports, pbresult]);
  React.useEffect(() => {}, []);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [fetch, setFetch] = React.useState(false);
  const [resultAlert, setResultAlert] = React.useState(false);

  const allClose = () => {
    setResultAlert(false);
    setOpenConfirm(false);
    onClose();
    setExportFalse(false); //이게 있어야 publish success message가 publish후 한번만 뜨고 그후 재진입시 안뜸, 없으면 계속 뜸
  };
  const onPublishConfirmed = () => {
    setFetch(true);
    setPublish(system, ric, cntry, channelMapId, publishType);
  };

  return (
    <div>
      <Dialog open={!!channelMapId} onClose={onClose} aria-labelledby="form-dialog-title" className={'pop_wrap publish_pop'}>
        <div className="MuiDialogTitle-root pop_head" id="form-dialog-title">
          <h2 className="MuiTypography-root MuiTypography-h6">
            Check Dialog
            <IconButton aria-label="close" className={'noTit_close'} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </h2>
        </div>
        <div className={'pop_body'}>
          <div className="tbl_wrap">
            {precheck.beforeNAfter && <PublishCheckBeforeNAfter publishType={publishType} />}
            {precheck.beforeNAfter && (
              <PublishButtons
                publishType={publishType}
                onPublish={() => {
                  setOpenConfirm(true);
                }}
                onClose={onClose}
                system={system}
                ric={ric}
                cntry={cntry}
              />
            )}
          </div>
        </div>
        <Dialog open={openConfirm} aria-labelledby="confirm-dialog">
          <div className="pop_size_small">
            {fetch ? (
              <LoadingIndicator />
            ) : (
              <React.Fragment>
                <div className="ico_condition1"></div>
                <p className="pop_comment">Do you want to publish?</p>
                <DialogActions>
                  <div className="btn_set_wrap alC">
                    <Button color="primary" onClick={onPublishConfirmed} className="btn_color2">
                      OK
                    </Button>
                    <Button
                      autoFocus
                      color="primary"
                      onClick={() => {
                        setOpenConfirm(false);
                      }}
                      className="btn_color1">
                      Cancel
                    </Button>
                  </div>
                </DialogActions>
              </React.Fragment>
            )}
          </div>
        </Dialog>
        <Dialog open={resultAlert} aria-labelledby="confirm-dialog">
          <div className="pop_size_small">
            <p className="pop_comment">{exports.result ? 'Published successfully.' : `Failed. ${JSON.stringify(exports.msg)}`}</p>
            <DialogActions>
              <div className="btn_set_wrap alC">
                <Button color="primary" onClick={allClose} className="btn_color2">
                  OK
                </Button>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </Dialog>
    </div>
  );
}
PublishDialog.propTypes = {
  system: PropTypes.string.isRequired,
  ric: PropTypes.string.isRequired,
  cntry: PropTypes.string.isRequired,
  channelMapId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  publishType: PropTypes.string.isRequired,
  setPrecheckList: PropTypes.func.isRequired,
  precheck: PropTypes.object,
  setPublish: PropTypes.func.isRequired,
  pbresult: PropTypes.bool,
  exports: PropTypes.object,
  setExportFalse: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  exports: state.channelMapping.exports,
  precheck: state.channelMapping.precheck,
  pbresult: state.channelMapping.pbresult,
});
const mapDispatchToProps = dispatch => ({
  setPublish: (system, ric, cntry, channelMapId, publishType) => dispatch(setPublish(system, ric, cntry, channelMapId, publishType)),
  setPrecheckList: (system, ric, cntry, channelMapId, publishType) => dispatch(setPrecheckList(system, ric, cntry, channelMapId, publishType)),
  setExportFalse: bool => dispatch(setExportFalse(bool)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PublishDialog);
