import * as actions from '../../actions/actionTypes';

const initialState = 1;

export default function page(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FEEDS_HISTORY:
      return action.payload.pageNo || state;
    default:
      return state;
  }
}
