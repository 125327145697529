import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogContent, DialogTitle, FormControlLabel, FormGroup, MenuItem, Select, Switch, Table, Grid, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const devicePlatformList = ['Android', 'IOS'];
const tabTypeList = ['tab'];
const skeletonTypeList = ['default', 'carousel', 'live', 'my', 'search', 'slider'];
const tabLinkTypeList = ['movie', 'tvshow'];
const locationList = ['top', 'scroll', 'bottom', 'link'];

function TabAdd({ data, handleTabInputChange, handleCreateSubmit, handleTabClose, handleTabInputNumberChange, regionList }) {
  const [imgName, setImgName] = useState('');
  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        CREATE TAB
        <IconButton aria-label="close" onClick={handleTabClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleCreateSubmit}>
        <DialogContent className={'pop_body'}>
          <div>
            <TableContainer className={'tbl_wrap'}>
              <Table className={'tbl_row_typ1'}>
                <tbody>
                  <TableRow width="100%">
                    <TableCell colSpan={1} width="20%">
                      <label className="is_required">Device Type</label>
                    </TableCell>
                    <TableCell colSpan={1} width="30%">
                      <Select name="deviceType" id="deviceType" fullWidth value={data.deviceType ? data.deviceType : ''} onChange={event => handleTabInputChange(event)}>
                        {[...regionList].map(item => (
                          <MenuItem key={item.deviceType} value={item.deviceType}>
                            {item.deviceType}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell colSpan={1} width="20%">
                      <label className="is_required">Region</label>
                    </TableCell>
                    <TableCell colSpan={1} width="30%">
                      <Select name="region" id="region" fullWidth value={data.region ? data.region : ''} onChange={event => handleTabInputChange(event)}>
                        {([...regionList].find(n => n.deviceType === data.deviceType) || { list: [] }).list.map(n2 => (
                          <MenuItem key={n2.region} value={n2.region}>
                            {n2.region}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%">
                    <TableCell colSpan={1}>
                      <label className="is_required">Platform Version</label>
                    </TableCell>
                    {(data.deviceType === 'tv' || (data.deviceType !== 'pc' && data.deviceType !== 'mobile')) && (
                      <TableCell colSpan={3}>
                        <TextField fullWidth name="platformVersion" inputProps={{ maxLength: 11 }} required value={data.platformVersion ? data.platformVersion : ''} onChange={event => handleTabInputNumberChange(event)} placeholder="ex) 4.3.0" />
                      </TableCell>
                    )}
                    {data.deviceType === 'pc' && (
                      <TableCell colSpan={3}>
                        <TextField fullWidth name="platformVersion" inputProps={{ maxLength: 50 }} required value={data.platformVersion ? data.platformVersion : ''} onChange={event => handleTabInputChange(event)} placeholder="ex) pc" />
                      </TableCell>
                    )}
                    {data.deviceType === 'mobile' && (
                      <TableCell colSpan={3}>
                        <Select name="platformVersion" id="platformVersion" required style={{ minWidth: 120 }} value={data.platformVersion ? data.platformVersion : ''} onChange={event => handleTabInputChange(event)}>
                          {[...devicePlatformList].map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow width="100%">
                    <TableCell colSpan={1} width="20%">
                      <label className="is_required">Tab Code</label>
                    </TableCell>
                    <TableCell colSpan={1} width="30%">
                      <TextField fullWidth name="tabCode" inputProps={{ maxLength: 20 }} required value={data.tabCode ? data.tabCode : ''} onChange={event => handleTabInputChange(event)} placeholder="ex) T-Tab Name" />
                    </TableCell>
                    <TableCell colSpan={1} width="20%">
                      <label className="is_required">Tab Name</label>
                    </TableCell>
                    <TableCell colSpan={1} width="30%">
                      <TextField fullWidth name="tabName" inputProps={{ maxLength: 100 }} required value={data.tabName ? data.tabName : ''} onChange={event => handleTabInputChange(event)} placeholder="ex) Tab Name" />
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%">
                    <TableCell colSpan={1} width="20%">
                      <label className="is_required">Type</label>
                    </TableCell>
                    <TableCell colSpan={data.type !== 'link' ? 3 : 1} width="30%">
                      <Select name="type" id="type" required fullWidth value={data.type ? data.type : ''} onChange={event => handleTabInputChange(event)}>
                        {[...tabTypeList].map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    {data.type === 'link' && (
                      <TableCell colSpan={1} width="20%">
                        <label className="is_required">Link Type</label>
                      </TableCell>
                    )}
                    {data.type === 'link' && (
                      <TableCell colSpan={1} width="30%">
                        <Select name="linkType" id="linkType" fullWidth value={data.linkType ? data.linkType : ''} onChange={event => handleTabInputChange(event)}>
                          {[...tabLinkTypeList].map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow width="100%">
                    <TableCell colSpan={1} width="20%">
                      <label className="is_required">Skeleton Type</label>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <Select name="skeletonType" id="skeletonType" fullWidth value={data.skeletonType ? data.skeletonType : ''} onChange={event => handleTabInputChange(event)}>
                        {[...skeletonTypeList].map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%">
                    <TableCell colSpan={1} width="20%">
                      <label className="is_required">Location Type</label>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Select name="locationType" id="locationType" fullWidth value={data.locationType ? data.locationType : ''} onChange={event => handleTabInputChange(event)}>
                        {[...locationList].map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <FormGroup row>
                        <FormControlLabel control={<Switch name="categoryFlag" checked={data.categoryFlag === 'Y'} inputProps={{ 'aria-label': 'secondary checkbox' }} onChange={event => handleTabInputChange(event)} />} label="Catogory Flag" />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                  <Fragment>
                    <TableRow width="100%">
                      <TableCell>
                        <label>Tab Image</label>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <div className="logo_preview_add">
                          <input
                            accept="image/*"
                            id="tabImageUrl"
                            type="file"
                            name="tabImg"
                            className="logo_preview_input"
                            onChange={e => {
                              if (e.target.files && e.target.files.length >= 1) {
                                const reader = new FileReader();
                                const img = e.target.files[0];
                                reader.readAsDataURL(img);
                                reader.onload = () => {
                                  setImgName(img.name);
                                };
                              }
                            }}
                            required={false}
                          />
                          <Grid container="true" spacing={1} style={{ marginTop: '5px' }}>
                            <Grid item="true" sm={9} style={{ wordBreak: 'break-word' }}>
                              {imgName === '' ? <p className="col-md-9">{data.tabImageUrl}</p> : <p className="col-md-9">{imgName}</p>}
                            </Grid>
                            <Grid item="true" sm={3}>
                              <label htmlFor={'tabImageUrl'}>
                                <Button component="span" className="btn_color4" style={{ width: 110 }}>
                                  Upload File
                                </Button>
                              </label>
                            </Grid>
                          </Grid>
                        </div>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                </tbody>
              </Table>
            </TableContainer>
          </div>
          <div className={'btn_set_wrap'}>
            <Button type="submit" color="primary" className={'btn_color2'}>
              Save
            </Button>
            <Button data-testid="addButton" color="primary" onClick={handleTabClose} className={'btn_color1'}>
              Cancel
            </Button>
          </div>
        </DialogContent>
      </form>
    </Fragment>
  );
}

TabAdd.propTypes = {
  data: PropTypes.object,
  regionList: PropTypes.array.isRequired,
  regionCountries: PropTypes.array.isRequired,
  handleTabInputChange: PropTypes.func.isRequired,
  handleTabClose: PropTypes.func.isRequired,
  handleCreateSubmit: PropTypes.func.isRequired,
  handleTabInputNumberChange: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  regionList: state.homeApp.regionList,
  regionCountries: state.cntry.regionCountries,
});

export default connect(mapStateToProps)(TabAdd);
