import { SET_RSS_LOG, SET_API_FAIL } from './actionTypes';
import { API_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';
const URL = API_URL;
const setRssLog = list => ({
  type: SET_RSS_LOG,
  payload: list,
});
const doFail = body => ({
  type: SET_API_FAIL,
  payload: body,
});
export const getRssLog = param => dispatch => {
  let apiUrl = `${URL}/api/sdepth/rssfeed/feed-logs?page=${param.page}&size=${param.size}&sortDir=DESC&cntryCode=${param.cntryCode}&cpName=${param.cpName}&type=${param.type}&rssPath=${param.rssPath}&resultCode=${param.resultCode}`;
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
    // body: JSON.stringify(param),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(setRssLog(body));
    })
    .catch(ex => dispatch(doFail(ex)));
};
