import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ChipInput from 'material-ui-chip-input';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

const style = theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(1),
  },
  keywordBox: {
    position: 'absolute',
    zIndex: 1,
  },
});

export function ChipsFilter({ classes, filters, onChange }) {
  const [chips, setChips] = useState([]);
  const [chipText, setChipText] = useState('');
  const [keywordText, setKeywordText] = useState('');
  const [isKeywordBoxOpen, setIsKeywordBoxOpen] = useState(false);

  const handleInputClick = () => {
    if (isKeywordBoxOpen === false && keywordText === '') {
      setIsKeywordBoxOpen(true);
    }
  };

  const handleInputChange = ev => {
    if (keywordText) {
      if (ev.target.value.search(keywordText + ':') < 0) {
        setChipText('');
        setKeywordText('');
        return;
      } else {
        setChipText(ev.target.value);
      }
    }
  };

  const handleInputFocus = () => {
    if (chipText === '') {
      setIsKeywordBoxOpen(true);
    }
  };

  const handleInputBlur = () => {
    setIsKeywordBoxOpen(false);
  };

  const handleAddChip = chip => {
    const newChips = [...chips, chip];

    setChips(newChips);
    setChipText('');
    setIsKeywordBoxOpen(true);

    onChange && onChange(newChips);
  };

  const handleDeleteChip = (chip, index) => {
    const newChips = [...chips.slice(0, index), ...chips.slice(index + 1)];

    setChips(newChips);
    onChange && onChange(newChips);
  };

  const handleKeywordSelect = (ev, index) => {
    setKeywordText(filters[index].name);
    setChipText(filters[index].name + ':');
    setIsKeywordBoxOpen(false);

    ev.preventDefault();
  };

  return (
    <div className={classes.root}>
      <ChipInput
        fullWidth
        clearInputValueOnChange
        placeholder={'Search...'}
        value={chips}
        InputProps={{
          onClick: handleInputClick,
          onChange: handleInputChange,
          onFocus: handleInputFocus,
          onBlur: handleInputBlur,
          value: chipText,
        }}
        onAdd={chip => handleAddChip(chip)}
        onDelete={(chip, index) => handleDeleteChip(chip, index)}
      />
      {isKeywordBoxOpen ? (
        <Paper className={classes.keywordBox} elevation={1}>
          {filters.map((key, index) => {
            return (
              <MenuItem key={String(index)} onMouseDown={ev => handleKeywordSelect(ev, index)}>
                {key.name}
              </MenuItem>
            );
          })}
        </Paper>
      ) : null}
    </div>
  );
}

ChipsFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

export default withStyles(style)(ChipsFilter);
