import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CommonDialog from '../../../common/dialog/CommonDialog';
import { request } from '../../../util/request';
import { getSegmentDetails } from '../../../actions/homeAppActions';

function ANTDeleteSegment({ segmentSubRow, abTestRow, setOpen, ableToDelete }) {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [descriptionDelete, setDescriptionDelete] = React.useState('Are you sure to delete this Segment ?');
  return (
    <div style={{ paddingRight: '6px', paddingLeft: '6px' }}>
      <React.Fragment>
        <Button
          disabled={ableToDelete}
          style={{ background: '#dc004e', border: '1px solid #dc004e', color: '#ffffff' }}
          className={'btn_size_tbl btn_color3 btn_set_wrap segment_wrap'}
          onClick={() => {
            setOpenDelete(true);
          }}>
          Delete
        </Button>
        <CommonDialog
          open={openDelete}
          description={descriptionDelete}
          handleCancel={() => {
            setOpenDelete(false);
          }}
          handleOk={async () => {
            let isSuccess = await request(`/api/antSegmentDelete/`, 'PATCH', segmentSubRow);
            if (isSuccess) {
              setOpen(false);
              setOpenDelete(false);
              alert('Successfully Deleted !');
              getSegmentDetails(segmentSubRow.abtestId).then(response => {
                abTestRow.segmentList = [];
                response.map(data => {
                  abTestRow.segmentList.push(data);
                });
                setOpen(true);
              });
            }
          }}
        />
      </React.Fragment>
    </div>
  );
}
ANTDeleteSegment.propTypes = {};
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ANTDeleteSegment));
