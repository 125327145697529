import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';

function PublishBeforeNAfter({ publishType, precheck, dmaGroupCodes }) {
  const getDMACodeCity = value => {
    let dmaGroupName;
    if (!(value == null || value.length === 0)) {
      dmaGroupName = dmaGroupCodes.find(x => x.dmaGroupName === value);
    }
    return dmaGroupName ? `${dmaGroupName.dmaGroupName}` : '';
  };

  const hasDiffrenceInDmacodes = rowData => {
    const { pDmaGroupName, dDmaGroupName } = rowData;
    let finalVal = false;
    if (pDmaGroupName != dDmaGroupName) finalVal = true;
    return finalVal;
  };

  const higlightDMACodeaddRemove = (rowData, dmaCodeType, currentDmaCode) => {
    const addRemoveChannelMap = !rowData.pChanCode || !rowData.dChanCode;
    if (!addRemoveChannelMap) {
      const dmaCodesList = rowData[dmaCodeType].split(',');
      return !dmaCodesList.includes(currentDmaCode);
    }
    return false;
  };

  const generateText = (list, chanCode, status) => {
    const channelNumbers =
      list &&
      list
        .map(x => {
          return x[chanCode];
        })
        .join(',');
    return list && list.length > 0 ? `${channelNumbers} ${status}` : '';
  };

  const channelMapDifflist = confList => {
    const addedChannelMaps = confList && confList.filter(x => !x.pChanCode);
    const modifiedChannelMaps =
      confList &&
      confList.filter(rowData => {
        if (rowData.pChanCode && rowData.dChanCode) {
          return hasDiffrenceInDmacodes(rowData);
        }
      });
    const removedChannelMaps = confList && confList.filter(x => !x.dChanCode);
    const addChannelMapText = generateText(addedChannelMaps, 'dChanNo', 'added');
    const modifiedChannelMapText = generateText(modifiedChannelMaps, 'pChanNo', 'modified');
    const removedChannelMapText = generateText(removedChannelMaps, 'pChanNo', 'removed');
    const channelMapDiffText = [removedChannelMapText, addChannelMapText, modifiedChannelMapText].reduce((prev, curr) => {
      if (prev.length > 0 && curr.length > 0) {
        return prev + ' / ' + curr;
      }
      return prev + curr;
    });
    return channelMapDiffText.length > 0 ? channelMapDiffText : 'There is no changes.';
  };
  return (
    <div className="preview_wrap">
      <div className="tbl_wrap publish_table">
        <div className="tbl_top_cont_wrap">
          <div className="tbl_top_cont">
            <p className="tit">{`AS-IS (status: ${publishType === 'testing' ? 'Testing' : 'Published'})`}</p>
            <p className="time">(last update date : {precheck.beforeNAfter.lastPublishDate})</p>
          </div>
          <div className="tbl_top_cont">
            <p className="tit">TO-BE (status: Saved)</p>
            <p className="time">(last update date : {precheck.beforeNAfter.lastUpdateDate}</p>
          </div>
        </div>
        <div className="tbl_wrap">
          <span className="publish_channel_diff">{channelMapDifflist(precheck.beforeNAfter.confList)}</span>
        </div>
        <div className="publish_table_cont">
          <div className="publish_table_cont_body">
            <MaterialTable
              data={precheck.beforeNAfter.confList}
              columns={[
                {
                  title: 'Ch. No',
                  field: 'pChanNo',
                  alignItems: 'center',
                },
                {
                  title: 'Ch. ID',
                  field: 'pChanCode',
                  alignItems: 'center',
                  //render: rowData => settingCode(rowData, 'pChanCode'),
                },
                {
                  title: 'Ch. Name',
                  field: 'pChanName',
                  alignItems: 'left',
                  //render: rowData => settingCode(rowData, 'pChanName'),
                },
                {
                  title: 'DMA Group Name',
                  field: 'pDmaGroupName',
                  alignItems: 'center',
                  render: rowData =>
                    rowData['pDmaGroupName'] &&
                    rowData['pDmaGroupName'].split(',').map(x => (
                      <Fragment key={x}>
                        <span className={`${higlightDMACodeaddRemove(rowData, 'dDmaGroupName', x) ? 'dmacode-diff' : ''}`}>{getDMACodeCity(x)}</span> <br />
                      </Fragment>
                    )),
                },
                {
                  title: 'LastChgDate',
                  field: 'pChgDate',
                  alignItems: 'left',
                },
              ]}
              options={{
                search: false,
                paging: false,
                showTitle: false,
                doubleHorizontalScroll: false,
                // maxBodyHeight: '460px',
                // minBodyHeight: '460px',
                rowStyle: (rowData, index) => ({
                  backgroundColor: !rowData.dChanCode || rowData.pChanCode !== rowData.dChanCode || hasDiffrenceInDmacodes(rowData) ? '#fef5f8' : index % 2 === 0 ? '#fdfdfd' : '#fff',
                  color: !rowData.dChanCode || rowData.pChanCode !== rowData.dChanCode || hasDiffrenceInDmacodes(rowData) ? '#d8295c' : '#484848',
                }),
              }}
            />
            <MaterialTable
              columns={[
                {
                  title: 'Ch. No',
                  field: 'dChanNo',
                  alignItems: 'center',
                },
                {
                  title: 'Ch. ID',
                  field: 'dChanCode',
                  alignItems: 'center',
                  //render: rowData => settingCode(rowData, 'dChanCode'),
                },
                {
                  title: 'Ch. Name',
                  field: 'dChanName',
                  alignItems: 'left',
                  //render: rowData => settingCode(rowData, 'dChanName'),
                },
                {
                  title: 'DMA Group Name',
                  field: 'dDmaGroupName',
                  alignItems: 'center',
                  render: rowData =>
                    rowData['dDmaGroupName'] &&
                    rowData['dDmaGroupName'].split(',').map(x => (
                      <Fragment key={x}>
                        <span className={`${higlightDMACodeaddRemove(rowData, 'pDmaGroupName', x) ? 'dmacode-diff' : ''}`}>{getDMACodeCity(x)}</span> <br />
                      </Fragment>
                    )),
                },
                {
                  title: 'LastChgDate',
                  field: 'dChgDate',
                  alignItems: 'left',
                },
              ]}
              data={precheck.beforeNAfter.confList}
              options={{
                search: false,
                paging: false,
                showTitle: false,
                doubleHorizontalScroll: false,
                rowStyle: (rowData, index) => ({
                  backgroundColor: !rowData.pChanCode || rowData.pChanCode !== rowData.dChanCode || hasDiffrenceInDmacodes(rowData) ? '#fef5f8' : index % 2 === 0 ? '#fdfdfd' : '#fff',
                  color: !rowData.pChanCode || rowData.pChanCode !== rowData.dChanCode || hasDiffrenceInDmacodes(rowData) ? '#d8295c' : '#484848',
                }),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
PublishBeforeNAfter.propTypes = {
  precheck: PropTypes.object,
  publishType: PropTypes.string.isRequired,
  dmaGroupCodes: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  precheck: state.channelMapping.precheck,
  dmaGroupCodes: state.dmaGroupCode.dmaGroupCodes,
});
// const mapDispatchToProps = dispatch => ({
//   setExport: (system, ric, cntry, channelMapId, isTesting) => dispatch(setExport(system, ric, cntry, channelMapId, isTesting)),
//   setPublishList: (system, ric, cntry, channelMapId, isTesting) => dispatch(setPublishList(system, ric, cntry, channelMapId, isTesting)),
//   setExportFalse: bool => dispatch(setExportFalse(bool)),
// });
export default connect(mapStateToProps)(PublishBeforeNAfter);
