import * as actions from '../../actions/actionTypes';

const initialState = 10;

export default function size(state = initialState, action) {
  switch (action.type) {
    case actions.SET_RSS_FEED:
      return action.payload.size;
    default:
      return state;
  }
}
