import React from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
//import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CommonDialog({ open, title, description, handleOk, handleCancel, fetching }) {
  return (
    <React.Fragment>
      <Dialog open={open} aria-labelledby="form-dialog-title" className={'pop_wrap pop_alert'}>
        <div className="pop_size_small">
          <p className="pop_comment">{title}</p>
          {fetching ? (
            <CircularProgress />
          ) : (
            <div>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" className="MuiDialogActions-root">
                  {description}
                </DialogContentText>
              </DialogContent>
              <div className="btn_set_wrap alC">
                <Button
                  className={'btn_color2'}
                  onClick={() => {
                    handleOk();
                  }}>
                  OK
                </Button>
                <Button
                  className={'btn_color1'}
                  onClick={() => {
                    handleCancel();
                  }}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </React.Fragment>
  );
}

CommonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};
