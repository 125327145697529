import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function regionCountries(state = initialState, action) {
  switch (action.type) {
    case actions.GET_HA_REGIONS:
      return action.payload;
    default:
      return state;
  }
}
