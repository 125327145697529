//feed receive
import * as actions from '../../actions/actionTypes';

const initialState = [];
export default function feeds(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CHANNEL_MAPPING:
      return action.payload;
    // case actions.SET_CHANNEL_COLLECT:
    //   return action.payload;
    default:
      return state;
  }
}
