import { combineReducers } from 'redux';
import fetching from './fetching';
import message from './message';
import status from './status';

export default combineReducers({
  fetching,
  message,
  status,
});
