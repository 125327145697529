import { combineReducers } from 'redux';
import sourceChannel from './sourceChannel';
import fetching from './fetching';
import last from './last';
import page from './page';
import size from './size';
import totalElements from './totalElements';
import totalPages from './totalPages';
import feeds from './feeds';
import history from './history';
import configlist from './configlist';
import confMap from './confMap';
import saved from './saved';
import precheck from './precheck';
import exports from './exports';
import failmessage from './failmessage';
import viewData from './viewData';
import original from './original';
import pbresult from './pbresult';
import revokeChannelNameList from './revokeChannelNameList';
import pbchannelnameresult from './pbchannelnameresult';
import publishChannelName from './publishChannelName';
import channelMappedData from './channelMappedData';
import updateMediaStaticUrl from './updateMediaStaticUrl';
import histConfMap from './histConfMap';
import channelMapReservation from './channelMapReservation';
import channelMapReservationByIdType from './channelMapReservationByIdType';
import channelMapHiddenData from './channelMapHiddenData';
import rsChannelIds from './rsChannelIds';
import addRSChannelIds from './addRSChannelIds';

export default combineReducers({
  sourceChannel,
  fetching,
  last,
  page,
  size,
  totalElements,
  totalPages,
  feeds, // feed receive data
  history,
  configlist,
  confMap,
  saved,
  precheck,
  exports,
  failmessage,
  viewData,
  original,
  pbresult,
  revokeChannelNameList,
  pbchannelnameresult,
  publishChannelName,
  updateMediaStaticUrl,
  channelMappedData,
  histConfMap,
  channelMapReservation,
  channelMapReservationByIdType,
  channelMapHiddenData,
  rsChannelIds,
  addRSChannelIds,
});
