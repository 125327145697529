export const countbyIssueDataSet = {
  countByIssue : '0'
  , countByNewIssue : '0'
  , unaddressedByIssue : '0'
  , processingByIssue : '0'
  , newFlag : 'N'
};

export const lineChartDataSet = {
  label: 'issue of issues',
  data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  fill: false,
  borderColor: '#9D8DF7',
  //point style
  pointBorderColor: '#9D8DF7',
  pointBackgroundColor: '#9D8DF7',
  pointBorderWidth: 2,
};

export const lineChartSet = {
  dataSet : {
    labels: ['01시', '02시', '03시', '04시', '05시', '06시', '07시', '08시', '09시', '10시', '11시', '12시', '13시', '14시', '15시', '16시', '17시', '18시', '19시', '20시', '21시', '22시', '23시', '24시'],
    datasets: [ lineChartDataSet ]
  },
  optionSet : {
    legend: {
      display: false,
    },
    scales: {
      y: {
        min: 0,
        max: 10,
      }
    }
  }
};
