import { combineReducers } from 'redux';
import channelMapIds from './channelMapIds';
import channels from './channels';
import channel from './channel';
import ch0104 from './ch0104';
import last from './last';
import page from './page';
import size from './size';
import totalElements from './totalElements';
import totalPages from './totalPages';
import chId from './chId';

export default combineReducers({
  channelMapIds,
  channels,
  channel,
  ch0104,
  last,
  page,
  size,
  totalElements,
  totalPages,
  chId,
});
