import * as actions from '../../actions/actionTypes';

const initialState = false;

export default function fetching(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PROVIDERS_FETCHING:
      return true;
    case actions.SET_PROVIDERS:
      return false;
    default:
      return state;
  }
}
