import React from 'react';

import { FeedBasicFilter, FeedTopTrendFilter, FeedDuplicatedErrorFilter, FeedResolveCountFilter } from './ContentFilter/ContentFilter';
import FeedBasicTrend from '../ComponentList/ContentComponent/FeedBasicTrend';
import FeedTopTrend from '../ComponentList/ContentComponent/FeedTopTrend';
import FeedDuplicatedErrorTrend from '../ComponentList/ContentComponent/FeedDuplicatedErrorTrend';
import FeedResolveTrend from '../ComponentList/ContentComponent/FeedResolveTrend';

import {
  BeaconTrendFilter,
  DurationAccessFilter,
  UniqueDeviceFilter,
  ContentFilter,
  DurationAccessBarFilter,
  BeaconBaselineDocCountTrendFilter
} from './StatisticsFilter/StatisticsFilter';
import ServiceTrendComponent from '../ComponentList/ServiceComponent/ServiceTrendComponent';
import ServiceTopTrendComponent from '../ComponentList/ServiceComponent/ServiceTopTrendComponent';
import ServiceResolveTrendComponent from '../ComponentList/ServiceComponent/ServiceResolveTrendComponent';
import { ServiceBasicAppFilter, ServiceBasicPlayFilter, ServiceTopAppFilter, ServiceTopPlayFilter, ServiceResolveTrendFilter } from './ServiceFilter/ServiceBasicFilter';
import BeaconTrendComponent from "../ComponentList/StatisticsComponent/BeaconTrendComponent";
import DurationAccessLineTrend from '../ComponentList/StatisticsComponent/DurationAccessLineTrend';
import UniqueDeviceLineTrend from '../ComponentList/StatisticsComponent/UniqueDeviceLineTrend';
import ContentLineTrend from '../ComponentList/StatisticsComponent/ContentLineTrend';
import DurationAccessBarTrend from '../ComponentList/StatisticsComponent/DurationAccessBarTrend';
import BeaconBaselineDocCountTrend from "../ComponentList/StatisticsComponent/BeaconBaselineDocCountTrend";

export const ComponentItems = [
  { id: 1, priority: 1, type: 'Service', name: 'App Issues', component: <ServiceTrendComponent />, checked: true, selected: true , filter: ServiceBasicAppFilter, copy: true, delete: false, show: false, table: {} },
  { id: 2, priority: 1, type: 'Service', name: 'App Top Issue Contents', component: <ServiceTopTrendComponent />, checked: false, selected: false , filter: ServiceTopAppFilter, copy: true, delete: false, show: false, table: {} },
  { id: 3, priority: 1, type: 'Service', name: 'Play Issues', component: <ServiceTrendComponent />, checked: false, selected: false , filter: ServiceBasicPlayFilter, copy: true, delete: false, show: false, table: {} },
  { id: 4, priority: 1, type: 'Service', name: 'Play Top Issue Contents', component: <ServiceTopTrendComponent />, checked: false, selected: false , filter: ServiceTopPlayFilter, copy: true, delete: false, show: false, table: {} },
  { id: 5, priority: 1, type: 'Service', name: 'Resolve Issue Contents', component: <ServiceResolveTrendComponent />, checked: false, selected: false , filter: ServiceResolveTrendFilter, copy: true, delete: false, show: false, table: {} },

  { id: 6, priority: 2, type: 'Statistics', name: 'Beacon Counts', component: <BeaconTrendComponent />, checked: false, selected: false , filter: BeaconTrendFilter, copy: true, delete: false, show: false, table: {} },
  { id: 7, priority: 2, type: 'Statistics', name: 'Periodic Beacon Counts', component: <BeaconBaselineDocCountTrend />, checked: false, selected: false , filter: BeaconBaselineDocCountTrendFilter, copy: true, delete: false, show: false, table: {} },
  { id: 8, priority: 2, type: 'Statistics', name: 'Duration & Access', component: <DurationAccessLineTrend />, checked: false, selected: false , filter: DurationAccessFilter, copy: true, delete: false, show: false, table: {} },
  { id: 9, priority: 2, type: 'Statistics', name: 'Unique Device', component: <UniqueDeviceLineTrend />, checked: false, selected: false , filter: UniqueDeviceFilter, copy: true, delete: false, show: false, table: {} },
  { id: 10, priority: 2, type: 'Statistics', name: 'Top Duration & Access Contents', component: <DurationAccessBarTrend />, checked: false, selected: false , filter: DurationAccessBarFilter, copy: true, delete: false, show: false, table: {} },
  { id: 11, priority: 2, type: 'Statistics', name: 'Statistics Per Contents', component: <ContentLineTrend />, checked: false, selected: false , filter: ContentFilter, copy: true, delete: false, show: false, table: {} },

  { id: 12, priority: 3, type: 'Content', name: 'CP Feed Counts', component: <FeedBasicTrend />, checked: false, selected: false , filter: FeedBasicFilter, copy: true, delete: false, show: false, table: {} },
  { id: 13, priority: 3, type: 'Content', name: 'Top Feed CPs', component: <FeedTopTrend />, checked: false, selected: false , filter: FeedTopTrendFilter, copy: true, delete: false, show: false, table: {} },
  { id: 14, priority: 3, type: 'Content', name: 'CP Error Status', component: <FeedDuplicatedErrorTrend />, checked: false, selected: false , filter: FeedDuplicatedErrorFilter, copy: true, delete: false, show: false, table: {} },
  { id: 15, priority: 3, type: 'Content', name: 'Feed Resolve Trend', component: <FeedResolveTrend />, checked: false, selected: false , filter: FeedResolveCountFilter, copy: true, delete: false, show: false, table: {} },
];

export const ComponentSize = [
  { index: 'Medium', name: 'Medium' },
  { index: 'Large', name: 'Large' },
];
