import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
});

function LoadingIndicator({ classes }) {
  return <CircularProgress className={classes.progress} />;
  // return (
  //   <Backdrop
  //     sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
  //     open={true}
  //     //onClick={handleClose}
  //   >
  //     <CircularProgress className={classes.progress} />
  //   </Backdrop>
  // );
}

LoadingIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoadingIndicator);
