import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import LoadingIndicator from '../../../common/LoadingIndicator';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';

export function ChannelDialog({ channelId, providerId, channelDialogOpen, closeChannelDialog, isRoleAdmin }) {
  const [item, setItem] = useState();
  //const [fetching, setFetching] = useState();
  //console.log('open', channelDialogOpen, '[id]', channelId, '[providerId]', providerId);
  // console.log('prog? true: false', prog ? true : false); //{}[] true, undefined/null/[].join() false,

  useEffect(() => {
    if (channelId) {
      //setFetching(true);
      fetch(isRoleAdmin ? API_BASE_URL + '/admin/providers/' + providerId + '/channels/' + channelId : API_BASE_URL + '/channels/' + channelId, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then(res => res.json())
        .then(body => {
          if (body.status) {
            alert('[server error] ' + body.message);
          } else {
            setItem(body);
          }
          //setFetching(false);
        })
        .catch(error => {
          //setFetching(false);
          alert('[error message] ' + error);
        });
    }
  }, [channelId]);

  return (
    <>
      {
        <div>
          {item && (
            <Dialog
              open={channelDialogOpen}
              onClose={closeChannelDialog}
              //aria-labelledby="form-dialog-title"
              className={'pop_wrap'}>
              <div className="MuiDialogTitle-root pop_head" id="form-dialog-title">
                <h2 className="MuiTypography-root MuiTypography-h6">
                  Channel Detail
                  <IconButton
                    aria-label="close" //???
                    className={'noTit_close'}
                    onClick={closeChannelDialog}>
                    <CloseIcon />
                  </IconButton>
                </h2>
              </div>
              <div className={'pop_body'}>
                <div className="tbl_wrap tbl_radius">
                  <table className="tbl_row_typ1 tbl_channel">
                    <colgroup>
                      <col style={{ Width: '13%' }} />
                      <col style={{ Width: '37%' }} />
                      <col style={{ Width: '13%' }} />
                      <col style={{ Width: 'auto' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>Channel ID</th>
                        <td colSpan={3}>{channelId}</td>
                      </tr>
                      <tr>
                        <th>Channel Name</th>
                        <td>{item.channelName}</td>
                        <th>CP Channel No.</th>
                        <td>{item.channelNumber}</td>
                      </tr>
                      <tr>
                        <th rowSpan={2}>Logo</th>
                        <td rowSpan={2}>
                          <div className="logo_preview_wrap">
                            <div>
                              <img
                                src={item.logoUrl}
                                title={item.logoUrl}
                                alt="invalid"
                                style={{
                                  width: 250, //Width는 작동안함
                                  maxHeight: 100,
                                  border: '1px solid #cbccce',
                                  background: '#fbfbfb', //=backgroundColor
                                }}
                              />
                            </div>
                            <div>
                              {/* <div> className="logo_black_area"> */}
                              <img
                                src={item.logoUrl}
                                title={item.logoUrl}
                                alt="invalid"
                                style={{
                                  width: 250,
                                  maxHeight: 100,
                                  border: '1px solid #cbccce',
                                  background: 'black',
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <th>Logo Width</th>
                        <td>{item.logoWidth}</td>
                      </tr>
                      <tr>
                        <th>Logo Height</th>
                        <td>{item.logoHeight}</td>
                      </tr>
                      <tr>
                        <th>Log URL</th>
                        <td colSpan={3}>{item.logoUrl}</td>
                      </tr>
                      {/*
                      <tr>
                        <th>Media Onetime Url</th>
                        <td colSpan={3}>{item.mediaOnetimeUrl}</td>
                      </tr>
                      <tr>
                        <th>Media Static Url</th>
                        <td colSpan={3}>{item.mediaStaticUrl}</td>
                      </tr>
                      */}
                      <tr>
                        <th>Resolution</th>
                        <td>{item.resolution}</td>
                        <th>Stream Container Type</th>
                        <td>{item.streamContainerType}</td>
                      </tr>
                      <tr>
                        <th>Channel Genre Code</th>
                        <td>{item.channelGenreCode}</td>
                        <th>Channel Type</th>
                        <td>{item.channelType}</td>
                      </tr>
                      <tr>
                        <th>Ad Flag</th>
                        <td>{item.adFlag ? 'true' : 'false'}</td>
                        <th>Adult Flag</th>
                        <td>{item.adultFlag ? 'true' : 'false'}</td>
                      </tr>
                      <tr>
                        <th>Paid Flag</th>
                        <td>{item.paidFlag ? 'true' : 'false'}</td>
                        <th>Promotion Flag</th>
                        <td>{item.promotionFlag ? 'true' : 'false'}</td>
                      </tr>
                      <tr>
                        <th>Active Time Period Start</th>
                        <td>{item.activeTimePeriodStart}</td>
                        <th>Active Time Period End</th>
                        <td>{item.activeTimePeriodEnd}</td>
                      </tr>
                      <tr>
                        <th>Contact</th>
                        <td colSpan={3}>{item.contact}</td>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="btn_set_wrap">
                  <Button
                    //color="secondary"
                    variant="contained"
                    className={'btn_color2'}
                    onClick={closeChannelDialog}>
                    Close
                  </Button>
                </div>
              </div>
            </Dialog>
          )}
        </div>
      }
    </>
  );
}
ChannelDialog.propTypes = {
  channelId: PropTypes.string,
  providerId: PropTypes.string,
  channelDialogOpen: PropTypes.bool.isRequired,
  closeChannelDialog: PropTypes.func.isRequired,
  isRoleAdmin: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  isRoleAdmin: state.currentUser.isRoleAdmin,
});
export default connect(mapStateToProps)(ChannelDialog);
