const initialState = [];

export const dmaGroupCodes = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DMA_GROUP_CODE_RESULT':
      return action.payload;
    default:
      return state;
  }
};
