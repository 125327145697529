import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IssTable from '../common/IssTable';
//import { ServicIssueSet } from '../DataSet/Service/ServiceIssueDataSet';
import { ServiceIssueGroupDataSet } from '../DataSet/Service/ServiceIssueGroupDataSet';
import PrimaryButton from '../common/PrimaryButton';
import CreateJiraModal from '../modal/CreateJira';
import ServiceDetailTable from '../common/ServiceDetailTable';
import ServiceIssueGroupTable from '../common/ServiceIssueGroupTable';
import { ISS_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import Alert from '../modal/Alert';
import Confirm from '../modal/Confirm';
import ModalPopup from '../modal/ModalPopup';
import Loading from '../common/Loading';
import IssTabs from '../common/IssTabs';
import IssTab from '../common/IssTab';
import PopImage from '../modal/PopImage';

function ServiceCommon( { searchParam, changeIssueTabCount, username } ) {
  const [issueCheckedList, setIssueCheckedList] = useState([]);
  const [isShowCreateJiraPopup, setShowCreateJiraPopup] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [isConfirm, setIsConfirm] = useState(false);
  const [confirmTitle, setConfirmitle] = useState('Confirm');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [issueList, setIssueList] = useState(ServiceIssueGroupDataSet.appData);
  const [issueTableType, setIssueTableType] = useState('app');
  const [isIssueView, setIsIssueView] = useState(false);
  const [similarIssueHistory, setSimilarIssueHistory] = useState(ServiceIssueGroupDataSet.appSimilarIssueHistory);
  const [focusItem, setFocusItem] = useState('');
  const [isImage, setIsImage] = useState(false);
  const defaultIssueGuide = {
    evidence : ''
    , actionDetails : ''
    , issueId: ''
    , issueCode: ''
    , issueCountry : ''
    , issueType: ''
    , issueRoot: ''
    , beaconImageUrl: ''
    , imageUrl: ''
  };
  const [actionPoints, setActionPoints] = useState(defaultIssueGuide);
  // Contents Info, Description
  const [contentsInfo, setContentsInfo] = useState(ServiceIssueGroupDataSet.contentsInfo);
  const [beaconDescription, setBeaconDescription] = useState(ServiceIssueGroupDataSet.beaconInspectionDescription);
  const [cmpDescription, setCmpDescription] = useState(ServiceIssueGroupDataSet.beaconInspectionDescription);
  // Contents Info, Description end
  const [actions, setActions] = useState([]);
  const [actionPointDetail, setActionPointDetail] = useState('');
  const [lastChangedStatus, setLastChangedStatus] = useState('');
  const [lastChangedAssign, setLastChangedAssign] = useState('');
  const [registerJiraissueList, setRegisterJiraissueList] = useState([]);
  const [isModalPopup, setModalPopup] = useState(false);
  const [modalPopupTitle, setModalPopupTitle] = useState('');
  const maxLength = 1000;
  const [currentSubTab, clickSubTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const tabMenus = [
    {
      name: 'Contents Info', tooltip: ''
    }
  ];
  const [param, savedParam] = useState({ param: '', tabIdx: 0, issueId: '' });

  useEffect(() => {
    let currentParam = '';
    currentParam += '&dates=' + searchParam.dates;
    currentParam += '&countries='+ searchParam.countries;
    currentParam += '&providers='+ searchParam.providers;
    currentParam += '&platforms='+ searchParam.platforms;
    currentParam += '&issueRoot=' + searchParam.issueRoot;
    currentParam += '&issueState=' + searchParam.issueState;
    currentParam += '&assignGroup=' + searchParam.assignGroup;
    currentParam += '&contentsType=' + searchParam.contentsType;
    currentParam += '&searchTextType=' + searchParam.searchType;
    currentParam += '&searchText=' + searchParam.searchText;
    savedParam({ param: currentParam, tabIdx: searchParam.currentTabIdx, issueId: searchParam.currentIssueId ? searchParam.currentIssueId : '' });
    getIssueList(currentParam, searchParam.currentTabIdx, searchParam.currentIssueId ? searchParam.currentIssueId : '');
    setCurrentPage(1);
  }, [ searchParam ]);

  const getIssueType = (currentTab) => {
    switch (currentTab) {
      case 0 :
        return 'app';
      case 1 :
          return 'play';
      case 2 :
        return 'schedule';
      default :
        return 'app';
    }
  };

  const getIssueDescription = () => {
    let description = "";
    let issueType = getIssueType(param.tabIdx);
    if (registerJiraissueList.length == 1) {
      let selectedIssueId = registerJiraissueList[0].split("_")[0];
      if (issueType === 'schedule') {
        for (let r of issueList.rowdata) {
          if (r.issueId === selectedIssueId && r.subTable == undefined) {
            description = `\u25CF Issue ID : `+r.issueId+`\n\u25CF Date : `+r.row[1];
            description += `\n\u25CF Country : `+r.row[4]+`\n\u25CF Platform : `+r.row[5];
            description += `\n\u25CF Error Code : `+r.issueCode+`\n\u25CF CP : `+r.contentId;
            description += `\n\u25CF Action Details : `+r.actionDescription;
            break;
          }
        }
      } else {
        for (let r of issueList.rowdata) {
          if (r.issueId === selectedIssueId && r.subTable == undefined) {
            description = `\u25CF Issue ID : `+r.issueId+`\n\u25CF Date : `+r.row[1];
            description += `\n\u25CF Source : `+r.issueRoot+`\n\u25CF Country : `+r.issueCountry;
            description += `\n\u25CF Platform : `+r.row[7]+`\n\u25CF Error Code : `+r.issueCode;
            description += `\n\u25CF CP : `+r.row[8]+`\n\u25CF Content ID : `+r.contentId;
            description += `\n\u25CF Content Name : `+r.row[11]+`\n\u25CF Issue Title : `+r.row[12];
            description += `\n\u25CF Action Details : `+r.actionDescription;
            break;
          }
        }
      }
    }
    return description;
  }

  const getIssueList = (param, currentTabIdx, currentIssueId) => {
    setIsIssueView(false);
    let issueType =  getIssueType(currentTabIdx);
    let url = `${ISS_BASE_URL}/api/service/issue/${issueType}`;
    let current = '?current=';
    if (currentIssueId){
      current += currentIssueId;
    }
    url = url + current;
    if (param){
      url = url + param;
    }
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        const type = body.issueType;
        const focusIssueId = body.focusIssueId;
        const assigneeDropdownItem = [];
        body.management.assignee.map(m =>
          assigneeDropdownItem.push( { index : m.groupName , name : m.groupName, itemType: 'assigneeGroup' } )
        );
        const assignGroupItem = {
          title : ''
          , dropdownItem : assigneeDropdownItem
        };
        changeIssueTabCount({
          app: body.dataCount.app
          , play: body.dataCount.play
          , serviceData: body.dataCount.serviceData
          , image: body.dataCount.image
          , schedule: body.dataCount.schedule
          , sum: body.dataCount.sum
        });
        if (type === 'app'){
          let issue = body.dataSet.map((n) => {
            const {
              issueId, issueDate, countryCode
              , providerId, issueSubType, contentId, contentName
              , issueState, assignGroup, jiraUrl, actionDescription
              , detailList, lastChgUsrId
            } = n;
            if (detailList.length > 1){
              let subData = detailList.map((sub) => {
                return {
                  row: [
                    sub.issueCodeLv
                    , sub.detailIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), sub.issueRoot, sub.platformList, providerId, contentId
                    , contentName, sub.shortMsg, '', '', ''
                    , '', ''
                  ]
                  , issueId : issueId
                  , rowId : issueId.concat('_', sub.issueCode, sub.issueRoot)
                  , issueCountry : countryCode
                  , issueCode : sub.issueCode
                  , issueType : type
                  , contentId : contentId
                  , isFocus : false
                  , issueRoot : sub.issueRoot
                  , rowType : 'SUB'
                  , actionDescription : actionDescription
                  , issueSubType : issueSubType
                  , issueCount : sub.detailIssueCount
                  , issueBeaconInfo : sub.beaconList
                  , imageUrl : sub.imageUrl
                };
              });
              return {
                row: [
                  issueId, issueDate, countryCode, issueSubType
                  , [...new Set(detailList.map(m => m.issueCodeLv))]
                  , detailList[0].detailIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').concat(' (', detailList.map(m => m.detailIssueCount).reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), ')')
                  , [...new Set(detailList.map(m => m.issueRoot))], [...new Set(detailList.map(m => m.platformList).reduce(function (acc, cur) {return acc.concat(cur);}).sort((a, b) => a.localeCompare(b)))], providerId, contentId
                  , contentName, detailList[0].shortMsg, issueState, { ...assignGroupItem, ...{ title : assignGroup } }, lastChgUsrId
                  , jiraUrl, actionDescription
                ]
                , issueId : issueId
                , rowId : issueId.concat('_', countryCode)
                , issueCountry : countryCode
                , issueCode : detailList[0].issueCode
                , issueType : type
                , contentId : contentId
                , isFocus : focusIssueId === issueId
                , issueRoot : detailList[0].issueRoot
                , rowType : 'MAIN'
                , actionDescription : actionDescription
                , issueSubType : issueSubType
                , issueCount : detailList[0].detailIssueCount
                , issueBeaconInfo : detailList[0].beaconList
                , imageUrl : detailList[0].imageUrl
                , subTable: {
                  colspanCount: 4
                  , rowdata: subData
                }
              };
            } else {
              return { row: [
                  issueId, issueDate, countryCode, issueSubType
                  , [detailList[0].issueCodeLv]
                  , detailList[0].detailIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), [detailList[0].issueRoot], detailList[0].platformList, providerId, contentId
                  , contentName, detailList[0].shortMsg, issueState, { ...assignGroupItem, ...{ title : assignGroup } }, lastChgUsrId
                  , jiraUrl, actionDescription
                ]
                , issueId : issueId
                , rowId : issueId.concat('_', countryCode)
                , issueCountry : countryCode
                , issueCode : detailList[0].issueCode
                , issueType : type
                , contentId : contentId
                , isFocus : focusIssueId === issueId
                , issueRoot : detailList[0].issueRoot
                , rowType : 'EACH'
                , actionDescription : actionDescription
                , issueSubType : issueSubType
                , issueCount : detailList[0].detailIssueCount
                , imageUrl : detailList[0].imageUrl
                , issueBeaconInfo : detailList[0].beaconList
              };
            }
          });
          if (focusIssueId){
            const focus = issue.find(f => f.isFocus === true);
            if (focus){
              onClickRowItem(focus);
            }
          } else {
            setCmpDescription(ServiceIssueGroupDataSet.appIssueGuide);
            setSimilarIssueHistory(ServiceIssueGroupDataSet.appSimilarIssueHistory);
          }
          setIssueList({ ...ServiceIssueGroupDataSet.appData, ...{ rowdata : issue } });
        } else if (type === 'play'){
          let issue = body.dataSet.map((n) => {
            const {
              issueId, issueDate, countryCode
              , providerId, issueSubType, contentId, contentName
              , issueState, assignGroup, jiraUrl, actionDescription
              , detailList, lastChgUsrId, channelNumber
            } = n;
            if (detailList.length > 1){
              let subData = detailList.map((sub) => {
                return {
                  row: [
                    sub.issueCodeLv
                    , sub.detailIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), sub.issueRoot, sub.platformList, providerId, channelNumber
                    , contentId, contentName , sub.shortMsg, '', ''
                    , '', '', ''
                  ]
                  , issueId : issueId
                  , rowId : issueId.concat('_', sub.issueCode, sub.issueRoot)
                  , issueCountry : countryCode
                  , issueCode : sub.issueCode
                  , issueType : type
                  , contentId : contentId
                  , isFocus : false
                  , issueRoot : sub.issueRoot
                  , rowType : 'SUB'
                  , actionDescription : actionDescription
                  , issueSubType : issueSubType
                  , issueCount : sub.detailIssueCount
                  , issueBeaconInfo : sub.beaconList
                  , imageUrl : sub.imageUrl
                };
              });
              return {
                row: [
                  issueId, issueDate, countryCode, issueSubType
                  , [...new Set(detailList.map(m => m.issueCodeLv))], detailList[0].detailIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').concat(' (', detailList.map(m => m.detailIssueCount).reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), ')')
                  , [...new Set(detailList.map(m => m.issueRoot))], [...new Set(detailList.map(m => m.platformList).reduce(function (acc, cur) {return acc.concat(cur);}).sort((a, b) => a.localeCompare(b)))], providerId, channelNumber
                  , contentId, contentName, detailList[0].shortMsg, issueState, { ...assignGroupItem, ...{ title : assignGroup } }
                  , lastChgUsrId, jiraUrl, actionDescription
                ]
                , issueId : issueId
                , rowId : issueId.concat('_', countryCode)
                , issueCountry : countryCode
                , issueCode : detailList[0].issueCode
                , issueType : type
                , contentId : contentId
                , isFocus : focusIssueId === issueId
                , issueRoot : detailList[0].issueRoot
                , rowType : 'MAIN'
                , actionDescription : actionDescription
                , issueSubType : issueSubType
                , issueCount : detailList[0].detailIssueCount
                , issueBeaconInfo : detailList[0].beaconList
                , imageUrl : detailList[0].imageUrl
                , subTable: {
                  colspanCount: 4
                  , rowdata: subData
                }
              };
            } else {
              return { row: [
                  issueId, issueDate, countryCode, issueSubType
                  , [detailList[0].issueCodeLv], detailList[0].detailIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  , [detailList[0].issueRoot], detailList[0].platformList, providerId, channelNumber
                  , contentId, contentName, detailList[0].shortMsg, issueState, { ...assignGroupItem, ...{ title : assignGroup } }
                  , lastChgUsrId, jiraUrl, actionDescription
                ]
                , issueId : issueId
                , rowId : issueId.concat('_', countryCode)
                , issueCountry : countryCode
                , issueCode : detailList[0].issueCode
                , issueType : type
                , contentId : contentId
                , isFocus : focusIssueId === issueId
                , issueRoot : detailList[0].issueRoot
                , rowType : 'EACH'
                , actionDescription : actionDescription
                , issueSubType : issueSubType
                , issueCount : detailList[0].detailIssueCount
                , imageUrl : detailList[0].imageUrl
                , issueBeaconInfo : detailList[0].beaconList
              };
            }
          });
          if (focusIssueId){
            const focus = issue.find(f => f.isFocus === true);
            if (focus){
              onClickRowItem(focus);
            }
          } else {
            setCmpDescription(ServiceIssueGroupDataSet.appIssueGuide);
            setSimilarIssueHistory(ServiceIssueGroupDataSet.playSimilarIssueHistory);
          }
          setIssueList({ ...ServiceIssueGroupDataSet.playData, ...{ rowdata : issue } });
        } else if (issueType === 'schedule'){
          let issue = body.dataSet.map((n) => {
            const {
              issueId, issueDate, providerId, issueState, assignGroup
              , issueCodeLv, jiraUrl, actionDescription, lastChgUsrId, contentCount
              , countryCodes, platforms, issueCode
            } = n;
            return { row: [
                issueId, issueDate, providerId, issueCodeLv, countryCodes.split(',')
                , platforms.split(','), contentCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), issueState, { ...assignGroupItem, ...{ title : assignGroup } }, lastChgUsrId
                , jiraUrl, actionDescription, issueId
              ]
              , issueId : issueId
              , rowId : issueId.concat('_', providerId)
              , issueCode : issueCode
              , issueType : type
              , contentId : providerId
              , isFocus : focusIssueId === issueId
              , rowType : 'EACH'
              , actionDescription : actionDescription
              , issueCount : contentCount
            };
          });
          if (currentIssueId){
            const focus = issue.find(f => f.isFocus === true);
            if (focus){
              onClickRowItem(focus);
            }
          } else {
            setCmpDescription(ServiceIssueGroupDataSet.scheduleIssueGuide);
            setSimilarIssueHistory(ServiceIssueGroupDataSet.scheduleSimilarHistory);
          }
          setIssueList({ ...ServiceIssueGroupDataSet.scheduleData, ...{ rowdata : issue } });
        } else {
          setIssueList(ServiceIssueGroupDataSet.appData)
          setCmpDescription(ServiceIssueGroupDataSet.scheduleIssueGuide);
          setSimilarIssueHistory(ServiceIssueGroupDataSet.appSimilarIssueHistory);
        }
        setIssueTableType(issueType);
        if (!currentIssueId){
          setActionPoints(defaultIssueGuide);
          setFocusItem('');
        }
        setActionPointDetail('');
      } else {
        setAlertTitle('Error');
        setAlertMessage('[getIssueList : error]');
        setIsAlert(true);
      }
    })
    .catch(error => {
      console.log("getIssueList - error: " , error);
    }).finally(() => {
      setIsIssueView(true);
    });
  };


  const onOpenAlert = () => {
    setIsAlert(true);
  };
  const onCloseAlert = () => {
    setIsAlert(false);
  };
  const onApprovefirm  = () => {
    saveIssue(searchParam.currentTabIdx);
  };
  const onCloseConfirm  = () => {
    setIsConfirm(false);
  };
  const OnSaveAction = () => {
    if (!issueCheckedList || issueCheckedList.length < 1){
      setAlertTitle('Warning');
      setAlertMessage('Please select any checkbox.');
      setIsAlert(true);
      return;
    }

    const saveAction = {
      actionList: []
      , issueState : lastChangedStatus
      , assignGroup: lastChangedAssign
      , actionDescription : actionPointDetail
      , user : username === 'admin' ? 'cmpuser' : username
    };
    issueCheckedList.forEach(item => {
      let id = item.split('_');
      if (id.length == 2){
        saveAction.actionList.push({
          issueId: id[0]
          , countryCode: id[1]
        });
      }
    });
    setActions(saveAction);
    setConfirmMessage('Would you like to post a solution ?');
    setIsConfirm(true);
  };

  const saveIssue = (currentTabIdx) => {
    let issueType =  getIssueType(currentTabIdx);
    let url = `${ISS_BASE_URL}/api/service/issue/${issueType}/actions`;
    fetch(url, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(actions)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('INFO');
        setAlertMessage('Issue update success.');
        setIsAlert(true);
        getIssueList(param.param, param.tabIdx, param.issueId);
      } else {
        setAlertTitle('Error');
        setAlertMessage('[saveIssue : error]');
        setIsAlert(true);
      }
    })
    .catch(error => {
      console.log("saveIssue - error: " , error);
    }).finally(() => {
      setIsConfirm(false);
    });
  };

  const OnOpenCreateJiraPopop = () => {
    let jiraIssueList = issueCheckedList.filter(f => f.indexOf('main') < 0);
    if (issueCheckedList.length > 0) {
      setRegisterJiraissueList(jiraIssueList);
      setShowCreateJiraPopup(true);
    } else {
      setAlertTitle('Warning');
      setAlertMessage('Please select any checkbox');
      onOpenAlert();
    }
  };

  const OnCloseCreateJiraPopop = () => {
    setShowCreateJiraPopup(false);
  };

  const handleJiraCallback = (data) => {
    if (data.result === 'SUCCESS') {
      setAlertTitle('Info');
      setAlertMessage('Jira creation success');
      onOpenAlert();
      getIssueList(param.param, param.tabIdx, param.issueId);
    } else {
      setAlertMessage('Jira creation failure, reason: ' + data.reason);
      onOpenAlert();
    }
  };

  const handleJiraStatusCallback = (status) => {
    if (status === 'processing') {
      setModalPopupTitle('Create Jira');
      setModalPopup(true);
    } else {
      setModalPopup(false);
    }
  };
  const onClickRowItem = item => {
    if (item && item.issueId !== '') {
      setFocusItem(item.rowId);
      // evidenceVal
      let evidenceVal = '[' + item.issueCode + '] ' + item.issueCountry;
      setActionPoints({ ...actionPoints, ...{
        evidence : evidenceVal, issueId : item.issueId, issueCode : item.issueCode, issueCountry : item.issueCountry, issueRoot : item.issueRoot
        , issueType : item.issueType, beaconImageUrl : item.beaconImageUrl, imageUrl : item.imageUrl
      } });
      // Guide and SimilarHistoryList
      getServiceInspectionGuide(item.issueType, item.issueId, item.issueCode, item.issueRoot);
      getSimilarIssueHistoryList(item.issueType, item.contentId, item.issueCode, item.issueCountry);
    }
  };

  const onClickContentsInfoRowItem = (item) => {
    if (item.inspectionList && item.inspectionList.length > 0){
      let inspectionDescription = item.inspectionList.map((n) => {
        const { statusCode, method, result, resultDescription } = n;
        return { row: [statusCode, method, result, resultDescription] };
      });
      setBeaconDescription({ ...ServiceIssueGroupDataSet.beaconInspectionDescription, ...{ rowdata : inspectionDescription } });
    } else if (item.errorResult && item.errorResult.length > 0){
      let errorDescription = item.errorResult.map((n) => {
        const { errorDescription, errorGuide } = n;
        return { row: [errorDescription, errorGuide] };
      });
      setBeaconDescription({ ...ServiceIssueGroupDataSet.beaconErrorDescription, ...{ rowdata : errorDescription } });
    } else {
      setBeaconDescription(ServiceIssueGroupDataSet.beaconInspectionDescription);
    }
  };
  const getServiceInspectionGuide = (issueType, issueId, issueCode, issueRoot) => {
    let param = `?issueType=${issueType}&issueRoot=${issueRoot}&issueId=${issueId}&issueCode=${issueCode}&menuType=DETAIL` ;
    let url = `${ISS_BASE_URL}/api/service/issue/description${param}`;
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        if (issueType === 'app' || issueType === 'play'){
          let contents = body.dataSet.map((n) => {
            const {
              issueId, countryCode, contentId, contentName, providerId, issueCode, issueRoot
              , platformCode,  beaconRoot, channelNumber, programId, extraInformation, rawDataUrl, crtDate, lastChgDate
              , inspectionList, errorResult
            } = n;
            return {
              row: [platformCode, beaconRoot, channelNumber, crtDate, lastChgDate, extraInformation, rawDataUrl]
              , inspectionList: inspectionList
              , errorResult: errorResult
            };
          });
          setContentsInfo({ ...ServiceIssueGroupDataSet.contentsInfo, ...{ rowdata : contents } });
          if (contents && contents.length > 0 && contents[0].inspectionList && contents[0].inspectionList.length){
            const inspectionDescription = contents[0].inspectionList.map((n) => {
              const { statusCode, method, result, resultDescription } = n;
              return { row: [statusCode, method, result, resultDescription] };
            });
            setBeaconDescription({ ...ServiceIssueGroupDataSet.beaconInspectionDescription, ...{ rowdata : inspectionDescription } });
          } else if (contents && contents.length > 0 && contents[0].errorResult && contents[0].errorResult.length){
            const errorDescription = contents[0].errorResult.map((n) => {
              const { errorDescription, errorGuide } = n;
              return { row: [errorDescription, errorGuide] };
            });
            setBeaconDescription({ ...ServiceIssueGroupDataSet.beaconErrorDescription, ...{ rowdata : errorDescription } });
          } else {
            setBeaconDescription(ServiceIssueGroupDataSet.beaconInspectionDescription);
          }
        } else if (issueType === 'schedule'){
          let issue = body.dataSet.map((n) => {
            const {
              contentId, contentName, endDateTime, issueGuide
            } = n;
            return { row: [contentId, contentName, endDateTime, issueGuide] };
          });
          setCmpDescription({ ...ServiceIssueGroupDataSet.scheduleIssueGuide, ...{ rowdata : issue } });
        } else {
          setCmpDescription(ServiceIssueGroupDataSet.scheduleIssueGuide);
          setContentsInfo(ServiceIssueGroupDataSet.contentsInfo);
          setBeaconDescription(ServiceIssueGroupDataSet.beaconInspectionDescription);
        }
      } else {
        setAlertTitle('Error');
        setAlertMessage('[getServiceInspectionGuide : error]');
        setIsAlert(true);
      }
    })
    .catch(error => {
      console.log("getServiceInspectionGuide - error: " , error);
    }).finally(() => {});
  };
  const getSimilarIssueHistoryList = (issueType, contentId, issueCode, issueCountry) => {
    let param = `?issueType=${issueType}&contentId=${contentId}&issueCode=${issueCode}&issueCountry=${issueCountry}`;
    let url = `${ISS_BASE_URL}/api/service/similar/issue${param}`;
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        if (body.issueType === 'app'){
          let similar = body.dataSet.map((n) => {
            const {
              issueId, issueDate, countryCode, issueCount, providerId, issueSubType, contentId
              , contentName, issueState, assignGroup, jiraUrl, actionDescription, issueRoot, shortMsg
              , platformList, lastChgUsrId, issueCodeLv
            } = n;
            return { row: [
                issueId, issueDate, countryCode, issueSubType
                , issueCodeLv
                , issueCount, issueRoot, platformList, providerId, contentId
                , contentName, shortMsg, issueState, assignGroup, lastChgUsrId
                , jiraUrl, actionDescription
              ]
            };
          });
          setSimilarIssueHistory({ ...ServiceIssueGroupDataSet.appSimilarIssueHistory, ...{ rowdata : similar } });

        } else if (body.issueType === 'play'){
          let similar = body.dataSet.map((n) => {
            const {
              issueId, issueDate, countryCode, issueCount, providerId, issueSubType, contentId
              , contentName, issueState, assignGroup, jiraUrl, actionDescription, issueRoot, shortMsg
              , platformList, lastChgUsrId, issueCodeLv, channelNumber
            } = n;
            return { row: [
                issueId, issueDate, countryCode, issueSubType, issueCodeLv
                , issueCount, issueRoot, platformList, providerId, channelNumber
                , contentId, contentName, shortMsg, issueState, assignGroup
                , lastChgUsrId, jiraUrl, actionDescription
              ]
            };
          });
          setSimilarIssueHistory({ ...ServiceIssueGroupDataSet.playSimilarIssueHistory, ...{ rowdata : similar } });

        } else if (issueType === 'schedule'){
          let issue = body.dataSet.map((n) => {
            const {
              issueId, issueDate, providerId, issueState, assignGroup
              , issueCodeLv, jiraUrl, actionDescription, lastChgUsrId, contentCount
              , countryCodes, platforms, issueCode
            } = n;
            return { row: [
              issueId, issueDate, providerId, issueCodeLv, countryCodes.split(',')
              , platforms.split(','), contentCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), issueState, assignGroup, lastChgUsrId
              , jiraUrl, actionDescription
              ]
              , issueId : issueId, issueCode : issueCode
            };
          });
          setSimilarIssueHistory({ ...ServiceIssueGroupDataSet.scheduleSimilarHistory, ...{ rowdata : issue } });
        }
      } else {
        setAlertTitle('Error');
        setAlertMessage('[getSimilarIssueHistoryList : error]');
        setIsAlert(true);
      }
    })
    .catch(error => {
      console.log("getSimilarIssueHistoryList - error: " , error);
    }).finally(() => {});
  };

  const valueInitialize = () => {
    // setValueInitNeed(true);
  };

  const handleTextAreaChange = event => {
    const { value } = event.target;
    if (value.length <= maxLength) {
      setActionPointDetail(value);
    }
  };

  const isGroupingIssue = (action) => {
    if (action.issueType === 'app' || action.issueType === 'play') {
      return true;
    }
    return false;
  };

  const isGroupingBeaconIssue = (action) => {
    if (action.issueType === 'app' || action.issueType === 'play') {
      if (action.issueRoot === '	beacon'){
        return true;
      }
    }
    return false;
  };

  const tabChangeHandler = (selectedIdx, _data) => {
    clickSubTab(selectedIdx);
  };

  const actionsTabHandler = (selectedIdx, _data) => {
    return;
  };

  const onOpenImage = () => {
    setIsImage(true);
  };

  const onCloseImage = () => {
    setIsImage(false);
  };

  const changeDropDownAlert = () => {
    setAlertTitle('Warning');
    setAlertMessage('Select the checkbox to change the Dropdown box.');
    onOpenAlert();
  };

  return (
    <>
      <div className="contents-section-wide">
        {isIssueView ? (
          <div className="grid-row-wide">
            <div className="row-box box-tbl box-lgchannels">
              <ServiceIssueGroupTable
                height={'calc(100% - 62px)'}
                pagination={true}
                tableType={issueTableType}
                data={issueList}
                btnIconType={true}
                handleClickItem={item => onClickRowItem(item)}
                changeDropDownAlert={changeDropDownAlert}
                pagingChangeCallback={() => valueInitialize()}
                focusItem={focusItem}
                setFocusItem={setFocusItem}
                setIssueCheckedList={setIssueCheckedList}
                setLastChangedStatus={setLastChangedStatus}
                setLastChangedAssign={setLastChangedAssign}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                sortingItem={{ enable: true, sortColumn: -1, sortDirection: 'desc' }}
              />
            </div>
            <div className="row-box box-actionPoints">
              <div className="row">
                <div className="head">
                  <div className="title">
                    <h2>Action Points</h2>
                  </div>
                </div>
                {/* TODO */}
                {(isGroupingIssue(actionPoints)) ? (
                <div className="grid-column-3">
                  {( actionPoints.issueRoot === 'beacon' || actionPoints.issueRoot === 'AutoTest') && (
                    <React.Fragment>
                    <div className="box-screen-shot">
                      <img src = {actionPoints.imageUrl} width = "100%" height = "100%" onClick={onOpenImage}/>
                    </div>
                    <div className="box-contents-info">
                      <h3>Contents Info</h3>
                      <IssTable pagination={false} data={contentsInfo}
                        handleClickItem={item => onClickContentsInfoRowItem(item)}
                      />
                    </div>
                    <div className={'box-beacon-des'}>
                      <h3>Description</h3>
                      <IssTable pagination={false} data={beaconDescription} />
                    </div>
                    </React.Fragment>
                  )}
                  {( actionPoints.issueRoot === 'CMP') && (
                    <div className={'box-cmp-des'}>
                      <h3>Description</h3>
                      <IssTable pagination={false} data={cmpDescription} />
                    </div>
                  )}
                  {( actionPoints.issueRoot === 'telemetry') && (
                    <div className={'box-des-service'}>
                      <h3>Description</h3>
                      <IssTable pagination={false} data={cmpDescription} />
                    </div>
                  )}
                  <div className="box-actionDetail">
                    <h3>Action Details (Count : {issueCheckedList.length})</h3>
                    <textarea placeholder="Enter action details" value={actionPointDetail} onChange={event => handleTextAreaChange(event)}></textarea>
                    <p>
                      {actionPointDetail ? actionPointDetail.length : 0}/{maxLength}
                    </p>
                  </div>
                </div>
                ) : (
                <div className="grid-column-3">
                  <div className={ 'box-des-service' }>
                    <h3>Description</h3>
                    <IssTable pagination={false} data={cmpDescription} />
                  </div>
                  <div className="box-actionDetail">
                    <h3>Action Details (Count : {issueCheckedList.length})</h3>
                    <textarea placeholder="Enter action details" value={actionPointDetail} onChange={event => handleTextAreaChange(event)}></textarea>
                    <p>
                      {actionPointDetail ? actionPointDetail.length : 0}/{maxLength}
                    </p>
                  </div>
                </div>
                )}
                {/* TODO */}
                <div className="button-container align-right">
                  <PrimaryButton type={'button'} text={'Save'} btnClass={'btn-primary-gray'} onClick={OnSaveAction}/>
                  <PrimaryButton type={'button'} text={'Create Jira'} btnClass={'btn-primary-accent'} onClick={OnOpenCreateJiraPopop} />
                </div>
              </div>
              <div className="row">
                <div className="box-issueHistory">
                  <IssTable pagination={false} data={similarIssueHistory} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="loading_wrap">
            <Loading isVisible={true} />
          </div>
        )}
        {isShowCreateJiraPopup &&
          <CreateJiraModal
            type={getIssueType(searchParam.currentTabIdx)}
            issueIdList={registerJiraissueList}
            isOpen={isShowCreateJiraPopup}
            onClose={OnCloseCreateJiraPopop}
            jiraCallback={handleJiraCallback}
            jiraStatusCallback={handleJiraStatusCallback}
            jiraDescription={getIssueDescription()}
          />
        }
        {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
        {isConfirm &&
          <Confirm isOpen={isConfirm} title={confirmTitle} message={confirmMessage}
            onApprove={onApprovefirm}
            onClose={onCloseConfirm}
          />
        }
        {isModalPopup && <ModalPopup isOpen={isModalPopup} title={modalPopupTitle}/>}
        {isGroupingIssue(actionPoints) && isImage && <PopImage isOpen={isImage} title={actionPoints.issueId} url={actionPoints.imageUrl} onClose={onCloseImage}/>}
      </div>
    </>
  );
}
ServiceCommon.propTypes = {
  username: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  username: state.currentUser.username,
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceCommon));
