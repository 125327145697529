import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LoadingIndicator from '../../../common/LoadingIndicator';
import Button from '@material-ui/core/Button';
import ProgramTable from './ProgramTable';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';

function Program({ providerId, programId, history }) {
  const [fetching, setFetching] = useState();
  const [prog, setProg] = useState();
  // console.log('[providerId]', providerId, '[programId]', programId);
  // console.log(providerId ? true : false);

  useEffect(() => {
    if (programId) {
      setFetching(true);
      fetch(
        providerId && providerId !== 'null' && providerId !== 'undefined' //router link paramter는 string으로 변환되어 전달됨
          ? API_BASE_URL + '/admin/providers/' + providerId + '/programs/' + programId
          : API_BASE_URL + '/programs/' + programId,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
        .then(res => res.json())
        .then(body => {
          //console.log('[then]', body);
          if (body.status) {
            alert('[server error] ' + body.message);
          } else {
            setProg(body);
          }
          setFetching(false);
        })
        .catch(error => {
          setFetching(false);
          alert('[error message] ' + error);
        });
    }
  }, [providerId, programId]);

  // useEffect(() => {
  //   if (programs) {
  //     setProg(programs.find((item) => item.programId === id)); //filter-if no matched, return []
  //   }
  // }, [programs]);

  return (
    <>
      {fetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        <div>
          {prog && <ProgramTable prog={prog} />}
          <div className="btn_set_wrap">
            <Button
              color="secondary"
              variant="contained"
              className={'btn_color2'}
              onClick={() => {
                history.push('/PROGRAM/programlist');
                //return <Route path="/PROGRAM/program" />;
              }}>
              List
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
Program.propTypes = {
  providerId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
export default withRouter(Program);
