//import * as actions from '../../actions/actionTypes';

const initialState = [{ criterion: '', criterionDetail: '' }];

export default function categoryRule(state = initialState, action) {
  switch (action.type) {
    case 'SET_CATEGORY_RULE':
      //console.log('[admins][payload]', action.payload);
      return action.payload;
    default:
      return state;
  }
}
