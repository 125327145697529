import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

function DynamicDropDown(props) {
  const { rowId, items, title, isDropDownValueInit, onChange, checkedList } = props;
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState(title);

  useEffect(() => {
    if (isDropDownValueInit) {
      setLabel(title);
    }
  }, [isDropDownValueInit]);

  const onBlur = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const onClick = e => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onSetLabel = (e, item) => {
    e.stopPropagation();
    const selectItem = {
      index : item.index
      , name : item.name
      , rowId : rowId
      , itemType : item.itemType
      , isChange : item.name !== title
      , isChecked : checkedList.includes(rowId)
    };
    if (checkedList.includes(rowId)){
      setLabel(item.name);
    }
    onChange(selectItem);
    setOpen(false);
  };

  return (
    <>
      <div tabIndex={0} className={classNames('ui-dropdown-type02', { on: open })} onBlur={e => onBlur(e)}>
        <div className="label" onClick={e => onClick(e)}>
          {label || title}
        </div>
        <ul className={open ? 'on' : ''}>
          {items && items.map((item, index) => (
            <li key={index} onClick={(e) => onSetLabel(e, item)}>
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default DynamicDropDown;
