import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ManualTable from './ManualTable';
import { request } from '../../../util/request';
import { getRegionProvidersAdminAction } from '../../../actions/providerActions';
import { getHeaders } from '../../../util/actionUtil';
import { API_URL } from '../../../constants';

export default function SliderSearch({ cat, cntry, selectSearch, status, categoryResultData, tableRef, region }) {
  const [cp, setCp] = React.useState('All');
  const typeList = (cat.contentType || '').split(',');
  const [type, setType] = React.useState(typeList[0] || '');
  const [id, setId] = React.useState('');
  const [name, setName] = React.useState('');
  const [data, setData] = React.useState([]);

  const clickSearch = async event => {
    event.preventDefault();
    const result = await request(`/api/manualSearch?cntry=${cntry}&type=${type}&cp=${cp}&id=${id}&name=${name}&status=${status}`, 'GET', null, true);
    setData(
      result.map(n => {
        return { ...n, type: type };
      }),
    );
  };

  return (
    <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list'}>
      <CpFilter2 typeList={typeList} type={type} setType={setType} cp={cp} setCp={setCp} id={id} setId={setId} name={name} setName={setName} region={region} />
      <div className="btn_set_wrap search_wrap manual">
        <Button className="btn_color2" onClick={clickSearch}>
          search
        </Button>
      </div>

      <ManualTable
        data={data}
        tableRef={tableRef}
        select={selectSearch}
        dlagDropFlag={false}
        options={{
          selection: true,
          showTextRowsSelected: false,
          // selectionProps: () => ({
          //   checked: false,
          // }),
          pageSize: 15,
          pageSizeOptions: [5, 15, 30, 50, 100],
          doubleHorizontalScroll: true,
          maxBodyHeight: 500,
          minBodyHeight: 500,
          rowStyle: rowData => ({
            backgroundColor: categoryResultData.find(x => x.content_id === rowData.id && x.cp_id === rowData.cp) ? '#e0e0e0' : rowData.tableData.checked ? '#fef5f8' : '#FFF',
            color: rowData.tableData.checked ? '#d8295c' : '#484848',
            pointerEvents: !rowData.tableData.checked && categoryResultData.find(x => x.content_id === rowData.id && x.cp_id === rowData.cp) ? 'none' : 'initial',
          }),
          selectionProps: rowData => {
            // console.log('selectionProps start');
            const findCurrentRow = categoryResultData.find(x => x.content_id === rowData.id && x.cp_id === rowData.cp);
            // console.log(categoryResultData, rowData, findCurrentRow);
            rowData.tableData.disabled = findCurrentRow ? true : false;
            // console.log('selectionProps end');
            return {
              disabled: findCurrentRow ? true : false,
            };
          },
        }}
      />
    </div>
  );
}
SliderSearch.propTypes = {};

function CpFilter({ cplist, typeList, type, setType, cp, setCp, id, setId, name, setName, regionProvider, getRegionProvidersAdminAction, region }) {
  const [cpDetail, setCPDetails] = React.useState('All');
  React.useEffect(() => {
    getRegionProvidersAdminAction(region);
    const apiUrl = `/api/admin/providers`;
      fetch(`${API_URL}${apiUrl}`, {
        method: 'GET',
        headers: getHeaders(),
      }).then(res => res.json())
        .then(body => {
          const bodyFilter = body.list.map(data => data.providerId);
          setCPDetails(bodyFilter);
        });
  }, []);

  const AllCountry = ['All'];
  const cpNameList = [...cpDetail, ...AllCountry];
  return (
    <div className="filter_area row manualTop">
      <div className="filter_row manualTop">
        <FormControl variant="outlined">
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            onChange={e => {
              setType(e.target.value);
            }}>
            {typeList.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>CP</InputLabel>
          <Select
            value={cp}
            onChange={e => {
              setCp(e.target.value);
            }}>
            {cpNameList.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField //FormControl안에 넣으면 줄안맞음
          fullWidth
          margin="dense"
          variant="outlined"
          label="id" //focus안줬을때 mandatory text
          value={id}
          onChange={e => {
            setId(e.target.value);
          }}
        />
        <TextField //FormControl안에 넣으면 줄안맞음
          fullWidth
          margin="dense"
          variant="outlined"
          label="name" //focus안줬을때 mandatory text
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
CpFilter.propTypes = {
  regionProvider: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  //cplist: state.cplist.cplist,
  regionProvider: state.providers.regionProvider,
});
// const mapDispatchToProps = dispatch => ({
//   getTabCategoryAction(region, status) {
//     dispatch(getTabCategoryAction(region, status));
//   },
// });
const mapDispatchToProps = dispatch => ({
  getRegionProvidersAdminAction(region) {
    dispatch(getRegionProvidersAdminAction(region));
  },
});
const CpFilter2 = connect(mapStateToProps, mapDispatchToProps)(CpFilter);
