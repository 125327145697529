import React, { useEffect, useState } from 'react';
import Loading from '../../../common/Loading';
import { Bar } from 'react-chartjs-2';
import Alert from '../../../modal/Alert';
import { CHART_COLORS, CHART_COLORS_BY_COUNTRY, CHART_COLORS_BY_PROVIDER } from '../../../common/ChartConsts';
import { getHeaders } from '../../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../../constants/index';
import { feed_top_trend_data, feed_top_trend_data_by_country } from './FeedTrendData';

function FeedTopTrend(props) {
  const { height
        , title
        , originalFilter
        , currentFilter
        , updateTable
      } = props;
  const [trendData, setTrendData] = useState({data: {}, options: {}});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [isTrendDisplay, setTrendDisplay] = useState(false);
  const [topTrend, setTopTrend] = useState('total');
  const [topLimit, setTopLimit] = useState(5);

  useEffect(() => {
    if (currentFilter) {
      console.log(`[ISS] FeedTopTrend, filter: `, currentFilter);
      setTrendDisplay(false);
      setTrendData({});
      getTopTrend(currentFilter);
    }
  }, [currentFilter]);

  const processData = (data) => {
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.country]) {
        acc[item.country] = [];
      }
      acc[item.country].push(item);
      return acc;
    }, {});

    return groupedData;
  };

  const hexToRgba = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  function getColor(compareType, item) {
    if (compareType === 'Country') {
      return CHART_COLORS_BY_COUNTRY[item] || CHART_COLORS_BY_COUNTRY['OTHERS'];
    } else {
      return CHART_COLORS_BY_PROVIDER[item] || CHART_COLORS_BY_PROVIDER['OTHERS'];
    }
  }

  const getTopTrend = (filter) => {
    const tempCustomCheckboxFilter = [];
    const tempCustomRadioFilter = [];

    filter.customCheckboxFilter && filter.customCheckboxFilter.map((item, index) => {
      const [key, value] = Object.entries(item)[0];
      tempCustomCheckboxFilter.push(`${key}=${value.join(',')}`);
    });

    filter.customRadioFilter && filter.customRadioFilter.map((item, index) => {
      const [key, value] = Object.entries(item)[0];
      tempCustomRadioFilter.push(`${key}=${value}`);
    });

    const customCheckboxFilter = tempCustomCheckboxFilter ? tempCustomCheckboxFilter.join('&') : '';
    const customRadioFilter = tempCustomRadioFilter ? tempCustomRadioFilter.join('&') : '';
    const tempInterval = filter.interval ? filter.interval : 'd';
    const tempProvider = filter.providers ? filter.providers : '';
    const tempCountry = filter.country ? filter.country : '';
    const compareType = filter.compareType ? filter.compareType : 'None';

    let param = '';
    if (customCheckboxFilter && customRadioFilter) {
      param = `date=${filter.date}&interval=${tempInterval}&provider=${tempProvider}&${customCheckboxFilter}&${customRadioFilter}&country=${tempCountry}&compare=${compareType}`;
    } else if (customCheckboxFilter) {
      param = `date=${filter.date}&interval=${tempInterval}&provider=${tempProvider}&${customCheckboxFilter}&country=${tempCountry}&compare=${compareType}`;
    } else if (customRadioFilter) {
      param = `date=${filter.date}&interval=${tempInterval}&provider=${tempProvider}&${customRadioFilter}&country=${tempCountry}&compare=${compareType}`;
    } else {
      param = `date=${filter.date}&interval=${tempInterval}&provider=${tempProvider}&country=${tempCountry}&compare=${compareType}`;
    }

    fetch(`${ISS_BASE_URL}/api/application/dashboard/trend/feed/top?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          const tempTopTrend = currentFilter.customRadioFilter && currentFilter.customRadioFilter.find(item => item.hasOwnProperty('top')).top;
          const tempTopLimit = currentFilter.customRadioFilter && currentFilter.customRadioFilter.find(item => item.hasOwnProperty('limit')).limit;

          const topTrend = tempTopTrend ? tempTopTrend : 'total';
          setTopTrend(topTrend);
          const topLimit = tempTopLimit ? tempTopLimit : '5';
          setTopLimit(topLimit);

          const groupedData = processData(body.dataSet);

          let data;
          if (body.compareType === 'Country') {
            const labels = Object.keys(groupedData).flatMap(country => groupedData[country].map(item => `${country}_${item.providerId}`));

            const datasets = Object.keys(groupedData).map(country => ({
              label: country,
              data: labels.map(label => {
                const [labelCountry, labelId] = label.split('_');
                if (labelCountry === country) {
                  const item = groupedData[country].find(item => item.providerId === labelId);
                  return item ? (topTrend === 'total' ? item.totalCnt : item.failCnt) : null;
                }
                return null;
              }),
              backgroundColor: hexToRgba(getColor(body.compareType, country), 1),
              borderColor: hexToRgba(getColor(body.compareType, country), 1),
              borderWidth: 1
            }));

            data = {
              labels,
              datasets
            };
          } else {
            data = {
              labels: body.dataSet.map(item => item.providerId),
              datasets: [
              {
                label: `${topTrend}`,
                fill: false,
                pointRadius: 3,
                backgroundColor: body.dataSet.map(item => hexToRgba(getColor(body.compareType, item.providerId), 1)),
                borderColor: body.dataSet.map(item => hexToRgba(getColor(body.compareType, item.providerId), 1)),
                pointBorderColor: '#FFFFFF',
                pointBackgroundColor: body.dataSet.map(item => hexToRgba(getColor(body.compareType, item.providerId), 1)),
                pointBorderWidth: 2,
                order: 2,
                data: body.dataSet.map(item => topTrend === 'total' ? item.totalCnt : item.failCnt),
              }]
            };
          }

          const options = {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x: {
                ticks: {
                  callback: function(value, index, values) {
                    const label = this.getLabelForValue(value);
                    return label.split('_').length > 1 ? label.split('_')[1] : label;
                  }
                }
              },
              y: {
                ticks: {
                  stepSize: 2,
                },
                border: {
                  display: false,
                },
              },
            },
            plugins: {
              datalabels: {
                display: false,
              },
              legend: {
                display: true,
                labels: {
                  usePointStyle: true,
                  boxWidth: 6,
                  boxHeight: 6,
                  color: '#fff',
                  font: {
                    size: 12,
                  },
                },
              },
            },
          };

          setTrendData({data: data, options: options});

          // update table
          if (body.compareType === 'Country') {
            feed_top_trend_data_by_country.rowdata = body.dataSet.map(item => ({
              row: [
                item.country,
                item.providerId,
                item.totalCnt,
                item.failCnt
              ],
            }));
            updateTable(feed_top_trend_data_by_country);
          } else {
            feed_top_trend_data.rowdata = body.dataSet.map(item => ({
              row: [
                item.providerId,
                item.totalCnt,
                item.failCnt
              ],
            }));
            updateTable(feed_top_trend_data);
          }
          // update table

          setTrendDisplay(true);
        } else {
          setAlertMessage(`trend feed top error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`trend feed top error: `, error);
        onOpenAlert();
      })
      .finally(() => {});
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
    {
      isTrendDisplay ? (
        <div style={{ height: height }}>
          <div className='trend-head'>
            <div className="title" style={{top: "-30px"}}>
              <div> {title} ( {topTrend} Top {topLimit} ) </div>
            </div>
          </div>
          <Bar data={trendData.data} options={trendData.options} height='100%' />
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )
    }
    {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default FeedTopTrend;