//export const API_BASE_URL = 'http://10.157.88.222:8080/api';
export const API_BASE_URL = '/api';
//export const API_BASE_SDPAPI_URL = 'http://10.157.88.222:8080/sdpapi';
export const API_BASE_SDPAPI_URL = '/sdpapi';
//export const API_URL = 'http://10.157.88.222:8080';
export const API_URL = '';
//export const API_BASE_LOCAL_URL = 'http://10.157.88.222:8080';
export const API_BASE_LOCAL_URL = '';
export const MOCK_URL = 'http://13.125.140.150:8080/';
// ISS URL START
export const ISS_BASE_URL = `${window.location.host.indexOf('channel.lgtvcommon.com') > -1 ? (window.location.host.indexOf('dev') > -1 || window.location.host.indexOf('qt2') > -1 ? 'https://dev.cmpiss.lgtvcommon.com' : 'https://cmpiss.lgtvcommon.com'): 'http://localhost:9080' }`;
// ISS URL END
export const ACCESS_TOKEN = 'accessToken';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
// New Role and Auth
export const ROLE_SW_DEV = 'ROLE_SW_DEV';
export const ROLE_CP = 'ROLE_CP';
export const ROLE_CHANNEL_OPS = 'ROLE_CHANNEL_OPS';
export const ROLE_SERVICE_BIZ = 'ROLE_SERVICE_BIZ';
export const ROLE_LOCAL_CORP = 'ROLE_LOCAL_CORP';
export const ROLE_ISS_OPS = 'ROLE_ISS_OPS';
export const ROLE_AUTH_VIEW = 'ROLE_AUTH_VIEW';
export const ROLE_AUTH_EDIT = 'ROLE_AUTH_EDIT';

export const PROGRAM_LIST_SIZE = 5000; //serverMax=2000
export const CHANNEL_LIST_SIZE = 1000;
export const CHANNEL_CHANNEL_LIST_SIZE = 10000000; // channel channel
export const CHANNEL_CHANNEL_CONF_LIST_SIZE = 1000000; // channel channel
export const CHANNEL_CHANNEL_FEED_LIST_SIZE = 1000000; // channel channel
export const DISPLAY_RULES_LIST_SIZE = 1000; // Display Rules
export const PROMOTION_HOMESHELF_ITEMDISPLAY_LIST_SIZE = 1000; // promotion homeShelf DisplayRules
export const SCHEDULE_LIST_SIZE = 10;
export const METADATAFILE_LIST_SIZE = 10;
export const PROVIDER_LIST_SIZE = 10;
export const WHITELISTIP_LIST_SIZE = 10;
export const USER_LIST_SIZE = 10;
export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;
export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;
export const EMAIL_MAX_LENGTH = 40;
export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;
export const PROGRAM_TITLE_MAX_LENGTH = 140;
export const PROGRAM_DESCRIPTION_MAX_LENGTH = 1000;
export const PROGRAM_GENRE_MAX_LENGTH = 40;
export const PROVIDER_ID_MIN_LENGTH = 3;
export const PROVIDER_ID_MAX_LENGTH = 5;
export const IP_ADDRESS_MIN_LENGTH = 7;
export const IP_ADDRESS_MAX_LENGTH = 39;
export const PROVIDER_NAME_MIN_LENGTH = 5;
export const PROVIDER_NAME_MAX_LENGTH = 30;
export const APP_ID_MIN_LENGTH = 5;
export const APP_ID_MAX_LENGTH = 100;
export const INTERACTIVE_URL_MIN_LENGTH = 5;
export const INTERACTIVE_URL_MAX_LENGTH = 500;
export const LOGO_URL_MIN_LENGTH = 5;
export const LOGO_URL_MAX_LENGTH = 500;
export const DRAWER_WIDTH = 200; //200px인데 실제로는 200px보다 작은 164정도임
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const URL_REGEX = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/;
export const TABCATEGORY_AUTO_LIMIT_COUNT = 100;
