import React from 'react';
//import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export default function Display({ cat }) {
  return (
    <Typography>
      {' '}
      Region:{' '}
      <Typography component="span" variant="h5">
        {cat.region}
      </Typography>{' '}
      | Tab:{' '}
      <Typography component="span" variant="h5">
        {cat.parentName}
      </Typography>{' '}
      | Category:{' '}
      <Typography component="span" variant="h5">
        {cat.name}
      </Typography>{' '}
      {cat.mappingType === 'manual' && (
        <span>
           <br />
          Category Type:{' '}
          <Typography component="span" variant="h5">
            {cat.categoryType}
            &nbsp;
          </Typography>
        </span>
      )}
      | Content Type:{' '}
      <Typography component="span" variant="h5">
        {cat.contentType}
        &nbsp;
      </Typography>
      {cat.mappingType === 'manual' && (
        <span>
      | Mapping Type:{' '}
      <Typography component="span" variant="h5">
        {cat.mappingType}
      </Typography>
      </span>
      )}
    </Typography>
  );
}

Display.propTypes = {};
