import * as actions from '../../actions/actionTypes';

const initialState = null;

export default function original(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ORIGINAL:
      return action.payload;
    default:
      return state;
  }
}
