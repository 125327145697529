import { SET_SCHEDULES, SET_SCHEDULES_FETCHING, SET_SCHEDULES_ERROR } from './actionTypes';

import { API_BASE_URL } from '../constants';

import { getHeaders } from '../util/actionUtil';

const setScheduleFetchingAction = value => ({
  type: SET_SCHEDULES_FETCHING,
  payload: value,
});

const fetchGetUserCreatedSchedulesSuccess = body => ({
  type: SET_SCHEDULES,
  payload: body,
});

const fetchGetUserCreatedSchedulesFailure = ex => ({
  type: SET_SCHEDULES_ERROR,
  payload: ex,
});

export const getSchedulesAction = (cntry, cp, date, isRoleAdmin) => dispatch => {
  let apiUrl;

  cntry = cntry || 'US';
  date = date || '2020-03-16T00:00:00Z';
  if (isRoleAdmin) {
    apiUrl = API_BASE_URL + '/admin/schedules?date=' + date + '&countryCode=' + cntry;
    if (cp !== 'All') apiUrl += '&providerId=' + cp;
  } else {
    apiUrl = API_BASE_URL + '/schedules?date=' + date + '&countryCode=' + cntry;
  }

  dispatch(setScheduleFetchingAction(true));
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch(fetchGetUserCreatedSchedulesSuccess(body)))
    .catch(ex => dispatch(fetchGetUserCreatedSchedulesFailure(ex)));
};
