import React, { useEffect, useState } from 'react';
import DatePickerDefault from '../../common/DatePickerDefault';
import IconButton from '../../common/IconButton';
import { CHART_COLORS } from '../../common/ChartConsts';
import LineChart from '../../common/LineChart';
import { issue_history_total_data } from './IssueHistoryTotalData';
import annotationPlugin from 'chartjs-plugin-annotation';
import IssTable from '../../common/IssTable';
import DoughnutChart from '../../common/DoughnutChart';
import DefaultButton from '../../common/DefaultButton';
import RadioChartFilter from '../../common/RadioChartFilter';
import BarChart from '../../common/BarChart';

import { getHeaders } from '../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../constants/index';
import Loading from '../../common/Loading';
import Alert from '../../modal/Alert';
import xlsx from 'json-as-xlsx';

const IssueHistoryTotal = ({ filterCallback }) => {
  const plugin = [annotationPlugin];

  const [calendarValue, setCalendarValue] = useState([]); // [startDate, endDate] eg, 2023-08-20, 2023-09-19
  const [currentFilter, setCurrentFilter] = useState({ date: '', country: '', provider: '', platform: '' });
  const [rangeDate, setRangeDate] = useState([]);
  const [isHistoryDisplay, setHistoryDisplay] = useState(false);
  const [isHistorySubDisplay, setHistorySubDisplay] = useState(false);
  const [totalTableData, setTotalTableData] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [chartType, setChartType] = useState('Line');

  useEffect(() => {
    if (currentFilter && currentFilter.date.length > 0) {
      filterCallback(currentFilter);
    }
  }, [currentFilter]);

  useEffect(() => {
    defaultRangeDate();
  }, []);

  useEffect(() => {
    if (rangeDate && rangeDate.length > 0) {
      setHistoryDisplay(false);
      setHistorySubDisplay(false);
      getDashboardHistoryInfo('SERVICE');
      getDashboardHistoryInfo('CONTENTS');
      getDashboardHistoryInfo('STATISTICS');
      getDashboardHistorySubtypeInfo('SERVICE');
      getDashboardHistorySubtypeInfo('CONTENTS');
      getDashboardHistorySubtypeInfo('STATISTICS');
    }
  }, [rangeDate]);

  let maxYCandidateList = [];
  let issueCountDataSets = [];
  let issueResolvedDataSets = [];
  let issueCumulativeResolvedDataSets = [];
  let serviceIssueInfo = {
    issueCount: [],
    cumulativeIssueCount: [],
    resolvedIssueCount: [],
    cumulativeResolvedIssueCount: [],
  };
  let contentIssueInfo = {
    issueCount: [],
    cumulativeIssueCount: [],
    resolvedIssueCount: [],
    cumulativeResolvedIssueCount: [],
  };
  let statisticsIssueInfo = {
    issueCount: [],
    cumulativeIssueCount: [],
    resolvedIssueCount: [],
    cumulativeResolvedIssueCount: [],
  };

  const getDashboardHistoryInfo = type => {
    fetch(`${ISS_BASE_URL}/api/common/history/${type}/info?date=${rangeDate}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          const dateList = body.dataSet.map(item => item.date.substring(5, 10));
          const options = {
            scales: {
              y: {
                min: 0,
                max: 0,
                ticks: {
                  stepSize: 10,
                },
              },
            },
          };

          let issueCountItem = {
            label: '',
            data: [],
            fill: false,
            pointRadius: 3,
            borderColor: '',
            pointBorderColor: '#FFFFFF',
            pointBackgroundColor: '#85DDDB',
            pointBorderWidth: 2,
            order: 2,
          };

          let issueResolvedItem = {
            label: '',
            data: [],
            fill: false,
            pointRadius: 3,
            borderColor: '',
            pointBorderColor: '#FFFFFF',
            pointBackgroundColor: '#85DDDB',
            pointBorderWidth: 2,
            order: 2,
          };

          let issueCumulativeResolvedItem = {
            label: '',
            data: [],
            fill: false,
            pointRadius: 3,
            borderColor: '',
            pointBorderColor: '#FFFFFF',
            pointBackgroundColor: '#85DDDB',
            pointBorderWidth: 2,
            order: 2,
          };

          if (body.type === 'SERVICE') {
            // Daily Issue Count
            issueCountItem.label = 'service';
            issueCountItem.backgroundColor = `${CHART_COLORS.BLUE}`;
            issueCountItem.borderColor = `${CHART_COLORS.BLUE}`;
            issueCountItem.pointBackgroundColor = `${CHART_COLORS.BLUE}`;
            issueCountItem.data = body.dataSet.map(item => item.issueCount);
            maxYCandidateList.push(Math.max(...issueCountItem.data));
            issueCountDataSets.push(issueCountItem);

            // Daily Issue Resolved Rate
            issueResolvedItem.label = 'service';
            issueResolvedItem.backgroundColor = `${CHART_COLORS.BLUE}`;
            issueResolvedItem.borderColor = `${CHART_COLORS.BLUE}`;
            issueResolvedItem.pointBackgroundColor = `${CHART_COLORS.BLUE}`;
            issueResolvedItem.data = body.dataSet.map(item => item.resolvedRate);
            issueResolvedDataSets.push(issueResolvedItem);

            // Daily Cumulative Resolved Rate
            issueCumulativeResolvedItem.label = 'service';
            issueCumulativeResolvedItem.backgroundColor = `${CHART_COLORS.BLUE}`;
            issueCumulativeResolvedItem.borderColor = `${CHART_COLORS.BLUE}`;
            issueCumulativeResolvedItem.pointBackgroundColor = `${CHART_COLORS.BLUE}`;
            issueCumulativeResolvedItem.data = body.dataSet.map(item => item.cumulativeResolvedRate);
            issueCumulativeResolvedDataSets.push(issueCumulativeResolvedItem);

            // Set table values for Service Issues
            serviceIssueInfo.issueCount = body.dataSet.map(item => item.issueCount);
            serviceIssueInfo.cumulativeIssueCount = body.dataSet.map(item => item.cumulativeIssueCount);
            serviceIssueInfo.resolvedIssueCount = body.dataSet.map(item => item.resolvedIssueCount);
            serviceIssueInfo.cumulativeResolvedIssueCount = body.dataSet.map(item => item.cumulativeResolvedIssueCount);
          } else if (body.type === 'CONTENTS') {
            // Daily Issue Count
            issueCountItem.label = 'content';
            issueCountItem.backgroundColor = `${CHART_COLORS.PINK}`;
            issueCountItem.borderColor = `${CHART_COLORS.PINK}`;
            issueCountItem.pointBackgroundColor = `${CHART_COLORS.PINK}`;
            issueCountItem.data = body.dataSet.map(item => item.issueCount);
            maxYCandidateList.push(Math.max(...issueCountItem.data));
            issueCountDataSets.push(issueCountItem);

            // Daily Issue Resolved Rate
            issueResolvedItem.label = 'content';
            issueResolvedItem.backgroundColor = `${CHART_COLORS.PINK}`;
            issueResolvedItem.borderColor = `${CHART_COLORS.PINK}`;
            issueResolvedItem.pointBackgroundColor = `${CHART_COLORS.PINK}`;
            issueResolvedItem.data = body.dataSet.map(item => item.resolvedRate);
            issueResolvedDataSets.push(issueResolvedItem);

            // Daily Cumulative Resolved Rate
            issueCumulativeResolvedItem.label = 'content';
            issueCumulativeResolvedItem.backgroundColor = `${CHART_COLORS.PINK}`;
            issueCumulativeResolvedItem.borderColor = `${CHART_COLORS.PINK}`;
            issueCumulativeResolvedItem.pointBackgroundColor = `${CHART_COLORS.PINK}`;
            issueCumulativeResolvedItem.data = body.dataSet.map(item => item.cumulativeResolvedRate);
            issueCumulativeResolvedDataSets.push(issueCumulativeResolvedItem);

            // Set table values for Content Issues
            contentIssueInfo.issueCount = body.dataSet.map(item => item.issueCount);
            contentIssueInfo.cumulativeIssueCount = body.dataSet.map(item => item.cumulativeIssueCount);
            contentIssueInfo.resolvedIssueCount = body.dataSet.map(item => item.resolvedIssueCount);
            contentIssueInfo.cumulativeResolvedIssueCount = body.dataSet.map(item => item.cumulativeResolvedIssueCount);
          } else if (body.type === 'STATISTICS') {
            // Daily Issue Count
            issueCountItem.label = 'statistics';
            issueCountItem.backgroundColor = `${CHART_COLORS.MINT}`;
            issueCountItem.borderColor = `${CHART_COLORS.MINT}`;
            issueCountItem.pointBackgroundColor = `${CHART_COLORS.MINT}`;
            issueCountItem.data = body.dataSet.map(item => item.issueCount);
            maxYCandidateList.push(Math.max(...issueCountItem.data));
            issueCountDataSets.push(issueCountItem);

            // Daily Issue Resolved Rate
            issueResolvedItem.label = 'statistics';
            issueResolvedItem.backgroundColor = `${CHART_COLORS.MINT}`;
            issueResolvedItem.borderColor = `${CHART_COLORS.MINT}`;
            issueResolvedItem.pointBackgroundColor = `${CHART_COLORS.MINT}`;
            issueResolvedItem.data = body.dataSet.map(item => item.resolvedRate);
            issueResolvedDataSets.push(issueResolvedItem);

            // Daily Cumulative Resolved Rate
            issueCumulativeResolvedItem.label = 'statistics';
            issueCumulativeResolvedItem.backgroundColor = `${CHART_COLORS.MINT}`;
            issueCumulativeResolvedItem.borderColor = `${CHART_COLORS.MINT}`;
            issueCumulativeResolvedItem.pointBackgroundColor = `${CHART_COLORS.MINT}`;
            issueCumulativeResolvedItem.data = body.dataSet.map(item => item.cumulativeResolvedRate);
            issueCumulativeResolvedDataSets.push(issueCumulativeResolvedItem);

            // Set table values for Statistics Issues
            statisticsIssueInfo.issueCount = body.dataSet.map(item => item.issueCount);
            statisticsIssueInfo.cumulativeIssueCount = body.dataSet.map(item => item.cumulativeIssueCount);
            statisticsIssueInfo.resolvedIssueCount = body.dataSet.map(item => item.resolvedIssueCount);
            statisticsIssueInfo.cumulativeResolvedIssueCount = body.dataSet.map(item => item.cumulativeResolvedIssueCount);
          }

          if (issueCountDataSets.length === 3) {
            // Daily Issue Count
            options.scales.y.max = Math.max(...maxYCandidateList) + 10;
            issue_history_total_data.daily_total_error_occurrence.data.labels = dateList;
            issue_history_total_data.daily_total_error_occurrence.data.datasets = [];
            issue_history_total_data.daily_total_error_occurrence.data.datasets = issueCountDataSets;
            issue_history_total_data.daily_total_error_occurrence.options = options;

            // Daily Issue Resolved Rate
            issue_history_total_data.daily_total_error_resolution.data.labels = dateList;
            issue_history_total_data.daily_total_error_resolution.data.datasets = [];
            issue_history_total_data.daily_total_error_resolution.data.datasets = issueResolvedDataSets;

            // Daily Cumulative Resolved Rate
            issue_history_total_data.daily_total_error_cumulative_resolution.data.labels = dateList;
            issue_history_total_data.daily_total_error_cumulative_resolution.data.datasets = [];
            issue_history_total_data.daily_total_error_cumulative_resolution.data.datasets = issueCumulativeResolvedDataSets;

            // Set table values
            const tableDataList = body.dataSet.map(item => item.date.substring(2, 10));

            let tempTableData = [];
            let tableValueList = [];
            for (let i = 0; i < tableDataList.length; i++) {
              let tableItem = {
                row: [],
              };

              tableItem.row.push(tableDataList[i]);
              tableItem.row.push(serviceIssueInfo.issueCount[i]);
              tableItem.row.push(contentIssueInfo.issueCount[i]);
              tableItem.row.push(statisticsIssueInfo.issueCount[i]);
              tableItem.row.push(serviceIssueInfo.cumulativeIssueCount[i]);
              tableItem.row.push(contentIssueInfo.cumulativeIssueCount[i]);
              tableItem.row.push(statisticsIssueInfo.cumulativeIssueCount[i]);
              tableItem.row.push(serviceIssueInfo.resolvedIssueCount[i]);
              tableItem.row.push(contentIssueInfo.resolvedIssueCount[i]);
              tableItem.row.push(statisticsIssueInfo.resolvedIssueCount[i]);
              tableItem.row.push(serviceIssueInfo.cumulativeResolvedIssueCount[i]);
              tableItem.row.push(contentIssueInfo.cumulativeResolvedIssueCount[i]);
              tableItem.row.push(statisticsIssueInfo.cumulativeResolvedIssueCount[i]);

              tableValueList.push(tableItem);

              // Excel 다운로드를 위한 table data 저장
              let tableDataItem = {
                date: tableDataList[i],
                serviceIssueCount: serviceIssueInfo.issueCount[i],
                contentIssueCount: contentIssueInfo.issueCount[i],
                statisticsIssueCount: statisticsIssueInfo.issueCount[i],
                cumulativeServiceIssueCount: serviceIssueInfo.cumulativeIssueCount[i],
                cumulativeContentIssueCount: contentIssueInfo.cumulativeIssueCount[i],
                cumulativeStatisticsIssueCount: statisticsIssueInfo.cumulativeIssueCount[i],
                resolvedServiceIssueCount: serviceIssueInfo.resolvedIssueCount[i],
                resolvedContentIssueCount: contentIssueInfo.resolvedIssueCount[i],
                resolvedStatisticsIssueCount: statisticsIssueInfo.resolvedIssueCount[i],
                cumulativeResolvedServiceIssueCount: serviceIssueInfo.cumulativeResolvedIssueCount[i],
                cumulativeResolvedContentIssueCount: contentIssueInfo.cumulativeResolvedIssueCount[i],
                cumulativeResolvedStatisticsIssueCount: statisticsIssueInfo.cumulativeResolvedIssueCount[i]
              };
              tempTableData.push(tableDataItem);
            }

            setTotalTableData(tempTableData);

            issue_history_total_data.issue_history_total_table.rowdata = [...tableValueList].reverse();

            setHistoryDisplay(true);
          }
        } else {
          setAlertMessage(`getContentHistoryInfo error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`getContentHistoryInfo error:${error}`);
        onOpenAlert();
      });
  };

  let issueSubtypeDataSets = [];
  const getDashboardHistorySubtypeInfo = type => {
    fetch(`${ISS_BASE_URL}/api/common/history/${type}/subtype/info?date=${rangeDate}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          if (body.type === 'SERVICE') {
            issueSubtypeDataSets.push(body.type);
            const appIssue = body.dataSet.filter(item => item.subType === 'app');
            const imageIssue = body.dataSet.filter(item => item.subType === 'image');
            const metaIssue = body.dataSet.filter(item => item.subType === 'meta');
            const scheduleIssue = body.dataSet.filter(item => item.subType === 'schedule');
            const playIssue = body.dataSet.filter(item => item.subType === 'play');

            issue_history_total_data.data_service.datasets[0].data = [appIssue[0].issueCount, imageIssue[0].issueCount, metaIssue[0].issueCount, scheduleIssue[0].issueCount, playIssue[0].issueCount];
          } else if (body.type === 'CONTENTS') {
            issueSubtypeDataSets.push(body.type);
            const feedIssue = body.dataSet.filter(item => item.subType === 'feed');
            const adminIssue = body.dataSet.filter(item => item.subType === 'admin');
            const batchIssue = body.dataSet.filter(item => item.subType === 'batch');

            issue_history_total_data.data_contents.datasets[0].data = [feedIssue[0].issueCount, adminIssue[0].issueCount, batchIssue[0].issueCount];
          } else if (body.type === 'STATISTICS') {
            issueSubtypeDataSets.push(body.type);
            const statisticsIssue = body.dataSet.filter(item => item.subType === 'statistics');
            const beaconIssue = body.dataSet.filter(item => item.subType === 'beacon');

            issue_history_total_data.data_statistics.datasets[0].data = [statisticsIssue[0].issueCount, beaconIssue[0].issueCount];
          }

          if (issueSubtypeDataSets.length === 3) {
            setHistorySubDisplay(true);
          }
        } else {
          setAlertMessage(`getDashboardHistorySubtypeInfo error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`getDashboardHistorySubtypeInfo error:${error}`);
        onOpenAlert();
      });
  }

  // Default date는 30일
  const defaultRangeDays = 30; // 달력에 날짜를 보여 주기 위한 default 일자, 30일
  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());

    const thirtyDaysAgo = new Date(utcToday);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);

    const utcTodayString = utcToday.toISOString().substring(0, 10);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0, 10);

    setRangeDate([thirtyDaysAgoString, utcTodayString]); // [startDate, endDate]
  };

  const issueSearchByButton = () => {
    setCurrentFilter({ date: calendarValue, country: '', provider: '', platform: '' });
    setRangeDate([calendarValue[0], calendarValue[1]]); // [startDate, endDate]
  };

  const excelDownload = () => {
    let data = [
      {
        sheet: `Total_Issue`,
        columns: [
          { label: 'Date', value: (row) => row.date },
          { label: '당일발생오류(Service)', value: (row) => row.serviceIssueCount },
          { label: '당일발생오류(Content)', value: (row) => row.contentIssueCount },
          { label: '당일발생오류(Stastistics)', value: (row) => row.statisticsIssueCount },

          { label: '누적발생오류(Service)', value: (row) => row.cumulativeServiceIssueCount },
          { label: '누적발생오류(Content)', value: (row) => row.cumulativeContentIssueCount },
          { label: '누적발생오류(Stastistics)', value: (row) => row.cumulativeServiceIssueCount },

          { label: '당일발생오류해결건(Service)', value: (row) => row.resolvedServiceIssueCount },
          { label: '당일발생오류해결건(Content)', value: (row) => row.resolvedContentIssueCount },
          { label: '당일발생오류해결건(Stastistics)', value: (row) => row.resolvedStatisticsIssueCount },

          { label: '누적발생오류해결건(Service)', value: (row) => row.cumulativeResolvedServiceIssueCount },
          { label: '누적발생오류해결건(Content)', value: (row) => row.cumulativeResolvedContentIssueCount },
          { label: '누적발생오류해결건(Stastistics)', value: (row) => row.cumulativeResolvedStatisticsIssueCount },
        ],
        content: [...totalTableData],
      },
    ];
    let xLSettings = {
      fileName: `Total_issue`,
    };
    xlsx(data, xLSettings);
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const chartChangeHandler = value => {
    setChartType(value);
  };

  return (
    <>
      <div className="ui-components">
        <div className="box-left">
          <div className="ui-datepicker-box">
            <DatePickerDefault dateChangeHandler={newDate => setCalendarValue(newDate)} />
          </div>
          <div className="ui-dropdown-box">
            <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={issueSearchByButton} />
          </div>
          <div style ={{ padding:'0px 0px 0px 15px', border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px' }}>
            <RadioChartFilter chartType={chartType} onChangeRadio={chartChangeHandler} />
          </div>
        </div>
        <div className="box-btns">
          <IconButton type={'button'} text={'Excel Download'} btnClass={'btn-icon-download'} clickEvent={excelDownload} />
        </div>
      </div>
      {isHistoryDisplay && isHistorySubDisplay ? (
        <div className="grid-row">
          <div className="row">
            <div className="box-total">
              <div className="head">
                <div className="title">
                  <h2>Number of issues (based on occurrence date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.BLUE}` }}></i>
                      <span>Service</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Content</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Statistics</span>
                    </li>
                  </ul>
                </div>
                {
                  chartType === 'Line' ? (
                    <LineChart id={'daily_total_error_occurrence'} data={issue_history_total_data.daily_total_error_occurrence.data}
                    options={issue_history_total_data.daily_total_error_occurrence.options} plugins={plugin} />
                  ) : (
                    <BarChart id={'daily_total_error_occurrence'} data={issue_history_total_data.daily_total_error_occurrence.data}
                    options={issue_history_total_data.daily_total_error_occurrence.options} plugins={plugin}/>
                  )
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-total">
              <div className="head">
                <div className="title">
                  <h2>Issue resolution rate (based on occurrence date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.BLUE}` }}></i>
                      <span>Service</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Content</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Statistics</span>
                    </li>
                  </ul>
                </div>
                {
                  chartType === 'Line' ? (
                    <LineChart id={'daily_total_error_resolution'} data={issue_history_total_data.daily_total_error_resolution.data}
                    options={issue_history_total_data.daily_total_error_resolution.options} plugins={plugin} />
                  ) : (
                    <BarChart id={'daily_total_error_resolution'} data={issue_history_total_data.daily_total_error_resolution.data}
                    options={issue_history_total_data.daily_total_error_resolution.options} plugins={plugin}/>
                  )
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-total">
              <div className="head">
                <div className="title">
                  <h2>Issue resolution rate (based on cumulative daily date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.BLUE}` }}></i>
                      <span>Service</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Content</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Statistics</span>
                    </li>
                  </ul>
                </div>
                {
                  chartType === 'Line' ? (
                    <LineChart id={'daily_total_error_cumulative_resolution'} data={issue_history_total_data.daily_total_error_cumulative_resolution.data}
                    options={issue_history_total_data.daily_total_error_cumulative_resolution.options} plugins={plugin} />
                  ) : (
                    <BarChart id={'daily_total_error_cumulative_resolution'} data={issue_history_total_data.daily_total_error_cumulative_resolution.data}
                    options={issue_history_total_data.daily_total_error_cumulative_resolution.options} plugins={plugin}/>
                  )
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-total">
              <div className="circle-chart-container">
                <div className="circle-chart">
                  <div className="chart-wrap">
                    <DoughnutChart data={issue_history_total_data.data_service} width={'100%'} height={'100%'} />
                  </div>
                  <div className="chart-line-legend">
                    <ul>
                      <li>
                        <i style={{ backgroundColor: `${CHART_COLORS.PURPLE}` }}></i>
                        <span>LG Channels HomeApp</span>
                      </li>
                      <li>
                        <i style={{ backgroundColor: `${CHART_COLORS.BLUE}` }}></i>
                        <span>Schedule</span>
                      </li>
                      <li>
                        <i style={{ backgroundColor: `${CHART_COLORS.ORANGE}` }}></i>
                        <span>Playback</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="circle-chart">
                  <div className="chart-wrap">
                    <DoughnutChart data={issue_history_total_data.data_contents} width={'100%'} height={'100%'} />
                  </div>
                  <div className="chart-line-legend">
                    <ul>
                      <li>
                        <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                        <span>CP</span>
                      </li>
                      <li>
                        <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                        <span>CMP Organization</span>
                      </li>
                      <li>
                        <i style={{ backgroundColor: `${CHART_COLORS.BLUE}` }}></i>
                        <span>Data Deployment</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="circle-chart">
                  <div className="chart-wrap">
                    <DoughnutChart data={issue_history_total_data.data_statistics} width={'100%'} height={'100%'} />
                  </div>
                  <div className="chart-line-legend">
                    <ul>
                      <li>
                        <i style={{ backgroundColor: `${CHART_COLORS.PURPLE}` }}></i>
                        <span>Statistics</span>
                      </li>
                      <li>
                        <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                        <span>Beacon Data</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-issueHistory-total">
              <IssTable height={'100%'} pagination={false} data={issue_history_total_data.issue_history_total_table} />
            </div>
          </div>
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )}
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
};
export default IssueHistoryTotal;
