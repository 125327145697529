import * as actions from '../../actions/actionTypes';

import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_SW_DEV, ROLE_CP, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ, ROLE_LOCAL_CORP, ROLE_ISS_OPS } from '../../constants';

const initialState = '';

export default function userRole(state = initialState, action) {
  switch (action.type) {
    case actions.INIT_CURRENT_USER:
      return initialState;
    case actions.SET_CURRENT_USER:
      if (action.body && action.body.roles) {
        if (action.body.roles.find(i => i.name === ROLE_ADMIN || i.name === ROLE_SUPER_ADMIN)) {
          return ROLE_ADMIN;
        } else if (action.body.roles.find(i => i.name === ROLE_SW_DEV)) {
          return ROLE_SW_DEV;
        } else if (action.body.roles.find(i => i.name === ROLE_CP)) {
          return ROLE_CP;
        } else if (action.body.roles.find(i => i.name === ROLE_CHANNEL_OPS)) {
          return ROLE_CHANNEL_OPS;
        } else if (action.body.roles.find(i => i.name === ROLE_SERVICE_BIZ)) {
          return ROLE_SERVICE_BIZ;
        } else if (action.body.roles.find(i => i.name === ROLE_LOCAL_CORP)) {
          return ROLE_LOCAL_CORP;
        } else if (action.body.roles.find(i => i.name === ROLE_ISS_OPS)) {
          return ROLE_ISS_OPS;
        } else {
          return "NO_ROLE";
        }
      } else {
        return state;
      }
    default:
      return state;
  }
}