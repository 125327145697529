import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogContent, DialogTitle, FormControlLabel, FormGroup, MenuItem, Select, Switch, Table, TableCell, TableContainer, TableRow, TextField, Grid, makeStyles, Chip, FormControl, RadioGroup, Radio, FormHelperText } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ContentTabPanel from './ContentTabPanel';
import ContentEditerTabPanel from './ContentEditerTabPanel';

import { getChannelMapListByCountryCode } from '../../../actions/notificationActions';

const today = new Date(new Date().setDate(new Date().getDate() - 1));
const deviceTypeList = [
  { label: 'PC', value: 'pc' },
  { label: 'TV', value: 'tv' },
  { label: 'Mobile', value: 'mobile' },
];
const mobilePlatformList = [
  { label: 'Android', value: 'Android' },
  { label: 'IOS', value: 'IOS' },
];

const deviceCategoryList = [
  { label: 'Contents', value: '2' },
  { label: 'Event', value: '3' },
];

const pcPlatformList = [{ label: 'PC', value: 'pc' }];

const hourFomatList = [
  { value: '01:00', label: '01:00' },
  { value: '02:00', label: '02:00' },
  { value: '03:00', label: '03:00' },
  { value: '04:00', label: '04:00' },
  { value: '05:00', label: '05:00' },
  { value: '06:00', label: '06:00' },
  { value: '07:00', label: '07:00' },
  { value: '08:00', label: '08:00' },
  { value: '09:00', label: '09:00' },
  { value: '10:00', label: '10:00' },
  { value: '11:00', label: '11:00' },
  { value: '12:00', label: '12:00' },
  { value: '13:00', label: '13:00' },
  { value: '14:00', label: '14:00' },
  { value: '15:00', label: '15:00' },
  { value: '16:00', label: '16:00' },
  { value: '17:00', label: '17:00' },
  { value: '18:00', label: '18:00' },
  { value: '19:00', label: '19:00' },
  { value: '20:00', label: '20:00' },
  { value: '21:00', label: '21:00' },
  { value: '22:00', label: '22:00' },
  { value: '23:00', label: '23:00' },
];

function NotificationForm({ regionCountries, edit, data, handleInputChange, handleLangConentsChange, handleSubmit, handleClose, supportLanguages, getChannelMapList, channelMapList }) {
  const [imgName, setImgName] = useState('');

  useEffect(() => {
    getChannelMapList(data.countryCodes);
  }, [data.countryCodes]);

  const checkIsTextorTextImage = () => data.type === 'text' || data.type === 'textImage';

  const validateEndTime = startTime => {
    return data.startDate + startTime >= data.endDate + data.endTime;
  };
  const validateStartTime = endTime => {
    return data.endDate + endTime <= data.startDate + data.startTime;
  };
  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        {edit ? 'Edit' : 'Add'} {'Notification'}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={'pop_body'}>
          <div>
            <TableContainer className={'tbl_wrap'}>
              <Table className={'tbl_row_typ1'}>
                <tbody>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="is_required">Device Type</label>
                    </TableCell>
                    <TableCell>
                      <Select name="deviceType" id="deviceType" required style={{ minWidth: 120 }} value={data.deviceType ? data.deviceType : ''} onChange={event => handleInputChange(event)}>
                        {deviceTypeList.map(item => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <label className="is_required">Country</label>
                      <Select name="countryCodes" id="countryCodes" style={{ marginLeft: '20px', width: 120 }} value={data.countryCodes ? data.countryCodes : []} onChange={event => handleInputChange(event)} multiple required>
                        {regionCountries
                          .filter(n => n.deviceType === data.deviceType)
                          .map(item => (
                            <MenuItem key={item.countryCode} value={item.countryCode}>
                              {item.countryCode}
                            </MenuItem>
                          ))}
                      </Select>
                    </TableCell>
                    {(data.deviceType === 'tv' || data.deviceType === '') && (
                      <TableCell colSpan={2} style={{ paddingLeft: '5px' }}>
                        <label className="is_required">Channel Map</label>
                        <Select
                          name="channelMapList"
                          id="channelMap"
                          style={{ marginLeft: '10px', width: 160 }}
                          value={data.channelMapList ? data.channelMapList : []}
                          onChange={event => {
                            handleInputChange(event);
                          }}
                          multiple
                          required>
                          {channelMapList.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText>At least one channel map should be selected per country</FormHelperText> */}
                        {/* </div> */}
                      </TableCell>
                    )}
                    {data.deviceType === 'mobile' && (
                      <TableCell colSpan={2} style={{ paddingLeft: '5px' }}>
                        <label className="is_required">Platform</label>
                        <Select
                          name="channelMapList"
                          id="channelMap"
                          style={{ marginLeft: '10px', width: 180 }}
                          value={data.channelMapList ? data.channelMapList : []}
                          onChange={event => {
                            handleInputChange(event);
                          }}
                          multiple
                          required>
                          {mobilePlatformList.map(item => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText>At least one channel map should be selected per country</FormHelperText> */}
                        {/* </div> */}
                      </TableCell>
                    )}
                    {data.deviceType === 'pc' && (
                      <TableCell colSpan={2} style={{ paddingLeft: '5px' }}>
                        <label className="is_required">Platform</label>
                        <Select
                          name="channelMapList"
                          id="channelMap"
                          style={{ marginLeft: '10px', width: 180 }}
                          value={data.channelMapList ? data.channelMapList : []}
                          onChange={event => {
                            handleInputChange(event);
                          }}
                          multiple
                          required>
                          {pcPlatformList.map(item => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText>At least one channel map should be selected per country</FormHelperText> */}
                        {/* </div> */}
                      </TableCell>
                    )}
                  </TableRow>
                  {(data.deviceType === 'tv' || data.deviceType === 'pc' || data.deviceType === '') && (
                    <TableRow width="100%">
                      <TableCell>
                        <label className="is_required">Type</label>
                      </TableCell>
                      <TableCell colSpan={5}>
                        <FormControl component="fieldset">
                          <RadioGroup row aria-label="position" name="type" defaultValue="top" required value={data.type} onChange={event => handleInputChange(event)}>
                            <FormControlLabel value="text" control={<Radio />} label="Text" />
                            <FormControlLabel value="textImage" control={<Radio />} label="Text and Image" />
                            <FormControlLabel value="image" control={<Radio />} label="Image" />
                          </RadioGroup>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  )}
                  {data.deviceType === 'mobile' && (
                    <TableRow width="100%">
                      <TableCell>
                        <label className="is_required">Category</label>
                      </TableCell>
                      <TableCell colSpan={5}>
                        <Select name="deviceCategory" id="deviceCategory" required style={{ minWidth: 120 }} value={data.deviceCategory ? data.deviceCategory : ''} onChange={event => handleInputChange(event)}>
                          {deviceCategoryList.map(item => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow width="100%">
                    <TableCell>
                      <label className="is_required">Tiltle</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <TextField fullWidth name="title" required margin="dense" variant="outlined" value={data.title} onChange={event => handleInputChange(event)} placeholder="Title" />
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%">
                    <TableCell>
                      <label className="is_required">Start Date</label>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <TextField
                        variant="outlined"
                        id="startDate"
                        name="startDate"
                        label="Start Date"
                        type="date"
                        value={data.startDate}
                        onChange={event => handleInputChange(event)}
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                        inputProps={{ min: today.toISOString().slice(0, 10) }}
                        required
                      />
                    </TableCell>
                    <TableCell colSpan={1}>
                      {data.deviceType === 'mobile' && (
                        <Select id="startTime" name="startTime" required style={{ minWidth: 120 }} value={data.startTime} onChange={e => handleInputChange(e)}>
                          {[{ value: '00:00', label: '00:00' }, ...hourFomatList].map(item => (
                            <MenuItem disabled={validateEndTime(item.value)} key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </TableCell>
                    <TableCell>
                      <label className="is_required">End Date</label>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <TextField
                        variant="outlined"
                        id="endDate"
                        name="endDate"
                        label="End Date"
                        type="date"
                        value={data.endDate}
                        onChange={event => handleInputChange(event)}
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                        inputProps={{ min: data.startDate }}
                        required
                      />
                    </TableCell>
                    <TableCell colSpan={1}>
                      {data.deviceType === 'mobile' && (
                        <Select id="endtime" name="endTime" required style={{ minWidth: 120 }} value={data.endTime} onChange={e => handleInputChange(e)}>
                          {[...hourFomatList, { value: '23:59', label: '23:59' }].map(item => (
                            <MenuItem disabled={validateStartTime(item.value)} key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </TableCell>
                  </TableRow>
                  {(data.type === 'image' || data.type === 'textImage') && (
                    <Fragment>
                      <TableRow width="100%">
                        <TableCell>
                          <label className="is_required">Image Url</label>
                        </TableCell>
                        <TableCell colSpan={5}>
                          <div className="logo_preview_add">
                            <input
                              accept="image/*"
                              id="input_notification_image_url"
                              type="file"
                              name="imageUrl"
                              className="logo_preview_input"
                              onChange={e => {
                                if (e.target.files && e.target.files.length >= 1) {
                                  const reader = new FileReader();
                                  const img = e.target.files[0];
                                  reader.readAsDataURL(img);
                                  reader.onload = () => {
                                    setImgName(img.name);
                                  };
                                }
                              }}
                              required={!edit}
                            />
                            <Grid container spacing={3} style={{ marginTop: '5px' }}>
                              <Grid item sm={9} style={{ wordBreak: 'break-word' }}>
                                {edit && imgName === '' ? <p className="col-md-9">{data.imageUrl}</p> : <p className="col-md-9">{imgName}</p>}
                              </Grid>
                              <Grid item sm={3}>
                                <label htmlFor={'input_notification_image_url'}>
                                  <Button component="span" className="btn_color4" style={{ width: 110 }}>
                                    Upload File
                                  </Button>
                                </label>
                              </Grid>
                            </Grid>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow width="100%">
                        <TableCell>Deeplink Url</TableCell>
                        <TableCell colSpan={5}>
                          <TextField fullWidth name="deeplinkUrl" margin="dense" variant="outlined" value={data.deeplinkUrl} onChange={event => handleInputChange(event)} placeholder="Deeplink Url" />
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  )}
                  <TableRow width="100%">
                    <TableCell>
                      {' '}
                      <label className={checkIsTextorTextImage() ? 'is_required' : ''}>language</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <Select name="lang" id="lang" required={checkIsTextorTextImage()} style={{ minWidth: 120 }} value={data.lang} onChange={event => handleInputChange(event)} multiple>
                        {[...supportLanguages].map(item => (
                          <MenuItem key={item.lang_code} value={item.lang_code}>
                            {item.lang_code}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%">
                    <TableCell width="10%">
                      <label className={checkIsTextorTextImage() ? 'is_required' : ''}>Content</label>
                    </TableCell>
                    <TableCell width="90%" colSpan={5}>
                      {data.deviceType === 'mobile' && <ContentEditerTabPanel languages={data.lang} langContentsData={data.notificationMultiLangList ? data.notificationMultiLangList : []} handleContent={handleLangConentsChange} />}
                      {data.deviceType !== 'mobile' && <ContentTabPanel type={data.type} languages={data.lang} langContentsData={data.notificationMultiLangList ? data.notificationMultiLangList : []} handleContent={handleLangConentsChange} />}
                    </TableCell>
                  </TableRow>
                </tbody>
              </Table>
            </TableContainer>
          </div>
          <div className={'btn_set_wrap'}>
            <Button color="primary" onClick={handleClose} className={'btn_color1'}>
              Cancel
            </Button>
            <Button type="submit" color="primary" className={'btn_color2'}>
              Save
            </Button>
          </div>
        </DialogContent>
      </form>
    </Fragment>
  );
}

NotificationForm.propTypes = {
  edit: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleLangConentsChange: PropTypes.func.isRequired,
  supportLanguages: PropTypes.array.isRequired,
  getChannelMapList: PropTypes.func.isRequired,
  channelMapList: PropTypes.array.isRequired,
  regionCountries: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  supportLanguages: state.web.supportLanguages,
  channelMapList: state.homeApp.notificationsChannelMapList,
  regionCountries: state.cntry.regionCountries,
});

const mapDispatchToProps = dispatch => ({
  getChannelMapList: countryCodes => dispatch(getChannelMapListByCountryCode(countryCodes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationForm);
