import * as actions from '../../actions/actionTypes';

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '../../constants';

const initialState = false;

export default function isRoleAdmin(state = initialState, action) {
  switch (action.type) {
    case actions.INIT_CURRENT_USER:
      return initialState;
    case actions.SET_CURRENT_USER:
      if (action.body && typeof action.body.roles !== 'undefined') {
        if (action.body.roles.find(i => i.name === ROLE_ADMIN || i.name === ROLE_SUPER_ADMIN)) {
          return true;
        } else {
          return false;
        }
      } else {
        return state;
      }
    default:
      return state;
  }
}
