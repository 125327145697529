import * as actions from '../../actions/actionTypes';
//
const initialState = [];
//
export default function sd0105(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SD0105_SUCCESS:
      if (action.payload) {
        return action.payload;
      } else {
        return state;
      }
    case actions.SET_SD0105_FAILURE:
      return state;
    default:
      return state;
  }
}
