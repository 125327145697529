import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../common/Loading';
import { getHeaders } from '../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../constants/index';

import DefaultButton from '../../common/DefaultButton';
import DropDownForSearch from '../../common/DropDownForSearch';
import ChannelDataFlow from '../../common/ChannelDataFlow';
import IssTable from '../../common/IssTable';
import { dashboard_contents_tabledata } from './ContentsDashboardData';

import Alert from '../../modal/Alert';
import TutorialPage from '../../TutorialPage';

const ContentsDashboard = ({ filterCallback }) => {
  const [regions, setRegions] = useState({'dropdownList': [], 'checkedList' : []});
  const [countries, setCountries] = useState({'dropdownList': [], 'checkedList' : []});
  const [providers, setProviders] = useState({'dropdownList': [], 'checkedList' : []});

  const [paramRegions, setParamRegions] = useState([]);
  const [paramCountries, setParamCountries] = useState([]);
  const [paramProviders, setParamProviders] = useState([]);
  const [searchFilter, setSearchFilter] = useState({});

  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [providerError, setProviderError] = useState([]);
  const [feedError, setFeedError] = useState([]);
  const [adminError, setAdminError] = useState([]);
  const [batchError, setBatchError] = useState([]);
  const [providerErrorFetching, setProviderErrorFetching] = useState('init');
  const [feedErrorFetching, setFeedErrorFetching] = useState('init');
  const [adminErrorFetching, setAdminErrorFetching] = useState('init');
  const [batchErrorFetching, setBatchErrorFetching] = useState('init');

  const [isProviderErrorDisplay, setProviderErrorDisplay] = useState(false);
  const [isFeedErrorDisplay, setFeedErrorDisplay] = useState(false);
  const [isAdminErrorDisplay, setAdminErrorDisplay] = useState(false);
  const [isBatchErrorDisplay, setBatchErrorDisplay] = useState(false);

  const [feedErrorCount, setFeedErrorCount] = useState(0);
  const [adminErrorCount, setAdminErrorCount] = useState({ channel: 0, app: 0 });
  const [batchErrorCount, setBatchErrorCount] = useState(0);

  const [currentFilter, setCurrentFilter] = useState({ country: '', provider: '', platform: '' });

  const [isActive, setIsActive] = useState(false);

  const history = useHistory();

  useEffect(() => {
    valueInitialize();
    getContentsError('provider');
    getContentsError('feed');
    getContentsError('admin');
    getContentsError('batch');

    getSearchFilter();
  }, []);

  useEffect(() => {
    if ( searchFilter.countryByRegion ) {
      let dropdownCountries = [];
      if (paramRegions && paramRegions.length > 0){
        dropdownCountries = searchFilter.countryByRegion.filter(f => paramRegions.includes(f.region)).map(region =>
          region.countryList.map(map => ({ id: map.countryCode, value : map.fullName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownCountries.sort((a, b) => a.value.localeCompare(b.value));
      }
      // Unknown
      if (dropdownCountries.length > 0) {
        dropdownCountries.push({ id: 'Unknown', value : 'Unknown' });
      }

      setCountries({'dropdownList': dropdownCountries, 'checkedList' : [] });
    }
  }, [ paramRegions ]);

  useEffect(() => {
    if ( searchFilter.countryByRegion &&  searchFilter.providerByCountry) {
      let dropdownProviders = [];
      if (paramCountries && paramCountries.length > 0 && paramCountries.filter(item => item != 'Unknown').length > 0) {
        let ProviderList = searchFilter.providerByCountry.filter(f => paramCountries.filter(item => item != 'Unknown').includes(f.countryCode)).map(map =>
          map.providerList.map(pr => ({ id: pr.providerId, value : pr.providerName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownProviders = [...new Set(ProviderList.map((list) => JSON.stringify(list)))].map((list) => JSON.parse(list));
      }
      setProviders({ 'dropdownList': dropdownProviders, 'checkedList' : [] });
    }
  }, [ paramCountries ]);

  useEffect(() => {
    if (providerErrorFetching === 'done') {
      setProviderErrorDisplay(true);
    }
  }, [providerError]);

  useEffect(() => {
    if (feedError && feedError.length > 0) {
      dashboard_contents_tabledata.cp_issues.rowdata = feedError.map(item => ({
        row: [
          item.values[0].date.substring(2, 10),
          item.countries,
          item.cp + `(` + item.groupId.split('_')[1] + `)`,
          item.apis,
          item.title,
        ],
        groupId: item.groupId,
      }));
    }

    if (feedErrorFetching === 'done') {
      setFeedErrorCount(feedError.length);
      setFeedErrorDisplay(true);
    }
  }, [feedError]);

  useEffect(() => {
    if (adminError && adminError.length > 0) {
      dashboard_contents_tabledata.cmp_organization.rowdata = adminError.slice(0, 10).map(item => ({
        row: [
          item.date.substring(2, 10),
          item.country === 'All' ? 'ALL' : item.country,
          item.issueCode.startsWith('CN10') ? 'Channel' : 'App',
          item.issueTitle !== '' ? (item.issueTitle > 35 ? item.issueTitle.substring(0, 35) + '...' : item.issueTitle) : item.errorMessage.length > 35 ? item.errorMessage.substring(0, 35) + '...' : item.errorMessage,
        ],
        issueId: item.issueId,
        issueCode: item.issueCode,
      }));
    }

    if (adminErrorFetching === 'done') {
      const channelErrorCount = adminError ? adminError.filter(item => item.issueCode.startsWith('CN10')).length : 0;
      const appErrorCount = adminError.length - channelErrorCount;

      setAdminErrorCount({ channel: channelErrorCount, app: appErrorCount });
      setAdminErrorDisplay(true);
    }
  }, [adminError]);

  useEffect(() => {
    if (batchError && batchError.length > 0) {
      dashboard_contents_tabledata.data_deployment.rowdata = batchError.slice(0, 10).map(item => ({
        row: [item.date.substring(2, 10), item.country, 'Deploy', item.issueTitle !== '' ? (item.issueTitle > 35 ? item.issueTitle.substring(0, 35) + '...' : item.issueTitle) : item.errorMessage.length > 35 ? item.errorMessage.substring(0, 35) + '...' : item.errorMessage],
        issueId: item.issueId,
        issueCode: item.issueCode,
      }));
    }

    if (batchErrorFetching === 'done') {
      setBatchErrorCount(batchError.length);
      setBatchErrorDisplay(true);
    }
  }, [batchError]);

  useEffect(() => {
    if (currentFilter.country.length > 0) {
      valueInitialize();
      getContentsError('provider', currentFilter);
      getContentsError('feed', currentFilter);
      getContentsError('admin', currentFilter);
      getContentsError('batch', currentFilter);
      filterCallback(currentFilter);
    }
  }, [currentFilter]);

  const getSearchFilter = () => {
    fetch(`${ISS_BASE_URL}/api/common/search-filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSearchFilter({
          countryByRegion : body.countryByRegion
          , providerByCountry : body.providerByCountry
        })
        let dropdownRegion = [];
        // countryByRegion
        if ( body.countryByRegion ) {
          dropdownRegion = body.countryByRegion.map(map =>({ id: map.region, value : map.region }));
          dropdownRegion.sort((a, b) => a.value.localeCompare(b.value));
        }
        setRegions({ 'dropdownList': dropdownRegion, 'checkedList' : [] });
      } else {
        setAlertTitle('Error');
        setAlertMessage('[getSearchFilter : error]');
        setIsAlert(true);
      }
    })
    .catch(error => {
      console.log("getSearchFilter - error: " , error);
      setAlertTitle('Error');
      setAlertMessage('[getSearchFilter : error]');
      setIsAlert(true);
    }).finally(() => {});
  };

  const onClickRowItem = (category, item) => {
    let selectedData;
    if (category === 'cp') {
      selectedData = {
        category: 'cp', groupId: item.groupId,
          region: paramRegions, country: paramCountries, provider: paramProviders
      };
    } else if (category === 'admin') {
      selectedData = { category: 'admin', issueId: item.issueId, issueCode: item.issueCode };
    } else {
      selectedData = { category: 'batch', issueId: item.issueId, issueCode: item.issueCode };
    }

    history.push(`/ISS/ContentsDetail`, {
      param: JSON.stringify(selectedData),
    });
  };

  const valueInitialize = () => {
    dashboard_contents_tabledata.cmp_organization.rowdata = [];
    dashboard_contents_tabledata.data_deployment.rowdata = [];
    dashboard_contents_tabledata.cp_issues.rowdata = [];
    setProviderErrorDisplay(false);
    setFeedErrorDisplay(false);
    setAdminErrorDisplay(false);
    setBatchErrorDisplay(false);
  };

  const issueSearchByButton = () => {
    if (paramRegions.length === 0 || paramCountries.length === 0 || paramProviders.length === 0) {
      const showMessage = paramRegions.length === 0 ? 'region' : paramCountries.length === 0 ? 'country' : 'cp';
      setAlertTitle('Warning');
      setAlertMessage(`Please select any ${showMessage}`);

      onOpenAlert();
    } else {
      setCurrentFilter({ country: paramCountries, provider: paramProviders, platform: '' })
    }
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const getContentsError = (type, currentFilter) => {
    const issueStatusValue = 'Unaddressed,Processing';
    const countryListValue = currentFilter && currentFilter.country.length > 0 ? currentFilter.country : '';
    const providerValue = currentFilter && currentFilter.provider.length > 0 ? currentFilter.provider : '';
    let requestUrl = '';

    if (type === 'provider') {
      setProviderErrorFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/application/dashboard/provider/error?country=${countryListValue}&provider=${providerValue}`;
    } else if (type === 'feed') {
      setFeedErrorFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/application/dashboard/feed/error?issueStatus=${issueStatusValue}&country=${countryListValue}&provider=${providerValue}`;
    } else if (type === 'admin') {
      setAdminErrorFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/application/dashboard/admin/error?issueStatus=${issueStatusValue}&country=${countryListValue}&provider=${providerValue}`;
    } else if (type === 'batch') {
      setBatchErrorFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/application/dashboard/batch/error?issueStatus=${issueStatusValue}&country=${countryListValue}&provider=${providerValue}`;
    } else {
      console.error(`getContentsError, type:${type} error`);
      return;
    }

    fetch(requestUrl, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (type === 'provider') {
          setProviderErrorFetching('done');
          setProviderError(body);
        } else if (type === 'feed') {
          setFeedErrorFetching('done');
          const issueCpData = body.filter(item => item.feedDashboards.length > 0);

          const groupedData = issueCpData.reduce((acc, obj) => {
            const { groupId, groupTitle, providerId, feedDashboards } = obj;

            if (!acc[groupId]) {
              acc[groupId]= {
                title: groupTitle,
                cp: providerId,
                count: feedDashboards.length,
                countries: new Set(),
                apis: {},
                values: feedDashboards
              };
            }

            feedDashboards.map(item => {
              const countryArr = item.country.split(",");
              countryArr.forEach((c) => {
                if (!acc[groupId].countries[c]) {
                  acc[groupId].countries[c]= true;
                }
              });

              if (!acc[groupId].apis[item.apiName]) {
                acc[groupId].apis[item.apiName]= true;
              }
            })

            return acc;
          }, {});

          const modifiedData = Object.keys(groupedData).map((groupId) => {
            const { title, cp, count, countries, apis, values } = groupedData[groupId];
            return {
              groupId: groupId,
              title: title,
              cp: cp,
              count: count,
              countries: Object.keys(countries),
              apis: Object.keys(apis).join(", "),
              values: values
            };
          });
          setFeedError(modifiedData);
        } else if (type === 'admin') {
          setAdminErrorFetching('done');
          setAdminError(body);
        } else if (type === 'batch') {
          setBatchErrorFetching('done');
          setBatchError(body);
        }
      })
      .catch(error => {
        console.log(`getContentsError error: `, error);
      });
  };

  const handleContentIssue = (type, subType) => {
    let selectedData = null;
    if (type === 'admin') {
      if (subType === 'channel' && adminErrorCount.channel > 0) {
        const firstFindItem = adminError.find(item => item.issueCode.startsWith('CN10'));
        selectedData = { category: 'admin', issueId: firstFindItem.issueId, issueCode: firstFindItem.issueCode };
      } else if (subType === 'app' && adminErrorCount.app > 0) {
        const firstFindItem = adminError.find(item => item.issueCode.startsWith('CN11') || item.issueCode.startsWith('CN12'));
        selectedData = { category: 'admin', issueId: firstFindItem.issueId, issueCode: firstFindItem.issueCode };
      }
    } else if (type === 'batch' && batchErrorCount > 0) {
      selectedData = { category: 'batch', issueId: batchError[0].issueId, issueCode: batchError[0].issueCode };
    }

    if (selectedData) {
      history.push(`/ISS/ContentsDetail`, {
        param: JSON.stringify(selectedData),
      });
    }
  };

  const handleCpError = (cp) => {
    const firstFindItem = feedError.find(item => item.cp === cp);

    if (firstFindItem) {
      const selectedData = { category: 'cp', groupId: firstFindItem.groupId,
        region: paramRegions, country: paramCountries, provider: [cp] };

      if (selectedData) {
        history.push(`/ISS/ContentsDetail`, {
          param: JSON.stringify(selectedData),
        });
      }
    }
  };

  const handleOpen = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <>
      <div className="grid-vertical-two">
        <div className="grid-left">
          <div className="box-channel-dataflow">
            <div className="head">
              <div className="title">
                <button className="btn-tutorial-2" onClick={handleOpen}/>
                <h1>LG Channels Data Flow</h1>
              </div>
              <div className="ui-dropdown-box">
                <DropDownForSearch dropDownItem={regions} title="Region" onChangeDropDown={setParamRegions} />
                <DropDownForSearch dropDownItem={countries} title="Countries" onChangeDropDown={setParamCountries} />
                <DropDownForSearch dropDownItem={providers} title="CPs" onChangeDropDown={setParamProviders} />
                <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={issueSearchByButton} />
              </div>
            </div>
            {isProviderErrorDisplay && isFeedErrorDisplay && isAdminErrorDisplay && isBatchErrorDisplay ? (
              <div className="dafaflow-container">
                <div className="box">
                  <div className="head">
                    <h2>CP</h2>
                    <span className={feedErrorCount > 0 ? 'bg-gradient bg-cp on' : 'bg-gradient bg-cp'}></span>
                    {/* issue 발생시 bg-gradient 에 클래스 on 추가 */}
                  </div>
                  <div className="cp-contents">
                    <ul>
                      {providerError.map(item => (
                        <ChannelDataFlow key={item.cp} cp={item.cp} imageUrl={item.logoUrl} isActive={item.issueCount > 0 ? true : false} data={item.issueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} clickCpIcon={handleCpError} />
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="bg-processing"></div>
                <div className="box">
                  <div className="head">
                    <h2>CMP</h2>
                    <span className={adminError.length + batchError.length > 0 ? 'bg-gradient bg-cmp on' : 'bg-gradient bg-cmp'}></span>
                  </div>
                  <div className="cmp-contents">
                    <div className={adminErrorCount.channel > 0 ? 'summary-box on' : 'summary-box'} onClick={() => handleContentIssue('admin', 'channel')}>
                      <h3>
                        Channel
                        <br />
                        Organization
                      </h3>
                      <strong>{adminErrorCount.channel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
                    </div>
                    <div className={adminErrorCount.app > 0 ? 'summary-box on' : 'summary-box'} onClick={() => handleContentIssue('admin', 'app')}>
                      <h3>
                        APP
                        <br />
                        Organization
                      </h3>
                      <strong>{adminErrorCount.app.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
                    </div>
                    <div className={batchErrorCount > 0 ? 'summary-box on' : 'summary-box'} onClick={() => handleContentIssue('batch', 'deploy')}>
                      <h3>
                        Data
                        <br />
                        Deployment
                      </h3>
                      <strong>{batchErrorCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
                    </div>
                  </div>
                </div>
                <div className="bg-processing"></div>
                <div className="box">
                  <div className="head">
                    <h2>CMP API</h2>
                    <span className={'bg-gradient bg-device'}></span>
                  </div>
                  <div className="device-contents">
                    <div className={'summary-box'}>
                      <h3>
                        Live
                        <br />
                        Channels
                      </h3>
                      <strong>0</strong>
                    </div>
                    <div className={'summary-box'}>
                      <h3>
                        LG Channels
                        <br />
                        App
                      </h3>
                      <strong>0</strong>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="loading_wrap">
                <Loading isVisible={true} />
              </div>
            )}
          </div>
          {isAdminErrorDisplay && isBatchErrorDisplay ? (
            <div className="grid-column-2">
              <div className="grid-left">
                <div className="box-cmpIssues box-tbl">
                  <IssTable height={`calc(100% - 40px)`} pagination={false} data={dashboard_contents_tabledata.cmp_organization} handleClickItem={item => onClickRowItem('admin', item)} />
                </div>
              </div>
              <div className="grid-right">
                <div className="box-deviceIssues box-tbl">
                  <IssTable height={`calc(100% - 40px)`} pagination={false} data={dashboard_contents_tabledata.data_deployment} handleClickItem={item => onClickRowItem('batch', item)} />
                </div>
              </div>
            </div>
          ) : (
            <div className="loading_wrap">
              <Loading isVisible={true} />
            </div>
          )}
        </div>
        {isFeedErrorDisplay ? (
          <div className="grid-right">
            <div className="box-cpIssues box-tbl">
              <IssTable height={`calc(100% - 40px)`} pagination={false} data={dashboard_contents_tabledata.cp_issues} handleClickItem={item => onClickRowItem('cp', item)} />
            </div>
          </div>
        ) : (
          <div className="loading_wrap">
            <Loading isVisible={true} />
          </div>
        )}
      </div>
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
      {isActive && <TutorialPage data={'contentDashboard'} isActive={isActive} onClose={handleClose} />}
    </>
  );
};

export default ContentsDashboard;
