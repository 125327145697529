import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LandingPage from '../landing/landing.js';
import { makeStyles } from '@material-ui/core/styles';
import { LightTooltip } from '../../../../common/Tooltip';
import ChannelDialog from '../../channel/ChannelDialog';

const useStyles = makeStyles(() => ({
  sidebarStyle: {
    ////첨에 애적용하면 모양이 이상해지는 에러나서 opaque, menuItem 먼저적용하고 나중에 재적용하니 정상동작 왜그럴까?
    boxShadow: '1px 0 5px 1px black', ///채널명 사이드바 사이의 음영
    overflow: 'hidden', //!important;
    width: '7rem', //////////////todo overflow 없도록 조정할 것
    background: '#fff', ///없으면 투명해서 아래것이 비침
    position: 'fixed', //없으면 프로그램 가이드가 아래로 내려감
    zIndex: '102', ///없으면 투명해서 아래것이 비침
  },
  opaque: {
    height: '61.2px', //없으면 이 칸이 없어지면서 채널사이드바가 위로 올라감
  },
  menuItem: {
    height: '8.0166vh', //8.0166vh=80px
    minHeight: '50px', /////////////////////////////////////////////////////////////////////////////
    border: '0.5px solid rgba(34,36,38,.15)', //없으면 채널사이 경계선 없어짐
    textAlign: 'center', //채널명 가로중앙정렬
    padding: '30% 0%', //채널명 세로중앙정렬 힘들어서 대충만 조절
  },
}));

const SideBar = ({ schedules }) => {
  const classes = useStyles();

  const [channelDialogOpen, setChannelDialogOpen] = useState(false);
  const [channelId, setChannelId] = useState();
  const [providerId, setProviderId] = useState();

  const clickChannelDialog = e => {
    // console.log('[e.currentTarget][Attr]', e.currentTarget.attributes); //https://developer.mozilla.org/en-US/docs/Web/API/Element/attributes
    // console.log('[e.currentTarget][channelId]', e.currentTarget.id);
    // console.log('[e.currentTarget][provider]', e.currentTarget.parentNode.id);
    setChannelId(e.currentTarget.id);
    setProviderId(e.currentTarget.parentNode.id);
    setChannelDialogOpen(true);
  };

  return (
    <div>
      <div className={classes.sidebarStyle + ' schedule_sidebar'}>
        <LightTooltip arrow title={'channel count: ' + schedules.length}>
          <div className={classes.opaque + ' schedule_sidebar_head'}>
            <span>Channel</span>
          </div>
        </LightTooltip>
        {schedules.map(asset => (
          <div
            //as="a"
            key={asset.channelId}
            id={asset.providerId}
            className={classes.menuItem + ' schedule_sidebar_list'}>
            {/* <button id={asset.channelId} onClick={clickChannelDialog}> */}
            <span id={asset.channelId} onClick={clickChannelDialog} style={{ cursor: 'pointer' }}>
              <span className={'channel_name'}>{asset.channelName}</span>
              <span className={'channel_num'}>{asset.channelMajorNumber}</span>
            </span>
          </div>
        ))}
      </div>
      <ChannelDialog channelId={channelId} providerId={providerId} channelDialogOpen={channelDialogOpen} closeChannelDialog={() => setChannelDialogOpen(false)} />
      <LandingPage schedules={schedules} />
    </div>
  );
};

SideBar.propTypes = {
  schedules: PropTypes.array.isRequired,
};

export default SideBar;
