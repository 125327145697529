////////////////////////////////////////////////////////////////////////////////
//
////////////////////////////////////////////////////////////////////////////////
import React, { useState } from 'react'; // React
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
////////////////////////////////////////////////////////////////////////////////
//
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//
////////////////////////////////////////////////////////////////////////////////
export default function popupCopyToPlatform(parentDataFunction) {
  ////////////////////////////////////////////////////////////////////////////////
  // useState
  ////////////////////////////////////////////////////////////////////////////////
  const [useStateFormFeedIdx, setUseStateFormFeedIdx] = useState();
  ////////////////////////////////////////////////////////////////////////////////
  // useEffect
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  //
  ////////////////////////////////////////////////////////////////////////////////
  let selectedFeedMappId = parentDataFunction.feedMappId;
  //
  //
  let platformTag = <> </>;
  // key: "0"
  // selectKey: "LG_MOBILE"
  // selectValue: "LG_MOBILE"
  let tempArr = [];
  // let selectObj = {
  //     key: "0"
  //     , selectKey: "SELECT_ALL"
  //     , selectValue: "Select All"
  //     , disabled: false
  // }
  //
  // tempArr.push(selectObj);
  let tempCmSdepth0003 = parentDataFunction.cmSdepth0003;
  //
  //
  for (let idx01 in tempCmSdepth0003) {
    let selectedDisabled = false;
    //
    let tempSelectKey = tempCmSdepth0003[idx01].selectKey;
    // tempSelectKey = tempSelectKey.replace(/TV/gi, "");
    //  tempSelectKey = tempSelectKey.replace(/ /gi, "");
    //
    if (selectedFeedMappId === tempSelectKey) {
      selectedDisabled = true;
    } else {
      selectedDisabled = false;
    }
    let tempObj = {
      key: idx01 + 1 + '',
      selectKey: tempCmSdepth0003[idx01].selectKey,
      selectValue: tempCmSdepth0003[idx01].selectValue,
      disabled: selectedDisabled,
    };
    tempArr.push(tempObj);
  }
  //
  platformTag = tempArr.map(function (rec) {
    return (
      <>
        <FormControlLabel
          control={
            <Checkbox
              key={rec.key}
              value={rec.selectKey}
              disabled={rec.disabled}
              name={rec.selectKey}
              onChange={event => {
                //  {, webOS4.0}
                if (event.target.checked) {
                  if (useStateFormFeedIdx) {
                    let arrFormFeedIdx = useStateFormFeedIdx.split('|');
                    let isFormFeedIdx = false;
                    for (let idx01 in arrFormFeedIdx) {
                      if (arrFormFeedIdx[idx01] === event.target.value) {
                        isFormFeedIdx = true;
                      }
                    }
                    if (!isFormFeedIdx) {
                      let temp = useStateFormFeedIdx + '|' + event.target.value;
                      if (temp.length >= 1) {
                        if (temp.substr(0, 1) === '|') {
                          temp = temp.substr(1, temp.length);
                        }
                      }
                      setUseStateFormFeedIdx(temp);
                    }
                  } else {
                    // undefined이면 저장, 첫번쩨 저장
                    setUseStateFormFeedIdx(event.target.value);
                  }
                } else if (!event.target.checked) {
                  if (useStateFormFeedIdx) {
                    let arrFormFeedIdx = useStateFormFeedIdx.split('|');
                    for (let idx01 in arrFormFeedIdx) {
                      if (arrFormFeedIdx[idx01] === event.target.value) {
                        arrFormFeedIdx[idx01] = '';
                      }
                    }
                    let temp2 = '';
                    for (let idx02 in arrFormFeedIdx) {
                      if (arrFormFeedIdx[idx02] !== '') {
                        temp2 = temp2 + '|' + arrFormFeedIdx[idx02];
                      }
                    }
                    if (temp2.length >= 1) {
                      if (temp2.substr(0, 1) === '|') {
                        temp2 = temp2.substr(1, temp2.length);
                      }
                    }
                    setUseStateFormFeedIdx(temp2);
                  } else {
                    // undefined이면 저장, 첫번쩨 저장
                    setUseStateFormFeedIdx(event.target.value);
                  }
                }
              }}
            />
          }
          label={rec.selectValue}
        />
      </>
    );
  });
  ////////////////////////////////////////////////////////////////////////////////
  //
  ////////////////////////////////////////////////////////////////////////////////
  return (
    <React.Fragment>
      <div>
        <Dialog open={parentDataFunction.openClose} aria-labelledby="form-dialog-title" className={'pop_wrap pop_size_s'}>
          <DialogTitle id="form-dialog-title" className={'pop_head'}>
            {'Copy to Platforms'}
            <IconButton aria-label="close" onClick={parentDataFunction.handleClickOpenCloseCopyToPlatform}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={'pop_body'}>
            <p className="tbl_top_comment"> Copy rules to another platforms.</p>
            <div className="tbl_wrap mt30">{platformTag}</div>
            <div className={'btn_set_wrap alC'}>
              <Button variant="contained" className={'btn_color1'} onClick={parentDataFunction.handleClickOpenCloseCopyToPlatform}>
                Close
              </Button>
              <Button
                variant="contained"
                className={'btn_color2'}
                onClick={event => {
                  if (window.confirm('Before copying the settings,  make sure that the CP included in the settings is being serviced on that platform. Do you want to copy?')) {
                    setUseStateFormFeedIdx(''); // clear
                    parentDataFunction.handleClickSave(useStateFormFeedIdx);
                    parentDataFunction.handleClickOpenCloseCopyToPlatform();
                    return true;
                  } else {
                    return false;
                  }
                }}>
                {' '}
                Copy{' '}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
////////////////////////////////////////////////////////////////////////////////
//
////////////////////////////////////////////////////////////////////////////////
popupCopyToPlatform.propTypes = {
  parentDataFunction: PropTypes.any.isRequired,
};
