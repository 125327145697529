import React from 'react';

function PrimaryButton(props) {
  const { type, text, btnClass, onClick } = props;

  return (
    <>
      {type === 'a' && (
        <a href="#" className={btnClass} onClick={onClick}>
          {text}
        </a>
      )}
      {type === 'button' && (
        <button className={btnClass} onClick={onClick}>
          {text}
        </button>
      )}
    </>
  );
}

export default PrimaryButton;
