import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingIndicator from '../../../common/LoadingIndicator';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import ProgramTable from './ProgramTable';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';

export function ProgramDialog({ programId, providerId, programDialogOpen, closeProgramDialog, isRoleAdmin }) {
  const [prog, setProg] = useState();
  const [fetching, setFetching] = useState();
  // console.log('[id]', id, '[prog]', prog, '[programs]', programs);

  useEffect(() => {
    if (programId) {
      setFetching(true);
      fetch(isRoleAdmin ? API_BASE_URL + '/admin/providers/' + providerId + '/programs/' + programId : API_BASE_URL + '/programs/' + programId, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then(res => res.json())
        .then(body => {
          //console.log('[then]', body);
          if (body.status) {
            alert('[server error] ' + body.message);
          } else {
            setProg(body);
          }
          setFetching(false);
        })
        .catch(error => {
          setFetching(false);
          alert('[error message] ' + error);
        });
    }
  }, [programId]);

  return (
    <>
      {fetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        <div>
          {prog && (
            <Dialog
              open={programDialogOpen}
              onClose={closeProgramDialog}
              //aria-labelledby="form-dialog-title"
              className={'pop_wrap'}>
              <div className="MuiDialogTitle-root pop_head" id="form-dialog-title">
                <h2 className="MuiTypography-root MuiTypography-h6">
                  Program Detail
                  <IconButton
                    aria-label="close" //없으면 X버튼 위치앞으로감
                    className={'noTit_close'}
                    onClick={closeProgramDialog}>
                    <CloseIcon />
                  </IconButton>
                </h2>
              </div>
              <div className={'pop_body'}>
                <ProgramTable prog={prog} />
                <div className="btn_set_wrap">
                  <Button color="secondary" variant="contained" className={'btn_color2'} onClick={closeProgramDialog}>
                    Close
                  </Button>
                </div>
              </div>
            </Dialog>
          )}
        </div>
      )}
    </>
  );
}

ProgramDialog.propTypes = {
  programId: PropTypes.string,
  providerId: PropTypes.string,
  programDialogOpen: PropTypes.bool.isRequired,
  closeProgramDialog: PropTypes.func.isRequired,
  isRoleAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isRoleAdmin: state.currentUser.isRoleAdmin,
});
export default connect(mapStateToProps)(ProgramDialog);
