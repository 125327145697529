////////////////////////////////////////////////////////////////////////////////
// import
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// import basic
////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from 'react'; // React
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; // connect
import { withRouter } from 'react-router-dom'; // withRouter
////////////////////////////////////////////////////////////////////////////////
// import material
////////////////////////////////////////////////////////////////////////////////
import { Button, Dialog, Paper, DialogActions, TableContainer, TableRow, TableCell, Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import { deleteRssFeed, getRssFeedEdit, getRssFeedList, getRssFeedDetail } from '../../../../actions/rssFeedActions';
////////////////////////////////////////////////////////////////////////////////
// RSSFeed
////////////////////////////////////////////////////////////////////////////////
function RSSFeedDetail({ failmessage, history, feedDetail, deleteRssFeed, getRssFeedDetail, feedIdx }) {
  useEffect(() => {
    getRssFeedDetail(feedIdx);
    if (failmessage) {
      openAlert(failmessage, closeAlert, false);
    }
  }, [getRssFeedDetail, feedIdx, failmessage]);
  const [alert, setAlert] = useState({ open: false, message: '', callBack: null, isconfirm: false });
  const openAlert = (message, callBack, isconfirm) => {
    setAlert({
      open: true,
      message: message,
      callBack: () => callBack(),
      isconfirm: isconfirm,
    });
  };
  //
  const closeAlert = () => {
    setAlert({
      open: false,
      message: '',
      callBack: null,
      isconfirm: false,
    });
  };
  const onDeleteFunction = () => {
    deleteRssFeed(feedDetail.feedIdx, history);
  };
  return (
    <div>
      <React.Fragment>
        <TableContainer component={Paper} className={'tbl_wrap'}>
          <Table className="tbl_row_typ1 tbl_rssfeed">
            <colgroup>
              <col style={{ width: '15%' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '35%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Feed No.</TableCell>
                <td>{feedDetail.feedIdx}</td>
              </TableRow>
              <TableRow>
                <TableCell>Country</TableCell>
                <td>{feedDetail.cntryCode}</td>
              </TableRow>
              <TableRow>
                <TableCell>CP Name</TableCell>
                <td colSpan="3">{feedDetail.cpName || ''}</td>
                {/* <TableCell></TableCell>
                                    <td></td> */}
              </TableRow>
              <TableRow>
                <TableCell>Path</TableCell>
                <td colSpan={3}>{feedDetail.rssPath}</td>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <div className="btn_set_wrap">
          <div className="btn_set_left_cont">
            <Button
              variant="contained"
              onClick={() => {
                history.push('/PROMOTION/homeShelf/rssFeed');
              }}
              className="btn_color3">
              LIST
            </Button>
          </div>
          <Button variant="contained" onClick={() => openAlert('Are you sure to delete this?', onDeleteFunction, true)} className="btn_color1">
            DELETE
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              history.push(`/PROMOTION/homeShelf/rssFeed/edit/${feedDetail.feedIdx}`);
            }}
            className="btn_color2">
            EDIT
          </Button>
        </div>
      </React.Fragment>
      <Dialog open={alert.open} onClose={closeAlert} aria-labelledby="confirm-dialog">
        <div className="pop_size_small">
          <p className="pop_comment">{alert.message}</p>
          <DialogActions>
            <div className="btn_set_wrap alC">
              {alert.isconfirm ? (
              <Button color="primary" onClick={alert.callBack} className="btn_color2">
                OK
              </Button>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <Button autoFocus color="primary" onClick={closeAlert} className="btn_color1">
                Cancel
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
RSSFeedDetail.propTypes = {
  feedDetail: PropTypes.object,
  history: PropTypes.object,
  deleteRssFeed: PropTypes.func,
  getRssFeedDetail: PropTypes.func,
  feedIdx: PropTypes.string,
  failmessage: PropTypes.string,
};
////////////////////////////////////////////////////////////////////////////////
// mapStateToProps
////////////////////////////////////////////////////////////////////////////////
const mapStateToProps = state => ({
  feedDetail: state.rssFeed.feedDetail,
  failmessage: state.rssFeed.failmessage,
});
////////////////////////////////////////////////////////////////////////////////
// mapDispatchToProps
////////////////////////////////////////////////////////////////////////////////
const mapDispatchToProps = dispatch => ({
  getRssFeedDetail: feedIdx => dispatch(getRssFeedDetail(feedIdx)),
  getRssFeedEdit: feedIdx => dispatch(getRssFeedEdit(feedIdx)),
  deleteRssFeed: (feedIdx, callBack) => dispatch(deleteRssFeed(feedIdx, callBack)),
  getRssFeedList: param => dispatch(getRssFeedList(param)),
});
////////////////////////////////////////////////////////////////////////////////
// export
////////////////////////////////////////////////////////////////////////////////
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RSSFeedDetail));
