import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API_BASE_URL } from '../../../../constants';
import { getHeadersFormData } from '../../../../util/actionUtil';
import CommonDialog from '../../../../common/dialog/CommonDialog';
import { connect } from 'react-redux';
import { Button, DialogContent, DialogTitle, Table, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ImageEdit from './ImageEdit';

function CarouselEventForm({ eventBanner, handleClose, searchList }) {
  const prefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;
  const [isSave, setIsSave] = useState(false);
  const [eventBannerTilte, setEventBannerTilte] = useState('');
  const [horizontalImage, setHorizontalImage] = useState('');
  const [horizontalImageName, setHorizontalImageName] = useState('');
  const [horizontalImageInfo, setHorizontalImageInfo] = useState({ w: '', h: '' });
  const [verticalImage, setVerticalImage] = useState('');
  const [verticalImageName, setVerticalImageName] = useState('');
  const [verticalImageInfo, setVerticalImageInfo] = useState({ w: '', h: '' });
  const [focusImage, setFocusImage] = useState('');
  const [focusImageName, setFocusImageName] = useState('');
  const [focusImageInfo, setFocusImageInfo]  = useState({ w: '', h: '' });
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (eventBanner){
      setEventBannerTilte(eventBanner.title ? eventBanner.title : '');
      setHorizontalImage(eventBanner.horizontalImage ? `${prefix}${eventBanner.horizontalImage}` : '');
      setVerticalImage(eventBanner.verticalImage ? `${prefix}${eventBanner.verticalImage}` : '');
      setFocusImage(eventBanner.focusImage ? `${prefix}${eventBanner.focusImage}` : '');
    }
  }, [eventBanner]);

  const handleInputChange = e => {
    setEventBannerTilte(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsSave(true);
  };

  const saveCarouselEvent = ()  => {
    setIsSave(false);
    if (!eventBanner || !eventBanner.bannerId){
      if (horizontalImageName === null || horizontalImageName ===''){
        alert('Please select a horizontal image !');
        return;
      }
      if (verticalImageName === null || verticalImageName ===''){
        alert('Please select a vertical image !');
        return;
      }
      if (focusImageName === null || focusImageName ===''){
        alert('Please select a focus image !');
        return;
      }
    }
    setIsDisabled(true);
    const horizontalImageFile = document.getElementById('horizontalImage_file').files[0]; //undefined if not uploaded
    const verticalImageFile = document.getElementById('verticalImage_file').files[0]; //undefined if not uploaded
    const focusImageFile = document.getElementById('focusImage_file').files[0]; //undefined if not uploaded

    let formData = new FormData();
    formData.append('eventBannerTilte', eventBannerTilte);
    if (horizontalImageFile) formData.append('horizontalImage', horizontalImageFile);
    if (verticalImageFile) formData.append('verticalImage', verticalImageFile);
    if (focusImageFile) formData.append('focusImage', focusImageFile);

    if (eventBanner && eventBanner.bannerId){
      fetch(`${API_BASE_URL}/carousel/event/${eventBanner.bannerId}`, {
        method: 'PATCH',
        headers: getHeadersFormData(),
        body: formData,
      })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          if (body.message){
            alert(body.message);
          } else {
            alert('Some Error Occured !');
          }
        } else {
          alert('Successfully save !');
          searchList();
          handleClose();
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsDisabled(false);
      });
    } else {
      fetch(`${API_BASE_URL}/carousel/event`, {
        method: 'POST',
        headers: getHeadersFormData(),
        body: formData,
      })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          if (body.message){
            alert(body.message);
          } else {
            alert('Some Error Occured !');
          }
        } else {
          alert('Successfully save !');
          searchList();
          handleClose();
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsDisabled(false);
      });
    }
  };
   return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        EVENT
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={'pop_body'}>
          <div>
            <TableContainer className={'tbl_wrap'}>
              <Table className={'tbl_row_typ1'}>
                <tbody>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="is_required">Event Title</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <TextField fullWidth name="title" required margin="dense" variant="outlined" value={eventBannerTilte} onChange={event => handleInputChange(event)} placeholder="Title" />
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="is_required">Horizontal Image</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <ImageEdit
                        image={horizontalImage}
                        setImage={setHorizontalImage}
                        imageName={horizontalImageName}
                        setImageName={setHorizontalImageName}
                        imageInfo={horizontalImageInfo}
                        setImageInfo={setHorizontalImageInfo}
                        inputType="horizontalImage"
                        guideText="Recommended Size: 1280 x 720px"
                        isEdit={true}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="is_required">Vertical Image</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <ImageEdit
                        image={verticalImage}
                        setImage={setVerticalImage}
                        imageName={verticalImageName}
                        setImageName={setVerticalImageName}
                        imageInfo={verticalImageInfo}
                        setImageInfo={setVerticalImageInfo}
                        inputType="verticalImage"
                        guideText="Recommended Size: 610 x 968px"
                        isEdit={true}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="is_required">Focus Image</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <ImageEdit
                        image={focusImage}
                        setImage={setFocusImage}
                        imageName={focusImageName}
                        setImageName={setFocusImageName}
                        imageInfo={focusImageInfo}
                        setImageInfo={setFocusImageInfo}
                        inputType="focusImage"
                        guideText="Recommended Size: 2438 x 1525px"
                        isEdit={true}
                      />
                    </TableCell>
                  </TableRow>
                </tbody>
              </Table>
            </TableContainer>
          </div>
          <div className={'btn_set_wrap'}>
            <Button color="primary" onClick={handleClose} className={'btn_color1'} disabled={isDisabled}>
              Cancel
            </Button>
            <Button type="submit" color="primary" className={'btn_color2'} disabled={isDisabled}>
              Save
            </Button>
          </div>
        </DialogContent>
      </form>
      <CommonDialog
          open={isSave}
          description="Do you want to register for the event?"
          handleCancel={() => {
            setIsSave(false);
          }}
          handleOk={() => {
            saveCarouselEvent();
          }}
        />
    </Fragment>
  );
}

CarouselEventForm.propTypes = {
  eventBanner: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  searchList: PropTypes.func.isRequired,
};
export default connect()(CarouselEventForm);
