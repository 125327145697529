//feed receive
import * as actions from '../../actions/actionTypes';

const initialState = { result: false, msg: '' };
export default function exports(state = initialState, action) {
  switch (action.type) {
    case actions.SET_EXPORTS:
      return action.payload;
    case actions.SET_API_FAIL:
      return { result: false, msg: '' };
    default:
      return state;
  }
}
