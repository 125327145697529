import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { ROWHEADER_TYPE, TABLE_TYPE } from '../consts/IssConsts';
import { Pagination } from '@material-ui/lab';
import ServiceListDropDown from './ServiceListDropDown';
import DynamicDropDown from './DynamicDropDown';
import FixedDropDown from './FixedDropDown';
import ListDropDown from './ListDropDown';
import classNames from 'classnames';
import { defaultValue } from '../utils/IssUtils';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ExcelIconButton from '../common/IconButton';
import { ISS_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';

function ServiceIssueGroupRowComponent(props) {
  const { username, data, rowData, rowIdx, issTableData, checkedList, onCheckedEl, btnIconType, handleClick, onChangeDropDownList, focusItem } = props;
  const [open, setOpen] = useState(false);

  const DEFAULT_CELL_STYLE = ROWHEADER_TYPE.NORMAL;
  /**
   * draw row item by row type
   * @param {*} item
   * @param {*} _colIdx
   * @param {*} _rowIdx
   * @returns
   */
   const handleJiraUrl = (e, url) => {
    const jiraDomainUrl = 'https://hlm.lge.com/qi/browse/';
    e.stopPropagation();
    window.open(jiraDomainUrl + url, '_blank');
  };

  async function getExcelDownload(filter, username) {
    let fileName = '';
    const userInfo = username === 'admin' ? 'cmpuser' : username;
    await fetch(`${ISS_BASE_URL}/api/service/excel/download?dates=${filter.date}&countries=${filter.country}&platforms=${filter.platform}&cps=${filter.provider}&type=${filter.subType}&issueRoot=${filter.source}&issueState=${filter.state}&assignGroup=${filter.assign}&contentsType=${filter.contentsType}&searchTextType=${filter.searchTextType}&searchText=${filter.searchText}&userInfo=${userInfo}`, {
      method: 'GET',
      responseType: 'blob',
      headers: getHeaders()
    })
      .then(response => {
        fileName = response.headers.get('Content-Disposition').split('filename=')[1].slice(1, -1);
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(
          new Blob([blob])
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(err => {
        alert('error')
      });
  };

  const excelDownload = async (issueId) => {
    if ( issueId && issueId.length > 1 ) {
      const defaultRangeDays = 30;
      const today = new Date();
      const utcToday = new Date(today.toUTCString());

      const thirtyDaysAgo = new Date(utcToday);
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);

      const utcTodayString = utcToday.toISOString().substring(0, 10);
      const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0, 10);

      let filter = { subType: 'schedule', date: [thirtyDaysAgoString, utcTodayString], country: '', provider: '', platform: '', source: '', state: '', assign: '', contentsType: '', searchTextType: 'issueId', searchText: issueId };
      getExcelDownload(filter, username);
    }
  }

  const drawRowItemByRowType = (item, _colIdx, _rowIdx, type = '', rowId) => {
    let cellStyle = DEFAULT_CELL_STYLE;
    try {
      if (type === 'subtable') {
        cellStyle = data.cellStyle === undefined ? DEFAULT_CELL_STYLE : data.subTableCellStyle[_colIdx];
      } else {
        cellStyle = data.cellStyle === undefined ? DEFAULT_CELL_STYLE : data.cellStyle[_colIdx];
      }
    } catch (error) {
      console.error('##ISSUE Table Error:', error);
    }

    if (cellStyle === ROWHEADER_TYPE.BLANK_COLUMN) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}></TableCell>
      );
    }

    if (cellStyle === ROWHEADER_TYPE.FIRST) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`} component="th" scope="row">
          {item}
        </TableCell>
      );
    }

    if (cellStyle === ROWHEADER_TYPE.NORMAL) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          {item}
        </TableCell>
      );
    }

    if (cellStyle === ROWHEADER_TYPE.LONG) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          <div className="ellipsis">{item}</div>
        </TableCell>
      );
    }

    if (cellStyle === ROWHEADER_TYPE.DROPDOWN) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          <ServiceListDropDown
            rowId={rowId}
            items={data.dropdownItem}
            title={item}
            onChange={onChangeDropDownList}
            checkedList={checkedList}
          />
        </TableCell>
      );
    }

    if (cellStyle === ROWHEADER_TYPE.DYNAMIC_DROPDOWN) {
      if (!(rowId.indexOf('main') > -1))   {
        return (
          <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item.title}`}>
            <DynamicDropDown
              rowId={rowId}
              items={item.dropdownItem}
              title={item.title}
              onChange={onChangeDropDownList}
              checkedList={checkedList}
            />
          </TableCell>
        );
      } else {
        return (
          <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
            {''}
          </TableCell>
        );
      }
    }

    if (cellStyle === ROWHEADER_TYPE.JIRALINK) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          <button onClick={(e) => handleJiraUrl(e, item)}>{item}</button>
        </TableCell>
      );
    }

    if (cellStyle === ROWHEADER_TYPE.EXPAND) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`} onClick={(event) => expandClick(event)} style={{ cursor : isNaN(item) ? '': 'pointer' }}>
          {item}
        </TableCell>
      );
    }

    if (cellStyle === ROWHEADER_TYPE.DROPDOWN_FOR_DISPLAY) {
      let labelText = '';
      if (item.length > 1) {
        return (
          <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
            <FixedDropDown
              items={item}
              label={item[0]}
            />
          </TableCell>
        );
      } else if (item.length === 1) {
        return (
          <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
              {item[0]}
          </TableCell>
        );
      } else {
        return (
          <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
              {''}
          </TableCell>
        );
      }
    }
    if (cellStyle === ROWHEADER_TYPE.PLATFORM_DROPDOWN) {
      let labelText = '';
      if (item.length > 1) {
        return (
          <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
            <FixedDropDown
              items={item}
              label={item.length + labelText}
            />
          </TableCell>
        );
      } else if (item.length === 1) {
        return (
          <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
              {item[0]}
          </TableCell>
        );
      } else {
        return (
          <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
              {''}
          </TableCell>
        );
      }
    }
    if (cellStyle === ROWHEADER_TYPE.EXCEL_DOWNLOAD_BUTTON) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          <ExcelIconButton type={'button'} text={'Excel Download'} btnClass={'btn-icon-download'} clickEvent={() => excelDownload(item)}/>
        </TableCell>
      );
    }
  };

  const drawItemCheckboxChecked = (data) => {
    return checkedList.includes(data.rowId);
  };
  /**
   * row영역 Item별 Checkbox
   * @param {*} data
   * @returns
   */
  const drawItemCheckbox = data => {
    return (
      <TableCell>
        <label>
          <input type="checkbox"
            onChange={e => onCheckedEl(e.target.checked, data)}
            checked={drawItemCheckboxChecked(data)}
            onClick={e => e.stopPropagation()}
          />
          <span onClick={e => e.stopPropagation()}></span>
        </label>
      </TableCell>
    );
  };

  const drawColSpanTableCell = data => {
    if (data.colspanCount) {
      return <TableCell colSpan={data.colspanCount} />;
    }
  };

  const getSubTableRColSpanValue = () => {
    var spanVal = data.cellWidth.length;
    if (issTableData.isCheckboxSelection) spanVal++;
    return spanVal + 1; //확장버튼 영역으로 인한 cell 한개 추가
  };
  const expandClick = (event) => {
    //event.stopPropagation();
    setOpen(!open);
  };
  return (
    <React.Fragment>
      <TableRow className={open ? '' : ''} style={{ backgroundColor: focusItem === rowData.rowId ? '#302d3e' : '#1c1c1c' }} onClick={(event) => handleClick(event, `row_${0}`, rowData)}>
        {
          //rowItem 중 첫번째 checkbox
          issTableData.isCheckboxSelection && drawItemCheckbox(rowData)
        }
        <TableCell>
          { rowData.subTable && (
            // <button aria-label="expand row" onClick={(event) => expandClick(event)} className={classNames('btn-expand', { on: open })}>{rowData.rowId}</button>
            <IconButton aria-label="expand row" size="small" onClick={(event) => expandClick(event)} className={classNames('btn-expand-arrow', { on: open })}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        {rowData.row.map((colItem, _colIdx) => drawRowItemByRowType(colItem, _colIdx, rowIdx, '', rowData.rowId))}
      </TableRow>
      {rowData.subTable && (
        <TableRow className="insideTable">
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={getSubTableRColSpanValue()}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="sub_table">
                  <colgroup>
                    {issTableData.isCheckboxSelection && <col width={'1%'} key={'all'}></col>}
                    {<col width={'1%'} />}
                    {
                      /* column width */
                      data.cellWidth && data.cellWidth.map((width, index) => <col width={width} key={index} />)
                    }
                  </colgroup>
                  <TableBody>
                    {rowData.subTable.rowdata.map((srRows, _stIdx) => (
                      // <TableRow key={_stIdx} onClick={() => handleClick(`row_${_stIdx}`, srRows)}></TableRow>
                      <TableRow key={_stIdx} style={{ backgroundColor: focusItem === srRows.rowId ? '#302d3e' : '#1c1c1c' }} onClick={(event) => handleClick(handleClick, `row_${_stIdx}`, srRows)}>
                        {/* {
                          //rowItem 중 첫번째 checkbox
                          issTableData.isCheckboxSelection && drawItemCheckbox(srRows)
                        } */}
                        <TableCell />
                        <TableCell />
                        {drawColSpanTableCell(rowData.subTable)}
                        {srRows.row.map((colItem, _colIdx) => drawRowItemByRowType(colItem, _colIdx, rowIdx, 'subtable', srRows.rowId))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

const ServiceIssueGroupTable = props => {
  //const defaultItemsPerPage = 20; // 페이지당 default 항목 수
  const {
    height, pagination, tableType, data, btnIconType , handleClickItem, changeDropDownAlert
    , itemsPerPage, pagingChangeCallback, focusItem, setLastChangedStatus, setLastChangedAssign
    , setFocusItem, setIssueCheckedList, currentPage , setCurrentPage, pageSize , setPageSize, sortingItem
  } = props;

  //테이블 체크박스
  const groupTableRef = useRef(null);
  const [checkedList, setCheckedLists] = useState([]);
  const [lastStatus, setLastStatus] = useState('');
  const [lastAssign, setLastAssign] = useState('');
  //const [page, setPage] = useState(1);
  const [count, setCount] = useState(1); // Pagination의 count
  const [originalData, setOriginalData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [previousPageSize, setPreviousPageSize] = useState(20);
  const [isSortingEnable, setSortingEnable] = useState(false);
  const [sortColumn, setSortColumn] = useState(0);
  const [sortDirection, setSortDirection] = useState('desc');
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const displayPageCountList = [
    { index: 20, name: 20 },
    { index: 50, name: 50 },
    { index: 100, name: 100 },
  ];

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    sticky_first_line: {
      position: 'sticky',
      top: 0,
    },
    sticky_second_line: {
      position: 'sticky',
      top: '41px',
    },
  });

  const classes = useStyles();
  /**
   * data를 이용한 기본값 설정
   * 데이터에 필수값이 없는 경우 오류발생을 줄이기 위해 default값 설정처리
   */
  const issTableData = {
    tableType: defaultValue(data.tableType, TABLE_TYPE.NORMAL),
    isCheckboxSelection: defaultValue(data.tableType, TABLE_TYPE.NORMAL) === TABLE_TYPE.CHECKBOX_SELECTION ? true : false,
    title: defaultValue(data.title, '제목'),
    visibleTitle: defaultValue(data.visibleTitle, false),
    existTooltip: defaultValue(data.existTooltip, false),
    tooltip: defaultValue(data.tooltip, 'tooltip text..!!'),
  };

  const handleClick = (event, key, rowItem) => {
    if (handleClickItem) {
      handleClickItem(rowItem);
    }
  };

  const onChangeDropDownList = (item) => {
    if (item && item.isChecked){
      changeDropDownData(item.rowId, item.itemType, item.name);
    } else {
      changeDropDownAlert();
    }
  };

  const onChangePageHalder = (event, value) => {
    if (currentPage !== value){
      setFocusItem('');
    }
    setCurrentPage(value);
    setCheckedLists([]);
    if (pagingChangeCallback) {
      pagingChangeCallback();
    }
  };

  useEffect(() => {
    if (setLastChangedStatus) {
      setLastChangedStatus(lastStatus);
    }
  }, [ lastStatus ]);

  useEffect(() => {
    if (setLastChangedAssign) {
      setLastChangedAssign(lastAssign);
    }
  }, [ lastAssign ]);

  useEffect(() => {
    if (setIssueCheckedList) {
      setIssueCheckedList(checkedList);
      if (checkedList.length < 1){
        setLastStatus('');
        setLastAssign('');
      } else {
        let headerStatusIndex = data.header.indexOf('Status');
        let headerAssignIndex = data.header.indexOf('Assign');
        if (lastStatus && lastStatus !== ''){
          displayedData.forEach(main => {
            if ( checkedList.includes(main.rowId) ){
              main.row[headerStatusIndex] = lastStatus;
            }
            if (main.subTable && main.subTable.rowdata.length > 0){
              main.subTable.rowdata.forEach(sub => {
                if ( checkedList.includes(sub.rowId) ){
                  sub.row[headerStatusIndex - main.subTable.colspanCount] = lastStatus;
                }
              });
            }
          });
        }
        if (lastAssign && lastAssign !== ''){
          displayedData.forEach(main => {
            if ( checkedList.includes(main.rowId) ){
              main.row[headerAssignIndex].title = lastAssign;
            }
            if (main.subTable && main.subTable.rowdata.length > 0){
              main.subTable.rowdata.forEach(sub => {
                if ( checkedList.includes(sub.rowId) ){
                  sub.row[headerAssignIndex - main.subTable.colspanCount].title = lastAssign;
                }
              });
            }
          });
        }
      }
    }
  }, [checkedList]);

  useEffect(() => {
    if (data && data.rowdata) {
      setOriginalData(data.rowdata);
      setCount(Math.ceil(data.rowdata.length / (itemsPerPage ? itemsPerPage : pageSize)));
    }
  }, [data]);

  useEffect(() => {
    if (pagination) {
      const startIndex = (currentPage - 1) * (itemsPerPage ? itemsPerPage : pageSize);
      const endIndex = startIndex + (itemsPerPage ? itemsPerPage : pageSize);
      setDisplayedData(originalData.slice(startIndex, endIndex));
    } else {
      setDisplayedData(originalData);
    }
  }, [originalData]);

  useEffect(() => {
    if (pagination) {
      groupTableRef && groupTableRef.current.scrollTo(0, 0);
      const startIndex = (currentPage - 1) * (itemsPerPage ? itemsPerPage : pageSize);
      const endIndex = startIndex + (itemsPerPage ? itemsPerPage : pageSize);
      setDisplayedData(originalData.slice(startIndex, endIndex));
    }
  }, [currentPage]);

  useEffect(() => {
    if (pagination && data && data.rowdata) {
      setCount(Math.ceil(data.rowdata.length / pageSize));
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      setDisplayedData(originalData.slice(startIndex, endIndex));
      if (pageSize !== previousPageSize){
        setCurrentPage(1);
        setPreviousPageSize(pageSize);
      }
    }
  }, [pageSize]);

  useEffect(() => {
    if (sortingItem && sortingItem.enable) {
      setSortingEnable(true);
      setSortColumn(sortingItem.sortColumn ? sortingItem.sortColumn : 0);
      setSortDirection(sortingItem.sortDirection ? sortingItem.sortDirection : 'desc');
    }
    return () => {
      const ptableref = groupTableRef.current;
      if (ptableref) {
        // ptableref.remove();
      }
    };
  }, []);

  useEffect(() => {
    const sortedRows = [...data.rowdata].sort((a, b) => {
      if ( sortColumn === -1){
        return ;
      }
      const aValue = a.row[sortColumn] ? a.row[sortColumn] : '';
      const bValue = b.row[sortColumn] ? b.row[sortColumn] : '';
      if ( tableType ==='app'){
        switch (sortColumn) {
          // issue id
          case 0:
            const aIds = a.row[sortColumn].split('-');
            const bIds = b.row[sortColumn].split('-');
            return sortDirection === 'asc' ? parseFloat(aIds[1]) - parseFloat(bIds[1]) : parseFloat(bIds[1]) - parseFloat(aIds[1]);
          //Platform
          case 7:
            const aArray = a.row[sortColumn] ? a.row[sortColumn] : [];
            const bArray = b.row[sortColumn] ? b.row[sortColumn] : [];
            if(Array.isArray(aArray) && aArray.length === 0)  {
              aArray.push('');
            }
            if(Array.isArray(bArray) && bArray.length === 0)  {
              bArray.push('');
            }
            if ( aArray.length != bArray.length ){
              return sortDirection === 'asc' ? aArray.length - bArray.length : bArray.length - aArray.length;
            } else {
              return sortDirection === 'asc' ? aArray[0].localeCompare(bArray[0]) : bArray[0].localeCompare(aArray[0]);
            }
          // custom object
          case 4: case 6: case 13: case 14:
            if (typeof aValue == 'object' && typeof bValue === 'object') {
              if (Array.isArray(aValue) && Array.isArray(bValue)){
                return sortDirection === 'asc' ? aValue[0].localeCompare(bValue[0]) : bValue[0].localeCompare(aValue[0]);
              } else {
                return sortDirection === 'asc' ? aValue[Object.keys(aValue)[0]].localeCompare(bValue[Object.keys(bValue)[0]]) : bValue[Object.keys(bValue)[0]].localeCompare(aValue[Object.keys(aValue)[0]]);
              }
            } else {
              return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);;
            }
          // issue count
          case 5:
            return sortDirection === 'asc' ? a.issueCount - b.issueCount : b.issueCount - a.issueCount;
          default:
            return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);;
        }
      } else if ( tableType ==='play'){
        switch (sortColumn) {
          // issue id
          case 0:
            const aIds = a.row[sortColumn].split('-');
            const bIds = b.row[sortColumn].split('-');
            return sortDirection === 'asc' ? parseFloat(aIds[1]) - parseFloat(bIds[1]) : parseFloat(bIds[1]) - parseFloat(aIds[1]);
          //Platform
          case 7:
            const aArray = a.row[sortColumn] ? a.row[sortColumn] : [];
            const bArray = b.row[sortColumn] ? b.row[sortColumn] : [];
            if(Array.isArray(aArray) && aArray.length === 0)  {
              aArray.push('');
            }
            if(Array.isArray(bArray) && bArray.length === 0)  {
              bArray.push('');
            }
            if ( aArray.length != bArray.length ){
              return sortDirection === 'asc' ? aArray.length - bArray.length : bArray.length - aArray.length;
            } else {
              return sortDirection === 'asc' ? aArray[0].localeCompare(bArray[0]) : bArray[0].localeCompare(aArray[0]);
            }
          // custom object
          case 4: case 6: case 14: case 15:
            if (typeof aValue == 'object' && typeof bValue === 'object') {
              if (Array.isArray(aValue) && Array.isArray(bValue)){
                return sortDirection === 'asc' ? aValue[0].localeCompare(bValue[0]) : bValue[0].localeCompare(aValue[0]);
              } else {
                return sortDirection === 'asc' ? aValue[Object.keys(aValue)[0]].localeCompare(bValue[Object.keys(bValue)[0]]) : bValue[Object.keys(bValue)[0]].localeCompare(aValue[Object.keys(aValue)[0]]);
              }
            } else {
              return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);;
            }
          // issue count
          case 5:
            return sortDirection === 'asc' ? a.issueCount - b.issueCount : b.issueCount - a.issueCount;
          // channel number
          case 9:
            return sortDirection === 'asc' ? parseFloat(aValue) - parseFloat(bValue) : parseFloat(bValue) - parseFloat(aValue);
          default:
            return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);;
        }
      } else if (tableType ==='schedule' ){
        switch (sortColumn) {
          // issue id
          case 0:
            const aIds = a.row[sortColumn].split('-');
            const bIds = b.row[sortColumn].split('-');
            return sortDirection === 'asc' ? parseFloat(aIds[1]) - parseFloat(bIds[1]) : parseFloat(bIds[1]) - parseFloat(aIds[1]);
          // Platform
          case 4: case 5:
            const aArray = a.row[sortColumn] ? a.row[sortColumn] : [];
            const bArray = b.row[sortColumn] ? b.row[sortColumn] : [];
            if(Array.isArray(aArray) && aArray.length === 0)  {
              aArray.push('');
            }
            if(Array.isArray(bArray) && bArray.length === 0)  {
              bArray.push('');
            }
            if ( aArray.length != bArray.length ){
              return sortDirection === 'asc' ? aArray.length - bArray.length : bArray.length - aArray.length;
            } else {
              return sortDirection === 'asc' ? aArray[0].localeCompare(bArray[0]) : bArray[0].localeCompare(aArray[0]);
            }
          // contents count
          case 6:
            return sortDirection === 'asc' ? a.issueCount - b.issueCount : b.issueCount - a.issueCount;
          // custom object
          case 7: case 8:
          if (typeof aValue == 'object' && typeof bValue === 'object') {
            if (Array.isArray(aValue) && Array.isArray(bValue)){
              return sortDirection === 'asc' ? aValue[0].localeCompare(bValue[0]) : bValue[0].localeCompare(aValue[0]);
            } else {
              return sortDirection === 'asc' ? aValue[Object.keys(aValue)[0]].localeCompare(bValue[Object.keys(bValue)[0]]) : bValue[Object.keys(bValue)[0]].localeCompare(aValue[Object.keys(aValue)[0]]);
            }
          } else {
            return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);;
          }
          case 12:
            return ;
          default:
            return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);;
        }
      } else{
        return;
      }
    });
    setOriginalData(sortedRows);
  }, [sortColumn, sortDirection, data.rowdata]);

  const handleSort = (column) => {
    if (isSortingEnable) {
      const isSameColumn = sortColumn === column;
      const newSortDirection = isSameColumn ? (sortDirection === 'asc' ? 'desc' : 'asc') : sortDirection;
      setSortDirection(newSortDirection);
      setSortColumn(column);
      setCheckedLists([]);
    }
  };

  const onCheckedAll = useCallback(
    checked => {
      if (checked) {
        const checkedListArray = [];
        //main issue
        displayedData.forEach(item => checkedListArray.push(item.rowId));
        //sub issue
        let subIssue = displayedData.filter(f => f.rowType === 'main').map(m => m.subTable.rowdata).reduce(function (acc, cur) {
          return acc.concat(cur);
        }, []);
        subIssue.forEach(item => checkedListArray.push(item.rowId));
        setCheckedLists(checkedListArray);
      } else {
        setCheckedLists([]);
      }
    },
    [displayedData]
  );

  const onCheckedEl = useCallback(
    (checked, list) => {
      if (checked) {
        let cList = [];
        if (list.rowType === 'sub'){
          cList.push(list.rowId);
          let subList = [];
          let main = list.rowId.split('_');
          let mainRowId = main[0]+'_main';
          let sub = displayedData.filter(f => f.rowId === mainRowId);
          sub.map(m1 => m1.subTable).map(m2 => m2.rowdata).forEach(f => f.forEach(f2 => subList.push(f2.rowId)));
          subList = subList.filter(sf => sf !== list.rowId);
          let checked = subList.filter(el => checkedList.includes(el));
          if (subList.length !== 0 && subList.length === checked.length){
            cList.push(mainRowId);
          }
          cList = checkedList.concat(cList);
        } else if (list.rowType === 'main'){
          let sub = displayedData.filter(f => f.rowId === list.rowId);
          sub.map(m1 => m1.subTable).map(m2 => m2.rowdata).forEach(f => f.forEach(f2 => cList.push(f2.rowId)));
          cList = cList.filter(f => !checkedList.includes(f));
          cList = checkedList.concat(cList);
          cList.push(list.rowId);
        } else {
          if (!checkedList.includes(list.rowId)){
            cList.push(list.rowId);
          }
          cList = checkedList.concat(cList);
        }
        setCheckedLists(cList);
      } else {
        let cList = [];
        if (list.rowType === 'sub'){
          let main = list.rowId.split('_');
          let mainRowId = main[0]+'_main';
          cList.push(list.rowId);
          cList.push(mainRowId);
        } else if (list.rowType === 'main'){
          let sub = displayedData.filter(f => f.rowId === list.rowId);//.map(m => m.subTable.rowdata)
          sub.map(m1 => m1.subTable).map(m2 => m2.rowdata).forEach(f => f.forEach(f2 => cList.push(f2.rowId)));
          cList.push(list.rowId);
        } else {
          cList.push(list.rowId);
        }
        setCheckedLists(checkedList.filter(el => !cList.includes(el)));
      }
    },
    [checkedList, displayedData],
  );
  /**
   * title영역 All Checkbox
   * @param {*} data
   * @returns
   */
  const checkedCount = data => {
    let list =[];
    list = data.filter(f => f.rowType === 'main').map(m => m.subTable.rowdata).reduce(function (acc, cur) {
      return acc.concat(cur);
    }, []);
    let count = data.length + list.length;
    return count;
  };

  const handleDisplaySize = (item) => {
    setPageSize(item.index);
  };

  const drawTitleAllCheckbox = data => {
    return (
      <TableCell>
        <label className="checkCount">
          <input type="checkbox"
            onChange={e => onCheckedAll(e.target.checked)}
            checked={checkedList.length === 0 ? false : checkedList.length === checkedCount(data) ? true : false}
            onClick={e => e.stopPropagation()}
          />
          <span onClick={e => e.stopPropagation()}></span>
          <div>({checkedList.length})</div>
        </label>
      </TableCell>
    );
  };

  const changeDropDownData = (itemRowId, itemType, value) => {
    let headerIndex = -1;
    if ( itemType === 'issueState' ){
      if ( checkedList.length > 0 && checkedList.includes(itemRowId) ){
        setLastStatus(value);
      }
      headerIndex = data.header.indexOf('Status');
      displayedData.forEach(main => {
        if ( checkedList.includes(main.rowId) && checkedList.includes(itemRowId)){
          main.row[headerIndex] = value;
        }
      });
    } else if (itemType === 'assigneeGroup'){
      if ( checkedList.length > 0 && checkedList.includes(itemRowId) ){
        setLastAssign(value);
      }
      headerIndex = data.header.indexOf('Assign');
      displayedData.forEach(main => {
        if ( checkedList.includes(main.rowId) && checkedList.includes(itemRowId)){
          main.row[headerIndex].title = value;
        }
      });
    }
  };

  return (
    <>
      <TableContainer ref={groupTableRef} component={Paper} className="collapseTable" style={{ height: `${height}` }}>
        <Table stickyHeader={true} aria-label="collapsible table">
          <colgroup>
            {issTableData.isCheckboxSelection && <col width={'1%'} key={'all'}></col>}
            {<col width={'1%'} />}
            {
              /* column width */
              data.cellWidth && data.cellWidth.map((width, index) => <col width={width} key={index} />)
            }
          </colgroup>
          <TableHead>
            <TableRow>
              {issTableData.isCheckboxSelection && drawTitleAllCheckbox(displayedData)}
              <TableCell />
                {data.header.map((_header, index) => (
                  // <TableCell key={_header}>{_header}</TableCell>
                  <TableCell key={_header + '_' + index} className={data.headerTop && classes.sticky_second_line}
                    onClick={() => isSortingEnable ? handleSort(index) : ''}
                    onMouseEnter={() => isSortingEnable ? setHoveredColumn(index) : ''}
                    onMouseLeave={() => isSortingEnable ? setHoveredColumn(null) : ''}
                  >
                  {_header}
                  {isSortingEnable && (sortColumn === index || hoveredColumn === index) && (
                    <>
                      {sortDirection === 'asc' && sortColumn === index ? (
                        <ArrowUpwardIcon style={{ fontSize: '16px', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} />
                      ) : (
                        <ArrowDownwardIcon style={{ fontSize: '16px', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} />
                      )}
                    </>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.map((rowItem, _rowIdx) => (
              <ServiceIssueGroupRowComponent
                key={_rowIdx}
                rowData={rowItem}
                data={data}
                rowIdx={_rowIdx}
                issTableData={issTableData}
                checkedList={checkedList}
                onCheckedEl={onCheckedEl}
                btnIconType={btnIconType}
                handleClick={handleClick}
                onChangeDropDownList={onChangeDropDownList}
                focusItem={focusItem}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination &&
      <>
      <div className="pagination_ui">
        <div className="list_dropdown">
          <ListDropDown items={displayPageCountList} title={pageSize} onChange={handleDisplaySize} dropdownDirection={'up'}/>
        </div>
        <Pagination count={count} page={currentPage} showFirstButton showLastButton onChange={onChangePageHalder} className="ui-pagination" />
      </div>
      </>
      }
    </>
  );
};

ServiceIssueGroupTable.propTypes = {
  username: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  username: state.currentUser.username,
});

export default connect(mapStateToProps) (ServiceIssueGroupTable);
