import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { request } from '../../../util/request';
import { Button, Dialog, TableContainer, Table, TableCell, TableHead, makeStyles, IconButton, Collapse, Box, TableSortLabel } from '@material-ui/core';
import { getSupportLanguagesAction } from '../../../actions/webActions';
import { addNotificationAction, getNotificationsAction, publishNotificationsAction, searchNotificationsAction, updateNotificationAction } from '../../../actions/notificationActions';
import { setABTestParamAction, getABTestTabCategoryAction, endAbTest, getSegmentDetails, getServiceDetails, endANService, getServiceSegmentDetails } from '../../../actions/homeAppActions';
import ABTestFilter from './ABTestFilter';
import { Link } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AddABTest from './AddABTest';
import EditABTest from './EditABTest';
import ANTDeleteSegment from './ANTDeleteSegment';
import { getHeaders } from '../../../util/actionUtil';
import { API_BASE_URL } from '../../../constants';
import { NoDataMap } from '../../../common/NoDataMap';
import AddANserviceDialog from './AddANserviceDialog';
import EditANServiceDialog from './EditANserviceDialog';
import CircleIcon from '@material-ui/icons/FiberManualRecord';

function ABTest({ endAbTest, history, regionList, regionPlatformList, abTestTabCategory, abTestParam, abTestDetails, setABTestParamAction, getSupportLanguages, anServiceDetails }) {
  const classes = useStyles();
  const [utcHour, setUtcHour] = useState('');
  useEffect(() => {
    getSupportLanguages();
    fetchUTCTime();
    setABTestParamAction('', '', '', '', '', '');
  }, []);

  const [endTest, setEndTest] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [tabCode, setTabCode] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [categoryCode, setCategoryCode] = React.useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [displayMode, setDisplayMode] = React.useState('AN Service');
  const [endANService, setEndANService] = React.useState(false);

  const [abTestTitle, setAbTestTitle] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [stabCode, setStabCode] = React.useState('All');
  const [scategoryCode, setScategoryCode] = React.useState('All');
  const [deviceType, setDeviceType] = React.useState('All');
  const [region, setRegion] = React.useState('');
  const [platformVersion, setPlatformVersion] = React.useState('All');
  const [status, setStatus] = React.useState('All');
  const [openAddService, setOpenAddService] = useState(false);

  const defaultSearchData = {
    deviceType: '',
    countryCodes: [],
    channelMapList: [],
    date: '',
  };
  const [searchData, setSearchData] = useState(defaultSearchData);
  const [requiredField, setRequiredField] = useState(false);

  const handleSearchDataChange = e => {
    const { name, value } = e.target;
    if (name === 'deviceType') {
      setSearchData({ ...searchData, ...{ [name]: value, countryCodes: [], channelMapList: [] } });
    } else if (name === 'countryCodes') {
      setSearchData({ ...searchData, ...{ [name]: value, channelMapList: [] } });
    } else {
      setSearchData({ ...searchData, ...{ [name]: value } });
    }
  };

  const getCountryCode = value => {
    gePtlatformVersion(value);
    return regionList.find(n => n.deviceType === value).list[0].cntry;
  };

  const gePtlatformVersion = (deviceType, region) => {
    if (regionPlatformList.find(n => n.deviceType === deviceType && n.region === region)) {
      return regionPlatformList.find(n => n.deviceType === deviceType && n.region === region).platformVersion;
    }
    return '';
  };

  const getRegion = (deviceType, countryCode) => {
    abTestParam.region = '';
    (regionList.find(n => n.deviceType === deviceType) || { list: [] }).list.forEach(a => {
      if (a.cntry.includes(countryCode)) {
        abTestParam.region = a.region;
      }
    });
    return abTestParam.region;
  };

  const handleEndAbTest = async rowData => {
    if (window.confirm('Are you sure to end the test?')) {
      abTestDetails = [];
      setEndTest(true);
      endAbTest(rowData.idx);
      return true;
    } else {
      return false;
    }
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [date.getFullYear(), padTo2Digits(date.getUTCMonth() + 1), padTo2Digits(date.getUTCDate())].join('-') + ' ' + [padTo2Digits(date.getUTCHours()), padTo2Digits(date.getUTCMinutes()), padTo2Digits(date.getUTCSeconds())].join(':');
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function getUTCHour() {
    let utcHour = await fetch(API_BASE_URL + '/abtest/utchour', {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        return body;
      });
    return utcHour;
  }

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function anTestTableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  const fetchUTCTime = async () => {
    let utcHour = await getUTCHour();
    setUtcHour(utcHour);
  };

  function onClickServiceButton() {
    setOpenAddService(true);
  }

  return (
    <div>
      <Fragment>
        <ABTestFilter
          title={title}
          handleChangeTitle={e => {
            setTitle(e.target.value);
          }}
          regionList={regionList}
          deviceType={abTestParam.deviceType}
          handleChangeDeviceType={e => {
            setABTestParamAction(e.target.value, getCountryCode(e.target.value), regionList.find(n => n.deviceType === e.target.value).list[0].region, gePtlatformVersion(e.target.value, regionList.find(n => n.deviceType === e.target.value).list[0].region), abTestParam.status);
          }}
          countryCode={abTestParam.countryCode}
          region={abTestParam.region}
          handleChangeCountry={value => {
            setABTestParamAction(abTestParam.deviceType, value, getRegion(abTestParam.deviceType, value), gePtlatformVersion(abTestParam.deviceType, value), abTestParam.status);
          }}
          regionPlatformList={regionPlatformList}
          platformVersion={abTestParam.platformVersion}
          abTestTabCategory={abTestTabCategory}
          handleChangePlatformVersion={e => {
            setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, e.target.value, abTestParam.status);
          }}
          tabCode={tabCode}
          handleChangeTab={e => {
            setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, abTestParam.status);
            setTabCode(e.target.value);
          }}
          categoryCode={categoryCode}
          handleChangeCategory={e => {
            setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, abTestParam.status);
            setCategoryCode(e.target.value);
          }}
          searchData={searchData}
          handleSearchDataChange={(title, startDate, endDate, tbCode, catCode, dType, regionCode, pVersion, abStatus) => {
            setAbTestTitle(title);
            setStartDate(startDate);
            setEndDate(endDate);
            setStabCode(tbCode);
            setScategoryCode(catCode);
            setDeviceType(dType);
            setRegion(regionCode);
            setPlatformVersion(pVersion);
            setStatus(abStatus);
          }}
          requiredField={requiredField}
          endTest={endTest}
          setEndTest={setEndTest}
          displayMode={displayMode}
          handleChangeDisplayMode={e => {
            setDisplayMode(e.target.value);
          }}
          endANService={endANService}
          setEndANService={setEndANService}
        />

        <div className="terms_area tbl_wrap">
          <div className={classes.root}>
            <div className={'btn_set_wrap tbl_top1 an_test_service_label'}>
              <div>
                <Button color="secondary" variant="contained" className={'btn_color2'} onClick={() => onClickServiceButton()}>
                  Add
                </Button>
              </div>
              {displayMode === 'AN Service' ? <label className="label_size">Local Time Based</label> : <label className="label_size">UTC Based</label>}
            </div>
            <Paper className={classes.paper}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell id="title">
                        <TableSortLabel active={orderBy === 'title'} direction={orderBy === 'title' ? order : 'asc'} onClick={createSortHandler('title')}>
                          Title
                        </TableSortLabel>
                      </TableCell>

                      <TableCell id="countryCode">
                        {' '}
                        <TableSortLabel active={orderBy === 'countryCode'} direction={orderBy === 'countryCode' ? order : 'asc'} onClick={createSortHandler('countryCode')}>
                          country
                        </TableSortLabel>
                      </TableCell>

                      <TableCell id="startTime">
                        {' '}
                        <TableSortLabel active={orderBy === 'startTime'} direction={orderBy === 'startTime' ? order : 'asc'} onClick={createSortHandler('startTime')}>
                          {displayMode === 'AN Service' ? 'Start' : 'Start'}{' '}
                        </TableSortLabel>
                      </TableCell>

                      <TableCell id="endTime">
                        {' '}
                        <TableSortLabel active={orderBy === 'endTime'} direction={orderBy === 'endTime' ? order : 'asc'} onClick={createSortHandler('endTime')}>
                          {' '}
                          {displayMode === 'AN Service' ? 'Initial End' : 'Initial End'}
                        </TableSortLabel>{' '}
                      </TableCell>

                      <TableCell id="forcedEndTime">
                        {' '}
                        <TableSortLabel active={orderBy === 'forcedEndTime'} direction={orderBy === 'forcedEndTime' ? order : 'asc'} onClick={createSortHandler('forcedEndTime')}>
                          {displayMode === 'AN Service' ? 'Actual End' : 'Actual End'}
                        </TableSortLabel>{' '}
                      </TableCell>

                      <TableCell id="tabCode">
                        {' '}
                        <TableSortLabel active={orderBy === 'tabCode'} direction={orderBy === 'tabCode' ? order : 'asc'} onClick={createSortHandler('tabCode')}>
                          Tab
                        </TableSortLabel>
                      </TableCell>

                      <TableCell id="categoryCode">
                        {' '}
                        <TableSortLabel active={orderBy === 'categoryCode'} direction={orderBy === 'categoryCode' ? order : 'asc'} onClick={createSortHandler('categoryCode')}>
                          Category
                        </TableSortLabel>
                      </TableCell>

                      <TableCell id="platformVersion">
                        <TableSortLabel active={orderBy === 'platformVersion'} direction={orderBy === 'platformVersion' ? order : 'asc'} onClick={createSortHandler('platformVersion')}>
                          {' '}
                          Platform Version
                        </TableSortLabel>
                      </TableCell>
                      <TableCell id="status">
                        <TableSortLabel active={orderBy === 'status'} direction={orderBy === 'status' ? order : 'asc'} onClick={createSortHandler('status')}>
                          {' '}
                          Status
                        </TableSortLabel>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayMode !== 'AN Service' &&
                      anTestTableSort(abTestDetails, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(abTestRow => (
                          <Row
                            key={abTestRow.idx}
                            abTestRow={abTestRow}
                            history={history}
                            formatDate={formatDate}
                            handleEndAbTest={handleEndAbTest}
                            utcHour={utcHour}
                            displayMode={displayMode}
                            abTestTitle={abTestTitle}
                            startDate={startDate}
                            endDate={endDate}
                            tabCode={stabCode}
                            categoryCode={scategoryCode}
                            deviceType={deviceType}
                            region={region}
                            platformVersion={platformVersion}
                            status={status}
                            setEndANService={setEndANService}
                          />
                        ))}

                    {displayMode === 'AN Service' &&
                      anTestTableSort(anServiceDetails, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(abTestRow => <ANTTestServiceTable key={abTestRow.serviceId} serviceRow={abTestRow} abTestRow={abTestRow} history={history} formatDate={formatDate} setEndANService={setEndANService} utcHour={utcHour} />)}
                  </TableBody>
                </Table>
                {displayMode !== 'AN Service' && (!abTestDetails || !abTestDetails.length) ? <NoDataMap contentName="Data" show /> : null}
                {displayMode === 'AN Service' && (!anServiceDetails || !anServiceDetails.length) ? <NoDataMap contentName="Data" show /> : null}
              </TableContainer>
            </Paper>
          </div>
          <div className={'btn_set_wrap'}>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20]}
                  component="div"
                  count={displayMode === 'AN Service' ? anServiceDetails.length : abTestDetails.length}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={'Rows'}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </div>
        </div>
      </Fragment>
      <Dialog className={'pop_wrap'} open={openAddService}>
        <AddANserviceDialog handleTabClose={() => setOpenAddService(false)} setEndANService={setEndANService} />
      </Dialog>
    </div>
  );
}
function Row({ abTestRow, history, formatDate, handleEndAbTest, utcHour, displayMode, abTestTitle, startDate, endDate, tabCode, categoryCode, deviceType, region, platformVersion, status, setEndANService }) {
  const [open, setOpen] = React.useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [openAddService, setOpenAddService] = useState(false);

  const handleLinkClick = () => {
    setOpenPop(true);
  };

  const handleTabClose = () => {
    setOpenPop(false);
  };
  const expandSegment = abTestRow => {
    if (open) {
      setOpen(false);
    } else if (abTestRow.segmentList && abTestRow.segmentList.length > 0) {
      setOpen(!open);
    } else {
      getSegmentDetails(abTestRow.abtestId).then(response => {
        abTestRow.segmentList = abTestRow.segmentList || []; // Ensure segmentList exists
        response.forEach(data => {
          data.serviceList = []; // Initialize an empty array for servicesList
          abTestRow.segmentList.push(data);
        });
        setOpen(!open);
      });
    }
  };

  function checkForeligebleForEdit(ab, utcHour) {
    var startTime = new Date(ab.startTime);
    startTime.setDate(startTime.getDate() - 1);
    startTime.setHours(utcHour);
    var todayDateUTC = new Date(formatDate(new Date()));
    if (new Date(ab.forcedEndTime) <= new Date(formatDate(new Date()))) {
      return true;
    } else if (new Date(formatDate(new Date())) < new Date(ab.startTime)) {
      return false || todayDateUTC > startTime;
    } else {
      return true;
    }
  }

  function onClickServiceButton(abTestRow) {
    setOpenAddService(true);
  }

  function formateDateTime(dateTime, type) {
    if (type === 'startTime' || type === 'endTime') {
      return dateTime.slice(0, -3)
    }
    return (dateTime.split(':')[2] === '00') ? dateTime.slice(0, -3) : dateTime;
  }


  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ backgroundColor: '#EDF2FF' }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => expandSegment(abTestRow)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell key={abTestRow.title}>
          <Link className="link" onClick={handleLinkClick}>
            {abTestRow.title}
          </Link>
          <Dialog className={'pop_wrap'} open={openPop} onClose={handleTabClose}>
            <EditABTest id={abTestRow.idx} handleTabClose={handleTabClose} />
          </Dialog>
        </TableCell>
        <TableCell>{abTestRow.countryCode}</TableCell>
        <TableCell>{formateDateTime(abTestRow.startTime, 'startTime')}</TableCell>
        <TableCell>{formateDateTime(abTestRow.endTime, 'endTime')}</TableCell>
        <TableCell>{formateDateTime(abTestRow.forcedEndTime, 'actualEnd')}</TableCell>
        <TableCell>{abTestRow.tabName}</TableCell>
        <TableCell>{abTestRow.categoryName}</TableCell>
        <TableCell>{abTestRow.platformVersion}</TableCell>
        <TableCell>{abTestRow.status}</TableCell>
        <TableCell>
          {' '}
          <div className="btn_set_wrap segment_wrap">
            <React.Fragment>
              <Button color="secondary" variant="contained" className={'btn_color2'} onClick={() => onClickServiceButton(abTestRow)}>
                Service
              </Button>
            </React.Fragment>
          </div>
        </TableCell>
      </TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={15}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <div className="tab-container-box">
              <Table style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#fff' }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 100 }} />
                    <TableCell style={{ width: 160 }}>Sub Id</TableCell>
                    <TableCell style={{ width: 160 }}>Validity</TableCell>
                    <TableCell style={{ width: 170 }}>Segment Type</TableCell>
                    <TableCell style={{ width: 170 }}>Segment Id</TableCell>
                    <TableCell style={{ width: 220 }}>action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {abTestRow.segmentList.length > 0 ? (
                    abTestRow.segmentList.map(segmentSubRow => (
                      <SubTableBody
                        key={segmentSubRow.idx}
                        segmentSubRow={segmentSubRow}
                        abTestRow={abTestRow}
                        setOpen={setOpen}
                        history={history}
                        ableToDelete={checkForeligebleForEdit(abTestRow, utcHour)}
                        formatDate={formatDate}
                        displayMode={displayMode}
                        abTestTitle={abTestTitle}
                        startDate={startDate}
                        endDate={endDate}
                        tabCode={tabCode}
                        categoryCode={categoryCode}
                        deviceType={deviceType}
                        region={region}
                        platformVersion={platformVersion}
                        status={status}
                        utcHour={utcHour}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10} style={{ paddingLeft: '36%' }}>
                        No Data Found !
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </Box>
        </Collapse>
      </TableCell>
      <Dialog className={'pop_wrap'} open={openAddService}>
        <AddANserviceDialog handleTabClose={() => setOpenAddService(false)} abTestRow={abTestRow} setEndANService={setEndANService} />
      </Dialog>
    </React.Fragment>
  );
}

function SubTableBody({ segmentSubRow, abTestRow, setOpen, history, ableToDelete, formatDate, displayMode, abTestTitle, startDate, endDate, tabCode, categoryCode, deviceType, region, platformVersion, status, utcHour }) {
  function segMentTypeCheck(segmentType) {
    switch (segmentType) {
      case 'CMP':
        return 'AdTS Segment';
      case 'UPS':
        return 'LG Segment';
      case 'PER':
        return 'Personalize';
      default:
        return segmentType;
    }
  }
  const [openService, setOpenService] = React.useState(false);
  const [openPopService, setOpenPopService] = useState(false);
  const handleServiceLinkClick = () => {
    setOpenPopService(true);
  };
  const handleTabServiceClose = () => {
    setOpenPopService(false);
  };
  const expandSegment = segmentSubRow => {
    if (openService) {
      setOpenService(false);
    } else if (segmentSubRow.serviceList.length > 0) {
      setOpenService(!openService);
    } else {
      let testing = [];
      let ready = [];
      let done = [];
      getServiceDetails(segmentSubRow.abtestId, segmentSubRow.abtestSubid, abTestTitle, startDate, endDate, tabCode, categoryCode, deviceType, region, platformVersion, status).then(response => {
        response.map(data => {
          if (new Date(data.forcedEndTimeLocal) <= new Date(formatDate(new Date()))) {
            data.status = 'Done';
            done.push(data);
          } else if (new Date(formatDate(new Date())) < new Date(data.startTimeLocal)) {
            data.status = 'Ready';
            ready.push(data);
          } else {
            data.status = 'Testing';
            testing.push(data);
          }
        });
        [...testing, ...ready, ...done].map(data => segmentSubRow.serviceList.push(data));
        setOpenService(!openService);
      });
    }
  };

  return (
    <React.Fragment>
      <TableRow key={segmentSubRow.idx} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          {displayMode === 'All' && (
            <IconButton aria-label="expand row" size="small" onClick={() => expandSegment(segmentSubRow)}>
              {openService ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{segmentSubRow.abtestSubid}</TableCell>
        <TableCell>{segmentSubRow.validity && <CircleIcon htmlColor={segmentSubRow.validity === 'valid' ? 'green' : 'gray'} />}</TableCell>
        <TableCell>{segMentTypeCheck(segmentSubRow.segmentType)}</TableCell>
        <TableCell>{segmentSubRow.segmentId}</TableCell>
        <TableCell>
          {' '}
          <div className="btn_set_wrap segment_wrap">
            <React.Fragment>
              <Button color="secondary" variant="contained" className={'btn_color2'} onClick={() => history.push('/RECOMMEND/anservice/organize/abtest/' + abTestRow.idx + '/segment/' + segmentSubRow.idx)}>
                Organize
              </Button>
              {/* <EnhancedTable /> */}
              <Button style={{ background: '#dc004e', border: '1px solid #dc004e', color: '#ffffff' }} onClick={() => history.push(`/RECOMMEND/anservice/abtestresult/` + abTestRow.idx + '/segment/' + segmentSubRow.idx)}>
                Result
              </Button>
              {displayMode !== 'AN Test' && (
                <Button style={{ background: '#dc004e', border: '1px solid #dc004e', color: '#ffffff' }} onClick={() => history.push(`/RECOMMEND/anservice/addantestservice/` + abTestRow.idx + '/segment/' + segmentSubRow.idx)}>
                  Service
                </Button>
              )}
            </React.Fragment>
          </div>
        </TableCell>
      </TableRow>
      {displayMode !== 'Test' && (
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={15}>
          <Collapse in={openService} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table style={{ padding: '15px 40px 15px 20px', borderRight: '1px solid #e0e0e0', whiteSpace: 'pre' }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 160 }}>Title</TableCell>
                    <TableCell style={{ width: 170 }}>Country</TableCell>
                    <TableCell style={{ width: 170 }}>Start</TableCell>
                    <TableCell style={{ width: 220 }}>Initial end</TableCell>
                    <TableCell style={{ width: 220 }}>Actual end</TableCell>
                    <TableCell style={{ width: 220 }}>Tab Details</TableCell>
                    <TableCell style={{ width: 220 }}>Category Details</TableCell>
                    <TableCell style={{ width: 220 }}>Platform Details</TableCell>
                    <TableCell style={{ width: 220 }}>Status</TableCell>
                    <TableCell style={{ width: 220 }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {segmentSubRow.serviceList.length > 0 ? (
                    segmentSubRow.serviceList.map(serviceRow => (
                      <ANTTestServiceTable key={'_' + Math.random().toString(36).substr(2, 9)} serviceRow={serviceRow} setOpenService={setOpenService} abTestRow={abTestRow} history={history} segmentSubRow={segmentSubRow} formatDate={formatDate} utcHour={utcHour} />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10} style={{ paddingLeft: '36%', backgroundColor: 'rgb(255, 255, 255)' }}>
                        No Data Found !
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      )}
    </React.Fragment>
  );
}

function ANTTestServiceTable({ serviceRow, setOpenService, abTestRow, history, segmentSubRow, formatDate, setEndANService, utcHour }) {
  const [open, setOpen] = React.useState(false);
  const [tbOpen, setTbOpen] = React.useState(false);

  const handleServiceTextTabClose = () => {
    setTbOpen(false);
  };

  const handleEndANService = async serviceRow => {
    if (window.confirm('Are you sure to end the service?')) {
      await request(`/api/anservice/${serviceRow.serviceId}/end`, 'PATCH', null, true);
      if (setEndANService) {
        setEndANService(true);
      } else {
        segmentSubRow.serviceList = [];
        setOpenService(false);
      }
      return true;
    } else {
      return false;
    }
  };

  const handleDeleteANService = async serviceRow => {
    if (window.confirm('Are you sure to delete the service?')) {
      await request(`/api/anservice/${serviceRow.serviceId}/delete`, 'DELETE', null, true);
      if (setEndANService) {
        setEndANService(true);
      } else {
        segmentSubRow.serviceList = [];
        setOpenService(false);
      }
      return true;
    } else {
      return false;
    }
  };

  const expandSegment = serviceRow => {
    if (open) {
      setOpen(false);
    } else if (serviceRow.serviceSegmentList && serviceRow.serviceSegmentList.length > 0) {
      setOpen(!open);
    } else {
      getServiceSegmentDetails(serviceRow.recmdSegmentId).then(response => {
        serviceRow.serviceSegmentList = serviceRow.serviceSegmentList || []; // Ensure serviceSegmentList exists
        response.forEach(data => {
          data.serviceSegmentList = []; // Initialize an empty array for serviceSegmentList
          serviceRow.serviceSegmentList.push(data);
        });
        setOpen(!open);
      });
    }
  };

  function checkForEligebleForEditThroughDeploymentDate(ab) {
    var todayDateUTC = new Date(formatDate(new Date()));
    var startTimeCheck = new Date(ab.startTime);
    if (todayDateUTC.getHours() < utcHour) {
      todayDateUTC.setHours(utcHour);
      todayDateUTC.setMinutes(0);
      todayDateUTC.setSeconds(0);
    } else {
      todayDateUTC.setDate(todayDateUTC.getDate() + 1);
      todayDateUTC.setHours(utcHour);
      todayDateUTC.setMinutes(0);
      todayDateUTC.setSeconds(0);
    }
    return todayDateUTC > startTimeCheck;
  }

  function checkForeligebleForEdit(ab, utcHour) {
    var startTime = new Date(ab.startTime);
    startTime.setDate(startTime.getDate() - 1);
    startTime.setHours(utcHour);
    var todayDateUTC = new Date(formatDate(new Date()));
    if (new Date(ab.forcedEndTime) <= new Date(formatDate(new Date()))) {
      return true;
    } else if (new Date(formatDate(new Date())) < new Date(ab.startTime)) {
      return false || todayDateUTC > startTime;
    } else {
      return true;
    }
  }

  function formateDateTime(dateTime, type) {
    if (type === 'startTime' || type === 'endTime') {
      return dateTime.slice(0, -3)
    }
    return (dateTime.split(':')[2] === '00') ? dateTime.slice(0, -3) : dateTime;
  }


  return (
    <React.Fragment>
      <TableRow key={serviceRow.serviceId} sx={{ '& > *': { borderBottom: 'unset' } }} style={{ backgroundColor: '#EDF2FF' }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => expandSegment(serviceRow)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Link
            className="link"
            onClick={() => {
              setTbOpen(true);
            }}>
            {serviceRow.title}
          </Link>
          <Dialog
            className={'pop_wrap'}
            open={tbOpen}
            onClose={() => {
              setTbOpen(false);
            }}>
            <EditANServiceDialog serviceId={serviceRow.serviceId} recrecmdSegmentIdSerID={serviceRow.recmdSegmentId} handleTabClose={handleServiceTextTabClose} setEndANService={setEndANService} />
          </Dialog>
        </TableCell>
        <TableCell>{serviceRow.countryCode}</TableCell>
        <TableCell>{formateDateTime(serviceRow.startTimeLocal, 'startTime')}</TableCell>
        <TableCell>{formateDateTime(serviceRow.endTimeLocal, 'endTime')}</TableCell>
        <TableCell>{formateDateTime(serviceRow.forcedEndTimeLocal, 'actualEnd')}</TableCell>
        <TableCell>{serviceRow.tabCode}</TableCell>
        <TableCell>{serviceRow.categoryCode}</TableCell>
        <TableCell>{serviceRow.platformVersion}</TableCell>
        <TableCell>{serviceRow.status}</TableCell>
        <TableCell>
          <div className="btn_set_wrap" style={{ textAlign: 'left' }}>
            <Button disabled={new Date(formatDate(new Date())) >= new Date(serviceRow.forcedEndTimeLocal)} variant="contained" className={'btn_color2'} onClick={() => handleEndANService(serviceRow)}>
              End
            </Button>
            <Button disabled={serviceRow.status != 'Done'} variant="contained" className={'btn_color2'} onClick={() => handleDeleteANService(serviceRow)}>
              Delete
            </Button>
            <Button disabled={checkForeligebleForEdit(serviceRow, utcHour) || checkForEligebleForEditThroughDeploymentDate(serviceRow)} color="secondary" variant="contained" className={'btn_color2'} onClick={() => history.push(`/RECOMMEND/anservice/addsegment/` + serviceRow.serviceId)}>
              Segment
            </Button>
          </div>
        </TableCell>
      </TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={15}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <div className="tab-container-box">
              <Table style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#fff' }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 160 }}>Sub Id</TableCell>
                    <TableCell style={{ width: 170 }}>Segment Type</TableCell>
                    <TableCell style={{ width: 170 }}>Segment Id</TableCell>
                    <TableCell style={{ width: 220 }}>action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceRow.serviceSegmentList && serviceRow.serviceSegmentList.length > 0 ? (
                    serviceRow.serviceSegmentList.map(segmentSubRow => (
                      <ServiceSegmentTableBody key={'_' + Math.random().toString(36).substr(2, 9)} segmentSubRow={segmentSubRow} serviceRow={serviceRow} setOpen={setOpen} history={history} ableToDelete={checkForeligebleForEdit(serviceRow, utcHour)} />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10} style={{ paddingLeft: '36%' }}>
                        No Data Found !
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </Box>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
}

function ServiceSegmentTableBody({ segmentSubRow, serviceRow, setOpen, history, ableToDelete }) {
  function segMentTypeCheck(segmentType) {
    switch (segmentType) {
      case 'CMP':
        return 'AdTS Segment';
      case 'UPS':
        return 'LG Segment';
      case 'PER':
        return 'Personalize';
      default:
        return segmentType;
    }
  }

  return (
    <React.Fragment>
      <TableRow key={segmentSubRow.idx}>
        <TableCell>{segmentSubRow.recmdSegmentSubid}</TableCell>
        <TableCell>{segMentTypeCheck(segmentSubRow.segmentType)}</TableCell>
        <TableCell>{segmentSubRow.segmentId}</TableCell>
        <TableCell>
          {' '}
          <div className="btn_set_wrap segment_wrap">
            <React.Fragment>
              <Button color="secondary" variant="contained" className={'btn_color2'} onClick={() => history.push('/RECOMMEND/anservice/organize/service/' + serviceRow.serviceId + '/segment/' + segmentSubRow.idx)}>
                Organize
              </Button>
              <ANTDeleteSegment segmentSubRow={segmentSubRow} abTestRow={serviceRow} setOpen={setOpen} ableToDelete={ableToDelete} />
              {/* <EnhancedTable /> */}
              <Button style={{ background: '#dc004e', border: '1px solid #dc004e', color: '#ffffff' }} onClick={() => history.push(`/RECOMMEND/anservice/result/` + serviceRow.serviceId + '/segment/' + segmentSubRow.idx)}>
                Result
              </Button>
            </React.Fragment>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: '20px !important',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 50
  },
}));

ABTest.propTypes = {
  getABTestTabCategoryAction: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  regionList: PropTypes.array.isRequired,
  regionPlatformList: PropTypes.array.isRequired,
  abTestParam: PropTypes.object.isRequired,
  getNotifications: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  updateNotification: PropTypes.func.isRequired,
  publishNotification: PropTypes.func.isRequired,
  getSupportLanguages: PropTypes.func.isRequired,
  searchNotification: PropTypes.func.isRequired,
  abTestTabCategory: PropTypes.array.isRequired,
  abTestDetails: PropTypes.array.isRequired,
  endAbTest: PropTypes.func.isRequired,
  anServiceDetails: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  notifications: state.homeApp.notifications,
  regionList: state.homeApp.regionList, //filter country
  regionPlatformList: state.homeApp.regionPlatformList, //filter platform
  abTestParam: state.homeApp.abTestParam,
  abTestTabCategory: state.homeApp.abTestTabCategory, // homeapp info
  abTestDetails: state.homeApp.abTestDetails, //grid list
  anServiceDetails: state.homeApp.anServiceDetails,
});

const mapDispatchToProps = dispatch => ({
  getABTestTabCategoryAction(deviceType, region, platformVersion) {
    dispatch(getABTestTabCategoryAction(deviceType, region, platformVersion));
  },
  setABTestParamAction(deviceType, countryCode, region, platformVersion, tabCode, categoryCode, status) {
    dispatch(setABTestParamAction(deviceType, countryCode, region, platformVersion, tabCode, categoryCode, status));
  },
  getNotifications: () => dispatch(getNotificationsAction()),
  addNotification: data => dispatch(addNotificationAction(data)),
  updateNotification: data => dispatch(updateNotificationAction(data)),
  publishNotification: data => dispatch(publishNotificationsAction(data)),
  getSupportLanguages: () => dispatch(getSupportLanguagesAction()),
  searchNotification: data => dispatch(searchNotificationsAction(data)),
  endAbTest: id => dispatch(endAbTest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ABTest));
