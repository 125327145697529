import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import CpMemberForm from './CpMemberForm';
import { validateProviderId } from '../cpCode/CPCodeAdd';
import { validatePw } from '../ChangePw';
import { getMembersAction } from '../../../actions/userActions';
import { request } from '../../../util/request';

function CpMemberAdd({ isRoleAdmin, getMembersAction }) {
  const [filter, setFilter] = useReducer((state, newState) => ({ ...state, ...newState }), {
    providerId: {
      value: '',
      errorMsg: 'CP Code must be already added. (capitalized 3-5 characters)',
    },
    username: {
      value: '',
    },
    password: {
      value: '',
      errorMsg: 'At least 6 characters', //Enter a combination of at least 6 numbers, letters and punctuation marks(like ! and &)
    },
    passwordReEnter: {
      value: '',
    },
  });

  const [open, setOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const handleInputChange = e => {
    const inputId = e.target.name; //
    setFilter({
      [inputId]: {
        value: e.target.value,
        errorMsg: filter[inputId].errorMsg,
      },
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (validateProviderId(filter.providerId.value) && validatePw(filter.password.value, filter.passwordReEnter.value)) {
      const newItem = {
        providerId: filter.providerId.value,
        username: filter.username.value,
        name: filter.username.value,
        password: filter.password.value,
        roles: [
          { id: 3, name: 'ROLE_CP' },
          { id: 5, name: 'ROLE_USER' }, //없으면 403Error
        ],
      };
      alert('Are you sure to add new CP Member?');
      const isSuccess = await request('/api/admin/users', 'POST', newItem);
      if (isSuccess) {
        setSuccessSnackbarOpen(true);
        getMembersAction(isRoleAdmin);
        setOpen(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={() => setOpen(true)} className={'btn_color2'}>
        Add
      </Button>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={1000} //1000=1sec
        onClose={() => setSuccessSnackbarOpen(false)} //없으면 없어지지않음
        message="Successfully Doned !"
        style={{ backgroundColor: 'black' }}
      />
      <Dialog open={open} className={'pop_wrap'}>
        <CpMemberForm title="Add CP Member" isIdVisible={true} isAccessVisible={false} isPasswordVisible={true} filter={filter} handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={() => setOpen(false)} />
      </Dialog>
    </React.Fragment>
  );
}

CpMemberAdd.propTypes = {
  getMembersAction: PropTypes.func.isRequired,
  isRoleAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isRoleAdmin: state.currentUser.isRoleAdmin,
});
const mapDispatchToProps = dispatch => ({
  getMembersAction(isRoleAdmin) {
    dispatch(getMembersAction(isRoleAdmin));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CpMemberAdd);
// id: 2
// name: "rakuten"
// username: "rakuten"
// email: "rakuten@rakuten.com"
// providerId: "RAKUTEN"
// createdAt: "2020-01-17T04:49:10Z"
// roles: [{id: 5, name: "ROLE_USER"}, {id: 3, name: "ROLE_CP"}]
// useFlag: null
