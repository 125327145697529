import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { getChannelMapListByCountryCode } from '../../../actions/notificationActions';

const deviceTypeList = [
  { label: 'PC', value: 'pc' },
  { label: 'TV', value: 'tv' },
  { label: 'Mobile', value: 'mobile' },
];

const mobilePlatformList = [
  { label: 'Android', value: 'Android' },
  { label: 'IOS', value: 'IOS' }
];

const pcPlatformList = [
  { label: 'PC', value: 'pc' },
];

function NotificationFilter({regionCountries, searchData, handleSearchDataChange, getChannelMapList, channelMapList, requiredField }) {
  useEffect(() => {
    getChannelMapList(searchData.countryCodes);
  }, [searchData.countryCodes]);

  return (
    <Fragment>
      <div className="filter_area row">
        {/* <TextField fullWidth margin="dense" variant="outlined" label="Title" value={title} onChange={handleChangeTitle} /> */}
        <div className="filter_row">
          <FormControl required variant="outlined" error={requiredField && searchData.deviceType == ''}>
            <InputLabel>Device Type</InputLabel>
            <Select name="deviceType" id="search_deviceType" value={searchData.deviceType} onChange={e => handleSearchDataChange(e)}>
              {deviceTypeList.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            {requiredField && searchData.deviceType == '' && <FormHelperText>Device Type is required!</FormHelperText>}
          </FormControl>

          <FormControl required variant="outlined" error={requiredField && searchData.countryCodes.length === 0}>
            <InputLabel>Country</InputLabel>
            <Select name="countryCodes" id="search_countryCodes" value={searchData.countryCodes} onChange={e => handleSearchDataChange(e)} multiple>
              {regionCountries.filter(n =>n.deviceType === searchData.deviceType).map(item => (
                        <MenuItem key={item.countryCode} value={item.countryCode}>
                          {item.countryCode}
                        </MenuItem>
                      ))}
            </Select>
            {requiredField && searchData.countryCodes.length === 0 && <FormHelperText>Country is required!</FormHelperText>}
          </FormControl>
        </div>
        <div className="filter_row">
        {(searchData.deviceType  === 'tv'  || searchData.deviceType == "") && (
          <FormControl required variant="outlined" error={requiredField && searchData.channelMapList.length === 0}>
            <InputLabel>Channel Map</InputLabel>
            <Select name="channelMapList" id="search_channelMapList" value={searchData.channelMapList} onChange={e => handleSearchDataChange(e)} multiple>
              {channelMapList.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            {requiredField && searchData.channelMapList.length === 0 && <FormHelperText>Channel Map is required!</FormHelperText>}
          </FormControl>
        )}
        {(searchData.deviceType  === 'mobile' ) && (
          <FormControl required variant="outlined" error={requiredField && searchData.channelMapList.length === 0}>
            <InputLabel>Platform</InputLabel>
            <Select name="channelMapList" id="search_channelMapList" value={searchData.channelMapList} onChange={e => handleSearchDataChange(e)} multiple>
              {mobilePlatformList.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
              ))}
            </Select>
            {requiredField && searchData.channelMapList.length === 0 && <FormHelperText>Channel Map is required!</FormHelperText>}
          </FormControl>
        )}
        {(searchData.deviceType  === 'pc' ) && (
          <FormControl required variant="outlined" error={requiredField && searchData.channelMapList.length === 0}>
            <InputLabel>Platform</InputLabel>
            <Select name="channelMapList" id="search_channelMapList" value={searchData.channelMapList} onChange={e => handleSearchDataChange(e)} multiple>
              {pcPlatformList.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
              ))}
            </Select>
            {requiredField && searchData.channelMapList.length === 0 && <FormHelperText>Channel Map is required!</FormHelperText>}
          </FormControl>
        )}
          <TextField
            variant="outlined"
            name="date"
            id="date"
            label="Date"
            type="date"
            value={searchData.date}
            onChange={e => handleSearchDataChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
    </Fragment>
  );
}
NotificationFilter.propTypes = {
  regionCountries: PropTypes.array.isRequired,
  searchData: PropTypes.object.isRequired,
  handleSearchDataChange: PropTypes.func.isRequired,
  getChannelMapList: PropTypes.func.isRequired,
  channelMapList: PropTypes.array.isRequired,
  requiredField: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  regionCountries: state.cntry.regionCountries,
  channelMapList: state.homeApp.notificationsChannelMapList,
});

const mapDispatchToProps = dispatch => ({
  getChannelMapList: countryCodes => dispatch(getChannelMapListByCountryCode(countryCodes)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationFilter);
