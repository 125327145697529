import React, { useState, useEffect } from 'react';
import AlertDialog from '../../../common/dialog/AlertDialog';
import { TableContainer, Table, TableHead, TableRow, TableCell, TextField } from '@material-ui/core';
import MultiLangEdit from './MultiLangEdit';

const EditCategoryName = (props) => {
  const { isDisplayEditCategoryName
        , selectedCategoryName
        , langList
        , multiLang
        , callbackChangedDefaultCategoryName
        , callbackChangedCategoryNameByLang
      } = props;


  const [isAlert, setIsAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [displayWarningList, setDisplayWarningList] = useState([]);
  const [editDefaultCategoryName, setEditDefaultCategoryName] = useState('');
  const [editLangList, setEditLangList] = React.useState([{ langCode: '', name: '' }]);

  useEffect(() => {
    if (isDisplayEditCategoryName) {
      let initLangList = [{ langCode: '', name: '' }];

      if (multiLang && multiLang.length > 0) {
        initLangList = multiLang.map(item => {
          return {
            langCode: item.langCode,
            name: item.relatedContentCategoryName
          }
        });
      }

      setEditLangList([...initLangList]);
      setEditDefaultCategoryName(selectedCategoryName.relatedContentCategoryName);
    }
  }, [isDisplayEditCategoryName]);

  useEffect(() => {
    callbackChangedDefaultCategoryName(editDefaultCategoryName);
  }, [editDefaultCategoryName]);

  useEffect(() => {
    callbackChangedCategoryNameByLang(editLangList);
  }, [editLangList]);

  const setEditLang = (i, type, value) => {
    if (type === 'langCode') {
      const isLangCodeDuplicated = editLangList.find(item => item.langCode === value);

      if (isLangCodeDuplicated) {
        setAlertDescription(`${value} is already selected, so select other language`);
        setIsAlert(true);
        return;
      }
    }

    let newData = [...editLangList];
    newData[i][type] = value;
    setEditLangList(newData);
  };

  return (
    <React.Fragment>
      {
        isDisplayEditCategoryName ? (
        <div>
          <TableContainer className={'tbl_wrap'}>
            <Table className={'tbl_row_typ1 tbl_notification'}>
              <colgroup>
                <col style={{ width: '5%' }} />
                <col style={{ width: '95%' }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <td colSpan="3">
                    <div>
                      <TextField
                        style={{ width: '50%' }}
                        variant="outlined"
                        value={editDefaultCategoryName}
                        onChange={e => {
                          setEditDefaultCategoryName(e.target.value);
                        }}
                      />
                    </div>
                  </td>
                </TableRow>
                <TableRow className='edit_multi_lang_row_layout'>
                  <TableCell>Multilingual Name</TableCell>
                  <td colSpan="3" >
                    <MultiLangEdit editLangList={editLangList} setEditLangList={setEditLangList} setEditLang={setEditLang} allLangList={langList} />
                  </td>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </div>
        ) : (
          <></>
        )
      }


      <AlertDialog
        openAlert={isAlert}
        description={alertDescription}
        warningList={displayWarningList}
        closeHandler={() => { setIsAlert(false); }}
      />
    </React.Fragment>
  );
}

export default EditCategoryName;