import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { getProvidersAdminAction } from '../../actions/providerActions';

export function CpMemberFilter({ providerIds, getProvidersAdminAction, cp, handleChangeCp, id, handleInputChangeId }) {
  React.useEffect(() => {
    if (providerIds.length === 0) getProvidersAdminAction();
  }, []);

  return (
    <>
      <div className="filter_area">
        {cp && (
          <FormControl variant="outlined">
            <InputLabel>CP Code</InputLabel>
            <Select value={cp} onChange={handleChangeCp}>
              {
                providerIds.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))
                /* <MenuItem value="All">All</MenuItem>
            <MenuItem value="SUPER_ADMIN">SUPER_ADMIN</MenuItem>
            <MenuItem value="ADMIN">ADMIN</MenuItem> */
              }
            </Select>
          </FormControl>
        )}

        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="ID" //focus안줬을때 mandatory text
          value={id}
          onChange={e => handleInputChangeId(e)}
        />
      </div>
    </>
  );
}

CpMemberFilter.propTypes = {
  providerIds: PropTypes.array.isRequired,
  getProvidersAdminAction: PropTypes.func.isRequired,
  cp: PropTypes.string.isRequired,
  handleChangeCp: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  handleInputChangeId: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  providerIds: state.providers.providerIds,
});
const mapDispatchToProps = dispatch => ({
  getProvidersAdminAction() {
    dispatch(getProvidersAdminAction());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CpMemberFilter);
