import { ROWHEADER_TYPE } from '../../consts/IssConsts';

export const dashboard_contents_tabledata = {
  cp_issues: {
    title: 'CP',
    visibleTitle: true,
    existTooltip: true,
    tooltip: 'Detected issues when there is missing or mismatched data in CP metadata.',
    header: ['Date', 'Country', 'CP', 'API Name', 'Issue Contents'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.COUNTRY_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['20%', '10%', '20%', '25%', '25%'],
    rowdata: [],
  },
  cmp_organization: {
    title: 'CMP Organization',
    visibleTitle: true,
    existTooltip: true,
    tooltip: 'Detected issues related to managing (scheduling) of channels and home apps.',
    header: ['Date', 'Country', 'Issue classification', 'Issue Contents'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['15%', '10%', '30%', '45%'],
    rowdata: [],
  },
  data_deployment: {
    title: 'Data Deployment',
    visibleTitle: true,
    existTooltip: true,
    tooltip: 'Detected issues related to managing (distribution) of channels and home apps.',
    header: ['Date', 'Country', 'Issue classification', 'Issue Contents'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['15%', '10%', '30%', '45%'],
    rowdata: [],
  },
};
