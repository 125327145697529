export const TABLE_TYPE = {
  CHECKBOX_SELECTION: 'CHECKBOX_SELECTION',
  NORMAL: 'NORMAL',
};

export const ROWHEADER_TYPE = {
  NORMAL: 0,
  FIRST: 1,
  LONG: 2,
  DROPDOWN: 3,
  CHECKBOX: 4,
  JIRALINK: 5,
  PLATFORM_DROPDOWN: 6,
  REGION_DROPDOWN: 7,
  DOWNLOAD_URL: 8,
  EXPAND: 9,
  BLANK_URL_LINK: 10,
  ASSIGN_DROPDOWN: 11,
  DYNAMIC_DROPDOWN: 12,
  BLANK_LONG_URL_LINK: 13,
  EXCEL_DOWNLOAD_BUTTON: 14,
  COUNTRY_DROPDOWN: 15,
  BLANK_COLUMN: 16,
  DROPDOWN_FOR_DISPLAY: 17,
  EDITABLE: 18,
};
