import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../common/Loading';
import { CHART_COLORS } from '../../common/ChartConsts';
import LineChart from '../../common/LineChart';
import BarChart from '../../common/BarChart';
import Alert from '../../modal/Alert';
import ServiceDetailTable from '../../common/ServiceDetailTable';
import ServiceIssueGroupTable from '../../common/ServiceIssueGroupTable';
import annotationPlugin from 'chartjs-plugin-annotation';
import { service_history_chartData, service_issue_countData, service_issue_resolvedRateData, service_issue_accResolvedRateData } from './IssueHistoryServiceDataset.js';
import { ServiceIssueGroupDataSet } from '../../DataSet/Service/ServiceIssueGroupDataSet';
import { ISS_BASE_URL } from '../../../../constants';
import { getHeaders } from '../../../../util/actionUtil';

function IssueHistoryServiceCommon ({ pageCurrentTab, currentFilter, chartType }) {

  const [isSearchDisplay, setSearchDisplay] = useState(false);
  const [focusItem, setFocusItem] = useState('');
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const history = useHistory();
  const [dataError, setDataError] = useState([]);
  const [issueHistoryList, setIssueHistoryList] = useState(ServiceIssueGroupDataSet.appHistoryData);
  const [issueTableType, setIssueTableType] = useState('app');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const plugin = [annotationPlugin];

  useEffect(() => {
    if (currentFilter && currentFilter.date.length > 0 && pageCurrentTab !== 0) {
      requestData();
    }
  }, [currentFilter]);

  const requestData = () => {
    let changeFilter = { date:'', country:'', provider:'', platform:'', source: '', state: '', assign: '', contentsType: '', searchTextType: '', searchText: '' };
    changeFilter = currentFilter;
    if (pageCurrentTab === 1) {
      getServiceHistoryInfo('app', changeFilter);
    } else if (pageCurrentTab === 2) {
      getServiceHistoryInfo('play', changeFilter);
    } else if (pageCurrentTab === 3) {
      getServiceHistoryInfo('schedule', changeFilter);
    }
  };

  const getServiceHistoryInfo = (type, filter) => {
    setSearchDisplay(false);
    fetch(`${ISS_BASE_URL}/api/service/history/${type}?dates=${filter.date}&countries=${filter.country}&platforms=${filter.platform}&cps=${filter.provider}&issueRoot=${filter.source}&issueState=${filter.state}&assignGroup=${filter.assign}&contentsType=${filter.contentsType}&searchTextType=${filter.searchTextType}&searchText=${filter.searchText}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          let graphColor = CHART_COLORS.PINK;
          let labelName = '';
          if (type === 'app') {
            graphColor = CHART_COLORS.BLUE;
            labelName = 'App';
            let table = body.tableDataSet.map((n) => {
              const {
                issueId, issueDate, countryCode
                , providerId, issueSubType, contentId, contentName
                , issueState, assignGroup, jiraUrl, actionDescription
                , detailList, lastChgUsrId
              } = n;
              if (detailList.length > 1){
                let subData = detailList.map((sub) => {
                  return {
                    row: [
                      sub.issueCodeLv
                      , sub.detailIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), sub.issueRoot, sub.platformList, providerId, contentId
                      , contentName, sub.shortMsg, '', '', ''
                      , '', ''
                    ]
                    , issueId : issueId
                    , rowId : issueId.concat('_', 'SUB')
                    , issueCountry : countryCode
                    , issueCode : sub.issueCode
                    , issueType : type
                    , contentId : contentId
                    , isFocus : false
                    , issueRoot : sub.issueRoot
                    , rowType : 'SUB'
                    , issueSubType : issueSubType
                    , issueCount : sub.detailIssueCount
                  };
                });
                return {
                  row: [
                    issueId, issueDate, countryCode, issueSubType
                    , [...new Set(detailList.map(m => m.issueCodeLv))]
                    , detailList[0].detailIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').concat(' (', detailList.map(m => m.detailIssueCount).reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), ')'), [...new Set(detailList.map(m => m.issueRoot))], [...new Set(detailList.map(m => m.platformList).reduce(function (acc, cur) {return acc.concat(cur);}).sort((a, b) => a.localeCompare(b)))], providerId, contentId
                    , contentName, detailList[0].shortMsg, issueState, assignGroup, lastChgUsrId
                    , jiraUrl, actionDescription
                  ]
                  , issueId : issueId
                  , rowId : issueId.concat('_', countryCode)
                  , issueCountry : countryCode
                  , issueCode : detailList[0].issueCode
                  , issueType : type
                  , contentId : contentId
                  , isFocus : false
                  , issueRoot : detailList[0].issueRoot
                  , rowType : 'MAIN'
                  , issueSubType : issueSubType
                  , issueCount : detailList[0].detailIssueCount
                  , subTable: {
                    colspanCount: 4
                    , rowdata: subData
                  }
                };
              } else {
                return { row: [
                    issueId, issueDate, countryCode, issueSubType
                    , [detailList[0].issueCodeLv]
                    , detailList[0].detailIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), [detailList[0].issueRoot], detailList[0].platformList, providerId, contentId
                    , contentName, detailList[0].shortMsg, issueState, assignGroup, lastChgUsrId
                    , jiraUrl, actionDescription
                  ]
                  , issueId : issueId
                  , rowId : issueId.concat('_', countryCode)
                  , issueCountry : countryCode
                  , issueCode : detailList[0].issueCode
                  , issueType : type
                  , contentId : contentId
                  , isFocus : false
                  , issueRoot : detailList[0].issueRoot
                  , rowType : 'EACH'
                  , issueSubType : issueSubType
                  , issueCount : detailList[0].detailIssueCount
                };
              }
            });
            setIssueHistoryList({ ...ServiceIssueGroupDataSet.appHistoryData, ...{ rowdata : table } });
          } else if (type === 'play') {
            graphColor = CHART_COLORS.PURPLE;
            labelName = 'Playback';
            let table = body.tableDataSet.map((n) => {
              const {
                issueId, issueDate, countryCode, issueCount
                , providerId, issueSubType, contentId, contentName
                , issueState, assignGroup, jiraUrl, actionDescription
                , detailList, lastChgUsrId, channelNumber
              } = n;
              if (detailList.length > 1){
                let subData = detailList.map((sub) => {
                  return {
                    row: [
                      sub.issueCodeLv
                      , sub.detailIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), sub.issueRoot, sub.platformList, providerId, channelNumber
                      , contentId, contentName, sub.shortMsg, '', ''
                      , '', '', ''
                    ]
                    , issueId : issueId
                    , rowId : issueId.concat('_', 'SUB')
                    , issueCountry : countryCode
                    , issueCode : sub.issueCode
                    , issueType : type
                    , contentId : contentId
                    , isFocus : false
                    , issueRoot : sub.issueRoot
                    , rowType : 'SUB'
                    , issueSubType : issueSubType
                    , issueCount : sub.detailIssueCount
                  };
                });
                return {
                  row: [
                    issueId, issueDate, countryCode, issueSubType
                    , [...new Set(detailList.map(m => m.issueCodeLv))]
                    , detailList[0].detailIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').concat(' (', detailList.map(m => m.detailIssueCount).reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), ')')
                    , [...new Set(detailList.map(m => m.issueRoot))], [...new Set(detailList.map(m => m.platformList).reduce(function (acc, cur) {return acc.concat(cur);}).sort((a, b) => a.localeCompare(b)))], providerId, channelNumber
                    , contentId, contentName, detailList[0].shortMsg, issueState
                    , assignGroup, lastChgUsrId, jiraUrl, actionDescription
                  ]
                  , issueId : issueId
                  , rowId : issueId.concat('_', countryCode)
                  , issueCountry : countryCode
                  , issueCode : detailList[0].issueCode
                  , issueType : type
                  , contentId : contentId
                  , isFocus : false
                  , issueRoot : detailList[0].issueRoot
                  , rowType : 'MAIN'
                  , issueSubType : issueSubType
                  , issueCount : issueCount
                  , subTable: {
                    colspanCount: 3
                    , rowdata: subData
                  }
                };
              } else {
                return { row: [
                    issueId, issueDate, countryCode, issueSubType
                    , [detailList[0].issueCodeLv]
                    , detailList[0].detailIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), [detailList[0].issueRoot], detailList[0].platformList, providerId, channelNumber
                    , contentId, contentName, detailList[0].shortMsg, issueState
                    , assignGroup, lastChgUsrId, jiraUrl, actionDescription
                  ]
                  , issueId : issueId
                  , rowId : issueId.concat('_', countryCode)
                  , issueCountry : countryCode
                  , issueCode : detailList[0].issueCode
                  , issueType : type
                  , contentId : contentId
                  , isFocus : false
                  , issueRoot : detailList[0].issueRoot
                  , rowType : 'EACH'
                  , issueSubType : issueSubType
                  , issueCount : issueCount
                };
              }
            });
            setIssueHistoryList({ ...ServiceIssueGroupDataSet.playHistoryData, ...{ rowdata : table } });
          } else if (type === 'schedule') {
            graphColor = CHART_COLORS.PINK;
            labelName = 'Schedule';
            let table = body.tableDataSet.map((n) => {
              const {
                issueId, issueDate, providerId, issueState, assignGroup
              , issueCodeLv, jiraUrl, actionDescription, lastChgUsrId, contentCount
              , countryCodes, platforms, issueCode
              } = n;
              return { row: [
                  issueId, issueDate, providerId, issueCodeLv, countryCodes.split(',')
                  , platforms.split(','), contentCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), issueState, assignGroup, lastChgUsrId
                  , jiraUrl, actionDescription
                ]
                , issueId : issueId
                , rowId : issueId.concat('_', providerId)
                , issueCode : issueCode
                , issueType : type
                , contentId : providerId
                , isFocus : false
                , rowType : 'EACH'
                , actionDescription : actionDescription
                , issueCount : contentCount
              };
            });
            setIssueHistoryList({ ...ServiceIssueGroupDataSet.scheduleHistoryData, ...{ rowdata : table } });
          }
          setIssueTableType(type);
          let graphDataSet = body.graphDataSet;
          let dateList = graphDataSet.map(n => n.date.substring(5, 10));
          service_issue_countData.data = graphDataSet.map(n => n.issueCount);
          service_issue_countData.backgroundColor = `${graphColor}`;
          service_issue_countData.borderColor = `${graphColor}`;
          service_issue_countData.pointBackgroundColor = `${graphColor}`;
          service_issue_countData.label = labelName;
          service_issue_resolvedRateData.data = graphDataSet.map(n => n.resolvedRate);
          service_issue_resolvedRateData.backgroundColor = `${graphColor}`;
          service_issue_resolvedRateData.borderColor = `${graphColor}`;
          service_issue_resolvedRateData.pointBackgroundColor = `${graphColor}`;
          service_issue_resolvedRateData.label = labelName;
          service_issue_accResolvedRateData.data = graphDataSet.map(n => n.cumulativeResolveRate);
          service_issue_accResolvedRateData.backgroundColor = `${graphColor}`;
          service_issue_accResolvedRateData.borderColor = `${graphColor}`;
          service_issue_accResolvedRateData.pointBackgroundColor = `${graphColor}`;
          service_issue_accResolvedRateData.label = labelName;
          service_history_chartData.daily_services_error_occurrence.data.labels = dateList;
          service_history_chartData.daily_services_error_occurrence.data.datasets = [service_issue_countData];
          service_history_chartData.daily_services_error_occurrence.options.scales.y.max = Math.max(...service_issue_countData.data);
          service_history_chartData.daily_services_error_accumulated.data.labels = dateList;
          service_history_chartData.daily_services_error_accumulated.data.datasets = [service_issue_resolvedRateData];
          service_history_chartData.daily_services_error_accumulated2.data.labels = dateList;
          service_history_chartData.daily_services_error_accumulated2.data.datasets = [service_issue_accResolvedRateData];
          setFocusItem('');
          setDataError(body.tableDataSet);
        } else {
          setAlertMessage('getServiceHistoryInfo error');
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage('getContentHistoryInfo error: ', error);
        onOpenAlert();
      })
      .finally(() => {
        setSearchDisplay(true);
      });
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
      {isSearchDisplay ? (
        <div className="grid-row">
          <div className="row">
            <div className="box-sub">
              <div className="head">
                <div className="title">
                  <h2>Number of issues (based on occurrence date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
              {
                chartType === 'Line' ? (
                  <LineChart id={'daily_services_error_occurrence'} data={service_history_chartData.daily_services_error_occurrence.data} options={service_history_chartData.daily_services_error_occurrence.options} plugins={plugin} />
                ) : (
                  <BarChart id={'daily_services_error_occurrence'} data={service_history_chartData.daily_services_error_occurrence.data} options={service_history_chartData.daily_services_error_occurrence.options} plugins={plugin} />
                )
              }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-sub">
              <div className="head">
                <div className="title">
                  <h2>Issue resolution rate (based on occurrence date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
              {
                chartType === 'Line' ? (
                  <LineChart id={'daily_services_error_accumulated'} data={service_history_chartData.daily_services_error_accumulated.data} options={service_history_chartData.daily_services_error_accumulated.options} plugins={plugin}/>
                ) : (
                  <BarChart id={'daily_services_error_accumulated'} data={service_history_chartData.daily_services_error_accumulated.data} options={service_history_chartData.daily_services_error_accumulated.options} plugins={plugin} />
                )
              }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-sub">
              <div className="head">
                <div className="title">
                  <h2>Issue resolution rate (based on cumulative daily date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
              {
                chartType === 'Line' ? (
                  <LineChart id={'daily_services_error_accumulated2'} data={service_history_chartData.daily_services_error_accumulated2.data} options={service_history_chartData.daily_services_error_accumulated2.options} plugins={plugin}/>
                ) : (
                  <BarChart id={'daily_services_error_accumulated2'} data={service_history_chartData.daily_services_error_accumulated2.data} options={service_history_chartData.daily_services_error_accumulated2.options} plugins={plugin} />
                )
              }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-tbl box-issueHistory">
              <ServiceIssueGroupTable
                height={'calc(100% - 76px)'}
                pagination={true}
                tableType={issueTableType}
                data={issueHistoryList}
                btnIconType={true}
                focusItem={focusItem}
                setFocusItem={setFocusItem}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                sortingItem={{ enable: true, sortColumn: -1, sortDirection: 'desc' }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )}
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  )
}

export default IssueHistoryServiceCommon;