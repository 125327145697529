import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function history(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FEEDS_HISTORY:
      return action.payload.feedHistList;
    default:
      return state;
  }
}
