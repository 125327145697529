import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../../common/LoadingIndicator';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { API_BASE_URL } from '../../constants';
import { getHeaders } from '../../util/actionUtil';
import MaterialTable from 'material-table';

export default function CategoryTableDialog({ row, dialogOpen, closeDialog }) {
  const [rowDetail, setRowDetail] = useState();
  const [fetching, setFetching] = useState();
  //console.log('[row]', row);
  //console.log('[rowDetail]', rowDetail);

  useEffect(() => {
    if (row) {
      setFetching(true);
      const vodimageUrl = `${API_BASE_URL}/vodImg?cp=${row.provider_idx}&id=${row.content_id}`;
      const vodSeasonimageUrl = `${API_BASE_URL}/vodSeasonImg?cp=${row.cp_id}&id=${row.content_id}`;
      fetch(`${row.type === 'tvshow-series' ? vodSeasonimageUrl : vodimageUrl}`, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then(res => res.json())
        .then(body => {
          //console.log('[then]', body);
          if (body.status) {
            alert('[server error] ' + body.message);
          } else {
            setRowDetail(body);
          }
          setFetching(false);
        })
        .catch(error => {
          setFetching(false);
          alert('[error message] ' + error);
        });
    }
  }, [row]);

  return (
    <>
      {fetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        <div>
          {rowDetail && (
            <Dialog open={dialogOpen} onClose={closeDialog} className={'pop_wrap'}>
              <div className="MuiDialogTitle-root pop_head" id="form-dialog-title">
                <h2 className="MuiTypography-root MuiTypography-h6">
                  Content Image Detail
                  <IconButton
                    aria-label="close" //없으면 X버튼 위치앞으로감
                    className={'noTit_close'}
                    onClick={closeDialog}>
                    <CloseIcon />
                  </IconButton>
                </h2>
              </div>

              {rowDetail.length > 0 ? (
                <MaterialTable
                  title=""
                  columns={[
                    { title: 'type', field: 'type' },
                    { title: 'size', field: 'size' },
                    { title: 'ratio', field: 'ratio' },
                    { title: 'image', render: n => <img alt="invalid" src={n.image} title={n.image} style={{ maxWidth: 100, maxHeight: 100, backgroundColor: 'black', border: '1px solid #cbccce' }} /> },
                  ]}
                  data={rowDetail}
                  options={{ paging: false }}
                />
              ) : (
                <center>
                  <br />
                  <h2>There is no image in this content !</h2>
                </center>
              )}

              <div className={'pop_body'}>
                <div className="btn_set_wrap">
                  <Button color="secondary" variant="contained" className={'btn_color2'} onClick={closeDialog}>
                    Close
                  </Button>
                </div>
              </div>
            </Dialog>
          )}
        </div>
      )}
    </>
  );
}

CategoryTableDialog.propTypes = {
  row: PropTypes.object,
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};
