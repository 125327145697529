/* eslint-disable */
import React, { useState, useEffect } from 'react'; // React
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions } from '@material-ui/core';
function ChannelMappingAlert({ alert }) {
  useEffect(() => {
    alert.open && openAlert(alert.message);
  }, [alert]);
  const [stateAlert, setAlert] = useState(alert);

  const openAlert = message => {
    setAlert({
      open: true,
      message: message,
    });
  };

  const closeAlert = () => {
    setAlert({
      open: false,
      message: '',
    });
    if(alert.closeAction){
      alert.closeAction();
    }
  };

  return (
    <React.Fragment>
      <Dialog open={stateAlert.open} onClose={closeAlert} aria-labelledby="confirm-dialog">
        <div className="pop_size_small">
          <p className="pop_comment" dangerouslySetInnerHTML={{ __html: stateAlert.message }}></p>
          <DialogActions>
            <div className="btn_set_wrap alC">
              <Button color="primary" onClick={closeAlert} className="btn_color2">
                OK
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
ChannelMappingAlert.propTypes = {
  stateAlert: PropTypes.object,
};
export default ChannelMappingAlert;
