import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { request } from '../../../util/request';
import { Dialog, DialogActions, TextField } from '@material-ui/core';
import FreeOnLgChannelTable from './FreeOnLgChannelTable';
import FreeOnLgChannelDisplay from './FreeOnLgChannelDisplay';
import { getFreeONLGChannelsSettingListAction } from '../../../actions/freeONLgChannelActions';
import xlsx from 'json-as-xlsx';

function FreeOnLgChannelsResult({ id, cntry, result, handleSetResult, selectResult, handleSetDeleteData, onClickAdd, onClickDelete, freeOnLgSettingList, recommendMonth, handleDateChange, getPreviousMonthYear,
  getNextMonthYear, isLastMonth, masBatchTime, masBatchFlag, utcHour, renderOrder, renderOrderEdit, handleSetInsertTableData, handleSetTypeData, setTypeData, getFreeONLGChannelsSettingListAction, renderFixFlag,
  setDataAdded, dataAdded, deletedResult, setDeletedResult, setIsItReorder }) {
  const [onSaveOpen, setOnSaveOpen] = React.useState(false);
  const [swapData, setSwapData] = React.useState([]);
  const [duplicateOrder, setDuplicateOrder] = React.useState();
  const [originalData, setOriginalData] = React.useState([]);
  const [onDeleteData, setOnDeleteData] = React.useState(false);
  const tableRef = React.useRef(null);

  const handleresult = (result) => {
    handleSetResult(JSON.parse(JSON.stringify([...result])));
  }
  React.useEffect(() => {
    if (freeOnLgSettingList.length > 0) {
      handleresult(JSON.parse(JSON.stringify(freeOnLgSettingList)));
    } else {
      handleresult([]);
    }
  }, [freeOnLgSettingList]);

  const clickSave = async e => {
    e.preventDefault();
    const uniqueValues = new Set(result.map(v => parseInt(v.result_order)));
    if (result.length < 1 && deletedResult.length > 0) {
      setOnDeleteData(true)
      setOnSaveOpen(true)
    } else {
      if (result.length < 1) alert('The number of result is less than 1 ! Please add at lease one content !');
      else if (result.find(n => n.result_order === '')) alert('Some order is empty ! Please enter the order !');
      else if (uniqueValues.size < result.length) {
        const duplicateCounts = result.reduce((acc, item) => {
          const order = item.result_order;
          acc[order] = (acc[order] || 0) + 1;
          return acc;
        }, {});
        const duplicates = Object.keys(duplicateCounts)
          .filter(order => duplicateCounts[order] > 1)
          .map(order => parseInt(order));
        setDuplicateOrder(duplicates);
        setOnSaveOpen(true);
      }
      else {
        setDuplicateOrder();
        setOnSaveOpen(true)
      }
    }

  };

  function reOrderArray(orgArray, modifiedArray) {
    orgArray.filter(item => item.result_order === 0).map(m => m.result_order = 1);
    modifiedArray.filter(item => item.result_order === 0).map(m => m.result_order = 1);
    const newData = modifiedArray.filter(modifiedItem => !orgArray.some(orgItem => orgItem.content_id === modifiedItem.content_id));
    const originalData = modifiedArray.filter(modifiedItem => orgArray.some(orgItem => orgItem.content_id === modifiedItem.content_id && orgItem.result_order === modifiedItem.result_order &&
      orgItem.fix_flag === modifiedItem.fix_flag));
    const modifiedData = modifiedArray.filter(modifiedItem => orgArray.some(orgItem => orgItem.content_id === modifiedItem.content_id && (orgItem.result_order !== modifiedItem.result_order ||
      orgItem.fix_flag !== modifiedItem.fix_flag)));
    newData.map(item => item.original = 'new');
    modifiedData.map(item => item.original = 'new');
    originalData.map(item => item.original = 'old');
    const margeOriginalWithAdded = [...newData, ...modifiedData, ...originalData];
    //margeOriginalWithAdded.sort((a, b) => (a.result_order + a.original + a.name).localeCompare(b.result_order + b.original + b.name));
    margeOriginalWithAdded.sort((a, b) => {
      if(a.result_order !== b.result_order) return a.result_order - b.result_order;
      if (a.original === 'new' && b.original !== 'new') return -1;
      if (a.original !== 'new' && b.original === 'new') return 1;
      return a.name.localeCompare(b.name);
    });
    let finalReorderArray = margeOriginalWithAdded.map((item, index) => ({ ...item, result_order: index + 1 }));
    return finalReorderArray;
  }
  const onConfirmReorder = async e => {
    const resultList = [...result]
    const newResultList = resultList.map((n, index) => {
      return n;
    });
    const OriginalSwap = swapData.length > 0 ? swapData : originalData.length > 0 ? originalData : freeOnLgSettingList;
    let result_final_data = reOrderArray(OriginalSwap, newResultList);
    const newResult = result_final_data
      .map((n, index) => {
        const { country_code, cp_idx, provider_idx, type, content_id, image_height, fix_flag, result_order, image_width, name, vod_idx, image, deeplink_id } = n;
        let freeOnLgChSettingList = { countryCode: country_code, recmdMonth: recommendMonth, score: result.length - result_order + 1 };
        freeOnLgChSettingList.providerIdx = cp_idx || provider_idx;
        freeOnLgChSettingList.contentType = type;
        freeOnLgChSettingList.contentId = content_id;
        freeOnLgChSettingList.imageHeight = image_height;
        freeOnLgChSettingList.imageWidth = image_width;
        freeOnLgChSettingList.title = name;
        freeOnLgChSettingList.vodIdx = vod_idx;
        freeOnLgChSettingList.imageUrl = image;
        freeOnLgChSettingList.deeplinkId = deeplink_id;
        freeOnLgChSettingList.resultOrder = result_order;
        freeOnLgChSettingList.fixFlag = fix_flag || 'Y';
        return freeOnLgChSettingList;
      });
    const reOrderResult = resultList.map((n, index) => {
      return n;
    });
    let result_ReorderData = reOrderArray(OriginalSwap, reOrderResult);
    const isSuccess = await request(`/api/admin/countryCode/${cntry}/${recommendMonth}/saveFreeOnChannels`, 'POST', newResult);
    if (isSuccess) {
      alert('Successfully saved !');
      getFreeONLGChannelsSettingListAction(cntry, recommendMonth);
      handleSetResult(result_ReorderData);
    }
    setTypeData();
    setOnSaveOpen(false);
  };

  const onConfirm = async e => {
    const newResult = result.map((n, index) => {
      const { country_code, cp_idx, provider_idx, type, content_id, fix_flag, image_height, result_order, image_width, name, vod_idx, image, deeplink_id } = n;
      let freeOnLgChSettingList = { countryCode: country_code, recmdMonth: recommendMonth, score: result.length - result_order + 1 };
      freeOnLgChSettingList.providerIdx = cp_idx || provider_idx;
      freeOnLgChSettingList.contentType = type;
      freeOnLgChSettingList.contentId = content_id;
      freeOnLgChSettingList.imageHeight = image_height;
      freeOnLgChSettingList.imageWidth = image_width;
      freeOnLgChSettingList.title = name;
      freeOnLgChSettingList.vodIdx = vod_idx;
      freeOnLgChSettingList.imageUrl = image;
      freeOnLgChSettingList.deeplinkId = deeplink_id;
      freeOnLgChSettingList.resultOrder = result_order;
      freeOnLgChSettingList.fixFlag = fix_flag || 'Y';
      return freeOnLgChSettingList;
    }).sort((a, b) => a.resultOrder - b.resultOrder).map((item, index) => ({ ...item, resultOrder: index + 1, score: result.length - index}));
    const isSuccess = await request(`/api/admin/countryCode/${cntry}/${recommendMonth}/saveFreeOnChannels`, 'POST', newResult);
    const reOrderResult = result.map((n, index) => {
      const { country_code, cp_idx, cp, provider_idx, type, content_id, fix_flag, image_height, result_order, genre, last_chg_date, image_width, name, vod_idx, image, deeplink_id } = n;
      let freeOnLgChResult = {
        country_code: country_code, cp: cp, id: content_id, genre: genre, image: image, last_chg_date: last_chg_date, name: name,
        type: type, content_id: content_id, result_order: result_order, provider_idx: cp_idx || provider_idx, fix_flag: fix_flag,
        image_height: image_height, image_width: image_width
      };
      return freeOnLgChResult;
    }).sort((a, b) => a.result_order - b.result_order).map((item, index) => ({ ...item, result_order: index + 1 }));
    if (isSuccess) {
      alert('Successfully saved !');
      setDeletedResult([])
      setOnDeleteData(false)
      handleSetResult(reOrderResult);
    }
    handleSetInsertTableData(reOrderResult);
    getFreeONLGChannelsSettingListAction(cntry, recommendMonth);
    setOriginalData(JSON.parse(JSON.stringify(reOrderResult)));
    setSwapData([]);
    setTypeData();
    setOnSaveOpen(false);
  };

  const getFreeOnLgChannelInfo = (newResult) => {
    let dataRows = newResult.map((row, index) => ({
      Order: row.resultOrder,
      Type: row.contentType,
      CP: row.cp,
      ID: row.contentId,
      Name: row.title,
      Fix: row.fixFlag,
      Image: row.imageUrl,
      Genre: row.genre,
      'Last Change date': row.lastChDate,
      RecommendMonth: row.recmdMonth,
    }));
    let data = [
      {
        sheet: 'freeonlg-channel-info',
        columns: [
          { label: 'Order', value: 'Order' },
          { label: 'Type', value: 'Type' },
          { label: 'CP', value: 'CP' },
          { label: 'ID', value: 'ID' },
          { label: 'Name', value: 'Name' },
          { label: 'Fix', value: 'Fix' },
          { label: 'Image', value: 'Image' },
          { label: 'Genre', value: 'Genre' },
          { label: 'Last Change date', value: 'Last Change date' },
          { label: 'RecommendMonth', value: 'RecommendMonth' },
        ],
        content: dataRows
      },
    ];
    let xLSettings = {
      fileName: cntry + '_' + 'freeonlg-channel-info-' + recommendMonth,
    };
    xlsx(data, xLSettings);
  };


  const getFreeONLGChannel = () => {
    const newResult = result.map((n, index) => {
      const { cp_idx, provider_idx, type, content_id, name, image, genre, cp, last_chg_date, result_order, fix_flag } = n;
      let freeOnLgChSettingList = { recmdMonth: recommendMonth };
      freeOnLgChSettingList.resultOrder = result_order;
      freeOnLgChSettingList.providerIdx = cp_idx || provider_idx;
      freeOnLgChSettingList.contentType = type;
      freeOnLgChSettingList.contentId = content_id;
      freeOnLgChSettingList.title = name;
      freeOnLgChSettingList.fixFlag = fix_flag;
      freeOnLgChSettingList.imageUrl = image;
      freeOnLgChSettingList.genre = genre;
      freeOnLgChSettingList.cp = cp;
      freeOnLgChSettingList.lastChDate = last_chg_date;
      return freeOnLgChSettingList;
    });
    getFreeOnLgChannelInfo(newResult);
  };

  return (
    <React.Fragment>
      <div className="arrow_btn_area">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Button className="btn_move piece" title="Click this to add content" onClick={onClickAdd} />
        <br />
        <Button className="btn_move remove" title="Click this to remove content" onClick={onClickDelete} />
      </div>
      <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list'} style={{ marginLeft: '6%' } /* margin-left: 6%;+'tbl_no_data'*/}>
        <span>
          <div className="mapping_tbl_header_manual_freeonlg">
            <FreeOnLgChannelDisplay masBatchTime={masBatchTime} masBatchFlag={masBatchFlag} utcHour={utcHour}></FreeOnLgChannelDisplay>
            <br />
            <br />
            <br />
            <br />
          </div>
        </span>
        <div className='freeOnLgCalenderRoot freeOnLgCalender'>
          <TextField
            variant="outlined"
            name="recommendMonth"
            id="recommendMonth"
            label="Recommend Month"
            type="month" // Use type "month" for YYYY-MM format
            placeholder="YYYY-MM"
            value={recommendMonth}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: getPreviousMonthYear(),
              max: getNextMonthYear()
            }}
          />
          <Button className="btn_color2 excelButtonLGCh" onClick={() => { getFreeONLGChannel(); }}>
            Download
          </Button>
        </div>
        <span id="popup"></span>

        <FreeOnLgChannelTable
          isResult
          data={result}
          renderOrder={renderOrder}
          renderOrderEdit={renderOrderEdit}
          select={selectResult}
          setDeleteData={handleSetDeleteData}
          setSwapData={setSwapData}
          handleSetInsertTableData={handleSetInsertTableData}
          handleSetTypeData={handleSetTypeData}
          renderFixFlag={renderFixFlag}
          dlagDropFlag={true}
          options={{
            selection: true,
            showTextRowsSelected: false,
            paging: true,
            pageSize: 15,
            pageSizeOptions: [5, 15, 30, 50, 100],
            doubleHorizontalScroll: true,
            maxBodyHeight: 500,
            minBodyHeight: 500,
            rowStyle: rowData => ({
              backgroundColor: rowData.change ? '#fef5f8' : '#FFF',
              color: rowData.change ? '#d8295c' : '#484848',
            }),
          }}
          setDataAdded={setDataAdded}
          dataAdded={dataAdded}
          setIsItReorder={setIsItReorder}
        />

        <div className="btn_set_wrap">
          <form id="addRowFrm" autoComplete="off">
            <div className="btn_set_wrap">
              <Button className="btn_color2" onClick={clickSave} disabled={isLastMonth}>
                save
              </Button>
            </div>
          </form>
        </div>
        {duplicateOrder != null ?
          <Dialog open={onSaveOpen} aria-labelledby="confirm-dialog">
            <div className="pop_size_small">
              <React.Fragment>
                <div className="ico_condition1"></div>
                <p class="pop_comment">Some order is duplicated ({duplicateOrder.join(', ')})! Do you want to reorder and save?</p>
                <DialogActions>
                  <div className="btn_set_wrap alC">
                    <Button color="primary" onClick={onConfirmReorder} className="btn_color2">
                      OK
                    </Button>
                    <Button
                      autoFocus
                      color="primary"
                      onClick={() => setOnSaveOpen(false)}
                      className="btn_color1">
                      Cancel
                    </Button>
                  </div>
                </DialogActions>
              </React.Fragment>
            </div>
          </Dialog> :
          <Dialog open={onSaveOpen} aria-labelledby="confirm-dialog">
            <div className="pop_size_small">
              <React.Fragment>
                <div className="ico_condition1"></div>
                {onDeleteData &&
                  <p className="pop_comment"> There is no content to save. Delete existing data ? </p>
                }

                {!onDeleteData &&
                  <p className="pop_comment"> Do you want to save the result ? </p>
                }
                <DialogActions>
                  <div className="btn_set_wrap alC">
                    <Button color="primary" onClick={onConfirm} className="btn_color2">
                      OK
                    </Button>
                    <Button
                      autoFocus
                      color="primary"
                      onClick={() => setOnSaveOpen(false)}
                      className="btn_color1">
                      Cancel
                    </Button>
                  </div>
                </DialogActions>
              </React.Fragment>
            </div>
          </Dialog>
        }
      </div>
    </React.Fragment>
  );
}
FreeOnLgChannelsResult.propTypes = {};
const mapStateToProps = state => ({
  freeOnLgSettingList: state.freeOnLGChannelView.freeONLGChannelSettingList,
  regionList: state.homeApp.regionList,
  param: state.homeApp.param,
  tabCategory: state.homeApp.tabCategory,
});
const mapDispatchToProps = dispatch => ({
  getFreeONLGChannelsSettingListAction(cntry, recommendedMonth) {
    dispatch(getFreeONLGChannelsSettingListAction(cntry, recommendedMonth));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FreeOnLgChannelsResult));
