import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { BizAreaFilter } from './';

export function ProgramFilter({ bizArea, biz, handleChangeBiz, country, handleChangeCntry, cp, handleChangeCp, channels, channel, handleChangeChannel, title, handleInputChangeTitle, programId, handleInputChangeProgramId, keyword, handleInputChangeKeyword }) {
  let cntryList;
  let cps;
  if (cp) {
    cntryList = bizArea
      ? bizArea.area.map(item => (
          <MenuItem key={item.countryCode} value={item.countryCode}>
            {item.countryCode}
          </MenuItem>
        ))
      : null;
    cps = bizArea ? bizArea.area.find(item => item.countryCode === country) : null;
  } else {
    cntryList = bizArea
      ? bizArea.area.map(item => (
          <MenuItem key={item.countryCode} value={item.countryCode}>
            {item.countryCode}
          </MenuItem>
        ))
      : null;
  }

  return (
    <>
      <BizAreaFilter biz={biz} handleChangeBiz={handleChangeBiz} />
      <div className="filter_area row">
        <div className="filter_row">
          <FormControl variant="outlined">
            <InputLabel>Country</InputLabel>
            <Select value={country} onChange={handleChangeCntry}>
              {cntryList}
            </Select>
          </FormControl>
          {cp && (
            <FormControl variant="outlined">
              <InputLabel>CP</InputLabel>
              <Select value={cp} onChange={handleChangeCp}>
                {cps &&
                  cps.providers &&
                  cps.providers.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          <FormControl variant="outlined">
            <InputLabel>Channel</InputLabel>
            <Select value={channel} onChange={handleChangeChannel}>
              {channels &&
                channels.map(item => (
                  <MenuItem key={item.channelMajorNumber} value={item.channelName}>
                    {item.channelName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div className="filter_row">
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label="Program Title" //focus안줬을때 mandatory text
            value={title}
            onChange={e => handleInputChangeTitle(e)}
            // onKeyDown={(event) => {
            //   handleEnterTitle(event);
            //   // console.log('[event.type]', event.type);//keydown
            //   // console.log('[event.which]', event.which);//13
            //   // console.log('[event.key]', event.key);//Enter
            //   // console.log('[event.keyCode]', event.keyCode);//13
            // }}
          />
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label="Program ID" //focus안줬을때 mandatory text
            value={programId}
            onChange={e => handleInputChangeProgramId(e)}
          />
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label="Keyword" //focus안줬을때 mandatory text
            value={keyword}
            onChange={e => handleInputChangeKeyword(e)}
          />
        </div>
      </div>
    </>
  );
}

ProgramFilter.propTypes = {
  bizArea: PropTypes.object,
  biz: PropTypes.string,
  handleChangeBiz: PropTypes.func.isRequired,
  country: PropTypes.string,
  handleChangeCntry: PropTypes.func.isRequired,
  cp: PropTypes.string,
  handleChangeCp: PropTypes.func,
  channels: PropTypes.array,
  channel: PropTypes.string,
  handleChangeChannel: PropTypes.func.isRequired,
  title: PropTypes.string,
  handleInputChangeTitle: PropTypes.func.isRequired,
  programId: PropTypes.string,
  handleInputChangeProgramId: PropTypes.func.isRequired,
  keyword: PropTypes.string,
  handleInputChangeKeyword: PropTypes.func.isRequired,
};
export default ProgramFilter;
