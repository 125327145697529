import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
//import MaterialTable from 'material-table';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

//import { getProvidersAdminAction } from '../../../actions/providerActions';
// 상세
const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1100,
      // marginLeft: 'auto',
      // marginRight: 'auto',
    },
  },
});

export function NotificationDetail({ id, history }) {
  const [region, setRegion] = React.useState('America');
  return (
    <div>
      <TableContainer className={'tbl_wrap'}>
        <Table className={'tbl_row_typ1 tbl_notification'} aria-label="spanning table">
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '35%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <td colSpan="3">Maintenance Notice_0303</td>
            </TableRow>
            <TableRow>
              <TableCell>Writer</TableCell>
              <td>109</td>
              <TableCell>Created</TableCell>
              <td>2020-03-03 5:52</td>
            </TableRow>
            <TableRow>
              <TableCell>Business Area</TableCell>
              <td colSpan="3">ALL</td>
            </TableRow>
            <TableRow>
              <TableCell>Contents</TableCell>
              <td colSpan="3">Please note that we are currently maintaining the website to provide a better user experience. Maintenance period: 2020-03-04 23:55 ~ 2020-03-05 04:55 Maintenance purpose: server update</td>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <div className="btn_set_wrap">
        <div className="btn_set_left_cont">
          <Button
            variant="contained"
            className="btn_color3"
            onClick={() => {
              history.push('/SYSTEM/notification');
            }}>
            LIST
          </Button>
        </div>
        <Button variant="contained" className="btn_color1">
          DELETE
        </Button>
        <Button variant="contained" className="btn_color2">
          EDIT
        </Button>
      </div>
    </div>
  );
}

NotificationDetail.propTypes = {};

const mapStateToProps = state => ({});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(NotificationDetail)));
