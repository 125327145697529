import React, { useState, useEffect } from 'react';
import FilterPopup from './FilterPopup';
import IssTable from '../common/IssTable';
import xlsx from 'json-as-xlsx';
import Alert from '../modal/Alert';
import IconButton from '../common/IconButton';

function ComponentContainer(props) {
  const { size
        , rangeDays
        , defaultFilter
        , components
        , newSelectedComponentIds
        , onComponentSelect
        , onComponentRemove
        , onComponentMove
        , onComponentAdd
        , onComponentTableShowChange
        , onFilterChange
        , isDivideSection
      } = props;
  const [filterPopupOpen, setFilterPopupOpen] = useState(false);
  const [filterPopupId, setFilterPopupId] = useState(null);
  const [filterApplyTargetIds, setfilterApplyTargetIds] = useState([0]);
  const [groupedComponents, setGroupedComponents] = useState({});
  const [sortedTypes, setSortedTypes] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [componentHeight, setComponentHeight] = useState('357px');

  useEffect(() => {
    if (components) {
      const groupedItems = components.reduce((acc, item) => {
        if (!acc[item.type]) {
          acc[item.type] = [];
        }
        acc[item.type].push(item);
        return acc;
      }, {});
      setGroupedComponents(groupedItems);

      const tempSortedTypes = Object.keys(groupedItems).sort((a, b) => {
        const aPriority = groupedItems[a][0].priority;
        const bPriority = groupedItems[b][0].priority;
        return aPriority - bPriority;
      });

      setSortedTypes(tempSortedTypes);
    }
  }, [components]);

  const handleDragStart = (e, componentId) => {
    e.dataTransfer.setData('componentId', componentId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const componentId = e.dataTransfer.getData('componentId');
    const targetElement = findParentWithDataId(e.target);

    if (targetElement) {
      const sourceIndex = components.findIndex(component => component.id === Number(componentId));
      const targetId = targetElement.getAttribute('data-id');
      const targetIndex = components.findIndex(component => component.id === Number(targetId));

      if (targetIndex !== -1 && targetIndex !== sourceIndex) {
        onComponentMove(sourceIndex, targetIndex);
      }
    }
  };

  const findParentWithDataId = (element) => {
    let parent = element;
    while (parent) {
      if (parent.dataset.id) {
        return parent;
      }
      parent = parent.parentElement;
    }
    return null;
  };

  const handleFilterPopupOpen = (componentId) => {
    setFilterPopupId(componentId);
    setFilterPopupOpen(true);
  };

  const handleFilterPopupClose = () => {
    setfilterApplyTargetIds([]);
    setFilterPopupOpen(false);
  };

  const handleFilterPopupApply = (componentId, changeFilter) => {
    if (changeFilter) {
      setfilterApplyTargetIds([componentId]);

      onFilterChange();
    }
    setFilterPopupOpen(false);
  }

  const handleCopyComponent = (componentId) => {
    const copiedComponent = components.find(component => component.id === componentId);
    onComponentAdd(copiedComponent);
  };

  const updateTableFlag = (componentId, value) => {
    onComponentTableShowChange(componentId, value, 'flag');
  };

  const updateTable = (componentId, newTable) => {
    const findComponent = components.find(component => component.id === componentId);

    if (findComponent && findComponent.show) {
      findComponent.show = false;
    }
    onComponentTableShowChange(componentId, newTable, 'table');
  };

  const excelDownload = (componentId) => {
    const findComponent = components.find(component => component.id === componentId);

    if (findComponent && findComponent.table && findComponent.table.rowdata && findComponent.table.rowdata.length > 0) {
      const data = [
        {
          sheet: findComponent.table.title,
          columns: findComponent.table.header.map(header => ({ label: header, value: header })),
          content: findComponent.table.rowdata.map(row => {
            let rowObj = {};
            row.row.forEach((cell, index) => {
              rowObj[findComponent.table.header[index]] = cell;
            });
            return rowObj;
          }),
        }
      ];

      const settings = {
        fileName: findComponent.table.title,
      };

      xlsx(data, settings);
    } else {
      setAlertMessage(`There is no data`);
      onOpenAlert();
    }
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
      <div className={`container ${size === 'Large' ? 'large' : 'medium'}`} onDragOver={handleDragOver} onDrop={handleDrop}>
        <div>
          {(isDivideSection ? sortedTypes : ['All']).map(type => (
            <div className="component-section">
              {
                isDivideSection ? (
                  <div className="component-title-group">
                    <div className="component-bar"> </div>
                    <div className="component-title"> {type} </div>
                  </div>
                ) : (
                  <></>
                )
              }
              <div className="component-item">
                {(isDivideSection ? groupedComponents[type] : components).map((component) => (
                  <div
                    key={component.id}
                    className={`component ${component.selected ? 'selected' : ''}`}
                    data-id={component.id}
                    draggable
                    onDragStart={(e) => handleDragStart(e, component.id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!filterPopupOpen) {
                        onComponentSelect(component.id);
                      }
                    }}
                  >
                    <div className='option-area'>
                      <button className="option-filter"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFilterPopupOpen(component.id);
                        }}
                      > </button>

                      {
                        component.copy ? (
                          <button className="option-copy"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopyComponent(component.id);
                          }}
                        > </button>
                        ) : (
                          <></>
                        )
                      }

                      {!component.selected && (
                        <button className="option-remove"
                          onClick={(e) => {
                            e.stopPropagation();
                            onComponentRemove(component.id);
                          }}
                        > </button>
                      )}
                    </div>
                    {(filterPopupOpen && filterPopupId === component.id ) &&  (
                      <FilterPopup
                        componentId={component.id}
                        filter={component.filter}
                        rangeDays={rangeDays}
                        onCancel={handleFilterPopupClose}
                        onApply={handleFilterPopupApply}
                      />
                    )}
                    {React.cloneElement(component.component,
                      { height: componentHeight,
                        title: component.name,
                        originalFilter: component.filter,
                        currentFilter: component.filter.selectedFilter ? component.filter.selectedFilter : defaultFilter,
                        updateTable: (newTable) => updateTable(component.id, newTable),
                        size: size,
                        id: component.id
                      })
                    }
                    <hr className="separator"/>
                    <div className="table-header">
                      <div className="table-button">
                        <span>Table View</span>
                        <button
                          className={`toggle-button ${component.show ? 'open' : 'close'}`}
                          onClick={(e) => {e.stopPropagation(); updateTableFlag(component.id, !component.show)}}
                          disabled={Object.keys(component.table).length < 1}
                        >
                        </button>
                      </div>
                      <button
                        className={`excel-download`}
                        onClick={(e) => {e.stopPropagation(); excelDownload(component.id)}}
                      >
                      </button>
                    </div>
                    {
                      component.show && Object.keys(component.table).length > 0 && (
                        <div className="table-body">
                          <IssTable
                            pagination={true}
                            data={component.table}
                            sortingItem={{ enable: true, sortColumn: -1, sortDirection: 'desc' }}
                          />
                        </div>
                      )
                    }
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default ComponentContainer;
