import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function channel(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CH0103_SUCCESS:
      if (action.payload.list) {
        return action.payload.list;
      } else {
        return state;
      }
    case actions.SET_CH0103_FAILURE:
      return state;
    default:
      return state;
  }
}
