import React from 'react';
import PropTypes from 'prop-types';
import Guide from '../guide/guide.js';
import { makeStyles } from '@material-ui/core/styles';

const hourMinutes = 60;
const HOUR_PX_SIZE = 240; //600
const MINUTE_PX_SIZE = HOUR_PX_SIZE / hourMinutes;

function getProgramWidth(schedule, index) {
  const start = convertToUtc(new Date(schedule.start));
  const end = convertToUtc(new Date(schedule.end));

  let startHour = start.getHours();
  let endHour = end.getHours();
  let startMin = start.getMinutes();
  let endMin = end.getMinutes();
  if (start.getDate() !== end.getDate()) {
    //31일 다음은 1일이므로 크기로 비교하면 안됨
    if (index === 0) {
      startHour = 0; //first program
      startMin = 0;
    } else {
      endHour = 24; //last program
      endMin = 0;
    }
  }
  const durationInMinute = endHour * hourMinutes + endMin - (startHour * hourMinutes + startMin);
  return Math.abs(durationInMinute * MINUTE_PX_SIZE);
}

let PROGRAM_LEFT = 0;
function getProgramLeft(schedule, index, preSchedule) {
  if (index === 0) {
    const start = convertToUtc(new Date(schedule.start));
    const end = convertToUtc(new Date(schedule.end));

    if (start.getDate() === end.getDate()) {
      const size = start.getHours() * hourMinutes + start.getMinutes();
      PROGRAM_LEFT = Math.abs(size * MINUTE_PX_SIZE);
    }
    return PROGRAM_LEFT;
  } else {
    if (preSchedule && preSchedule.end !== schedule.start) {
      const preEnd = convertToUtc(new Date(preSchedule.end));
      const start = convertToUtc(new Date(schedule.start));
      const size = (start - preEnd) / 60 / 1000;
      PROGRAM_LEFT += Math.abs(size * MINUTE_PX_SIZE);
    }
    return PROGRAM_LEFT;
  }
}

export function getDatePosition(date) {
  return date.getHours() * HOUR_PX_SIZE + date.getMinutes() * MINUTE_PX_SIZE;
}

export function convertToUtc(date) {
  return new Date(date.getTime() - 9 * 60 * 60 * 1000); //9hour*60min*60sec*1000sec
}

const useStyles = makeStyles(() => ({
  channelList: {
    display: 'flex', //없으면 모든 시간표가 일렬로
    margin: '0', //없으면 그리드 사이간격 넓어지면서 이상해짐
    padding: '0', //없으면 채널스케쥴이 살짝 뒤로 가면서 시간표랑 안맞아짐 찾기어려웠음 주의할 것
  },
}));

const ChannelList = ({ schedules, clickProgramDialog }) => {
  const classes = useStyles();
  const noDataLeft = 24 * hourMinutes + 0 - (0 * hourMinutes + 0);

  return (
    <div>
      {schedules.map(channel => {
        return (
          <ul key={channel.channelId} id={channel.providerId} className={classes.channelList + ' program_list_wrap'}>
            {channel.schedules ? (
              channel.schedules.map((schedule, index, array) => (
                <Guide key={schedule.programId + index} programLeft={getProgramLeft(schedule, index, index === 0 ? null : array[index - 1])} programWidth={getProgramWidth(schedule, index)} schedule={schedule} index={index} clickProgramDialog={clickProgramDialog} />
              ))
            ) : (
              <Guide programLeft={0} programWidth={Math.abs(noDataLeft * MINUTE_PX_SIZE)} />
            )}
          </ul>
        );
      })}
    </div>
  );
};

ChannelList.propTypes = {
  schedules: PropTypes.array.isRequired,
  clickProgramDialog: PropTypes.func.isRequired,
};

export default ChannelList;
