const initialState = [];
//
export default function ch0302(state = initialState, action) {
  switch (action.type) {
    case 'SET_CH0302':
      if (action.payload) {
        return action.payload;
      } else {
        return state;
      }
    case 'SET_CH0302_ERROR':
      return state;
    default:
      return state;
  }
}
