import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ApiCallLogFilter } from '../../../common/filter';
import LoadingIndicator from '../../../common/LoadingIndicator';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import { getLogAction } from '../../../actions/logActions';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';

// const styles = (theme) => ({
//   layout: {
//     width: 'auto',
//     marginLeft: theme.spacing(3),
//     marginRight: theme.spacing(3),
//   },
// });
export function ApiCallLogs({ getLogAction, fetching, logs, isRoleAdmin }) {
  //console.log('[isRoleAdmin]', isRoleAdmin, '[logs]', logs);

  const [shouldRequest, setShouldRequest] = React.useState(true);
  const [cp, setCp] = React.useState('All');
  const [api, setApi] = React.useState('All');
  const [file, setFile] = React.useState('');
  const [status, setStatus] = React.useState('All');
  const [message, setMessage] = React.useState('');
  const [messageRequest, setMessageRequest] = React.useState('');

  const initDate = new Date();
  initDate.setSeconds(0); //start date=from date일 때 에러방지
  initDate.setMilliseconds(0); //start date=from date일 때 에러방지
  initDate.setDate(initDate.getDate() - 7);
  const [startDate, setStartDate] = React.useState(initDate);
  const startDateStr = startDate.toISOString().slice(0, 11) + '00:00:00Z'; //2020-03-01T00:00:00Z

  const [endDate, setEndDate] = React.useState(new Date());
  const requestEndDate = new Date(endDate);
  requestEndDate.setDate(endDate.getDate() + 1);
  const endDateStr = requestEndDate.toISOString().slice(0, 11) + '00:00:00Z';

  const handleChangeStartDate = date => {
    if (date > endDate) alert('Start date is greater then end date, please re-select the date !');
    else setStartDate(date);
  };
  const handleChangeEndDate = date => {
    if (date < startDate) alert('Start date is greater then end date, please re-select the date !');
    else setEndDate(date);
  };

  const handleClickSearch = () => {
    setMessageRequest(message);
    setShouldRequest(true);
  };
  useEffect(() => {
    if (shouldRequest) {
      getLogAction(isRoleAdmin, cp, api, file, status, startDateStr, endDateStr);
      setShouldRequest(false);
    }
  }, [isRoleAdmin, shouldRequest]);

  const getLogFileDownload  = async (rowData) => {
    if (rowData.filePath === null || rowData.filePath === '' || rowData.filePath === undefined) {
      alert('Not Found File');
      return;
    }
    if (isRoleAdmin === true) {
      await getFileDownload(`/admin/apiCallLog/${rowData.idx}/file`, 'GET', rowData);
    }
  };

  return (
    <div>
      <ApiCallLogFilter
        cp={isRoleAdmin ? cp : null}
        handleChangeCp={e => setCp(e.target.value)}
        api={api}
        handleChangeApi={e => setApi(e.target.value)}
        file={file}
        handleInputChangeFile={e => setFile(e.target.value)}
        status={status}
        handleChangeStatus={e => setStatus(e.target.value)}
        message={message}
        handleInputChangeMessage={e => setMessage(e.target.value)}
        startDate={startDate}
        handleChangeStartDate={handleChangeStartDate}
        endDate={endDate}
        handleChangeEndDate={handleChangeEndDate}
      />
      <div className="btn_set_wrap search_wrap">
        <Button variant="contained" className={'btn_color2'} onClick={handleClickSearch}>
          Search
        </Button>
      </div>
      {fetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        <MaterialTable
          onRowClick={(event, rowData) => {
            getLogFileDownload(rowData);
          }}
          title=" " //없으면 Table Title이 default title로 나옴
          columns={[
            {
              title: 'CP',
              field: 'providerName',
            },
            {
              title: 'API',
              field: 'apiName',
            },
            {
              title: 'File',
              field: 'fileName',
            },
            {
              title: 'Status',
              field: 'successFlag',
            },
            {
              title: 'Message',
              field: 'message',
            },
            {
              title: 'Start Date',
              field: 'startTime',
            },
            {
              title: 'End Date',
              field: 'requestTime',
            },
          ]}
          data={logs
            .filter(item => (item.message ? item.message.toLowerCase().includes(messageRequest.toLowerCase()) : item))
            .map(n => {
              let newData = {};
              newData['idx'] = n.idx;
              newData['apiName'] = n.apiName;
              newData['fileName'] = n.fileName;
              newData['message'] = n.message;
              newData['providerName'] = n.providerName;
              newData['successFlag'] = n.successFlag ? 'success' : n.successFlag === null ? 'processing' : 'fail';
              newData['startTime'] = n.startTime;
              newData['requestTime'] = n.requestTime;
              newData['filePath'] = n.filePath;
              return newData;
            })}
          options={{
            pageSize: 10,
            rowStyle: { backgroundColor: '#fff' },
          }}
        />
      )}
    </div>
  );
}
ApiCallLogs.propTypes = {
  fetching: PropTypes.bool.isRequired,
  getLogAction: PropTypes.func.isRequired,
  logs: PropTypes.array.isRequired,
  isRoleAdmin: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  fetching: state.log.fetching,
  logs: state.log.logs,
  isRoleAdmin: state.currentUser.isRoleAdmin,
});
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  getLogAction(isRoleAdmin, cp, api, file, status, from, to) {
    dispatch(getLogAction(isRoleAdmin, cp, api, file, status, from, to));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ApiCallLogs));

/* Admin MetaFile Download : response blob */
export async function getFileDownload(requestPath, requestMethod, rowData) {
  await fetch(API_BASE_URL + requestPath, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        rowData.fileName,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(err => {
      alert('error')
    });
}