export const issue_contents_history_total_data = {
  daily_contents_total_error_occurrence: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 0,
          ticks: {
            stepSize: 10,
          },
        },
      },
    },
  },
  daily_contents_total_error_resolution: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (value) => `${value}%`,
          },
        },
      },
    },
  },

  daily_contents_total_error_cumulative_resolution: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (value) => `${value}%`,
          },
        },
      },
    },
  },
};
