import { API_BASE_URL } from '../constants';
import { getHeaders, getHeadersFormData } from '../util/actionUtil';
import { showLoader, hideLoader } from './loaderActions';

export const getNotificationsAction = () => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/notifications`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch({ type: 'SET_NOTIFICATIONS_DATA', payload: body });
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const getChannelMapListByCountryCode = countryList => dispatch => {
  // dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/notifications/channelMapList?countryCodes=` + countryList.join(','), {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'SET_NOTIFICATIONS_CHANNEL_MAP_LIST', payload: body });
    })
    .catch(() => {})
    .finally(() => {
      // dispatch(hideLoader());
    });
};

export const addNotificationAction = notificationsData => dispatch => {
  const { imageData, startDate, endDate } = notificationsData;
  notificationsData['startDate'] = new Date(startDate).toISOString();
  notificationsData['endDate'] = new Date(endDate).toISOString();
  notificationsData.lastChgDate = new Date().toISOString();
  delete notificationsData['imageData'];
  delete notificationsData['lang'];

  let formData = new FormData();
  formData.append('image', imageData);
  formData.append('data', JSON.stringify(notificationsData));

  //======= Api Call =======
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/notifications`, {
    method: 'POST',
    headers: getHeadersFormData(),
    body: formData,
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch(getNotificationsAction());
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const updateNotificationAction = notification => dispatch => {
  const { imageData, startDate, endDate } = notification;
  notification['startDate'] = new Date(startDate).toISOString();
  notification['endDate'] = new Date(endDate).toISOString();
  notification.lastChgDate = new Date().toISOString();

  delete notification['imageData'];
  delete notification['lang'];
  delete notification['tableData'];

  let formData = new FormData();
  formData.append('image', imageData);
  formData.append('data', JSON.stringify(notification));

  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/notifications`, {
    method: 'PATCH',
    headers: getHeadersFormData(),
    body: formData,
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch(getNotificationsAction());
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export async function enableNotificationAction(notification) {
  const { imageData, startDate, endDate } = notification;
  notification['startDate'] = new Date(startDate).toISOString();
  notification['endDate'] = new Date(endDate).toISOString();
  notification.lastChgDate = new Date().toISOString();

  delete notification['imageData'];
  delete notification['lang'];
  delete notification['tableData'];

  let formData = new FormData();
  formData.append('image', imageData);
  formData.append('data', JSON.stringify(notification));

  showLoader();
  return fetch(`${API_BASE_URL}/admin/notifications`, {
    method: 'PATCH',
    headers: getHeadersFormData(),
    body: formData,
  })
    .then(res => res.json())
    .catch(() => {})
    .finally(() => {
      hideLoader();
    });
}

export const publishNotificationsAction = id => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/notifications/publish?id=` + id, {
    method: 'POST',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        if (body.message){
          alert(body.message);
        } else {
          alert('Some Error Occured !');
        }
      } else {
        alert('Successfully Published !');
        dispatch(getNotificationsAction());
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const searchNotificationsAction = data => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/notifications/search`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch({ type: 'SET_NOTIFICATIONS_DATA', payload: body });
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const getHisttoryAction = id => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/admin/notificationshistory/${id}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch({ type: 'SET_NOTIFICATIONS_HISTORY_DATA', payload: body });
      }
    })
    .finally(() => {
      dispatch(hideLoader());
    });
};
