export const CHART_COLORS = {
  PURPLE: 'rgba(157, 141, 247, 1)',
  PINK: 'rgba(233, 146, 227, 1)',
  MINT: 'rgba(133, 221, 219, 1)',
  BLUE: 'rgba(76, 132, 224, 1)',
  ORANGE: 'rgba(246, 192, 101, 1)',
  GRAY: 'rgba(255, 255, 255, 0.1)',
  GRAY_LABEL: 'rgba(0, 0, 0, 0.4)',
  WHITE: 'rgba(255,255,255,1)',
  BROWN: '#A52A2A',
  GOLD: '#FFD700',
  CHOCOLATE: '#D2691E',
  LAVENDER: '#E6E6FA',
  IVORY: '#FFFFF0',
  UNSELECTED: ['#767676', '#b8b8b8', '#d7d7d7', '#4e4e4e', '#aeaeae'],
  UNSEL_1: '#767676',
  UNSEL_2: '#b8b8b8',
  UNSEL_3: '#d7d7d7',
  UNSEL_4: '#4e4e4e',
  UNSEL_5: '#aeaeae',
};

export const CHART_COLORS_BY_COUNTRY = {
  AR: '#69B0EE',
  AT: '#719BF4',
  AU: '#EFA1F1',
  BE: '#FCF057',
  BR: '#89D35C',
  CA: '#E47874',
  CH: '#6387D5',
  CL: '#D4AA00',
  CO: '#FF9DB0',
  DE: '#77B2E0',
  DK: '#8E9FFF',
  ES: '#723939',
  FI: '#E78B48',
  FR: '#FFBF7C',
  GB: '#74D1A0',
  IE: '#FE83A8',
  IN: '#5390DC',
  IT: '#A0E7E3',
  JP: '#BED38F',
  KR: '#BE6F75',
  LU: '#526496',
  MX: '#FCBCEA',
  NL: '#8DD6BD',
  NO: '#DC8083',
  PE: '#6760BC',
  PT: '#FDA1A1',
  SE: '#4E75CE',
  US: '#AFBCE8',
  OTHERS: '#FFD700',
};

export const CHART_COLORS_BY_PROVIDER = {
  AMG: '#69B0EE',
  AMGNOW: '#719BF4',
  AMGUP: '#EFA1F1',
  APS: '#FCF057',
  BILD: '#89D35C',
  BLBG: '#E47874',
  FMD: '#6387D5',
  FREQCY: '#D4AA00',
  FUNKE: '#FF9DB0',
  HMN: '#77B2E0',
  JKM: '#8E9FFF',
  MMATV: '#723939',
  NCAA: '#E78B48',
  NEW: '#FFBF7C',
  NEWIDUP: '#74D1A0',
  NTLUS: '#FE83A8',
  OTTR: '#5390DC',
  PLT: '#A0E7E3',
  RBX: '#BED38F',
  RKT: '#BE6F75',
  RKTOG: '#526496',
  SHOPTIME: '#FCBCEA',
  SPT: '#8DD6BD',
  TVUP: '#DC8083',
  UPLUS: '#6760BC',
  WNEW: '#FDA1A1',
  WRL: '#4E75CE',
  XUMO: '#AFBCE8',
  OTHERS: '#FFD700',
};

export const CHART_COLORS_BY_PLATFORM = {
  'webOS 3.0': '#69B0EE',
  'webOS 3.5': '#719BF4',
  'webOS 4.0': '#EFA1F1',
  'webOS 4.5': '#FCF057',
  'webOS 5.0': '#89D35C',
  'webOS 6.0': '#E47874',
  'webOS 22': '#6387D5',
  'webOS 23': '#D4AA00',
  'webOS 24': '#FF9DB0',
  'ME 6.0': '#77B2E0',
  'ME 7.0': '#8E9FFF',
  'WEE 5.0': '#723939',
  'WEE 6.0': '#E78B48',
  'WEE 7.0': '#FFBF7C',
  'webOS 25': '#74D1A0',
  OTHERS: '#FFD700',
};

export const CHART_LINE_GRID_COLOR = {
  GRID_WHITE: 'rgba(0,0,0,0.05)',
  GRID_GRAY: '#55565A',
  BASE_LINE: '#F6C065',
};

export const CHART_LINE_GRID_SUB_MAX_COLOR = {
  GRID_WHITE: 'rgba(0,0,0,0.05)',
  GRID_GRAY: '#55565A',
  BASE_LINE: '#E992E3',
};

export const CHART_LINE_GRID_SUB_MIN_COLOR = {
  GRID_WHITE: 'rgba(0,0,0,0.05)',
  GRID_GRAY: '#55565A',
  BASE_LINE: '#E992E3',
};

export const CHART_BAR_COLOR = {
  BLUE: '#4C84E0',
  PINK: '#E992E3',
};

export const CHART_BAR_GRID_COLOR = {
  WHITE: '#FFFFFF',
  GRAY_AXES: '#55565A',
};

export const CHART_LINE_ANN_BASELINE = (title, value) => {
  return {
    type: 'line',
    borderColor: CHART_LINE_GRID_COLOR.BASE_LINE,
    borderWidth: 2,
    fill: false,
    borderDash: [5, 5],
    borderDashOffset: 2,
    scaleID: 'y',
    value: value,
    label: {
      backgroundColor: CHART_COLORS.GRAY_LABEL,
      borderRadius: 10,
      content: `${title} : ${Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      display: true,
      position: 'center'
    },
  };
};

export const CHART_LINE_SUB_MAX_BASELINE = (title, value) => {
  return {
    type: 'line',
    borderColor: CHART_LINE_GRID_SUB_MAX_COLOR.BASE_LINE,
    borderWidth: 2,
    fill: false,
    pointRadius: 0, //포인트 삭제
    borderDash: [5, 5],
    borderDashOffset: 2,
    scaleID: 'y',
    value: value,
    label: {
      backgroundColor: CHART_COLORS.GRAY_LABEL,
      borderRadius: 10,
      content: `${title} : ${Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      display: true,
      position: 'start'
    },
  };
};

export const CHART_LINE_SUB_MIN_BASELINE = (title, value) => {
  return {
    type: 'line',
    borderColor: CHART_LINE_GRID_SUB_MIN_COLOR.BASE_LINE,
    borderWidth: 2,
    fill: false,
    pointRadius: 0, //포인트 삭제
    borderDash: [5, 5],
    borderDashOffset: 2,
    scaleID: 'y',
    value: value,
    label: {
      backgroundColor: CHART_COLORS.GRAY_LABEL,
      borderRadius: 10,
      content: `${title} : ${Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      display: true,
      position: 'end'
    },
  };
};

export const POINT_STYLE_BY_BEACON_TYPE = {
  channel: 'circle',
  program: 'rect',
  homeapp: 'triangle',
  system: 'star'
}