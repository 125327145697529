import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function ScrollToTop({ children, location }) {
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (prevLocation && location.pathname !== prevLocation.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, prevLocation]);

  return children;
}

ScrollToTop.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(ScrollToTop);
