import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppBar, Tab, Tabs, TextField } from '@material-ui/core';
import QuillEditor from '../../../common/QuillEditor/QuillEditor';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

//a11yProps
function ContentEditerTabPanel({ languages, langContentsData, handleContent }) {

  const [tabValue, setTabValue] = useState(0);
  const [contentsData, setContentsData] = useState([]);
  const [htmlContent, setHtmlContent] = useState("");
  const [tabLanguage, setTabLanguage] = useState("");
  const quillRef = useRef([]);
  const quillRefTemp = useRef();

  if (langContentsData.length > 0 && (tabLanguage === null || tabLanguage === '')){
    setTabLanguage(langContentsData[0].langCode);
  }

  useEffect(() => {
    const fiteredContentData = langContentsData.filter(c => languages.includes(c.langCode));
    setContentsData(fiteredContentData);
    setTabValue(tabValue >= fiteredContentData.length ? 0 : tabValue);
    handleContent(fiteredContentData);
    languages.forEach((lang, index) => {
      if (fiteredContentData[index] === undefined) {
        setContentsData([...fiteredContentData, { langCode: lang, title: '', content: '' }]);
        handleContent([...fiteredContentData, { langCode: lang, title: '', content: '' }]);
        quillRef.current[index] = {...quillRefTemp};
      }
    });
  }, [languages]);

  useEffect(() => {
    const newContentsData = contentsData.map(data => {
      if (data.langCode === tabLanguage) {
        return {
          ...data,
          content: htmlContent,
        };
      }
      return data;
    });

    if (newContentsData.length > 0){
      setContentsData(newContentsData);
      handleContent(newContentsData);
    }
  }, [htmlContent]);

  const handleTabChange = (event, newValue) => {

    if (langContentsData.length > 0){
      setTabLanguage(langContentsData[newValue].langCode);
    }
    setTabValue(newValue);
  };

  const handleContentTitleChange = (event, lang) => {
    const { value } = event.target;
    const newContentsData = contentsData.map(data => {
      if (data.langCode === lang) {
        return {
          ...data,
          title: value,
        };
      }
      return data;
    });
    setContentsData(newContentsData);
    handleContent(newContentsData);
  };

  return (
    <Fragment>
      {languages.length > 0 && (
        <Fragment>
          <AppBar position="static" id="scheduleTabHead">
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto">
              {languages.map(lang => (
                <Tab key={lang} label={lang} {...a11yProps(lang)} />
              ))}
            </Tabs>
          </AppBar>
          {langContentsData.map((contentLang, index) => (
            <div key={index} role="tabpanel" hidden={tabValue !== index} id={`simple-tabpanel-${contentLang.langCode}`} aria-labelledby={`simple-tab-${contentLang.langCode}`}>
              {
                <div>
                  <TextField label="title" fullWidth margin="dense" variant="outlined" name="title" id="title" value={contentLang.title}
                  onChange={event => handleContentTitleChange(event, contentLang.langCode)} required autoFocus />
                  <QuillEditor
                  quillRef={quillRef.current[index]}
                  id={`content-${contentLang.langCode}`}
                  htmlContent={contentLang.content}
                  setHtmlContent={setHtmlContent}
                  api={`api-${contentLang.langCode}`}
                  readOnly={false}
                  />
                  <p className="check_bite">
                  </p>
                </div>
              }
            </div>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
}

ContentEditerTabPanel.propTypes = {
  languages: PropTypes.array.isRequired,
  langContentsData: PropTypes.array.isRequired,
  handleContent: PropTypes.func.isRequired,
};

export default connect()(ContentEditerTabPanel);
