import React, { useState, useEffect } from 'react';
import DatePickerDefault from '../../common/DatePickerDefault';
import IssTab from '../../common/IssTab';
import IssTabs from '../../common/IssTabs';
import IssueHistoryStatisticsCommon from './IssueHistoryStatisticsCommon';
import IssueHistoryBeaconCommon from './IssueHistoryBeaconCommon';
import IssueHistoryStatisticsTotal from './IssueHistoryStatisticsTotal';
import IconButton from '../../common/IconButton';
import DropDown from '../../common/DropDown';
import DefaultButton from '../../common/DefaultButton';
import Alert from "../../modal/Alert";
import RadioChartFilter from '../../common/RadioChartFilter';
import DropDownForSearch from '../../common/DropDownForSearch';
import { ISS_BASE_URL } from '../../../../constants/index';
import { getHeaders } from '../../../../util/actionUtil';

const IssueHistoryStatistics = ({ filterCallback }) => {
  const [pageCurrentTab, pageClickTab] = useState(0);
  const [calendarValue, setCalendarValue] = useState([]);
  const [paramRegions, setParamRegions] = useState([]);
  const [paramCountries, setParamCountries] = useState([]);
  const [paramProviders, setParamProviders] = useState([]);
  const [currentFilter, setCurrentFilter] = useState({date: '', country: '', provider: '', type:''});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isDateShow, setDateShow] = useState(true);
  const [downloadDataType, setDownloadDataType] = useState('');
  const [isDownloadFlag, setDownloadFlag] = useState(false);
  const [providerIdAndNameMapping, setProviderIdAndNameMapping] = useState([]);
  const [defaultDate, setDefaultDate] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [paramTypeList, setParamTypeList] = useState([]);
  const subTypeName = ['beacon'];
  const [chartType, setChartType] = useState('Line');
  const [regions, setRegions] = useState({'dropdownList': [], 'checkedList' : []});
  const [countries, setCountries] = useState({'dropdownList': [], 'checkedList' : []});
  const [providers, setProviders] = useState({'dropdownList': [], 'checkedList' : []});
  const [searchFilter, setSearchFilter] = useState({});
  const [groups, setGroups] = useState({ dropdownList: [], checkedList: [] });
  const [checkedGroups, setCheckedGroups] = useState([])

  useEffect(() => {
    defaultRangeDate();
    getSearchFilter();
    setTypeList({ dropdownList: ['channel','vod'] });
  }, []);

  useEffect(() => {
    setCurrentFilter({subType: subTypeName[pageCurrentTab], date: calendarValue, country: '', provider: '', type:'', groupList: []});
    setDownloadDataType('');
  }, [pageCurrentTab]);

  useEffect(() => {
    setCurrentFilter({subType: subTypeName[pageCurrentTab], date: calendarValue, country: '', provider: '', type:'', groupList: []});
  }, [calendarValue]);

  useEffect(() => {
    if ( searchFilter.countryByRegion ) {
      let dropdownCountries = [];
      if (paramRegions && paramRegions.length > 0){
        dropdownCountries = searchFilter.countryByRegion.filter(f => paramRegions.includes(f.groupName))
        .map(region => region.countryList.map(map => ({ id: map.countryCode, value : map.fullName })))
        .reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownCountries.sort((a, b) => a.value.localeCompare(b.value));
      }
      // Unknown
      if (dropdownCountries.length > 0) {
        dropdownCountries.push({ id: 'Unknown', value : 'Unknown' });
      }

      setCountries({'dropdownList': dropdownCountries, 'checkedList' : [] });
    }
  }, [paramRegions]);

  useEffect(() => {
    if ( searchFilter.countryByRegion &&  searchFilter.providerByCountry) {
      let dropdownProviders = [];
      if (paramCountries && paramCountries.length > 0 && paramCountries.filter(item => item != 'Unknown').length > 0) {
        let ProviderList = searchFilter.providerByCountry.filter(f => paramCountries.filter(item => item != 'Unknown')
        .includes(f.countryCode)).map(map => map.providerList.map(pr => ({ id: pr.providerId, value : pr.providerName })))
        .reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownProviders = [...new Set(ProviderList.map((list) => JSON.stringify(list)))].map((list) => JSON.parse(list));
      }
      setProviders({ 'dropdownList': dropdownProviders, 'checkedList' : [] });
    }
  }, [ paramCountries ]);

  useEffect(() => {
    if (currentFilter.date.length > 0) {
      filterCallback(currentFilter);
    }
  }, [currentFilter]);

  useEffect(() => {
    if(!isDateShow) {
      setDateShow(true);
    }
  }, [isDateShow]);

  useEffect(() => {
    setDownloadFlag(false);
  }, [isDownloadFlag]);

  const onClickSearchStatistics = () => {
    const regions = [...new Set(paramRegions.map(region => region.substring(0,3)))];
    if (pageCurrentTab === 1 && (regions.length === 0 || paramCountries.length === 0 || paramProviders.length === 0 || paramTypeList.length === 0)) {
      const message = regions.length === 0 ? 'region' : paramCountries.length === 0 ? 'country'
        : paramTypeList.length === 0 ? 'type' : 'cp';
      setAlertTitle('Warning');
      setAlertMessage(`Please select any ${message}`);
      onOpenAlert();
    } else if (pageCurrentTab === 2 && checkedGroups.length === 0) {
      setAlertTitle('Warning');
      setAlertMessage('Please select any group');
      onOpenAlert();
    } else {
      setCurrentFilter({subType: subTypeName[pageCurrentTab], date: calendarValue, country: paramCountries, provider: paramProviders, groupList: checkedGroups, type: paramTypeList});
    }
  };

  const getSearchFilter = () => {
    fetch(`${ISS_BASE_URL}/api/common/search-filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSearchFilter({
          countryByRegion : body.countryByRegion
          , providerByCountry : body.providerByCountry
        })
        let dropdownRegion = [];
        // countryByRegion
        if ( body.countryByRegion ) {
          dropdownRegion = Array.from(new Set(body.countryByRegion.map(map => map.groupName )));
          setGroups({ dropdownList: dropdownRegion, checkedList: dropdownRegion })
          dropdownRegion = dropdownRegion.map(map =>({ id: map, value : map }));
          dropdownRegion.sort((a, b) => a.value.localeCompare(b.value));
        }
        setRegions({ 'dropdownList': dropdownRegion, 'checkedList' : [] });
      } else {
        setAlertTitle('Error');
        setAlertMessage('[getSearchFilter : error]');
        setIsAlert(true);
      }
    })
    .catch(error => {
      console.log("getSearchFilter - error: " , error);
      setAlertTitle('Error');
      setAlertMessage('[getSearchFilter : error]');
      setIsAlert(true);
    }).finally(() => {});
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const excelDownload = () => {
    console.log(`[ISS] excelDownload`);
    setDownloadDataType('excel');
    setDownloadFlag(true);
  };

  const pdfDownload = () => {
    console.log(`[ISS] pdfDownload`);
    setDownloadDataType('pdf');
    setDownloadFlag(true);
  };

  //------------------------------------
  //tab 관련
  //------------------------------------
  const tabMenus = [
    {
      name: 'Beacon Data',
      tooltip: '',
    }
  ];

  // Default date는 30일
  const defaultRangeDays = 30; // 달력에 날짜를 보여 주기 위한 default 일자, 30일
  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());

    const thirtyDaysAgo = new Date(utcToday);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);

    const utcTodayString = utcToday.toISOString().substring(0, 10);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0, 10);

    setDefaultDate([thirtyDaysAgoString, utcTodayString]); // [startDate, endDate]
  };

  const tabChangeHandler = (selectedIdx, _data) => {
    if (pageCurrentTab !== selectedIdx) {
      setDateShow(false);
      setCurrentFilter({date: '', country: '', provider: '', ric:'', type:''});
      setCalendarValue(defaultDate);
      pageClickTab(selectedIdx);
    }
  };

  const chartChangeHandler = value => {
    setChartType(value);
  };

  return (
    <>
      <div className="ui-components">
        <div className="box-left">
          <div className="ui-datepicker-box">
            {
              isDateShow === true ? (
                  <DatePickerDefault dateChangeHandler={(newDate) => setCalendarValue(newDate)}/>
              ) : (
                  <></>
              )}
          </div>
          <div className="ui-dropdown-box">
            <>
              <DropDown item={groups} title="Group" checkedList={groups.checkedList} onChangeDropDown={setCheckedGroups} />
            </>
            <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={onClickSearchStatistics}/>
            <div style ={{ padding:'0px 0px 0px 15px', border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px' }}>
              <RadioChartFilter chartType={chartType} onChangeRadio={chartChangeHandler} />
            </div>
          </div>
        </div>
      </div>
      <div className="tab-container">
        <div className="box-btns">
          <IconButton type={'button'} text={'Excel Download'} btnClass={'btn-icon-download'} clickEvent={excelDownload}/>
        </div>
        <IssTabs tabMenus={tabMenus} currentTab={pageCurrentTab} className={'ui-tab-type03'} onChange={tabChangeHandler}>
          <IssTab isActive={true}>
          {
            isDownloadFlag === false ? (
              <IssueHistoryBeaconCommon currentTab={pageCurrentTab} currentFilter={currentFilter} downloadDataType={downloadDataType} chartType={chartType}/>
            ) : (
                <></>
            )
          }
          </IssTab>
        </IssTabs>
      </div>
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
};

export default IssueHistoryStatistics;
