import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingIndicator from '../../../common/LoadingIndicator';
import MaterialTable from 'material-table';
//import Button from '@material-ui/core/Button';
import CpMemberAdd from './CpMemberAdd';
import ChangePw from '../ChangePw';
import SwitchAccess from './SwitchAccess';
import AccountDelete from '../AccountDelete';
import { getMembersAction } from '../../../actions/userActions';
//import { request } from '../../../util/request';
import { CpMemberFilter } from '../../../common/filter';
import Button from '@material-ui/core/Button';

export function CpMember({ isRoleAdmin, getMembersAction, fetching, members }) {
  const [cp, setCp] = React.useState('All');
  const [id, setId] = React.useState('');
  const [list, setList] = React.useState();

  useEffect(() => {
    getMembersAction(isRoleAdmin);
  }, [isRoleAdmin]);

  useEffect(() => {
    setList(userListId);
  }, [members]);

  const userList = members.map(n => {
    let newData = {};
    newData['ID'] = n.username;
    newData['CP Code'] = n.providerId;
    newData['Access'] = n.useFlag === 'N' ? false : true;
    return newData;
  });
  const userListRole = cp !== 'All' ? userList.filter(item => item['CP Code'] === cp) : userList;
  const userListId = id ? userListRole.filter(item => item.ID.toLowerCase().includes(id.toLowerCase())) : userListRole;

  return (
    <div>
      <CpMemberFilter cp={isRoleAdmin && cp} handleChangeCp={e => setCp(e.target.value)} id={id} handleInputChangeId={e => setId(e.target.value)} />
      <div className="btn_set_wrap search_wrap">
        <Button variant="contained" className={'btn_color2'} onClick={() => setList(userListId)}>
          Search
        </Button>
      </div>
      {fetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        <div className={'tbl_wrap'}>
          <div className={'btn_set_wrap tbl_top1'}>{isRoleAdmin && <CpMemberAdd />}</div>
          <MaterialTable
            title=" " //없으면 Table Title이 default title로 나옴
            columns={
              isRoleAdmin
                ? [
                    {
                      title: 'ID',
                      field: 'ID',
                    },

                    {
                      title: 'Password',
                      render: rowData => <ChangePw item={rowData} reloadAction={getMembersAction} />,
                    },
                    {
                      title: 'CP Code',
                      field: 'CP Code',
                    },
                    {
                      title: 'Access',
                      render: rowData => <SwitchAccess item={rowData} />,
                    },
                    {
                      title: 'Action',
                      render: n => (
                        <React.Fragment>
                          <div className={'btn_set_wrap_tbl'}>
                            <AccountDelete requestPath={`/api/admin/users/${n['ID']}`} reloadAction={getMembersAction} isRoleAdmin={isRoleAdmin} />
                          </div>
                        </React.Fragment>
                      ),
                    },
                  ]
                : [
                    {
                      title: 'ID',
                      field: 'ID',
                    },
                  ]
            }
            data={list}
            options={{
              pageSize: 10,
              pageSizeOptions: [5, 10, 30, 50, 100],
              rowStyle: { backgroundColor: '#fff' },
            }}
          />
        </div>
      )}
    </div>
  );
}

CpMember.propTypes = {
  isRoleAdmin: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  getMembersAction: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  isRoleAdmin: state.currentUser.isRoleAdmin,
  members: state.users.members,
  fetching: state.users.memberFetching,
});
const mapDispatchToProps = dispatch => ({
  getMembersAction(isRoleAdmin) {
    dispatch(getMembersAction(isRoleAdmin));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CpMember));

// id: 2
// name: "rakuten"
// username: "rakuten"
// email: "rakuten@rakuten.com"
// providerId: "RAKUTEN"
// createdAt: "2020-01-17T04:49:10Z"
// roles: [{id: 5, name: "ROLE_USER"}, {id: 3, name: "ROLE_CP"}]
