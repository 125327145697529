import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { getProvidersAdminAction } from '../../actions/providerActions';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const apis = ['All', '/programschedules', '/channels', '/vods', '/programs', '/schedules'];
const statuses = ['All', 'success', 'fail', 'processing'];

function ApiCallLogFilter({ cp, handleChangeCp, providerIds, getProvidersAdminAction, api, handleChangeApi, file, handleInputChangeFile, status, handleChangeStatus, message, handleInputChangeMessage, startDate, handleChangeStartDate, endDate, handleChangeEndDate }) {
  const classes = useStyles();
  //console.log('[CpResponse]', CpResponse);
  //console.log('[providerIds]', providerIds);

  useEffect(() => {
    if (providerIds.length === 0) getProvidersAdminAction();
  }, []);

  return (
    <>
      <div className="filter_area row">
        <div className="filter_row">
          {cp && (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">CP</InputLabel>
              <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={cp} onChange={handleChangeCp}>
                {providerIds.map(cp => (
                  <MenuItem key={cp} value={cp}>
                    {cp}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">API</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={api} onChange={handleChangeApi}>
              {apis.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            //autoComplete="off"
            fullWidth
            margin="dense"
            variant="outlined"
            //name="Title"
            //id="Title"
            label="File" //focus안줬을때 mandatory text
            value={file}
            onChange={event => handleInputChangeFile(event)}
            //onKeyDown={(event) => handleEnter(event)}
          />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={status} onChange={handleChangeStatus}>
              {statuses.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="filter_row">
          <TextField
            //autoComplete="off"
            fullWidth
            margin="dense"
            variant="outlined"
            //name="Title"
            //id="Title"
            label="Message" //focus안줬을때 mandatory text
            value={message}
            onChange={event => handleInputChangeMessage(event)}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.datePicker}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Start date"
              value={startDate}
              onChange={handleChangeStartDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.datePicker}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="End date"
              value={endDate}
              onChange={handleChangeEndDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </>
  );
}

ApiCallLogFilter.propTypes = {
  cp: PropTypes.string,
  handleChangeCp: PropTypes.func.isRequired,
  providerIds: PropTypes.array.isRequired,
  getProvidersAdminAction: PropTypes.func.isRequired,
  api: PropTypes.string.isRequired,
  handleChangeApi: PropTypes.func.isRequired,
  file: PropTypes.string.isRequired,
  handleInputChangeFile: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  handleInputChangeMessage: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  handleChangeStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.object.isRequired,
  handleChangeEndDate: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  providerIds: state.providers.providerIds,
});
const mapDispatchToProps = dispatch => ({
  getProvidersAdminAction() {
    dispatch(getProvidersAdminAction());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ApiCallLogFilter);
