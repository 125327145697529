////////////////////////////////////////////////////////////////////////////////
// import
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// import basic
////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from 'react'; // React
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; // connect
import { withRouter } from 'react-router-dom'; // withRouter
////////////////////////////////////////////////////////////////////////////////
// import material
////////////////////////////////////////////////////////////////////////////////
import { TablePagination } from '@material-ui/core';
import MaterialTable from 'material-table';
import RssLogFilter from '../../../../common/filter/RssLogFilter';
import { getRssLog } from '../../../../actions/rssLogsActions';
////////////////////////////////////////////////////////////////////////////////
// RSSCollectionLogs
////////////////////////////////////////////////////////////////////////////////
function RSSCollectionLogs({ feedlist, getRssLog, size, page, totalElements }) {
  useEffect(() => {
    callLogList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [country, setCntry] = useState({ selectKey: 'ALL', selectValue: 'ALL' });
  const [cp, setCpName] = useState('');
  const [resultCode, setResultCode] = useState('ALL');
  const [path, setPath] = useState('');
  let columns = [
    {
      title: 'Country',
      field: 'cntryCode',
      width: '13%',
    },
    {
      title: 'CP',
      field: 'cpName',
      width: '13%',
    },
    {
      title: 'File Name',
      align: 'center',
      field: 'rssPath',
      width: 'auto',
    },
    {
      title: 'Date',
      field: 'crtDate',
      width: '13%',
    },
    {
      title: 'Result',
      field: 'resultCode',
      width: '13%',
    },
    {
      title: 'Reason',
      field: 'resultMsg',
      width: '13%',
    },
  ];
  const onChangRowPerPage = pageSize => {
    let countryCode = country.selectKey === 'ALL' ? '' : country.selectKey;
    let settingCode = resultCode === 'ALL' ? '' : resultCode;
    // let settingType = type == 'ALL' ? '' : type;
    getRssLog({
      page: page,
      size: pageSize,
      sortDir: 'DESC',
      cntryCode: countryCode,
      cpName: cp,
      type: '',
      rssPath: path,
      resultCode: settingCode,
    });
  };
  const settingPage = props => {
    return <TablePagination {...props} count={totalElements} page={page} rowsPerPage={size} onChangePage={(e, page) => onChangePage(page)} />;
  };

  const onChangePage = no => {
    let countryCode = country.selectKey === 'ALL' ? '' : country.selectKey;
    let settingCode = resultCode === 'ALL' ? '' : resultCode;
    // let settingType = type === 'ALL' ? '' : type;
    getRssLog({
      page: no,
      size: size,
      sortDir: 'DESC',
      cntryCode: countryCode,
      cpName: cp,
      type: '',
      rssPath: path,
      resultCode: settingCode,
      param: 'onChange',
    });
  };
  const callLogList = () => {
    let countryCode = country.selectValue === 'ALL' ? '' : country.selectValue;
    let settingCode = resultCode === 'ALL' ? '' : resultCode;
    // let settingType = type === 'ALL' ? '' : type;
    getRssLog({
      page: page,
      size: size,
      sortDir: 'DESC',
      cntryCode: countryCode,
      cpName: cp,
      type: '',
      rssPath: path,
      resultCode: settingCode,
    });
  };
  const handleCpName = value => setCpName(value);
  //
  return (
    <div>
      <RssLogFilter country={country} cp={cp} path={path} resultCode={resultCode} handleResultCode={setResultCode} handlePath={setPath} handleCntry={setCntry} handleCpName={handleCpName} getRssLog={callLogList} />
      <div className="tbl_wrap">
        <MaterialTable
          title={''}
          columns={columns}
          data={feedlist}
          options={{
            totalElements: totalElements,
            pageSize: size,
            page: page,
            rowsPerPage: size,
            pageSizeOptions: [5, 10, 20],
            rowStyle: { backgroundColor: '#fff' },
          }}
          components={{
            Pagination: props => settingPage(props),
          }}
          onChangeRowsPerPage={size => onChangRowPerPage(size)}
        />
      </div>
    </div>
  );
}
////////////////////////////////////////////////////////////////////////////////
// RSSCollectionLogs.propTypes
////////////////////////////////////////////////////////////////////////////////
RSSCollectionLogs.propTypes = {
  feedlist: PropTypes.array,
  getRssLog: PropTypes.func,
  size: PropTypes.number,
  page: PropTypes.number,
  totalElements: PropTypes.number,
};
////////////////////////////////////////////////////////////////////////////////
// mapStateToProps
////////////////////////////////////////////////////////////////////////////////
const mapStateToProps = state => ({
  feedlist: state.rssLog.feedlist,
  size: state.rssLog.size,
  totalElements: state.rssLog.totalElements,
  page: state.rssLog.page,
  countries: state.rssLog.countries,
});
////////////////////////////////////////////////////////////////////////////////
// mapDispatchToProps
////////////////////////////////////////////////////////////////////////////////
const mapDispatchToProps = dispatch => ({
  getRssLog: param => dispatch(getRssLog(param)),
});
////////////////////////////////////////////////////////////////////////////////
// export
////////////////////////////////////////////////////////////////////////////////
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RSSCollectionLogs));
