import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
export function ScheduleFilter({ bizArea, cntry, handleChangeCntry, cp, handleChangeCp, channels, channel, handleChangeChannel, date, handleChangeDate, handleClickToday }) {
  let cntryList;
  let cps;
  if (cp) {
    //when login with Admin
    cntryList = bizArea
      ? bizArea.area.map(item => (
          <MenuItem key={item.countryCode} value={item.countryCode}>
            {item.countryCode}
          </MenuItem>
        ))
      : null;
    cps = bizArea ? bizArea.area.find(item => item.countryCode === cntry) : null;
  } else {
    //when login with CP Member
    cntryList = bizArea
      ? bizArea.area.map(item => (
          <MenuItem key={item.countryCode} value={item.countryCode}>
            {item.countryCode}
          </MenuItem>
        ))
      : null;
  }

  return (
    <>
      <div className="filter_area">
        <FormControl variant="outlined">
          <InputLabel>Country</InputLabel>
          <Select value={cntry} onChange={handleChangeCntry}>
            {cntryList}
          </Select>
        </FormControl>
        {cp && (
          <FormControl variant="outlined">
            <InputLabel>CP</InputLabel>
            <Select value={cp} onChange={handleChangeCp}>
              {cps && cps.providers
                ? ['All', ...cps.providers].map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        )}
        {channel && (
          <FormControl variant="outlined">
            <InputLabel>Channel name</InputLabel>
            <Select value={channel} onChange={handleChangeChannel}>
              {channels.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar //date 선택창 위에 날짜추가로 표시하지않음
            variant="inline" //date 선택창 위치가 바로밑으로 오게함
            inputVariant="outlined" //input창 전체가 테두리있는 모양이됨
            format="MM/dd/yyyy"
            //margin="normal"
            label="Date"
            value={date}
            onChange={handleChangeDate}
          />
        </MuiPickersUtilsProvider>
        <FormControl variant="outlined" className="filter_inner_today">
          <Button variant="contained" className={'btn_color2'} onClick={handleClickToday}>
            Today
          </Button>
        </FormControl>
      </div>
    </>
  );
}

ScheduleFilter.propTypes = {
  bizArea: PropTypes.object,
  cntry: PropTypes.string,
  handleChangeCntry: PropTypes.func.isRequired,
  cp: PropTypes.string,
  handleChangeCp: PropTypes.func,
  channels: PropTypes.array,
  channel: PropTypes.string,
  handleChangeChannel: PropTypes.func,
  date: PropTypes.object,
  handleChangeDate: PropTypes.func,
  handleClickToday: PropTypes.func.isRequired,
};

// const mapStateToProps = (state) => ({
//   cntryCpResponse: state.cntry.countries,
// });
// const mapDispatchToProps = (dispatch) => ({
//   getProvidersAdminAction() {
//     dispatch(getProvidersAdminAction());
//   },
// });
export default ScheduleFilter;
