import { CHART_COLORS } from '../../common/ChartConsts'

export const service_history_chartData = {
  daily_services_error_occurrence: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 0,
          ticks: {
            stepSize: 10,
          },
        },
      },
    },
  },
  daily_services_error_accumulated: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (value) => `${value}%`,
          },
        },
      },
    },
  },
  daily_services_error_accumulated2: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (value) => `${value}%`,
          },
        },
      },
    },
  },
}

export const service_issue_countData = {
  label: 'service',
  data: [],
  fill: false,
  pointRadius: 3,
  backgroundColor: `${CHART_COLORS.PINK}`,
  borderColor: `${CHART_COLORS.PINK}`,
  pointBorderColor: '#FFFFFF',
  pointBackgroundColor: '#85DDDB',
  pointBorderWidth: 2,
  order: 2,
}

export const service_issue_resolvedRateData = {
  label: 'service',
  data: [],
  fill: false,
  pointRadius: 3,
  backgroundColor: `${CHART_COLORS.PINK}`,
  borderColor: `${CHART_COLORS.PINK}`,
  pointBorderColor: '#FFFFFF',
  pointBackgroundColor: '#85DDDB',
  pointBorderWidth: 2,
  order: 2,
}

export const service_issue_accResolvedRateData = {
  label: 'service',
  data: [],
  fill: false,
  pointRadius: 3,
  backgroundColor: `${CHART_COLORS.PINK}`,
  borderColor: `${CHART_COLORS.PINK}`,
  pointBorderColor: '#FFFFFF',
  pointBackgroundColor: '#85DDDB',
  pointBorderWidth: 2,
  order: 2,
}

export const issue_services_history_total_data = {
  daily_services_total_error_occurrence: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 0,
          ticks: {
            stepSize: 10,
            callback: function (label) {
              return `${this.getLabelForValue(label)}  `
            },
          },
        },
      },
    },
  },
  daily_services_total_error_resolution: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (value) => `${value}%`,
          },
        },
      },
    },
  },

  daily_services_total_error_cumulative_resolution: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (value) => `${value}%`,
          },
        },
      },
    },
  },
}
