//import * as actions from '../../actions/actionTypes';

const initialState = {
  tabAddPopUp: false,
};

export default function popUp(state = initialState, action) {
  switch (action.type) {
    case 'TAB_ADD_OPEN':
      return { ...state, tabAddPopUp: true };
    case 'TAB_ADD_CLOSE':
      return { ...state, tabAddPopUp: false };
    default:
      return state;
  }
}
