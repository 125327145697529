import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Typography } from '@material-ui/core';
import {FormControl,InputLabel,Select,MenuItem} from '@material-ui/core';

function Region({ regionList }) {
  const [deviceType, setDeviceType] = React.useState('tv');
  return (
    <div>
      <React.Fragment>
        <div className="filter_area">
          <FormControl variant="outlined">
            <InputLabel /*id="Region"없어도 되는듯*/>Device Type</InputLabel>
            <Select value={deviceType} onChange={(e)=>setDeviceType(e.target.value)}>
              {regionList.map(n => (
                <MenuItem key={n.deviceType} value={n.deviceType}>
                  {n.deviceType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={'tbl_wrap'}>
          <MaterialTable
            title=" " //없으면 Table Title이 default title로 나옴
            columns={[
              {
                title: 'Region',
                field: 'region',
              },
              {
                title: 'Country List',
                render: row =>
                  <Typography component="span" variant="h6">{row.cntry.join(', ')}</Typography>
              },
            ]}
            data={(regionList.find(n=>n.deviceType===deviceType)||{list:[]}).list}
            options={{
              paging: false,
              search: false,
              rowStyle: { backgroundColor: '#fff' },
            }}
          />
        </div>
      </React.Fragment>
    </div>
  );
}
Region.propTypes = {
  regionList: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  regionList: state.homeApp.regionList,
});
export default connect(mapStateToProps)(withRouter(Region));