import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FeedInfo from './FeedInfo';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import SelectedFeedNChannelMap from './SelectedFeedNChannelMap';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { getCntryInfo } from '../../../actions/chMappingFilterActions';
import { getFeedInfoList, setCollect, getSourceChannelList, setChannelMapIdList, getFeedDataByCountry, getChannelMapInfo, setHistoryChannelMapIdList } from '../../../actions/channelMappingActions';
import { ChannelMappingFilter } from '../../../common/filter/ChannelMappingFilter';

function ChannelMapping({ getSourceChannelList, getFeedInfoList, setCollect, feeds, cntryInfo, getCntryInfo, setChannelMapIdList, getFeedDataByCountry, getChannelMapInfo, setHistoryChannelMapIdList }) {
  const [system, setSystem] = useState('cdp');
  const [cntry, setCntry] = useState('US');
  const [cp, setCp] = useState({ cpCode: 'ALL', cpName: 'ALL' });
  const [fetch, setFetching] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '' });
  const ric = (cntryInfo.find(n => n.cntry === cntry) || { ric: '' }).ric;
  const [cpChannelDataViewRow, SetCpChannelDataViewRow] = useState();

  useEffect(() => {
    if (!cntryInfo.length) getCntryInfo();
  }, []);

  useEffect(() => {
    getFeedInfoList(system, cntry, cp.cpCode);
    setChannelMapIdList(system, cntry, ric);
    SetCpChannelDataViewRow('');
  }, [getFeedInfoList, system, cntry, cp]);

  const closeAlert = () => {
    setAlert({
      open: false,
      message: '',
    });
  };
  const handleCp = e => {
    let cpName = e.target.value;
    setCp({
      cpCode: cpName,
      cpName: cpName,
    });
  };
  const clickView = rowData => {
    SetCpChannelDataViewRow(rowData);
    getSourceChannelList(system, feeds, rowData);
  };
  const showLoadingIndicator = isShow => {
    setFetching(isShow);
  };
  const clickCollect = rowData => {
    showLoadingIndicator(true);
    setCollect(feeds, cntry, rowData.cpName, result => setCollectResult(result));
  };
  const setCollectResult = result => {
    setAlert({
      open: true,
      message: result ? 'Collected successfully.' : 'Failed to collect.',
    });
    showLoadingIndicator(false);
  };

  const getAllProvidersFeedDataByCountry = () => {
    getFeedDataByCountry(cntry);
  };

  const getAllChannelMapInfo = () => {
    getChannelMapInfo();
  };

  return (
    <div>
      <ChannelMappingFilter system={system} handleSystem={e => setSystem(e.target.value)} cntry={cntry} cntryInfo={cntryInfo} cp={cp} /*cplist={cplist}*/ handleCountry={e => setCntry(e.target.value)} handleCp={handleCp} />
      <div className="tbl_wrap feed_info_tbl tbl_head_fixed tbl_mapping">
        <FeedInfo system={system} feeds={feeds} clickCollect={clickCollect} clickView={clickView} downloadFeed={getAllProvidersFeedDataByCountry} downloadChannelMapInfo={getAllChannelMapInfo} />
      </div>
      <div className="channel_mapping_wrap" id="channel_mapping_wrap">
        <SelectedFeedNChannelMap system={system} ric={ric} cntry={cntry} cp={cp} showLoadingIndicator={showLoadingIndicator} cpChannelDataViewRow={cpChannelDataViewRow} cntryInfo={cntryInfo} />
      </div>
      <Dialog open={alert.open} onClose={closeAlert} aria-labelledby="confirm-dialog">
        <div className="pop_size_small">
          <p className="pop_comment">{alert.message}</p>
          <DialogActions>
            <div className="btn_set_wrap alC">
              <Button color="primary" onClick={closeAlert} className="btn_color2">
                OK
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
      {fetch && (
        <div className="loading_wrap" style={{ position: 'absolute', top: 300, left: 0, right: 0, zIndex: 999 }}>
          <LoadingIndicator />
        </div>
      )}
      {/* {detailVisible ? <ChannelFeed open={detailVisible} close={handleCloseDetail} histParam={histParam} setHistParam={setHistParam} /> : <React.Fragment />} */}
    </div>
  );
}
ChannelMapping.propTypes = {
  fetching: PropTypes.bool.isRequired,
  getFeedInfoList: PropTypes.func.isRequired,
  setCollect: PropTypes.func.isRequired,
  getSourceChannelList: PropTypes.func.isRequired,
  users: PropTypes.object,
  sourceChannel: PropTypes.object,
  feeds: PropTypes.array,
  settingViewData: PropTypes.func,
  //cplist: PropTypes.array,
  cntryInfo: PropTypes.array.isRequired,
  getCntryInfo: PropTypes.func.isRequired,
  setChannelMapIdList: PropTypes.func.isRequired,
  getFeedDataByCountry: PropTypes.func.isRequired,
  getChannelMapInfo: PropTypes.func.isRequired,
  setHistoryChannelMapIdList: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  users: state.currentUser,
  channels: state.channelMapping.channels,
  fetching: state.channelMapping.fetching,
  feeds: state.channelMapping.feeds,
  sourceChannel: state.channelMapping.sourceChannel,
  //cplist: state.cplist.cplist,
  confMap: state.channelMapping.confMap,
  cntryInfo: state.countries.cntryInfo,
});
const mapDispatchToProps = dispatch => ({
  getFeedInfoList: (system, country, cp) => dispatch(getFeedInfoList(system, country, cp)),
  setCollect: (feeds, cntry, cp, callBack) => dispatch(setCollect(feeds, cntry, cp, callBack)),
  getSourceChannelList: (system, feeds, rowData) => dispatch(getSourceChannelList(system, feeds, rowData)),
  getCntryInfo: (cntry, channelIds) => dispatch(getCntryInfo(cntry, channelIds)),
  setChannelMapIdList: (system, cntry, ric) => dispatch(setChannelMapIdList(system, cntry, ric)),
  getFeedDataByCountry: cntry => dispatch(getFeedDataByCountry(cntry)),
  getChannelMapInfo: () => dispatch(getChannelMapInfo()),
  setHistoryChannelMapIdList: (system, cntry, ric) => dispatch(setHistoryChannelMapIdList(system, cntry, ric)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChannelMapping));
