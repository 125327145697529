import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, Button, DialogContent, DialogTitle, TableContainer, Switch, AppBar, Tab, Table, TableCell, TableHead, TableRow, Tabs } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import QuillEditor from '../../../common/QuillEditor/QuillEditor';

const useStyles = makeStyles(() => ({
  notificationDispaly: {
    fontWeight: 'normal',
  },
  contentText: {
    verticalAlign: 'baseline',
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function NotificationView({ handleClose, data }) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [htmlContent, setHtmlContent] = useState("");
  const quillRef = useRef();
  const api = "";
  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        {`View Notification`}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={'pop_body'}>
        <TableContainer className={'tbl_wrap'}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="15%">title</TableCell>
                <TableCell colSpan="5" className={classes.notificationDispaly}>
                  {data.title}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Version</TableCell>
                <TableCell colSpan="2" className={classes.notificationDispaly}>
                  {data.version}
                </TableCell>
                <TableCell>Device Type</TableCell>
                <TableCell colSpan="1" className={classes.notificationDispaly}>
                  {data.deviceType}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Channel Map</TableCell>
                <TableCell colSpan="5" className={classes.notificationDispaly}>
                  {data.channelMapList.join(', ')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Country</TableCell>
                <TableCell colSpan="2" className={classes.notificationDispaly}>
                  {data.countryCodes.join(',')}
                </TableCell>
                <TableCell>Enable</TableCell>
                <TableCell colSpan="2" className={classes.notificationDispaly}>
                  <Switch checked={data.enable === 'Y'} inputProps={{ 'aria-label': 'secondary checkbox' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> Start date</TableCell>
                <TableCell colSpan="2" className={classes.notificationDispaly}>
                  {data.startDate}
                </TableCell>
                <TableCell>End Date</TableCell>
                <TableCell colSpan="2" className={classes.notificationDispaly}>
                  {data.endDate}
                </TableCell>
              </TableRow>
              {(data.type === 'image' || data.type === 'textImage') && (
                <TableRow>
                  <TableCell> Image Url</TableCell>
                  <TableCell colSpan="2" className={classes.notificationDispaly} style={{ maxWidth: '200px' }}>
                    {data.imageUrl}
                  </TableCell>
                  <TableCell> Deeplink Url</TableCell>
                  <TableCell colSpan="2" className={classes.notificationDispaly}>
                    {data.deeplinkUrl}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell className={classes.contentText}>Languages
                  <Fragment>
                      <AppBar position="static" id="scheduleTabHead">
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto" orientation="vertical">
                          {data.notificationMultiLangList.map((langContent, i) => (
                            <Tab key={langContent.langCode} label={langContent.langCode} {...a11yProps(i)} />
                          ))}
                        </Tabs>
                      </AppBar>
                  </Fragment>
                </TableCell>
                <TableCell colSpan="5" className={classes.termsPolicyDispaly}>
                  {data.notificationMultiLangList && (
                    <Fragment>
                      {data.notificationMultiLangList.map((lang, index) => (
                        <div key={index} role="tabpanel" hidden={tabValue !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
                          <div>
                            <b>Title:</b> {lang.title}
                          </div>
                          {((data.type === 'text' || data.type === 'textImage') && data.deviceType !== 'mobile') && (
                            <Fragment>
                              <b>Content:</b>
                              <div style={{ whiteSpace: 'pre-wrap' }}>{lang.content}</div>
                            </Fragment>
                          )}
                          {/* {<div dangerouslySetInnerHTML={{ __html: lang.description }} style={{ whiteSpace: 'pre-line' }} />} */}
                          {(data.deviceType === 'mobile') && (
                          <QuillEditor
                              quillRef={quillRef}
                              id={`content-${index}`}
                              htmlContent={lang.content}
                              setHtmlContent={setHtmlContent}
                              api={api}
                              readOnly={true}
                              />
                          )}
                        </div>
                      ))}
                    </Fragment>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <div className={'btn_set_wrap '}>
          <Button variant="contained" className={'btn_color1'} onClick={handleClose}>
            Close
          </Button>
        </div>
      </DialogContent>
    </Fragment>
  );
}

NotificationView.propTypes = {
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default connect()(NotificationView);
