
const initialState = [];
export const privacypolicies = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PRIVACY_POLICIES_DATA':
            return action.payload;
        case 'ADD_PRIVACY_POLICY_DATA':
            return [...state, action.payload];
        case 'UPDATE_PRIVACY_POLICY_DATA':
            const policies = state.slice();
            policies.splice(state.findIndex(x => x.idx === action.payload.idx), 1, action.payload);
            return [...policies];
        default:
            return state;
    }
};