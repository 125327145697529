import React from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';
import FeedInfoEdit from './FeedInfoEdit';
import { number } from 'prop-types';

function compareName(firstSPNames, secondSPNames) {
  const fstSpname = firstSPNames.spName.toUpperCase();
  const secondSPname = secondSPNames.spName.toUpperCase();
  let comparison = 0;
  if (fstSpname > secondSPname) {
    comparison = 1;
  } else if (fstSpname < secondSPname) {
    comparison = -1;
  }
  return comparison;
}
export default function FeedInfo({ system, clickCollect, clickView, feeds, downloadFeed, downloadChannelMapInfo }) {
  let spName = '';
  var count;
  var array = [];
  const spCp = new Map();
  feeds = feeds.sort(compareName);
  if (feeds.length > 0) {
    count = feeds.length;
    for (let i = 0; i < feeds.length; i++) {
      if (spCp && spCp.has(feeds[i]['spName'])) {
        spCp.set(feeds[i]['spName'], spCp.get(feeds[i]['spName']) + 1);
      } else {
        spCp.set(feeds[i]['spName'], 1);
      }
    }
  }
  let feedInfoColumns =
    [
      {
        title: 'Country',
        field: 'cntryCode',
        //width: '9%',
        // headerStyle: {
        //   textAlign: 'center',
        // },
        // cellStyle: {
        //   textAlign: 'center',
        // },
        render: rowData => rowData.cntryCode,
      },
      {
        title: 'CP',
        field: 'spName',
        render: rowData => (count == rowData.length || rowData.spName != spName ? rowData.spName : ''),
        headerStyle: {
          borderRightWidth: 0,
        },
        cellStyle: (e, rowData) => {
          spName = rowData.spName;
          count = count - 1;
          if (count == 0) {
            count = feeds.length;
            spName = '';
          }
          if (array.includes(rowData.spName) && spCp && spCp.get(rowData.spName) > 1) {
            return { borderRight: '1px solid #eee', borderBottom: '0px' };
          } else if (!array.includes(rowData.spName) && spCp && spCp.get(rowData.spName) > 1) {
            array.push(spName);
            return { borderTop: '1px solid #eee', borderRight: '1px solid #eee', borderBottom: '0px' };
          } else {
            array.push(spName);
            return { borderTop: '1px solid #eee' };
          }
        },
      },
      {
        render: rowData => (
          <tr>
            <td> {spCp && spCp.get(rowData.spName) > 1 ? rowData.cpName : ''}</td>
          </tr>
        ),
      },
      {
        title: 'CP Name',
        field: 'cpFullName',
      },
      {
        title: 'Rcv Date',
        field: 'rcvDate',
      },
      {
        title: 'Channel List',
        field: 'channellist',
        render: rowData => (
          <Button className="btn_size_tbl btn_color3" variant="contained" onClick={() => clickView(rowData)}>
            View
          </Button>
        ),
      },
    ];

  return (
    <MaterialTable
      title="Feed Info"
      columns={feedInfoColumns}
      data={feeds}
      options={{
        search: false,
        paging: false,
        //doubleHorizontalScroll: true,
        maxBodyHeight: '570px',
        //minBodyHeight: '271px',
        rowStyle: { backgroundColor: '#fff' },
        cellStyle: { borderRight: '1px solid #eee' },
        headerStyle: { borderRight: '1px solid #eee' },
      }}
      actions={[
        {
          icon: () => (
            <Button className="btn_size_tbl download_feed_btn btn_color2" variant="contained">
              Download Channel Map
            </Button>
          ),
          tooltip: `Download`,
          isFreeAction: true,
          onClick: () => downloadChannelMapInfo(),
        },
        {
          icon: () => (
            <Button className="btn_size_tbl download_feed_btn btn_color2" variant="contained">
              Download All CP's Feed
            </Button>
          ),
          tooltip: `Download All CP's Feed`,
          isFreeAction: true,
          onClick: () => downloadFeed(),
        },
      ]}
    />
  );
}

FeedInfo.propTypes = {
  system: PropTypes.string.isRequired,
  clickCollect: PropTypes.func.isRequired,
  clickView: PropTypes.func.isRequired,
  feeds: PropTypes.array.isRequired,
  downloadFeed: PropTypes.func.isRequired,
  downloadChannelMapInfo: PropTypes.func.isRequired,
};
