import React, { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const ContentFilter = (props) => {
  const { providerList, callbackContentFilter } = props;

  const [contentType, setContentType] = useState(['live', 'movie']);
  const [paramContentType, setParamContentType] = useState('live');
  const [paramProvider, setParamProvider] = useState('');
  const [paramChannelId, setParamChannelId] = useState('');
  const [paramChannelName, setParamChannelName] = useState('');

  const onSearchClick = () => {
    const contentType = paramContentType ? paramContentType : 'live';
    const providerName = paramProvider ? paramProvider : 'ALL';

    const contentFilterResult = {
      contentType: contentType,
      providerName: providerName,
      contentId: paramChannelId,
      contentName: paramChannelName
    };

    callbackContentFilter(contentFilterResult);
  };

  return (
    <React.Fragment>
      <div className="filter_area">
        <FormControl variant="outlined">
          <InputLabel id="Type">Type</InputLabel>
          <Select labelId="Type" id="Type" value={paramContentType}
            onChange={e => setParamContentType(e.target.value)}>
            {contentType.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="CP">CP</InputLabel>
          <Select labelId="CP" id="CP" value={paramProvider ? paramProvider : 'ALL'}
            onChange={e => setParamProvider(e.target.value)} >
            {['ALL',  ...providerList].map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="id"
          value={paramChannelId}
          onChange={e => setParamChannelId(e.target.value)}
        />
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="name"
          value={paramChannelName}
          onChange={e => setParamChannelName(e.target.value)}
        />
        <div className="btn_set_wrap search_wrap live_tv">
          <Button variant="contained" className={'btn_color2'} onClick={onSearchClick}>
            Search
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContentFilter;