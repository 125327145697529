import React from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import { getCategoryRuleAction, getTabCategoryAction, getCountAction } from '../../../actions/homeAppActions';
import { request } from '../../../util/request';
import { channelGenre, programGenre, krChannelGenre } from '../../../constants/genre';
import CategoryTable from '../CategoryTable';
import Display from './Display';
import CheckDialog from './CheckDialog';
import { ThemeProvider } from '@material-ui/styles';
import InfoIconOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { getRegionProvidersAdminAction } from '../../../actions/providerActions';
import { Dialog, DialogActions } from '@material-ui/core';
import { getHeaders } from '../../../util/actionUtil';
import { API_URL, TABCATEGORY_AUTO_LIMIT_COUNT } from '../../../constants';

const channelGenreName = channelGenre.map(n => n.name);
const krChannelGenreName = krChannelGenre.map(n => n.name);

function Auto({ id, history, countKeyword, categoryRule, getCategoryRuleAction, tabCategory, getTabCategoryAction, param, regionList, getCountAction, cplist, getRegionProvidersAdminAction, regionProvider }) {
  const [cat, setCat] = React.useState(tabCategory.find(n => n.categoryId == id) || {}); //n.categoryId is integer, type is different so using ==
  const [cntryList, setCntryList] = React.useState([]);
  const [cntryCode, setCntryCode] = React.useState('');
  //const [allCpList, setAllCpList] = React.useState([]);
  const [cp, setCp] = React.useState([{ name: 'All', value: '' }]);
  const [criteria, setCriteria] = React.useState([{ criterion: '', criterionDetail: '' }]);
  const [ordering, setOrdering] = React.useState('');
  const [result, setResult] = React.useState([]);
  const [includeFlag, setIncludeFlag] = React.useState('');
  const [ruleList, setRuleList] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [type, setType] = React.useState('');
  const [genreResultList, setGenreResultList] = React.useState([]);
  const [vodDispInterval, setVodDispInterval] = React.useState('');
  const [mappingType, setMappingType] = React.useState('');
  const [onSaveOpen, setOnSaveOpen] = React.useState(false);
  const [cpDetail, setCPDetails] = React.useState('All');
  const cpNameList = [...['All'], ...cpDetail];

  React.useEffect(() => {
    fetchGenre();
  }, [criteria]);

  React.useEffect(() => {
    const apiUrl = `/api/admin/providers`;
    fetch(`${API_URL}${apiUrl}`, {
      method: 'GET',
      headers: getHeaders(),
    }).then(res => res.json())
      .then(body => {
        const bodyFilter = body.list.map(data => data.providerId);
        setCPDetails(bodyFilter);
      });
  }, []);

  const fetchGenre = async () => {
    const newCpList = cp.map(n => n.name).join(',');
    let criteriaTxt = criteria.map(m => (m.criterion == 'channelGenre' ? (cat.region === 'KR' ? 'channel_kr' : 'channel') : 'program'));
    if (newCpList && criteriaTxt) {
      if (criteriaTxt != '' && newCpList) {
        const genData = await request(`/api/genre/${criteriaTxt}/${newCpList}?contentType=${cat.contentType}&region=${cat.region}&countries=${cntryList}`, 'GET', null, true);
        setGenreResultList(genData);
      }
    }
  };

  let data = [];
  if (cat.contentType) {
    const contentTypes = cat.contentType && cat.contentType.split(',');
    if (contentTypes && contentTypes.includes('live')) {
      data.push({ key: 'channelGenre', value: genreResultList ? (cat.region === 'KR' ? distinctGenre(genreResultList.filter(n => n.genreType === 'channel_kr').map(n => n.genreName)) : distinctGenre(genreResultList.filter(n => n.genreType === 'channel').map(n => n.genreName))) : [] })
    }

    if (contentTypes && isProgramGenre(contentTypes)) {
      data.push({ key: 'programGenre', value: genreResultList ? distinctGenre(genreResultList.filter(n => n.genreType === 'program').map(n => n.genreName)) : [] })
    }

    if (contentTypes && isKeyword(contentTypes)) {
      const cpNames = cpNameList.filter(c => c !== "All").map(c => c);
      const keywordData = countKeyword
        .filter(n => contentTypes.includes(n.type) && (cp.map(c => c.name === "All" ? cpNames : [c.name])).some(c => c.includes(n.cp)))
        .map(n => n.keyword)
        .filter((value, index, self) => self.indexOf(value) === index);
      data.push({ key: 'keyword', value: keywordData });
    }
    if (contentTypes && isSourceId(contentTypes)) {
      data.push({ key: 'sourceId', value: ruleList.filter(n => n.type === 'sourceId').map(n => n.keyword) });
    }
  }

  function distinctGenre(genreResultList) {
    return [...new Set(genreResultList)];
  }

  function isProgramGenre(contentTypes) {
    if (contentTypes && contentTypes.includes('movie')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('tvshow')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('tvshow-series')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('livestream')) {
      return true;
    }
    return false;
  }

  function isKeyword(contentTypes) {
    if (contentTypes && contentTypes.includes('movie')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('tvshow')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('live')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('livestream')) {
      return true;
    }
    return false;
  }

  function isSourceId(contentTypes) {
    if (contentTypes && contentTypes.includes('movie')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('tvshow')) {
      return true;
    }
    if (contentTypes && contentTypes.includes('livestream')) {
      return true;
    }
    return false;
  }

  const fetchCategoryDetail = async () => {
    const { categoryName, categoryOrder, contentType, mappingType, orderType, cpList, includeExclusiveFlag, vodDispInterval } = await request(`/api/category/${id}`, 'GET', null, true);
    const list = (cpList || '').split(',').map(n => ({ name: n === 'All' ? n : n.slice(0, n.indexOf(':')), value: n.slice(n.indexOf(':') + 1) }));
    setCp(list);
    setOrdering(orderType);
    setIncludeFlag(includeExclusiveFlag);
    setVodDispInterval(vodDispInterval)
    setMappingType(mappingType)
  };

  React.useEffect(() => {
    getRegionProvidersAdminAction(param.region);
  }, []);

  const fetchRule = async () => {
    let cpList;
    if (cp === 'All') {
      cpList = cp.map(n => n.name);
    } else {
      cpList = cp.map(n => n.name).join(',');
    }
    const result = await request(`/api/ruleInfo?cp=${cpList}`, 'GET', null, true);
    setRuleList(result);
  };

  React.useEffect(() => {
    fetchRule();
    setGenreResultList([]);
    fetchGenre();
  }, [cp]);

  React.useEffect(() => {
    if (!tabCategory.length) getTabCategoryAction(param.deviceType, param.region, param.platformVersion, param.status.charAt(0));
    fetchCategoryDetail();
    getCategoryRuleAction(id);
  }, [id]);

  React.useEffect(() => {
    if (categoryRule.length)
      setCriteria(
        categoryRule.map(n => {
          const { criterion, criterionDetail } = n;
          const newCategoryRule = { criterion, criterionDetail };
          return newCategoryRule;
        }),
      );
    else setCriteria([{ criterion: '', criterionDetail: '' }]);
  }, [categoryRule]);
  React.useEffect(() => {
    setCat(tabCategory.find(n => n.categoryId == id) || {});
  }, [tabCategory]);
  React.useEffect(() => {
    setCntryList(((regionList.find(n => n.deviceType === param.deviceType) || { list: [] }).list.find(m => m.region === param.region) || { cntry: [''] }).cntry);
  }, [regionList]);
  React.useEffect(() => {
    setCntryCode(cntryList[0]);
  }, [cntryList]);
  React.useEffect(() => {
    if (cntryList !== undefined) {
      getCountAction('keyword', cntryList);
      getCountAction('genre', cntryList);
    }
  }, [cntryList]);

  const clickSearch = async event => {
    event.preventDefault();
    if (cp.find(n => { return n.name !== "All" && !n.value })) alert('Some cp count is empty!');
    else if (criteria.find(n => !n.criterion)) alert('Some criterion is empty!');
    else {
      let param2 = `&type=${cat.contentType}`;
      criteria.map(m => {
        if (m.criterion === 'keyword') {
          param2 += `&keyword=${m.criterionDetail}`;
        }
        if (m.criterion === 'channelGenre') {
          if (cat.region === 'KR') {
            param2 += `&channelGenre=${m.criterionDetail === '' ? '' : krChannelGenre.find(n => n.name === m.criterionDetail).code}`;
          } else {
            param2 += `&channelGenre=${m.criterionDetail === '' ? '' : channelGenre.find(n => n.name === m.criterionDetail).code}`;
          }
        }
        if (m.criterion === 'programGenre') {
          param2 += `&programGenre=${programGenre.indexOf(m.criterionDetail)}`;
        }
        if (m.criterion === 'sourceId') {
          param2 += `&sourceId=${m.criterionDetail}`;
        }
      });
      if (includeFlag === 'Y') param2 += `&includeFlag=1`;
      let newCpList = "";
      if (cp[0].name === 'All') {
        newCpList = cp.map(n => `${n.name}`);
      } else {
        newCpList = cp.map(n => `${n.name}:${n.value}`).join(',');
      }
      const result = await request(`/api/autoSearch?cntry=${cntryCode}&cp=${newCpList}${param2}&order=${ordering}&status=${param.status.charAt(0)}`, 'GET', null, true);
      setResult(result);
    }
  };

  const clickApply = async event => {
    event.preventDefault();
    if (!result.length) alert('No search result ! Click search button first');
    else if (result.length < 5 && !cat.contentType.includes('livestream')) alert('The search result count is less then 5 ! Change the rule !');
    else {
      alert('Are you sure to apply the search result?');
      const newCategoryResult = result.slice(0, TABCATEGORY_AUTO_LIMIT_COUNT).map((n, i) => {
        let categoryResult = {};
        const { cp_idx, content_id, type } = n;
        categoryResult.categoryIdx = id;
        categoryResult.countryCode = cntryCode;
        categoryResult.providerIdx = cp_idx;
        categoryResult.contentType = type;
        categoryResult.contentId = content_id;
        categoryResult.resultOrder = i + 1;
        return categoryResult;
      });
      const isSuccess = await request(`/api/category/${id}/result?cntry=${cntryCode}`, 'PATCH', newCategoryResult);
      if (isSuccess) alert('Successfully saved !');
    }
  };

  const clickSave = async event => {
    event.preventDefault();

    let channelType = false;
    let vodType = false;
    let validationFail = false;

    const types = cat.contentType.split(',');
    types.map(type => {
      if (type === 'live') channelType = true;
      if (type === 'movie') vodType = true;
      if (type === 'tvshow') vodType = true;
      if (type === 'tvshow-series') vodType = true;
      if (type === 'livestream') vodType = true;
    });

    if (criteria.length > 2) {
      alert('You cannot set more than 2 criteria.');
      return;
    } else if (criteria.length === 2) {
      if (types.length === 1) {
        alert('You cannot set two criteria for one content type.');
        return;
      } else if (channelType && vodType) {
        criteria.map(rule => {
          if (rule.criterion === 'keyword') {
            alert('If criteria is a keyword, choose one criterion.');
            validationFail = true;
          } else if (rule.criterion === 'sourceId') {
            alert('If criteria is a sourceId, choose one criterion.');
            validationFail = true;
          } else if (criteria[0].criterion === criteria[1].criterion) {
            alert('You cannot set the same criteria.');
            validationFail = true;
          }
        });
      } else if (!channelType && vodType) {
        alert('Please set one criteria.');
        return;
      }
    } else if (criteria.length === 1) {
      if (criteria[0].criterion === 'channelGenre' && vodType) {
        alert('Please select a program genre.');
        return;
      } else if (criteria[0].criterion === 'programGenre' && channelType) {
        alert('Please select a channel genre.');
        return;
      }
    }

    if (mappingType === 'position_auto'  &&  isNaN(vodDispInterval)) {
      alert('VOD display interval should be between 2 and 400!')
      return;
    }

    if ( mappingType === 'position_auto' && (vodDispInterval < 2 || vodDispInterval > 400)) {
      alert('VOD display interval should be between 2 and 400!')
      return;
    }

    if (validationFail) return;
    else if (cp.find(cpData => { return cpData.name !== "All" && !cpData.value })) alert('Some cp count is empty!');
    else if (criteria.find(n => !n.criterion)) alert('Some criterion is empty!');
    else {
      setOnSaveOpen(true);
    }
  };

  const onConfirm = async e => {
    const newCpList = cp.map(n => n.name === 'All' ? n.name : `${n.name}:${n.value}`).join(',');
      const rule = [];
      criteria.map(element => {
        if (element.criterion === 'channelGenre') {
          rule.push({ ...element, criterionDetailCode: cat.region === 'KR' ? (element.criterionDetail === '' ? '' : krChannelGenre.find(n => n.name === element.criterionDetail).code) : element.criterionDetail === '' ? '' : channelGenre.find(n => n.name === element.criterionDetail).code });
        } else if (element.criterion === 'programGenre') {
          rule.push({ ...element, criterionDetailCode: programGenre.indexOf(element.criterionDetail) });
        } else {
          rule.push({ ...element });
        }
      });
      let isSuccess = await request(`/api/category/${id}`, 'PATCH', { cpList: newCpList, orderType: ordering, includeExclusiveFlag: includeFlag, resultUpdateFlag: 'Y', mappingType: cat.mappingType, vodDispInterval: vodDispInterval });
      if (isSuccess) {
        isSuccess = await request(`/api/category/${id}/rule`, 'PATCH', rule);
        if (isSuccess) alert('Successfully saved !');
      }
    setOnSaveOpen(false);
  };
  return (
    <div>
      <Display cat={cat} />
      <CpFilter
        cpList={cpNameList}
        cp={cp}
        setEachCp={(n, i, type) => {
          let newData = cp.map(n => n); /////todo
          newData[i][type] = n;
          setCp(newData);
        }}
        setCp={setCp}
      />
      <CriteriaFilter
        data={data}
        criteria={criteria}
        setEachCriteria={(n, i, type) => {
          let newData = criteria.map(n => n); /////todo
          newData[i][type] = n;
          setCriteria(newData);
        }}
        setCriteria={setCriteria}
        ordering={ordering}
        onChangeOrdering={e => {
          setOrdering(e.target.value);
        }}
        includeFlag={includeFlag}
        onChangeIncludeFlag={e => {
          if (!e.target.value || e.target.value !== 'Y') setIncludeFlag('Y');
          else setIncludeFlag('N');
        }}
        vodDispInterval={vodDispInterval}
        onChangeVodDispInterval={e => {
          setVodDispInterval(e.target.value);
        }}
        mappingType={mappingType}
      />
      <div className="btn_set_wrap search_wrap">
        <span style={{ padding: '0 600px 0 0' }}>
          <FormControl variant="outlined" style={{ padding: '0 12px 0 0' }}>
            <InputLabel>Country</InputLabel>
            <Select
              value={cntryCode}
              style={{ height: '38px' }}
              onChange={e => {
                setCntryCode(e.target.value);
              }}>
              {cntryList.map(n => (
                <MenuItem key={n} value={n}>
                  {n}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" className={'btn_color2'} onClick={clickSearch}>
            Search
          </Button>
          <Button variant="contained" className={'btn_color2'} onClick={clickApply}>
            Apply
          </Button>
        </span>
        <Button variant="contained" className={'btn_color2'} onClick={clickSave}>
          Save
        </Button>
        <Button
          variant="contained"
          className={'btn_color2'}
          onClick={() => {
            setType('keyword');
            setDialogOpen(true);
          }}>
          Check Keyword
        </Button>
        <Button
          variant="contained"
          className={'btn_color2'}
          onClick={() => {
            setType('genre');
            setDialogOpen(true);
          }}>
          Check Genre
        </Button>
        <Button
          variant="contained"
          className={'btn_color2'}
          onClick={() => {
            history.push('/HOME-APP/tab');
          }}>
          Back
        </Button>
      </div>
      <div className={'tbl_wrap'}>
        <CategoryTable
          result={result}
          options={{
            pageSize: 30,
            pageSizeOptions: [15, 30, 50, 100],
          }}
        />
      </div>
      <CheckDialog
        dialogOpen={dialogOpen}
        closeDialog={() => {
          setDialogOpen(false);
        }}
        type={type}
        cntryCode={cntryCode}
      />
       <Dialog open={onSaveOpen} aria-labelledby="confirm-dialog">
          <div className="pop_size_small">
            <React.Fragment>
              <div className="ico_condition1"></div>
              <p className="pop_comment">Do you want to save the rule ?</p>
              <DialogActions>
                <div className="btn_set_wrap alC">
                  <Button color="primary" onClick={onConfirm} className="btn_color2">
                    OK
                  </Button>
                  <Button
                    autoFocus
                    color="primary"
                    onClick={() => setOnSaveOpen(false)}
                    className="btn_color1">
                    Cancel
                  </Button>
                </div>
              </DialogActions>
            </React.Fragment>
          </div>
        </Dialog>
    </div>
  );
}

Auto.propTypes = {};
const mapStateToProps = state => ({
  regionList: state.homeApp.regionList,
  param: state.homeApp.param,
  tabCategory: state.homeApp.tabCategory,
  categoryRule: state.homeApp.categoryRule,
  cplist: state.cplist.cplist,
  regionProvider: state.providers.regionProvider,
  countKeyword: state.homeApp.countKeyword,
});

const mapDispatchToProps = dispatch => ({
  getCategoryRuleAction(id) {
    dispatch(getCategoryRuleAction(id));
  },
  getTabCategoryAction(deviceType, region, platformVersion, status) {
    dispatch(getTabCategoryAction(deviceType, region, platformVersion, status));
  },
  getCountAction(type, cntryList) {
    dispatch(getCountAction(type, cntryList));
  },
  getRegionProvidersAdminAction(region) {
    dispatch(getRegionProvidersAdminAction(region));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auto));

export function CpFilter({ cpList, cp, setEachCp, setCp }) {

  const AllCPSave = (e) => {
    return e.target.value === 'All' ? setCp([{ name: 'All', value: '' }]) : "";
  };

  let newCpAll = cp.filter((n, index) => {
    return n.name === 'All' ? n : null
  })

  const isEmpty = (input) => {
    if ( typeof input === "undefined" || input === null || input === "null"
        || input === "" || input.length === 0 || (typeof input === "string" &&input.trim().length === 0)
        || (Array.isArray(input) && input.length === 0 )
        || (typeof input === "object" && !Object.keys(input).length) ){
      return true;
    }
    return false;
  }

  const cpContentsCount = (value, i) => {
    if (!isEmpty(value)) {
      if(!isFinite(value)){
        return;
      }else{
        if(Number(value) > 100){
          return;
        }
      }
    };
    let numberValue = isEmpty(value) ? '': Number(value);
    setEachCp(numberValue, i, 'value');
  };

  cp = newCpAll.length > 0 ? newCpAll : cp
  let addedRow = cp.map((n, i) => (
    <div className="filter_row" key={i}>
      <FormControl variant="outlined">
        <InputLabel>CP</InputLabel>
        {cp[i].name === 'All' ?
          <Select style={{ width: '47%' }}
            value={cp[i].name}
            onChange={e => {
              setEachCp(e.target.value, i, 'name');
              setEachCp('', i, 'value');
              AllCPSave(e);
            }}>
            {cpList.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
          : (
            <Select
              value={cp[i].name}
              onChange={e => {
                setEachCp(e.target.value, i, 'name');
                setEachCp('', i, 'value');
                AllCPSave(e);
              }}>
              {cpList.map(n => (
                <MenuItem key={n} value={n}>
                  {n}
                </MenuItem>
              ))}
            </Select>
          )}
      </FormControl>
      {cp[i].name === 'All' ? "" : (
        <TextField //FormControl안에 넣으면 줄안맞음
          fullWidth
          margin="dense"
          variant="outlined"
          label="100 counts or less" //focus안줬을때 mandatory text
          value={cp[i].value}
          onChange={e => {
            cpContentsCount(e.target.value, i);
          }}
        />
      )}
      {cp[i].name === 'All' ? "" : (
        <FormControl variant="outlined" className="filter_inner_today">
          <Button
            variant="contained"
            className={'btn_color2'}
            onClick={() => {
              setCp(cp.concat([{ name: '', value: '' }]));
            }}>
            Add
          </Button>
        </FormControl>
      )}
      {cp.length > 1 && (
        <FormControl variant="outlined" className="filter_inner_today">
          <Button
            variant="contained"
            className={'btn_color2'}
            onClick={() => {
              setCp(cp.filter((n, idx) => idx !== i));
            }}>
            Delete
          </Button>
        </FormControl>
      )}
    </div>
  ));
  return <div className="filter_area row">{addedRow}</div>;
}

export function CriteriaFilter({ data, criteria, setCriteria, setEachCriteria, ordering, onChangeOrdering, includeFlag, onChangeIncludeFlag, vodDispInterval, onChangeVodDispInterval, mappingType }) {
  let addedRow = criteria.map((n, i) => (
    <div className="filter_row" key={i}>
      <FormControl variant="outlined">
        <InputLabel>criteria</InputLabel>
        <Select
          value={criteria[i].criterion}
          onChange={e => {
            setEachCriteria(e.target.value, i, 'criterion');
            setEachCriteria('', i, 'criterionDetail');
          }}>
          {data
            .map(item => item.key)
            .map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel>detail criteria</InputLabel>
        <Select
          value={criteria[i].criterionDetail}
          onChange={e => {
            setEachCriteria(e.target.value, i, 'criterionDetail');
          }}>
          {data.find(data => data.key === n.criterion) &&
            data
              .find(data => data.key === n.criterion)
              .value.map(n => (
                <MenuItem key={n} value={n}>
                  {n}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      {criteria[i].criterion === 'All' || (
        <FormControl variant="outlined" className="filter_inner_today">
          <Button
            variant="contained"
            className={'btn_color2'}
            onClick={() => {
              setCriteria(criteria.concat([{ criterion: '', criterionDetail: '' }]));
            }}>
            Add
          </Button>
        </FormControl>
      )}
      {criteria.length > 1 && (
        <FormControl variant="outlined" className="filter_inner_today">
          <Button
            variant="contained"
            className={'btn_color2'}
            onClick={() => {
              setCriteria(criteria.filter((n, idx) => idx !== i));
            }}>
            Delete
          </Button>
        </FormControl>
      )}
    </div>
  ));
  return (
    <div className="filter_area row">
      {addedRow}
      <div className="filter_row">
        <FormControl component="fieldset">
          <FormLabel component="legend">Ordering</FormLabel>
          <RadioGroup row aria-label="position" name="position" value={ordering} onChange={onChangeOrdering}>
            <FormControlLabel value="latest" control={<Radio color="primary" />} label="latest first" />
            <FormControlLabel value="popular" control={<Radio color="primary" />} label="popular first" />
            <FormControlLabel value="time" control={<Radio color="primary" />} label="start time first" />
          </RadioGroup>
          <div component="legend" className="area_information" style={{ display: ordering ? '' : "none" }} >
            <FormLabel component="legend">
              <InfoIconOutlinedIcon fontSize="small" style={{ color: "#484848" }} />
            </FormLabel>
            <FormLabel component="legend" className="cont_information" style={{ color: "#484848" }}>
              {orderingCheck(ordering)}
            </FormLabel>
          </div>
        </FormControl>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel checked={includeFlag === 'Y'} onChange={onChangeIncludeFlag} value={includeFlag} control={<Checkbox color="primary" />} label="Include exclusive license cotents " labelPlacement="end" />
          </FormGroup>
        </FormControl>
      </div>
      <div className="filter_row" style={{ display: mappingType === 'position_auto' ? '' : "none" }}>
        <FormControl component="fieldset">
          <div component="legend" className="area_information">
            <FormLabel component="legend">VOD display interval</FormLabel>
            <TextField
              margin="dense"
              variant="outlined"
              value={vodDispInterval}
              onChange={onChangeVodDispInterval}
              InputProps={{
                style: { width: '5%', marginTop: '-3%' }
              }}
            />
          </div>
        </FormControl>
      </div>
    </div>
  );
}

function orderingCheck(ordering) {
  if (ordering === 'popular') {
    return 'Duration, Batch time : daily UTC 19:10';
  } else if (ordering === 'latest') {
    return ' Update date';
  } else if (ordering === 'time') {
    return ' Start time & update date';
  }
}