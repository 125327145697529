//import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function allCountries(state = initialState, action) {
  switch (action.type) {
    case 'SET_CNTRY':
      if (action.payload.status) {
        return state; //TODO
      } else if (action.payload) {
        const ricKeys = Object.keys(action.payload);
        const allCntry2DArray = ricKeys.map(item => action.payload[item].map(item => (item.countryCode ? item.countryCode : item)));
        const allCntry1DArray = [].concat(...allCntry2DArray);
        return allCntry1DArray;
      } else {
        return state;
      }
    case 'SET_CNTRY_ERROR':
      return state;
    default:
      return state;
  }
}
