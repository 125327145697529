import React, { useEffect, useState } from 'react';
import { ISS_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';

import Alert from '../modal/Alert';
function IssueHistoryTab({ currentTab, currentFilter }) {

  const [historyIssue, setHistoryIssue] = useState({ total: 0, resolve: 0, unresolved: 0 });
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [rangeDate, setRangeDate] = useState([]);
  const [isRequestHolding, setRequestHolding] = useState(false);

  // Default date는 30일
  const defaultRangeDays = 30; // 달력에 날짜를 보여 주기 위한 default 일자, 30일
  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());

    const thirtyDaysAgo = new Date(utcToday);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);

    const utcTodayString = utcToday.toISOString().substring(0,10);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0,10);

    setRangeDate([thirtyDaysAgoString, utcTodayString]);  // [startDate, endDate]
  }

  useEffect(() => {
    if (rangeDate && rangeDate.length > 0 && isRequestHolding) {
      setRequestHolding(false);
      getCountbyIssue('tab');
    }
  }, [rangeDate]);

  useEffect(() => {
    defaultRangeDate();
  }, []);

  useEffect(() => {
    if (rangeDate && rangeDate.length > 0) {
      getCountbyIssue('tab');
    } else {
      setRequestHolding(true);
    }
  }, [currentTab]);

  useEffect(() => {
    if (currentFilter && currentFilter.date.length > 0) {
      getCountbyIssue('filter');
    }
  }, [currentFilter]);

  const getCountbyIssue = requestType => {
    let addr = '';
    let param = '';
    if (currentTab === 0) {
      addr = `/api/common/history/total-issue/count`;
      param = requestType === 'filter' ? `?date=${currentFilter.date}&country=${currentFilter.country}&provider=${currentFilter.provider}` : `?date=${rangeDate}`;
    } else if (currentTab === 1) {
      addr = `/api/common/history/service-issue/count`;
      param = requestType === 'filter' ? `?date=${currentFilter.date}&country=${currentFilter.country}&platform=${currentFilter.platform}&provider=${currentFilter.provider}&subtype=${currentFilter.subType}` : `?date=${rangeDate}`;
    } else if (currentTab === 2) {
      addr = `/api/common/history/contents-issue/count`;
      param = requestType === 'filter' ? `?date=${currentFilter.date}&country=${currentFilter.country}&provider=${currentFilter.provider}&subtype=${currentFilter.subType}` : `?date=${rangeDate}`;
    } else if (currentTab === 3) {
      addr = `/api/common/history/statistics-issue/count`;
      param = requestType === 'filter' ? `?date=${currentFilter.date}&country=${currentFilter.country}&provider=${currentFilter.provider}&group=${currentFilter.groupList}&type=${currentFilter.type}&subtype=${currentFilter.subType}` : `?date=${rangeDate}`;
    } else {
      return;
    }
    fetch(`${ISS_BASE_URL}${addr}${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          setHistoryIssue({ total: body.dataSet.total, resolve: body.dataSet.resolve, unresolved: body.dataSet.unresolved });
        } else {
          setAlertMessage(`[getHistoryIssueCount server error]`);
          onOpenAlert();
        }
      });
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
      <div className="contents-service">
        <div className="line">
          <h3>Total</h3>
          <div className="num-summary">
            <strong>{historyIssue.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
          </div>
        </div>
        <div className="line">
          <h3>Resolve</h3>
          <div className="num-summary">
            <strong>{historyIssue.resolve.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
          </div>
        </div>
        <div className="line">
          <h3>Unresolved</h3>
          <div className="num-summary">
            <strong>{historyIssue.unresolved.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong>
          </div>
        </div>
      </div>
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default IssueHistoryTab;
