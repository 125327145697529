import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-multi-date-picker';
import DefaultButton from '../common/DefaultButton';
import Alert from '../modal/Alert';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RadioDropDown from "./RadioDropDown";

function DateTimePicker(props) {
  const { rangeDays, dateChangeHandler, timeChangeHandler, selectedDate, selectedFilter } = props;

  const [visibleCal, setVisibleCal] = useState(false);                    // 달력을 화면에 보여 주기 위한 용도
  const [showStartDate, setShowStartDate] = useState('');                 // 화면에 보이는 start date
  const [showEndDate, setShowEndDate] = useState('');                     // 화면에 보이는 end date
  const [showCalendarDate, setShowCalendarDate] = useState('');           // 달력에 보이는 date
  const [isDateChange, setDateChange] = useState(false);                  // 날짜가 변경되었을 때 callback을 보내 주기 위한 용도
  const [backupStartDate, setBackupStartDate] = useState('');             // 화면에 보이는 start date backup
  const [backupEndDate, setBackupEndDate] = useState('');                 // 화면에 보이는 end date backup
  const [utcToday, setUtcToday] = useState('');                           // UTC 기준 today
  const [isSelectedDateChange, setSelectedDateChange] = useState(false);  // 변경된 date 값을 유지하기 위한 용도

  const [currentTab, setCurrentTab] = useState('');
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [baseTime, setBaseTime] = useState('00:00')

  const candidateTimes = {dropdownList: ['00:00', '03:00', '06:00', '09:00', '12:00', '15:00', '18:00', '21:00'] }

  const defaultRangeDays = 7;

  useEffect(() => {
    defaultRangeDate();
  }, []);

  useEffect(() => {
    if (selectedDate && selectedDate.length > 0) {
      setShowStartDate(selectedDate[0]);
      setSelectedDateChange(true);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (isDateChange || isSelectedDateChange) {
      const changeDate = [showStartDate];
      dateChangeHandler(changeDate);
      if (isDateChange) {
        setDateChange(false);
      }
      if (isSelectedDateChange) {
        setSelectedDateChange(false);
      }
    }
  }, [isDateChange, isSelectedDateChange]);

  useEffect(() => {
    timeChangeHandler(baseTime)
  }, [baseTime]);

  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());

    const yesterday = new Date(utcToday);
    yesterday.setDate(utcToday.getDate() - 1);

    const period = rangeDays ? rangeDays : defaultRangeDays;
    const pastDay = new Date(utcToday);

    pastDay.setDate(yesterday.getDate() - period);

    const todayString = today.toISOString().substring(0, 10);
    const yesterdayString = yesterday.toISOString().substring(0, 10);
    const pastDayString = pastDay.toISOString().substring(0, 10);

    setUtcToday(todayString);
    setShowStartDate(pastDayString);
    setShowEndDate(yesterdayString);

    setDateChange(true);
  }

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const onVisibleCal = tab => {
    setShowCalendarDate(showStartDate);
    setBackupStartDate(showStartDate);
    setVisibleCal(true);
  };

  const onSetChange = selectedDate => {
    setShowStartDate(typeof (selectedDate) === 'object' ? selectedDate.toString() : selectedDate);
  };

  const onSelect = () => {
    const maxLookupDay = 90;

    const startDate = new Date(showStartDate);
    const todayDate = new Date(utcToday);
    const differenceFromToday = (todayDate.getTime() - startDate.getTime()) / (1000*3600*24)

    if (todayDate < startDate) {
      setAlertMessage('End date is greater than Today, please re-select the date');
      onOpenAlert()
    } else if (differenceFromToday > maxLookupDay) {
      setAlertMessage('You can only look up for 3 months')
      onOpenAlert()
    } else {
      setDateChange(true)
      setVisibleCal(false)
    }
  };

  const onCancel = () => {
    if (backupStartDate) {
      setShowStartDate(backupStartDate);
    }
    setVisibleCal(false);
  };

  const handleSelectedTime = (item) => {
    setBaseTime(item)
  }

  return (
    <>
      <div className="ui-datepicker trend">
        <div className="datepick-container">
          <div className="datepick-box">
            <span className="value-date" onClick={() => onVisibleCal('startCal')}>
              {showStartDate}
            </span>
          </div>
          {visibleCal && (
            <div className="calendar-container">
              <Calendar highlightToday={false} value={showCalendarDate} onChange={onSetChange} format="YYYY-MM-DD">
                <div className="button-container">
                  <DefaultButton type={'button'} text={'Cancel'} btnClass={'trend-btn-search'} clickEvent={onCancel} />
                  <DefaultButton type={'button'} text={'Select'} btnClass={'trend-btn-search'} clickEvent={onSelect} />
                </div>
              </Calendar>
            </div>
          )}
          <RadioDropDown
            item={candidateTimes}
            selectedItem={selectedFilter ? selectedFilter.time : baseTime}
            onChangeDropDown={handleSelectedTime} />
        </div>
      </div>
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default DateTimePicker;
