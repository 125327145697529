import React, { useEffect, useState } from "react";
import { ISS_BASE_URL } from "../../../../../constants";
import { getHeaders } from "../../../../../util/actionUtil";
import {
  CHART_COLORS,
  CHART_COLORS_BY_COUNTRY, CHART_COLORS_BY_PLATFORM,
  POINT_STYLE_BY_BEACON_TYPE
} from "../../../common/ChartConsts";
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import Loading from "../../../common/Loading";
import { BeaconTrendFilter } from "../../DataSet/StatisticsFilter/StatisticsFilter";
import { getScalesMaxValue } from '../../../utils/IssUtils';
import {beacon_basic_trend_data} from "./StatisticsTrendData";
import {ROWHEADER_TYPE} from "../../../consts/IssConsts";

function BeaconTrendComponent(props) {
  const { height, title, originalFilter, currentFilter, updateTable } = props;
  const [trendData, setTrendData] = useState({data: {}, options: {}});
  const [isTrendDisplay, setIsTrendDisplay] = useState(false);
  const [displayBeaconTypes, setDisplayBeaconTypes] = useState(BeaconTrendFilter.customCheckboxFilter[0].beaconTypes);
  const plugin = [annotationPlugin];
  const chartColors = [CHART_COLORS.PURPLE, CHART_COLORS.PINK, CHART_COLORS.MINT, CHART_COLORS.BLUE, CHART_COLORS.ORANGE];
  let beaconTypes = BeaconTrendFilter.customCheckboxFilter[0].beaconTypes;

  useEffect(() => {
    if (currentFilter) {
      getBeaconTrendData(currentFilter)
    }
  }, [currentFilter]);

  function getColor(compareType, item) {
    if (compareType === 'Country') {
      return CHART_COLORS_BY_COUNTRY[item] || CHART_COLORS_BY_COUNTRY['OTHERS'];
    } else {
      return CHART_COLORS_BY_PLATFORM[item] || CHART_COLORS_BY_PLATFORM['OTHERS'];
    }
  }

  const hexToRgba = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const trimLegendList = (elements, limit) => {
    if (elements && elements.length > limit) {
      return elements.slice(0, limit);
    }
    return elements
  }

  const convertPlatformFormat = (platform) => {
    return platform.replace('webOS', 'webOSTV').replace('webOSTV 22', 'webOSTV 7.0')
  }

  const getBeaconTrendData = (filter) => {

    let selectedBeaconTypes = [...displayBeaconTypes]

    if (filter.customCheckboxFilter && filter.customCheckboxFilter.length > 0) {
      const customCheckboxFilters = filter.customCheckboxFilter.reduce((acc, cur) => {
        return {...acc, ...cur}
      })

      selectedBeaconTypes = customCheckboxFilters['beaconTypes']
      setDisplayBeaconTypes([...selectedBeaconTypes])
    }

    const endDate = filter.date[1];
    let param = `startDate=${filter.date[0]}&endDate=${endDate}`
    if (filter.country) param += `&countries=${filter.country}`
    if (filter.platforms) param += `&platforms=${filter.platforms}`
    if (filter.interval) param += `&interval=${filter.interval}`
    if (selectedBeaconTypes.length !== 0) param += `&beaconTypes=${selectedBeaconTypes.join(',')}`
    param += `&compareType=${filter.compareType}`

    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/beacon/trend?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        // period 중복 제거
        const periods = [...new Set(body.map(item => item.period.substring(5)))].sort()
        const values = body.map(item => item.docCount)

        const data = {
          labels: [...periods],
          datasets: [],
        }

        const graphDatasets = []

        if (!filter.compareType) filter.compareType = 'None'
        if (filter.compareType === 'None') {
          selectedBeaconTypes.forEach((beacon, index) => {
            let filtered = body.filter(item => item.dataSourceType === beacon).sort(function (a, b) {
              if (a.period > b.period) return 1
              else return -1
            });
            graphDatasets.push({
              label: beacon,
              data: filtered.map(item => item.docCount),
              fill: false,
              pointRadius: 3,
              pointBorderColor: '#FFFFFF',
              borderColor: chartColors[index],
              backgroundColor: chartColors[index],
              pointBackgroundColor: chartColors[index],
              pointBorderWidth: 2,
              order: 2,
            })
          });
        } else if (filter.compareType === 'Country') {
          selectedBeaconTypes.forEach((beacon) => {
            filter.country.forEach((country) => {
              let filtered = body.filter(item => item.dataSourceType === beacon && item.countryCode === country)
                .sort(function (a, b) {
                  if (a.period > b.period) return 1
                  else return -1
              })
              graphDatasets.push({
                label: `${beacon}_${country}`,
                data: filtered.map(item => item.docCount),
                fill: false,
                pointRadius: 3,
                pointBorderColor: '#FFFFFF',
                borderColor: hexToRgba(getColor(filter.compareType, country), 1),
                backgroundColor: hexToRgba(getColor(filter.compareType, country), 1),
                pointBackgroundColor: hexToRgba(getColor(filter.compareType, country), 1),
                pointBorderWidth: 2,
                order: 2,
                pointStyle: POINT_STYLE_BY_BEACON_TYPE[beacon]
              })
            })
          })
        } else {
          selectedBeaconTypes.forEach((beacon) => {
            filter.platforms.forEach((platform) => {
              let filtered = body.filter(item => item.dataSourceType === beacon && item.platform === convertPlatformFormat(platform))
                .sort(function (a, b) {
                  if (a.period > b.period) return 1
                  else return -1
                })
              graphDatasets.push({
                label: `${beacon}_${platform}`,
                data: filtered.map(item => item.docCount),
                fill: false,
                pointRadius: 3,
                pointBorderColor: '#FFFFFF',
                borderColor: hexToRgba(getColor(filter.compareType, platform), 1),
                backgroundColor: hexToRgba(getColor(filter.compareType, platform), 1),
                pointBackgroundColor: hexToRgba(getColor(filter.compareType, platform), 1),
                pointBorderWidth: 2,
                order: 2,
                pointStyle: POINT_STYLE_BY_BEACON_TYPE[beacon]
              })
            })
          })
        }

        const options = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              display: false,
            },
          },
          scales: {
            y: {
              min: 0,
              max: Math.floor(Math.max(...values)) === 0 ? 1.0 : Math.floor(getScalesMaxValue(Math.max(...values))),
            },
            x: {
              ticks: {
                callback: function(value, index, values) {
                  if (data.labels[index] && (data.labels[index]).length > 5) {
                    if (index > 0 && (data.labels[index - 1]).substring(0, 5) === (data.labels[index]).substring(0, 5)) {
                      return '';
                    }
                    return (data.labels[index]).substring(0, 5);
                  } else {
                    return data.labels[index];
                  }
                }
              }
            }
          }
        }

        data.datasets = graphDatasets
        setTrendData({data: data, options: options})
        setIsTrendDisplay(true)

        if (filter.compareType === 'Country') {
          let tableData = body.reduce((acc, cur) => {
            const { period, dataSourceType, docCount, countryCode } = cur
            let tableRow = acc.find(item => item.period === period && item.countryCode === countryCode);
            if (tableRow) {
              tableRow[dataSourceType] = docCount
            } else {
              acc.push({period: period, countryCode: countryCode, [dataSourceType]: docCount})
            }
            return acc
          }, [])
          tableData = tableData.sort(function (a, b) {
            if (a.countryCode < b.countryCode) return -1
            if (a.countryCode > b.countryCode) return 1
            return new Date(a.period) - new Date(b.period)
          })
          beacon_basic_trend_data.rowdata = tableData.map(item => ({
             row: [item.period.slice(5, 10), item.countryCode].concat(selectedBeaconTypes.map(beacon => item[beacon]))
          }))
          beacon_basic_trend_data.header = ['Date', 'Country'].concat(selectedBeaconTypes)
          beacon_basic_trend_data.cellStyle = Array(selectedBeaconTypes.length + 2)
            .fill(ROWHEADER_TYPE.NORMAL)
          beacon_basic_trend_data.cellWidth = Array(selectedBeaconTypes.length + 2)
            .fill(Math.round(100 / (selectedBeaconTypes.length + 1)));

          updateTable(beacon_basic_trend_data)

        } else if (filter.compareType === 'Platform') {
          let tableData = body.reduce((acc, cur) => {
            const { period, dataSourceType, docCount, platform } = cur
            let tableRow = acc.find(item => item.period === period && item.platform === platform);
            if (tableRow) {
              tableRow[dataSourceType] = docCount
            } else {
              acc.push({period: period, platform: platform, [dataSourceType]: docCount})
            }
            return acc
          }, [])
          tableData = tableData.sort(function (a, b) {
            if (a.platform < b.platform) return -1
            if (a.platform > b.platform) return 1
            return new Date(a.period) - new Date(b.period)
          })
          beacon_basic_trend_data.rowdata = tableData.map(item => ({
            row: [item.period.slice(5, 10), item.platform].concat(selectedBeaconTypes.map(beacon => item[beacon]))
          }))
          beacon_basic_trend_data.header = ['Date', 'Platform'].concat(selectedBeaconTypes)
          beacon_basic_trend_data.cellStyle = Array(selectedBeaconTypes.length + 2)
            .fill(ROWHEADER_TYPE.NORMAL)
          beacon_basic_trend_data.cellWidth = Array(selectedBeaconTypes.length + 2)
            .fill(Math.round(100 / (selectedBeaconTypes.length + 1)));

          updateTable(beacon_basic_trend_data)
        } else {
          const reduceByDatetime = body.reduce((acc, cur) => {
            const { period, dataSourceType, docCount } = cur
            if (!acc[period]) {
              acc[period] = { period, data: {} }
            }
            acc[period].data[dataSourceType] = docCount
            return acc
          }, {});

          const tableData = Object.values(reduceByDatetime).sort(function (a, b) {
            if (a.period > b.period) return 1
            else return -1
          })
          beacon_basic_trend_data.rowdata = tableData.map(item => ({
            row: [item.period.slice(5, 10)].concat(selectedBeaconTypes.map(beacon => item.data[beacon]))
          }))
          beacon_basic_trend_data.header = ['Date'].concat(selectedBeaconTypes)
          beacon_basic_trend_data.cellWidth = Array(selectedBeaconTypes.length + 1)
            .fill(Math.round(100 / (selectedBeaconTypes.length + 1)));

          updateTable(beacon_basic_trend_data)
        }
      });
  }

  return (
    <>
      {
        isTrendDisplay ? (
          <div style={{height: height}}>
            <div className="trend-head">
              <div className="title" style={{top: "-30px"}}>
                <div>{title}</div>
              </div>
              <div className="legend">
                <ul>
                  {
                    currentFilter.compareType === 'None' && (
                      displayBeaconTypes.map((beacon, index) => (
                        <li key={index}>
                          <i style={{backgroundColor: chartColors[index]}}/>
                          <span>{beacon}</span>
                        </li>
                      ))
                    )
                  }
                  {
                    currentFilter.compareType === 'Country' && (
                      trimLegendList(currentFilter.country, 13).map((country) => (
                          <li key={country}>
                            <i style={{backgroundColor: hexToRgba(getColor(currentFilter.compareType, country), 1)}}/>
                            <span>{country}</span>
                          </li>
                      ))
                    )
                  }
                  {
                    currentFilter.compareType === 'Platform' && (
                      trimLegendList(currentFilter.platforms, 7).map((platform) => (
                        <li key={platform}>
                          <i style={{backgroundColor: hexToRgba(getColor(currentFilter.compareType, platform), 1)}}/>
                          <span>{platform}</span>
                        </li>
                      ))
                    )
                  }
                </ul>
              </div>
            </div>
            <Line id={'beacon-doc-count'} data={trendData.data} options={trendData.options}/>
          </div>
        ) : (
          <div>
            <Loading isVisible={true} />
          </div>
        )
      }
    </>
  );
}

export default BeaconTrendComponent;