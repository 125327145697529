import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Button, Dialog, Switch } from '@material-ui/core';
import { getSupportLanguagesAction } from '../../../actions/webActions';
import { addNotificationAction, getNotificationsAction, publishNotificationsAction, searchNotificationsAction, updateNotificationAction, enableNotificationAction } from '../../../actions/notificationActions';
import NotificationForm from './NotificationForm';
import NotificationView from './NotificationView';
import NotificationFilter from './NotificationFilter';
import NotificationHistory from './NotificationHistory';

function Notification({ notifications, getNotifications, addNotification, updateNotification, publishNotification, getSupportLanguages, searchNotification }) {
  useEffect(() => {
    searchNotification(searchData);
    getSupportLanguages();
  }, []);

  const [open, setOpen] = useState(false);
  const defaultPopupType = {
    add: false,
    edit: false,
    preview: false,
    history: false,
  };
  const [popupType, setPopupType] = useState(defaultPopupType);

  const defaultData = {
    type: 'text',
    title: '',
    deviceType: '',
    version: '',
    countryCodes: [],
    channelMapList: [],
    lang: [],
    notificationMultiLangList: [],
    enable: 'N',
    imageUrl: '',
    deeplinkUrl: '',
    deviceCategory: '',
    startTime: '',
    endTime: '',
  };
  const [notification, setNotification] = useState({});

  const defaultSearchData = {
    deviceType: '',
    countryCodes: [],
    channelMapList: [],
    date: '',
  };
  const [searchData, setSearchData] = useState(defaultSearchData);
  const [requiredField, setRequiredField] = useState(false);

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === 'deviceType' ) {
      if (value === 'mobile'){
        setNotification({ ...notification, ...{ [name]: value, countryCodes: [], channelMapList: [], type: 'text' } });
      } else {
        setNotification({ ...notification, ...{ [name]: value, countryCodes: [], channelMapList: [], deviceCategory: '', startTime: '', endTime: '' } });
      }
    } else if (name === 'enable') {
      setNotification({ ...notification, ...{ [name]: e.target.checked ? 'Y' : 'N' } });
    } else {
      setNotification({ ...notification, ...{ [name]: value } });
    }
  };

  const handleLangConentsChange = langData => {
    setNotification({ ...notification, ...{ notificationMultiLangList: langData } });
  };

  const checkEmptyContentsExits = () => {
    if (notification.notificationMultiLangList) {
      const checkContentsEmpty = notification.notificationMultiLangList.map(obj => {
        if (notification.type === 'text' || notification.type === 'textImage') {
          if (obj.title.trim().length === 0 || obj.content.trim().length === 0) {
            return { lang: obj.langCode, isEmpty: true };
          }
        }
        return { lang: obj.langCode, isEmpty: false };
      });
      return checkContentsEmpty;
    }
    return [];
  };

  const hasEmptyContents = () =>
    checkEmptyContentsExits()
      .map(x => x.isEmpty)
      .includes(true);
  const emptyContentsCountyList = () =>
    checkEmptyContentsExits()
      .filter(x => x.isEmpty === true)
      .map(x => x.lang)
      .join(',');

  const validateChannelMapAndContry = () => {
    if (notification.deviceType === 'tv'){
      const checkCountryChannelMapExits = notification.countryCodes.map(val => {
      return notification.channelMapList.filter(x => x.includes(val)).length === 0;
    });
    return checkCountryChannelMapExits.includes(true);
  } else {
    return false;
  }
  };

  const handleSubmit = e => {
    e.preventDefault();
    let notificationData;
    if (notification.type === 'image' || notification.type === 'textImage') {
      const imageData = document.getElementById('input_notification_image_url').files[0]; //undefined if not uploaded
      notificationData = { ...notification, ...{ imageData } };
    } else {
      notificationData = { ...notification };
    }

    if (validateChannelMapAndContry()) {
      alert('At least one channel map should be selected per country');
    } else if (hasEmptyContents()) {
      alert(`${emptyContentsCountyList()} language contents are empty please check.`);
    } else {
      // console.log('notificationData', notificationData);
      setNotification(notificationData);
      if (popupType.edit) {
        updateNotification(notificationData);
      } else {
        addNotification(notificationData);
      }
      setOpen(false);
      setPopupType({ ...defaultPopupType });
    }
  };

  const handlePublish = id => {
    publishNotification(id);
  };

  const getNotificationData = rowData => {
    setOpen(true);
    setNotification(rowData);
  };

  const handleSearchSubmit = () => {
    const requiedFiledsSatus = searchData.deviceType == '' || searchData.countryCodes.length === 0 || searchData.channelMapList.length === 0;
    if (requiedFiledsSatus) {
      setRequiredField(true);
      return;
    } else {
      setRequiredField(false);
    }
    searchNotification(searchData);
  };

  const handleSearchDataChange = e => {
    const { name, value } = e.target;
    if (name === 'deviceType' ) {
      setSearchData({ ...searchData, ...{ [name]: value, countryCodes: [], channelMapList: [] } });
    } else if (name === 'countryCodes') {
      setSearchData({ ...searchData, ...{ [name]: value, channelMapList: [] } });
    } else {
      setSearchData({ ...searchData, ...{ [name]: value } });
    }
  };

  const handleEnable = async (e, rowData) => {
    e.preventDefault();
    const notificationData = { ...rowData };
    notificationData.enable = e.target.checked ? 'Y' : 'N';
    await enableNotificationAction(notificationData);
    searchNotification(searchData);
  };

  return (
    <Fragment>
      <NotificationFilter searchData={searchData} handleSearchDataChange={handleSearchDataChange} requiredField={requiredField} />
      <div className="btn_set_wrap search_wrap">
        <Button variant="contained" className={'btn_color2'} onClick={handleSearchSubmit}>
          Search
        </Button>
      </div>
      <div className="terms_area tbl_wrap">
        <div className={'btn_set_wrap tbl_top1'}>
          <Button
            color="secondary"
            variant="contained"
            className={'btn_color2'}
            onClick={() => {
              // ==== add Popup
              setOpen(true);
              setPopupType({ ...defaultPopupType, add: true });
              setNotification(defaultData);
            }}>
            Add
          </Button>
        </div>
        <MaterialTable
          title=""
          columns={[
            {
              title: 'Device Type',
              field: 'deviceType',
            },
            {
              title: 'country',
              field: 'countryCodes',
              render: rowData => rowData.countryCodes.join(','),
            },
            {
              title: 'version',
              render: rowData => (
                <button
                  onClick={() => {
                    const row = { ...rowData };
                    row.startDate = row.startDate.slice(0, 10);
                    row.endDate = row.endDate.slice(0, 10);
                    getNotificationData(row);
                    setPopupType({ ...defaultPopupType, preview: true });
                  }}>
                  <span className="link">{rowData.version}</span>
                </button>
              ),
            },
            {
              title: 'enable',
              field: 'enable',
              render: rowData => <Switch checked={rowData.enable === 'Y'} disabled={rowData.publishedFlag === 'N'} inputProps={{ 'aria-label': 'secondary checkbox' }} onChange={event => handleEnable(event, rowData)} />,
            },
            {
              title: 'Published',
              field: 'publishedFlag',
            },
            {
              title: 'title',
              field: 'title',
            },
            {
              title: 'Start Date',
              field: 'startDate',
              render: rowData => rowData.startDate.slice(0, 10),
            },
            {
              title: 'End Date',
              field: 'endDate',
              render: rowData => rowData.endDate.slice(0, 10),
            },
            {
              title: 'lastChgDate',
              field: 'lastChgDate',
            },
            {
              title: 'action',
              field: 'Action',
              width: '10%',
              render: rowData => (
                <div className={'btn_set_wrap_tbl'}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                      setPopupType({ ...defaultPopupType, edit: true });
                      const row = { ...rowData };
                      row.startDate = row.startDate.slice(0, 10);
                      row.endDate = row.endDate.slice(0, 10);
                      row['lang'] = row.notificationMultiLangList ? row.notificationMultiLangList.map(x => x.langCode) : [];
                      setNotification(row);
                    }}
                    className={'btn_color3 btn_size_tbl'}>
                    Edit
                  </Button>
                  <Button variant="contained" className={'btn_color2 btn_size_tbl'} onClick={() => handlePublish(rowData.idx)}>
                    Publish
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                      setPopupType({ ...defaultPopupType, history: true });
                      const row = { ...rowData };
                      row.startDate = row.startDate.slice(0, 10);
                      row.endDate = row.endDate.slice(0, 10);
                      row['lang'] = row.notificationMultiLangList ? row.notificationMultiLangList.map(x => x.langCode) : [];
                      setNotification(row);
                    }}
                    className={'btn_color2 btn_size_tbl'}>
                    History
                  </Button>
                </div>
              ),
            },
          ]}
          data={notifications}
          options={{
            pageSize: 10,
            pageSizeOptions: [5, 10, 20, 30, 50],
            search: true,
            rowStyle: { backgroundColor: '#fff' },
          }}
        />
      </div>
      <Dialog open={open} className={'pop_wrap'}>
        {popupType.preview && <NotificationView data={notification} handleClose={() => setOpen(false)} />}
        {(popupType.add || popupType.edit) && <NotificationForm edit={popupType.edit} data={notification} handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={() => setOpen(false)} handleLangConentsChange={handleLangConentsChange} />}
        {popupType.history && <NotificationHistory handleClose={() => setOpen(false)} historyId={notification.historyId} />}
      </Dialog>
    </Fragment>
  );
}

Notification.propTypes = {
  notifications: PropTypes.array.isRequired,
  getNotifications: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  updateNotification: PropTypes.func.isRequired,
  publishNotification: PropTypes.func.isRequired,
  getSupportLanguages: PropTypes.func.isRequired,
  searchNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notifications: state.homeApp.notifications,
});

const mapDispatchToProps = dispatch => ({
  getNotifications: () => dispatch(getNotificationsAction()),
  addNotification: data => dispatch(addNotificationAction(data)),
  updateNotification: data => dispatch(updateNotificationAction(data)),
  publishNotification: data => dispatch(publishNotificationsAction(data)),
  getSupportLanguages: () => dispatch(getSupportLanguagesAction()),
  searchNotification: data => dispatch(searchNotificationsAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notification));
