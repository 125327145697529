import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import ChangePwForm from './ChangePwForm';
import { request } from '../../util/request';

export const validatePw = (pw, pwReEnter) => {
  if (pw.length < 6) {
    alert('Please check password length !');
    return false;
  } else if (pw !== pwReEnter) {
    alert('Please re-enter exact password !');
    return false;
  } else return true;
};

export default function ChangePw({ item, reloadAction }) {
  const [filter, setFilter] = useReducer((state, newState) => ({ ...state, ...newState }), {
    password: {
      value: '',
      errorMsg: 'At least 6 characters',
    },
    passwordReEnter: {
      value: '',
    },
  });

  const [open, setOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = e => {
    const inputId = e.target.name;
    setFilter({
      [inputId]: {
        value: e.target.value,
        errorMsg: filter[inputId].errorMsg,
      },
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (validatePw(filter.password.value, filter.passwordReEnter.value)) {
      const newItem = {
        password: filter.password.value,
      };
      alert('Are you sure to change password?');
      //alert('blocked');
      const isSuccess = await request('/api/admin/users/' + item['ID'], 'PATCH', newItem);
      if (isSuccess) {
        setSuccessSnackbarOpen(true);
        reloadAction();
        setOpen(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} className={'btn_size_tbl btn_color3'}>
        Change
      </Button>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={1000} //1000=1sec
        onClose={handleSuccessSnackbarClose} //없으면 없어지지않음
        message="Successfully Doned !"
        style={{ backgroundColor: 'black' }}
      />
      <Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={'pop_wrap'}>
        <ChangePwForm title="Change password" filter={filter} handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={handleClose} />
      </Dialog>
    </React.Fragment>
  );
}

ChangePw.propTypes = {
  reloadAction: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

// @Size(min = 4, max = 40)
// private String name;
// @Size(min = 6, max = 20)
// private String password;
