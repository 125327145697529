import { ROWHEADER_TYPE } from '../consts/IssConsts';

export const feature_bat_tabledata = {
  feature_bat_list_data: {
    title: 'Feature Bat Issues',
    visibleTitle: false,
    existTooltip: false,
    tooltip: '',
    header: ['Feature Bat ID', 'Mac Address', 'Pass', 'Fail', 'Success Rate',
    'Start Date', 'End Date'],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL],
    cellWidth: ['25%', '15%', '10%', '10%', '10%', '15%', '15%'],
    rowdata: [],
  },
  existing_issues: {
    title: 'Feature Bat',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Statistics Issues tooltip message..!!',
    header: ['TC ID', 'Beacon', 'Status', 'Start / End',
    'Request', 'Response', 'Error Message', 'Description', 'Comment'],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.EDITABLE],
    cellWidth: ['4%', '4%', '3%', '8%', '17%', '17%', '13%', '17%', '17%'],
    rowdata: [],
  },
  existing_empty_issues: {
    title: 'Feature Bat Empty',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Statistics Issues tooltip message..!!',
    header: ['TC ID', 'Beacon', 'Status', 'Start Date', 'End Date',
    'Request', 'Response', 'Error Message', 'Description'],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL],
      cellWidth: ['5%', '7%', '3%', '12%', '12%', '17%', '17%', '17%', '17%'],
    rowdata: [],
  },
};
