export const BeaconTypes = ['channel', 'program', 'homeapp', 'system']

export const BeaconTrendFilter = {
  basicFilter: { area: 'group', provider: false, platform: true, interval: true }, // area : region or group, interval : h or d
  customCheckboxFilter: [
    {
      beaconTypes: ['channel', 'program', 'homeapp', 'system']
    }
  ],
  customRadioFilter: [],
  customInputFilter: [],
  compareFilter: ['None', 'Country', 'Platform'],
  selectedFilter: null
}

export const DurationAccessFilter = {
  basicFilter: { area: 'group', provider: true, platform: true, interval: false },     // area : region or group
  customCheckboxFilter: [],
  customRadioFilter: [
    {
      api : ['duration', 'access']
    }
  ],
  customInputFilter: [],
  compareFilter: ['None', 'Country', 'Platform', 'CP'],
  selectedFilter: null
};

export const UniqueDeviceFilter = {
  basicFilter: { area: 'group', provider: false, platform: true, interval: false },     // area : region or group
  customCheckboxFilter: [], // 서버에서 가져온 값으로 설정
  customRadioFilter: [],
  customInputFilter: [],
  compareFilter: ['None', 'Country', 'Platform'],
  selectedFilter: null
};

export const ContentFilter = {
  basicFilter: { area: 'group', provider: false, platform: true, interval: false },     // area : region or group
  customCheckboxFilter: [], // 서버에서 가져온 값으로 설정
  customRadioFilter: [
    {
      api : ['duration', 'access', 'unique_device']
    }
  ],
  customInputFilter: [],
  compareFilter: ['None', 'Country', 'Platform'],
  selectedFilter: null,
  hiddenFilter : []
};

export const DurationAccessBarFilter = {
  basicFilter: { area: 'group', provider: true, platform: true, interval: false },     // area : region or group
  customCheckboxFilter: [
    {
      type : ['Channel', 'Vod']
    }
  ],
  customRadioFilter: [
    {
      api : ['duration', 'access']
    }
  ],
  customInputFilter: [],
  compareFilter: ['None', 'Country', 'Platform', 'CP'],
  selectedFilter: null
};

export const BeaconBaselineDocCountTrendFilter = {
  basicFilter: { area: 'group', provider: false, platform: true, interval: false, singlePeriod: true }, // area : region or group, interval : h or d
  customCheckboxFilter: [
    {
      beaconTypes: ['channel', 'program', 'homeapp', 'system']
    }
  ],
  customRadioFilter: [
    {
      displayType: ['Hourly Sequential', 'Weekly Same-Time']
    },
    {
      lookupPeriods: ['1','2','3','4','5','6','7','8','9','10']
    }
  ],
  // radio dropdown filter에 placeholder 문구 추가 시 customRadioFilter 내의 key값 이용
  // 적용 후 local storage 삭제해야 함
  radioPlaceholder: {
    lookupPeriods: 'days'
  },
  customInputFilter: [],
  compareFilter: ['None', 'Country', 'Platform'],
  selectedFilter: null
};