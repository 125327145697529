import React from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 18px;
    --y: 298px;
    --width: 299px;
    --height: 56px;

    top: 0;
    left: 0;
    width: 333px;
    height: 1080px;
  }

  #clip-02 {
    --x: 1px;
    --y: 92px;
    --width: 1570px;
    --height: 49px;

    top: 0;
    left: 333px;
    width: 1587px;
    height: calc(100% - 345px);
  }

  #clip-03 {
    --x: 1px;
    --y: 1px;
    --width: 608px;
    --height: 445px;

    top: 568px;
    left: 333px;
    width: 621px;
    height: 512px;
  }

  #clip-04 {
    --x: 1px;
    --y: 1px;
    --width: 568px;
    --height: 445px;

    top: 568px;
    left: 954px;
    width: 581px;
    height: 512px;
  }

  #clip-05 {
    --x: 1px;
    --y: 1px;
    --width: 367px;
    --height: 445px;

    top: 568px;
    left: 1535px;
    width: 385px;
    height: 512px;
  }

  #text-01 {
    top: 250px;
    left: 18px;
  }

  #text-02 {
    top: 175px;
    left: 537px;
  }

  #text-03 {
    top: 680px;
    left: 32px;
  }

  #line-01 {
    top: 102px;
    left: 78px;
  }

  #line-02 {
    top: 145px;
    left: 468px;
  }

  #line-03 {
    top: 809px;
    left: 177px;
  }
`;

const StatisticsDashboard01 = () => {
  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" className="border" />
        </div>

        <div id="clip-02" className="clip-box">
          <div id="clip-02-hole" className="hole" />
          <div id="clip-02-border" className="border" />
        </div>

        <div id="clip-03" className="clip-box">
          <div id="clip-03-hole" className="hole" />
          <div id="clip-03-border" className="border" />
        </div>

        <div id="clip-04" className="clip-box">
          <div id="clip-04-hole" className="hole" />
          <div id="clip-04-border" className="border" />
        </div>

        <div id="clip-05" className="clip-box">
          <div id="clip-05-hole" className="hole" />
          <div id="clip-05-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">Statistics tab</div>
          <div id="text-02" className="text">
            You can select Issue tab or set conditions for the filter.
          </div>
          <div id="text-03" className="text">
            You can check details<br/>
            about statistics issues<br/>
            from today to 14 days<br/>
            ago.
          </div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_6158_line_01.png" alt='Line' />
          </div>
          <div id="line-02" className="line">
            <img src="/common/images/iss/tutorial/t_6158_line_02.png" alt='Line' />
          </div>
          <div id="line-03" className="line">
            <img src="/common/images/iss/tutorial/t_6158_line_03.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default StatisticsDashboard01;