import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, DialogTitle, DialogContent, TextField } from '@material-ui/core';
import { saveAnTestConfiguration, setABTestParamAction, getABTestTabCategoryAction, getCountAction } from '../../../actions/homeAppActions';
import AddABTestFilter from './AddABTestFilter';
import LoadingIndicator from '../../../common/LoadingIndicator';
import ABTestManualResult from './ABTestManualResult';
import ABTestManualSearch from './ABTestManualSearch';
import { isNormalInteger } from '../../../util/util';
import { request } from '../../../util/request';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function AddABTest({ saveAnTestConfiguration, getCountAction, history, abTestTabCategory, id, abTestParam, fetching, setABTestParamAction, regionList, regionPlatformList, getABTestTabCategoryAction, handleTabClose }) {
  const today = new Date();
  const yestreday = new Date();
  const nextMonthDate = new Date();
  yestreday.setDate(today.getDate() - 1);
  nextMonthDate.setMonth(today.getMonth() + 1);
  const monthAgoDate = new Date();
  monthAgoDate.setMonth(monthAgoDate.getMonth() - 1);
  const [result, setResult] = React.useState([]);
  const [time, setTime] = useState('');
  const [title, setTitle] = useState();
  const [startDate, setStartDate] = React.useState(today.toISOString().slice(0, 10));
  const [endDate, setEndDate] = React.useState('');
  const [startTimeAnt, setStartTimeAnt] = useState('');
  const [endTimeAnt, setEndTimeAnt] = React.useState('');
  const [tabCode, setTabCode] = React.useState('');
  const [categoryCode, setCategoryCode] = React.useState('');
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth() + 1 > 9 ? now.getUTCMonth() + 1 : '0' + (now.getUTCMonth() + 1);
  const day = now.getUTCDate() > 9 ? now.getUTCDate() : '0' + now.getUTCDate();
  const hours = now.getUTCHours() > 9 ? now.getUTCHours() : '0' + now.getUTCHours();
  const minutes = now.getUTCMinutes() > 9 ? now.getUTCMinutes() : '0' + now.getUTCMinutes();
  const seconds = now.getUTCSeconds() > 9 ? now.getUTCSeconds() : '0' + now.getUTCSeconds();
  const [abStatus, setAbStatus] = React.useState('All');
  const [dType, setDType] = React.useState('All');
  const [pVersion, setPVersion] = React.useState('All');
  const [catCode, setCatCode] = React.useState('All');
  const [tbCode, setTbCode] = React.useState('All');
  const [abTestTitle, setAbTestTitle] = React.useState('');
  const [startDt, setStartDt] = React.useState('');
  const [endDt, setEndDt] = React.useState('');
  const [regionLoad, setRegionLoad] = React.useState('');

  React.useEffect(() => {
    setTime(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
  }, [abTestParam]);

  React.useEffect(() => {
    setABTestParamAction('', '', '', '', '', '');
  }, []);

  const clickSave = async e => {
    e.preventDefault();
    if (!title || title.trim().length === 0) alert('Enter the Title');
    else if (title.length > 255) alert('Title should be within 255 characters');
    else if (startTimeAnt === '') alert('Select start time');
    else if (!endDate) alert('Select an end date');
    else if (endTimeAnt === '') alert('Select end time');
    else if (!tabCode) alert('Select a tab code');
    else if (!categoryCode) alert('Select a category code');
    //else if(!countryCode) alert('Select a country');
    //else if (result.length > 15) alert('The number of result is more than 15 ! Please remove some content !');
    else if (result.find(n => n.result_order === '')) alert('Some order is empty ! Please enter the order !');
    //TODO order duplication check
    else if (window.confirm('Are you sure to save the result?')) {
      let startTime = startTimeAnt > 9 ? startDate + ' ' + startTimeAnt + ':00:00' : startDate + ' 0' + startTimeAnt + ':00:00';
      let endTime = endTimeAnt > 9 ? endDate + ' ' + endTimeAnt + ':00:00' : endDate + ' 0' + endTimeAnt + ':00:00';
      const addAbTestData = {
        title,
        startTime,
        endTime,
        tabCode,
        categoryCode,
        ...abTestParam
      };
      saveAnTestConfiguration(addAbTestData, abTestTitle, startDt, endDt, tbCode, catCode, dType, regionLoad, pVersion, abStatus);
      handleTabClose();
      // window.location.reload();
    }
  };

  const getCountryCode = value => {
    gePtlatformVersion(value);
    return regionList.find(n => n.deviceType === value).list[0].cntry;
  };

  const gePtlatformVersion = (deviceType, region) => {
    if (regionPlatformList.find(n => n.deviceType === deviceType && n.region === region)) {
      return regionPlatformList.find(n => n.deviceType === deviceType && n.region === region).platformVersion;
    }
    return '';
  };

  const getRegion = (deviceType, countryCode) => {
    abTestParam.region = '';
    (regionList.find(n => n.deviceType === deviceType) || { list: [] }).list.forEach(a => {
      if (a.cntry.includes(countryCode)) {
        abTestParam.region = a.region;
      }
    });
    return abTestParam.region;
  };

  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        Add A/N Test
        <IconButton aria-label="close" onClick={handleTabClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form id="addRowFrm" autoComplete="off">
        <DialogContent className={'pop_body'}>
          <div>
            <AddABTestFilter
              title={title}
              handleChangeTitle={e => {
                setTitle(e.target.value);
              }}
              startDate={startDate}
              handleChangeStartDate={e => {
                setStartDate(e.target.value);
                setEndDate('');
                setEndTimeAnt('');
              }}
              startTimeAnt={startTimeAnt}
              handleChangeStartTime={e => {
                setStartTimeAnt(e.target.value);
                setEndTimeAnt('');
              }}
              endDate={endDate}
              handleChangeEndDate={e => {
                setEndDate(e.target.value);
                setEndTimeAnt('');
              }}
              endTimeAnt={endTimeAnt}
              handleChangeEndTime={e => {
                setEndTimeAnt(e.target.value);
              }}
              regionList={regionList}
              deviceType={abTestParam.deviceType}
              handleChangeDeviceType={e => {
                setABTestParamAction(e.target.value, getCountryCode(e.target.value), regionList.find(n => n.deviceType === e.target.value).list[0].region, gePtlatformVersion(e.target.value, regionList.find(n => n.deviceType === e.target.value).list[0].region), abTestParam.mappingType);
              }}
              countryCode={abTestParam.countryCode}
              region={abTestParam.region}
              handleChangeCountry={e => {
                setABTestParamAction(abTestParam.deviceType, e.target.value, getRegion(abTestParam.deviceType, e.target.value), gePtlatformVersion(abTestParam.deviceType, e.target.value), abTestParam.mappingType);
              }}
              regionPlatformList={regionPlatformList}
              platformVersion={abTestParam.platformVersion}
              abTestTabCategory={abTestTabCategory}
              handleChangePlatformVersion={e => {
                setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, e.target.value, abTestParam.mappingType);
              }}
              tabCode={tabCode}
              handleChangeTab={e => {
                setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, abTestParam.mappingType);
                setTabCode(e.target.value);
              }}
              categoryCode={categoryCode}
              handleChangeCategory={e => {
                setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, abTestParam.mappingType);
                setCategoryCode(e.target.value);
              }}
              mappingType={abTestParam.mappingType}
              handleChangeMappingType={e => {
                setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, e.target.value);
              }}
            />
          </div>
          <div className={'btn_set_wrap'}>
            <Button type="submit" color="primary" className={'btn_color2'} onClick={clickSave}>
              Save
            </Button>
            <Button data-testid="addButton" color="primary" onClick={handleTabClose} className={'btn_color1'}>
              Cancel
            </Button>
          </div>
        </DialogContent>
      </form>
    </Fragment>
  );
}

AddABTest.propTypes = {
  notifications: PropTypes.array.isRequired,
  abTestTabCategory: PropTypes.array.isRequired,
  abTestParam: PropTypes.object.isRequired,
  getNotifications: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  getABTestTabCategoryAction: PropTypes.func.isRequired,
  updateNotification: PropTypes.func.isRequired,
  publishNotification: PropTypes.func.isRequired,
  getSupportLanguages: PropTypes.func.isRequired,
  searchNotification: PropTypes.func.isRequired,
  regionList: PropTypes.array.isRequired,
  regionPlatformList: PropTypes.array.isRequired,
  saveAnTestConfiguration: PropTypes.func.isRequired,
  handleTabClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notifications: state.homeApp.notifications,
  abTestParam: state.homeApp.abTestParam,
  abTestTabCategory: state.homeApp.abTestTabCategory,
  regionList: state.homeApp.regionList,
  regionPlatformList: state.homeApp.regionPlatformList,
  fetching: state.homeApp.manualResultFetching,
});

const mapDispatchToProps = dispatch => ({
  setABTestParamAction(deviceType, countryCode, region, platformVersion, tabCode, categoryCode, mappingType) {
    dispatch(setABTestParamAction(deviceType, countryCode, region, platformVersion, tabCode, categoryCode, mappingType));
  },
  getABTestTabCategoryAction(deviceType, region, platformVersion) {
    dispatch(getABTestTabCategoryAction(deviceType, region, platformVersion));
  },
  saveAnTestConfiguration(addAbTestData, abTestTitle, startDt, endDt, tbCode, catCode, dType, regionLoad, pVersion, abStatus) {
    dispatch(saveAnTestConfiguration(addAbTestData, abTestTitle, startDt, endDt, tbCode, catCode, dType, regionLoad, pVersion, abStatus));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddABTest));
