import React, { useEffect, useState } from 'react';
import Loading from '../../../common/Loading';
import { Line } from 'react-chartjs-2';
import Alert from '../../../modal/Alert';
import { getHeaders } from '../../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../../constants/index';
import { service_resolve_trend_data } from './ServiceTrendData';

function ServiceResolveTrendComponent(props) {
  const { height
        , title
        , currentFilter
        , updateTable
      } = props;
  const [trendData, setTrendData] = useState({data: {}, options: {}});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [isTrendDisplay, setTrendDisplay] = useState(false);

  useEffect(() => {
    if (currentFilter) {
      console.log(`[ISS] ServiceResolveTrend currentFilter: `, currentFilter);
      setTrendDisplay(false);
      getResolveTrend(currentFilter);
    }
  }, [currentFilter]);

  const getResolveTrend = (filter) => {
    let issueType = '';
    filter.customRadioFilter && filter.customRadioFilter.map((item, index) => {
      const [_, value] = Object.entries(item)[0];
      issueType = value;
    });
    let param = `issueType=${issueType}&dates=${filter.date}`;
    if (filter.country) param += `&countries=${filter.country}`;
    if (filter.platforms) param += `&platforms=${filter.platforms}`;
    if (filter.providers) param += `&providers=${filter.providers}`;

    fetch(`${ISS_BASE_URL}/api/service/trend/resolve?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          const issueDate = body.dataSet.map(item => item.issueDate.substring(5));
          const data = {
            labels: issueDate,
            datasets: [
              {
                label: 'Total Resolve Count',
                data: body.dataSet.map(item => item.resolveCnt),
                fill: false,
                pointBorderColor: '#FFFFFF',
                borderColor: '#4E75CE',
                backgroundColor: '#4E75CE',
                pointBackgroundColor: '#4E75CE',
                pointBorderWidth: 2,
                pointRadius: 4,
                pointStyle: 'circle',
              },
              {
                label: 'Auto Resolve Count',
                data: body.dataSet.map(item => item.autoCnt),
                fill: false,
                pointBorderColor: '#FFFFFF',
                borderColor: '#BE6F75',
                backgroundColor: '#BE6F75',
                pointBackgroundColor: '#BE6F75',
                pointBorderWidth: 2,
                pointRadius: 4,
                pointStyle: 'triangle',
              },
              {
                label: 'Manual Resolve Count',
                data: body.dataSet.map(item => item.manualCnt),
                fill: false,
                pointBorderColor: '#FFFFFF',
                borderColor: '#D4AA00',
                backgroundColor: '#D4AA00',
                pointBackgroundColor: '#D4AA00',
                pointBorderWidth: 2,
                pointRadius: 4,
                pointStyle: 'rect',
              }
            ],
          };

          const options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                min: 0,
                ticks: {
                  stepSize: 10,
                },
              },
            },
            plugins: {
              legend: {
                display: true,
                labels: {
                  usePointStyle: true,
                  boxWidth: 10,
                  boxHeight: 10,
                  color: '#fff',
                  font: {
                    size: 12,
                  },
                },
              },
              datalabels: {
                display: false,
              }
            },
          };

          setTrendData({data: data, options: options});

          service_resolve_trend_data.rowdata = body.dataSet.map(item => ({
            row: [
              item.issueDate,
              item.resolveCnt,
              item.autoCnt,
              item.manualCnt
            ],
          }));
          updateTable(service_resolve_trend_data);
          setTrendDisplay(true);
        } else {
          setAlertMessage(`getServiceResolveTrend error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`getServiceResolveTrend error: `, error);
        onOpenAlert();
      })
      .finally(() => {});
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };
  return (
    <>
    {
      isTrendDisplay ? (
        <div style={{ height: height }}>
          <div className='trend-head'>
            <div className="title" style={{top: "-30px"}}>
              <div> {title} </div>
            </div>
          </div>
          <Line data={trendData.data} options={trendData.options} />
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )
    }
    {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default ServiceResolveTrendComponent;