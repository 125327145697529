import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
function TabDetailSkinPreview({ tabName, bgImg, previewImg, buttonImg, tabColorPicker, titleColorPicker, plotColorPicker, categoryColorPicker, title, description }) {
  //reference: http://qt2-eic.lgchhomeapp.lgtvcommon.com/homeapp/v1/#/
  return (
    <Fragment>
      <div className={'content_dispaly'}>
        <Grid container spacing={2}>
          <div style={{ width: '100%', height: '500px' }}>
            <div
              className="background"
              style={{
                backgroundImage: `url(${bgImg})`,
                height: '100%',
                width: '100%',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}>
              <header
                className="header-area"
                style={{
                  display: 'flex',
                  // width: '64rem',
                  height: '2.6rem',
                  position: 'relative',
                }}>
                <div
                  className="header home-header"
                  style={{
                    zIndex: '100',
                    display: 'flex',
                    width: '100%',
                  }}>
                  <div
                    className="header-heading"
                    style={{
                      display: 'flex',
                      marginLeft: '2.5rem',
                      marginTop: '1.8rem',
                    }}>
                    <img
                      src="http://qt2-eic.lgchhomeapp.lgtvcommon.com/homeapp/v1/img/logo_lgchannelrs.32356cbc.png"
                      className="home-header-logo"
                      style={{
                        width: '2.5rem',
                        height: '2.5rem',
                        marginTop: '-0.3rem',
                        marginLeft: '-0.2rem',
                        marginRight: '0.4rem',
                      }}
                    />
                    <div
                      className="ui-header-heading"
                      style={{
                        position: 'relative',
                        display: 'flex',
                      }}>
                      <div
                        className="ui-header-heading-container"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}>
                        <div>
                          <h2
                            role="heading"
                            className="ui-header-heading-heading"
                            style={{
                              fontSize: '1.8rem',
                              fontWeight: 400,
                              color: '#e6e6e6',
                            }}>
                            LG Channels{' '}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="header-tabs"
                    style={{
                      position: 'absolute',
                      top: '1.9rem',
                      left: '17.5rem',
                      right: '11.7rem',
                    }}>
                    <div
                      className="ui-tabs-h"
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        maxWidth: '54rem',
                        height: '2.2rem',
                      }}>
                      <button
                        role="listitem"
                        className="ui-tab-h ui-tab-h_large-text"
                        style={{
                          width: '8.7rem',
                          height: '2.2rem',
                          position: 'relative',
                          boxSizing: 'border-box',
                          padding: '0.5rem 0.1rem',
                          fontSize: '1.1rem',
                          color: `${tabColorPicker.r ? 'rgba(' + tabColorPicker.r + ',' + tabColorPicker.g + ',' + tabColorPicker.b + ',' + tabColorPicker.a + ')' : tabColorPicker} `,
                          fontWight: '600',
                          borderRadius: '0.2rem',
                          zIndex: '10',
                          borderBottom: '1px solid #e6e6e6',
                        }}>
                        <div
                          className="marquee-text"
                          style={{
                            pointerEvents: 'none',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            transitionProperty: 'transform',
                            transitionTimingFunction: 'linear',
                            position: 'relative',
                            fontWeight: 600,
                          }}>
                          <span>{tabName ? tabName : 'Watch Now'}</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </header>
              <div
                className="content"
                style={{
                  position: 'absolute',
                  width: '93.9%',
                  height: '100%',
                }}>
                <div
                  className="content-preview-imgg"
                  style={{
                    top: '-2.95em',
                    right: '0',
                    // left: '14.25em',
                    position: 'absolute',
                    height: '22.5rem',
                  }}>
                  <div className="preview">
                    <div
                      className="preview-image preview__preview-image preview-gradient-image"
                      style={{
                        maskImage: 'url(../images/img_gradient_reversed3.ca569cdc.png)',
                        maskSize: 'cover',
                        maskRepeat: 'no-repeat',
                      }}>
                      <img
                        src={previewImg}
                        alt="screen"
                        className="preview-image__image"
                        style={{
                          width: '38.5rem',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="content-info"
                  style={{
                    width: '23rem',
                    maxHeight: '10.73rem',
                    top: '5.2rem',
                    left: '4.5rem',
                    color: '#e6e6e6',
                    overflow: 'hidden',
                    position: 'absolute',
                  }}>
                  <h2 className="preview-title marquee preview-title">
                    <div
                      className="marquee__text"
                      style={{
                        direction: 'ltr',
                        textAlign: 'left',
                        textOverflow: 'clip',
                        transitionDuration: '0s',
                        transform: 'translateX(0px)',
                        color: `${titleColorPicker.r ? 'rgba(' + titleColorPicker.r + ',' + titleColorPicker.g + ',' + titleColorPicker.b + ',' + titleColorPicker.a + ')' : titleColorPicker}`,
                      }}>
                      <span>{title}</span>
                    </div>
                  </h2>
                  <p className="preview-channel marquee">
                    <div
                      className="marquee__text"
                      style={{
                        direction: 'ltr',
                        textAlign: 'left',
                        textOverflow: 'clip',
                      }}></div>
                  </p>
                  <div className="summary preview-summary">
                    <p
                      lass="summary__description summary__description_ellipsis"
                      style={{
                        color: `${plotColorPicker.r ? 'rgba(' + plotColorPicker.r + ',' + plotColorPicker.g + ',' + plotColorPicker.b + ',' + plotColorPicker.a + ')' : plotColorPicker}`,
                      }}>
                      {description}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="category"
                style={{
                  color: `${categoryColorPicker.r ? 'rgba(' + categoryColorPicker.r + ',' + categoryColorPicker.g + ',' + categoryColorPicker.b + ',' + categoryColorPicker.a + ')' : categoryColorPicker}`,
                  marginLeft: '2rem',
                  top: '25rem',
                  position: 'absolute',
                  height: '1.16667rem',
                  left: 0,
                  paddingLeft: '2.5rem',
                  fontSize: '1rem',
                  fontWeight: 600,
                  lineHeight: '1.33333rem',
                }}>
                Featured
              </div>
              <div
                style={{
                  marginLeft: '2.5rem',
                  top: '27rem',
                  position: 'absolute',
                  height: '1.16667rem',
                  left: 0,
                  paddingLeft: '2rem',
                  display: 'flex',
                }}>
                {[...Array(4)].map((x, i) => (
                  <Fragment key={i}>
                    <div style={{ marginRight: '0.5rem' }}>
                      <div
                        style={{
                          width: '12.8rem',
                          height: '7.2rem',
                          position: 'relative',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}>
                        <button
                          style={{
                            color: '#e6e6e6',
                            zIndex: '10',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                          }}>
                          <img
                            style={{
                              display: 'block',
                              width: '100%',
                              height: '100%',
                            }}
                            src={i == 0 ? buttonImg : 'https://a1.amagi.tv/link/4f4be70e04ccc773b2efe64c8ed13d13b96802e6/output768x432.jpeg'}
                          />
                        </button>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </Fragment>
  );
}

TabDetailSkinPreview.propTypes = {
  tabName: PropTypes.string,
  bgImg: PropTypes.string.isRequired,
  previewImg: PropTypes.string.isRequired,
  buttonImg: PropTypes.string.isRequired,
  tabColorPicker: PropTypes.any.isRequired,
  titleColorPicker: PropTypes.any.isRequired,
  plotColorPicker: PropTypes.any.isRequired,
  categoryColorPicker: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default connect()(TabDetailSkinPreview);
