import * as actions from '../../actions/actionTypes';

const initialState = '';

export default function failmessage(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SAVE_FAIL:
      return action.payload;
    default:
      return initialState;
  }
}
