import classNames from 'classnames';
import React, { useState } from 'react';

function CauseDropDown(props) {
  const { items, title, onChange } = props;
  const [open, setOpen] = useState(false);

  const onBlur = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const onClick = e => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onSetLabel = (e, item) => {
    e.stopPropagation();
    onChange(item);
    setOpen(false);
  };

  return (
    <>
      <div tabIndex={0} className={classNames('ui-dropdown-type04', { on: open })} onBlur={e => onBlur(e)}>
        <div className="label" onClick={e => onClick(e)}>
          {title}
        </div>
        <ul className={open ? 'on' : ''}>
          {items && items.map((item, index) => (
            <li key={index} onClick={(e) => onSetLabel(e, item)}>
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default CauseDropDown;