import React, { useEffect, useState } from 'react';
import annotationPlugin from 'chartjs-plugin-annotation';
import { getHeaders } from '../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../constants/index';
import Loading from '../../common/Loading';
import Alert from '../../modal/Alert';
import LineChart from '../../common/LineChart';
import BarChart from '../../common/BarChart';
import { CHART_COLORS } from '../../common/ChartConsts';

import { issue_contents_history_total_data } from './IssueHistoryContentsTotalData';

function IssueHistoryContentsTotal({ currentFilter, chartType }) {
  const plugin = [annotationPlugin];

  const [rangeDate, setRangeDate] = useState([]);
  const [isHistoryDisplay, setHistoryDisplay] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (currentFilter && currentFilter.date.length > 0 && currentFilter.subType === 'All') {
      setRangeDate([currentFilter.date[0], currentFilter.date[1]]);  // [startDate, endDate]
    }
  }, [currentFilter]);

  useEffect(() => {
    if (currentFilter && (currentFilter.subType === '')) {
      defaultRangeDate();
    }
  }, []);

  useEffect(() => {
    if (rangeDate && rangeDate.length > 0) {
      setHistoryDisplay(false);
      getContentHistoryInfo('feed');
      getContentHistoryInfo('admin');
      getContentHistoryInfo('batch');
    }
  }, [rangeDate]);

  let maxYCandidateList = [];
  let issueCountDataSets = [];
  let issueResolvedDataSets = [];
  let issueCumulativeResolvedDataSets = [];

  const getContentHistoryInfo = type => {
    fetch(`${ISS_BASE_URL}/api/application/dashboard/content/history/info?type=${type}&date=${rangeDate}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          const dateList = body.dataSet.map(item => item.date.substring(5, 10));
          const options = {
            scales: {
              y: {
                min: 0,
                max: 0,
                ticks: {
                  stepSize: 10,
                },
              },
            },
          };

          let issueCountItem = {
            label: '',
            data: [],
            fill: false,
            pointRadius: 3,
            borderColor: '',
            pointBorderColor: '#FFFFFF',
            pointBackgroundColor: '#85DDDB',
            pointBorderWidth: 2,
            order: 2,
          };

          let issueResolvedItem = {
            label: '',
            data: [],
            fill: false,
            pointRadius: 3,
            borderColor: '',
            pointBorderColor: '#FFFFFF',
            pointBackgroundColor: '#85DDDB',
            pointBorderWidth: 2,
            order: 2,
          };

          let issueCumulativeResolvedItem = {
            label: '',
            data: [],
            fill: false,
            pointRadius: 3,
            borderColor: '',
            pointBorderColor: '#FFFFFF',
            pointBackgroundColor: '#85DDDB',
            pointBorderWidth: 2,
            order: 2,
          };

          if (body.type === 'feed') {
            // Daily Issue Count
            issueCountItem.label = 'cp';
            issueCountItem.backgroundColor = `${CHART_COLORS.BLUE}`;
            issueCountItem.borderColor = `${CHART_COLORS.BLUE}`;
            issueCountItem.pointBackgroundColor = `${CHART_COLORS.BLUE}`;
            issueCountItem.data = body.dataSet.map(item => item.issueCount);
            maxYCandidateList.push(Math.max(...issueCountItem.data));
            issueCountDataSets.push(issueCountItem);

            // Daily Issue Resolved Rate
            issueResolvedItem.label = 'cp';
            issueResolvedItem.backgroundColor = `${CHART_COLORS.BLUE}`;
            issueResolvedItem.borderColor = `${CHART_COLORS.BLUE}`;
            issueResolvedItem.pointBackgroundColor = `${CHART_COLORS.BLUE}`;
            issueResolvedItem.data = body.dataSet.map(item => item.resolvedRate);
            issueResolvedDataSets.push(issueResolvedItem);

            // Daily Cumulative Resolved Rate
            issueCumulativeResolvedItem.label = 'cp';
            issueCumulativeResolvedItem.backgroundColor = `${CHART_COLORS.BLUE}`;
            issueCumulativeResolvedItem.borderColor = `${CHART_COLORS.BLUE}`;
            issueCumulativeResolvedItem.pointBackgroundColor = `${CHART_COLORS.BLUE}`;
            issueCumulativeResolvedItem.data = body.dataSet.map(item => item.cumulativeResolveRate);
            issueCumulativeResolvedDataSets.push(issueCumulativeResolvedItem);
          } else if (body.type === 'admin') {
            // Daily Issue Count
            issueCountItem.label = 'CMP Organization';
            issueCountItem.backgroundColor = `${CHART_COLORS.PINK}`;
            issueCountItem.borderColor = `${CHART_COLORS.PINK}`;
            issueCountItem.pointBackgroundColor = `${CHART_COLORS.PINK}`;
            issueCountItem.data = body.dataSet.map(item => item.issueCount);
            maxYCandidateList.push(Math.max(...issueCountItem.data));
            issueCountDataSets.push(issueCountItem);

            // Daily Issue Resolved Rate
            issueResolvedItem.label = 'CMP Organization';
            issueResolvedItem.backgroundColor = `${CHART_COLORS.PINK}`;
            issueResolvedItem.borderColor = `${CHART_COLORS.PINK}`;
            issueResolvedItem.pointBackgroundColor = `${CHART_COLORS.PINK}`;
            issueResolvedItem.data = body.dataSet.map(item => item.resolvedRate);
            issueResolvedDataSets.push(issueResolvedItem);

            // Daily Cumulative Resolved Rate
            issueCumulativeResolvedItem.label = 'CMP Organization';
            issueCumulativeResolvedItem.backgroundColor = `${CHART_COLORS.PINK}`;
            issueCumulativeResolvedItem.borderColor = `${CHART_COLORS.PINK}`;
            issueCumulativeResolvedItem.pointBackgroundColor = `${CHART_COLORS.PINK}`;
            issueCumulativeResolvedItem.data = body.dataSet.map(item => item.cumulativeResolveRate);
            issueCumulativeResolvedDataSets.push(issueCumulativeResolvedItem);
          } else if (body.type === 'batch') {
            // Daily Issue Count
            issueCountItem.label = 'Data Deployment';
            issueCountItem.backgroundColor = `${CHART_COLORS.MINT}`;
            issueCountItem.borderColor = `${CHART_COLORS.MINT}`;
            issueCountItem.pointBackgroundColor = `${CHART_COLORS.MINT}`;
            issueCountItem.data = body.dataSet.map(item => item.issueCount);
            maxYCandidateList.push(Math.max(...issueCountItem.data));
            issueCountDataSets.push(issueCountItem);

            // Daily Issue Resolved Rate
            issueResolvedItem.label = 'Data Deployment';
            issueResolvedItem.backgroundColor = `${CHART_COLORS.MINT}`;
            issueResolvedItem.borderColor = `${CHART_COLORS.MINT}`;
            issueResolvedItem.pointBackgroundColor = `${CHART_COLORS.MINT}`;
            issueResolvedItem.data = body.dataSet.map(item => item.resolvedRate);
            issueResolvedDataSets.push(issueResolvedItem);

            // Daily Cumulative Resolved Rate
            issueCumulativeResolvedItem.label = 'Data Deployment';
            issueCumulativeResolvedItem.backgroundColor = `${CHART_COLORS.MINT}`;
            issueCumulativeResolvedItem.borderColor = `${CHART_COLORS.MINT}`;
            issueCumulativeResolvedItem.pointBackgroundColor = `${CHART_COLORS.MINT}`;
            issueCumulativeResolvedItem.data = body.dataSet.map(item => item.cumulativeResolveRate);
            issueCumulativeResolvedDataSets.push(issueCumulativeResolvedItem);
          }

          if (issueCountDataSets.length === 3) {
            // Daily Issue Count
            const issueMaxCount = Math.max(...maxYCandidateList);

            options.scales.y.max = issueMaxCount + Math.ceil(issueMaxCount / 10);
            issue_contents_history_total_data.daily_contents_total_error_occurrence.data.labels = dateList;
            issue_contents_history_total_data.daily_contents_total_error_occurrence.data.datasets = [];
            issue_contents_history_total_data.daily_contents_total_error_occurrence.data.datasets = issueCountDataSets;
            issue_contents_history_total_data.daily_contents_total_error_occurrence.options = options;

            // Daily Issue Resolved Rate
            issue_contents_history_total_data.daily_contents_total_error_resolution.data.labels = dateList;
            issue_contents_history_total_data.daily_contents_total_error_resolution.data.datasets = [];
            issue_contents_history_total_data.daily_contents_total_error_resolution.data.datasets = issueResolvedDataSets;

            // Daily Cumulative Resolved Rate
            issue_contents_history_total_data.daily_contents_total_error_cumulative_resolution.data.labels = dateList;
            issue_contents_history_total_data.daily_contents_total_error_cumulative_resolution.data.datasets = [];
            issue_contents_history_total_data.daily_contents_total_error_cumulative_resolution.data.datasets = issueCumulativeResolvedDataSets;

            setHistoryDisplay(true);

            if (issueMaxCount === 0) {
              setAlertTitle(`Info`);
              setAlertMessage(`No issues found`);
              onOpenAlert();
            }
          }
        } else {
          setAlertMessage(`getContentHistoryInfo error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        console.log(`getContentHistoryInfo error: `, error);
      })
      .finally(() => {});
  };

  // Default date는 30일
  const defaultRangeDays = 30; // 달력에 날짜를 보여 주기 위한 default 일자, 30일
  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());

    const thirtyDaysAgo = new Date(utcToday);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);

    const utcTodayString = utcToday.toISOString().substring(0, 10);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0, 10);

    setRangeDate([thirtyDaysAgoString, utcTodayString]); // [startDate, endDate]
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
      {isHistoryDisplay ? (
        <div className="grid-row">
          <div className="row">
            <div className="box-total">
              <div className="head">
                <div className="title">
                  <h2>Number of issues (based on occurrence date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.BLUE}` }}></i>
                      <span>CP</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>CMP Organization</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Data Deployment</span>
                    </li>
                  </ul>
                </div>
                {
                  chartType === 'Line' ? (
                    <LineChart id={'daily_total_error_occurrence'} data={issue_contents_history_total_data.daily_contents_total_error_occurrence.data}
                    options={issue_contents_history_total_data.daily_contents_total_error_occurrence.options} plugins={plugin} />
                  ) : (
                    <BarChart id={'daily_total_error_occurrence'} data={issue_contents_history_total_data.daily_contents_total_error_occurrence.data}
                    options={issue_contents_history_total_data.daily_contents_total_error_occurrence.options} plugins={plugin}/>
                  )
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-total">
              <div className="head">
                <div className="title">
                  <h2>Issue resolution rate (based on occurrence date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.BLUE}` }}></i>
                      <span>CP</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>CMP Organization</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Data Deployment</span>
                    </li>
                  </ul>
                </div>
                {
                  chartType === 'Line' ? (
                    <LineChart id={'daily_total_error_resolution'} data={issue_contents_history_total_data.daily_contents_total_error_resolution.data}
                    options={issue_contents_history_total_data.daily_contents_total_error_resolution.options} plugins={plugin} />
                  ) : (
                    <BarChart id={'daily_total_error_resolution'} data={issue_contents_history_total_data.daily_contents_total_error_resolution.data}
                    options={issue_contents_history_total_data.daily_contents_total_error_resolution.options} plugins={plugin}/>
                  )
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-total">
              <div className="head">
                <div className="title">
                  <h2>Issue resolution rate (based on cumulative daily date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.BLUE}` }}></i>
                      <span>CP</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>CMP Organization</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Data Deployment</span>
                    </li>
                  </ul>
                </div>
                {
                  chartType === 'Line' ? (
                    <LineChart id={'daily_total_error_cumulative_resolution'} data={issue_contents_history_total_data.daily_contents_total_error_cumulative_resolution.data}
                    options={issue_contents_history_total_data.daily_contents_total_error_cumulative_resolution.options} plugins={plugin} />
                  ) : (
                    <BarChart id={'daily_total_error_cumulative_resolution'} data={issue_contents_history_total_data.daily_contents_total_error_cumulative_resolution.data}
                    options={issue_contents_history_total_data.daily_contents_total_error_cumulative_resolution.options} plugins={plugin}/>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )}
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default IssueHistoryContentsTotal;
