import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';

function ImageEdit({ image, setImage, imageName, setImageName, imageInfo, setImageInfo, inputType, guideText, isEdit, isRequired }) {
  const style = {
    color : 'red',
  };
  return (
    <div className="logo_preview_add">
      <input type="file" id={`${inputType}_file`} hidden></input>
      {image && (
      <img
        alt="invalid"
        src={image}
        title={image}
        style={{ maxWidth: '100%', backgroundColor: 'black' }}
        onLoad={e => {
          setImageInfo({ w: e.target.naturalWidth, h: e.target.naturalHeight });
        }}
      />
      )}
      <input
        accept="image/*"
        id={`${inputType}`}
        type="file"
        name={`input_${inputType}`}
        className="logo_preview_input"
        onChange={e => {
          if (e.target.files && e.target.files.length >= 1) {
            const reader = new FileReader();
            const img = e.target.files[0];
            reader.readAsDataURL(img);
            reader.onload = e2 => {
              setImage(e2.target.result);
            document.getElementById(`${inputType}_file`).files = document.getElementById(`${inputType}`).files;
            setImageName(img.name);
            };
          }
        }}
      />
      <Grid container spacing={3} style={{ marginTop: '5px' }}>
        <Grid item sm={9} style={{ wordBreak: 'break-word' }}>
        {guideText &&(
          <p className="col-md-9" style={style}>{guideText}</p>
        )}
        {imageName &&(
          <p className="col-md-9">{imageName}</p>
        )}
        {image && (
        <p className="col-md-9">
          Actual Image Size: {imageInfo.w} X {imageInfo.h}
        </p>
        )}
        </Grid>
        {isEdit && (
          <Grid item sm={3}>
            <label htmlFor={`${inputType}`}>
              <Button component="span" className="btn_color4" style={{ width: 110 }}>
              Upload File
              </Button>
            </label>
            {!isRequired && (
              <Button
                component="span"
                className="btn_color4"
                style={{ width: 110, marginTop : 10 }}
                onClick={() => {
                  document.getElementById(`${inputType}_file`).value = '';
                  setImage('');
                  setImageName('');
                  setImageInfo({ w: '', h: '' });
                }}>
                Delete File
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
}

ImageEdit.propTypes = {
  image: PropTypes.string.isRequired,
  setImage: PropTypes.func.isRequired,
  imageName: PropTypes.string.isRequired,
  setImageName: PropTypes.func.isRequired,
  imageInfo: PropTypes.object.isRequired,
  setImageInfo: PropTypes.func.isRequired,
  inputType: PropTypes.string.isRequired,
  guideText: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
};
export default connect()(ImageEdit);
