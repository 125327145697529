import { SET_RSS_FEED, SET_RSS_FEED_DETAIL, SET_API_FAIL, SET_RSS_FEED_EDIT } from './actionTypes';
import { API_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';
const URL = API_URL;
const setRssFeed = list => ({
  type: SET_RSS_FEED,
  payload: list,
});
const setRssFeedDetail = data => ({
  type: SET_RSS_FEED_DETAIL,
  payload: data,
});
const setRssFeedEdit = data => ({
  type: SET_RSS_FEED_EDIT,
  payload: data,
});
const doFail = body => ({
  type: SET_API_FAIL,
  payload: body,
});
export const getRssFeedList = param => dispatch => {
  let apiUrl = `${URL}/api/sdepth/rssfeeds?cntryCode=${param.cntryCode}&page=${param.page}&size=${param.size}&cpName=${param.cpName}`;
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch(setRssFeed(body)))
    .catch(ex => dispatch(doFail(ex)));
};

export const getRssFeedDetail = feedIdx => dispatch => {
  let apiUrl = `${URL}/api/sdepth/rssfeed/${feedIdx}`;
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch(setRssFeedDetail(body)))
    .catch(() => dispatch(doFail()));
};

export const getRssFeedEdit = feedIdx => dispatch => {
  let apiUrl = `${URL}/api/sdepth/rssfeed/${feedIdx}`;
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch(setRssFeedEdit(body)))
    .catch(() => dispatch(doFail()));
};
export const createRssFeed = (param, history) => dispatch => {
  let apiUrl = `${URL}/api/sdepth/rssfeed`;
  fetch(apiUrl, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(param),
  })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        history.push('/PROMOTION/homeShelf/rssFeed');
      } else {
        dispatch(doFail());
      }
    })
    .catch(() => dispatch(doFail()));
};

export const updateRssFeed = (param, history) => dispatch => {
  let apiUrl = `${URL}/api/sdepth/rssfeed`;
  fetch(apiUrl, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(param),
  })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        history.push('/PROMOTION/homeShelf/rssFeed');
      } else {
        dispatch(doFail());
      }
    })
    .catch(() => dispatch(doFail()));
};

export const deleteRssFeed = (feedIdx, history) => dispatch => {
  let apiUrl = `${URL}/api/sdepth/rssfeed/${feedIdx}`;
  fetch(apiUrl, {
    method: 'DELETE',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      let result = body.result;
      if (result === 'SUCCESS') {
        history.push('/PROMOTION/homeShelf/rssFeed');
      } else {
        dispatch(doFail(body));
      }
    })
    .catch(() => dispatch(doFail()));
};
