const initialState = { deviceType: '', countryCode: '', region: '', platformVersion: '', mappingType: 'auto', status: 'All' };

export default function abTestParam(state = initialState, action) {
  switch (action.type) {
    case 'SET_HOMEAPP_ABTEST_PARAM':
      if (action.payload) {
        return action.payload;
      } else {
        return state;
      }
    default:
      return state;
  }
}
