import { combineReducers } from 'redux';
import ch0201 from './ch0201';
import ch0202 from './ch0202';
import ch0203 from './ch0203';
import ch0204 from './ch0204';
import ch0205 from './ch0205';
import ch0206 from './ch0206';
import last from './last';
import page from './page';
import size from './size';
import totalElements from './totalElements';
import totalPages from './totalPages';
//
export default combineReducers({
  ch0201,
  ch0202,
  ch0203,
  ch0204,
  ch0205,
  ch0206,
  last,
  page,
  size,
  totalElements,
  totalPages,
});
