import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, DialogTitle, DialogContent, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditReasonValidityDailogFilter from './EditReasonValidityDailogFilter';
import { updateANTestSegmentValidityReason} from '../../../actions/homeAppActions';


function EditReasonValidityDialog({ updateANTestSegmentValidityReason, handleTabClose ,idx ,validity , reason ,setReason, setValidity , setReasonData ,setValidityData }) {
  const clickSave = async e => {
    e.preventDefault();
    if (!validity) alert('Select the Validity');
    else if (reason === null || reason.trim().length === 0) alert('Enter the reason');
    else if (reason.length > 250) alert('Reason should be within 250 characters');
    else if (window.confirm('Are you sure to save the result?')) {
      const validityReason = {
        validity,
        reason,
        idx
      };
      updateANTestSegmentValidityReason(validityReason);
      setReasonData(reason);
      setValidityData(validity);
      handleTabClose();
     }
  };

  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        Update Valid/Reason
        <IconButton aria-label="close" onClick={handleTabClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form id="addRowFrm" autoComplete="off">
        <DialogContent className={'pop_body'}>
          <div>
            <EditReasonValidityDailogFilter
              validity={validity}
              handleChangeValidity={e => {
                setValidity(e.target.value);
              }}
              reason={reason}
              handleChangeReason={e => {
                setReason(e.target.value);
              }}
            />
          </div>
          <div className={'btn_set_wrap'}>
            <Button type="submit" color="primary" className={'btn_color2'} onClick={clickSave}>
              Save
            </Button>
            <Button data-testid="addButton" color="primary" onClick={handleTabClose} className={'btn_color1'}>
              Cancel
            </Button>
          </div>
        </DialogContent>
      </form>
    </Fragment>
  );
}

EditReasonValidityDialog.propTypes = {
  updateANTestSegmentValidityReason: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
  updateANTestSegmentValidityReason(validityReason) {
    dispatch(updateANTestSegmentValidityReason(validityReason));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditReasonValidityDialog));
