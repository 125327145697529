import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
//import { Redirect } from 'react-router-dom';
//import SigninForm from '../SigninForm';
//import SnackBar from '../../../common/SnackBar';
import { signinAction } from '../../../actions/signinoutActions';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 520,
    margin: '0 auto',
    marginTop: 40,
  },
  submit: {
    marginTop: theme.spacing && theme.spacing(3),
  },
}));

export function Signin({ signinAction, status }) {
  const classes = useStyles();
  const [input, setInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    usernameOrEmail: '',
    password: '',
  });

  const handleInputChange = ev => {
    const { name, value } = ev.target;
    setInput({
      [name]: value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    signinAction({
      usernameOrEmail: input.usernameOrEmail,
      password: input.password,
    });
  };

  //console.log('[Signin][status]', status); //[status] 401 [message] Bad credentials
  //{"status":401,"errorCode":"CDP_SVC_4011","message":"Bad credentials"}

  useEffect(() => {
    if (status === 401) {
      alert('Your ID or Password is incorrect. Please try again after fixing!');
    }
  }, [status]);

  return (
    <div>
      {/* <div id="signin-container"> */}
      <div className={classes.root} id={'loginView'}>
        <Typography component="h1" variant="h1" align="center" gutterBottom className={'login_logo'}>
          <span className={'hide'}>LG Electronics</span>
        </Typography>

        {/* <form className={classes.form} id={'loginBox'} onSubmit={handleSubmit}> */}
        <form id={'loginBox'} onSubmit={handleSubmit}>
          <h2 className={'h2_tit_login'}>CMP</h2>
          <TextField id="usernameOrEmail" name="usernameOrEmail" autoComplete="signin email" autoFocus value={input.usernameOrEmail} onChange={handleInputChange} label="id" variant="outlined" required />
          <TextField name="password" type="password" id="password" autoComplete="signin current-password" value={input.password} onChange={handleInputChange} label="Password" variant="outlined" required />
          {/* <FormControlLabel
						control={<Checkbox value="remember" color="primary" />}
						label="Remember me"
					/> */}
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            LOGIN
          </Button>
        </form>
        <p className={'login_comment'}>※ If you forgot your password, please contact us via Harmony.</p>
      </div>
    </div>
  );
}

Signin.propTypes = {
  signinAction: PropTypes.func.isRequired,
  //accessToken: PropTypes.string.isRequired,
  //fetching: PropTypes.bool.isRequired,
  //message: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};
/* istanbul ignore next */
const mapStateToProps = state => ({
  //isAuthenticated: state.currentUser.isAuthenticated,
  //fetching: state.signin.fetching,
  status: state.signin.status,
  //message: state.signin.message,
});
const mapDispatchToProps = dispatch => ({
  signinAction(value) {
    dispatch(signinAction(value));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Signin);
