import React, { useEffect, useState } from 'react';
import Loading from '../../../common/Loading';
import { Line } from 'react-chartjs-2';
import Alert from '../../../modal/Alert';
import { CHART_COLORS_BY_COUNTRY, CHART_COLORS_BY_PROVIDER, CHART_COLORS_BY_PLATFORM } from '../../../common/ChartConsts';
import { getHeaders } from '../../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../../constants/index';
import { service_basic_trend_data, service_country_trend_data, service_platform_trend_data, service_provider_trend_data } from './ServiceTrendData';

function ServiceTrendComponent(props) {
  const { height, title, currentFilter, updateTable, size } = props;
  const [trendData, setTrendData] = useState({data: {}, options: {}});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [isTrendDisplay, setTrendDisplay] = useState(false);
  const typeList = {'Country':'country', 'Platform':'webOSVersion', 'ErrorCode':'clientErrorCode', 'Content':'contentId', 'Provider':'providerId'};
  const [displayType, setDisplayType] = useState([]);
  const [displayCompareType, setDisplayCompareType] = useState('None');

  useEffect(() => {
    if (currentFilter) {
      console.log(`[ISS] ServiceComponent, currentFilter: `, currentFilter);
      setDisplayCompareType(currentFilter.compareType ? currentFilter.compareType : 'None');
      getTrendBasic(currentFilter);
    }
  }, [currentFilter]);

  const getTrendBasic = (filter) => {
    let sourceFilter = '';

    filter.customCheckboxFilter && filter.customCheckboxFilter.map((item, index) => {
      const [_, value] = Object.entries(item)[0];
      sourceFilter = value.join(',');
    });

    const issueType = title.includes('App')? 'app' : 'play';
    const customInputFilter = filter.contentName ? filter.searchType + '=' + filter.contentName : '';
    const intervalType = filter.interval ? filter.interval : 'd';
    const compareType = filter.compareType ? typeList[filter.compareType] : 'None';
    const endDate = currentFilter.date[1];
    const customDate = currentFilter.date[0] + "," + endDate;
    let param = `dates=${customDate}&intervalType=${intervalType}`;
    if (filter.country) param += `&countries=${filter.country}`;
    if (filter.platforms) param += `&platforms=${filter.platforms}`;
    if (filter.contentName) param += `&${customInputFilter}`;
    if (sourceFilter) param += `&source=${sourceFilter}`;
    if (filter.providers) param += `&providers=${filter.providers}`;
    if (compareType) param += `&compareType=${compareType}`;
    if (issueType) param += `&issueType=${issueType}`;

    fetch(`${ISS_BASE_URL}/api/service/trend/basic?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          const data = {
            labels: [],
            datasets: [],
          };
          const options = {
            scales: {
              y: {
                min: 0,
                max: 0,
                ticks: {
                  stepSize: 10,
                },
              },
              x: {
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: body.compareType !== 'webOSVersion',
                labels: {
                  usePointStyle: true,
                  boxWidth: 6,
                  boxHeight: 6,
                  color: '#fff',
                  font: {
                    size: 12,
                  },
                },
              },
              datalabels: {
                display: false,
              },
            }
          };
          data.labels = body.dataSet.map(item => item.date);
          function arraySum(array) {
            return array.reduce((a, b) => a + b, 0);
          };
          body.dataSet.forEach(item => {
            if ('webOSTV 7.0' in item) {
              item['webOSTV 22'] = item['webOSTV 7.0'];
              delete item['webOSTV 7.0'];
            }
          });
          let trendBasicData = [];
          let keyList = {};
          body.dataSet.map(item => {
            let ySumCount = 0;
            Object.keys(item).map(k => {
              if (k.length > 0 && k != 'date') {
                ySumCount = Math.max(ySumCount, item[k] + 5);
                if (k in keyList === false) {
                  keyList[k] = [];
                }
              }
            });
            options.scales.y.max = Math.max(options.scales.y.max, ySumCount);
          });
          body.dataSet.map(item => {
            const countries = Object.keys(item);
            Object.keys(keyList).map(k => {
              if (countries.includes(k)) {
                keyList[k].push(item[k]);
              } else {
                keyList[k].push(0);
              }
            });
          });

          let sortedKeyList = Object.keys(keyList).sort((a, b) => arraySum(keyList[b]) - arraySum(keyList[a]));
          if (body.compareType === 'webOSVersion') {
            sortedKeyList = sortedKeyList.sort((a, b) => parseFloat(b.replace('webOSTV ', '')) - parseFloat(a.replace('webOSTV ', '')));
          };
          let tempDisplayList = [];
          sortedKeyList.map(key => {
            let temp = {
              label: key,
              data: keyList[key],
              fill: false,
              borderColor: hexToRgba(getColor(filter.compareType, key), 1),
              backgroundColor: hexToRgba(getColor(filter.compareType, key), 1),
              order: 2,
            };
            tempDisplayList.push(key);
            trendBasicData.push(temp);
          });
          if (body.compareType === 'None' && filter.contentName) {
            setDisplayType([filter.contentName]);
          } else {
            setDisplayType(tempDisplayList);
          }
          data.datasets = trendBasicData;
          setTrendData({data: data, options: options});
          // update table
          if (body.compareType === 'None') {
            service_basic_trend_data.rowdata = body.dataSet.map(item => ({
              row: [
                item.date,
                item.total
              ],
            }));
            updateTable(service_basic_trend_data);
          } else if (body.compareType === 'country') {
            let countryRows = [];
            sortedKeyList.map(key => {
              body.dataSet.map(item => {
                if (key in item) {
                  countryRows.push({row: [key, item.date, item[key]]});
                }
              });
            });
            service_country_trend_data.rowdata = countryRows;
            updateTable(service_country_trend_data);
          } else if (body.compareType === 'webOSVersion') {
            let platformRows = [];
            sortedKeyList.map(key => {
              body.dataSet.map(item => {
                if (key in item) {
                  platformRows.push({row: [key, item.date, item[key]]});
                }
              });
            });
            service_platform_trend_data.rowdata = platformRows;
            updateTable(service_platform_trend_data);
          } else if (body.compareType === 'providerId') {
            let providerRows = [];
            sortedKeyList.map(key => {
              body.dataSet.map(item => {
                if (key in item) {
                  providerRows.push({row: [key, item.date, item[key]]});
                }
              });
            });
            service_provider_trend_data.rowdata = providerRows;
            updateTable(service_provider_trend_data);
          };
          setTrendDisplay(true);
        } else {
          setAlertMessage(`trend basic error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`trend basic error: `, error);
        onOpenAlert();
      })
      .finally(() => {});
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const hexToRgba = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  function getColor(compareType, item) {
    if (compareType === 'Country') {
      return CHART_COLORS_BY_COUNTRY[item] || CHART_COLORS_BY_COUNTRY['OTHERS'];
    } else if (compareType === 'Platform') {
      let p = item.replace('TV', '');
      return CHART_COLORS_BY_PLATFORM[p] || CHART_COLORS_BY_PLATFORM['OTHERS'];
    } else {
      return CHART_COLORS_BY_PROVIDER[item] || CHART_COLORS_BY_PROVIDER['OTHERS'];
    }
  };

  function CustomLegend({ chart }) {
    const datasets = chart.datasets.map((dataset, index) => {
      dataset.pointStyle = 'circle';
      return (
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '8px', marginBottom: '0px', width: 'auto', fontSize: '12px',}}>
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              backgroundColor: dataset.borderColor,
              borderRadius: '50%',
              marginRight: '4px',
            }}
          ></span>
          {dataset.label}
        </div>
      );
    });

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '0px', maxWidth: '500px', margin: '0 auto', }}>
        {datasets}
      </div>
    );
  };

  return (
    <>
    {
      isTrendDisplay ? (
        <div style={{ height: height }}>
          <div className="trend-head">
            <div className="title" style={{top: "-30px"}}>
              <div> {title} </div>
            </div>
            {displayCompareType === 'Platform' && <CustomLegend chart={trendData.data} />}
          </div>
          <Line id={'daily_contents_error_occurrence'} data={trendData.data}
                      options={trendData.options} />
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )
    }
    {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default ServiceTrendComponent;