import React, { useState, useEffect, Fragment, useMemo ,useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PushAlarmFilter } from '../../../common/filter';
import MaterialTable from 'material-table';
import { Button , Dialog } from '@material-ui/core';
import { getPushAlarmList, updatePushAlarm } from '../../../actions/pushAlarmActions';
import { getSupportLanguagesAction } from '../../../actions/webActions';
import LoadingIndicator from '../../../common/LoadingIndicator';
import PushAlarmForm from './PushAlarmForm';
import PushAlarmView from './PushAlarmView';

export function PushAlarm({ pushAlarmlist, getPushAlarmList, regionCountries, updatePushAlarm, getSupportLanguages, fetching }) {
  const allCountries = regionCountries.filter(n =>n.deviceType === 'mobile').map(item => (
      item.countryCode
  ));

  const [shouldRequest, setShouldRequest] = useState(true);
  const [title, setTitle] = useState('');
  const [countryCode, setCountryCode] = useState('All');
  const [state, setState] = useState('All');
  const [open, setOpen] = useState(false);
  const [pushAlarm, setPushAlarm] = useState({});
  const [chkBox, setChkBox] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const startDateStr = startDate.toISOString().slice(0, 11) + '00:00:00Z'; //2020-03-01T00:00:00Z UTC
  const [startTime, setStartTime] = useState('');
  const [startDateYn, setStartDateYn] = useState('Y');
  const checkedItemsArray = [];

  const defaultPopupType = {
    add: false,
    edit: false,
    preview: false,
  };
  const [popupType, setPopupType] = useState(defaultPopupType);

  const defaultData = {
    title: '',
    state: '1',
    platformCode: '',
    platformName: '',
    countryCodes: [],
    multiLangList: [],
    lang: [],
    startDate: '',
  };

  const handleTitle = value => setTitle(value);

  const handleAllCheckBoxChange = (event) => {
    if (event.target.checked) {
      allCountries.forEach(data => checkedItemsArray.push(data));
      setChkBox(checkedItemsArray);
    } else {
      setChkBox([]);
    }
  };

  const handleCheckBoxChange = (event) => {
    const { id, checked } = event.target;
    if(checked) {
      setChkBox([...chkBox, id]);
    } else {
      const filter = chkBox.filter((n) => n !== id )
      setChkBox([...filter]);
    }
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setPushAlarm({ ...pushAlarm, ...{ [name]: value } });
  };

  const handleLangConentsChange = langData => {
    setPushAlarm({ ...pushAlarm, ...{ multiLangList: langData } });
  };

  const handleClickSearch = () => {
    setShouldRequest(true);
  };

  const getPushAlarmData = data => {
    setOpen(true);
    setPushAlarm(data);
  };

  const handleChangeStartTime = event => {
    setStartTime(event.target.value);
  };

  const handleChangeStartDate = date => {
    setStartDate(new Date(date));
  };

  const handleChangeStartDateYn = (event) => {
    const { checked } = event.target;
    if(checked) {
      setStartDateYn('Y');
    } else {
      setStartDateYn('N');
    }
  };

  const handleSubmit = (event, param) => {
    event.preventDefault();

    if(param === 'Publish'){
      pushAlarm['state'] = '2';
    }else{
      pushAlarm['state'] = '1';
    }

    if(startDateYn === 'Y'){
      if(startTime === null || startTime === ''){
        alert('Open Time is required');
        return;
      }
      pushAlarm['startDate'] = startDate.toISOString().slice(0, 10) + ' ' + startTime + ':00';
    }else {
      pushAlarm['startDate'] = '';
    }

    //console.log('handleSubmit:', pushAlarm);

    if (!validateCheck(pushAlarm)){
      return false;
    }

    updatePushAlarm(pushAlarm);
    setOpen(false);
    setChkBox([]);
  };

  useEffect(() => {
    setPushAlarm({ ...pushAlarm, ...{ ['countryCodes']: chkBox } });
  }, [chkBox])

  useEffect(() => {
    if (shouldRequest) {
      getPushAlarmList(title, countryCode, state);
      getSupportLanguages();
      setShouldRequest(false);
    }
  }, [shouldRequest]);

  return (
    <Fragment>
      <div>
        <PushAlarmFilter
          title={title}
          allCountries={allCountries}
          handleTitle={handleTitle}
          countryCode={countryCode}
          handleCountryCode={e => setCountryCode(e.target.value)}
          state={state}
          handleState={e => setState(e.target.value)}
        />
        <div className="btn_set_wrap search_wrap">
          <Button variant="contained" className={'btn_color2'} onClick={handleClickSearch}>
            Search
          </Button>
        </div>
        <div className={'tbl_wrap'}>
          <div className={'btn_set_wrap tbl_top1'}>
            <Button
              color="secondary"
              variant="contained"
              className={'btn_color2'}
              onClick={() => {
                setOpen(true);
                setPopupType({ ...defaultPopupType, add: true });
                setPushAlarm(defaultData);
              }}>
              Add
            </Button>
          </div>
          {fetching ? (
            <div className="loading_wrap">
              <LoadingIndicator />
            </div>
          ) : (
              <MaterialTable
                title=" "
                columns={[
                  {
                    title: 'Country',
                    field: 'countryCode',
                    width: '15%',
                  },
                  {
                    title: 'Platform',
                    field: 'platformName',
                    width: '10%',
                  },
                  {
                    title: 'Title',
                    render: data => (
                      <button
                        onClick={() => {
                          getPushAlarmData(data);
                          setPopupType({ ...defaultPopupType, preview: true });
                        }}>
                        <span className="link">{data.title}</span>
                      </button>
                    ),
                  },
                  {
                    title: 'State',
                    field: 'state',
                    width: '8%',
                  },
                  {
                    title: 'Open Date',
                    field: 'startDate',
                    width: '12%',
                  },
                  {
                    title: 'Push',
                    field: 'pushFlag',
                    width: '6%',
                  },
                  {
                    title: 'Action',
                    field: 'action',
                    width: '10%',
                    render: data => (
                      <div className={'btn_set_wrap_tbl'}>
                        <Button
                          disabled={data.state === 'Publish'}
                          variant="contained"
                          onClick={() => {
                            setOpen(true);
                            setPopupType({ ...defaultPopupType, edit: true });
                            // adding languges
                            const pushAlarm = { ...data };
                            pushAlarm['lang'] = pushAlarm.multiLangList ? pushAlarm.multiLangList.map(x => x.lang) : [];
                            setPushAlarm(pushAlarm);
                          }}
                          className={'btn_color3 btn_size_tbl'}>
                          Edit
                        </Button>
                      </div>
                    ),
                  },
                ]}
                data={pushAlarmlist
                  .map((rowData, i) => {
                    let newData = {};
                    newData['countryCode'] = rowData.countryCode;
                    newData['title'] = rowData.title;
                    newData['state'] = rowData.state === 1 ?  'Draft' : 'Publish';
                    newData['created'] = rowData.crtDate;
                    newData['platformCode'] = rowData.platformCode;
                    newData['platformName'] = rowData.platformName;
                    newData['multiLangList'] = rowData.multiLangList;
                    newData['countryCodes'] = rowData.countryCode.split(',');
                    newData['startDate'] = rowData.startDate;
                    newData['accessKey'] = rowData.accessKey;
                    newData['pushFlag'] = rowData.pushFlag;
                    return newData;
                  })}
                options={{
                  pageSize: 10,
                  rowStyle: { backgroundColor: '#fff' },
                }}
              />
            )}
        </div>
        <Dialog open={open} className={'pop_wrap'}>
          {(popupType.add || popupType.edit) &&
          <PushAlarmForm
            edit={popupType.edit}
            data={pushAlarm}
            title="Push Alarm"
            handleInputChange={handleInputChange}
            handleCheckBoxChange={handleCheckBoxChange}
            chkBox={chkBox}
            setChkBox={setChkBox}
            handleAllCheckBoxChange={handleAllCheckBoxChange}
            allCountries={allCountries}
            handleSubmit={handleSubmit}
            handleLangConentsChange={handleLangConentsChange}
            setStartDate={setStartDate}
            setStartTime={setStartTime}
            startDate={startDateStr}
            startTime={startTime}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeStartTime={handleChangeStartTime}
            startDateYn={startDateYn}
            setStartDateYn={setStartDateYn}
            handleChangeStartDateYn={handleChangeStartDateYn}
            handleClose={() => {
              setOpen(false);
              setChkBox([]);
              setStartDate(new Date());
              setStartTime('');
              setStartDateYn('Y');
            }}
          />}
          {popupType.preview &&
          <PushAlarmView
            data={pushAlarm}
            title="Push Alarm"
            handleClose={() => setOpen(false)}
          />}
        </Dialog>
      </div>
    </Fragment>
  );
}

export const validateCheck = pushAlarm => {
  if (!validateIsNull(pushAlarm.platformCode)){
    alert('Platform is required');
    return false;
  }
  if (!validateIsNull(pushAlarm.platformName)){
    alert('OS is required');
    return false;
  }
  if (!validateLength(pushAlarm.countryCodes)){
    alert('Country is required');
    return false;
  }
  if (!validateIsNull(pushAlarm.title)){
    alert('Title is required');
    return false;
  }
  if (!validateIsNull(pushAlarm.lang)){
    alert('Language is required');
    return false;
  }
  return true;
};

export const validateIsNull = object => {
  if (object !== null && object !== ''){
    return true;
  } else {
    return false;
  }
};

export const validateLength = countryCodes => {
  if (countryCodes.length > 0){
    return true;
  } else {
    return false;
  }
};

PushAlarm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  pushAlarmlist: PropTypes.array.isRequired,
  getPushAlarmList: PropTypes.func.isRequired,
  updatePushAlarm: PropTypes.func.isRequired,
  regionCountries: PropTypes.array.isRequired,
  getSupportLanguages: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  fetching: state.pushAlarm.fetching,
  pushAlarmlist: state.pushAlarm.pushAlarmlist,
  regionCountries: state.cntry.regionCountries,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  getPushAlarmList(title, countryCode, state) {
    dispatch(getPushAlarmList(title, countryCode, state));
  },
  updatePushAlarm: data => dispatch(updatePushAlarm(data)),
  getSupportLanguages: () => dispatch(getSupportLanguagesAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter((PushAlarm)));
