import * as actions from '../../actions/actionTypes';

const initialState = { list: [], isDataLoad: false };

export default function addRSChannelIds(state = initialState, action) {
  switch (action.type) {
    case 'GET_ADD_RS_CHANNEL_IDS':
      return action.payload;
    default:
      return state;
  }
}
