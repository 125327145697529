import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function detailcountries(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DETAIL_COUNTRY:
      let result = [];
      if (action.payload.length) {
        result = action.payload.map(v => {
          return v.selectValue;
        });
      }
      return result;
    default:
      return state;
  }
}
