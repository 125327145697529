import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

function ModalPopup(props) {
  const { isOpen, title } = props;

  const showTitle = title ? title : 'Jira Processing';

  return (
    <>
      <Dialog open={isOpen} className="modal-popup">
        <DialogTitle className="modal-title">
          {showTitle}
        </DialogTitle>
        <DialogContent className="modal-contents">
          <p className="modal-image">Processing...</p>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ModalPopup;
