import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
//import NotificationDetail from './NotificationDetail';
//import NotificationEdit from './NotificationEdit';
//import { getAdminsAction } from '../../../actions/providerActions';
import { getAdminsAction } from '../../../actions/userActions';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
// 목록
const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1100,
      // marginLeft: 'auto',
      // marginRight: 'auto',
    },
  },
});

export function Notification({ classes, admins, getAdminsAction, history }) {
  // const loadUserList = useCallback(() => {
  //   if (username) {
  //     getProvidersAdminAction();
  //   }
  //   console.log('[username]', username);
  //   console.log('[providers]', providers);
  // }, [getProvidersAdminAction, username]);

  useEffect(() => {
    getAdminsAction();
  }, []);

  // useEffect(() => {
  //   if (providers.length === 0) {
  //     loadUserList();
  //   }
  // }, [loadUserList, providers.length]);

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <div className="filter_area">
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label="Title" //focusì•ˆì¤¬ì�„ë•Œ mandatory text
            //value={id}
          />
        </div>
        <div className="btn_set_wrap search_wrap">
          <Button variant="contained" className={'btn_color2'}>
            Search
          </Button>
        </div>
        <div className={'tbl_wrap'}>
          <div className={'btn_set_wrap tbl_top1'}>
            <Button
              color="secondary"
              variant="contained"
              className={'btn_color2'}
              onClick={() => {
                history.push('/SYSTEM/notificationCreate');
              }}>
              Add
            </Button>
          </div>
          <MaterialTable
            title=" " //없으면 Table Title이 default title로 나옴
            columns={[
              {
                title: 'No',
                field: 'No',
              },
              {
                title: 'Business Area',
                field: 'Business Area',
              },
              {
                title: 'Title',
                field: 'Title',
                render: data => (
                  <Link className="link" to={`/SYSTEM/notification/${data.id}`}>
                    {data.Title}
                  </Link>
                ),
              },
              {
                title: 'Display',
                field: 'Display',
              },
              {
                title: 'Writer',
                field: 'Writer',
              },
              {
                title: 'Created',
                field: 'Created',
              },
            ]}
            data={admins.map((n, i) => {
              let newData = {};
              newData['No'] = i + 1;
              newData['Business Area'] = 'All';
              newData['Title'] = 'Test';
              newData['Display'] = 'Y';
              newData['Writer'] = n.name;
              newData['Created'] = n.createdAt;
              //   //console.log('newData=',newData);
              return newData;
            })}
            options={{
              pageSize: 10,
              rowStyle: { backgroundColor: '#fff' },
              // filtering: true,
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
Notification.propTypes = {
  classes: PropTypes.object.isRequired,
  //fetching: PropTypes.bool.isRequired,
  getUsersAction: PropTypes.func,
  providers: PropTypes.array,
  username: PropTypes.string,
};

const mapStateToProps = state => ({
  //providers: state.providers.providers,
  //fetching: state.whitelistIps.fetching,
  admins: state.users.admins,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  getAdminsAction() {
    dispatch(getAdminsAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Notification)));
