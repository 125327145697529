import * as actions from '../../actions/actionTypes';

const initialState = { result: false, msg: '' };
export default function publishChannelName(state = initialState, action) {
    switch (action.type) {
        case actions.SET_CHANNELMAP_PUBLISH_DATA:
            return action.payload;
        case actions.SET_CHANNELMAP_PUBLISH_FAIL:
            return action.payload;
        default:
            return state;
    }
}