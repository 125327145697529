import React, { useEffect, useState, useRef, useCallback } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import { ROWHEADER_TYPE, TABLE_TYPE } from '../consts/IssConsts';
import ListDropDown from './ListDropDown';
import FixedDropDown from './FixedDropDown';
import { defaultValue } from '../utils/IssUtils';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {TextareaAutosize} from "@material-ui/core";

export const DEFAULT_HEIGHT = 'calc(100% - 40px)';
export const DEFAULT_PAGE_HEIGHT = 'calc(100% - 44px)';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  sticky_first_line: {
    position: 'sticky',
    top: 0,
  },
  sticky_second_line: {
    position: 'sticky',
    top: '41px',
  },
});

const IssTable = props => {
  const defaultItemsPerPage = 20; // 페이지당 default 항목 수
  const classes = useStyles();

  const { height, pagination, data, handleClickItem, itemsPerPage, pagingChangeCallback
    , handleDropDownList, handleCheckedList, isCheckboxValueInit, handleAssignDropDownList
    , dropdownDirection, sortingItem, changeDropDownAlert, handleExcelDownload, handlePage, handleEditedData
    , selectFirstRow } = props;
  const [selectedColumn, setSelectedColumn] = useState('init');

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1); // Pagination의 count
  const [originalData, setOriginalData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);

  const [isDropDownValueInit, setDropDownValueInit] = useState(false);
  const [displayItemCountPerPage, setDisplayItemCountPerPage] = useState(defaultItemsPerPage);

  const [isSortingEnable, setSortingEnable] = useState(false);
  const [sortColumn, setSortColumn] = useState(0);
  const [sortDirection, setSortDirection] = useState('desc');
  const [hoveredColumn, setHoveredColumn] = useState(null);

  // Dashboard 화면에서 mouse down과 up 사이의 시간이 300ms 이내인 경우에만 click이 발생하고 그 외는 drag로 인식하도록 함
  const [mouseDownTime, setMouseDownTime] = useState(0);
  const [clickDuration, setClickDuration] = useState(0);
  const clickThreshold = 300;

  const [isEditing, setIsEditing]= useState({});
  const [editedData, setEditedData] = useState({})

  const handleTextClick = (id) => {
    if (clickDuration > clickThreshold) {
      return;
    }
    setIsEditing({ ...isEditing, [id]: true });
  };

  const handleInputBlur = (e, id, rowIdx, colIdx) => {
    setIsEditing({ ...isEditing, [id]: false });
    const newData = [...originalData]
    newData[rowIdx]['row'][colIdx] = e.target.value
    setOriginalData(newData)
    setEditedData({...editedData, [rowIdx]: newData[rowIdx]['row']})
  };

  useEffect(() => {
    if (handleEditedData) {
      handleEditedData(editedData);
    }
  }, [editedData]);

  const displayItemCount = [
    { index: 20, name: 20 },
    { index: 50, name: 50 },
    { index: 100, name: 100 },
  ];

  /**
   * data를 이용한 기본값 설정
   * 데이터에 필수값이 없는 경우 오류발생을 줄이기 위해 default값 설정처리
   */
  const issTableData = {
    tableType: defaultValue(data.tableType, TABLE_TYPE.NORMAL),
    isCheckboxSelection: defaultValue(data.tableType, TABLE_TYPE.NORMAL) === TABLE_TYPE.CHECKBOX_SELECTION ? true : false,
    title: defaultValue(data.title, '제목'),
    visibleTitle: defaultValue(data.visibleTitle, false),
    existTooltip: defaultValue(data.existTooltip, false),
    tooltip: defaultValue(data.tooltip, 'tooltip text..!!'),
  };

  var customHeight = height;

  if (!height) {
    customHeight = DEFAULT_HEIGHT;
  }
  if (!height && pagination) {
    customHeight = DEFAULT_PAGE_HEIGHT;
  }
  const tableRef = useRef(null);
  const DEFAULT_CELL_STYLE = ROWHEADER_TYPE.NORMAL;

  useEffect(() => {
    if (data && data.rowdata) {
      setOriginalData(data.rowdata);
      setCount(Math.ceil(data.rowdata.length / displayItemCountPerPage));
      setCheckedLists([])
      if (selectFirstRow && data.rowdata.length > 0) {
        if (data.rowdata[0].deeplinkFlag) {
          setSelectedColumn('init');
        } else {
          setSelectedColumn('row_0')
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (originalData) {
      if (pagination) {
        const startIndex = (page - 1) * displayItemCountPerPage;
        const endIndex = startIndex + displayItemCountPerPage;
        setDisplayedData(originalData.slice(startIndex, endIndex));
      } else {
        setDisplayedData(originalData);
      }
    }
  }, [originalData]);

  useEffect(() => {
    setDropDownValueInit(false);
    if (pagination) {
      tableRef && tableRef.current.scrollTo(0, 0);
      const startIndex = (page - 1) * displayItemCountPerPage;
      const endIndex = startIndex + displayItemCountPerPage;
      setDisplayedData(originalData.slice(startIndex, endIndex));
    }
    if(handlePage != null && handlePage != undefined){
      if(Math.ceil(data.rowdata.length / displayItemCountPerPage) >= handlePage){
        setPage(handlePage);
      } else {
        setPage(Math.ceil(data.rowdata.length / displayItemCountPerPage));
      }
    }
  }, [page]);

  useEffect(() => {
    if (data && data.rowdata) {
      setCount(Math.ceil(data.rowdata.length / displayItemCountPerPage));
      const startIndex = (page - 1) * displayItemCountPerPage;
      const endIndex = startIndex + displayItemCountPerPage;
      setDisplayedData(originalData.slice(startIndex, endIndex));
    }
  }, [displayItemCountPerPage]);

  useEffect(() => {
    setDisplayItemCountPerPage(defaultItemsPerPage);
    if (sortingItem && sortingItem.enable) {
      setSortingEnable(true);
      setSortColumn(sortingItem.sortColumn ? sortingItem.sortColumn : 0);
      setSortDirection(sortingItem.sortDirection ? sortingItem.sortDirection : 'desc');
    }
    return () => {
      const ptableref = tableRef.current;
      if (ptableref) {
        ptableref.remove();
      }
    };
  }, []);

  /**
   * page click event
   *
   * @param {*} event
   * @param {*} value page no
   */
  const onPagingChangeHandlder = (event, value) => {
    setDropDownValueInit(true);
    setSelectedColumn('');
    setPage(value);
    if (pagingChangeCallback) {
      pagingChangeCallback(value);
    }
  };

  //테이블 체크박스
  const [checkedList, setCheckedLists] = useState([]);

  useEffect(() => {
    if (isCheckboxValueInit) {
      setCheckedLists([]);
    }
  }, [isCheckboxValueInit]);

  useEffect(() => {
    if (handleCheckedList) {
      handleCheckedList(checkedList);
    }
  }, [checkedList]);

  const onCheckedAll = useCallback(
    checked => {
      if (checked) {
        const checkedListArray = [];
        displayedData.forEach(item => checkedListArray.push(item));
        setCheckedLists(checkedListArray);
      } else {
        setCheckedLists([]);
      }
    },
    [displayedData],
  );

  const onCheckedEl = useCallback(
    (checked, list) => {
      if (checked) {
        setCheckedLists([...checkedList, list]);
      } else {
        setCheckedLists(checkedList.filter(el => el !== list));
      }
    },
    [checkedList],
  );

  const handleClick = (key, rowItem) => {
    if (clickDuration > clickThreshold) {
      return;
    }
    if (handleClickItem) {
      handleClickItem(rowItem);
    }
    setSelectedColumn(key);
  };

  const handleMouseDown = (event) => {
    setMouseDownTime(Date.now());
  };

  const handleMouseUp = (event) => {
    const mouseUpTime = Date.now();
    const processingTime = mouseUpTime - mouseDownTime;
    setClickDuration(processingTime);
  };

  const handleDisplaySize = (item) => {
    setDisplayItemCountPerPage(item.index);
  };

  /**
   * title영역 All Checkbox
   * @param {*} data
   * @returns
   */
  const drawTitleAllCheckbox = data => {
    return (
      <TableCell>
        <label className="checkCount">
          <input type="checkbox" onChange={e => onCheckedAll(e.target.checked)} checked={checkedList.length === 0 ? false : checkedList.length === data.length ? true : false} />
          <span></span>
          <div>({checkedList.length})</div>
        </label>
      </TableCell>
    );
  };

  /**
   * row영역 Item별 Checkbox
   * @param {*} data
   * @returns
   */
  const drawItemCheckbox = data => {
    return (
      <TableCell>
        <label>
          <input type="checkbox" onChange={e => onCheckedEl(e.target.checked, data)} checked={checkedList.includes(data) ? true : false} onClick={e => e.stopPropagation()} />
          <span onClick={e => e.stopPropagation()}></span>
        </label>
      </TableCell>
    );
  };

  const updateDropdownList = (type, value) => {
    let headerIndex = -1;
    if (type === 'Status') {
      headerIndex = data.header.indexOf('Status');
    } else if (type === 'Assign') {
      headerIndex = data.header.indexOf('Assign');
    }

    if (headerIndex > -1 && checkedList) {
      displayedData.forEach(item => {
        if (checkedList.find(data =>
          ((data.issueId && data.issueId === item.issueId) || (data.groupId && data.groupId === item.groupId)))) {
          item.row[headerIndex] = value;
        }
      });
    }
  };

  const onChangeDropDownList = (item) => {
    if (item && item.isChecked && handleDropDownList) {
      updateDropdownList('Status', item.status);
      handleDropDownList(item);
    } else if (changeDropDownAlert) {
      changeDropDownAlert();
    }
  };

  const onChangeAssignDropDownList = (item) => {
    if (item && item.isChecked && handleAssignDropDownList) {
      updateDropdownList('Assign', item.status);
      handleAssignDropDownList(item);
    } else if (changeDropDownAlert) {
      changeDropDownAlert();
    }
  };

  const handleJiraUrl = (e, url) => {
    const jiraDomainUrl = 'https://hlm.lge.com/qi/browse/';
    e.stopPropagation();
    window.open(jiraDomainUrl + url, '_blank');
  };

  const handleTargetBlankUrl = (e, url) => {
    e.stopPropagation();
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleExcelDownlaod = (e, item) => {
    e.stopPropagation();
    if (handleExcelDownload) {
      handleExcelDownload(item);
    }
  };

  useEffect(() => {
    // Deeplink 로 들어오는 경우가 있어서 최초는 정렬하지 않도록 함
    if (selectedColumn === 'init') {
      return;
    }
    const convertValue = (value) => {
      if (typeof value === 'number') {
        return parseFloat(value);
      } else {
        const newValue = Number(value.replace(/,|%|/g, ''));
        if (isNaN(newValue)) {
          return value;
        } else {
          return newValue;
        }
      }
    };
    if (sortingItem && sortingItem.enable) {
      const sortedRows = [...data.rowdata].sort((a, b) => {
        const aValue = a.row[sortColumn] ? a.row[sortColumn] : '';
        const bValue = b.row[sortColumn] ? b.row[sortColumn] : '';
        if (typeof aValue == 'object' || typeof bValue === 'object') {
          return 0;
        }
        const newValueA = convertValue(aValue);
        const newValueB = convertValue(bValue);
        if (!isNaN(newValueA) && !isNaN(newValueB)) {
          return sortDirection === 'asc' ? newValueA - newValueB : newValueB - newValueA;
        } else {
          if (isNaN(newValueA) && isNaN(newValueB)) {
            const tempA = newValueA.split('-');
            const tempB = newValueB.split('-');
            if (tempA.length === 2 && tempB.length === 2) {
              if (tempA[0] === tempB[0]) {
                return sortDirection === 'asc' ? parseFloat(tempA[1]) - parseFloat(tempB[1]) : parseFloat(tempB[1]) - parseFloat(tempA[1]);
              } else {
                return sortDirection === 'asc' ? newValueA.localeCompare(newValueB) : newValueB.localeCompare(newValueA);
              }
            } else {
              return sortDirection === 'asc' ? newValueA.localeCompare(newValueB) : newValueB.localeCompare(newValueA);
            }
          } else {
            const changeValueA = typeof newValueA === 'number' ? newValueA.toString() : newValueA;
            const changeValueB = typeof newValueB === 'number' ? newValueB.toString() : newValueB;
            return sortDirection === 'asc' ? changeValueA.localeCompare(changeValueB) : changeValueB.localeCompare(changeValueA);
          }
        }
      });
      setOriginalData(sortedRows);
    }
  }, [sortColumn, sortDirection, data.rowdata]);

  const handleSort = (column) => {
    if (isSortingEnable) {
      setSelectedColumn('');
      const isSameColumn = sortColumn === column;
      const newSortDirection = isSameColumn ? (sortDirection === 'asc' ? 'desc' : 'asc') : sortDirection;
      setSortDirection(newSortDirection);
      setSortColumn(column);
    }
  };

  const ellipsisText = (text, size) => {
    let returnText = '';
    if (text && text.length > size) {
      returnText = text.substring(0, size) + '...';
    } else {
      return text;
    }
    return returnText;
  };

  /**
   * draw row item by row type
   * @param {*} item
   * @param {*} _colIdx
   * @param {*} _rowIdx
   * @returns
   */
  const drawRowItemByRowType = (groupId, issueId, item, _colIdx, _rowIdx) => {
    let cellStyle = DEFAULT_CELL_STYLE;
    try {
      cellStyle = data.cellStyle === undefined ? DEFAULT_CELL_STYLE : data.cellStyle[_colIdx];
    } catch (error) {
      console.error('##ISSUE Table Error:', error);
    }

    if (cellStyle === ROWHEADER_TYPE.FIRST) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`} component="th" scope="row">
          {item}
        </TableCell>
      );
    }

    if (cellStyle === ROWHEADER_TYPE.NORMAL) {
      return <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>{item}</TableCell>;
    }

    if (cellStyle === ROWHEADER_TYPE.LONG) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          <div className="ellipsis">{item}</div>
        </TableCell>
      );
    }

    if (cellStyle === ROWHEADER_TYPE.DROPDOWN) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          <ListDropDown
            groupId={groupId}
            issueId={issueId}
            items={data.dropdownItem}
            title={item}
            isDropDownValueInit={isDropDownValueInit}
            onChange={onChangeDropDownList}
            checkedList={checkedList}
          />
        </TableCell>
      );
    }

    // ASSIGN_DROPDOWN
    if (cellStyle === ROWHEADER_TYPE.ASSIGN_DROPDOWN) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          <ListDropDown
            groupId={groupId}
            issueId={issueId}
            items={data.assignDropdownItem}
            title={item}
            isDropDownValueInit={isDropDownValueInit}
            onChange={onChangeAssignDropDownList}
            checkedList={checkedList}
          />
        </TableCell>
      );
    }

    if (cellStyle === ROWHEADER_TYPE.JIRALINK) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          <button onClick={(e) => handleJiraUrl(e, item)}>{item}</button>
        </TableCell>
      );
    }
    if (cellStyle === ROWHEADER_TYPE.BLANK_URL_LINK) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          <button onClick={(e) => handleTargetBlankUrl(e, item)}>{item}</button>
        </TableCell>
      );
    }
    if (cellStyle === ROWHEADER_TYPE.BLANK_LONG_URL_LINK) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          <button onClick={(e) => handleTargetBlankUrl(e, item)}>{ellipsisText(item, 40)}</button>
        </TableCell>
      );
    }
    if (cellStyle === ROWHEADER_TYPE.DOWNLOAD_URL) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          {(item !== undefined) && (<button onClick={(e) => handleTargetBlankUrl(e, item)}>URL</button>)}
        </TableCell>
      );
    }

    if (cellStyle === ROWHEADER_TYPE.PLATFORM_DROPDOWN || cellStyle === ROWHEADER_TYPE.REGION_DROPDOWN || cellStyle === ROWHEADER_TYPE.COUNTRY_DROPDOWN) {
      let labelText = '';
      if (item.length > 1) {
        return (
          <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
            <FixedDropDown
              items={item}
              label={item.length + labelText}
            />
          </TableCell>
        );
      } else if (item.length === 1) {
        return (
          <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
            {item[0]}
          </TableCell>
        );
      } else {
        return (
          <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
            {''}
          </TableCell>
        );
      }
    }

    if (cellStyle === ROWHEADER_TYPE.EXCEL_DOWNLOAD_BUTTON) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}>
          <button class="btn-icon-download" onClick={(e) => handleExcelDownlaod(e, item)}>
            <i></i>
            <span>Excel Download</span>
          </button>
        </TableCell>
      );
    }

    if (cellStyle === ROWHEADER_TYPE.EDITABLE) {
      return (
        <TableCell key={`${_colIdx}_${_rowIdx}_tablecell_${item}`} onClick={() => handleTextClick(`${_colIdx}_${_rowIdx}_tablecell_${item}`)} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
          {isEditing[`${_colIdx}_${_rowIdx}_tablecell_${item}`] ? (
            <TextareaAutosize
              key={`${_colIdx}_${_rowIdx}_tablecell_${item}`}
              className={'editable-area'}
              autoFocus={true}
              defaultValue={item}
              onFocus={e => {
                if (item && item.length > 0) {
                  e.target.setSelectionRange(item.length, item.length);
                }
              }}
              onBlur={e => handleInputBlur(e, `${_colIdx}_${_rowIdx}_tablecell_${item}`, _rowIdx, _colIdx)}
            />
          ) : (
            // Edit 창 전환 효과를 위해 태그 구분
            <p className={'editable'} placeholder={'Comment here'}>
              {item}
            </p>
          )}
        </TableCell>
      );
    }
  };

  const drawHeaderTop = headerTopData => {
    return (
      <TableRow id={'historyHeaderTop'}>
        <>
          {headerTopData.map((headerItem, idx) => {
            return (
              <TableCell key={`htop_${idx}`} rowSpan={headerItem.rowspan} colSpan={headerItem.colspan} className={classes.sticky_first_line}>
                {headerItem.value}
              </TableCell>
            );
          })}
        </>
      </TableRow>
    );
  };

  return (
    <>
      {data.visibleTitle && (
        <div className="head">
          <div className="title">
            <h2>{issTableData.title}</h2>
            {issTableData.existTooltip && (
              <div className="ui-tooltip">
                <i>툴팁</i>
                <div className="tooltip">{issTableData.tooltip}</div>
              </div>
            )}
          </div>
        </div>
      )}
      <TableContainer ref={tableRef} component={Paper} className="tableWrapper" style={{ height: `${customHeight}` }}>
        <Table stickyHeader={true} aria-label="simple table">
          <colgroup>
            {issTableData.isCheckboxSelection && <col width={'3%'} key={'all'}></col>}
            {
              /* column width */
              data.cellWidth && data.cellWidth.map((width, index) => <col width={width} key={index}></col>)
            }
          </colgroup>
          <TableHead>
            {data.headerTop && drawHeaderTop(data.headerTop)}
            <TableRow>
              {issTableData.isCheckboxSelection && drawTitleAllCheckbox(displayedData)}
              {data.header.map((_header, index) => (
                <TableCell key={_header + '_' + index} className={data.headerTop && classes.sticky_second_line}
                  onClick={() => isSortingEnable ? handleSort(index) : ''}
                  onMouseEnter={() => isSortingEnable ? setHoveredColumn(index) : ''}
                  onMouseLeave={() => isSortingEnable ? setHoveredColumn(null) : ''}
                >
                  {_header}
                  {isSortingEnable && (sortColumn === index || hoveredColumn === index) && (
                    <>
                      {sortDirection === 'asc' && sortColumn === index ? (
                        <ArrowUpwardIcon style={{ fontSize: '16px', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} />
                      ) : (
                        <ArrowDownwardIcon style={{ fontSize: '16px', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} />
                      )}
                    </>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.map((rowItem, _rowIdx) => (
              <TableRow key={`row_${_rowIdx}`} style={{ backgroundColor: selectedColumn === `row_${_rowIdx}` || (selectedColumn === 'init' && rowItem.deeplinkFlag) ? '#302d3e' : '#1c1c1c' }}
                onClick={() => handleClick(`row_${_rowIdx}`, rowItem)}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
              >
                {
                  //rowItem 중 첫번째 checkbox
                  issTableData.isCheckboxSelection && drawItemCheckbox(rowItem)
                }
                {rowItem.row.map((colItem, _colIdx) => drawRowItemByRowType(rowItem.groupId, rowItem.issueId, colItem, _colIdx, _rowIdx))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination ? (
        <div className='pagination_ui'>
          <div className='list_dropdown'>
            <ListDropDown items={displayItemCount} title={displayItemCountPerPage} onChange={handleDisplaySize} dropdownDirection={dropdownDirection} />
          </div>
          <Pagination count={count} page={page} showFirstButton showLastButton onChange={onPagingChangeHandlder} className="ui-pagination" />
        </div>
      ) : (
        <></>
      )
      }
    </>
  );
};

export default IssTable;
