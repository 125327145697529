import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

function ListDropDown(props) {
  const { items, title, isDropDownValueInit, onChange, issueId, dropdownDirection, checkedList, groupId } = props;

  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState(title);

  useEffect(() => {
    if (isDropDownValueInit) {
      setLabel(title);
    }
  }, [isDropDownValueInit]);

  const onBlur = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const onClick = e => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onSetLabel = (e, item) => {
    e.stopPropagation();

    const isChecked = checkedList && checkedList.find(item =>
      ((issueId && item.issueId === issueId) || (groupId && item.groupId === groupId))) ? true : false;

    if (isChecked) {
      setLabel(item);
    }

    onChange({ groupId: groupId, issueId: issueId, status: item.name, index: item.index, isChecked: isChecked });
    setOpen(false);
  };

  return (
    <>
      <div tabIndex={0} className={classNames('ui-dropdown-type02')} onBlur={e => onBlur(e)}>
        <div className="label" onClick={e => onClick(e)}>
          {label.name || title}
        </div>
        <ul className={open ? dropdownDirection && dropdownDirection === 'up' ? 'show-up' : 'on' : ''}>
          {items && items.map((item, index) => (
            <li key={index} onClick={(e) => onSetLabel(e, item)}>
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default ListDropDown;