import { combineReducers } from 'redux';
import feedlist from './feedlist';
import countries from './countries';
import feedDetail from './feedDetail';
import size from './size';
import page from './page';
import detailcountries from './detailcountries';
import totalElements from './totalElements';
import feedEdit from './feedEdit';
import failmessage from './failmessage';
export default combineReducers({
  feedlist,
  size,
  page,
  countries,
  detailcountries,
  totalElements,
  feedDetail,
  feedEdit,
  failmessage,
});
