import * as actions from '../../actions/actionTypes';
const initialState = [];
export default function channelChannelConfView(state = initialState, action) {
  switch (action.type) {
    // SET_CHANNEL_CHANNEL_CONF_VIEW
    case actions.SET_CHANNEL_CHANNEL_CONF_VIEW:
      //
      if (action.payload.list) {
        return [...state, ...action.payload.list];
      } else {
        return state;
      }
    default:
      return state;
  }
}
