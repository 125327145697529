import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { HeaderMenuList, InsideANTestMenuList } from '../AppHeader'; // body 중간에서 import하면 에러남
//import { DRAWER_WIDTH } from '../../../constants';
import { setHeaderMenuAction } from '../../../actions/uiActions';
import sidebarStyle from '../../../resources/jss/material-dashboard-react/components/sidebarStyle';
import { DoubleArrowTwoTone } from '@material-ui/icons';

const styles = theme => ({
  ...sidebarStyle(theme),
  // drawerOpen: {
  //   width: DRAWER_WIDTH,
  // },
  drawerClose: {
    overflowX: 'hidden',
    [theme.breakpoints.down('lg')]: {
      width: 0,
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolbarFooter: {
    right: 0,
    bottom: 0,
    position: 'absolute',
  },
  collapseIcon: {
    color: 'gray',
  },
  collapseActive: {
    transform: 'rotate(180deg) !important',
  },
  // listItem: {
  // 	height: 50 //메뉴간 높이 정확히 50px로 맞춰짐
  // }
});

function Links({ classes, item, location, pathName }) {
  const pathLocal = '/' + location.pathname.split('/').slice(1,3).join('/').toUpperCase();
  return (
    <Link to={item.path} className={classNames(classes.item)}>
      <ListItem button selected={item.path.toUpperCase() === pathLocal} className={classes.listItem}>
        <ListItemText className={classes.itemText} primary={item.label} disableTypography={true} />
      </ListItem>
    </Link>
  );
}

function AppDrawer({ classes, drawerOpen, headerMenu, isRoleAdmin, location, isAuthenticated, setHeaderMenuAction }) {
  let currentHeaderMenu;
  const [currentSideMenu, setCurrentSideMenu] = useState([]);
  const [isLevel3MenuOpened, setIsLevel3MenuOpened] = useState(true);
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [isCmpSite, setCmpSite] = useState(true);

  useEffect(() => {
    setHeaderMenuAction(location.pathname.split('/')[1]);
    if (location && location.pathname) {
      if (location.pathname.includes('/ISS')) {
        setCmpSite(false);
      } else {
        setCmpSite(true);
      }
    }
  }, [location]);

  useEffect(() => {
    currentHeaderMenu = HeaderMenuList.find(i => i.menu === headerMenu); //not found, return undefined
    if (currentHeaderMenu) {
      let _modifiedDetailMenu = currentHeaderMenu.detailMenu.filter(menu => isRoleAdmin || currentHeaderMenu.menu !== 'STATISTICS' || menu.label !== 'LG Channels Home')
      setCurrentSideMenu(_modifiedDetailMenu);
    }
  }, [headerMenu, isRoleAdmin]);

  //
  let arrPathname = location.pathname.split('/');
  let pathName = '';
  if (arrPathname.length === 2) {
    pathName = '/' + arrPathname[1];
  } else if (arrPathname.length === 3) {
    pathName = '/' + arrPathname[1] + '/' + arrPathname[2];
  } else if (arrPathname.length >= 4) {
    pathName = '/' + arrPathname[1] + '/' + arrPathname[2] + '/' + arrPathname[3];
  } else if (arrPathname.length > 3) {
    pathName = '/' + arrPathname[1] + '/' + arrPathname[2] + '/' + arrPathname[3];
  }
  return (
    <>
      {isAuthenticated && drawerOpen && (
        <Drawer
          variant="permanent"
          // className={classNames(classes.drawer, {
          //   [classes.drawerOpen]: drawerOpen,
          //   [classes.drawerClose]: !drawerOpen,
          // })}
          id={'snb'}
          className={isCmpSite ? classNames('', { 'collapse': drawerStatus }) : classNames('', { 'iss-collapse': drawerStatus })}
        >
          <div className={classes.toolbar} id={'dummy'}></div>{' '}
          <List className={classes.list + (isCmpSite ? ' snb_body' : ' snb_iss_body')}>
            {currentSideMenu.map(
              item =>
                (isRoleAdmin || !item.isBlockedForCp) &&
                (item.child && item.child.length ? (
                  <li
                    key={item.label}
                    className={isLevel3MenuOpened ? 'has_depth is_active' : 'has_depth'}
                    onClick={() => {
                      setIsLevel3MenuOpened(!isLevel3MenuOpened);
                    }}>
                    <Links classes={classes} item={item} location={location} />
                    {isLevel3MenuOpened && (
                      <ul>
                        {item.child.map(v => (
                          <li
                            className={pathName === v.path ? 'is_active' : undefined}
                            key={v.label}
                            onClick={e => {
                              e.stopPropagation();
                            }}>
                            <Link to={v.path} location={location}>
                              <span>{v.label}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ) : (
                  <li key={item.label}>
                      <Links classes={classes} item={item} location={location} />
                  </li>
                )),
            )}
          </List>
          <div
            className={classes.toolbarFooter}
            onClick={() => {
              setDrawerStatus(!drawerStatus);
            }}>
            <DoubleArrowTwoTone className={classNames(classes.collapseIcon, { [classes.collapseActive]: !drawerStatus })} />
          </div>
        </Drawer>
      )}
    </>
  );
}

Links.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
AppDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  headerMenu: PropTypes.string.isRequired,
  isRoleAdmin: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  setHeaderMenuAction: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  drawerOpen: state.ui.drawerOpen,
  headerMenu: state.ui.headerMenu,
  isRoleAdmin: state.currentUser.isRoleAdmin,
  isAuthenticated: state.currentUser.isAuthenticated,
});
const mapDispatchToProps = dispatch => ({
  setHeaderMenuAction(value) {
    dispatch(setHeaderMenuAction(value));
  },
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppDrawer)));
