import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

export default function CpCodeForm({ title, filter, handleInputChange, handleSubmit, handleClose, isCpCodeVisible }) {
  return (
    <div>
      <DialogTitle id="form-dialog-title" className={'pop_head'}>
        {title}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={'pop_body'}>
        <form onSubmit={handleSubmit}>
          <div className={'tbl_wrap'}>
            <table className={'tbl_row_typ1'}>
              <colgroup>
                <col style={{ width: '200px' }} />
                <col style={{ width: 'auto' }} />
              </colgroup>
              <tbody>
                {isCpCodeVisible && (
                  <tr>
                    <th>
                      <label htmlFor={'providerId'} className={'is_required'}>
                        CP Code
                      </label>
                    </th>
                    <td>
                      <TextField
                        //id="name"
                        //type="email"
                        //autoComplete="off"
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        name="providerId"
                        id="providerId"
                        //label="CP Code"//focus안줬을때 mandatory text
                        //placeholder="CP Code" //focus줬을때 mandatory text
                        value={filter.providerId.value}
                        onChange={event => handleInputChange(event)}
                        helperText={filter.providerId.errorMsg}
                        required
                        autoFocus
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <th>
                    <label htmlFor={'providerName'} className={'is_required'}>
                      Name
                    </label>
                  </th>
                  <td>
                    <TextField
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      name="providerName"
                      id="providerName"
                      //placeholder={editItem ? editItem['Name'] : ''}
                      value={filter.providerName.value}
                      onChange={event => handleInputChange(event)}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor={'lgPlayerFlag'} className={'is_required'}>
                      Use LG Player?
                    </label>
                  </th>
                  <td>
                    <TextField fullWidth margin="dense" variant="outlined" name="lgPlayerFlag" id="lgPlayerFlag" value={filter.lgPlayerFlag.value} onChange={event => handleInputChange(event)} required select>
                      <MenuItem name="lgPlayerFlag" value="Y">
                        Y
                      </MenuItem>
                      <MenuItem name="lgPlayerFlag" value="N">
                        N
                      </MenuItem>
                    </TextField>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor={'interactiveUrl'}>Interactive URL</label>
                  </th>
                  <td>
                    <TextField fullWidth margin="dense" variant="outlined" name="interactiveUrl" id="interactiveUrl" value={filter.interactiveUrl.value} onChange={event => handleInputChange(event)} helperText={filter.interactiveUrl.errorMsg} />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor={'logoUrl'}>CP Logo URL</label>
                  </th>
                  <td>
                    <TextField fullWidth margin="dense" variant="outlined" name="logoUrl" id="logoUrl" value={filter.logoUrl.value} onChange={event => handleInputChange(event)} />
                    {/* <DialogContentText>* marked is mandatory field</DialogContentText> */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'btn_set_wrap'}>
            <Button data-testid="addButton" color="primary" onClick={handleClose} className={'btn_color1'}>
              Cancel
            </Button>
            <Button data-testid="addButton" type="submit" color="secondary" className={'btn_color2'}>
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </div>
  );
}
CpCodeForm.propTypes = {
  title: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isCpCodeVisible: PropTypes.bool.isRequired,
};
