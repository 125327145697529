import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

function AppVersionForm({ title, filter, handleInputChange, handleSubmit, handleClose, isAppVersionVisible, allCountries }) {
  return (
    <div>
      <DialogTitle id="form-dialog-title" className={'pop_head'}>
        {title}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={'pop_body'}>
        <form onSubmit={handleSubmit}>
          <div className={'tbl_wrap'}>
            <table className={'tbl_row_typ1'}>
              <colgroup>
                <col style={{ width: '200px' }} />
                <col style={{ width: 'auto' }} />
              </colgroup>
              <tbody>
                {isAppVersionVisible && (
                  <tr>
                    <th>
                      <label htmlFor={'countryCode'} className={'is_required'}>
                        Country
                      </label>
                    </th>
                    <td>
                      <Select
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        name="countryCode"
                        id="countryCode"
                        value={filter.countryCode.value}
                        onChange={event => handleInputChange(event)}
                        helperText={filter.countryCode.errorMsg}
                        required select>
                        {[...allCountries].map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </td>
                  </tr>
                )}
                <tr>
                  <th>
                    <label htmlFor={'platformName'} className={'is_required'}>
                      Platform
                    </label>
                  </th>
                  <td>
                    <TextField
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      name="platformName"
                      id="platformName"
                      //placeholder={editItem ? editItem['Name'] : ''}
                      value={filter.platformName.value}
                      onChange={event => handleInputChange(event)}
                      helperText={filter.platformName.errorMsg}
                      required select>
                        <MenuItem name="platformName" value="Android">
                          Android
                        </MenuItem>
                        <MenuItem name="platformName" value="IOS">
                          IOS
                        </MenuItem>
                      </TextField>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor={'reqUpdate'} className={'is_required'}>
                      Update
                    </label>
                  </th>
                  <td>
                    <TextField fullWidth margin="dense" variant="outlined" name="reqUpdate" id="reqUpdate" value={filter.reqUpdate.value} onChange={event => handleInputChange(event)} helperText={filter.reqUpdate.errorMsg} required select>
                      <MenuItem name="reqUpdate" value="Y">
                        Y (Required)
                      </MenuItem>
                      <MenuItem name="reqUpdate" value="N">
                        N (Optional)
                      </MenuItem>
                    </TextField>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor={'versionName'} className={'is_required'}>Version Name</label>
                  </th>
                  <td>
                    <TextField fullWidth margin="dense" variant="outlined" name="versionName" id="versionName" value={filter.versionName.value} onChange={event => handleInputChange(event)} helperText={filter.versionName.errorMsg} required />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor={'versionCode'} className={'is_required'}>Version Code</label>
                  </th>
                  <td>
                    <TextField fullWidth margin="dense" variant="outlined" name="versionCode" id="versionCode" value={filter.versionCode.value} onChange={event => handleInputChange(event)} helperText={filter.versionCode.errorMsg} required />
                    {/* <DialogContentText>* marked is mandatory field</DialogContentText> */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'btn_set_wrap'}>
            <Button data-testid="addButton" color="primary" onClick={handleClose} className={'btn_color1'}>
              Cancel
            </Button>
            <Button data-testid="addButton" type="submit" color="secondary" className={'btn_color2'}>
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </div>
  );
}
AppVersionForm.propTypes = {
  allCountries: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  allCountries: state.cntry.allCountries,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AppVersionForm);
