import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
//import CircularProgress from '@material-ui/core/CircularProgress';
//import LoadingIndicator from '../../common/LoadingIndicator';
//import { getHeaders } from '../../util/actionUtil';
import { getTabCategoryAction } from '../../actions/homeAppActions';
import { request } from '../../util/request';
import CommonDialog from '../../common/dialog/CommonDialog';

function TabCategoryDelete({ categoryCode, tabCode, id, param, deleteCategory }) {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [descriptionDelete, setDescriptionDelete] = React.useState('Are you sure to delete this category ?');
  const [feedmapSdksDataLoading, setFeedmapSdksDataLoading] =  React.useState(false);

  return (
    <div>
      <React.Fragment>
        <Button
          className={'btn_size_tbl btn_color3'}
          onClick={() => {
            deleteCategory(
              {
                deviceType : param.deviceType,
                region: param.region,
                 platformVersion: param.platformVersion,
                  status: param.status,
                   tabCode: tabCode,
                    categoryCode: categoryCode,
                    id:id
              }
            );
          }}>
          Delete
        </Button>
        <CommonDialog
          open={openDelete}
          description={descriptionDelete}
          handleCancel={() => {
            setOpenDelete(false);
          }}
          handleOk={async () => {
            let isSuccess = await request(`/api/category/${id}`, 'DELETE');
            if (isSuccess) {
              alert('Successfully done !');
              getTabCategoryAction(param.deviceType, param.region, param.platformVersion, param.status.charAt(0));
              setOpenDelete(false);
            }
          }}
        />
      </React.Fragment>
    </div>
  );
}
TabCategoryDelete.propTypes = {};
const mapStateToProps = state => ({
  param: state.homeApp.param,
});
const mapDispatchToProps = dispatch => ({
  getTabCategoryAction(deviceType, region, platformVersion, status) {
    dispatch(getTabCategoryAction(deviceType, region, platformVersion, status));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TabCategoryDelete));
