import * as React from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, Button, DialogContent, FormControl, Select, MenuItem, InputLabel, TextField } from '@material-ui/core';
import { getAllChannelMapReservation, clearChannelMapReservation, setHistoryChannelMapIdList } from '../../../actions/channelMappingActions';
import CloseIcon from '@material-ui/icons/Close';

function Row({ row, handleClear }) {
  const [open, setOpen] = React.useState(false);
  const resTatus = reservationStatus => {
    if (reservationStatus === 'R') {
      return 'Ready';
    } else if (reservationStatus === 'D') {
      return 'Done';
    } else if (reservationStatus === 'C') {
      return 'Cancel';
    } else {
      return 'Error';
    }
  };
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.channelMapId}
        </TableCell>
        <TableCell>{row.saveType === 'T' ? 'Reservation For Test' : 'Reservation For Publish'}</TableCell>
        <TableCell>{row.reservationDate}</TableCell>
        <TableCell>{resTatus(row.reservationStatus)}</TableCell>
        <TableCell>{row.reservationResultMessage}</TableCell>
        <TableCell>{row.modifier}</TableCell>
        <TableCell>{row.crtDate}</TableCell>
        <TableCell>
          <div className={'btn_set_wrap_tbl'}>
            <Button variant="contained" className={'btn_color2 btn_size_tbl'} onClick={() => handleClear(row.idx, row.channelMapId, row.saveType == 'T' ? 'R-T' : 'R-P')} disabled={row.reservationStatus != 'R'}>
              Clear
            </Button>
          </div>
        </TableCell>
      </TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={9}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Channel No</TableCell>
                  <TableCell>Channel Id</TableCell>
                  <TableCell>Channel Name</TableCell>
                  <TableCell>Genre</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Modifier</TableCell>
                  <TableCell>DMA Group</TableCell>
                  <TableCell style={{ borderRight: 0 }}>Crt.Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.reservedChannelList.map(historyRow => (
                  <TableRow key={historyRow.chno}>
                    <TableCell>{historyRow.chno}</TableCell>
                    <TableCell>{historyRow.chid}</TableCell>
                    <TableCell>{historyRow.chname}</TableCell>
                    <TableCell>{historyRow.genre}</TableCell>
                    <TableCell>{historyRow.status}</TableCell>
                    <TableCell>{historyRow.action}</TableCell>
                    <TableCell>{historyRow.modifier}</TableCell>
                    <TableCell>{historyRow.dmaGrpName}</TableCell>
                    <TableCell style={{ borderRight: 0 }}>{historyRow.crtDate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
}

function ReservationDialog({ handleClose, system, ric, channelMapReservation, getAllChannelMapReservation, clearChannelMapReservation, saveTypeMap, channelMapId, publishStatus, histConfMap, setHistoryChannelMapIdList, cntryInfo, hcntry }) {
  const [hdonwloadType, setHdownloadType] = React.useState('ALL');
  const [hchannelMapId, setHchannelMapId] = React.useState(channelMapId ? channelMapId : 'ALL');
  const [reserveStatus, setReserveStatus] = React.useState('ALL');
  const [cntry, setCntry] = React.useState(hcntry);
  const today = new Date();
  const monthAgoDate = new Date();
  monthAgoDate.setMonth(monthAgoDate.getMonth() + 1);
  const [hstartDate, setHstartDate] = React.useState(today.toISOString().slice(0, 10));
  const [hendDate, setHendDate] = React.useState(monthAgoDate.toISOString().slice(0, 10));
  const reservationTypeListCDP = [
    { label: 'Reservation For Test', value: 'T' },
    { label: 'Reservation For Publish', value: 'P' },
  ];

  const reservationStatus = [
    { label: 'Ready', value: 'R' },
    { label: 'Done', value: 'D' },
    { label: 'Cancel', value: 'C' },
    { label: 'Error', value: 'E' },
  ];

  const handleClear = (reservedId, chMap, saveType) => {
    if (window.confirm('Are you sure to clear this?')) {
      clearChannelMapReservation(reservedId, chMap, saveType, publishStatus, channelMapId, cntry, hchannelMapId, hdonwloadType, hstartDate, hendDate, reserveStatus);
      return true;
    } else {
      return false;
    }
  };

  const countryChange = value => {
    setCntry(value);
    setHchannelMapId('ALL');
    setHdownloadType('ALL');
  };

  const channelMapChange = value => {
    setHchannelMapId(value);
  };

  React.useEffect(() => {
    if (channelMapReservation) {
      channelMapReservation.reservedList = [];
    }
  }, []);

  React.useEffect(() => {
    setHistoryChannelMapIdList(system, cntry, ric);
  }, [system, cntry, ric]);

  const historyChange = value => {
    setHdownloadType(value);
  };

  const historyStartDateChange = value => {
    setHstartDate(value);
    setHendDate('');
  };

  const historyEndDateChange = value => {
    setHendDate(value);
  };

  const reservetionStatusChange = value => {
    setReserveStatus(value);
  };

  return (
    <div>
      <Dialog open={true} aria-labelledby="form-dialog-title" className={'pop_wrap reservation_pop'}>
        <div className="MuiDialogTitle-root pop_head" id="form-dialog-title">
          <h2 className="MuiTypography-root MuiTypography-h6">
            Channel Map Reservations
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </h2>
        </div>
        <div className="mapping_tbl_header reservation">
          <FormControl variant="outlined">
            <InputLabel style={{ marginLeft: '10px' }}>Country</InputLabel>
            <Select label="Country" value={cntry} style={{ width: '100px', marginLeft: '10px' }} id="Country" onChange={e => countryChange(e.target.value)}>
              <MenuItem key="ALL" value="ALL">
                ALL
              </MenuItem>
              {cntryInfo.map(v => (
                <MenuItem key={v.cntry} value={v.cntry}>
                  {v.cntry}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel style={{ width: '100px', marginLeft: '20px' }}>Channel map</InputLabel>
            <Select label="Channel map" style={{ width: '215px', marginLeft: '20px' }} value={hchannelMapId ? hchannelMapId : ''} onChange={e => channelMapChange(e.target.value)}>
              {histConfMap && (
                <MenuItem key="ALL" value="ALL">
                  ALL
                </MenuItem>
              )}
              {histConfMap.mapList.map(item => (
                <MenuItem key={item.channelMapId} value={item.channelMapId}>
                  {item.channelMapId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel style={{ width: '100px', marginLeft: '20px' }}>Type</InputLabel>
            <Select label="Type" style={{ width: '215px', marginLeft: '20px' }} value={hdonwloadType} onChange={event => historyChange(event.target.value)}>
              <MenuItem key="ALL" value="ALL">
                ALL
              </MenuItem>
              {
                reservationTypeListCDP.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel style={{ width: '210px', marginLeft: '20px' }}>Reservation Status</InputLabel>
            <Select label="Reservation Status" style={{ width: '215px', marginLeft: '20px' }} value={reserveStatus} onChange={event => reservetionStatusChange(event.target.value)}>
              <MenuItem key="ALL" value="ALL">
                ALL
              </MenuItem>
              {
                reservationStatus.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <TextField
            style={{ marginLeft: '20px' }}
            variant="outlined"
            id="startDate"
            name="startDate"
            label="startDate"
            type="date"
            value={hstartDate ? hstartDate : ''}
            onChange={event => historyStartDateChange(event.target.value)}
            InputLabelProps={{
              shrink: true,
              required: true,
            }}
            inputProps={{ max: today.toISOString().slice(0, 10) }}
            required
          />

          <TextField
            variant="outlined"
            id="endDate"
            name="endDate"
            label="End Date"
            type="date"
            value={hendDate ? hendDate : ''}
            onChange={event => historyEndDateChange(event.target.value)}
            InputLabelProps={{
              shrink: true,
              required: true,
            }}
            inputProps={{ min: hstartDate }}
            required
            disabled={!hstartDate}
          />

          <Button
            style={{ marginLeft: '17px', padding: '0 15px', lineHeight: '34px' }}
            className="btn_color2"
            type="submit"
            onClick={() => {
              // ==== add Popup
              if (!hchannelMapId) {
                window.alert('Please select the chanelmap!');
                return;
              }
              // setHdownloadType(hdonwloadType);
              if (!hdonwloadType) {
                window.alert('Please select the resrvation type!');
                return;
              }
              if (!hstartDate) {
                window.alert('Please select resrvation start date!');
                return;
              }
              if (!hendDate) {
                window.alert('Please select resrvation end date!');
                return;
              }
              getAllChannelMapReservation(cntry, hchannelMapId, hdonwloadType, hstartDate, hendDate, reserveStatus);
              //getChannelMapInfoData(hchannelMapId, hdonwloadType, system, hstartDate, hendDate, ric, cntry, pointOfTime).then(response => {
              // setChannelInfo(response.list);
              //});
            }}>
            Search
          </Button>
        </div>
        <DialogContent className={'pop_body'}>
          <TableContainer component={Paper} className={'reserv_table_data'}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>ChannelMapId</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Reservation DateTime</TableCell>
                  <TableCell>Reservation Status</TableCell>
                  <TableCell>Result Message</TableCell>
                  <TableCell>Modifier</TableCell>
                  <TableCell>Create Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {channelMapReservation.reservedList.map(row => (
                  <Row key={row.name} row={row} handleClear={handleClear} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={'btn_set_wrap'}>
            <Button color="primary" onClick={handleClose} className={'btn_color1'}>
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

ReservationDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  getAllChannelMapReservation: PropTypes.func.isRequired,
  clearChannelMapReservation: PropTypes.func.isRequired,
  saveTypeMap: PropTypes.func,
  channelMapId: PropTypes.func,
  setHistoryChannelMapIdList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  channelMapReservation: state.channelMapping.channelMapReservation,
  histConfMap: state.channelMapping.histConfMap,
});

const mapDispatchToProps = dispatch => ({
  getAllChannelMapReservation: (cntry, hchannelMapId, hdonwloadType, hstartDate, hendDate, reserveStatus) => dispatch(getAllChannelMapReservation(cntry, hchannelMapId, hdonwloadType, hstartDate, hendDate, reserveStatus)),
  clearChannelMapReservation: (reservedId, chMap, saveType, publishStatus, channelMapId, cntry, hchannelMapId, hdonwloadType, hstartDate, hendDate, reserveStatus) =>
  dispatch(clearChannelMapReservation(reservedId, chMap, saveType, publishStatus, channelMapId, cntry, hchannelMapId, hdonwloadType, hstartDate, hendDate, reserveStatus)),
  setHistoryChannelMapIdList: (system, cntry, ric) => dispatch(setHistoryChannelMapIdList(system, cntry, ric)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReservationDialog);
