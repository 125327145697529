import React from 'react';

function IconButton(props) {
  const { type, text, btnClass, clickEvent, tooltip } = props;
  return (
    <>
      {type === 'a' && (
        <a href="#" className={btnClass}>
          <i></i>
          <span>{text}</span>
        </a>
      )}
      {type === 'button' && (
        <button className={btnClass} onClick={clickEvent}>
          <i></i>
          <span>{text}</span>
        </button>
      )}
      {tooltip && (
        <div className="title">
          <div className="ui-tooltip">
            <i>툴팁</i>
            <div className="tooltip">{tooltip}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default IconButton;
