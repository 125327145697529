import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '../../common/Loading';
import IssTable from '../../common/IssTable';
import LineChart from '../../common/LineChart';
import BarChart from '../../common/BarChart';
import Alert from '../../modal/Alert';
import xlsx from 'json-as-xlsx';
import annotationPlugin from 'chartjs-plugin-annotation';

import { CHART_COLORS } from '../../common/ChartConsts';
import { getHeaders } from '../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../constants/index'
import { content_history_chartdata, content_history_tabledata } from './IssueHistoryContentsData';

function IssueHistoryContentsCommon({ username, pageCurrentTab, currentFilter, downloadDataType, chartType }) {
  const plugin = [annotationPlugin];

  const [isErrorDisplay, setErrorDisplay] = useState(false);
  const [currentHistoryTableData, setCurrentHistoryTableData] = useState({});
  const [rangeDate, setRangeDate] = useState([]);
  const [isChartDisplay, setChartDisplay] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');

  const [feedError, setFeedError] = useState([]);
  const [adminError, setAdminError] = useState([]);
  const [batchError, setBatchError] = useState([]);
  const [feedErrorFetching, setFeedErrorFetching] = useState('init');
  const [adminErrorFetching, setAdminErrorFetching] = useState('init');
  const [batchErrorFetching, setBatchErrorFetching] = useState('init');

  useEffect(() => {
    if (currentFilter && currentFilter.date.length > 0 && currentFilter.subType !== 'All') {
      requestData('filter');
    }
  }, [currentFilter]);

  useEffect(() => {
    if (pageCurrentTab === 1) {
      content_history_tabledata.cp_issue_history.rowdata = feedError.map(item => ({
        row: [
          item.groupId,
          item.values[0].date.substring(2, 10),
          item.countries,
          item.cp,
          item.title,
          item.values[0].status,
          item.apis,
          item.values[0].issueAssignee,
          item.values[0].modifier,
          item.values[0].jiraUrl,
          item.values[0].actionDetails,
        ],
      }));

      setCurrentHistoryTableData(content_history_tabledata.cp_issue_history);
    } else if (pageCurrentTab === 2) {
      content_history_tabledata.organization_history.rowdata = adminError.map(item => ({
        row: [
          item.issueId,
          item.date.substring(2, 10),
          item.priority,
          item.country === 'All' ? 'ALL' : item.country,
          item.issueTitle,
          item.status,
          item.userName,
          item.functionName.length > 25 ? item.functionName.substring(0, 25) + '...' : item.functionName,
          item.functionParameter.length > 40 ? item.functionParameter.substring(0, 40) + '...' : item.functionParameter,
          item.issueAssignee,
          item.modifier,
          item.jiraUrl,
          item.actionDetails,
        ],
      }));

      setCurrentHistoryTableData(content_history_tabledata.organization_history);
    } else if (pageCurrentTab === 3) {
      content_history_tabledata.deployment_history.rowdata = batchError.map(item => ({
        row: [
          item.issueId,
          item.date.substring(2, 10),
          item.priority,
          item.issueTitle.length > 30 ? item.issueTitle.substring(0, 30) + '...' : item.issueTitle,
          item.status,
          item.jobName,
          item.startDate.substring(2, 10),
          item.endDate.substring(2, 10),
          item.executionTime,
          item.issueAssignee,
          item.modifier,
          item.jiraUrl,
          item.actionDetails,
        ],
      }));

      setCurrentHistoryTableData(content_history_tabledata.deployment_history);
    }

    if (feedErrorFetching === 'done' || adminErrorFetching === 'done' || batchErrorFetching === 'done') {
      setErrorDisplay(true);
    }
  }, [feedError, adminError, batchError]);

  useEffect(() => {
    if (downloadDataType === 'excel') {
      if (pageCurrentTab === 1 && feedError.length > 0) {
        let data = [];
        feedError.map(item => {
          const sheetData = {
            sheet: `${item.groupId}_Issue`,
            columns: [
              { label: 'Group ID', value: (row) => row.groupId },
              { label: 'Issue ID', value: (row) => row.issueId },
              { label: 'Date', value: row => row.date.substring(2,10)},
              { label: 'Priority', value: row => row.priority},
              { label: 'Country', value: row => row.country},
              { label: 'CP', value: row => row.providerId},
              { label: 'Channel/VOD', value: row => row.contentName},
              { label: 'Issue Code', value: row => row.issueCode},
              { label: 'Issue Title', value: row => row.issueTitle},
              { label: 'Status', value: row => row.status},
              { label: 'API Name', value: row => row.apiName},
              { label: 'File Name', value: row => row.filename},
              { label: 'Assign', value: row => row.issueAssignee},
              { label: 'Modifier', value: row => row.modifier},
              { label: 'Jira URL', value: row => row.jiraComment ? row.jiraUrl + ', ' + row.jiraComment : row.jiraUrl},
              { label: 'Action Details', value: row => row.actionDetails},
              { label: 'Issue Guide', value: row => row.issueGuide},
              { label: 'Cause Analysis', value: row => row.errorMessage.length > 30000 ? row.errorMessage.substring(0,30000) : row.errorMessage},
            ],
            content: [...item.values],
          };

          data.push(sheetData);
        });

        let xLSettings = {
          fileName: `CP_Issue`,
        };
        xlsx(data, xLSettings);
      } else if (pageCurrentTab === 2 && adminError.length > 0) {
        let data = [
          {
            sheet: `Organization_Issue`,
            columns: [
              { label: 'Issue ID', value: (row) => row.issueId },
              { label: 'Date', value: row => row.date.substring(2,10)},
              { label: 'Priority', value: row => row.priority},
              { label: 'Country', value: row => row.country},
              { label: 'Issue Code', value: row => row.issueCode},
              { label: 'Issue Title', value: row => row.issueTitle},
              { label: 'Status', value: row => row.status},
              { label: 'User Name', value: row => row.userName},
              { label: 'Function Name', value: row => row.functionName},
              { label: 'Function Parameter', value: row => row.functionParameter.length > 30000 ? row.functionParameter.substring(0,30000) : row.functionParameter},
              { label: 'Assign', value: row => row.issueAssignee},
              { label: 'Modifier', value: row => row.modifier},
              { label: 'Jira URL', value: row => row.jiraUrl},
              { label: 'Action Details', value: row => row.actionDetails},
            ],
            content: [...adminError],
          },
        ];
        let xLSettings = {
          fileName: `Organization_Issue`,
        };
        xlsx(data, xLSettings);
      } else if (pageCurrentTab === 3 && batchError.length > 0) {
        let data = [
          {
            sheet: `Deployment_Issue`,
            columns: [
              { label: 'Issue ID', value: (row) => row.issueId },
              { label: 'Date', value: row => row.date.substring(2,10)},
              { label: 'Priority', value: row => row.priority},
              { label: 'Issue Code', value: row => row.issueCode},
              { label: 'Issue Title', value: row => row.issueTitle},
              { label: 'Status', value: row => row.status},
              { label: 'Job Name', value: row => row.jobName},
              { label: 'Start Date', value: row => row.startDate.substring(2,10)},
              { label: 'End Date', value: row => row.endDate.substring(2,10)},
              { label: 'Execution Time', value: row => row.executionTime},
              { label: 'Assign', value: row => row.issueAssignee},
              { label: 'Modifier', value: row => row.modifier},
              { label: 'Jira URL', value: row => row.jiraUrl},
              { label: 'Action Details', value: row => row.actionDetails},
            ],
            content: [...batchError],
          },
        ];
        let xLSettings = {
          fileName: `Deployment_Issue`,
        };
        xlsx(data, xLSettings);
      }
    }
  }, [downloadDataType, feedError, adminError, batchError]);

  const requestData = (type) => {
    let changeFilter = {date:'', country:'', provider:'', platform:''};

    if (type === 'tab') {
      changeFilter.date = rangeDate;
    } else {
      changeFilter = currentFilter;
    }

    setErrorDisplay(false);
    setChartDisplay(false);
    if (pageCurrentTab === 1) {
      content_history_tabledata.cp_issue_history.rowdata = [];
      getContentHistoryInfo('feed', changeFilter);
      getContentsError('feed', changeFilter);
    } else if (pageCurrentTab === 2) {
      getContentHistoryInfo('admin', changeFilter);
      getContentsError('admin', changeFilter);
    } else if (pageCurrentTab === 3) {
      getContentHistoryInfo('batch', changeFilter);
      getContentsError('batch', changeFilter);
    }
  }

  const getContentHistoryInfo = (type, filter) => {
    fetch(`${ISS_BASE_URL}/api/application/dashboard/content/history/info?type=${type}&date=${filter.date}&country=${filter.country}&provider=${filter.provider}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          const issueCountDataSet = {
            label: 'content',
            data: [],
            fill: false,
            pointRadius: 3,
            backgroundColor: `${CHART_COLORS.PINK}`,
            borderColor: `${CHART_COLORS.PINK}`,
            pointBorderColor: '#FFFFFF',
            pointBackgroundColor: `${CHART_COLORS.PINK}`,
            pointBorderWidth: 2,
            order: 2,
          };
          const resolvedRateDataSet = {
            label: 'content',
            data: [],
            fill: false,
            pointRadius: 3,
            backgroundColor: `${CHART_COLORS.PINK}`,
            borderColor: `${CHART_COLORS.PINK}`,
            pointBorderColor: '#FFFFFF',
            pointBackgroundColor: `${CHART_COLORS.PINK}`,
            pointBorderWidth: 2,
            order: 2,
          };
          const accResolvedRateDataSet = {
            label: 'content',
            data: [],
            fill: false,
            pointRadius: 3,
            backgroundColor: `${CHART_COLORS.PINK}`,
            borderColor: `${CHART_COLORS.PINK}`,
            pointBorderColor: '#FFFFFF',
            pointBackgroundColor: `${CHART_COLORS.PINK}`,
            pointBorderWidth: 2,
            order: 2,
          };
          const options = {
            scales: {
              y: {
                min: 0,
                max: 0,
                ticks: {
                  stepSize: 10,
                },
              },
            }
          };

          const dateList = body.dataSet.map(item => item.date.substring(5,10));
          issueCountDataSet.data = body.dataSet.map(item => item.issueCount);

          const issueMaxCount = Math.max(...issueCountDataSet.data);
          options.scales.y.max = issueMaxCount + Math.ceil(issueMaxCount / 10);

          resolvedRateDataSet.data = body.dataSet.map(item => item.resolvedRate);
          accResolvedRateDataSet.data = body.dataSet.map(item => item.cumulativeResolveRate);

          content_history_chartdata.daily_contents_error_occurrence.data.labels = dateList;
          content_history_chartdata.daily_contents_error_occurrence.data.datasets=[];
          content_history_chartdata.daily_contents_error_occurrence.data.datasets.push(issueCountDataSet);
          content_history_chartdata.daily_contents_error_occurrence.options = options;

          content_history_chartdata.daily_contents_error_accumulated.data.labels = dateList;
          content_history_chartdata.daily_contents_error_accumulated.data.datasets=[];
          content_history_chartdata.daily_contents_error_accumulated.data.datasets.push(resolvedRateDataSet);

          content_history_chartdata.daily_contents_error_accumulated2.data.labels = dateList;
          content_history_chartdata.daily_contents_error_accumulated2.data.datasets=[];
          content_history_chartdata.daily_contents_error_accumulated2.data.datasets.push(accResolvedRateDataSet);

          setChartDisplay(true);

          if (issueMaxCount === 0) {
            setAlertTitle(`Info`);
            setAlertMessage(`No issues found`);
            onOpenAlert();
          }
        } else {
          setAlertMessage(`getContentHistoryInfo error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`getContentHistoryInfo error: `, error);
        onOpenAlert();
      })
      .finally(() => {});
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const getContentsError = (type, currentFilter) => {
    const issueStatusValue = 'Unaddressed,Processing,Resolve';
    const countryListValue = currentFilter && currentFilter.country.length > 0 ? currentFilter.country : '';
    const providerValue = currentFilter && currentFilter.provider.length > 0 ? currentFilter.provider : '';
    const dateList = currentFilter && currentFilter.date && currentFilter.date.length > 0 ? currentFilter.date : '';
    const userInfo = username === 'admin' ? 'cmpuser' : username;

    let requestUrl = '';

    if (type === 'feed') {
      setFeedErrorFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/application/dashboard/feed/error?issueStatus=${issueStatusValue}&country=${countryListValue}&provider=${providerValue}&date=${dateList}&requestLocation=history&userInfo=${userInfo}`;
    } else if (type === 'admin') {
      setAdminErrorFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/application/dashboard/admin/error?issueStatus=${issueStatusValue}&country=${countryListValue}&provider=${providerValue}&date=${dateList}`;
    } else if (type === 'batch') {
      setBatchErrorFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/application/dashboard/batch/error?issueStatus=${issueStatusValue}&country=${countryListValue}&provider=${providerValue}&date=${dateList}`;
    } else {
      console.error(`getContentsError, type:${type} error`);
      return;
    }

    fetch(requestUrl, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (type === 'feed') {
          setFeedErrorFetching('done');
          const issueCpData = body.filter(item => item.feedDashboards.length > 0);

          const groupedData = issueCpData.reduce((acc, obj) => {
            const { groupId, groupTitle, groupGuide, providerId, feedDashboards } = obj;

            if (!acc[groupId]) {
              acc[groupId]= {
                title: groupTitle,
                guide: groupGuide,
                cp: providerId,
                count: feedDashboards.length,
                countries: new Set(),
                apis: new Set(),
                values: feedDashboards
              };
            }

            feedDashboards.map(item => {
              const countryArr = item.country.split(",");
              countryArr.forEach((c) => {
                if (!acc[groupId].countries[c]) {
                  acc[groupId].countries[c]= true;
                }
              });

              if (!acc[groupId].apis[item.apiName]) {
                acc[groupId].apis[item.apiName]= true;
              }
            })

            return acc;
          }, {});

          const modifiedData = Object.keys(groupedData).map((groupId) => {
            const { title, guide, cp, count, countries, apis, values } = groupedData[groupId];
            return {
              groupId: groupId,
              title: title,
              guide: guide,
              cp: cp,
              count: count,
              countries: Object.keys(countries),
              apis: Object.keys(apis).join(", "),
              values: values
            };
          });
          setFeedError(modifiedData);
        } else if (type === 'admin') {
          setAdminErrorFetching('done');
          setAdminError(body);
        } else if (type === 'batch') {
          setBatchErrorFetching('done');
          setBatchError(body);
        }
      })
      .catch(error => {
        console.log(`getContentsError error: `, error);
      });
  };

  return (
    <>
      {isErrorDisplay && isChartDisplay ? (
        <div className="grid-row">
          <div className="row">
            <div className="box-sub">
              <div className="head">
                <div className="title">
                  <h2>Number of issues (based on occurrence date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
              {
                chartType === 'Line' ? (
                  <LineChart id={'daily_contents_error_occurrence'} data={content_history_chartdata.daily_contents_error_occurrence.data}
                  options={content_history_chartdata.daily_contents_error_occurrence.options} plugins={plugin} />
                ) : (
                  <BarChart id={'daily_contents_error_occurrence'} data={content_history_chartdata.daily_contents_error_occurrence.data}
                  options={content_history_chartdata.daily_contents_error_occurrence.options} plugins={plugin}/>
                )
              }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-sub">
              <div className="head">
                <div className="title">
                  <h2>Issue resolution rate (based on occurrence date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
              {
                chartType === 'Line' ? (
                  <LineChart id={'daily_contents_error_accumulated'} data={content_history_chartdata.daily_contents_error_accumulated.data}
                  options={content_history_chartdata.daily_contents_error_accumulated.options} plugins={plugin} />
                ) : (
                  <BarChart id={'daily_contents_error_accumulated'} data={content_history_chartdata.daily_contents_error_accumulated.data}
                  options={content_history_chartdata.daily_contents_error_accumulated.options} plugins={plugin}/>
                )
              }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-sub">
              <div className="head">
                <div className="title">
                  <h2>Issue resolution rate (based on cumulative daily date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
              {
                chartType === 'Line' ? (
                  <LineChart id={'daily_contents_error_accumulated2'} data={content_history_chartdata.daily_contents_error_accumulated2.data}
                  options={content_history_chartdata.daily_contents_error_accumulated2.options} plugins={plugin} />
                ) : (
                  <BarChart id={'daily_contents_error_accumulated2'} data={content_history_chartdata.daily_contents_error_accumulated2.data}
                  options={content_history_chartdata.daily_contents_error_accumulated2.options} plugins={plugin}/>
                )
              }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-tbl box-issueHistory">
              <IssTable height={'calc(100% - 76px)'} pagination={true} itemsPerPage={9} data={currentHistoryTableData} dropdownDirection={'up'}/>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )}
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

IssueHistoryContentsCommon.propTypes = {
  username: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  username: state.currentUser.username,
});

export default connect(mapStateToProps)(IssueHistoryContentsCommon);
