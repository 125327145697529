import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

function PopImage(props) {
  const { isOpen, onClose, title, url} = props;
  const [open, setOpen] = useState(isOpen);

  const imageTitle = title ? title : 'Title';
  const imageUrl = url ? url : '';

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      onClose();
    }
    return () => {
      setOpen(false);
    };
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modal-image" fullWidth maxWidth="lg">
        <DialogTitle id="alert-dialog-title" className="modal-title">
          {imageTitle}
          <button className="btn-close" onClick={handleClose}>
            close
          </button>
        </DialogTitle>
        <DialogContent className="modal-contents">
          <img src = {imageUrl} width="100%" height="100%"/>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PopImage;