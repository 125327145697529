import React from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import CategoryTableDialog from './CategoryTableDialog';
export default function CategoryTable({ result, options }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [row, setRow] = React.useState();
  return (
    <React.Fragment>
      <MaterialTable
        title=" " //없으면 Table Title이 default title로 나옴
        columns={[
          {
            title: 'order',
            render: n => n.tableData.id + 1,
            width: 80,
          },
          {
            title: 'cp',
            field: 'cp_id',
            width: 110,
          },
          {
            title: 'type',
            render: n => n.type,
            width: 80,
          },
          {
            title: 'contentId',
            render: n =>
              n.type !== 'live' ? (
                <button
                  title="Click this to show all images in this content !"
                  onClick={() => {
                    setDialogOpen(true);
                    setRow(n);
                  }}>
                  <span className="link">{n.content_id}</span>
                </button>
              ) : (
                <div>{n.content_id}</div>
              ),
            width: 130,
          },
          {
            title: 'name',
            field: 'name',
            width: 150,
          },
          {
            title: 'season',
            field: 'season',
            width: 70,
          },
          {
            title: 'episode',
            field: 'episode',
            width: 80,
          },
          {
            title: 'image',
            render: n => <img alt="invalid" src={n.image} title={n.image} style={{ maxWidth: 100, maxHeight: 100, backgroundColor: 'black', border: '1px solid #cbccce' }} />,
          },
          {
            title: 'startTime',
            render: n => (n.start_date_time ? n.start_date_time.slice(0, 16) : ''),
          },
          {
            title: 'genre',
            field: 'genre',
            width: 120,
          },
          {
            title: 'keyword',
            field: 'keywords',
          },
          {
            title: 'lastChgDate', //2020-11-16T18:55:10.000+0000
            render: n => (n.last_chg_date ? n.last_chg_date.slice(0, 16) : ''),
            width: 150,
          },
          {
            title: 'exclusiveLicense',
            render: n => (n.exclusive_view_flag ? 'Y' : 'N'),
            width: 80,
          },
          {
            title: 'sourceId',
            field: 'exclusive_view_id',
            width: 80,
          },
        ]}
        data={result}
        options={options}
      // options={{
      //   pageSize: 15,
      //   pageSizeOptions: [5, 15],
      // }}
      />
      <CategoryTableDialog
        row={row}
        dialogOpen={dialogOpen}
        closeDialog={() => {
          setDialogOpen(false);
        }}
      />
    </React.Fragment>
  );
}
CategoryTable.propTypes = {};
