import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppBar, Tab, Tabs, TextareaAutosize, TextField } from '@material-ui/core';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ContentTabPanel({ languages, langContentsData, handleContent, type }) {
  const [tabValue, setTabValue] = useState(0);
  const [contentsData, setContentsData] = useState([]);

  useEffect(() => {
    const fiteredContentData = langContentsData.filter(c => languages.includes(c.langCode));
    setContentsData(fiteredContentData);
    setTabValue(tabValue >= fiteredContentData.length ? 0 : tabValue);
    handleContent(fiteredContentData);
    languages.forEach((lang, index) => {
      if (fiteredContentData[index] === undefined) {
        setContentsData([...fiteredContentData, { langCode: lang, title: '', content: '' }]);
        handleContent([...fiteredContentData, { langCode: lang, title: '', content: '' }]);
      }
    });
  }, [languages]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleContentChange = (event, lang) => {

    const { value } = event.target;
    const newContentsData = contentsData.map(data => {
      if (data.langCode === lang) {
        return {
          ...data,
          content: value,
        };
      }
      return data;
    });
    setContentsData(newContentsData);
    handleContent(newContentsData);
  };

  const handleContentTitleChange = (event, lang) => {
    const { value } = event.target;
    const newContentsData = contentsData.map(data => {
      if (data.langCode === lang) {
        return {
          ...data,
          title: value,
        };
      }
      return data;
    });
    setContentsData(newContentsData);
    handleContent(newContentsData);
  };

  return (
    <Fragment>
      {languages.length > 0 && (
        <Fragment>
          <AppBar position="static" id="scheduleTabHead">
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto">
              {languages.map(lang => (
                <Tab key={lang} label={lang} {...a11yProps(lang)} />
              ))}
            </Tabs>
          </AppBar>
          {langContentsData.map((contentLang, index) => (
            <div key={index} role="tabpanel" hidden={tabValue !== index} id={`simple-tabpanel-${contentLang.langCode}`} aria-labelledby={`simple-tab-${contentLang.langCode}`}>
              {
                <div>
                  <TextField label="title" fullWidth margin="dense" variant="outlined" name="title" id="title" value={contentLang.title} onChange={event => handleContentTitleChange(event, contentLang.langCode)} autoFocus />
                  {(type === 'text' || type === 'textImage') && (
                    <Fragment>
                      <TextareaAutosize
                        name="content"
                        placeholder={`Add Content for ${contentLang.langCode}`}
                        id={`content-${contentLang.langCode}`}
                        variant="outlined"
                        aria-label="minimum height"
                        color="currentColor"
                        rowsMin={10}
                        className="tbl_textarea"
                        onChange={event => handleContentChange(event, contentLang.langCode)}
                        value={contentLang.content}
                        defaultValue={contentLang.content}
                      />
                      <p className="check_bite">
                      </p>
                    </Fragment>
                  )}
                </div>
              }
            </div>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
}

ContentTabPanel.propTypes = {
  languages: PropTypes.array.isRequired,
  langContentsData: PropTypes.array.isRequired,
  handleContent: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default connect()(ContentTabPanel);
