import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
export function CommonStatisticFilter({ allCps, cp, handleChangeCp, allCountries, cntry, handleChangeCntry, deleteCountry, platforms, platform, handleChangePlatform, deletePlatform }) {
  let _allCpList = [...allCps];
  if (allCps.indexOf('xumo') < 0 && allCps.indexOf('XUMO') < 0) {
    _allCpList.push('XUMO');
  }
  if (allCps.indexOf('wavve') < 0 && allCps.indexOf('WAVVE') < 0) {
    _allCpList.push('WAVVE');
  }

  return (
    <>
      <div className="filter_row">
        {cp && (
          <FormControl variant="outlined">
            <InputLabel>CP</InputLabel>
            <Select value={cp} onChange={handleChangeCp}>
              {['All', ...(_allCpList.sort())].map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl variant="outlined">
          <InputLabel>Country</InputLabel>
          <Select
            value={cntry}
            onChange={handleChangeCntry}
            multiple
            renderValue={selected => (
              <div>
                {selected.map(value => (
                  <Chip key={value} label={value} onDelete={() => deleteCountry(value, selected)} onMouseDown={e => e.stopPropagation()} />
                ))}
              </div>
            )}>
            {['All', ...(allCountries.sort())].map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Platform</InputLabel>
          <Select
            value={platform}
            onChange={handleChangePlatform}
            multiple
            renderValue={selected => (
              <div>
                {selected.map(value => (
                  <Chip key={value} label={value} onDelete={() => deletePlatform(value, selected)} onMouseDown={e => e.stopPropagation()} />
                ))}
              </div>
            )}>
            {['All', ...(platforms.sort())].map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
}

CommonStatisticFilter.propTypes = {
  allCps: PropTypes.array.isRequired,
  cp: PropTypes.string,
  handleChangeCp: PropTypes.func.isRequired,
  allCountries: PropTypes.array.isRequired,
  cntry: PropTypes.array.isRequired,
  handleChangeCntry: PropTypes.func.isRequired,
  deleteCountry: PropTypes.func.isRequired,
  platforms: PropTypes.array.isRequired,
  platform: PropTypes.array.isRequired,
  handleChangePlatform: PropTypes.func.isRequired,
  deletePlatform: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  allCountries: state.cntry.allCountries,
  allCps: state.cntry.allCps,
  platforms: state.cntry.platforms,
});

export default connect(mapStateToProps)(CommonStatisticFilter);
