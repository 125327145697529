import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

function MonitorFilter({ allCountries, modes, supportLanguages, deviceVersions, platformCodes, serviceDevices,
    apiStatusList, country, mode, language, deviceVersion, platformCode, serviceDevice, apiStatus, requiredField,
    handleChangeCountry, handleChangeMode, handleChangeLanguage,
    handleChangeDeviceVersion, handleChangePlatformCode, handleChangeServiceDevice, handleChangeApiStatus }) {

    return (
        <Fragment>
            <div className="filter_area row">
                <div className="filter_area">
                    <FormControl required variant="outlined" >
                        <InputLabel>Country</InputLabel>
                        <Select value={country} onChange={handleChangeCountry} >
                            {[...allCountries].map(item => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" required={requiredField} error={requiredField && mode == ''}>
                        <InputLabel>Mode</InputLabel>
                        <Select value={mode} onChange={handleChangeMode}>
                            {modes.map(item => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {requiredField && mode == '' && <FormHelperText>Mode is required!</FormHelperText>}
                    </FormControl>
                    <FormControl variant="outlined" error={requiredField && language == ''}>
                        <InputLabel>Language</InputLabel>
                        <Select value={language} onChange={handleChangeLanguage}>
                            {[...supportLanguages].map(item => (
                                <MenuItem key={item.lang_code} value={item.lang_code}>
                                    {item.lang_code}
                                </MenuItem>
                            ))}
                        </Select>
                        {requiredField && language == '' && <FormHelperText>Language is required!</FormHelperText>}
                    </FormControl>
                    <FormControl variant="outlined" error={requiredField && deviceVersion == ''}>
                        <InputLabel>Platform Version</InputLabel>
                        <Select value={deviceVersion} onChange={handleChangeDeviceVersion}>
                            {
                                [...deviceVersions].map(item => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                        </Select>
                        {requiredField && deviceVersion == '' && <FormHelperText>Platform Version is required!</FormHelperText>}
                    </FormControl>
                </div>
                <div className="filter_area">
                    <FormControl variant="outlined" error={requiredField && platformCode == ''}>
                        <InputLabel>Platform Code</InputLabel>
                        <Select value={platformCode} onChange={handleChangePlatformCode}>
                            {[...platformCodes].map(item => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                        {requiredField && platformCode == '' && <FormHelperText>Platform Code is required!</FormHelperText>}
                    </FormControl>
                    <FormControl variant="outlined" error={requiredField && serviceDevice == ''}>
                        <InputLabel>Device</InputLabel>
                        <Select value={serviceDevice} onChange={handleChangeServiceDevice}>
                            {[...serviceDevices].map(item => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                        {requiredField && serviceDevice == '' && <FormHelperText>Device is required!</FormHelperText>}
                    </FormControl>

                    <FormControl variant="outlined" error={requiredField && apiStatus == ''}>
                        <InputLabel>API Status</InputLabel>
                        <Select value={apiStatus} onChange={handleChangeApiStatus}>
                            {[...apiStatusList].map(item => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {requiredField && apiStatus == '' && <FormHelperText>Api Status is required!</FormHelperText>}
                    </FormControl>
                </div>
            </div>
        </Fragment>
    )
}
MonitorFilter.propTypes = {
    allCountries: PropTypes.array.isRequired,
    modes: PropTypes.array.isRequired,
    supportLanguages: PropTypes.array.isRequired,
    deviceVersions: PropTypes.array.isRequired,
    platformCodes: PropTypes.array.isRequired,
    serviceDevices: PropTypes.array.isRequired,
    apiStatusList: PropTypes.array.isRequired,
    country: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    deviceVersion: PropTypes.string.isRequired,
    platformCode: PropTypes.string.isRequired,
    serviceDevice: PropTypes.string.isRequired,
    apiStatus: PropTypes.string.isRequired,
    requiredField: PropTypes.bool.isRequired,
    handleChangeCountry: PropTypes.func.isRequired,
    handleChangeMode: PropTypes.func.isRequired,
    handleChangeLanguage: PropTypes.func.isRequired,
    handleChangeDeviceVersion: PropTypes.func.isRequired,
    handleChangePlatformCode: PropTypes.func.isRequired,
    handleChangeServiceDevice: PropTypes.func.isRequired,
    handleChangeApiStatus: PropTypes.func.isRequired,
};

export default connect()(MonitorFilter);