import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function providerIds(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PROVIDERS:
      if (action.payload.list) {
        return action.payload.list.map(item => item.providerId).concat('All');
        //return [...action.payload.list];
      } else {
        return state;
      }
    default:
      return state;
  }
}
