import { SET_CHANNELS, SET_CHANNELS_FETCHING } from './actionTypes';
import { API_BASE_URL, CHANNEL_LIST_SIZE } from '../constants';
import { getHeaders } from '../util/actionUtil';
//
const setChannelFetchingAction = value => ({
  type: SET_CHANNELS_FETCHING,
  payload: value,
});
const fetchGetUserCreatedChannelsSuccess = body => ({
  type: SET_CHANNELS,
  payload: body,
});
const fetchGetUserCreatedChannelsFailure = ex => ({
  type: SET_CHANNELS,
  payload: ex,
});

//
export const getUserCreatedChannelsAction = (page, size, isRoleAdmin) => dispatch => {
  page = page || 0;
  size = size || CHANNEL_LIST_SIZE;
  let apiUrl = API_BASE_URL + '/channels';
  if (isRoleAdmin) apiUrl = API_BASE_URL + '/admin/channels';

  // dispatch
  dispatch(setChannelFetchingAction(true));
  //

  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch(fetchGetUserCreatedChannelsSuccess(body)))
    .catch(ex => dispatch(fetchGetUserCreatedChannelsFailure(ex)));
};
// const fetchEditChannelsSuccess = body => ({
//   type: SET_EDIT_CHANNEL,
//   payload: body,
// });
// const fetchEditChannelsFailure = ex => ({
//   type: SET_EDIT_CHANNEL,
//   payload: ex,
// });
// export const editChannelsAction = (page, size, isRoleAdmin, channelId) => dispatch => {
//   page = page || 0;
//   size = size || CHANNEL_LIST_SIZE;
//   let apiUrl = API_BASE_URL + '/channels/' + channelId;
//   if (isRoleAdmin === true) {
//     apiUrl = API_BASE_URL + '/admin/channels/' + channelId;
//   }
//   dispatch(setChannelFetchingAction(true));
//   return fetch(apiUrl, {
//     method: 'PATCH',
//     headers: getHeaders(),
//   })
//     .then(res => res.json())
//     .then(body => dispatch(fetchEditChannelsSuccess(body)))
//     .catch(ex => dispatch(fetchEditChannelsFailure(ex)));
// };
// const fetchRemoveChannelsSuccess = body => ({
// 	type: SET_REMOVE_CHANNEL,
// 	payload: body
// });
// const fetchRemoveChannelsFailure = ex => ({
// 	type: SET_REMOVE_CHANNEL,
// 	payload: ex
// });
// export const removeChannelsAction = (isRoleAdmin, providerId, channelId) => dispatch => {
// 	let apiUrl = API_BASE_URL + '/channels/' + channelId;
// 	if(isRoleAdmin===true){
// 		apiUrl = API_BASE_URL + '/admin/providers/' + providerId + '/channels/' + channelId;
// 	}
// 	dispatch(setChannelFetchingAction(true));
// 	return fetch(apiUrl, {
// 		method: 'DELETE',
// 		headers: getHeaders()
// 	})
// 		.then(res => res.json())
// 		.then(body => dispatch(fetchRemoveChannelsSuccess(body)))
// 		.catch(ex => dispatch(fetchRemoveChannelsFailure(ex)));
// };
