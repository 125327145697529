import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppBar, Button, DialogContent, DialogTitle, makeStyles, Switch, Tab, Table, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import QuillEditor from '../../../common/QuillEditor/QuillEditor';

const useStyles = makeStyles(() => ({
  pushAlarmDispaly: {
    fontWeight: 'normal',
  },
  contentText: {
    verticalAlign: 'baseline',
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function PushAlarmView({ title, handleClose, data }) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [htmlContent, setHtmlContent] = useState("");
  const quillRef = useRef();
  const api = "";

  useEffect(() => {
    //console.log('[useEffect] PushAlarmView :', data);
  }, []);

  return (
    <Fragment>
      {title && (
        <Fragment>
          <DialogTitle className="pop_head" id="form-dialog-title">
            {`View ${title}`}
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={'pop_body'}>
            <TableContainer className={'tbl_wrap'}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="20%">Platform</TableCell>
                    <TableCell colSpan="2" className={classes.pushAlarmDispaly}>
                      {data.platformCode}
                    </TableCell>
                    <TableCell width="20%">OS</TableCell>
                    <TableCell colSpan="2" className={classes.pushAlarmDispaly}>
                      {data.platformName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Country</TableCell>
                    <TableCell colSpan="2" className={classes.pushAlarmDispaly}>
                      {data.countryCode}
                    </TableCell>
                    <TableCell>State</TableCell>
                    <TableCell colSpan="2" className={classes.pushAlarmDispaly}>
                      {data.state}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell colSpan="5" className={classes.pushAlarmDispaly}>
                      {data.title}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.contentText}>Languages
                      <Fragment>
                        <AppBar position="static" id="scheduleTabHead">
                          <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto" orientation="vertical">
                            {data.multiLangList.map((langContent, i) => (
                              <Tab key={langContent.lang} label={langContent.lang} {...a11yProps(i)} />
                            ))}
                          </Tabs>
                        </AppBar>
                      </Fragment>
                    </TableCell>
                    <TableCell colSpan="5" className={classes.pushAlarmDispaly}>
                      {data.multiLangList && (
                        <Fragment>
                          {data.multiLangList.map((lang, index) => (
                            <div key={index} role="tabpanel" hidden={tabValue !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
                              <div>
                                <b>Title:</b> {lang.title}
                              </div>
                              <div style={{ whiteSpace: 'pre-wrap' }}>
                              <QuillEditor
                              quillRef={quillRef}
                              id={`content-${index}`}
                              htmlContent={lang.content}
                              setHtmlContent={setHtmlContent}
                              api={api}
                              readOnly={true}
                              />
                              </div>
                            </div>
                          ))}
                        </Fragment>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Open Date</TableCell>
                    <TableCell colSpan="5" className={classes.pushAlarmDispaly}>
                      {data.startDate}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <div className={'btn_set_wrap alC'}>
              <Button variant="contained" className={'btn_color2'} onClick={handleClose}>
                Close
              </Button>
            </div>
          </DialogContent>
        </Fragment>
      )}
    </Fragment>
  );
}
PushAlarmView.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default connect()(PushAlarmView);
