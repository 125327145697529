import {
  INIT_CURRENT_USER,
  SET_AUTHENTICATED,
  SET_CURRENT_USER,
  // SET_USERS,
  // SET_USERS_FETCHING,
} from './actionTypes';

import { API_BASE_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';

export const setAuthenticatedAction = value => ({
  type: SET_AUTHENTICATED,
  payload: value,
});

export const setCurrentUserAction = value => ({
  type: SET_CURRENT_USER,
  payload: value,
});

export const initCurrentUserAction = () => ({
  type: INIT_CURRENT_USER,
});

const fetchGetCurrentUserActionSuccess = body => ({
  type: SET_CURRENT_USER,
  body,
});

const fetchGetCurrentUserActionFailure = ex => ({
  type: SET_CURRENT_USER,
  ex,
});

export const getCurrentUserAction = () => dispatch => {
  const apiUrl = API_BASE_URL + '/users/me';

  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(fetchGetCurrentUserActionSuccess(body));
    })
    .catch(ex => {
      dispatch(fetchGetCurrentUserActionFailure(ex));
    });
};

// const setUsersFetchingAction = (value) => ({
//   type: SET_USERS_FETCHING,
//   payload: value,
// });
// const fetchGetUsersActionSuccess = (body) => ({
//   type: SET_USERS,
//   payload: body,
// });
// const fetchGetUsersActionFailure = (ex) => ({
//   type: SET_USERS,
//   payload: ex,
// });

export const getAdminsAction = () => dispatch => {
  // 	name = name || '';
  // 	const requestParams = `?providerId=${providerId}&username=${username}&name=${name}&email=${email}`;

  dispatch({
    type: 'SET_ADMINS_FETCHING',
    payload: true,
  });
  return fetch(API_BASE_URL + '/admin/users?role=ROLE_ADMIN', {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: 'SET_ADMINS',
        payload: body,
      }),
    )
    .catch(ex =>
      dispatch({
        type: 'SET_ADMINS_ERROR',
        payload: ex,
      }),
    );
};

export const getMembersAction = isRoleAdmin => dispatch => {
  const url = isRoleAdmin ? '/admin/users?role=ROLE_CP' : '/users';

  dispatch({
    type: 'SET_MEMBERS_FETCHING',
    payload: true,
  });
  return fetch(API_BASE_URL + url, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: 'SET_MEMBERS',
        payload: body,
      }),
    )
    .catch(ex =>
      dispatch({
        type: 'SET_MEMBERS_ERROR',
        payload: ex,
      }),
    );
};

// export const userResponse = {
//   page: 0,
//   size: 30,
//   totalElements: 4,
//   totalPages: 1,
//   last: true,
//   list: [
//     {
//       id: 1,
//       name: 'admin',
//       username: 'admin',
//       email: 'admin@lge.com',
//       providerId: 'RAKUTEN',
//       createdAt: '2020-01-17T04:49:10Z',
//       roles: [
//         { id: 5, name: 'ROLE_USER' },
//         { id: 1, name: 'ROLE_SUPER_ADMIN' },
//         { id: 2, name: 'ROLE_ADMIN' },
//       ],
//     },
//     {
//       id: 2,
//       name: 'rakuten',
//       username: 'rakuten',
//       email: 'rakuten@rakuten.com',
//       providerId: 'RAKUTEN',
//       createdAt: '2020-01-17T04:49:10Z',
//       roles: [
//         { id: 5, name: 'ROLE_USER' },
//         { id: 3, name: 'ROLE_CP' },
//       ],
//     },
//     {
//       id: 3,
//       name: 'pluto',
//       username: 'pluto',
//       email: 'pluto@pluto.com',
//       providerId: 'PLT',
//       createdAt: '2020-01-17T04:49:10Z',
//       roles: [
//         { id: 5, name: 'ROLE_USER' },
//         { id: 3, name: 'ROLE_CP' },
//       ],
//     },
//     {
//       id: 4,
//       name: 'lgp',
//       username: 'lgp',
//       email: 'lgp@lge.com',
//       providerId: 'LGP',
//       createdAt: '2020-01-17T06:23:42Z',
//       roles: [
//         { id: 5, name: 'ROLE_USER' },
//         { id: 3, name: 'ROLE_CP' },
//       ],
//     },
//   ],
// };
