import React, { useEffect, useState } from 'react';
import Loading from '../../../common/Loading';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import Alert from '../../../modal/Alert';
import { getHeaders } from '../../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../../constants/index';
import { format, addDays, differenceInDays } from 'date-fns';
import { getScalesMaxValue } from '../../../utils/IssUtils';
import {
  CHART_COLORS_BY_COUNTRY,
  CHART_COLORS_BY_PROVIDER,
  CHART_COLORS_BY_PLATFORM
} from '../../../common/ChartConsts';
import {
  duration_access_line_trend_data,
  duration_access_country_line_trend_data,
  duration_access_platform_line_trend_data
 } from './StatisticsTrendData';

function ContentLineTrend(props) {
  const { height, title, originalFilter, currentFilter, updateTable, size } = props;
  const [trendData, setTrendData] = useState({data: {}, options: {}});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [isDurationDisplay, setDurationDisplay] = useState(false);
  const [isAccessDisplay, setAccessDisplay] = useState(false);
  const [isUniqueDeviceDisplay, setUniqueDeviceDisplay] = useState(false);
  const [chartCount, setChartCount] = useState(0);
  const [displayType, setDisplayType] = useState([]);
  const [contentName, setContentName] = useState('');
  const plugin = [annotationPlugin];
  const initValue = 5;

  useEffect(() => {
    if (currentFilter.date) {
      const radioFilter = currentFilter.customRadioFilter;
      const tempCountry = currentFilter.country ? currentFilter.country : [];
      const tempPlatforms = currentFilter.platforms ? currentFilter.platforms
      .map(map => map.replace('webOS', 'webOSTV').replace('webOSTV 22', 'webOSTV 7.0')) : [];
      const endDate = currentFilter.date[1];
      const compareType = currentFilter.compareType;
      getContentFilter({startDate: currentFilter.date[0], endDate: endDate, countryCodeList: tempCountry,
        platformList: tempPlatforms, contentNameList: [],
        indicator: radioFilter && radioFilter.length > 0 ? radioFilter[0].api : 'duration',
        compareType: compareType});
    }
  }, [currentFilter, size]);

  const getContentFilter = (bodyFilter) => {
    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/trend/contentFilter`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(bodyFilter),
    })
      .then(res => res.json())
      .then(body => {
        const bodyList = body.map(item => item.contentName);
        let tempCurrentContentNameList = [];
        let tempContentNameList = [];

        if (currentFilter.customCheckboxFilter && currentFilter.customCheckboxFilter.length > 0) {
          const tempContentName = currentFilter.customCheckboxFilter.find(item => item.hasOwnProperty('content')).content;
          tempContentName.forEach((c) => {
            tempCurrentContentNameList.push(c);
          });
          bodyFilter.contentNameList.push(...tempCurrentContentNameList);
          const tempCustomCheckboxFilter = [
            {
              content : [...tempCurrentContentNameList]
            }
          ];
          originalFilter.customCheckboxFilter = tempCustomCheckboxFilter;

        } else {
          let cnt = bodyList.length > initValue ? initValue : bodyList.length
          for ( let i = 0; i < cnt; i++) {
            tempContentNameList.push(bodyList[i]);
          };
          bodyFilter.contentNameList.push(...tempContentNameList);
          const tempCustomCheckboxFilter = [
            {
              content : bodyList
            }
          ];
          originalFilter.customCheckboxFilter = tempCustomCheckboxFilter;
        }
        const hiddenList = body.map(item => ({contentName: item.contentName, countryCode: item.countryCode, platform: item.platform }));
        originalFilter.hiddenFilter = hiddenList;
        setChartCount(bodyFilter.contentNameList.length);

        if (bodyFilter.startDate) {
          getContentTrend(bodyFilter);
        }
      })
      .catch(error => {
        setAlertMessage(`trend statistics contentFilter catch: `, error);
        onOpenAlert();
      })
      .finally(() => {});
  };

  const getContentTrend = (bodyFilter) => {
    const apiName = bodyFilter.indicator === '' ? 'duration' : bodyFilter.indicator;
    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/trend/content`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(bodyFilter),
    })
      .then(res => res.json())
      .then(body => {
        const data = {
          labels: [],
          datasets: [],
        };

        const options = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              display: false,
            },
          },
          scales: {
            y: {
              min: 0,
              max: 0,
              ticks: {
                stepSize: 10,
              },
            },
            x: {
              ticks: {
                callback: function(value, index, values) {
                  if (data.labels[index] && (data.labels[index]).length > 5) {
                    if (index > 0 && (data.labels[index - 1]).substring(0, 5) === (data.labels[index]).substring(0, 5)) {
                      return '';
                    }
                    return (data.labels[index]).substring(0, 5);
                  } else {
                    return data.labels[index];
                  }
                }
              },
            },
          },
        };

        const startDate = new Date(bodyFilter.startDate);
        const endDate = new Date(bodyFilter.endDate);
        const differenceDays = differenceInDays(endDate, startDate);
        const compareType = bodyFilter.compareType;
        let trendChartData = [];
        let dateList = [];
        let minList = [];
        let maxList = [];

        for ( let i = 0; i <= differenceDays; i++) {
          dateList.push(format(addDays(startDate, i),'yyyy-MM-dd'));
        }

        if (body && body.length > 0) {
          let contentBody = body.map(map => ({ period: map.period, contentName : map.contentName,
            country: map.countryCode, platform: map.platform, value: apiName === 'duration' ?
            map.duration : apiName === 'access' ? map.access : map.uniqueDevice }));
          if(compareType && compareType != 'None'){
            let compareTypeList = [];
            const compareList = [... new Set(contentBody.map(item =>
              compareType === 'Country' ? item.country : item.platform))];
            compareList.forEach((compareItem) => {
              let tempCompareList = [];
              let valueList = [];
              dateList.forEach((dl) => {
                let tempValue = 0.0;
                const findContent = contentBody.find(item =>
                  (compareType === 'Country' ? item.country : item.platform) ===
                    compareItem && item.period === dl);
                if (findContent && apiName === 'duration'){
                  tempValue = Math.round(((findContent.value/60)/60)*10)/10;
                } else if (findContent && apiName != 'duration'){
                  tempValue = findContent.value;
                }
                tempCompareList.push({ period: dl, contentName: contentBody[0].contentName,
                  value: tempValue, country: compareItem, platform: compareItem});
              });
              valueList = tempCompareList.map(map => map.value);
              compareTypeList.push(...tempCompareList);
              trendChartData.push(chartTemplate(compareType, compareItem, compareItem, valueList));
              minList.push(Math.min(...valueList));
              maxList.push(Math.max(...valueList));
            });
            if(compareType && compareType === 'Country'){
              duration_access_country_line_trend_data.rowdata = compareTypeList.map(item => ({
                row: [
                  item.period,
                  bodyFilter.indicator,
                  item.country,
                  item.value
                ],
              }));
              updateTable(duration_access_country_line_trend_data);
            } else if(compareType && compareType === 'Platform'){
              duration_access_platform_line_trend_data.rowdata = compareTypeList.map(item => ({
                row: [
                  item.period,
                  bodyFilter.indicator,
                  item.platform,
                  item.value
                ],
              }));
              updateTable(duration_access_platform_line_trend_data);
            }
            setContentName(contentBody[0].contentName);
          } else {
            let compareTypeList = [];
            bodyFilter.contentNameList.forEach((cnl) => {
              let template = [];
              let valueList = [];
              let contentBody = body.filter(item => cnl === item.contentName)
              .map(map => ({ period: map.period, contentName : map.contentName,
                value : apiName === 'duration' ? map.duration :
                apiName === 'access' ? map.access : map.uniqueDevice}));

              if(contentBody.length === 0) return;

              dateList.forEach((dl) => {
                let tempValue = 0.0;
                const findContent = contentBody.find(item => item.period === dl);
                if (findContent && apiName === 'duration'){
                  tempValue = Math.round(((findContent.value/60)/60)*10)/10;
                } else if (findContent && apiName != 'duration'){
                  tempValue = findContent.value;
                }
                template.push({ period: dl, contentName: contentBody[0].contentName,  value: tempValue });
              });

              valueList = template.map(map => map.value);
              compareTypeList.push(...template);
              trendChartData.push(chartTemplate(compareType, template[0].contentName, template[0].contentName, valueList));
              minList.push(Math.min(...valueList));
              maxList.push(Math.max(...valueList));
            });
            duration_access_line_trend_data.rowdata = compareTypeList.map(item => ({
              row: [
                item.period,
                bodyFilter.indicator,
                item.value
              ],
            }));
            updateTable(duration_access_line_trend_data);
            setContentName('');
          }

        } else {
          let template = [];
          let valueList = [];

          dateList.forEach((dl) => {
            let tempValue = 0.0;
            template.push({ period: dl, contentName: 'No data',  value: tempValue });
          });

          valueList = template.map(map => map.value);
          trendChartData.push(chartTemplate(compareType, '',  template[0].contentName, valueList));
          minList.push(0.0);
          maxList.push(1.0);
          duration_access_line_trend_data.rowdata = template.map(item => ({
            row: [
              item.period,
              bodyFilter.indicator,
              item.value
            ],
          }));
          updateTable(duration_access_line_trend_data);
          setContentName('');
        }
        let displayTypeData = [];
        const sizeCnt = size === 'Large' ? 2 : 1;
        let limitCnt = compareType === 'Country' ? 12 :
        compareType === 'Platform' ? 9 : 5;
        trendChartData.forEach((dl, index) => {
          if(index < (limitCnt * sizeCnt)){
          displayTypeData.push(dl);
          }
        });
        setDisplayType(displayTypeData);
        options.scales.y.min = Math.floor(Math.min(...minList));
        options.scales.y.max = Math.floor(Math.max(...maxList)) === 0 ? 1.0 : Math.floor(getScalesMaxValue(Math.max(...maxList)));
        data.labels = dateList.map(map => map.substring(5));
        data.datasets = trendChartData;
        setTrendData({data: data, options: options});

        if (apiName === 'duration'){
          setDurationDisplay(true);
          setAccessDisplay(false);
          setUniqueDeviceDisplay(false);
        } else if (apiName === 'access'){
          setDurationDisplay(false);
          setAccessDisplay(true);
          setUniqueDeviceDisplay(false);
        } else {
          setDurationDisplay(false);
          setAccessDisplay(false);
          setUniqueDeviceDisplay(true);
        }
      })
      .catch(error => {
        setAlertMessage(`trend getContentTrend catch: `, error);
        onOpenAlert();
      })
      .finally(() => {});
  };

  const chartTemplate = (compareType, paramValue, paramLabel, valueList) => {
    const template = {
      label: paramLabel.replace('webOSTV', 'OS') ,
      data: valueList,
      fill: false,
      pointRadius: 3,
      pointBorderColor: '#FFFFFF',
      borderColor: hexToRgba(getColor(compareType, paramValue), 0.4),
      backgroundColor: hexToRgba(getColor(compareType, paramValue), 0.4),
      pointBackgroundColor: hexToRgba(getColor(compareType, paramValue), 0.4),
      pointBorderWidth: 2,
      order: 2,
    };
    return template;
  };

  function getColor(compareType, item) {
    const randomColor = `#${Math.random().toString(16).substr(-6)}`;
    if (compareType === 'Country') {
      return CHART_COLORS_BY_COUNTRY[item] || CHART_COLORS_BY_COUNTRY['OTHERS'];
    } else if (compareType === 'Platform') {
      const convertItem = item.replace('webOSTV', 'webOS').replace('webOS 7.0','webOS 22');
      return CHART_COLORS_BY_PLATFORM[convertItem] || CHART_COLORS_BY_PLATFORM['OTHERS'];
    } else {
      return randomColor;
    }
  }

  const hexToRgba = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
    <div style={{ height: height }}>
      <div className='trend-head'>
        <div className="title" style={{top: "-30px"}}>
          <div> {title} {contentName ? `(${contentName})`: ''}</div>
        </div>
        <div className="legend">
          <ul>
            { isDurationDisplay || isAccessDisplay || isUniqueDeviceDisplay ? (
              displayType.map((item) => (
                <li>
                  <i style={{backgroundColor: item.borderColor}}/>
                    <span>{item.label}</span>
                </li>
              ))
            ) : (
              <></>
            )
            }
          </ul>
        </div>
      </div>
      { isDurationDisplay || isAccessDisplay || isUniqueDeviceDisplay ? (
        <Line id={'daily_contents_error_occurrence'} data={trendData.data}
          options={trendData.options} plugins={plugin} />
          ) : (
            <></>
          )
      }
    </div>
    {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default ContentLineTrend;