import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField, Checkbox, OutlinedInput, ListItemText } from '@material-ui/core';
import { getABTestTabCategoryAction } from '../../../actions/homeAppActions';

function AddServiceFilter({ countryCode,
  handleChangeStartDate,
  handleChangeStartTime,
  handleChangeEndDate,
  handleChangeEndTime,
  handleChangeTab,
  handleChangeCategory,
  abTestTabCategory,
  region,
  segment,
  segmentId,
  handleChangeSegment,
  segmentType,
  handleChangeSegmentType,
  tabCode,
  categoryCode,
  deviceType,
  platformVersion,
  title,
  startDate,
  startTimeAnt,
  endDate,
  endTimeAnt,
  dayOfWeek,
  handleDayOfWeekChange,
  isItEdit,
  validToUpdate,
  contentPolicy,
  handleContentPolicy,
  getABTestTabCategoryAction,
  mappingType,
  handleChangeMappingType,
  defaultContent,
  onChangeDefaultGroup,
  sampleRate,
  handleSampleRate,
  comparativeSegmentType,
  handleChangeComparativeSegmentType,
  handleChangeComparativeSegmentId,
  comparativeSegmentId
}) {
  const today = new Date();
  const yestreday = new Date();
  const nextMonthDate = new Date();
  yestreday.setDate(today.getDate() - 1);
  nextMonthDate.setMonth(today.getMonth() + 1);
  const [startTimeLimit, setStartTimeLimit] = React.useState('');
  let segmentBTypes = new Map();
  segment.filter(seg => seg.segmentGroup === 'B').map(segtype => { segmentBTypes.set(segtype.segmentType, segtype.segmentName) });
  segmentBTypes = new Map([...segmentBTypes.entries()].sort());

  let comparativeSegmentTypes = new Map();
  segment.filter(seg => seg.segmentGroup === 'C').map(segtype => { comparativeSegmentTypes.set(segtype.segmentType, segtype.segmentName) }).sort();
  comparativeSegmentTypes = new Map([...comparativeSegmentTypes.entries()].sort());
  const dayOfweekList = [
    'SUN',
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
  ];
  const mappingTypes = [
    { value: 'auto', label: 'auto' },
    { value: 'manual', label: 'manual' },
  ];
  const hourFomatList = [
    { value: 0, label: '00:00' },
    { value: 1, label: '1:00' },
    { value: 2, label: '2:00' },
    { value: 3, label: '3:00' },
    { value: 4, label: '4:00' },
    { value: 5, label: '5:00' },
    { value: 6, label: '6:00' },
    { value: 7, label: '7:00' },
    { value: 8, label: '8:00' },
    { value: 9, label: '9:00' },
    { value: 10, label: '10:00' },
    { value: 11, label: '11:00' },
    { value: 12, label: '12:00' },
    { value: 13, label: '13:00' },
    { value: 14, label: '14:00' },
    { value: 15, label: '15:00' },
    { value: 16, label: '16:00' },
    { value: 17, label: '17:00' },
    { value: 18, label: '18:00' },
    { value: 19, label: '19:00' },
    { value: 20, label: '20:00' },
    { value: 21, label: '21:00' },
    { value: 22, label: '22:00' },
    { value: 23, label: '23:00' },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function startTimeChange(e) {
    handleChangeStartTime(e)
    setStartTimeLimit(e.target.value)
  }
  React.useEffect(() => {
    if (deviceType !== '' && region !== '' && platformVersion !== '') {
      getABTestTabCategoryAction(deviceType, countryCode === 'All' ? 'All' : region, platformVersion);
    }
  }, [deviceType, region, platformVersion]);

  function getDescription() {
    return segment.filter((item) => item.segmentId === segmentId).map(item => item.description);
  }

  return (
    <Fragment>
      <div className="filter_area row">
        <div className="filter_row">
          <TextField margin="dense" id="serviceTitle" name="serviceTitle" label="Service Title" placeholder="Service Title" variant="outlined" value={isItEdit ? title : undefined} disabled />
          <TextField
            variant="outlined"
            name="startDate"
            id="startDate"
            label="Start Date"
            type="date"
            value={startDate ? startDate : ''}
            onChange={handleChangeStartDate}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: today.toLocaleDateString('en-CA') }}
            disabled
          />

          <FormControl variant="outlined" className="filter_area">
            <InputLabel>Start Time</InputLabel>
            <Select margin="dense" variant="outlined" id="startTime" name="startTime" value={startTimeAnt} onChange={e => startTimeChange(e)} inputProps={{ min: yestreday.toISOString().slice(0, 10) }} required disabled>
              {hourFomatList.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            name="endDate"
            id="endDate"
            label="End Date"
            type="date"
            value={endDate ? endDate : ''}
            onChange={handleChangeEndDate}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: startDate }}
            disabled
          />

          <FormControl variant="outlined" className="filter_area">
            <InputLabel>End Time</InputLabel>
            <Select margin="dense" variant="outlined" label="End Time" id="endTime" name="endTime" value={endTimeAnt} onChange={handleChangeEndTime} disabled>
              {hourFomatList.map(item => (
                <MenuItem key={item.value} value={item.value} disabled>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {<TextField margin="dense" variant="outlined" label="Device Type" value={deviceType} required disabled={true} />}
          {<TextField margin="dense" variant="outlined" label="Country" value={countryCode} required disabled />}
        </div>
        <div className="filter_row">
          <TextField margin="dense" variant="outlined" label="platform version" value={platformVersion} required disabled={true} />
          {abTestTabCategory && (
            <FormControl variant="outlined" className="filter_area">
              <InputLabel>Tab</InputLabel>
              <Select margin="dense" variant="outlined" name="channelMapList" id="search_channelMapList" value={tabCode} onChange={handleChangeTab} disabled>
                {abTestTabCategory.map((n, idx) => (
                  <MenuItem key={`${n.tabCode}_${idx}`} value={n.tabCode}>
                    {n.tabCode + ' ' + n.tabName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {abTestTabCategory && (
            <FormControl variant="outlined" className="filter_area">
              <InputLabel>Category</InputLabel>
              <Select margin="dense" variant="outlined" name="channelMapList" id="search_channelMapList" value={categoryCode} onChange={handleChangeCategory} disabled>
                {tabCode &&
                  (abTestTabCategory.find(n => n.tabCode == tabCode) || { categoryDetails: [] }).categoryDetails.map(n => (
                    <MenuItem key={n.categoryCode} value={n.categoryCode}>
                      {n.categoryCode + ' ' + n.categoryName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {
            <FormControl variant="outlined" className="filter_area">
              <InputLabel>Segment Type</InputLabel>
              <Select variant="outlined" id="segmentType" name="segmentType" label="segmentType" value={segmentType} onChange={handleChangeSegmentType} required disabled={validToUpdate}>
                {[...segmentBTypes].map((values, keys) => (
                  <MenuItem key={values[0]} value={values[0]}>
                    {values[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          {
            <FormControl required variant="outlined">
              <InputLabel>Segment</InputLabel>
              <Select name="segmentId" id="segmentId" value={segmentId} onChange={handleChangeSegment} disabled={validToUpdate}>
                {segment
                  .filter(seg => seg.segmentType === segmentType && seg.segmentGroup === 'B')
                  .map(n => (
                    <MenuItem key={n.segmentId} value={n.segmentId} title={n.description}>
                      {n.segmentId}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          }
          {
            <FormControl required variant="outlined">
              <InputLabel>Mapping Type</InputLabel>
              <Select name="mappingType" id="mappingTypeId" value={mappingType} onChange={handleChangeMappingType} disabled={validToUpdate}>
                {mappingTypes.map(n => (
                  <MenuItem key={n.value} value={n.value}>
                    {n.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          <TextField variant="outlined" className="adtest_add" id="sampleRate" name="sampleRate" placeholder="Sample Rate" label="Sample Rate" required value={sampleRate} onChange={handleSampleRate} disabled={validToUpdate} />
        </div>
        <div className="filter_row">
          <TextField
            id="SegmentDesc"
            name="SegmentDesc"
            label="Segment Description"
            placeholder="Segment Description"
            variant="outlined"
            value={getDescription()}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="contentPolicy"
            name="contentPolicy"
            label="Content Policy"
            placeholder="Content Policy"
            variant="outlined"
            value={contentPolicy}
            onChange={handleContentPolicy}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ maxLength: '255' }}
          />
        </div>
        <div className="filter_row">
          <div style={{ width: 'fit-content' }}>
            <label style={{ marginLeft: '30px', marginTop: '32px', display: 'inline-block', height: '50px' }}>
              CGroup
              <Checkbox id="defaultGroup" name="defaultGroup" checked={defaultContent} onChange={onChangeDefaultGroup} disabled={validToUpdate} />
            </label>
            {defaultContent && (
              <FormControl variant="outlined" required>
                <InputLabel id="cGroupSegmentType">C Group Segment Type </InputLabel>
                <Select style={{ width: 285 }} label="cGroupSegmentType" id="cGroupSegmentType" value={comparativeSegmentType} onChange={handleChangeComparativeSegmentType} disabled={validToUpdate}>
                  {[...comparativeSegmentTypes].map((values, keys) => (
                    <MenuItem key={values[0]} value={values[0]}>
                      {values[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {defaultContent && (
              <FormControl variant="outlined" required>
                <InputLabel id="cGroupSegment">C Group Segment</InputLabel>
                <Select style={{ width: 285 }} label="cGroupSegment" id="cGroupSegment" value={comparativeSegmentId ? comparativeSegmentId : ''} onChange={handleChangeComparativeSegmentId} disabled={validToUpdate}>
                  {segment.filter(seg => seg.segmentType === comparativeSegmentType && seg.segmentGroup === 'C').map(segC => (
                    <MenuItem key={segC} value={segC.segmentId} title={segC.description}>
                      {segC.segmentId}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        </div>
        <div style={{ display: 'flex' }} className="filter_row">
          <FormControl variant="outlined">
            <InputLabel id="dayOfWeek">Day of week</InputLabel>
            <Select style={{ width: 285, }} label="dayOfWeek" id="dayOfWeek" multiple value={dayOfWeek} onChange={handleDayOfWeekChange} renderValue={selected => selected.join(', ')} MenuProps={MenuProps} disabled={validToUpdate}>
              {dayOfweekList.map(day => (
                <MenuItem key={day} value={day}>
                  <Checkbox checked={dayOfWeek.indexOf(day) > -1} />
                  <ListItemText primary={day} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </Fragment>
  );
}
AddServiceFilter.propTypes = {
  abTestTabCategory: PropTypes.array.isRequired,
  regionCountries: PropTypes.array.isRequired,
  searchData: PropTypes.object.isRequired,
  handleSearchDataChange: PropTypes.func.isRequired,
  getChannelMapList: PropTypes.func.isRequired,
  channelMapList: PropTypes.array.isRequired,
  requiredField: PropTypes.bool.isRequired,
  regionList: PropTypes.array.isRequired,
  getABTestTabCategoryAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  regionCountries: state.cntry.regionCountries,
  segment: state.homeApp.segment,
  channelMapList: state.homeApp.notificationsChannelMapList,
});

const mapDispatchToProps = dispatch => ({
  getABTestTabCategoryAction(deviceType, region, platformVersion) {
    dispatch(getABTestTabCategoryAction(deviceType, region, platformVersion));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AddServiceFilter);
