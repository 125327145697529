import { combineReducers } from 'redux';
import data from './data';
import dataFetching from './dataFetching';
import graph from './graph';
import graphFetching from './graphFetching';
import tableDetail from './tableDetail';
import tableDetailFetching from './tableDetailFetching';
import statisticResultShow from './statisticResultShow';

export default combineReducers({
  data,
  dataFetching,
  graph,
  graphFetching,
  tableDetail,
  tableDetailFetching,
  statisticResultShow
});
