import * as actions from '../../actions/actionTypes';

import { ROLE_AUTH_VIEW, ROLE_AUTH_EDIT } from '../../constants';

const initialState = '';

export default function userAuth(state = initialState, action) {
  switch (action.type) {
    case actions.INIT_CURRENT_USER:
      return initialState;
    case actions.SET_CURRENT_USER:
      if (action.body && action.body.roles) {
        if (action.body.roles.find(i => i.name === ROLE_AUTH_EDIT)) {
          return ROLE_AUTH_EDIT;
        } else if (action.body.roles.find(i => i.name === ROLE_AUTH_VIEW)) {
          return ROLE_AUTH_VIEW;
        } else {
          return "NO_AUTH";
        }
      } else {
        return state;
      }
    default:
      return state;
  }
}