import { showLoader, hideLoader } from './loaderActions';
import { API_BASE_URL, API_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';
import { SET_SOURCE_CHANNEL, SET_WATCH_LIVE_CHANNELS, SET_SAVE_FLAG, SET_SAVE_FAIL, SET_EXPORTS , SET_WATCH_LIVE_CHANNELS_ACTUAL} from './actionTypes';

const URL = API_URL;
const APIURL = API_BASE_URL;

export const getWatchLiveChannelList = (countryCode, providerId, chId, chName, selectStatus) => dispatch => {
  let config = {
    method: 'GET',
    headers: getHeaders(),
  };
  let apiUrl = `${APIURL}/admin/watchLiveChannelSearch?countryCode=${countryCode}&providerId=${providerId}&chId=${chId}&chName=${chName}&status=${selectStatus}`;
  dispatch(showLoader());
  fetch(apiUrl, config)
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'GET_RS_CHANNEL_IDS', payload: { list: [], isDataLoad: false } });
      dispatch(hideLoader());

      if (body.status) alert(`[Server Response Error]${body}`);
      else {
        let list = body.list;

        let sourceChannel = {
          channellist: [...list],
        };

        const channelIds = list.map(id => id.channelId) || [];
        channelIds && channelIds.length && dispatch(getRsChannelIds(countryCode, channelIds));
        dispatch({
          type: SET_SOURCE_CHANNEL,
          payload: sourceChannel,
        });
      }
    })
    .catch(ex => {
      alert(ex);
      dispatch(hideLoader());
    });
};

export const getRsChannelIds = (country, channelIds) => dispatch => {
  let apiUrl = `${APIURL}/admin/${country}/channel-ids`;
  fetch(apiUrl, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(channelIds),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'GET_RS_CHANNEL_IDS', payload: { list: body, isDataLoad: true } });
    })
    .catch(ex => {
      dispatch({ type: 'GET_RS_CHANNEL_IDS_ERROR', payload: ex });
    });
};

export const getWatchLiveChannelListByCountryAndStatus = (countryCode, status , recommendMonth) => dispatch => {
  let apiUrl = `${APIURL}/admin/country/${countryCode}/${status}/${recommendMonth}/getWatchLiveChannelList`;
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      let newList = body.list.map(v => {
        return {
          channelId: v.channelId,
          popChannelId: v.popChannelId,
          channelName: v.channelName,
          channelNumber: v.channelNumber,
          channelGenreName: v.channelGenreName,
          cpId: v.cpId,
          cpName: v.cpName,
          logoUrl: v.logoUrl,
          resultOrder: v.resultOrder,
          manualFlag: v.manualFlag,
          channelIdx: v.channelIdx,
          popChannelAdded: v.changed,
          recommendedMonth: v.recommendedMonth
        };
      });
      newList.sort((a, b) => a.resultOrder - b.resultOrder);
      const originatData = JSON.parse(JSON.stringify(newList));
      dispatch({
        type: SET_WATCH_LIVE_CHANNELS,
        payload: originatData,
      });
      dispatch({
        type: SET_WATCH_LIVE_CHANNELS_ACTUAL,
        payload: newList,
      });
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch(hideLoader());
    });
};
export const setExportFalse = bool => dispatch => {
  dispatch({
    type: SET_EXPORTS,
    payload: { result: bool, msg: '' },
  });
};
export const saveWatchLive = (countryCode, list, saveType) => dispatch => {
  let apiUrl = `${APIURL}/admin/watch-live/${countryCode}/saved-channels?saveType=${saveType}`;
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(list, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (body.returnValue) {
        dispatch({
          type: SET_SAVE_FLAG,
          payload: true,
        });
      } else if (!body.returnValue) {
        dispatch({
          type: SET_SAVE_FAIL,
          payload: `Error: ${body.message}`,
        });
      }
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch({
        type: SET_SAVE_FLAG,
        payload: setExportFalse,
      });
      dispatch(hideLoader());
    });
};

export const resetWatchLiveChannels = (countryCode, status) => dispatch => {
  let apiUrl = `${APIURL}/admin/country/${countryCode}/${status}/resetWatchLiveChannels`;
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      let newList = body.list.map(v => {
        return {
          channelId: v.channelId,
          popChannelId: v.popChannelId,
          channelName: v.channelName,
          channelNumber: v.channelNumber,
          channelGenreName: v.channelGenreName,
          cpId: v.cpId,
          cpName: v.cpName,
          logoUrl: v.logoUrl,
          resultOrder: v.resultOrder,
          manualFlag: v.manualFlag,
          channelIdx: v.channelIdx,
          popChannelAdded: v.changed
        };
      });
      newList.sort((a, b) => a.resultOrder - b.resultOrder);
      dispatch({
        type: SET_WATCH_LIVE_CHANNELS,
        payload: newList,
      });
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch(hideLoader());
    });
};
