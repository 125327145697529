import ServiceDashboard01 from './ServiceDashboard/ServiceDashboard01';
import ServiceDashboard02 from './ServiceDashboard/ServiceDashboard02';
import ServiceDashboard03 from './ServiceDashboard/ServiceDashboard03';
import ServiceDashboard04 from './ServiceDashboard/ServiceDashboard04';
import ServiceDashboard05 from './ServiceDashboard/ServiceDashboard05';
import ServiceDashboard06 from './ServiceDashboard/ServiceDashboard06';
import ContentDashboard01 from './ContentDashboard/ContentDashboard01';
import ContentDashboard02 from './ContentDashboard/ContentDashboard02';
import ContentDashboard03 from './ContentDashboard/ContentDashboard03';
import ContentDashboard04 from './ContentDashboard/ContentDashboard04';
import StatisticsDashboard01 from './StatisticsDashboard/StatisticsDashboard01';
import AdvertisementDashboard01 from './AdvertisementDashboard/AdvertisementDashboard01';
import ServiceDetail01 from './ServiceDetail/ServiceDetail01';
import ServiceDetail02 from './ServiceDetail/ServiceDetail02';
import ServiceDetail03 from './ServiceDetail/ServiceDetail03';
import ServiceDetail04 from './ServiceDetail/ServiceDetail04';
import ContentDetail01 from './ContentDetail/ContentDetail01';
import ContentDetail02 from './ContentDetail/ContentDetail02';
import ContentDetail03 from './ContentDetail/ContentDetail03';
import StatisticsDetail01 from './StatisticsDetail/StatisticsDetail01';
import StatisticsDetail02 from './StatisticsDetail/StatisticsDetail02';
import StatisticsDetail03 from './StatisticsDetail/StatisticsDetail03';
import AdvertisementDetail01 from './AdvertisementDetail/AdvertisementDetail01';
import IssueHistory01 from './IssueHistory/IssueHistory01';
import IssueHistory02 from './IssueHistory/IssueHistory02';
import IssueHistory03 from './IssueHistory/IssueHistory03';
import IssueHistory04 from './IssueHistory/IssueHistory04';
import IssueHistory05 from './IssueHistory/IssueHistory05';
import IssueHistory06 from './IssueHistory/IssueHistory06';
import IssueTrend01 from './IssueTrend/IssueTrend01';
import IssueTrend02 from './IssueTrend/IssueTrend02';
import IssueTrend03 from './IssueTrend/IssueTrend03';
import IssueTrend04 from './IssueTrend/IssueTrend04';

export const TutorialItems = {
  serviceDashboard: [ServiceDashboard01, ServiceDashboard02, ServiceDashboard03, ServiceDashboard04, ServiceDashboard05, ServiceDashboard06],
  contentDashboard: [ContentDashboard01, ContentDashboard02, ContentDashboard03, ContentDashboard04],
  statisticsDashboard: [StatisticsDashboard01],
  advertisementDashboard: [AdvertisementDashboard01],
  serviceDetail: [ServiceDetail01, ServiceDetail02, ServiceDetail03, ServiceDetail04],
  contentDetail: [ContentDetail01, ContentDetail02, ContentDetail03],
  statisticsDetail: [StatisticsDetail01, StatisticsDetail02, StatisticsDetail03],
  advertisementDetail: [AdvertisementDetail01],
  issueHistory: [IssueHistory01, IssueHistory02, IssueHistory03, IssueHistory04, IssueHistory05, IssueHistory06],
  issueTrend: [IssueTrend01, IssueTrend02, IssueTrend03, IssueTrend04],
};

