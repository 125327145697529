import React from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 18px;
    --y: 189px;
    --width: 948px;
    --height: 530px;

    top: 0;
    left: 0;
    width: 961px;
    height: 1080px;
  }

  #clip-02 {
    --x: 820px;
    --y: 92px;
    --width: 113px;
    --height: 55px;

    top: 0;
    left: 962px;
    width: calc(100% - 962px);
    height: 1080px;
  }

  #text-01 {
    top: 285px;
    left: 997px;
  }

  #line-01 {
    top: 127px;
    left: 965px;
  }
`;

const IssueTrend04 = () => {
  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" className="border" />
        </div>

        <div id="clip-02" className="clip-box">
          <div id="clip-02-hole" className="hole" />
          <div id="clip-02-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">You can see the indicator in graphs<br/>
                and tables. You can also set detailed<br/>
                conditions through filters.
            </div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_6125_line_01.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default IssueTrend04;