import * as actions from '../../actions/actionTypes';

const initialState = [];
export default function revokeChannelNameList(state = initialState, action) {
  switch (action.type) {
    case actions.SET_REVOKE_CHANNEL_NAME_LIST:
      return action.payload;
    default:
      return state;
  }
}
