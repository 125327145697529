import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

export default function PublishButtons({ publishType, onPublish, onClose }) {
  return (
    <React.Fragment>
      <div className="btn_set_wrap">
        <Button variant="contained" onClick={onPublish} className="btn_color2">
          {publishType}
        </Button>
        <Button variant="contained" onClick={onClose} className="btn_color1">
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
}
PublishButtons.propTypes = {
  publishType: PropTypes.string.isRequired,
  onPublish: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
