import * as actions from '../../actions/actionTypes';

const initialState = 0;

export default function apiKeyId(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CURRENT_USER:
      if (action.body && typeof action.body.apiKeyId !== 'undefined') {
        return action.body.apiKeyId;
      } else {
        return state;
      }
    case actions.SET_API_KEY_ID:
      if (action.body && typeof action.body.id !== 'undefined') {
        return action.body.id;
      } else {
        return state;
      }
    default:
      return state;
  }
}
