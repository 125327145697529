import React from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 16px;
    --y: 167px;
    --width: 310px;
    --height: 120px;

    top: 0;
    left: 0;
    width: 1920px;
    height: 302px;
  }

  #clip-02 {
    --x: 16px;
    --y: 1px;
    --width: 310px;
    --height: 410px;

    top: 290px;
    left: 0;
    width: 1920px;
    height: 420px;
  }

  #clip-03 {
    --x: 16px;
    --y: 8px;
    --width: 310px;
    --height: 126px;

    top: 702px;
    left: 0;
    width: 1920px;
    height: 358px;
  }

  #text-01 {
    top: 182px;
    left: 446px;
  }

  #text-02 {
    top: 385px;
    left: 446px;
  }

  #text-03 {
    top: 740px;
    left: 446px;
  }

  #line-01 {
    top: 211px;
    left: 324px;
  }

  #line-02 {
    top: 476px;
    left: 324px;
  }

  #line-03 {
    top: 770px;
    left: 324px;
  }
`;

const ServiceDashboard01 = () => {
  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" className="border" />
        </div>

        <div id="clip-02" className="clip-box">
          <div id="clip-02-hole" className="hole" />
          <div id="clip-02-border" className="border" />
        </div>

        <div id="clip-03" className="clip-box">
          <div id="clip-03-hole" className="hole" />
          <div id="clip-03-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">
            You can see the total number of<br/>
            issues that have occurred.
          </div>
          <div id="text-02" className="text">
            You can select the major issue info tab to display the number of issues,<br/>
            the number of issues being processed or in progress, and the number<br/>
            of completed issues for the relevant tab items.<br/>
            Also the world map section which one on the right side would be<br/>
            changed too.
          </div>
          <div id="text-03" className="text">
            You can search issue with issue ID(it should be enterd correctly).<br/>
            After entering the issue ID, be moved to the detail page for the issue.
          </div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_1937_line_01.png" alt='Line' />
          </div>
          <div id="line-02" className="line">
            <img src="/common/images/iss/tutorial/t_1937_line_02.png" alt='Line' />
          </div>
          <div id="line-03" className="line">
            <img src="/common/images/iss/tutorial/t_1937_line_03.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default ServiceDashboard01;