import { createMuiTheme } from '@material-ui/core/styles';

// 빈 palette전달하면 기본색을 사용한다
const palette = {
  // primary: { main: '#A50034' },
  // secondary: { main: '#6b6b6b' }
};

// const themeName = 'Burgundy Dove Gray Horses';

// const typography = {
// 	useNextVariants: true
// };

// export default createMuiTheme({ palette, typography, themeName });
//export default createMuiTheme({ palette, themeName });//typography빼도 달라진점 없는듯
export default createMuiTheme({ palette }); //typography, themeName빼도 달라진점 없는듯
