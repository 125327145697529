import React from 'react';
import { CHART_COLORS } from './ChartConsts';

const DoughnutLegend = () => {
  return (
    <>
      <div className="chart-doughnut-legend">
        <ul>
          <li>
            <i style={{ backgroundColor: `${CHART_COLORS.PURPLE}` }}></i>
            <span>LG Channels HomeApp</span>
          </li>
          <li>
            <i style={{ backgroundColor: `${CHART_COLORS.ORANGE}` }}></i>
            <span>Playback</span>
          </li>
          {/* <li>
            <i style={{ backgroundColor: `${CHART_COLORS.BLUE}` }}></i>
            <span>Schedule</span>
          </li>
          <li>
            <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
            <span>Image</span>
          </li>
          <li>
            <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
            <span>Service Data</span>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default DoughnutLegend;
