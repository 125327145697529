import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { convertToUtc } from '../channelList/channelList.js';
import { LightTooltip } from '../../../../common/Tooltip';

const useStyles = makeStyles(() => ({
  guideStyle: {
    wordWrap: 'break-word',
    //border: 'solid 0.5px rgb(143, 145, 146)',
    borderStyle: 'solid',
    borderWidth: '0.2px 0.2px 0.2px 0.2px',
    textAlign: 'left',
    height: '8.0166vh', //없으면 웹페이지 비율바뀌면 안맞게됨
    minHeight: '50px', /////////////64면 화면확대해도 문제없는데 50이면 문제됨 why?
    padding: '17px', //없으면 제목이 위로올라감
    position: 'relative', //무조건 0시부터 시작하는게 아니면 이거 꼭 있어야함
    overflow: 'hidden',
  },
  programTitle: {
    //fontSize: '1.1rem', //없으면 글자엄청커짐
    margin: '0', //없으면 이상해짐
    //fontWeight: '500'
  },
  programTime: {
    color: '#7a58d8',
  },
  shownow: {
    backgroundColor: 'rgb(167, 165, 165)',
  },
  tooltip: {
    fontSize: 20,
  },
}));

const Guide = ({ programLeft, programWidth, schedule, index, clickProgramDialog }) => {
  const classes = useStyles();
  const showNow = classes.guideStyle;
  /*currentShow(schedule, index)
    ? `${classes.guideStyle} ${classes.shownow + ' on_time'}`
    : classes.guideStyle;*/

  return (
    <div>
      {schedule ? (
        <LightTooltip
          arrow
          title={
            <React.Fragment>
              {schedule.title} <br /> <b>{translateDateToolTip(schedule)}</b>
            </React.Fragment>
          }>
          <li style={{ left: programLeft, width: programWidth }} className={showNow + ' program_list_body'} id={schedule.programId}>
            <button className={classes.programTitle + ' program_list'} onClick={clickProgramDialog}>
              <span className={classes.programTime + ' program_time'}>{translateDate(schedule, index)}</span>
              <span className={'program_title'}>{schedule.title}</span>
            </button>
          </li>
        </LightTooltip>
      ) : (
        <li style={{ left: programLeft, width: programWidth }} className={showNow + ' program_list_body'}>
          <span className={'program_title'}>
            <br />
            <center>No data</center>
          </span>
        </li>
      )}
    </div>
  );
};
Guide.propTypes = {
  programLeft: PropTypes.number.isRequired,
  programWidth: PropTypes.number.isRequired,
  schedule: PropTypes.object,
  index: PropTypes.number,
  clickProgramDialog: PropTypes.func,
};

export default Guide;

function translateDate(schedule, index) {
  const strt = convertToUtc(new Date(schedule.start));
  const end = convertToUtc(new Date(schedule.end));
  if (strt.getDate() !== end.getDate())
    if (index === 0) return ' - ' + ('0' + end.getHours()).slice(-2) + ':' + ('0' + end.getMinutes()).slice(-2);
    else return ('0' + strt.getHours()).slice(-2) + ':' + ('0' + strt.getMinutes()).slice(-2) + ' - ';
  else return ('0' + strt.getHours()).slice(-2) + ':' + ('0' + strt.getMinutes()).slice(-2) + ' - ' + ('0' + end.getHours()).slice(-2) + ':' + ('0' + end.getMinutes()).slice(-2);
}
function translateDateToolTip(schedule) {
  const start = convertToUtc(new Date(schedule.start));
  const end = convertToUtc(new Date(schedule.end));
  return (
    ('0' + (start.getMonth() + 1)).slice(-2) +
    '/' +
    ('0' + start.getDate()).slice(-2) +
    ' ' +
    ('0' + start.getHours()).slice(-2) +
    ':' +
    ('0' + start.getMinutes()).slice(-2) +
    ' - ' +
    ('0' + (end.getMonth() + 1)).slice(-2) +
    '/' +
    ('0' + end.getDate()).slice(-2) +
    ' ' +
    ('0' + end.getHours()).slice(-2) +
    ':' +
    ('0' + end.getMinutes()).slice(-2)
  );
}

// function currentShow(schedule, index) {
//   const now = convertToUtc(new Date());
//   const start = convertToUtc(new Date(schedule.start));
//   const end = convertToUtc(new Date(schedule.end));
//   let startHour = start.getHours();
//   let endHour = end.getHours();

//   if (start.getDate() !== end.getDate())
//     if (index === 0) startHour += 24;
//     else endHour += 24;

//   return (
//     now.getHours() * 60 + now.getMinutes() > startHour * 60 + start.getMinutes() &&
//     now.getHours() * 60 + now.getMinutes() < endHour * 60 + end.getMinutes()
//   );
// }
