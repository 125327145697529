import * as actions from '../../actions/actionTypes';

const initialState = '';

export default function apikey(state = initialState, action) {
  switch (action.type) {
    case actions.INIT_CURRENT_USER:
      return initialState;
    case actions.SET_CURRENT_USER:
      if (action.body && typeof action.body.apikey !== 'undefined') {
        return action.body.apikey;
      } else {
        return state;
      }
    default:
      return state;
  }
}
