import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IssTable from '../common/IssTable';
import PrimaryButton from '../common/PrimaryButton';
import CauseDropDown from '../common/CauseDropDown';
import { request } from '../utils/request';
import Loading from '../common/Loading';
import { sub_statistics_tabledata,
  sub_statistics_beacon_chart,
  beacon_chartdata_value_dataset,
  beacon_chartdata_change_dataset } from './StatisticsDetailData';
import Alert from '../modal/Alert';
import CreateJiraModal from "../modal/CreateJira";
import ModalPopup from "../modal/ModalPopup";
import { ISS_BASE_URL } from '../../../constants/index';
import { getHeaders } from '../../../util/actionUtil';
import LineChart from '../common/LineChart';
import { CHART_COLORS, CHART_LINE_GRID_COLOR } from '../common/ChartConsts';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { getScalesMaxValue } from '../utils/IssUtils';

function StatisticsBeaconTab({ username, currentItem, currentFilter, currentTab, searchEvent }) {
  let directValue = 9999;
  let directName = 'Select Cause';
  let dataType = 'beacon';
  const [causeList, setCauseList] = useState([]);
  const [causeSel, setCauseSel] = useState(directValue);
  const [causeNameSel, setCauseNameSel] = useState(directName);
  const [causeTextArea, setCauseTextArea] = useState();
  const [isBeaconDisplay, setIsBeaconDisplay] = useState(false);
  const [isBeaconChartDisplay, setIsBeaconChartDisplay] = useState(false);
  const [isBeaconReferenceDisplay, setIsBeaconReferenceDisplay] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ issueId: '', issueCode: '' });
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Beacon Detail');
  const [alertMessage, setAlertMessage] = useState();
  const [currentFocusData, setCurrentFocusData] = useState({});
  const [isCheckboxValueInit, setCheckboxValueInit] = useState(false);
  const [updatingIssueList, setUpdatingIssueList] = useState([]); // {issueId:'', issueStatus:''} DB에 업데이트할 Issue list
  const [actionDetails, setActionDetails] = useState({ causeIdx: directValue, causeDetail: '' });
  const [issueIdList, setIssueIdList] = useState([]);
  const [isShowCreateJiraPopup, setShowCreateJiraPopup] = useState(false);
  const [isModalPopup, setModalPopup] = useState(false);
  const [modalPopupTitle, setModalPopupTitle] = useState('');
  const [beaconInitFlag, setBeaconInitFlag] = useState(true);
  const [beaconList, setBeaconList] = useState([]);
  const [beaconListFetching, setBeaconListFetching] = useState('init');
  const [currentBeaconListTableData, setCurrentBeaconListTableData] = useState({});
  const [beaconReferenceList, setBeaconReferenceList] = useState([]);
  const [beaconReferenceListFetching, setBeaconReferenceListFetching] = useState('init');
  const [currentBeaconReferenceListTableData, setCurrentBeaconReferenceListTableData] = useState({});
  const [beaconChart, setBeaconChart] = useState([]);
  const [beaconChartFetching, setBeaconChartFetching] = useState('init');
  const [isValueInitNeed, setValueInitNeed] = useState(false);
  const [page, setPage] = useState(1);
  const [paramPage, setParamPage] = useState();
  const defaultChartValue = 25;
  const maxLength = 1000;

  useEffect(() => {
    fetchCause();
    getAssignGroupList();
  }, []);

  useEffect(() => {
    if (isValueInitNeed) {
      setValueInitNeed(false);
      setCheckboxValueInit(false);
    }
  }, [isValueInitNeed]);

  useEffect(() => {
    if (currentFilter && currentTab === 1 && currentFilter.subType != null) {
      setIsBeaconDisplay(false);
      valueInitialize(currentFilter.pagingType, page);
      getBeaconListInfo(currentFilter);
    }
  }, [currentFilter]);

  useEffect(() => {
    if (currentItem && currentItem.issueId !== '') {
      setIsBeaconDisplay(false);
      setIsBeaconReferenceDisplay(false);
      setSelectedItem({ issueId: currentItem.issueId, issueCode: currentItem.issueCode });
    }
  }, [currentItem]);

  const getBeaconListInfo = filter => {
    let body = {
      dataSourceTypeList: filter.dataSourceType,
      detectingConditionTypeList: filter.detectingConditionType,
      groupList: filter.groupList,
    };
    setBeaconListFetching('loading');
    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/beaconIssue`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
    .then(res => res.json())
    .then(body => {
      setBeaconListFetching('done');
      setBeaconList(body);
    })
    .catch(error => {
      console.log(`getBeaconListInfo error: `, error);
    }).finally(() => {});
  };

  const getIssueBeaconChartInfo = (item) => {
    const body = {
      dataSourceType: item.dataSourceType,
      detectionItem: item.detectionItem,
      datetime: item.detectionTime,
      groupName: item.groupName,
      detectingType: item.detectingConditionType
    }
    setBeaconChartFetching('loading');

    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/beacon/beaconChart`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
    .then(res => res.json())
    .then(body => {
      body['referenceAvg'] = item.referenceAvg
      setBeaconChartFetching('done');
      setBeaconChart(body);
    })
    .catch(error => {
      console.log(`getIssueBeaconChartInfo error: `, error);
    }).finally(() => {});
  };

  const getBeaconReferenceListInfo = issueCode => {
    let body = { issueCode: issueCode };
    setBeaconReferenceListFetching('loading');

    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/issueBeaconReference`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
    .then(res => res.json())
    .then(body => {
      setBeaconReferenceListFetching('done');
      setBeaconReferenceList(body);
    })
    .catch(error => {
      console.log(`getBeaconReferenceListInfo error: `, error);
    }).finally(() => {});
  };

  useEffect(() => {
    if (beaconList && beaconList.length > 0) {
      let selectedData = undefined;

      if (currentItem && currentItem.issueId !== '') {
        selectedData = beaconList.find(item => item.issueId === currentItem.issueId);
      }

      if (selectedData) {
        const selectedRowdata = {
          row: [selectedData.issueId,
            selectedData.detectionTime,
            selectedData.issueLevel,
            selectedData.dataSourceType,
            selectedData.detectionItem,
            selectedData.detectingConditionType,
            `${Math.round(selectedData.docCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
            selectedData.diff > 0 ? '+' + Math.round(selectedData.diff * 10) / 10 + '%' : Math.round(selectedData.diff * 10) / 10 + '%',
            selectedData.groupName,
            selectedData.status,
            selectedData.assignGroup,
            selectedData.lastChgUsrId,
            selectedData.jiraUrl,
            selectedData.causeProcessDetail
          ],
          issueId: selectedData.issueId,
          issueCode: selectedData.issueCode,
          issueStatus: selectedData.status,
          causeIdx: selectedData.causeIdx,
          causeProcessDetail: selectedData.causeProcessDetail,
          assignGroup: selectedData.assignGroup,
          dataSourceType: selectedData.dataSourceType,
          detectionItem: selectedData.detectionItem,
          detectionTime: selectedData.detectionTime,
          groupName: selectedData.groupName,
          detectingConditionType: selectedData.detectingConditionType,
          deeplinkFlag: true,
          referenceAvg: selectedData.referenceAvg
        };
        const othersRowdata = beaconList
          .filter(item => item.issueId !== selectedData.issueId)
          .map(item => ( {
          row: [item.issueId,
            item.detectionTime,
            item.issueLevel,
            item.dataSourceType,
            item.detectionItem,
            item.detectingConditionType,
            `${Math.round(item.docCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
            item.diff > 0 ? '+' + Math.round(item.diff * 10) / 10 + '%' : Math.round(item.diff * 10) / 10 + '%',
            item.groupName,
            item.status,
            item.assignGroup,
            item.lastChgUsrId,
            item.jiraUrl,
            item.causeProcessDetail
          ],
          issueId: item.issueId,
          issueCode: item.issueCode,
          causeIdx: item.causeIdx,
          issueStatus: item.status,
          causeProcessDetail: item.causeProcessDetail,
          assignGroup: item.assignGroup,
          issueLevel: item.issueLevel,
          dataSourceType: item.dataSourceType,
          detectionItem: item.detectionItem,
          detectionTime: item.detectionTime,
          groupName: item.groupName,
          detectingConditionType: item.detectingConditionType,
          referenceAvg: item.referenceAvg
        }));
        sub_statistics_tabledata.beacon_data.rowdata = [selectedRowdata, ...othersRowdata];
        setCurrentBeaconListTableData(sub_statistics_tabledata.beacon_data);
        onClickRowItem(selectedRowdata);
      } else {
        sub_statistics_tabledata.beacon_data.rowdata = beaconList.map(item => ( {
          row: [item.issueId,
            item.detectionTime,
            item.issueLevel,
            item.dataSourceType,
            item.detectionItem,
            item.detectingConditionType,
            `${Math.round(item.docCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
            item.diff > 0 ? '+' + Math.round(item.diff * 10) / 10 + '%' : Math.round(item.diff * 10) / 10 + '%',
            item.groupName,
            item.status,
            item.assignGroup,
            item.lastChgUsrId,
            item.jiraUrl,
            item.causeProcessDetail
          ],
          issueId: item.issueId,
          issueCode: item.issueCode,
          issueStatus: item.status,
          causeIdx: item.causeIdx,
          causeProcessDetail: item.causeProcessDetail,
          assignGroup: item.assignGroup,
          issueLevel: item.issueLevel,
          dataSourceType: item.dataSourceType,
          detectionItem: item.detectionItem,
          detectionTime: item.detectionTime,
          groupName: item.groupName,
          detectingConditionType: item.detectingConditionType,
          referenceAvg: item.referenceAvg
        }))
        sub_statistics_tabledata.beacon_data.rowdata[0].deeplinkFlag = true;
        setCurrentBeaconListTableData(sub_statistics_tabledata.beacon_data);
        onClickRowItem(sub_statistics_tabledata.beacon_data.rowdata[0]);
      }
    } else {
      sub_statistics_tabledata.beacon_data.rowdata = [];
      beacon_chartdata_value_dataset.data = [];
      beacon_chartdata_change_dataset.data = [];
      sub_statistics_beacon_chart.value_graph.options.scales.y.min = 0;
      sub_statistics_beacon_chart.value_graph.options.scales.y.max = defaultChartValue;
      sub_statistics_beacon_chart.value_graph.options.baseline.value = 0;
      beacon_chartdata_value_dataset.data = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      sub_statistics_beacon_chart.value_graph.data.datasets = [beacon_chartdata_value_dataset];
      sub_statistics_beacon_chart.changes_graph.options.scales.y.min = 0;
      sub_statistics_beacon_chart.changes_graph.options.scales.y.max = defaultChartValue;
      sub_statistics_beacon_chart.changes_graph.options.baseline.value = 0;
      beacon_chartdata_change_dataset.data = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      sub_statistics_beacon_chart.changes_graph.data.datasets = [beacon_chartdata_change_dataset];
      setCurrentBeaconListTableData(sub_statistics_tabledata.beacon_data);
      if(beaconInitFlag === true) {
        setBeaconInitFlag(false);
      } else {
        setAlertTitle(`Info`);
        setAlertMessage(`There is no data`);
        onOpenAlert();
        if (beaconChartFetching === 'init') {
          setIsBeaconChartDisplay(true);
        }
      }
    }
    if (beaconListFetching === 'done') {
      setIsBeaconDisplay(true);
    }
  }, [beaconList]);

  useEffect(() => {
    if (beaconChart.weekly || beaconChart.daily) {
      beacon_chartdata_value_dataset.data = [];
      beacon_chartdata_change_dataset.data = [];
      // average of document count of last one week
      let weeklyAvgDocCount = beaconChart['referenceAvg']
      let weeklyMinDocCount = Math.min(...beaconChart.weekly);
      let weeklyMaxDocCount = Math.max(...beaconChart.weekly);
      if(weeklyMaxDocCount === 0) weeklyMaxDocCount = defaultChartValue;
      if(weeklyMinDocCount === weeklyMaxDocCount) weeklyMinDocCount = 0;
      sub_statistics_beacon_chart.value_graph.options.scales.y.min = 0;
      sub_statistics_beacon_chart.value_graph.options.scales.y.max = getScalesMaxValue(weeklyMaxDocCount);
      sub_statistics_beacon_chart.value_graph.options.baseline.value = weeklyAvgDocCount;
      sub_statistics_beacon_chart.value_graph.data.labels = beaconChart.periodArr;
      beacon_chartdata_value_dataset.data = beaconChart.weekly;

      let dailyMinDocCount = parseInt(beaconChart.dailyMinDocCount[0]);
      let dailyMaxDocCount = parseInt(beaconChart.dailyMaxDocCount[0]);
      let dailyAvgDocCount = parseInt(beaconChart.dailyAvgDocCount[0]);
      if(dailyMaxDocCount === 0) dailyMaxDocCount = defaultChartValue;
      if(dailyMinDocCount === dailyMaxDocCount) dailyMinDocCount = 0;
      sub_statistics_beacon_chart.changes_graph.options.scales.y.min = dailyMinDocCount;
      sub_statistics_beacon_chart.changes_graph.options.scales.y.max = getScalesMaxValue(dailyMaxDocCount);
      sub_statistics_beacon_chart.changes_graph.options.baseline.value = parseInt(dailyAvgDocCount);
      sub_statistics_beacon_chart.changes_graph.data.labels = beaconChart.dailyPeriodArr;
      beacon_chartdata_change_dataset.data = beaconChart.daily;
    }
    if (beaconChartFetching === 'done') {
      setIsBeaconChartDisplay(true);
    }
  }, [beaconChart]);

  useEffect(() => {
    if (beaconReferenceList && beaconReferenceList.length > 0) {
      sub_statistics_tabledata.beacon_existing_issues.rowdata = beaconReferenceList.map(item => ( {
        row: [item.issueId,
          item.detectionTime,
          item.issueLevel,
          `${Math.round(item.docCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
          item.diff > 0 ? '+' + Math.round(item.diff * 10) / 10 + '%' : Math.round(item.diff * 10) / 10 + '%',
          item.groupName,
          item.causeName,
          item.assignGroup,
          item.lastChgUsrId,
          item.jiraUrl,
          item.causeProcessDetail
        ]
      }));
      setCurrentBeaconReferenceListTableData(sub_statistics_tabledata.beacon_existing_issues);
      if (beaconReferenceListFetching === 'done') {
        setIsBeaconReferenceDisplay(true);
      }
    }
  }, [beaconReferenceList]);

  useEffect(() => {
    if (selectedItem && selectedItem.issueId !== '') {
      getBeaconReferenceListInfo(selectedItem.issueCode);
    }
    setCurrentFocusData(selectedItem);
  }, [selectedItem]);

  const onClickRowItem = (item) => {
    if (item && item.issueId !== '') {
      setCurrentFocusData(item);
      setIsBeaconReferenceDisplay(false);
      let findCause = causeList.find(cause => cause.index === item.causeIdx);
      if(findCause) {
        handleChangeCause(findCause);
      } else {
        handleChangeCause({index:directValue, name:directName});
      }
      setSelectedItem({ issueId: item.issueId, issueCode: item.issueCode });
      setIsBeaconChartDisplay(false);
      getIssueBeaconChartInfo(item);
    }
    setActionDetails({ causeIdx: item.causeIdx, causeDetail: item.causeProcessDetail });
  };

  const valueInitialize = (mode, item) => {
    if (mode === 'Save'){
      setParamPage(page);
      setPage(item);
    } else if (mode === 'paging'){
      setParamPage();
      setPage(item);
    } else {
      setParamPage();
      setPage(1);
    }
    setValueInitNeed(true);
    setIsBeaconReferenceDisplay(false);
    setIsBeaconChartDisplay(false);
    setBeaconChart([]);
    setSelectedItem({ issueId: '', issueCode: '' });
    setActionDetails({ causeIdx: directValue, causeDetail: '' });
    currentItem = null;
    sub_statistics_tabledata.beacon_existing_issues.rowdata = [];
    setCurrentBeaconReferenceListTableData(sub_statistics_tabledata.beacon_existing_issues);
    checkboxValueInitialize();
  };

  const fetchCause = async code => {
    const causeDrop = await request(`/api/statistics/dashboard/cause/${dataType}`, 'GET', null, true);
    let causeMap = causeDrop.map(n => ({ index: n.causeIdx, name: n.causeName.length > 40
      ? n.causeName.substring(0, 40) + '...' : n.causeName }));
    causeMap.push({ index: directValue, name: directName });
    causeMap.sort((a, b) => (a.index > b.index ? -1 : b.index > a.index ? 1 : 0))
    setCauseList(causeMap);
  };

  const getAssignGroupList = () => {
    fetch(`${ISS_BASE_URL}/api/common/issue/management/group`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        const groupNameList = [... new Set(body.dataSet.map(item => item.groupName))];
        const tempGroupNameList = groupNameList.map(item => {
          return { index: item, name: item }
        });
        sub_statistics_tabledata.beacon_data.assignDropdownItem = tempGroupNameList;
      } else {
        console.log(`getStatisticsErrorCount managementGroup fetching error`);
      }
    })
    .catch(error => {
      console.log(`getStatisticsErrorCount error: `, error);
    });
  };

  const handleTextAreaChange = event => {
    const { value } = event.target;
    if (value.length <= maxLength) {
      setCauseTextArea(value);
      setActionDetails({ causeIdx: actionDetails.causeIdx, causeDetail: value });
    }
  };

  const handleChangeCause = (item) => {
    setCauseSel(item.index);
    setCauseNameSel(item.name);
    setActionDetails({ causeIdx: item.index, causeDetail: actionDetails.causeDetail });
  };

  const onSubmit = async (event, item) => {
    event.preventDefault();

    switch (item) {
      case 'Save': {
        if(updatingIssueList && updatingIssueList.length > 0) {
          const findItem = updatingIssueList.find(item => currentFocusData && item.issueId == currentFocusData.issueId);
          if(!findItem){
            setAlertTitle(`Info`);
            setAlertMessage(`Please select a data`);
            onOpenAlert();
            return;
          }
          if (findItem && findItem.issueStatus === 'Resolve' && causeSel === directValue) {
            setAlertTitle(`Info`);
            setAlertMessage('Please select a Cause');
            onOpenAlert();
            return;
          }
          let itemList = [];
          for(let i=0; i< updatingIssueList.length; i++) {
            itemList.push({
              user: username === 'admin' ? 'cmpuser' : username,
              causeIdx: actionDetails.causeIdx,
              issueId: updatingIssueList[i].issueId,
              causeProcessDetail: causeTextArea,
              dataType: dataType,
              status: findItem.issueStatus,
              assignGroup: findItem.assignGroup,
            })
          }
          let isSuccess = await request(`/api/statistics/dashboard/updateCause`, 'PATCH', itemList, true, false, false);

          if (isSuccess.success) {
            setAlertTitle('Info');
            setAlertMessage('New cause is saved successfully');
            onOpenAlert();
            searchEvent('Save');
          } else {
            setAlertTitle('Info');
            setAlertMessage(isSuccess.message);
            onOpenAlert();
          }
        } else {
          setAlertTitle('Info');
          setAlertMessage('Please select any checkbox');
          onOpenAlert();
          return;
        }
        break;
      }
      case 'Jira': {
        if (updatingIssueList.length > 0) {
          let existJiraUrlList = [];

          if (currentTab === 1) {
            existJiraUrlList = updatingIssueList.filter(data => beaconList.find(item => item.issueId === data.issueId && item.jiraUrl)).map(item => item.issueId);
          } else {
            console.error(`[ISS] currentTab[${currentTab}] error, so ignore`);
            return;
          }

          if (existJiraUrlList.length > 0) {
            setAlertTitle('Info');
            setAlertMessage(`IssueId:(${existJiraUrlList}) is already registered in jira`);
            onOpenAlert();
          } else {
            const issueIds = updatingIssueList.map(item => item.issueId);
            setIssueIdList(issueIds);
            setShowCreateJiraPopup(true);
          }
        } else {
          setAlertTitle('Info');
          setAlertMessage(`Please select any checkbox`);
          onOpenAlert();
        }
        break;
      }
    }
  };

  const handleJiraCallback = (data) => {
    if (data.result === 'SUCCESS') {
      setAlertTitle(`Info`);
      setAlertMessage(`Jira creation success`);
      onOpenAlert();
      searchEvent();
    } else {
      setAlertTitle('Error');
      setAlertMessage(`Jira creation failure, reason: ` + data.reason);
      onOpenAlert();
    }
  };

  const OnCloseCreateJiraPopop = () => {
    setShowCreateJiraPopup(false);
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const updateDropDownList = (data) => {
    if (updatingIssueList.length > 0) {
      const modifyUpdatingIssueList = updatingIssueList.map(item => {
        return { issueId: item.issueId, issueStatus: data.status, assignGroup: item.assignGroup };
      });

      setUpdatingIssueList(modifyUpdatingIssueList);
    }
  };

  const updateAssignDropDownList = (data) => {
    if (updatingIssueList.length > 0) {
      const modifyUpdatingIssueList = updatingIssueList.map(item => {
        return { issueId: item.issueId, issueStatus: item.issueStatus, assignGroup: data.status };
      });

      setUpdatingIssueList(modifyUpdatingIssueList);
    }
  };

  const checkboxValueInitialize = () => {
    setCheckboxValueInit(true);
    setUpdatingIssueList([]);
  };

  const updateCheckedList = items => {
    if (items.length > 0) {
      const checkedIssueList = items.map(item => {
        return {issueId: item.issueId, issueStatus: item.issueStatus, assignGroup: item.assignGroup};
      });
      setUpdatingIssueList(checkedIssueList);
    } else {
      setUpdatingIssueList([]);
    }
  };

  const handleJiraStatusCallback = status => {
    console.info(`[ISS] jira status: ${status}`);

    if (status === 'processing') {
      setModalPopupTitle('Jira Processing');
      setModalPopup(true);
    } else {
      setModalPopup(false);
    }
  };

  const changeDropDownAlert = () => {
    setAlertTitle('Warning');
    setAlertMessage('Select the checkbox to change the Dropdown box.');
    onOpenAlert();
  };

  return (
    <>
    {isBeaconDisplay ? (
      <div className="grid-row-wide">
        <div className="row-box box-tbl box-statistics tbl-type-short">
        <IssTable height={'calc(100% - 55px)'} pagination={true}
          data={currentBeaconListTableData}
          handleClickItem={item => onClickRowItem(item)}
          pagingChangeCallback={(item) => valueInitialize('paging', item)}
          handleDropDownList={(item) => updateDropDownList(item)}
          handleCheckedList={item => updateCheckedList(item)}
          isCheckboxValueInit={isCheckboxValueInit}
          handleAssignDropDownList={item => updateAssignDropDownList(item)}
          sortingItem={{enable: true, sortColumn: 1, sortDirection: 'desc'}}
          changeDropDownAlert={changeDropDownAlert}
          handlePage={paramPage ? paramPage : page}
        />
        </div>
        <div className="row-box box-action">
          <div className="grid-column-3">
            <div className="box-docCount-detail">
              <div className="head">
                <div className="title">
                  <h2>Daily Doc Count(Same time)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_LINE_GRID_COLOR.BASE_LINE}` }}></i>
                      <span>Average(Last Week)</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Doc count</span>
                    </li>
                  </ul>
                </div>
                {isBeaconDisplay && !isBeaconChartDisplay ? (
                  <div className="loading_wrap">
                    <LoadingIndicator />
                  </div>
                ) : (
                <LineChart id={'value_chart'} data={sub_statistics_beacon_chart.value_graph.data}
                options={sub_statistics_beacon_chart.value_graph.options} width={'100%'} height={'100%'} />
                )}
              </div>
            </div>
            <div className="box-normalRange-detail">
              <div className="head">
                <div className="title">
                  <h2>Doc Count By Time</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Doc count</span>
                    </li>
                  </ul>
                </div>
                {isBeaconDisplay && !isBeaconChartDisplay ? (
                  <div className="loading_wrap">
                    <LoadingIndicator />
                  </div>
                ) : (
                <LineChart id={'changes_chart'} data={sub_statistics_beacon_chart.changes_graph.data}
                options={sub_statistics_beacon_chart.changes_graph.options} width={'100%'} height={'100%'} />
                )}
              </div>
            </div>
            <div className="box-actionDetail">
              <div className="form-container">
                <h3>Action Details (Count : {updatingIssueList.length})</h3>
                <div className="line">
                  <CauseDropDown items={causeList}
                  title={causeNameSel}
                  onChange={handleChangeCause} />
                </div>
                <div className="line">
                  <textarea value={causeTextArea} placeholder="Enter action details"
                  maxLength={maxLength} onChange={event => handleTextAreaChange(event)}
                  />
                </div>
                {causeTextArea ? causeTextArea.length : 0}/{maxLength}
                <div className="button-container align-right">
                  <PrimaryButton type={'button'} text={'Save'}
                                btnClass={'btn-primary-gray'} onClick={async e => onSubmit(e, 'Save')}/>
                  <PrimaryButton type={'button'} text={'Create Jira'}
                                btnClass={'btn-primary-accent'} onClick={async e => onSubmit(e, 'Jira')}/>
                </div>
              </div>
            </div>
          </div>
          <div className="box-existingIssues">
            <h3>&nbsp;&nbsp;Issue History</h3>
            {isBeaconReferenceDisplay ? (
              <div className="tbl-container">
                <IssTable height={'100%'} pagination={false}
                data={currentBeaconReferenceListTableData} />
              </div>
            ) : (
              <div className="tbl-container">
              <IssTable height={'100%'} pagination={false}
              data={sub_statistics_tabledata.beacon_existing_empty_issues} />
              </div>
            )}
          </div>
        </div>
      </div>
      ) : (
      <div className="loading_wrap">
        <Loading isVisible={true} />
      </div>
    )}
    {isShowCreateJiraPopup && <CreateJiraModal issueIdList={issueIdList} isOpen={isShowCreateJiraPopup} onClose={OnCloseCreateJiraPopop} jiraCallback={handleJiraCallback} type={'BEACON'} jiraStatusCallback={handleJiraStatusCallback}/>}
    {isAlert && <Alert isOpen={isAlert} onClose={onCloseAlert} title={alertTitle} message={alertMessage}/>}
    {isModalPopup && <ModalPopup isOpen={isModalPopup} title={modalPopupTitle}/>}
    </>
  );
}
StatisticsBeaconTab.propTypes = {
  searchEvent: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  username: state.currentUser.username,
});

export default connect(mapStateToProps)(StatisticsBeaconTab);
