import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Select, MenuItem, InputLabel, Dialog, DialogActions, TextField } from '@material-ui/core';
import WatchLiveTable from './WatchLiveTable';
import { getWatchLiveChannelListByCountryAndStatus, saveWatchLive, resetWatchLiveChannels } from '../../../actions/watchLiveMappingAction';
import { request } from '../../../util/request';
import { isChannelListDiff } from '../../../util/util';

function WatchLiveResult({
  resultTableRef,
  getWatchLiveChannelListByCountryAndStatus,
  setTypeData,
  selectResult,
  handleSetTypeData,
  onClickDelete,
  deletedResult,
  setDeletedResult,
  renderOrderEdit,
  countryCode,
  watchLiveResults,
  onClickAdd,
  handleSetInsertTableData,
  result,
  handleSetResult,
  renderOrder,
  renderFixFlag,
  handleResetData,
  flagReset,
  changed,
  setChanged,
  selectStatus,
  setSelectStatus,
  resetWatchLiveChannels,
  watchLiveResultsOriginal,
  recommendMonth,
  handleDateChange,
  getPreviousMonthYear,
  getNextMonthYear,
  isLastMonth
}) {
  const [onSaveOpen, setOnSaveOpen] = React.useState(false);
  const [onResetOpen, setOnResetOpen] = React.useState(false);
  const [onPublishType, setOnPublishType] = React.useState(false);
  const [onTestingType, setOnTestingType] = React.useState(false);
  const [isResetData, setIsResetData] = React.useState(false);
  const [onDeleteData, setOnDeleteData] = React.useState(false);

  React.useEffect(() => {
    if (countryCode != ' ') {
      getWatchLiveChannelListByCountryAndStatus(countryCode, selectStatus, recommendMonth);
    }
  }, [countryCode, recommendMonth]);

  useEffect(() => {
    selectStatusChange(selectStatus);
  }, [countryCode]);

  const selectStatusChange = value => {
    setSelectStatus(value);
    if (countryCode && value) {
      getWatchLiveChannelListByCountryAndStatus(countryCode, value, recommendMonth);
      setIsResetData(false);
      setChanged(false);
    }
  };
  const handleResult = result => {
    handleSetResult(JSON.parse(JSON.stringify([...result])));
  };
  useEffect(() => {
    if (watchLiveResults.length > 0) {
      handleResult(JSON.parse(JSON.stringify(watchLiveResults)));
    } else {
      handleResult([]);
    }
  }, [watchLiveResults]);

  useEffect(() => {
    let popChannelAdded = watchLiveResults.some(item => true === item.popChannelAdded);
    if (popChannelAdded) {
      setChanged(true);
    } else if (isChannelListDiff(watchLiveResultsOriginal, result)) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [watchLiveResultsOriginal, result]);

  const status = [
    { value: 'S-T', label: 'Saved - Testing' },
    { value: 'S-P', label: 'Saved - Published' },
  ];

  const clickSave = async e => {
    e.preventDefault();
    let filterFixY = result.filter(y => y.manualFlag === 'Y');
    const uniqueYValues = new Set(filterFixY.map(v => parseInt(v.resultOrder)));
    const uniqueValues = new Set(result.map(v => parseInt(v.resultOrder)));
    if (result.length < 1 && deletedResult.length > 0) {
      setOnDeleteData(true);
      setOnSaveOpen(true);
    } else {
      if (result.length < 1) {
        alert('The number of result is less than 1 ! Please add at lease one content !');
      } else if (result.find(n => n.resultOrder === '')) {
        alert('Some order is empty ! Please enter the order !');
      } else if (uniqueYValues.size < filterFixY.length) {
        alert('Some order is duplicate ! Please enter the unique order');
      } else if (result.length > 30) {
        alert('The number of channels exceeds 30.');
      } else if (uniqueValues.has(31)) {
        alert('The Order of channels exceeds 30.');
      } else {
        setOnSaveOpen(true);
      }
    }
  };
  const clickReset = async e => {
    e.preventDefault();
    if (result && result.find(n => n.resultOrder === '')) alert('Some order is empty ! Please enter the order !');
    else {
      setOnResetOpen(true);
    }
  };

  const onResetConfirm = async e => {
    handleResult([]);
    resetWatchLiveChannels(countryCode, selectStatus);
    handleResetData('Y');
    setOnResetOpen(false);
    setIsResetData(true);
    setChanged(true);
  };

  const onSaveConfirm = async e => {
    const newResult = result
      .map((n, index) => {
        const { channelId, manualFlag, logoUrl, resultOrder, channelGenreName, channelNumber, channelName, cpId, channelIdx } = n;
        let watchliveList = { countryCode: countryCode, idx: index + 1, recommendMonth: recommendMonth };
        watchliveList.channelIdx = channelIdx;
        watchliveList.selectStatus = selectStatus;
        watchliveList.resultOrder = resultOrder;
        watchliveList.manualFlag = manualFlag || 'N';
        watchliveList.channelGenreName = channelGenreName;
        watchliveList.channelName = channelName;
        watchliveList.cpId = cpId;
        watchliveList.channelNumber = channelNumber;
        watchliveList.logoUrl = logoUrl;
        watchliveList.chId = channelId;
        return watchliveList;
      })
      .sort((a, b) => a.resultOrder - b.resultOrder)
      .map((item, index) => ({ ...item }));
    const isSuccess = await request(`/api/admin/watch-live/${countryCode}/${recommendMonth}/saved-channels?saveType=${selectStatus}`, 'POST', newResult);
    if (isSuccess) {
      alert('Successfully saved !');
      setDeletedResult([]);
      setOnDeleteData(false);
      handleResult([]);
      selectStatusChange(selectStatus);
    }
    setTypeData();
    setOnSaveOpen(false);
    setIsResetData(false);
    setChanged(false);
  };
  const onClickTest = async e => {
    setOnTestingType(true);
  };
  const onClickPublish = async e => {
    setOnPublishType(true);
  };

  const onTestAndPublishConfirm = async e => {
    const newResult = result.map((n, index) => {
      const { channelId, manualFlag, logoUrl, resultOrder, channelGenreName, channelNumber, channelName, cpId, channelIdx } = n;
      let watchliveList = { countryCode: countryCode, idx: index + 1, recommendMonth: recommendMonth };
      watchliveList.channelIdx = channelIdx;
      watchliveList.selectStatus = selectStatus;
      watchliveList.resultOrder = resultOrder;
      watchliveList.manualFlag = manualFlag || 'Y';
      watchliveList.channelGenreName = channelGenreName;
      watchliveList.channelName = channelName;
      watchliveList.cpId = cpId;
      watchliveList.channelNumber = channelNumber;
      watchliveList.logoUrl = logoUrl;
      watchliveList.chId = channelId;
      return watchliveList;
    });
    const isSuccess = await request(`/api/admin/watch-live/${countryCode}/${recommendMonth}/publish?saveType=${selectStatus}`, 'POST', newResult);
    if (isSuccess) {
      alert('Published successfully!');
    }
    setOnTestingType(false);
    setOnPublishType(false);
  };
  return (
    <React.Fragment>
      <div className="arrow_btn_area">
        <Button className="btn_move piece" title="Click this to add content" onClick={onClickAdd} />
        <br />
        <Button className="btn_move remove" title="Click this to remove content" onClick={onClickDelete} />
      </div>
      <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list watchlive-right-tbl'} style={{ marginLeft: '6%' }}>
      <span>
          <div className="watch_live_select_status">
            <TextField
              variant="outlined"
              name="recommendMonth"
              id="recommendMonth"
              label="Recommend Month"
              type="month" // Use type "month" for YYYY-MM format
              placeholder="YYYY-MM"
              value={recommendMonth}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: getPreviousMonthYear(),
                max: getNextMonthYear()
              }}
            />
            {
              <Fragment>
                <Select variant="outlined" style={{ width: '30%', textAlign: 'left', marginLeft: '10px' }} value={selectStatus} onChange={e => selectStatusChange(e.target.value)}>
                  {[...status].map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </Fragment>
            }
          </div>
        </span>
        <span id="popup"></span>
        <WatchLiveTable
          isResult
          watchLiveResults={watchLiveResults}
          resultTableRef={resultTableRef}
          data={result}
          handleSetInsertTableData={handleSetInsertTableData}
          renderFixFlag={renderFixFlag}
          flagReset={flagReset}
          renderOrder={renderOrder}
          renderOrderEdit={renderOrderEdit}
          handleSetTypeData={handleSetTypeData}
          select={selectResult}
          dlagDropFlag={true}
          setChanged={setChanged}
          options={{
            search: false,
            selection: true,
            showTextRowsSelected: false,
            paging: false,
            pageSize: 30,
            doubleHorizontalScroll: true,
            maxBodyHeight: 500,
            minBodyHeight: 500,
            rowStyle: rowData => ({
              backgroundColor: rowData.change ? '#fef5f8' : rowData.manualFlag === 'Y' ? '#fff0f3' : '#FFF',
              color: rowData.change ? '#d8295c' : '#484848',
            }),
          }}
        />
        <div className="btn_set_wrap">
          <form id="addRowFrm" autoComplete="off">
            <div className="btn_set_wrap">
              {selectStatus && (selectStatus === 'S-T' || selectStatus === 'S-P') && (
                <Button className="btn_color2" onClick={clickReset} disabled={!result || isLastMonth}>
                  RESET
                </Button>
              )}
              {selectStatus && (selectStatus === 'S-T' || selectStatus === 'S-P') && (
                <Button className="btn_color2" onClick={clickSave} disabled={!changed || isLastMonth}>
                  SAVE
                </Button>
              )}
              {selectStatus && selectStatus === 'S-T' && (
                <Button className="btn_color2" onClick={onClickTest} disabled={isResetData || changed || isLastMonth}>
                  TEST
                </Button>
              )}
              {selectStatus && selectStatus === 'S-P' && (
                <Button className="btn_color2" onClick={onClickPublish} disabled={isResetData || changed || isLastMonth}>
                  PUBLISH
                </Button>
              )}
            </div>
          </form>
        </div>
        <Dialog open={onResetOpen} aria-labelledby="confirm-dialog">
          <div className="pop_size_small">
            <React.Fragment>
              <div className="ico_condition1"></div>
              {onDeleteData && <p className="pop_comment"> Do you want to save the result ? </p>}

              {!onDeleteData && <p className="pop_comment"> Do you want to reset the result ? </p>}
              <DialogActions>
                <div className="btn_set_wrap alC">
                  <Button color="primary" onClick={onResetConfirm} className="btn_color2">
                    OK
                  </Button>
                  <Button autoFocus color="primary" onClick={() => setOnResetOpen(false)} className="btn_color1">
                    Cancel
                  </Button>
                </div>
              </DialogActions>
            </React.Fragment>
          </div>
        </Dialog>
        <Dialog open={onSaveOpen} aria-labelledby="confirm-dialog">
          <div className="pop_size_small">
            <React.Fragment>
              <div className="ico_condition1"></div>
              {onDeleteData && <p className="pop_comment"> Do you want to save the result ? </p>}

              {!onDeleteData && <p className="pop_comment"> Do you want to save the result ? </p>}
              <DialogActions>
                <div className="btn_set_wrap alC">
                  <Button color="primary" onClick={onSaveConfirm} className="btn_color2">
                    OK
                  </Button>
                  <Button autoFocus color="primary" onClick={() => setOnSaveOpen(false)} className="btn_color1">
                    Cancel
                  </Button>
                </div>
              </DialogActions>
            </React.Fragment>
          </div>
        </Dialog>
      </div>
      <Dialog open={onTestingType} aria-labelledby="confirm-dialog">
        <div className="pop_size_small">
          <React.Fragment>
            <div className="ico_condition1"></div>
            <p className="pop_comment">Do you want to test the results ?</p>
            <DialogActions>
              <div className="btn_set_wrap alC">
                <Button color="primary" className="btn_color2" onClick={onTestAndPublishConfirm} onResetOpen>
                  OK
                </Button>
                <Button autoFocus color="primary" onClick={() => setOnTestingType(false)} className="btn_color1">
                  Cancel
                </Button>
              </div>
            </DialogActions>
          </React.Fragment>
        </div>
      </Dialog>
      <Dialog open={onPublishType} aria-labelledby="confirm-dialog">
        <div className="pop_size_small">
          <React.Fragment>
            <div className="ico_condition1"></div>
            <p className="pop_comment">Do you want to publish the results ?</p>
            <DialogActions>
              <div className="btn_set_wrap alC">
                <Button color="primary" className="btn_color2" onClick={onTestAndPublishConfirm}>
                  OK
                </Button>
                <Button autoFocus color="primary" onClick={() => setOnPublishType(false)} className="btn_color1">
                  Cancel
                </Button>
              </div>
            </DialogActions>
          </React.Fragment>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
const mapStateToProps = state => ({
  watchLiveResults: state.watchLive.watchLiveChannels,
  watchLiveResultsOriginal: state.watchLive.watchLiveChannelActual,
});
const mapDispatchToProps = dispatch => ({
  getWatchLiveChannelListByCountryAndStatus(countryCode, status , recommendedMonth) {
    dispatch(getWatchLiveChannelListByCountryAndStatus(countryCode, status , recommendedMonth));
  },
  saveWatchLive: (countryCode, saveData, saveType) => dispatch(saveWatchLive(countryCode, saveData, saveType)),
  resetWatchLiveChannels(countryCode, status) {
    dispatch(resetWatchLiveChannels(countryCode, status));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WatchLiveResult));
