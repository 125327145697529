import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const DEFAULT_TITLE = 'selected';
/* sample
const dropDownItem = {
  dropdownList = [
    { id: 'item Key', value: 'item vulue' },
   ......
  ]
  , checkedList = ['item Key', 'item Key', .....]
}
*/
function DropDownForSearch( props ) {
  const { dropDownItem, title, onChangeDropDown, uiClassName, scroll } = props;
  const [open, setOpen] = useState(false);
  const [checkedItemsList, setCheckedItemsList] = useState([]);
  const defineClass = uiClassName ? uiClassName : "ui-dropdown-type01";

  useEffect(() => {
    if (dropDownItem && dropDownItem.dropdownList){
      if (dropDownItem.checkedList && dropDownItem.checkedList.length > 0){
        setCheckedItemsList(dropDownItem.checkedList);
      } else {
        onCheckedAll(true);
      }
    }
  }, [dropDownItem]);

  const onCheckedAll = useCallback(
    checked => {
      if (checked) {
        setCheckedItemsList(dropDownItem.dropdownList.map(m => m.id));
      } else {
        setCheckedItemsList([]);
      }
    },
    [dropDownItem],
  );

  const onCheckedEl = useCallback(
    (checked, item) => {
      if (checked) {
        setCheckedItemsList([...checkedItemsList, item]);
      } else {
        setCheckedItemsList(checkedItemsList.filter(el => el !== item));
      }
    },
    [checkedItemsList],
  );

  useEffect(() => {
    if (onChangeDropDown){
      onChangeDropDown(checkedItemsList);
    }
  }, [checkedItemsList]);

  const onBlur = () => {
    setOpen(false);
  };

  const onClick = e => {
    setOpen(!open);
  };

  return (
    <>
      <div tabIndex={0} className={`${defineClass} ${scroll ? 'scroll' : ''}`} onBlur={onBlur}>
        <div className="label" onClick={e => onClick(e)}>
          {checkedItemsList.length} {title || DEFAULT_TITLE}
        </div>
        <ul className={open ? 'on' : ''}>
          <li>
            <label>
              <input
                type="checkbox"
                name="region2"
                onChange={e => onCheckedAll(e.target.checked)}
                checked={checkedItemsList.length === 0 ? false : checkedItemsList.length === dropDownItem.dropdownList.length ? true : false}
              />
              <span>Select All</span>
            </label>
          </li>
          {dropDownItem &&
            dropDownItem.dropdownList &&
            dropDownItem.dropdownList.map((item, index) => (
              <li key={index}>
                <label>
                  <input type="checkbox" name="region2" onChange={e => onCheckedEl(e.target.checked, item.id)} checked={checkedItemsList.includes(item.id) ? true : false} />
                  <span>{item.value}</span>
                </label>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
DropDownForSearch.propTypes = {
  title: PropTypes.string.isRequired,
  onChangeDropDown: PropTypes.func,
};
export default DropDownForSearch;
