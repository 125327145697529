import React from 'react';
import PropTypes from 'prop-types';

function DetailIconButton(props) {
  const { type, text, btnClass, category, issueId, issueCode, paramFilter, paramRegions, clickEvent, tooltip, rowData } = props;
  const onClickDetailEvent = e => {
    e.preventDefault();
    let selectedData;
    if (category === 'statistics') {
      selectedData = { category: 'statistics', issueId: issueId, issueCode: issueCode, paramFilter: paramFilter, paramRegions: paramRegions };
    } else if (category === 'beacon') {
      selectedData = { category: 'beacon', issueId: issueId, issueCode: issueCode, paramFilter: paramFilter, paramRegions: paramRegions };
    } else {
      selectedData = { category: category, issueId: issueId, paramFilter: paramFilter, rowData: rowData};
    }
    clickEvent(selectedData);
  };
  return (
      <>
        {type === 'a' && (
            <a href="#" className={btnClass}>
              <i></i>
              <span>{text}</span>
            </a>
        )}
        {type === 'button' && (
            <button className={btnClass} onClick={onClickDetailEvent}>
              <i></i>
              <span>{text}</span>
            </button>
        )}
        {tooltip && (
            <div className="title">
              <div className="ui-tooltip">
                <i>툴팁</i>
                <div className="tooltip">{tooltip}</div>
              </div>
            </div>
        )}
      </>
  );
}
DetailIconButton.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  btnClass: PropTypes.string.isRequired,
  paramFilter: PropTypes.array,
  paramRegions: PropTypes.array,
  clickEvent: PropTypes.func.isRequired,
};

export default DetailIconButton;
