import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WatchLiveSearch from './WatchLiveSearch';
import WatchLiveResult from './WatchLiveResult';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import { isNormalInteger } from '../../../util/util';

export function CountryFilter({ cntryList, cntry, setCntry }) {
  return (
    <div className="filter_area row manualCat">
      <div className="filter_row manualCat">
        <FormControl variant="outlined">
          <InputLabel>Country</InputLabel>
          <Select
            value={cntry}
            onChange={e => {
              setCntry(e.target.value);
            }}>
            {cntryList.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

function WatchLive({ param, allCountries, watchLiveChannelsResult, failmessage }) {
  const selectedTableRef = useRef();
  const selectedResultTableRef = useRef();
  const [cntryList, setCntryList] = React.useState([' ']);
  const [cntry, setCntry] = React.useState('US');
  const [result, setResult] = React.useState([]);
  const [insertTableData, setInsertTableData] = React.useState([]);
  const [typeData, setTypeData] = React.useState(' ');
  const [flagReset, setFlagReset] = React.useState('N');
  const [isItEdit, setIsItEdit] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState([]);
  const [deletedResult, setDeletedResult] = React.useState([]);
  const [changed, setChanged] = React.useState(false);
  const [selectStatus, setSelectStatus] = React.useState('S-T');
  const [isLastMonth, setIsLastMonth] = React.useState(false);

  const NumericOnly = e => {
    const reg = /^[0-9\b]+$/;
    let preval = e.target.value;
    if (preval > 30) {
      e.target.value = 30;
    }
    if (e.target.value === '' || reg.test(e.target.value)) return true;
    else e.target.value = preval.substring(0, preval.length - 1);
  };

  const renderOrderEdit = n => {
    const getval = () => {
      return isItEdit ? n.resultOrder : undefined;
    };
    return (
      <TextField
        key={`ct-order-${n.channelId}`}
        id={`ct-order-${n.channelId}`}
        fullWidth
        margin="dense"
        onFocus={() => setIsItEdit(false)}
        value={getval()}
        defaultValue={n.resultOrder}
        onChange={NumericOnly}
        onBlur={e => {
          setChanged(true);
          if (e.target.value === '0') alert('The order must be greater than 0 ! Please re-enter !');
          else if (!isNormalInteger(e.target.value) && e.target.value !== '') alert('The order is not natural number ! Please enter natural number !');
          else {
            if (typeData === 'Insert') {
              const newResult = selectedResultTableRef.current.dataManager.sortedData.slice();
              newResult.find(n2 => n2.channelId === n.channelId && n2.cpId === n.cpId).resultOrder = e.target.value;
              setResult(newResult);
            } else {
              const newResult = selectedResultTableRef.current.dataManager.sortedData.slice();
              newResult.find(n2 => n2.channelId === n.channelId && n2.cpId === n.cpId).resultOrder = e.target.value;
              setResult(newResult);
            }
          }
        }}
      />
    );
  };

  React.useEffect(() => {
    setCntryList([...allCountries].sort());
    setResult(watchLiveChannelsResult);
  }, [allCountries]);

  const addResult = () => {
    let startOrder = Math.max(...result.map(n => n.resultOrder)) + 1;
    startOrder = startOrder === -Infinity ? 1 : startOrder;
    let dataIsChecked = selectedTableRef.current.dataManager.data;
    dataIsChecked = dataIsChecked.filter(o => o.tableData.checked);
    dataIsChecked = dataIsChecked.map((n, i) => {
      const { cpId, channelId, channelIdx, channelName, manualFlag, logoUrl, channelGenreName } = n;
      return { cpId: cpId, channelId: channelId, channelIdx: channelIdx, channelName: channelName, manualFlag: manualFlag, logoUrl: logoUrl, channelGenreName: channelGenreName, resultOrder: startOrder + i };
    });
    result.forEach(addedResult => {
      let dataIsCheckedFound = dataIsChecked.find(rk => rk.channelId === addedResult.channelId && rk.cpId === addedResult.cpId);
      if (dataIsCheckedFound) {
        dataIsChecked = dataIsChecked.filter(item => item !== dataIsCheckedFound);
      }
    });

    if (dataIsChecked.find(n => result.find(n2 => n2.channelId === n.channelId && n2.cpId == n.cpId))) {
      alert('content is already exist !');
    } else if (dataIsChecked.length + result.length > 30) {
      alert('More than 30 channels can not be added');
    } else if (dataIsChecked.length > 0) {
      selectedTableRef.current.onAllSelected(false);
      result.sort((a, b) => a.resultOrder - b.resultOrder);
      setResult(result.concat(dataIsChecked));
      setInsertTableData(insertTableData.concat(dataIsChecked));
      setChanged(true);
    } else {
      alert('Please select the channel to add');
    }
  };

  const selectSearch = rows => {
    let dataIsChecked = selectedTableRef.current.dataManager.data.filter(o => o.tableData.disabled);
  };

  const selectResult = rows => {
    setDeleteData(
      rows.map(n => {
        const { channelId, manualFlag, cpId, type } = n;
        return { channelId: channelId, manualFlag: manualFlag, cpId: cpId, type: type };
      }),
    );
  };
  const handleSetInsertTableData = data => {
    setInsertTableData([...data]);
    setResult([...data]);
  };

  const handleSetResult = data => {
    setInsertTableData([...data]);
    setResult([...data]);
  };

  const handleSetTypeData = data => {
    setTypeData(data);
  };
  const handleResetData = data => {
    setFlagReset(data);
  };

  const renderOrder = n => (
    <TextField
      key={`ct-order-${n.channelId}`}
      id={`ct-order-${n.channelId}`}
      fullWidth
      margin="dense"
      value={n.resultOrder}
      onChange={e => {
        setChanged(true);
        if (e.target.value === '0') alert('The order must be greater than 0 ! Please re-enter !');
        else if (!isNormalInteger(e.target.value) && e.target.value !== '') alert('The order is not natural number ! Please enter natural number !');
        else {
          if (typeData === 'Insert') {
            const newResult = result.slice();
            newResult.sort((a, b) => a.resultOrder - b.resultOrder);
            newResult.find(n2 => n2.channelId === n.channelId && n2.cpId === n.cpId).resultOrder = e.target.value;
            setResult(newResult);
          } else {
            const newResult = result.slice();
            newResult.sort((a, b) => a.resultOrder - b.resultOrder);
            newResult.find(n2 => n2.channelId === n.channelId && n2.cpId === n.cpId).resultOrder = e.target.value;
            setResult(newResult);
          }
        }
      }}
    />
  );

  const flagList = [
    { value: 'Y', label: 'Y' },
    { value: 'N', label: 'N' },
  ];
  React.useEffect(() => {
    setFlagReset('N');
  }, [result]);

  const renderFixFlag = rowData => {
    const defaultFixFlag = flagReset === 'Y' ? 'N' : rowData.manualFlag ? rowData.manualFlag : 'N';
    return (
      <div>
        {isItEdit && (
          <Select name="fix" onFocus={() => setIsItEdit(false)} value={defaultFixFlag} onBlur={e => onFixFlagCode(e, rowData)}>
            {flagList.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {`${item.value}`}
              </MenuItem>
            ))}
          </Select>
        )}
        {!isItEdit && (
          <Select name="fix" onFocus={() => setIsItEdit(false)} defaultValue={defaultFixFlag} onBlur={e => onFixFlagCode(e, rowData)}>
            {flagList.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {`${item.value}`}
              </MenuItem>
            ))}
          </Select>
        )}
      </div>
    );
  };

  const onFixFlagCode = (e, rowData) => {
    const newResult = selectedResultTableRef.current.dataManager.sortedData.slice();
    newResult.find(n2 => n2.channelId === rowData.channelId && n2.cpId === rowData.cpId).manualFlag = e.target.value;
    setResult(newResult);
    setChanged(true);
    setFlagReset('N');
  };
  const removeResult = () => {
    setIsItEdit(true);
    setChanged(true);
    if (deleteData.length < 1) {
      alert('Please select the content to remove');
    } else {
      if (typeData === 'Insert') {
        setDeletedResult(insertTableData);
        setResult(insertTableData.filter(n => !deleteData.find(n2 => n2.channelId === n.channelId && n2.cpId === n.cpId)));
        setInsertTableData(insertTableData.filter(n => !deleteData.find(n2 => n2.channelId === n.channelId && n2.cpId === n.cpId)));
      } else {
        setDeletedResult(result);
        setResult(result.filter(n => !deleteData.find(n2 => n2.channelId === n.channelId && n2.cpId === n.cpId)));
      }
      setDeleteData([]);
    }
  };
  const handlePageChange = () => {
    selectedTableRef.current.onChangePage({}, 0);
  };
  const getCurrentMonthYear = () => {
    const currentDate = new Date();
    const year = currentDate.getUTCFullYear();
    let month = currentDate.getUTCMonth() + 1;
    if (month < 10) {
      month = '0' + month; // Prefix single-digit months with 0
    }
    return `${year}-${month}`;
  };
  const getPreviousMonthYear = () => {
    const currentDate = new Date();
    let year = currentDate.getUTCFullYear();
    let month = currentDate.getUTCMonth() + 1;

    // Calculate the starting month for the previous 9 months
    let startMonth = month - 1;
    if (startMonth <= 0) {
      // If start month is less than or equal to 0, adjust year and month accordingly
      year -= 1;
      startMonth += 12;
    }
    if (startMonth < 12) {
      startMonth = '0' + startMonth; // Prefix single-digit months with 0
    }
    return `${year}-${startMonth}`;
  };


  const getNextMonthYear = () => {
    const currentDate = new Date();
    currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
    const year = currentDate.getUTCFullYear();
    let month = currentDate.getUTCMonth() + 1;
    if (month < 10) {
      month = '0' + month; // Prefix single-digit months with 0
    }
    return `${year}-${month}`;
  };

  const [recommendMonth, setRecommendMonth] = React.useState(getCurrentMonthYear());
  const handleDateChange = (data) => {
    const selectedYearMonth = parseInt(data.target.value.replace('-', ''));
    setRecommendMonth(data.target.value);
    const currentDate = new Date();
    const currentYearMonth = parseInt(currentDate.getUTCFullYear() + (currentDate.getUTCMonth() > 9 ? currentDate.getUTCMonth() : '0' + currentDate.getUTCMonth()));
    setIsLastMonth(selectedYearMonth <= currentYearMonth);
  }
  return (
    <div>
      <React.Fragment>
        <CountryFilter cntryList={cntryList} cntry={cntry} setCntry={setCntry} />
        <div className="channel_mapping_wrap">
          <WatchLiveSearch cntry={cntry} tableRef={selectedTableRef} region={param.region} selectSearch={selectSearch} configData={result} selectStatus={selectStatus} handlePageChange={handlePageChange} isLastMonth={isLastMonth} />
          <WatchLiveResult
            countryCode={cntry}
            resultTableRef={selectedResultTableRef}
            onClickAdd={addResult}
            handleSetInsertTableData={handleSetInsertTableData}
            result={result}
            insertTableData={insertTableData}
            handleSetResult={handleSetResult}
            renderFixFlag={renderFixFlag}
            renderOrder={renderOrder}
            renderOrderEdit={renderOrderEdit}
            typeData={typeData}
            setTypeData={setTypeData}
            handleSetTypeData={handleSetTypeData}
            handleResetData={handleResetData}
            flagReset={flagReset}
            deletedResult={deletedResult}
            setDeletedResult={setDeletedResult}
            onClickDelete={removeResult}
            selectResult={selectResult}
            changed={changed}
            setChanged={setChanged}
            selectStatus={selectStatus}
            setSelectStatus={setSelectStatus}
            recommendMonth={recommendMonth}
            handleDateChange={handleDateChange}
            getPreviousMonthYear={getPreviousMonthYear}
            getNextMonthYear={getNextMonthYear}
            isLastMonth={isLastMonth}
          />
        </div>
      </React.Fragment>
    </div>
  );
}
WatchLive.propTypes = {};
const mapStateToProps = state => ({
  param: state.homeApp.param,
  allCountries: state.cntry.allCountries,
  watchLiveChannelsResult: state.watchLive.watchLiveChannels,
  failmessage: state.channelMapping.failmessage,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WatchLive));
