import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'; // withRouter

import PropTypes from 'prop-types';
import IssueTab from './IssueTab';
import moment from 'moment';

import { ISS_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import Alert from '../modal/Alert';

function SideBar({ currentTab, changeTab, currentFilter, changeFilter }) {
  const tabMenus = [{ name: 'Service' }, { name: 'Content' }, { name: 'Statistics' }, { name: 'Advertisement'}];

  const [searchData, setSearchData] = useState('');
  const [issueCount, setIssueCount] = useState('0');
  const [issueTime, setIssueTime] = useState(moment().utc().format('YYYY.MM.DD HH:mm:ss'));
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');

  const history = useHistory();

  useEffect(() => {
    changeFilter({country:'', provider:'', platform:''});
    getIssueCount();
  }, []);

  useEffect(() => {
    changeFilter({country:'', provider:'', platform:''});
  }, [currentTab]);

  const onSubmit = e => {
    e.preventDefault();
    getIssue();
  };

  const moveToDetailPage = (item) => {
    if (item.issue_state === 'Resolve') {
      if (item.issue_type === 'content' && item.issue_id.startsWith('CN0')) {
        setAlertMessage('Group id [' + item.issue_id + '] has already been resolved.');
        onOpenAlert();
      } else {
        setAlertMessage('Issue code [' + item.issue_code + '] has already been resolved.');
        onOpenAlert();
      }
    } else {
      if (item.issue_type === 'content') {
        const category = item.issue_id.startsWith('CN0') ? 'cp' : item.issue_id.startsWith('CN1') ? 'admin' : item.issue_id.startsWith('CN2') ? 'batch' : 'device';

        let searchData;
        if (category === 'cp') {
          searchData = { category: category, groupId: item.issue_id };
        } else {
          searchData = { category: category, issueId: item.issue_id, issueCode: item.issue_code };
        }

        history.push('/ISS/ContentsDetail', {
          param: JSON.stringify(searchData),
        });
      } else if (item.issue_type === 'service') {
        let selectedData = { issueTab: item.issue_tab, issueId: item.issue_id, issueCode: item.issue_code };
        history.push('/ISS/ServiceDetail', {
          param: JSON.stringify(selectedData),
        });
      } else if (item.issue_type === 'statistics' || item.issue_type === 'beacon') {
        const searchData = { category: item.issue_type, issueId: item.issue_id, issueCode: item.issue_code };

        history.push('/ISS/StatisticsDetail', {
          param: JSON.stringify(searchData),
        });
      }
    }
  };

  const getIssue = () => {
    if (!searchData){
      return;
    }

    fetch(`${ISS_BASE_URL}/api/common/issue/search/${searchData.trim()}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        if (body.dataSet){
          moveToDetailPage(body.dataSet);
        } else {
          setAlertMessage('Issue not found with this ID');
          onOpenAlert();
        }
      } else {
        setAlertMessage('[getIssue server error]');
        onOpenAlert();
      }
    })
    .catch(error => {
      setAlertMessage(`getIssue : ${error.toString()}`);
      onOpenAlert();
    }).finally(() => {});
  };
  const getIssueCount = () => {
    fetch(`${ISS_BASE_URL}/api/common/issue/count`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        let value = body.dataSet.issueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        setIssueTime(moment().utc().format('YYYY.MM.DD HH:mm:ss'));
        setIssueCount(value);
      } else {
        setAlertMessage('[getIssueCount server error]');
        onOpenAlert();
      }
    })
    .catch(error => {
      setAlertMessage(`getIssueCount : ${error.toString()}`);
      onOpenAlert();
    }).finally(() => {});
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
      <div className="sidebar-section">
        <div className="head-title">
          <h1>
            LG Channels
            <br />
            Issue Sensing System
          </h1>
          <button className="btn-refresh" onClick={() => window.location.reload()}/>
        </div>
        <div className="box-total-issue">
          <h2>Total Issues</h2>
          <span className="time">{issueTime} (UTC) </span>
          <strong>{issueCount}</strong>
        </div>
        <div className="box-summary-tabs">
          <div className="ui-tab-type01">
            <div className="ui-tab-menu">
              {tabMenus.map((el, index) => (
                <a key={index} className={index === currentTab ? 'on' : ''} onClick={() => changeTab(index)}>
                  {el.name}
                </a>
              ))}
            </div>
            <div className="ui-tab-contents">
              <div className="tab-box">{<IssueTab currentTab={currentTab} currentFilter={currentFilter}/>}</div>
            </div>
          </div>
        </div>
        <div className="box-search">
          <div className="box-search-title">Search by Issue Id</div>
          <div className="field-input-search">
            <form id="issue_search" action="#" onSubmit={onSubmit}>
              <i></i>
              <input type="text" placeholder="Search" onChange={event => setSearchData(event.target.value)} />
            </form>
          </div>
        </div>
        <footer>
          <p>
            Copyright © LG Electronics.
            <br />
            All Rights Reserved.
          </p>
        </footer>
      </div>
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

SideBar.propTypes = {
  currentTab: PropTypes.number.isRequired,
  changeTab: PropTypes.func.isRequired,
};

export default SideBar;
