//import * as actions from '../../actions/actionTypes';

const initialState = 'PROGRAM';

export default function headerMenu(state = initialState, action) {
  switch (action.type) {
    case 'SET_HEADER_MENU':
      return action.payload;
    default:
      return state;
  }
}
