import { SET_SD0201_SUCCESS, SET_SD0201_FAILURE, SET_2ND_DEPTHBYREGION_SUCCESS, SET_2ND_DEPTHBYREGION_FAILURE } from './actionTypes'; // RSS Feeds 조회
import { SET_SD0101_SUCCESS, SET_SD0101_FAILURE } from './actionTypes'; // Feed Mapp 조회
import { SET_SD0103_SUCCESS, SET_SD0103_FAILURE } from './actionTypes'; // Feed Mapp Setting History Combo 조회
import { SET_SD0104_SUCCESS, SET_SD0104_FAILURE } from './actionTypes'; // Feed Mapp Setting History 조회
import { SET_SD0105_SUCCESS, SET_SD0105_FAILURE } from './actionTypes'; // Feed Mapp Setting 저장
import { SET_SD0106_SUCCESS, SET_SD0106_FAILURE } from './actionTypes'; // Feed Mapp Sdk 저장/수정
import { SET_SD0107_SUCCESS, SET_SD0107_FAILURE } from './actionTypes'; // Feed Mapp Sdk 목록 조회 (하단 sdk version 리스트 )
import { SET_SD0108_SUCCESS, SET_SD0108_FAILURE } from './actionTypes'; // Preview 목록조회
import { SET_SD0109_SUCCESS, SET_SD0109_FAILURE } from './actionTypes'; // Feed Mapp Sdk 삭제
import { SET_RULES_STORE } from './actionTypes'; // rules store
import { SET_SDK_VERSION_SETTING } from './actionTypes'; // rules store
import { DISPLAY_RULES_LIST_SIZE } from '../constants';
import { API_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';
const fetchSd0201Success = body => ({
  type: SET_SD0201_SUCCESS,
  payload: body,
});
const fetchSd0201Failure = ex => ({
  type: SET_SD0201_FAILURE,
  payload: ex,
});
const fetchSd0101Success = body => ({
  type: SET_SD0101_SUCCESS,
  payload: body,
});
const fetchSd0101Failure = ex => ({
  type: SET_SD0101_FAILURE,
  payload: ex,
});
const fetchRulesStore = ex => ({
  type: SET_RULES_STORE,
  payload: ex,
});
const fetchSd0103Success = body => ({
  type: SET_SD0103_SUCCESS,
  payload: body,
});
const fetchSd0103Failure = ex => ({
  type: SET_SD0103_FAILURE,
  payload: ex,
});
const fetchSd0104Success = body => ({
  type: SET_SD0104_SUCCESS,
  payload: body,
});
const fetchSd0104Failure = ex => ({
  type: SET_SD0104_FAILURE,
  payload: ex,
});
const fetchSd0105Success = body => ({
  type: SET_SD0105_SUCCESS,
  payload: body,
});
const fetchSd0105Failure = ex => ({
  type: SET_SD0105_FAILURE,
  payload: ex,
});
const fetchSd0106Success = body => ({
  type: SET_SD0106_SUCCESS,
  payload: body,
});
const fetchSd0106Failure = ex => ({
  type: SET_SD0106_FAILURE,
  payload: ex,
});
const fetchSd0107Success = body => ({
  type: SET_SD0107_SUCCESS,
  payload: body,
});

const fetchSd0107Failure = ex => ({
  type: SET_SD0107_FAILURE,
  payload: ex,
});
const fetch2ndDepthByRegionSuccess = body => ({
  type: SET_2ND_DEPTHBYREGION_SUCCESS,
  payload: body,
});
const fetch2ndDepthByRegionFailure = body => ({
  type: SET_2ND_DEPTHBYREGION_FAILURE,
  payload: body,
});
const fetchSd0108Success = body => ({
  type: SET_SD0108_SUCCESS,
  payload: body,
});
const fetchSd0108Failure = ex => ({
  type: SET_SD0108_FAILURE,
  payload: ex,
});
const fetchSd0109Success = body => ({
  type: SET_SD0109_SUCCESS,
  payload: body,
});
const fetchSd0109Failure = ex => ({
  type: SET_SD0109_FAILURE,
  payload: ex,
});
const fetchSdkVersionSetting = ex => ({
  type: SET_SDK_VERSION_SETTING,
  payload: ex,
});
// RSS Feeds 조회
export const getSd0201Action = (isRoleAdmin, cntryCode) => dispatch => {
  return fetch(API_URL + '/api/sdepth/rssfeed/' + cntryCode + 'country/', {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(fetchSd0201Success(body));
    })
    .catch(ex => {
      dispatch(fetchSd0201Failure(ex));
    });
};
// Feed Mapp 조회
export const getSd0101Action = (isRoleAdmin, feedMapId) => dispatch => {
  return fetch(API_URL + '/api/sdepth/feedmap/' + feedMapId, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(fetchSd0101Success(body));
    })
    .catch(ex => {
      dispatch(fetchSd0101Failure(ex));
    });
};
// Rules에 사용하기 위한 저장 공간
export const getFirstRulesStoreAction = (isRoleAdmin, cntryCode) => dispatch => {
  let obj = {
    itemsPerCycle: 0,
    list: [],
  };
  return fetch(API_URL + '/api/sdepth/' + cntryCode + '/rssfeeds', {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body) {
        if (body.length >= 1) {
          for (let idx01 in body) obj.list.push(assign0201Rues(idx01, body[idx01]));
        } else {
          obj.list.push(assign0201Rues(0, {}));
        }
      } else {
        obj.list.push(assign0201Rues(0, {}));
      }
      let itemsPerCycle = 0;
      for (let idx01 in obj.list) obj.list[idx01].key = idx01;
      obj.itemsPerCycle = itemsPerCycle;
      return dispatch(fetchRulesStore(obj));
    })
    .catch(ex => {
      return dispatch(fetchRulesStore(obj));
    });
};
function assign0201Rues(idx, obj0201) {
  let obj = {
    borderViewOrder: '',
    boardCheck: false,
    boardCountry: obj0201['cntryCode'] ? obj0201['cntryCode'] : '',
    boardCp: obj0201['cpName'] ? obj0201['cpName'] : '',
    boardItemsForCpPerCycle: '',
    boardOrder: idx * 1 + 1, // 문자 숫자로 처음 넣는다.
    boardState: obj0201['state'] ? obj0201['state'] : '',
    boardUpDown: '',
    saveCntryCode: obj0201['cntryCode'] ? obj0201['cntryCode'] : '',
    saveCpName: obj0201['cpName'] ? obj0201['cpName'] : '',
    saveFeedIdx: '',
    saveItmCntPerCycl: '0',
    saveOrderNo: '',
    savePlatforms: '',
    sd0101CntryCode: '',
    sd0101CpName: '',
    sd0101FeedIdx: '',
    sd0101FeedMapId: '',
    sd0101Idx: '',
    sd0101ItmCntPerCycl: '',
    sd0101OrderNo: '',
    sd0201CntryCode: obj0201['cntryCode'] ? obj0201['cntryCode'] : '',
    sd0201CpName: obj0201['cpName'] ? obj0201['cpName'] : '',
    sd0201FeedIdx: obj0201['feedIdx'] ? obj0201['feedIdx'] : '',
    sd0201LastChgDate: obj0201['lastChgDate'] ? obj0201['lastChgDate'] : '',
    sd0201NextCollectTime: obj0201['nextCollectTime'] ? obj0201['nextCollectTime'] : '',
    sd0201RssPath: obj0201['rssPath'] ? obj0201['rssPath'] : '',
    sd0201State: obj0201['state'] ? obj0201['state'] : '',
  };
  return obj;
}
// Rules에 사용하기 위한 저장 공간
export const getRulesStoreAction = (isRoleAdmin, cntryCode, feedMapId) => dispatch => {
  let obj = {
    itemsPerCycle: 0,
    list: [],
  };
  let itemsPerCycle = 0;

  return fetch(API_URL + '/api/sdepth/' + cntryCode + '/rssfeeds', {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body1 => {
      if (body1) {
        if (body1.length >= 1) {
          for (let idx01 in body1) obj.list.push(assign0201Rues(idx01, body1[idx01]));
        } else {
          obj.list.push(assign0201Rues({}));
        }
      } else {
        obj.list.push(assign0201Rues({}));
      }
      if (obj.list.length >= 1) {
        if (obj.list[0].cpName !== '') {
          fetch(API_URL + '/api/sdepth/feedmap/' + feedMapId, {
            method: 'GET',
            headers: getHeaders(),
          })
            .then(res => res.json())
            .then(body2 => {
              if (body2) {
                if (body2[0]) {
                  if (body2[0].feedMapSetList) {
                    if (body2[0].feedMapSetList.length >= 1) {
                      // 국가로 찾은 Rules 컬럼과 Platform과 비교해서 같은 열은 값을 할당한다.
                      for (let idx01 in obj.list) {
                        let oneCountry = obj.list[idx01];
                        for (let idx02 in body2[0].feedMapSetList) {
                          let onePlatform = body2[0].feedMapSetList[idx02];
                          if (oneCountry.sd0201FeedIdx * 1 === onePlatform.feedIdx * 1) {
                            oneCountry.borderViewOrder = idx02; // 화면에 보여주는 순서
                            oneCountry.boardCheck = true; // true Platform에 값이 있을 경우 자동으로 부여한다.
                            oneCountry.boardItemsForCpPerCycle = onePlatform.itmCntPerCycl;
                            oneCountry.sd0101CntryCode = onePlatform.cntryCode;
                            oneCountry.sd0101CpName = onePlatform.cpName;
                            oneCountry.sd0101FeedIdx = onePlatform.feedIdx;
                            oneCountry.sd0101FeedMapId = onePlatform.feedMapId;
                            oneCountry.sd0101Idx = onePlatform.idx;
                            oneCountry.sd0101ItmCntPerCycl = onePlatform.itmCntPerCycl;
                            oneCountry.sd0101OrderNo = onePlatform.orderNo;
                            itemsPerCycle = itemsPerCycle * 1 + onePlatform.itmCntPerCycl * 1;
                          }
                        }
                      }
                    }
                  }
                }
              }
              // key 할당
              for (let idx01 in obj.list) obj.list[idx01].key = idx01;
              let orderList = [];
              // 번호 있는 값은 순서대로 넣는다.
              for (let i = 0; i < 1000; i++) {
                for (let idx01 in obj.list) {
                  if (obj.list[idx01]['borderViewOrder'] !== '') {
                    if (obj.list[idx01]['borderViewOrder'] * 1 === i) {
                      orderList.push(JSON.parse(JSON.stringify(obj.list[idx01])));
                    }
                  }
                }
              }
              // 번호 없는 값을 넣는다.
              for (let idx01 in obj.list) {
                if (obj.list[idx01]['borderViewOrder'] === '') orderList.push(JSON.parse(JSON.stringify(obj.list[idx01])));
              }
              //번호 다시 부여
              for (let idx01 in orderList) orderList[idx01]['boardOrder'] = idx01 * 1 + 1;
              obj.list = orderList;
              obj.itemsPerCycle = itemsPerCycle;
              return dispatch(fetchRulesStore(obj));
            })
            .catch(ex => {
              return dispatch(fetchRulesStore(obj));
            });
        }
      }
    })
    .catch(ex => {
      return dispatch(fetchRulesStore(obj));
    });
};
// Feed Mapp Setting History Combo 조회
export const getFeedmapsetLogsNamesAction = (isRoleAdmin, feedMapId, page, size) => dispatch => {
  page = page || 0;
  size = size || DISPLAY_RULES_LIST_SIZE;
  let data = {
    page: page * 1,
    size: size * 1,
  };

  return fetch(API_URL + '/api/sdepth/feedmapset/' + feedMapId + '/logs-names?' + new URLSearchParams(data), {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(fetchSd0103Success(body));
    })
    .catch(ex => {
      dispatch(fetchSd0103Failure(ex));
    });
};
// Feed Mapp Setting History 조회
export const getFeedmapsetLogsAction = (isRoleAdmin, feedMapId, mapVer) => dispatch => {
  return fetch(API_URL + '/api/sdepth/feedmapset/' + feedMapId + '/' + mapVer * 1 + '/logs', {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(fetchSd0104Success(body));
    })
    .catch(ex => {
      dispatch(fetchSd0104Failure(ex));
    });
};
export const getFeedmapsetAction = (isRoleAdmin, jsonStringifyParams) => dispatch => {
  return fetch(API_URL + '/api/sdepth/feedmapset', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(JSON.parse(jsonStringifyParams)),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(fetchSd0105Success(body));
    })
    .catch(ex => {
      dispatch(fetchSd0105Failure(ex));
    });
};
export const getFeedmapSdkAction = (isRoleAdmin, idx, feedMapId, sdkVer, svcFeedIdx, region, platformVersion, tabCodeSave, categoryCode) => dispatch => {
  return fetch(API_URL + '/api/sdepth/feedmap/sdk', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      idx: idx,
      feedMapId: feedMapId,
      sdkVer: sdkVer,
      svcFeedIdx: svcFeedIdx,
      region: region,
      platformVersion: platformVersion,
      tabCode: tabCodeSave,
      categoryCode: categoryCode,
    }),
  })
    .then(res => res.json())
    .then(body => {
      if (body.Result === 'FAILED') {
        dispatch(fetchSd0106Success(body));
      } else {
        fetch(API_URL + '/api/sdepth/feedmap/sdks/' + feedMapId, {
          method: 'GET',
          headers: getHeaders(),
        })
          .then(res2 => res2.json())
          .then(body2 => {
            dispatch(fetchSd0107Success(body2));
          })
          .catch(ex2 => {
            dispatch(fetchSd0107Failure(ex2));
          });
      }
    })
    .catch(ex => {
      dispatch(fetchSd0106Failure(ex));
    });
};
// Feed Mapp Sdk 목록 조회 (하단 sdk version 리스트 )
export const getFeedmapSdksOneAction = (isRoleAdmin, feedMapId) => dispatch => {
  return fetch(API_URL + '/api/sdepth/feedmap/sdks/' + feedMapId, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      // feedMappSdk의 svcFeedIdx 재정렬
      body.feedMappSdk.forEach(v2 => {
        //svcFeedIdx: "40,17,1"
        let arrSvcFeedIdx = v2['svcFeedIdx'].split(',');
        let newSvcFeedIdx = '';
        body.feedMappSet.forEach(v => {
          let feedIdx = v['feedIdx'];
          for (let idx01 in arrSvcFeedIdx) {
            let oneSvcFeedIdx = arrSvcFeedIdx[idx01];
            // feedMappSet 기준으로 재정렬 한다.
            if (feedIdx * 1 === oneSvcFeedIdx * 1) newSvcFeedIdx = newSvcFeedIdx + ',' + oneSvcFeedIdx;
          }
        });
        // 앞의 , 제거
        if (newSvcFeedIdx.length >= 1) newSvcFeedIdx = newSvcFeedIdx.substr(1, newSvcFeedIdx.length);
        v2['svcFeedIdx'] = newSvcFeedIdx;
      });
      // XUMO(2) 형식 만들기
      for (let idx01 in body.feedMappSdk) {
        // 1. 시작svcFeedIdx
        let oneSvcFeedIdx = body.feedMappSdk[idx01].svcFeedIdx;
        if (oneSvcFeedIdx) {
          let poolCpItem = '';
          // 2. 시작
          if (oneSvcFeedIdx !== '') {
            // 3. 시작
            let arrSvcFeedIdx = oneSvcFeedIdx.split(',');
            for (let idx02 in arrSvcFeedIdx) {
              // 4. 시작
              let oneSvcFeedIdx = arrSvcFeedIdx[idx02];
              if (body.feedMappSdk) {
                // 5. 시작
                if (body.feedMappSdk.length >= 1) {
                  for (let idx03 in body.feedMappSet) {
                    // 1. 시작
                    if (oneSvcFeedIdx * 1 === body.feedMappSet[idx03].feedIdx * 1) {
                      let tempCpName = body.feedMappSet[idx03]['cpName'];
                      let tempitmCntPerCycl = body.feedMappSet[idx03]['itmCntPerCycl'];
                      // 예 : XUMO(3),
                      let cpItem = tempCpName + '(' + tempitmCntPerCycl + ')';
                      poolCpItem = poolCpItem + cpItem + ',';
                    }
                    // 7. 끝
                  }
                  // 6. 끝
                }
                // 5. 끝
              }
              // 4. 끝
            }
            // 3. 끝
          }
          // 2. 끝
          if (poolCpItem) {
            if (poolCpItem.length >= 2) {
              // XUMO(3) 배열에 넣는다.
              body.feedMappSdk[idx01].cpItem = poolCpItem.substr(0, poolCpItem.length - 1);
            } else {
              body.feedMappSdk[idx01].cpItem = '';
            }
          }
        }
        // 1. 끝
      }
      // 값이 없을 때 clear
      if (!body) {
        body['feedMappSdk'] = [];
        body['feedMappSet'] = [];
      }
      dispatch(fetchSd0107Success(body));
    })
    .catch(ex => {
      dispatch(fetchSd0107Failure(ex));
    });
};

export const getFeedmapSdksByRegion = region => dispatch => {
  return fetch(API_URL + '/api/sdepth/feedmap/sdksregion/' + region, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(fetch2ndDepthByRegionSuccess(body));
    })
    .catch(ex => {
      dispatch(fetch2ndDepthByRegionFailure(ex));
    });
};

export const getFeedmapSdksCategoryOneAction = (isRoleAdmin, feedMapId) => dispatch => {
  return fetch(API_URL + '/api/sdepth/feedmap/sdks-categories/' + feedMapId, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(fetchSd0107Success(body));
    })
    .catch(ex => {
      dispatch(fetchSd0107Failure(ex));
    });
};
// Preview 목록조회
export const getFeedmapFeedMapIdChannelsAction = (isRoleAdmin, feedMapId, sdkVer) => dispatch => {
  return fetch(API_URL + '/api/sdepth/feedmap/' + feedMapId + '/sdkVer/' + sdkVer + '/channels', {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      // order 순번 입력
      for (let idx01 in body.list) body.list[idx01]['order'] = idx01 * 1 + 1;
      // 문제가 있으면 빈값으로 화면 clear
      if (!body) body.list = [];
      // 문제가 있으면 빈값으로 화면 clear
      if (!body.list) body.list = [];
      dispatch(fetchSd0108Success(body));
    })
    .catch(ex => {
      dispatch(fetchSd0108Failure(ex));
    });
};
// Feed Mapp Sdk 목록 조회 (하단 sdk version 리스트 )
export const getFeedmapSdkIdxAction = (isRoleAdmin, idx, feedMapId) => dispatch => {
  return fetch(API_URL + '/api/sdepth/feedmap/sdk/' + idx, {
    method: 'DELETE',
    headers: getHeaders(),
    body: JSON.stringify({}),
  })
    .then(res => res.json())
    .then(body => {
      fetch(API_URL + '/api/sdepth/feedmap/sdks/' + feedMapId, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then(res2 => res2.json())
        .then(body2 => {
          dispatch(fetchSd0107Success(body2));
        })
        .catch(ex2 => {
          dispatch(fetchSd0107Failure(ex2));
        });
      dispatch(fetchSd0109Success(body));
    })
    .catch(ex => {
      dispatch(fetchSd0107Failure(ex));
    });
};

export const getSdkVersionByFeedIdAction = () => dispatch => {
  return fetch(API_URL + '/api/sdepth/feedmap/sdksversion', {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(fetchSd0101Success(body.feedMappSdk));
    })
    .catch(ex => {
      dispatch(fetchSd0101Failure(ex));
    });
};

// SdkVersionSetting
export const getSdkVersionSettingAction = () => dispatch => {
  return dispatch(
    fetchSdkVersionSetting([
      {
        type: '',
        cpNames: '',
        feedMapId: '',
        idx: '',
        lastChgDate: '',
        sdkVer: '',
        svcFeedIdx: '',
      },
    ]),
  );
};
