import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppBar, Button, DialogContent, DialogTitle, makeStyles, Switch, Tab, Table, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  termsPolicyDispaly: {
    fontWeight: 'normal',
  },
  contentText: {
    verticalAlign: 'baseline',
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TermPolicyView({ title, handleClose, data }) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Fragment>
      {title && (
        <Fragment>
          <DialogTitle className="pop_head" id="form-dialog-title">
            {`View ${title}`}
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={'pop_body'}>
            <TableContainer className={'tbl_wrap'}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="15%">Comment</TableCell>
                    <TableCell width="35%" colSpan="5" className={classes.termsPolicyDispaly}>
                      {data.comment}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="15%">Device Type</TableCell>
                    <TableCell width="35%" colSpan={(data.deviceType  === 'mobile' )? 1 : 5} className={classes.termsPolicyDispaly}>
                      {data.deviceType}
                    </TableCell>
                    { data.deviceType  === 'mobile' && (
                    <Fragment>
                    <TableCell width="15%">mobile Platform</TableCell>
                    <TableCell width="35%" colSpan="1" className={classes.termsPolicyDispaly}>
                      {data.devicePlatform}
                    </TableCell>
                    </Fragment>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell width="15%">Version</TableCell>
                    <TableCell width="35%" colSpan={(data.deviceType  === 'mobile' )? 1 : 5} className={classes.termsPolicyDispaly}>
                      {data.version}
                    </TableCell>
                    { data.deviceType  === 'mobile' && (
                    <Fragment>
                    <TableCell width="15%" >mobile Version</TableCell>
                    <TableCell width="35%" colSpan="1" className={classes.termsPolicyDispaly}>
                      {data.deviceVersion}
                    </TableCell>
                    </Fragment>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell width="15%">Country</TableCell>
                    <TableCell width="35%" colSpan="1" className={classes.termsPolicyDispaly}>{data.countryCode }</TableCell>
                    <TableCell width="15%">Enable</TableCell>
                    <TableCell width="35%" colSpan="1" className={classes.termsPolicyDispaly}>
                      <Switch checked={data.enable === 'Y'} inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="15%">Type Order</TableCell>
                    <TableCell width="35%" colSpan="1"  className={classes.termsPolicyDispaly}>{data.typeOrder}</TableCell>
                    <TableCell width="15%">{data.mandatory ? 'Mandatory' : ''}</TableCell>
                    <TableCell width="35%" colSpan="1" className={classes.termsPolicyDispaly}>{data.mandatory}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.contentText}>Content</TableCell>
                    <TableCell colSpan="5" className={classes.termsPolicyDispaly}>
                      {/* <div
                                                    dangerouslySetInnerHTML={{ __html: data.content }} style={{ whiteSpace: 'pre-line' }}
                                                /> */}

                      {data.termMultiLangList && (
                        <Fragment>
                          <AppBar position="static" id="scheduleTabHead">
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
                              {data.termMultiLangList.map((langContent, i) => (
                                <Tab key={langContent.langCode} label={langContent.langCode} {...a11yProps(i)} />
                              ))}
                            </Tabs>
                          </AppBar>
                          {data.termMultiLangList.map((lang, index) => (
                            <div key={index} role="tabpanel" hidden={tabValue !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
                              <div>
                                <b>Title:</b> {lang.title}
                              </div>
                              <b>Description:</b>
                              <div style={{ whiteSpace: 'pre-wrap' }}>{lang.description}</div>
                              {/* {<div dangerouslySetInnerHTML={{ __html: lang.description }} style={{ whiteSpace: 'pre-line' }} />} */}
                            </div>
                          ))}
                        </Fragment>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <div className={'btn_set_wrap alC'}>
              <Button variant="contained" className={'btn_color2'} onClick={handleClose}>
                Close
              </Button>
            </div>
          </DialogContent>
        </Fragment>
      )}
    </Fragment>
  );
}
TermPolicyView.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default connect()(TermPolicyView);
