import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import Snackbar from '@material-ui/core/Snackbar';
import CpCodeForm from './CpCodeForm';
import { getProvidersAdminAction } from '../../../actions/providerActions';
import { request } from '../../../util/request';

export const validateProviderId = ProviderId => {
  if (/^[A-Z]{3,5}$/.test(ProviderId)) return true;
  else {
    alert('CP Code must be capitalized 3-5 characters, e.g ABC');
    return false;
  }
};
export const validateInteractiveUrl = (lgPlayerFlag, interactiveUrl) => {
  if (lgPlayerFlag === '') {
    alert('Please select LG player useflag!'); //+ '. Try again after fixing error !');
    return false;
  } else if (lgPlayerFlag === 'N' && !interactiveUrl) {
    alert('If LG Player is not used, this Interactive URL is mandatory'); //+ '. Try again after fixing error !');
    return false;
  } else return true;
};
function CpCodeAdd({ getProvidersAdminAction }) {
  const [filter, setFilter] = useReducer((state, newState) => ({ ...state, ...newState }), {
    providerId: {
      value: '',
      errorMsg: 'CP Code must be capitalized 3-5 characters, e.g ABC',
    },
    providerName: {
      value: '',
    },
    interactiveUrl: {
      value: '',
      errorMsg: 'If LG Player is not used, this Interactive URL is mandatory',
    },
    logoUrl: {
      value: '',
    },
    lgPlayerFlag: {
      value: '',
    },
  });

  const [open, setOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const handleInputChange = e => {
    const inputId = e.target.name;
    setFilter({
      [inputId]: {
        value: e.target.value,
        errorMsg: filter[inputId].errorMsg,
      },
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (validateProviderId(filter.providerId.value) && validateInteractiveUrl(filter.lgPlayerFlag.value, filter.interactiveUrl.value)) {
      const newItem = {
        providerId: filter.providerId.value,
        providerName: filter.providerName.value,
        appId: filter.providerId.value,
        lgPlayerFlag: filter.lgPlayerFlag.value === 'Y' ? true : false,
        interactiveUrl: filter.interactiveUrl.value,
        logoUrl: filter.logoUrl.value,
      };
      alert('Are you sure to add new CP Coode?');
      const isSuccess = await request('/api/admin/providers', 'POST', newItem);
      if (isSuccess) {
        setSuccessSnackbarOpen(true);
        getProvidersAdminAction();
        setOpen(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Button color="secondary" variant="contained" onClick={() => setOpen(true)} className={'btn_color2'}>
        Add
      </Button>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={1000} //1000=1sec
        onClose={() => setSuccessSnackbarOpen(false)} //없으면 없어지지않음
        message="Successfully Doned !"
        style={{ backgroundColor: 'black' }}
      />
      <Dialog open={open} className={'pop_wrap'}>
        <CpCodeForm title="Add CP Code" isCpCodeVisible={true} filter={filter} handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={() => setOpen(false)} />
      </Dialog>
    </React.Fragment>
  );
}

CpCodeAdd.propTypes = {
  getProvidersAdminAction: PropTypes.func.isRequired,
};
// const mapStateToProps = (state) => ({
//   isAdded: state.providers.isAdded,
// });
const mapDispatchToProps = dispatch => ({
  getProvidersAdminAction() {
    dispatch(getProvidersAdminAction());
  },
});
export default connect(null, mapDispatchToProps)(CpCodeAdd);

// const validateIpAddress = (ipAddr) => {
//     if (ipAddr.length < IP_ADDRESS_MIN_LENGTH) {
//       return {
//         validateStatus: 'error',
//         errorMsg: `IP Address is too short (Minimum ${IP_ADDRESS_MIN_LENGTH} characters needed.)`,
//       };
//     } else if (ipAddr.length > IP_ADDRESS_MAX_LENGTH) {
//       return {
//         validationStatus: 'error',
//         errorMsg: `IP Address is too long (Maximum ${IP_ADDRESS_MAX_LENGTH} characters allowed.)`,
//       };
//     } else if (
//       !/((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(
//         ipAddr,
//       )
//     ) {
//       return {
//         validationStatus: 'error',
//         errorMsg: 'IP Address format is not valid.',
//       };
//     } else {
//       return {
//         validateStatus: 'success',
//         errorMsg: null,
//       };
//     }
//   };

// type: "cors"
// url: "http://qt2-kic.channel.lgtvcommon.com/api/admin/providers"
// redirected: false
// status: 400
// ok: false
// statusText: ""
// headers: Headers {}
// body: ReadableStream
// bodyUsed: true
// __proto__: Response
//****************************************************************[Response.json]
// status: 400
// errorCode: "CDP_SVC_4004"
// message: "Provider is duplicated with providerId : TESTC"
// __proto__: Object

// type: "cors"
// url: "http://qt2-kic.channel.lgtvcommon.com/api/admin/providers"
// redirected: false
// status: 201
// ok: true
// statusText: ""
// headers: Headers {}
// body: (...)
// bodyUsed: false
// __proto__: Response
//**************************************************************** maybe[Response.json]
// id: "TESTE"
// location: "http://qt2-kic.channel.lgtvcommon.com/api/admin/providers/TESTE"

// type: "cors"
// url: "http://qt2-kic.channel.lgtvcommon.com/api/admin/__providers"
// redirected: false
// status: 405
// ok: false
// statusText: ""
// headers: Headers {}
// body: ReadableStream
// bodyUsed: true
// __proto__: Response
//****************************************************************[Response.json]
// status: 405
// errorCode: "CDP_SVC_UNKNOWN"
// message: "POST method is not supported for this request. Supported methods are GET "
// __proto__: Object
