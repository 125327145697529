import React from 'react';
import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
//import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
//import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HomeAppFilter from './HomeAppFilter';
import { setParamAction, getTabCategoryAction } from '../../actions/homeAppActions';
import CategoryTable from './CategoryTable';
import { request } from '../../util/request';
import { hideLoader, showLoader } from '../../actions/loaderActions';
function Emulate({ regionList, regionPlatformList, param, setParamAction, tabCategory, getTabCategoryAction ,showLoaderStatus,hideLoaderStatus}) {

  const cntryList = ((regionList.find(n => n.deviceType===param.deviceType)||{list:[]}).list.find(m=>m.region===param.region)||{cntry:[]}).cntry;
  const [cntry, setCntry] = React.useState('US');
  const [value, setValue] = React.useState(0);
  const [tabResult, setTabResult] = React.useState([]);
  const tabList = tabCategory.filter(n => n.tabId !== -1);

  const getRegion = (value) => {
    gePtlatformVersion(value, regionList.find(n => n.deviceType === value).list[0].region)
    return regionList.find(n => n.deviceType === value).list[0].region;
  };

  const gePtlatformVersion = (deviceType, region) => {
      if (regionPlatformList.find(n => n.deviceType === deviceType && n.region === region) ){
        return regionPlatformList.find(n => n.deviceType === deviceType && n.region === region).platformVersion;
      }
      return '';
  };

  const fetchContent = async () => {
    showLoaderStatus();
    const tabResult = await request(`/api/tab/${tabList[value].tabId}/result?cntry=${cntry}`, 'GET', null, true);
    setTabResult(tabResult);
    hideLoaderStatus();
  };

  const handleChange = async (event, newValue) => {
    try {
      showLoaderStatus();
      const tabResult = await request(`/api/tab/${tabList[newValue].tabId}/result?cntry=${cntry}`, 'GET', null, true);
      setTabResult(tabResult);
      setValue(newValue);
      hideLoaderStatus();
    } catch (error) {
      hideLoaderStatus();
    }
  };

  React.useEffect(() => {
    setCntry(cntryList[0]);
  }, [regionList]);

  React.useEffect(() => {
    setValue(0);
    setCntry(cntryList[0]);
    getTabCategoryAction(param.deviceType, param.region, param.platformVersion, param.status.charAt(0));
  }, [param] );

  React.useEffect(() => {
    if (tabCategory.length) fetchContent();
  }, [tabCategory, cntry] );

  return (
    <div>
      <HomeAppFilter
        regionList={regionList}
        regionPlatformList={regionPlatformList}
        deviceType={param.deviceType}
        handleChangeDeviceType={e => { setParamAction(e.target.value, getRegion(e.target.value), param.status, gePtlatformVersion(e.target.value, getRegion(e.target.value))); }}
        region={param.region}
        handleChangeRegion={e => {
          setParamAction(param.deviceType, e.target.value, param.status, gePtlatformVersion(param.deviceType, e.target.value));
        }}
        platformVersion={param.platformVersion}
        handleChangePlatformVersion={e => {
          setParamAction(param.deviceType, param.region, param.status, e.target.value);
        }}
        status={param.status}
        handleChangeStatus={e => {
          setParamAction(param.deviceType, param.region, e.target.value, param.platformVersion);
        }}
        cntryList={cntryList}
        cntry={cntry}
        changeCntry={e => {
          setCntry(e.target.value);
        }}
      />
      <div>
        <AppBar position="static" id={'scheduleTabHead'}>
          <Tabs value={value} onChange={handleChange}  aria-label="simple tabs example">
            {tabList.map((n, i) => (
              <Tab key={n.tabId} label={n.name} {...a11yProps(i)} />
            ))}
          </Tabs>
        </AppBar>
        {tabList.map((n, i) => (
          <TabPanel key={n.tabId} value={value} index={i} categoryList={tabCategory.filter(n2 => n2.useFlag === 'Y' && n2.parentId == n.tabId)} tabResult={value === i ? tabResult : []} />
        ))}
      </div>
    </div>
  );
}

Emulate.propTypes = {};
const mapStateToProps = state => ({
  param: state.homeApp.param,
  tabCategory: state.homeApp.tabCategory,
  regionList: state.homeApp.regionList,
  regionPlatformList: state.homeApp.regionPlatformList,
});
const mapDispatchToProps = dispatch => ({
  setParamAction(deviceType, region, status, platformVersion) {
    dispatch(setParamAction(deviceType, region, status, platformVersion));
  },

  getTabCategoryAction(deviceType, region, platformVersion, status) {
    dispatch(getTabCategoryAction(deviceType, region, platformVersion, status));
  },
    showLoaderStatus: () => dispatch(showLoader()),
    hideLoaderStatus: () => dispatch(hideLoader()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Emulate);

function TabPanel({ value, index, categoryList, tabResult }) {
  const [expanded, setExpanded] = React.useState(categoryList.map(n => 'false'));
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
      {value === index && (
        <Box p={3}>
          <Button
            variant="contained"
            className={'btn_color2'}
            onClick={() => {
              setExpanded(categoryList.map(n => 'true'));
            }}>
            Expand All
          </Button>
          <Button
            variant="contained"
            className={'btn_color2'}
            onClick={() => {
              setExpanded(categoryList.map(n => 'false'));
            }}>
            Collapse All
          </Button>
          {categoryList.map((n, i) => {
            const result = tabResult.filter(n2 => n2.category_idx === n.categoryId);
            return (
              <Accordion
                key={n.categoryId}
                expanded={expanded[i] === 'true'}
                onChange={() => {
                  let newEx = expanded.map(n => n);
                  newEx[i] = newEx[i] === 'true' ? 'false' : 'true';
                  setExpanded(newEx);
                }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  <Typography variant="h5">{n.name}&nbsp;</Typography>
                  <Typography variant="h6">{`(count:${result.length}, ${n.cpList}, ${n.mappingType}${n.mappingType === 'auto' && n.rule ? `-${n.rule}, order:${n.orderType}` : ''}${n.includeExclusiveFlag === 'Y' ? ', includeExclusive' : ''})`}</Typography>
                </AccordionSummary>
                <CategoryTable result={result} options={{ paging: false }} />
              </Accordion>
            );
          })}
        </Box>
      )}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
