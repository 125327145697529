import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles'; // makeStyles
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
export function ChannelMappingFilter({ system, handleSystem, cp, cntry, cntryInfo, handleCp, handleCountry }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className="filter_area">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="Country">Country</InputLabel>
          <Select labelId="Country" id="Country" value={cntry} label="Country" onChange={e => handleCountry(e)}>
            {cntryInfo.map(v => (
              <MenuItem key={v.cntry} value={v.cntry}>
                {v.cntry}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="CP">CP</InputLabel>
          <Select labelId="CP" id="CP" value={cp.cpName} label="CP" onChange={e => handleCp(e)}>
            {cplist.map(v => (
              <MenuItem key={v.cpName} value={v.cpName}>
                {v.cpName}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </div>
    </React.Fragment>
  );
}

ChannelMappingFilter.propTypes = {
  //cplist: PropTypes.array.isRequired,
  countries: PropTypes.array,
  cp: PropTypes.object.isRequired,
  cntry: PropTypes.string.isRequired,
  handleCountry: PropTypes.func,
  handleCp: PropTypes.func,
};
export default ChannelMappingFilter;
