import React from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 14px;
    --y: 139px;
    --width: 1340px;
    --height: 46px;

    top: 0px;
    left: 0px;
    width: 1920px;
    height: 200px;
  }

  #clip-02 {
    --x: 14px;
    --y: 1px;
    --width: 1892px;
    --height: 400px;

    top: 200px;
    left: 0;
    width: 1920px;
    height: 495px;
  }

  #clip-03 {
    --x: 14px;
    --y: 1px;
    --width: 734px;
    --height: 469px;

    top: 683px;
    left: 0;
    width: 758px;
    height: calc(100% - 683px);
  }

  #clip-04 {
    --x: 1px;
    --y: 1px;
    --width: 710px;
    --height: 469px;

    top: 683px;
    left: 758px;
    width: 723px;
    height: calc(100% - 683px);
  }

  #clip-05 {
    --x: 1px;
    --y: 1px;
    --width: 424px;
    --height: 469px;

    top: 683px;
    left: 1481px;
    width: 438px;
    height: calc(100% - 683px);
  }

  #text-01 {
    top: 94px;
    left: 713px;
  }

  #text-02 {
    top: 144px;
    left: 1460px;
  }

  #text-03 {
    top: 627px;
    left: 98px;
  }

  #line-01 {
    top: 105px;
    left: 630px;
  }

  #line-02 {
    top: 160px;
    left: 1767px;
  }
  #line-03 {
    top: 640px;
    left: 1140px;
  }
`;

const AdvertisementDetail01 = () => {
  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" className="border" />
        </div>

        <div id="clip-02" className="clip-box">
          <div id="clip-02-hole" className="hole" />
          <div id="clip-02-border" className="border" />
        </div>

        <div id="clip-03" className="clip-box">
          <div id="clip-03-hole" className="hole" />
          <div id="clip-03-border" className="border" />
        </div>

        <div id="clip-04" className="clip-box">
          <div id="clip-04-hole" className="hole" />
          <div id="clip-04-border" className="border" />
        </div>

        <div id="clip-05" className="clip-box">
          <div id="clip-05-hole" className="hole" />
          <div id="clip-05-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">You can set conditions for the filter.</div>
          <div id="text-02" className="text">You can check issue list</div>
          <div id="text-03" className="text">You can check details about advertisement data issues from today to 14 days ago.</div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_6121_line_01.png" alt='Line' />
          </div>

          <div id="line-02" className="line">
            <img src="/common/images/iss/tutorial/t_6121_line_02.png" alt='Line' />
          </div>

          <div id="line-03" className="line">
            <img src="/common/images/iss/tutorial/t_6121_line_03.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default AdvertisementDetail01;