import React, {useEffect, useState} from 'react';
import DatePickerStatistics from '../common/DatePickerStatistics';
import DefaultButton from '../common/DefaultButton';
import LoadingIndicator from "../../../common/LoadingIndicator";
import {feature_bat_tabledata} from "./FeatureBatData";
import { ISS_BASE_URL } from '../../../constants/index';
import { getHeaders } from '../../../util/actionUtil';
import IssTable from '../common/IssTable';
import DropDown from '../common/DropDown';
import Alert from '../modal/Alert';
import PropTypes from "prop-types";
import {connect} from "react-redux";

const FeatureBat = ({ userRole, userAuth  }) => {
  const [issueCount, setIssueCount] = useState({});
  const [isListDisplay, setIsListDisplay] = useState(false);
  const [isReferenceDisplay, setIsReferenceDisplay] = useState(false);
  const [calendarValue, setCalendarValue] = useState([]);  // [startDate, endDate] eg, 2023-08-20, 2023-09-19
  const [rangeDate, setRangeDate] = useState([]);
  const [featurebatList, setFeaturebatList] = useState([]);
  const [referenceList, setReferenceList] = useState([]);
  const [featurebatListFetching, setFeaturebatListFetching] = useState('init');
  const [referenceListFetching, setReferenceListFetching] = useState('init');
  const [currentFeaturebatListTableData, setCurrentfeaturebatListTableData] = useState({});
  const [currentReferenceListTableData, setCurrentReferenceListTableData] = useState({});
  const [selectedItem, setSelectedItem] = useState({ fbId: '' });
  const [beaconTypes, setBeaconTypes] = useState({ dropdownList: [] });
  const [statusTypes, setStatusTypes] = useState({ dropdownList: [] });
  const [paramBeaconTypes, setParamBeaconTypes] = useState([]);
  const [paramStatusTypes, setParamStatusTypes] = useState([]);
  const [featurebatInput, setFeaturebatInput] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlert, setIsAlert] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({date:'', beaconType:[], status:[], fbId:''});
  const [initFlag, setInitFlag] = useState(true);
  const statusTypeItems = ['pass', 'fail'];
  const beaconTypeItems = ['Channel', 'Program', 'Usability app', 'Usability content',
  'Usability tab', 'Usability option', 'Search', 'Player', 'System'];
  const [statisticsInitFlag, setStatisticsInitFlag] = useState(true);
  const [editedData, setEditedData] = useState({});

  useEffect(() => {
    defaultRangeDate();
    setBeaconTypes({ dropdownList: [...beaconTypeItems] });
    setStatusTypes({ dropdownList: [...statusTypeItems] });
    setFeaturebatInput('');
  }, []);

  useEffect(() => {
    if (currentFilter.date && currentFilter.date.length > 0) {
      setIsListDisplay(false);
      valueInitialize();
      getFeaturebatListInfo(currentFilter);
      fetchIssueCount(currentFilter);
    }
  }, [currentFilter]);

  useEffect(() => {
    if (rangeDate && rangeDate.length > 0) {
      setCurrentFilter({date: calendarValue.length === 0 ? defaultRangeDate() : calendarValue ,
        beaconType: [], status: [], fbId: ''});
    }
  }, [rangeDate]);

  useEffect(() => {
    console.log(userRole);
  }, [userRole]);
  useEffect(() => {
    console.log(userAuth);
  }, [userAuth]);

  const getFeaturebatListInfo = (item) => {
    let body = {
      date: item.date,
      beaconTypeList: item.beaconType,
      //statusList : item.status,
      featurebatId: item.fbId
    };
    setFeaturebatListFetching('loading');

    fetch(`${ISS_BASE_URL}/api/featurebat/featurebatList`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
    .then(res => res.json())
    .then(body => {
      setFeaturebatListFetching('done');
      let statusBody = [];
      if (item.status.length === 1 && item.status[0] === 'pass'){
        statusBody = body.filter(item => item.passCount > 0);
      } else if (item.status.length === 1 && item.status[0] === 'fail'){
        statusBody = body.filter(item => item.failCount > 0);
      } else {
        statusBody = body;
      }
      setFeaturebatList(statusBody);
    })
    .catch(error => {
      console.log(`getFeaturebatListInfo error: `, error);
    }).finally(() => {});
  };

  const getFeaturebatReferenceInfo = (item) => {
    let body = {
      beaconTypeList: item.beaconType,
      statusList : item.status,
      featurebatId: item.fbId
    };
    setReferenceListFetching('loading');

    fetch(`${ISS_BASE_URL}/api/featurebat/featurebatReference`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
    .then(res => res.json())
    .then(body => {
      setReferenceListFetching('done');
      setReferenceList(body);
    })
    .catch(error => {
      console.log(`getFeaturebatReferenceInfo error: `, error);
    }).finally(() => {});
  };

  const fetchIssueCount = (item) => {
    let body = {
      date: item.date,
      beaconTypeList: item.beaconType,
      statusList : item.status,
      featurebatId: item.fbId
    };
    fetch(`${ISS_BASE_URL}/api/featurebat/count`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
    .then(res => res.json())
    .then(body => {
      let groupCount = body.groupCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      let tcCount = body.tcCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      let newTcCount = body.newTcCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      let passCount = body.passCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      let failCount = body.failCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      setIssueCount({groupCount: groupCount, tcCount: tcCount, newTcCount: newTcCount,
        passCount: passCount, failCount: failCount});
    })
    .catch(error => {
      console.log(`fetchIssueCount error: `, error);
    }).finally(() => {});
  };

  useEffect(() => {
    if(featurebatList && featurebatList.length > 0) {
      feature_bat_tabledata.feature_bat_list_data.rowdata = featurebatList.map(item => ( {
        row: [
          item.fbId,
          item.macAddress,
          `${Math.round(item.passCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
          `${Math.round(item.failCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
          `${Math.round(item.successRate).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} %`,
          item.tcStartDateTime,
          item.tcEndDateTime
        ],
        fbId: item.fbId,
        macAddress: item.macAddress
      }));
    } else {
      feature_bat_tabledata.feature_bat_list_data.rowdata = [];
      if(initFlag === true) {
        setInitFlag(false);
      } else {
        setAlertTitle('Info');
        setAlertMessage(`There is no data`);
        onOpenAlert();
      }
    }
    setCurrentfeaturebatListTableData(feature_bat_tabledata.feature_bat_list_data);

    if (featurebatListFetching === 'done') {
      setIsListDisplay(true);
    }
  }, [featurebatList]);

  useEffect(() => {
    if (referenceList && referenceList.length > 0) {
      feature_bat_tabledata.existing_issues.rowdata = referenceList.map(item => ( {
        row: [
          item.tcId,
          item.beaconType,
          item.status,
          item.tcStartDateTime + '\n\t\t/\n' + item.tcEndDateTime,
          item.request,
          item.response,
          item.errMsg,
          item.description,
          item.comment
        ],
        tcId: item.tcId
      }));
      setCurrentReferenceListTableData(feature_bat_tabledata.existing_issues);
      if (referenceListFetching === 'done') {
        setIsReferenceDisplay(true);
      }
      if(statisticsInitFlag === true) {
        setStatisticsInitFlag(false);
      }
    } else {
      feature_bat_tabledata.existing_empty_issues.rowdata = [];
    }
  }, [referenceList]);

  useEffect(() => {
    if (selectedItem && selectedItem.fbId !== '') {
      getFeaturebatReferenceInfo({beaconType: paramBeaconTypes, status: paramStatusTypes,
        fbId: selectedItem.fbId});
    }
  }, [selectedItem]);

  const onClickRowItem = item => {
    if (item && item.fbId !== '') {
      setIsReferenceDisplay(false);
      setSelectedItem({ fbId: item.fbId });
    }
  };

  const valueInitialize = () => {
    setIsReferenceDisplay(false);
    setSelectedItem({ fbId: '' });
    feature_bat_tabledata.existing_issues.rowdata = [];
    setCurrentReferenceListTableData(feature_bat_tabledata.existing_issues);
  };

  const defaultRangeDays = 30; // 달력에 날짜를 보여 주기 위한 default 일자, 30일
  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());

    const thirtyDaysAgo = new Date(utcToday);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);

    const utcTodayString = utcToday.toISOString().substring(0, 10);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0, 10);
    setRangeDate([thirtyDaysAgoString, utcTodayString]);  // [startDate, endDate]
  }

  const issueSearchByButton = () => {

    if (paramBeaconTypes.length === 0 || paramStatusTypes.length === 0 || calendarValue.length === 0) {
      const showMessage = paramBeaconTypes.length === 0 ? 'Beacon Type' : paramStatusTypes.length === 0 ?
      'Status Type' : 'Date';
      setAlertTitle('Warning');
      setAlertMessage(`Please select any ${showMessage}`);
      onOpenAlert();
    } else {
      setCurrentFilter({date: calendarValue, beaconType: paramBeaconTypes, status: paramStatusTypes,
        fbId: featurebatInput});
    }
  };

  const handleContentNameInputChange = event => {
    setFeaturebatInput(event.target.value);
  };

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      issueSearchByButton();
    }
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const onSaveComment = () => {

    let tcInfo = Object.values(editedData).map((item) => ({
      featurebatId: selectedItem.fbId,
      tcId: item[0],
      comment: item[8]
    }))

    // comment가 바뀐 tc만 필터링
    let body = tcInfo.filter((tc) => {
      const origin = referenceList.find((item) => tc.tcId === item.tcId)
      return origin && origin.comment !== tc.comment
    })

    fetch(`${ISS_BASE_URL}/api/featurebat/comment`, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(() => getFeaturebatReferenceInfo({beaconType: paramBeaconTypes, status: paramStatusTypes,
        fbId: selectedItem.fbId}))
      .then(() => setStatisticsInitFlag(true))
      .then(() => {
        setEditedData({})
        setAlertTitle('Comment')
        setAlertMessage('Successfully Saved')
        onOpenAlert(true)
      })


  }

  return (
    <>
      <div className="iss-main">
        <div className="main-container">
          <div className="inner">
            <div className="sub-detail-wrapper sub-detail-feature">
              <div>
                <div className="head-title">
                  <h1>Feature Bat</h1>
                </div>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                  <div className="box-total-issue">
                    <h2>Total</h2>
                    <strong>{issueCount.groupCount}</strong>
                  </div>
                  <div className="contents-summary">
                    <div className="line">
                      <h3>TC</h3>
                      <div className="num-summary">
                        <strong>{issueCount.tcCount}</strong>
                        {issueCount.newTcCount > 0 &&
                          <span className="new">{issueCount.newTcCount}</span>
                        }
                      </div>
                    </div>
                    <div className="line">
                      <h3>Pass</h3>
                      <div className="num-summary">
                        <strong>{issueCount.passCount}</strong>
                      </div>
                    </div>
                    <div className="line">
                      <h3>Fail</h3>
                      <div className="num-summary">
                        <strong>{issueCount.failCount}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contents-section-wide">
                  <div className="ui-dropdown-box">
                    <DatePickerStatistics rangeDays={defaultRangeDays}
                    dateChangeHandler={(newDate) => setCalendarValue(newDate)}/>
                    <DropDown item={beaconTypes} title="Beacon" onChangeDropDown={setParamBeaconTypes}/>
                    <DropDown item={statusTypes} title="Status" onChangeDropDown={setParamStatusTypes}/>
                    <div className="content-name">
                      <input type="text" value={featurebatInput} placeholder="Feature Bat ID"
                        onChange={event => handleContentNameInputChange(event)}
                        onKeyPress={handleOnKeyPress}
                      />
                    </div>
                    <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'}
                    clickEvent={issueSearchByButton}/>
                  </div>
                  <div className="ui-dropdown-box">&nbsp;</div>
                  {!isListDisplay ? (
                    <div className="loading_wrap">
                      <LoadingIndicator />
                    </div>
                  ) : (
                    <div className="grid-row-wide-prewrap">
                      <div className="row-box box-tbl box-statistics tbl-type-short">
                        <IssTable height={'400px'} pagination={true}
                          data={currentFeaturebatListTableData}
                          handleClickItem={item => onClickRowItem(item)}
                          pagingChangeCallback={() => valueInitialize()}
                          sortingItem={{enable: true, sortColumn: 0, sortDirection: 'desc'}} />
                      </div>
                      {!statisticsInitFlag ? (
                        <div className="box-existingIssues">
                          <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <h3>{selectedItem.fbId}</h3>
                            <DefaultButton type={'button'} text={'Save'} btnClass={'btn-search'} clickEvent={onSaveComment} />
                          </div>
                          <div className="tbl-container">
                            <IssTable height={'882px'} pagination={false}
                                      data={isReferenceDisplay ? currentReferenceListTableData : feature_bat_tabledata.existing_empty_issues}
                                      handleEditedData={setEditedData} />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAlert && <Alert isOpen={isAlert} onClose={onCloseAlert} title={alertTitle} message={alertMessage} />}
    </>
  );
};

FeatureBat.propTypes = {
  userRole: PropTypes.string.isRequired,
  userAuth: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  userRole: state.currentUser.userRole,
  userAuth: state.currentUser.userAuth,
});
export default connect(mapStateToProps)(FeatureBat);
