import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable, { MTableBodyRow } from 'material-table';
import { Button, Dialog, Switch } from '@material-ui/core';
//import CircularProgress from '@material-ui/core/CircularProgress';
//import LoadingIndicator from '../../common/LoadingIndicator';
import { Link } from 'react-router-dom';
import HomeAppFilter from './HomeAppFilter';
import { setParamAction, getTabCategoryAction, copyTabCategoryAction, addTabAction, updateTabRandomStatus, showTabCreatePop, hideTabCreatePop, setExpandTabAction, tabDisableAction, tabDeletebyIdxAction, updateTabOrder } from '../../actions/homeAppActions';
import { request } from '../../util/request';
import TabCategoryAdd from './TabCategoryAdd';
import CommonDialog from '../../common/dialog/CommonDialog';
import TabCategoryDelete from './TabCategoryDelete';
import TabCategoryCopy from './TabCategoryCopy';
import TabAdd from './TabAdd';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getFeedmapSdksByRegion } from '../../actions/displayRulesActions';
import ChannelMappingAlert from '../channel/ChannelMapping/ChannelMappingAlert';

function TabCategory({
  regionList,
  regionPlatformList,
  param,
  setParamAction,
  setExpandTab,
  tabExpand,
  tabCategory,
  getTabCategoryAction,
  updateTabRandomFlag,
  copyTabCategory,
  addTab,
  tabAddOpen,
  showAddTabStatus,
  hideAddTabStatus,
  tabDisable,
  tabDelete,
  updateTabOrder,
  getFeedmapSdksByRegion,
  feedMappSdk,
}) {
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [openApply, setOpenApply] = React.useState(false);
  const tableRef = React.useRef(null);
  const [openPop, setOpenPop] = useState(false);
  const [copy, setCopy] = useState({});
  const [useTab, setUseTab] = useState({});
  const [tableData, setTableData] = useState([]);
  const [enableSaveOrder, setEnableSaveOrder] = useState(false);
  const [data, setData] = useState([]);
  const [rowDataIds, setRowDataIds] = useState([]);

  const [feedmapSdksData, setFeedmapSdksData] = useState({
    keyInfo: null,
    isLoading: false,
    type: '',
  });

  const handleExpandAll = () => {
    if (!tableRef.current) return;
    const _data = [...tableRef.current.state.data];
    tableRef.current.state.data.forEach(n => {
      const { detailPanel } = tableRef.current.props;
      let handleShowDetailPanel = detailPanel;
      // If props.detailPanel is an array not a func
      if (typeof detailPanel === 'object') {
        // ~ I AM USING INDEX 0 HERE - CHANGE TO WHATEVER YOU NEED ~
        handleShowDetailPanel = detailPanel[n.tableData.id].render;
      }
      // If `showDetailPanel` does not exist, add it to open detailPanel..
      _data[n.tableData.id].tableData = {
        ..._data[n.tableData.id].tableData,
        showDetailPanel: handleShowDetailPanel,
      };
    });

    setData(_data);
  };

  const handleCollapseAll = () => {
    if (!tableRef.current) return;
    const _data = [...tableRef.current.state.data];
    tableRef.current.state.data.forEach(n => {
      // if (n.tableData.showDetailPanel === false) tableRef.current.showDetailPanel(n.tableData.path, n);
      const { detailPanel } = tableRef.current.props;
      let handleShowDetailPanel = detailPanel;
      // If props.detailPanel is an array not a func
      if (typeof detailPanel === 'object') {
        // ~ I AM USING INDEX 0 HERE - CHANGE TO WHATEVER YOU NEED ~
        handleShowDetailPanel = detailPanel[n.tableData.id].render;
      }
      // If `showDetailPanel` already exists, remove it to close the detailPanel..
      _data[n.tableData.id].tableData.showDetailPanel = '';
    });
    setData(_data);
  };
  const DrageState = {
    row: -1,
    dropIndex: -1, // drag target
    position: -1,
    dragPosition: -1,
    type: '',
    srtart: -1,
    end: -1,
  };
  React.useEffect(() => {
    const updatedData = [];
    tableData.forEach(n => {
      if (param.status.charAt(0) === 'S' ? n : n.useFlag === 'Y') {
        for (let index = 0; index < Math.min(n.tabOrder, 1); index++) {
          updatedData.push({ ...n, index });
        }
      }
    });
    updatedData.forEach(n => {
      if (n && n.tabId && rowDataIds.includes(n.tabId)) {
        const { detailPanel } = tableRef.current.props;
        let handleShowDetailPanel = detailPanel;
        n.tableData = {
          ...n.tableData,
          showDetailPanel: handleShowDetailPanel,
        };
      }

      if (n && n.tabId && isExpand(n.tabId)) {
        const { detailPanel } = tableRef.current.props;
        let handleShowDetailPanel = detailPanel;
        n.tableData = {
          ...n.tableData,
          showDetailPanel: handleShowDetailPanel,
        };
      }
    });
    setData(updatedData);
    setRowDataIds([]);
  }, [tableData]);

  React.useEffect(() => {
    // data.map((element, index) => (element.result_order = index + 1));
    setTableData(tabCategory);
  }, [tabCategory]);

  const defaultChangeTpye = {
    disable: false,
    delete: false,
  };
  const addExpand = (key, value) => {
    setExpandTab(key, value);
  };

  const isExpand = key => {
    if (tabExpand.has(key)) {
      return tabExpand.get(key);
    } else {
      return false;
    }
  };

  const [tabChangeTpye, setTabChangeTpye] = useState(defaultChangeTpye);
  const [description, setDescription] = useState('');
  const defaultTab = {
    deviceType: '',
    region: '',
    platformVersion: '',
    tabCode: '',
    tabName: '',
    tabImg: '',
    type: '',
    linkType: '',
    skeletonType:'',
    locationType: '',
    useFlag: '',
    categoryFlag: 'Y',
  };
  const handleTabInputChange = e => {
    const { name, value } = e.target;
    if (name === 'deviceType') {
      setUseTab({ ...useTab, ...{ [name]: value, region: '', platformVersion: '' } });
    } else if (name === 'region') {
      setUseTab({ ...useTab, ...{ [name]: value, platformVersion: '' } });
    } else if (name === 'type') {
      setUseTab({ ...useTab, ...{ [name]: value, linkType: '' } });
    } else if (name === 'categoryFlag') {
      setUseTab({ ...useTab, ...{ [name]: e.target.checked ? 'Y' : 'N' } });
    } else {
      setUseTab({ ...useTab, ...{ [name]: value } });
    }
  };

  const copyData = {
    targetDeviceType: '',
    targetRegion: '',
    targetVersion: '',
    cloneVersion: '',
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === 'targetDeviceType') {
      setCopy({ ...copy, ...{ [name]: value, targetRegion: '', targetVersion: '', cloneVersion: '' } });
    } else if (name === 'targetRegion') {
      setCopy({ ...copy, ...{ [name]: value, targetVersion: '', cloneVersion: '' } });
    } else {
      setCopy({ ...copy, ...{ [name]: value } });
    }
  };

  const handleInputNumberChange = e => {
    const { name, value } = e.target;
    const onlyNumber = value.replace(/[^-/.0-9]/g, '');
    setCopy({ ...copy, ...{ [name]: onlyNumber } });
  };
  const handleTabInputNumberChange = e => {
    const { name, value } = e.target;
    const onlyNumber = value.replace(/[^-/.0-9]/g, '');
    setUseTab({ ...useTab, ...{ [name]: onlyNumber } });
  };

  let maxCategoryCode = Math.max(...tabCategory.filter(n => n.categoryCode && !n.categoryCode.startsWith('C-')).map(n2 => n2.categoryCode.slice(1))) || 0;
  if (maxCategoryCode === -Infinity || maxCategoryCode === Infinity) maxCategoryCode = 0;

  React.useEffect(() => {
    getTabCategoryAction(param.deviceType, param.region, param.platformVersion, param.status.charAt(0));
  }, [param]);

  const getRegion = value => {
    gePtlatformVersion(value, regionList.find(n => n.deviceType === value).list[0].region);
    return regionList.find(n => n.deviceType === value).list[0].region;
  };

  const gePtlatformVersion = (deviceType, region) => {
    if (regionPlatformList.find(n => n.deviceType === deviceType && n.region === region)) {
      return regionPlatformList.find(n => n.deviceType === deviceType && n.region === region).platformVersion;
    }
    return '';
  };

  React.useEffect(() => {
    if (param.region && feedmapSdksData.isLoading) {
      getFeedmapSdksByRegion(`${param.region}`);
    }
  }, [param, feedmapSdksData]);

  React.useEffect(() => {
    if (feedmapSdksData.isLoading && feedmapSdksData.type === 'DELETE') {
      setDeleteWarningMessage(feedmapSdksData.keyInfo);
      setFeedmapSdksData({
        keyInfo: null,
        isLoading: false,
        type: '',
      });
    } else if (feedmapSdksData.isLoading && feedmapSdksData.type === 'DISABLE') {
      setDisableWarningMessage(feedmapSdksData.keyInfo);
      setFeedmapSdksData({
        keyInfo: null,
        isLoading: false,
        type: '',
      });
    } else if (feedmapSdksData.isLoading && feedmapSdksData.type === 'CATEGORY_DELETE') {
      deleteWarningCategory(feedmapSdksData.keyInfo);
      setFeedmapSdksData({
        keyInfo: null,
        isLoading: false,
        type: '',
      });
    }
  }, [feedMappSdk]);

  const handleCopySubmit = e => {
    e.preventDefault();
    if (copy.targetDeviceType === 'tv') {
      if (!validatedVersion(copy.cloneVersion)) {
        alert('[COPY HOME-APP DATA] Web os version format is incorrect.');
        return false;
      }
    }
    setOpenPop(false);
    copyTabCategory(copy);
  };

  const handleCreateSubmit = e => {
    e.preventDefault();
    var pattern = /\s/g;
    if (useTab.tabCode.match(pattern)) {
      alert('Tab Code에 공백이 존재합니다.');
      return false;
    }
    const imageData = document.getElementById('tabImageUrl').files[0]; //undefined if not uploaded
    const updateUseTab = {
      ...useTab,
      imageData: imageData,
    };
    setUseTab({ ...updateUseTab });
    if (updateUseTab.deviceType === 'tv') {
      if (!validatedVersion(updateUseTab.platformVersion)) {
        alert('[CREATE TAB] Web os version format is incorrect.');
        return false;
      }
    }
    setData(reOrderTabRowByDefault(data));
    updateTabOrder([...data], param.deviceType, param.region, param.platformVersion, param.status, false);
    addTab(updateUseTab);
  };

  const validatedVersion = text => {
    const pattern = /\d{1,3}.\d{1,3}.\d{1,3}/;
    return pattern.test(text);
  };

  const setDisableMessage = tab => {
    setFeedmapSdksData({
      keyInfo: tab,
      isLoading: true,
      type: 'DISABLE',
    });
  };

  const setDisableWarningMessage = tab => {
    setTabChangeTpye({ ...defaultChangeTpye, disable: true, delete: false });
    const tabFound = feedMappSdk.find(sd => sd.tabCode === tab.tabCode && sd.platformVersion === tab.platformVersion && sd.region === tab.region);
    let tabName = '[' + tab.deviceType + '-' + tab.region + '-' + tab.platformVersion + '-' + tab.name + '(' + tab.tabCode + ')]';
    let flag = 'N';
    let isAlert = !!tabFound;
    if (tab.useFlag === 'N') {
      flag = 'Y';
      setDescription(tabName + ' Do you want to activate the tab? Saved, tested, and published are all applicable.');
    } else if (tabFound) {
      setDescription(tabName + ' Configured in 2nd depth! Can not be disabled');
    } else {
      setDescription(tabName + ' Do you want to disable the tab? Saved, tested, and published are all applicable.');
    }
    setUseTab({ ...useTab, ...{ deviceType: tab.deviceType, region: tab.region, platformVersion: tab.platformVersion, tabCode: tab.tabCode, tabName: tab.name, useFlag: flag, isAlert: isAlert } });
  };

  const setDeleteMessage = tab => {
    setFeedmapSdksData({
      keyInfo: tab,
      isLoading: true,
      type: 'DELETE',
    });
  };

  const setDeleteWarningMessage = tab => {
    let tabName = '[' + tab.deviceType + '-' + tab.region + '-' + tab.platformVersion + '-' + tab.name + '(' + tab.tabCode + ')]';
    const tabFound = feedMappSdk.find(sd => sd.tabCode === tab.tabCode && sd.platformVersion === tab.platformVersion && sd.region === tab.region);
    let description = tabName + ' Deleting a tab deletes the child content together. Are you sure you want to delete it ?';
    let isAlert = !!tabFound;
    if (tabFound) {
      description = tabName + ' Configured in 2nd depth! Can not be deleted';
    }
    setDescription(description);
    setTabChangeTpye({ ...defaultChangeTpye, disable: false, delete: true });
    setUseTab({
      ...useTab,
      ...{
        deviceType: tab.deviceType,
        region: tab.region,
        platformVersion: tab.platformVersion,
        tabCode: tab.tabCode,
        tabName: tab.name,
        useFlag: 'Y',
        tabIdx: tab.tabId,
        isAlert: isAlert,
      },
    });
  };

  const deleteCategory = tabParam => {
    setFeedmapSdksData({
      keyInfo: tabParam,
      isLoading: true,
      type: 'CATEGORY_DELETE',
    });
  };

  const deleteWarningCategory = tabParam => {
    let description = 'Are you sure to delete this category ?';
    const catFound = feedMappSdk.find(sd => sd.region === tabParam.region && sd.platformVersion === tabParam.platformVersion && sd.tabCode === tabParam.tabCode && sd.categoryCode === tabParam.categoryCode);
    let isAlert = !!catFound;
    if (catFound) {
      const country = catFound.feedMapId.split(':')[0];
      description = `${tabParam.categoryCode} configured in 2nd depth of a country ${country}`;
    } else {
      description = 'Are you sure to delete this category ?';
    }
    setDescription(description);
    setTabChangeTpye({ ...defaultChangeTpye, disable: false, delete: true });
    setUseTab({
      ...useTab,
      ...{ ...tabParam, deleteType: 'category', isAlert: isAlert }
    });
  };

  const allHandleOk = async () => {
    if (useTab.deleteType && useTab.deleteType === 'category') {
      let isSuccess = await request(`/api/categoryDelete`, 'PATCH', useTab);
      if (isSuccess) {
        alert('Successfully done !');
        getTabCategoryAction(param.deviceType, param.region, param.platformVersion, param.status.charAt(0));
        setUseTab({ ...useTab, deleteType: '', isAlert: false });
      }
    } else {
      if (tabChangeTpye.disable) {
        tabDisable(useTab);
      } else if (tabChangeTpye.delete) {
        tabDelete(useTab);
      }
    }
    setTabChangeTpye({ ...defaultChangeTpye, disable: false, delete: false });
  };

  const closeAction = () => {
    setTabChangeTpye({ ...defaultChangeTpye, disable: false, delete: false });
    setUseTab({ ...useTab, deleteType: '', isAlert: false });
  };

  const UpdateButton = () => {
    const [fetching, setFetching] = React.useState(false);
    return (
      <React.Fragment>
        <Button
          className="btn_color2"
          onClick={() => {
            setOpenUpdate(true);
          }}>
          Update Contents By Rule
        </Button>
        <CommonDialog
          open={openUpdate}
          description="If you click this, all contents of this region and status will be updated by each rules. But manual mapping contents will not updated because it does not have any ules.
            And this work can take time. Are you sure to do this ?"
          handleCancel={() => {
            setOpenUpdate(false);
          }}
          handleOk={async () => {
            setFetching(true);
            const isSuccess = await request(`/api/device/${param.deviceType}/region/${param.region}/platform/${param.platformVersion}/status/${param.status.charAt(0)}/update`, 'POST', false, true);
            setFetching(false);
            if (isSuccess) alert('Successfully done !');
            setOpenUpdate(false);
          }}
          fetching={fetching}
        />
      </React.Fragment>
    );
  };

  const ApplyButton = () => {
    const [fetching, setFetching] = React.useState(false);
    return (
      <React.Fragment>
        {param.status !== 'Published' && (
          <React.Fragment>
            <Button
              className="btn_color2"
              onClick={() => {
                setOpenApply(true);
              }}>
              {param.status === 'Saved' ? 'Apply to Testing' : 'Apply to Published'}
            </Button>
            <CommonDialog
              open={openApply}
              description="Before this, you must update contents by rule by clicking left button and go to the emulate menu to check the final results. And this work can take time. Are you sure to do this ?"
              handleCancel={() => {
                setOpenApply(false);
              }}
              handleOk={async () => {
                setFetching(true);
                let isSuccess = await request(`/api/device/${param.deviceType}/region/${param.region}/platform/${param.platformVersion}/status/${param.status.charAt(0)}/publish`, 'POST');
                setFetching(false);
                if (isSuccess) alert('Successfully done !');
                setOpenApply(false);
              }}
              fetching={fetching}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const handleRandomFlag = (e, rowData) => {
    e.preventDefault();
    const enable = e.target.checked ? 'Y' : 'N';
    updateTabRandomFlag(rowData.tabId, enable, param);
  };

  //Reorder the table row
  const offsetIndex = (from, to, arr = []) => {
    if (from < to) {
      let start = arr.slice(0, from),
        between = arr.slice(from + 1, to + 1),
        end = arr.slice(to + 1);
      return [...start, ...between, arr[from], ...end];
    }
    if (from > to) {
      let start = arr.slice(0, to),
        between = arr.slice(to, from),
        end = arr.slice(from + 1);
      return [...start, arr[from], ...between, ...end];
    }
    return arr;
  };

  const reOrderTabRowByDefault = data => {
    let indexCount = 1;
    data.filter(n => n.locationType === 'top').map((element, index) => (element.tabOrder = index + indexCount));

    indexCount = 11;
    data.filter(n => n.locationType === 'scroll').map((element, index) => (element.tabOrder = index + indexCount));

    indexCount = 100;
    data.filter(n => n.locationType === 'bottom').map((element, index) => (element.tabOrder = index + indexCount));

    indexCount = 1000;
    data.filter(n => n.locationType != 'top' && n.locationType != 'scroll' && n.locationType !== 'bottom').map((element, index) => (element.tabOrder = index + indexCount));

    data.sort((a, b) => a.tabOrder - b.tabOrder);
    return data;
  };

  const reOrderRow = (from, to, type) => {
    let newtableData = [];
    let locType = tableData[from].locationType;
    if (type === 'Swap') {
      tabCategory = tabCategory.filter(n => (param.status.charAt(0) === 'S' ? n : n.useFlag === 'Y'));
      [tableData[from], tableData[to]] = [tableData[to], tableData[from]];
      let start = tableData[0],
        between = tableData.slice(1, tableData.length - 1),
        end = tableData[tableData.length - 1];
      newtableData = [start, ...between, end];
    } else if (type === 'Insert') {
      newtableData = offsetIndex(from, to, tableData);
    } else {
      return;
    }
    let indexCount = 0;
    if (locType === 'top') {
      indexCount = 1;
    } else if (locType === 'scroll') {
      indexCount = 11;
    } else if (locType === 'bottom') {
      indexCount = 100;
    } else {
      indexCount = 1000;
    }

    newtableData.filter(n => n.locationType === locType).map((element, index) => (element.tabOrder = index + indexCount));
    newtableData.sort((a, b) => a.tabOrder - b.tabOrder);
    setTableData(newtableData);
    setEnableSaveOrder(true);
  };

  const reOrderCategoryRowByDefault = childData => {
    let indexCount = 1;
    childData.filter(n => n.categoryType === 'top').map((element, index) => (element.categoryOrder = index + indexCount));

    indexCount = 11;
    childData.filter(n => n.categoryType != 'top').map((element, index) => (element.categoryOrder = index + indexCount));

    childData.sort((a, b) => a.categoryOrder - b.categoryOrder);
    return childData;
  };

  const defaultReOrderCategoryRow = tabId => {
    let childData = [];
    childData = tableData.filter(row => row && row.parentId && row.parentId === tabId);
    childData = childData.filter(n => (param.status.charAt(0) === 'S' ? n : n.useFlag === 'Y'));

    let indexCount = 1;
    childData.filter(n => n.categoryType === 'top' && n.parentId === tabId).map((element, index) => (element.categoryOrder = index + indexCount));

    indexCount = 11;
    childData.filter(n => n.categoryType != 'top' && n.parentId === tabId).map((element, index) => (element.categoryOrder = index + indexCount));

    childData.sort((a, b) => a.categoryOrder - b.categoryOrder);
    return childData;
  };

  const reOrderCategoryRow = (from, to, type, tabId, rowData) => {
    let childData = [];
    let parentIds = [];
    data
      .filter(row => row && row.tabId && row.tableData.showDetailPanel)
      .forEach(n => {
        parentIds.push(n.tabId);
      });
    setRowDataIds(parentIds);
    childData = tableData.filter(row => row && row.parentId && row.parentId === tabId);
    childData = childData.filter(n => (param.status.charAt(0) === 'S' ? n : n.useFlag === 'Y'));
    let newtableData = [];
    let categoryType = childData[from].categoryType;
    if (type === 'Swap') {
      tabCategory = tabCategory.filter(n => (param.status.charAt(0) === 'S' ? n : n.useFlag === 'Y'));
      [childData[from], childData[to]] = [childData[to], childData[from]];
      let start = childData[0],
        between = childData.slice(1, childData.length - 1),
        end = childData[childData.length - 1];
      newtableData = [start, ...between, end];
    } else if (type === 'Insert') {
      childData = offsetIndex(from, to, childData);
    } else {
      return;
    }
    let indexCount = 0;
    if (categoryType === 'top') {
      indexCount = 1;
      childData.filter(n => categoryType === 'top' && n.categoryType === categoryType && n.parentId === tabId).map((element, index) => (element.categoryOrder = index + indexCount));
    } else {
      indexCount = 11;
      childData.filter(n => n.categoryType != 'top' && n.parentId === tabId).map((element, index) => (element.categoryOrder = index + indexCount));
    }
    childData.sort((a, b) => a.categoryOrder - b.categoryOrder);
    let updatedData = [];
    updatedData = tableData.filter(row => row && row.parentId != tabId);
    setTableData([...updatedData, ...childData]);
    setEnableSaveOrder(true);
  };

  const saveTabOrder = () => {
    let data = tableData.filter(n => (param.status.charAt(0) === 'S' ? n : n.useFlag === 'Y') && n.tabOrder != undefined);
    data = data.map(({ tabId, tabOrder }) => ({ tabId, tabOrder }));
    let categoryData = tableData.filter(n => (param.status.charAt(0) === 'S' ? n : n.useFlag === 'Y') && n.parentId != undefined);
    categoryData = categoryData.map(({ categoryId, categoryOrder, categoryCode }) => ({ categoryId, categoryOrder, categoryCode }));
    updateTabOrder([...data, ...categoryData], param.deviceType, param.region, param.platformVersion, param.status);
  };

  const clearCategoryOrder = () => {
    getTabCategoryAction(param.deviceType, param.region, param.platformVersion, param.status.charAt(0));
  };

  const defaultCategoryOrder = tableId => {
    let childData = [];
    childData = tableData.filter(row => row && row.parentId && row.parentId === tableId);
    childData = childData.filter(n => (param.status.charAt(0) === 'S' ? n : n.useFlag === 'Y'));

    let childDataPositionAuto = [];
    childDataPositionAuto = childData.filter(n => n.mappingType === 'position_auto' && n.parentId === tableId);

    let childDataPositionManual = [];
    childDataPositionManual = childData.filter(n => n.mappingType === 'position_manual' && n.parentId === tableId);

    let childDataFavorite = [];
    childDataFavorite = childData.filter(n => n.mappingType != 'position_auto' && n.mappingType != 'position_manual' && n.categoryType === 'favorite' && n.parentId === tableId);

    let childDataRank = [];
    childDataRank = childData.filter(n => n.mappingType != 'position_auto' && n.mappingType != 'position_manual' && n.categoryType === 'rank' && n.parentId === tableId);

    let childDataCommon = [];
    childDataCommon = childData.filter(n => n.mappingType != 'position_auto' && n.mappingType != 'position_manual' && n.categoryType != 'rank' && n.categoryType != 'favorite' && n.parentId === tableId);

    let updatedData = [];
    updatedData = tableData.filter(row => row && row.parentId != tableId);
    childData = [...childDataPositionAuto, ...childDataPositionManual, ...childDataFavorite, ...childDataRank, ...childDataCommon];
    let indexCount = 1;
    childData.map((element, index) => (element.categoryOrder = index + indexCount));
    setTableData([...updatedData, ...childData]);
  };

  const commonColumn = [
    {
      title: 'tabOrder',
      field: 'tabOrder',
      width: 100,
    },
    {
      title: 'tabCode',
      field: 'tabCode',
      width: 150,
    },
    {
      title: 'locationType',
      field: 'locationType',
      width: 150,
    },
    {
      title: 'skeletonType',
      field: 'skeletonType',
      width: 150,
    },
    {
      title: 'themeApply',
      render: n => n.tabId !== -1 && <Switch checked={n.themeApply === 'Y' ? true : false} disabled inputProps={{ 'aria-label': 'secondary checkbox' }} />,
      width: 120,
      id: 'themeApply',
    },
    {
      title: 'name',
      //eslint-disable-next-line react/display-name
      render: data => (
        <Link className="link" to={`/HOME-APP/detail/${param.status}/${data.parentId ? `category/${data.parentId}/${data.categoryId}` : `tab/${data.tabId}/0`}`}>
          {data.name}
        </Link>
      ),

      width: 250,
    },
    {
      title: 'Random',
      render: n => n.categoryFlag === 'Y' && <Fragment>{n.tabId !== -1 ? <Switch checked={n.randomOrderFlag === 'Y'} inputProps={{ 'aria-label': 'secondary checkbox' }} onChange={event => handleRandomFlag(event, n)} /> : n.fixFlag}</Fragment>,
      width: 100,
    },
  ];

  let column =
    param.status !== 'Saved'
      ? commonColumn
      : [
          ...commonColumn,
          {
            title: 'action',
            // eslint-disable-next-line react/display-name
            render: n => (
              <div>
                {n.parentId ? (
                  n.mappingType ? (
                    <React.Fragment>
                      <Link to={`/HOME-APP/${n.mappingType === 'manual' ? 'manual' : n.mappingType === 'slider' ? 'slider' : n.mappingType === 'carousel' ? 'carousel' : 'automatic'}Organizing/${n.categoryId}`}>
                        <Button className={'btn_size_tbl btn_color3'}>Organize</Button>
                      </Link>
                      <TabCategoryDelete deleteCategory={deleteCategory} categoryCode={n.categoryCode} id={n.categoryId} tabCode={n.tabCode} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <TabCategoryDelete deleteCategory={deleteCategory} categoryCode={n.categoryCode} id={n.categoryId} tabCode={n.tabCode} />
                    </React.Fragment>
                  )
                ) : (
                  n.categoryFlag === 'Y' && (
                    <React.Fragment>
                      <TabCategoryAdd tabId={n.tabId} maxCategoryCode={maxCategoryCode} defaultReOrderCategoryRow={defaultReOrderCategoryRow} updateTabOrder={updateTabOrder} />
                      <Link to={`/HOME-APP/${n.tabId}/schedule`}>
                        <Button className={'btn_size_tbl btn_color3'}>Schedule</Button>
                      </Link>
                    </React.Fragment>
                  )
                )}
              </div>
            ),
            width: 260,
          },
          {
            title: 'enable',
            // eslint-disable-next-line react/display-name
            render: n => (
              <div>
                {(!n.categoryCode || n.tabId > -1) && (
                  <React.Fragment>
                    <Switch checked={n.useFlag === 'Y' ? true : false} inputProps={{ 'aria-label': 'secondary checkbox' }} onChange={() => setDisableMessage(n)} />
                    <Button
                      className={'btn_size_tbl btn_color3'}
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        setDeleteMessage(n);
                      }}>
                      DELETE
                    </Button>
                  </React.Fragment>
                )}
              </div>
            ),
            width: 250,
          },
        ];

  const renderNestedTable = rowData => {
    const childRows = tableData.filter(row => {
      return row && row.parentId && row.parentId === rowData.tabId;
    });
    const filteredChildRows = childRows.filter(n => (param.status.charAt(0) === 'S' ? n : n.useFlag === 'Y'));
    if (filteredChildRows.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        <div style={{ display: rowData.tabCode === 'T-LIVE' ? '' : 'none' }}>
          <Button
            className="btn_size_tbl btn_color3"
            style={{ width: '10%', marginLeft: '90%' }}
            onClick={() => {
              defaultCategoryOrder(rowData.tabId);
            }}>
            Default Order
          </Button>
        </div>
        <div className="tbl_wrap channel_conf" style={{ width: '100%', marginLeft: '0%' }}>
          <MaterialTable
            title=""
            columns={[
              /*
              {
                title: 'categoryOrder',
                field: 'categoryOrder',
                width: 130,
              },
              */
              {
                title: 'categoryCode',
                field: 'categoryCode',
                width: 130,
              },
              {
                title: 'name',
                // eslint-disable-next-line react/display-name
                render: data => (
                  <Link className="link" to={`/HOME-APP/detail/${param.status}/${data.parentId ? `category/${data.parentId}/${data.categoryId}` : `tab/${data.tabId}/0`}`}>
                    {data.name}
                  </Link>
                ),
                width: 150,
              },
              {
                title: 'contentType',
                render: n => (n.contentType && n.contentType.split(',').length > 1) ?  n.contentType.split(',').join('\n') : n.contentType,
                width: 120,
              },
              {
                title: 'mappingType',
                field: 'mappingType',
                width: 120,
              },
              {
                title: 'categoryType',
                field: 'categoryType',
                width: 140,
              },
              {
                title: 'orderType',
                render: n => n.mappingType === 'auto' && n.orderType,
                width: 110,
              },
              {
                title: 'rule',
                render: n => n.mappingType === 'auto' && n.rule,
                width: 150,
              },
              {
                title: 'cpList',
                render: n => n.mappingType === 'auto' && n.cpList,
                width: 150,
              },
              {
                title: 'includeExclusive',
                render: n => n.mappingType === 'auto' && (n.includeExclusiveFlag === 'Y' ? 'Y' : 'N'),
                width: 110,
              },
              {
                title: 'action',
                hidden: param.status !== 'Saved',
                // eslint-disable-next-line react/display-name
                render: n => (
                  <div>
                    {n.parentId ? (
                      n.mappingType ? (
                        <React.Fragment>
                          <Link to={`/HOME-APP/${n.mappingType === 'manual' || n.mappingType === 'position_manual' ? 'manual' : n.mappingType === 'slider' ? 'slider' : n.mappingType === 'carousel' ? 'carousel' : 'automatic'}Organizing/${n.categoryId}`}>
                            <Button className={'btn_size_tbl btn_color3'}>Organize</Button>
                          </Link>
                          <TabCategoryDelete deleteCategory={deleteCategory} categoryCode={n.categoryCode} id={n.categoryId} tabCode={rowData.tabCode} />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <TabCategoryDelete deleteCategory={deleteCategory} categoryCode={n.categoryCode} id={n.categoryId} tabCode={rowData.tabCode} />
                        </React.Fragment>
                      )
                    ) : (
                      n.categoryFlag === 'Y' && (
                        <React.Fragment>
                          <TabCategoryAdd tabId={n.tabId} maxCategoryCode={maxCategoryCode} />
                          <Link to={`/HOME-APP/${n.tabId}/schedule`}>
                            <Button className={'btn_size_tbl btn_color3'}>Schedule</Button>
                          </Link>
                        </React.Fragment>
                      )
                    )}
                  </div>
                ),
                width: 180,
              },
            ]}
            data={filteredChildRows}
            options={{
              showTextRowsSelected: false,
              isLoading: true,
              search: false,
              paging: false,
              showEmptyDataSourceMessage: false,
              rowStyle: rowData => ({
                backgroundColor: rowData.change ? '#fef5f8' : '#FFF',
                color: rowData.change ? '#d8295c' : '#484848',
              }),
              tableLayout: 'fixed',
            }}
            components={{
              Row: props => (
                <MTableBodyRow
                  {...props}
                  icons={{
                    DetailPanel: () => {
                      const childRows = tableData.filter(row => row.parentId === props.data.tabId);
                      const filteredChildData = childRows.filter(n => (param.status.charAt(0) === 'S' ? n : n.useFlag === 'Y'));
                      if (filteredChildData.length === 0) {
                        return null; // No subCommonColumn data, hide the expand icon
                      }
                      return <ChevronRightIcon />;
                    },
                  }}
                  draggable="true"
                  onDragStart={e => {
                    DrageState.row = props.data.tableData.id;
                    DrageState.srtart = e.target.getBoundingClientRect().top;
                  }}
                  onDragEnter={e => {
                    e.preventDefault();
                    if (props.data.tableData.id !== DrageState.row) {
                      DrageState.dropIndex = props.data.tableData.id;
                      DrageState.position = (e.target.parentElement.getBoundingClientRect().top + e.target.parentElement.getBoundingClientRect().bottom) / 2;
                      document.getElementById('popup').style.display = 'block';
                    }
                    DrageState.end = e.target.parentElement.getBoundingClientRect().top;
                    if (DrageState.srtart == DrageState.end) {
                      document.getElementById('popup').style.display = 'none';
                    }
                  }}
                  onDragOver={e => {
                    e.preventDefault();
                    DrageState.dragPosition = e.clientY;
                    document.getElementById('popup').clientY = e.clientY;

                    if (DrageState.position >= DrageState.dragPosition - 30 && DrageState.position <= DrageState.dragPosition + 30) {
                      document.getElementById('popup').innerHTML = 'Swap';
                      e.dataTransfer.dropEffect = 'move';
                    } else {
                      document.getElementById('popup').innerHTML = 'Insert';
                      e.dataTransfer.dropEffect = 'copy';
                    }
                    DrageState.type = document.getElementById('popup').innerHTML;
                  }}
                  onDragEnd={e => {
                    if (param.status.charAt(0) === 'S' && DrageState.dropIndex !== -1 && DrageState.srtart != DrageState.end) {
                      let childData = [];
                      childData = tableData.filter(row => row && row.parentId && row.parentId === props.data.parentId);
                      childData = childData.filter(n => (param.status.charAt(0) === 'S' ? n : n.useFlag === 'Y'));
                      if (childData[DrageState.row].categoryType === 'top') {
                        if (childData[DrageState.dropIndex].categoryType === 'top') {
                          reOrderCategoryRow(DrageState.row, DrageState.dropIndex, DrageState.type, props.data.parentId, rowData);
                        } else {
                          reOrderCategoryRow(DrageState.row, DrageState.dropIndex, 'Insert', props.data.parentId, rowData);
                        }
                      } else if (childData[DrageState.row].categoryType != 'top') {
                        if (childData[DrageState.dropIndex].categoryType != 'top') {
                          reOrderCategoryRow(DrageState.row, DrageState.dropIndex, DrageState.type, props.data.parentId, rowData);
                        } else {
                          reOrderCategoryRow(DrageState.row, DrageState.dropIndex, 'Insert', props.data.parentId, rowData);
                        }
                      }
                    }
                    DrageState.row = -1;
                    DrageState.dropIndex = -1;
                    DrageState.position = -1;
                    DrageState.dragPosition = -1;
                    DrageState.type = '';
                    DrageState.srtart = -1;
                    DrageState.end = -1;
                    document.getElementById('popup').style.display = 'none';
                  }}
                />
              ),
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  return (
    <div>
      <React.Fragment>
        <HomeAppFilter
          regionList={regionList}
          regionPlatformList={regionPlatformList}
          deviceType={param.deviceType}
          handleChangeDeviceType={e => {
            setParamAction(e.target.value, getRegion(e.target.value), param.status, gePtlatformVersion(e.target.value, getRegion(e.target.value)));
          }}
          region={param.region}
          handleChangeRegion={e => {
            setParamAction(param.deviceType, e.target.value, param.status, gePtlatformVersion(param.deviceType, e.target.value));
          }}
          platformVersion={param.platformVersion}
          handleChangePlatformVersion={e => {
            setParamAction(param.deviceType, param.region, param.status, e.target.value);
          }}
          status={param.status}
          handleChangeStatus={e => {
            setParamAction(param.deviceType, param.region, e.target.value, param.platformVersion);
          }}
        />
        <div className="btn_set_wrap search_wrap">
          <UpdateButton />
          <ApplyButton />
          {param.status.charAt(0) === 'S' && (
            <Button
              color="secondary"
              variant="contained"
              className={'btn_color2'}
              onClick={() => {
                setOpenPop(true);
                setCopy(copyData);
              }}>
              COPY HOME-APP DATA
            </Button>
          )}
        </div>
        <div className={'tbl_wrap'}>
          <div className={'btn_set_wrap tbl_top1'}>
            <Button color="secondary" variant="contained" onClick={handleExpandAll}>
              Expand All
            </Button>
            <Button color="secondary" variant="contained" onClick={handleCollapseAll}>
              Collapse All
            </Button>
            {param.status.charAt(0) === 'S' && (
              <Button
                color="secondary"
                variant="contained"
                className={'btn_color2'}
                onClick={() => {
                  showAddTabStatus();
                  setUseTab(defaultTab);
                }}>
                Create Tab
              </Button>
            )}
            {param.status.charAt(0) === 'S' && (
              <Button
                color="secondary"
                variant="contained"
                className={'btn_color2'}
                //disabled={!enableSaveOrder}
                onClick={() => {
                  saveTabOrder();
                }}>
                Save Order
              </Button>
            )}
            {param.status.charAt(0) === 'S' && (
              <Button
                color="secondary"
                variant="contained"
                className={'btn_color2'}
                //disabled={!enableSaveOrder}
                onClick={() => {
                  clearCategoryOrder();
                }}>
                Clear Order
              </Button>
            )}
          </div>
          <span id="popup"></span>
          <MaterialTable
            tableRef={tableRef}
            title=" " //없으면 Table Title이 default title로 나옴
            columns={column}
            data={data}
            detailPanel={rowData => {
              return renderNestedTable(rowData);
            }}
            //onTreeExpandChange={(row, rows) => console.log("rows", row.tabId + "_" + rows)}
            options={{
              paging: false,
              rowStyle: rowData => {
                return { backgroundColor: rowData.categoryOrder === undefined ? /*=isTab?*/ '#edf2ff' /*gray*/ : '#fff' /*white*/ };
              },
              showDetailPanelIcon: false,
            }}
            components={{
              Row: props => (
                <MTableBodyRow
                  {...props}
                  icons={{
                    DetailPanel: () => {
                      const childRows = tableData.filter(row => row.parentId === props.data.tabId);
                      const filteredChildData = childRows.filter(n => (param.status.charAt(0) === 'S' ? n : n.useFlag === 'Y'));
                      if (filteredChildData.length === 0) {
                        return null; // No subCommonColumn data, hide the expand icon
                      }
                      return (
                        <ChevronRightIcon
                          onClick={event => {
                            if (typeof props.data.tableData.showDetailPanel != 'undefined') {
                              addExpand(props.data.tabId, false);
                            } else {
                              addExpand(props.data.tabId, true);
                            }
                          }}
                        />
                      );
                    },
                  }}
                  draggable="true"
                  onDragStart={e => {
                    DrageState.row = props.data.tableData.id;
                    DrageState.srtart = e.target.getBoundingClientRect().top;
                  }}
                  onDragEnter={e => {
                    e.preventDefault();
                    if (props.data.tableData.id !== DrageState.row) {
                      DrageState.dropIndex = props.data.tableData.id;
                      DrageState.position = (e.target.parentElement.getBoundingClientRect().top + e.target.parentElement.getBoundingClientRect().bottom) / 2;
                      document.getElementById('popup').style.display = 'block';
                    }
                    DrageState.end = e.target.parentElement.getBoundingClientRect().top;
                    if (DrageState.srtart == DrageState.end) {
                      document.getElementById('popup').style.display = 'none';
                    }
                  }}
                  onDragOver={e => {
                    e.preventDefault();
                    DrageState.dragPosition = e.clientY;
                    document.getElementById('popup').clientY = e.clientY;

                    if (DrageState.position >= DrageState.dragPosition - 30 && DrageState.position <= DrageState.dragPosition + 30) {
                      document.getElementById('popup').innerHTML = 'Swap';
                      e.dataTransfer.dropEffect = 'move';
                    } else {
                      document.getElementById('popup').innerHTML = 'Insert';
                      e.dataTransfer.dropEffect = 'copy';
                    }
                    DrageState.type = document.getElementById('popup').innerHTML;
                  }}
                  onDragEnd={e => {
                    if (param.status.charAt(0) === 'S' && DrageState.dropIndex !== -1 && DrageState.srtart != DrageState.end) {
                      if (tableData[DrageState.row].tabCode) {
                        if (tableData[DrageState.dropIndex].locationType === tableData[DrageState.row].locationType) {
                          reOrderRow(DrageState.row, DrageState.dropIndex, DrageState.type);
                        } else {
                          reOrderRow(DrageState.row, DrageState.dropIndex, 'Insert');
                        }
                      }
                    }
                    DrageState.row = -1;
                    DrageState.dropIndex = -1;
                    DrageState.position = -1;
                    DrageState.dragPosition = -1;
                    DrageState.type = '';
                    DrageState.srtart = -1;
                    DrageState.end = -1;
                    document.getElementById('popup').style.display = 'none';
                  }}
                />
              ),
            }}
          />
        </div>
        <Dialog open={openPop} className={'pop_wrap'}>
          <TabCategoryCopy data={copy} handleInputChange={handleInputChange} handleSubmit={handleCopySubmit} handleClose={() => setOpenPop(false)} regionList={regionList} regionPlatformList={regionPlatformList} handleInputNumberChange={handleInputNumberChange} />
        </Dialog>
        <Dialog open={tabAddOpen} className={'pop_wrap'}>
          <TabAdd data={useTab} handleTabInputChange={handleTabInputChange} handleCreateSubmit={handleCreateSubmit} handleTabClose={() => hideAddTabStatus()} regionList={regionList} regionPlatformList={regionPlatformList} handleTabInputNumberChange={handleTabInputNumberChange} />
        </Dialog>
        {!useTab.isAlert && (
          <CommonDialog
            open={tabChangeTpye.disable || tabChangeTpye.delete}
            description={description}
            handleCancel={() => {
              setTabChangeTpye({ ...defaultChangeTpye, disable: false, delete: false });
            }}
            handleOk={allHandleOk}
          />
        )}
        {useTab.isAlert && (
          <ChannelMappingAlert
            alert={{
              message: description,
              open: useTab.isAlert,
              closeAction: closeAction,
            }}
          />
        )}
      </React.Fragment>
    </div>
  );
}
TabCategory.propTypes = {
  regionList: PropTypes.array.isRequired,
  regionPlatformList: PropTypes.array.isRequired,
  param: PropTypes.object.isRequired,
  setParamAction: PropTypes.func.isRequired,
  tabCategory: PropTypes.array.isRequired,
  getTabCategoryAction: PropTypes.func.isRequired,
  updateTabRandomFlag: PropTypes.func.isRequired,
  copyTabCategory: PropTypes.func.isRequired,
  addTab: PropTypes.func.isRequired,
  tabDisable: PropTypes.func.isRequired,
  tabDelete: PropTypes.func.isRequired,
  tabAddOpen: PropTypes.bool.isRequired,
  setExpandTab: PropTypes.func.isRequired,
  tabExpand: PropTypes.object.isRequired,
  updateTabOrder: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  regionList: state.homeApp.regionList,
  regionPlatformList: state.homeApp.regionPlatformList,
  param: state.homeApp.param,
  tabCategory: state.homeApp.tabCategory,
  tabExpand: state.homeApp.tabExpand,
  tabAddOpen: state.popUp.tabAddPopUp,
  feedMappSdk: (state.displayRules.depthByRegion && state.displayRules.depthByRegion.feedMappSdk) || [],
});
const mapDispatchToProps = dispatch => ({
  setParamAction(deviceType, region, status, platformVersion) {
    dispatch(setParamAction(deviceType, region, status, platformVersion));
  },

  updateTabRandomFlag(tabId, randomFlag, param) {
    dispatch(updateTabRandomStatus(tabId, randomFlag, param));
  },

  copyTabCategory: data => dispatch(copyTabCategoryAction(data)),

  addTab: data => dispatch(addTabAction(data)),

  tabDisable: data => dispatch(tabDisableAction(data)),

  tabDelete: data => dispatch(tabDeletebyIdxAction(data)),

  showAddTabStatus: () => dispatch(showTabCreatePop()),

  hideAddTabStatus: () => dispatch(hideTabCreatePop()),

  setExpandTab: (key, value) => dispatch(setExpandTabAction(key, value)),
  updateTabOrder: (data, deviceType, region, platformVersion, status) => dispatch(updateTabOrder(data, deviceType, region, platformVersion, status)),
  getFeedmapSdksByRegion(region) {
    dispatch(getFeedmapSdksByRegion(region));
  },
  getTabCategoryAction(deviceType, region, platformVersion, status) {
    dispatch(getTabCategoryAction(deviceType, region, platformVersion, status));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TabCategory));
