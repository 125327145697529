import { combineReducers } from 'redux';
import countries from './countries';
import allCountries from './allCountries';
import allCps from './allCps';
import platforms from './platforms';
import regionCountries from './regionCountries';

export default combineReducers({
  countries,
  allCountries,
  allCps,
  platforms,
  regionCountries,
});
