import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { getMonitorPreviewDataAction } from '../../actions/homeAppActions';
import LoadingIndicator from '../../common/LoadingIndicator';

const useStyles = makeStyles(() => ({
    cardContent: {
        padding: '5px 0px',
    },
    appBar: {
        backgroundColor: '#ae1a49 !important'
    },
    tabs: {

    },
    indicator: {
        backgroundColor: 'white',
    },
    category: {
        display: 'grid',
        backgroundColor: 'white',
        border: '1px solid #5c5c5c',
        borderRadius: '4px',
        padding: '10px',
        marginBottom: '5px',
        marginTop: '5px'
    },
    noContents: {
        marginLeft: '15px'
    },
    errorData: {
        color: 'red',
        border: '1px solid red',
        padding: '10px',
    },
    catDivider: {
        marginBottom: '10px',
    },
    results: {
        marginTop: '10px',
    }
}));

function TabCategoryList({ previewData, showContent, monitorPreview, monitorPreviewData, getMonitorPreviewData }) {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    useEffect(() => {
        getMonitorPreviewData(previewData);
    }, []);

    const formatedResult = () => {
        return <Fragment>
            <AppBar position="static" className={classes.appBar}>
                <Tabs value={tabValue} onChange={handleTabChange}
                    className={classes.tabs}
                    classes={{
                        indicator: classes.indicator
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs monitor-preview-tabs">
                    {JSON.parse(monitorPreviewData.result).tabs && JSON.parse(monitorPreviewData.result).tabs.map((tab, index) => (
                        <Tab key={tab.tabId} label={tab.tabId} id={`wrapped-tab-${index}`} className="btn_color3" aria-controls={`wrapped-tabpanel-${index}`} />
                    ))}
                </Tabs>
            </AppBar>
            {JSON.parse(monitorPreviewData.result).tabs && JSON.parse(monitorPreviewData.result).tabs.map((tab, tabIndex) => (
                <Fragment key={tabIndex}>
                    <div
                        role="tabpanel"
                        hidden={tabValue !== tabIndex}
                        id={`monitor-preview-tabpanel-${tabIndex}`}
                        aria-labelledby={`monitor-preview-tab-${tabIndex}`}
                    >
                        <Box>
                            {
                                tab.categories && tab.categories.map(category => (
                                    <div className={classes.category} key={category.categoryCode}>
                                        <Typography variant="h6" display="block" gutterBottom>
                                            {category.categoryName}
                                        </Typography>
                                        <Divider className={classes.catDivider} />
                                        <Grid container spacing={2}>
                                            {
                                                category.contents && category.contents.map(content => (
                                                    <Grid item md={2} key={content.contentId}>
                                                        <Card >
                                                            <CardActionArea>
                                                                <CardMedia
                                                                    component="img"
                                                                    image={content.thumbnailUrl}
                                                                    alt={content.title}
                                                                    onClick={() => showContent(content)}
                                                                />
                                                                <CardContent className={classes.cardContent}>
                                                                    <Typography gutterBottom variant="button" component="div">
                                                                        {content.title}
                                                                    </Typography>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                    </Grid>
                                                ))
                                            }
                                            {
                                                category.contents && category.contents.length === 0 &&
                                                <Typography variant="subtitle1" gutterBottom className={classes.noContents}>
                                                    Contents not avaliable.
                                                </Typography>
                                            }
                                        </Grid>
                                    </div>
                                ))
                            }
                            {
                                !tab.categories &&
                                <Typography variant="subtitle1" gutterBottom className={classes.category}>
                                    Categories not avaliable.
                                </Typography>
                            }
                        </Box>
                    </div>
                </Fragment>
            ))}
            {
                (JSON.parse(monitorPreviewData.result).tabs === null) && 'Tabs not avaliable.'
            }
        </Fragment>;
    }

    return (
        <Fragment>
            {
                monitorPreview.loading ?
                    <LoadingIndicator />
                    :
                    (
                        monitorPreviewData.status ?
                        formatedResult()
                            : (<Fragment>
                                <div className={classes.errorData}>
                                    {(monitorPreviewData.errorMessage != null)
                                        ? monitorPreviewData.errorMessage : `${monitorPreviewData.result}`}

                                </div>
                                <div className={classes.results}>
                                    {(monitorPreviewData.errorMessage != null)
                                        ?
                                           formatedResult()
                                        : monitorPreviewData.errorMessage}
                                </div>
                             </Fragment>)
                    )
            }

        </Fragment >
    );
}


TabCategoryList.propTypes = {
    previewData: PropTypes.object.isRequired,
    showContent: PropTypes.func.isRequired,
    monitorPreview: PropTypes.object.isRequired,
    monitorPreviewData: PropTypes.object.isRequired,
    getMonitorPreviewData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    monitorPreview: state.homeApp.monitorPreview,
    monitorPreviewData: state.homeApp.monitorPreview.data,
});

const mapDispatchToProps = dispatch => ({
    getMonitorPreviewData: (id) => dispatch(getMonitorPreviewDataAction(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TabCategoryList);