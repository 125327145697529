import React from 'react';
import PropTypes from 'prop-types';
//import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

export function AdminFilter({ role, handleChangeRole, id, handleInputChangeId }) {
  return (
    <>
      <div className="filter_area">
        <FormControl variant="outlined">
          <InputLabel>Role</InputLabel>
          <Select value={role} onChange={handleChangeRole}>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="SUPER_ADMIN">SUPER_ADMIN</MenuItem>
            <MenuItem value="ADMIN">ADMIN</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="ID" //focus안줬을때 mandatory text
          value={id}
          onChange={e => handleInputChangeId(e)}
        />
      </div>
    </>
  );
}

AdminFilter.propTypes = {
  role: PropTypes.string.isRequired,
  handleChangeRole: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  handleInputChangeId: PropTypes.func.isRequired,
};

// const mapStateToProps = (state) => ({
//   isRoleAdmin: state.currentUser.isRoleAdmin,
// });
// const mapDispatchToProps = (dispatch) => ({
//   getProvidersAdminAction() {
//     dispatch(getProvidersAdminAction());
//   },
// });
//export default connect(mapStateToProps)(AdminFilter);
export default AdminFilter;
