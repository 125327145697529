import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { getChannelAction } from '../../../actions/channelChannelActions';
function ChannelView({ isRoleAdmin, history, getChannelAction, channel, ric, system, channelMapId, chanName, chanIdSearch, chanId, chanNo }) {
  useEffect(() => {
    getChannelAction(isRoleAdmin, system, ric, channelMapId, chanId);
  }, [chanId]);

  const handleClickList = () => {
    history.push(`/CHANNEL/channel/${ric}/${system}/${channelMapId}/${chanName}/${chanIdSearch}/${chanNo}`);
  };
  const handleClickEdit = () => {
    history.push(`/CHANNEL/channel/channelEdit/${ric}/${system}/${channelMapId}/${chanName}/${chanIdSearch}/${chanId}/${chanNo}`);
  };

  let imgUrl = '';
  if (channel.admLogoUrl) imgUrl = channel.admLogoUrl;
  else if (channel.logoUrl) imgUrl = channel.logoUrl;

  return (
    <div>
      <form noValidate autoComplete="off">
        <div className="tbl_wrap tbl_radius">
          <table className="tbl_row_typ1 tbl_channel">
            <colgroup>
              <col style={{ width: '13%' }} />
              <col style={{ width: '47%' }} />
              <col style={{ width: '13%' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <thead>
              <tr>
                <th>Channel ID</th>
                <td>{chanId}</td>
                <th>Channel No.</th>
                <td>{channel.chanNo}</td>
              </tr>
              <tr>
                <th>Channel Name</th>
                <td colSpan="3">{channel.chanName}</td>
              </tr>
              <tr>
                <th rowSpan={3}>Logo</th>
                <td rowSpan={3}>
                  <div className="logo_preview_wrap">
                    <div className="logo_white_area">
                      <div className="logo_img_area">
                        <img src={imgUrl} />
                      </div>
                    </div>
                    <div className="logo_black_area">
                      <div className="logo_img_area">
                        <img src={imgUrl} />
                      </div>
                    </div>
                  </div>
                </td>
                <th>Logo Width</th>
                <td>{channel.logoWidth}</td>
              </tr>
              <tr>
                <th>Logo Height</th>
                <td>{channel.logoHeight}</td>
              </tr>
              <tr>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>Log URL</th>
                <td colSpan={3}>{imgUrl}</td>
              </tr>
              <tr>
                <th>Last Updated</th>
                <td colSpan={3}>{channel.lastChgDate}</td>
              </tr>
            </thead>
          </table>
        </div>
      </form>
      <div className="btn_set_wrap">
        <div className="btn_set_left_cont">
          <Button variant="contained" className={'btn_color3'} onClick={handleClickList}>
            {' '}
            List{' '}
          </Button>
        </div>
        <Button variant="contained" className={'btn_color2'} onClick={() => handleClickEdit(chanId)}>
          {' '}
          Edit{' '}
        </Button>
      </div>
    </div>
  );
}
ChannelView.propTypes = {
  getChannelAction: PropTypes.func.isRequired,
  channel: PropTypes.any.isRequired,
  isRoleAdmin: PropTypes.any.isRequired,
  username: PropTypes.string,
  history: PropTypes.object.isRequired,
  ric: PropTypes.string.isRequired,
  system: PropTypes.string.isRequired,
  channelMapId: PropTypes.string.isRequired,
  chanName: PropTypes.string.isRequired,
  chanIdSearch: PropTypes.string.isRequired,
  chanId: PropTypes.string.isRequired,
  chanNo: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  channel: state.channelChannel.channel,
  username: state.currentUser.username,
  isRoleAdmin: state.currentUser.isRoleAdmin,
});
const mapDispatchToProps = dispatch => ({
  getChannelAction(isRoleAdmin, system, ric, chanMapId, chanId) {
    dispatch(getChannelAction(isRoleAdmin, system, ric, chanMapId, chanId));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChannelView));
