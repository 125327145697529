import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { DateRangePicker, DatePicker } from 'rsuite';
import "rsuite/dist/rsuite.css";
import moment from "moment";

let lastWeekDay;
let lastWeekEndDay;
let lastMonth;

export const getLastMonth = () => {
  lastMonth = moment().clone().subtract(1, 'month');

  return lastMonth.format('YYYY-MM');
}
export const getLastWeekDay = () => {
  const currentWeekStart = moment().clone().startOf('week');

  lastWeekDay = currentWeekStart.clone().subtract(7, 'days');

  lastWeekEndDay = lastWeekDay.clone().add(6, 'days');

  return lastWeekDay.format('YYYY-MM-DD');
};
export function DateFilter({ dateType, handleChange, handleChangeMonth, handleChangeWeek }) {
  const styles = { margin : 10 };
  return (
    <>
      <div className="filter_row">
        <div className="choice_month_typ">
          <FormControl component="fieldset">
            <RadioGroup aria-label="gender" name="gender1" value={dateType} onChange={handleChange}>
              <FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
              <DatePicker
                size="lg"
                defaultValue={moment(lastMonth).toDate()}
                oneTap
                format="yyyy-MM"
                onChange={handleChangeMonth}
                style={styles}
              />

              <FormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
              <DateRangePicker
                size="lg"
                defaultValue={[moment(lastWeekDay).toDate(), moment(lastWeekEndDay).toDate()]}
                oneTap
                showOneCalendar
                showWeekNumbers
                format="yyyy-MM-dd"
                hoverRange="week"
                onChange={handleChangeWeek}
                ranges={[]}
                style={styles}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </>
  );
}

DateFilter.propTypes = {
  dateType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeMonth: PropTypes.func.isRequired,
  handleChangeWeek: PropTypes.func.isRequired,
};

export default DateFilter;