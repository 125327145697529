import * as actions from '../../actions/actionTypes';

const initialState = '';

export default function message(state = initialState, action) {
  switch (action.type) {
    case actions.INIT_NEW_PROGRAM_RESULT:
      return initialState;
    case actions.SET_NEW_PROGRAM_RESULT:
      if (typeof action.payload.message !== 'undefined') {
        return action.payload.message;
      } else {
        return state;
      }
    default:
      return state;
  }
}
