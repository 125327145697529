const initialState = [];
export const notifications = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS_DATA':
      return action.payload;
    case 'ADD_NOTIFICATION_DATA':
      return [...state, action.payload];
    case 'UPDATE_NOTIFICATION_DATA':
      const notifications = state.slice();
      notifications.splice(
        state.findIndex(x => x.idx === action.payload.idx),
        1,
        action.payload,
      );
      return [...notifications];
    default:
      return state;
  }
};
