import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Breadcrumb from '../Breadcrumb';
import { BreadcrumbMap } from '../AppHeader/MenuList';
// const useStyles = makeStyles(theme => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300,
//   },
// }));
function PageTitle({ location, isAuthenticated }) {
  //const classes = useStyles();
  //console.log('[location]', location, '[isAuthenticated]', isAuthenticated);
  let item;
  let tempArr = location.pathname.split('/');
  let pathnameArr = [];
  let cnt = 0;

  if ((tempArr.length > 2) && !(tempArr.length > 5)) {
    cnt = 3;
  } else if (tempArr.length > 3){
    cnt = 4;
  }else {
    cnt = tempArr.length;
  }
  for (let j = 0; j < cnt; j++) {
    if (tempArr[j].trim()) {
      pathnameArr.push(tempArr[j]);
    }
  }
  //RECOMMEND
  let newPathName = '';
  if (tempArr && tempArr[1] === 'PROMOTION') {
    for (let i = 0; i < pathnameArr.length; i++) {
      newPathName = newPathName + '/' + pathnameArr[i];
    }
  } else if (tempArr && tempArr[1] === 'RECOMMEND') {
    for (let i = 0; i < pathnameArr.length; i++) {
      newPathName = newPathName + '/' + pathnameArr[i];
    }
  } else {
    // 레벨 3이상은 :id같은 값이다.
    // 예 : /CHANNEL/channel/channelView/159
    for (let i = 0; i < pathnameArr.length; i++) {
      newPathName = newPathName + '/' + pathnameArr[i];
    }
    //
  }

  if (isAuthenticated && (location.pathname !== '/'
    && location.pathname.toString().indexOf('/ISS') === -1
    && location.pathname.toString().indexOf('/iss') === -1)) {
    item = (
      <div className="cont_head">
        <Typography component="h2" variant="h2" align="left" color="textPrimary" gutterBottom>
          {BreadcrumbMap[newPathName]}
        </Typography>
        <Breadcrumb />
      </div>
    );
  } else {
    item = <div />;
  }
  return item; //{{item} Error: Objects are not valid as a React child (found: object with keys {item}). If you meant to render a collection of children, use an array instead.
}
PageTitle.propTypes = {
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  isAuthenticated: state.currentUser.isAuthenticated,
});
// const mapDispatchToProps = dispatch => ({
//   setAuthenticatedAction(value) {
//     dispatch(setAuthenticatedAction(value));
//   },
// });
export default withRouter(connect(mapStateToProps, null)(PageTitle));
