import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { programGenre } from '../../../constants/genre';

export default function ProgramTable({ prog }) {
  let defaultLang = prog.multiLanguageList && prog.multiLanguageList.find(item => item.defaultFlag === true);
  if (!defaultLang) defaultLang = {};

  return (
    <div>
      <TableContainer component={Paper} className={'tbl_wrap'}>
        {prog && (
          <Table className={'tbl_row_typ1 tbl_program'} aria-label="spanning table">
            <TableHead>
              {prog.providerId ? (
                <React.Fragment>
                  <TableRow>
                    <TableCell style={{ width: '20%' }}>CP Code</TableCell>
                    <td>{prog.providerId}</td>
                    <td rowSpan={4} colSpan={2} style={{ width: '35%' }}>
                      <img className={'tbl_img_wrap'} alt="invalid" src={prog.imageUrl} title={prog.imageUrl} style={{ Width: 400, maxHeight: 240, border: '1px solid #cbccce' }} />
                    </td>
                  </TableRow>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <td>{prog.programId}</td>
                  </TableRow>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TableRow>
                    <TableCell style={{ width: '20%' }}>ID</TableCell>
                    <td>{prog.programId}</td>
                    <td rowSpan={3} colSpan={2} style={{ width: '35%' }}>
                      <img className={'tbl_img_wrap'} alt="invalid" src={prog.imageUrl} title={prog.imageUrl} style={{ Width: 400, maxHeight: 240, border: '1px solid #cbccce' }} />
                    </td>
                  </TableRow>
                </React.Fragment>
              )}

              <TableRow>
                <TableCell>Title</TableCell>
                <td>{defaultLang.title}</td>
              </TableRow>
              <TableRow>
                <TableCell>Description</TableCell>
                <td>{defaultLang.description}</td>
              </TableRow>

              <TableRow>
                <TableCell>Resolution</TableCell>
                <td>{prog.resolution}</td>

                <TableCell>Duration(sec)</TableCell>
                <td>{prog.duration}</td>
              </TableRow>
              <TableRow>
                <TableCell>Season No.</TableCell>
                <td>{prog.seasonNo}</td>
                <TableCell>Episode No.</TableCell>
                <td>{prog.episodeNo}</td>
              </TableRow>
              <TableRow>
                <TableCell>Season Title</TableCell>
                <td>{defaultLang.seasonTitle}</td>
                <TableCell>Episode Title</TableCell>
                <td>{defaultLang.episodeTitle}</td>
              </TableRow>
              <TableRow>
                <TableCell>Genre</TableCell>
                <td colSpan={3}>{prog.genreList && prog.genreList.map(item => '1st: ' + programGenre[item.firstGenreCode] + ' | 2nd: ' + (item.secondGenreCode ? programGenre[item.secondGenreCode] : '')).join(' , ')}</td>
              </TableRow>
              <TableRow>
                <TableCell>Keywords</TableCell>
                <td colSpan={3}>{(prog.keywords || []).join(', ')}</td>
              </TableRow>

              <TableRow>
                <TableCell>Rating</TableCell>
                <td colSpan={3}>{prog.ratingList && prog.ratingList.map(item => item.ratingArea + ': ' + item.ratingCode).join(' | ')}</td>
              </TableRow>
              {/*
              <TableRow>
                <TableCell>Media Static URL</TableCell>
                <td colSpan={3}>{prog.mediaStaticUrl}</td>
              </TableRow>
              <TableRow>
                <TableCell>Media Onetime URL</TableCell>
                <td colSpan={3}>{prog.mediaOnetimeUrl}</td>
              </TableRow>
              */}
              <TableRow>
                <TableCell>MultiAudio-Flag</TableCell>
                <td colSpan={3}>{prog.multiAudioFlag}</td>
              </TableRow>
              <TableRow>
                <TableCell>Caption-Flag</TableCell>
                <td colSpan={3}>{prog.captionFlag}</td>
              </TableRow>
              <TableRow>
                <TableCell>Caption</TableCell>
                <td colSpan={3}>{prog.captionList && prog.captionList.map(item => 'Language: ' + item.captionLanguage + ' | Url: ' + item.captionUrl + ' | Force-Flag: ' + item.forceFlag).join(' , ')}</td>
              </TableRow>
              <TableRow>
                <TableCell>Last Updated</TableCell>
                <td colSpan={3}>{prog.lastUpdate}</td>
              </TableRow>
            </TableHead>
          </Table>
        )}
      </TableContainer>
      <ExpansionPanel className={'multi_language_wrap'}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          className={'btn_multi_language'}
          //aria-controls="panel1a-content"
          //id="panel1a-header"
        >
          View Multilingual Information ({prog.multiLanguageList.length})
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <TableContainer component={Paper} className={'tbl_wrap'}>
            <Table className={'tbl_row_typ1 tbl_program'} aria-label="spanning table">
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: 'auto' }} />
              </colgroup>
              <TableHead>
                {prog.multiLanguageList.map(item => (
                  <React.Fragment key={item.language}>
                    <TableRow>
                      <TableCell colSpan={2} className={'tit_language'}>
                        Language: {item.language}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <td>{item.title}</td>
                    </TableRow>
                    <TableRow>
                      <TableCell>Season title </TableCell>
                      <td>{item.seasonTitle}</td>
                    </TableRow>
                    <TableRow>
                      <TableCell>Episode title</TableCell>
                      <td>{item.episodeTitle}</td>
                    </TableRow>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <td>{item.description}</td>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableHead>
            </Table>
          </TableContainer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

ProgramTable.propTypes = {
  prog: PropTypes.object.isRequired,
};
