import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

function FixedDropDown(props) {
  const { items, label} = props;
  const [open, setOpen] = useState(false);

  const onBlur = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const onClick = e => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onSetLabel = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      <div tabIndex={0} className={classNames('ui-dropdown-type03', { on: open })} onBlur={e => onBlur(e)}>
        <div className="label" onClick={e => onClick(e)}>
          {label || ''}
        </div>
        <ul className={open ? 'on' : ''}>
          {items && items.map((item, index) => (
            <li key={index} onClick={(e) => onSetLabel(e, item)}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default FixedDropDown;