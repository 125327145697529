import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IssTable from '../common/IssTable';
import { detail_contents_tabledata } from './ContentsDetailData';
import PrimaryButton from '../common/PrimaryButton';
import CreateJiraModal from '../modal/CreateJira';
import Loading from '../common/Loading';
import { getHeaders } from '../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../constants/index';
import Alert from '../modal/Alert';
import ModalPopup from '../modal/ModalPopup';
import xlsx from 'json-as-xlsx';

function ContentsDetailCommon({ username, currentTab, currentItem, issueChangeCallback, isExcelDownloadFlag, currentFilter }) {
  const [isShowCreateJiraPopup, setShowCreateJiraPopup] = useState(false);
  const [isValueInitNeed, setValueInitNeed] = useState(false);
  const [isContentsErrorDisplay, setContentsErrorDisplay] = useState(false);
  const [isIssueGuideDisplay, setIssueGuideDisplay] = useState('init');
  const [issueCodeFetching, setIssueCodeFetching] = useState('init');
  const [selectedItem, setSelectedItem] = useState({ groupId: '', issueId: '', issueCode: '' });
  const [actionDetails, setActionDetails] = useState('');
  const [currentFocusData, setCurrentFocusData] = useState('');

  const [feedError, setFeedError] = useState([]);
  const [adminError, setAdminError] = useState([]);
  const [batchError, setBatchError] = useState([]);
  const [feedErrorFetching, setFeedErrorFetching] = useState('init');
  const [adminErrorFetching, setAdminErrorFetching] = useState('init');
  const [batchErrorFetching, setBatchErrorFetching] = useState('init');

  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');

  const [currentIssueData, setCurrentIssueData] = useState({});
  const [currentIssueDescription, setCurrentIssueDescription] = useState({});
  const [currentIssueRelatedHistory, setCurrentIssueRelatedHistory] = useState({});
  const [issueIdList, setIssueIdList] = useState([]);
  const [isCheckboxValueInit, setCheckboxValueInit] = useState(false);

  const [updatingIssueList, setUpdatingIssueList] = useState([]);

  const [isModalPopup, setModalPopup] = useState(false);
  const [modalPopupTitle, setModalPopupTitle] = useState('');

  const tabName = ['feed', 'admin', 'batch'];

  const maxLength = 1000;

  useEffect(() => {
    getManagementGroup();
  }, []);

  useEffect(() => {
    if (isValueInitNeed) {
      setIssueGuideDisplay('done');
      setIssueCodeFetching('done');
      setValueInitNeed(false);
      setCheckboxValueInit(false);
    }
  }, [isValueInitNeed]);

  useEffect(() => {
    if (currentTab === 0 && (!currentFilter || currentFilter.provider.length === 0)) {
      initAndRequest('feed');
    } else if (currentTab === 1) {
      initAndRequest('admin');
    } else if (currentTab === 2) {
      initAndRequest('batch');
    }
  }, [currentTab]);

  useEffect(() => {
    if (isExcelDownloadFlag) {
      if (updatingIssueList.length > 0) {
        const groupIdList = updatingIssueList.map(item => item.groupId);
        handleExcelDownload(groupIdList);
      } else {
        setAlertMessage(`Please select the issues you want to download`);
        onOpenAlert();
      }
    }
  }, [isExcelDownloadFlag]);

  useEffect(() => {
    if (currentTab === 0 && currentFilter && currentFilter.provider && currentFilter.provider.length > 0) {
      initAndRequest('feed');
    }
  }, [currentFilter]);

  useEffect(() => {
    if (currentItem && (currentItem.issueId !== '' || currentItem.groupId !== '')) {
      setContentsErrorDisplay(false);

      setIssueGuideDisplay('processing');
      setSelectedItem({ groupId: currentItem.groupId, issueId: currentItem.issueId, issueCode: currentItem.issueCode });
    }
  }, [currentItem]);

  useEffect(() => {
    if (selectedItem && (selectedItem.issueId !== '' || selectedItem.groupId !== '')) {
      let findItem = null;

      if (currentTab === 0 && feedError && feedError.length > 0) {
        getContentsIssueCode('feed', selectedItem.groupId);

        findItem = feedError.find(item => item.groupId === selectedItem.groupId);
        if (findItem) {
          let issueTitleList = new Set();

          findItem.values.map(item => {
            if (!issueTitleList[item.issueTitle]) {
              issueTitleList[item.issueTitle] = true;
            }
          });

          const causeAnalysis = Object.keys(issueTitleList).join(", ");

          detail_contents_tabledata.cp_issue_description.rowdata = [
            {
              row: [causeAnalysis, findItem.guide],
              groupId: findItem.groupId,
              issueStatus: findItem.values[0].status,
              issueAssign: findItem.values[0].issueAssignee
            },
          ];
          setCurrentIssueDescription(detail_contents_tabledata.cp_issue_description);
        }
      } else if (currentTab === 1 && adminError && adminError.length > 0) {
        getContentsIssueCode('admin', selectedItem.issueCode);
        findItem = adminError.find(item => item.issueId === selectedItem.issueId);
        if (findItem) {
          const additionalParameter = findItem.functionParameter.length > 40 ? `[ functionParameter : ` + findItem.functionParameter + ' ] [Cause Analysis : ' : '';
          const causeAnalysis = additionalParameter + findItem.errorMessage + (additionalParameter.length > 0 ? ' ]' : '');

          detail_contents_tabledata.organization_description.rowdata = [
            {
              row: [causeAnalysis, findItem.issueGuide],
              issueId: findItem.issueId,
              issueCode: findItem.issueCode,
              issueStatus: findItem.status,
              issueAssign: findItem.issueAssignee
            },
          ];
          setCurrentIssueDescription(detail_contents_tabledata.organization_description);
        }
      } else if (currentTab === 2 && batchError && batchError.length > 0) {
        getContentsIssueCode('batch', selectedItem.issueCode);
        findItem = batchError.find(item => item.issueId === selectedItem.issueId);
        if (findItem) {
          detail_contents_tabledata.deployment_description.rowdata = [
            {
              row: [findItem.errorMessage, findItem.issueGuide],
              issueId: findItem.issueId,
              issueCode: findItem.issueCode,
              issueStatus: findItem.status,
              issueAssign: findItem.issueAssignee
            },
          ];
          setCurrentIssueDescription(detail_contents_tabledata.deployment_description);
        }
      }

      setCurrentFocusData(selectedItem);
      setIssueGuideDisplay('done');
    }
  }, [selectedItem, feedError, adminError, batchError]);

  useEffect(() => {
    if (currentTab === 0 && feedError && feedError.length > 0) {
      let selectedData = undefined;

      if (currentItem && (currentItem.issueId !== '' || currentItem.groupId !== '')) {
        selectedData = feedError.find(item => item.groupId === currentItem.groupId);
      }

      if (selectedData) {
        const selectedRowdata = {
          row: [
            selectedData.groupId,
            selectedData.values[0].date.substring(2, 10),
            selectedData.countries,
            selectedData.cp + ' (' + selectedData.count + ')',
            selectedData.title,
            selectedData.values[0].status,
            selectedData.apis,
            selectedData.values[0].issueAssignee,
            selectedData.values[0].modifier,
            selectedData.values[0].jiraUrl,
            selectedData.values[0].actionDetails,
            selectedData.groupId,
          ],
          groupId: selectedData.groupId,
          issueStatus: selectedData.values[0].status,
          issueAssign: selectedData.values[0].issueAssignee,
          deeplinkFlag: true,
        };
        const othersRowdata = feedError
          .filter(item => item.groupId !== selectedData.groupId)
          .map(item => ({
            row: [
              item.groupId,
              item.values[0].date.substring(2, 10),
              item.countries,
              item.cp + ' (' + item.count + ')',
              item.title,
              item.values[0].status,
              item.apis,
              item.values[0].issueAssignee,
              item.values[0].modifier,
              item.values[0].jiraUrl,
              item.values[0].actionDetails,
              item.groupId,
            ],
            groupId: item.groupId,
            issueStatus: item.values[0].status,
            issueAssign: item.values[0].issueAssignee,
          }));

        detail_contents_tabledata.cp_issues.rowdata = [selectedRowdata, ...othersRowdata];
      } else {
        detail_contents_tabledata.cp_issues.rowdata = feedError.map(item => ({
          row: [
            item.groupId,
            item.values[0].date.substring(2, 10),
            item.countries,
            item.cp + ' (' + item.count + ')',
            item.title,
            item.values[0].status,
            item.apis,
            item.values[0].issueAssignee,
            item.values[0].modifier,
            item.values[0].jiraUrl,
            item.values[0].actionDetails,
            item.groupId,
          ],
          groupId: item.groupId,
          issueStatus: item.values[0].status,
          issueAssign: item.values[0].issueAssignee,
        }));
      }

      setCurrentIssueData(detail_contents_tabledata.cp_issues);
    } else if (currentTab === 1 && adminError && adminError.length > 0) {
      let selectedData = undefined;

      if (currentItem && currentItem.issueId !== '') {
        selectedData = adminError.find(item => item.issueId === currentItem.issueId);
      }

      if (selectedData) {
        const selectedRowdata = {
          row: [
            selectedData.issueId,
            selectedData.date.substring(2, 10),
            selectedData.priority,
            selectedData.country === 'All' ? 'ALL' : selectedData.country,
            selectedData.issueTitle,
            selectedData.status,
            selectedData.userName,
            selectedData.functionName,
            selectedData.functionParameter.length > 40 ? selectedData.functionParameter.substring(0, 40) + '...' : selectedData.functionParameter,
            selectedData.issueAssignee,
            selectedData.modifier,
            selectedData.jiraUrl,
            selectedData.actionDetails,
          ],
          issueId: selectedData.issueId,
          issueCode: selectedData.issueCode,
          issueStatus: selectedData.status,
          issueAssign: selectedData.issueAssignee,
          deeplinkFlag: true,
        };
        const othersRowdata = adminError
          .filter(item => item.issueId !== selectedData.issueId)
          .map(item => ({
            row: [
              item.issueId,
              item.date.substring(2, 10),
              item.priority,
              item.country === 'All' ? 'ALL' : item.country,
              item.issueTitle,
              item.status,
              item.userName,
              item.functionName,
              item.functionParameter.length > 40 ? item.functionParameter.substring(0, 40) + '...' : item.functionParameter,
              item.issueAssignee,
              item.modifier,
              item.jiraUrl,
              item.actionDetails,
            ],
            issueId: item.issueId,
            issueCode: item.issueCode,
            issueStatus: item.status,
            issueAssign: item.issueAssignee,
          }));

        detail_contents_tabledata.organization_issues.rowdata = [selectedRowdata, ...othersRowdata];
      } else {
        detail_contents_tabledata.organization_issues.rowdata = adminError.map(item => ({
          row: [
            item.issueId,
            item.date.substring(2, 10),
            item.priority,
            item.country === 'All' ? 'ALL' : item.country,
            item.issueTitle,
            item.status,
            item.userName,
            item.functionName,
            item.functionParameter.length > 40 ? item.functionParameter.substring(0, 40) + '...' : item.functionParameter,
            item.issueAssignee,
            item.modifier,
            item.jiraUrl,
            item.actionDetails,
          ],
          issueId: item.issueId,
          issueCode: item.issueCode,
          issueStatus: item.status,
          issueAssign: item.issueAssignee,
        }));
      }

      setCurrentIssueData(detail_contents_tabledata.organization_issues);
    } else if (currentTab === 2 && batchError && batchError.length > 0) {
      let selectedData = undefined;

      if (currentItem && currentItem.issueId !== '') {
        selectedData = batchError.find(item => item.issueId === currentItem.issueId);
      }

      if (selectedData) {
        const selectedRowdata = {
          row: [
            selectedData.issueId,
            selectedData.date.substring(2, 10),
            selectedData.priority,
            selectedData.issueTitle,
            selectedData.status,
            selectedData.jobName,
            selectedData.startDate.substring(2, 10),
            selectedData.endDate.substring(2, 10),
            selectedData.executionTime,
            selectedData.issueAssignee,
            selectedData.modifier,
            selectedData.jiraUrl,
            selectedData.actionDetails,
          ],
          issueId: selectedData.issueId,
          issueCode: selectedData.issueCode,
          issueStatus: selectedData.status,
          issueAssign: selectedData.issueAssignee,
          deeplinkFlag: true,
        };
        const othersRowdata = batchError
          .filter(item => item.issueId !== selectedData.issueId)
          .map(item => ({
            row: [
              item.issueId,
              item.date.substring(2, 10),
              item.priority,
              item.issueTitle,
              item.status,
              item.jobName,
              item.startDate.substring(2, 10),
              item.endDate.substring(2, 10),
              item.executionTime,
              item.issueAssignee,
              item.modifier,
              item.jiraUrl,
              item.actionDetails,
            ],
            issueId: item.issueId,
            issueCode: item.issueCode,
            issueStatus: item.status,
            issueAssign: item.issueAssignee,
          }));

        detail_contents_tabledata.deployment_issues.rowdata = [selectedRowdata, ...othersRowdata];
      } else {
        detail_contents_tabledata.deployment_issues.rowdata = batchError.map(item => ({
          row: [
            item.issueId,
            item.date.substring(2, 10),
            item.priority,
            item.issueTitle,
            item.status,
            item.jobName,
            item.startDate.substring(2, 10),
            item.endDate.substring(2, 10),
            item.executionTime,
            item.issueAssignee,
            item.modifier,
            item.jiraUrl,
            item.actionDetails,
          ],
          issueId: item.issueId,
          issueCode: item.issueCode,
          issueStatus: item.status,
          issueAssign: item.issueAssignee,
        }));
      }

      setCurrentIssueData(detail_contents_tabledata.deployment_issues);
    }

    if (feedErrorFetching === 'done' || adminErrorFetching === 'done' || batchErrorFetching === 'done') {
      setContentsErrorDisplay(true);
    }
  }, [feedError, adminError, batchError]);

  const OnOpenCreateJiraPopop = () => {
    if (updatingIssueList.length > 0) {

      let existJiraUrlList = [];

      if (currentTab === 0) {
        existJiraUrlList = updatingIssueList.filter(data => feedError.find(item => item.groupId === data.groupId && item.values[0].jiraUrl)).map(item => item.groupId);
      } else if (currentTab === 1) {
        existJiraUrlList = updatingIssueList.filter(data => adminError.find(item => item.issueId === data.issueId && item.jiraUrl)).map(item => item.issueId);
      } else if (currentTab === 2) {
        existJiraUrlList = updatingIssueList.filter(data => batchError.find(item => item.issueId === data.issueId && item.jiraUrl)).map(item => item.issueId);
      } else {
        console.error(`[ISS] currentTab[${currentTab}] error, so ignore`);
        return;
      }

      if (existJiraUrlList.length > 0) {
        setAlertMessage(`IssueId:(${existJiraUrlList}) is already registered in jira`);
        onOpenAlert();
      } else {
        let issueIds;
        if (currentTab === 0) {
          issueIds = updatingIssueList.map(item => item.groupId);
        } else {
          issueIds = updatingIssueList.map(item => item.issueId);
        }

        setIssueIdList(issueIds);
        setShowCreateJiraPopup(true);
      }
    } else {
      setAlertMessage(`Please select any checkbox`);
      onOpenAlert();
    }
  };

  const OnCloseCreateJiraPopop = () => {
    setShowCreateJiraPopup(false);
  };

  const handleJiraCallback = data => {
    if (data.result === 'SUCCESS') {
      initAndRequest(tabName[currentTab]);
      setAlertTitle(`Info`);
      setAlertMessage(`Jira creation success`);
      onOpenAlert();
    } else {
      setAlertMessage(`Jira creation failure, reason: ` + data.reason);
      onOpenAlert();
    }
  };

  const handleJiraStatusCallback = status => {
    if (status === 'processing') {
      setModalPopupTitle('Create Jira');
      setModalPopup(true);
    } else {
      setModalPopup(false);
    }
  };

  const onClickRowItem = item => {
    if (item && (item.issueId || item.groupId)) {
      setCurrentFocusData(item);
      setIssueGuideDisplay('processing');
      setSelectedItem({ groupId: item.groupId, issueId: item.issueId, issueCode: item.issueCode });
    }
  };

  const initAndRequest = type => {
    setContentsErrorDisplay(false);
    setSelectedItem({ groupId: '', issueId: '', issueCode: '' });
    setActionDetails('');

    if (type === 'feed') {
      detail_contents_tabledata.cp_issues.rowdata = [];
      detail_contents_tabledata.cp_issue_description.rowdata = [];
      detail_contents_tabledata.cp_issue_history.rowdata = [];
      setCurrentIssueData(detail_contents_tabledata.cp_issues);
      setCurrentIssueDescription(detail_contents_tabledata.cp_issue_description);
      setCurrentIssueRelatedHistory(detail_contents_tabledata.cp_issue_history);

      getContentsError('feed');
    } else if (type === 'admin') {
      detail_contents_tabledata.organization_issues.rowdata = [];
      detail_contents_tabledata.organization_description.rowdata = [];
      detail_contents_tabledata.organization_history.rowdata = [];
      setCurrentIssueData(detail_contents_tabledata.organization_issues);
      setCurrentIssueDescription(detail_contents_tabledata.organization_description);
      setCurrentIssueRelatedHistory(detail_contents_tabledata.organization_history);

      getContentsError('admin');
    } else if (type === 'batch') {
      detail_contents_tabledata.deployment_issues.rowdata = [];
      detail_contents_tabledata.deployment_description.rowdata = [];
      detail_contents_tabledata.deployment_history.rowdata = [];
      setCurrentIssueData(detail_contents_tabledata.deployment_issues);
      setCurrentIssueDescription(detail_contents_tabledata.deployment_description);
      setCurrentIssueRelatedHistory(detail_contents_tabledata.deployment_history);

      getContentsError('batch');
    }
  };

  const updateContentError = () => {
    if (actionDetails === null || actionDetails.length === 0) {
      setAlertMessage(`Please input any message`);
      onOpenAlert();
    } else if (updatingIssueList.length === 0) {
      setAlertMessage(`Please select updating checkbox`);
      onOpenAlert();
    } else {
      if (currentTab === 0) {
        updateContentErrorInfo('feed', updatingIssueList, actionDetails);
      } else if (currentTab === 1) {
        updateContentErrorInfo('admin', updatingIssueList, actionDetails);
      } else if (currentTab === 2) {
        updateContentErrorInfo('batch', updatingIssueList, actionDetails);
      }
    }
  };

  const valueInitialize = () => {
    setValueInitNeed(true);
    setSelectedItem({ groupId: '', issueId: '', issueCode: '' });
    setActionDetails('');
    setCurrentFocusData('');
    currentItem = null;
    setIssueCodeFetching('processing');
    setIssueGuideDisplay('processing');
    detail_contents_tabledata.cp_issue_description.rowdata = [];
    detail_contents_tabledata.cp_issue_history.rowdata = [];
    detail_contents_tabledata.organization_description.rowdata = [];
    detail_contents_tabledata.organization_history.rowdata = [];
    detail_contents_tabledata.deployment_description.rowdata = [];
    detail_contents_tabledata.deployment_history.rowdata = [];

    checkboxValueInitialize();
  };

  const checkboxValueInitialize = () => {
    setCheckboxValueInit(true);
    setUpdatingIssueList([]);
  };

  const handleChange = event => {
    const { value } = event.target;
    if (value.length <= maxLength) {
      setActionDetails(value);
    }
  };

  const updateCheckedList = items => {
    if (items.length > 0) {
      const checkedIssueList = items.map(item => {
        return { groupId: item.groupId, issueId: item.issueId, issueStatus: item.issueStatus, issueAssign: item.issueAssign };
      });

      setUpdatingIssueList(checkedIssueList);
    } else {
      setUpdatingIssueList([]);
    }
  };

  const updateDropDownList = data => {
    if (updatingIssueList.length > 0) {
      const modifyUpdatingIssueList = updatingIssueList.map(item => {
        return { groupId: item.groupId, issueId: item.issueId, issueStatus: data.status, issueAssign: item.issueAssign };
      });

      setUpdatingIssueList(modifyUpdatingIssueList);
    }
  };

  const updateAssignDropDownList = data => {
    if (updatingIssueList.length > 0) {
      const modifyUpdatingIssueList = updatingIssueList.map(item => {
        return { groupId: item.groupId, issueId: item.issueId, issueStatus: item.issueStatus, issueAssign: data.status };
      });

      setUpdatingIssueList(modifyUpdatingIssueList);
    }
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const getContentsIssueCode = (type, paramValue) => {
    setIssueCodeFetching('processing');

    let paramValueList;
    const issueStatusValue = 'Resolve';

    if (type === 'feed') {
      paramValueList = `groupId=${paramValue}&issueStatus=${issueStatusValue}`;
    } else {
      paramValueList = `issueCode=${paramValue}&issueStatus=${issueStatusValue}`;
    }

    fetch(`${ISS_BASE_URL}/api/application/dashboard/${type}/error?` + paramValueList, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (type === 'feed') {
          const issueCpData = body.filter(item => item.feedDashboards.length > 0);

          const groupedData = issueCpData.reduce((acc, obj) => {
            const { groupId, groupTitle, providerId, feedDashboards } = obj;

            if (!acc[groupId]) {
              acc[groupId] = {
                title: groupTitle,
                cp: providerId,
                count: feedDashboards.length,
                countries: new Set(),
                apis: {},
                values: feedDashboards
              };
            }

            feedDashboards.map(item => {
              const countryArr = item.country.split(",");
              countryArr.forEach((c) => {
                if (!acc[groupId].countries[c]) {
                  acc[groupId].countries[c] = true;
                }
              });

              if (!acc[groupId].apis[item.apiName]) {
                acc[groupId].apis[item.apiName] = true;
              }
            })

            return acc;
          }, {});

          const modifiedData = Object.keys(groupedData).map((groupId) => {
            const { title, cp, count, countries, apis, values } = groupedData[groupId];
            return {
              groupId: groupId,
              title: title,
              cp: cp,
              count: count,
              countries: Object.keys(countries),
              apis: Object.keys(apis).join(", "),
              values: values
            };
          });

          detail_contents_tabledata.cp_issue_history.rowdata = modifiedData.map(item => ({
            row: [
              item.groupId,
              item.values[0].date.substring(2, 10),
              item.countries,
              item.cp,
              item.title,
              item.values[0].status,
              item.apis,
              item.values[0].issueAssignee,
              item.values[0].modifier,
              item.values[0].jiraUrl,
              item.values[0].actionDetails,
            ],
            groupId: item.groupId,
          }));

          setCurrentIssueRelatedHistory(detail_contents_tabledata.cp_issue_history);
        } else if (type === 'admin') {
          detail_contents_tabledata.organization_history.rowdata = body.map(item => ({
            row: [
              item.issueId,
              item.date.substring(2, 10),
              item.priority,
              item.country === 'All' ? 'ALL' : item.country,
              item.issueTitle,
              item.status,
              item.userName,
              item.functionName,
              item.functionParameter.length > 60 ? item.functionParameter.substring(0, 60) + '...' : item.functionParameter,
              item.issueAssignee,
              item.modifier,
              item.jiraUrl,
              item.actionDetails,
            ],
            issueId: item.issueId,
            issueCode: item.issueCode,
          }));

          setCurrentIssueRelatedHistory(detail_contents_tabledata.organization_history);
        } else if (type === 'batch') {
          detail_contents_tabledata.deployment_history.rowdata = body.map(item => ({
            row: [
              item.issueId,
              item.date.substring(2, 10),
              item.priority,
              item.issueTitle,
              item.status,
              item.jobName,
              item.startDate.substring(2, 10),
              item.endDate.substring(2, 10),
              item.executionTime,
              item.issueAssignee,
              item.modifier,
              item.jiraUrl,
              item.actionDetails,
            ],
            issueId: item.issueId,
            issueCode: item.issueCode,
          }));

          setCurrentIssueRelatedHistory(detail_contents_tabledata.deployment_history);
        }

        setIssueCodeFetching('done');
      })
      .catch(error => {
        console.log(`getContentsIssueCode error: `, error);
      });
  };

  const updateContentErrorInfo = (type, updatingIssueList, actionDetails) => {
    const requestData = {
      user: username === 'admin' ? 'cmpuser' : username,
      actionDetails: actionDetails,
      contentIssueUpdatingItemList: updatingIssueList
    };

    fetch(`${ISS_BASE_URL}/api/application/dashboard/update/${type}/error`, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(requestData)
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          initAndRequest(type);
          setAlertTitle(`Info`);
          setAlertMessage(`Issue update success`);
          onOpenAlert();
        } else {
          setAlertMessage(`Issue update fail`);
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`Issue update fail: `, error);
        onOpenAlert();
      });
  };

  const getContentsError = type => {
    const issueStatusValue = 'Unaddressed,Processing';
    const countryListValue = currentFilter && currentFilter.country.length > 0 ? currentFilter.country : '';
    const providerValue = currentFilter && currentFilter.provider.length > 0 ? currentFilter.provider : '';
    const searchTypeValue = currentFilter && currentFilter.searchType ? currentFilter.searchType : '';
    const contentNameValue = currentFilter && currentFilter.contentName ? currentFilter.contentName : '';
    const dateList = currentFilter && currentFilter.date && currentFilter.date.length > 0 ? currentFilter.date : '';

    let requestUrl = '';

    if (type === 'feed') {
      setFeedErrorFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/application/dashboard/feed/error?issueStatus=${issueStatusValue}&country=${countryListValue}&provider=${providerValue}&searchType=${searchTypeValue}&contentName=${contentNameValue}`;
    } else if (type === 'admin') {
      setAdminErrorFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/application/dashboard/admin/error?issueStatus=${issueStatusValue}`;
    } else if (type === 'batch') {
      setBatchErrorFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/application/dashboard/batch/error?issueStatus=${issueStatusValue}`;
    } else {
      console.error(`getContentsError, type:${type} error`);
      return;
    }

    fetch(requestUrl, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        let contentErrorType = '';
        let contentIssueCount = 0;
        if (type === 'feed') {
          const issueCpData = body.filter(item => item.feedDashboards.length > 0);

          const groupedData = issueCpData.reduce((acc, obj) => {
            const { groupId, groupTitle, groupGuide, providerId, feedDashboards } = obj;

            if (!acc[groupId]) {
              acc[groupId] = {
                title: groupTitle,
                guide: groupGuide,
                cp: providerId,
                count: feedDashboards.length,
                countries: new Set(),
                apis: new Set(),
                values: feedDashboards
              };
            }

            feedDashboards.map(item => {
              const countryArr = item.country.split(",");
              countryArr.forEach((c) => {
                if (!acc[groupId].countries[c]) {
                  acc[groupId].countries[c] = true;
                }
              });

              if (!acc[groupId].apis[item.apiName]) {
                acc[groupId].apis[item.apiName] = true;
              }
            })

            return acc;
          }, {});

          const modifiedData = Object.keys(groupedData).map((groupId) => {
            const { title, guide, cp, count, countries, apis, values } = groupedData[groupId];
            return {
              groupId: groupId,
              title: title,
              guide: guide,
              cp: cp,
              count: count,
              countries: Object.keys(countries),
              apis: Object.keys(apis).join(", "),
              values: values
            };
          });

          setFeedErrorFetching('done');
          setFeedError(modifiedData);
          contentErrorType = 'feedError';
          contentIssueCount = issueCpData.length;
        } else if (type === 'admin') {
          setAdminErrorFetching('done');
          setAdminError(body);
          contentErrorType = 'adminError';
          contentIssueCount = body.length;
        } else if (type === 'batch') {
          setBatchErrorFetching('done');
          setBatchError(body);
          contentErrorType = 'batchError';
          contentIssueCount = body.length;
        }

        if (issueChangeCallback) {
          issueChangeCallback({ type: contentErrorType, issueCount: contentIssueCount });
        }
      })
      .catch(error => {
        console.log(`getContentsError error: `, error);
      });
  };

  const getManagementGroup = () => {
    fetch(`${ISS_BASE_URL}/api/common/issue/management/group`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          const groupNameList = [... new Set(body.dataSet.map(item => item.groupName))];

          const tempGroupNameList = groupNameList.map(item => {
            return { index: item, name: item }
          });

          detail_contents_tabledata.cp_issues.assignDropdownItem = tempGroupNameList;
          detail_contents_tabledata.organization_issues.assignDropdownItem = tempGroupNameList;
          detail_contents_tabledata.deployment_issues.assignDropdownItem = tempGroupNameList;
        } else {
          console.log(`[ISS] managementGroup fetching error`);
        }
      })
      .catch(error => {
        console.log(`[ISS] managementGroup error: `, error);
      })
  };

  const changeDropDownAlert = () => {
    setAlertTitle('Warning');
    setAlertMessage('Select the checkbox to change the Dropdown box.');
    onOpenAlert();
  };

  const handleExcelDownload = (groupIdList) => {
    const excelDownFeedError = feedError.filter(item => groupIdList.includes(item.groupId));

    if (excelDownFeedError.length > 0) {
      let data = [];
      excelDownFeedError.map(item => {
        const sheetData = {
          sheet: `${item.groupId}_Issue`,
          columns: [
            { label: 'Group ID', value: (row) => row.groupId },
            { label: 'Issue ID', value: (row) => row.issueId },
            { label: 'Date', value: row => row.date.substring(2,10)},
            { label: 'Priority', value: row => row.priority},
            { label: 'Country', value: row => row.country},
            { label: 'CP', value: row => row.providerId},
            { label: 'Channel/VOD', value: row => row.contentName},
            { label: 'Issue Code', value: row => row.issueCode},
            { label: 'Issue Title', value: row => row.issueTitle},
            { label: 'Status', value: row => row.status},
            { label: 'API Name', value: row => row.apiName},
            { label: 'File Name', value: row => row.filename},
            { label: 'Assign', value: row => row.issueAssignee},
            { label: 'Modifier', value: row => row.modifier},
            { label: 'Jira URL', value: row => row.jiraUrl},
            { label: 'Action Details', value: row => row.actionDetails},
            { label: 'Issue Guide', value: row => row.issueGuide},
            { label: 'Cause Analysis', value: row => row.errorMessage.length > 30000 ? row.errorMessage.substring(0,30000) : row.errorMessage},
          ],
          content: [...item.values],
        };

        data.push(sheetData);
      });

      let xLSettings = {
        fileName: `CP_Issue`,
      };
      xlsx(data, xLSettings);
    }
  };

  return (
    <>
      {isContentsErrorDisplay ? (
        <div>
          <div className="contents-section-wide">
            <div className="grid-row-wide">
              <div className="row-box box-tbl box-lgchannels">
                <IssTable
                  height={'calc(100% - 62px)'}
                  pagination={true}
                  data={currentIssueData}
                  handleClickItem={item => onClickRowItem(item)}
                  pagingChangeCallback={() => valueInitialize()}
                  handleCheckedList={item => updateCheckedList(item)}
                  isCheckboxValueInit={isCheckboxValueInit}
                  handleDropDownList={item => updateDropDownList(item)}
                  handleAssignDropDownList={item => updateAssignDropDownList(item)}
                  sortingItem={{ enable: true, sortColumn: -1, sortDirection: 'desc' }}
                  changeDropDownAlert={changeDropDownAlert}
                />
              </div>
              <div className="row-box box-actionPoints">
                <div className="row">
                  <div className="head">
                    <div className="title">
                      <h2>Action Points</h2>
                    </div>
                  </div>
                  <div className="grid-column-2">
                    {isIssueGuideDisplay !== 'processing' ? (
                      <div className="box-des">
                        <h3>Description</h3>
                        <IssTable height={'calc(100% - 40px)'} pagination={false} data={currentIssueDescription} />
                      </div>
                    ) : (
                      <></>
                    )}
                    {isIssueGuideDisplay !== 'processing' ? (
                      <div className="box-actionDetail">
                        <h3>Action Details (Count : {updatingIssueList.length})</h3>
                        <textarea value={actionDetails} onChange={handleChange} maxLength={maxLength}></textarea>
                        <p>
                          {actionDetails ? actionDetails.length : 0}/{maxLength}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="button-container align-right">
                    <PrimaryButton type={'button'} text={'Save'} btnClass={'btn-primary-gray'} onClick={updateContentError} />
                    <PrimaryButton type={'button'} text={'Create Jira'} btnClass={'btn-primary-accent'} onClick={OnOpenCreateJiraPopop} />
                  </div>
                </div>
                {issueCodeFetching !== 'processing' ? (
                  <div className="row">
                    <div className="box-issueHistory">
                      <IssTable height={'calc(100% - 30px)'} pagination={false} data={currentIssueRelatedHistory} />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {isShowCreateJiraPopup && <CreateJiraModal type={'CONTENTS'} issueIdList={issueIdList} isOpen={isShowCreateJiraPopup}
            onClose={OnCloseCreateJiraPopop} jiraCallback={handleJiraCallback} jiraStatusCallback={handleJiraStatusCallback} />}
          {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
          {isModalPopup && <ModalPopup isOpen={isModalPopup} title={modalPopupTitle} />}
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )}
    </>
  );
}

ContentsDetailCommon.propTypes = {
  username: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  username: state.currentUser.username,
});

export default connect(mapStateToProps)(ContentsDetailCommon);