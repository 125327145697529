//import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function tabCategory(state = initialState, action) {
  switch (action.type) {
    case 'SET_TAB_CATEGORY':
      return action.payload;
    default:
      return state;
  }
}
