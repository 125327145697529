///* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { mergeChannelConf } from '../../../actions/channelMappingActions';
import SelectedFeed from './SelectedFeed';
import ChannelMappingAlert from './ChannelMappingAlert';
import SelectedChannelMap from './SelectedChannelMap';
function SelectedFeedNChannelMap({ system, ric, cntry, cp, sourceChannel, failmessage, mergeChannelConf, configlist, showLoadingIndicator, user, cpChannelDataViewRow, cntryInfo, rsChannelIds }) {
  useEffect(() => {
    if (failmessage) openAlert(failmessage);
  }, [failmessage]);
  useEffect(() => {}, []);
  const selectedTableRef = useRef();
  const [alert, setAlert] = useState({ open: false, message: '' });
  const [isFeedChange, setIsFeedChange] = React.useState(false);

  const configListSetting = target => {
    mergeChannelConf(target);
  };
  const openAlert = message => {
    setAlert({
      open: true,
      message: message,
    });
  };

  const onClickCopy = copyType => {
    if (copyType === 0) {
      selectedTableRef.current.onAllSelected(false);
    }
    if (!configlist) {
      openAlert('First you need to search the channel conf.');
      return;
    }
    let newList = [...configlist];
    let invalidUrl = sourceChannel.channellist.filter(v => !v.mediaStaticUrl && v.channelType === 'Linear');
    let editableFlag = sourceChannel.channellist.filter(v => v.mediaUrlEditableFlag === 'N');
    if (invalidUrl.length >= 1 && editableFlag.length == 0) {
      openAlert('There are Channel IDs with invalid URL');
      return;
    }
    if (copyType === 1) {
      let existCopy = configlist.some(item => {
        let exist = sourceChannel.channellist.filter(v => item.channelId === v.channelId);
        return exist.length >= 1;
      });
      if (existCopy) {
        openAlert('There are duplicate channel IDs.');
      } else {
        sourceChannel.channellist.forEach(v => {
          let obj = {
            channelId: v.channelId,
            channelName: v.channelName,
            channelNumber: v.channelNumber,
            channelGenreName: v.channelGenreName,
            cpName: v.cpName,
            deleteFlag: 'N',
            logoUrl: v.logoUrl,
            change: true,
            addFlag: true,
            dmaGroupName: '',
            modifier: user.name,
            mediaStaticUrl: v.mediaStaticUrl,
            channelType: v.channelType,
          };
          newList.push({ ...obj });
        });
      }
    } else {
      //  선택 copy
      let selection = document.querySelectorAll('input[type=checkBox]:checked');
      let checked = [];
      if (selection && selection.length) {
        selection.forEach(v => {
          if (v.value) {
            let selected = sourceChannel.channellist.filter((item, i) => '' + i === v.value);
            checked.push(selected[0]);
          }
        });
      }

      if (!checked.length) {
        openAlert('Select a channel from the channel list.');
        return;
      }

      let existCopy = configlist.some(item => {
        let exist = checked.filter(v => item.channelId === v.channelId);
        return exist.length >= 1;
      });

      let notExistInRs = checked.filter(function (item) {
        return !rsChannelIds.includes(item.channelId);
      });
      if (notExistInRs.length !== 0) {
        openAlert('Selected Channel not present in RS');
        return;
      } else if (existCopy) {
        openAlert('There are duplicate channel IDs.');
        return;
      } else {
        checked.forEach(v => {
          let obj = {
            channelId: v.channelId,
            channelName: v.channelName,
            channelNumber: v.channelNumber,
            channelGenreName: v.channelGenreName,
            cpName: v.cpName,
            deleteFlag: 'N',
            logoUrl: v.logoUrl,
            change: true,
            addFlag: true,
            dmaGroupName: '',
            modifier: user.name,
            mediaStaticUrl: v.mediaStaticUrl,
            channelType: v.channelType,
          };
          newList.push({ ...obj });
        });
      }
    }
    configListSetting([...newList]);
    selectedChannelMapColumnSize();
  };

  function selectedChannelMapColumnSize() {
    const fixedColumns = document.querySelectorAll("[style^='position: sticky; top: 0px; left:']");
    if(fixedColumns.length > 0){
       let header = fixedColumns[0];
       header.style.width = parseInt(header.style.width, 10)+1+'px';
    }
  }

  return (
    <React.Fragment>
      <SelectedFeed system={system} ric={ric} cntry={cntry} openAlert={openAlert} sourceChannel={sourceChannel} tableRef={selectedTableRef} cpChannelDataViewRow={cpChannelDataViewRow} setIsFeedChange={setIsFeedChange} />
      <div className="arrow_btn_area">
        <Button className="btn_move piece" title=">" onClick={() => onClickCopy(0)}>
          &nbsp;
        </Button>
        <Button className="btn_move all" title=">>" onClick={() => onClickCopy(1)}>
          &nbsp;
        </Button>
      </div>
      <SelectedChannelMap system={system} ric={ric} cntry={cntry} cp={cp} openAlert={openAlert} showLoadingIndicator={showLoadingIndicator} cntryInfo={cntryInfo} isFeedChange={isFeedChange} selectedChannelMapColumnSize={selectedChannelMapColumnSize}/>
      <ChannelMappingAlert alert={alert} />
    </React.Fragment>
  );
}
SelectedFeedNChannelMap.propTypes = {
  system: PropTypes.string.isRequired,
  ric: PropTypes.string.isRequired,
  cntry: PropTypes.string.isRequired,
  cp: PropTypes.object,
  sourceChannel: PropTypes.object.isRequired,
  failmessage: PropTypes.string.isRequired,
  mergeChannelConf: PropTypes.func.isRequired,
  configlist: PropTypes.array,
  showLoadingIndicator: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  cpChannelDataViewRow: PropTypes.object.isRequired,
  cntryInfo: PropTypes.array,
};
const mapStateToProps = state => ({
  failmessage: state.channelMapping.failmessage,
  user: state.currentUser,
  sourceChannel: state.channelMapping.sourceChannel,
  configlist: state.channelMapping.configlist,
  rsChannelIds: state.channelMapping.rsChannelIds.list,
});
const mapDispatchToProps = dispatch => ({
  mergeChannelConf: data => dispatch(mergeChannelConf(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SelectedFeedNChannelMap);
