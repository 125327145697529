import React from 'react';
import IssueHistoryTotal from './IssueHistoryTotal';
import IssueHistoryService from './IssueHistoryService';
import IssueHistoryContents from './IssueHistoryContents';
import IssueHistoryStatistics from './IssueHistoryStatistics';

function ContentsAreaIssueHistory ({ currentTab, changeFilter }) {
  let content;
  if (currentTab === 0) {
    content = <IssueHistoryTotal filterCallback={filter => changeFilter(filter)}/>;
  } else if (currentTab === 1) {
    content = <IssueHistoryService filterCallback={filter => changeFilter(filter)}/>;
  } else if (currentTab === 2) {
    content = <IssueHistoryContents filterCallback={filter => changeFilter(filter)}/>;
  } else if (currentTab === 3) {
    content = <IssueHistoryStatistics filterCallback={filter => changeFilter(filter)}/>;
  }

  return <div className="contents-section">{content}</div>;
};

export default ContentsAreaIssueHistory;