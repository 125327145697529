import { defaultFont } from '../../material-dashboard-react.js';

const sidebarStyle = () => ({
  list: {
    marginTop: '0',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    position: 'unset',
  },
  item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    '&:hover,&:focus,&:visited,&': {
      color: 'inherit',
    },
  },
  itemText: {
    ...defaultFont,
    margin: '0',
    lineHeight: '30px',
    fontSize: '14px',
    color: 'inherit',
  },
  itemTextRTL: {
    textAlign: 'right',
  },
});

export default sidebarStyle;
