import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from '@material-ui/core/Dialog';
//import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ChipsFilter from '../../../common/ChipsFilter';
//import NewWhitelistIp from '../NewWhitelistIp';
import NoData from '../../../common/NoData';
import SnackBar from '../../../common/SnackBar';
import DeleteAlert from '../../../common/DeleteAlert';
import Typography from '@material-ui/core/Typography';

import { getWhitelistIpsAdminAction } from '../../../actions/whitelistIpsActions';

import { WHITELISTIP_LIST_SIZE } from '../../../constants';

import { API_BASE_URL } from '../../../constants';

import { getHeaders } from '../../../util/actionUtil';

const keyFilters = [{ name: 'providerId' }, { name: 'ipAddr' }];

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  noMetadataFiles: {
    margin: 50,
    padding: 50,
  },
});

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function WhitelistIpList({ classes, getWhitelistIpsAdminAction, username, whitelistIps }) {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(-1);
  const [openAlert, setOpenAlert] = useState(false);
  const [filter, setFilter] = useReducer((state, newState) => ({ ...state, ...newState }), {
    ipAddr: '',
    providerId: '',
  });
  const [snackBar, setSnackBar] = useReducer((state, newState) => ({ ...state, ...newState }), {
    snackBarOpen: false,
    message: '',
    variant: 'success',
  });
  const prevUsername = usePrevious(username);
  const prevFilter = usePrevious(filter);

  const loadWhitelistIpList = useCallback(
    (page = 0, size = WHITELISTIP_LIST_SIZE) => {
      if (username) {
        getWhitelistIpsAdminAction(page, size, filter.providerId, filter.ipAddr);
      }
    },
    [filter.ipAddr, filter.providerId, getWhitelistIpsAdminAction, username],
  );

  useEffect(() => {
    if (whitelistIps.length === 0) {
      loadWhitelistIpList();
    }
  }, [loadWhitelistIpList, whitelistIps.length]);

  useEffect(() => {
    if (prevFilter) {
      if ((prevUsername === '' && username) || prevFilter.ipAddr !== filter.ipAddr || prevFilter.providerId !== filter.providerId) {
        loadWhitelistIpList();
      }
    }
  }, [filter, loadWhitelistIpList, prevFilter, prevUsername, username]);

  const handleFilterChange = filterList => {
    keyFilters.forEach(i => {
      const filter = filterList.find(j => j.split(':')[0] === i.name);
      if (filter) {
        setFilter({
          [i.name]: filter.substr(i.name.length + 1, filter.length),
        });
      } else {
        setFilter({
          [i.name]: '',
        });
      }
    });
  };

  const deleteAction = async id => {
    const config = {
      method: 'DELETE',
      headers: getHeaders(),
    };

    const response = await fetch(`${API_BASE_URL}/admin/whitelist-ips/${id}`, config);
    const json = await response.json();
    setSnackBar({
      snackBarOpen: true,
      message: response.ok ? 'Successfully deleted' : `<${json.errorCode}> ${json.message}`,
      variant: response.ok ? 'success' : 'error',
    });
    loadWhitelistIpList();
  };

  // const handleAdd = async data => {
  //   handleDialogClose();
  //   const config = {
  //     method: 'POST',
  //     headers: getHeaders(),
  //     body: JSON.stringify(data),
  //   };

  //   const response = await fetch(`${API_BASE_URL}/admin/whitelist-ips`, config);
  //   const json = await response.json();
  //   setSnackBar({
  //     snackBarOpen: true,
  //     message: response.ok ? 'Successfully added' : `<${json.errorCode}> ${json.message}`,
  //     variant: response.ok ? 'success' : 'error',
  //   });
  //   loadWhitelistIpList();
  // };

  const handleDialogOpen = () => {
    setOpenAddDialog(true);
  };

  const handleDialogClose = () => {
    setOpenAddDialog(false);
  };

  const handleSnackBarClose = () => {
    setSnackBar({
      snackBarOpen: false,
    });
  };

  const handleAlertOpen = id => {
    setDeleteId(id);
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setDeleteId(-1);
    setOpenAlert(false);
  };

  const handleAlertConfirm = () => {
    setOpenAlert(false);
    deleteAction(deleteId);
  };

  return (
    <div className={classes.layout}>
      <Typography variant="h6" color="textSecondary" paragraph>
        Whitelist IP List
      </Typography>
      <ChipsFilter filters={keyFilters} onChange={handleFilterChange} />
      <Button
        // data-testid="newButton"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleDialogOpen}>
        New
      </Button>
      {whitelistIps.length > 0 && (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center">providerId</TableCell>
              <TableCell align="center">ipAddr</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {whitelistIps.map(n => (
              <TableRow key={n.ipAddr}>
                <TableCell align="center">{n.providerId}</TableCell>
                <TableCell align="center">{n.ipAddr}</TableCell>
                <TableCell align="center">
                  <IconButton data-testid="deleteButton" aria-label="delete" className={classes.margin} onClick={() => handleAlertOpen(n.id)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <Dialog open={openAddDialog} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Whitelist IP</DialogTitle>
        {/* <DialogContent>
          <NewWhitelistIp addAction={handleAdd} />
        </DialogContent> */}
      </Dialog>
      <NoData contentName="Whitelist IP" show={whitelistIps.length === 0} />
      <SnackBar open={snackBar.snackBarOpen} variant={snackBar.variant} message={snackBar.message} onClose={handleSnackBarClose} />
      <DeleteAlert open={openAlert} onClose={handleAlertClose} onConfirm={handleAlertConfirm} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" />
    </div>
  );
}

WhitelistIpList.propTypes = {
  classes: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  getWhitelistIpsAdminAction: PropTypes.func.isRequired,
  whitelistIps: PropTypes.array.isRequired,
  username: PropTypes.string,
};

const mapStateToProps = state => ({
  whitelistIps: state.whitelistIps.whitelistIps,
  fetching: state.whitelistIps.fetching,
  username: state.currentUser.username,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  getWhitelistIpsAdminAction(page, size, providerId, ipAddr) {
    dispatch(getWhitelistIpsAdminAction(page, size, providerId, ipAddr));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(WhitelistIpList)));
