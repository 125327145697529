import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/core/styles';
import { withRouter, Route } from 'react-router-dom';
//import { Redirect } from 'react-router-dom';
import Signin from '../../user/Signin';
import Dashboard from '../../dashboard/Dashboard';

// const styles = (theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   // heroUnit: {
//   // 	backgroundColor: theme.palette.background.paper
//   // },
//   heroContent: {
//     maxWidth: 600,
//     margin: '0 auto',
//     padding: theme.spacing(8, 6),
//   },
// });

function Main({ isAuthenticated }) {
  let mainpage;

  if (isAuthenticated) {
    //mainpage = <Redirect to="/dashboard" />;/다른 페이지로 가기 때문에 rerendering안됨
    mainpage = <Route path="/" component={Dashboard} />;
  } else {
    //mainpage=<meta http-equiv="refresh" content="0; url=/signin"/>;
    mainpage = <Route path="/" component={Signin} />;
  }
  //return <div className={classes.heroUnit}>{mainpage}</div>;
  return <div>{mainpage}</div>;
}

Main.propTypes = {
  //classes: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  isAuthenticated: state.currentUser.isAuthenticated,
});
// const mapDispatchToProps = (dispatch) => ({
//   rehydrateAction() {
//     dispatch(rehydrateAction());
//   },
// });
//export default connect(mapStateToProps)(withRouter(withStyles(styles)(Main)));
export default connect(mapStateToProps)(withRouter(Main));
