import * as actions from '../../actions/actionTypes';

const initialState = {
  loading: false,
  data: {},
};

export default function loader(state = initialState, action) {
  switch (action.type) {
    case actions.SHOW_LOADER:
      return { ...state, loading: true };
    case actions.HIDE_LOADER:
      return { ...state, loading: false };
    default:
      return state;
  }
}
