import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function tableDetail(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATISTIC_TABLE:
      if (action.payload.status) return { status: action.payload.status, message: action.payload.message };
      else if (action.payload) {
        return action.payload.map(n => {
          Object.keys(n).forEach(m => {
            n[m] = isNaN(1 * n[m]) ? n[m] : parseFloat(n[m]);
          });
          return n;
        });
      } else return [];
    case actions.SET_STATISTIC_TABLE_INIT:
      return [];
    case actions.SET_STATISTIC_ERROR:
      return [];
    default:
      return state;
  }
}
