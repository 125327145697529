import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import AlertDialog from '../../../common/dialog/AlertDialog';
import ConfirmDialog from '../../../common/dialog/ConfirmDialog';
import Button from '@material-ui/core/Button';
import EditCategoryName from './EditCategoryName'
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';

const ShowCategoryName = (props) => {
  const { isDisplayCategoryTitle
        , langList
        , relatedContentCategory
        , country
        , callbackClose
      } = props;


  const [isAlert, setIsAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [displayWarningList, setDisplayWarningList] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState({});
  const [isDisplayEditCategoryName, setDisplayEditCategoryName] = useState(false);
  const [multiLang, setMultiLang] = useState([]);
  const [editDefaultCategoryName, setEditDefaultCategoryName] = useState('');
  const [editCategoryNameByLang, setEditCategoryNameByLang] = useState({});
  const [isConfirm, setIsConfirm] = useState(false);
  const [confirmDescription, setConfirmDescription] = useState('');
  const [confirmId, setConfirmId] = useState('');

  useEffect(() => {
    if (isDisplayCategoryTitle) {
      initialize();
    }
  }, [isDisplayCategoryTitle]);

  const handleAction = (result) => {
    setSelectedCategoryName(result);

    const param = `country=${country}&categoryType=${result.relatedContentCategoryType}`;

    setDisplayEditCategoryName(false);
    fetch(`${API_BASE_URL}/admin/livetv/related-content-category-multi-lang?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setMultiLang(body.multiLang);
        setDisplayEditCategoryName(true);
      } else {
        setAlertDescription(`[LiveTV] related-content-category-multi-lang request fail`);
        setIsAlert(true);
      }
    })
    .catch(error => {
      setAlertDescription(`[LiveTV] related-content-category-multi-lang request error: `, error);
      setIsAlert(true);
    }).finally(() => {});
  };

  const saveCategoryName = () => {
    const updateLangList = editCategoryNameByLang.map(item => {
      if (item.langCode && item.name) {
        return {
          langCode: item.langCode,
          relatedContentCategoryName: item.name,
        }
      }
    }).filter(item => item);

    const requestData = {
      country: country,
      relatedContentCategoryType: selectedCategoryName.relatedContentCategoryType,
      relatedContentDefaultCategoryName: editDefaultCategoryName,
      relatedContentCategoryMultiLangList: updateLangList
    };

    fetch(`${API_BASE_URL}/admin/livetv/save-related-content-category`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(requestData)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertDescription(`Related Content category saved successfully`);
        setIsAlert(true);
      } else {
        setAlertDescription(`[LiveTV] save-related-content-category request fail`);
        setIsAlert(true);
      }
    })
    .catch(error => {
      setAlertDescription(`[LiveTV] save-related-content-category request error: `, error);
      setIsAlert(true);
    }).finally(() => {});
  };

  const handleConfirm = () => {
    saveCategoryName();

    setIsConfirm(false);
    initialize();
    callbackClose();
  };

  const handleCancel = () => {
    initialize();
    callbackClose();
  };

  const handleSave = () => {
    if (!editDefaultCategoryName) {
      setAlertDescription(`Default name is empty, please input default name`);
      setIsAlert(true);
      return;
    }

    setConfirmDescription(`Do you want to change related content category name ?`);
    setIsConfirm(true);
  };

  const initialize = () => {
    setDisplayEditCategoryName(false);
    setSelectedCategoryName({});
    setMultiLang([]);
    setEditDefaultCategoryName('');
    setEditCategoryNameByLang({});
  };

  return (
    <React.Fragment>
      {
        isDisplayCategoryTitle ? (
        <div className='live_tv_related_content_category_name_layout'>
          <div className='live_tv_related_content_category_name'>
            <div className='view_category_name'>
              <MaterialTable
                title=""
                columns={[
                  {
                    title: 'Classfication',
                    field: 'relatedContentCategoryType',
                    align: 'center',
                    width: '20%',
                  },
                  {
                    title: 'Priority',
                    field: 'priority',
                    align: 'center',
                    width: '10%',
                  },
                  {
                    title: 'Description',
                    field: 'description',
                    align: 'center',
                    width: '40%',
                  },
                  {
                    title: 'Default Category name',
                    field: 'relatedContentCategoryName',
                    align: 'center',
                    width: '20%',
                  },
                  {
                    title: 'Edit',
                    field: '',
                    align: 'center',
                    width: '10%',
                    render: rowData => (
                      <Button
                        className="btn_size_tbl btn_color3"
                        variant="contained"
                        onClick={() => handleAction(rowData)}
                      >
                        Edit
                      </Button>
                    ),
                  },
                ]}
                data={relatedContentCategory}
                options={{
                  paging: false,
                  sorting: false,
                  search: false,
                  maxBodyHeight: 550,
                  rowStyle: rowData => ({
                    backgroundColor: selectedCategoryName.relatedContentCategoryType === rowData.relatedContentCategoryType ? '#FFFFE0' : '',
                  })
                }}
              />
            </div>
            <div className='edit_category_name'>
              <EditCategoryName
                isDisplayEditCategoryName={isDisplayEditCategoryName}
                selectedCategoryName={selectedCategoryName}
                langList={langList}
                multiLang={multiLang}
                callbackChangedDefaultCategoryName={setEditDefaultCategoryName}
                callbackChangedCategoryNameByLang={setEditCategoryNameByLang}
              />
            </div>
          </div>
          <div className='button_layout'>
            <Button className="btn_color2"
              onClick={handleCancel}
              >
              Cancel
            </Button>
            <Button className="btn_color2"
              onClick={handleSave}
              disabled={!isDisplayEditCategoryName}
            >
              Save
            </Button>
          </div>
        </div>
        ) : (
          <></>
        )
      }

      <AlertDialog
        openAlert={isAlert}
        description={alertDescription}
        warningList={displayWarningList}
        closeHandler={() => { setIsAlert(false); }}
      />
      <ConfirmDialog
        id={confirmId}
        openDialog={isConfirm}
        description={confirmDescription}
        confirmHandler={handleConfirm}
        closeHandler={() => { setIsConfirm(false); }}
      />
    </React.Fragment>
  );
}

export default ShowCategoryName;