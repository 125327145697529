import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import DefaultButton from '../../common/DefaultButton';
import DoughnutChart from '../../common/DoughnutChart';
import IssTable from '../../common/IssTable';
import DropDownForSearch from '../../common/DropDownForSearch';
import DoughnutLegend from '../../common/DoughnutLegend';
import { DashboardSet } from '../../DataSet/Service/ServiceDashboardDataSet';
import { ISS_BASE_URL } from '../../../../constants';
import { getHeaders } from '../../../../util/actionUtil';
import Alert from '../../modal/Alert';
import TutorialPage from '../../TutorialPage';

const ServiceDashboard = ( { filterCallback } ) => {
  const history = useHistory();
  const [initData, setData] = useState({'regions': [], 'countries': [], 'providers': []});
  const [regions, setRegions] = useState({'dropdownList': [], 'checkedList' : []});
  const [countries, setCountries] = useState({'dropdownList': [], 'checkedList' : []});
  const [providers, setProviders] = useState({'dropdownList': [], 'checkedList' : []});
  const [appIssueSet, setAppIssueSet] = useState(DashboardSet.appIssueSet);
  const [playIssueSet, setPlayIssueSet] = useState(DashboardSet.playIssueSet);
  const [scheduleIssueSet, setScheduleIssueSet] = useState(DashboardSet.scheduleIssues);
  const [regioChartView, isRegioChartView] = useState(false);
  const [serviceCaRegion, setServiceCaRegion] = useState(DashboardSet.serviceRegion.ca);
  const [serviceLatamRegion, setServiceLatamRegion] = useState(DashboardSet.serviceRegion.latam);
  const [serviceUsRegion, setServiceUsRegion] = useState(DashboardSet.serviceRegion.us);
  const [serviceEuRegion, setServiceEuRegion] = useState(DashboardSet.serviceRegion.eu);
  const [serviceAuRegion, setServiceAuRegion] = useState(DashboardSet.serviceRegion.au);
  const [serviceInRegion, setServiceInRegion] = useState(DashboardSet.serviceRegion.in);
  const [serviceKrJpRegion, setServiceKrJpRegion] = useState(DashboardSet.serviceRegion.krjp);
  const [clickedDonutCa, setClickedDonutCa] = useState('AIC(CA)');
  const [clickedDonutLatam, setClickedDonutLatam] = useState('AIC(LATAM)');
  const [clickedDonutUs, setClickedDonutUs] = useState('AIC(US)');
  const [clickedDonutEu, setClickedDonutEu] = useState('EIC(EU)');
  const [clickedDonutAu, setClickedDonutAu] = useState('KIC(AU)');
  const [clickedDonutIn, setClickedDonutIn] = useState('KIC(IN)');
  const [clickedDonutKrJp, setClickedDonutKrJp] = useState('KIC(KR/JP)');
  const [paramRegions, setParamRegions] = useState([]);
  const [paramCountries, setParamCountries] = useState([]);
  const [paramProviders, setParamProviders] = useState([]);
  const [searchFilter, setSearchFilter] = useState({});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    getSearchFilter();
    getDashboard('');
  }, []);
  //------------------------------------
  //tab 관련
  //------------------------------------
  const tabMenus = [
    {
      name: DashboardSet.scheduleIssues.title,
      tooltip: DashboardSet.scheduleIssues.tooltip,
    },
    {
      name: 'Service Data Issues',
      tooltip: 'Detected issues when content descriptions are missing.',
    },
  ];

  //------------------------------------
  // data 조회
  //------------------------------------
  useEffect(() => {
    if ( searchFilter.countryByRegion ) {
      let dropdownCountries = [];
      if (paramRegions && paramRegions.length > 0){
        dropdownCountries = searchFilter.countryByRegion.filter(f => paramRegions.includes(f.region)).map(region =>
          region.countryList.map(map => ({ id: map.countryCode, value : map.fullName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownCountries.sort((a, b) => a.value.localeCompare(b.value));
      }
      setCountries({'dropdownList': dropdownCountries, 'checkedList' : [] });
    }
  }, [ paramRegions ]);

  useEffect(() => {
    if ( searchFilter.countryByRegion &&  searchFilter.providerByCountry) {
      let dropdownProviders = [];
      if (paramCountries && paramCountries.length > 0){
        let ProviderList = searchFilter.providerByCountry.filter(f => paramCountries.includes(f.countryCode)).map(map =>
          map.providerList.map(pr => ({ id: pr.providerId, value : pr.providerName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownProviders = [...new Set(ProviderList.map((list) => JSON.stringify(list)))].map((list) => JSON.parse(list));
        dropdownProviders.sort((a, b) => a.value.localeCompare(b.value));
      }
      setProviders({ 'dropdownList': dropdownProviders, 'checkedList' : [] });
    }
  }, [ paramCountries ]);
  //------------------------------------
  // data 조회
  //------------------------------------
  const getSearchFilter = () => {
    fetch(`${ISS_BASE_URL}/api/common/search-filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSearchFilter({
          countryByRegion : body.countryByRegion
          , providerByCountry : body.providerByCountry
        })
        let dropdownRegion = [];
        let initCountries = [];
        let initProviders = [];
        // countryByRegion
        if ( body.countryByRegion ) {
          dropdownRegion = body.countryByRegion.map(map =>({ id: map.region, value : map.region }));
          dropdownRegion.sort((a, b) => a.value.localeCompare(b.value));
          initCountries = body.countryByRegion.map(region => region.countryList.map(country => country.countryCode)).reduce(function (acc, cur) { return acc.concat(cur); });
          initCountries.sort((a, b) => a.localeCompare(b));
        }
        setRegions({ 'dropdownList': dropdownRegion, 'checkedList' : [] });
        // providerByCountry
        if ( body.providerByCountry ) {
          let ProviderList = body.providerByCountry.map(country => country.providerList.map(provider => provider.providerId )).reduce(function (acc, cur) { return acc.concat(cur); });
          initProviders = [...new Set(ProviderList)];
          initProviders.sort((a, b) => a.localeCompare(b));
        }
        setData({'regions': [], 'countries': initCountries, 'providers': initProviders });
      } else {
        setAlertTitle('Error');
        setAlertMessage('[getSearchFilter : error]');
        setIsAlert(true);
      }
    })
    .catch(error => {
      console.log("getSearchFilter - error: " , error);
      setAlertTitle('Error');
      setAlertMessage('[getSearchFilter : error]');
      setIsAlert(true);
    }).finally(() => {});
  };

  const setRegionDoughnutChart = (dataSet) => {
    dataSet.map((n) => {
      const { region, appCount, imageCount,  serviceDataCount, scheduleCount, playCount, sumCount } = n;
      let datasets = [];
      let set;
      if (sumCount > 0){
        set = { ...DashboardSet.serviceRegion.regionData, ...{} };
        set.label = region;
        set.data = [appCount, imageCount, serviceDataCount, scheduleCount, playCount];
      } else {
        set = { ...DashboardSet.serviceRegion.noRegionData, ...{} };
        set.label = region;
      }
      datasets.push(set);
      if (region === 'AIC(CA)'){
        setServiceCaRegion({ ...serviceCaRegion, ...{ id: region, title : region, sum : sumCount, datasets : datasets } });
      } else if (region === 'AIC(LATAM)') {
        setServiceLatamRegion({ ...serviceLatamRegion, ...{ id: region, title : region, sum : sumCount, datasets : datasets } });
      } else if (region === 'AIC(US)') {
        setServiceUsRegion({ ...serviceUsRegion, ...{ id: region, title : region, sum : sumCount, datasets : datasets } });
      } else if (region === 'EIC(EU)') {
        setServiceEuRegion({ ...serviceEuRegion, ...{ id: region, title : region, sum : sumCount, datasets : datasets } });
      } else if (region === 'KIC(AU)') {
        setServiceAuRegion({ ...serviceAuRegion, ...{ id: region, title : region, sum : sumCount, datasets : datasets } });
      } else if (region === 'KIC(IN)') {
        setServiceInRegion({ ...serviceInRegion, ...{ id: region, title : region, sum : sumCount, datasets : datasets } });
      } else if (region === 'KIC(KR/JP)') {
        setServiceKrJpRegion({ ...serviceKrJpRegion, ...{ id: region, title : region, sum : sumCount, datasets : datasets } });
      }
    });
  };

  const getDashboard = (param) => {
    isRegioChartView(false);
    let url = `${ISS_BASE_URL}/api/service/issue/dashboard`;
    if (param){
      url = url + param;
    }
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        let app = body.app.map((n) => {
          const { issueDate, countryCode, issueSubType, providerId, contentName, detailList, issueId } = n;
          return { row: [issueDate, countryCode, issueSubType, providerId, contentName, detailList[0].shortMsg]
            , issueId : issueId
          };
        });
        setAppIssueSet({ ...appIssueSet, ...{ rowdata : app } });

        let play = body.play.map((n) => {
          const { issueDate, countryCode, issueSubType, providerId, contentName, detailList, issueId } = n;
          return { row: [issueDate, countryCode, issueSubType, providerId, contentName, detailList[0].shortMsg]
            , issueId : issueId
          };
        });
        setPlayIssueSet({ ...playIssueSet, ...{ rowdata : play } });

        let schedule = body.schedule.map((n) => {
          const { issueId, issueDate, providerId, contentCount, countryCodes, shortMsg } = n;
          return { row: [
            issueDate, providerId, countryCodes.split(',').length, contentCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), shortMsg
            ]
            , issueId : issueId
          };
        });
        setScheduleIssueSet({ ...scheduleIssueSet, ...{ rowdata : schedule } });
        setRegionDoughnutChart(body.region);

        if ( body.focusRegion.indexOf('AIC(CA)') > -1 ){
          setClickedDonutCa('AIC(CA)');
        } else {
          setClickedDonutCa('CA');
        }
        if ( body.focusRegion.indexOf('AIC(LATAM)') > -1 ){
          setClickedDonutLatam('AIC(LATAM)');
        } else {
          setClickedDonutLatam('LATAM');
        }
        if ( body.focusRegion.indexOf('AIC(US)') > -1 ){
          setClickedDonutUs('AIC(US)');
        } else {
          setClickedDonutUs('US');
        }
        if ( body.focusRegion.indexOf('EIC(EU)') > -1 ){
          setClickedDonutEu('EIC(EU)');
        } else {
          setClickedDonutEu('EU');
        }
        if ( body.focusRegion.indexOf('KIC(AU)') > -1 ){
          setClickedDonutAu('KIC(AU)');
        } else {
          setClickedDonutAu('AU');
        }
        if ( body.focusRegion.indexOf('KIC(IN)') > -1 ){
          setClickedDonutIn('KIC(IN)');
        } else {
          setClickedDonutIn('IN');
        }
        if ( body.focusRegion.indexOf('KIC(KR/JP)') > -1 ){
          setClickedDonutKrJp('KIC(KR/JP)');
        } else {
          setClickedDonutKrJp('KR/JP');
        }
        isRegioChartView(true);
      } else {
        setAlertTitle('Error');
        setAlertMessage('[getDashboard : error]');
        setIsAlert(true);
      }
    })
    .catch(error => {
      console.log("getDashboard - error: " , error);
      setAlertTitle('Error');
      setAlertMessage('[getDashboard : error]');
      setIsAlert(true);
    }).finally(() => {});
  };

  const onSelect = (id) => {
    setRegions({ ...regions, ...{ checkedList : [id] } });
    chartIssueSearch(id);
  };

  const chartIssueSearch = (regionId) => {
    let chartCountry = [];
    let chartProviders = [];
    chartCountry = searchFilter.countryByRegion.find(region => regionId === region.region).countryList.map(country => country.countryCode);
    if (chartCountry && chartCountry.length > 0){
      let providerList = searchFilter.providerByCountry.filter(f => chartCountry.includes(f.countryCode))
        .map(m => m.providerList).reduce(function (acc, cur) { return acc.concat(cur); }).map( p => p.providerId)
      chartProviders = [...new Set(providerList)]
    }

    let param = '';
    if (chartCountry){
      param += '?countries='+ chartCountry +'&providers='+ chartProviders;
    }
    filterCallback({ country: chartCountry, provider: chartProviders });
    getDashboard(param);
  };

  const issueSearchByButton = () => {
    if (!paramRegions || paramRegions.length < 1
      || !paramCountries || paramCountries.length < 1
      || !paramProviders || paramProviders.length < 1) {
      const showMessage = !paramRegions || paramRegions.length < 1 ? 'region' : !paramCountries || paramCountries.length < 1 ? 'country' : 'cp';
      setAlertTitle('Warning');
      setAlertMessage(`Please select any ${showMessage}`);
      onOpenAlert();
    } else {
      let param = '';
      if (paramCountries){
        param += '?countries='+ paramCountries +'&providers='+ paramProviders;
        filterCallback({ country: paramCountries, provider: paramProviders });
      }
      getDashboard(param);
    }
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const onClickRowItem = (issueTab, item) => {
    let selectedData = {
      issueTab: issueTab, issueId: item.issueId
      , searchRegion : paramRegions, searchCountry : paramCountries, searchProvider: paramProviders
    };
    history.push('/ISS/ServiceDetail', {
      param: JSON.stringify(selectedData),
    });
  };

  const onClickMap = () => {
    setRegions({ ...regions, checkedList: [] });
    filterCallback({ country: initData.countries, provider: initData.providers });
    getDashboard('');
  };

  const handleOpen = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <>
      <div className="grid-vertical-two">
        <div className="grid-left">
          <div className="box-worldmap">
            <div className="head">
              <div className="title">
                <button className="btn-tutorial-2" onClick={handleOpen}/>
                <h1>Monitoring World Map</h1>
              </div>
              <div className="ui-dropdown-box">
                <DropDownForSearch dropDownItem={regions} title="Region" onChangeDropDown={setParamRegions} />
                <DropDownForSearch dropDownItem={countries} title="Countries" onChangeDropDown={setParamCountries} />
                <DropDownForSearch dropDownItem={providers} title="CPs" onChangeDropDown={setParamProviders} />
                <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={issueSearchByButton}/>
              </div>
            </div>
            <div className="ui-chart-container" onClick={onClickMap}>
            { regioChartView &&
              <div>
              <DoughnutLegend />
                {/* AIC(CA) */}
                <DoughnutChart key={serviceCaRegion.id} data={serviceCaRegion} className="donut-chart-07" onSelect={onSelect} onSelectedId={clickedDonutCa} />
                {/* AIC(LATAM) */}
                <DoughnutChart key={serviceLatamRegion.id} data={serviceLatamRegion} className="donut-chart-03" onSelect={onSelect} onSelectedId={clickedDonutLatam} />
                {/* AIC(US) */}
                <DoughnutChart key={serviceUsRegion.id} data={serviceUsRegion} className="donut-chart-01" onSelect={onSelect} onSelectedId={clickedDonutUs} />
                {/* EIC(EU) */}
                <DoughnutChart key={serviceEuRegion.id} data={serviceEuRegion} className="donut-chart-02" onSelect={onSelect} onSelectedId={clickedDonutEu} />
                {/* KIC(AU) */}
                <DoughnutChart key={serviceAuRegion.id} data={serviceAuRegion} className="donut-chart-05" onSelect={onSelect} onSelectedId={clickedDonutAu} />
                {/* KIC(IN) */}
                <DoughnutChart key={serviceInRegion.id} data={serviceInRegion} className="donut-chart-04" onSelect={onSelect} onSelectedId={clickedDonutIn} />
                {/* KIC(KR/JP) */}
                <DoughnutChart key={serviceKrJpRegion.id} data={serviceKrJpRegion} className="donut-chart-06" onSelect={onSelect} onSelectedId={clickedDonutKrJp} />
              </div>
              }
            </div>
          </div>
          <div className="grid-column-2">
            <div className="grid-left">
              <div className="box-appIssues box-tbl">
                <IssTable pagination={false} data={appIssueSet} handleClickItem={item => onClickRowItem(0, item)}/>
              </div>
            </div>
            <div className="grid-right">
              <div className="box-playbackIssues box-tbl">
                <IssTable pagination={false} data={scheduleIssueSet} handleClickItem={item => onClickRowItem(2, item)}/>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-right">
          <div className="box-imageIssues box-tbl">
            <IssTable pagination={false} data={playIssueSet} handleClickItem={item => onClickRowItem(1, item)}/>
          </div>
        </div>
      </div>
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
      {isActive && <TutorialPage data={'serviceDashboard'} isActive={isActive} onClose={handleClose} />}
    </>
  );
};

ServiceDashboard.propTypes = {};
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServiceDashboard));
