import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
export default function CpMemberForm({
  title,
  filter,
  handleInputChange,
  handleSubmit,
  handleClose,
  isIdVisible, //true in add
  isPasswordVisible, //true in add
  isAccessVisible, //true in edit
}) {
  return (
    <div>
      <DialogTitle id="form-dialog-title" className={'pop_head'}>
        {title}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={'pop_body'}>
        <form onSubmit={handleSubmit}>
          <div className={'tbl_wrap'}>
            <table className={'tbl_row_typ1'}>
              <colgroup>
                <col style={{ width: '200px' }} />
                <col style={{ width: 'auto' }} />
              </colgroup>
              <tbody>
                {isIdVisible && (
                  <tr>
                    <th>
                      <label htmlFor={'username'} className={'is_required'}>
                        ID
                      </label>
                    </th>
                    <td>
                      <TextField fullWidth margin="dense" variant="outlined" name="username" id="username" value={filter.username.value} onChange={event => handleInputChange(event)} helperText={filter.username.errorMsg} required autoFocus
                        inputProps={{ maxLength: 15 }} />
                    </td>
                  </tr>
                )}
                {isPasswordVisible && (
                  <React.Fragment>
                    <tr>
                      <th>
                        <label htmlFor={'password'} className={'is_required'}>
                          Password
                        </label>
                      </th>
                      <td>
                        <TextField fullWidth margin="dense" variant="outlined" name="password" id="password" value={filter.password.value} onChange={event => handleInputChange(event)} helperText={filter.password.errorMsg} type="password" required />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label htmlFor={'password'} className={'is_required'}>
                          Re-enter password
                        </label>
                      </th>
                      <td>
                        <TextField fullWidth margin="dense" variant="outlined" name="passwordReEnter" id="passwordReEnter" value={filter.passwordReEnter.value} onChange={event => handleInputChange(event)} type="password" required />
                      </td>
                    </tr>
                  </React.Fragment>
                )}

                <tr>
                  <th>
                    <label htmlFor={'providerId'} className={'is_required'}>
                      CP Code
                    </label>
                  </th>
                  <td>
                    <TextField
                      //id="name"
                      //type="email"
                      //autoComplete="off"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      name="providerId"
                      id="providerId"
                      //label="CP Code"//focus안줬을때 mandatory text
                      //placeholder="CP Code" //focus줬을때 mandatory text
                      value={filter.providerId.value}
                      onChange={event => handleInputChange(event)}
                      helperText={filter.providerId.errorMsg}
                      required
                    />
                  </td>
                </tr>
                {isAccessVisible && (
                  <tr>
                    <th>
                      <label for={'useFlag'} className={'is_required'}>
                        Access
                      </label>
                    </th>
                    <td>
                      <TextField fullWidth margin="dense" variant="outlined" name="useFlag" id="useFlag" value={filter.useFlag.value} onChange={event => handleInputChange(event)} required select>
                        <MenuItem name="useFlag" value="Y">
                          Y
                        </MenuItem>
                        <MenuItem name="useFlag" value="N">
                          N
                        </MenuItem>
                      </TextField>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className={'btn_set_wrap'}>
            <Button
              data-testid="addButton"
              //type="cancel"//error
              color="primary"
              onClick={handleClose}
              className={'btn_color1'}>
              Cancel
            </Button>
            <Button
              data-testid="addButton"
              type="submit"
              color="primary"
              className={'btn_color2'}
            //disabled={isFormInvalid()}
            >
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </div>
  );
}

CpMemberForm.propTypes = {
  filter: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

// const mapStateToProps = (state) => ({
//   isAdded: state.providers.isAdded,
// });

// const mapDispatchToProps = (dispatch) => ({
//   addProvidersAdminAction(json) {
//     dispatch(addProvidersAdminAction(json));
//   },
// });

// export default connect(null, mapDispatchToProps)(withRouter(CpCodeAdd));
