import {ROWHEADER_TYPE, TABLE_TYPE} from "../consts/IssConsts";
import {CHART_COLORS} from "../common/ChartConsts";

export const requestCountDataset = {
    label: 'Request Count',
    data: [],
    fill: false,
    borderColor: CHART_COLORS.MINT,
    pointBorderColor: CHART_COLORS.WHITE,
    pointBorderWidth: 2,
    pointBackgroundColor: CHART_COLORS.MINT
  }

export const impressionCountDataset = {
  label: 'Impression Count',
  data: [],
  fill: false,
  borderColor: CHART_COLORS.PURPLE,
  pointBorderColor: CHART_COLORS.WHITE,
  pointBorderWidth: 2,
  pointBackgroundColor: CHART_COLORS.PURPLE
}

export const impressionSuccessRateDataset = {
  label: 'Render Rate',
  data: [],
  fill: false,
  borderColor: CHART_COLORS.MINT,
  pointBorderColor: CHART_COLORS.WHITE,
  pointBorderWidth: 2,
  pointBackgroundColor: CHART_COLORS.MINT
}

export const AdDetailData = {
  detailTable: {
    tableType: TABLE_TYPE.CHECKBOX_SELECTION,
    title: 'Ad Issues',
    visibleTitle: false,
    existTooltip: false,
    header: ['Date', 'Issue Id', 'AD Type', 'Platform', 'App Name', 'Inventory Name', 'Banner Type',
      'Render Rate', 'Change Rate', 'Action Detail', 'Status', 'Assign', 'Jira URL'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DROPDOWN, ROWHEADER_TYPE.ASSIGN_DROPDOWN, ROWHEADER_TYPE.JIRALINK],
    cellWidth: ['6%', '5%', '5%', '7%', '9%', '11%', '9%', '6%', '6%', '16%', '6%', '4%', '7%'],
    rowdata: [],
    dropdownItem: [
      { index: 'Unaddressed', name: 'Unaddressed' },
      { index: 'Processing', name: 'Processing' },
      { index: 'Resolve', name: 'Resolve' },
    ],
    assignDropdownItem: []
  },
  detailGraph: {
    requestImpressionCount: {
      data: {
        labels: ['D-14', 'D-13', 'D-12', 'D-11', 'D-10', 'D-9', 'D-8', 'D-7', 'D-6', 'D-5', 'D-4', 'D-3', 'D-2', 'D-1', 'Today'],
        datasets: [requestCountDataset, impressionCountDataset]
      },
      options: {
        scales: {
          y: {
            min: 0,
            max: 25,
          },
        }
      }
    },
    impressionSuccessRate: {
      data: {
        labels: ['D-14', 'D-13', 'D-12', 'D-11', 'D-10', 'D-9', 'D-8', 'D-7', 'D-6', 'D-5', 'D-4', 'D-3', 'D-2', 'D-1', 'Today'],
        datasets: [impressionSuccessRateDataset]
      },
      options: {
        baselineSubMax: {
          display: true,
          value: 0,
          title: 'Max',
        },
        baselineSubMin: {
          display: true,
          value: 0,
          title: 'Min',
        },
        scales: {
          y: {
            min: 0,
            max: 100,
          },
        }
      }
    }
  }

}