import * as actions from '../../actions/actionTypes';

const initialState = {
  feedIdx: 1,
  cntryCode: '',
  cpName: '',
  rssPath: '',
  lastChgDate: '',
  state: '',
};
export default function feedEdit(state = initialState, action) {
  switch (action.type) {
    case actions.SET_RSS_FEED_EDIT:
      return action.payload || state;
    default:
      return state;
  }
}
