import React from 'react';
import { Link } from 'react-router-dom';

const IssPageTabs = props => {
  const { tabMenus, currentTab, onChange } = props;

  const onSelectMenu = (selectedIdx, data) => {
    console.log(`#IssPageTabs onSelectMenu=>[${selectedIdx}]\n`, data);
    onChange(selectedIdx, data);
  };

  return tabMenus.map((el, index) => (
    <>
      <div key={index} className={index === currentTab ? 'on' : ''}>
        <h1>
          <Link to="#" onClick={() => onSelectMenu(index, el)}>
            {el.title}
          </Link>
        </h1>
        <div className="ui-tooltip">
          <i>툴팁</i>
          <div className="tooltip">{el.tooltip}</div>
        </div>
      </div>
    </>
  ));
};

export default IssPageTabs;
