import React, { useRef } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import MaterialTable from 'material-table';
//import Button from '@material-ui/core/Button';
import LoadingIndicator from '../../../common/LoadingIndicator';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getTabCategoryAction, getTabCategoryResultAction } from '../../../actions/homeAppActions';
import ManualSearch from './ManualSearch';
import ManualResult from './ManualResult';
import { request } from '../../../util/request';
import { isNormalInteger } from '../../../util/util';

function Manual({ id, tabCategory, getTabCategoryAction, param, regionList, fetching, getTabCategoryResultAction, manualList }) {
  const [cat, setCat] = React.useState(tabCategory.find(n => n.categoryId == id) || {}); //n.categoryId is integer, type is different so using ==
  const [cntryList, setCntryList] = React.useState([' ']);
  const [cntry, setCntry] = React.useState(' ');
  const [result, setResult] = React.useState([]);
  const [addData, setAddData] = React.useState([]);
  const [deleteData, setDeleteData] = React.useState([]);
  const [typeData, setTypeData] = React.useState(' ');
  const [insertTableData, setInsertTableData] = React.useState([]);
  const [isItEdit, setIsItEdit] = React.useState(false);
  const selectedTableRef = useRef();

  const renderOrderEdit = n => (
    <TextField
      fullWidth
      margin="dense"
      onFocus={() => setIsItEdit(false)}
      value={isItEdit ? n.result_order : undefined}
      defaultValue={!isItEdit ? n.result_order : undefined}
      onBlur={e => {
        if (e.target.value === '0') alert('The order must be greater than 0 ! Please re-enter !');
        else if (!isNormalInteger(e.target.value) && e.target.value !== '') alert('The order is not natural number ! Please enter natural number !');
        else {
          if (typeData === 'Insert') {
            const newResult = insertTableData.slice();
            newResult.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id).result_order = e.target.value;
            setResult(newResult);
          }
          else {
            const newResult = result.slice();
            newResult.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id).result_order =e.target.value;
            setResult(newResult);
          }
        }
      }}
    />
  );

  const renderOrder = n => (
    <TextField
      fullWidth
      margin="dense"
      value={n.result_order}
      onChange={e => {
        if (e.target.value === '0') alert('The order must be greater than 0 ! Please re-enter !');
        else if (!isNormalInteger(e.target.value) && e.target.value !== '') alert('The order is not natural number ! Please enter natural number !');
        else {
          if (typeData === 'Insert') {
            const newResult = insertTableData.slice();
            newResult.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id).result_order = e.target.value;
            setResult(newResult);
          } else {
            const newResult = result.slice();
            newResult.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id).result_order =e.target.value;
            setResult(newResult);
          }
        }
      }}
    />
  );
  const checkTvShowSearies = rows => {
    return rows.find(
      n =>
        result.filter(r => {
          if (r.category_idx) {
            return r.provider_idx === n.cp_idx && r.series_id === n.series_id && n.type === 'tvshow-series';
          } else {
            return r.cp_idx === n.cp_idx && r.series_id === n.series_id && n.type === 'tvshow-series';
          }
        })[0],
    );
  };

  const checkDuplicateSeriesList = data => {
    const tvShowData = data.filter(n => n.type === 'tvshow-series');
    const lookup = tvShowData.reduce((a, e) => {
      a[e.series_id] = ++a[e.series_id] || 0;
      return a;
    }, {});

    return tvShowData.filter(e => lookup[e.series_id]).length >= 2;
  };

  const selectSearch = rows => {
    let startOrder = Math.max(...result.map(n => n.result_order)) + 1;
    startOrder = startOrder === -Infinity ? 1 : startOrder;

    setAddData(
      rows.filter(n => (result.find(n2 => n2.content_id === n.id && n2.cp_id === n.cp) === undefined)).map((n, i) => {
        const { cp_idx, cp, id, name, image, type, series_id, genre } = n;
        return { cp_idx: cp_idx, cp_id: cp, name: name, image: image, country_code: cntry, type: type, content_id: id, result_order: startOrder + i, series_id: series_id, genre: genre };
      })
    );
  };

  const selectResult = rows => {
    setDeleteData(
      rows.map(n => {
        const { cp_idx, content_id, name, type } = n;
        return { cp_idx: cp_idx, content_id: content_id, name: name, type: type };
      }),
    );
  };

  const addResult = () => {
    if (addData.find(n => result.find(n2 => n2.content_id === n.content_id && n2.cp_idx === n.cp_idx))) {
      alert('content is already exist !');
    } else if (checkTvShowSearies(addData) || checkDuplicateSeriesList(addData)) {
      alert('Only one season allowed per series!');
      selectedTableRef.current.onAllSelected(false);
    } else {
      selectedTableRef.current.onAllSelected(false);
      setResult(result.concat(addData));
      setInsertTableData(insertTableData.concat(addData))
      setAddData([]);
    }

  };
  const removeResult = () => {
    setIsItEdit(true);
    if (typeData === 'Insert') {
      setResult(insertTableData.filter(n => !deleteData.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id)));
      setInsertTableData(insertTableData.filter(n => !deleteData.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id)));
    } else {
      setResult(result.filter(n => !deleteData.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id)));
    }
    setDeleteData([]);

  };
  /*
  Manual 카테고리 서버에서 데이터를 받아오는 부분.
  */
  /*
  const requestResult = async () => {
    const categoryResult = await request(`/api/category/${id}/result?cntry=${cntry}`, 'GET', null, true);
    if (cntry) setResult(categoryResult);
  };
  */
  React.useEffect(() => {
    if (!tabCategory.length) getTabCategoryAction(param.deviceType, param.region, param.platformVersion, param.status.charAt(0));
    if (cntry != ' ') {
      getTabCategoryResultAction(cntry, id);
      setResult(manualList);
    }
  }, [id, cntry]);

  React.useEffect(() => {
    setCat(tabCategory.find(n => n.categoryId == id) || {});
  }, [tabCategory]);
  React.useEffect(() => {
    setCntryList(((regionList.find(n => n.deviceType === param.deviceType) || { list: [] }).list.find(n2 => n2.region === param.region) || { cntry: [] }).cntry);
  }, [regionList]);
  React.useEffect(() => {
    setCntry(cntryList[0]);
  }, [cntryList]);

  const handleSetResult = (data) => {
    setResult([...data])
  }
  const handleSetInsertTableData = (data) => {
    setInsertTableData([...data])
  }
  const handleSetTypeData = (data) => {
    setTypeData(data)
  }
  const handleIsItEditData = (data) => {
    setIsItEdit(data)
  }

  return (
    <div>
      <React.Fragment>
        <CntryFilter cntryList={cntryList} cntry={cntry} setCntry={setCntry} />
        <div className="channel_mapping_wrap">
          <ManualSearch cntry={cntry} tableRef={selectedTableRef} cat={cat} selectSearch={selectSearch} status={param.status.charAt(0)} categoryResultData={result} region={param.region} />
          {fetching ?
            (
              <div className="loading_wrap">
                <LoadingIndicator />
              </div>
            ) :
            (
              <ManualResult id={id} cntry={cntry} cat={cat} result={result} handleSetResult={handleSetResult} renderOrder={renderOrder} renderOrderEdit={renderOrderEdit} selectResult={selectResult} onClickAdd={addResult} onClickDelete={removeResult} handleSetInsertTableData={handleSetInsertTableData} handleSetTypeData={handleSetTypeData} typeData={typeData} insertTableData={insertTableData} setTypeData={setTypeData}/>
            )
          }
        </div>
      </React.Fragment>
    </div>
  );
}
Manual.propTypes = {};
const mapStateToProps = state => ({
  fetching: state.homeApp.manualResultFetching,
  manualList: state.homeApp.manualResultList,
  regionList: state.homeApp.regionList,
  param: state.homeApp.param,
  tabCategory: state.homeApp.tabCategory,
});
const mapDispatchToProps = dispatch => ({
  getTabCategoryAction(deviceType, region, platformVersion, status) {
    dispatch(getTabCategoryAction(deviceType, region, platformVersion, status));
  },
  getTabCategoryResultAction(cntry, id) {
    dispatch(getTabCategoryResultAction(cntry, id));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Manual));

export function CntryFilter({ cntryList, cntry, setCntry }) {
  return (
    <div className="filter_area row manualCat">
      <div className="filter_row manualCat">
        <FormControl variant="outlined">
          <InputLabel>Country</InputLabel>
          <Select
            value={cntry}
            onChange={e => {
              setCntry(e.target.value);
            }}>
            {cntryList.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
