import React from 'react';
import { connect } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import { request } from '../../../../util/request';

function UploadImagePopup({ title, handleClose, selectRowData, result, setResult, id, cntry, cat }) {
  const [horizontalImg, setHorizontalImg] = React.useState('');
  const [bigImg, setBigImg] = React.useState('');
  const [horizontalEdit, setHorizontalEdit] = React.useState(false);
  const [bigEdit, setBigEdit] = React.useState(false);
  const [horizontalImgDelYn, setHorizontalImgDelYn] = React.useState('N');
  const [bigImgDelYn, setBigImgDelYn] = React.useState('N');
  const [horizontalInfo, setHorizontalInfo] = React.useState({ w: '', h: '' });
  const [bigInfo, setBigInfo] = React.useState({ w: '', h: '' });
  const prefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;

  const clickSave = async e => {
    e.preventDefault();

    alert('Are you sure to save this?');
    let requestBody = { };
    const horizontalImg = document.getElementById('hidden_file_horizontal').files[0];
    const bigImg = document.getElementById('hidden_file_big').files[0];
    requestBody = new FormData();
    if (horizontalImg) requestBody.append('horizontalImg', horizontalImg);
    if (bigImg) requestBody.append('bigImg', bigImg);
    requestBody.append('horizontalImgDelYn', horizontalImgDelYn);
    requestBody.append('bigImgDelYn', bigImgDelYn);
    requestBody.append('categoryIdx', id);
    requestBody.append('sliderIdx', selectRowData.slider_idx);
    requestBody.append('cntry', cntry);
    requestBody.append('contentId', selectRowData.content_id);
    let isSuccess = await request(`/api/category/slider/image`, 'PATCH', requestBody, true, false, true);
    if (isSuccess.result === 'success') {
      setHorizontalEdit(false);
      setBigEdit(false);
      handleContentImage(isSuccess);
      handleClose();
    } else {
      alert(isSuccess.result + isSuccess.message);
    }
  };

  const handleContentImage = (response) => {
    const newData = result.slice();
          newData.find(n => n.content_id === selectRowData.content_id).horizontalImg = response.horizontalImg;
          newData.find(n => n.content_id === selectRowData.content_id).bigImg = response.bigImg;
          setResult(newData);
  };

  React.useEffect(() => {
    setHorizontalImg(
      result.find(n => n.content_id === selectRowData.content_id).horizontalImg
    );
    setBigImg(
      result.find(n => n.content_id === selectRowData.content_id).bigImg
    );
  }, []);

  return (
    <div>
      <DialogTitle id="form-dialog-title" className={'pop_head'}>
        {title}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={'pop_body'}>
        <form>
          <div className={'tbl_wrap'}>
            <table className={'tbl_row_typ1'}>
              <colgroup>
                <col style={{ width: '200px' }} />
                <col style={{ width: 'auto' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <label htmlFor={'tabCategory'}>
                      Tab/Category
                    </label>
                  </th>
                  <td>
                      {cat.parentName} / {cat.name}
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor={'country'}>
                      Country
                    </label>
                  </th>
                  <td>
                      {cntry}
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor={'contentId'}>
                      Content ID
                    </label>
                  </th>
                  <td>
                    {selectRowData.content_id}
                  </td>
                </tr>
                <tr>
                  <th>
                    <label htmlFor={'contentName'}>
                      Content Name
                    </label>
                  </th>
                  <td>
                    {selectRowData.name}
                  </td>
                </tr>
                <tr>
                <th>Horizontal Image</th>
                <td>
                  {horizontalImg && (
                  <img
                    alt="invalid"
                    src={horizontalEdit === true ? horizontalImg : prefix + horizontalImg}
                    title=''
                    style={{ maxWidth: '100%', backgroundColor: 'black' }}
                    onLoad={e => {
                      setHorizontalInfo({ w: e.target.naturalWidth, h: e.target.naturalHeight });
                    }}
                  />
                   )}
                  <Grid container style={{ marginTop: '5px' }}>
                    <Grid>
                      <p className="col-md-9">Recommended Size: 1720 x 968</p>
                      {horizontalImg && (
                        <p className="col-md-9">
                          Actual Image Size: {horizontalInfo.w} X {horizontalInfo.h}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  {<EditImg img={horizontalImg} setImg={setHorizontalImg} isEdit={horizontalEdit} setIsEdit={setHorizontalEdit} setDeleteYn={setHorizontalImgDelYn} type="horizontal" />}
                </td>
              </tr>
              <tr>
                <th>Big Image</th>
                <td>
                  {bigImg && (
                  <img
                    alt="invalid"
                    src={bigEdit === true ? bigImg : prefix + bigImg}
                    title=''
                    style={{ maxWidth: '100%', backgroundColor: 'black' }}
                    onLoad={e => {
                      setBigInfo({ w: e.target.naturalWidth, h: e.target.naturalHeight });
                    }}
                  />
                   )}
                  <Grid container style={{ marginTop: '5px' }}>
                    <Grid>
                      <p className="col-md-9">Recommended Size: 3262 x 968</p>
                      {bigImg && (
                        <p className="col-md-9">
                          Actual Image Size: {bigInfo.w} X {bigInfo.h}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  {<EditImg img={bigImg} setImg={setBigImg} isEdit={bigEdit} setIsEdit={setBigEdit} setDeleteYn={setBigImgDelYn} type="big" />}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'btn_set_wrap'}>
            <Button className="btn_color2" onClick={clickSave}>
              save
            </Button>
            <Button data-testid="addButton" color="primary" onClick={handleClose} className={'btn_color1'}>
              Cancel
            </Button>
          </div>
        </form>
      </DialogContent>
    </div>
  );
}
UploadImagePopup.propTypes = {
};
const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadImagePopup);

function EditImg({ img, setImg, isEdit, setIsEdit, setDeleteYn, type }) {

  const [imgName, setImgName] = React.useState(img);
  return (
    <div className="logo_preview_add">
      {/* <p className="logo_preview_comment">※ Recommended: ?PX height and ?PX width</p> */}
      <input
        accept="image/*"
        id={`input_file_${type}`}
        type="file"
        className="logo_preview_input"
        onChange={e => {
          if (e.target.files && e.target.files.length >= 1) {
            const reader = new FileReader();
            const img = e.target.files[0];
            reader.readAsDataURL(img);
            reader.onload = e2 => {
              document.getElementById(`hidden_file_${type}`).files = document.getElementById(`input_file_${type}`).files;
              setImg(e2.target.result);
              setImgName(img.name);
              setIsEdit(true);
              setDeleteYn('N');
            };
          }
        }}
      />
      <input type="file" id={`hidden_file_${type}`} hidden ></input>
      <Grid container spacing={3} style={{ marginTop: '5px' }}>
        <Grid item sm={9}>
          <p className="col-md-9">{imgName}</p>
        </Grid>
        <Grid item sm={3}>
          <label htmlFor={`input_file_${type}`}>
            <Button component="span" className="btn_color4" style={{ width: 110 }}>
              Upload File
            </Button>
          </label>
          {(isEdit || img !== '') && (
            <label>
              {img && (
                <Button
                  component="span"
                  className="btn_color4"
                  style={{ width: 110, marginTop : 10 }}
                  onClick={() => {
                    setImg('');
                    setImgName('');
                    setDeleteYn('Y');
                    document.getElementById(`input_file_${type}`).value = '';
                    setIsEdit(true);
                  }}>
                  Delete File
                </Button>
              )}
            </label>
          )}
        </Grid>
      </Grid>
    </div>
  );
}