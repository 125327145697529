import * as actions from '../../actions/actionTypes';

const initialState = null;

export default function viewData(state = initialState, action) {
  switch (action.type) {
    case actions.SET_VIEW_DATA:
      return action.payload;
    case actions.SET_CHANNEL_MAPPING:
      return state;
    default:
      return state;
  }
}
