import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IssTabs from '../common/IssTabs';
import IssTab from '../common/IssTab';
import DropDown from '../common/DropDown';
import DropDownForSearch from '../common/DropDownForSearch';
import RadioDropDown from '../common/RadioDropDown';
import DefaultButton from '../common/DefaultButton';
import DatePickerService from '../common/DatePickerService';
import ServiceCommon from './ServiceCommon';
import { ISS_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import Alert from '../modal/Alert';
import Loading from '../common/Loading';
import InfoIcon from "@material-ui/icons/Info";
import Chip from "@material-ui/core/Chip";
import Info from "../modal/Info";
import TutorialPage from '../TutorialPage';

export function ServiceDetail() {
  const today = new Date();
  const utcToday = new Date(today.toUTCString());
  const period = 60;
  const pastDay = new Date(utcToday);
  pastDay.setDate(pastDay.getDate() - period);
  const utcTodayString = utcToday.toISOString().substring(0, 10);
  const pastDayString = pastDay.toISOString().substring(0, 10);
  const history = useHistory();
  const location = useLocation();
  const [isIssueView, setIsIssueView] = useState(false);
  const [issueTabCount, changeIssueTabCount] = useState({ app: 0, play: 0, serviceData: 0, image: 0, schedule: 0 , sum: 0 });
  const [issueTabTooltip, changeIssueTabTooltip] = useState({ app: 'App Issues tooltip', play: 'Play Issues tooltip', schedule: 'Schedule Issues tooltip'});
  const [currentTab, changeCurrentTab] = useState(0);
  const [searchFilter, setSearchFilter] = useState({});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [regions, setRegions] = useState({'dropdownList': [], 'checkedList' : []});
  const [countries, setCountries] = useState({'dropdownList': [], 'checkedList' : []});
  const [providers, setProviders] = useState({'dropdownList': [], 'checkedList' : []});
  const [platforms, setPlatforms] = useState({ 'dropdownList': [] });
  const [searchSourceType] = useState({ dropdownList: ['CMP', 'beacon', 'telemetry', 'AutoTest'] });
  const [searchStatusType] = useState({ dropdownList: ['Unaddressed', 'Processing'] });
  const [searchAssignType, setSearchAssignType] = useState({ dropdownList: [] });
  const [searchContentsType] = useState({ dropdownList: ['tab', 'channel', 'vod', 'season'] });
  const searchTypeList = [
    { type: 'contentName', value: 'Content Name' },
    { type: 'issueId', value: 'Issue Id' },
    { type: 'contentID', value: 'Content ID' },
    { type: 'errorCode', value: 'Error Code' }
  ];
  const [searchType] = useState({ dropdownList: searchTypeList.map(m => m.value ) });
  const [paramCalendar, setParamCalendar] = useState([pastDayString, utcTodayString]);
  const [paramRegions, setParamRegions] = useState([]);
  const [paramCountries, setParamCountries] = useState([]);
  const [paramProviders, setParamProviders] = useState([]);
  const [paramPlatforms, setParamPlatforms] = useState([]);
  const [paramSource, setParamSource] = useState(['CMP', 'beacon', 'telemetry', 'AutoTest']);
  const [paramState, setParamState] = useState(['Unaddressed', 'Processing']);
  const [paramAssign, setParamAssign] = useState([]);
  const [paramContentsType, setParamContentsType] = useState(['tab', 'channel', 'vod', 'season']);
  const [paramSearchType, setParamSearchType] = useState('contentName');
  const [paramSearchText, setParamSearchText] = useState('');
  const [checkedCountryList, setCheckedCountryList] = useState([]);
  const [checkedProviderList, setCheckedProviderList] = useState([]);
  const [searchParam, setSearchParam] = useState({});
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState({});
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    getText();
    getSearchFilter();
  }, [location]);

  useEffect(() => {
    if ( searchFilter.countryByRegion ) {
      let dropdownCountries = [];
      let dropdownCheckedCountries = [];
      // List to display in dropdown box
      if (paramRegions && paramRegions.length > 0){
        dropdownCountries = searchFilter.countryByRegion.filter(f => paramRegions.includes(f.region)).map(region =>
          region.countryList.map(map => ({ id: map.countryCode, value : map.fullName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownCountries.sort((a, b) => a.value.localeCompare(b.value));
      }
      // location parameter initial value
      if (checkedCountryList && checkedCountryList.length > 0 ) {
        dropdownCheckedCountries = [ ...checkedCountryList];
        setCheckedCountryList([]);
      }
      setCountries({'dropdownList': dropdownCountries, 'checkedList' : dropdownCheckedCountries });
    }
  }, [ paramRegions ]);

  useEffect(() => {
    if ( searchFilter.countryByRegion &&  searchFilter.providerByCountry) {
      let dropdownProviders = [];
      let dropdownCheckedProviders = [];
      // List to display in dropdown box
      if (paramCountries && paramCountries.length > 0){
        let ProviderList = searchFilter.providerByCountry.filter(f => paramCountries.includes(f.countryCode)).map(map =>
          map.providerList.map(pr => ({ id: pr.providerId, value : pr.providerName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownProviders = [...new Set(ProviderList.map((list) => JSON.stringify(list)))].map((list) => JSON.parse(list));
        dropdownProviders.sort((a, b) => a.value.localeCompare(b.value));
      }
      // location parameter initial value
      if (checkedProviderList && checkedProviderList.length > 0 ) {
        dropdownCheckedProviders = [ ...checkedProviderList];
        setCheckedProviderList([]);
      }
      setProviders({ 'dropdownList': dropdownProviders, 'checkedList' : dropdownCheckedProviders });
    }
  }, [ paramCountries ]);

  const getText = () => {
    fetch(`${ISS_BASE_URL}/api/common/text`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        changeIssueTabTooltip({
          app: (body.text.DETAIL_SERVICE_APP_TOOLTIP) ? body.text.DETAIL_SERVICE_APP_TOOLTIP : ''
          , play: (body.text.DETAIL_SERVICE_PLAY_TOOLTIP) ? body.text.DETAIL_SERVICE_PLAY_TOOLTIP : ''
          , schedule: (body.text.DETAIL_SERVICE_SCHEDULE_TOOLTIP) ? body.text.DETAIL_SERVICE_SCHEDULE_TOOLTIP : ''
        });
        setInfoMessage(
            [
              {name: 'LG Channels HomeApp',
              message: body.text.DETAIL_SERVICE_APP_ISSUE_INFO ? body.text.DETAIL_SERVICE_APP_ISSUE_INFO : ''},
              {name: 'Playback',
              message: body.text.DETAIL_SERVICE_PLAY_ISSUE_INFO ? body.text.DETAIL_SERVICE_PLAY_ISSUE_INFO : ''},
              {name: 'Schedule',
              message: body.text.DETAIL_SERVICE_SCHEDULE_ISSUE_INFO ? body.text.DETAIL_SERVICE_SCHEDULE_ISSUE_INFO : ''}
            ]);
      } else {
        setAlertTitle('Error');
        setAlertMessage('getText : error');
        onOpenAlert();
      }
    })
    .catch(error => {
      console.log('getText : error', error);
    }).finally(() => {});
  };

  const getSearchFilter = () => {
    setIsIssueView(false);
    fetch(`${ISS_BASE_URL}/api/common/search-filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        // set useState (SearchFilter)
        setSearchFilter({
          countryByRegion: body.countryByRegion
          , providerByCountry: body.providerByCountry
          , platform: body.platform
        })
        // set dropdown Region
        let dropdownRegion = [];
        let dropdownCheckedRegion = [];
        // countryByRegion
        if ( body.countryByRegion ) {
          dropdownRegion = body.countryByRegion.map(map =>({ id: map.region, value : map.region }));
          dropdownRegion.sort((a, b) => a.value.localeCompare(b.value));
          if ( (location && location.state && JSON.parse(location.state.param).searchRegion)){
            dropdownCheckedRegion = JSON.parse(location.state.param).searchRegion
          }
        }
        if ( (location && location.state && JSON.parse(location.state.param).searchCountry)){
          setCheckedCountryList(JSON.parse(location.state.param).searchCountry)
        }
        if ( (location && location.state && JSON.parse(location.state.param).searchProvider)){
          setCheckedProviderList(JSON.parse(location.state.param).searchProvider)
        }
        setRegions({ 'dropdownList': dropdownRegion, 'checkedList' : dropdownCheckedRegion });

        let platforms = [];
        let platformCodes = [];
        if ( body.platform ) {
          platforms = body.platform.map(map => map.productPlatform);
          body.platform.map(map => {
            platformCodes.push(map.productPlatform);
          })
          setPlatforms({ 'dropdownList': platforms });
        }

        let assignee = [];
        if ( body.management ) {
          assignee = body.management.assignee.map(map => map.groupName);
          setSearchAssignType({ 'dropdownList': assignee });
        }
        changeCurrentTab(((location && location.state) && JSON.parse(location.state.param).issueTab) ? JSON.parse(location.state.param).issueTab : 0);
        // set SearchParam
        setSearchParam({
          dates: paramCalendar
          , countries: ((location && location.state) &&  JSON.parse(location.state.param).searchCountry ) ? JSON.parse(location.state.param).searchCountry: []
          , providers: ((location && location.state) &&  JSON.parse(location.state.param).searchProvider ) ? JSON.parse(location.state.param).searchProvider: []
          , platforms: platformCodes
          , issueRoot: paramSource
          , issueState: paramState
          , assignGroup: assignee
          , contentsType: searchContentsType.dropdownList.find(fi => !paramContentsType.includes(fi)) ? paramContentsType : []
          , searchType: paramSearchType
          , searchText: (paramSearchText ? paramSearchText.trim() : '')
          , currentTabIdx: ((location && location.state) && JSON.parse(location.state.param).issueTab) ? JSON.parse(location.state.param).issueTab : 0
          , currentIssueId: ((location && location.state) && JSON.parse(location.state.param).issueId) ? JSON.parse(location.state.param).issueId : ''
        });
        setIsIssueView(true);
      } else {
        setAlertTitle('Error');
        setAlertMessage('getSearchFilter : error');
        onOpenAlert();
      }
    })
    .catch(error => {
      console.log('getSearchFilter : error', error);
    }).finally(() => {});
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  //------------------------------------
  //tab 관련
  //------------------------------------
  const tabMenus = [
    {
      name: `LG Channels HomeApp (${issueTabCount.app.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`,
      tooltip: issueTabTooltip.app,
      isDisplay : true
    },
    {
      name: `Playback (${issueTabCount.play.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`,
      tooltip: issueTabTooltip.play,
      isDisplay : true
    },
    {
      name: `Schedule (${issueTabCount.schedule.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`,
      tooltip: issueTabTooltip.schedule,
      isDisplay : true
    },
  ];

  const tabChangeHandler = (selectedIdx, _data) => {
    changeCurrentTab(selectedIdx);
    setSearchParam({ ...searchParam, ...{ currentTabIdx: selectedIdx,  currentIssueId: '' } });
  };

  const handleBackButton = (item) => {
    const selectedLocation = { type : item };
    history.push(`/ISS/IssDashboard`, {
      param: JSON.stringify(selectedLocation),
    });
  };

  const issueSearchByButton = () => {
    let showMessage = '';
    if (!paramCalendar || paramCalendar.length < 2) {
      showMessage = 'search period';
      setAlertTitle('Warning');
      setAlertMessage(`Please select any ${showMessage}`);
      onOpenAlert();
      return;
    }
    if (!paramRegions || paramRegions.length < 1
      || !paramCountries || paramCountries.length < 1
      || !paramProviders || paramProviders.length < 1
      || !paramPlatforms || paramPlatforms.length < 1) {
      showMessage = !paramRegions || paramRegions.length < 1 ? 'region' : !paramCountries || paramCountries.length < 1 ? 'country' : !paramProviders || paramProviders.length < 1 ? 'cp' : 'platform';
      setAlertTitle('Warning');
      setAlertMessage(`Please select any ${showMessage}`);
      onOpenAlert();
      return;
    }
    if (!paramSource || paramSource.length < 1
      || !paramState || paramState.length < 1
      || !paramAssign || paramAssign.length < 1) {
      showMessage = !paramSource || paramSource.length < 1 ? 'issue Source' : !paramState || paramState.length < 1 ? 'issue Status' : 'assign Group';
      setAlertTitle('Warning');
      setAlertMessage(`Please select any ${showMessage}`);
      onOpenAlert();
      return;
    }

    const platformCodes = searchFilter.platform.filter(item => paramPlatforms.includes(item.productPlatform)).map(item => item.productPlatform);
    let type = searchTypeList.filter(f => f.value === paramSearchType).map(m => m.type);

    setSearchParam({
      dates: paramCalendar
      , countries: paramCountries
      , providers: paramProviders
      , platforms: platformCodes
      , issueRoot: paramSource
      , issueState: paramState
      , assignGroup: paramAssign
      , contentsType: searchContentsType.dropdownList.find(fi => !paramContentsType.includes(fi)) ? paramContentsType : []
      , searchType: type
      , searchText: (paramSearchText ? paramSearchText.trim() : '')
      , currentTabIdx: currentTab
      , currentIssueId: ((location && location.state) && JSON.parse(location.state.param).issueId) ? JSON.parse(location.state.param).issueId : ''
    });
  };

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      issueSearchByButton();
    }
  };

  const handleTextInputChange = event => {
    setParamSearchText(event.target.value);
  };

  const onOpenInfo = () => {
    setIsOpenInfo(true);
  }

  const onCloseInfo = () => {
    setIsOpenInfo(false);
  }

  const handleOpen = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <div className="iss-main">
      <div className="main-container">
        <div className="inner">
          <div className="sub-detail-wrapper sub-detail-service">
            <div className="head">
              <div className="title">
                <button className="btn-tutorial-2" onClick={handleOpen}/>
                <h1>Service Detail (Issue Count : {issueTabCount.sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})</h1>
              </div>
              <div className="back" onClick={() => handleBackButton('Service')}>
                <button></button>
              </div>
              <Chip label={"Info"} icon={<InfoIcon/>} className={'info'} onClick={onOpenInfo} />
            </div>
            <div style ={{ padding:'20px 20px 0 20px', border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px' }}>
              <div style={{ height : '50px', display: 'flex', gap:'8px' }}>
                <DatePickerService
                  dateChangeHandler={setParamCalendar}
                  receiveDate={paramCalendar}
                />
                <DropDownForSearch dropDownItem={regions} title="Region" onChangeDropDown={setParamRegions} />
                <DropDownForSearch dropDownItem={countries} title="Countries" onChangeDropDown={setParamCountries} />
                <DropDownForSearch dropDownItem={providers} title="CPs" onChangeDropDown={setParamProviders} />
                <DropDown item={platforms} title="Platforms" onChangeDropDown={setParamPlatforms} />
              </div>
              <div style={{ height : '50px', display: 'flex', gap:'8px' }}>
                <DropDown item={searchSourceType} title="Source" onChangeDropDown={setParamSource} />
                <DropDown item={searchStatusType} title="Status" onChangeDropDown={setParamState} />
                <DropDown item={searchAssignType} title="Assign" onChangeDropDown={setParamAssign} />
                {( currentTab !== 2) && (
                  <DropDown item={searchContentsType} title="Contents Type" onChangeDropDown={setParamContentsType} checkedList={paramContentsType} />
                )}
                <RadioDropDown item={searchType} onChangeDropDown={setParamSearchType} />
                <div className="content-name">
                  <input type="text" value={paramSearchText} placeholder={paramSearchType}
                    onChange={event => handleTextInputChange(event)}
                    onKeyPress={handleOnKeyPress}
                    style={{ width: '200px', height: '32px', padding: '0 8px', border: '1px solid rgba(255, 255, 255, 0.2)', backgroundColor: 'transparent', color: '#fff', fontSize: '14px' }}
                  />
                </div>
                <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={issueSearchByButton}/>
              </div>
            </div>
            <br/>
            <div className="box-components">
            { isIssueView ? (
              <IssTabs tabMenus={tabMenus} currentTab={currentTab} className={'ui-tab-type03'} onChange={tabChangeHandler}>
                <IssTab isActive={currentTab === 0}/>
                <IssTab isActive={currentTab === 1}/>
                <IssTab isActive={currentTab === 2}/>
                {/* <IssTab isActive={currentTab === 3}/>
                <IssTab isActive={currentTab === 4}/> */}
                <ServiceCommon
                  searchParam={searchParam}
                  changeIssueTabCount={changeIssueTabCount}
                />
              </IssTabs>
              ) : (
                <div className="loading_wrap">
                  <Loading isVisible={true} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
      {isOpenInfo && <Info isOpen={isOpenInfo} title={'Service Issue Info'} message={infoMessage} onClose={onCloseInfo} className="modal-alert-long"/>}
      {isActive && <TutorialPage data={'serviceDetail'} isActive={isActive} onClose={handleClose} />}
    </div>
  );
}

ServiceDetail.propTypes = {};
const mapStateToProps = state => ({});
export default connect(mapStateToProps)(withRouter(ServiceDetail));
