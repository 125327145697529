import React from 'react';
import ServiceDashboard from './ServiceDashboard';
import ContentsDashboard from './ContentsDashboard';
import StatisticsDashboard from './StatisticsDashboard';
import AdDashboard from "./AdDashboard";

function ContentArea ({ currentTab, changeFilter }) {
  let content;
  if (currentTab === 0) {
    content = <ServiceDashboard filterCallback={filter => changeFilter(filter)} />;
  } else if (currentTab === 1) {
    content = <ContentsDashboard filterCallback={filter => changeFilter(filter)} />;
  } else if (currentTab === 2) {
    content = <StatisticsDashboard filterCallback={filter => changeFilter(filter)} />;
  } else if (currentTab === 3) {
    content = <AdDashboard filterCallback={filter => changeFilter(filter)} />;
  }

  return <div className="contents-section">{content}</div>;
}

export default ContentArea;