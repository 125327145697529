import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from '@material-ui/core/Modal';
import PrimaryButton from '../common/PrimaryButton';
import ListDropDown from '../common/ListDropDown';
import { select_data_type } from './JiraData';
import DatePicker from 'react-multi-date-picker';
import { getHeaders } from '../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../constants/index';

function CreateJiraModal({ username, isOpen, onClose, type, issueIdList, jiraCallback, jiraStatusCallback, jiraDescription }) {
  const [open, setOpen] = useState(isOpen);

  const [jiraDueDate, setJiraDueDate] = useState('');
  const [jiraTitle, setJiraTitle] = useState('');
  const [jiraDetails, setJiraDetails] = useState('');
  const [jiraPriority, setJiraPriority] = useState('');
  const [jiraIssueCategoryFirst, setJiraIssueCategoryFirst] = useState('');
  const [jiraIssueCategorySecond, setJiraIssueCategorySecond] = useState('');
  const [jiraReceiveOrg, SetJiraReceiveOrg] = useState('');
  const [jiraAssignee, setJiraAssignee] = useState('');

  const [groupList, setGroupList] = useState([]);
  const [managementGroupFetching, setManagementGroupFetching] = useState('init');
  const [displayGroupNameList, setDisplayGroupNameList] = useState([]);
  const [displayAssigneeNameList, setDisplayAssigneeNameList] = useState([]);
  const [displayAssigneeName, setDisplayAssigneeName] = useState('');

  const issueCategory = ['Content', 'Service', 'Statistics', 'Advertisement'];
  const issueCategoryContent = ['CP', 'CMPOrganization', 'CMPDeployment'];
  const issueCategoryService = ['LGCHomeApp', 'Playback', 'Schedule'];
  const issueCategoryStatistics = ['StatisticsChange', 'BeaconData'];

  useEffect(() => {
    if (!open) {
      //상위 close 이벤트 전달.
      onClose();
    }

    return () => {
      setOpen(false);
    };
  }, [open]);

  useEffect(() => {
    getManagementGroup();

    const today = new Date();
    const utcToday = new Date(today.toUTCString());
    const utcTodayString = utcToday.toISOString().substring(0, 10);

    setJiraDueDate(utcTodayString);
    setJiraPriority(select_data_type.priority[1].name);

    let issueCategorySecond = 'None';
    if (type === 'CONTENTS') {
      setJiraIssueCategoryFirst(issueCategory[0]);
      issueCategorySecond = issueIdList[0].startsWith('CN0') ? issueCategoryContent[0] :
        issueIdList[0].startsWith('CN1') ? issueCategoryContent[1] : issueCategoryContent[2];
    } else if (type === 'STATISTICS' || type === 'BEACON') {
      setJiraIssueCategoryFirst(issueCategory[2]);
      issueCategorySecond = issueIdList[0].startsWith('ST0') || issueIdList[0].startsWith('ST1') ? issueCategoryStatistics[0] : issueCategoryStatistics[1];
    } else if (type === 'AD') {
      setJiraIssueCategoryFirst(issueCategory[3]);
    } else {
      setJiraIssueCategoryFirst(issueCategory[1]);
      issueCategorySecond = issueIdList[0].startsWith('SVAP') ? issueCategoryService[0] :
        issueIdList[0].startsWith('SVPL') ? issueCategoryService[1] : issueCategoryService[2];
    }

    setJiraIssueCategorySecond(issueCategorySecond);
  }, []);

  const handleDate = (day) => {
    const tempDate = new Date(day);
    const dueDate = new Date(tempDate.toUTCString());
    const dueDateString = dueDate.toISOString().substring(0, 10);

    setJiraDueDate(dueDateString);
  };

  const handleGroupName = (selectedValue) => {
    const tempAssigneeNameList = groupList.filter(item => item.groupName === selectedValue.status).map(item => {
      return { index: item.assigneeName, name: item.assigneeName }
    });

    setDisplayAssigneeNameList([...tempAssigneeNameList]);
    setDisplayAssigneeName(tempAssigneeNameList[0].name);

    SetJiraReceiveOrg(selectedValue.status);

    const tempAssigneeId = groupList.find(item => item.assigneeName === tempAssigneeNameList[0].name);
    setJiraAssignee(tempAssigneeId.assigneeId);
  };

  const handleAssignee = (selectedValue) => {
    const tempAssignee = groupList.find(item => item.assigneeName === selectedValue.status);
    setDisplayAssigneeName(tempAssignee.assigneeName);
    setJiraAssignee(tempAssignee.assigneeId);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getManagementGroup = () => {
    setManagementGroupFetching('loading');
    fetch(`${ISS_BASE_URL}/api/common/issue/management/group`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          const groupNameList = [... new Set(body.dataSet.map(item => item.groupName))];

          const tempGroupNameList = groupNameList.map(item => {
            return { index: item, name: item }
          });
          setDisplayGroupNameList([...tempGroupNameList]);

          const tempAssigneeNameList = body.dataSet.filter(item => item.groupName === groupNameList[0]).map(item => {
            return { index: item.assigneeName, name: item.assigneeName }
          });
          setDisplayAssigneeNameList([...tempAssigneeNameList]);
          setDisplayAssigneeName(tempAssigneeNameList[0].name);

          SetJiraReceiveOrg(groupNameList[0]);
          const tempAssigneeId = body.dataSet.find(item => item.assigneeName === tempAssigneeNameList[0].name);
          setJiraAssignee(tempAssigneeId.assigneeId);

          setGroupList([...body.dataSet]);
        } else {
          console.log(`[ISS] managementGroup fetching error`);
        }
      })
      .catch(error => {
        console.log(`[ISS] managementGroup error: `, error);
      })
      .finally(() => {
        setManagementGroupFetching('done');
      });
  };

  const jiraCreate = () => {
    const cmpissLabel = 'CMPISS';
    const jiraData = {
      type: type,
      priority: jiraPriority,
      labels: cmpissLabel,
      issueIdList: issueIdList.join(','),
      summary: jiraTitle,
      description: jiraDescription? jiraDescription : jiraDetails,
      issueCategoryFirst: jiraIssueCategoryFirst,
      issueCategorySecond: jiraIssueCategorySecond,
      receiveOrg: jiraReceiveOrg,
      assignee: jiraAssignee,
      reporter: username === 'admin' ? 'cmpuser' : username,
      dueDate: jiraDueDate
    };

    jiraStatusCallback('processing');
    fetch(`${ISS_BASE_URL}/api/common/jira/create`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(jiraData)
    })
      .then(res => res.json())
      .then(body => {
        jiraCallback(body);
      })
      .catch(error => {
        jiraCallback({ result: "FAILED", reason: "Unknown Error" });
      })
      .finally(() => {
        jiraStatusCallback('done');
      });

    setOpen(false);
  };

  return (
    <>
      {
        managementGroupFetching === 'done' ? (
          <div>
            <Modal open={open} onClose={handleClose}>
              <div className="modal-default modal-create-jira">
                <div className="modal-title">
                  <h2>Create Jira</h2>
                  <button className="btn-close-modal" onClick={handleClose}>
                    닫기
                  </button>
                </div>
                <div className="modal-contents-container">
                  <div className="ui-form">
                    <div className="line">
                      <div className="title">
                        <h3>Priority</h3>
                      </div>
                      <div className="form-box">
                        <ListDropDown items={select_data_type.priority} title={jiraPriority} onChange={item => setJiraPriority(item.status)} />
                      </div>
                    </div>
                    <div className="line">
                      <div className="title">
                        <h3>Jira Receive Org</h3>
                      </div>
                      <div className="form-box">
                        <ListDropDown items={displayGroupNameList} title={jiraReceiveOrg} onChange={handleGroupName} />
                      </div>
                    </div>
                    <div className="line">
                      <div className="title">
                        <h3>Assignee</h3>
                      </div>
                      <div className="form-box">
                        <ListDropDown items={displayAssigneeNameList} title={displayAssigneeName} onChange={handleAssignee} />
                      </div>
                    </div>
                    <div className="line">
                      <div className="title">
                        <h3>Found In</h3>
                      </div>
                      <div className="form-box">
                        <input type="text" value={issueIdList} readOnly />
                      </div>
                    </div>
                    <div className="line">
                      <div className="title">
                        <h3>Summary</h3>
                      </div>
                      <div className="form-box">
                        <input type="text" placeholder="Enter the title" onChange={event => setJiraTitle(event.target.value)} />
                      </div>
                    </div>
                    <div className="line">
                      <div className="title">
                        <h3>Description</h3>
                      </div>
                      <div className="form-box">
                        <textarea placeholder="Enter issue details" onChange={event => setJiraDetails(event.target.value)}></textarea>
                      </div>
                    </div>
                    <div className="line">
                      <div className="title">
                        <h3>Due Date</h3>
                      </div>
                      <div className="form-box">
                        <DatePicker value={jiraDueDate} onChange={handleDate} dateFormat="yyyy-MM-dd" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="button-container align-center">
                    <PrimaryButton type={'button'} text={'Cancel'} btnClass={'btn-primary-gray'} onClick={handleClose} />
                    <PrimaryButton type={'button'} text={'Save'} btnClass={'btn-primary-accent'} onClick={jiraCreate} />
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        ) : (
          <></>
        )
      }
    </>
  );
}

CreateJiraModal.propTypes = {
  username: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  username: state.currentUser.username,
});

export default connect(mapStateToProps)(CreateJiraModal);