import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';

//import * as serviceWorker from './serviceWorker';
import configureStore from './store';

import './index.css';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

//ReactDOM.render(<App />, document.getElementById('root'));
//serviceWorker.unregister();
