import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { Dialog, DialogActions, DialogContent, Table, TableCell, TableContainer, TableRow, TextField, Button, IconButton, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { setPublishChannelName, updateMediaSaticUrl } from '../../../actions/channelMappingActions';

function EditMediaStaticURL({ system, ric, handleClose, handleMediaStaticUrl, cntry, updateMediaSaticUrl, updateMediaStaticUrl, channelMappedData, feeds, cpChannelDataViewRow, data, openAlert }) {
    const [isLoading, setIsLoading] = React.useState(false);
    const [onPublish, setOnPublish] = React.useState(false);
    const [editedChannel, setEditedChannel] = React.useState([]);

    const [resultAlert, setResultAlert] = React.useState(false);
    React.useEffect(() => {
        if (updateMediaStaticUrl.result) {
            setResultAlert(true);
        } else {
            setResultAlert(false);
        }
    }, [updateMediaStaticUrl]);

    const handleSubmit = () => {
        if (data.mediaStaticUrl === null || data.mediaStaticUrl.trim() === '') {
            openAlert('Media static URL can not be empty');
            return;
        } else if (/\s/.test(data.mediaStaticUrl)) {
            openAlert('Media static URL should not contain spaces');
            return;
        }else {
            updateMediaSaticUrl(system, ric, feeds, data, cpChannelDataViewRow);
        }
    };
    const allClose = () => {
        updateMediaStaticUrl.result = false;
        setResultAlert(false);
        handleClose();
    };

    return (
        <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title" className={'pop_wrap edit_url_pop'}>
            <DialogTitle id="form-dialog-title" className={'pop_head'}>
                Edit Media Static URL
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={'pop_body'}>
                <form onSubmit={""}>
                    <div className={'tbl_wrap'}>
                        <table className={'tbl_row_typ1'}>
                            <colgroup>
                                <col style={{ width: '200px' }} />
                                <col style={{ width: 'auto' }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>
                                        <label htmlFor={'channelId'}>
                                            Channel ID
                                        </label>
                                    </th>
                                    <td>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            name="channelId"
                                            id="channelId"
                                            value={data.channelId}
                                            onChange={""}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <label htmlFor={'channelId'}>
                                            Channel Name
                                        </label>
                                    </th>
                                    <td>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            name="channelId"
                                            id="channelId"
                                            value={data.channelName}
                                            onChange={""}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <label htmlFor={'mediaStaticUrl'} className={'is_required'}>
                                            Media Static URL
                                        </label>
                                    </th>
                                    <td>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            name="mediaStaticUrl"
                                            id="mediaStaticUrl"
                                            placeholder="Media Static Url"
                                            value={data.mediaStaticUrl}
                                            onChange={event => handleMediaStaticUrl(event)}
                                            required
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={'btn_set_wrap'}>
                        <Button data-testid="addButton" color="secondary" className={'btn_color2'} onClick={handleSubmit} alert={alert}>
                            Update
                        </Button>
                        <Button data-testid="addButton" color="primary" onClick={handleClose} className={'btn_color1'}>
                            Cancel
                        </Button>
                    </div>
                </form>
            </DialogContent>
            <Dialog open={resultAlert} aria-labelledby="confirm-dialog">
                <div className="pop_size_small">
                    <p className="pop_comment">{updateMediaStaticUrl.msg}</p>
                    <DialogActions>
                        <div className="btn_set_wrap alC">
                            <Button color="primary" onClick={allClose} className="btn_color2">
                                OK
                            </Button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        </Dialog>
    );
}
EditMediaStaticURL.propTypes = {
    handleMediaStaticUrl: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    updateMediaStaticUrl: state.channelMapping.updateMediaStaticUrl,
    pbResult: state.channelMapping.pbchannelnameresult,
    channelMappedData: state.channelMapping.channelMappedData,
});
const mapDispatchToProps = dispatch => ({
    updateMediaSaticUrl: (system, ric, feeds, data, cpChannelDataViewRow) => dispatch(updateMediaSaticUrl(system, ric, feeds, data, cpChannelDataViewRow))
});
export default connect(mapStateToProps, mapDispatchToProps)(EditMediaStaticURL);