import { API_URL } from '../constants';
import { getHeaders, getHeadersFormData } from '../util/actionUtil';

export async function request(requestPath, requestMethod, requestBody, isIncludeResponse, isUpdate, isFormData) {
  let result = false;

  const requestCommon = {
    method: requestMethod,
    headers: isFormData ? getHeadersFormData() : getHeaders(),
  };

  const requestObj = requestMethod === 'GET' || !requestBody ? requestCommon : { ...requestCommon, body: isFormData ? requestBody : JSON.stringify(requestBody) };

  await fetch(API_URL + requestPath, requestObj)
    .then(res => {
      //console.log('***res', res);
      return res.json();
    })
    .then(json => {
      if ((json.status && json.error) || json.errorCode) {
        if (json.errorCode === 'CDP_SVC_4004' && requestPath === '/admin/users')
          //user ID is duplicated with existing ID : test
          alert('ID already exists! Please use another ID!');
        else if (json.errorCode === 'CDP_SVC_4004' && requestPath === '/admin/providers')
          //Provider is duplicated with providerId : TEST
          alert('CP code already exists! Please use another CP Code!');
        else if (json.errorCode === 'CDP_SVC_4042' && requestPath === '/admin/users')
          //Provider not found with id : ABC
          alert('CP Code does not exists! First, add CP Code!');
        else alert('Server error message: ' + json.message);
      } else if (json.returnValue == false && requestPath.includes('/saveFreeOnChannels')) {
        alert(json.message);
      } else {
        if (isIncludeResponse) result = json;
        else result = true;
      }
    })
    .catch(err => {
      //console.log('[err]', err);
      if (isUpdate) {
        alert('Server cannot response right now because this work takes time. Please check the result after a few minutes');
      } else {
        if (err.toString() === 'TypeError: Failed to fetch') alert('No server response');
        else alert('Error message: ' + err);
      }
    });

  return result;
}

// * Could not get any response
// * Why this might have happened:
// The server couldn't send a response:
// Ensure that the backend is working properly
// Self-signed SSL certificates are being blocked:
// Fix this by turning off 'SSL certificate verification' in Settings > General
// Proxy configured incorrectly
// Ensure that proxy is configured correctly in Settings > Proxy
// Request timeout:
// Change request timeout in Settings > General

// status: 404
// errorCode: "CDP_SVC_4042"
// message: "Provider not found with id : ABC"
