import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function HeaderProfile({ name, handleSignout }) {
  let node = useRef();
  let [open, setOpen] = useState(false);
  const userInfoClick = e => {
    if (node.current.contains(e.target)) {
      // Inside the click
      return;
    }
    // Ouside the click
    setOpen(false);
  };

  const handleDropdownValue = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', userInfoClick);
    return () => {
      document.removeEventListener('mousedown', userInfoClick);
    };
  }, []);

  return (
    <div ref={node} className={open ? 'user_info is_active' : 'user_info'}>
      <button
        type={'button'}
        className={'user_name'}
        onClick={() => {
          setOpen(!open);
        }}>
        <span>{name}</span>
      </button>
      <div className={'user_info_box'}>
        <ul>
          <li>
            <Link to={'/profile'} className={'my_profile'} onClick={handleDropdownValue}>
              <span>My Profile</span>
            </Link>
          </li>
          <li>
            <Link to={'#'} className={'log_out'} onClick={handleSignout}>
              <span>Log out</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

HeaderProfile.propTypes = {
  name: PropTypes.string.isRequired,
  handleSignout: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  name: state.currentUser.name,
});
// const mapDispatchToProps = (dispatch) => ({
//   // setDrawerOpenAction (value) {
//   // 	dispatch(setDrawerOpenAction(value));
//   // }
// });
export default connect(mapStateToProps)(HeaderProfile);
