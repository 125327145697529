//import * as actions from '../../actions/actionTypes';

const initialState = new Map();

export default function tabExpand(state = initialState, action) {
    switch (action.type) {
      case 'SET_HOMEAPP_TAB_EXPAND':
        if (action.payload.value){
          state.set(action.payload.key, action.payload.value);
        } else {
          state.delete(action.payload.key);
        }
        return state;
      case 'RESET_HOMEAPP_TAB_EXPAND':
        state.clear();
        return state;
      default:
          return state;
  }
}