import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LineChart from '../common/LineChart';
import { countbyIssueDataSet, lineChartSet, lineChartDataSet } from './DashboardDataSet';
import { ISS_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';

function IssueTab({ currentTab, currentFilter }) {

  useEffect(() => {
    getCountbyIssue('tab');
  }, [ currentTab ] );

  useEffect(() => {
    if (currentFilter && (currentFilter.country.length > 0 || (currentFilter.startDate && currentFilter.endDate))) {
      getCountbyIssue('filter');
    }
  }, [ currentFilter ] );

  const [dashboardChartLabel, setDashboardChartLabel] = useState('issues by time(UTC)');
  const [dashboardbyIssue, setDashboardbyIssue] = useState(countbyIssueDataSet);
  const [dashboardLineChartData, setDashboardLineChartData] = useState(lineChartSet.dataSet);
  const [dashboardLineChartOption, setDashboardLineChartOption] = useState(lineChartSet.optionSet);

  const getCountbyIssue = (requestType) => {
    let addr = '';
    let param = '';
    if (currentTab === 0){
      addr = '/api/common/service-issue/count';
      param = requestType === 'filter' ? `?country=${currentFilter.country}&provider=${currentFilter.provider}` : '';
      setDashboardChartLabel('Service issues by time (UTC)');
    } else if (currentTab === 1){
      addr = '/api/common/contents-issue/count';
      param = requestType === 'filter' ? `?country=${currentFilter.country}&provider=${currentFilter.provider}` : '';
      setDashboardChartLabel('Content issues by time (UTC)');
    } else if (currentTab === 2){
      addr = '/api/common/statistics-issue/count';
      param = requestType === 'filter' ? `?country=${currentFilter.country === 'All' ? '' : currentFilter.country}`+
      `&provider=${currentFilter.provider}&groupList=${currentFilter.groupList}&contentName=${currentFilter.contentName}`+
      `&type=${currentFilter.type}&dataSourceType=${currentFilter.dataSourceType === undefined ? [] : currentFilter.dataSourceType}`+
      `&detectingConditionType=${currentFilter.detectingConditionType === undefined ? [] : currentFilter.detectingConditionType}`+
      `&startDate=${currentFilter.date[0]}&endDate=${currentFilter.date[1]}` : '';
      setDashboardChartLabel('Statistics issues by time (UTC)');
    } else if (currentTab === 3) {
      addr = '/api/common/ad-issue/count'
      if (requestType === 'filter') {
        param = `?country=${currentFilter.country}&platform=${currentFilter.platform}`
        if (currentFilter.appName) param += `&appName=${currentFilter.appName}`
        if (currentFilter.inventoryName) param += `&inventoryName=${currentFilter.inventoryName}`
        if (currentFilter.bannerType) param += `&bannerType=${currentFilter.bannerType}`
        if (currentFilter.startDate && currentFilter.endDate) param += `&startDate=${currentFilter.startDate}&endDate=${currentFilter.endDate}`
        param = '?' + new URLSearchParams(param)
      }
      setDashboardChartLabel('Ad issues by time (UTC)')
    } else {
      return;
    }
    fetch(`${ISS_BASE_URL}${addr}${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        let countValue = body.dataSet.issueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let unaddressedValue = body.dataSet.unaddressed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let processingValue = body.dataSet.processing.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let newCountValue = body.dataSet.newIssueCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let newFlag = body.dataSet.newFlag;
        setDashboardbyIssue({ ...dashboardbyIssue, ...{ countByIssue : countValue, unaddressedByIssue: unaddressedValue, processingByIssue : processingValue
          ,countByNewIssue : newCountValue, newFlag: newFlag } });

        let charMaxScales = body.dataSet.charMaxScales;
        let charMinScales = body.dataSet.charMinScales;
        let chartLabels = [];
        let chartData = [];
        body.dataSet.chartDataSet.map(map =>{
          chartLabels.push(map.byHour);
          chartData.push(map.hourCount);
        });
        let lineChart = [];
        let lineChartData =  lineChartDataSet;

        if (currentTab === 0) {
          lineChartData.label = 'Service issues';
        } else if (currentTab === 1) {
          lineChartData.label = 'Content issues';
        } else if (currentTab === 2) {
          lineChartData.label = 'Statistics issues';
        } else {
          lineChartData.label = 'Ad issues'
        }

        lineChartData.data = chartData;
        lineChart.push(lineChartData);
        setDashboardLineChartOption({ ...dashboardLineChartOption, ...{ scales : { y : { min : charMinScales, max : charMaxScales } } } } );
        setDashboardLineChartData({ ...dashboardLineChartData, ...{ labels : chartLabels , datasets :lineChart } } );
      } else {
        alert('[getIssue server error]');
      }
    })
    .catch(error => {
      alert('getCountbyIssue : '  + error.toString());
    }).finally(() => {});
  };
  return (
    <div className="contents-service">
      <div className="line">
        <h3>Issue Count</h3>
        <div className="num-summary">
          <strong>{dashboardbyIssue.countByIssue}</strong>
          {dashboardbyIssue.newFlag === 'Y' &&
          <span className="new">{dashboardbyIssue.countByNewIssue}</span>
          }
        </div>
      </div>
      <div className="line">
        <h3>Unaddressed</h3>
        <div className="num-summary">
          <strong>{dashboardbyIssue.unaddressedByIssue}</strong>
        </div>
      </div>
      <div className="line">
        <h3>Processing</h3>
        <div className="num-summary">
          <strong>{dashboardbyIssue.processingByIssue}</strong>
        </div>
      </div>
      <div className="ui-chart-container">
        <span className="new">{dashboardChartLabel}</span>
        <div className="chart-wrap">
          <LineChart id={currentTab} data={dashboardLineChartData} options={dashboardLineChartOption} />
        </div>
      </div>
    </div>
  );
}

IssueTab.propTypes = {
  currentTab: PropTypes.number.isRequired
};

export default IssueTab;
