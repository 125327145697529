import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { setAuthenticatedAction, setCurrentUserAction } from '../../../actions/userActions';
import { logoutAction } from '../../../actions/signinoutActions';
import { setHeaderMenuAction, setDrawerOpenAction } from '../../../actions/uiActions';
import HeaderSubMenu from './HeaderSubMenu';
import HeaderTime from './HeaderTime';
import HeaderProfile from './HeaderProfile';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const styles = theme => ({
  appBar: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  // grow: {
  //   flexGrow: 1,
  // },
  // logo: {
  //   width: 40,
  //   verticalAlign: 'middle',
  //   margin: 10,
  // },
  // tagline: {
  //   verticalAlign: 'middle',
  // },
  // link: {
  //   textDecoration: 'none',
  //   color: 'inherit',
  // },
});

const gnbMouseLeave = () => {
  document.getElementById('header').classList.remove('is_active');
};

export function AppHeader({ classes, drawerOpen, isAuthenticated, logoutAction, setHeaderMenuAction, setAuthenticatedAction, setCurrentUserAction, history, location, setDrawerOpenAction }) {
  let menuItems;

  const clickHeaderMenu = e => {
    setHeaderMenuAction(e.currentTarget.innerText);
  };
  const clickHeaderSubMenu = e => {
    setHeaderMenuAction(e.target.parentNode.parentNode.parentNode.parentNode.parentNode.firstChild.innerText); //dispatch 시켜주지 않으면 state로 관리되지 않음
  };

  const handleSignout = () => {
    setCurrentUserAction({
      name: '',
      username: '',
    });
    logoutAction();
    setAuthenticatedAction(false);

    history.push('/');
  };

  //console.log('drawerOpen', drawerOpen);
  //console.log('isAuthenticated', isAuthenticated);

  if (isAuthenticated) {
    const currentMenu = location.pathname.split('/')[1];

    menuItems = (
      <AppBar position="fixed" className={classes.appBar} id={'header'}>
        <Toolbar>
          <IconButton //https://mui.com/components/drawers/
            color="inherit"
            onClick={() => {
              setDrawerOpenAction(!drawerOpen);
            }}>
            <MenuIcon />
          </IconButton>
          <h1 className={'top_logo'}>
            <Link to="/" className={classes.link}>
              <span>CMP</span>
            </Link>
          </h1>
          <>
            <menu className={'gnb'} onMouseLeave={gnbMouseLeave}>
              <HeaderSubMenu clickHeaderMenu={clickHeaderMenu} clickHeaderSubMenu={clickHeaderSubMenu} currentMenu={currentMenu} />
              <div className={'top_right'}>
                <HeaderTime handleSignout={handleSignout} />
                <HeaderProfile handleSignout={handleSignout} />
              </div>
            </menu>
          </>
        </Toolbar>
      </AppBar>
    );
  } else {
    menuItems = <div className={'no_header'} />;
  }

  return <>{menuItems}</>;
}

AppHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  logoutAction: PropTypes.func.isRequired,
  setAuthenticatedAction: PropTypes.func.isRequired,
  setCurrentUserAction: PropTypes.func.isRequired,
  setHeaderMenuAction: PropTypes.func.isRequired,
  setDrawerOpenAction: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  drawerOpen: state.ui.drawerOpen,
  isAuthenticated: state.currentUser.isAuthenticated,
});
const mapDispatchToProps = dispatch => ({
  logoutAction() {
    dispatch(logoutAction());
  },
  setAuthenticatedAction(value) {
    dispatch(setAuthenticatedAction(value));
  },
  setCurrentUserAction(value) {
    dispatch(setCurrentUserAction(value));
  },
  setHeaderMenuAction(value) {
    dispatch(setHeaderMenuAction(value));
  },
  setDrawerOpenAction(value) {
    dispatch(setDrawerOpenAction(value));
  },
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppHeader)));
