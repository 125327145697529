import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogContent, DialogTitle, Dialog } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getHisttoryAction } from '../../actions/webActions';
import MaterialTable from 'material-table';
import TermPolicyView from './TermPolicyView';

function TermPolicyHistory({ title, type, handleClose, historyId, getHisttoryData, historyData }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    getHisttoryData(type, historyId);
  }, []);

  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        {`History - ${title}`}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={'pop_body'}>
        <div className="tbl_wrap no_sch mt20 no_round">
          <MaterialTable
            title="Version History"
            columns={[
              {
                title: 'Version',
                field: 'version',
                render: rowData => (
                  <button
                    type="button"
                    onClick={() => {
                      setOpen(true);
                      setData(rowData);
                    }}>
                    {rowData.version}
                  </button>
                ),
              },
              {
                title: 'lastChgDate',
                field: 'lastChgDate',
                render: rowData => (
                  <button
                    type="button"
                    onClick={() => {
                      setOpen(true);
                      setData(rowData);
                    }}>
                    {rowData.lastChgDate.replace(/T/gi, ' ')}
                  </button>
                ),
              },
            ]}
            data={historyData}
            options={{
              search: false,
              paging: true,
            }}
          />
        </div>
        <div className={'btn_set_wrap alC'}>
          <Button variant="contained" className={'btn_color2'} onClick={handleClose}>
            Close
          </Button>
        </div>
      </DialogContent>
      <Dialog open={open} className={'pop_wrap'}>
        <TermPolicyView data={data} title={title} handleClose={() => setOpen(false)} />
      </Dialog>
    </Fragment>
  );
}

TermPolicyHistory.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  historyData: PropTypes.array.isRequired,
  historyId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  getHisttoryData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  historyData: state.web.termsPrivacyHistory,
});

const mapDispatchToProps = dispatch => ({
  getHisttoryData: (type, id) => dispatch(getHisttoryAction(type, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermPolicyHistory);
