import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
//import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
//import { request } from './request';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';

export default function ChangeApiKey({ reloadAction, apikeyId }) {
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  const handleClickOpen = async () => {
    alert('Are you sure to update API Key?');
    await fetch(API_BASE_URL + '/api-keys', {
      method: 'POST',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(json => {
        //console.log('[json]', json);
        if (json.errorCode) {
          alert('Server error message: ' + json.message);
        } else {
          fetch(API_BASE_URL + '/api-keys/' + apikeyId, {
            method: 'DELETE',
            headers: getHeaders(),
          });

          setSuccessSnackbarOpen(true);
          reloadAction();
        }
      })
      .catch(err => {
        //console.log('[err]', err);
        if (err.toString() === 'TypeError: Failed to fetch') alert('No server response');
        else alert('Error message: ' + err);
      });
    //alert('blocked');
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} className={'btn_size_tbl btn_color3'}>
        Update
      </Button>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={1000} //1000=1sec
        onClose={handleSuccessSnackbarClose} //없으면 없어지지않음
        message="Successfully Doned !"
        style={{ backgroundColor: 'black' }}
      />
    </React.Fragment>
  );
}

ChangeApiKey.propTypes = {
  reloadAction: PropTypes.func.isRequired,
  apikeyId: PropTypes.number,
};
