import { ROWHEADER_TYPE } from '../../../consts/IssConsts';

export const service_basic_trend_data = {
  title: 'Service Basic Trend',
  header: ['Date', 'Error Count'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['50%', '50%'],
  rowdata: [],
};

export const service_country_trend_data = {
  title: 'Service Country Trend',
  header: ['Country', 'Date', 'Error Count'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['33%', '33%', '33%'],
  rowdata: [],
};

export const service_platform_trend_data = {
  title: 'Service Platform Trend',
  header: ['Platform', 'Date', 'Error Count'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['33%', '33%', '33%'],
  rowdata: [],
};

export const service_provider_trend_data = {
  title: 'Service Provider Trend',
  header: ['Provider', 'Date', 'Error Count'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['33%', '33%', '33%'],
  rowdata: [],
};

export const service_top_trend_data = {
  title: 'Service Top Trend',
  header: ['Content Id', 'Date', 'Error Count'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['50%', '30%', '20%'],
  rowdata: [],
};

export const service_resolve_trend_data = {
  title: 'Service Resolve Trend',
  header: ['Date', 'Total Resolve', 'Auto Resolve', 'Manual Resolve'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['25%', '25%', '25%', '25%'],
  rowdata: [],
};

export const CHART_COLORS_BY_CONTENT = {
  0: '#69B0EE',
  1: '#719BF4',
  2: '#EFA1F1',
  3: '#FCF057',
  4: '#89D35C',
  5: '#E47874',
  6: '#6387D5',
  7: '#D4AA00',
  8: '#FF9DB0',
  9: '#77B2E0',
}