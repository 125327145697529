import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function TimerDialog({ open, handleClose, title, description, handleReset, handleSignout }) {
  return (
    <React.Fragment>
      <Dialog open={open} aria-labelledby="form-dialog-title" className={'pop_wrap pop_alert'}>
        <div className="pop_size_small">
          <p className="pop_comment">{title}</p>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className="MuiDialogActions-root">
              {description}
            </DialogContentText>
          </DialogContent>
          {handleReset ? (
            <div className="btn_set_wrap alC">
              <Button
                className={'btn_color2'}
                onClick={() => {
                  handleClose();
                  handleReset();
                }}>
                OK
              </Button>
              <Button
                className={'btn_color1'}
                onClick={() => {
                  handleClose();
                }}>
                Cancel
              </Button>
            </div>
          ) : (
            <div className="btn_set_wrap alC">
              <Button
                className={'btn_color2'}
                onClick={() => {
                  handleClose();
                  handleSignout();
                }}>
                OK
              </Button>
            </div>
          )}
        </div>
      </Dialog>
    </React.Fragment>
  );
}

TimerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleReset: PropTypes.func,
  handleResetCancel: PropTypes.func,
  handleSignout: PropTypes.func,
};
