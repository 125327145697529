import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Routes from './routes';
import LoadingIndicator from './common/LoadingIndicator';
import { ThemeProvider } from '@material-ui/styles';
import { rehydrateAction } from './actions/rehydrateActions';
import { chMappingActions } from './actions/chMappingFilterActions';
//import { getCurrentUserAction } from './actions/userActions';
import theme from './theme';
import './App.css';
//import Signin from './components/user/Signin';

function App({ accessToken, rehydrateAction, username, isRoleAdmin, chMappingActions }) {
  useEffect(() => {
    rehydrateAction(isRoleAdmin);
    chMappingActions();
    // if (accessToken) {
    //   getCurrentUserAction();
    // }
  }, [accessToken, isRoleAdmin]);

  //if (isAuthenticated)
  if (accessToken && !username) {
    return <LoadingIndicator />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    );
  }
  //else return <Signin />;
}

App.propTypes = {
  accessToken: PropTypes.string.isRequired,
  rehydrateAction: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  isRoleAdmin: PropTypes.bool.isRequired,
  chMappingActions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  accessToken: state.signin.accessToken,
  username: state.currentUser.username,
  isRoleAdmin: state.currentUser.isRoleAdmin,
  //isAuthenticated: state.currentUser.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  // getCurrentUserAction() {
  //   dispatch(getCurrentUserAction());
  // },
  chMappingActions: () => dispatch(chMappingActions()),
  rehydrateAction(isRoleAdmin) {
    dispatch(rehydrateAction(isRoleAdmin));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
