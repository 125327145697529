import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { InputLabel, TextField, FormControl, Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const states = ['All', 'Draft', 'Publish'];

function PushAlarmFilter({ title, allCountries, handleTitle, countryCode, handleCountryCode, state, handleState }) {
  const classes = useStyles();

  return (
    <>
      <div className="filter_area row">
        <div className="filter_row">
          <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="countryCode">Country Code</InputLabel>
              <Select labelId="countryCode" id="countryCode" label="countryCode" value={countryCode} onChange={handleCountryCode}>
                <MenuItem key='All' value='All'>
                  All
                </MenuItem>
                {[...allCountries].map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">State</InputLabel>
              <Select labelId="state" id="state" label="state" value={state} onChange={handleState}>
                {states.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </div>

        <div className="filter_row">
          <TextField onChange={e => handleTitle(e.target.value)} label="Title" value={title} variant="outlined" />
        </div>
      </div>
    </>
  );
}

PushAlarmFilter.propTypes = {
  title: PropTypes.string,
  countryCode: PropTypes.string,
  handleCountryCode: PropTypes.func.isRequired,
  state: PropTypes.string,
  handleState: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PushAlarmFilter);
