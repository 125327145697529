import * as actions from '../../actions/actionTypes';

const initialState = '';

export default function failmessage(state = initialState, action) {
  switch (action.type) {
    case actions.SET_API_FAIL:
      return (action.payload || { msg: '' }).msg || initialState;
    default:
      return initialState;
  }
}
