import React, { useEffect } from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 336px;
    --y: 1316px;
    --width: 1566px;
    --height: 451px;

    top: 0;
    left: 0;
    width: 1920px;
    height: 2000px;
  }

  #text-01 {
    top: 1252px;
    left: 709px;
  }

  #line-01 {
    top: 1260px;
    left: 637px;
  }
`;

const IssueHistory06 = () => {
  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">
            You can check the number of the issues in a table.
          </div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_6174_line_01.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default IssueHistory06;