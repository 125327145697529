import { API_BASE_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';

export const getLogAction = (isRoleAdmin, cp, api, file, status, from, to) => dispatch => {
  //console.log('[getLogAction][cp]', cp, '[api]', api);
  if (cp === 'All') cp = '';
  if (api === 'All') api = '';
  if (status === 'All') status = '';

  let apiUrl = '/apiCallLog?providerId=' + cp + '&api=' + api + '&file=' + file + '&status=' + status + '&from=' + from + '&to=' + to;

  if (isRoleAdmin) {
    apiUrl = API_BASE_URL + '/admin' + apiUrl;
  } else apiUrl = API_BASE_URL + apiUrl;

  dispatch({
    type: 'SET_LOG_FETCHING',
    payload: true,
  });
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: 'SET_LOG',
        payload: body,
      }),
    ) //include 400Error
    .catch(ex =>
      dispatch({
        type: 'SET_LOG',
        payload: ex,
      }),
    );
};
