import { ROWHEADER_TYPE, TABLE_TYPE } from '../../consts/IssConsts';

export const content_history_tabledata = {
  cp_issue_history: {
    tableType: TABLE_TYPE.NORMAL,
    title: 'CP History',
    visibleTitle: true,
    existTooltip: false,
    tooltip: 'CP Issue History tooltip message..!!',

    header: ['Issue ID', 'Date', 'Cnty.', 'CP', 'Issue Title', 'Status', 'API Name', 'Assign', 'Modifier', 'Jira URL', 'Action Details'],
    cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.COUNTRY_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['7%', '7%', '7%', '7%', '10%', '7%', '15%', '10%', '10%', '10%', '10%'],
    rowdata: [],
  },

  organization_history: {
    tableType: TABLE_TYPE.NORMAL,
    title: 'CMP Organization History',
    visibleTitle: true,
    existTooltip: false,
    tooltip: 'Organization Issue History tooltip message..!!',
    header: ['Issue ID', 'Date', 'Lv', 'Cnty.', 'Issue Title', 'Status', 'User Name', 'Function Name', 'Function Parameter', 'Assign', 'Modifier', 'Jira URL', 'Action Details'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['7%', '5%', '2%', '3%', '12%', '5%', '5%', '9%', '20%', '5%', '9%', '8%', '10%'],
    rowdata: [],
  },

  deployment_history: {
    tableType: TABLE_TYPE.NORMAL,
    title: 'Data Deployment History',
    visibleTitle: true,
    existTooltip: false,
    tooltip: 'Deployment History tooltip message..!!',
    header: ['Issue ID', 'Date', 'Lv', 'Issue Title', 'Status', 'Job Name', 'Start Date', 'End Date', 'Execution Time', 'Assign', 'Modifier', 'Jira URL', 'Action Details'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['7%', '5%', '2%', '15%', '5%', '15%', '5%', '5%', '8%', '5%', '10%', '8%', '10%'],
    rowdata: [],
  },
};

export const content_history_chartdata = {
  daily_contents_error_occurrence: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 0,
          ticks: {
            stepSize: 10,
          },
        },
      }
    },
  },
  daily_contents_error_accumulated: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (value) => `${value}%`,
          },
        },
      },
    },
  },
  daily_contents_error_accumulated2: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (value) => `${value}%`,
          },
        },
      },
    },
  },
};
