import { combineReducers } from 'redux';
import programs from './programs';
import channels from './channels';
import schedules from './schedules';
import metadataFiles from './metadataFiles';
import currentUser from './currentUser';
import signin from './signin';
import newProgram from './newProgram';
import setting from './setting';
import signup from './signup';
import ui from './ui';
import users from './users';
import providers from './providers';
import log from './log';
import cntry from './cntry';
import countries from './countries';
import code from './code'; // 코드
import cplist from './cplist';
//import whitelistIps from './whitelistIps';
import channelMapping from './channelMapping'; // Channel
import channelChannel from './channelChannel'; // Channel
import channelChannelView from './channelChannelView'; // Channel
import channelChannelFeed from './channelChannelFeed'; // Channel
import channelChannelFeedView from './channelChannelFeedView'; // Channel
import channelChannelConf from './channelChannelConf'; // Channel
import channelChannelConfView from './channelChannelConfView'; // Channel
import displayRules from './displayRules'; // Display Rules
import rssFeed from './rssFeed';
import rssLog from './rssLog';
import statistic from './statistic';
import homeApp from './homeApp';
import freeOnLGChannelView from './freeOnLGChannelView';
import loader from './pageLoader';
import web from './web';
import dmaCode from './dmaCode';
import dmaGroupCode from './dmaGroupCode';
import appVersion from './appVersion';
import pushAlarm from './pushAlarm';
import popUp from './popUp';
import watchLive from './watchLive';

const cdp = combineReducers({
  programs,
  schedules,
  metadataFiles,
  currentUser,
  signin,
  newProgram,
  setting,
  signup,
  ui,
  users,
  providers,
  log,
  cntry,
  code,
  channelMapping,
  channels,
  channelChannel,
  channelChannelView,
  channelChannelFeed,
  channelChannelFeedView,
  channelChannelConf,
  channelChannelConfView,
  displayRules, // Display Rules
  countries,
  cplist,
  rssFeed,
  rssLog,
  statistic,
  homeApp,
  freeOnLGChannelView,
  loader,
  web,
  dmaCode,
  dmaGroupCode,
  appVersion,
  pushAlarm,
  //whitelistIps
  popUp,
  watchLive
});

export default cdp;
