import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles'; // makeStyles
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export function AppVersionFilter({ osName, handleOsName, cntry, handleCountry, allCountries }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className="filter_area">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Country</InputLabel>
          <Select value={cntry} onChange={e => handleCountry(e)}>
          {['All', ...allCountries].map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>OS</InputLabel>
          <Select value={osName} onChange={e => handleOsName(e)}>
            {['All', 'Android', 'IOS'].map(v => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </React.Fragment>
  );
}

AppVersionFilter.propTypes = {
  handleCountry: PropTypes.func,
  handleOsName: PropTypes.func,
};

export default AppVersionFilter;
