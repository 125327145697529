import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import SentimentDissatisfied from '@material-ui/icons/SentimentDissatisfied';

const styles = theme => ({
  root: {
    maxWidth: 520,
    margin: '0 auto',
    marginTop: 40,
  },
  paper: {
    marginTop: theme.spacing && theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing && theme.spacing(2, 3, 3),
  },
  avatar: {
    margin: theme.spacing && theme.spacing(1),
    backgroundColor: theme.palette && theme.palette.secondary.main,
  },
});

export function NoData({ classes, message }) {
  // if (!show) {
  // 	return null;
  // }

  return (
    <div className={classes.root + ' no_data_wrap'}>
      {/*React.Fragment->div: Invalid prop `className` supplied to `React.Fragment`. React.Fragment can only have `key` and `children` props*/}
      <Paper className={classes.paper} elevation={1}>
        {/*없으면 세로중앙정렬안됨 */}
        <Avatar className={classes.avatar + ' icon_no_data'}>
          <SentimentDissatisfied />
        </Avatar>
        <Typography component="h2" variant="h6">
          {message}
          {/* {`No ${contentName} Found.`} */}
        </Typography>
      </Paper>
    </div>
  );
}

NoData.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
  //contentName: PropTypes.string.isRequired,
  //show: PropTypes.bool.isRequired
};

export default withStyles(styles)(NoData);
