import React, { useEffect, useState } from 'react'
import Loading from '../../common/Loading'
import LineChart from '../../common/LineChart'
import { issue_services_history_total_data } from './IssueHistoryServiceDataset.js'
import { CHART_COLORS } from '../../common/ChartConsts'
import { getHeaders } from '../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../constants/index';
import Alert from '../../modal/Alert';
import annotationPlugin from 'chartjs-plugin-annotation';
import BarChart from '../../common/BarChart'

function IssueHistoryServiceTotal ({ currentFilter, chartType }) {
  const [rangeDate, setRangeDate] = useState([]);
  const [isHistoryDisplay, setHistoryDisplay] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const plugin = [annotationPlugin];

  let countDataset = [];
  let resolvedDataset = [];
  let cumulativeDataset = [];
  let maxY = 0;

  useEffect(() => {
    if (currentFilter && currentFilter.date.length > 0 && currentFilter.subType === 'All') {
      setRangeDate([currentFilter.date[0], currentFilter.date[1]]);
    }
  }, [currentFilter]);

  useEffect(() => {
    if (currentFilter && (currentFilter.subType === '')) {
      defaultRangeDate();
    }
  }, []);

  useEffect(() => {
    if (rangeDate && rangeDate.length > 0) {
      setHistoryDisplay(false);
      let changeFilter = { date: '' };
      changeFilter.date = rangeDate;
      getServicesTotalError(changeFilter);
    }
  }, [rangeDate]);

  const setTotalGraphDataset = (dataSet, color, label) => {
    let service_issue_countData = {
      label: '',
      data: [],
      fill: false,
      pointRadius: 3,
      borderColor: '',
      backgroundColor: '',
      pointBorderColor: '#FFFFFF',
      pointBackgroundColor: `${color}`,
      pointBorderWidth: 2,
      order: 2,
    };

    let service_issue_resolvedRateData = {
      label: '',
      data: [],
      fill: false,
      pointRadius: 3,
      borderColor: '',
      backgroundColor: '',
      pointBorderColor: '#FFFFFF',
      pointBackgroundColor: `${color}`,
      pointBorderWidth: 2,
      order: 2,
    };

    let service_issue_accResolvedRateData = {
      label: '',
      data: [],
      fill: false,
      pointRadius: 3,
      borderColor: '',
      backgroundColor: '',
      pointBorderColor: '#FFFFFF',
      pointBackgroundColor: `${color}`,
      pointBorderWidth: 2,
      order: 2,
    };
    service_issue_countData.label = label;
    service_issue_countData.borderColor = `${color}`;
    service_issue_countData.backgroundColor = `${color}`;
    service_issue_countData.data = dataSet.map(n => n.issueCount);
    maxY = Math.max(Math.max(...service_issue_countData.data), maxY);
    countDataset.push(service_issue_countData);

    service_issue_resolvedRateData.label = label;
    service_issue_resolvedRateData.borderColor = `${color}`;
    service_issue_resolvedRateData.backgroundColor = `${color}`;
    service_issue_resolvedRateData.data = dataSet.map(n => n.resolvedRate);
    resolvedDataset.push(service_issue_resolvedRateData);

    service_issue_accResolvedRateData.label = label;
    service_issue_accResolvedRateData.borderColor = `${color}`;
    service_issue_accResolvedRateData.backgroundColor = `${color}`;
    service_issue_accResolvedRateData.data = dataSet.map(n => n.cumulativeResolveRate);
    cumulativeDataset.push(service_issue_accResolvedRateData);
  }

  const getServicesTotalError = () => {
    setHistoryDisplay(false);
    fetch(`${ISS_BASE_URL}/api/service/history/total?dates=${rangeDate}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          let dateList = body.app.map(n => n.date.substring(5, 10));
          setTotalGraphDataset(body.app, CHART_COLORS.BLUE, 'App');
          setTotalGraphDataset(body.play, CHART_COLORS.PURPLE, 'Playback');
          setTotalGraphDataset(body.schedule, CHART_COLORS.PINK, 'Schedule');
          issue_services_history_total_data.daily_services_total_error_occurrence.data.labels = dateList;
          issue_services_history_total_data.daily_services_total_error_occurrence.data.datasets = countDataset;
          issue_services_history_total_data.daily_services_total_error_occurrence.options.scales.y.max = maxY;
          issue_services_history_total_data.daily_services_total_error_resolution.data.labels = dateList;
          issue_services_history_total_data.daily_services_total_error_resolution.data.datasets = resolvedDataset;
          issue_services_history_total_data.daily_services_total_error_cumulative_resolution.data.labels = dateList;
          issue_services_history_total_data.daily_services_total_error_cumulative_resolution.data.datasets = cumulativeDataset;
        } else {
          setAlertMessage(`getServiceHistoryInfo error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        console.log(`getServiceHistoryInfo error: `, error);
      })
      .finally(() => {
        setHistoryDisplay(true);
      })
  }

  const defaultRangeDays = 30; // 달력에 날짜를 보여 주기 위한 default 일자, 30일
  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());

    const thirtyDaysAgo = new Date(utcToday);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);

    const utcTodayString = utcToday.toISOString().substring(0, 10);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0, 10);

    setRangeDate([thirtyDaysAgoString, utcTodayString]); // [startDate, endDate]
  }

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
      {isHistoryDisplay ? (
        <div className="grid-row">
          <div className="row">
            <div className="box-total">
              <div className="head">
                <div className="title">
                  <h2>Number of issues (based on occurrence date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.BLUE}` }}></i>
                      <span>App</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PURPLE}` }}></i>
                      <span>Playback</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Schedule</span>
                    </li>
                  </ul>
                </div>
                {
                  chartType === 'Line' ? (
                    <LineChart id={'daily_total_error_occurrence'} data={issue_services_history_total_data.daily_services_total_error_occurrence.data} options={issue_services_history_total_data.daily_services_total_error_occurrence.options} plugins={plugin} />
                  ) : (
                    <BarChart id={'daily_total_error_occurrence'} data={issue_services_history_total_data.daily_services_total_error_occurrence.data} options={issue_services_history_total_data.daily_services_total_error_occurrence.options} plugins={plugin} />
                  )
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-total">
              <div className="head">
                <div className="title">
                  <h2>Issue resolution rate (based on occurrence date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.BLUE}` }}></i>
                      <span>App</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PURPLE}` }}></i>
                      <span>Playback</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Schedule</span>
                    </li>
                  </ul>
                </div>
                {
                  chartType === 'Line' ? (
                    <LineChart id={'daily_total_error_resolution'} data={issue_services_history_total_data.daily_services_total_error_resolution.data} options={issue_services_history_total_data.daily_services_total_error_resolution.options} plugins={plugin} />
                  ) : (
                    <BarChart id={'daily_total_error_resolution'} data={issue_services_history_total_data.daily_services_total_error_resolution.data} options={issue_services_history_total_data.daily_services_total_error_resolution.options} plugins={plugin} />
                  )
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="box-total">
              <div className="head">
                <div className="title">
                  <h2>Issue resolution rate (based on cumulative daily date)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.BLUE}` }}></i>
                      <span>App</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PURPLE}` }}></i>
                      <span>Playback</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Schedule</span>
                    </li>
                  </ul>
                </div>
                {
                  chartType === 'Line' ? (
                    <LineChart id={'daily_total_error_cumulative_resolution'} data={issue_services_history_total_data.daily_services_total_error_cumulative_resolution.data} options={issue_services_history_total_data.daily_services_total_error_cumulative_resolution.options} plugins={plugin} />
                  ) : (
                    <BarChart id={'daily_total_error_cumulative_resolution'} data={issue_services_history_total_data.daily_services_total_error_cumulative_resolution.data} options={issue_services_history_total_data.daily_services_total_error_cumulative_resolution.options} plugins={plugin} />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )}
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  )
}

export default IssueHistoryServiceTotal;