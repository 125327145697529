import * as actions from '../../actions/actionTypes';

const initialState = { name: '', menu: [] };

export default function role(state = initialState, action) {
  switch (action.type) {
    case actions.INIT_CURRENT_USER:
      return initialState;
    case actions.SET_CURRENT_USER:
      if (action.body && typeof action.body.name !== 'undefined') {
        let foundAdmin = action.body.roles.find(n => n.name === 'ROLE_SUPER_ADMIN');
        if (foundAdmin) {
          return { name: foundAdmin.name, menu: (foundAdmin.menu || '').split('|') };
        } else {
          let visibleMenus = []
          action.body.roles.map(role => {
            if (role.menu) {
              visibleMenus = visibleMenus.concat(role.menu.split('|'))
            }
          })
          foundAdmin = action.body.roles.filter(n => n.name !== 'ROLE_USER')[0] || state;
          return { name: foundAdmin.name, menu: visibleMenus };
        }
      } else {
        return state;
      }
    default:
      return state;
  }
}
