import { ROWHEADER_TYPE, TABLE_TYPE } from '../consts/IssConsts';
import { CHART_COLORS, CHART_BAR_COLOR } from '../common/ChartConsts';

export const sub_statistics_tabledata = {
  statistics: {
    tableType: TABLE_TYPE.CHECKBOX_SELECTION,
    title: 'Issue Status',
    visibleTitle: false,
    existTooltip: false,
    tooltip: '',
    header: ['Issue Id', 'Date', 'P', 'Type', 'Country',
    'Content Name', 'CP', 'Indicator', 'Value (AVG)', 'Rate',
    'Status', 'Assign', 'Modifier', 'Jira Url', 'Action Details'],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.DROPDOWN,
      ROWHEADER_TYPE.ASSIGN_DROPDOWN,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.JIRALINK,
      ROWHEADER_TYPE.NORMAL,
    ],
    dropdownItem: [
      { index: 'Unaddressed', name: 'Unaddressed' },
      { index: 'Processing', name: 'Processing' },
      { index: 'Resolve', name: 'Resolve' },
    ],
    assignDropdownItem: [],
    cellWidth: ['4%', '4%', '2%', '4%', '3%',
    '10%', '3%', '5%', '4%', '4%',
    '5%','5%', '5%', '5%', '10%'],
    rowdata: [],
  },
  existing_issues: {
    title: 'Issue History',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Statistics Issues tooltip message..!!',
    header: ['Issue Id', 'Date', 'P', 'Country', 'Content Name',
    'Rate', 'Cause', 'Assign', 'Modifier', 'Jira Url',
    'Action Details'],
    cellStyle: [ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.JIRALINK,
      ROWHEADER_TYPE.NORMAL],
    cellWidth: ['4%', '4%', '2%', '4%', '15%',
    '3%', '10%', '5%', '5%', '8%',
    '20%'],
    rowdata: [],
  },
  existing_empty_issues: {
    title: 'Issue History',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Statistics Issues tooltip message..!!',
    header: ['Issue Id', 'Date', 'P', 'Country', 'Content Name',
    'Rate', 'Cause', 'Assign', 'Modifier', 'Jira Url',
    'Action Details'],
    cellStyle: [ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.JIRALINK,
      ROWHEADER_TYPE.NORMAL],
    cellWidth: ['4%', '4%', '2%', '4%', '15%',
    '3%', '10%', '5%', '5%', '8%',
    '20%'],
    rowdata: [],
  },
  beacon_data: {
    tableType: TABLE_TYPE.CHECKBOX_SELECTION,
    title: 'beacon data',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Statistics Issues tooltip message..!!',
    header: ['Issue Id', 'Datetime', 'Lv', 'Beacon Type', 'Indicator',
    'Detection Type', 'Doc Count', 'Difference(from AVG)', 'Group', 'Status',
    'Assign', 'Modifier', 'Jira Url', 'Action Details'],
    dropdownItem: [
      { index: 'Unaddressed', name: 'Unaddressed' },
      { index: 'Processing', name: 'Processing' },
      { index: 'Resolve', name: 'Resolve' },
    ],
    assignDropdownItem: [],
    cellStyle: [ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.DROPDOWN,
      ROWHEADER_TYPE.ASSIGN_DROPDOWN,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.JIRALINK,
      ROWHEADER_TYPE.NORMAL],
    cellWidth: ['4%', '7%', '2%', '5%', '5%',
    '5%', '4%', '7%', '4%', '5%',
    '5%', '5%', '8%', '20%'],
    rowdata: [],
  },
  beacon_existing_issues: {
    title: 'Issue History',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Statistics Issues tooltip message..!!',
    header: ['Issue Id', 'Datetime', 'Lv', 'Doc Count', 'Difference(from AVG)',
    'Group', 'Cause', 'Assign', 'Modifier', 'Jira Url',
    'Action Details'],
    cellStyle: [ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.JIRALINK,
      ROWHEADER_TYPE.NORMAL],
    cellWidth: ['4%', '7%', '2%', '5%', '7%',
    '4%', '8%', '5%', '5%', '8%',
    '20%'],
    rowdata: [],
  },
  beacon_existing_empty_issues: {
    title: 'Issue History',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Statistics Issues tooltip message..!!',
    header: ['Issue Id', 'Datetime', 'Lv', 'Doc Count', 'Difference(from AVG)',
    'Group', 'Cause', 'Assign', 'Modifier', 'Jira Url',
    'Action Details'],
    cellStyle: [ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.JIRALINK,
      ROWHEADER_TYPE.NORMAL],
    cellWidth: ['4%', '7%', '2%', '5%', '7%',
    '4%', '8%', '5%', '5%', '8%',
    '20%'],
    rowdata: [],
  },
  feature_bat: {
    tableType: TABLE_TYPE.COLLAPSE,
    title: 'Feature Bat',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Statistics Issues tooltip message..!!',
    header: ['Country', 'Platform', 'Issue Code', 'Period', 'Indicator',
    'Cause Code', 'Jira'],
    cellStyle: [ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.JIRALINK],
    cellWidth: ['10%', '10%', '10%', '10%', '10%',
    '10%', '10%'],
    rowdata: [
      { row: ['Text', 'Text', 'Text', '23-08', 'Text', 'Text', 'Text'] },
      { row: ['Text', 'Text', 'Text', '23-08', 'Text', 'Text', 'Text'] },
      { row: ['Text', 'Text', 'Text', '23-08', 'Text', 'Text', 'Text'] },
      { row: ['Text', 'Text', 'Text', '23-08', 'Text', 'Text', 'Text'] },
      { row: ['Text', 'Text', 'Text', '23-08', 'Text', 'Text', 'Text'] },
      { row: ['Text', 'Text', 'Text', '23-08', 'Text', 'Text', 'Text'] },
      { row: ['Text', 'Text', 'Text', '23-08', 'Text', 'Text', 'Text'] },
      { row: ['Text', 'Text', 'Text', '23-08', 'Text', 'Text', 'Text'] },
      { row: ['Text', 'Text', 'Text', '23-08', 'Text', 'Text', 'Text'] },
      { row: ['Text', 'Text', 'Text', '23-08', 'Text', 'Text', 'Text'] },
    ],
  },
};

export const select_detail_data_type = {
  issue_type: [{ index: 'empty', name: 'empty' }],
  priority: [
    { index: 'P1', name: 'P1' },
    { index: 'P2', name: 'P2' },
    { index: 'P3', name: 'P3' },
  ],
  first_label: [
    { index: 'CMP-label', name: 'CMP-label' },
    { index: 'CMP-label2', name: 'CMP-label2' },
  ],
  second_label: [
    { index: 'Feed', name: 'Feed' },
    { index: 'Feed1', name: 'Feed1' },
    { index: 'Feed2', name: 'Feed2' },
  ],
};

export const sub_statistics_chartdata = {
  value_graph: {
    data: {
      labels: ['D-14', 'D-13', 'D-12', 'D-11', 'D-10', 'D-9', 'D-8', 'D-7', 'D-6', 'D-5', 'D-4', 'D-3', 'D-2', 'D-1', 'Today'],
      datasets: [ statistics_chartdata_value_dataset ],
    },
    options: {
      baseline: {
        display: true,
        value: 0,
        title: 'AVG',
      },
      baselineSubMax: {
        display: true,
        value: 0,
        title: 'Max',
      },
      baselineSubMin: {
        display: true,
        value: 0,
        title: 'Min',
      },
      legend: {
        display: false,
      },
      scales: {
        y: {
          min: -20,
          max: 25,
        },
      },
    },
  },

  changes_graph: {
    data: {
      labels: ['D-14', 'D-13', 'D-12', 'D-11', 'D-10', 'D-9', 'D-8', 'D-7', 'D-6', 'D-5', 'D-4', 'D-3', 'D-2', 'D-1', 'Today'],
      datasets: [ statistics_chartdata_change_dataset, statistics_chartdata_avg_dataset ],
    },
    options: {
      legend: {
        display: false,
      },
      scales: {
        y: {
          min: -20,
          max: 25,
        },
      },
    },
  },
};

export const statistics_chartdata_value_dataset = {
  label: 'Native Value',
  data: [],
  fill: false,
  pointRadius: 3,
  borderColor: '#85DDDB',
  //point style
  pointBorderColor: '#FFFFFF',
  pointBackgroundColor: '#85DDDB',
  pointBorderWidth: 2,
  order: 1,
};

export const statistics_chartdata_change_dataset = {
  label: 'Rate',
  data: [],
  fill: false,
  borderColor: '#85DDDB',
  //point style
  pointBorderColor: '#FFFFFF',
  pointBackgroundColor: '#85DDDB',
  pointBorderWidth: 2,
  order: 2,
};

export const statistics_chartdata_avg_dataset = {
  label: 'Avg Rate',
  data: [],
  fill: false,
  borderColor: CHART_BAR_COLOR.PINK,
  //point style
  pointBorderColor: '#FFFFFF',
  pointBackgroundColor: CHART_BAR_COLOR.PINK,
  pointBorderWidth: 2,
  order: 2,
};

export const sub_statistics_beacon_chart = {
  value_graph: {
    data: {
      labels: ['D-14', 'D-13', 'D-12', 'D-11', 'D-10', 'D-9', 'D-8', 'D-7', 'D-6', 'D-5', 'D-4', 'D-3', 'D-2', 'D-1', 'Today'],
      datasets: [ beacon_chartdata_value_dataset ],
    },
    options: {
      baseline: {
        display: true,
        value: 0,
        title: 'AVG',
      },
      scales: {
        y: {
          min: -20,
          max: 25,
        },
      },
    },
  },
  changes_graph: {
    data: {
      labels: ['H-69','H-66','H-63','H-60','H-57','H-54','H-51','H-48','H-45','H-42',
        'H-39', 'H-36', 'H-33', 'H-30', 'H-27','H-24', 'H-21', 'H-18', 'H-15', 'H-12',
        'H-9', 'H-6', 'H-3', 'H-0'],
      datasets: [ beacon_chartdata_change_dataset ],
    },
    options: {
      baseline: {
        display: true,
        value: 0,
        title: 'AVG',
      },
      scales: {
        y: {
          min: -20,
          max: 25,
        },
      },
    },
  },
};

export const beacon_chartdata_value_dataset = {
  label: 'Doc count',
  data: [],
  fill: false,
  pointRadius: 3,
  borderColor: CHART_COLORS.MINT, //'#85DDDB',
  //point style
  pointBorderColor: CHART_COLORS.WHITE, //'#FFFFFF',
  pointBackgroundColor: CHART_COLORS.MINT, //'#85DDDB',
  pointBorderWidth: 2,
  order: 2,
};

export const beacon_chartdata_change_dataset = {
  label: 'Doc count',
  data: [],
  fill: false,
  borderColor: CHART_COLORS.MINT, //'#85DDDB',
  //point style
  pointBorderColor: CHART_COLORS.WHITE, //'#FFFFFF',
  pointBackgroundColor: CHART_COLORS.MINT, //'#85DDDB',
  pointBorderWidth: 2,
  order: 2,
};