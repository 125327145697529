import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setDrawerOpenAction } from '../../actions/uiActions';
import { getLogAction } from '../../actions/logActions';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';

function Dashboard({ isRoleAdmin, setDrawerOpenAction, getLogAction, logs, history }) {
  useEffect(() => {
    setDrawerOpenAction(false);

    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    const startDateStr = startDate.toISOString().slice(0, 11) + '00:00:00Z'; //2020-03-01T00:00:00Z
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    const endDateStr = endDate.toISOString().slice(0, 11) + '00:00:00Z';
    getLogAction(isRoleAdmin, 'All', 'All', '', 'All', startDateStr, endDateStr);

    return () => {
      setDrawerOpenAction(true);
    };
  }, [isRoleAdmin]);

  return (
    <div>
      {/* <div className="dashboard_top">Latest Access 2020-03-10 02:30:16 (GTM)</div> */}
      <div className="dashboard_top" />
      <div className="dashboard_wrap">
        <div className="flex_row">
          <div className="leftT_board">
            <div className="tbl_wrap">
              <Button
                onClick={() => {
                  history.push('/SYSTEM/notification');
                }}
                className="btn_direct">
                View more
              </Button>
              <MaterialTable
                title="Notification" //없으면 Table Title이 default title로 나옴
                columns={[
                  {
                    title: 'Contents',
                    field: 'providerName',
                    width: '75%',
                  },
                  {
                    title: 'Date',
                    field: 'requestTime',
                    width: '25%',
                  },
                ]}
                data={
                  logs &&
                  logs.slice(0, 2).map(n => {
                    let newData = {};
                    //newData['apiName'] = n.apiName;
                    //newData['message'] = n.message;
                    newData['providerName'] = n.providerName;
                    newData['requestTime'] = <span className="date">{n.requestTime}</span>;
                    //   //console.log('newData=',newData);
                    return newData;
                  })
                }
                options={{
                  search: false,
                  paging: false,
                  // filtering: true,
                }}
              />
            </div>
          </div>
          <div className="rightT_board">
            <div className="tbl_wrap">
              {' '}
              {/*className={'btn_set_wrap tbl_top'}>*/}
              <Button
                onClick={() => {
                  history.push('/SYSTEM/apiCallLogs');
                }}
                className="btn_direct">
                View more
              </Button>
              <MaterialTable
                title="API Call Logs" //없으면 Table Title이 default title로 나옴
                columns={[
                  {
                    title: 'Categories',
                    field: 'providerName',
                    width: '15%',
                  },
                  {
                    title: 'Contents',
                    field: 'fileName',
                    width: '40%',
                  },
                  {
                    title: 'Status',
                    field: 'successFlag',
                    width: '20%',
                  },
                  {
                    title: 'Date',
                    field: 'requestTime',
                    width: '25%',
                  },
                ]}
                data={
                  logs &&
                  logs.slice(0, 5).map(n => {
                    let newData = {};
                    newData['fileName'] = n.fileName;
                    newData['providerName'] = <span className="cate_typ typ1">{n.providerName}</span>; //typ1~typ5
                    newData['successFlag'] = n.successFlag ? <span className="check_status success">Success</span> : <span className="check_status fail">Fail</span>;
                    newData['requestTime'] = <span className="date">{n.requestTime}</span>;
                    return newData;
                  })
                }
                options={{
                  search: false,
                  paging: false,
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex_row">
          <div className="leftB_board">
            <div className="board_head">
              <h6>Quick Menu</h6>
            </div>
            <div className="board_body">
              <div className="board_flex_row">
                <button
                  type="button"
                  className="quick_link link1"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = `/common/files/01. CDP Admin Operation Policy_(ENG)20210428.docx`;
                    link.target = '_blank';
                    link.download = '01. CDP Admin Operation Policy_(ENG)20210428.docx';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}>
                  <i></i>
                  <span className="txt">Operation Policy Guide</span>
                </button>
                <button
                  type="button"
                  className="quick_link link2"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = `/common/files/02. LG Smart TV CDP Admin_User Manual_(CP)_V1.1_ENG.pptx`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}>
                  <i></i>
                  <span className="txt">CMP Admin User Manual</span>
                </button>
              </div>
              <div className="board_flex_row">
                <button
                  type="button"
                  className="quick_link link3"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = `/common/files/03. Contact Information.docx`;
                    link.target = '_blank';
                    link.download = '03. Contact Information.docx';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}>
                  <i></i>
                  <span className="txt">
                    Contact
                    <br />
                    by menu
                  </span>
                </button>
                <button
                  type="button"
                  className="quick_link link4"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = `/common/files/04. LgChannels_CP_FAQ_v0.1.pdf`;
                    link.target = '_blank';
                    link.download = '04. LgChannels_CP_FAQ_v0.1.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}>
                  <i></i>
                  <span className="txt">FAQ</span>
                </button>
              </div>
            </div>
          </div>
          <div className="rightB_board">
            <div className="board_head">
              <h6>Frequently Used Menu</h6>
            </div>
            <div className="board_body">
              <ul className="used_menu_list">
                <li>
                  <button type="button" className="used_menu_link red" onClick={() => history.push('/PROGRAM/schedulelist')}>
                    <span className="link_num">1</span>
                    <span className="link_cont">
                      <span className="link_tit">Schedule</span>
                      <span className="link_location">PROGRAM &gt; Schedule</span>
                    </span>
                  </button>
                </li>
                <li>
                  <button type="button" className="used_menu_link" onClick={() => history.push('/CHANNEL/channelMapping')}>
                    <span className="link_num">2</span>
                    <span className="link_cont">
                      <span className="link_tit">IP Channel Mapping</span>
                      <span className="link_location">CHANNEL &gt; IP Channel Mapping</span>
                    </span>
                  </button>
                </li>
                <li>
                  <button type="button" className="used_menu_link" onClick={() => history.push('/STATISTICS/summary2')}>
                    <span className="link_num">3</span>
                    <span className="link_cont">
                      <span className="link_tit">Platform</span>
                      <span className="link_location">STATISTICS &gt; Platform</span>
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  isRoleAdmin: PropTypes.bool.isRequired,
  setDrawerOpenAction: PropTypes.func.isRequired,
  getLogAction: PropTypes.func.isRequired,
  logs: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isRoleAdmin: state.currentUser.isRoleAdmin,
  logs: state.log.logs,
});

const mapDispatchToProps = dispatch => ({
  setDrawerOpenAction(value) {
    dispatch(setDrawerOpenAction(value));
  },

  getLogAction(isRoleAdmin, cp, api, file, status, from, to) {
    dispatch(getLogAction(isRoleAdmin, cp, api, file, status, from, to));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
