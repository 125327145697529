//import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function allCps(state = initialState, action) {
  switch (action.type) {
    case 'SET_CNTRY':
      if (action.payload.status) {
        return state; //TODO
      } else if (action.payload) {
        const ricKeys = Object.keys(action.payload);
        const allCntry2DArray = ricKeys.map(item => Array.from(new Set(action.payload[item].map(item => item.providers).flat())));
        //console.log('allCntry1DArray', new Set(allCntry2DArray.flat()));
        return Array.from(new Set(allCntry2DArray.flat()));
      } else {
        return state;
      }
    case 'SET_CNTRY_ERROR':
      return state;
    default:
      return state;
  }
}
