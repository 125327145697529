import React, { useEffect, useState } from 'react';

import DatePickerTrend from '../common/DatePickerTrend';
import { getHeaders } from '../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../constants/index';
import DropDownForSearch from '../common/DropDownForSearch';
import DropDown from '../common/DropDown';
import RadioDropDown from '../common/RadioDropDown';
import Alert from '../modal/Alert';
import DateTimePicker from "../common/DateTimePicker";
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';

function FilterPopup(props) {
  const { componentId, filter, rangeDays, onCancel, onApply } = props;
  const [regions, setRegions] = useState({'dropdownList': [], 'checkedList' : []});
  const [countries, setCountries] = useState({'dropdownList': [], 'checkedList' : []});
  const [providers, setProviders] = useState({'dropdownList': [], 'checkedList' : []});
  const [platforms, setPlatforms] = useState({'dropdownList': [], 'checkedList' : []});

  const [paramRegions, setParamRegions] = useState([]);
  const [paramCountries, setParamCountries] = useState([]);
  const [paramProviders, setParamProviders] = useState([]);
  const [paramPlatforms, setParamPlatforms] = useState([]);
  const [searchFilter, setSearchFilter] = useState({});

  const [checkedCountryList, setCheckedCountryList] = useState([]);
  const [checkedProviderList, setCheckedProviderList] = useState([]);
  const [checkedPlatformList, setCheckedPlatformList] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedTime, setSelectedTime] = useState('00:00')

  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [calendarValue, setCalendarValue] = useState([]);  // [startDate, endDate] eg, 2023-08-20, 2023-09-19
  const [interval, setInterval] = useState({ dropdownList: ['d', 'h'] });
  const [paramInterval, setParamInterval] = useState('');

  const [customCheckboxFilter, setCustomCheckboxFilter] = useState([]);
  const [searchType, setSearchType] = useState({ dropdownList: [] });
  const [paramSearchType, setParamSearchType] = useState('');
  const [contentNameInput, setContentNameInput] = useState('');
  const [customRadioFilter, setCustomRadioFilter] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState('');

  const [checkedCountryCnt, setCheckedCountryCnt] = useState(0);
  const [checkedPlatformCnt, setCheckedPlatformCnt] = useState(0);

  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [templateFilter, setTemplateFilter] = useState([]);

  const [compareType, setCompareType] = useState({ dropdownList: [] });
  const [selectedCompareType, setSelectedCompareType] = useState('');
  const [paramCompareType, setParamCompareType] = useState('');

  useEffect(() => {
    getSearchFilter();
    customFilterSave(filter);
  }, []);

  useEffect(() => {
    if (calendarValue && calendarValue.length > 0) {
      const startDate = new Date(calendarValue[0]);
      const endDate = new Date(calendarValue[1]);
      const daysDifference = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

      if (daysDifference > 30) { // 한달 이상일 때에 최소 단위를 하루 이상으로 변경함
        setInterval({ dropdownList: ['d'] });
      } else {
        setInterval({ dropdownList: ['d', 'h'] });
      }
    }
  }, [calendarValue]);

  useEffect(() => {
    if (countries.dropdownList && countries.dropdownList.length > 0 && checkedCountryList && checkedCountryList.length > 0) {
      setCountries({'dropdownList' : countries.dropdownList, 'checkedList' : checkedCountryList});
      setCheckedCountryList([]);
    }
  }, [countries]);

  useEffect(() => {
    if (providers.dropdownList && providers.dropdownList.length > 0 && checkedProviderList && checkedProviderList.length > 0) {
      setProviders({'dropdownList' : providers.dropdownList, 'checkedList' : checkedProviderList});
      setCheckedProviderList([]);
    }
  }, [providers]);

  useEffect(() => {
    if (platforms.dropdownList && platforms.dropdownList.length > 0 && checkedPlatformList && checkedPlatformList.length > 0) {
      setPlatforms({'dropdownList' : platforms.dropdownList, 'checkedList' : checkedPlatformList});
      setCheckedPlatformList([]);
    }
  }, [platforms]);

  useEffect(() => {
    if ( searchFilter.countryByRegion ) {
      let dropdownCountries = [];
      if (paramRegions && paramRegions.length > 0) {
        if (filter.basicFilter.area === 'region') {
          dropdownCountries = searchFilter.countryByRegion.filter(f => paramRegions.includes(f.region)).map(region =>
            region.countryList.map(map => ({ id: map.countryCode, value : map.fullName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        } else {
          dropdownCountries = searchFilter.countryByRegion.filter(f => paramRegions.includes(f.groupName)).map(region =>
            region.countryList.map(map => ({ id: map.countryCode, value : map.fullName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        }
        dropdownCountries.sort((a, b) => a.value.localeCompare(b.value));
      }

      setCountries({'dropdownList': dropdownCountries, 'checkedList': [] });
    }
  }, [ paramRegions ]);

  useEffect(() => {
    if ( searchFilter.countryByRegion &&  searchFilter.providerByCountry) {
      let dropdownProviders = [];
      if (paramCountries && paramCountries.length > 0 && paramCountries.filter(item => item != 'Unknown').length > 0) {
        let ProviderList = searchFilter.providerByCountry.filter(f => paramCountries.filter(item => item != 'Unknown').includes(f.countryCode)).map(map =>
          map.providerList.map(pr => ({ id: pr.providerId, value : pr.providerName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownProviders = [...new Set(ProviderList.map((list) => JSON.stringify(list)))].map((list) => JSON.parse(list));
      }

      setProviders({ 'dropdownList': dropdownProviders, 'checkedList': checkedProviderList });
    }
  }, [ paramCountries ]);

  const getSearchFilter = () => {
    fetch(`${ISS_BASE_URL}/api/common/search-filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSearchFilter({
          countryByRegion : body.countryByRegion
          , providerByCountry : body.providerByCountry
          , platform: body.platform
        })
        let dropdownRegion = [];
        // countryByRegion
        if ( body.countryByRegion ) {
          if (filter.basicFilter.area === 'region') {
            dropdownRegion = body.countryByRegion.map(map =>({ id: map.region, value : map.region }));
          } else {
            dropdownRegion = Array.from(new Set(body.countryByRegion.map(map => map.groupName )));
            dropdownRegion = dropdownRegion.map(map =>({ id: map, value : map }));
          }
          dropdownRegion.sort((a, b) => a.value.localeCompare(b.value));
        }
        setRegions({ 'dropdownList': dropdownRegion,
          'checkedList': filter.selectedFilter ? filter.selectedFilter.region : [] });

        let platforms = [];
        let platformCodes = [];
        if ( body.platform ) {
          platforms = body.platform.map(map => map.productPlatform);
          body.platform.map(map => {
            platformCodes.push(map.productPlatform);
          })
          platforms = platforms.map(map =>({ id: map, value : map }));
          setPlatforms({'dropdownList' : platforms,
            'checkedList': filter.selectedFilter ? filter.selectedFilter.platforms : []});
        }
      } else {
        setAlertTitle('Error');
        setAlertMessage('[getSearchFilter : error]');
        onOpenAlert();
      }
    })
    .catch(error => {
      console.log("getSearchFilter - error: " , error);
      setAlertTitle('Error');
      setAlertMessage('[getSearchFilter : error]');
      onOpenAlert();
    }).finally(() => {});
  };

  const customFilterSave = (paramFilter) => {
    let customCheckboxFilter = [];

    if (paramFilter.selectedFilter) {
      setCheckedCountryList(paramFilter.selectedFilter.country);
      setCheckedProviderList(paramFilter.selectedFilter.providers);
      setCheckedPlatformList(paramFilter.selectedFilter.platforms);
      setSelectedDate(paramFilter.selectedFilter.date);
      setSelectedInterval(paramFilter.selectedFilter.interval);
      setSelectedCompareType(paramFilter.selectedFilter.compareType);
      setContentNameInput(paramFilter.selectedFilter.contentName);
    }

    paramFilter.customCheckboxFilter && paramFilter.customCheckboxFilter.forEach((filterItem) => {
      Object.entries(filterItem).forEach(([key, value]) => {
        const item = {key: key,
          value: {dropdownList: [...value]},
          selectedValue: [],
          func: (selectedValues) => {
            item.selectedValue = [...selectedValues];
          },
        };
        customCheckboxFilter.push(item);
      });
    });

    setCustomCheckboxFilter(customCheckboxFilter);

    let customRadioFilter = [];

    paramFilter.customRadioFilter && paramFilter.customRadioFilter.forEach((filterItem) => {
      Object.entries(filterItem).forEach(([key, value]) => {
        let placeholder
        if (paramFilter.hasOwnProperty('radioPlaceholder') && paramFilter.radioPlaceholder.hasOwnProperty(key)) {
          placeholder = paramFilter.radioPlaceholder[key]
        }
        const item = {key: key,
          value: {dropdownList: [...value]},
          selectedValue: '',
          func: (selectedValues) => {
            item.selectedValue = selectedValues;
          },
          placeholder: placeholder
        };
        customRadioFilter.push(item);
      });
    });

    setCustomRadioFilter(customRadioFilter);

    if (paramFilter.customInputFilter && paramFilter.customInputFilter.length > 0) {
      setSearchType({ dropdownList: paramFilter.customInputFilter });
    }

    if (paramFilter.compareFilter && paramFilter.compareFilter.length > 0) {
      setCompareType({ dropdownList: paramFilter.compareFilter });
    }
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const handleContentNameInputChange = event => {
    setContentNameInput(event.target.value);
  };

  const handleParamCountries = countriesArr => {
    setParamCountries(countriesArr);
    if (filter.hiddenFilter && filter.hiddenFilter.length > 0){
      let template = [];
      let templatePlatforms = [];
      let templateCountries = [];
      if (countriesArr && countriesArr.length > 0){
        setCheckedCountryCnt(countries.checkedList.length);
        if(checkedCountryCnt > 0 && checkedCountryCnt != countriesArr.length
          && countriesArr.length === countries.dropdownList.length
          && filter.selectedFilter && filter.selectedFilter.customCheckboxFilter[0].content.length > 0){
          filter.selectedFilter.customCheckboxFilter = [{ content : [] }];
        }
        countriesArr.forEach((val) => {
          const findContent = filter.hiddenFilter.filter(item => item.countryCode.includes(val)).map(item => item.contentName);
          if (findContent.length > 0){
            templateCountries.push(...findContent);
          }
        });
        if(paramPlatforms && paramPlatforms.length > 0 ){
          const tempPlatforms = paramPlatforms.map(map => map.replace('webOS', 'webOSTV').replace('webOSTV 22', 'webOSTV 7.0'));
          tempPlatforms.forEach((val) => {
            const findPlatforms = filter.hiddenFilter.filter(item => item.platform.includes(val)).map(item => item.contentName);
            if (findPlatforms.length > 0){
              templatePlatforms.push(...findPlatforms);
            }
          });
          templateCountries.forEach((val) => {
            const findCross = templatePlatforms.filter(item => item === val);
            if (findCross.length > 0){
              template.push(...findCross);
            }
          });
        } else {
          template = [...templateCountries];
        }
        const tempCustomCheckboxFilter = [{ content : [...new Set(template)] }];
        filter.customCheckboxFilter = tempCustomCheckboxFilter;
      } else {
        const tempCustomCheckboxFilter = [{ content : [] }];
        filter.customCheckboxFilter = tempCustomCheckboxFilter;
        if(filter.selectedFilter && filter.selectedFilter.customCheckboxFilter[0].content.length > 0
          && countries.dropdownList.length > 0){
          filter.selectedFilter.customCheckboxFilter = [{ content : [] }];
        }

      }
      customCheckboxFilterSave(template);
    }
  };

  const handleParamPlatforms = platformArr => {
    setParamPlatforms(platformArr);
    if (filter.hiddenFilter && filter.hiddenFilter.length > 0){
      let template = [];
      let templatePlatforms = [];
      let templateCountries = [];
      if (platformArr && platformArr.length > 0){
        setCheckedPlatformCnt(platforms.checkedList.length);
        if(checkedPlatformCnt > 0 && checkedPlatformCnt != platformArr.length
          && platformArr.length === platforms.dropdownList.length
          && filter.selectedFilter && filter.selectedFilter.customCheckboxFilter[0].content.length > 0){
          // All country select Deselect set
          filter.selectedFilter.customCheckboxFilter = [{ content : [] }];
        }
        const tempPlatforms = platformArr.map(map => map.replace('webOS', 'webOSTV').replace('webOSTV 22', 'webOSTV 7.0'));
        tempPlatforms.forEach((val) => {
          const findContent = filter.hiddenFilter.filter(item => item.platform.includes(val)).map(item => item.contentName);
          if (findContent.length > 0){
            templatePlatforms.push(...findContent);
          }
        });
        if(paramCountries && paramCountries.length > 0 ){
          paramCountries.forEach((val) => {
            const findCountryCode = filter.hiddenFilter.filter(item => item.countryCode.includes(val)).map(item => item.contentName);
            if (findCountryCode.length > 0){
              templateCountries.push(...findCountryCode);
            }
          });
          templatePlatforms.forEach((val) => {
            const findCross = templateCountries.filter(item => item === val);
            if (findCross.length > 0){
              template.push(...findCross);
            }
          });
        } else {
          template = [...templatePlatforms];
        }
        const tempCustomCheckboxFilter = [{ content : [...new Set(template)] }];
        filter.customCheckboxFilter = tempCustomCheckboxFilter;
      } else {
        const tempCustomCheckboxFilter = [{ content : [] }];
        filter.customCheckboxFilter = tempCustomCheckboxFilter;
        // Deselect set
        if(filter.selectedFilter && filter.selectedFilter.customCheckboxFilter[0].content.length > 0
          && platforms.dropdownList.length > 0){
            filter.selectedFilter.customCheckboxFilter = [{ content : [] }];
        }
      }
      customCheckboxFilterSave(template);
    }
  };

  const customCheckboxFilterSave = template => {
    if(filter.selectedFilter && filter.selectedFilter.customCheckboxFilter[0].content.length > 0 && template.length > 0){
      let tempSelectedFilter = [];
      let tempSelectedItem = [];
      // selected set
      const selectedValue = filter.selectedFilter.customCheckboxFilter[0].content;
      selectedValue.forEach((val) => {
        const findContent = template.filter(item => item === val);
        if (findContent.length > 0){
          tempSelectedItem.push(...findContent);
        }
      });
      tempSelectedFilter = [{ content : [...new Set(tempSelectedItem)] }];
      filter.selectedFilter.customCheckboxFilter = tempSelectedFilter;
    }

    // background set
    let duplicatedTemplate = new Set(template);
    let customCheckboxFilter = [];
    let tempTemplate = [];
    const item = {key: 'content',
      value: {dropdownList: [...duplicatedTemplate]},
      selectedValue: [],
      func: (selectedValues) => {
        item.selectedValue = [...selectedValues];
      },
    };
    customCheckboxFilter.push(item);
    setCustomCheckboxFilter(customCheckboxFilter);

    duplicatedTemplate.forEach((val) => {
      tempTemplate.push({contentName: val });
    });
    setTemplateFilter(tempTemplate);
  }

  const handleApply = () => {
    const tempCustomCheckboxFilter = customCheckboxFilter.map(item => {
      return { [item.key]: item.selectedValue };
    });

    const tempCustomRadioFilter = customRadioFilter.map(item => {
      return { [item.key]: item.selectedValue };
    });

    const changeFilter = {
      date: calendarValue,
      time: selectedTime,
      interval: paramInterval,
      region: paramRegions,
      country: paramCountries,
      providers: paramProviders,
      platforms: paramPlatforms,
      searchType: paramSearchType,
      contentName: contentNameInput ? contentNameInput.trim() : '',
      customCheckboxFilter: tempCustomCheckboxFilter,
      customRadioFilter: tempCustomRadioFilter,
      compareType: paramCompareType,
    }

    filter.selectedFilter = changeFilter;

    onApply(componentId, changeFilter);
  }

  useEffect(() => {
    if(customCheckboxFilter && customCheckboxFilter.length > 0 ){
      let tempSelectedItem = [];
      // selected set
      autoCompleteValue.forEach((val) => {
        const findContent =
        filter.hiddenFilter.filter(item => item.contentName === val.contentName)
        .map(map => map.contentName);
        if (findContent.length > 0){
          tempSelectedItem.push(...findContent);
        }
      });
      if(tempSelectedItem.length === 0){
        tempSelectedItem = [...templateFilter.map(item => item.contentName)];
      }
      // background set
      let customCheckboxFilter = [];
      const item = {key: 'content',
        value: {dropdownList: [...new Set(tempSelectedItem)]},
        selectedValue: [],
        func: (selectedValues) => {
          item.selectedValue = [...selectedValues];
        },
      };
      customCheckboxFilter.push(item);
      setCustomCheckboxFilter(customCheckboxFilter);
    }
  }, [autoCompleteValue]);

  const handleReset = () => {
    setAutoCompleteValue([]);
    let customCheckboxFilter = [];
      const item = {key: 'content',
        value: {dropdownList: [...new Set(templateFilter.map(item => item.contentName))]},
        selectedValue: [],
        func: (selectedValues) => {
          item.selectedValue = [...selectedValues];
        },
      };
      customCheckboxFilter.push(item);
      setCustomCheckboxFilter(customCheckboxFilter);
  }

  const useStyles = makeStyles({
    root: {
      backgroundColor: '#1c1c1c',
      color: '#fff',
      borderRadius: 0,
      border: '1px solid rgba(255, 255, 255, 0.1)',
      height: 25,
      padding: 0,
      margin: 0,
      fontSize: 14,
      '&[aria-selected="true"]': {
        backgroundColor: '#3a374e',
        border: '1px solid rgba(255, 255, 255, 0.1)',
      },
      '&[data-focus="true"]': {
        backgroundColor: '#3a374e',
        border: '1px solid rgba(255, 255, 255, 0.1)',
      },
    }
  });

  const classes = useStyles();

  return (
    <>
    <div className="filter-area">
      <div className="filter-header">
        <div> Indicator Filter </div>
        <div className="close" onClick={onCancel}> </div>
      </div>
      <div className="filter-separator"></div>
      <div className="period-filter">
        {
          filter.basicFilter.singlePeriod ? (
            <>
              <div className="filter-title">Basic</div>
              <div className="ui-datepicker-box">
                <DateTimePicker
                  dateChangeHandler={(newDate) => setCalendarValue(newDate)}
                  timeChangeHandler={setSelectedTime}
                  rangeDays={rangeDays}
                  selectedDate={selectedDate}
                  selectedFilter={filter.selectedFilter}
                />
              </div>
            </>
          ) : (
            <>
              <div className="filter-title">Basic</div>
              <div className="ui-datepicker-box">
                <DatePickerTrend
                  dateChangeHandler={(newDate) => setCalendarValue(newDate)}
                  rangeDays={rangeDays}
                  selectedDate={selectedDate}
                />
              </div>
            </>
          )
        }
        {
          filter.basicFilter.interval ? (
            <RadioDropDown
              item={interval}
              selectedItem={selectedInterval}
              onChangeDropDown={setParamInterval}
              uiClassName={`ui-dropdown-type07`}
            />
          ) : (
            <></>
          )
        }
      </div>
      {
        filter.basicFilter.area === 'none' && !filter.basicFilter.platform ? (
          <></>
        ) :
        (
          <>
            <div className="basic-filter">
              <div className="filter-title"></div>
              <div className="basic-filter-layout">
                {
                  filter.basicFilter.area === 'none' ? (
                    <></>
                  ) : (
                    <>
                      {
                        filter.basicFilter.area === 'region' ? (
                          <DropDownForSearch dropDownItem={regions} title="Region" onChangeDropDown={setParamRegions} scroll={true} uiClassName={`ui-dropdown-type06`} />
                        ) : (
                          <DropDownForSearch dropDownItem={regions} title="Group" onChangeDropDown={setParamRegions} scroll={true} uiClassName={`ui-dropdown-type06`} />
                        )
                      }
                      <DropDownForSearch dropDownItem={countries} title="Countries" onChangeDropDown={handleParamCountries} scroll={true} uiClassName={`ui-dropdown-type06`} />
                      {
                        filter.basicFilter.provider ? (
                          <DropDownForSearch dropDownItem={providers} title="CPs" onChangeDropDown={setParamProviders} scroll={true} uiClassName={`ui-dropdown-type06`} />
                        ) : (
                          <></>
                        )
                      }
                    </>
                  )
                }
                {
                  filter.basicFilter.platform ? (
                    <DropDownForSearch
                      dropDownItem={platforms}
                      title="Platforms"
                      onChangeDropDown={handleParamPlatforms}
                      scroll={true}
                      uiClassName={`ui-dropdown-type06`}
                    />
                  ) : (
                  <></>
                  )
                }
              </div>
            </div>
          </>
        )
      }
      { ((filter.customCheckboxFilter && filter.customCheckboxFilter.length > 0) ||
        (filter.customRadioFilter && filter.customRadioFilter.length > 0)) ? (
        <>
          <div className="filter-separator-sub"></div>
          <div className="custom-filter">
            <div className="filter-title">Custom</div>
            {
              filter.customCheckboxFilter && filter.customCheckboxFilter.length > 0 ? (
                customCheckboxFilter.map(filterItem =>
                  <DropDown
                    item={filterItem.value}
                    title={filterItem.key}
                    checkedList={filter.selectedFilter && filter.selectedFilter.customCheckboxFilter.length > 0 ?
                      filter.selectedFilter.customCheckboxFilter.find(item => item.hasOwnProperty(filterItem.key))[filterItem.key] : []}
                    onChangeDropDown={filterItem.func}
                    scroll={true}
                    uiClassName={`ui-dropdown-type07`}
                  />
                )
              ) : (
                <></>
              )
            }
            {
              filter.customRadioFilter && filter.customRadioFilter.length > 0 ? (
                customRadioFilter.map(filterItem =>
                  <RadioDropDown
                    item={filterItem.value}
                    title={filterItem.placeholder}
                    selectedItem={filter.selectedFilter && filter.selectedFilter.customRadioFilter.length > 0 ?
                      filter.selectedFilter.customRadioFilter.find(item => item.hasOwnProperty(filterItem.key))[filterItem.key] : ''}
                    onChangeDropDown={filterItem.func}
                    scroll={true}
                    uiClassName={`ui-dropdown-type07`}
                  />
                )
              ) : (
                <></>
              )
            }
          </div>
        </>
        ) : (
          <></>
        )
      }
      { filter.customInputFilter && filter.customInputFilter.length > 0 ? (
        <>
          <div className="custom-input-filter">
            <div className="filter-title"></div>
            <RadioDropDown
              item={searchType}
              onChangeDropDown={setParamSearchType}
              scroll={true}
              uiClassName={`ui-dropdown-type07`}
            />
            <div className="content-name">
              <input type="text" value={contentNameInput} placeholder={paramSearchType}
                onChange={event => handleContentNameInputChange(event)}
              />
            </div>
          </div>
        </>
       ) : (
        <></>
       )
      }
      {
        filter.hiddenFilter && filter.hiddenFilter.length > 0 ? (
          <>
            <div className="custom-filter">
              <div className="filter-title"></div>
              <Autocomplete
                multiple
                size="small"
                id="autoComplete"
                options={templateFilter}
                disableCloseOnSelect
                getOptionLabel={(option) => option.contentName}
                value={autoCompleteValue}
                handleHomeEndKeys
                onChange={(event, newValue) => {
                  setAutoCompleteValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                classes={{ option: classes.root }}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={selected}
                    />
                    {option.contentName}
                  </>
                )}
                renderInput={(params) => (
                  <div className="ui-dropdown-box">
                    <div className="content-search" ref={params.InputProps.ref} >
                      <input type="text" {...params.inputProps} label="Contents" placeholder="Contents"/>
                    </div>
                    <button onClick={(e) => {
                      e.stopPropagation();
                      handleReset();
                    }}><DeleteForeverIcon fontSize="medium" style={{ color: "#85dddb"}} />
                    </button>
                  </div>
                )}
              />
            </div>
          </>
        ) : (
          <></>
        )
      }
      {
        filter.compareFilter && filter.compareFilter.length > 0 ? (
          <>
            <div className="filter-separator-sub"></div>
            <div className="custom-filter">
              <div className="filter-title">Compare</div>
              <RadioDropDown
                item={compareType}
                selectedItem={selectedCompareType}
                onChangeDropDown={setParamCompareType}
                scroll={true}
                uiClassName={`ui-dropdown-type07`}
              />
            </div>
          </>
        ) : (
          <></>
        )
      }
      <div className="filter-footer">
        <button onClick={(e) => {
          e.stopPropagation();
          onCancel();
        }}>Cancel</button>
        <button onClick={(e) => {
          e.stopPropagation();
          handleApply();
        }}>Apply</button>
      </div>
    </div>
    {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default FilterPopup;
