import React, { useState } from 'react';
import SideBarIssueHistory from './SideBarIssueHistory';
import ContentsAreaIssueHistory from './ContentsAreaIssueHistory';

const IssueHistory = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentFilter, setCurrentFilter] = useState({subType:'All', date:'', country:'', provider:'', platform:''});

  return (
    <div className="iss-main">
      <div className="main-container">
        <div className="inner">
          <div className="sub-detail-wrapper sub-detail-issuehistory">
            <div className="grid-column-2">
              <SideBarIssueHistory currentTab={currentTab} changeTab={tab => setCurrentTab(tab)}  currentFilter={currentFilter} changeFilter={filter => setCurrentFilter(filter)}/>
              <ContentsAreaIssueHistory currentTab={currentTab} changeFilter={filter => setCurrentFilter(filter)}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IssueHistory;
