import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, DialogTitle, DialogContent, TextField } from '@material-ui/core';
import { updateANTestService, setABTestParamAction, getABTestTabCategoryAction, getCountAction, getSegmentDetails } from '../../../actions/homeAppActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import { request } from '../../../util/request';
import AddANServiceDailogFilter from './AddANServiceDailogFilter';

function EditANServiceDialog({ updateANTestService, abTestTabCategory, abTestParam, setABTestParamAction, regionList, regionPlatformList, handleTabClose, abTestRow, serviceId, setEndANService ,recrecmdSegmentIdSerID  }) {
  const today = new Date();
  const yestreday = new Date();
  const nextMonthDate = new Date();
  yestreday.setDate(today.getDate() - 1);
  nextMonthDate.setMonth(today.getMonth() + 1);
  const monthAgoDate = new Date();
  monthAgoDate.setMonth(monthAgoDate.getMonth() - 1);
  const [result, setResult] = React.useState([]);
  const [time, setTime] = useState('');
  const [title, setTitle] = useState();
  const [sampleSize, setSampleSize] = useState();
  const [sampleRatePer, setSampleRatePer] = useState();
  const [startDate, setStartDate] = React.useState(today.toISOString().slice(0, 10));
  const [endDate, setEndDate] = React.useState('');
  const [startTimeAnt, setStartTimeAnt] = useState('');
  const [endTimeAnt, setEndTimeAnt] = React.useState('');
  const [tabCode, setTabCode] = React.useState('');
  const [categoryCode, setCategoryCode] = React.useState('');
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth() + 1 > 9 ? now.getUTCMonth() + 1 : '0' + (now.getUTCMonth() + 1);
  const day = now.getUTCDate() > 9 ? now.getUTCDate() : '0' + now.getUTCDate();
  const hours = now.getUTCHours() > 9 ? now.getUTCHours() : '0' + now.getUTCHours();
  const minutes = now.getUTCMinutes() > 9 ? now.getUTCMinutes() : '0' + now.getUTCMinutes();
  const seconds = now.getUTCSeconds() > 9 ? now.getUTCSeconds() : '0' + now.getUTCSeconds();
  const [segmentList, setsegmentList] = React.useState([]);
  const [segmentListData, setSegmentListData] = React.useState(new Set());
  const [deviceType, setDeviceType] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');
  const [platformVersion, setPlatformVersion] = React.useState('');
  const [segmentId, setSegmentId] = React.useState('');
  const [mappingType, setMappingType] = React.useState('');
  const [endDateCheck, setEndDateCheck] = React.useState();
  const [recmdSegmentId, setRecmdSegmentId] = React.useState('');
  const [abTestId, setAbTestId] = React.useState('');
  const [segmentType, setSegmentType] = React.useState('');
  const [defaultGroupContent, setDefaultGroupContent] = React.useState(false);
  const [abtestSubid, setAbtestSubid] = useState();
  const [description, setDescription] = React.useState('');
  const [validToUpdate, setValidToUpdate] = React.useState(false);
  const [disableUpdate, setDisableUpdate] = React.useState(false);
  React.useEffect(() => {
    setABTestParamAction('', '', '', '', '', '');
  }, []);
  const dayOfweekList = [
    'SUN',
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
  ];
  React.useEffect(() =>{
    getRegion(deviceType, countryCode);
  },[countryCode]);

  const [dayOfWeek, setDayOfWeek] = React.useState(dayOfweekList);
  React.useEffect(() => {
    setTime(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
    fetchTbTestData();
  }, [serviceId]);

  const fetchTbTestData = async () => {
    const tbTestData = await request(`/api/anservice/${serviceId}`, 'GET', null, true);
    setTitle(tbTestData.title);
    setStartDate(tbTestData.startTimeLocal.split(' ')[0]);
    let stime = tbTestData.startTimeLocal.split(' ')[1].split(':')[0];
    stime = stime.startsWith(0) ? stime.substring(1, 2) : stime;
    setStartTimeAnt(stime);
    setEndDate(tbTestData.forcedEndTimeLocal.split(' ')[0]);
    let etime = tbTestData.forcedEndTimeLocal.split(' ')[1].split(':')[0];
    etime = etime.startsWith(0) ? etime.substring(1, 2) : etime;
    setEndTimeAnt(etime);
    setDeviceType(tbTestData.deviceType);
    setCountryCode(tbTestData.countryCode);
    setPlatformVersion(tbTestData.platformVersion);
    setTabCode(tbTestData.tabCode);
    setCategoryCode(tbTestData.categoryCode);
    setSegmentId(tbTestData.segmentId);
    setMappingType(tbTestData.mappingType);
    setEndDateCheck(tbTestData.forcedEndTimeLocal);
    setRecmdSegmentId(tbTestData.recmdSegmentId);
    setAbTestId(tbTestData.abtestId);
    setSegmentType(tbTestData.segmentType);
    setSegmentId(tbTestData.segmentId);
    setDefaultGroupContent(tbTestData.defaultContent === 'Y' ? true : false);
    //setDayOfWeek(tbTestData.dayOfWeek.filter(element => { return element !== null; }));
    setAbtestSubid(tbTestData.abtestSubid);
    setResult([]);
    setDescription(tbTestData.description)
    var startTime = new Date(tbTestData.startTimeLocal);
    startTime.setDate(startTime.getDate() - 1);
    let utcHour = await getUTCHour();
    startTime.setHours(utcHour);
    var todayDateUTC = new Date(formatDate(new Date()));

    startTime.setHours(utcHour);
    var startTimeCheck = new Date(tbTestData.startTime);
    if (todayDateUTC.getHours() < utcHour) {
      todayDateUTC.setHours(utcHour)
      todayDateUTC.setMinutes(0)
      todayDateUTC.setSeconds(0)
    } else {
      todayDateUTC.setDate(todayDateUTC.getDate() + 1);
      todayDateUTC.setHours(utcHour)
      todayDateUTC.setMinutes(0)
      todayDateUTC.setSeconds(0)
    }
    setValidToUpdate(checkForeligebleForEdit(tbTestData) || todayDateUTC > startTimeCheck);
    setABTestParamAction(tbTestData.deviceType, tbTestData.countryCode, tbTestData.region, tbTestData.platformVersion, '', '')
  };

  function checkForeligebleForEdit(ab) {
    if (new Date(ab.forcedEndTime) <= new Date(formatDate(new Date()))) {
      setDisableUpdate(true)
      return true;
    } else if (new Date(formatDate(new Date())) < new Date(ab.startTime)) {
      setDisableUpdate(false)
      return false;
    } else {
      setDisableUpdate(false)
      return true;
    }
  }

  async function getUTCHour() {
    let utcHour = await fetch(API_BASE_URL + '/abtest/utchour', {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        return body;
      });
    return utcHour;
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [date.getFullYear(), padTo2Digits(date.getUTCMonth() + 1), padTo2Digits(date.getUTCDate())].join('-') + ' ' + [padTo2Digits(date.getUTCHours()), padTo2Digits(date.getUTCMinutes()), padTo2Digits(date.getUTCSeconds())].join(':');
  }

  const clickSave = async e => {
    e.preventDefault();
    if (!title || title.trim().length === 0) alert('Please enter the Title');
    else if (title.length > 255) alert('Title should be within 255 characters');
    else if (startTimeAnt === '') alert('Please select start time');
    else if (!endDate) alert('Please select an end date');
    else if (endTimeAnt === '') alert('Select end time');
    else if (!tabCode) alert('Please select a tab code');
    else if (!categoryCode) alert('Please select a category code');
    else if (!segmentList) alert('Please select the Segment');
    else if (result.find(n => n.result_order === '')) alert('Some order is empty ! Please enter the order !');
    //TODO order duplication check
    else if (window.confirm('Are you sure to save the result?')) {
      let startTimeLocal = startTimeAnt > 9 ? startDate + ' ' + startTimeAnt + ':00:00' : startDate + ' 0' + startTimeAnt + ':00:00';
      let endTimeLocal = endTimeAnt > 9 ? endDate + ' ' + endTimeAnt + ':00:00' : endDate + ' 0' + endTimeAnt + ':00:00';

      const addSeviceData = {
        serviceId,
        title,
        startTimeLocal,
        endTimeLocal,
        tabCode,
        categoryCode,
        segmentList,
        dayOfWeek,
        ...abTestParam,
      };
      updateANTestService(addSeviceData);
      setEndANService(true)
      handleTabClose();
    }
  };
  const getCountryCode = value => {
    gePtlatformVersion(value);
    return regionList.find(n => n.deviceType === value).list[0].cntry;
  };

  const gePtlatformVersion = (deviceType, region) => {
    if (regionPlatformList.find(n => n.deviceType === deviceType && n.region === region)) {
      return regionPlatformList.find(n => n.deviceType === deviceType && n.region === region).platformVersion;
    }
    return '';
  };

  const getRegion = (deviceType, countryCode) => {
    abTestParam.region = '';
    (regionList.find(n => n.deviceType === deviceType) || { list: [] }).list.forEach(a => {
      if (a.cntry.includes(countryCode)) {
        abTestParam.region = a.region;
      }
    });
    return abTestParam.region;
  };

  React.useEffect(() => {
    abTestRow && getSegmentDetails(abTestRow.abtestId).then(response => {
      const updatedsegmentListData = new Set(segmentListData); // Create a copy of the existing set
      response.forEach(data => {
        data.serviceList = [];
        updatedsegmentListData.add(data);
        abTestRow.segmentList.push(data);
      });
      setSegmentListData(updatedsegmentListData); // Update the state with the modified set
    });

  }, [abTestRow && abTestRow.abtestId]);

  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        Edit A/N Service
        <IconButton aria-label="close" onClick={handleTabClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form id="addRowFrm" autoComplete="off">
        <DialogContent className={'pop_body'}>
          <div>
            <AddANServiceDailogFilter
              title={title}
              handleChangeTitle={e => {
                setTitle(e.target.value);
              }}
              startDate={startDate}
              handleChangeStartDate={e => {
                setStartDate(e.target.value);
                setEndDate('');
                setEndTimeAnt('');
              }}
              startTime={startTimeAnt}
              handleChangeStartTime={e => {
                setStartTimeAnt(e.target.value);
                setEndTimeAnt('');
              }}
              endDate={endDate}
              handleChangeEndDate={e => {
                setEndDate(e.target.value);
                setEndTimeAnt('');
              }}
              endTimeAnt={endTimeAnt}
              handleChangeEndTime={e => {
                setEndTimeAnt(e.target.value);
              }}
              regionList={regionList}
              deviceType={abTestParam.deviceType}
              handleChangeDeviceType={e => {
                setABTestParamAction(e.target.value, getCountryCode(e.target.value), regionList.find(n => n.deviceType === e.target.value).list[0].region, gePtlatformVersion(e.target.value, regionList.find(n => n.deviceType === e.target.value).list[0].region), abTestParam.mappingType);
              }}
              countryCode={abTestParam.countryCode}
              region={abTestParam.region}
              recrecmdSegmentIdSerID={recrecmdSegmentIdSerID}
              handleChangeCountry={e => {
                setABTestParamAction(abTestParam.deviceType, e.target.value, getRegion(abTestParam.deviceType, e.target.value), gePtlatformVersion(abTestParam.deviceType, e.target.value), abTestParam.mappingType);
                setCountryCode( e.target.value);
              }}
              regionPlatformList={regionPlatformList}
              platformVersion={abTestParam.platformVersion}
              abTestTabCategory={abTestTabCategory}
              handleChangePlatformVersion={e => {
                setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, e.target.value, abTestParam.mappingType);
              }}
              tabCode={tabCode}
              handleChangeTab={e => {
                setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, abTestParam.mappingType);
                setTabCode(e.target.value);
              }}
              categoryCode={categoryCode}
              handleChangeCategory={e => {
                setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, abTestParam.mappingType);
                setCategoryCode(e.target.value);
              }}
              mappingType={abTestParam.mappingType}
              handleChangeMappingType={e => {
                setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, e.target.value);
              }}
              dayOfWeek={dayOfWeek}
              handleDayOfWeekChange={ev => {
                const {
                  target: { value },
                } = ev;
                setDayOfWeek(
                  typeof value === 'string' ? value.split(',') : value,
                );
              }}
              segmentList={segmentList}
              segmentListData={segmentListData}
              handleSegmentListData={selectedSegment => {
                // Check if the segment is already selected
                const isSegmentSelected = segmentList.includes(selectedSegment);
                // If the segment is selected, remove it
                if (isSegmentSelected) {
                  setsegmentList(segmentList.filter(segment => segment !== selectedSegment));
                }
                // If the segment is not selected, add it
                else {
                  setsegmentList([...segmentList, selectedSegment]);
                }
              }}
              abTestRow={abTestRow}
              validToUpdate={validToUpdate}
              disableUpdate={disableUpdate}
            />
          </div>
          <div className={'btn_set_wrap'}>
            <Button type="submit" color="primary" className={'btn_color2'} onClick={clickSave}>
              Save
            </Button>
            <Button data-testid="addButton" color="primary" onClick={handleTabClose} className={'btn_color1'}>
              Cancel
            </Button>
          </div>
        </DialogContent>
      </form>
    </Fragment>
  );
}

EditANServiceDialog.propTypes = {
  abTestTabCategory: PropTypes.array.isRequired,
  abTestParam: PropTypes.object.isRequired,
  getABTestTabCategoryAction: PropTypes.func.isRequired,
  getSupportLanguages: PropTypes.func.isRequired,
  searchNotification: PropTypes.func.isRequired,
  regionList: PropTypes.array.isRequired,
  regionPlatformList: PropTypes.array.isRequired,
  updateANTestService: PropTypes.func.isRequired,
  handleTabClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  abTestParam: state.homeApp.abTestParam,
  abTestTabCategory: state.homeApp.abTestTabCategory,
  regionList: state.homeApp.regionList,
  regionPlatformList: state.homeApp.regionPlatformList,
  fetching: state.homeApp.manualResultFetching,
});

const mapDispatchToProps = dispatch => ({
  setABTestParamAction(deviceType, countryCode, region, platformVersion, tabCode, categoryCode, mappingType) {
    dispatch(setABTestParamAction(deviceType, countryCode, region, platformVersion, tabCode, categoryCode, mappingType));
  },
  getABTestTabCategoryAction(deviceType, region, platformVersion) {
    dispatch(getABTestTabCategoryAction(deviceType, region, platformVersion));
  },
  updateANTestService(addSeviceData) {
    dispatch(updateANTestService(addSeviceData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditANServiceDialog));
