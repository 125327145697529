import React, { useEffect } from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 12px;
    --y: 1110px;
    --width: 1896px;
    --height: 240px;

    top: 0;
    left: 0;
    width: 1920px;
    height: 1400px;
  }

  #text-01 {
    top: 1038px;
    left: 189px;
  }

  #line-01 {
    top: 1048px;
    left: 70px;
  }
`;

const ContentDetail03 = () => {
  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">You can see list of similar issues</div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_6138_line_01.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default ContentDetail03;