import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingIndicator from '../../common/LoadingIndicator';
import MaterialTable from 'material-table';

function TableHomeAppChart({ tableDetail, tableDetailFetching }) {
  let tabcategoryData = [];
  let typeData = [];

  tableDetail.length > 0 && tableDetail.forEach(n => {
    if (n.tableType === 'tabCategory') {
      tabcategoryData.push(n);
    } else if (n.tableType === 'type') {
      typeData.push(n);
    }
  });

  const tabcategoryDataList = tabcategoryData.length < 10 ? [tabcategoryData.length, 10] : tabcategoryData.length > 10 ? [10, tabcategoryData.length] : [10];
  const typeDataList = typeData.length < 10 ? [typeData.length, 10] : typeData.length > 10 ? [10, typeData.length] : [10];
  return (
    <React.Fragment>
      {tableDetailFetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        tabcategoryData.length > 0 && (
          <div className="tbl_wrap tbl_col_typ1 tbl_summary">
            <br />
            <br />
            <center style={{ fontSize: '23px', fontWeight: 500 }}>Usability by Tab/Categories</center>

            <MaterialTable
              title=""
              columns={[
                { title: 'Tab', field: 'firstDivision' },
                { title: 'Category', field: 'secondDivision' },
                { title: 'Unique Device', field: 'uniqueDevice' },
                { title: 'Access', field: 'access' },
                { title: 'Play Count', field: 'playCount' },
              ]}
              data={JSON.parse(JSON.stringify(tabcategoryData))}
              options={{
                fixedColumns: {
                  left: 2,
                },
                pageSize: 10,
                pageSizeOptions: tabcategoryDataList,
                exportButton: true,
              }}
            />
          </div>
        )
      )}
      {tableDetailFetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        typeData.length > 0 && (
          <div className="tbl_wrap tbl_col_typ1 tbl_summary">
            <br />
            <br />
            <center style={{ fontSize: '23px', fontWeight: 500 }}>Usability by Types</center>

            <MaterialTable
              title=""
              columns={[
                { title: 'Type', field: 'firstDivision' },
                { title: 'Name', field: 'secondDivision' },
                { title: 'Unique Device', field: 'uniqueDevice' },
                { title: 'Access', field: 'access' },
                { title: 'Play Count', field: 'playCount' },
              ]}
              data={JSON.parse(JSON.stringify(typeData))}
              options={{
                fixedColumns: {
                  left: 2,
                },
                pageSize: 10,
                pageSizeOptions: typeDataList,
                exportButton: true,
              }}
            />
          </div>
        )
      )}
    </React.Fragment>
  );
}

TableHomeAppChart.propTypes = {
  tableDetail: PropTypes.array.isRequired,
  tableDetailFetching: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  tableDetail: state.statistic.tableDetail,
  tableDetailFetching: state.statistic.tableDetailFetching,
});

export default connect(mapStateToProps)(TableHomeAppChart);
