const initialState = [];
//
export default function cm0007(state = initialState, action) {
  switch (action.type) {
    case 'SET_CM0007':
      if (action.payload) {
        return action.payload;
      } else {
        return state;
      }
    case 'SET_CM0007_ERROR':
      return state;
    default:
      return state;
  }
}
