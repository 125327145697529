import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ABTestManualTable from './ABTestManualTable';
import { request } from '../../../util/request';
import { getRegionProvidersAdminAction } from '../../../actions/providerActions';
import { getHeaders } from '../../../util/actionUtil';
import { useState, useEffect } from 'react';
import { API_URL } from '../../../constants';
import { getCountAction } from '../../../actions/homeAppActions';

export default function ABTestManualSearch({ getCountAction, abTestTabCategory, tabCode, categoryCode, cat, countryCode, selectSearch, status, categoryResultData, tableRef, region,  mappingType }) {
  const [cp, setCp] = React.useState('All');
  const typeList = (cat.contentType || '').split(',');
  const [type, setType] = React.useState(typeList[0] || '');
  const genreList = (cat.genre || '').split(',');
  const [genre, setGenre] = React.useState(genreList[0] || '');
  const [id, setId] = React.useState('');
  const [name, setName] = React.useState('');
  const [data, setData] = React.useState([]);

  const clickSearch = async event => {
    event.preventDefault();
    if (!type) alert('Select content type');
    const result = await request(`/api/abTestManualSearch?cntry=${countryCode}&type=${type}&cp=${cp}&id=${id}&name=${name}&status=${status}`, 'GET', null, true);
    if (genre && genre != 'All') {
      setData(
        result
          .filter(n2 => n2.genre === genre)
          .map(n => {
            return { ...n, type: type };
          }),
      );
    } else {
      setData(
        result.map(n => {
          return { ...n, type: type };
        }),
      );
    }
  };

  return (
    <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list'}>
      <CpFilter2
        getCountAction={getCountAction}
        abTestTabCategory={abTestTabCategory}
        tabCode={tabCode}
        categoryCode={categoryCode}
        countryCode={countryCode}
        typeList={typeList}
        type={type}
        genre={genre}
        setType={setType}
        cp={cp}
        setCp={setCp}
        id={id}
        setId={setId}
        name={name}
        setName={setName}
        region={region}
        clickSearch={clickSearch}
        setGenre={setGenre}
      />

      <ABTestManualTable
        data={mappingType === 'auto' ? [] : data}
        tableRef={tableRef}
        select={selectSearch}
        options={{
          selection: true,
          showTextRowsSelected: false,
          // selectionProps: () => ({
          //   checked: false,
          // }),
          pageSize: 15,
          pageSizeOptions: [5, 15, 30, 50, 100],
          doubleHorizontalScroll: true,
          maxBodyHeight: 500,
          minBodyHeight: 500,
          rowStyle: rowData => ({
            backgroundColor: categoryResultData.find(x => x.content_id === rowData.id && x.cp_id === rowData.cp) ? '#e0e0e0' : rowData.tableData.checked ? '#fef5f8' : '#FFF',
            color: rowData.tableData.checked ? '#d8295c' : '#484848',
            pointerEvents: !rowData.tableData.checked && categoryResultData.find(x => x.content_id === rowData.id && x.cp_id === rowData.cp) ? 'none' : 'initial',
          }),
          selectionProps: rowData => {
            const findCurrentRow = categoryResultData.find(x => x.content_id === rowData.id && x.cp_id === rowData.cp);
            rowData.tableData.disabled = findCurrentRow ? true : false;
            return {
              disabled: findCurrentRow ? true : false,
            };
          },
        }}
      />
    </div>
  );
}
ABTestManualSearch.propTypes = {};

function CpFilter({ countGenre, getCountAction, abTestTabCategory, tabCode, categoryCode, countryCode, genre, setGenre, type, setType, cp, setCp, id, setId, name, setName, getRegionProvidersAdminAction, region, clickSearch, mappingType }) {
  const [cpDetail, setCPDetails] = React.useState('All');
  const [genreDetail, setGenreDetail] = React.useState([]);
  React.useEffect(() => {
    region && getRegionProvidersAdminAction(region);
  }, []);
  const getContentType = () => {
    const categoryDetailsTemp = abTestTabCategory.find(n => n.tabCode === tabCode);
    const categoryCodeTemp = (categoryDetailsTemp && categoryDetailsTemp.categoryDetails.find(n => n.categoryCode === categoryCode)) || null;
    const contentTypeTemp = (categoryCodeTemp && categoryCodeTemp.contentType.split(',')) || [];
    return contentTypeTemp;
  };
  useEffect(() => {
    if (countryCode) {
      const apiUrl = `/api/abTestSearch/${countryCode}/feeds?cpName=`;
      fetch(`${API_URL}${apiUrl}`, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then(res => res.json())
        .then(data => {
          const result = (data || []).map(data => data.cpName);
          setCPDetails(result);
        });
    }
  }, [countryCode]);
  useEffect(() => {
    if (type && cp) fetchGenre();
  }, [region, countryCode, type, cp]);

  const fetchGenre = async () => {
    const genData = await request(`/api/region/getGenre/${cp}?countryCode=${countryCode}&region=${region}&type=${type}&cp=${cp}`, 'GET', null, true);
    setGenreDetail(genData);
  };
  let data = '';
  data = genreDetail.map(n => n.genreName);
  data = [...new Set(data)]
  // useEffect(() => {
  //     if (countryCode ) {
  //       getCountAction('genre', countryCode);
  //     }
  //   }, [countryCode]);
  const AllCountry = ['All'];
  const cpNameList = [...cpDetail, ...AllCountry];
  return (
    <div className="filter_area row">
      <div className="filter_row">
        {abTestTabCategory && (
          <FormControl required variant="outlined">
            <InputLabel>Type</InputLabel>
            <Select
              name="channelMapList"
              id="search_channelMapList"
              value={type}
              onChange={e => {
                setType(e.target.value);
              }}>
              {['live', 'tvshow', 'movie', 'tvshow-series', 'livestream'].map(n => (
                <MenuItem key={n} value={n}>
                  {n}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl variant="outlined" size="medium">
          <InputLabel>CP</InputLabel>
          <Select
            value={cp}
            onChange={e => {
              setCp(e.target.value);
            }}>
            {cpNameList.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="medium">
          <InputLabel>Genre</InputLabel>
          <Select
            value={genre}
            onChange={e => {
              setGenre(e.target.value);
            }}>
            {data.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField //FormControl안에 넣으면 줄안맞음
          margin="dense"
          variant="outlined"
          label="id" //focus안줬을때 mandatory text
          size="medium"
          value={id}
          onChange={e => {
            setId(e.target.value);
          }}
        />
        <TextField //FormControl안에 넣으면 줄안맞음
          margin="dense"
          size="medium"
          variant="outlined"
          label="name" //focus안줬을때 mandatory text
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
        />
        <div className="MuiFormControl-root btn_set_wrap search_wrap">
          <Button className="btn_color2" onClick={clickSearch} >
            search
          </Button>
        </div>
      </div>
    </div>
  );
}

CpFilter.propTypes = {
  regionProvider: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  regionProvider: state.providers.regionProvider,
  countGenre: state.homeApp.countGenre,
});
const mapDispatchToProps = dispatch => ({
  getRegionProvidersAdminAction(region) {
    dispatch(getRegionProvidersAdminAction(region));
  },
  getCountAction(genreType, countryCode) {
    dispatch(getCountAction(genreType, countryCode));
  },
});

const CpFilter2 = connect(mapStateToProps, mapDispatchToProps)(CpFilter);
