import React, { useEffect, useState } from 'react';
import DatePickerDefault from '../common/DatePickerDefault';
import DefaultButton from '../common/DefaultButton';
import { CHART_COLORS } from '../common/ChartConsts';
import LineChart from '../common/LineChart';
import { assign_issue_history_data } from './AssignHistoryData';
import annotationPlugin from 'chartjs-plugin-annotation';

import { getHeaders } from '../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../constants/index';
import Alert from '../modal/Alert';
import Loading from '../common/Loading';

const AssignHistory = () => {
  const [calendarValue, setCalendarValue] = useState([]); // [startDate, endDate] eg, 2023-08-20, 2023-09-19
  const [rangeDate, setRangeDate] = useState([]);

  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');

  const [displayGroup, setDisplayGroup] = useState([]);
  const [displayColor, setDisplayColor] = useState([CHART_COLORS.BLUE, CHART_COLORS.PINK,
    CHART_COLORS.MINT, CHART_COLORS.ORANGE, CHART_COLORS.PURPLE, CHART_COLORS.GOLD,
    CHART_COLORS.CHOCOLATE, CHART_COLORS.BROWN, CHART_COLORS.LAVENDER, CHART_COLORS.IVORY]);
  const [isDisplayGroupDailyIssue, setDisplayGroupDailyIssue] = useState(false);
  const [isDisplayGroupRemainIssue, setDisplayGroupRemainIssue] = useState(false);

  const plugin = [annotationPlugin];

  useEffect(() => {
    defaultRangeDate();
  }, []);

  useEffect(() => {
    if (rangeDate && rangeDate.length > 0) {
      setDisplayGroupDailyIssue(false);
      setDisplayGroupRemainIssue(false);
      setDisplayGroup([]);
      getAssignIssueCount();
      getAssignIssueCount('UNRESOLVED');
    }
  }, [rangeDate]);

  // Default date는 30일
  const defaultRangeDays = 30; // 달력에 날짜를 보여 주기 위한 default 일자, 30일
  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());

    const thirtyDaysAgo = new Date(utcToday);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);

    const utcTodayString = utcToday.toISOString().substring(0, 10);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0, 10);

    setRangeDate([thirtyDaysAgoString, utcTodayString]); // [startDate, endDate]
  };

  const issueSearchByButton = () => {
    setRangeDate([calendarValue[0], calendarValue[1]]); // [startDate, endDate]
  };

  const getAssignIssueCount = (type) => {
    const searchType = type ? type : '';

    fetch(`${ISS_BASE_URL}/api/common/history/assign/count?fromDate=${rangeDate[0]}&toDate=${rangeDate[1]}&searchType=${searchType}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          const options = {
            scales: {
              y: {
                min: 0,
                max: 0,
                ticks: {
                  stepSize: 10,
                },
              },
            },
          };

          const groupList = Object.keys(body.dataSet);
          let tempDisplayGroup = [];

          setDisplayGroup(prevItem => {
            if (prevItem.length === 0) {
              groupList.map((group, index) => {
                tempDisplayGroup.push({ name: group, color: displayColor[index % displayColor.length] });
              });
              return tempDisplayGroup;
            } else {
              tempDisplayGroup = prevItem;
              return prevItem;
            }
          })

          if (body.searchType === 'UNRESOLVED') {
            const remainIssueCountDataSets = [];
            const maxYCandidateList = [];

            tempDisplayGroup.map((group) => {
              const remainIssueCountItem = {};

              remainIssueCountItem.label = group.name;
              remainIssueCountItem.data = body.dataSet[group.name].map(item => item.sumCumulative);
              remainIssueCountItem.fill = false;
              remainIssueCountItem.pointRadius = 3;
              remainIssueCountItem.borderColor = group.color;
              remainIssueCountItem.pointBorderColor = '#FFFFFF';
              remainIssueCountItem.pointBackgroundColor = group.color;
              remainIssueCountItem.pointBorderWidth = 2;
              remainIssueCountItem.order = 2;

              maxYCandidateList.push(Math.max(...remainIssueCountItem.data));
              remainIssueCountDataSets.push(remainIssueCountItem);
            });

            options.scales.y.max = Math.max(...maxYCandidateList);
            assign_issue_history_data.remain_issue_count.data.labels = body.dataSet[groupList[0]].map(item => item.issueDate.substring(5, 10));
            assign_issue_history_data.remain_issue_count.data.datasets = [];
            assign_issue_history_data.remain_issue_count.data.datasets = remainIssueCountDataSets;
            assign_issue_history_data.remain_issue_count.options = options;

            setDisplayGroupRemainIssue(true);
          } else {
            const dailyIssueCountDataSets = [];
            const maxYCandidateList = [];

            tempDisplayGroup.map((group) => {
              const dailyIssueCountItem = {};

              dailyIssueCountItem.label = group.name;
              dailyIssueCountItem.data = body.dataSet[group.name].map(item => item.sumCount);
              dailyIssueCountItem.fill = false;
              dailyIssueCountItem.pointRadius = 3;
              dailyIssueCountItem.borderColor = group.color;
              dailyIssueCountItem.pointBorderColor = '#FFFFFF';
              dailyIssueCountItem.pointBackgroundColor = group.color;
              dailyIssueCountItem.pointBorderWidth = 2;
              dailyIssueCountItem.order = 2;

              maxYCandidateList.push(Math.max(...dailyIssueCountItem.data));
              dailyIssueCountDataSets.push(dailyIssueCountItem);
            });

            options.scales.y.max = Math.max(...maxYCandidateList);
            assign_issue_history_data.daily_issue_count.data.labels = body.dataSet[groupList[0]].map(item => item.issueDate.substring(5, 10));
            assign_issue_history_data.daily_issue_count.data.datasets = [];
            assign_issue_history_data.daily_issue_count.data.datasets = dailyIssueCountDataSets;
            assign_issue_history_data.daily_issue_count.options = options;

            setDisplayGroupDailyIssue(true);
          }
        } else {
          setAlertMessage(`getAssignIssueCount error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`getAssignIssueCount error:${error}`);
        onOpenAlert();
      });
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
      <div className="iss-main">
        <div className="main-container">
          <div className="inner">
            <div className="sub-detail-wrapper sub-detail-assignhistory">
              <div className="grid-column-2">
                <div className="contents-section">
                  <div className="ui-components">
                    <div className="head-title">
                      <div>Assign History</div>
                    </div>
                    <div className="box-left">
                      <div className="ui-datepicker-box">
                        <DatePickerDefault dateChangeHandler={newDate => setCalendarValue(newDate)} />
                      </div>
                      <div className="ui-dropdown-box">
                        <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={issueSearchByButton} />
                      </div>
                    </div>
                  </div>
                  {isDisplayGroupDailyIssue && isDisplayGroupRemainIssue ? (
                    <div className="grid-row">
                      <div className="row">
                        <div className="box-total">
                          <div className="head">
                            <div className="title">
                              <h2> Number of issues (based on occurrence date) </h2>
                            </div>
                          </div>
                          <div className="ui-chart-container">
                            <div className="chart-line-legend">
                              <ul>
                                {displayGroup.map((item, index) => (
                                  <li key={index}>
                                    <i style={{ backgroundColor: item.color }}></i>
                                    <span> {item.name} </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <LineChart id={'assign_issue_history_data'} data={assign_issue_history_data.daily_issue_count.data} options={assign_issue_history_data.daily_issue_count.options} plugins={plugin} />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="box-total">
                          <div className="head">
                            <div className="title">
                              <h2> Number of remained issues by date </h2>
                            </div>
                          </div>
                          <div className="ui-chart-container">
                            <div className="chart-line-legend">
                              <ul>
                                {displayGroup.map((item, index) => (
                                  <li key={index}>
                                    <i style={{ backgroundColor: item.color }}></i>
                                    <span> {item.name} </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <LineChart id={'assign_issue_history_data'} data={assign_issue_history_data.remain_issue_count.data} options={assign_issue_history_data.remain_issue_count.options} plugins={plugin} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="loading_wrap">
                      <Loading isVisible={true} />
                    </div>
                  )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
};
export default AssignHistory;
