import * as actions from '../../actions/actionTypes';

const initialState = false;

export default function pbchannelnameresult(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PUBLISH_CHANNEL_NAME_RESULT:
      return action.payload;
    default:
      return false;
  }
}
