export const assign_issue_history_data = {
  daily_issue_count: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: function (label) {
              return `${this.getLabelForValue(label)}  `;
            },
          },
        },
      },
    },
  },

  remain_issue_count: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: function (label) {
              return `${this.getLabelForValue(label)}  `;
            },
          },
        },
      },
    },
  },
};
