//import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function abTestDetails(state = initialState, action) {
  switch (action.type) {
    case 'SET_AB_TEST_DETAILS':
      let testing = [];
      let ready = [];
      let done = [];
      action.payload.map(ab => {
        if (new Date(ab.forcedEndTime) <= new Date(formatDate(new Date()))) {
          ab.status = 'Done';
          ab.segmentList= [];
          done.push(ab);
        } else if (new Date(formatDate(new Date())) < new Date(ab.startTime)) {
          ab.status = 'Ready';
          ab.segmentList= [];
          ready.push(ab);
        } else {
          ab.status = 'Testing';
          ab.segmentList= [];
          testing.push(ab);
        }
      });

      return [...testing, ...ready, ...done];
    default:
      return state;
  }
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return [date.getFullYear(), padTo2Digits(date.getUTCMonth() + 1), padTo2Digits(date.getUTCDate())].join('-') + ' ' + [padTo2Digits(date.getUTCHours()), padTo2Digits(date.getUTCMinutes()), padTo2Digits(date.getUTCSeconds())].join(':');
}

