import { SET_PROVIDERS, SET_PROVIDERS_FETCHING, SET_REGION_PROVIDERS } from './actionTypes';

import { API_BASE_URL } from '../constants';

import { getHeaders } from '../util/actionUtil';

const setProvidersFetchingAction = value => ({
  type: SET_PROVIDERS_FETCHING,
  payload: value,
});

const fetchGetProviderSuccess = body => {
  return {
    type: SET_PROVIDERS,
    payload: body,
  };
};

const fetchGetProvidersFailure = ex => {
  return {
    type: SET_PROVIDERS,
    payload: ex,
  };
};

const fetchGetRegionProviderSuccess = body => {
  return {
    type: SET_REGION_PROVIDERS,
    payload: body,
  };
};

const fetchGetRegionProvidersFailure = ex => {
  return {
    type: SET_REGION_PROVIDERS,
    payload: ex,
  };
};

export const getProvidersAdminAction = () => dispatch => {
  //export const getProvidersAdminAction = (page, size, providerId, providerName) => dispatch => {
  // page = page || 0;
  // size = size || PROVIDER_LIST_SIZE;
  // providerId = providerId || '';
  // providerName = providerName || '';
  // const requestParams = `?providerId=${providerId}&providerName=${providerName}`;
  // const apiUrl = API_BASE_URL + '/admin/providers' + requestParams;
  const apiUrl = API_BASE_URL + '/admin/providers';

  dispatch(setProvidersFetchingAction(true));
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json()) //convert to json
    .then(body => dispatch(fetchGetProviderSuccess(body))) //include 400Error
    .catch(ex => dispatch(fetchGetProvidersFailure(ex)));
};

export const getRegionProvidersAdminAction = (region) => dispatch => {
  const apiUrl = API_BASE_URL + `/admin/providers/region/${region}`;

  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch(fetchGetRegionProviderSuccess(body)))
    .catch(ex => dispatch(fetchGetRegionProvidersFailure(ex)));
};

// export const addProvidersAdminAction = (json) => (dispatch) => {
//   const apiUrl = API_BASE_URL + '/admin/providers';
//   dispatch(setProvidersFetchingAction(true));
//   return (
//     fetch(apiUrl, {
//       method: 'POST',
//       headers: getHeaders(),
//       body: JSON.stringify(json),
//     })
//       .then((res) => res.json())
//       //.then((body) => dispatch(console.log('[addProvidersAdminAction][server response]', body)))
//       .then((body) =>
//         dispatch({
//           type: 'SET_PROVIDERS_ADD',
//           payload: body,
//         }),
//       ) //include 400Error
//       .catch((ex) => dispatch(fetchGetProvidersFailure(ex)))
//   );
// };
