import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import AlertDialog from '../../../common/dialog/AlertDialog';
import ConfirmDialog from '../../../common/dialog/ConfirmDialog';

const OrganizedContentList = (props) => {
  const { resultTableRef
        , savedRelatedContent
        , moveContentList
        , deletedContentList
        , callbackDeletingContent
        , callbackSavedRelatedContent
        , currentChannelId
        , status
        , isDisplayContent
        , isDisplayChannel
        , isChannelListChanged
        , callbackDeploy
        , callbackViewSavedContentIds
        , callbackCategoryTitle
        } = props;

  const [viewSavedContentList, setViewSavedContentList] = useState([]);
  const [maxSelectableContentList, setMaxSelectableContentList] = useState(15);
  const [deletingContentList, setDeletingContentList] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [displayWarningList, setDisplayWarningList] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [confirmDescription, setConfirmDescription] = useState('');
  const [confirmId, setConfirmId] = useState('');
  const [isAutoSaveButtonEnable, setIsAutoSaveButtonEnable] = useState(true);
  const [isManualSaveButtonEnable, setIsManualSaveButtonEnable] = useState(false);
  const [isNormalSaveButtonEnable, setIsNormalSaveButtonEnable] = useState(false);
  const [isPublishButtonEnable, setIsPublishButtonEnable] = useState(true);

  useEffect(() => {
    callbackViewSavedContentIds(viewSavedContentList.map(item => item.contentId));
  }, [viewSavedContentList]);

  useEffect(() => {
    setIsNormalSaveButtonEnable(false);
    setIsManualSaveButtonEnable(false);
    setIsPublishButtonEnable(true);
  }, [isDisplayChannel]);

  useEffect(() => {
    if (savedRelatedContent && savedRelatedContent.length > 0) {
      setViewSavedContentList([...savedRelatedContent.sort((a,b) => a.contentOrder - b.contentOrder)]);
    } else {
      setViewSavedContentList([]);
    }
  }, [savedRelatedContent]);

  useEffect(() => {
    if (isChannelListChanged) {
      setIsPublishButtonEnable(false);
    }
  }, [isChannelListChanged]);

  useEffect(() => {
    if (moveContentList && moveContentList.length > 0) {
      const duplicatedContentId = moveContentList.filter(item =>
        viewSavedContentList.some(prevItem => prevItem.contentId === item.contentId)).map(item => item.contentId);
      if (duplicatedContentId && duplicatedContentId.length > 0) {
        if (duplicatedContentId.length === 1) {
          setAlertDescription(`There is duplicate content id`);
          setDisplayWarningList(duplicatedContentId);
        } else {
          setAlertDescription(`There are duplicate content ids`);
          setDisplayWarningList(duplicatedContentId);
        }
        setIsAlert(true);
      } else {
        const currentCount = viewSavedContentList.length + moveContentList.length;
        if (currentCount > maxSelectableContentList) {
          setAlertDescription(`Please select any content within 15, current selected content counts are ${currentCount}`);
          setIsAlert(true);
        } else {

          const contentListWithOrder = moveContentList.map((item, index) => {
            return {
              ...item,
              contentOrder: viewSavedContentList.length + index + 1
            };
          });

          setIsNormalSaveButtonEnable(true);
          setIsManualSaveButtonEnable(true);
          setIsPublishButtonEnable(false);
          setViewSavedContentList(prevData => [...prevData, ...contentListWithOrder]);
        }
      }
    }
  }, [moveContentList]);

  useEffect(() => {
    callbackDeletingContent(JSON.parse(JSON.stringify(deletingContentList.map(({tableData, ...rest}) => rest))));
  }, [deletingContentList]);

  useEffect(() => {
    if (deletedContentList && deletedContentList.length > 0) {
      const updatedContent = viewSavedContentList.filter(prevItem =>
        !deletedContentList.some(item => item.contentId === prevItem.contentId));

      const reorderedData = updatedContent.map((item, index) => {
        return {
          ...item,
          contentOrder: index + 1
        };
      });

      setIsNormalSaveButtonEnable(true);
      setIsManualSaveButtonEnable(true);
      setIsPublishButtonEnable(false);
      setViewSavedContentList([...reorderedData]);
    }
  }, [deletedContentList]);

  const processAllAutoSave = () => {
    setConfirmId('allAutoSave');
    setConfirmDescription(`Do you want to change related content rule to auto for all channels ?`);
    setIsConfirm(true);
  };

  const processAllManualSave = () => {
    setConfirmId('allManualSave');
    setConfirmDescription(`Do you want to change related content rule to manual for all channels ?`);
    setIsConfirm(true);
  };

  const handleSave = () => {
    setIsNormalSaveButtonEnable(false);
    setIsManualSaveButtonEnable(false);
    setIsPublishButtonEnable(true);
    callbackSavedRelatedContent({channelId: currentChannelId, relatedContent: viewSavedContentList});
  };

  const processDeploy = () => {
    setConfirmId('deploy');
    setConfirmDescription(`Do you want to publish ?`)
    setIsConfirm(true);
  };

  const handleConfirm = (id) => {
    setIsConfirm(false);
    if (id === 'deploy') {
      callbackDeploy();
    } else if (id === 'allAutoSave') {
      callbackSavedRelatedContent({channelId: 'allAuto'});
    } else if (id === 'allManualSave') {
      setIsManualSaveButtonEnable(false);
      setIsPublishButtonEnable(true);
      callbackSavedRelatedContent({channelId: 'allManual', relatedContent: viewSavedContentList});
    }
  };

  return (
    <React.Fragment>
      {
        isDisplayContent ? (
          <div>
            <h3>{status}</h3>
            <MaterialTable
              tableRef={resultTableRef}
              resultTableRef
              title=""
              columns={[
                {
                  title: 'Order',
                  field: 'contentOrder',
                  align: 'center',
                  width: '10%',
                },
                {
                  title: 'Type',
                  field: 'contentType',
                  align: 'center',
                  width: '10%',
                },
                {
                  title: 'CP',
                  field: 'providerName',
                  align: 'center',
                  width: '15%',
                },
                {
                  title: 'ID',
                  field: 'contentId',
                  align: 'center',
                  width: '20%',
                },
                {
                  title: 'name',
                  field: 'contentName',
                  align: 'center',
                  width: '20%',
                },
                {
                  title: 'Genre',
                  field: 'genre',
                  align: 'center',
                  width: '15%',
                },
                {
                  title: 'Image',
                  field: 'imageUrl',
                  align: 'center',
                  width: '10%',
                  render: rowData => <img src={rowData.imageUrl} style={{ width: 70, backgroundColor: 'black' }} />,
                },
              ]}
              data={viewSavedContentList}
              options={{
                paging: false,
                sorting: true,
                selection: true,
                minBodyHeight: 100,
                maxBodyHeight: 500,
              }}

              onSelectionChange={(rows) => {
                setDeletingContentList(rows);
              }}
            />
          </div>
        ) : (
          <></>
        )
      }
      {
        isDisplayChannel ? (
        <div className="btn_set_wrap">
          <Button className="btn_color2"
            onClick={callbackCategoryTitle}
          >
            Category Title
          </Button>
          <Button className="btn_color2"
            onClick={processAllAutoSave}
            disabled={!isAutoSaveButtonEnable}
          >
            All Auto Save
          </Button>
          <Button className="btn_color2"
            onClick={processAllManualSave}
            disabled={!isManualSaveButtonEnable}
          >
            All Manual Save
          </Button>
          <Button className="btn_color2"
            onClick={handleSave}
            disabled={!isNormalSaveButtonEnable && !isChannelListChanged}
          >
            Save
          </Button>
          <Button className="btn_color2"
            onClick={processDeploy}
            disabled={!isPublishButtonEnable}
          >
            {status ==='Testing' || status === 'SavedTesting' ? 'Testing' : 'Published'}
          </Button>
        </div>
        ) : (
          <></>
        )
      }
      <AlertDialog
        openAlert={isAlert}
        description={alertDescription}
        warningList={displayWarningList}
        closeHandler={() => { setIsAlert(false); }}
      />
      <ConfirmDialog
        id={confirmId}
        openDialog={isConfirm}
        description={confirmDescription}
        confirmHandler={handleConfirm}
        closeHandler={() => { setIsConfirm(false); }}
      />
    </React.Fragment>
  );
}

export default OrganizedContentList;
