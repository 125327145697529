import React, { useEffect, useState, useRef } from 'react';
import Loading from '../../../common/Loading';
import { Bar } from 'react-chartjs-2';
import Alert from '../../../modal/Alert';
import { getHeaders } from '../../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../../constants/index';
import { feed_duplicated_error_trend_data, CHART_COLORS_BY_ERROR, ERROR_TOOLTIP_INFO } from './FeedTrendData';

function FeedDuplicatedErrorTrend(props) {
  const { height
        , title
        , originalFilter
        , currentFilter
        , updateTable
        , id
      } = props;
  const [trendData, setTrendData] = useState({data: {}, options: {}});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [isTrendDisplay, setTrendDisplay] = useState(false);

  const chartRef = useRef(null);

  useEffect(() => {
    if (currentFilter) {
      console.log(`[ISS] FeedDuplicatedErrorTrend, filter: `, currentFilter);
      setTrendDisplay(false);
      setTrendData({});
      getTrendFeedDuplicatedError(currentFilter);
    }
  }, [currentFilter]);

  function getColor(errorCode) {
    return CHART_COLORS_BY_ERROR[errorCode] || CHART_COLORS_BY_ERROR['CDP_OTHERS'];
  }

  function getErrorTooltip(errorCode) {
    return ERROR_TOOLTIP_INFO[errorCode] || ERROR_TOOLTIP_INFO['CDP_OTHERS'];
  }

  const getTrendFeedDuplicatedError = (filter) => {
    const tempCustomCheckboxFilter = [];

    filter.customCheckboxFilter && filter.customCheckboxFilter.map((item, index) => {
      const [key, value] = Object.entries(item)[0];
      tempCustomCheckboxFilter.push(`${key}=${value.join(',')}`);
    });

    const customCheckboxFilter = tempCustomCheckboxFilter ? tempCustomCheckboxFilter.join('&') : '';
    const tempInterval = filter.interval ? filter.interval : 'd';
    const tempProvider = filter.providers ? filter.providers : '';

    let param = '';
    if (customCheckboxFilter) {
      param = `date=${filter.date}&interval=${tempInterval}&provider=${tempProvider}&${customCheckboxFilter}`;
    } else {
      param = `date=${filter.date}&interval=${tempInterval}&provider=${tempProvider}`;
    }

    fetch(`${ISS_BASE_URL}/api/application/dashboard/trend/feed/duplicated-error/count?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          const uniqueErrorCode = [...new Set(body.dataSet.map(item => item.errorCode))].sort();
          const tempCustomCheckboxFilter = [
            {
              api : ['/programschedules', '/channels', '/vods']
            },
            {
              errorCode : uniqueErrorCode
            }
          ];

          if (!customCheckboxFilter) {
            originalFilter.customCheckboxFilter = tempCustomCheckboxFilter;
          }

          const groupedData = body.dataSet.reduce((acc, curr) => {
            if (!acc[curr.providerId]) {
              acc[curr.providerId] = {};
            }
            acc[curr.providerId][curr.errorCode] = curr.errorCount;
            return acc;
          }, {});

          const datasets = uniqueErrorCode.map(code => {
            const data = Object.values(groupedData).map(item => item[code] > 0 ? item[code] : null);
            const label = data.every(value => value === null) ? '' : code;

            return {
              label: label,
              data: data,
              backgroundColor: getColor(code),
              borderColor: getColor(code),
              borderWidth: 1,
              stack: 'Stack 0'
            };
          });

          const chartData = {
            labels: Object.keys(groupedData),
            datasets: datasets
          };

          const options = {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              xAxes: [{ stacked: true }],
              yAxes: [{ stacked: true }]
            },
            plugins: {
              datalabels: {
                display: false,
              },
              legend: {
                display: true,
                labels: {
                  usePointStyle: true,
                  boxWidth: 6,
                  boxHeight: 6,
                  color: '#fff',
                  font: {
                    size: 12,
                  },
                },
                onHover: (event, legendItem) => {
                  const tooltip = document.getElementById(`tooltip_${id}`);
                  const chart = chartRef.current;
                  if (!chart) return;

                  const legendItems = chart.legend.legendItems;
                  const legendItemIndex = legendItems.indexOf(legendItem);
                  const legendBox = chart.legend.legendHitBoxes[legendItemIndex];

                  const tooltipX = legendBox.left + (legendBox.width / 2);
                  const tooltipY = legendBox.top;

                  tooltip.style.display = 'block';
                  tooltip.style.left = `${tooltipX + 10}px`;
                  tooltip.style.top = `${tooltipY + 45}px`;
                  tooltip.innerHTML = getErrorTooltip(legendItem.text);
                },
                onLeave: () => {
                  const tooltip = document.getElementById(`tooltip_${id}`);
                  tooltip.style.display = 'none';
                },
              },
            },
          };

          setTrendData({data: chartData, options: options});

          // update table
          feed_duplicated_error_trend_data.rowdata = body.dataSet.map(item => ({
            row: [
              item.providerId,
              item.errorCode,
              item.errorCount
            ],
          }));
          updateTable(feed_duplicated_error_trend_data);
          // update table

          setTrendDisplay(true);
        } else {
          setAlertMessage(`trend feed basic error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`trend feed basic error: `, error);
        onOpenAlert();
      })
      .finally(() => {});
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
    {
      isTrendDisplay ? (
        <div style={{ height: height }}>
          <div className='trend-head'>
            <div className="title" style={{top: "-30px"}}>
              <div> {title} </div>
            </div>
          </div>
          <Bar ref={chartRef} data={trendData.data} options={trendData.options} height='100%' />
          <div
            id={`tooltip_${id}`}
            style={{
              display: 'none',
              position: 'absolute',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              padding: '5px',
              borderRadius: '3px',
              pointerEvents: 'none',
              transform: 'translate(-50%, -100%)',
            }}
          />
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )
    }
    {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default FeedDuplicatedErrorTrend;