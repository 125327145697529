import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import LoadingIndicator from '../../../common/LoadingIndicator';
import Button from '@material-ui/core/Button';
import { ProgramFilter } from '../../../common/filter';
import { Link } from 'react-router-dom';
import { getUserCreatedProgramsAction } from '../../../actions/programActions';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';

const initChannel = {
  channelMajorNumber: 0,
  channelName: 'All',
};
function ProgramList({ fetching, getUserCreatedProgramsAction, programs, isRoleAdmin, cntryCpResponse }) {
  const [shouldRequest, setShouldRequest] = React.useState(true);
  const [biz, setBiz] = React.useState('ALL');
  const bizArea = cntryCpResponse.find(item => item.biz === biz);
  const [country, setCountry] = React.useState('US');
  const [cp, setCp] = React.useState('PLT');
  const [channels, setChannels] = React.useState([initChannel]);
  const [channel, setChannel] = React.useState('All');
  const [title, setTitle] = React.useState('');
  const [programId, setProgramId] = React.useState('');
  const [keyword, setKeyword] = React.useState('');

  const requestChannels = (selectedCntry, selectedCp) => {
    const path = '/schedules?date=1900-01-01T00:00:00Z&countryCode=';

    fetch(isRoleAdmin ? API_BASE_URL + '/admin' + path + (selectedCntry ? selectedCntry : country) + '&providerId=' + selectedCp : API_BASE_URL + path + country, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.status) {
          //alert('[server error] ' + body.message);
        } else {
          setChannels([initChannel, ...body]);
          setChannel('All');
        }
      })
      .catch(error => {
        alert('[error message] ' + error);
      });
  };

  const handleChangeCntry = e => {
    const cps = bizArea ? bizArea.area.find(item => item.countryCode === e.target.value) : { providers: [''] };
    setCountry(e.target.value);

    if (cps.providers.length) {
      setCp(cps.providers[0]);
      requestChannels(e.target.value, cps.providers[0]);
    } else {
      setCp('All');
    }
  };

  useEffect(() => {
    if (shouldRequest) {
      getUserCreatedProgramsAction(isRoleAdmin, country, cp, channel === 'All' ? '' : channel, title, programId, keyword);
      setShouldRequest(false);
    }
  }, [shouldRequest]);
  useEffect(() => {
    requestChannels(country, cp);
  }, []);

  const contents = [
    {
      title: 'Image',
      field: 'image',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: rowData => <img alt="invalid" src={rowData.image} title={rowData.image} style={{ maxWidth: 40, maxHeight: 24, border: '1px solid #cbccce' }} />,
    },
    {
      title: 'ID',
      field: 'programId',
      width: 'auto',
      // eslint-disable-next-line react/display-name
      render: data => (
        <Link className="link" to={`/PROGRAM/program/${data.providerId}/${data.programId}`}>
          {data.programId}
        </Link>
      ),
    },
    {
      title: 'Title',
      field: 'title',
      width: '27%',
    },
    { title: 'Season', field: 'seasonNo', width: '10%', filtering: false },
    { title: 'Episode', field: 'episodeNo', width: '10%', filtering: false },
    { title: 'Duration(sec)', field: 'duration', width: '10%', filtering: false },
  ];

  return (
    <div>
      <ProgramFilter
        biz={biz}
        bizArea={bizArea}
        handleChangeBiz={e => setBiz(e.target.value)}
        country={country}
        handleChangeCntry={handleChangeCntry}
        cp={isRoleAdmin ? cp : null}
        handleChangeCp={e => {
          requestChannels(null, e.target.value);
          setCp(e.target.value);
        }}
        channels={channels}
        channel={channel}
        handleChangeChannel={e => setChannel(e.target.value)}
        title={title}
        handleInputChangeTitle={e => setTitle(e.target.value)}
        programId={programId}
        handleInputChangeProgramId={e => setProgramId(e.target.value)}
        keyword={keyword}
        handleInputChangeKeyword={e => setKeyword(e.target.value)}
      />
      <div className="btn_set_wrap search_wrap">
        <Button variant="contained" className={'btn_color2'} onClick={() => setShouldRequest(true)}>
          Search
        </Button>
      </div>
      {fetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        <MaterialTable
          title="" //"Program List"//Filter를 가려서 일단 주석처리
          columns={
            isRoleAdmin
              ? [
                  {
                    title: 'CP Code',
                    field: 'providerId',
                    width: '10%',
                  },
                  ...contents,
                ]
              : contents
          }
          data={
            programs && programs.map
              ? programs.map(n => {
                  let newData = {};
                  newData['providerId'] = isRoleAdmin ? n.providerId : null;
                  newData['programId'] = n.programId;
                  newData['title'] = (n.multiLanguageList && n.multiLanguageList.length > 0) ? n.multiLanguageList[0].title : null;
                  newData['image'] = n.imageUrl;
                  newData['seasonNo'] = n.seasonNo;
                  newData['episodeNo'] = n.episodeNo;
                  newData['duration'] = n.duration;
                  return newData;
                })
              : []
          }
          options={{
            pageSize: 10,
            pageSizeOptions: [5, 10, 20, 50],
            rowStyle: { backgroundColor: '#fff' },
          }}
        />
      )}
    </div>
  );
}
ProgramList.propTypes = {
  //classes: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  getUserCreatedProgramsAction: PropTypes.func.isRequired,
  programs: PropTypes.array.isRequired,
  isRoleAdmin: PropTypes.bool.isRequired,
  cntryCpResponse: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  fetching: state.programs.fetching,
  programs: state.programs.programs,
  isRoleAdmin: state.currentUser.isRoleAdmin,
  cntryCpResponse: state.cntry.countries,
});
const mapDispatchToProps = dispatch => ({
  getUserCreatedProgramsAction(isRoleAdmin, country, cp, channel, title, programId, keyword) {
    dispatch(getUserCreatedProgramsAction(isRoleAdmin, country, cp, channel, title, programId, keyword));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProgramList));
