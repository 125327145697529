import { ROWHEADER_TYPE } from '../../consts/IssConsts';
import { CHART_COLORS, CHART_BAR_COLOR } from '../../common/ChartConsts';

export const dashboard_statistics_tabledata = {
  statistics_issues: {
    title: 'Statistics Change',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Statistics Issues tooltip message..!!',
    header: ['Issue Id', 'Date', 'P', 'Country', 'Type', 'Content Name', 'CP', 'Indicator', 'Value (AVG)', 'Rate', 'Action'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.LONG, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['5%', '5%', '3%', '4%', '4%', '15%', '5%', '9%', '8%', '5%', '7%'],
    rowdata: [],
  },
  statistics_empty_issues: {
    title: 'Empty Statistics Change',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Statistics Issues tooltip message..!!',
    header: ['Issue Id', 'Date', 'P', 'Country', 'Type', 'Content Name', 'CP', 'Indicator', 'Value (AVG)', 'Rate', 'Action'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.LONG, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['5%', '5%', '3%', '4%', '4%', '15%', '5%', '9%', '8%', '5%', '7%'],
    rowdata: [],
  },
};

export const dashboard_statistics_beacon_tabledata = {
  beacon_data_issue: {
    title: 'Beacon Data',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Beacon Issues tooltip message..!!',
    header: ['Issue Id', 'Datetime', 'Beacon Type', 'Indicator', 'Detecting Type', 'Doc Count', 'Difference(from AVG)', 'Group', 'Action'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.LONG, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
    rowdata: [],
  },
  beacon_data_empty_issue: {
    title: 'Empty Beacon Data',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Beacon Issues tooltip message..!!',
    header: ['Issue Id', 'Datetime', 'Beacon Type', 'Indicator', 'Detecting Type', 'Doc Count', 'Difference(from AVG)', 'Group', 'Action'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.LONG, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
    rowdata: [],
  },
};

export const statistics_chartdata_value_dataset = {
  label: 'Native Value',
  data: [],
  fill: false,
  pointRadius: 3,
  borderColor: '#85DDDB',
  //point style
  pointBorderColor: '#FFFFFF',
  pointBackgroundColor: '#85DDDB',
  pointBorderWidth: 2,
  order: 1,
};

export const statistics_chartdata_change_dataset = {
  label: 'Rate',
  data: [],
  fill: false,
  borderColor: '#85DDDB',
  //point style
  pointBorderColor: '#FFFFFF',
  pointBackgroundColor: '#85DDDB',
  pointBorderWidth: 2,
  order: 2,
};

export const statistics_chartdata_avg_dataset = {
  label: 'Avg Rate',
  data: [],
  fill: false,
  borderColor: CHART_BAR_COLOR.PINK,
  //point style
  pointBorderColor: '#FFFFFF',
  pointBackgroundColor: CHART_BAR_COLOR.PINK,
  pointBorderWidth: 2,
  order: 2,
};

export const beacon_chartdata_value_dataset = {
  label: 'Doc count',
  data: [],
  fill: false,
  pointRadius: 3,
  borderColor: CHART_COLORS.MINT, //'#85DDDB',
  //point style
  pointBorderColor: CHART_COLORS.WHITE, //'#FFFFFF',
  pointBackgroundColor: CHART_COLORS.MINT, //'#85DDDB',
  pointBorderWidth: 2,
  order: 2,
};

export const beacon_chartdata_change_dataset = {
  label: 'Doc count',
  data: [],
  fill: false,
  borderColor: CHART_COLORS.MINT, //'#85DDDB',
  //point style
  pointBorderColor: CHART_COLORS.WHITE, //'#FFFFFF',
  pointBackgroundColor: CHART_COLORS.MINT, //'#85DDDB',
  pointBorderWidth: 2,
  order: 2,
};

export const statistics_chartdata_bar_dataset = {
    label: 'bar',
    data: [],
    fill: false,
    backgroundColor: [CHART_BAR_COLOR.BLUE, CHART_BAR_COLOR.PINK],
};

export const dashboard_statistics_chartdata = {
  value_graph: {
    data: {
      labels: ['D-14', 'D-13', 'D-12', 'D-11', 'D-10', 'D-9', 'D-8', 'D-7', 'D-6', 'D-5', 'D-4', 'D-3', 'D-2', 'D-1', 'Today'],
      datasets: [ statistics_chartdata_value_dataset ],
    },
    options: {
      baseline: {
        display: true,
        value: 0,
        title: 'AVG',
      },
      baselineSubMax: {
        display: true,
        value: 0,
        title: 'Max',
      },
      baselineSubMin: {
        display: true,
        value: 0,
        title: 'Min',
      },
      legend: {
        display: false,
      },
      scales: {
        y: {
          min: -20,
          max: 25,
        },
      },
    },
  },

  changes_graph: {
    data: {
      labels: ['D-14', 'D-13', 'D-12', 'D-11', 'D-10', 'D-9', 'D-8', 'D-7', 'D-6', 'D-5', 'D-4', 'D-3', 'D-2', 'D-1', 'Today'],
      datasets: [ statistics_chartdata_change_dataset, statistics_chartdata_avg_dataset ],
    },
    options: {
      legend: {
        display: false,
      },
      scales: {
        y: {
          min: -20,
          max: 25,
        },
      },
    },
  },

  barchart_graph: {
    data: {
      labels: ['US'],
      datasets: [ statistics_chartdata_bar_dataset ],
    },
    options: {
      legend: {
        display: true,
      },
      scales: {
        y: {
          min: 0, //y축 min 값
          max: 100, //y축 max 값
        },
      },
      maxBarThickness: 40, //라인타입의 두께 조정
    },
  },
};

export const dashboard_statistics_beacon_chart = {
  value_graph: {
    data: {
      labels: ['D-14', 'D-13', 'D-12', 'D-11', 'D-10', 'D-9', 'D-8', 'D-7', 'D-6', 'D-5', 'D-4', 'D-3', 'D-2', 'D-1', 'Today'],
      datasets: [ beacon_chartdata_value_dataset ],
    },
    options: {
      baseline: {
        display: true,
        value: 0,
        title: 'AVG',
      },
      scales: {
        y: {
          min: 0,
          max: 25,
        },
      },
    },
  },
  changes_graph: {
    data: {
      labels: ['H-69','H-66','H-63','H-60','H-57','H-54','H-51','H-48','H-45','H-42',
               'H-39', 'H-36', 'H-33', 'H-30', 'H-27','H-24', 'H-21', 'H-18', 'H-15', 'H-12',
               'H-9', 'H-6', 'H-3', 'H-0'],
      datasets: [ beacon_chartdata_change_dataset ],
    },
    options: {
      baseline: {
        display: true,
        value: 0,
        title: 'AVG',
      },
      scales: {
        y: {
          min: 0,
          max: 25,
        },
      },
    },
  },
};
