export const SET_PROGRAMS = 'SET_PROGRAMS';
export const SET_PROGRAMS_FETCHING = 'SET_PROGRAMS_FETCHING';
export const SET_PROGRAMS_PARAM = 'SET_PROGRAMS_PARAM';
export const INIT_NEW_PROGRAM_RESULT = 'INIT_NEW_PROGRAM_RESULT';
export const SET_NEW_PROGRAM_FETCHING = 'SET_NEW_PROGRAM_FETCHING';
export const SET_NEW_PROGRAM_RESULT = 'SET_NEW_PROGRAM_RESULT';
export const SET_CHANNELS = 'SET_CHANNELS';
export const SET_CHANNELS_FETCHING = 'SET_CHANNELS_FETCHING';
// channel param
export const SET_CHANNEL_CHANNEL_PARAM = 'SET_CHANNEL_CHANNEL_PARAM';
// channel list
export const INIT_CHANNEL_CHANNEL = 'INIT_CHANNEL_CHANNEL';
export const SET_CHANNEL_CHANNEL = 'SET_CHANNEL_CHANNEL';
export const SET_CHANNEL_CHANNEL_FETCHING = 'SET_CHANNEL_CHANNEL_FETCHING';
export const SET_EDIT_CHANNEL_CHANNEL = 'SET_EDIT_CHANNEL_CHANNEL';
export const SET_REMOVE_CHANNEL_CHANNEL = 'SET_REMOVE_CHANNEL_CHANNEL';
// channel view
export const SET_CHANNEL_CHANNEL_VIEW = 'SET_CHANNEL_CHANNEL_VIEW';
export const SET_CHANNEL_CHANNEL_VIEW_FETCHING = 'SET_CHANNEL_CHANNEL_VIEW_FETCHING';
// channel edit
export const SET_CH0103_SUCCESS = 'SET_CH0103_SUCCESS';
export const SET_CH0103_FAILURE = 'SET_CH0103_FAILURE';
//
export const SET_CHANNEL_MAP_IDS_SUCCESS = 'SET_CHANNEL_MAP_IDS_SUCCESS';
export const SET_CHANNEL_MAP_IDS_FAILURE = 'SET_CHANNEL_MAP_IDS_FAILURE';
//
export const SET_CH0104_SUCCESS = 'SET_CH0104_SUCCESS';
export const SET_CH0104_FAILURE = 'SET_CH0104_FAILURE';
// channel conf param
export const SET_CHANNEL_CHANNEL_CONF_PARAM = 'SET_CHANNEL_CHANNEL_CONF_PARAM';
// channel conf list
export const SET_CH0303_SUCCESS = 'SET_CH0303_SUCCESS';
export const SET_CH0303_FAILURE = 'SET_CH0303_FAILURE';
export const SET_CH0304_SUCCESS = 'SET_CH0304_SUCCESS';
export const SET_CH0304_FAILURE = 'SET_CH0304_FAILURE';
export const SET_CH0305_SUCCESS = 'SET_CH0305_SUCCESS';
export const SET_CH0305_FAILURE = 'SET_CH0305_FAILURE';
export const SET_CH0306_SUCCESS = 'SET_CH0306_SUCCESS';
export const SET_CH0306_FAILURE = 'SET_CH0306_FAILURE';
export const SET_CH0307_SUCCESS = 'SET_CH0307_SUCCESS';
export const SET_CH0307_FAILURE = 'SET_CH0307_FAILURE';
//
export const SET_EDIT_CHANNEL_CHANNEL_CONF = 'SET_EDIT_CHANNEL_CHANNEL_CONF';
export const SET_REMOVE_CHANNEL_CHANNEL_CONF = 'SET_REMOVE_CHANNEL_CHANNEL_CONF';
// channel conf view
export const SET_CHANNEL_CHANNEL_CONF_VIEW = 'SET_CHANNEL_CHANNEL_CONF_VIEW';
export const SET_CHANNEL_CHANNEL_CONF_VIEW_FETCHING = 'SET_CHANNEL_CHANNEL_CONF_VIEW_FETCHING';
// channel feed param
export const SET_CHANNEL_CHANNEL_FEED_PARAM = 'SET_CHANNEL_CHANNEL_FEED_PARAM';
// channel feed list
export const SET_CH0201_SUCCESS = 'SET_CH0201_SUCCESS';
export const SET_CH0201_FAILURE = 'SET_CH0201_FAILURE';
export const SET_CH0202_SUCCESS = 'SET_CH0202_SUCCESS';
export const SET_CH0202_FAILURE = 'SET_CH0202_FAILURE';
export const SET_CH0203_SUCCESS = 'SET_CH0203_SUCCESS';
export const SET_CH0203_FAILURE = 'SET_CH0203_FAILURE';
export const SET_CH0204_SUCCESS = 'SET_CH0204_SUCCESS';
export const SET_CH0204_FAILURE = 'SET_CH0204_FAILURE';
export const SET_CH0205_SUCCESS = 'SET_CH0205_SUCCESS';
export const SET_CH0205_FAILURE = 'SET_CH0205_FAILURE';
export const SET_CH0206_SUCCESS = 'SET_CH0206_SUCCESS';
export const SET_CH0206_FAILURE = 'SET_CH0206_FAILURE';
// channel feed view
export const SET_CHANNEL_CHANNEL_FEED_VIEW = 'SET_CHANNEL_CHANNEL_FEED_VIEW';
export const SET_CHANNEL_CHANNEL_FEED_VIEW_FETCHING = 'SET_CHANNEL_CHANNEL_FEED_VIEW_FETCHING';
// Display Rules
// Display Rules
export const SET_SD0201_SUCCESS = 'SET_SD0201_SUCCESS'; // RSS Feeds 조회
export const SET_SD0201_FAILURE = 'SET_SD0201_FAILURE'; // RSS Feeds 조회
export const SET_SD0101_SUCCESS = 'SET_SD0101_SUCCESS'; // Feed Mapp 조회
export const SET_SD0101_FAILURE = 'SET_SD0101_FAILURE'; // Feed Mapp 조회
export const SET_SD0103_SUCCESS = 'SET_SD0103_SUCCESS'; // Feed Mapp Setting History Combo 조회
export const SET_SD0103_FAILURE = 'SET_SD0103_FAILURE'; // Feed Mapp Setting History Combo 조회
export const SET_SD0104_SUCCESS = 'SET_SD0104_SUCCESS'; // Feed Mapp Setting History 조회
export const SET_SD0104_FAILURE = 'SET_SD0104_FAILURE'; // Feed Mapp Setting History 조회
export const SET_SD0105_SUCCESS = 'SET_SD0105_SUCCESS'; // Feed Mapp Setting 저장
export const SET_SD0105_FAILURE = 'SET_SD0201_FAILURE'; // Feed Mapp Setting 저장
export const SET_SD0106_SUCCESS = 'SET_SD0106_SUCCESS'; // Feed Mapp Sdk 저장/수정
export const SET_SD0106_FAILURE = 'SET_SD0106_FAILURE'; // Feed Mapp Sdk 저장/수정
export const SET_SD0107_SUCCESS = 'SET_SD0107_SUCCESS'; // Feed Mapp Sdk 목록 조회 (하단 sdk version 리스트 )
export const SET_SD0107_FAILURE = 'SET_SD0107_FAILURE'; // Feed Mapp Sdk 목록 조회 (하단 sdk version 리스트 )
export const SET_SD0108_SUCCESS = 'SET_SD0108_SUCCESS'; // Preview 목록조회
export const SET_SD0108_FAILURE = 'SET_SD0108_FAILURE'; // Preview 목록조회
export const SET_SD0109_SUCCESS = 'SET_SD0109_SUCCESS'; // Feed Mapp Sdk 삭제
export const SET_SD0109_FAILURE = 'SET_SD0109_FAILURE'; // Feed Mapp Sdk 삭제
//
export const SET_RULES_STORE = 'SET_RULES_STORE'; //rules store
//
export const SET_DISPLAYRULES_MAINSTORE = 'SET_DISPLAYRULES_MAINSTORE'; // display Rules
//
export const SET_DISPLAY_RULES = 'SET_DISPLAY_RULES';
export const SET_DISPLAY_RULES_FETCHING = 'SET_DISPLAY_RULES_FETCHING';
//
export const SET_SDK_VERSION_SETTING = 'SET_SDK_VERSION_SETTING';
//
export const SET_EDIT_CHANNEL = 'SET_EDIT_CHANNEL';
export const SET_REVOKE_CHANNEL_NAME_LIST = 'SET_REVOKE_CHANNEL_NAME_LIST';
export const SET_REMOVE_CHANNEL = 'SET_REMOVE_CHANNEL';
export const SET_SCHEDULES = 'SET_SCHEDULES';
export const SET_SCHEDULES_FETCHING = 'SET_SCHEDULES_FETCHING';
export const SET_SCHEDULES_ERROR = 'SET_SCHEDULES_ERROR';
export const INIT_CURRENT_USER = 'INIT_CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_SIGNIN_RESULT = 'SET_SIGNIN_RESULT';
export const SET_SIGNUP_RESULT = 'SET_SIGNUP_RESULT';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const REHYDRATE = 'REHYDRATE';
export const INIT_SIGNUP_RESULT = 'INIT_SIGNUP_RESULT';
export const SET_SIGNIN_FETCHING = 'SET_SIGNIN_FETCHING';
export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN';
export const SET_API_KEY = 'SET_API_KEY';
export const SET_API_KEY_ID = 'SET_API_KEY_ID';
export const SET_METADATAFILES = 'SET_METADATAFILES';
export const SET_METADATAFILES_FETCHING = 'SET_METADATAFILES_FETCHING';
export const SET_PROVIDERS = 'SET_PROVIDERS';
export const SET_PROVIDERS_FETCHING = 'SET_PROVIDERS_FETCHING';
export const SET_WHITELISTIPS = 'SET_WHITELISTIPS';
export const SET_WHITELISTIPS_FETCHING = 'SET_WHITELISTIPS_FETCHING';
export const SET_ADMINS_FETCHING = 'SET_ADMINS_FETCHING';
export const SET_ADMINS = 'SET_ADMINS';
export const SET_ADMINS_ERROR = 'SET_ADMINS_ERROR';
export const SET_MEMBERS_FETCHING = 'SET_MEMBERS_FETCHING';
export const SET_MEMBERS = 'SET_MEMBERS';
export const SET_MEMBERS_ERROR = 'SET_MEMBERS_ERROR';
export const SET_CNTRY = 'SET_CNTRY';
export const SET_LOG = 'SET_LOG';
export const SET_LOG_FETCHING = 'SET_LOG_FETCHING';
export const SET_EDIT_MEDIA_STATIC_URL = 'SET_EDIT_MEDIA_STATIC_URL';
export const SET_EDIT_MEDIA_STATIC_URL_FAIL = 'SET_EDIT_MEDIA_STATIC_URL_FAIL';
// channel mapping feed
export const SET_API_FAIL = 'SET_API_FAIL';
export const SET_CHANNEL_MAPPING = 'SET_CHANNEL_MAPPING';
export const SET_FEEDS_HISTORY = 'SET_FEEDS_HISTORY';
export const SET_CHANNEL_MAPPING_FETCHING = 'SET_CHANNEL_MAPPING_FETCHING';
export const SET_CONF_MAP = 'SET_CONF_MAP';
export const SET_HIST_CONF_MAP = 'SET_HIST_CONF_MAP';
export const SET_CONFIG_LIST = 'SET_CONFIG_LIST';
export const SET_SAVE_FLAG = 'SET_SAVE_FLAG';
export const SET_SAVE_FAIL = 'SET_SAVE_FAIL';
export const SET_PRECHECK = 'SET_PRECHECK';
export const MERGE_CONFIG_LIST = 'MERGE_CONFIG_LIST';
export const SET_EXPORTS = 'SET_EXPORTS';
export const SET_ORIGINAL = 'SET_ORIGINAL';
export const SET_VIEW_DATA = 'SET_VIEW_DATA';
export const MERGE_SOURCE_LIST = 'MERGE_SOURCE_LIST';
export const SET_PUBLISH_RESULT = 'SET_PUBLISH_RESULT';
export const SET_CHANNELMAP_DATA = 'SET_CHANNELMAP_DATA';
// country
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_CNTRY_INFO = 'GET_CNTRY_INFO';
export const GET_HA_REGIONS = 'GET_HA_REGIONS';
// cplist
export const GET_CPLIST = 'GET_CPLIST';
// rss feed
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const SET_ALL_COUNTRY = 'SET_ALL_COUNTRY';
export const SET_DETAIL_COUNTRY = 'SET_DETAIL_COUNTRY';
export const SET_RSS_FEED = 'SET_RSS_FEED';
export const SET_RSS_FEED_DETAIL = 'SET_RSS_FEED_DETAIL';
export const SET_RSS_FEED_EDIT = 'SET_RSS_FEED_EDIT';
export const SET_WATCH_LIVE_CHANNELS = 'SET_WATCH_LIVE_CHANNELS'
export const SET_WATCH_LIVE_RESULT = 'SET_WATCH_LIVE_RESULT'
export const SET_WATCH_LIVE_CHANNELS_ACTUAL = 'SET_WATCH_LIVE_CHANNELS_ACTUAL'
//
export const SET_RSS_LOG = 'SET_RSS_LOG';
export const SET_LOG_COUNTRY = 'SET_LOG_COUNTRY';
// channel mapping source channel
export const SET_SOURCE_CHANNEL = 'SET_SOURCE_CHANNEL';
export const SET_SOURCE_CONF = 'SET_SOURCE_CONF';

// statistic
export const SET_STATISTIC = 'SET_STATISTIC';
export const SET_STATISTIC_INIT = 'SET_STATISTIC_INIT';
export const SET_STATISTIC_FETCHING = 'SET_STATISTIC_FETCHING';
export const SET_STATISTIC_GRAPH = 'SET_STATISTIC_GRAPH';
export const SET_STATISTIC_GRAPH_INIT = 'SET_STATISTIC_GRAPH_INIT';
export const SET_STATISTIC_GRAPH_FETCHING = 'SET_STATISTIC_GRAPH_FETCHING';
export const SET_STATISTIC_TABLE = 'SET_STATISTIC_TABLE';
export const SET_STATISTIC_TABLE_INIT = 'SET_STATISTIC_TABLE_INIT';
export const SET_STATISTIC_TABLE_FETCHING = 'SET_STATISTIC_TABLE_FETCHING';
export const SET_STATISTIC_ERROR = 'SET_STATISTIC_ERROR';
export const SET_STATISTIC_RESULT_SHOW = 'SET_STATISTIC_RESULT_SHOW';
export const SET_STATISTIC_RESULT_SHOW_HOLDING = 'SET_STATISTIC_RESULT_SHOW_HOLDING';

export const SET_REGION = 'SET_REGION';
export const SET_TAB_CATEGORY = 'SET_TAB_CATEGORY';
export const SET_CATEGORY = 'SET_CATEGORY';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
// DMA Code
export const SET_ZIP_DMA_CODE_RESULT = 'SET_ZIP_DMA_CODE_RESULT';
// DMA Group Code
export const SET_DMA_GROUP_CODE_RESULT = 'SET_DMA_GROUP_CODE_RESULT';
// AppVersion
export const SET_APPVERSION = 'SET_APPVERSION';
export const SET_APPVERSION_FETCHING = 'SET_APPVERSION_FETCHING';
// PushAlarm
export const SET_PUSH_ALARM = 'SET_PUSH_ALARM';
export const SET_PUSH_ALARM_FETCHING = 'SET_PUSH_ALARM_FETCHING';
// HomeApp Manual Result
export const SET_MANUAL_RESULT = 'SET_MANUAL_RESULT';
export const SET_MANUAL_RESULT_FETCHING = 'SET_MANUAL_RESULT_FETCHING';
export const SET_PUBLISH_CHANNEL_NAME_RESULT = 'SET_PUBLISH_CHANNEL_NAME_RESULT';
export const SET_CHANNELMAP_PUBLISH_DATA = 'SET_CHANNELMAP_PUBLISH_DATA';
export const SET_CHANNELMAP_PUBLISH_FAIL = 'SET_CHANNELMAP_PUBLISH_FAIL';
export const SET_REGION_PROVIDERS = 'SET_REGION_PROVIDERS';
export const SET_SLIDER_RESULT_FETCHING = 'SET_SLIDER_RESULT_FETCHING';
export const SET_SLIDER_RESULT = 'SET_SLIDER_RESULT';
export const SET_HOMEAPP_GENRE = 'SET_HOMEAPP_GENRE';
export const SET_CHANNEL_MAPPING_RESERVATION = 'SET_CHANNEL_MAPPING_RESERVATION';
export const SET_ALL_CHANNEL_MAPPING_RESERVATION = 'SET_ALL_CHANNEL_MAPPING_RESERVATION';
export const SET_CHANNEL_MAPPING_RESERVATION_BY_ID_TYPE = 'SET_CHANNEL_MAPPING_RESERVATION_BY_ID_TYPE';
export const SET_AB_TEST_TAB_CATEGORY = 'SET_AB_TEST_TAB_CATEGORY';
export const SET_REGION_SEGMENT = 'SET_REGION_SEGMENT';
export const SET_DAILY_DATA = 'SET_DAILY_DATA';
export const SET_CHANNEL_MAPPING_HIDDEN_DATA = 'SET_CHANNEL_MAPPING_HIDDEN_DATA';
export const GET_RS_CHANNEL_IDS = 'GET_RS_CHANNEL_IDS';
export const SET_2ND_DEPTHBYREGION_SUCCESS = 'SET_2ND_DEPTHBYREGION_SUCCESS';
export const SET_2ND_DEPTHBYREGION_FAILURE = 'SET_2ND_DEPTHBYREGION_FAILURE';
//FreeONLG Channel
export const SET_FREEONLG_SETTINGLIST = 'SET_FREEONLG_SETTINGLIST';
export const SET_FREEONLG_SETTINGLIST_FETCHING = 'SET_FREEONLG_SETTINGLIST_FETCHING';
