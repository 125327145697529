import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ABTestManualTable from './ABTestManualTable';
//import { getTabCategoryAction } from '../../../actions/homeAppActions';

function ABTestManualResult({ id, history, cntry, cat, result, setResult, renderOrder, selectResult, onClickAdd, onClickDelete, homeAppResult, isItEdit }) {
  React.useEffect(() => {
    setResult(isItEdit ? homeAppResult : []);
  }, []);

  return (
    <React.Fragment>
      <div className="arrow_btn_area">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Button className="btn_move piece" title="Click this to add content" onClick={onClickAdd} />
        <br />
        <Button className="btn_move remove" title="Click this to remove content" onClick={onClickDelete} />
      </div>
      <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list'} style={{ marginLeft: '6%' } /* margin-left: 6%;+'tbl_no_data'*/}>
        <span id="popup"></span>
        <ABTestManualTable
          isResult
          data={result}
          renderOrder={renderOrder}
          select={selectResult}
          options={{
            selection: true,
            showTextRowsSelected: false,
            //isLoading: true,
            //search: false,
            paging: true,
            pageSize: 15,
            pageSizeOptions: [5, 15, 30, 50, 100],
            doubleHorizontalScroll: true,
            maxBodyHeight: 500,
            minBodyHeight: 500,
            //showEmptyDataSourceMessage: false,
            rowStyle: rowData => ({
              backgroundColor: rowData.change ? '#fef5f8' : '#FFF',
              color: rowData.change ? '#d8295c' : '#484848',
            }),
          }}
        />
      </div>
    </React.Fragment>
  );
}
ABTestManualResult.propTypes = {};
const mapStateToProps = state => ({
  homeAppResult: state.homeApp.manualResultList,
  regionList: state.homeApp.regionList,
  tabCategoryInfo: state.homeApp.tabCategoryInfo,
});
const mapDispatchToProps = dispatch => ({
  // getTabCategoryAction(region, status) {
  //   dispatch(getTabCategoryAction(region, status));
  // },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ABTestManualResult));
