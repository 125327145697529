import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function feedlist(state = initialState, action) {
  switch (action.type) {
    case actions.SET_RSS_FEED:
      return action.payload.content;
    case actions.SET_API_FAIL:
      return state;
    default:
      return state;
  }
}
