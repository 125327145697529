import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { request } from '../../../util/request';
import { getRegionProvidersAdminAction } from '../../../actions/providerActions';
import { getHeaders } from '../../../util/actionUtil';
import { useState, useEffect } from 'react';
import { API_URL } from '../../../constants';
import FreeOnLgChannelTable from './FreeOnLgChannelTable';
import LoadingIndicator from '../../../common/LoadingIndicator';

export default function FreeOnLgChannelSearch({ cat, cntry, selectSearch, status, categoryResultData, tableRef, region, isLastMonth }) {
  const [cp, setCp] = React.useState('All');
  const typeList = (cat.contentType || '').split(',');
  const [type, setType] = React.useState('All');
  const [id, setId] = React.useState('');
  const [name, setName] = React.useState('');
  const [data, setData] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);

  const clickSearch = async event => {
    event.preventDefault();
    setDataLoaded(true)
    const result = await request(`/api/admin/freeOnLgChannelSearch?cntry=${cntry}&type=${type}&cp=${cp}&id=${id}&name=${name}&status=${status}`, 'GET', null, true);
    setData(
      result.map(n => {
        return { ...n };
      }),
    );
    setDataLoaded(false)
    if (!result.length > 0) {
      alert('There are no search results to display!')
    }
  };

  React.useEffect(() => {
    setData([]);
  }, [cntry]);

  return (
    <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list'} id={'searchfolgc'} style={{ marginTop: '40px' }}>
      <CpFilter2 cntry={cntry} typeList={typeList} type={type} setType={setType} cp={cp} setCp={setCp} id={id} setId={setId} name={name} setName={setName} region={region} />
      <div className="btn_set_wrap search_wrap manual" style={{ marginLeft: '95%' }}>
        <Button className="btn_color2" onClick={clickSearch} disabled={isLastMonth}>
          search
        </Button>
      </div>
      <FreeOnLgChannelTable
        data={data}
        tableRef={tableRef}
        select={selectSearch}
        dlagDropFlag={false}
        options={{
          selection: true,
          showTextRowsSelected: false,
          pageSize: 15,
          pageSizeOptions: [5, 15, 30, 50, 100],
          doubleHorizontalScroll: true,
          maxBodyHeight: 500,
          minBodyHeight: 500,
          rowStyle: rowData => ({
            backgroundColor: (categoryResultData || []).find(x => x.id === rowData.id && x.cp === rowData.cp) ? '#e0e0e0' : rowData.eligible_to_transfer && rowData.eligible_to_transfer === 'true' ? rowData.tableData.checked ? '#fef5f8' : '#FFF' : rowData.presence === 'Vertical,Horizontal' ? '#7FB3D5' : '#FF6961',
            color: rowData.tableData.checked ? '#d8295c' : '#484848',
            pointerEvents: !rowData.tableData.checked && (categoryResultData || []).find(x => x.id === rowData.id && x.cp === rowData.cp) ? 'none' : 'initial',
          }),
          selectionProps: rowData => {
            const findCurrentRow = (categoryResultData || []).find(x => x.id === rowData.id && x.cp === rowData.cp);
            rowData.tableData.disabled = findCurrentRow || rowData.eligible_to_transfer === 'false' ? true : false;
            if (rowData.tableData.disabled) {
              return {
                disabled: true,
                checked: false,
              };

            }

          },
        }}
      />
      {dataLoaded && (
        <div className="loading_wrap" style={{ position: 'absolute', top: 200, left: 0, right: 0, zIndex: 999 }}>
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
}
FreeOnLgChannelSearch.propTypes = {};

function CpFilter({ cplist, cntry, typeList, type, setType, cp, setCp, id, setId, name, setName, regionProvider, getRegionProvidersAdminAction, region }) {
  const [cpDetail, setCPDetails] = React.useState('All');
  React.useEffect(() => {
    getRegionProvidersAdminAction(region);
  }, []);
  useEffect(() => {
    if (cntry) {
      const apiUrl = `/api/admin/providers`;
      fetch(`${API_URL}${apiUrl}`, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then(res => res.json())
        .then(data => {
          const result = (data.list || []).map(data => data.providerId);
          setCPDetails(result);
        });
    }
  }, [cntry]);
  const AllCountry = ['All'];
  const cpNameList = [...cpDetail];
  return (
    <div className="filter_area row manualTop">
      <div className="filter_row manualTop">
        <FormControl variant="outlined">
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            onChange={e => {
              setType(e.target.value);
            }}>
            {['All', 'tvshow', 'movie', 'tvshow-series'].map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>CP</InputLabel>
          <Select
            value={cp}
            onChange={e => {
              setCp(e.target.value);
            }}>
            {[...AllCountry, ...cpNameList.sort()].map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="id"
          value={id}
          onChange={e => {
            setId(e.target.value);
          }}
        />
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          label="name"
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
        />
      </div>
    </div>
  );
}

CpFilter.propTypes = {
  regionProvider: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  regionProvider: state.providers.regionProvider,
});
const mapDispatchToProps = dispatch => ({
  getRegionProvidersAdminAction(region) {
    dispatch(getRegionProvidersAdminAction(region));
  },
});

const CpFilter2 = connect(mapStateToProps, mapDispatchToProps)(CpFilter);
