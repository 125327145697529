//import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function logs(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOG':
      //console.log('[SET_LOG][action]', action);
      if (action.payload.status) {
        return state; //TODO
      } else if (action.payload) {
        return action.payload;
      } else {
        return state;
      }
    // case 'SET_LOG_ERROR':
    //   return state;
    default:
      return state;
  }
}
