import * as actions from '../../actions/actionTypes';
//
const initialState = [];
//
export default function ch0303(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CH0303_SUCCESS:
      if (action.payload.list) {
        return action.payload.list;
      } else {
        return state;
      }
    case actions.SET_CH0303_FAILURE:
      return state;
    default:
      return state;
  }
}
