const initialState = [];
export const tabCategoryList = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TAB_CATEGORY_LIST':
      return action.payload;
    default:
      return state;
  }
};

const csListInitialState = [];
export const categoryScheduleList = (state = csListInitialState, action) => {
  switch (action.type) {
    case 'SET_CATEGORY_SCHEDULE_LIST':
      return action.payload;
    default:
      return state;
  }
};
