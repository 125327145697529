import * as actions from '../../actions/actionTypes';

const initialState = {
  fileName: '',
  channellist: [],
};

export default function sourceChannel(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CHANNEL_MAPPING:
      return initialState;
    case actions.SET_SOURCE_CHANNEL:
      return action.payload;
    case actions.MERGE_SOURCE_LIST:
      return {
        ...state,
        channellist: [...action.payload],
      };
    default:
      return state;
  }
}
