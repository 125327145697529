import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

function EditReasonValidityDailogFilter({
  // validityData,
  validity,
  handleChangeValidity,
  // reasonData,
  reason,
  handleChangeReason
}) {
  const validityTypes = [
    { value: 'valid', label: 'valid' },
    { value: 'invalid', label: 'invalid' },
  ];
  return (
    <Fragment>
      <div className={'tbl_wrap'}>
        <table className={'tbl_row_typ1'}>
          <colgroup>
            <col style={{ width: '150px' }} />
            <col style={{ width: '300px' }} />
          </colgroup>
          <colgroup>
            <col style={{ width: '150px' }} />
            <col style={{ width: '300px' }} />
          </colgroup>
          <tbody>
            <tr>
              <React.Fragment>
                <th>
                  <InputLabel>Validity</InputLabel>
                </th>
                <td>
                  <Select  variant="outlined" value={validity} onChange={handleChangeValidity} className="adtest_add">
                    {validityTypes &&
                      validityTypes.map(n => (
                        <MenuItem key={n.value} value={n.value}>
                          {n.value}
                        </MenuItem>
                      ))}
                  </Select>
                </td>
              </React.Fragment>
              <th>
                <InputLabel> Reason</InputLabel>
              </th>
              <td>
                <TextField id="reason" name="reason" placeholder="Reason" variant="outlined" className=" adtest_add" value={reason} onChange={handleChangeReason} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}
EditReasonValidityDailogFilter.propTypes = {

};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(EditReasonValidityDailogFilter);
