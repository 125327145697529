import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles'; // makeStyles
import { connect } from 'react-redux';
import { setAllCountry } from '../../actions/rssLogFilterActions';
import { InputLabel, TextField, FormControl, Select, Button, MenuItem } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
export function RssLogFilter({ allcountry, setAllCountry, getRssLog, handleCntry, handleCpName, handleResultCode, handlePath, country, cp, path, resultCode }) {
  useEffect(() => {
    setAllCountry();
  }, [setAllCountry]);
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className="filter_area">
        {/* Country */}
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="Country">Country</InputLabel>
          <Select labelId="Country" id="Country" value={country.selectValue} onChange={e => handleCntry({ selectKey: e.target.value, selectValue: e.target.value })} label="Country">
            {allcountry.map(v => {
              return (
                <MenuItem key={v.selectKey} name={v.selectKey} value={v.selectValue}>
                  {v.selectValue}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField label="CP Name" variant="outlined" value={cp} onChange={e => handleCpName(e.target.value)} />
        <TextField label="File Name" variant="outlined" value={path} onChange={e => handlePath(e.target.value)} />
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="Type">Result</InputLabel>
          <Select labelId="Result" id="Result" value={resultCode} onChange={e => handleResultCode(e.target.value)} label="Result">
            <MenuItem value="ALL">ALL</MenuItem>
            <MenuItem value="SUCCESS">SUCCESS</MenuItem>
            <MenuItem value="FAILED">FAILED</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="btn_set_wrap search_wrap">
        <Button variant="contained" className={'btn_color2'} onClick={getRssLog}>
          {' '}
          Search{' '}
        </Button>
      </div>
    </React.Fragment>
  );
}

RssLogFilter.propTypes = {
  allcountry: PropTypes.array,
  setAllCountry: PropTypes.func,
  country: PropTypes.object,
  getRssLog: PropTypes.func,
  handleCntry: PropTypes.func,
  handleCpName: PropTypes.func,
  handleResultCode: PropTypes.func,
  handleType: PropTypes.func,
  handlePath: PropTypes.func,
  cp: PropTypes.string,
  type: PropTypes.string,
  path: PropTypes.string,
  resultCode: PropTypes.string,
  // getRssFeedList: PropTypes.func
};
const mapStateToProps = state => ({
  allcountry: state.rssLog.countries,
});
const mapDispatchToProps = dispatch => ({
  setAllCountry: () => dispatch(setAllCountry()),
});
export default connect(mapStateToProps, mapDispatchToProps)(RssLogFilter);
