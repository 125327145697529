import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function channelMapIds(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CHANNEL_MAP_IDS_SUCCESS:
      if (action.payload) return action.payload;
      else return state;

    case actions.SET_CHANNEL_MAP_IDS_FAILURE:
      return state;
    default:
      return state;
  }
}
