import {CHART_COLORS} from "../../common/ChartConsts";
import {ROWHEADER_TYPE, TABLE_TYPE} from "../../consts/IssConsts";

export const dailyStatisticsErrorOccurrenceTotal = {
  data: {
    labels: ['1일', '2일', '3일', '4일', '5일', '6일', '7일', '8일', '9일', '10일', '11일', '12일', '13일', '14일', '15일', '16일', '17일', '18일', '19일', '20일', '21일', '22일', '23일', '24일', '25일', '26일', '28일', '29일', '30일'],
    datasets: [
      {
        label: 'Statistics',
        data: [],
        fill: false,
        pointRadius: 3,
        backgroundColor: `${CHART_COLORS.MINT}`, //bar chart color
        borderColor: `${CHART_COLORS.MINT}`, //line chart color
        //point style
        pointBorderColor: '#FFFFFF',
        pointBackgroundColor: '#85DDDB',
        pointBorderWidth: 2,
        order: 2,
      },
      {
        label: 'Beacon',
        data: [],
        fill: false,
        pointRadius: 3,
        backgroundColor: `${CHART_COLORS.PINK}`,
        borderColor: `${CHART_COLORS.PINK}`,
        //point style
        pointBorderColor: '#FFFFFF',
        pointBackgroundColor: `${CHART_COLORS.PINK}`,
        pointBorderWidth: 2,
        order: 2,
      },
    ],
  },
  options: {
    scales: {
      y: {
        min: 0,
        max: 300,
        ticks: {
          stepSize: 100,
        },
      },
    },
  },
};

export const dailyStatisticsErrorOccurrence = {
  data: {
    labels: ['1일', '2일', '3일', '4일', '5일', '6일', '7일', '8일', '9일', '10일', '11일', '12일', '13일', '14일', '15일', '16일', '17일', '18일', '19일', '20일', '21일', '22일', '23일', '24일', '25일', '26일', '28일', '29일', '30일'],
    datasets: [
      {
        label: 'Issue Count',
        data: [],
        fill: false,
        pointRadius: 3,
        backgroundColor: `${CHART_COLORS.MINT}`, //bar chart color
        borderColor: `${CHART_COLORS.MINT}`, //line chart color
        //point style
        pointBorderColor: '#FFFFFF',
        pointBackgroundColor: '#85DDDB',
        pointBorderWidth: 2,
        order: 2,
      },
    ],
  },
  options: {
    scales: {
      y: {
        min: 0,
        max: 300,
        ticks: {
          stepSize: 100,
        },
      },
    },
  },
};

export const dailyBeaconErrorOccurrence = {
  data: {
    labels: ['1일', '2일', '3일', '4일', '5일', '6일', '7일', '8일', '9일', '10일', '11일', '12일', '13일', '14일', '15일', '16일', '17일', '18일', '19일', '20일', '21일', '22일', '23일', '24일', '25일', '26일', '28일', '29일', '30일'],
    datasets: [
      {
        label: 'Issue Count',
        data: [],
        fill: false,
        pointRadius: 3,
        backgroundColor: `${CHART_COLORS.PINK}`, //bar chart color
        borderColor: `${CHART_COLORS.PINK}`, //line chart color
        //point style
        pointBorderColor: '#FFFFFF',
        pointBackgroundColor: `${CHART_COLORS.PINK}`,
        pointBorderWidth: 2,
        order: 2,
      },
    ],
  },
  options: {
    scales: {
      y: {
        min: 0,
        max: 300,
        ticks: {
          stepSize: 100,
        },
      },
    },
  },
};

export const dailyStatisticsErrorAccumulated = {
  data: {
    labels: ['1일', '2일', '3일', '4일', '5일', '6일', '7일', '8일', '9일', '10일', '11일', '12일', '13일', '14일', '15일', '16일', '17일', '18일', '19일', '20일', '21일', '22일', '23일', '24일', '25일', '26일', '28일', '29일', '30일'],
      datasets: [
      {
        label: 'Rate',
        data: [],
        fill: false,
        pointRadius: 3,
        backgroundColor: `${CHART_COLORS.MINT}`, //bar chart color
        borderColor: `${CHART_COLORS.MINT}`, //line chart color
        //point style
        pointBorderColor: '#FFFFFF',
        pointBackgroundColor: '#85DDDB',
        pointBorderWidth: 2,
        order: 2,
      },
    ],
  },
  options: {
    scales: {
      y: {
        min: 0,
          max: 100,
          ticks: {
            stepSize: 50,
            callback: (value) => `${value}%`,
          },
      },
    },
  },
};

export const dailyBeaconErrorAccumulated = {
  data: {
    labels: ['1일', '2일', '3일', '4일', '5일', '6일', '7일', '8일', '9일', '10일', '11일', '12일', '13일', '14일', '15일', '16일', '17일', '18일', '19일', '20일', '21일', '22일', '23일', '24일', '25일', '26일', '28일', '29일', '30일'],
    datasets: [
      {
        label: 'Rate',
        data: [],
        fill: false,
        pointRadius: 3,
        backgroundColor: `${CHART_COLORS.PINK}`, //bar chart color
        borderColor: `${CHART_COLORS.PINK}`, //line chart color
        //point style
        pointBorderColor: '#FFFFFF',
        pointBackgroundColor: `${CHART_COLORS.PINK}`,
        pointBorderWidth: 2,
        order: 2,
      },
    ],
  },
  options: {
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 50,
          callback: (value) => `${value}%`,
        },
      },
    },
  },
};

export const dailyStatisticsErrorAccumulatedTotal = {
  data: {
    labels: ['1일', '2일', '3일', '4일', '5일', '6일', '7일', '8일', '9일', '10일', '11일', '12일', '13일', '14일', '15일', '16일', '17일', '18일', '19일', '20일', '21일', '22일', '23일', '24일', '25일', '26일', '28일', '29일', '30일'],
    datasets: [
      {
        label: 'Statistics',
        data: [],
        fill: false,
        pointRadius: 3,
        backgroundColor: `${CHART_COLORS.MINT}`, //bar chart color
        borderColor: `${CHART_COLORS.MINT}`, //line chart color
        //point style
        pointBorderColor: '#FFFFFF',
        pointBackgroundColor: '#85DDDB',
        pointBorderWidth: 2,
        order: 2,
      },
      {
        label: 'Beacon',
        data: [],
        fill: false,
        pointRadius: 3,
        backgroundColor: `${CHART_COLORS.PINK}`, //bar chart color
        borderColor: `${CHART_COLORS.PINK}`, //line chart color
        //point style
        pointBorderColor: '#FFFFFF',
        pointBackgroundColor: `${CHART_COLORS.PINK}`,
        pointBorderWidth: 2,
        order: 2,
      },
    ],
  },
  options: {
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 50,
          callback: (value) => `${value}%`,
        },
      },
    },
  },
};

export const dailyStatisticsErrorStatus = {
  data: {
    labels: ['1일', '2일', '3일', '4일', '5일', '6일', '7일', '8일', '9일', '10일', '11일', '12일', '13일', '14일', '15일', '16일', '17일', '18일', '19일', '20일', '21일', '22일', '23일', '24일', '25일', '26일', '28일', '29일', '30일'],
    datasets: [
      {
        label: 'Rate',
        data: [],
        fill: false,
        pointRadius: 3,
        backgroundColor: `${CHART_COLORS.MINT}`, //bar chart color
        borderColor: `${CHART_COLORS.MINT}`, //line chart color
        //point style
        pointBorderColor: '#FFFFFF',
        pointBackgroundColor: '#85DDDB',
        pointBorderWidth: 2,
        order: 2,
      },
    ],
  },
  options: {
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 50,
          callback: (value) => `${value}%`,
        },
      },
    },
  },
};

export const dailyBeaconErrorStatus = {
  data: {
    labels: ['1일', '2일', '3일', '4일', '5일', '6일', '7일', '8일', '9일', '10일', '11일', '12일', '13일', '14일', '15일', '16일', '17일', '18일', '19일', '20일', '21일', '22일', '23일', '24일', '25일', '26일', '28일', '29일', '30일'],
    datasets: [
      {
        label: 'Rate',
        data: [],
        fill: false,
        pointRadius: 3,
        backgroundColor: `${CHART_COLORS.PINK}`, //bar chart color
        borderColor: `${CHART_COLORS.PINK}`, //line chart color
        //point style
        pointBorderColor: '#FFFFFF',
        pointBackgroundColor: `${CHART_COLORS.PINK}`,
        pointBorderWidth: 2,
        order: 2,
      },
    ],
  },
  options: {
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 50,
          callback: (value) => `${value}%`,
        },
      },
    },
  },
};

export const dailyStatisticsErrorStatusTotal = {
  data: {
    labels: ['1일', '2일', '3일', '4일', '5일', '6일', '7일', '8일', '9일', '10일', '11일', '12일', '13일', '14일', '15일', '16일', '17일', '18일', '19일', '20일', '21일', '22일', '23일', '24일', '25일', '26일', '28일', '29일', '30일'],
    datasets: [
      {
        label: 'Statistics',
        data: [],
        fill: false,
        pointRadius: 3,
        backgroundColor: `${CHART_COLORS.MINT}`, //bar chart color
        borderColor: `${CHART_COLORS.MINT}`, //line chart color
        //point style
        pointBorderColor: '#FFFFFF',
        pointBackgroundColor: '#85DDDB',
        pointBorderWidth: 2,
        order: 2,
      },
      {
        label: 'Beacon',
        data: [],
        fill: false,
        pointRadius: 3,
        backgroundColor: `${CHART_COLORS.PINK}`, //bar chart color
        borderColor: `${CHART_COLORS.PINK}`, //line chart color
        //point style
        pointBorderColor: '#FFFFFF',
        pointBackgroundColor: `${CHART_COLORS.PINK}`,
        pointBorderWidth: 2,
        order: 2,
      },
    ],
  },
  options: {
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 50,
          callback: (value) => `${value}%`,
        },
      },
    },
  },
};

export const statisticsIssueHistory = {
  tableType: TABLE_TYPE.NORMAL,
  title: 'Statistics Issue History',
  visibleTitle: true,
  existTooltip: false,
  tooltip: 'Statistics Issues tooltip message..!!',
  header: ['Issue ID', 'P', 'Country', 'CP', 'Type',
    'Content Name', 'Status', 'Assign','Detection Item', 'Value (AVG)',
    'Rate', 'Cause', 'Action Details', 'Date', 'Change Date', 'Jira URL'],
  cellStyle: [
    ROWHEADER_TYPE.NORMAL,
    ROWHEADER_TYPE.NORMAL,
    ROWHEADER_TYPE.NORMAL,
    ROWHEADER_TYPE.NORMAL,
    ROWHEADER_TYPE.NORMAL,
    ROWHEADER_TYPE.LONG,
    ROWHEADER_TYPE.NORMAL,
    ROWHEADER_TYPE.NORMAL,
    ROWHEADER_TYPE.NORMAL,
    ROWHEADER_TYPE.NORMAL,
    ROWHEADER_TYPE.NORMAL,
    ROWHEADER_TYPE.LONG,
    ROWHEADER_TYPE.LONG,
    ROWHEADER_TYPE.NORMAL,
    ROWHEADER_TYPE.NORMAL,
    ROWHEADER_TYPE.JIRALINK,
  ],
  cellWidth: ['6%', '4%', '4%', '6%', '5%',
    '7%', '7%', '4%', '8%', '5%',
    '5%', '5%', '7%', '7%', '6%', '10%'],
  rowdata: [],
};

export const beaconIssueHistory = {
  tableType: TABLE_TYPE.NORMAL,
  title: 'Beacon Issue History',
  visibleTitle: true,
  existTooltip: false,
  tooltip: 'Statistics Issues tooltip message..!!',
  header: ['Issue ID', 'Issue Code', 'Group', 'Data Source', 'Detection Item', 'Status', 'Assign', 'Cause', 'Action Details', 'Date', 'Change Date', 'Jira URL'],
  cellStyle: [
  ROWHEADER_TYPE.NORMAL,
  ROWHEADER_TYPE.NORMAL,
  ROWHEADER_TYPE.NORMAL,
  ROWHEADER_TYPE.NORMAL,
  ROWHEADER_TYPE.NORMAL,
  ROWHEADER_TYPE.NORMAL,
  ROWHEADER_TYPE.NORMAL,
  ROWHEADER_TYPE.LONG,
  ROWHEADER_TYPE.LONG,
  ROWHEADER_TYPE.NORMAL,
  ROWHEADER_TYPE.NORMAL,
  ROWHEADER_TYPE.JIRALINK
],
  cellWidth: ['5%', '5%', '3%', '5%', '7%', '4%', '5%', '6%', '7%', '5%', '7%', '10%'],
  rowdata: [],
};
