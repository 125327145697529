import {
  INIT_NEW_PROGRAM_RESULT,
  SET_PROGRAMS,
  SET_PROGRAMS_FETCHING,
  SET_PROGRAMS_PARAM,
  //  SET_NEW_PROGRAM_FETCHING,
  //  SET_NEW_PROGRAM_RESULT,
} from './actionTypes';
import { API_BASE_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';

export const initProgramsAction = () => ({
  type: SET_PROGRAMS,
  payload: {
    program: [],
  },
});

export const initNewProgramAction = () => ({
  type: INIT_NEW_PROGRAM_RESULT,
  payload: {},
});

const setProgramFetchingAction = value => ({
  type: SET_PROGRAMS_FETCHING,
  payload: value,
});

const fetchGetUserCreatedProgramsSuccess = body => ({
  type: SET_PROGRAMS,
  payload: body,
});

const fetchGetUserCreatedProgramsFailure = ex => ({
  type: SET_PROGRAMS,
  payload: ex,
});

export const getUserCreatedProgramsAction = (isRoleAdmin, cntry, cp, channel, title, programId, keyword) => dispatch => {
  // page = page || 0;
  // size = size || PROGRAM_LIST_SIZE;
  let apiUrl = '/programs?countryCode=' + cntry + '&channelName=' + channel + '&programTitle=' + title + '&programId=' + programId + '&keyword=' + keyword;

  if (isRoleAdmin) {
    apiUrl = API_BASE_URL + '/admin' + apiUrl;
    if (cp && cp !== 'all') {
      apiUrl += '&providerId=' + cp;
    }
  } else {
    apiUrl = API_BASE_URL + apiUrl;
  }
  //console.log('[getUserCreatedProgramsAction][title]', title);
  dispatch(setProgramFetchingAction(true));
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch(fetchGetUserCreatedProgramsSuccess(body)))
    .catch(ex => dispatch(fetchGetUserCreatedProgramsFailure(ex)));
};

export const setProgramsParamAction = param => {
  //console.log('[setProgramsParamAction][param]', param);
  return {
    type: SET_PROGRAMS_PARAM,
    payload: param,
  };
};

// const setNewProgramFetchingAction = value => ({
//   type: SET_NEW_PROGRAM_FETCHING,
//   payload: value,
// });

// const fetchCreateProgramSuccess = body => ({
//   type: SET_NEW_PROGRAM_RESULT,
//   payload: body,
// });

// const fetchCreateProgramFailure = ex => ({
//   type: SET_NEW_PROGRAM_RESULT,
//   payload: ex,
// });

// export const createProgramAction = programData => dispatch => {
//   const apiUrl = API_BASE_URL + '/programs';

//   dispatch(setNewProgramFetchingAction(true));
//   return fetch(apiUrl, {
//     method: 'POST',
//     headers: getHeaders(),
//     body: JSON.stringify(programData),
//   })
//     .then(res => res.json())
//     .then(body => dispatch(fetchCreateProgramSuccess(body)))
//     .catch(ex => dispatch(fetchCreateProgramFailure(ex)));
// };
