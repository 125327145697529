import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import PropTypes from 'prop-types';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { calImprovedRate } from './AnServiceCommon';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export function ANServiceContentDuration({ dailyData, defaultContent }) {
  const [days, SetDays] = React.useState(['(SUN)', '(MON)', '(TUE)', '(WED)', '(THU)', '(FRI)', '(SAT)']);


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Average Duration (in mins)',
        font: {
          size: 24,
          style: 'italic',
          family: 'Helvetica Neue',
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x'
        },
        zoom: {
          pinch: {
            enabled: true
          },
          wheel: {
            enabled: false
          },
          mode: 'x',
        }
      }
    },
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
      y:
      {
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return `${value}`;
          }
        }
      }
    }
  };

  const labels = dailyData.map(dailyData => dailyData.DT.substr(5, 9) + ' ' + days[new Date(dailyData.DT).getDay()]);

  const dataSetAB = [
    {
      label: 'A',
      data: dailyData.map(dailyData => dailyData.avg_duration_A),
      backgroundColor: '#ffdab9',
    },
    {
      label: 'B',
      data: dailyData.map(dailyData => dailyData.avg_duration_B),
      backgroundColor: '#ffb6c1',
    }
  ];

  const dataSetABC = [
    {
      label: 'A',
      data: dailyData.map(dailyData => dailyData.avg_duration_A),
      backgroundColor: '#ffdab9',
    },
    {
      label: 'B',
      data: dailyData.map(dailyData => dailyData.avg_duration_B),
      backgroundColor: '#ffb6c1',
    },
    {
      label: 'C',
      data: dailyData.map(dailyData => dailyData.avg_duration_C),
      backgroundColor: '#CFCFC4',
    }
  ];

  const data = {
    labels,
    datasets: defaultContent === 'N' ? dataSetAB : dataSetABC,
  };
  return <div style={{ height: '500px' }}><Bar options={options} data={data} /></div>;
}

ANServiceContentDuration.propTypes = {
  dailyData: PropTypes.array.isRequired,
};

export default ANServiceContentDuration;
