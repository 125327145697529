//http://collab.lge.com/main/display/RICH/LG+Channels+Metadata+Specification+v0.94#LGChannelsMetadataSpecificationv0.94-A.GenreCode
export const channelGenre = [
  { code: 0, name: 'all' },
  { code: 2, name: 'drama' },
  { code: 17, name: 'comedy' },
  { code: 18, name: 'music' },
  { code: 23, name: 'sport' },
  { code: 41, name: 'movies' },
  { code: 45, name: 'kids' },
  { code: 49, name: 'realityTv' },
  { code: 57, name: 'hobbyLeisure' },
  { code: 59, name: 'news' },
  { code: 62, name: 'talkShow&Entertainment' },
  { code: 998, name: 'adult' },
  { code: 999, name: 'etc' },
];

export const krChannelGenre = [
  { code: 0, name: 'All' },
  { code: 100, name: 'Movies' },
  { code: 110, name: 'Drama' },
  { code: 120, name: 'Sport/Interest' },
  { code: 130, name: 'News/Investments' },
  { code: 140, name: 'Kids/Cartoon' },
  { code: 150, name: 'Education' },
  { code: 160, name: 'Documentary/Information' },
  { code: 170, name: 'Women/Home Shopping' },
  { code: 180, name: 'Music/Entertainment' },
  { code: 190, name: 'Overseas Satellite' },
  { code: 200, name: 'Broadcasting' },
  { code: 210, name: 'Local Channel' },
  { code: 230, name: 'Comprehensive Programming' },
  { code: 998, name: 'Adult' },
  { code: 999, name: 'ETC' },
];

export const programGenreLevel1 = [
  { code: 1, name: 'ETC' },
  { code: 2, name: 'Drama' },
  { code: 5, name: 'Topics/Documentary' },
  { code: 18, name: 'Music' },
  { code: 23, name: 'Sport' },
  { code: 29, name: 'Adult' },
  { code: 41, name: 'Film' },
  { code: 45, name: 'Kids' },
  { code: 56, name: 'Recreation Entertainment' },
  { code: 57, name: 'Hobby/Leisure' },
  { code: 58, name: 'Education' },
  { code: 59, name: 'News' },
  { code: 60, name: 'Culture/Information' },
  { code: 61, name: 'Shopping' },
];

export const programGenre = [
  /*0*/ '',
  /*1*/ 'ETC',
  /*2*/ 'Drama',
  /*3*/ 'Romance',
  /*4*/ 'SF',
  /*5*/ 'Topics/Documentary',
  /*6*/ 'Action',
  /*7*/ 'Animation',
  /*8*/ 'Fantasy',
  /*9*/ 'Adventure',
  /*10*/ 'Mystery',
  /*11*/ 'Western',
  /*12*/ 'Musical',
  /*13*/ 'Noir',
  /*14*/ 'Crime',
  /*15*/ 'Martial Arts',
  /*16*/ 'War',
  /*17*/ 'Comedy',
  /*18*/ 'Music',
  /*19*/ 'Biography',
  /*20*/ 'Omnibus',
  /*21*/ 'Short Film',
  /*22*/ 'Family',
  /*23*/ 'Sport',
  /*24*/ 'Experimental Film',
  /*25*/ '',
  /*26*/ '3D',
  /*27*/ 'Silent film',
  /*28*/ 'Black and white films',
  /*29*/ 'Adult',
  /*30*/ 'Blockbuster',
  /*31*/ 'Queer',
  /*32*/ 'History and Narrative',
  /*33*/ 'Period drama',
  /*34*/ 'Thriller',
  /*35*/ 'Detective',
  /*36*/ 'Sitcom',
  /*37*/ 'Spy',
  /*38*/ 'Schooldays',
  /*39*/ 'Horror',
  /*40*/ 'Natural disasters',
  /*41*/ 'Film',
  /*42*/ '',
  /*43*/ 'Independent Film',
  /*44*/ 'International',
  /*45*/ 'Kids',
  /*46*/ 'Lifestyle',
  /*47*/ '',
  /*48*/ '',
  /*49*/ 'Reality TV',
  /*50*/ '',
  /*51*/ '',
  /*52*/ 'Romantic Comedy',
  /*53*/ 'Black Comedy',
  /*54*/ 'Erotic',
  /*55*/ '',
  /*56*/ 'Recreation Entertainment',
  /*57*/ 'Hobby/Leisure',
  /*58*/ 'Education',
  /*59*/ 'News',
  /*60*/ 'Culture/Information',
  /*61*/ 'Shopping',
  /*62*/ 'talk show & Entertainment',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /* */ '',
  /*100*/ 'Government & Politics',
  /*101*/ 'Health & Wellness',
  /*102*/ 'Home & Garden',
  /*103*/ 'Documentary',
  /*104*/ 'Law',
  /*105*/ 'Outdoors',
  /*106*/ 'Performing Arts',
  /*107*/ 'Police&Investigation',
  /*108*/ 'Popular Culture',
  /*109*/ 'Religion & Spirituality',
  /*110*/ 'Science',
  /*111*/ 'Society',
  /*112*/ 'Technology&Media',
  /*113*/ 'The Supernatural',
  /*114*/ 'Samurai',
  /*115*/ 'Variety Show',
  /*116*/ 'Tokusatsu',
  /*117*/ 'Sumo',
  /*118*/ 'Nature',
  /*119*/ 'Language',
  /*120*/ 'J-Pop',
  /*121*/ 'skating',
  /*122*/ 'Environment',
  /*123*/ '',
  /*124*/ 'Classic / Literature',
  /*125*/ 'Epic',
  /*126*/ 'Teenager',
  /*127*/ 'Docudrama',
  /*128*/ 'Medical',
  /*129*/ 'Soap drama',
  /*130*/ 'Baseball',
  /*131*/ 'Basketball',
  /*132*/ 'Boxing',
  /*133*/ 'Cricket',
  /*134*/ 'Cycling',
  /*135*/ 'Equestrian',
  /*136*/ 'Extreme Sports',
  /*137*/ '',
  /*138*/ 'Football',
  /*139*/ 'Golf',
  /*140*/ 'Handball',
  /*141*/ 'Ice Hockey',
  /*142*/ 'Motor Sports',
  /*143*/ 'Olympics',
  /*144*/ 'Team Sports',
  /*145*/ 'Pro Wrestling',
  /*146*/ 'Sailing',
  /*147*/ 'Soccer',
  /*148*/ 'Special Event',
  /*149*/ 'Sports News',
  /*150*/ 'Tennis',
  /*151*/ 'Volleyball',
  /*152*/ 'Water Sports',
  /*153*/ 'Winter Sports',
  /*154*/ 'Community Issues',
  /*155*/ 'Finance&Economics',
  /*156*/ 'Interview',
  /*157*/ 'Weather',
  /*158*/ 'Award Ceremonies',
  /*159*/ 'Charity Program',
  /*160*/ 'Comedy Show',
  /*161*/ 'Entertainment News',
  /*162*/ 'Game ',
  /*163*/ 'Quiz show',
  /*164*/ 'opera',
  /*165*/ 'Classical',
  /*166*/ 'Concerts',
  /*167*/ 'Country&Folk',
  /*168*/ 'Hip-Hop / Rap',
  /*169*/ 'Indie Rock',
  /*170*/ 'Jazz',
  /*171*/ 'Latin',
  /*172*/ 'Metal',
  /*173*/ 'New Age',
  /*174*/ 'Pop',
  /*175*/ 'Punk',
  /*176*/ 'R&B',
  /*177*/ 'Reggae',
  /*178*/ 'Rock',
  /*179*/ 'Soundtrack / Musical',
  /*180*/ 'Spoken&Audio',
  /*181*/ 'Traditional',
  /*182*/ 'Travel&Adventure',
  /*183*/ 'Animals&Pets',
  /*184*/ 'Art&Design',
  /*185*/ 'Business&Industry',
  /*186*/ 'Ethnicity&Culture',
  /*187*/ 'Fashion',
  /*188*/ 'Fire&Rescue',
  /*189*/ 'Fitness&Exercise',
  /*190*/ 'road movie',
  /*191*/ 'trandy drama',
  /*192*/ 'humman drama',
  /*193*/ 'non fiction',
  /*194*/ 'cult movie',
  /*195*/ 'romance comics',
  /*196*/ 'magic/circus',
  /*197*/ 'rottery',
  /*198*/ 'Winter Olympics',
  /*199*/ 'Asian game',
  /*200*/ 'squash',
  /*201*/ 'Track/field',
  /*202*/ 'marathon',
  /*203*/ 'gymnastics/aerobic',
  /*204*/ 'swimming',
  /*205*/ 'magazine',
  /*206*/ 'ski',
  /*207*/ 'wrestling',
  /*208*/ 'Korean wrestling',
  /*209*/ 'judo',
  /*210*/ 'bowling',
  /*211*/ 'shoot/archery',
  /*212*/ 'table tennis',
  /*213*/ 'taekwondo',
  /*214*/ 'bicycle race',
  /*215*/ 'horse ',
  /*216*/ 'fishing',
  /*217*/ 'dancing',
  /*218*/ 'baduk(game of go)',
  /*219*/ 'Korean chess',
  /*220*/ 'Korean classical music',
  /*221*/ 'dance music',
  /*222*/ 'childrens song',
  /*223*/ 'music contest show',
  /*224*/ 'infantile education',
  /*225*/ 'elementary education',
  /*226*/ 'secondary education',
  /*227*/ 'scholastic ability education',
  /*228*/ 'certificate training',
  /*229*/ 'University of the Air ',
  /*230*/ 'English education',
  /*231*/ 'Japanese language education',
  /*232*/ 'chinese language education',
  /*233*/ 'Russian language education',
  /*234*/ 'French  language education ',
  /*235*/ 'German language education',
  /*236*/ 'foreign language education',
  /*237*/ 'computer/internet',
  /*238*/ 'job education',
  /*239*/ 'teacher training',
  /*240*/ 'children education',
  /*241*/ 'debate',
  /*242*/ 'Buddhism',
  /*243*/ 'Christian religion',
  /*244*/ 'Catholic',
  /*245*/ 'furniture shopping',
  /*246*/ 'electrical appliance shopping',
  /*247*/ 'kitchen/home supplies',
  /*248*/ 'health/reports shopping',
  /*249*/ 'golf supplies shopping',
  /*250*/ 'jewelry/watch shopping',
  /*251*/ 'cosmetic shopping',
  /*252*/ 'food shopping',
  /*253*/ 'Interia ',
  /*254*/ 'car appliances shopping',
  /*255*/ 'Fashion shopping',
  /*256*/ 'food/cooking',
  /*257*/ 'election',
  /*258*/ 'Agriculture',
  /*259*/ 'Arm wrestling',
  /*260*/ 'Auction',
  /*261*/ 'Aviation',
  /*262*/ 'Badminton',
  /*263*/ 'Ballet',
  /*264*/ 'Biathlon',
  /*265*/ 'Triathlon',
  /*266*/ 'Billiards',
  /*267*/ 'Bobsled',
  /*268*/ 'Card games',
  /*269*/ 'Cheerleading',
  /*270*/ 'Consumer',
  /*271*/ 'Curling',
  /*272*/ 'Fencing',
  /*273*/ 'Figure skating',
  /*274*/ 'Hockey',
  /*275*/ 'Holiday',
  /*276*/ 'Hunting',
  /*277*/ 'Rugby',
  /*278*/ 'Softball',
  /*279*/ 'World Cup',
  /*280*/ 'Weightlifting',
  /*281*/ 'Military',
  /*282*/ 'Futsal',
  /*283*/ 'Modern pentathlon',
  /*284*/ 'easter',
  /*285*/ 'web drama',
];
