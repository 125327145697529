import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getHeaders } from '../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../constants/index';
import IssTabs from '../common/IssTabs';
import IssTab from '../common/IssTab';
import ContentsDetailCommon from './ContentsDetailCommon';
import DefaultButton from '../common/DefaultButton';
import DropDownForSearch from '../common/DropDownForSearch';
import Alert from '../modal/Alert';
import RadioDropDown from '../common/RadioDropDown';
import IconButton from '../common/IconButton';
import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/Info";
import Info from "../modal/Info";
import TutorialPage from '../TutorialPage';

const ContentsDetail = ({}) => {
  const [currentTab, clickTab] = useState(0);
  const [currentItem, changeCurrentItem] = useState({ groupId: '', issueId: '', issueCode: '' });
  const [contentIssueCount, setContentIssueCount] = useState({ total: 0, feedError: 0, adminError: 0, batchError: 0 });
  const [isTabMenuDisplay, setTabMenuDisplay] = useState(false);
  const [tabMenus, setTabMenus] = useState([]);

  const [regions, setRegions] = useState({'dropdownList': [], 'checkedList' : []});
  const [countries, setCountries] = useState({'dropdownList': [], 'checkedList' : []});
  const [providers, setProviders] = useState({'dropdownList': [], 'checkedList' : []});
  const [searchFilter, setSearchFilter] = useState({});

  const [paramProviders, setParamProviders] = useState([]);
  const [paramRegions, setParamRegions] = useState([]);
  const [paramCountries, setParamCountries] = useState([]);
  const [currentFilter, setCurrentFilter] = useState({ country: '', provider: '' });

  const [checkedCountryList, setCheckedCountryList] = useState([]);
  const [checkedProviderList, setCheckedProviderList] = useState([]);

  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [searchType, setSearchType] = useState({ dropdownList: [] });
  const [paramSearchType, setParamSearchType] = useState('Content Name');
  const [contentNameInput, setContentNameInput] = useState();

  const [calendarValue, setCalendarValue] = useState([]); // [startDate, endDate] eg, 2023-08-20, 2023-09-19
  const [defaultDate, setDefaultDate] = useState([]);

  const [isExcelDownloadFlag, setExcelDownloadFlag] = useState(false);
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');

  const [isActive, setIsActive] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (countries.dropdownList && countries.dropdownList.length > 0 && checkedCountryList && checkedCountryList.length > 0) {
      setCountries({'dropdownList' : countries.dropdownList, 'checkedList' : checkedCountryList});
      setCheckedCountryList([]);
    }
  }, [countries]);

  useEffect(() => {
    if (providers.dropdownList && providers.dropdownList.length > 0 && checkedProviderList && checkedProviderList.length > 0) {
      setProviders({'dropdownList' : providers.dropdownList, 'checkedList' : checkedProviderList});
      setCheckedProviderList([]);
    }
  }, [providers]);

  useEffect(() => {
    const searchItemList = ['Group ID', 'Date', 'Issue Title', 'Status', 'API Name', 'Assign'];

    initTabMenus();
    getContentsErrorCount();
    getText();
    setSearchType({ dropdownList: searchItemList });
    defaultRangeDate();
    getSearchFilter();
  }, []);

  useEffect(() => {
    if (tabMenus && tabMenus.length > 0) {
      setTabMenuDisplay(true);
    }
  }, [tabMenus]);

  useEffect(() => {
    if ( searchFilter.countryByRegion ) {
      let dropdownCountries = [];
      if (paramRegions && paramRegions.length > 0){
        dropdownCountries = searchFilter.countryByRegion.filter(f => paramRegions.includes(f.region)).map(region =>
          region.countryList.map(map => ({ id: map.countryCode, value : map.fullName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownCountries.sort((a, b) => a.value.localeCompare(b.value));
      }
      // Unknown
      if (dropdownCountries.length > 0) {
        dropdownCountries.push({ id: 'Unknown', value : 'Unknown' });
      }

      setCountries({'dropdownList': dropdownCountries, 'checkedList' : [] });
    }
  }, [ paramRegions ]);

  useEffect(() => {
    if ( searchFilter.countryByRegion &&  searchFilter.providerByCountry) {
      let dropdownProviders = [];
      if (paramCountries && paramCountries.length > 0 && paramCountries.filter(item => item != 'Unknown').length > 0) {
        let ProviderList = searchFilter.providerByCountry.filter(f => paramCountries.filter(item => item != 'Unknown').includes(f.countryCode)).map(map =>
          map.providerList.map(pr => ({ id: pr.providerId, value : pr.providerName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownProviders = [...new Set(ProviderList.map((list) => JSON.stringify(list)))].map((list) => JSON.parse(list));
      }
      setProviders({ 'dropdownList': dropdownProviders, 'checkedList' : checkedProviderList });
    }
  }, [ paramCountries ]);

  useEffect(() => {
    const tabMenu = [{ name: `CP (${contentIssueCount.feedError.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})` },
    { name: `CMP Organization (${contentIssueCount.adminError.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})` },
    { name: `Data Deployment (${contentIssueCount.batchError.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})` }];
    setTabMenus(tabMenu);
  }, [contentIssueCount]);

  useEffect(() => {
    if (location && location.state && searchFilter.countryByRegion) {
      const selectedItem = JSON.parse(location.state.param);

      if (selectedItem) {
        if (selectedItem.category === 'cp') {
          changeCurrentItem({ groupId: selectedItem.groupId });

          if (selectedItem.region && selectedItem.region.length > 0
              && selectedItem.country && selectedItem.country.length > 0
              && selectedItem.provider && selectedItem.provider.length > 0) {
            setRegions(prevData => ({ ...prevData, 'checkedList' : selectedItem.region }));
            setCheckedCountryList(selectedItem.country);
            setCheckedProviderList(selectedItem.provider);

            setCurrentFilter({ country: selectedItem.country, provider: selectedItem.provider });
          } else {
            const allCountries = [...new Set(searchFilter.countryByRegion.flatMap(region =>
              region.countryList.map(map => map.countryCode)))];
            allCountries.push('Unknown');

            const allProvider = [...new Set(searchFilter.providerByCountry.flatMap(country =>
              country.providerList.map(map => map.providerId)))];

            setCurrentFilter({ country: allCountries, provider: allProvider });
          }
        } else if (selectedItem.category === 'admin') {
          changeCurrentItem({ issueId: selectedItem.issueId, issueCode: selectedItem.issueCode });
          clickTab(1);
        } else if (selectedItem.category === 'batch') {
          changeCurrentItem({ issueId: selectedItem.issueId, issueCode: selectedItem.issueCode });
          clickTab(2);
        } else if (selectedItem.category === 'device') {
          changeCurrentItem({ issueId: selectedItem.issueId, issueCode: selectedItem.issueCode });
          clickTab(3);
        }
      }
    }
  }, [location, searchFilter]);

  useEffect(() => {
    setExcelDownloadFlag(false);
  }, [isExcelDownloadFlag]);

  const getSearchFilter = () => {
    fetch(`${ISS_BASE_URL}/api/common/search-filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSearchFilter({
          countryByRegion : body.countryByRegion
          , providerByCountry : body.providerByCountry
        })

        let dropdownRegion = [];
        if ( body.countryByRegion ) {
          dropdownRegion = body.countryByRegion.map(map =>({ id: map.region, value : map.region }));
          dropdownRegion.sort((a, b) => a.value.localeCompare(b.value));
        }
        setRegions({ 'dropdownList': dropdownRegion, 'checkedList' : [] });
      } else {
        setAlertTitle('Error');
        setAlertMessage('[getSearchFilter : error]');
        setIsAlert(true);
      }
    })
    .catch(error => {
      console.log("getSearchFilter - error: " , error);
      setAlertTitle('Error');
      setAlertMessage('[getSearchFilter : error]');
      setIsAlert(true);
    }).finally(() => {});
  };

  const tabChangeHandler = (selectedIdx, _data) => {
    changeCurrentItem({ groupId: '', issueId: '', issueCode: '' });
    setCurrentFilter({ country: '', provider: '' });
    setCheckedCountryList([]);
    setCheckedProviderList([]);
    clickTab(selectedIdx);
    setExcelDownloadFlag(false);
  };

  const initTabMenus = () => {
    const tabMenu = [{ name: `CP (0)` }, { name: `CMP Organization (0)` }, { name: `Data Deployment (0)` }];
    setTabMenus(tabMenu);
  };

  const getContentsErrorCount = () => {
    fetch(`${ISS_BASE_URL}/api/application/dashboard/content/error/count`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        const feedError = body.find(item => item.issueName === 'feed');
        const adminError = body.find(item => item.issueName === 'admin');
        const batchError = body.find(item => item.issueName === 'batch');
        const totalCount = feedError.issueCount + adminError.issueCount + batchError.issueCount;

        setContentIssueCount({
          total: totalCount,
          feedError: feedError.issueCount,
          adminError: adminError.issueCount,
          batchError: batchError.issueCount
        });
      })
      .catch(error => {
        console.log(`getContentsErrorCount error: `, error);
      });
  };

  const updateIssueCount = (result) => {
    setContentIssueCount((prevCount) => ({
      ...prevCount,
      [result.type]: result.issueCount,
      total: prevCount.total + (result.issueCount - prevCount[result.type])
    }));
  }

  const issueSearchByButton = () => {
    if (currentTab === 0 && (paramRegions.length === 0 || paramCountries.length === 0 || paramProviders.length === 0)) {
      const showMessage = paramRegions.length === 0 ? 'region' : paramCountries.length === 0 ? 'country' : 'cp';
      setAlertTitle('Warning');
      setAlertMessage(`Please select any ${showMessage}`);

      onOpenAlert();
    } else {
      changeCurrentItem({ groupId: '', issueId: '', issueCode: '' });

      setCurrentFilter({
        date: calendarValue, country: paramCountries, provider: paramProviders,
        searchType: paramSearchType, contentName: contentNameInput ? contentNameInput.trim() : ''
      })
    }
  };

  const handleBackButton = (item) => {
    const selectedLocation = { type: item };

    history.push(`/ISS/IssDashboard`, {
      param: JSON.stringify(selectedLocation),
    });
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const handleContentNameInputChange = event => {
    setContentNameInput(event.target.value);
  };

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      issueSearchByButton();
    }
  };

  const handleParamSearchType = (searchType) => {
    setContentNameInput('');
    setParamSearchType(searchType);
  };

  // Default date는 30일
  const defaultRangeDays = 30; // 달력에 날짜를 보여 주기 위한 default 일자, 30일
  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());

    const thirtyDaysAgo = new Date(utcToday);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);

    const utcTodayString = utcToday.toISOString().substring(0, 10);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0, 10);

    setDefaultDate([thirtyDaysAgoString, utcTodayString]); // [startDate, endDate]
  };

  const excelDownload = () => {
    setExcelDownloadFlag(true);
  };

  const onOpenInfo = () => {
    setIsOpenInfo(true);
  }

  const onCloseInfo = () => {
    setIsOpenInfo(false);
  }

  const getText = () => {
    fetch(`${ISS_BASE_URL}/api/common/text`, {
      method: 'GET',
      headers: getHeaders(),
    })
        .then(res => res.json())
        .then(body => {
          if (body.result === 'SUCCESS') {
            setInfoMessage(
          [
                  {name: 'CP',
                    message: body.text.DETAIL_CONTENT_CP_ISSUE_INFO ? body.text.DETAIL_CONTENT_CP_ISSUE_INFO : ''},
                  {name: 'CMP Organization',
                    message: body.text.DETAIL_CONTENT_CMP_ISSUE_INFO ? body.text.DETAIL_CONTENT_CMP_ISSUE_INFO : ''},
                  {name: 'Data Deployment',
                    message: body.text.DETAIL_CONTENT_DEPLOY_ISSUE_INFO ? body.text.DETAIL_CONTENT_DEPLOY_ISSUE_INFO : ''}
                ]);
          } else {
            setAlertTitle('Error')
            setAlertMessage('Failed to get info message')
          }
        })
        .catch(e => {
          console.log('getText: ', e)
        }).finally(() => {})
  };

  const handleOpen = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <>
      {isTabMenuDisplay ? (
        <>
          <div className="iss-main">
            <div className="main-container">
              <div className="inner">
                <div className="sub-detail-wrapper sub-detail-contents">
                  <div className="head">
                    <div className="title">
                      <button className="btn-tutorial-2" onClick={handleOpen}/>
                      <h1>Content Detail ({contentIssueCount.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})</h1>
                    </div>
                    <div className="back" onClick={() => handleBackButton('Content')}>
                      <button></button>
                    </div>
                    <Chip label={"Info"} icon={<InfoIcon/>} className={'info'} onClick={onOpenInfo} />
                  </div>
                  <div className="box-components">
                    <div className="ui-dropdown-box">
                      {currentTab === 0 ? (
                        <>
                          <DropDownForSearch dropDownItem={regions} title="Region" onChangeDropDown={setParamRegions} />
                          <DropDownForSearch dropDownItem={countries} title="Countries" onChangeDropDown={setParamCountries} />
                          <DropDownForSearch dropDownItem={providers} title="CPs" onChangeDropDown={setParamProviders} />
                          <RadioDropDown item={searchType} onChangeDropDown={handleParamSearchType} />
                          <div className="content-name">
                            <input type="text" value={contentNameInput} placeholder={paramSearchType}
                              onChange={event => handleContentNameInputChange(event)}
                              onKeyPress={handleOnKeyPress}
                            />
                          </div>
                          <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={issueSearchByButton} />
                          <IconButton type={'button'} text={'Excel Download'} btnClass={'btn-icon-download'} clickEvent={excelDownload} />
                        </>
                      ) : (
                        <></>
                      )
                      }

                    </div>
                    <IssTabs tabMenus={tabMenus} currentTab={currentTab} className={'ui-tab-type03'} onChange={tabChangeHandler}>
                      <IssTab isActive={true}>
                        <ContentsDetailCommon currentTab={currentTab}
                          currentItem={(currentItem.issueId !== '' || currentItem.groupId !== '') ? currentItem : null}
                          currentFilter={currentFilter}
                          isExcelDownloadFlag={isExcelDownloadFlag}
                          issueChangeCallback={updateIssueCount}
                        />
                      </IssTab>
                    </IssTabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
          {isOpenInfo && <Info isOpen={isOpenInfo} title={'Content Issue Info'} message={infoMessage} onClose={onCloseInfo}
                               className="modal-alert-long" />}
          {isActive && <TutorialPage data={'contentDetail'} isActive={isActive} onClose={handleClose} />}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ContentsDetail;