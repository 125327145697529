const initialState = [];

export default function serviceBaseData(state = initialState, action) {
    switch (action.type) {
        case 'SET_MONITOR_SERVICE_BASE_DATA':
            return action.payload;
        default:
            return state;
    }
}
