import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function ChangePw({ title, filter, handleInputChange, handleSubmit, handleClose }) {
  return (
    <div>
      <DialogTitle id="form-dialog-title" className={'pop_head'}>
        {title}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={'pop_body'}>
        <form onSubmit={handleSubmit}>
          <div className={'tbl_wrap'}>
            <table className={'tbl_row_typ1'}>
              <colgroup>
                <col style={{ width: '200px' }} />
                <col style={{ width: 'auto' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <label htmlFor={'name'} className={'is_required'}>
                      Password
                    </label>
                  </th>
                  <td>
                    <TextField fullWidth margin="dense" variant="outlined" name="password" value={filter.password.value} onChange={event => handleInputChange(event)} helperText={filter.password.errorMsg} type="password" required />
                  </td>
                </tr>

                <tr>
                  <th>
                    <label htmlFor={'password'} className={'is_required'}>
                      Re-enter password
                    </label>
                  </th>
                  <td>
                    <TextField fullWidth margin="dense" variant="outlined" name="passwordReEnter" value={filter.passwordReEnter.value} onChange={event => handleInputChange(event)} type="password" required />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={'btn_set_wrap'}>
            <Button
              data-testid="addButton"
              //type="cancel"//error
              color="primary"
              onClick={handleClose}
              className={'btn_color1'}>
              Cancel
            </Button>
            <Button
              data-testid="addButton"
              type="submit"
              color="primary"
              className={'btn_color2'}
              //disabled={isFormInvalid()}
            >
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </div>
  );
}

ChangePw.propTypes = {
  title: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
// const mapStateToProps = (state) => ({
//   isAdded: state.providers.isAdded,
// });
// const mapDispatchToProps = (dispatch) => ({
//   addProvidersAdminAction(json) {
//     dispatch(addProvidersAdminAction(json));
//   },
// });
// export default connect(null, mapDispatchToProps)(withRouter(CpCodeAdd));
