import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogContent, DialogTitle, MenuItem, Select, Table, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const devicePlatformList = ['Android', 'IOS'];

function TabCategoryCopy({ data, handleInputChange, handleSubmit, handleClose, handleInputNumberChange, regionList, regionPlatformList }) {

  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        COPY HOME-APP DATA
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={'pop_body'}>
          <div>
            <TableContainer className={'tbl_wrap'}>
              <Table className={'tbl_row_typ1'}>
              <tbody>
                <TableRow width="100%">
                  <TableCell colSpan={4}>
                    <label className="">select device</label>
                  </TableCell>
                </TableRow>
                <TableRow width="100%">
                  <TableCell colSpan={1} width = "20%">
                    <label className="is_required">Device Type</label>
                  </TableCell>
                  <TableCell colSpan={1} width = "30%">
                    <Select name="targetDeviceType" id="targetDeviceType" required style={{ minWidth: 120 }} value={data.targetDeviceType ? data.targetDeviceType : ''} onChange={event => handleInputChange(event)}>
                    {[...regionList].map(item => (
                        <MenuItem key={item.deviceType} value={item.deviceType}>
                          {item.deviceType}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell colSpan={1} width = "20%">
                    <label className="is_required">Region</label>
                  </TableCell>
                  <TableCell colSpan={1} width = "30%">
                    <Select name="targetRegion" id="targetRegion" required style={{ minWidth: 120 }} value={data.targetRegion ? data.targetRegion : ''} onChange={event => handleInputChange(event)}>
                    {([...regionList].find(n => n.deviceType === data.targetDeviceType) || { list: [] }).list.map(n2 => (
                      <MenuItem key={n2.region} value={n2.region}>
                        {n2.region}
                      </MenuItem>
                    ))}
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}>
                      <label className="">copy platform version</label>
                  </TableCell>
                </TableRow>
                <TableRow width="100%">
                  <TableCell colSpan={1}>
                    <label className="is_required">source version</label>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Select name="targetVersion" id="targetVersion" required style={{ minWidth: 120 }} value={data.targetVersion ? data.targetVersion : ''} onChange={event => handleInputChange(event)}>
                    {(regionPlatformList.filter(n => n.deviceType === data.targetDeviceType && n.region === data.targetRegion)).map(n2 => (
                      <MenuItem key={n2.platformVersion} value={n2.platformVersion}>
                        {n2.platformVersion}
                      </MenuItem>
                    ))}
                    </Select>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <label className="is_required">result copy version</label>
                  </TableCell>
                  {(data.targetDeviceType === 'tv' || (data.targetDeviceType !== 'pc' && data.targetDeviceType !== 'mobile') ) && (
                  <TableCell colSpan={1}>
                    <TextField
                      fullWidth name="cloneVersion"
                      inputProps={{ maxLength: 11 }}
                      required
                      value={data.cloneVersion ? data.cloneVersion : ''}
                      onChange={event => handleInputNumberChange(event)}
                      placeholder="ex) 4.3.0" />
                  </TableCell>
                  )}
                  {(data.targetDeviceType === 'pc'  ) && (
                  <TableCell colSpan={1}>
                    <TextField
                      fullWidth name="cloneVersion"
                      inputProps={{ maxLength: 11 }}
                      required
                      value={data.cloneVersion ? data.cloneVersion : ''}
                      onChange={event => handleInputChange(event)}
                      placeholder="ex) pc" />
                  </TableCell>
                  )}
                  {(data.targetDeviceType === 'mobile'  ) && (
                  <TableCell colSpan={1}>
                    <Select name="cloneVersion" id="cloneVersion" required style={{ minWidth: 120 }} value={data.cloneVersion ? data.cloneVersion : ''} onChange={event => handleInputChange(event)}>
                    {[...devicePlatformList].map(item => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  )}
                </TableRow>
                </tbody>
              </Table>
            </TableContainer>
          </div>
          <div className={'btn_set_wrap'}>
            <Button data-testid="addButton" color="primary" onClick={handleClose} className={'btn_color1'}>
              Cancel
            </Button>
            <Button type="submit" color="primary" className={'btn_color2'}>
              Save
            </Button>
          </div>
        </DialogContent>
      </form>
    </Fragment>
  );
}

TabCategoryCopy.propTypes = {
  data: PropTypes.object,
  regionList: PropTypes.array.isRequired,
  regionPlatformList: PropTypes.array.isRequired,
  regionCountries: PropTypes.array.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleInputNumberChange: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  regionList: state.homeApp.regionList,
  regionPlatformList: state.homeApp.regionPlatformList,
  regionCountries: state.cntry.regionCountries
});

export default connect(mapStateToProps)(TabCategoryCopy);

