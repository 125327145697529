import { GET_COUNTRY, GET_CPLIST, SET_API_FAIL } from './actionTypes';
import { API_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';
const URL = API_URL;
const getCountries = list => ({
  type: GET_COUNTRY,
  payload: list,
});
const getCPList = list => ({
  type: GET_CPLIST,
  payload: list,
});
const doFail = body => ({
  type: SET_API_FAIL,
  payload: body,
});
export const chMappingActions = () => async dispatch => {
  let apiUrl = `${URL}/sdpapi/countries`;
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch(getCountries(body)))
    .catch(ex => dispatch(doFail()));
  let cpUrl = `${URL}/sdpapi/cps`;
  fetch(cpUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch(getCPList(body)))
    .catch(ex => dispatch(doFail()));
};
export const getCntryInfo = () => dispatch => {
  fetch(`${URL}/sdpapi/cntryInfo`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: 'GET_CNTRY_INFO',
        payload: body,
      }),
    )
    .catch(ex => dispatch(doFail()));
};
export const callCPList = () => dispatch => {};
