import * as actions from '../../actions/actionTypes';

const initialState = 'Y';

export default function statisticResultShow(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATISTIC_FETCHING:
    case actions.SET_STATISTIC_RESULT_SHOW:
      return 'Y';
    case actions.SET_STATISTIC_RESULT_SHOW_HOLDING:
      return 'N';
    default:
      return state;
  }
}
