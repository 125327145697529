import * as actions from './actionTypes';
import { API_BASE_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';

export const getStatisticAction = (isRoleAdmin, menu, current, cp, country, platform, callback) => dispatch => {
  if (isRoleAdmin === undefined)
    dispatch({
      type: actions.SET_STATISTIC_INIT,
      payload: {},
    });
  else {
    let periodType = current.split('-').length === 2 ? 'monthly' : 'weekly';

    fetch(`${API_BASE_URL}/statistics/display?periodType=${periodType}&period=${current}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body && body['displayFlag'] === 'Y') {
        let body = { current: current, cp: cp, country: country, platform: platform };

        dispatch({
          type: actions.SET_STATISTIC_FETCHING,
          payload: true,
        });

        return fetch(`${API_BASE_URL}/statistics/${menu}`, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify(body),
        })
        .then(res => res.json())
        .then(body => {
          let _keys = Object.keys(body).sort();
          callback(_keys);

          if (menu === 'homeapp') {
            for (let _month of  _keys) {
              body[_month]['totalDuration'] = (body[_month]['totalDuration'] / 3600).toFixed(2);
              body[_month]['durationPerUd'] = (body[_month]['durationPerUd'] / 3600).toFixed(2);
            }
          }

          return dispatch({
            type: actions.SET_STATISTIC,
            payload: body,
          });
        }) //include 400Error
        .catch(ex => {
          return dispatch({
            type: actions.SET_STATISTIC_ERROR,
            payload: ex,
          });
        });
      } else {
        return dispatch({
          type: actions.SET_STATISTIC_RESULT_SHOW_HOLDING,
          payload: false,
        });
      }
    })
    .catch(ex => {
      return dispatch({
        type: actions.SET_STATISTIC_ERROR,
        payload: ex,
      });
    });
  }
};

export const getGraphAction = (isRoleAdmin, menu, current, cp, country, platform) => dispatch => {
  if (isRoleAdmin === undefined)
    dispatch({
      type: actions.SET_STATISTIC_GRAPH_INIT,
      payload: null,
    });
  else {
    let body = { current: current, cp: cp, country: country, platform: platform };

    dispatch({
      type: actions.SET_STATISTIC_GRAPH_FETCHING,
      payload: true,
    });
    return fetch(`${API_BASE_URL}/statistics/${menu}/graph`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(body =>
        dispatch({
          type: actions.SET_STATISTIC_GRAPH,
          payload: body,
        }),
      ) //include 400Error
      .catch(ex =>
        dispatch({
          type: actions.SET_STATISTIC_ERROR,
          payload: ex,
        }),
      );
  }
};

export const getDetailTableAction = (isRoleAdmin, menu, current, cp, country, platform) => dispatch => {
  if (isRoleAdmin === undefined)
    dispatch({
      type: actions.SET_STATISTIC_TABLE_INIT,
      payload: {},
    });
  else {
    let body = { current: current, cp: cp, country: country, platform: platform };

    dispatch({
      type: actions.SET_STATISTIC_TABLE_FETCHING,
      payload: true,
    });
    return fetch(`${API_BASE_URL}/statistics/${menu}/table`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(body => {
        return dispatch({
          type: actions.SET_STATISTIC_TABLE,
          payload: body,
        });
      }) //include 400Error
      .catch(ex =>
        dispatch({
          type: actions.SET_STATISTIC_ERROR,
          payload: ex,
        }),
      );
  }
};

export const updateStatisticResultShow = (result) => dispatch => {
  if (result) {
    dispatch({
      type: actions.SET_STATISTIC_RESULT_SHOW,
      payload: true,
    });
  } else {
    dispatch({
      type: actions.SET_STATISTIC_RESULT_SHOW_HOLDING,
      payload: false,
    });
  }
};