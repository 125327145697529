import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { HeaderMenuList } from './MenuList';
import {ROLE_ADMIN, ROLE_AUTH_EDIT, ROLE_CP, ROLE_SUPER_ADMIN} from "../../../constants";

const gnbMouseEnter = () => {
  document.getElementById('header').classList.add('is_active');
};

const MenuLink = ({ clickHeaderMenu, clickHeaderSubMenu, list, role, userRole, userAuth }) => {

  const hasAuthorityToSubTab = (roleName, authName, subTab) => {
    if (roleName === ROLE_ADMIN || roleName === ROLE_SUPER_ADMIN) return true;
    if (subTab.role && subTab.role.includes(roleName)) {
      return !(subTab.auth === ROLE_AUTH_EDIT && authName !== ROLE_AUTH_EDIT);
    }

    return false;
  }

  const detailMenu = list.detailMenu
    .map(
      sublist =>
      (
        (role.name !== 'ROLE_CP' || list.menu !== 'STATISTICS' || sublist.label !== 'LG Channels Home')
       || hasAuthorityToSubTab(userRole, userAuth, sublist)
      ) && (
      <li key={sublist.path}>
        <Link to={sublist.path} onClick={clickHeaderSubMenu}>
          <span>{sublist.label}</span>
        </Link>
      </li>
    ));
  return (
    <React.Fragment>
      <Link to={detailMenu[0].key} onMouseEnter={gnbMouseEnter} onClick={clickHeaderMenu}>
        <span>{list.menu}</span>
      </Link>
      <div className={'sub_menu'}>
        <ul>{detailMenu}</ul>
      </div>
    </React.Fragment>
  );
};

function HeaderSubMenu({ clickHeaderMenu, clickHeaderSubMenu, role, currentMenu, userRole, userAuth }) {
  return (
    <ul>
      {HeaderMenuList.map(
        list =>
          ((role.name === 'ROLE_SUPER_ADMIN' || role.menu.includes(list.menu))
             || (list.role && list.role.includes(userRole))
          ) && (
            <li key={list.menu} className={list.menu === currentMenu ? 'is_active' : ''}>
              <MenuLink clickHeaderMenu={clickHeaderMenu} clickHeaderSubMenu={clickHeaderSubMenu} list={list} role={role}
              userRole={userRole} userAuth={userAuth} />
            </li>
          ),
      )}
    </ul>
  );
}

MenuLink.propTypes = {
  clickHeaderMenu: PropTypes.func.isRequired,
  clickHeaderSubMenu: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
};

HeaderSubMenu.propTypes = {
  clickHeaderMenu: PropTypes.func.isRequired,
  clickHeaderSubMenu: PropTypes.func.isRequired,
  currentMenu: PropTypes.string,
  role: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  role: state.currentUser.role,
  userRole: state.currentUser.userRole,
  userAuth: state.currentUser.userAuth,
});
// const mapDispatchToProps = (dispatch) => ({
//   setDrawerOpenAction (value) {
//   	dispatch(setDrawerOpenAction(value));
//   }
// });
export default connect(mapStateToProps)(HeaderSubMenu);
