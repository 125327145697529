import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export { default as ApiCallLogFilter } from './ApiCallLogFilter';
export { default as BizAreaFilter } from './BizAreaFilter';
export { default as ProgramFilter } from './ProgramFilter';
export { default as ScheduleFilter } from './ScheduleFilter';
export { default as AdminFilter } from './AdminFilter';
export { default as CpMemberFilter } from './CpMemberFilter';

export { default as CommonStatisticFilter } from './statistic/CommonStatisticFilter';
export { default as DateFilter } from './statistic/DateFilter';
export { default as PushAlarmFilter } from './PushAlarmFilter';

function CountryFilterRaw({ allCountries, country, handleChangeCountry, multiple, includeAll }) {
  //console.log('country???', country);
  const cntryArray = includeAll ? ['All', ...allCountries] : allCountries;
  return (
    <FormControl variant="outlined">
      <InputLabel>Country</InputLabel>
      <Select value={country} onChange={handleChangeCountry} multiple={multiple}>
        {cntryArray.map(item => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
CountryFilterRaw.propTypes = {
  allCountries: PropTypes.array.isRequired,
  //country: PropTypes.object, //if multiple is true, country should be array
  handleChangeCountry: PropTypes.func.isRequired,
  multiple: PropTypes.bool, //if multiple is true, country should be array
  includeAll: PropTypes.bool,
};
const mapStateToProps = state => ({
  allCountries: state.cntry.allCountries,
});
const CountryFilter = connect(mapStateToProps)(CountryFilterRaw);
export { CountryFilter };
