import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import { styled } from '@material-ui/core/styles';
import ChartIcon from '@material-ui/icons/PollOutlined';

function RadioChartFilter( props ) {
  const { chartType, onChangeRadio } = props;

  const chartChangeHandler = event => {
    const { value } = event.target;
    onChangeRadio(value);
  };

  const BpIcon = styled('span')({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#394b59',
    backgroundImage:'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#30404d',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(57,75,89,.5)',
    },
  });

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#7b54f1',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#c5bbff',
    },
  });

  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup row aria-label="chartFilter" name="chartFilter" value={chartType} onChange={chartChangeHandler}>
          <FormControlLabel control={<ChartIcon fontSize="medium" style={{ color: "#85dddb", cursor: 'default'}} />} />
          <FormControlLabel value="Line" control={<Radio disableRipple color="default" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />} label="Line" />
          <FormControlLabel value="Bar" control={<Radio disableRipple color="default" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />} label="Bar" />
        </RadioGroup>
      </FormControl>
    </>
  );
}
RadioChartFilter.propTypes = {
  chartType: PropTypes.string.isRequired,
  onChangeRadio: PropTypes.func,
};
export default RadioChartFilter;
