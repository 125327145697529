import * as actions from '../../actions/actionTypes';

const initialState = false;

export default function freeONLGChannelSettingListFetching(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FREEONLG_SETTINGLIST_FETCHING:
      return true;
    case actions.SET_FREEONLG_SETTINGLIST:
      return false;
    default:
      return state;
  }
}
