import React, { useEffect, useState } from 'react';
import Loading from '../../../common/Loading';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import Alert from '../../../modal/Alert';
import { getHeaders } from '../../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../../constants/index'
import { format, addDays, differenceInDays } from 'date-fns';
import { getScalesMaxValue } from '../../../utils/IssUtils';
import {
  CHART_COLORS_BY_COUNTRY,
  CHART_COLORS_BY_PROVIDER,
  CHART_COLORS_BY_PLATFORM
} from '../../../common/ChartConsts';
import {
  unique_device_line_trend_data,
  unique_device_country_line_trend_data,
  unique_device_platform_line_trend_data
 } from './StatisticsTrendData';

function UniqueDeviceLineTrend(props) {
  const { height, title, originalFilter, currentFilter, updateTable, size} = props;
  const [trendData, setTrendData] = useState({data: {}, options: {}});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [isUniqueDeviceDisplay, setUniqueDeviceDisplay] = useState(false);
  const plugin = [annotationPlugin];
  const [displayType, setDisplayType] = useState([]);

  useEffect(() => {
    if (currentFilter.date) {
      const tempCountry = currentFilter.country ? currentFilter.country : [];
      const tempPlatforms = currentFilter.platforms ? currentFilter.platforms
      .map(map => map.replace('webOS', 'webOSTV').replace('webOSTV 22', 'webOSTV 7.0')) : [];
      const endDate = currentFilter.date[1];
      const compareType = currentFilter.compareType;
      getDataTrend({startDate: currentFilter.date[0], endDate: endDate, countryCodeList: tempCountry,
        platformList: tempPlatforms, compareType: compareType})
    }
  }, [currentFilter, size]);

  const getDataTrend = (bodyFilter) => {
    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/trend/uniquedevice`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(bodyFilter),
    })
      .then(res => res.json())
      .then(body => {
        const data = {
          labels: [],
          datasets: [],
        };

        const options = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              display: false,
            },
          },
          scales: {
            y: {
              min: 0,
              max: 0,
              ticks: {
                stepSize: 10,
              },
            },
            x: {
              ticks: {
                callback: function(value, index, values) {
                  if (data.labels[index] && (data.labels[index]).length > 5) {
                    if (index > 0 && (data.labels[index - 1]).substring(0, 5) === (data.labels[index]).substring(0, 5)) {
                      return '';
                    }
                    return (data.labels[index]).substring(0, 5);
                  } else {
                    return data.labels[index];
                  }
                }
              },
            },
          },
        };

        const startDate = new Date(bodyFilter.startDate);
        const endDate = new Date(bodyFilter.endDate);
        const differenceDays = differenceInDays(endDate, startDate);
        const compareType = bodyFilter.compareType;
        let dateList = [];
        let trendChartData = [];
        let template = [];
        let minList = [];
        let maxList = [];

        for ( let i = 0; i <= differenceDays; i++) {
          dateList.push(format(addDays(startDate, i),'yyyy-MM-dd'));
        }

        if (body && body.length > 0) {
          let contentBody = body.map(map => ({ period: map.period, country: map.countryCode,
            platform: map.platform, value: map.uniqueDevice }));
          if(compareType && compareType != 'None'){
            let compareTypeList = [];
            const compareList = [... new Set(contentBody.map(item =>
              compareType === 'Country' ? item.country : item.platform))];
            compareList.forEach((compareItem) => {
              let tempCompareList = [];
              let valueList = [];
              dateList.forEach((dl) => {
                let tempValue = 0.0;
                const findContent = contentBody.find(item =>
                  (compareType === 'Country' ? item.country : item.platform) ===
                    compareItem && item.period === dl);
                  if (findContent){
                    tempValue = findContent.value;
                  }
                tempCompareList.push({ period: dl, value: tempValue, country: compareItem, platform:  compareItem});
              });
              valueList = tempCompareList.map(map => map.value);
              compareTypeList.push(...tempCompareList);
              trendChartData.push(chartTemplate(compareType, compareItem, compareItem, valueList));
              minList.push(Math.min(...valueList));
              maxList.push(Math.max(...valueList));
            });
            if(compareType && compareType === 'Country'){
              unique_device_country_line_trend_data.rowdata = compareTypeList.map(item => ({
                row: [
                  item.period,
                  item.country,
                  item.value
                ],
              }));
              updateTable(unique_device_country_line_trend_data);
            } else if(compareType && compareType === 'Platform'){
              unique_device_platform_line_trend_data.rowdata = compareTypeList.map(item => ({
                row: [
                  item.period,
                  item.platform,
                  item.value
                ],
              }));
              updateTable(unique_device_platform_line_trend_data);
            }
          } else {
            let valueList = [];
            dateList.forEach((dl) => {
              let tempValue = 0.0;
              const findContent = contentBody.find(item => item.period === dl);
              if (findContent){
                tempValue = findContent.value;
              }
              template.push({ period: dl, value: tempValue });
            });
            valueList = template.map(map => map.value);
            trendChartData.push(chartTemplate(compareType, '', 'Unique Device', valueList));
            minList.push(Math.min(...valueList));
            maxList.push(Math.max(...valueList));
            unique_device_line_trend_data.rowdata = template.map(item => ({
              row: [
                item.period,
                item.value
              ],
            }));
            updateTable(unique_device_line_trend_data);
          }
        } else {
          let valueList = [];
          dateList.forEach((dl) => {
            let tempValue = 0.0;
            template.push({ period: dl, value: tempValue });
          });
          valueList = template.map(map => map.value);
          trendChartData.push(chartTemplate('None', '', 'Unique Device', valueList));
          minList.push(0.0);
          maxList.push(1.0);
          unique_device_line_trend_data.rowdata = template.map(item => ({
            row: [
              item.period,
              item.value
            ],
          }));
          updateTable(unique_device_line_trend_data);
        }
        let displayTypeData = [];
        const sizeCnt = size === 'Large' ? 2 : 1;
        let limitCnt = compareType === 'Country' ? 12 :
        compareType === 'Platform' ? 9 : 1;
        trendChartData.forEach((dl, index) => {
          if(index < (limitCnt * sizeCnt)){
          displayTypeData.push(dl);
          }
        });
        setDisplayType(displayTypeData);
        options.scales.y.min = Math.floor(Math.min(...minList));
        options.scales.y.max = Math.floor(Math.max(...maxList)) === 0 ? 1.0 : Math.floor(getScalesMaxValue(Math.max(...maxList)));
        data.labels = dateList.map(map => map.substring(5));
        data.datasets = trendChartData;
        setTrendData({data: data, options: options});
        setUniqueDeviceDisplay(true);
      })
      .catch(error => {
        setAlertMessage(`trend Unique Device catch: `, error);
        onOpenAlert();
      })
      .finally(() => {});
  };

  const chartTemplate = (compareType, paramValue, paramLabel, valueList) => {
    const template = {
      label: paramLabel.replace('webOSTV', 'OS') ,
      data: valueList,
      fill: false,
      pointRadius: 3,
      pointBorderColor: '#FFFFFF',
      borderColor: hexToRgba(getColor(compareType, paramValue), 0.4),
      backgroundColor: hexToRgba(getColor(compareType, paramValue), 0.4),
      pointBackgroundColor: hexToRgba(getColor(compareType, paramValue), 0.4),
      pointBorderWidth: 2,
      order: 2,
    };
    return template;
  };

  function getColor(compareType, item) {
    if (compareType === 'Country') {
      return CHART_COLORS_BY_COUNTRY[item] || CHART_COLORS_BY_COUNTRY['OTHERS'];
    } else if (compareType === 'Platform') {
      const convertItem = item.replace('webOSTV', 'webOS').replace('webOS 7.0','webOS 22');
      return CHART_COLORS_BY_PLATFORM[convertItem] || CHART_COLORS_BY_PLATFORM['OTHERS'];
    } else {
      return CHART_COLORS_BY_PROVIDER[item] || CHART_COLORS_BY_PROVIDER['OTHERS'];
    }
  }

  const hexToRgba = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <>
    <div style={{ height: height }}>
      <div className='trend-head'>
        <div className="title" style={{top: "-30px"}}>
          <div> {title} </div>
        </div>
        <div className="legend">
          <ul>
            { isUniqueDeviceDisplay ? (
              displayType.map((item) => (
                <li>
                  <i style={{backgroundColor: item.borderColor}}/>
                    <span>{item.label}</span>
                </li>
              ))
              ) : (
                <></>
              )
            }
          </ul>
        </div>
      </div>
      { isUniqueDeviceDisplay ? (
        <Line id={'daily_contents_error_occurrence'} data={trendData.data}
          options={trendData.options} plugins={plugin} />
        ) : (
          <></>
        )
      }
    </div>
    {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default UniqueDeviceLineTrend;