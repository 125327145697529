const initialState = [];

export default function platforms(state = initialState, action) {
  switch (action.type) {
    case 'SET_PLATFORMS':
      if (action.payload.status) {
        return state;
      } else if (action.payload) {
        return action.payload.length ? action.payload.sort() : state;
      } else {
        return state;
      }
    default:
      return state;
  }
}
