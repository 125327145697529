//import { SET_REGION, SET_TAB_CATEGORY, SET_CATEGORY } from './actionTypes';
import { API_BASE_URL } from '../constants';
import { getHeaders, getHeadersFormData } from '../util/actionUtil';
import { hideLoader, showLoader } from './loaderActions';

export const getRegionAction = () => dispatch => {
  return fetch(`${API_BASE_URL}/regionList`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'SET_REGION', payload: body });
    })
    .catch(ex => {
      dispatch({ type: 'SET_REGION_ERROR', payload: ex });
    });
};

export const getTabCategorySliderResultAction = (cntry, id) => dispatch => {
  const apiUrl = `${API_BASE_URL}/category/${id}/slider/result?cntry=${cntry}`;

  dispatch({
    type: 'SET_SLIDER_RESULT_FETCHING',
    payload: true,
  });
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: 'SET_SLIDER_RESULT',
        payload: body,
      }),
    ) //include 400Error
    .catch(ex =>
      dispatch({
        type: 'SET_SLIDER_RESULT',
        payload: ex,
      }),
    );
};

export const getRegionPlatformAction = () => dispatch => {
  return fetch(`${API_BASE_URL}/regionPlatformList`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'SET_REGION_PLATFORM', payload: body });
    })
    .catch(ex => {
      dispatch({ type: 'SET_REGION_PLATFORM_ERROR', payload: ex });
    });
};

export const setParamAction = (deviceType, region, status, platformVersion) => dispatch => {
  dispatch({ type: 'SET_HOMEAPP_PARAM', payload: { deviceType: deviceType, region: region, status: status, platformVersion: platformVersion } });
};

export const setExpandTabAction = (key, value) => dispatch => {
  dispatch({ type: 'SET_HOMEAPP_TAB_EXPAND', payload: { key: key, value: value } });
};

export const resetExpandTabAction = () => dispatch => {
  dispatch({ type: 'RESET_HOMEAPP_TAB_EXPAND', payload: {} });
};

export const getTabCategoryAction = (deviceType, region, platformVersion, status) => dispatch => {
  if (!platformVersion) {
    platformVersion = '-';
  }
  return fetch(`${API_BASE_URL}/device/${deviceType}/region/${region}/platform/${platformVersion}/status/${status}/tab-category`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch({ type: 'SET_TAB_CATEGORY', payload: body }))
    .catch(ex => dispatch({ type: 'SET_TAB_CATEGORY_ERROR', payload: ex }));
};

export const getTabCategoryResultAction = (cntry, id) => dispatch => {
  const apiUrl = `${API_BASE_URL}/category/${id}/result?cntry=${cntry}`;

  dispatch({
    type: 'SET_MANUAL_RESULT_FETCHING',
    payload: true,
  });
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: 'SET_MANUAL_RESULT',
        payload: body,
      }),
    ) //include 400Error
    .catch(ex =>
      dispatch({
        type: 'SET_MANUAL_RESULT',
        payload: ex,
      }),
    );
};
export const copyTabCategoryAction = data => dispatch => {
  const tab = { ...data };
  return fetch(`${API_BASE_URL}/device/${tab.targetDeviceType}/region/${tab.targetRegion}/platform/${tab.targetVersion}/status/S/clone?cloneVersion=${tab.cloneVersion}`, {
    method: 'POST',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.result) {
        alert(body.message);
        if (body.result === 'success') {
          dispatch(getRegionPlatformAction());
        }
        return true;
      } else {
        alert('A server error has occurred. Contact the manager');
        return false;
      }
    })
    .catch(() => { });
};

export const addTabAction = data => dispatch => {
  const { imageData, ...rest } = { ...data };
  let formData = new FormData();
  formData.append('image', imageData);
  formData.append('data', JSON.stringify(rest));

  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/device/${rest.deviceType}/region/${rest.region}/platform/${rest.platformVersion}/status/S/tab`, {
    method: 'POST',
    headers: getHeadersFormData(),
    body: formData,
    // headers: getHeaders(),
    // body: JSON.stringify(tab),
  })
    .then(res => res.json())
    .then(body => {
      if (body.result) {
        if (body.result === 'success') {
          dispatch(getRegionPlatformAction());
          dispatch(setParamAction(rest.deviceType, rest.region, 'Saved', rest.platformVersion));
          dispatch(getTabCategoryAction(rest.deviceType, rest.region, rest.platformVersion, 'S'));
          dispatch(hideTabCreatePop());
          alert('Successfully! is registered.');
        } else {
          alert('A server error has occurred. Contact the manager');
        }
      } else {
        alert(body.message);
      }
    })
    .catch(() => { })
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const tabDisableAction = data => dispatch => {
  const tab = { ...data };
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/device/${tab.deviceType}/region/${tab.region}/platform/${tab.platformVersion}/status/tabCode/${tab.tabCode}?useFlag=${tab.useFlag}`, {
    method: 'PATCH',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.result) {
        if (body.result === 'success') {
          dispatch(getRegionPlatformAction());
          dispatch(setParamAction(tab.deviceType, tab.region, 'Saved', tab.platformVersion));
          dispatch(getTabCategoryAction(tab.deviceType, tab.region, tab.platformVersion, 'S'));
          //dispatch(hideTabCreatePop());
          alert('Successfully! is registered.');
        } else {
          alert('A server error has occurred. Contact the manager');
        }
      } else {
        alert(body.message);
      }
    })
    .catch(() => { })
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const tabDeleteAction = data => dispatch => {
  const tab = { ...data };
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/device/${tab.deviceType}/region/${tab.region}/platform/${tab.platformVersion}/status/tabCode/${tab.tabCode}`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.result) {
        if (body.result === 'success') {
          dispatch(getRegionPlatformAction());
          dispatch(setParamAction(tab.deviceType, tab.region, 'Saved', tab.platformVersion));
          dispatch(getTabCategoryAction(tab.deviceType, tab.region, tab.platformVersion, 'S'));
          alert('Successfully! is registered.');
        } else {
          alert('A server error has occurred. Contact the manager');
        }
      } else {
        alert(body.message);
      }
    })
    .catch(() => { })
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const tabDeletebyIdxAction = data => dispatch => {
  const tab = { ...data };
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/device/region/platform/tab/${tab.tabIdx}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(tab),
  })
    .then(res => res.json())
    .then(body => {
      if (body.result) {
        if (body.result === 'success') {
          dispatch(getRegionPlatformAction());
          dispatch(setParamAction(tab.deviceType, tab.region, 'Saved', tab.platformVersion));
          dispatch(getTabCategoryAction(tab.deviceType, tab.region, tab.platformVersion, 'S'));
          alert('Successfully! is registered.');
        } else {
          alert('A server error has occurred. Contact the manager');
        }
      } else {
        alert(body.message);
      }
    })
    .catch(() => { })
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const getCategoryRuleAction = id => dispatch => {
  return fetch(`${API_BASE_URL}/category/${id}/rule`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch({ type: 'SET_CATEGORY_RULE', payload: body }))
    .catch(ex => dispatch({ type: 'SET_CATEGORY_RULE_ERROR', payload: ex }));
};

export const getCountAction = (type, cntryList) => dispatch => {
  return fetch(`${API_BASE_URL}/count/${type}?cntry=${cntryList}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch({ type: `SET_COUNT_${type.toLocaleUpperCase()}`, payload: body }))
    .catch(() => { });
};

export const getSupportLanguagesAction = cntry => dispatch => {
  return fetch(`${API_BASE_URL}/supportLanguages?cntry=${cntry}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch({ type: 'SET_SUPPORT_LANGUAGES', payload: body }))
    .catch(() => { });
};

export const getServiceBaseDetailsAction = () => dispatch => {
  return fetch(`${API_BASE_URL}/serviceBaseDetails`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch({ type: 'SET_MONITOR_SERVICE_BASE_DATA', payload: body }))
    .catch(() => { });
};

export const getMonitorDataAction = (data, pageNo, rowPerPage) => dispatch => {
  pageNo = pageNo || 1;
  rowPerPage = rowPerPage || 10;
  let paramsData = {
    pageNo: pageNo * 1,
    rowPerPage: rowPerPage * 1,
  };

  dispatch({ type: 'SET_MONITOR_DATA_INIT' });
  return fetch(`${API_BASE_URL}/monitor?` + new URLSearchParams(paramsData), {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(body => {
      body.monitorList = body.monitorList ? body.monitorList : [];
      dispatch({ type: 'SET_MONITOR_DATA_SUCCESS', payload: body });
    })
    .catch(() => { });
};

export const getMonitorPreviewDataAction = (rowData, download) => dispatch => {
  dispatch({ type: 'SET_MONITOR_PREVIEW_DATA_INIT' });
  return fetch(`${API_BASE_URL}/monitorPreview?id=${rowData.id}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'SET_MONITOR_PREVIEW_DATA_SUCCESS', payload: body });

      if (body.status && download) {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(body.result)}`;
        const link = document.createElement('a');
        link.href = jsonString;
        link.download = `${rowData.channelMapId}-${rowData.crtDate}-data.json`;
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch(() => { });
};

//==== Tab categorySchedule Actions Start ====

export const getTabCategoryList = tabId => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/tab/${tabId}/categories`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status && body.message) {
        alert(body.message);
      } else {
        dispatch({ type: 'SET_TAB_CATEGORY_LIST', payload: body });
      }
    })
    .catch(() => { })
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const updateTabRandomStatus = (tabId, randomFlag, param) => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/tab/${tabId}/randomOrder?random=${randomFlag}`, {
    method: 'PATCH',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status && body.message) {
        alert(body.message);
      } else {
        dispatch(getTabCategoryAction(param.deviceType, param.region, param.platformVersion, param.status.charAt(0)));
      }
    })
    .catch(() => { })
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const updateCategoryFixStatus = (categoryId, fixFlag, tabId) => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/category/${categoryId}/fixOrder?fix=${fixFlag}`, {
    method: 'PATCH',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch(getTabCategoryList(tabId));
      }
    })
    .catch(() => { })
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const getCategoryScheduleList = categoryId => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/category/${categoryId}/schedule`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch({ type: 'SET_CATEGORY_SCHEDULE_LIST', payload: body });
      }
    })
    .catch(() => { })
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const addCategoryScheduleList = (categoryId, data) => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/category/${categoryId}/schedule`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch(getCategoryScheduleList(categoryId));
      }
    })
    .catch(() => { })
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const updateCategoryScheduleList = (categoryId, data) => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/category/${categoryId}/schedule`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch(getCategoryScheduleList(categoryId));
      }
    })
    .catch(() => { })
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const deleteCategoryScheduleList = (categoryId, scheduleId) => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/category/${categoryId}/schedule/${scheduleId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status) {
        alert(body.message);
      } else {
        dispatch(getCategoryScheduleList(categoryId));
      }
    })
    .catch(() => { })
    .finally(() => {
      dispatch(hideLoader());
    });
};

//==== Tab categorySchedule Actions End ====
export const showTabCreatePop = () => dispatch => {
  dispatch({
    type: 'TAB_ADD_OPEN',
  });
};

export const hideTabCreatePop = () => dispatch => {
  dispatch({
    type: 'TAB_ADD_CLOSE',
  });
};

export const updateTabOrder = (data, deviceType, region, platformVersion, status, required) => dispatch => {
  dispatch(showLoader());
  return fetch(`${API_BASE_URL}/tab/order`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(data, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (body.status && body.message) {
        alert(body.message);
      } else {
        dispatch(getRegionPlatformAction());
        dispatch(setParamAction(deviceType, region, 'Saved', platformVersion));
        dispatch(getTabCategoryAction(deviceType, region, platformVersion, 'S'));
        if (required) {
          alert('Successfully saved !');
        }
      }
    })
    .catch(() => { })
    .finally(() => {
      dispatch(hideLoader());
    });
};
export const getHomeAppGenreAction = () => dispatch => {
  return fetch(`${API_BASE_URL}/home-app/genres`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'SET_HOMEAPP_GENRE', payload: body });
    })
    .catch(ex => {
      alert(ex);
    });
};

export const getABTestTabCategoryAction = (deviceType, region, platformVersion) => dispatch => {
  if (!platformVersion) {
    platformVersion = '-';
  }
  return fetch(`${API_BASE_URL}/abtest/device/${deviceType}/region/${region}/platform/${platformVersion}/tab-category`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch({ type: 'SET_AB_TEST_TAB_CATEGORY', payload: body }))
    .catch(ex => dispatch({ type: 'SET_TAB_CATEGORY_ERROR', payload: ex }));
};

export const searchTbTests = (abTestTitle, startDate, endDate, tabCode, categoryCode, deviceType, region, platformVersion, status) => dispatch => {
  // dispatch(showLoader());
  region = typeof (region) === 'undefined' ? '' : region;
  return fetch(`${API_BASE_URL}/search/abtest?abTestTitle=${abTestTitle}&startDate=${startDate}&endDate=${endDate}&tabCodeName=${tabCode}&cateCodeName=${categoryCode}&deviceType=${deviceType}&region=${region}&platformVersion=${platformVersion}&status=${status}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'SET_AB_TEST_DETAILS', payload: body, });
    })
    .catch(() => { })
    .finally(() => {
      // dispatch(hideLoader());
    });
};
export const getSegment = (countryCode, platformVersion) => dispatch => {
  if (!platformVersion) {
    platformVersion = '-';
  }
  return fetch(`${API_BASE_URL}/abtest/countryCode/${countryCode}/platform/${platformVersion}/segment`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'SET_REGION_SEGMENT', payload: body });
    })
    .catch(ex => dispatch({ type: 'SET_TAB_CATEGORY_ERROR', payload: ex }));
};

export const endAbTest = id => dispatch => {
  return fetch(`${API_BASE_URL}/abtest/${id}/end`, {
    method: 'PATCH',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => { })
    .catch(() => { })
    .finally(() => {
      // dispatch(hideLoader());
    });
};

export const endANService = id => dispatch => {
  return fetch(`${API_BASE_URL}/anservice/${id}/end`, {
    method: 'PATCH',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => { })
    .catch(() => { })
    .finally(() => {
      // dispatch(hideLoader());
    });
};

export const setABTestParamAction = (deviceType, countryCode, region, platformVersion, status) => dispatch => {
  dispatch({ type: 'SET_HOMEAPP_ABTEST_PARAM', payload: { deviceType: deviceType, countryCode: countryCode, region: region, platformVersion: platformVersion, status: status } });
};

export const getABTest = id => dispatch => {
  return fetch(`${API_BASE_URL}/abtest/${id}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      //dispatch({ type: 'SET_REGION', payload: body });
      alert('Successfully updated !');
    })
    .catch(ex => {
      dispatch({ type: 'SET_REGION_ERROR', payload: ex });
    });
};

export const saveAnTestConfiguration = (addAbTestData, abTestTitle, startDt, endDt, tbCode, catCode, dType, regionLoad, pVersion, abStatus) => dispatch => {
  let apiUrl = `${API_BASE_URL}/saveAnTestConfiguration`;
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(addAbTestData, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (body.returnValue) {
        alert('Successfully saved !');
        dispatch(searchTbTests(abTestTitle, startDt, endDt,
          tbCode, catCode, dType, regionLoad, pVersion, abStatus));
        dispatch(hideLoader());
      } else {
        alert(body.message);
        dispatch(hideLoader());
      }
    })
    .catch(ex => dispatch({ type: 'SET_TAB_CATEGORY_ERROR', payload: ex })
      .finally(() => {
      }));
};

export const getAbTestResultAction = (id, segMentId) => dispatch => {
  const apiUrl = `${API_BASE_URL}/abtest/${id}/segment/${segMentId}/result`;

  dispatch({
    type: 'SET_MANUAL_RESULT_FETCHING',
    payload: true,
  });

  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: 'SET_MANUAL_RESULT',
        payload: body,
      }),
    ) //include 400Error
    .catch(ex =>
      dispatch({
        type: 'SET_MANUAL_RESULT',
        payload: ex,
      }),
    );
};

export const getAbTestDailyData = (abTestId, abtestSubid) => dispatch => {
  const apiUrl = `${API_BASE_URL}/abtest/dailydata/${abTestId}/subId/${abtestSubid}`;

  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: 'SET_DAILY_DATA',
        payload: body,
      }),
    ) //include 400Error
    .catch(ex =>
      dispatch({
        type: 'SET_DAILY_DATA',
        payload: ex,
      }),
    );
};

export const saveSegmentConfiguration = addSegmentData => dispatch => {
  let apiUrl = `${API_BASE_URL}/saveAnTestSegment`;
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(addSegmentData, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (body.returnValue) {
        alert('Successfully saved !');
        dispatch(hideLoader());
      } else {
        alert(body.message);
        dispatch(hideLoader());
      }
    })
    .catch(ex => dispatch({ type: 'SET_TAB_CATEGORY_ERROR', payload: ex }).finally(() => { }));
};

export const getSegmentDetails = async (abTestId) => {
  abTestId = encodeURIComponent(abTestId);
  const apiUrl = `${API_BASE_URL}/abtest/segmentList/${abTestId}`;
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  }).then(res => res.json());
  return response;
};

export const getServiceSegmentDetails = async (recmdSegmentId) => {
  recmdSegmentId = encodeURIComponent(recmdSegmentId);
  const apiUrl = `${API_BASE_URL}/anservice/segmentList/${recmdSegmentId}`;
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  }).then(res => res.json());
  return response;
};

export const updateSegmentAndResult = addSegmentData => dispatch => {
  let apiUrl = `${API_BASE_URL}/updateSegment`;
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(addSegmentData, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (body.returnValue) {
        alert('Successfully Updated !');
        dispatch(hideLoader());
      } else {
        alert(body.message);
        dispatch(hideLoader());
      }
    })
    .catch(ex => dispatch({ type: 'SET_TAB_CATEGORY_ERROR', payload: ex }).finally(() => { }));
};

export const getServiceDetails = async (anTestId, abTestSubId, abTestTitle, startDate, endDate, tabCode, categoryCode, deviceType, region, platformVersion, status) => {
  anTestId = encodeURIComponent(anTestId);
  abTestSubId = encodeURIComponent(abTestSubId);
  region = typeof (region) === 'undefined' ? '' : region;
  const apiUrl = `${API_BASE_URL}/antest/serviceList/${anTestId}/subId/${abTestSubId}?abTestTitle=${abTestTitle}&startDate=${startDate}&endDate=${endDate}&tabCodeName=${tabCode}&cateCodeName=${categoryCode}&deviceType=${deviceType}&region=${region}&platformVersion=${platformVersion}&status=${status}`;
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  }).then(res => res.json());
  return response;
};

export const updateANTestService = addSeviceData => dispatch => {
  let apiUrl = `${API_BASE_URL}/saveAnTestService`;
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(addSeviceData, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (body.returnValue) {
        alert('Successfully Updated !');
        dispatch(hideLoader());
      } else {
        alert(body.message);
        dispatch(hideLoader());
      }
    })
    .catch(ex => dispatch({ type: 'SET_TAB_CATEGORY_ERROR', payload: ex }).finally(() => { }));
};

export const getAbTestServiceResultAction = (id, segMentId) => dispatch => {

};

export const resetABTestTabCategoryAction = () => dispatch => {
  dispatch({ type: 'RESET_AB_TEST_TAB_CATEGORY' })
};

export const getANSErviceDailyData = (recmdSegmentId, recmdSegmentSubid) => dispatch => {
  const apiUrl = `${API_BASE_URL}/anservice/dailydata/${recmdSegmentId}/subId/${recmdSegmentSubid}`;

  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: 'SET_DAILY_DATA',
        payload: body,
      }),
    ) //include 400Error
    .catch(ex =>
      dispatch({
        type: 'SET_DAILY_DATA',
        payload: ex,
      }),
    );
};

export const searchANService = (abTestTitle, startDate, endDate, tabCode, categoryCode, deviceType, region, platformVersion, status) => dispatch => {
  // dispatch(showLoader());
  region = typeof (region) === 'undefined' ? '' : region;
  return fetch(`${API_BASE_URL}/search/snservice?abTestTitle=${abTestTitle}&startDate=${startDate}&endDate=${endDate}&tabCodeName=${tabCode}&cateCodeName=${categoryCode}&deviceType=${deviceType}&region=${region}&platformVersion=${platformVersion}&status=${status}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'SET_AN_SERVICE_DETAILS', payload: body, });
    })
    .catch(() => { })
    .finally(() => {
      // dispatch(hideLoader());
    });
};

export const addANServiceSegment = addSeviceData => dispatch => {
  let apiUrl = `${API_BASE_URL}/saveAnServiceSegment`;
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(addSeviceData, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (body.returnValue) {
        alert('Successfully Updated !');
        dispatch(hideLoader());
      } else {
        alert(body.message);
        dispatch(hideLoader());
      }
    })
    .catch(ex => dispatch({ type: 'SET_TAB_CATEGORY_ERROR', payload: ex }).finally(() => { }));
};

export const getAnServicetResultAction = (id, segMentId) => dispatch => {
  const apiUrl = `${API_BASE_URL}/anservice/${id}/segment/${segMentId}/result`;

  dispatch({
    type: 'SET_MANUAL_RESULT_FETCHING',
    payload: true,
  });

  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: 'SET_MANUAL_RESULT',
        payload: body,
      }),
    ) //include 400Error
    .catch(ex =>
      dispatch({
        type: 'SET_MANUAL_RESULT',
        payload: ex,
      }),
    );
};

export const updateServiceSegmentAndResult = addServiceSegmentData => dispatch => {
  let apiUrl = `${API_BASE_URL}/updateServiceSegment`;
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(addServiceSegmentData, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (body.returnValue) {
        alert('Successfully Updated !');
        dispatch(hideLoader());
      } else {
        alert(body.message);
        dispatch(hideLoader());
      }
    })
    .catch(ex => dispatch({ type: 'SET_TAB_CATEGORY_ERROR', payload: ex }).finally(() => { }));
};

export const deleteAbTest = id => dispatch => {
  return fetch(`${API_BASE_URL}/abtest/${id}/delete`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => { })
    .catch(() => { })
    .finally(() => {
      // dispatch(hideLoader());
    });
};

export const updateANTestSegmentValidityReason = validityReason => dispatch => {
  let apiUrl = `${API_BASE_URL}/antest/segment-validity-reason`;
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(validityReason, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (body.result) {
        alert('Successfully Updated !');
        dispatch(hideLoader());
      } else {
        alert(body.message);
        dispatch(hideLoader());
      }
    })
    .catch(ex => dispatch({ type: 'SET_TAB_CATEGORY_ERROR', payload: ex }).finally(() => { }));
}