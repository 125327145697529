import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import SideBar from './SideBar';
import ContentsArea from './ContentsArea';

import { ISS_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import Alert from '../modal/Alert';

const IssDashboard = ({ issueId }) => {
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');

  const [currentTab, setCurrentTab] = useState(0);
  const [currentFilter, setCurrentFilter] = useState({ country: '', provider: '', platform: '' });
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location && location.state) {
      const selectedLocation = JSON.parse(location.state.param);

      if (selectedLocation) {
        if (selectedLocation.type === 'Service') {
          setCurrentTab(0);
        } else if (selectedLocation.type === 'Content') {
          setCurrentTab(1);
        } else if (selectedLocation.type === 'Statistics') {
          setCurrentTab(2);
        } else if (selectedLocation.type === 'Advertisement') {
          setCurrentTab(3);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (issueId) {
      getIssue();
    }
  }, []);

  const moveToDetailPage = (item) => {
    if (item.issue_state === 'Resolve') {
      if (item.issue_type === 'content' && item.issue_id.startsWith('CN0')) {
        setAlertMessage('Group id [' + item.issue_id + '] has already been resolved.');
        onOpenAlert();
      } else {
        setAlertMessage('Issue code [' + item.issue_code + '] has already been resolved.');
        onOpenAlert();
      }
    } else {
      if (item.issue_type === 'content') {
        const category = item.issue_id.startsWith('CN0') ? 'cp' : item.issue_id.startsWith('CN1') ? 'admin' : item.issue_id.startsWith('CN2') ? 'batch' : 'device';

        let searchData;
        if (category === 'cp') {
          searchData = { category: category, groupId: item.issue_id };
        } else {
          searchData = { category: category, issueId: item.issue_id, issueCode: item.issue_code };
        }

        history.push('/ISS/ContentsDetail', {
          param: JSON.stringify(searchData),
        });
      } else if (item.issue_type === 'service') {
        let selectedData = { issueTab: item.issue_tab, issueId: item.issue_id, issueCode: item.issue_code };
        history.push('/ISS/ServiceDetail', {
          param: JSON.stringify(selectedData),
        });
      } else if (item.issue_type === 'statistics' || item.issue_type === 'beacon') {
        const searchData = { category: item.issue_type, issueId: item.issue_id, issueCode: item.issue_code };

        history.push('/ISS/StatisticsDetail', {
          param: JSON.stringify(searchData),
        });
      }
    }
  };

  const getIssue = () => {
    fetch(`${ISS_BASE_URL}/api/common/issue/search/${issueId}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          if (body.dataSet) {
            moveToDetailPage(body.dataSet);
          } else {
            setAlertMessage('Issue not found with this ID');
            onOpenAlert();
          }
        } else {
          setAlertMessage('[getIssue server error]');
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`getIssue : ${error.toString()}`);
        onOpenAlert();
      }).finally(() => { });
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  if (issueId) {
    return (
      <>
        {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
      </>
    );
  } else {
    return (
      <div className="iss-main">
        <div className="main-container">
          <div className="inner">
            <div className="contents-section-container">
              <SideBar currentTab={currentTab} changeTab={tab => setCurrentTab(tab)} currentFilter={currentFilter} changeFilter={filter => setCurrentFilter(filter)} />
              <ContentsArea currentTab={currentTab} changeFilter={filter => setCurrentFilter(filter)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default IssDashboard;
