///* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
//import { setConfMapFile } from '../../../actions/channelMappingActions';
function AddRow({ setAddRow, addRow, failmessage, onClickAddRow, configlist }) {
  // useEffect(() => {
  //   if (configlist) {
  //     configListSetting([...configlist]);
  //     //setOriginal(JSON.parse(JSON.stringify([...configlist])));
  //   }
  // }, [configlist]);
  useEffect(() => {
    if (failmessage) {
      openAlert(failmessage);
    }
  }, [failmessage]);
  // useEffect(() => {
  //   if (viewData) {
  //     let isFTP = !!viewData.feedType;
  //     if (!mapFile || !configlist) {
  //       //  이미 작업중인 데이터가 존재하지 않을때만 갱신
  //       setConfMapFile(isFTP, viewData.cntryCode);
  //       configListSetting(null);
  //     }
  //   }
  // }, []);
  // useEffect(() => {
  //   if (viewData) {
  //     let isFTP = !!viewData.feedType;
  //     // let mapFileName = mapFile.channelMapId || mapFile.confCode;
  //     // console.log(mapFile, mapFileName);
  //     if (!mapFile || !configlist) {
  //       //  이미 작업중인 데이터가 존재하지 않을때만 갱신
  //       setFTP(isFTP);
  //       setConfMapFile(isFTP, viewData.cntryCode);
  //       setMapFile(null);
  //     }
  //   }
  // }, [viewData]);
  //
  let disabled = !configlist;
  //const [originalData, setOriginal] = useState(null);
  const [alert, setAlert] = useState({ open: false, message: '' });
  const [mapFile, setMapFile] = useState(null);
  //const [configList, setConfigList] = useState(null);
  const [changed, setChanged] = useState(false);

  const configListSetting = target => {
    //setConfigList(target);
    isChangeFlag(target);
  };

  const isChangeFlag = target => {
    if (target) {
      let isChanged = !!target.filter(v => {
        return v.change;
      }).length;
      setChanged(isChanged);
    }
  };
  const openAlert = message => {
    setAlert({
      open: true,
      message: message,
    });
  };
  const onChangeAddRowInput = e => {
    let name = e.target.name.split('_')[1];
    let value = e.target.value;
    // validation 추가 필요
    setAddRow({ ...addRow, [name]: value });
  };

  return (
    <React.Fragment>
      <span className="channel_add_area_empty"></span>
      <TextField variant="outlined" name="add_channelNumber" disabled={disabled} value={addRow.channelNumber} className="channel_add1" onChange={onChangeAddRowInput} />
      <TextField variant="outlined" name="add_channelId" disabled={disabled} value={addRow.channelId} className="channel_add2" onChange={onChangeAddRowInput} />
      <TextField variant="outlined" name="add_channelName" disabled={disabled} className="channel_add3" value={addRow.channelName} onChange={onChangeAddRowInput} />
      <span className="btn_add">
        <Button className="btn_color4" disabled={disabled} onClick={() => onClickAddRow(addRow)}>
          Add
        </Button>
      </span>
    </React.Fragment>
  );
}
AddRow.propTypes = {
  sourceChannel: PropTypes.object,
  failmessage: PropTypes.string,
  onClickAddRow: PropTypes.func,
  addRow: PropTypes.object,
  setAddRow: PropTypes.func,
};
const mapStateToProps = state => ({
  failmessage: state.channelMapping.failmessage,
  users: state.currentUser,
  sourceChannel: state.channelMapping.sourceChannel,
  confMap: state.channelMapping.confMap,
  configlist: state.channelMapping.configlist,
  saved: state.channelMapping.saved,
});
// const mapDispatchToProps = dispatch => ({
//   setConfMapFile: (isFTP, cntryCode) => dispatch(setConfMapFile(isFTP, cntryCode)),
// });
export default connect(mapStateToProps)(AddRow);
