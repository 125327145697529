///* eslint-disable */
import React, { useState, Fragment, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormControl, Select, MenuItem, Chip, makeStyles, InputLabel } from '@material-ui/core';
import { Button, Checkbox, TextField, FormGroup, FormControlLabel } from '@material-ui/core';
import MaterialTable, { MTableBodyRow, MTableCell, MTableHeader } from 'material-table';
import PublishDialog from './PublishDialog';
import DownloadHistoryDialog from './DownloadHistoryDialog';
import { Dialog } from '@material-ui/core';
import { setChannelConf, doSaveConf, mergeChannelConf, getRsChannelIdsForAddButton, setShowColumnOnOFFTableData, getDmaGroupCodeList, getChannelMapInfoByChMapId, setChannelMapData, doReservationConf, getChannelMapReservationByChannelMapIdAndType, getReservationConf } from '../../../actions/channelMappingActions';
import { NoDataMap } from '../../../common/NoDataMap';
import AddRow from './AddRow';
import ReservationDialog from './ReservationDialog';
import DeleteIconOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import { request } from '../../../util/request';
import ViewColumnIcon from "@material-ui/icons/ViewColumn";

const today = new Date();
const yestreday = new Date();
const nextMonthDate = new Date();
yestreday.setDate(today.getDate() - 1);
nextMonthDate.setMonth(today.getMonth() + 1);
const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const channelStyle = {
  lineHeight: '1px',
  textAlign: 'left'
};
const sdKStyle = {
  color: 'blue',
  lineHeight: '1px',
  textAlign: 'left'
};

function SelectedChannelMap({
  system,
  ric,
  cntry,
  cp,
  openAlert,
  original,
  failmessage,
  doSaveConf,
  setChannelConf,
  mergeChannelConf,
  confMap,
  configlist,
  showLoadingIndicator,
  getDmaGroupCodeList,
  dmaGroupCodes,
  getChannelMapInfoByChMapId,
  sChannelMapData,
  cntryInfo,
  doReservationConf,
  channelMapReservationByIdType,
  getChannelMapReservationByChannelMapIdAndType,
  getReservationConf,
  channelMappedData,
  channelMapHiddenData,
  setShowColumnOnOFFTableData,
  selectedChannelMapColumnSize,
  getRsChannelIdsForAddButton,
  addRSChannelIds
}) {
  const saveTypes = [
    { value: 'T', label: 'Saved - Testing' },
    { value: 'P', label: 'Saved - Published' },
    { value: 'R-T', label: 'Reservation For Testing' },
    { value: 'R-P', label: 'Reservation For Published' },
  ];

  const action = [
    { value: 'add', label: 'Add' },
    { value: 'delete', label: 'Delete' },
  ];
  const hourFomatList = [
    { value: 0, label: '00:00' },
    { value: 1, label: '1:00' },
    { value: 2, label: '2:00' },
    { value: 3, label: '3:00' },
    { value: 4, label: '4:00' },
    { value: 5, label: '5:00' },
    { value: 6, label: '6:00' },
    { value: 7, label: '7:00' },
    { value: 8, label: '8:00' },
    { value: 9, label: '9:00' },
    { value: 10, label: '10:00' },
    { value: 11, label: '11:00' },
    { value: 12, label: '12:00' },
    { value: 13, label: '13:00' },
    { value: 14, label: '14:00' },
    { value: 15, label: '15:00' },
    { value: 16, label: '16:00' },
    { value: 17, label: '17:00' },
    { value: 18, label: '18:00' },
    { value: 19, label: '19:00' },
    { value: 20, label: '20:00' },
    { value: 21, label: '21:00' },
    { value: 22, label: '22:00' },
    { value: 23, label: '23:00' },
  ];

  const classes = useStyles();
  const [logoURL, setLogoURL] = React.useState(false);
  const [chNo, setChNo] = React.useState(false);
  const [chName, setChName] = React.useState(false);
  const [eventOn, setEventOn] = React.useState();
  const [hiddenOn, sethiddenOn] = React.useState(false);
  const [fieldName, setFieldName] = React.useState();
  const [logoUrlElements, setlogoUrlElements] = React.useState();
  const [channelNameElements, setchannelNameElements] = React.useState();
  const [channelNumberElements, setchannelNumberElements] = React.useState();
  const [modifiedActionColum, setModifiedActionColum] = React.useState(false);
  const [modifiedTPColum, setModifiedTPColum] = React.useState(true);
  const [addRSChannelId, setAddRSRSChannelId] = useState(false);

  React.useEffect(() => {
    const _logoUrlElements = document.getElementsByClassName('channel-map-table-logoUrl');
    setlogoUrlElements(_logoUrlElements);
    const _channelNameElements = document.getElementsByClassName('channel-map-table-channelName');
    setchannelNameElements(_channelNameElements);
    const _channelNumberElements = document.getElementsByClassName('channel-map-table-channelNumber');
    setchannelNumberElements(_channelNumberElements);
  }, [logoUrlElements, channelNameElements, channelNumberElements]);

  React.useEffect(() => {
    if (eventOn) {
      if (eventOn.field === 'logoUrl' || eventOn.field === 'channelNumber' || eventOn.field === 'channelName') {
        leftWidthSetForColumns();
        const fixedColumns = document.querySelectorAll("[style^='position: sticky; top: 0px; left:']");
        for (var i = 0; i < fixedColumns.length; i++) {
          new ResizeObserver(leftWidthSetForColumns).observe(fixedColumns[i]);
        }
      }
    }
  }, [chName, logoURL, chNo, eventOn, hiddenOn, fieldName]);

  React.useEffect(() => {
    if (configlist) isChangeFlag(configlist);
  }, [configlist]);
  React.useEffect(() => {
    if (failmessage) openAlert(failmessage);
  }, [failmessage]);
  React.useEffect(() => {
    configlistSetting(null);
  }, [cntry, cp]);
  React.useEffect(() => {
    configlistSetting(null);
    setChannelMapId('');
    setPublishStatus('');
  }, [cntry, cp]);

  React.useEffect(() => {
    setChannelMapId('');
    setPublishStatus('');
  }, [system, cntry]);

  const [channelMapGuide, setChannelMapGuide] = useState('');
  const [sdkGuide, setSdkGuide] = useState('');
  const [isChannelMapGuide, setIsChannelMapGuide] = useState(false);
  const [selectOption, setSelectOption] = React.useState('');
  const [channelMapId, setChannelMapId] = React.useState('');
  const [changed, setChanged] = React.useState(false);
  const [deleteIntermediate, setDeleteIntermediate] = React.useState(false);
  const [deleteAll, setDeleteAll] = React.useState(false);
  const [publishType, setPublishType] = React.useState('none');
  const [publishStatus, setPublishStatus] = React.useState('');
  const [order, setOrder] = React.useState({ key: '', type: '' });
  const [donwloadType, setDownloadType] = React.useState('S-T');
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [open, setOpen] = useState(false);
  const [addRow, setAddRow] = useState({
    logoUrl: '',
    channelId: '',
    channelName: '',
    channelNumber: '',
    channelGenreName: '',
    cpName: '',
    deleteFlag: 'N',
    change: true,
    addFlag: true,
    channelStatus: '',
    mediaStaticUrl: '',
  });
  const [reserveDate, setReserveDate] = React.useState('');
  const [reservationTime, setReservationTime] = React.useState('');
  const [reservedDateTime, setReservedDateTime] = React.useState();
  const [reservationId, setReservationId] = React.useState('');
  const [dateComp, setDateComp] = React.useState('');
  const [enableReservation, setEnableReservation] = React.useState(false);
  const [openReservation, setOpenReservation] = useState(false);
  const tableRef = React.useRef(null);

  const saveTypeList = [
    ['R', 'Ready'],
    ['D', 'Complete'],
    ['C', 'Cancel'],
    ['E', 'Error'],
  ];

  const saveTypeMap = new Map(saveTypeList);

  React.useEffect(() => {
    if (channelMapId && publishStatus) {
      showLoadingIndicator(true);
      let statusIs = publishStatus;
      if (publishStatus === 'R-T' || publishStatus === 'R-P') {
        getChannelMapReservationByChannelMapIdAndType(channelMapId, publishStatus);
        setReservedDateTime('');
        setReserveDate('');
        setReservationTime('');
        setReservationId('');
        statusIs = publishStatus === 'R-T' ? 'T' : 'P';
        setEnableReservation(false);
      } else {
        setChannelConf(system, channelMapId, cntry, ric, statusIs);
        sChannelMapData({ channelMapId, statusIs });
      }
      showLoadingIndicator(false);
      setOrder({ key: '', type: '' });
    }
  }, [channelMapId, publishStatus]);

  React.useEffect(() => {
    if (channelMapId && publishStatus && modifiedTPColum && (publishStatus === 'T' || publishStatus === 'P')) {
      setTimeout(() => {
        setModifiedTPColum(false);
      }, 1000);
    }
  }, [publishStatus]);

  React.useEffect(() => {
    setReservedDateTime('');
    setReserveDate('');
    setReservationTime('');
    setReservationId('');
  }, [channelMapReservationByIdType.reservedList]);

  React.useEffect(() => {
    if (cntry) {
      getDmaGroupCodeList(cntry);
    }
  }, [cntry]);
  const getDMAGroupCode = value => {
    let dmaGroupCode;
    if (!(value == null || value.length === 0)) {
      dmaGroupCode = dmaGroupCodes.find(x => x.dmaGroupCode === value);
    }
    return dmaGroupCode ? `${dmaGroupCode.dmaGroupName}` : '';
  };

  var scrollTimer = -1;

  function leftWidthSetForColumns() {
    if (scrollTimer != -1) clearTimeout(scrollTimer);

    const logoUrlElements = document.getElementsByClassName('channel-map-table-logoUrl');
    const channelNumberElements = document.getElementsByClassName('channel-map-table-channelNumber');
    const channelNameElements = document.getElementsByClassName('channel-map-table-channelName');
    const fixedColumns = document.querySelectorAll("[style^='position: sticky; top: 0px; left:']");

    if (logoUrlElements.length > 0) {
      let logoHeader = fixedColumns[0];
      let logoWidth = parseInt(logoHeader.style.width, 10);
      if (channelNumberElements.length > 0) {
        let chNoHeader = fixedColumns[1];
        chNoHeader.style.left = logoWidth + 'px';
        for (let i = 0; i < channelNumberElements.length; i++) {
          channelNumberElements[i].style.left = logoWidth + 'px';
        }

        if (channelNameElements.length > 0) {
          let chNameLeft = logoWidth + parseInt(chNoHeader.style.width, 10);
          let chNameHeader = fixedColumns[2];
          chNameHeader.style.left = chNameLeft + 'px';
          for (let i = 0; i < channelNameElements.length; i++) {
            channelNameElements[i].style.left = chNameLeft + 'px';
          }
        }
      } else if (channelNameElements.length > 0) {
        let chNameLeft = logoWidth;
        let chNameHeader = fixedColumns[1];
        chNameHeader.style.left = chNameLeft + 'px';
        for (let i = 0; i < channelNameElements.length; i++) {
          channelNameElements[i].style.left = chNameLeft + 'px';
        }
      }
    } else if (channelNumberElements.length > 0) {
      let chNoHeader = fixedColumns[0];
      chNoHeader.style.left = '0px';
      for (let i = 0; i < channelNumberElements.length; i++) {
        channelNumberElements[i].style.left = '0px';
      }

      if (channelNameElements.length > 0) {
        let chNameHeader = fixedColumns[1];
        let chNameLeft = parseInt(chNoHeader.style.width, 10);
        chNameHeader.style.left = chNameLeft + 'px';
        for (let i = 0; i < channelNameElements.length; i++) {
          channelNameElements[i].style.left = chNameLeft + 'px';
        }
      }
    } else if (channelNameElements.length > 0) {
      let chNameHeader = fixedColumns[0];
      chNameHeader.style.left = '0px';
      for (let i = 0; i < channelNameElements.length; i++) {
        channelNameElements[i].style.left = '0px';
      }
    }
  }

  const getDmaCodeName = dmaGroupCode => {
    return dmaGroupCodes.filter(item => item.dmaGroupCode === dmaGroupCode)[0].dmaGroupName;
  };

  const dmaCodeCustomSorting = {
    ascending: (a, b) => getDmaCodeName(a.dmaGroupCode).length - getDmaCodeName(b.dmaGroupCode).length,
    descending: (a, b) => getDmaCodeName(b.dmaGroupCode).length - getDmaCodeName(a.dmaGroupCode).length,
  };

  let editableColumns = [
    {
      title: 'logo',
      field: 'logoUrl',
      width: 100,
      draggable: false,
      render: rowData =>
        rowData.logoUrl ? (
          <span className="board_thumbnail">
            <img src={`${rowData.logoUrl}`} style={{ backgroundColor: '#3d3d3d', width: '100%', height: '100%' }} />
          </span>
        ) : (
          <span className="board_thumbnail no_img"></span>
        ),
      defaultSort: order.key === '0' ? order.type : '',
      headerStyle: {
        backgroundColor: '#fff !important',
        position: 'sticky',
        left: 0,
        zIndex: 999,
        maxWidth: 100,
      },
      cellStyle: {
        backgroundColor: '#fff !important',
        position: 'sticky',
        left: 0,
        zIndex: 11,
        maxWidth: 100,
      },
    },
    {
      title: 'Ch. No',
      field: 'channelNumber',
      width: 100,
      draggable: false,
      render: rowData => (
        <TextField
          variant="outlined"
          name="channelNumber"
          defaultValue={rowData.channelNumber}
          onBlur={e => {
            e.stopPropagation();
            onChanged(e, rowData);
          }}
        />
      ),
      defaultSort: order.key === '1' ? order.type : '',
      headerStyle: {
        backgroundColor: '#fff !important',
        left: '100px',
        position: 'sticky',
        zIndex: 999,
        maxWidth: 100,
      },
      cellStyle: {
        backgroundColor: '#FFF !important',
        position: 'sticky',
        left: '100px',
        zIndex: 11,
        maxWidth: 100,
      },
    },
    {
      title: 'Ch. Name',
      field: 'channelName',
      width: 250,
      draggable: false,
      render: rowData => {
        // return channelMapId === '3000TMS' ? (
        //   <TextField
        //     variant="outlined"
        //     name="channelNumber"
        //     defaultValue={rowData.channelName}
        //     onBlur={e => {
        //       e.stopPropagation();
        //       onChanged(e, rowData);
        //     }}
        //   />
        // ) :
        return <p style={{ wordBreak: 'break-all' }}>{rowData.channelName}</p>;
      },
      defaultSort: order.key === '3' ? order.type : '',
      headerStyle: {
        backgroundColor: '#fff !important',
        position: 'sticky',
        left: '200px',
        zIndex: 999,
        maxWidth: 250,
      },
      cellStyle: {
        backgroundColor: '#fff !important',
        position: 'sticky',
        left: '200px',
        zIndex: 11,
        maxWidth: 250,
      },
    },
    {
      title: 'Ch. ID',
      field: 'channelId',
      width: 210,
      render: rowData => <p style={{ wordBreak: 'break-all' }}>{rowData.channelId}</p>,
      defaultSort: order.key === '2' ? order.type : '',
    },
    {
      title: 'CP Name',
      field: 'cpName',
      width: 270,
      render: rowData => <p style={{ wordBreak: 'break-all' }}>{rowData.cpName}</p>,
      defaultSort: order.key === '4' ? order.type : '',
    },
    {
      title: 'Genre',
      field: 'channelGenreName',
      width: 200,
      render: rowData => {
        return <p style={{ wordBreak: 'break-all' }}>{rowData.channelGenreName}</p>;
      },
      defaultSort: order.key === '5' ? order.type : '',
    },
    {
      title: (
        <FormGroup>
          <FormControlLabel className="selectedChannelMap-deleteAll" id="selectedChannelMapdeleteAll" control={<Checkbox indeterminate={deleteIntermediate} onChange={e => selectDeleteAll(e)} style={{display: 'none'}} />} label="Delete" />
        </FormGroup>
      ),
      field: 'deleteFlag',
      width: 180,
      render: rowData => {
        return <Checkbox id={rowData.channelId} name="deleteFlag" onChange={e => onChangeDelete(e, rowData)} />;
      },
      defaultSort: order.key === '6' ? order.type : '',
      hidden: publishStatus && (publishStatus === 'R-T' || publishStatus === 'R-P'),
    },
    {
      title: 'Change',
      field: 'change',
      width: 170,
      render: rowData => (
        <p id={rowData.channelId + 'change'} style={{ wordBreak: 'break-all' }}>
          {rowData.change ? 'Y' : ''}
        </p>
      ),
      defaultSort: order.key === '7' ? order.type : '',
      hidden: publishStatus && (publishStatus === 'R-T' || publishStatus === 'R-P'),
    },
    {
      title: 'Modifier',
      field: 'modifier',
      width: 180,
      defaultSort: order.key === '8' ? order.type : '',
    },
    {
      title: 'DMA Group Name',
      field: 'dmaGroupName',
      width: 270,
      customSort: dmaCodeCustomSorting.ascending,
      render: rowData => (
        <div style={{ minWidth: '180px', maxWidth: '325px' }}>
          <Select name="dmaGroupCode" defaultValue={rowData.dmaGroupCode} onBlur={e => onChangeDMAGroupCode(e, rowData)}>
            <MenuItem value=""> Select DMA Name</MenuItem>
            {dmaGroupCodes.map(item => (
              <MenuItem key={item.dmaGroupCode} value={item.dmaGroupCode}>
                {/* {getDMACodeWithCity(item.dmaCode)} */}
                {`${item.dmaGroupName}`}
              </MenuItem>
            ))}
          </Select>
        </div>
      ),
    },
    {
      title: 'Ch. Status',
      field: 'channelStatus',
      width: 180,
      render: rowData => (
        <TextField
          variant="outlined"
          name="channelStatus"
          defaultValue={rowData.channelStatus}
          onBlur={e => {
            onChangeStatus(e, rowData);
          }}
        />
      ),
      defaultSort: order.key === '10' ? order.type : '',
    },
    {
      title: 'Last Chg Date',
      field: 'lastChgDate',
      width: 230,
      defaultSort: order.key === '11' ? order.type : '',
      hidden: publishStatus && (publishStatus === 'R-T' || publishStatus === 'R-P'),
    },
    {
      title: 'Action',
      field: 'action',
      hidden: publishStatus && (publishStatus === 'T' || publishStatus === 'P'),
      width: 250,
      render: rowData => (
        <div className="channel_conf_resrve_action">
          <Select name="action" value={rowData.action ? rowData.action : 'add'} onChange={e => onChangeAction(e, rowData)}>
            {action.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {`${item.label}`}
              </MenuItem>
            ))}
          </Select>
          <IconButton key="delete" aria-label="Delete" className={rowData.change} onClick={() => handleClickDelete(rowData)}>
            {' '}
            <DeleteIconOutlinedIcon className={classes.icon} />
            <span className="hide">삭제</span>
          </IconButton>
        </div>
      ),
    },
  ];

  let confHeight = configlist && configlist.length ? '876px' : '';
  let confMinHeight = configlist && configlist.length ? '800px' : '';
  const onChanged = (e, rowData) => {
    let newArr = [...configlist];
    let idx = rowData.tableData.id;
    newArr[idx]['channelNumber'] = isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    if (!rowData.addFlag) {
      rowData.channelNumber = parseInt(e.target.value);
      let index = original.findIndex(v => v.channelId === rowData.channelId);
      const originalChannelStatus = original[index].channelStatus || '';
      const rowDataChannelStatus = rowData.channelStatus || '';
      let compareChanStatus = originalChannelStatus !== rowDataChannelStatus;
      let compareChanNumber = original[index].channelNumber !== parseInt(e.target.value);
      let compareDeleteFlag = original[index].deleteFlag !== rowData.deleteFlag;
      let compareDma = original[index].dmaGroupCode !== rowData.dmaGroupCode;
      newArr[idx]['change'] = compareChanStatus || compareChanNumber || compareDeleteFlag || compareDma ? true : false;
      if (newArr[idx]['change']) {
        if (document.getElementById(rowData.channelId + 'change')) {
          rowData.change = true;
          document.getElementById(rowData.channelId + 'change').innerText = 'Y';
          document.getElementById(rowData.channelId + 'change').parentElement.parentElement.style = 'transition: all 300ms ease 0s; background-color: rgb(254, 245, 248); color: rgb(216, 41, 92);';
        }
      } else if (document.getElementById(rowData.channelId + 'change')) {
        rowData.change = false;
        document.getElementById(rowData.channelId + 'change').innerText = '';
        document.getElementById(rowData.channelId + 'change').parentElement.parentElement.style = 'transition: all 300ms ease 0s; background-color: rgb(255, 255, 255); color: rgb(72, 72, 72);';
      }

    }
    isChangeFlag([...configlist]);
  };
  const onChangeStatus = (e, rowData) => {
    let checkChannelStatus = rowData.channelStatus || '';
    if (checkChannelStatus == e.target.value) {
      return;
    }
    let newArr = [...configlist];
    let idx = rowData.tableData.id;
    newArr[idx]['channelStatus'] = e.target.value;
    rowData.channelStatus = e.target.value;
    if (!rowData.addFlag) {
      let index = original.findIndex(v => v.channelId === rowData.channelId);
      const originalChannelStatus = original[index].channelStatus || '';
      let compareChanStatus = originalChannelStatus !== e.target.value;
      let compareChanNumber = original[index].channelNumber !== rowData.channelNumber;
      let compareDeleteFlag = original[index].deleteFlag !== rowData.deleteFlag;
      let compareDma = original[index].dmaGroupCode !== rowData.dmaGroupCode;
      newArr[idx]['change'] = compareChanStatus || compareChanNumber || compareDeleteFlag || compareDma ? true : false;
      if (newArr[idx]['change']) {
        if (document.getElementById(rowData.channelId + 'change')) {
          rowData.change = true;
          document.getElementById(rowData.channelId + 'change').innerText = 'Y';
          document.getElementById(rowData.channelId + 'change').parentElement.parentElement.style = 'transition: all 300ms ease 0s; background-color: rgb(254, 245, 248); color: rgb(216, 41, 92);';
        }
      } else if (document.getElementById(rowData.channelId + 'change')) {
        rowData.change = false;
        document.getElementById(rowData.channelId + 'change').innerText = '';
        document.getElementById(rowData.channelId + 'change').parentElement.parentElement.style = 'transition: all 300ms ease 0s; background-color: rgb(255, 255, 255); color: rgb(72, 72, 72);';
      }
    }
    isChangeFlag([...configlist]);
  };

  const onChangeDMAGroupCode = (e, rowData) => {
    const { value } = e.target;
    let newValue = value;
    let newArr = [...configlist];
    let idx = rowData.tableData.id;
    if (!rowData.addFlag) {
      let index = original.findIndex(v => v.channelId === rowData.channelId);
      let compareDma = true;
      if (newArr[idx]['dmaGroupCode'] == value) {
        newValue = value;
        if (`${original[index].dmaGroupCode}`) {
          newArr[idx]['change'] = true;
        }
      } else {
        newArr[idx]['change'] = compareDma;
      }
      if (`${original[index].dmaGroupCode}` === newValue) {
        newArr[idx]['change'] = false;
        compareDma = false;
      }
      const originalChannelStatus = original[index].channelStatus || '';
      const rowDataChannelStatus = rowData.channelStatus || '';
      let compareChanStatus = originalChannelStatus !== rowDataChannelStatus;
      let compareChanNumber = original[index].channelNumber !== rowData.channelNumber;
      let compareDeleteFlag = original[index].deleteFlag !== rowData.deleteFlag;
      newArr[idx]['change'] = compareChanStatus || compareChanNumber || compareDeleteFlag || compareDma ? true : false;
      if (newArr[idx]['change']) {
        if (document.getElementById(rowData.channelId + 'change')) {
          rowData.change = true;
          document.getElementById(rowData.channelId + 'change').innerText = 'Y';
          document.getElementById(rowData.channelId + 'change').parentElement.parentElement.style = 'transition: all 300ms ease 0s; background-color: rgb(254, 245, 248); color: rgb(216, 41, 92);';
        }
      } else if (document.getElementById(rowData.channelId + 'change')) {
        rowData.change = false;
        document.getElementById(rowData.channelId + 'change').innerText = '';
        document.getElementById(rowData.channelId + 'change').parentElement.parentElement.style = 'transition: all 300ms ease 0s; background-color: rgb(255, 255, 255); color: rgb(72, 72, 72);';
      }
    }
    newArr[idx]['dmaGroupCode'] = newValue;
    isChangeFlag([...configlist]);
  };

  const configlistSetting = target => {
    mergeChannelConf(target);
    isChangeFlag(target);
    selectedChannelMapColumnSize();
  };

  const isChangeFlag = target => {
    if (target) {
      let isChanged = !!target.filter(v => v.change).length;
      setChanged(isChanged);
    }
  };
  // const onClickSearch = () => {
  //   if (channelMapId) setChannelConf(system, channelMapId, cntry, ric);
  // };

  const channelMapChange = value => {
    setChannelMapId(value);
    setDeleteAll(false);
    setDeleteIntermediate(false);
    if (value && publishStatus) {
      getSdkVersion(value, publishStatus);
    }
  };

  const publishStatusChange = value => {
    setPublishStatus(value);
    if (channelMapId && value) {
      getSdkVersion(channelMapId, value);
    }
  };

  const historyChange = value => {
    setDownloadType(value);
  };

  const historyStartDateChange = value => {
    setStartDate(value);
    setEndDate('');
  };

  const historyEndDateChange = value => {
    setEndDate(value);
  };

  const publishTypeListCDP = [
    { label: 'Save For Test', value: 'S-T' },
    { label: 'Save For Publish', value: 'S-P' },
    { label: 'Testing', value: 'T' },
    { label: 'Publish', value: 'P' },
  ];

  const publishTypeListSDP = [
    { label: 'Save', value: 'S' },
    { label: 'Publish', value: 'P' },
  ];

  const reservationStartDateChange = value => {
    channelMapReservationByIdType.reservedList
      .filter(k => k.idx == reservedDateTime)
      .forEach(m => {
        m.reservationDate == value + ' ' + (reservationTime > 9 ? reservationTime : '0' + reservationTime) + ':00:00' ? setChanged(false) : setChanged(true);
      });
    setReserveDate(value);
    setReservationTime('');
  };

  const reservationTimeChange = value => {
    channelMapReservationByIdType.reservedList
      .filter(k => k.idx == reservedDateTime)
      .forEach(m => {
        m.reservationDate == reserveDate + ' ' + (value > 9 ? value : '0' + value) + ':00:00' ? setChanged(false) : setChanged(true);
      });
    setReservationTime(value);
  };

  const onChangeDelete = (e, rowData) => {
    let cheked = e.target.checked;
    if (rowData.deleteFlag == 'Y') {
      rowData.deleteFlag = 'N';
      document.getElementById(rowData.channelId).setAttribute('checked', false);
    } else {
      rowData.deleteFlag = 'Y';
      document.getElementById(rowData.channelId).setAttribute('checked', true);
    }
    let newRow = Object.assign({}, rowData);
    newRow[e.target.name] = cheked ? 'Y' : 'N';
    newRow['change'] = rowData.addFlag || cheked;
    rowData['deleteFlag'] = cheked ? 'Y' : 'N';
    rowData['change'] = rowData.addFlag || cheked;
    if (cheked) {
      document.getElementById(rowData.channelId).parentElement.parentElement.classList.remove('MuiIconButton-colorSecondary');
      document.getElementById(rowData.channelId).parentElement.parentElement.classList.add('PrivateSwitchBase-checked-44', 'Mui-checked', 'MuiIconButton-colorSecondary');
      const svgCheckboxEle = document.getElementById(rowData.channelId).parentElement.getElementsByTagName('svg');
      svgCheckboxEle[0].innerHTML = '<path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>';
    } else {
      document.getElementById(rowData.channelId).parentElement.parentElement.classList.add('MuiIconButton-colorSecondary');
      document.getElementById(rowData.channelId).parentElement.parentElement.classList.remove('PrivateSwitchBase-checked-44', 'Mui-checked', 'MuiIconButton-colorSecondary');
      const svgCheckboxEle = document.getElementById(rowData.channelId).parentElement.getElementsByTagName('svg');
      svgCheckboxEle[0].innerHTML = '<path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>';
    }

    if (!rowData.addFlag) {
      let index = original.findIndex(v => v.channelId === rowData.channelId);
      const originalChannelStatus = original[index].channelStatus || '';
      const rowDataChannelStatus = rowData.channelStatus || '';
      let compareChanStatus = originalChannelStatus !== rowDataChannelStatus;
      let compareChanNumber = original[index].channelNumber !== rowData.channelNumber;
      let compareDeleteFlag = rowData.addFlag || cheked;
      let compareDma = original[index].dmaGroupCode !== rowData.dmaGroupCode;
      newRow['change'] = compareChanStatus || compareChanNumber || compareDeleteFlag || compareDma ? true : false;
      if (newRow['change']) {
        rowData.change = true;
        document.getElementById(rowData.channelId + 'change').innerText = 'Y';
        document.getElementById(rowData.channelId + 'change').parentElement.parentElement.style = 'transition: all 300ms ease 0s; background-color: rgb(254, 245, 248); color: rgb(216, 41, 92);';
      } else {
        rowData.change = false;
        document.getElementById(rowData.channelId + 'change').innerText = '';
        document.getElementById(rowData.channelId + 'change').parentElement.parentElement.style = 'transition: all 300ms ease 0s; background-color: rgb(255, 255, 255); color: rgb(72, 72, 72);';
      }
    }

    configlist[rowData.tableData.id] = newRow;
    const deletedTure = configlist.filter(config => config.deleteFlag == 'Y');
    let isAllSelectedTemp;
    let isDeleteIntermediate;
    if (deletedTure.length == 0) {
      isDeleteIntermediate = false;
      isAllSelectedTemp = false;
    } else if (configlist.length > deletedTure.length) {
      isDeleteIntermediate = true;
      isAllSelectedTemp = false;
    } else if (configlist.length == deletedTure.length) {
      isDeleteIntermediate = false;
      isAllSelectedTemp = true;
    }
    setDeleteIntermediate(isDeleteIntermediate);
    setDeleteAll(isAllSelectedTemp);
    document.getElementById('selectedChannelMapdeleteAll').getElementsByTagName('input')[0].checked = isAllSelectedTemp;
    if (isAllSelectedTemp) {
      document.getElementById('selectedChannelMapdeleteAll').parentElement.parentElement.parentElement.classList.add('MuiTableSortLabel-active');
      document.getElementById('selectedChannelMapdeleteAll').firstElementChild.classList.add('PrivateSwitchBase-checked-44', 'Mui-checked');
      const svgCheckboxEle = document.getElementById('selectedChannelMapdeleteAll').getElementsByTagName('svg');
      svgCheckboxEle[0].innerHTML = '<path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>';
    } else {
      document.getElementById('selectedChannelMapdeleteAll').parentElement.parentElement.parentElement.classList.remove('MuiTableSortLabel-active');
      document.getElementById('selectedChannelMapdeleteAll').firstElementChild.classList.remove('PrivateSwitchBase-checked-44', 'Mui-checked');
      const svgCheckboxEle = document.getElementById('selectedChannelMapdeleteAll').getElementsByTagName('svg');
      svgCheckboxEle[0].innerHTML = '<path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>';
    }
    // configlistSetting([...configlist]);
    isChangeFlag([...configlist]);
  };
  const selectDeleteAll = e => {
    let cheked = e.target.checked;
    let newConfList = configlist.map(config => {
      config['deleteFlag'] = cheked ? 'Y' : 'N';
      config['change'] = config.addFlag || cheked;
      document.getElementById(config.channelId).checked = cheked;
      document.getElementById(config.channelId + 'change');
      if (cheked) {
        document.getElementById(config.channelId).parentElement.parentElement.classList.remove('MuiIconButton-colorSecondary');
        document.getElementById(config.channelId).parentElement.parentElement.classList.add('PrivateSwitchBase-checked-44', 'Mui-checked', 'MuiIconButton-colorSecondary');
        const svgCheckboxEle = document.getElementById(config.channelId).parentElement.getElementsByTagName('svg');
        svgCheckboxEle[0].innerHTML = '<path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>';
      } else {
        document.getElementById(config.channelId).parentElement.parentElement.classList.add('MuiIconButton-colorSecondary');
        document.getElementById(config.channelId).parentElement.parentElement.classList.remove('PrivateSwitchBase-checked-44', 'Mui-checked', 'MuiIconButton-colorSecondary');
        const svgCheckboxEle = document.getElementById(config.channelId).parentElement.getElementsByTagName('svg');
        svgCheckboxEle[0].innerHTML = '<path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>';
      }
      return config;
    });
    let newArr = [...newConfList];
    newArr.map(rowData => {
      if (!rowData.addFlag) {
        let index = original.findIndex(v => v.channelId === rowData.channelId);
        const originalChannelStatus = original[index].channelStatus || '';
        const rowDataChannelStatus = rowData.channelStatus || '';
        let compareChanStatus = originalChannelStatus !== rowDataChannelStatus;
        let compareChanNumber = original[index].channelNumber !== rowData.channelNumber;
        let compareDeleteFlag = rowData.addFlag || cheked;
        let compareDma = original[index].dmaGroupCode !== rowData.dmaGroupCode;
        newArr['change'] = compareChanStatus || compareChanNumber || compareDeleteFlag || compareDma ? true : false;
        if (newArr['change']) {
          rowData.change = true;
          document.getElementById(rowData.channelId + 'change').innerText = 'Y';
          document.getElementById(rowData.channelId + 'change').parentElement.parentElement.style = 'transition: all 300ms ease 0s; background-color: rgb(254, 245, 248); color: rgb(216, 41, 92);';
        } else {
          rowData.change = false;
          document.getElementById(rowData.channelId + 'change').innerText = '';
          document.getElementById(rowData.channelId + 'change').parentElement.parentElement.style = 'transition: all 300ms ease 0s; background-color: rgb(255, 255, 255); color: rgb(72, 72, 72);';
        }
      }
    });
    isChangeFlag([...newConfList]);
    if (cheked) {
      document.getElementById('selectedChannelMapdeleteAll').parentElement.parentElement.parentElement.classList.add('MuiTableSortLabel-active');
      document.getElementById('selectedChannelMapdeleteAll').firstElementChild.classList.add('PrivateSwitchBase-checked-44', 'Mui-checked');
      const svgCheckboxEle = document.getElementById('selectedChannelMapdeleteAll').getElementsByTagName('svg');
      svgCheckboxEle[0].innerHTML = '<path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>';
    } else {
      document.getElementById('selectedChannelMapdeleteAll').parentElement.parentElement.parentElement.classList.remove('MuiTableSortLabel-active');
      document.getElementById('selectedChannelMapdeleteAll').firstElementChild.classList.remove('PrivateSwitchBase-checked-44', 'Mui-checked');
      const svgCheckboxEle = document.getElementById('selectedChannelMapdeleteAll').getElementsByTagName('svg');
      svgCheckboxEle[0].innerHTML = '<path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>';
    }
    setDeleteAll(cheked);
    setDeleteIntermediate(false);
    // configlistSetting([...newConfList]);
  };

  const isEmpty = addRow => {
    if (!addRow.channelNumber) {
      openAlert('Enter channel number.');
      return false;
    }
    if (!addRow.channelId) {
      openAlert('Enter channel ID.');
      return false;
    }
    if (!addRow.channelName) {
      openAlert('Enter channel name.');
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    if (addRSChannelId) {
      if (addRSChannelIds.length) {
        let existNumber = configlist && configlist.filter(v => v.deleteFlag !== 'Y' && addRow.channelNumber == v.channelNumber);
        if (existNumber && existNumber.length) {
          openAlert('There are duplicate channel numbers.');
          return;
        }
        addRow['change'] = true;
        addRow['addFlag'] = true;
        addRow['deleteFlag'] = 'N';
        configlist.push(addRow);
        configlistSetting([...configlist]);
        setAddRow({ addFlag: true, logoUrl: '', channelId: '', channelName: '', channelNumber: '', channelGenreName: '', deleteFlag: 'N', change: true });
      } else {
        openAlert('Channel information is not available in the target region.');
        return;
      }

    }
  }, [addRSChannelIds]);

  const onClickAddRow = addRow => {
    let empty = isEmpty(addRow);
    if (!empty) return;
    let existCode = configlist.filter(v => v.deleteFlag !== 'Y' && addRow.channelId == v.channelId);
    if (existCode.length) {
      openAlert('There are duplicate channel IDs.');
      return;
    }
    let existNumber = configlist.filter(v => v.deleteFlag !== 'Y' && addRow.channelNumber == v.channelNumber);
    if (existNumber.length) {
      openAlert('There are duplicate channel numbers.');
      return;
    }
    // Check chnannel number is present in RS table or not
    const channelIds = Array.isArray(addRow.channelId) ? addRow.channelId : [addRow.channelId];
    getRsChannelIdsForAddButton(cntry, channelIds);
    setAddRSRSChannelId(true);
  };
  const isDuplicateId = () => {
    return configlist.some(v => {
      let exist = configlist.filter(item => item.deleteFlag !== 'Y' && v.channeld === item.channelId);
      return exist.length >= 2;
    });
  };
  const isDuplicateNum = () => {
    return configlist.some(v => {
      let exist = configlist.filter(item => item.deleteFlag !== 'Y' && v.channelNumber == item.channelNumber);
      return exist.length >= 2;
    });
  };

  const getDmaGroupCodeResultList = () => {
    return configlist.reduce((ac, a) => {
      const findChannelNumber = ac.find(n => n.channelNumber === a.channelNumber);
      const dmaGroupCodeList = a.dmaGroupCode ? [...a.dmaGroupCode.split(',')] : [];
      if (findChannelNumber) {
        findChannelNumber.dmaGroupCodeList = [...findChannelNumber.dmaGroupCodeList, ...dmaGroupCodeList];
      } else {
        ac = [...ac, { channelNumber: a.channelNumber, dmaGroupCodeList: [...dmaGroupCodeList] }];
      }
      return ac;
    }, []);
  };

  const hasDuplicates = arr => arr.length !== new Set(arr).size;
  const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);

  const getDuplicateDMAGroupCodeChannels = () => {
    return getDmaGroupCodeResultList().filter(x => {
      if (hasDuplicates(x.dmaGroupCodeList)) {
        return x;
      }
    });
  };

  const getDuplicateDMAGroupCodeChannelsText = () => {
    return getDuplicateDMAGroupCodeChannels()
      .map(function (channelObj) {
        return '<span class="channel_dmacodes">' + 'Ch. No - ' + channelObj.channelNumber + ' DMA Group Names (' + findDuplicates(channelObj.dmaGroupCodeList) + ')' + '</span>';
      })
      .join('');
  };

  const isDuplicateDMAGroupCodes = () => {
    const checkDulicatesList = getDmaGroupCodeResultList().map(x => hasDuplicates(x.dmaGroupCodeList));
    return checkDulicatesList.includes(true);
  };

  const isEmptyNum = () => {
    return configlist.some(v => {
      return v.deleteFlag !== 'Y' && !v.channelNumber;
    });
  };
  const isOK = () => {
    if (isEmptyNum()) {
      openAlert('There are empty channel numbers. Fill out fields.');
      return false;
    }
    if (isDuplicateId()) {
      openAlert('There are duplicate channel IDs.');
      return false;
    }
    // if (isDuplicateNum()) {
    //   openAlert('There are duplicate channel numbers.');
    //   return false;
    // }
    if (isDuplicateDMAGroupCodes()) {
      openAlert(`Duplicate DMA Group Name found in same channel numbers ${getDuplicateDMAGroupCodeChannelsText()}`);
      return false;
    }
    return true;
  };

  const onSave = () => {
    const result = isOK();
    if (result) {
      let data = configlist.filter(n => n.deleteFlag !== 'Y');
      data = data.map(({ channelNumber, channelId, dmaGroupCode, channelStatus, mediaStaticUrl }) => ({ channelNumber, channelId, dmaGroupCode, channelStatus, saveType: publishStatus, mediaStaticUrl }));
      doSaveConf(system, ric, cntry, channelMapId, data, publishStatus);
      setDeleteAll(false);
      setDeleteIntermediate(false);
    }
  };

  const modifyColumns = () => {
    const modifiedColumns = [...editableColumns];
    const modifiedHiddenColumns = channelMapHiddenData.length > 0 ? [...channelMapHiddenData] : [];
    for (const filteredColumn of modifiedHiddenColumns) {
      if (publishStatus === 'T' || publishStatus === 'P') {
        if (filteredColumn.field === 'deleteFlag' || filteredColumn.field === 'change' || filteredColumn.field === 'lastChgDate') {
          let matchingColumnIndex = modifiedColumns.findIndex(col => col.field === filteredColumn.field);
          modifiedColumns[matchingColumnIndex].hidden = modifiedTPColum ? false : filteredColumn.hidden;
          modifiedColumns[matchingColumnIndex].hiddenByColumnsButton = modifiedTPColum ? false : filteredColumn.hidden;
        } else if (filteredColumn.field !== 'action') {
          let matchingColumnIndex = modifiedColumns.findIndex(col => col.field === filteredColumn.field);
          if (matchingColumnIndex !== -1) {
            modifiedColumns[matchingColumnIndex].hidden = filteredColumn.hidden;
            modifiedColumns[matchingColumnIndex].hiddenByColumnsButton = filteredColumn.hidden;
          }
        }
      } else if (publishStatus === 'R-T' || publishStatus === 'R-P') {
        if (filteredColumn.field === 'action') {
          let matchingColumnIndex = modifiedColumns.findIndex(col => col.field === filteredColumn.field);
          modifiedColumns[matchingColumnIndex].hidden = modifiedActionColum ? false : filteredColumn.hidden;
          modifiedColumns[matchingColumnIndex].hiddenByColumnsButton = modifiedActionColum ? false : filteredColumn.hidden;
          setModifiedActionColum(false);
        } else if (filteredColumn.field !== 'deleteFlag' && filteredColumn.field !== 'change' && filteredColumn.field !== 'lastChgDate') {
          let matchingColumnIndex = modifiedColumns.findIndex(col => col.field === filteredColumn.field);
          if (matchingColumnIndex !== -1) {
            modifiedColumns[matchingColumnIndex].hidden = filteredColumn.hidden;
            modifiedColumns[matchingColumnIndex].hiddenByColumnsButton = filteredColumn.hidden;
          }
        }
      }
    }
    return modifiedColumns;
  };

  const MTable = useMemo(() => {
    const handleViewColumnClick = () => {
      const columnName = tableRef.current ? tableRef.current.state.columns : '';
      const mainColumns = [...columnName];
      const modifiedHiddenColumns = channelMapHiddenData.length > 0 ? [...channelMapHiddenData] : [];
      if (publishStatus === 'R-T' || publishStatus === 'R-P') {
        for (const filteredColumn of modifiedHiddenColumns) {
          if (filteredColumn.field === 'deleteFlag' || filteredColumn.field === 'change' || filteredColumn.field === 'lastChgDate') {
            let matchingColumnIndex = mainColumns.findIndex(col => col.field === filteredColumn.field);
            if (matchingColumnIndex !== -1) {
              mainColumns[matchingColumnIndex] = { ...mainColumns[matchingColumnIndex], ...filteredColumn };
            }
          }
        }
        setShowColumnOnOFFTableData([...mainColumns]);
      } else if (publishStatus === 'T' || publishStatus === 'P') {
        if (modifiedHiddenColumns.length === 0) {
          setModifiedActionColum(true);
        }
        for (const filteredColumn of modifiedHiddenColumns) {
          if (filteredColumn.field === 'action') {
            let matchingColumnIndex = mainColumns.findIndex(col => col.field === filteredColumn.field);
            if (matchingColumnIndex !== -1) {
              mainColumns[matchingColumnIndex] = { ...mainColumns[matchingColumnIndex], ...filteredColumn };
            }
          }
        }
        setShowColumnOnOFFTableData([...mainColumns]);
      }
    };

    function assignResizeEventToFixedColumn() {
      const fixedColumns = document.querySelectorAll("[style^='position: sticky; top: 0px; left:']");
      for (var i = 0; i < fixedColumns.length; i++) {
        new ResizeObserver(leftWidthSetForColumns).observe(fixedColumns[i]);
      }
      selectedChannelMapColumnSize();
    }

    return (
      <MaterialTable
        title=""
        tableRef={tableRef}
        columns={configlist ? (channelMapHiddenData.length > 0 ? modifyColumns() : editableColumns) : []}
        data={configlist || []}
        onOrderChange={(key, type) => {
          setOrder({ key: `${key}`, type });
        }}
        isLoading={configlist && configlist.length > 0 && assignResizeEventToFixedColumn()}
        options={{
          showTextRowsSelected: false,
          isLoading: true,
          search: false,
          paging: false,
          maxBodyHeight: confHeight,
          minBodyHeight: confMinHeight,
          showEmptyDataSourceMessage: false,
          columnsButton: true,
          //columnResizable: true,
          headerStyle: {
            backgroundColor: '#fff',
            position: 'sticky',
            top: 0,
          },
          tableLayout: 'fixed',
          rowStyle: rowData => ({
            backgroundColor: rowData.change ? '#fef5f8' : '#FFF',
            color: rowData.change ? '#d8295c' : '#484848',
          }),
        }}
        icons={{
          ViewColumn: props => <ViewColumnIcon {...props} onClick={handleViewColumnClick} />,
        }}
        onChangeColumnHidden={event => {
          setEventOn(event);
          sethiddenOn(event.hiddenByColumnsButton);
          setFieldName(Math.random());
        }}
        components={{
          Row: props => {
            return <MTableBodyRow id={`channel-map-table-${props.index}`} className={`channel-map-table-${props.index}`} {...props} />;
          },
          Cell: props => {
            return <MTableCell id={`channel-map-table-${props.columnDef.field}`} className={`channel-map-table-${props.columnDef.field}`} {...props} />;
          },
        }}
      />
    );
  }, [configlist, original]);

  const onReservation = () => {
    let newArr = [...configlist];
    if (newArr.length == 0) {
      openAlert('Please add channels for mapping!');
      return;
    }
    const result = isOK();
    if (result) {
      if (!reserveDate) {
        openAlert('Please select the Reservation Date');
        return false;
      }
      if (reservationTime === '') {
        openAlert('Please select the Reservation Time');
        return false;
      }
      let data = configlist.filter(n => n.deleteFlag !== 'Y');
      data = data.map(({ channelNumber, channelId, dmaGroupCode, channelStatus, action, mediaStaticUrl }) => ({ channelNumber, channelId, dmaGroupCode, channelStatus, action, saveType: publishStatus, mediaStaticUrl }));
      let dateTime = reservationTime > 9 ? reserveDate + ' ' + reservationTime + ':00:00' : reserveDate + ' 0' + reservationTime + ':00:00';
      doReservationConf(system, ric, cntry, channelMapId, data, publishStatus, dateTime, reservationId === 'Select Existing Reservation' ? '' : reservationId);
      setDeleteAll(false);
      setDeleteIntermediate(false);
    }
  };

  const onChangeAction = (e, rowData) => {
    let newArr = [...configlist];
    let idx = rowData.tableData.id;
    newArr[idx]['action'] = e.target.value;
    if (!rowData.addFlag) {
      let index = original.findIndex(v => v.channelId === rowData.channelId);
      let compare = `${original[index].action}` !== e.target.value;
      newArr[idx]['change'] = compare;
    }
    mergeChannelConf(newArr);
    selectedChannelMapColumnSize();
  };

  const handleClickDelete = rowData => {
    let idx = rowData.tableData.id;
    configlist.splice(idx, 1);
    mergeChannelConf([...configlist]);
  };

  const reservedDateTimeChange = value => {
    setDateComp('');
    channelMapReservationByIdType.reservedList
      .filter(k => k.idx == value)
      .forEach(m => {
        setReserveDate(m.reservationDate.split(' ')[0]);
        setReservationTime(m.reservationTime);
        setDateComp(m.reservationDate);
        setEnableReservation(m.reservationStatus != 'R');
      });

    setReservedDateTime(value);
    showLoadingIndicator(true);
    setReservationId(value);
    getReservationConf(system, channelMapId, cntry, ric, publishStatus, value);
    sChannelMapData({ channelMapId, publishStatus });
    showLoadingIndicator(false);
    setOrder({ key: '', type: '' });
    if (value === 'Select Existing Reservation') {
      setReservedDateTime('');
      setReserveDate('');
      setReservationTime('');
      setReservationId('');
    }
  };

  const onReservationList = () => {
    setOpenReservation(true);
  };

  const newReservation = () => {
    showLoadingIndicator(true);
    getChannelMapReservationByChannelMapIdAndType(channelMapId, publishStatus);
    setReservedDateTime('');
    setReserveDate('');
    setReservationTime('');
    setReservationId('');
    setEnableReservation(false);
    showLoadingIndicator(false);
    setOrder({ key: '', type: '' });
  };

  React.useEffect(() => {
    if (channelMapId && publishStatus && channelMapGuide != '' && sdkGuide != '') {
      setIsChannelMapGuide(true);
    } else {
      setIsChannelMapGuide(false);
    }
  }, [channelMapId, publishStatus]);

  const getSdkVersion = async (channelMapId, status) => {
    if (status.indexOf('T') > -1) {
      status = 'T';
    } else if (status.indexOf('P') > -1) {
      status = 'P';
    } else {
      return;
    }
    const result = await request(`/api/admin/sdk-version?device=tv&channelMapId=${channelMapId}&status=${status}&country=${cntry}`, 'GET', null, true);
    const text = { fromVersion: '', toVersion: '', isLastVersion : false };
    let serviceDevice = '';
    let texList = [];
    if (result.result === 'ok') {
      result.info
        .filter(sdk => sdk.channelMapId === channelMapId)
        .map(sdk => {
          serviceDevice = sdk.serviceDevice;
          if (text.fromVersion === '') {
            text.fromVersion = sdk.fromVersion;
            text.toVersion = sdk.toVersion;
            text.isLastVersion = sdk.rowNumber === 1;
          } else if (text.fromVersion === sdk.toVersion) {
            text.fromVersion = sdk.fromVersion;
          } else {
            texList.push({ fromVersion: text.fromVersion, toVersion: text.toVersion, isLastVersion: text.isLastVersion });
            text.fromVersion = sdk.fromVersion;
            text.toVersion = sdk.toVersion;
            text.isLastVersion = sdk.rowNumber === 1;
          }
        });
      if (text.fromVersion !== '') {
        texList.push(text);
      }
      if (texList.length > 0){
        let versionList = '';
        texList.map(m =>{
          if (m.isLastVersion){
            versionList = `(${m.fromVersion} ~ )`;
          } else {
            versionList = `(${m.fromVersion} ~ ${m.toVersion})`.concat(versionList !== '' ? ', ' : '').concat(versionList);
          }
        });
        setSdkGuide(`platform version ${versionList} .`);
        let guideText = `This channel map will be applied to [${serviceDevice}]`;
        setIsChannelMapGuide(true);
        setChannelMapGuide(guideText);
      } else {
        setIsChannelMapGuide(false);
        setChannelMapGuide('');
        setSdkGuide('');
      }
    } else {
      setIsChannelMapGuide(false);
      setChannelMapGuide('');
      setSdkGuide('');
    }
  };
  return (
    <React.Fragment>
      <div
        //className={configlist ? (publishStatus && (publishStatus === 'R-T' || publishStatus === 'R-P') ? 'tbl_wrap channel_reserve_conf_table tbl_head_fixed channel_conf tbl_no_data tbl_wrap_on_off_reservation tbl_wrap_on_off_sdk' : 'tbl_wrap channel_conf_table tbl_head_fixed channel_conf tbl_no_data tbl_wrap_on_off tbl_wrap_on_off_sdk') : 'tbl_wrap tbl_head_fixed channel_conf channel_conf_nodata tbl_no_data tbl_wrap_on_off'}>
        className={
          configlist
            ? publishStatus && (publishStatus === 'R-T' || publishStatus === 'R-P')
              ? 'tbl_wrap channel_reserve_conf_table tbl_head_fixed channel_conf tbl_no_data tbl_wrap_on_off_reservation tbl_wrap_on_off_sdk'
              : isChannelMapGuide
                ? 'tbl_wrap channel_conf_table tbl_head_fixed channel_conf tbl_no_data tbl_wrap_on_off tbl_wrap_on_off_sdk'
                : 'tbl_wrap channel_conf_table tbl_head_fixed channel_conf tbl_no_data tbl_wrap_on_off'
            : 'tbl_wrap tbl_head_fixed channel_conf channel_conf_nodata tbl_no_data tbl_wrap_on_off'
        }>
        {/* <span> */}
        <div className="mapping_tbl_header">
          <h6 className="MuiTypography-root MuiTypography-h6">Selected Channel Map</h6>
          <p className="showing_count">
            Showing all <span>{(configlist && configlist.length) || 0}</span>
          </p>
          {/* <div className="sel_file_name"> */}
          {/* <FormControl variant="outlined" style={{ display: 'block', minWidth: 590 }}> */}
          <FormControl variant="outlined" style={{ minWidth: '100%', display: 'block', marginBottom: '1rem' }}>
            {/* <Select value={channelMapId} onChange={e => setChannelMapId(e.target.value)} style={{ display: 'block' }}> */}
            <Select style={{ width: '60%' }} value={channelMapId} onChange={e => channelMapChange(e.target.value)}>
              {confMap.mapList.map(item => (
                <MenuItem key={item.channelMapId} value={item.channelMapId}>
                  {item.channelMapId}
                </MenuItem>
              ))}
            </Select>
            {
              <Fragment>
                <Select style={{ width: '35%', marginLeft: '30px' }} value={publishStatus} onChange={e => publishStatusChange(e.target.value)}>
                  {[...saveTypes].map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </Fragment>
            }
            {isChannelMapGuide && (publishStatus === 'T' || publishStatus === 'P') && (
              <p className="login_comment" style={{ marginTop: 20 }}>
                <h6 style={channelStyle}>{channelMapGuide}</h6>
                <br />
                <h6 style={sdKStyle}>{sdkGuide}</h6>
              </p>
            )}
            {publishStatus && (publishStatus === 'R-T' || publishStatus === 'R-P') && (
              <FormControl variant="outlined" style={{ minWidth: '230px', marginTop: '10px' }} className="filter_area">
                <InputLabel>Select Existing Reservation</InputLabel>
                <Select id="reservedDateTime" name="reservedDateTime" label="reservedDateTime" value={reservedDateTime ? reservedDateTime : ''} onChange={e => reservedDateTimeChange(e.target.value)}>
                  {channelMapReservationByIdType.reservedList
                    .filter(item => yestreday < new Date(item.reservationDate))
                    .map(item => (
                      <MenuItem key={item.idx} value={item.idx}>
                        {item.reservationStatus !== 'R' ? item.reservationDate + ' ' + saveTypeMap.get(item.reservationStatus) : item.reservationDate}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {publishStatus && (publishStatus === 'R-T' || publishStatus === 'R-P') && (
              <FormControl>
                <TextField
                  style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '15px' }}
                  variant="outlined"
                  id="reservationDate"
                  name="reservationDate"
                  label="reservationDate"
                  type="date"
                  value={reserveDate ? reserveDate : ''}
                  onChange={event => reservationStartDateChange(event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  inputProps={{ min: yestreday.toISOString().slice(0, 10), max: nextMonthDate.toISOString().slice(0, 10) }}
                  required
                />
              </FormControl>
            )}
            {publishStatus && (publishStatus === 'R-T' || publishStatus === 'R-P') && (
              <FormControl variant="outlined" style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '15px', width: '125px' }} className="filter_area">
                <InputLabel>Select Time</InputLabel>
                <Select variant="outlined" id="reservationTime" name="reservationTime" label="reservationTime" value={reservationTime} onChange={e => reservationTimeChange(e.target.value)} disabled={!reserveDate} inputProps={{ min: yestreday.toISOString().slice(0, 10) }} required>
                  {hourFomatList.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {publishStatus && (publishStatus === 'R-T' || publishStatus === 'R-P') && reservedDateTime && (
              <FormControl variant="outlined" style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '12px', width: '125px' }} className="filter_area">
                <Button className="reserv_btn_color" onClick={newReservation}>
                  New
                </Button>
              </FormControl>
            )}
          </FormControl>
        </div>
        {/* <Button className="btn_color2" onClick={onClickSearch}>Search</Button> */}
        {/* </div> */}
        {/* </span> */}
        {/* <MaterialTable
          title=""
          columns={configlist ? editableColumns : []}
          data={configlist || []}
          onOrderChange={(key, type) => {
            setOrder({ key: `${key}`, type });
          }}
          options={{
            showTextRowsSelected: false,
            isLoading: true,
            search: false,
            paging: false,
            maxBodyHeight: confHeight,
            minBodyHeight: confMinHeight,
            showEmptyDataSourceMessage: false,
            rowStyle: rowData => ({
              backgroundColor: rowData.change ? '#fef5f8' : '#FFF',
              color: rowData.change ? '#d8295c' : '#484848',
            }),
            tableLayout: 'fixed',
          }}
        /> */}
        {MTable}
        {!configlist || !configlist.length ? <NoDataMap contentName="Data" show /> : null}
        <React.Fragment>
          <form id="addRowFrm" autoComplete="off">
            <div className="channel_add_area_wrap">
              <div className="channel_add_area">
                <AddRow onClickAddRow={onClickAddRow} addRow={addRow} setAddRow={setAddRow} />
              </div>
              <div className="btn_set_wrap">
                {
                  <Button
                    className="btn_color2"
                    onClick={() => {
                      setOpen(true);
                    }}>
                    History
                  </Button>
                }
                {
                  <Button className="btn_color2" onClick={onReservationList}>
                    Reservation List
                  </Button>
                }
                {
                  <Button
                    className="btn_color2"
                    onClick={() => {
                      getChannelMapInfoByChMapId(channelMapId);
                    }}
                    disabled={!configlist || changed}>
                    Download
                  </Button>
                }
                {publishStatus && (publishStatus === 'R-T' || publishStatus === 'R-P') && (
                  <Button className="btn_color2" onClick={onReservation} disabled={reservedDateTime !== 'Select Existing Reservation' && enableReservation}>
                    Reservation
                  </Button>
                )}
                {publishStatus && (publishStatus === 'T' || publishStatus === 'P') && (
                  <Button className="btn_color2" onClick={onSave} disabled={!configlist || !changed}>
                    Save
                  </Button>
                )}
                {
                  <Fragment>
                    {publishStatus === 'T' && (
                      <Button
                        className="btn_color2"
                        onClick={() => {
                          setPublishType('testing');
                        }}
                        disabled={!configlist || changed}>
                        Testing
                      </Button>
                    )}

                    {publishStatus === 'P' && (
                      <Button
                        className="btn_color2"
                        onClick={() => {
                          setPublishType('publish');
                        }}
                        disabled={!configlist || changed}>
                        Publish
                      </Button>
                    )}
                  </Fragment>
                }
              </div>
            </div>
          </form>
        </React.Fragment>
      </div>
      {publishType === 'none' ? <React.Fragment /> : <PublishDialog onClose={() => setPublishType('none')} system={system} ric={ric} cntry={cntry} channelMapId={channelMapId} publishType={publishType} />}
      <Dialog open={open} className={'pop_wrap'} style={{ width: '1460px' }}>
        {donwloadType === 'none' ? <React.Fragment /> : donwloadType === 'none' ? <React.Fragment /> : <DownloadHistoryDialog handleClose={() => setOpen(false)} system={system} ric={ric} cntryInfo={cntryInfo} hcntry={cntry} channelMapId={channelMapId} showLoadingIndicator={showLoadingIndicator} />}
      </Dialog>
      <Dialog open={openReservation} className={'pop_wrap'} style={{ width: '1460px' }}>
        {donwloadType === 'none' ? (
          <React.Fragment />
        ) : donwloadType === 'none' ? (
          <React.Fragment />
        ) : (
          <ReservationDialog handleClose={() => setOpenReservation(false)} system={system} ric={ric} saveTypeMap={saveTypeMap} channelMapId={channelMapId} publishStatus={publishStatus} cntryInfo={cntryInfo} hcntry={cntry} />
        )}
      </Dialog>
    </React.Fragment>
  );
}
SelectedChannelMap.propTypes = {
  system: PropTypes.string.isRequired,
  ric: PropTypes.string.isRequired,
  cntry: PropTypes.string.isRequired,
  openAlert: PropTypes.func,
  handleInputChange: PropTypes.func.isRequired,
  cntryInfo: PropTypes.array,
};
const mapStateToProps = state => ({
  users: state.currentUser,
  confMap: state.channelMapping.confMap,
  configlist: state.channelMapping.configlist,
  saved: state.channelMapping.saved,
  viewData: state.channelMapping.viewData,
  original: state.channelMapping.original,
  failmessage: state.channelMapping.failmessage,
  dmaGroupCodes: state.dmaGroupCode.dmaGroupCodes,
  channelMapReservationByIdType: state.channelMapping.channelMapReservationByIdType,
  channelMappedData: state.channelMapping.channelMappedData,
  channelMapHiddenData: state.channelMapping.channelMapHiddenData,
  addRSChannelIds: state.channelMapping.addRSChannelIds.list,
});
const mapDispatchToProps = dispatch => ({
  mergeChannelConf: data => dispatch(mergeChannelConf(data)),
  setChannelConf: (system, channelMapId, cntry, ric, saveType) => dispatch(setChannelConf(system, channelMapId, cntry, ric, saveType)),
  doSaveConf: (system, ric, cntry, channelMapId, saveData, saveType) => dispatch(doSaveConf(system, ric, cntry, channelMapId, saveData, saveType)),
  getDmaGroupCodeList: cntry => dispatch(getDmaGroupCodeList(cntry)),
  getChannelMapInfoByChMapId: channelMapId => dispatch(getChannelMapInfoByChMapId(channelMapId)),
  sChannelMapData: data => dispatch(setChannelMapData(data)),
  doReservationConf: (system, ric, cntry, channelMapId, saveData, saveType, reserveDateTime, reserveId) => dispatch(doReservationConf(system, ric, cntry, channelMapId, saveData, saveType, reserveDateTime, reserveId)),
  getChannelMapReservationByChannelMapIdAndType: (channelMapId, saveType) => dispatch(getChannelMapReservationByChannelMapIdAndType(channelMapId, saveType)),
  getReservationConf: (system, channelMapId, cntry, ric, saveType, reservationId) => dispatch(getReservationConf(system, channelMapId, cntry, ric, saveType, reservationId)),
  setShowColumnOnOFFTableData: showData => dispatch(setShowColumnOnOFFTableData(showData)),
  getRsChannelIdsForAddButton: (country, channelIds) => dispatch(getRsChannelIdsForAddButton(country, channelIds)),

});
export default connect(mapStateToProps, mapDispatchToProps)(SelectedChannelMap);
