import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogContent, DialogTitle, IconButton, Card, CardActionArea, CardContent, CardMedia, Typography, Grid, makeStyles, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
    contentData: {
        overflowX: 'auto',
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '10px 10px',
    }
}));

function TabContent({ content, handleClose }) {
    const classes = useStyles();
    return (
        <Fragment>
            <div className="tab_content">
                <DialogTitle id="form-dialog-title" className={'pop_head'}>
                    {'Content Details'}
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={'pop_body'}>
                    <div className={'content_dispaly'}>
                        <Grid container spacing={2}>
                            <Grid item md={4} spacing={3}>
                                <Card >
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            image={content.thumbnailUrl}
                                            alt={content.title}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {`Thumbnail`}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item md={4} spacing={3}>
                                <Card >
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            image={content.previewImgUrl}
                                            alt={content.title}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {`Preview`}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.contentData}>
                            <pre>
                                {
                                    JSON.stringify(content, undefined, 2)
                                }
                            </pre>
                        </Grid>
                    </div>
                    <div className={'btn_set_wrap'}>
                        <Button color="primary" onClick={handleClose} className={'btn_color1'}>
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
            </div>
        </Fragment >
    );
}

TabContent.propTypes = {
    content: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default connect()(TabContent);