import * as actions from '../../actions/actionTypes';

const initialState = {
  type: '',
  mapList: [],
};

export default function histConfMap(state = initialState, action) {
  switch (action.type) {
    case actions.SET_HIST_CONF_MAP:
      return action.payload;
    // case actions.SET_CHANNEL_MAPPING:
    //   return initialState;
    default:
      return state;
  }
}
