///* eslint-disable */
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'; // React
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button, Checkbox, Dialog, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { doSaveChannelName, doRevokeChannelName, mergeSourceList } from '../../../actions/channelMappingActions';
import { makeStyles } from '@material-ui/core/styles';
import PublishEditedChannel from './PublishEditedChannel';
import { SET_SOURCE_CHANNEL } from '../../../actions/actionTypes';
import EditMediaStaticURL from './EditMediaStaticURL';
const useStyles = makeStyles(() => ({
  channelNameEdit: {
    fontWeight: 'bold',
  },
}));
function SelectedFeed({ mergeSourceList, system, ric, cntry, sourceChannel, configlist, tableRef, openAlert, doSaveChannelName, doRevokeChannelName, feeds, rowData, cpChannelDataViewRow, revokeChannelNameList, doUpdateSourceChannel, setIsFeedChange, rsChannelIds, rsDataLoaded }) {
  const classes = useStyles();
  const [mappedFeed, setMappedFeed] = useState([]);
  //const [changedFeed, setChangedFeed] = useState({});
  const [publishType, setPublishType] = React.useState('none');
  const defaultPopupType = {
    add: false,
    edit: false,
    preview: false,
    history: false,
  };
  const [open, setOpen] = useState(false);
  const [popupType, setPopupType] = useState(defaultPopupType);
  const [mediaStaticUrl, setMediaStaticUrl] = useState();
  const defaultData = {
    channelId: '',
    mediaStaticUrl: '',
  };
  useMemo(() => {
    if (configlist && configlist.length != 0) {
      const channelId = configlist.map(config => config.channelId);
      setMappedFeed(channelId);
    }
    if (configlist && configlist.length === 0) {
      //reset mapped feed when config list is empty
      setMappedFeed([]);
    }
  }, [configlist]);

  useEffect(() => {
    if (sourceChannel.channellist && revokeChannelNameList.list && revokeChannelNameList.list.length != 0) {
      const updatedChannelList = sourceChannel.channellist;
      revokeChannelNameList.list.map(data => {
        const editedChannelIndex = updatedChannelList.findIndex(channel => channel.channelIdx === data.channelIdx);
        if (editedChannelIndex >= 0) {
          updatedChannelList[editedChannelIndex]['isModify'] = true;
          updatedChannelList[editedChannelIndex]['originalChannelName'] = updatedChannelList[editedChannelIndex].channelName;
          updatedChannelList[editedChannelIndex].channelName = data.channelName;
          updatedChannelList[editedChannelIndex].revokeFlag = data.revokeFlag;
        }
        return data;
      });
      let sourceChannelUpdate = {
        channellist: [...updatedChannelList],
      };
      doUpdateSourceChannel(sourceChannelUpdate);
      tableRef.current.onAllSelected(false);
    }
  }, [revokeChannelNameList]);

  const onChanged = (e, rowData) => {
    let newChannellist = [...sourceChannel.channellist];
    let idx = rowData.tableData.id;

    if (!newChannellist[idx]['originalChannelName']) {
      newChannellist[idx]['originalChannelName'] = rowData.channelName;
    }

    if (newChannellist[idx]['originalChannelName'] && newChannellist[idx]['originalChannelName'] === e.target.value) {
      delete newChannellist[idx]['originalChannelName'];
      delete newChannellist[idx]['isModify'];
    } else {
      newChannellist[idx]['channelName'] = e.target.value;
      newChannellist[idx]['isModify'] = true;
    }
    setIsFeedChange(true);
    mergeSourceList(newChannellist);
  };

  const handleMediaStaticUrl = e => {
    const { name, value } = e.target;
    setMediaStaticUrl({ ...mediaStaticUrl, ...{ [name]: value } });
  };
  let columns = [
    {
      title: '',
      field: 'logoUrl',
      width: '9%',
      render: rowData => {
        return rowData.logoUrl ? (
          <span className="board_thumbnail">
            <img src={rowData.logoUrl} style={{ backgroundColor: '#3d3d3d', width: '100%', height: '100%' }} />
          </span>
        ) : (
          <span className="board_thumbnail no_img"></span>
        );
      },
    },
    {
      title: 'Ch. ID',
      field: 'channelId',
      width: '13%',
      render: rowData => {
        return <p style={{ wordBreak: 'break-all' }}>{rowData.channelId}</p>;
      },
    },
    {
      title: 'Ch. Name',
      field: 'channelName',
      width: '18%',
      render: rowData => (
        <TextField
          variant="outlined"
          name="channelName"
          style={{ width: 110 }}
          InputProps={{
            className: rowData.modifiedFlag ? classes.channelNameEdit : '',
          }}
          //defaultValue={rowData.channelName}
          value={rowData.channelName}
          onChange={e => {
            e.preventDefault();
            onChanged(e, rowData);
          }}
        />
      ),
    },
    {
      title: 'Genre',
      field: 'channelGenreName',
      width: '14%',
      render: rowData => {
        return <p style={{ wordBreak: 'break-all' }}>{rowData.channelGenreName}</p>;
      },
    },
    {
      title: 'Ch. No',
      field: 'channelNumber',
      width: '12%',
      render: rowData => {
        return <p style={{ wordBreak: 'break-all' }}>{rowData.channelNumber}</p>;
      },
    },
    {
      title: 'Media URL',
      field: 'mediaUrlEditableFlag',
      width: '18%',
      hidden: !(sourceChannel && sourceChannel.channellist[0] && sourceChannel.channellist[0].mediaUrlEditableFlag == 'Y'),
      render: n => {
        return (
          <Link
            className="link"
            onClick={() => {
              // ==== add Popup
              setOpen(true);
              setPopupType({ ...defaultPopupType, add: true });
              setMediaStaticUrl(n);
            }}>
            {n.mediaUrlEditableFlag === 'N' ? '' : n.mediaStaticUrl ? 'Y' : 'N'}
          </Link>
        );
      },
    },
  ];

  const isEmptyChanName = () => {
    const modifyChannels = sourceChannel.channellist.filter(cList => cList.isModify);
    if (modifyChannels.length == 0) {
      return true;
    } else if (modifyChannels.length != 0) {
      return modifyChannels.some(v => {
        return !v.channelName.trim();
      });
    }
  };
  const isOK = () => {
    if (isEmptyChanName()) {
      openAlert('There are empty channel names. Fill out fields.');
      return false;
    }
    return true;
  };

  const onSave = () => {
    const result = isOK();
    if (result) {
      const data = Object.values(sourceChannel.channellist)
        .filter(filterFeed => {
          return filterFeed.isModify;
        })
        .map(feed => {
          return { channelId: feed.channelId, savedChannelName: feed.channelName, channelIdx: feed.channelIdx, revokeFlag: feed.revokeFlag };
        });
      doSaveChannelName(system, ric, cntry, data, feeds, cpChannelDataViewRow);
    }
  };
  const onPublish = () => {
    setPublishType('publish');
  };
  const onRevoke = () => {
    let selection = document.querySelectorAll('input[type=checkBox]:checked');
    let checked = [];
    if (selection && selection.length != 0) {
      selection.forEach(v => {
        const getChannelByIndex = sourceChannel.channellist[Number(v.value)];
        if (v.value && getChannelByIndex) {
          const { channelName, channelIdx } = getChannelByIndex;
          checked.push({ channelName, channelIdx });
        }
      });
    }
    if (!checked.length) {
      openAlert('Select a channel from the channel list.');
      return;
    } else {
      doRevokeChannelName(system, ric, cntry, checked, feeds, cpChannelDataViewRow, [...sourceChannel.channellist]);
    }
  };
  return (
    <React.Fragment>
      <div className={sourceChannel && sourceChannel.channellist.length ? 'tbl_wrap tbl_head_fixed tbl_source_conf channel_list' : 'tbl_wrap tbl_head_fixed tbl_source_conf channel_list nodata_channel_list'}>
        <MaterialTable
          tableRef={tableRef}
          title={`Selected Feed :  ${cpChannelDataViewRow ? cpChannelDataViewRow.cpName : ''}`}
          components={{
            Toolbar: props => (
              <span>
                <div className="mapping_tbl_header">
                  <MTableToolbar {...props} />
                  <p className="showing_count">
                    Showing all <span>{(sourceChannel && sourceChannel.channellist && sourceChannel.channellist.length) || 0}</span>
                  </p>
                </div>
                <div className="file_name_area">{sourceChannel.fileName}</div>
              </span>
            ),
          }}
          columns={columns}
          data={[...sourceChannel.channellist]}
          options={{
            showTextRowsSelected: false,
            selection: true,
            search: false,
            paging: false,
            doubleHorizontalScroll: true,
            maxBodyHeight: '935px',
            minBodyHeight: '900px',
            rowStyle: rowData => ({
              backgroundColor: rsDataLoaded && !rsChannelIds.includes(rowData.channelId) ? '#7FB3D5' : mappedFeed.includes(rowData.channelId) ? '#e0e0e0' : rowData.tableData.checked || rowData.isModify ? '#fef5f8' : '#FFF',
              //pointerEvents: !rowData.tableData.checked && mappedFeed.includes(rowData.channelId) ? 'none' : 'initial',
              color: rowData.tableData.checked || rowData.isModify ? '#d8295c' : '#484848',
            }),
            tableLayout: 'fixed',
          }}
        />
        <React.Fragment>
          <form id="addRowFrm" autoComplete="off">
            <div className="btn_set_wrap">
              {(
                <Button className="btn_color2" onClick={onSave}>
                  Save
                </Button>
              )}
              {(
                <Button className="btn_color2" onClick={onPublish}>
                  Publish
                </Button>
              )}
              {(
                <Button className="btn_color2" onClick={onRevoke}>
                  Revoke Channel Name
                </Button>
              )}
            </div>
          </form>
        </React.Fragment>
      </div>
      {publishType === 'none' ? <React.Fragment /> : <PublishEditedChannel onClose={() => setPublishType('none')} system={system} ric={ric} cntry={cntry} publishType={publishType} feeds={feeds} cpChannelDataViewRow={cpChannelDataViewRow} />}
      {open && <EditMediaStaticURL handleClose={() => setOpen(false)} handleMediaStaticUrl={handleMediaStaticUrl} data={mediaStaticUrl} system={system} ric={ric} cntry={cntry} feeds={feeds} cpChannelDataViewRow={cpChannelDataViewRow} openAlert={openAlert} />}
    </React.Fragment>
  );
}
SelectedFeed.propTypes = {
  sourceChannel: PropTypes.object,
  tableRef: PropTypes.any,
  cpChannelDataViewRow: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  configlist: state.channelMapping.configlist,
  revokeChannelNameList: state.channelMapping.revokeChannelNameList,
  feeds: state.channelMapping.feeds,
  sourceChannel: state.channelMapping.sourceChannel,
  rsChannelIds: state.channelMapping.rsChannelIds.list,
  rsDataLoaded: state.channelMapping.rsChannelIds.isDataLoad,
});

const mapDispatchToProps = dispatch => ({
  mergeSourceList: data => dispatch(mergeSourceList(data)),
  doSaveChannelName: (system, ric, cntry, saveData, feeds, rowData) => dispatch(doSaveChannelName(system, ric, cntry, saveData, feeds, rowData)),
  // doPublishChannelName: (system, ric, cntry,) => dispatch(doPublishChannelName(system, ric, cntry)),
  doRevokeChannelName: (system, ric, cntry, saveData, feeds, rowData, channellist) => dispatch(doRevokeChannelName(system, ric, cntry, saveData, feeds, rowData, channellist)),
  doUpdateSourceChannel: sourceChannel =>
    dispatch({
      type: SET_SOURCE_CHANNEL,
      payload: sourceChannel,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(SelectedFeed);
