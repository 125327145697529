import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import AdminAdd from './AdminAdd';
import LoadingIndicator from '../../../common/LoadingIndicator';
import ChangePw from '../ChangePw';
import AccountDelete from '../AccountDelete';
import { getAdminsAction } from '../../../actions/userActions';
import { AdminFilter } from '../../../common/filter';
import Button from '@material-ui/core/Button';
export function Admin({ getAdminsAction, fetching, admins, isSuperAdmin }) {
  const [role, setRole] = React.useState('All');
  const [id, setId] = React.useState('');
  const [list, setList] = React.useState();

  useEffect(() => {
    getAdminsAction();
  }, []);

  useEffect(() => {
    setList(userListId);
  }, [admins]);
  //console.log('[isSuperAdmin]', isSuperAdmin);

  const userList = admins.map(n => {
    let newData = {};
    newData['ID'] = n.username;
    newData['Role'] = n.roles.find(item => item.name === 'ROLE_SUPER_ADMIN') ? 'SUPER_ADMIN' : 'ADMIN';
    return newData;
  });
  const userListRole = role !== 'All' ? userList.filter(item => item.Role === role) : userList;
  const userListId = id ? userListRole.filter(item => item.ID.toLowerCase().includes(id.toLowerCase())) : userListRole;

  const content = [
    {
      title: 'ID',
      field: 'ID',
    },
    {
      title: 'Password',
      render: rowData => <ChangePw item={rowData} reloadAction={getAdminsAction} />,
    },
    {
      title: 'Role',
      field: 'Role',
    },
  ];

  return (
    <div>
      <AdminFilter role={role} handleChangeRole={e => setRole(e.target.value)} id={id} handleInputChangeId={e => setId(e.target.value)} />
      <div className="btn_set_wrap search_wrap">
        <Button variant="contained" className={'btn_color2'} onClick={() => setList(userListId)}>
          Search
        </Button>
      </div>
      {fetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        <div className={'tbl_wrap'}>
          {isSuperAdmin ? (
            <div className={'btn_set_wrap tbl_top1'}>
              <AdminAdd />
            </div>
          ) : null}
          <MaterialTable
            title=" " //없으면 Table Title이 default title로 나옴
            columns={
              isSuperAdmin
                ? [
                    ...content,
                    {
                      title: 'Action',
                      // eslint-disable-next-line react/display-name
                      render: n => {
                        const deleteButton = n.Role === 'SUPER_ADMIN' ? <div /> : <AccountDelete requestPath={`/api/admin/users/${n['ID']}`} reloadAction={getAdminsAction} />;
                        return (
                          <React.Fragment>
                            <div className={'btn_set_wrap_tbl'}>{deleteButton}</div>
                          </React.Fragment>
                        );
                      },
                    },
                  ]
                : content
            }
            data={list}
            options={{
              pageSize: 5,
              pageSizeOptions: [5, 10, 30, 50, 100],
              rowStyle: { backgroundColor: '#fff' },
            }}
          />
        </div>
      )}
    </div>
  );
}
Admin.propTypes = {
  fetching: PropTypes.bool.isRequired,
  getAdminsAction: PropTypes.func.isRequired,
  admins: PropTypes.array.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  fetching: state.users.adminFetching,
  admins: state.users.admins,
  isSuperAdmin: state.currentUser.isSuperAdmin,
});
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  getAdminsAction() {
    dispatch(getAdminsAction());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admin));
