// import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function sliderResultList(state = initialState, action) {
  switch (action.type) {
    case 'SET_SLIDER_RESULT':
      if (action.payload.status) {
        return state; //TODO
      } else if (action.payload) {
        return action.payload;
      } else {
        return state;
      }
    default:
      return state;
  }
}
