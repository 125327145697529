import { combineReducers } from 'redux';
import feedlist from './feedlist';
import countries from './countries';
import size from './size';
import page from './page';
import totalElements from './totalElements';
export default combineReducers({
  feedlist,
  size,
  page,
  countries,
  totalElements,
});
