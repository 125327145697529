import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

export const convertToGraphData = (data, indicator, dateList) => {
  const indicatorData = data.data.find(n => n.item === indicator);
  let result = {};

  //Summary, Channel, Program, homeapp
  result.list = indicatorData
    ? Object.keys(indicatorData)
        .filter(n => n !== 'item')
        .map(n => ({ name: n, [indicator]: indicatorData[n] }))
        .sort((a, b) => (a.name > b.name ? -1 : b.name > a.name ? 1 : 0))
    : [];
  result.max = Math.ceil(Math.max(...result.list.map(n => n[indicator])));

  return result;
};

function SimpleLineContainer({ data, dateList }) {
  const totalDuration = data.data.find(n => n.item === 'totalDuration');
  const udResult = convertToGraphData(data, 'uniqueDevice', dateList);

  const lineData = udResult.list.reverse()
    .map((n, i) => {
      n['totalDuration'] = !dateList ? totalDuration[n.name] : totalDuration.valueList[i];
      n['uniqueDeviceRatio'] = i > 0 && parseFloat(udResult.list[i-1]['uniqueDevice']) !== 0 ?
        (((parseFloat(udResult.list[i]['uniqueDevice']) - parseFloat(udResult.list[i-1]['uniqueDevice']))/parseFloat(udResult.list[i-1]['uniqueDevice'])) * 100).toFixed(1) + '%' : 0;
      n['totalDurationRatio'] = i > 0 && parseFloat(udResult.list[i-1]['totalDuration']) !== 0 ?
        (((parseFloat(udResult.list[i]['totalDuration']) - parseFloat(udResult.list[i-1]['totalDuration']))/parseFloat(udResult.list[i-1]['totalDuration'])) * 100).toFixed(1) + '%' : 0;
      return n;
    });

  const tdMax = Math.ceil(Math.max(...lineData.map(n => n['totalDuration'])));

  return (
    data.data.length > 0 && (
      <div className="chart_card">
        <div className="chart_row line_chart">
          <div className="chart_unit" width="99%">
            <span className="tit">Unique Device & Total Duration</span>
            <br />
            <br />
            <br />
            <center>
              <SimpleLineChart lineData={lineData} udMax={udResult.max} tdMax={tdMax} />
            </center>
          </div>
        </div>
      </div>
    )
  );
}
SimpleLineContainer.propTypes = {
  data: PropTypes.object.isRequired,
  dateList: PropTypes.array,
};
const mapStateToProps = state => ({
  data: state.statistic.data,
});

export default connect(mapStateToProps)(SimpleLineContainer);

function SimpleLineChart({ lineData, udMax, tdMax }) {
  const period = lineData && lineData.length > 0 ? (lineData[0]['name']).length > 7 ? 'WoW' : 'MoM' : '';

  return (
    <LineChart
      width={1450}
      height={320}
      data={lineData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis allowDataOverflow={false} domain={[0, udMax]} yAxisId="left" />
      <YAxis allowDataOverflow={false} domain={[0, tdMax]} yAxisId="right" orientation="right" />
      <Tooltip
        formatter={(value, name, props) => {
          return [
            `${name}: ${value} (${period} ${props.payload[name + 'Ratio']})`,
          ];
        }}
      />
      <Legend />
      <Line type="monotone" yAxisId="left" dataKey="uniqueDevice" stroke="#82ca9d" />
      <Line type="monotone" yAxisId="right" dataKey="totalDuration" stroke="#8884d8" />
    </LineChart>
  );
}
SimpleLineChart.propTypes = {
  lineData: PropTypes.array.isRequired,
  udMax: PropTypes.number,
  tdMax: PropTypes.number,
};
