import React, { useRef, useState, useEffect } from 'react';
import SearchedContentList from './SearchedContentList';
import OrganizedContentList from './OrganizedContentList';
import Button from '@material-ui/core/Button';
import AlertDialog from '../../../common/dialog/AlertDialog';

const ShowContentList = (props) => {
  const selectedTableRef = useRef();
  const resultTableRef = useRef();

  const { isDisplayContent
        , isDisplayChannel
        , country
        , providerList
        , status
        , savedRelatedContent
        , callbackSavedRelatedContent
        , currentChannelId
        , isChannelListChanged
        , callbackDeploy
        , callbackCategoryTitle
        } = props;

  const [selectedContentList, setSelectedContentList] = useState([]);
  const [moveContentList, setMoveContentList] = useState([]);
  const [deletingContentList, setDeletingContentList] = useState([]);
  const [deletedContentList, setDeletedContentList] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [viewContentIds, setViewContentIds] = useState([]);

  useEffect(() => {
    setMoveContentList([]);
    setDeletingContentList([]);
    setDeletedContentList([]);
  }, [isDisplayContent]);

  const handleMove = () => {
    if (selectedContentList.length > 0) {
      setMoveContentList([...selectedContentList]);
    } else {
      setAlertDescription('Select any content from the content lsit');
      setIsAlert(true);
    }

    selectedTableRef && selectedTableRef.current.onAllSelected(false);
  };

  const handleDelete = () => {
    if (deletingContentList.length > 0) {
      setDeletedContentList([...deletingContentList]);
    } else {
      setAlertDescription('Select any content from the content lsit');
      setIsAlert(true);
    }

    resultTableRef && resultTableRef.current.onAllSelected(false);
  };

  return (
    <React.Fragment>
      <div className={'live_tv_content_list'}>
        <div className='selected_channel_list'>
          <SearchedContentList
            selectedTableRef={selectedTableRef}
            country={country}
            providerList={providerList}
            status={status}
            isDisplayContent={isDisplayContent}
            callbackSelectedContent={setSelectedContentList}
            viewContentIds={viewContentIds}
          />
        </div>
        {
          isDisplayContent ? (
            <div className="arrow_btn_area">
              <Button className="btn_move piece" title=">" onClick={handleMove}>
                &nbsp;
              </Button>
              <Button className="btn_move remove" title="X" onClick={handleDelete}>
                &nbsp;
              </Button>
            </div>
          )
          : (
            <></>
          )
        }
        <div className='organized_channel_list'>
          <OrganizedContentList
            resultTableRef={resultTableRef}
            savedRelatedContent={savedRelatedContent}
            moveContentList={moveContentList}
            deletedContentList={deletedContentList}
            callbackDeletingContent={setDeletingContentList}
            callbackSavedRelatedContent={callbackSavedRelatedContent}
            currentChannelId={currentChannelId}
            status={status}
            isDisplayContent={isDisplayContent}
            isDisplayChannel={isDisplayChannel}
            isChannelListChanged={isChannelListChanged}
            callbackDeploy={callbackDeploy}
            callbackViewSavedContentIds={setViewContentIds}
            callbackCategoryTitle={callbackCategoryTitle}
          />
        </div>
      </div>
      <AlertDialog
        openAlert={isAlert}
        description={alertDescription}
        warningList={[]}
        closeHandler={() => { setIsAlert(false); }}
      />
    </React.Fragment>
  );
}

export default ShowContentList;