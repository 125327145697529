import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, Switch } from '@material-ui/core';
import MaterialTable from 'material-table';
import TabCategoryScheduleView from './TabCategoryScheduleView';
import { getTabCategoryList, getCategoryScheduleList, updateCategoryFixStatus } from '../../actions/homeAppActions';

function TabCategorySchedule({ tabId, tabCategoryData, getTabCategoryData, getCategoryScheduleList, updateCategoryFixFlag, history }) {
  const tableRef = React.useRef(null);
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState({});

  useEffect(() => {
    if (tabId) {
      getTabCategoryData(tabId);
    }
  }, [tabId]);

  const getCategoryScheduleListData = data => {
    getCategoryScheduleList(data.categoryId);
  };

  const handleFixFlag = (e, rowData) => {
    const enable = e.target.checked ? 'Y' : 'N';
    updateCategoryFixFlag(rowData.categoryId, enable, tabId);
  };

  const column = [
    {
      title: 'Category Order',
      field: 'categoryOrder',
      width: '15%',
    },
    {
      title: 'Category Code',
      field: 'categoryCode',
      width: '15%',
    },
    {
      title: 'Category Name',
      render: data => data.name,
      width: '15%',
    },
    {
      title: 'Random',
      width: '15%',
      render: rowData => <Fragment>{rowData.fixFlag === 'Y' ? 'Fix' : tabCategoryData.randomFlag}</Fragment>,
    },
    {
      title: 'Fix Flag',
      render: rowData => <Switch checked={rowData.fixFlag === 'Y'} disabled={tabCategoryData.randomFlag === 'N'} inputProps={{ 'aria-label': 'secondary checkbox' }} onChange={event => handleFixFlag(event, rowData)} />,
      width: '15%',
    },
    {
      title: 'Schedule List',
      render: data => (
        <Fragment>
          {data.scheduleList.map(x => (
            <p>
              {' '}
              {x.startDate.slice(0, 10)} - {x.endDate.slice(0, 10)}{' '}
            </p>
          ))}
        </Fragment>
      ),
      width: '25%',
    },
    {
      title: '',
      width: '15%',
      render: data => (
        <Button
          variant="contained"
          onClick={() => {
            setOpen(true);
            setCategory(data);
            getCategoryScheduleListData(data);
          }}
          className={'btn_color2 btn_size_tbl'}>
          Schedule
        </Button>
      ),
    },
  ];

  return (
    <Fragment>
      <MaterialTable
        tableRef={tableRef}
        title={`${tabCategoryData.name} Tab Schedule List`}
        columns={column}
        data={tabCategoryData.categoryList ? tabCategoryData.categoryList.filter(n => n.useFlag === 'Y') : []}
        options={{
          search: false,
          paging: false,
        }}
      />
      <div className="btn_set_wrap">
        <Button
          variant="contained"
          className="btn_color2"
          onClick={() => {
            history.push('/HOME-APP/tab');
          }}>
          Back
        </Button>
      </div>
      <Dialog open={open} className={'pop_wrap tab_schedule_popup'}>
        <TabCategoryScheduleView
          region={tabCategoryData.region}
          deviceType={tabCategoryData.deviceType}
          catergory={category}
          handleClose={() => {
            setOpen(false);
            getTabCategoryData(tabId);
          }}
        />
      </Dialog>
    </Fragment>
  );
}

TabCategorySchedule.propTypes = {
  tabId: PropTypes.string.isRequired,
  tabCategoryData: PropTypes.object.isRequired,
  getTabCategoryData: PropTypes.func.isRequired,
  getCategoryScheduleList: PropTypes.func.isRequired,
  updateCategoryFixFlag: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tabCategoryData: state.homeApp.tabCategoryList,
});

const mapDispatchToProps = dispatch => ({
  getTabCategoryData: tabId => dispatch(getTabCategoryList(tabId)),
  getCategoryScheduleList: categoryId => dispatch(getCategoryScheduleList(categoryId)),
  updateCategoryFixFlag: (categoryId, fixFlag, tabId) => dispatch(updateCategoryFixStatus(categoryId, fixFlag, tabId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TabCategorySchedule));
