import { SET_DRAWER_OPEN } from './actionTypes';

export const setDrawerOpenAction = payload => ({
  type: SET_DRAWER_OPEN,
  payload,
});

export const setHeaderMenuAction = payload => ({
  type: 'SET_HEADER_MENU',
  payload,
});
