import { combineReducers } from 'redux';
import admins from './admins';
import adminFetching from './adminFetching';
import members from './members';
import memberFetching from './memberFetching';

// import users from './users';
// import fetching from './fetching';
// import last from './last';
// import page from './page';
// import size from './size';
// import totalElements from './totalElements';
// import totalPages from './totalPages';

export default combineReducers({
  admins,
  adminFetching,
  members,
  memberFetching,
  //   users,
  //   fetching,
  // last,
  // page,
  // size,
  // totalElements,
  // totalPages
});
