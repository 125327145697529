import * as actions from '../../actions/actionTypes';

const initialState = false;

export default function adminFetching(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ADMINS_FETCHING:
      return true;
    case actions.SET_ADMINS:
      return false;
    case actions.SET_ADMINS_ERROR:
      return false;
    default:
      return state;
  }
}
