import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 336px;
    --y: 750px;
    --width: 1572px;
    --height: 294px;

    top: 0;
    left: 0;
    width: 1920px;
    height: 2000px;
  }

  #text-01 {
    top: 1071px;
    left: 709px;
  }

  #line-01 {
    top: 1044px;
    left: 637px;
  }
`;

const IssueHistory04 = () => {
  const elementRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, elementRef.current.offsetTop - 90); // GNB height
  }, []);

  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" ref={elementRef} className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">
          You can check the cumulative issue resolution rate in a graph based on<br/>
          the date of occurrence within the selected period.<br/>
          That is, even if all issues that occurred today are resolved,<br/>
          if any issues that occurred previously were not resolved, the value would be less than 100%.<br/>
          <br/>
          For example, if there were 10 issues that occurred yesterday and 10 today,<br/>
          and all issues that occurred today were resolved, but only 5 issues that occurred<br/>
          yesterday were resolved, the value should be 75%.
          </div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_6171_line_01.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default IssueHistory04;