import { combineReducers } from 'redux';
import ch0303 from './ch0303';
import ch0304 from './ch0304';
import ch0305 from './ch0305';
import ch0306 from './ch0306';
import ch0307 from './ch0307';
import last from './last';
import page from './page';
import size from './size';
import totalElements from './totalElements';
import totalPages from './totalPages';
//
export default combineReducers({
  ch0303,
  ch0304,
  ch0305,
  ch0306,
  ch0307,
  last,
  page,
  size,
  totalElements,
  totalPages,
});
