import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableBodyRow } from 'material-table';

export default function WatchLiveTable({ tableRef, resultTableRef, options, select, sourceChannel, handleSetTypeData, dlagDropFlag, isResult, data, renderOrder, renderOrderEdit, renderFixFlag, flagReset, setChanged, handleSetInsertTableData }) {
  const [order, setOrder] = useState({ key: '', type: '' });
  const [tableData, setTableData] = useState([]);
  const [isRowDragged, setIsRowDragged] = useState(false);

  const DrageState = {
    row: -1,
    dropIndex: -1, // drag target
    position: -1,
    dragPosition: -1,
    type: '',
    srtart: -1,
    end: -1,
  };
  const column = [
    { title: 'cp', field: 'cpId', defaultSort: order.key === '1' ? order.type : '' },
    { title: 'ch.Id', field: 'channelId', defaultSort: order.key === '2' ? order.type : '' },
    { title: 'ch.Name', field: 'channelName', defaultSort: order.key === '3' ? order.type : '' },
    {
      title: 'Logo',
      field: 'logoUrl',
      defaultSort: order.key === '4' ? order.type : '',
      render: rowData => {
        return rowData.logoUrl ? (
          <span className="board_thumbnail">
            <img src={rowData.logoUrl} style={{ backgroundColor: '#3d3d3d', width: '100%', height: '100%' }} />
          </span>
        ) : (
          <span className="board_thumbnail no_img"></span>
        );
      },
    },
    { title: 'Genre', field: 'channelGenreName', defaultSort: order.key === '5' ? order.type : '' },
  ];
  const resultColumn = [
    { title: 'order', field: 'resultOrder', render: isResult ? (!isRowDragged ? renderOrderEdit : renderOrder) : () => { }, hidden: !isResult, defaultSort: order.key === '0' ? order.type : '' },
    { title: 'cp', field: 'cpId', defaultSort: order.key === '1' ? order.type : '' },
    { title: 'ch.Id', field: 'channelId', render: rowData => { return rowData.popChannelId ? rowData.popChannelId : rowData.channelId }, defaultSort: order.key === '2' ? order.type : '' },
    { title: 'ch.Name', field: 'channelName', defaultSort: order.key === '3' ? order.type : '' },
    { title: 'fix', field: 'manualFlag', render: rowData => renderFixFlag(rowData), default: flagReset === 'Y' ? 'N' : 'Y', defaultSort: order.key === '4' ? order.type : '' },
    {
      title: 'Logo',
      field: 'logoUrl',
      defaultSort: order.key === '5   ' ? order.type : '',
      render: rowData => {
        return rowData.logoUrl ? (
          <span className="board_thumbnail">
            <img src={rowData.logoUrl} style={{ backgroundColor: '#3d3d3d', width: '100%', height: '100%' }} />
          </span>
        ) : (
          <span className="board_thumbnail no_img"></span>
        );
      },
    },
    { title: 'Genre', field: 'channelGenreName', defaultSort: order.key === '6' ? order.type : '' },
  ];

  useEffect(() => {
    setTableData(data);
    setOrder({ key: '', type: '' });
  }, [data]);

  useEffect(() => {
    if (isRowDragged) {
      setIsRowDragged(false)
    }
  }, [isRowDragged]);
  //Reorder the table row
  const offsetIndex = (from, to, arr = []) => {
    if (from < to) {
      let start = arr.slice(0, from),
        between = arr.slice(from + 1, to + 1),
        end = arr.slice(to + 1);
      return [...start, ...between, arr[from], ...end];
    }
    if (from > to) {
      let start = arr.slice(0, to),
        between = arr.slice(to, from),
        end = arr.slice(from + 1);
      return [...start, arr[from], ...between, ...end];
    }
    return arr;
  };
  const reOrderRow = (from, to, type) => {
    let newtableData = [];
    if (type === 'Swap') {
      [tableData[from], tableData[to]] = [tableData[to], tableData[from]];
      let start = tableData[0],
        between = tableData.slice(1, tableData.length - 1),
        end = tableData[tableData.length - 1];
      newtableData = [start, ...between, end];
    } else if (type === 'Insert') {
      newtableData = offsetIndex(from, to, tableData);
    } else {
      return;
    }
    newtableData.map((element, index) => (element.resultOrder = index + 1));
    setTableData(newtableData);
    handleSetInsertTableData(newtableData);
    handleSetTypeData(type);
  };
  return (
    <MaterialTable
      title=""
      columns={isResult ? resultColumn : column}
      tableRef={isResult ? resultTableRef : tableRef}
      onOrderChange={(key, type) => {
        setOrder({ key: `${key}`, type });
      }}
      components={
        dlagDropFlag
          ? {
            Row: props => (
              <MTableBodyRow
                {...props}
                draggable="true"
                onDragStart={e => {
                  DrageState.row = props.data.tableData.id;
                  DrageState.srtart = e.target.getBoundingClientRect().top;
                }}
                onDragEnter={e => {
                  e.preventDefault();
                  if (props.data.tableData.id !== DrageState.row) {
                    DrageState.dropIndex = props.data.tableData.id;
                    DrageState.position = (e.target.parentElement.getBoundingClientRect().top + e.target.parentElement.getBoundingClientRect().bottom) / 2;
                    document.getElementById('popup').style.display = 'block';
                  }
                  DrageState.end = e.target.parentElement.getBoundingClientRect().top;
                  if (DrageState.srtart == DrageState.end) {
                    document.getElementById('popup').style.display = 'none';
                  }
                }}
                onDragOver={e => {
                  DrageState.dragPosition = e.clientY;
                  document.getElementById('popup').clientY = e.clientY;
                  if (DrageState.position >= DrageState.dragPosition - 30 && DrageState.position <= DrageState.dragPosition + 30) {
                    document.getElementById('popup').innerHTML = 'Swap';
                  } else {
                    document.getElementById('popup').innerHTML = 'Insert';
                  }
                  DrageState.type = document.getElementById('popup').innerHTML;
                }}
                onDragEnd={e => {
                  if (DrageState.dropIndex !== -1 && DrageState.srtart != DrageState.end) {
                    setIsRowDragged(true);
                    reOrderRow(DrageState.row, DrageState.dropIndex, DrageState.type);
                    setChanged(true);
                  }
                  DrageState.row = -1;
                  DrageState.dropIndex = -1;
                  DrageState.position = -1;
                  DrageState.dragPosition = -1;
                  DrageState.type = '';
                  DrageState.srtart = -1;
                  DrageState.end = -1;
                  document.getElementById('popup').style.display = 'none';
                }}
              />
            ),
          }
          : {}
      }
      data={isResult ? [...tableData] : [...sourceChannel.channellist]}
      options={options}
      onSelectionChange={select}
    />
  );
}
