import { ROWHEADER_TYPE, TABLE_TYPE } from '../consts/IssConsts';

export const detail_contents_tabledata = {
  cp_issues: {
    tableType: TABLE_TYPE.CHECKBOX_SELECTION,
    title: 'Issue Status',
    visibleTitle: false,
    existTooltip: false,
    tooltip: '',
    header: ['Group ID', 'Date', 'Cnty.', 'CP', 'Issue Title', 'Status', 'API Name', 'Assign', 'Modifier', 'Jira URL', 'Action Details'],
    cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.COUNTRY_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.ASSIGN_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL],
    dropdownItem: [
      { index: 'Unaddressed', name: 'Unaddressed' },
      { index: 'Processing', name: 'Processing' },
      { index: 'Resolve', name: 'Resolve' },
    ],
    assignDropdownItem: [],
    cellWidth: ['5%', '5%', '5%', '5%', '10%', '5%', '15%', '10%', '10%', '10%', '20%'],
    rowdata: [],
  },
  cp_issue_description: {
    title: 'description',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'CP Issues tooltip message..!!',
    header: ['Issue Title', 'Guide'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['70%', '30%'],
    rowdata: [],
  },
  cp_issue_history: {
    title: 'Issue History',
    visibleTitle: true,
    existTooltip: false,
    tooltip: 'CP Issue History tooltip message..!!',
    header: ['Group ID', 'Date', 'Cnty.', 'CP', 'Issue Title', 'Status', 'API Name', 'Assign', 'Modifier', 'Jira URL', 'Action Details'],
    cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['5%', '5%', '5%', '5%', '10%', '5%', '15%', '10%', '10%', '10%', '20%'],
    rowdata: [],
  },

  /* Organization Issues */
  organization_issues: {
    tableType: TABLE_TYPE.CHECKBOX_SELECTION,
    title: 'Issue Status',
    visibleTitle: false,
    existTooltip: false,
    tooltip: '',
    header: ['Issue ID', 'Date', 'Lv', 'Cnty.', 'Issue Title', 'Status', 'user Name', 'Function Name', 'Function Parameter', 'Assign', 'Modifier', 'Jira URL', 'Action Details'],
    cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.ASSIGN_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL],
    dropdownItem: [
      { index: 'Unaddressed', name: 'Unaddressed' },
      { index: 'Processing', name: 'Processing' },
      { index: 'Resolve', name: 'Resolve' },
    ],
    assignDropdownItem: [],
    cellWidth: ['5%', '4%', '2%', '3%', '14%', '5%', '5%', '12%', '15%', '5%', '9%', '6%', '15%'],
    rowdata: [],
  },
  organization_description: {
    title: 'description',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Organization Issues tooltip message..!!',
    header: ['Cause analysis', 'Guide'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['70%', '30%'],
    rowdata: [],
  },

  organization_history: {
    title: 'Issue History',
    visibleTitle: true,
    existTooltip: false,
    tooltip: 'Organization Issue History tooltip message..!!',
    header: ['Issue ID', 'Date', 'Lv', 'Cnty.', 'Issue Title', 'Status', 'user Name', 'Function Name', 'Function Parameter', 'Assign', 'Modifier', 'Jira URL', 'Action Details'],
    cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['5%', '4%', '2%', '3%', '14%', '5%', '5%', '12%', '15%', '5%', '9%', '6%', '15%'],
    rowdata: [],
  },

  /* Deployment Issues */
  deployment_issues: {
    tableType: TABLE_TYPE.CHECKBOX_SELECTION,
    title: 'Issue Status',
    visibleTitle: false,
    existTooltip: false,
    tooltip: '',
    header: ['Issue ID', 'Date', 'Lv', 'Issue Title', 'Status', 'Job Name', 'Start Date', 'End Date', 'Executeion Time', 'Assign', 'Modifier', 'Jira URL', 'Action Details'],
    cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.ASSIGN_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL],
    dropdownItem: [
      { index: 'Unaddressed', name: 'Unaddressed' },
      { index: 'Processing', name: 'Processing' },
      { index: 'Resolve', name: 'Resolve' },
    ],
    assignDropdownItem: [],
    cellWidth: ['5%', '4%', '2%', '15%', '5%', '19%', '5%', '5%', '7%', '5%', '7%', '6%', '15%'],
    rowdata: [],
  },
  deployment_description: {
    title: 'description',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'deployment Issues tooltip message..!!',
    header: ['Cause analysis', 'Guide'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['70%', '30%'],
    rowdata: [],
  },

  deployment_history: {
    title: 'Issue History',
    visibleTitle: true,
    existTooltip: false,
    tooltip: 'Issue History tooltip message..!!',
    header: ['Issue ID', 'Date', 'Lv', 'Issue Title', 'Status', 'Job Name', 'Start Date', 'End Date', 'Executeion Time', 'Assign', 'Modifier', 'Jira URL', 'Action Details'],
    cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['5%', '4%', '2%', '15%', '5%', '19%', '5%', '5%', '7%', '5%', '7%', '6%', '15%'],
    rowdata: [],
  },
};
