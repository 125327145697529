import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import IssTabs from '../common/IssTabs';
import IssTab from '../common/IssTab';
import StatisticsTab from './StatisticsTab';
import StatisticsBeaconTab from './StatisticsBeaconTab';
import { ISS_BASE_URL } from '../../../constants/index';
import { getHeaders } from '../../../util/actionUtil';
import DropDown from '../common/DropDown';
import DropDownForSearch from '../common/DropDownForSearch';
import RadioDropDown from '../common/RadioDropDown';
import DefaultButton from '../common/DefaultButton';
import Alert from '../modal/Alert';
import DatePickerStatistics from '../common/DatePickerStatistics';
import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/Info";
import Info from "../modal/Info";
import TutorialPage from '../TutorialPage';

const StatisticsDetail = ({}) => {
  const [pageCurrentTab, pageClickTab] = useState(0);
  const [currentItem, changeCurrentItem] = useState({ issueId: '', issueCode: '' });
  const [contentIssueCount, setContentIssueCount] = useState({ total: 0, statisticsError: 0, beaconError: 0 });
  const [tabMenus, setTabMenus] = useState([]);
  const [currentFilter, setCurrentFilter] = useState({date:'', country:'', provider:'', contentName: '', type:''});
  const [paramRegions, setParamRegions] = useState([]);
  const [paramCountries, setParamCountries] = useState([]);
  const [paramProviders, setParamProviders] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const subTypeName = ['statistics', 'beacon'];
  const [contentNameInput, setContentNameInput] = useState();
  const [typeList, setTypeList] = useState({ dropdownList: [] });
  const [paramTypeList, setParamTypeList] = useState([]);
  const [checkedTypeList, setCheckedTypeList] = useState([]);
  const [checkedCountryList, setCheckedCountryList] = useState([]);
  const [checkedProviderList, setCheckedProviderList] = useState([]);
  const [searchType, setSearchType] = useState({ dropdownList: [] });
  const [paramSearchType, setParamSearchType] = useState('Content Name');
  const [beaconTypes, setBeaconTypes] = useState({ dropdownList: [] });
  const [checkedBeaconTypes, setCheckedBeaconTypes] = useState([]);
  const [paramBeaconTypes, setParamBeaconTypes] = useState([]);
  const [detectingTypes, setDetectingTypes] = useState({ dropdownList: [] })
  const [checkedDetectingTypes, setCheckedDetectingTypes] = useState([]);
  const [paramDetectingTypes, setParamDetectingTypes] = useState([]);
  const dataSourceTypes = ['channel', 'homeapp', 'program', 'system']
  const detectingConditionTypes = ['format', 'range', 'inc', 'dec']
  const [calendarValue, setCalendarValue] = useState([]);
  const [paramCalendar, setParamCalendar] = useState([]);
  const [isParamCalendar, setIsParamCalendar] = useState(true);
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState([]);
  const [regions, setRegions] = useState({'dropdownList': [], 'checkedList' : []});
  const [countries, setCountries] = useState({'dropdownList': [], 'checkedList' : []});
  const [providers, setProviders] = useState({'dropdownList': [], 'checkedList' : []});
  const [searchFilter, setSearchFilter] = useState({});
  const [groups, setGroups] = useState({ dropdownList: [], checkedList: [] });
  const [checkedGroups, setCheckedGroups] = useState([])
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (countries.dropdownList && countries.dropdownList.length > 0 && checkedCountryList && checkedCountryList.length > 0) {
      setCountries({'dropdownList' : countries.dropdownList, 'checkedList' : checkedCountryList});
      setCheckedCountryList([]);
    }
  }, [countries]);

  useEffect(() => {
    if (providers.dropdownList && providers.dropdownList.length > 0 && checkedProviderList && checkedProviderList.length > 0) {
      setProviders({'dropdownList' : providers.dropdownList, 'checkedList' : checkedProviderList});
      setCheckedProviderList([]);
    }
  }, [providers]);

  useEffect(() => {
    getText();
    if(location.state === undefined){
      getStatisticsErrorCount();
    }
    getSearchFilter();
    setTypeList({ dropdownList: ['channel','vod'] });
    setSearchType({ dropdownList: ['Content Name', 'Issue Id', 'Issue Code', 'Indicator'] });
    setBeaconTypes({ dropdownList: [...dataSourceTypes] })
    setDetectingTypes({ dropdownList: [...detectingConditionTypes] })
  }, []);

  useEffect(() => {
    setCurrentFilter({subType: subTypeName[pageCurrentTab], date: '', country: '', provider: '', groupList:[],
      contentName: '', type:'', searchType:'', dataSourceType: [], detectingConditionType: []});
  }, [pageCurrentTab]);

  const getStatisticsErrorCount = (date, country, provider, groupList, contentName, type, searchType, dataSourceType,
                                   detectingConditionType) => {
    if(date === null || date === '' || date === undefined) date = defaultRangeDate();
    if(country === null || country === '' || country === 'All' || country === undefined) country = [];
    if(provider === null || provider === '' || provider === undefined) provider = [];
    if(groupList === null || groupList === '' || groupList === undefined) groupList = [];
    if(contentName === null || contentName === undefined) contentName = '';
    if(type === null || type === '' || type === undefined) type = [];
    if(dataSourceType === null || dataSourceType === '' || dataSourceType === undefined) dataSourceType = [];
    if(detectingConditionType === null || detectingConditionType === '' || detectingConditionType === undefined) detectingConditionType = [];

    let body = {
      cpIdList: provider,
      countryCodeList: country,
      contentName: contentName,
      groupList: groupList,
      typeList: type,
      searchType: searchType,
      dataSourceTypeList: dataSourceType,
      detectingConditionTypeList: detectingConditionType,
      startDate: date[0],
      endDate: date[1],
    };
    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/errorCount`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(body => {
        const statisticsError = body.find(item => item.issueName === 'statistics');
        const beaconError = body.find(item => item.issueName === 'beacon');
        const totalCount = statisticsError.issueCount + beaconError.issueCount;
        setContentIssueCount({ total: beaconError.issueCount, statisticsError: statisticsError.issueCount, beaconError: beaconError.issueCount });
      })
      .catch(error => {
        console.log(`getStatisticsErrorCount error: `, error);
      });
  };

  useEffect(() => {
    const tabMenu = [{ name: `Statistics Change` },
    { name: `Beacon Data` }];
    setTabMenus(tabMenu);
  }, [contentIssueCount]);

  useEffect(() => {
    if ( searchFilter.countryByRegion ) {
      let dropdownCountries = [];
      if (paramRegions && paramRegions.length > 0){
        dropdownCountries = searchFilter.countryByRegion.filter(f => paramRegions.includes(f.groupName)).map(region =>
          region.countryList.map(map => ({ id: map.countryCode, value : map.fullName }))).reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownCountries.sort((a, b) => a.value.localeCompare(b.value));
      }
      // Unknown
      if (dropdownCountries.length > 0) {
        dropdownCountries.push({ id: 'Unknown', value : 'Unknown' });
      }

      setCountries({'dropdownList': dropdownCountries, 'checkedList' : [] });
    }
  }, [ paramRegions ]);

  useEffect(() => {
    if ( searchFilter.countryByRegion &&  searchFilter.providerByCountry) {
      let dropdownProviders = [];
      if (paramCountries && paramCountries.length > 0 && paramCountries.filter(item => item != 'Unknown').length > 0) {
        let ProviderList = searchFilter.providerByCountry.filter(f => paramCountries.filter(item => item != 'Unknown').includes(f.countryCode)).map(map =>
          map.providerList.map(pr => ({ id: pr.providerId, value : pr.providerName }))).reduce(function (acc, cur) { return acc.concat(cur); });

        dropdownProviders = [...new Set(ProviderList.map((list) => JSON.stringify(list)))].map((list) => JSON.parse(list));
      }
      setProviders({ 'dropdownList': dropdownProviders, 'checkedList' : checkedProviderList });
    }
  }, [ paramCountries ]);

  const getSearchFilter = () => {
    fetch(`${ISS_BASE_URL}/api/common/search-filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSearchFilter({
          countryByRegion : body.countryByRegion
          , providerByCountry : body.providerByCountry
        })

        let dropdownRegion = [];
        if ( body.countryByRegion ) {
          dropdownRegion = Array.from(new Set(body.countryByRegion.map(map => map.groupName )));
          setGroups({ dropdownList: dropdownRegion, checkedList: dropdownRegion })
          dropdownRegion = dropdownRegion.map(map =>({ id: map, value : map }));
          dropdownRegion.sort((a, b) => a.value.localeCompare(b.value));
        }
        setRegions({ 'dropdownList': dropdownRegion, 'checkedList' : [] });
      } else {
        setAlertTitle('Error');
        setAlertMessage('[getSearchFilter : error]');
        setIsAlert(true);
      }
    })
    .catch(error => {
      console.log("getSearchFilter - error: " , error);
      setAlertTitle('Error');
      setAlertMessage('[getSearchFilter : error]');
      setIsAlert(true);
    }).finally(() => {});
  };

  const issueSearchByButton = (item) => {
    if (pageCurrentTab === 0 && (paramRegions.length === 0 || paramCountries.length === 0 || paramProviders.length === 0 || paramTypeList.length === 0)) {
      const showMessage = paramRegions.length === 0 ? 'region' : paramCountries.length === 0 ? 'country' : paramTypeList.length === 0 ? 'type' : 'cp';
      setAlertTitle('Warning');
      setAlertMessage(`Please select any ${showMessage}`);
      onOpenAlert();
    } else if (pageCurrentTab === 1 && (checkedGroups.length === 0 || paramBeaconTypes.length === 0 || paramDetectingTypes.length === 0)) {
      const showMessage = checkedGroups.length === 0 ? 'Group' : paramBeaconTypes.length === 0 ? 'Beacon Type' : 'Detecting Type'
      setAlertTitle('Warning');
      setAlertMessage(`Please select any ${showMessage}`);
      onOpenAlert();
    } else {
      if (pageCurrentTab === 1) {
        getStatisticsErrorCount('', '', '', checkedGroups, '', '', '', paramBeaconTypes, paramDetectingTypes);
      } else {
        getStatisticsErrorCount(calendarValue, paramCountries, paramProviders, checkedGroups, contentNameInput, paramTypeList, paramSearchType, [], []);
      }

      setCurrentFilter({subType: subTypeName[pageCurrentTab], date: calendarValue, country: paramCountries, provider: paramProviders,
        groupList: checkedGroups, contentName: contentNameInput == null ? '' : contentNameInput.trim(), type: paramTypeList, searchType: paramSearchType,
        dataSourceType: paramBeaconTypes, detectingConditionType: paramDetectingTypes, pagingType: item});
    }
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const tabChangeHandler = (selectedIdx, _data) => {
    if (pageCurrentTab !== selectedIdx) {
    changeCurrentItem({ issueId: '', issueCode: '' });
    setCurrentFilter({date:'', country: '', provider: '', groupList:[], contentName: '', type:'', searchType: '',
      dataSourceType: [], detectingConditionType: []});
    setContentNameInput('');
    setCheckedCountryList([]);
    setCheckedProviderList([]);
    setCheckedTypeList([]);
    setCheckedBeaconTypes([]);
    setCheckedDetectingTypes([]);
    getStatisticsErrorCount();
    setCalendarValue([]);
    setParamCalendar([]);
    pageClickTab(selectedIdx);
    }
  };

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location && location.state) {
      const selectedItem = JSON.parse(location.state.param);
      if (selectedItem.paramFilter) {
        if(!searchFilter.countryByRegion){
          fetch(`${ISS_BASE_URL}/api/common/search-filter`, {
            method: 'GET',
            headers: getHeaders(),
          })
          .then(res => res.json())
          .then(body => {
            if (body.result === 'SUCCESS') {
              // Group Filter
              let groupArr = [];
              let countryByRegion = Array.from(new Set(body.countryByRegion.map(map => map.groupName)));
              const checkedGroupList = selectedItem.paramFilter.groupList.filter(group => countryByRegion.includes(group))
              selectedItem.paramRegions && selectedItem.paramRegions.forEach(group => {
                const groupItem = countryByRegion && countryByRegion.find(item => item === group);
                groupArr.push(groupItem);
              });
              setGroups({ dropdownList: countryByRegion, checkedList: checkedGroupList })
              countryByRegion = countryByRegion.map(map =>({ id: map, value : map }));
              countryByRegion.sort((a, b) => a.value.localeCompare(b.value));
              setRegions({ 'dropdownList': countryByRegion, 'checkedList' : groupArr });
              // Country Filter
              let providerArr = [];
              selectedItem.paramFilter.country && selectedItem.paramFilter.country != 'All'
              && selectedItem.paramFilter.country.forEach(paramCountry => {
                body.countryByRegion.filter(f => selectedItem.paramRegions.includes(f.groupName)).map(region => {
                  region.countryList.filter(f => f.countryCode.includes(paramCountry)).map(country => {
                    providerArr.push({ id: country.countryCode, value : country.fullName });
                  });
                });
              });
              let checkedProviderArr = providerArr.map(map => map.id);
              setCountries({ 'dropdownList': providerArr, 'checkedList': checkedProviderArr });
              setCheckedCountryList(checkedProviderArr);
            } else {
              setAlertTitle('Error');
              setAlertMessage('[location getSearchFilter : error]');
              setIsAlert(true);
            }
          })
          .catch(error => {
            console.log("location getSearchFilter - error: " , error);
            setAlertTitle('Error');
            setAlertMessage('[location getSearchFilter : error]');
            setIsAlert(true);
          }).finally(() => {});
        }

        setParamCalendar(selectedItem.paramFilter.date);
        setIsParamCalendar(false);
        setContentNameInput(selectedItem.paramFilter.contentName);
        setCheckedTypeList(selectedItem.paramFilter.type);
        setCheckedBeaconTypes(selectedItem.paramFilter.dataSourceType);
        setCheckedDetectingTypes(selectedItem.paramFilter.detectingConditionType);
        changeCurrentItem({ issueId: selectedItem.issueId, issueCode: selectedItem.issueCode });
        setCurrentFilter({subType: subTypeName[pageCurrentTab],
          date : selectedItem.paramFilter.date,
          country: selectedItem.paramFilter.country === 'All' ? '' : selectedItem.paramFilter.country ,
          provider: selectedItem.paramFilter.provider,
          groupList: selectedItem.paramFilter.groupList,
          contentName: selectedItem.paramFilter.contentName,
          type: selectedItem.paramFilter.type,
          searchType: paramSearchType,
          dataSourceType: selectedItem.paramFilter.dataSourceType,
          detectingConditionType: selectedItem.paramFilter.detectingConditionType
        });
        getStatisticsErrorCount(selectedItem.paramFilter.date, selectedItem.paramFilter.country, selectedItem.paramFilter.provider,
          selectedItem.paramFilter.groupList, selectedItem.paramFilter.contentName,
          selectedItem.paramFilter.type, paramSearchType, selectedItem.paramFilter.dataSourceType,
          selectedItem.paramFilter.detectingConditionType);
      } else {
        changeCurrentItem({ issueId: selectedItem.issueId, issueCode: selectedItem.issueCode });
        setCurrentFilter({subType: subTypeName[pageCurrentTab],
          date: [],
          country: '' ,
          provider: '' ,
          groupList: [] ,
          contentName: '' ,
          type: '' ,
          searchType: '' ,
          dataSourceType: [] ,
          detectingConditionType: [] ,
        });
        getStatisticsErrorCount();
      }
      if (selectedItem.category === 'statistics') {
        pageClickTab(0);
      } else if (selectedItem.category === 'beacon') {
        pageClickTab(1);
      }
    }
  }, [location]);

  useEffect(() => {
    if(!isParamCalendar){
      setIsParamCalendar(true);
    }
  }, [isParamCalendar]);

  const handleContentNameInputChange = event => {
    setContentNameInput(event.target.value);
  };

  const handleBackButton = (item) => {
    const selectedLocation = { type : item };

    history.push(`/ISS/IssDashboard`, {
      param: JSON.stringify(selectedLocation),
    });
  };

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      issueSearchByButton();
    }
  };

  const onOpenInfo = () => {
    setIsOpenInfo(true);
  }

  const onCloseInfo = () => {
    setIsOpenInfo(false);
  }

  const getText = () => {
    fetch(`${ISS_BASE_URL}/api/common/text`, {
      method: 'GET',
      headers: getHeaders(),
    })
        .then(res => res.json())
        .then(body => {
          if (body.result === 'SUCCESS') {
            setInfoMessage([
              {name: 'Statistics Change',
                message: body.text.DETAIL_STATISTICS_STATISTICS_ISSUE_INFO ? body.text.DETAIL_STATISTICS_STATISTICS_ISSUE_INFO : ''},
              {name: 'Beacon Data',
                message: body.text.DETAIL_STATISTICS_BEACON_ISSUE_INFO ? body.text.DETAIL_STATISTICS_BEACON_ISSUE_INFO : ''},
            ])
          } else {
            setAlertTitle('Error')
            setAlertMessage('Failed to get info message')
          }
        })
        .catch(e => {
          console.log('getText: ', e)
        }).finally(() => {})
  };

  const defaultRangeDays = 14;
  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());
    const thirtyDaysAgo = new Date(utcToday);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);
    const utcTodayString = utcToday.toISOString().substring(0, 10);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0, 10);
    return [thirtyDaysAgoString, utcTodayString];
  };

  const handleOpen = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <>
    <div className="iss-main">
      <div className="main-container">
        <div className="inner">
          <div className="sub-detail-wrapper sub-detail-statistics">
            <div className="head">
              <div className="title">
                <button className="btn-tutorial-2" onClick={handleOpen}/>
                <h1>Statistics Detail ({Math.round(contentIssueCount.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})</h1>
              </div>
              <div className="back" onClick={() => handleBackButton('Statistics')}>
                <button></button>
              </div>
              <Chip label={"Info"} icon={<InfoIcon/>} className={'info'} onClick={onOpenInfo} />
            </div>
            <div className="box-components">
              <div className="contents-section-wide">
                <div className="ui-dropdown-box">
                  {pageCurrentTab === 0 ? (
                    <>
                    <DatePickerStatistics rangeDays={defaultRangeDays} paramCalendar={!isParamCalendar ? paramCalendar : []} dateChangeHandler={(newDate) => setCalendarValue(newDate)}/>
                    <DropDownForSearch dropDownItem={regions} title="Group" onChangeDropDown={setParamRegions} />
                    <DropDownForSearch dropDownItem={countries} title="Countries" onChangeDropDown={setParamCountries} />
                    <DropDownForSearch dropDownItem={providers} title="CPs" onChangeDropDown={setParamProviders} />
                    <DropDown item={typeList} title="Types" checkedList={checkedTypeList} onChangeDropDown={setParamTypeList} />
                    <RadioDropDown item={searchType} onChangeDropDown={setParamSearchType} />
                    <div className="content-name">
                      <input type="text" value={contentNameInput} placeholder={paramSearchType}
                        onChange={event => handleContentNameInputChange(event)}
                        onKeyPress={handleOnKeyPress}
                      />
                    </div>
                    </>
                  ) : pageCurrentTab === 1 ? (
                    <>
                      <DropDown item={beaconTypes} title="Beacon Types" checkedList={checkedBeaconTypes} onChangeDropDown={setParamBeaconTypes}/>
                      <DropDown item={detectingTypes} title="Detecting Types" checkedList={checkedDetectingTypes} onChangeDropDown={setParamDetectingTypes}/>
                      <DropDown item={groups} title="Group" checkedList={groups.checkedList} onChangeDropDown={setCheckedGroups} />
                    </>
                  ) : (
                    < ></>
                  )
                  }
                  <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={issueSearchByButton}/>
                </div>
                <IssTabs tabMenus={tabMenus} currentTab={pageCurrentTab} className={'ui-tab-type03'} onChange={tabChangeHandler}>
                  <IssTab isActive={pageCurrentTab === 0}>
                    <StatisticsTab currentItem={currentItem.issueId !== '' ? currentItem : null}
                      currentFilter={currentFilter} currentTab={pageCurrentTab} searchEvent={issueSearchByButton}
                      defaultRangeDate={defaultRangeDate()}/>
                  </IssTab>
                  <IssTab isActive={pageCurrentTab === 1}>
                  <StatisticsBeaconTab currentItem={currentItem.issueId !== '' ? currentItem : null}
                      currentFilter={currentFilter} currentTab={pageCurrentTab} searchEvent={issueSearchByButton}/>
                  </IssTab>
                </IssTabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {isAlert && <Alert isOpen={isAlert} onClose={onCloseAlert} title={alertTitle} message={alertMessage}/>}
    {isOpenInfo && <Info isOpen={isOpenInfo} title={'Statistics Issue Info'} message={infoMessage} onClose={onCloseInfo}
                         className="modal-alert-long" />}
    {isActive && <TutorialPage data={'statisticsDetail'} isActive={isActive} onClose={handleClose} />}
  </>
  );
};

export default StatisticsDetail;
