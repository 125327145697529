import * as actions from '../../actions/actionTypes';

const initialState = [' '];

export default function cplist(state = initialState, action) {
  switch (action.type) {
    case actions.GET_CPLIST:
      /*let def = [
        {
          cpCode: 'ALL',
          cpName: 'ALL',
        },
      ];
      return action.payload.length ? def.concat(action.payload) : state;*/
      return action.payload.length ? action.payload.map(n => n.cpCode) : state;
    default:
      return state;
  }
}
