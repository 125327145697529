import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Display from './Display';
import ManualTable from './ManualTable';
import { request } from '../../../util/request';
import { Dialog, DialogActions } from '@material-ui/core';
import { getTabCategoryResultAction } from '../../../actions/homeAppActions';

function ManualResult({ id, history, cntry, cat, result, handleSetResult, renderOrder, selectResult, onClickAdd, onClickDelete, homeAppResult, renderOrderEdit, getTabCategoryResultAction, handleSetInsertTableData, handleSetTypeData, typeData, insertTableData, setTypeData }) {
  const [onSaveOpen, setOnSaveOpen] = React.useState(false);
  const [duplicateOrder, setDuplicateOrder] = React.useState();
  const [swapData, setSwapData] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]);

  const handleresult = (result) => {
    handleSetResult(JSON.parse(JSON.stringify([...result])));
  }
  React.useEffect(() => {
    handleresult(JSON.parse(JSON.stringify(homeAppResult)));
  }, [homeAppResult]);

  const clickSave = async e => {
    e.preventDefault();
    const uniqueValues = new Set(result.map(v => parseInt(v.result_order)));
    if (result.length < 1) alert('The number of result is less than 1 ! Please add at lease one content !');
    else if (result.find(n => n.result_order === '')) alert('Some order is empty ! Please enter the order !');
    else if (uniqueValues.size < result.length) {
      const duplicateCounts = result.reduce((acc, item) => {
        const order = item.result_order;
        acc[order] = (acc[order] || 0) + 1;
        return acc;
      }, {});
      const duplicates = Object.keys(duplicateCounts)
        .filter(order => duplicateCounts[order] > 1)
        .map(order => parseInt(order));
      setDuplicateOrder(duplicates);
      setOnSaveOpen(true);
    }
    else {
      setDuplicateOrder();
      setOnSaveOpen(true)
    }
  };

  function reOrderArray(orgArray, modifiedArray) {
    const newData = modifiedArray.filter(modifiedItem => !orgArray.some(orgItem => orgItem.content_id === modifiedItem.content_id));
    const originalData = modifiedArray.filter(modifiedItem => orgArray.some(orgItem => orgItem.content_id === modifiedItem.content_id && orgItem.result_order === modifiedItem.result_order));
    const modifiedData = modifiedArray.filter(modifiedItem => orgArray.some(orgItem => orgItem.content_id === modifiedItem.content_id && orgItem.result_order !== modifiedItem.result_order));
    newData.map(item => item.original = 'new');
    modifiedData.map(item => item.original = 'new');
    originalData.map(item => item.original = 'old');
    const margeOriginalWithAdded = [...newData, ...modifiedData, ...originalData];
    margeOriginalWithAdded.sort((a, b) => {
      if(a.result_order !== b.result_order) return a.result_order - b.result_order;
      if (a.original === 'new' && b.original !== 'new') return -1;
      if (a.original !== 'new' && b.original === 'new') return 1;
      return a.name.localeCompare(b.name);
    });
    let finalReorderArray = margeOriginalWithAdded.map((item, index) => ({ ...item, result_order: index + 1 }));
    return finalReorderArray;
  }

  const onConfirmReorder = async e => {
    const resultList = [...result]
    const newResultList = resultList.map((n, index) => {
      return n;
    });
    const OriginalSwap = swapData.length > 0 ? swapData : originalData.length > 0 ? originalData : homeAppResult;
    let result_final_data = reOrderArray(OriginalSwap, newResultList);
    const newResult = result_final_data
      .map(n => {
        const { country_code, cp_idx, provider_idx, type, content_id, result_order, name } = n;
        let categoryResult = { categoryIdx: id, countryCode: country_code };
        categoryResult.providerIdx = cp_idx || provider_idx;
        categoryResult.contentType = type;
        categoryResult.contentId = content_id;
        categoryResult.resultOrder = result_order;
        categoryResult.name = name;
        return categoryResult;
      });

    const reOrderResult = resultList.map((n, index) => {
      return n;
    });
    let result_ReorderData = reOrderArray(OriginalSwap, reOrderResult);
    const isSuccess = await request(`/api/category/${id}/result?cntry=${cntry}`, 'PATCH', newResult);
    if (isSuccess) {
      alert('Successfully saved !');
      getTabCategoryResultAction(cntry, id);
      handleSetResult(result_ReorderData);
    }
    setTypeData();
    setOnSaveOpen(false);
  };
  const onConfirm = async e => {
    const newResult = result.map((n, index) => {
      const { country_code, cp_idx, provider_idx, type, content_id, result_order } = n;
      let categoryResult = { categoryIdx: id, countryCode: country_code };
      categoryResult.providerIdx = cp_idx || provider_idx;
      categoryResult.contentType = type;
      categoryResult.contentId = content_id;
      categoryResult.resultOrder = result_order;
      return categoryResult;
    }).sort((a, b) => a.resultOrder - b.resultOrder).map((item, index) => ({ ...item, resultOrder: index + 1 }));

    const isSuccess = await request(`/api/category/${id}/result?cntry=${cntry}`, 'PATCH', newResult);
    const reOrderResult = result.map((n, index) => {
      const { country_code, cp_idx, cp_id, provider_idx, type, content_id, genre, image, last_chg_date, name, release_date, season, tab_idx, result_order } = n;
      let categoryResult = {
        categoryIdx: id, country_code: country_code, cp_id: cp_id, genre: genre, image: image, last_chg_date: last_chg_date, name: name, release_date: release_date, season: season, tab_idx: tab_idx,
        type: type, content_id: content_id, result_order: result_order, provider_idx: cp_idx || provider_idx
      };
      return categoryResult;
    }).sort((a, b) => a.result_order - b.result_order).map((item, index) => ({ ...item, result_order: index + 1 }));
    if (isSuccess) {
      alert('Successfully saved !');
      handleSetResult(reOrderResult);
    }
    handleSetInsertTableData(reOrderResult);
    setOriginalData(JSON.parse(JSON.stringify(reOrderResult)));
    setSwapData([]);
    setTypeData();
    setOnSaveOpen(false);
  };
  return (
    <React.Fragment>
      <div className="arrow_btn_area">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Button className="btn_move piece" title="Click this to add content" onClick={onClickAdd} />
        <br />
        <Button className="btn_move remove" title="Click this to remove content" onClick={onClickDelete} />
      </div>
      <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list'} style={{ marginLeft: '6%' } /* margin-left: 6%;+'tbl_no_data'*/}>
        <span>
          <div className="mapping_tbl_header_manual">
            <Display cat={cat}></Display>
            <br />
            <br />
            <br />
            <br />
          </div>
        </span>
        <span id="popup"></span>
        <ManualTable
          isResult
          data={result}
          renderOrder={renderOrder}
          renderOrderEdit={renderOrderEdit}
          select={selectResult}
          setSwapData={setSwapData}
          handleSetInsertTableData={handleSetInsertTableData}
          handleSetTypeData={handleSetTypeData}
          dlagDropFlag={true}
          options={{
            selection: true,
            showTextRowsSelected: false,
            //isLoading: true,
            //search: false,
            paging: true,
            pageSize: 15,
            pageSizeOptions: [5, 15, 30, 50, 100],
            doubleHorizontalScroll: true,
            maxBodyHeight: 500,
            minBodyHeight: 500,
            //showEmptyDataSourceMessage: false,
            rowStyle: rowData => ({
              backgroundColor: rowData.change ? '#fef5f8' : '#FFF',
              color: rowData.change ? '#d8295c' : '#484848',
            }),
          }}
        />
        <div className="btn_set_wrap">
          <form id="addRowFrm" autoComplete="off">
            <div className="btn_set_wrap">
              <Button className="btn_color2" onClick={clickSave}>
                save
              </Button>
              <Button
                className="btn_color2"
                onClick={() => {
                  history.push('/HOME-APP/tab');
                }}>
                Back
              </Button>
            </div>
          </form>
        </div>
        {duplicateOrder != null ?
          <Dialog open={onSaveOpen} aria-labelledby="confirm-dialog">
            <div className="pop_size_small">
              <React.Fragment>
                <div className="ico_condition1"></div>
                <p className="pop_comment">Some order is duplicated ({duplicateOrder.join(', ')})! Do you want to reorder and save?</p>
                <DialogActions>
                  <div className="btn_set_wrap alC">
                    <Button color="primary" onClick={onConfirmReorder} className="btn_color2">
                      OK
                    </Button>
                    <Button
                      autoFocus
                      color="primary"
                      onClick={() => setOnSaveOpen(false)}
                      className="btn_color1">
                      Cancel
                    </Button>
                  </div>
                </DialogActions>
              </React.Fragment>
            </div>
          </Dialog> :
          <Dialog open={onSaveOpen} aria-labelledby="confirm-dialog">
            <div className="pop_size_small">
              <React.Fragment>
                <div className="ico_condition1"></div>
                <p className="pop_comment">Do you want to save the result ?</p>
                <DialogActions>
                  <div className="btn_set_wrap alC">
                    <Button color="primary" onClick={onConfirm} className="btn_color2">
                      OK
                    </Button>
                    <Button
                      autoFocus
                      color="primary"
                      onClick={() => setOnSaveOpen(false)}
                      className="btn_color1">
                      Cancel
                    </Button>
                  </div>
                </DialogActions>
              </React.Fragment>
            </div>
          </Dialog>
        }
      </div>
    </React.Fragment>
  );
}
ManualResult.propTypes = {};
const mapStateToProps = state => ({
  homeAppResult: state.homeApp.manualResultList,
  regionList: state.homeApp.regionList,
  param: state.homeApp.param,
  tabCategory: state.homeApp.tabCategory,
});
const mapDispatchToProps = dispatch => ({
  // getTabCategoryAction(region, status) {
  //   dispatch(getTabCategoryAction(region, status));
  // },
  getTabCategoryResultAction(cntry, id) {
    dispatch(getTabCategoryResultAction(cntry, id));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManualResult));
