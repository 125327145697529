// area : none - 화면에 region or group filter를 보여 주지 않음
//      : region - region and country filter
//      : group - group and country filter
// interval : h or d
export const FeedBasicFilter = {
  basicFilter: { area: 'region', provider: true, platform: false, interval: true },
  customCheckboxFilter: [
    {
      api : ['/programschedules', '/channels', '/vods']
    },
    {
      count : ['total', 'fail']
    }
  ],
  customRadioFilter: [],
  customInputFilter: [],
  compareFilter: ['None', 'Country', 'Provider'],
  selectedFilter: null
};

export const FeedTopTrendFilter = {
  basicFilter: { area: 'region', provider: true, platform: false, interval: false },
  customCheckboxFilter: [
    {
      api : ['/programschedules', '/channels', '/vods']
    },
  ],
  customRadioFilter: [
    {
      top : ['total', 'fail']
    },
    {
      limit : ['5', '7', '10']
    },
  ],
  customInputFilter: [],
  compareFilter: ['None', 'Country'],
  selectedFilter: null
};

export const FeedDuplicatedErrorFilter = {
  basicFilter: { area: 'region', provider: true, platform: false, interval: false },
  customCheckboxFilter: [], // 서버에서 가져온 값으로 설정
  customRadioFilter: [],
  customInputFilter: [],
  compareFilter: ['None'],
  selectedFilter: null
};

export const FeedResolveCountFilter = {
  basicFilter: { area: 'region', provider: true, platform: false, interval: false },
  customCheckboxFilter: [],
  customRadioFilter: [],
  customInputFilter: [],
  compareFilter: ['None'],
  selectedFilter: null
};