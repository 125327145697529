import React, { useEffect, useState } from 'react';
import DefaultButton from '../../common/DefaultButton';
import NarrowDropDown from '../../common/NarrowDropDown';
import DropDownForSearch from '../../common/DropDownForSearch';
import LineChart from '../../common/LineChart';
import BarChart from '../../common/BarChart';
import { CHART_COLORS, CHART_LINE_GRID_COLOR } from '../../common/ChartConsts';
import IssTable from '../../common/IssTable';
import { dashboard_statistics_tabledata,
  statistics_chartdata_value_dataset,
  statistics_chartdata_change_dataset,
  statistics_chartdata_avg_dataset,
  dashboard_statistics_chartdata,
  dashboard_statistics_beacon_tabledata,
  dashboard_statistics_beacon_chart,
  statistics_chartdata_bar_dataset,
  beacon_chartdata_value_dataset,
  beacon_chartdata_change_dataset
 } from './StatisticsDashboardData';
import IssPageTabs from '../../common/IssPageTabs';
import IssPageTab from '../../common/IssPageTab';
import LoadingIndicator from '../../../../common/LoadingIndicator';
import { useHistory } from 'react-router-dom';
import Alert from '../../modal/Alert';
import { ISS_BASE_URL } from '../../../../constants/index';
import { getHeaders } from '../../../../util/actionUtil';
import DetailIconButton from "./DetailIconButton";
import DatePickerStatistics from '../../common/DatePickerStatistics';
import { getScalesMaxValue } from '../../utils/IssUtils';
import TutorialPage from '../../TutorialPage';

const StatisticsDashboard = ({ filterCallback }) => {
  const [currentTab, clickTab] = useState(0); //default tab 처리 dashboard statistics => 0 , beacon => 1
  const [isIssueDisplay, setIsIssueDisplay] = useState(false);
  const [isIssueChartDisplay, setIsIssueChartDisplay] = useState(false);
  const [isIssueBarChartDisplay, setIsIssueBarChartDisplay] = useState(false);
  const [isBeaconDisplay, setIsBeaconDisplay] = useState(false);
  const [isBeaconChartDisplay, setIsBeaconChartDisplay] = useState(false);
  const [paramRegions, setParamRegions] = useState([]);
  const [paramCountries, setParamCountries] = useState([]);
  const [paramProviders, setParamProviders] = useState([]);
  const [beaconTypes, setBeaconTypes] = useState([]);
  const [detectingTypes, setDetectingTypes] = useState([]);
  const [paramBeaconTypes, setParamBeaconTypes] = useState([]);
  const [paramDetectingTypes, setParamDetectingTypes] = useState([]);
  const [currentFilter, setCurrentFilter] = useState({date:'', country:'', provider:'', contentName: '', type:''});
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState();
  const [isAlert, setIsAlert] = useState(false);
  const [statisticsInitFlag, setStatisticsInitFlag] = useState(true);
  const [beaconInitFlag, setBeaconInitFlag] = useState(true);
  const history = useHistory();
  const defaultChartValue = 25;
  const [issueList, setIssueList] = useState([]);
  const [issueListFetching, setIssueListFetching] = useState('init');
  const [currentIssueListTableData, setCurrentIssueListTableData] = useState({});
  const [beaconList, setBeaconList] = useState([]);
  const [beaconListFetching, setBeaconListFetching] = useState('init');
  const [currentBeaconListTableData, setCurrentBeaconListTableData] = useState({});
  const [lineChart, setLineChart] = useState([]);
  const [lineChartFetching, setLineChartFetching] = useState('init');
  const [barChart, setBarChart] = useState([]);
  const [barChartFetching, setBarChartFetching] = useState('init');
  const [beaconChart, setBeaconChart] = useState([]);
  const [beaconChartFetching, setBeaconChartFetching] = useState('init');
  const [contentNameInput, setContentNameInput] = useState('');
  const [typeList, setTypeList] = useState([]);
  const [paramTypeList, setParamTypeList] = useState([]);
  const [defaultDate, setDefaultDate] = useState([]);
  const [calendarValue, setCalendarValue] = useState([]);
  const [regions, setRegions] = useState({'dropdownList': [], 'checkedList' : []});
  const [countries, setCountries] = useState({'dropdownList': [], 'checkedList' : []});
  const [providers, setProviders] = useState({'dropdownList': [], 'checkedList' : []});
  const [searchFilter, setSearchFilter] = useState({});
  const [groups, setGroups] = useState({ dropdownList: [] });
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const defineClass = "ui-dropdown-type05";
  let tdMin = ' (hr)';

  const pageTabMenus = [
    {
      title: 'Statistics Change',
      tooltip: 'Detected issues when there is a significant increase or decrease in usability data for content(based on the average data of the past two weeks).',
    },
    {
      title: 'Beacon Data',
      tooltip: 'Detected issues when beacon data is abnormally accumulated.',
    },
  ];

  const dataSources = ['channel', 'homeapp', 'program', 'system']
  const detectingConditions = ['format', 'range', 'inc', 'dec']

  useEffect(() => {
    defaultRangeDate();
    getSearchFilter();
    setTypeList({ dropdownList: ['channel','vod'] });
    setBeaconTypes({ dropdownList: [...dataSources] })
    setDetectingTypes({ dropdownList: [...detectingConditions] })
  }, []);

  useEffect(() => {
    setCurrentFilter({date: calendarValue.length === 0 ? defaultRangeDate() : calendarValue , country: 'All', provider: '', ric: '', contentName: '', type:'',
      dataSourceType: [], detectingConditionType: [], groupList: []});
  }, [currentTab]);

  const valueInitialize = () => {
    setIsIssueChartDisplay(false);
    setIsIssueBarChartDisplay(false);
    setIsIssueDisplay(false);
    setIsBeaconDisplay(false);
    setIsBeaconChartDisplay(false);
  };

  useEffect(() => {
    if ( searchFilter.countryByRegion ) {
      let dropdownCountries = [];
      if (paramRegions && paramRegions.length > 0){
        dropdownCountries = searchFilter.countryByRegion.filter(f => paramRegions.includes(f.groupName))
        .map(region => region.countryList.map(map => ({ id: map.countryCode, value : map.fullName })))
        .reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownCountries.sort((a, b) => a.value.localeCompare(b.value));
      }
      // Unknown
      if (dropdownCountries.length > 0) {
        dropdownCountries.push({ id: 'Unknown', value : 'Unknown' });
      }

      setCountries({'dropdownList': dropdownCountries, 'checkedList' : [] });
    }
  }, [paramRegions]);

  useEffect(() => {
    if ( searchFilter.countryByRegion &&  searchFilter.providerByCountry) {
      let dropdownProviders = [];
      if (paramCountries && paramCountries.length > 0 && paramCountries.filter(item => item != 'Unknown').length > 0) {
        let ProviderList = searchFilter.providerByCountry.filter(f => paramCountries.filter(item => item != 'Unknown')
        .includes(f.countryCode)).map(map => map.providerList.map(pr => ({ id: pr.providerId, value : pr.providerName })))
        .reduce(function (acc, cur) { return acc.concat(cur); });
        dropdownProviders = [...new Set(ProviderList.map((list) => JSON.stringify(list)))].map((list) => JSON.parse(list));
      }
      setProviders({ 'dropdownList': dropdownProviders, 'checkedList' : [] });
    }
  }, [ paramCountries ]);

  useEffect(() => {
    if (currentFilter.country.length > 0) {
      valueInitialize();
      if(currentTab === 0 && defaultDate.length > 0) {
        getIssueListInfo('issue', currentFilter);
      } if(currentTab === 1) {
        getIssueListInfo('beaconIssue', currentFilter);
      }
      filterCallback(currentFilter);
    }
  }, [currentFilter]);

  const getSearchFilter = () => {
    fetch(`${ISS_BASE_URL}/api/common/search-filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSearchFilter({
          countryByRegion : body.countryByRegion
          , providerByCountry : body.providerByCountry
        })
        let dropdownRegion = [];
        // countryByRegion
        if ( body.countryByRegion ) {
          dropdownRegion = Array.from(new Set(body.countryByRegion.map(map => map.groupName )));
          setGroups({dropdownList: dropdownRegion})
          dropdownRegion = dropdownRegion.map(map =>({ id: map, value : map }));
          dropdownRegion.sort((a, b) => a.value.localeCompare(b.value));
        }
        setRegions({ 'dropdownList': dropdownRegion, 'checkedList' : [] });
      } else {
        setAlertTitle('Error');
        setAlertMessage('[getSearchFilter : error]');
        setIsAlert(true);
      }
    })
    .catch(error => {
      console.log("getSearchFilter - error: " , error);
      setAlertTitle('Error');
      setAlertMessage('[getSearchFilter : error]');
      setIsAlert(true);
    }).finally(() => {});
  };

  const getIssueListInfo = (type, filter) => {
    let startDate = filter.date[0];
    let endDate = filter.date[1];
    let country = filter.country;
    let provider = filter.provider;
    let ric = filter.ric;
    let contentName = filter.contentName;
    let typeList = filter.type;
    let beaconType = filter.dataSourceType;
    if (beaconType == null || beaconType.length === 0) beaconType = [...dataSources]
    let detectingType = filter.detectingConditionType;
    if (detectingType == null || detectingType.length === 0) detectingType = [...detectingConditions]
    let requestUrl = '';
    let issueListBody = {};
    if(country === null || country === '' || country === 'All') country = [];
    if(provider === null || provider === '') provider = [];
    if(ric === null || ric === '') ric = [];
    if(typeList === null || typeList === '') typeList = [];
    if(filter.date.length === 0){
      startDate = defaultDate[0];
      endDate = defaultDate[1];
    }

    if (type === 'issue') {
      setIssueListFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/statistics/dashboard/issue`;
      issueListBody = {
        startDate: startDate,
        endDate: endDate,
        cpIdList: provider,
        countryCodeList: country,
        contentName: contentName,
        typeList: typeList,
        searchType: "Content Name"};
    } else if (type === 'beaconIssue') {
      setBeaconListFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/statistics/dashboard/beaconIssue`;
      issueListBody = {
        dataSourceTypeList: beaconType,
        detectingConditionTypeList: detectingType,
        groupList: checkedGroups,
      };
    } else {
      console.error(`getIssueListInfo, type:${type} error`);
      return;
    }
    fetch(requestUrl, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(issueListBody),
    })
    .then(res => res.json())
    .then(body => {
      if (type === 'issue') {
        setIssueListFetching('done');
        setIssueList(body);
      } else if (type === 'beaconIssue') {
        setBeaconListFetching('done');
        setBeaconList(body);
      }
    })
    .catch(error => {
      console.log(`getIssueListInfo error: `, error);
    }).finally(() => {});
  };

  const getIssueChartInfo = (chart, item) => {
    let requestUrl = '';
    let issueListBody = {};

    if (chart === 'issueChart') {
      setLineChartFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/statistics/dashboard/issueChart`;
      issueListBody = {cpId: item.cpId,
        uniqueId: item.uniqueId,
        current: item.period,
        period: item.period,
        countryCode: item.countryCode,
        type: item.type,
        detectionItem: item.detectionItem.replace(tdMin,'')};
    } else if (chart === 'issueBarChart') {
      setBarChartFetching('loading');
      requestUrl = `${ISS_BASE_URL}/api/statistics/dashboard/issueBarChart`;
      issueListBody = {period: item.period, issueCode: item.issueCode, countryCode: item.countryCode};
    } else {
      console.error(`getIssueChartInfo, type:${chart} error`);
      return;
    }
    fetch(requestUrl, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(issueListBody),
    })
    .then(res => res.json())
    .then(body => {
      if (chart === 'issueChart') {
        setLineChartFetching('done');
        setLineChart(body);
      } else if (chart === 'issueBarChart') {
        setBarChartFetching('done');
        setBarChart(body);
      }
    })
    .catch(error => {
      console.log(`getIssueChartInfo error: `, error);
    }).finally(() => {});
  };

  const getIssueBeaconChartInfo = (item) => {
    const body = {
      dataSourceType: item.dataSourceType,
      detectionItem: item.detectionItem,
      datetime: item.detectionTime,
      groupName: item.groupName,
      detectingType: item.detectingConditionType
    }
    setBeaconChartFetching('loading');

    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/beacon/beaconChart`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
    .then(res => res.json())
    .then(body => {
      body['referenceAvg'] = item.referenceAvg
      setBeaconChartFetching('done');
      setBeaconChart(body);
    })
    .catch(error => {
      console.log(`getIssueBeaconChartInfo error: `, error);
    }).finally(() => {});
  };

  useEffect(() => {
    if (issueList && issueList.length > 0) {
      dashboard_statistics_tabledata.statistics_issues.rowdata = issueList.map(item => ( {
        row: [
          item.issueId,
          item.period,
          item.issueLevel,
          item.countryCode,
          item.type,
          item.contentName,
          item.cpId,
          item.detectionItem === 'total_duration' ? item.detectionItem.concat(tdMin) : item.detectionItem,
          item.detectionItem === 'total_duration' ?`${(Math.round(((item.nativeValue/60)/60)*10)/10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          (${(Math.round(((item.average/60)/60)*10)/10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`
              : `${Math.round(item.nativeValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (${Math.round(item.average).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`,
          `${Math.round(item.avgValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} %`,
            <DetailIconButton text={'Detail'} type={'button'} btnClass={'btn-icon-detail'} clickEvent={detailByButton} category='statistics' issueId={item.issueId} issueCode={item.issueCode}
                        paramFilter={currentFilter} paramRegions={paramRegions}/>,
          item.uniqueId
        ],
        cpId: item.cpId,
        period: item.period,
        countryCode: item.countryCode,
        type: item.type,
        detectionItem: item.detectionItem,
        uniqueId: item.uniqueId,
        issueCode: item.issueCode
      }));
      dashboard_statistics_tabledata.statistics_issues.rowdata[0].deeplinkFlag = true;
      getIssueChartInfo('issueChart', issueList[0]);
      getIssueChartInfo('issueBarChart', issueList[0]);
      setCurrentIssueListTableData(dashboard_statistics_tabledata.statistics_issues);
    } else {
      dashboard_statistics_tabledata.statistics_issues.rowdata = [];
      statistics_chartdata_value_dataset.data = [];
      statistics_chartdata_change_dataset.data = [];
      statistics_chartdata_avg_dataset.data = [];
      dashboard_statistics_chartdata.value_graph.options.scales.y.min = 0;
      dashboard_statistics_chartdata.value_graph.options.scales.y.max = defaultChartValue;
      dashboard_statistics_chartdata.value_graph.options.baseline.value = 0;
      dashboard_statistics_chartdata.changes_graph.options.scales.y.min = 0;
      dashboard_statistics_chartdata.changes_graph.options.scales.y.max = defaultChartValue;
      statistics_chartdata_value_dataset.data = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      statistics_chartdata_change_dataset.data = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      statistics_chartdata_avg_dataset.data = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      dashboard_statistics_chartdata.value_graph.data.datasets = [statistics_chartdata_value_dataset];
      dashboard_statistics_chartdata.changes_graph.data.datasets = [statistics_chartdata_change_dataset, statistics_chartdata_avg_dataset];
      setIsIssueChartDisplay(true);
      statistics_chartdata_bar_dataset.data = [];
      dashboard_statistics_chartdata.barchart_graph.options.scales.y.max = defaultChartValue;
      dashboard_statistics_chartdata.barchart_graph.data.labels = ['N/A'];
      statistics_chartdata_bar_dataset.data = ['0'];
      dashboard_statistics_chartdata.barchart_graph.data.datasets = [statistics_chartdata_bar_dataset];
      setIsIssueBarChartDisplay(true);
      if(statisticsInitFlag === true) {
        setStatisticsInitFlag(false);
      } else {
        setAlertTitle('Info');
        setAlertMessage(`There is no data`);
        onOpenAlert();
      }
      setCurrentIssueListTableData(dashboard_statistics_tabledata.statistics_issues);
    }
    if (issueListFetching === 'done') {
      setIsIssueDisplay(true);
    }
  }, [issueList]);

  useEffect(() => {
    if (beaconList && beaconList.length > 0) {
      dashboard_statistics_beacon_tabledata.beacon_data_issue.rowdata = beaconList.map(item => ({
        row: [item.issueId,
          item.detectionTime,
          item.dataSourceType,
          item.detectionItem,
          item.detectingConditionType,
          `${Math.round(item.docCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
          item.diff > 0 ? '+' + Math.round(item.diff * 10) / 10 + '%' : Math.round(item.diff * 10) / 10 + '%',
          item.groupName,
          <DetailIconButton text={'Detail'} type={'button'} btnClass={'btn-icon-detail'} clickEvent={detailByButton} category='beacon' issueId={item.issueId} issueCode={item.issueCode}
                            paramFilter={currentFilter} paramRegions={paramRegions}/>,
          item.type
        ],
        dataSourceType: item.dataSourceType,
        detectionItem: item.detectionItem,
        detectionTime: item.detectionTime,
        groupName: item.groupName,
        detectingConditionType: item.detectingConditionType,
        issueLevel: item.issueLevel,
        referenceAvg: item.referenceAvg
      }));
      dashboard_statistics_beacon_tabledata.beacon_data_issue.rowdata[0].deeplinkFlag = true;
      getIssueBeaconChartInfo(beaconList[0]);
      setCurrentBeaconListTableData(dashboard_statistics_beacon_tabledata.beacon_data_issue);
    } else {
      dashboard_statistics_beacon_tabledata.beacon_data_issue.rowdata = [];
      beacon_chartdata_value_dataset.data = [];
      beacon_chartdata_change_dataset.data = [];
      dashboard_statistics_beacon_chart.value_graph.options.scales.y.min = 0;
      dashboard_statistics_beacon_chart.value_graph.options.scales.y.max = defaultChartValue;
      dashboard_statistics_beacon_chart.value_graph.options.baseline.value = 0;
      beacon_chartdata_value_dataset.data = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      dashboard_statistics_beacon_chart.value_graph.data.datasets = [beacon_chartdata_value_dataset];
      dashboard_statistics_beacon_chart.changes_graph.options.scales.y.min = 0;
      dashboard_statistics_beacon_chart.changes_graph.options.scales.y.max = defaultChartValue;
      dashboard_statistics_beacon_chart.changes_graph.options.baseline.value = 0;
      beacon_chartdata_change_dataset.data = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      dashboard_statistics_beacon_chart.changes_graph.data.datasets = [beacon_chartdata_change_dataset];
      setIsBeaconChartDisplay(true);
      if(beaconInitFlag === true) {
        setBeaconInitFlag(false);
      } else {
        setAlertTitle('Info');
        setAlertMessage(`There is no data`);
        onOpenAlert();
      }
      setCurrentBeaconListTableData(dashboard_statistics_beacon_tabledata.beacon_data_issue);
    }
    if (beaconListFetching === 'done') {
      setIsBeaconDisplay(true);
    }
  }, [beaconList]);

  useEffect(() => {
    if (lineChart.nativeValue && lineChart.nativeValue.length > 0) {
      let minNativeValue = parseFloat(lineChart.minNativeValue[0]);
      let maxNativeValue = parseFloat(lineChart.maxNativeValue[0]);
      let minValue = parseInt(lineChart.minValue[0]);
      let maxValue = parseInt(lineChart.maxValue[0]);
      let minAvgValue = parseInt(lineChart.minAvgValue[0]);
      let maxAvgValue = parseInt(lineChart.maxAvgValue[0]);
      let normalMaxValue = parseInt(lineChart.normalMaxValue[lineChart.normalMaxValue.length-1]);
      let normalMinValue = parseInt(lineChart.normalMinValue[lineChart.normalMaxValue.length-1]);
      if(minAvgValue < minValue) minValue = minAvgValue;
      if(maxAvgValue > maxValue) maxValue = maxAvgValue;
      if(maxValue === 0) maxValue = defaultChartValue;
      if(maxNativeValue === 0) maxNativeValue = defaultChartValue;
      if(minNativeValue === maxNativeValue) minNativeValue = 0;
      if(minValue === maxValue) minValue = 0;
      if(maxNativeValue < normalMaxValue) maxNativeValue = normalMaxValue;
      if(minNativeValue > normalMinValue) minNativeValue = normalMinValue;
      statistics_chartdata_value_dataset.data = [];
      statistics_chartdata_change_dataset.data = [];
      statistics_chartdata_avg_dataset.data = [];
      dashboard_statistics_chartdata.value_graph.options.scales.y.min = minNativeValue;
      dashboard_statistics_chartdata.value_graph.options.scales.y.max = getScalesMaxValue(maxNativeValue);
      dashboard_statistics_chartdata.value_graph.options.baseline.value = parseFloat(lineChart.avgNativeValue[0]);
      dashboard_statistics_chartdata.value_graph.options.baselineSubMax.value = parseFloat(normalMaxValue);
      dashboard_statistics_chartdata.value_graph.options.baselineSubMin.value = parseFloat(normalMinValue);
      dashboard_statistics_chartdata.changes_graph.options.scales.y.min = minValue;
      dashboard_statistics_chartdata.changes_graph.options.scales.y.max = getScalesMaxValue(maxValue);
      dashboard_statistics_chartdata.value_graph.data.labels = lineChart.periodValue;
      dashboard_statistics_chartdata.changes_graph.data.labels = lineChart.periodValue;
      statistics_chartdata_value_dataset.data = lineChart.nativeValue;
      statistics_chartdata_change_dataset.data = lineChart.value;
      statistics_chartdata_avg_dataset.data = lineChart.avgValue;
    }
    if (lineChartFetching === 'done') {
      setIsIssueChartDisplay(true);
    }
  }, [lineChart]);

  useEffect(() => {
    if (barChart.countryLabel) {
      statistics_chartdata_bar_dataset.data = [];
      let maxCountryCount = parseInt(barChart.maxCountryCount[0]);
      dashboard_statistics_chartdata.barchart_graph.options.scales.y.max = maxCountryCount;
      dashboard_statistics_chartdata.barchart_graph.data.labels = barChart.countryLabel;
      statistics_chartdata_bar_dataset.data = barChart.countryCount;
    }
    if (barChartFetching === 'done') {
      setIsIssueBarChartDisplay(true);
    }
  }, [barChart]);

  useEffect(() => {
    if (beaconChart.weekly || beaconChart.daily) {
      beacon_chartdata_value_dataset.data = [];
      beacon_chartdata_change_dataset.data = [];
      // average of document count of last one week
      let weeklyAvgDocCount = beaconChart['referenceAvg']
      let weeklyMaxDocCount = Math.max(...beaconChart.weekly);
      if(weeklyMaxDocCount === 0) weeklyMaxDocCount = defaultChartValue;
      dashboard_statistics_beacon_chart.value_graph.options.scales.y.min = 0;
      dashboard_statistics_beacon_chart.value_graph.options.scales.y.max = getScalesMaxValue(weeklyMaxDocCount);
      dashboard_statistics_beacon_chart.value_graph.options.baseline.value = weeklyAvgDocCount;
      dashboard_statistics_beacon_chart.value_graph.data.labels = beaconChart.periodArr;
      beacon_chartdata_value_dataset.data = beaconChart.weekly;

      let dailyMaxDocCount = parseInt(beaconChart.dailyMaxDocCount[0]);
      let dailyAvgDocCount = parseInt(beaconChart.dailyAvgDocCount[0]);
      if(dailyMaxDocCount === 0) dailyMaxDocCount = defaultChartValue;
      dashboard_statistics_beacon_chart.changes_graph.options.scales.y.min = 0;
      dashboard_statistics_beacon_chart.changes_graph.options.scales.y.max = getScalesMaxValue(dailyMaxDocCount);
      dashboard_statistics_beacon_chart.changes_graph.options.baseline.value = parseInt(dailyAvgDocCount);
      dashboard_statistics_beacon_chart.changes_graph.data.labels = beaconChart.dailyPeriodArr;
      beacon_chartdata_change_dataset.data = beaconChart.daily;
    }
    if (beaconChartFetching === 'done') {
      setIsBeaconChartDisplay(true);
    }
  }, [beaconChart]);

  const detailByButton = (selectedData) => {
    history.push(`/ISS/StatisticsDetail`, {
      param: JSON.stringify(selectedData),
    });
  };

  const pageTabChangeHandler = (selectedIdx, _data) => {
    if(currentTab !== selectedIdx) {
      valueInitialize();
      setContentNameInput('');
      setCalendarValue(defaultDate);
      clickTab(selectedIdx);
    }
  };

  const onClickRowItem = (item) => {
    setIsIssueChartDisplay(false);
    setIsIssueBarChartDisplay(false);
    getIssueChartInfo('issueChart',item);
    getIssueChartInfo('issueBarChart', item);
  };

  const onClickRowItemBeacon = (item) => {
    setIsBeaconChartDisplay(false);
    getIssueBeaconChartInfo(item);
  };

  const issueSearchByButton = () => {
    if (paramRegions.length === 0 || paramCountries.length === 0 || paramProviders.length === 0 || paramTypeList.length === 0) {
      const showMessage = paramRegions.length === 0 ? 'region' : paramCountries.length === 0 ? 'country' : paramTypeList.length === 0 ? 'type' : 'cp';
      setAlertTitle('Warning');
      setAlertMessage(`Please select any ${showMessage}`);
      onOpenAlert();
    } else {
      setCurrentFilter({date: calendarValue, country: paramCountries, provider: paramProviders, ric:'', contentName: contentNameInput == null ? '' : contentNameInput.trim(), type: paramTypeList});
    }
  };

  const issueBeaconSearchByButton = () => {
    if (paramBeaconTypes.length === 0) {
      setAlertTitle('Warning');
      setAlertMessage(`Please select any Beacon Type`);
      onOpenAlert();
    }
    else if (paramDetectingTypes.length === 0) {
      setAlertTitle('Warning');
      setAlertMessage(`Please select any Detecting Type`);
      onOpenAlert();
    }
    else if (checkedGroups.length === 0) {
      setAlertTitle('Warning');
      setAlertMessage('Please select any Group');
      onOpenAlert();
    } else {
      setCurrentFilter({date: '', country: 'All', provider: '', contentName: '', type: '',
        dataSourceType: paramBeaconTypes, detectingConditionType: paramDetectingTypes, groupList: checkedGroups });
    }
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const handleInputChange = event => {
    setContentNameInput(event.target.value);
  };
  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      issueSearchByButton();
    }
  };

  const defaultRangeDays = 14;
  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());
    const thirtyDaysAgo = new Date(utcToday);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - defaultRangeDays);
    const utcTodayString = utcToday.toISOString().substring(0, 10);
    const thirtyDaysAgoString = thirtyDaysAgo.toISOString().substring(0, 10);
    setDefaultDate([thirtyDaysAgoString, utcTodayString]);
    return [thirtyDaysAgoString, utcTodayString];
  };

  const handleOpen = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <>
      <div className="head">
        <div className="title tab-titles">
          <button className="btn-tutorial-2" onClick={handleOpen}/>
          <IssPageTabs tabMenus={pageTabMenus} currentTab={currentTab} onChange={pageTabChangeHandler} />
        </div>
        {currentTab === 0 ? (
          <div className="ui-dropdown-box">
            <DatePickerStatistics rangeDays={defaultRangeDays} dateChangeHandler={(newDate) => setCalendarValue(newDate)}/>
            <DropDownForSearch dropDownItem={regions} title="Group" onChangeDropDown={setParamRegions} uiClassName={defineClass}/>
            <DropDownForSearch dropDownItem={countries} title="Countries" onChangeDropDown={setParamCountries} uiClassName={defineClass}/>
            <DropDownForSearch dropDownItem={providers} title="CPs" onChangeDropDown={setParamProviders} uiClassName={defineClass}/>
            <NarrowDropDown item={typeList} title="Types" onChangeDropDown={setParamTypeList} />
            <div className="content-name">
              <input type="text" value={contentNameInput} placeholder="Content Name"
                onChange={event => handleInputChange(event)}
                onKeyPress={handleOnKeyPress}
              />
            </div>
            <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={issueSearchByButton}/>
          </div>
        ): (
          <div className="ui-dropdown-box">
            <NarrowDropDown item={beaconTypes} title="Beacon Types" onChangeDropDown={setParamBeaconTypes} />
            <NarrowDropDown item={detectingTypes} title="Detecting Types" onChangeDropDown={setParamDetectingTypes} />
            <NarrowDropDown item={groups} title="Group" onChangeDropDown={setCheckedGroups} />
            <DefaultButton type={'button'} text={'Search'} btnClass={'btn-search'} clickEvent={issueBeaconSearchByButton}/>
          </div>
        )}
      </div>
      <IssPageTab isActive={currentTab === 0}>
        {isIssueDisplay ? (
          <div className="grid-single-row">
            <div className="box-statisIssue">
              <IssTable height={`calc(100% - 42px)`} pagination={true}
              data={currentIssueListTableData}
              handleClickItem={item => onClickRowItem(item)}
              sortingItem={{enable: true, sortColumn: 0, sortDirection: 'desc'}}
              />
            </div>
          </div>
        ) : (
          <div className="loading_wrap">
            <LoadingIndicator />
          </div>
        )}
        <div className="grid-column-3">
          <div className="grid-left">
            <div className="box-value">
              <div className="head">
                <div className="title">
                  <h2>Daily value change</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Normal Range</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_LINE_GRID_COLOR.BASE_LINE}` }}></i>
                      <span>Native AVG</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Native Value</span>
                    </li>
                  </ul>
                </div>
                {isIssueDisplay && !isIssueChartDisplay ? (
                <div className="loading_wrap">
                  <LoadingIndicator />
                </div>
                ) : (
                <LineChart id={'value_chart'}
                data={dashboard_statistics_chartdata.value_graph.data}
                options={dashboard_statistics_chartdata.value_graph.options} />
                )}
              </div>
            </div>
          </div>
          <div className="grid-center">
            <div className="box-rateChange">
              <div className="head">
                <div className="title">
                  <h2>Daily inc/dec rate change</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Change from day before</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Change from AVG</span>
                    </li>
                  </ul>
                </div>
                {isIssueDisplay && !isIssueChartDisplay ? (
                <div className="loading_wrap">
                  <LoadingIndicator />
                </div>
                ) : (
                <LineChart id={'changes_chart'}
                data={dashboard_statistics_chartdata.changes_graph.data}
                options={dashboard_statistics_chartdata.changes_graph.options} />
                )}
              </div>
            </div>
          </div>
          <div className="grid-right">
            <div className="box-country">
              <div className="head">
                <div className="title">
                  <h2>Issue count by country</h2>
                </div>
              </div>
              <div className="ui-chart-container">
              {isIssueDisplay && !isIssueBarChartDisplay ? (
                <div className="loading_wrap">
                  <LoadingIndicator />
                </div>
                ) : (
                <BarChart id={'barChart'} data={dashboard_statistics_chartdata.barchart_graph.data}
                options={dashboard_statistics_chartdata.barchart_graph.options} width={'100%'} height={'100%'} />
                )}
              </div>
            </div>
          </div>
        </div>
      </IssPageTab>
      <IssPageTab isActive={currentTab === 1}>
        {isBeaconDisplay ? (
        <div className="grid-single-row">
          <div className="box-statisIssue">
            <IssTable height={`calc(100% - 42px)`} pagination={true}
            data={currentBeaconListTableData}
            handleClickItem={item => onClickRowItemBeacon(item)}
            sortingItem={{enable: true, sortColumn: 2, sortDirection: 'desc'}}
            />
          </div>
        </div>
        ) : (
          <div className="loading_wrap">
            <LoadingIndicator />
          </div>
        )}
        <div className="grid-column-2">
          <div className="grid-left">
            <div className="box-docCount">
              <div className="head">
                <div className="title">
                  <h2>Daily Doc Count(Same time)</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_LINE_GRID_COLOR.BASE_LINE}` }}></i>
                      <span>Average(Last Week)</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Doc count</span>
                    </li>
                  </ul>
                </div>
                {isBeaconDisplay && !isBeaconChartDisplay ? (
                  <div className="loading_wrap">
                    <LoadingIndicator />
                  </div>
                ) : (
                <LineChart id={'value_chart'} data={dashboard_statistics_beacon_chart.value_graph.data}
                options={dashboard_statistics_beacon_chart.value_graph.options} width={'100%'} height={'100%'} />
                )}
              </div>
            </div>
          </div>
          <div className="grid-center">
            <div className="box-normalRange">
              <div className="head">
                <div className="title">
                  <h2>Doc Count By Time</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Doc count</span>
                    </li>
                  </ul>
                </div>
                {isBeaconDisplay && !isBeaconChartDisplay ? (
                  <div className="loading_wrap">
                    <LoadingIndicator />
                  </div>
                ) : (
                <LineChart id={'changes_chart'} data={dashboard_statistics_beacon_chart.changes_graph.data}
                options={dashboard_statistics_beacon_chart.changes_graph.options} width={'100%'} height={'100%'} />
                )}
              </div>
            </div>
          </div>
        </div>
      </IssPageTab>
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
      {isActive && <TutorialPage data={'statisticsDashboard'} isActive={isActive} onClose={handleClose} />}
    </>
  );
};

export default StatisticsDashboard;
