import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, DialogContent, DialogTitle, MenuItem, Select, Table, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const today = new Date(new Date().setDate(new Date().getDate() - 1));

const weekDayList = [
  { value: 'MON', label: 'Monday' },
  { value: 'TUE', label: 'Tuesday' },
  { value: 'WED', label: 'Wednesday' },
  { value: 'THU', label: 'Thursday' },
  { value: 'FRI', label: 'Friday' },
  { value: 'SAT', label: 'Saturday' },
  { value: 'SUN', label: 'Sunday' },
];

const hourFomatList = [
  { value: 1, label: '1:00' },
  { value: 2, label: '2:00' },
  { value: 3, label: '3:00' },
  { value: 4, label: '4:00' },
  { value: 5, label: '5:00' },
  { value: 6, label: '6:00' },
  { value: 7, label: '7:00' },
  { value: 8, label: '8:00' },
  { value: 9, label: '9:00' },
  { value: 10, label: '10:00' },
  { value: 11, label: '11:00' },
  { value: 12, label: '12:00' },
  { value: 13, label: '13:00' },
  { value: 14, label: '14:00' },
  { value: 15, label: '15:00' },
  { value: 16, label: '16:00' },
  { value: 17, label: '17:00' },
  { value: 18, label: '18:00' },
  { value: 19, label: '19:00' },
  { value: 20, label: '20:00' },
  { value: 21, label: '21:00' },
  { value: 22, label: '22:00' },
  { value: 23, label: '23:00' },
];

const TabCategoryScheduleFrom = ({ region, deviceType, edit, schedule, regionList, handleClose, handleSubmit, handleInputChange }) => {
  //get country list depends on device type and region from tab/category page.
  const allCountries = ((regionList.find(x => x.deviceType === deviceType) || { list: [] }).list.find(x => x.region === region) || { cntry: [] }).cntry;

  const validateEndTime = endTime => {
    return parseInt(endTime) <= parseInt(schedule.startTime);
  };

  const validateStartTime = starTime => {
    return parseInt(starTime) >= parseInt(schedule.endTime);
  };

  const timevalidation = () => {
    // Both are required if any one time selected.
    return schedule.startTime !== '' || schedule.endTime !== '';
  };

  return (
    <div>
      <DialogTitle className="pop_head" id="form-dialog-title">
        {edit ? 'Edit' : 'Add'} {'Schedule Category'}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={'pop_body'}>
        <form onSubmit={handleSubmit}>
          <div>
            <TableContainer className={'tbl_wrap'}>
              <Table className={'tbl_row_typ1'}>
                <TableRow width="100%">
                  <TableCell>
                    <label className="is_required">Start Date</label>
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      id="startDate"
                      name="startDate"
                      // label="Start Date"
                      type="date"
                      value={schedule.startDate}
                      onChange={event => handleInputChange(event)}
                      // defaultValue={today.toISOString().slice(0, 10)}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      inputProps={{ min: today.toISOString().slice(0, 10) }}
                      required
                    />
                  </TableCell>
                  <TableCell>
                    <label className="is_required">End Date</label>
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      id="endDate"
                      name="endDate"
                      // label="End Date"
                      type="date"
                      value={schedule.endDate}
                      onChange={event => handleInputChange(event)}
                      // defaultValue={today.toISOString().slice(0, 10)}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      inputProps={{ min: schedule.startDate }}
                      required
                    />
                  </TableCell>
                </TableRow>
                <TableRow width="100%">
                  <TableCell>
                    <label className="is_required">Country</label>
                  </TableCell>
                  <TableCell>
                    <Select name="countryCodes" id="countryCodes" style={{ minWidth: 120 }} value={schedule.countryCodes} onChange={event => handleInputChange(event)} required multiple>
                      {[...allCountries].map(item => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <label> Week day</label>
                  </TableCell>
                  <TableCell>
                    <Select name="weekdayList" id="weekday" style={{ minWidth: 120, maxWidth: 220 }} value={schedule.weekdayList} onChange={e => handleInputChange(e)} multiple>
                      {[...weekDayList].map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow width="100%">
                  <TableCell>
                    <label className={timevalidation() ? 'is_required' : ''}> Start Time</label>
                  </TableCell>
                  <TableCell>
                    <Select id="starttime" name="startTime" required={timevalidation()} style={{ minWidth: 120 }} value={schedule.startTime} onChange={e => handleInputChange(e)}>
                      <MenuItem value=""> Select Time</MenuItem>
                      {[{ value: 0, label: '00:00' }, ...hourFomatList].map(item => (
                        <MenuItem disabled={validateStartTime(item.value)} key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <label className={timevalidation() ? 'is_required' : ''}> End Time</label>
                  </TableCell>
                  <TableCell>
                    <Select id="endtime" name="endTime" required={timevalidation()} style={{ minWidth: 120 }} value={schedule.endTime} onChange={e => handleInputChange(e)}>
                      <MenuItem value=""> Select Time</MenuItem>
                      {[...hourFomatList, { value: 24, label: '24:00' }].map(item => (
                        <MenuItem disabled={validateEndTime(item.value)} key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </div>
          <div className={'btn_set_wrap'}>
            <Button data-testid="addButton" color="primary" onClick={handleClose} className={'btn_color1'}>
              Close
            </Button>
            <Button data-testid="addButton" type="submit" color="primary" className={'btn_color2'}>
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </div>
  );
};

TabCategoryScheduleFrom.propTypes = {
  region: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  schedule: PropTypes.object.isRequired,
  regionList: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  regionList: state.homeApp.regionList,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TabCategoryScheduleFrom));
