import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { saveChannelAction } from '../../../actions/channelChannelActions';
function ChannelEdit({ isRoleAdmin, channel, saveChannelAction, history, ric, system, channelMapId, chanName, chanIdSearch, chanId, chanNo }) {
  const [logo, setLogo] = useState({
    name: '',
  });

  const handleClickCancel = () => {
    history.push(`/CHANNEL/channel/channelView/${ric}/${system}/${channelMapId}/${chanName}/${chanIdSearch}/${chanId}/${chanNo}`);
  };

  const handleClickSave = () => {
    if (window.confirm('Are you sure to save?')) {
      clickSave();
      return true;
    } else return false;
  };
  const clickSave = () => {
    let selectedFile = document.getElementById('logInputFile').files[0];
    if (!selectedFile) alert('Please select a logo file to update !');
    else {
      let dt = new Date();
      let yy = dt.getFullYear() + '';
      let mm = dt.getMonth() + 1;
      if (dt.getMonth() + 1 < 10) mm = '0' + (dt.getMonth() + 1);
      let dd = dt.getDate();
      if (dt.getDate() < 10) dd = '0' + dt.getDate();
      let hh = dt.getHours();
      if (dt.getHours() < 10) hh = '0' + dt.getHours();
      let mi = dt.getMinutes();
      if (dt.getMinutes() < 10) mi = '0' + dt.getMinutes();

      saveChannelAction(
        isRoleAdmin,
        ric,
        system,
        channel.icContentsSetId,
        channel.msoPrdtItmCode,
        channel.chanId, // chanCode:"EBS"
        selectedFile, // logoImage:file
        selectedFile.size,
        logo.width,
        logo.height,
        yy + mm + dd + hh + mi, // logoVer:"202005150000" : (현재년월일시분)
      );
      history.push(`/CHANNEL/channel/${ric}/${system}/${channelMapId}/${chanName}/${chanIdSearch}/${chanNo}`);
    }
  };

  let imgUrl = '';
  if (channel.admLogoUrl) imgUrl = channel.admLogoUrl;
  else if (channel.logoUrl) imgUrl = channel.logoUrl;
  return (
    <div>
      <form noValidate autoComplete="off">
        <div className="tbl_wrap tbl_radius">
          <table className="tbl_row_typ1 tbl_channel">
            <colgroup>
              <col style={{ width: '13%' }} />
              <col style={{ width: '47%' }} />
              <col style={{ width: '13%' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <thead>
              <tr>
                <th>Channel ID</th>
                <td>{chanId}</td>
                <th>
                  <span> Channel No. </span>
                </th>
                <td>{channel.chanNo}</td>
              </tr>
              <tr>
                <th>
                  <span> Channel Name </span>
                </th>
                <td colSpan="3">{channel.chanName}</td>
              </tr>
              <tr>
                <th rowSpan="3">Logo</th>
                <td rowSpan="3">
                  <div className="logo_preview_wrap">
                    <div className="logo_white_area">
                      <div className="logo_img_area">
                        <img src={imgUrl} />
                      </div>
                    </div>
                    <div className="logo_black_area">
                      <div className="logo_img_area">
                        <img src={imgUrl} />
                      </div>
                    </div>
                  </div>
                  <div className="logo_preview_add">
                    <p className="logo_preview_comment">※ Recommended 100PX height and 400PX width</p>
                    <input
                      accept="image/*"
                      id="logInputFile"
                      type="file"
                      className="logo_preview_input"
                      onChange={event => {
                        if (event.target.files)
                          if (event.target.files.length >= 1) {
                            let file = event.target.files[0];
                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = e => {
                              let image = new Image();
                              image.src = e.target.result;
                              image.onload = () => {
                                setLogo({ name: file.name, height: image.height, width: image.width });
                                return true;
                              };
                            };
                          }
                      }}
                    />
                    <label htmlFor="logInputFile" className="btn_logo_preview">
                      <Button component="span" className="btn_color4">
                        Select File
                      </Button>
                    </label>
                    <p className="file_name">{logo.name}</p>
                  </div>
                </td>
                <th>Logo Width</th>
                <td>{channel.logoWidth}</td>
              </tr>
              <tr>
                <th>Logo Height</th>
                <td>{channel.logoHeight}</td>
              </tr>
              <tr>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>Log URL</th>
                <td colSpan={3}>{imgUrl}</td>
              </tr>
            </thead>
          </table>
        </div>
      </form>
      <div className="btn_set_wrap">
        <Button variant="contained" className={'btn_color1'} onClick={handleClickCancel}>
          {' '}
          Cancel{' '}
        </Button>
        <Button variant="contained" className={'btn_color2'} onClick={handleClickSave}>
          {' '}
          Save{' '}
        </Button>
      </div>
    </div>
  );
}

ChannelEdit.propTypes = {
  isRoleAdmin: PropTypes.bool.isRequired,
  channel: PropTypes.any.isRequired,
  saveChannelAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  ric: PropTypes.string.isRequired,
  system: PropTypes.string.isRequired,
  channelMapId: PropTypes.string.isRequired,
  chanName: PropTypes.string.isRequired,
  chanIdSearch: PropTypes.string.isRequired,
  chanId: PropTypes.string.isRequired,
  chanNo: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  isRoleAdmin: state.currentUser.isRoleAdmin,
  channel: state.channelChannel.channel,
});
const mapDispatchToProps = dispatch => ({
  saveChannelAction(isRoleAdmin, ric, system, contentsSetId, chanMapId, chanCode, logoImage, logoSize, logoWidth, logoHeight, logoVer) {
    dispatch(saveChannelAction(isRoleAdmin, ric, system, contentsSetId, chanMapId, chanCode, logoImage, logoSize, logoWidth, logoHeight, logoVer));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChannelEdit));
