import { CHART_COLORS } from '../../common/ChartConsts';
import { ROWHEADER_TYPE, TABLE_TYPE } from '../../consts/IssConsts';

export const DashboardSet = {
  appIssueSet: {
    title: 'LG Channels Issues',
    visibleTitle: true,
    existTooltip: true,
    tooltip: 'Detected issues related to the LG Channels app (App loading failure, device errors, empty content within tabs etc)',
    header: ['Date', 'Cnty.', 'Type', 'CP', 'Contents Name', 'Issue Title'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['15%', '5%', '10%', '10%', '20%', '40%'],
    rowdata: [],
  },
  playIssueSet: {
    title: 'Playback',
    visibleTitle: true,
    existTooltip: true,
    tooltip: 'Detected issues when content playback is not functioning.',
    header: ['Date', 'Cnty.', 'Type', 'CP', 'Contents Name', 'Issue Title'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['15%', '5%', '10%', '10%', '20%', '40%'],
    rowdata: [],
  },
  imageIssueSet: {
    title: 'Image',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Detected issues when thumbnails, posters, logos, or representative images are missing.',
    header: ['Date', 'Cnty.', 'Type', 'CP', 'Contents Name', 'Issue Title'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.LONG, ROWHEADER_TYPE.LONG],
    cellWidth: ['25%', '5%', '5%', '5%', '30%', '30%'],
    rowdata: [],
  },
  scheduleIssues: {
    title: 'Schedule',
    visibleTitle: true,
    existTooltip: true,
    tooltip: 'Detected issues when schedule information is missing.',
    header: ['Date', 'CP', 'Cnty.', 'Contents Count', 'Issue Title'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.LONG, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['15%', '10%', '10%', '20%', '40%'],
    rowdata: [],
  },
  serviceDataIssues: {
    id: 'REGION ID',
    title: 'Service Data',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Detected issues when content descriptions are missing.',
    header: ['Date', 'Cnty.', 'Type', 'CP', 'Contents Name', 'Issue Title'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.LONG, ROWHEADER_TYPE.LONG],
    cellWidth: ['20%', '5%', '5%', '5%', '30%', '35%'],
    rowdata: [],
  },
  serviceRegion : {
    noRegionData : {
      data: [100], //100분률 환상으로 표기 또는 데이터로 표기
      backgroundColor: [CHART_COLORS.GRAY],
      selBackgroundColor: [CHART_COLORS.GRAY],
      unSelBackgroundColor: [CHART_COLORS.UNSEL_1],
      borderWidth: 0,
      borderRadius: 0,
      cutout: '80%',
    },
    regionData : {
      data: [0, 0, 0, 0, 0], //100분률 환상으로 표기 또는 데이터로 표기
      backgroundColor: [CHART_COLORS.PURPLE, CHART_COLORS.PINK, CHART_COLORS.MINT, CHART_COLORS.BLUE, CHART_COLORS.ORANGE],
      selBackgroundColor: [CHART_COLORS.PURPLE, CHART_COLORS.PINK, CHART_COLORS.MINT, CHART_COLORS.BLUE, CHART_COLORS.ORANGE],
      unSelBackgroundColor: [CHART_COLORS.UNSEL_1, CHART_COLORS.UNSEL_2, CHART_COLORS.UNSEL_3, CHART_COLORS.UNSEL_4, CHART_COLORS.UNSEL_5],
      borderWidth: 0,
      borderRadius: 2,
      cutout: '80%',
    },
    ca : {
      id: 'AIC(CA)',
      title: 'AIC(CA)',
      sum: 0,
      datasets: [
        {
          label: 'AIC(CA)',
          data: [100], //100분률 환상으로 표기 또는 데이터로 표기
          backgroundColor: [CHART_COLORS.GRAY],
          selBackgroundColor: [CHART_COLORS.GRAY],
          unSelBackgroundColor: [CHART_COLORS.UNSEL_1],
          borderWidth: 0,
          borderRadius: 0,
          cutout: '80%',
        },
      ],
    },
    latam : {
      id: 'AIC(LATAM)',
      title: 'AIC(LATAM)',
      sum: 0,
      datasets: [
        {
          label: 'AIC(LATAM)',
          data: [100], //100분률 환상으로 표기 또는 데이터로 표기
          backgroundColor: [CHART_COLORS.GRAY],
          selBackgroundColor: [CHART_COLORS.GRAY],
          unSelBackgroundColor: [CHART_COLORS.UNSEL_1],
          borderWidth: 0,
          borderRadius: 0,
          cutout: '80%',
        },
      ],
    },
    us : {
      id: 'AIC(US)',
      title: 'AIC(US)',
      sum: 0,
      datasets: [
        {
          label: 'AIC(US)',
          data: [100], //100분률 환상으로 표기 또는 데이터로 표기
          backgroundColor: [CHART_COLORS.GRAY],
          selBackgroundColor: [CHART_COLORS.GRAY],
          unSelBackgroundColor: [CHART_COLORS.UNSEL_1],
          borderWidth: 0,
          borderRadius: 2,
          cutout: '80%',
        },
      ],
    },
    eu : {
      id: 'EIC(EU)',
      title: 'EIC(EU)',
      sum: 0,
      datasets: [
        {
          label: 'EIC(EU)',
          data: [100], //100분률 환상으로 표기 또는 데이터로 표기
          backgroundColor: [CHART_COLORS.GRAY],
          selBackgroundColor: [CHART_COLORS.GRAY],
          unSelBackgroundColor: [CHART_COLORS.UNSEL_1],
          borderWidth: 0,
          borderRadius: 0,
          cutout: '80%',
        },
      ],
    },
    au : {
      id: 'KIC(AU)',
      title: 'KIC(AU)',
      sum: 0,
      datasets: [
        {
          label: 'KIC(AU)',
          data: [100], //100분률 환상으로 표기 또는 데이터로 표기
          backgroundColor: [CHART_COLORS.GRAY],
          selBackgroundColor: [CHART_COLORS.GRAY],
          unSelBackgroundColor: [CHART_COLORS.UNSEL_1],
          borderWidth: 0,
          borderRadius: 0,
          cutout: '80%',
        },
      ],
    },
    in : {
      id: 'KID(IN)',
      title: 'KID(IN)',
      sum: 0,
      datasets: [
        {
          label: 'KID(IN)',
          data: [100], //100분률 환상으로 표기 또는 데이터로 표기
          backgroundColor: [CHART_COLORS.GRAY],
          selBackgroundColor: [CHART_COLORS.GRAY],
          unSelBackgroundColor: [CHART_COLORS.UNSEL_1],
          borderWidth: 0,
          borderRadius: 0,
          cutout: '80%',
        },
      ],
    },
    krjp : {
      id: 'KIC(KR/JP)',
      title: 'KIC(KR/JP)',
      sum: 0,
      datasets: [
        {
          label: 'KIC(KR/JP)',
          data: [100], //100분률 환상으로 표기 또는 데이터로 표기
          backgroundColor: [CHART_COLORS.GRAY],
          selBackgroundColor: [CHART_COLORS.GRAY],
          unSelBackgroundColor: [CHART_COLORS.UNSEL_1],
          borderWidth: 0,
          borderRadius: 0,
          cutout: '80%',
        },
      ],
    }
  }
};