import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

function calImprovedRate(content_one, content_two) {
  if (isNaN(content_one) || isNaN(content_two) || content_one <= 0 || content_two <= 0) {
    return 'N/A';
  }
  const improvedRate = (content_one - content_two) * 100 / content_two === 0 ? 0 : ((content_one - content_two) * 100 / content_two).toFixed(1)
  if (isNaN(improvedRate)) {
    return ''
  }
  return improvedRate.toString() + '%'
}

export function ABContentDuration(abTestDailyData) {
  const [days, SetDays] = React.useState(['(SUN)', '(MON)', '(TUE)', '(WED)', '(THU)', '(FRI)', '(SAT)']);


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Average Duration (in mins)',
        font: {
          size: 24,
          style: 'italic',
          family: 'Helvetica Neue',
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x'
        },
        zoom: {
          pinch: {
            enabled: true
          },
          wheel: {
            enabled: false
          },
          mode: 'x',
        }
      }
    },
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
      y:
      {
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return `${value}`;
          }
        }
      }
    }
  };

  const labels = abTestDailyData.abTestDailyData.map(dailyData => dailyData.DT.substr(5, 9) + ' ' + days[new Date(dailyData.DT).getDay()]);

  const dataSetABC = [
    {
      label: 'A',
      data: abTestDailyData.abTestDailyData.map(dailyData => dailyData.all_content_A),
      backgroundColor: '#ffdab9',
    },
    {
      label: 'B',
      data: abTestDailyData.abTestDailyData.map(dailyData => dailyData.all_content_B),
      backgroundColor: '#ffb6c1',
    },
    {
      label: 'C',
      data: abTestDailyData.abTestDailyData.map(dailyData => dailyData.all_content_C),
      backgroundColor: '#CFCFC4',
    },
  ]

  const dataSetAB = [
    {
      label: 'A',
      data: abTestDailyData.abTestDailyData.map(dailyData => dailyData.all_content_A),
      backgroundColor: '#ffdab9',
    },
    {
      label: 'B',
      data: abTestDailyData.abTestDailyData.map(dailyData => dailyData.all_content_B),
      backgroundColor: '#ffb6c1',
    }
  ]

  const data = {
    labels,
    datasets: abTestDailyData.defaultContent === 'N' ? dataSetAB : dataSetABC,
  };
  return <div style={{ height: '500px' }}><Bar options={options} data={data} /></div>;
}

ABContentDuration.propTypes = {
  abTestDailyData: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  abTestDailyData: state.homeApp.abTestDailyData,
});
export default connect(mapStateToProps)(ABContentDuration);
