import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IssTable from '../common/IssTable';
import PrimaryButton from '../common/PrimaryButton';
import CauseDropDown from '../common/CauseDropDown';
import { request } from '../utils/request';
import Loading from '../common/Loading';
import { sub_statistics_tabledata,
  sub_statistics_chartdata,
  statistics_chartdata_value_dataset,
  statistics_chartdata_change_dataset,
  statistics_chartdata_avg_dataset } from './StatisticsDetailData';
import Alert from '../modal/Alert';
import CreateJiraModal from "../modal/CreateJira";
import ModalPopup from '../modal/ModalPopup';
import { ISS_BASE_URL } from '../../../constants/index';
import { getHeaders } from '../../../util/actionUtil';
import LineChart from '../common/LineChart';
import {CHART_COLORS, CHART_LINE_GRID_COLOR} from '../common/ChartConsts';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { getScalesMaxValue } from '../utils/IssUtils';

function StatisticsTab({ username, currentItem, currentFilter, currentTab, searchEvent, defaultRangeDate }) {
  let directValue = 9999;
  let directName = 'Select Cause';
  let dataType = 'statistics';
  let tdMin = ' (hr)';
  const [causeList, setCauseList] = useState([]);
  const [causeSel, setCauseSel] = useState(directValue);
  const [causeNameSel, setCauseNameSel] = useState(directName);
  const [causeTextArea, setCauseTextArea] = useState();
  const [isIssueDisplay, setIsIssueDisplay] = useState(false);
  const [isIssueReferenceDisplay, setIsIssueReferenceDisplay] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ issueId: '', issueCode: '' });
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Statistics Detail');
  const [alertMessage, setAlertMessage] = useState();
  const [currentFocusData, setCurrentFocusData] = useState({});
  const [isCheckboxValueInit, setCheckboxValueInit] = useState(false);
  const [updatingIssueList, setUpdatingIssueList] = useState([]); // {issueId:'', issueStatus:''} DB에 업데이트할 Issue list
  const [actionDetails, setActionDetails] = useState({ causeIdx: directValue, causeDetail: '' });
  const [issueIdList, setIssueIdList] = useState([]);
  const [isShowCreateJiraPopup, setShowCreateJiraPopup] = useState(false);
  const [isModalPopup, setModalPopup] = useState(false);
  const [modalPopupTitle, setModalPopupTitle] = useState('');
  const [statisticsInitFlag, setStatisticsInitFlag] = useState(true);
  const [issueList, setIssueList] = useState([]);
  const [issueListFetching, setIssueListFetching] = useState('init');
  const [currentIssueListTableData, setCurrentIssueListTableData] = useState({});
  const [referenceList, setReferenceList] = useState([]);
  const [referenceListFetching, setReferenceListFetching] = useState('init');
  const [currentReferenceListTableData, setCurrentReferenceListTableData] = useState({});
  const [isIssueChartDisplay, setIsIssueChartDisplay] = useState(false);
  const [lineChart, setLineChart] = useState([]);
  const [lineChartFetching, setLineChartFetching] = useState('init');
  const [isValueInitNeed, setValueInitNeed] = useState(false);
  const [page, setPage] = useState(1);
  const [paramPage, setParamPage] = useState();
  const defaultChartValue = 25;
  const maxLength = 1000;

  useEffect(() => {
    fetchCause();
    getAssignGroupList();
  }, []);

  useEffect(() => {
    if (isValueInitNeed) {
      setValueInitNeed(false);
      setCheckboxValueInit(false);
    }
  }, [isValueInitNeed]);

  useEffect(() => {
    if (currentFilter && currentTab === 0 && currentFilter.subType != null) {
      setIsIssueDisplay(false);
      valueInitialize(currentFilter.pagingType, page);
      getIssueListInfo(currentFilter);
    }
  }, [currentFilter]);

  useEffect(() => {
    if (currentItem && currentItem.issueId !== '') {
      setIsIssueDisplay(false);
      setIsIssueReferenceDisplay(false);
      setSelectedItem({ issueId: currentItem.issueId, issueCode: currentItem.issueCode });
    }
  }, [currentItem]);

  const getIssueListInfo = filter => {
    let country = filter.country;
    let provider = filter.provider;
    let contentName = filter.contentName;
    let typeList = filter.type;
    let searchType = filter.searchType;
    let date = filter.date;
    if(country === null || country === '') country = [];
    if(provider === null || provider === '') provider = [];
    if(typeList === null || typeList === '') typeList = [];
    if(date === null || date === '') date = defaultRangeDate;
    let body = {
      cpIdList: provider,
      countryCodeList: country,
      contentName: contentName,
      typeList: typeList,
      searchType: searchType,
      startDate: date[0],
      endDate: date[1],
    };
    setIssueListFetching('loading');

    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/issue`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
    .then(res => res.json())
    .then(body => {
      setIssueListFetching('done');
      setIssueList(body);
    })
    .catch(error => {
      console.log(`getIssueListInfo error: `, error);
    }).finally(() => {});
  };

  const getReferenceListInfo = issueCode => {
    let body = { issueCode: issueCode };
    setReferenceListFetching('loading');

    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/issueReference`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(body),
    })
    .then(res => res.json())
    .then(body => {
      setReferenceListFetching('done');
      setReferenceList(body);
    })
    .catch(error => {
      console.log(`getReferenceListInfo error: `, error);
    }).finally(() => {});
  };

  const getIssueChartInfo = (item) => {
    let issueListBody = {};
    setLineChartFetching('loading');
    issueListBody = {cpId: item.cpId,
      uniqueId: item.uniqueId,
      current: item.period,
      period: item.period,
      countryCode: item.countryCode,
      type: item.type,
      detectionItem: item.detectionItem.replace(tdMin,'')};

    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/issueChart`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(issueListBody),
    })
    .then(res => res.json())
    .then(body => {
      setLineChartFetching('done');
      setLineChart(body);
    })
    .catch(error => {
      console.log(`getIssueChartInfo error: `, error);
    }).finally(() => {});
  };

  useEffect(() => {
    if (issueList && issueList.length > 0) {
      let selectedData = undefined;

      if (currentItem && currentItem.issueId !== '') {
        selectedData = issueList.find(item => item.issueId === currentItem.issueId);
      }

      if (selectedData) {
        const selectedRowdata = {
          row: [
            selectedData.issueId,
            selectedData.period,
            selectedData.issueLevel,
            selectedData.type,
            selectedData.countryCode,
            selectedData.contentName,
            selectedData.cpId,
            selectedData.detectionItem === 'total_duration' ? selectedData.detectionItem.concat(tdMin) : selectedData.detectionItem,
            selectedData.detectionItem === 'total_duration' ?`${(Math.round(((selectedData.nativeValue/60)/60)*10)/10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            (${(Math.round(((selectedData.average/60)/60)*10)/10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`
            : `${Math.round(selectedData.nativeValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            (${Math.round(selectedData.average).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`,
            `${Math.round(selectedData.avgValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} %`,
            selectedData.status,
            selectedData.assignGroup,
            selectedData.lastChgUsrId,
            selectedData.jiraUrl,
            selectedData.causeProcessDetail
          ],
          issueId: selectedData.issueId,
          issueCode: selectedData.issueCode,
          issueStatus: selectedData.status,
          causeIdx: selectedData.causeIdx,
          causeProcessDetail: selectedData.causeProcessDetail,
          assignGroup: selectedData.assignGroup,
          cpId: selectedData.cpId,
          period: selectedData.period,
          countryCode: selectedData.countryCode,
          type: selectedData.type,
          detectionItem: selectedData.detectionItem,
          uniqueId: selectedData.uniqueId,
          deeplinkFlag: true,
        };
        const othersRowdata = issueList
          .filter(item => item.issueId !== selectedData.issueId)
          .map(item => ( {
          row: [
            item.issueId,
            item.period,
            item.issueLevel,
            item.type,
            item.countryCode,
            item.contentName,
            item.cpId,
            item.detectionItem === 'total_duration' ? item.detectionItem.concat(tdMin) : item.detectionItem,
            item.detectionItem === 'total_duration' ?`${(Math.round(((item.nativeValue/60)/60)*10)/10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            (${(Math.round(((item.average/60)/60)*10)/10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`
            : `${Math.round(item.nativeValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            (${Math.round(item.average).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`,
            `${Math.round(item.avgValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} %`,
            item.status,
            item.assignGroup,
            item.lastChgUsrId,
            item.jiraUrl,
            item.causeProcessDetail
          ],
          issueId: item.issueId,
          issueCode: item.issueCode,
          causeIdx: item.causeIdx,
          issueStatus: item.status,
          causeProcessDetail: item.causeProcessDetail,
          assignGroup: item.assignGroup,
          cpId: item.cpId,
          period: item.period,
          countryCode: item.countryCode,
          type: item.type,
          detectionItem: item.detectionItem,
          uniqueId: item.uniqueId
        }));
        sub_statistics_tabledata.statistics.rowdata = [selectedRowdata, ...othersRowdata];
        setCurrentIssueListTableData(sub_statistics_tabledata.statistics);
        onClickRowItem(selectedRowdata);
      } else {
        sub_statistics_tabledata.statistics.rowdata = issueList.map(item => ( {
          row: [
            item.issueId,
            item.period,
            item.issueLevel,
            item.type,
            item.countryCode,
            item.contentName,
            item.cpId,
            item.detectionItem === 'total_duration' ? item.detectionItem.concat(tdMin) : item.detectionItem,
            item.detectionItem === 'total_duration' ?`${(Math.round(((item.nativeValue/60)/60)*10)/10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            (${(Math.round(((item.average/60)/60)*10)/10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`
            : `${Math.round(item.nativeValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            (${Math.round(item.average).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`,
            `${Math.round(item.avgValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} %`,
            item.status,
            item.assignGroup,
            item.lastChgUsrId,
            item.jiraUrl,
            item.causeProcessDetail
          ],
          issueId: item.issueId,
          issueCode: item.issueCode,
          issueStatus: item.status,
          causeIdx: item.causeIdx,
          causeProcessDetail: item.causeProcessDetail,
          assignGroup: item.assignGroup,
          cpId: item.cpId,
          period: item.period,
          countryCode: item.countryCode,
          type: item.type,
          detectionItem: item.detectionItem,
          uniqueId: item.uniqueId
        }));
        sub_statistics_tabledata.statistics.rowdata[0].deeplinkFlag = true;
        setCurrentIssueListTableData(sub_statistics_tabledata.statistics);
        onClickRowItem(sub_statistics_tabledata.statistics.rowdata[0]);
      }
    } else {
      sub_statistics_tabledata.statistics.rowdata = [];
      statistics_chartdata_value_dataset.data = [];
      statistics_chartdata_change_dataset.data = [];
      statistics_chartdata_avg_dataset.data = [];
      sub_statistics_chartdata.value_graph.options.scales.y.min = 0;
      sub_statistics_chartdata.value_graph.options.scales.y.max = defaultChartValue;
      sub_statistics_chartdata.value_graph.options.baseline.value = 0;
      sub_statistics_chartdata.changes_graph.options.scales.y.min = 0;
      sub_statistics_chartdata.changes_graph.options.scales.y.max = defaultChartValue;
      statistics_chartdata_value_dataset.data = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      statistics_chartdata_change_dataset.data = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      statistics_chartdata_avg_dataset.data = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      sub_statistics_chartdata.value_graph.data.datasets = [statistics_chartdata_value_dataset];
      sub_statistics_chartdata.changes_graph.data.datasets = [statistics_chartdata_change_dataset, statistics_chartdata_avg_dataset];

      setCurrentIssueListTableData(sub_statistics_tabledata.statistics);
      if(statisticsInitFlag === true) {
        setStatisticsInitFlag(false);
      } else {
        setAlertTitle(`Info`);
        setAlertMessage(`There is no data`);
        onOpenAlert();
        if (lineChartFetching === 'init') {
          setIsIssueChartDisplay(true);
        }
      }
    }
    if (issueListFetching === 'done') {
      setIsIssueDisplay(true);
    }
  }, [issueList]);

  useEffect(() => {
    if (lineChart.nativeValue && lineChart.nativeValue.length > 0) {
      let minNativeValue = parseFloat(lineChart.minNativeValue[0]);
      let maxNativeValue = parseFloat(lineChart.maxNativeValue[0]);
      let minValue = parseInt(lineChart.minValue[0]);
      let maxValue = parseInt(lineChart.maxValue[0]);
      let minAvgValue = parseInt(lineChart.minAvgValue[0]);
      let maxAvgValue = parseInt(lineChart.maxAvgValue[0]);
      let normalMaxValue = parseInt(lineChart.normalMaxValue[lineChart.normalMaxValue.length-1]);
      let normalMinValue = parseInt(lineChart.normalMinValue[lineChart.normalMaxValue.length-1]);
      if(minAvgValue < minValue) minValue = minAvgValue;
      if(maxAvgValue > maxValue) maxValue = maxAvgValue;
      if(maxValue === 0) maxValue = defaultChartValue;
      if(maxNativeValue === 0) maxNativeValue = defaultChartValue;
      if(minNativeValue === maxNativeValue) minNativeValue = 0;
      if(minValue === maxValue) minValue = 0;
      if(maxNativeValue < normalMaxValue) maxNativeValue = normalMaxValue;
      if(minNativeValue > normalMinValue) minNativeValue = normalMinValue;
      statistics_chartdata_value_dataset.data = [];
      statistics_chartdata_change_dataset.data = [];
      statistics_chartdata_avg_dataset.data = [];
      sub_statistics_chartdata.value_graph.options.scales.y.min = minNativeValue;
      sub_statistics_chartdata.value_graph.options.scales.y.max = getScalesMaxValue(maxNativeValue)
      sub_statistics_chartdata.value_graph.options.baseline.value = parseFloat(lineChart.avgNativeValue[0]);
      sub_statistics_chartdata.value_graph.options.baselineSubMax.value = parseFloat(normalMaxValue);
      sub_statistics_chartdata.value_graph.options.baselineSubMin.value = parseFloat(normalMinValue);
      sub_statistics_chartdata.changes_graph.options.scales.y.min = minValue;
      sub_statistics_chartdata.changes_graph.options.scales.y.max = getScalesMaxValue(maxValue);
      sub_statistics_chartdata.value_graph.data.labels = lineChart.periodValue;
      sub_statistics_chartdata.changes_graph.data.labels = lineChart.periodValue;
      statistics_chartdata_value_dataset.data = lineChart.nativeValue;
      statistics_chartdata_change_dataset.data = lineChart.value;
      statistics_chartdata_avg_dataset.data = lineChart.avgValue;
    }
    if (lineChartFetching === 'done') {
      setIsIssueChartDisplay(true);
    }
  }, [lineChart]);

  useEffect(() => {
    if (referenceList && referenceList.length > 0) {
      sub_statistics_tabledata.existing_issues.rowdata = referenceList.map(item => ( {
        row: [
          item.issueId,
          item.period,
          item.issueLevel,
          item.countryCode,
          item.contentName,
          `${Math.round(item.avgValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} %`,
          item.causeName,
          item.assignGroup,
          item.lastChgUsrId,
          item.jiraUrl,
          item.causeProcessDetail
        ]
      }));
      setCurrentReferenceListTableData(sub_statistics_tabledata.existing_issues);
      if (referenceListFetching === 'done') {
        setIsIssueReferenceDisplay(true);
      }
    } else {
      sub_statistics_tabledata.existing_empty_issues.rowdata = [];
    }
  }, [referenceList]);

  useEffect(() => {
    if (selectedItem && selectedItem.issueId !== '') {
      getReferenceListInfo(selectedItem.issueCode);
    }
    setCurrentFocusData(selectedItem);
  }, [selectedItem]);

  const onClickRowItem = item => {
    if (item && item.issueId !== '') {
      setCurrentFocusData(item);
      setIsIssueReferenceDisplay(false);
      let findCause = causeList.find(cause => cause.index === item.causeIdx);
      if(findCause) {
        handleChangeCause(findCause);
      } else {
        handleChangeCause({index:directValue, name:directName});
      }
      setSelectedItem({ issueId: item.issueId, issueCode: item.issueCode });
      setIsIssueChartDisplay(false);
      getIssueChartInfo(item);
    }
    setActionDetails({ causeIdx: item.causeIdx, causeDetail: item.causeProcessDetail });
  };

  const valueInitialize = (mode, item) => {
    if (mode === 'Save'){
      setParamPage(page);
      setPage(item);
    } else if (mode === 'paging'){
      setParamPage();
      setPage(item);
    } else {
      setParamPage();
      setPage(1);
    }
    setValueInitNeed(true);
    setIsIssueReferenceDisplay(false);
    setIsIssueChartDisplay(false);
    setLineChart([]);
    setSelectedItem({ issueId: '', issueCode: '' });
    setActionDetails({ causeIdx: directValue, causeDetail: '' });
    currentItem = null;
    sub_statistics_tabledata.existing_issues.rowdata = [];
    setCurrentReferenceListTableData(sub_statistics_tabledata.existing_issues);
    checkboxValueInitialize();
  };

  const fetchCause = async code => {
    const causeDrop = await request(`/api/statistics/dashboard/cause/${dataType}`, 'GET', null, true);
    let causeMap = causeDrop.map(n => ({ index: n.causeIdx, name: n.causeName.length > 40
      ? n.causeName.substring(0, 40) + '...' : n.causeName }));
    causeMap.push({ index: directValue, name: directName });
    causeMap.sort((a, b) => (a.index > b.index ? -1 : b.index > a.index ? 1 : 0))
    setCauseList(causeMap);
  };

  const getAssignGroupList = () => {
    fetch(`${ISS_BASE_URL}/api/common/issue/management/group`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        const groupNameList = [... new Set(body.dataSet.map(item => item.groupName))];
        const tempGroupNameList = groupNameList.map(item => {
          return { index: item, name: item }
        });
        sub_statistics_tabledata.statistics.assignDropdownItem = tempGroupNameList;
      } else {
        console.log(`getStatisticsErrorCount managementGroup fetching error`);
      }
    })
    .catch(error => {
      console.log(`getStatisticsErrorCount error: `, error);
    });
  };

  const handleTextAreaChange = event => {
    const { value } = event.target;
    if (value.length <= maxLength) {
      setCauseTextArea(value);
      setActionDetails({ causeIdx: actionDetails.causeIdx, causeDetail: value });
    }
  };

  const handleChangeCause = item => {
    setCauseSel(item.index);
    setCauseNameSel(item.name);
    setActionDetails({ causeIdx: item.index, causeDetail: actionDetails.causeDetail });
  };

  const onSubmit = async (event, item) => {
    event.preventDefault();

    switch (item) {
      case 'Save': {
        if(updatingIssueList && updatingIssueList.length > 0) {
          const findItem = updatingIssueList.find(item => currentFocusData && item.issueId == currentFocusData.issueId);
          if(!findItem){
            setAlertTitle(`Info`);
            setAlertMessage(`Please select a data`);
            onOpenAlert();
            return;
          }
          if (findItem && findItem.issueStatus === 'Resolve' && causeSel === directValue) {
            setAlertTitle(`Info`);
            setAlertMessage('Please select a Cause');
            onOpenAlert();
            return;
          }
          let itemList = [];
          for(let i=0; i< updatingIssueList.length; i++) {
            itemList.push({
              user: username === 'admin' ? 'cmpuser' : username,
              causeIdx: actionDetails.causeIdx,
              issueId: updatingIssueList[i].issueId,
              causeProcessDetail: causeTextArea,
              dataType: dataType,
              status: findItem.issueStatus,
              assignGroup: findItem.assignGroup,
            })
          }
          let isSuccess = await request(`/api/statistics/dashboard/updateCause`, 'PATCH', itemList, true, false, false);

          if (isSuccess.success) {
            setParamPage(page);
            setAlertTitle(`Info`);
            setAlertMessage('Cause is updated successfully');
            onOpenAlert();
            searchEvent('Save');
          } else {
            setAlertTitle(`Info`);
            setAlertMessage(isSuccess.message);
            onOpenAlert();
          }
        } else {
          setAlertTitle(`Info`);
          setAlertMessage('Please select any checkbox');
          onOpenAlert();
          return;
        }
        break;
      }
      case 'Jira': {
        if (updatingIssueList.length > 0) {
          let existJiraUrlList = [];

          if (currentTab === 0) {
            existJiraUrlList = updatingIssueList.filter(data => issueList.find(item => item.issueId === data.issueId && item.jiraUrl)).map(item => item.issueId);
            console.log(existJiraUrlList);
          } else {
            console.error(`[ISS] currentTab[${currentTab}] error, so ignore`);
            return;
          }

          if (existJiraUrlList.length > 0) {
            setAlertTitle(`Info`);
            setAlertMessage(`IssueId:(${existJiraUrlList}) is already registered in jira`);
            onOpenAlert();
          } else {
            const issueIds = updatingIssueList.map(item => item.issueId);
            console.log(issueIds);
            setIssueIdList(issueIds);
            setShowCreateJiraPopup(true);
          }
        } else {
          setAlertTitle(`Info`);
          setAlertMessage(`Please select any checkbox`);
          onOpenAlert();
        }
        break;
      }
    }
  };

  const handleJiraCallback = data => {
    if (data.result === 'SUCCESS') {
      setAlertTitle(`Info`);
      setAlertMessage(`Jira creation success`);
      onOpenAlert();
      searchEvent();
    } else {
      setAlertTitle(`Info`);
      setAlertMessage(`Jira creation failure, reason: ` + data.reason);
      onOpenAlert();
    }
  };

  const OnCloseCreateJiraPopop = () => {
    setShowCreateJiraPopup(false);
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const updateDropDownList = data => {
    if (updatingIssueList.length > 0) {
      const modifyUpdatingIssueList = updatingIssueList.map(item => {
        return { issueId: item.issueId, issueStatus: data.status, assignGroup: item.assignGroup };
      });

      setUpdatingIssueList(modifyUpdatingIssueList);
    }
  };

  const updateAssignDropDownList = data => {
    if (updatingIssueList.length > 0) {
      const modifyUpdatingIssueList = updatingIssueList.map(item => {
        return { issueId: item.issueId, issueStatus: item.issueStatus, assignGroup: data.status };
      });

      setUpdatingIssueList(modifyUpdatingIssueList);
    }
  };

  const checkboxValueInitialize = () => {
    setCheckboxValueInit(true);
    setUpdatingIssueList([]);
  };

  const updateCheckedList = items => {
    if (items.length > 0) {
      const checkedIssueList = items.map(item => {
        return {issueId: item.issueId, issueStatus: item.issueStatus, assignGroup: item.assignGroup};
      });
      setUpdatingIssueList(checkedIssueList);
    } else {
      setUpdatingIssueList([]);
    }
  };

  const handleJiraStatusCallback = status => {
    console.info(`[ISS] jira status: ${status}`);

    if (status === 'processing') {
      setModalPopupTitle('Jira Processing');
      setModalPopup(true);
    } else {
      setModalPopup(false);
    }
  };

  const changeDropDownAlert = () => {
    setAlertTitle('Warning');
    setAlertMessage('Select the checkbox to change the Dropdown box.');
    onOpenAlert();
  };

  return (
    <>
    {isIssueDisplay ? (
      <div className="grid-row-wide">
        <div className="row-box box-tbl box-statistics tbl-type-short">
        <IssTable height={'calc(100% - 55px)'} pagination={true}
          data={currentIssueListTableData}
          handleClickItem={item => onClickRowItem(item)}
          pagingChangeCallback={(item) => valueInitialize('paging', item)}
          handleDropDownList={(item) => updateDropDownList(item)}
          handleCheckedList={item => updateCheckedList(item)}
          isCheckboxValueInit={isCheckboxValueInit}
          handleAssignDropDownList={item => updateAssignDropDownList(item)}
          sortingItem={{enable: true, sortColumn: 0, sortDirection: 'desc'}}
                  changeDropDownAlert={changeDropDownAlert}
          handlePage={paramPage ? paramPage : page}
        />
        </div>
        <div className="row-box box-action">
          <div className="grid-column-3">
            <div className="box-value-detail">
              <div className="head">
                <div className="title">
                  <h2>Daily value change</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Normal Range</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_LINE_GRID_COLOR.BASE_LINE}` }}></i>
                      <span>Native AVG</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Native Value</span>
                    </li>
                  </ul>
                </div>
                {isIssueDisplay && !isIssueChartDisplay ? (
                <div className="loading_wrap">
                  <LoadingIndicator />
                </div>
                ) : (
                <LineChart id={'value_chart'}
                data={sub_statistics_chartdata.value_graph.data}
                options={sub_statistics_chartdata.value_graph.options} />
                )}
              </div>
            </div>
            <div className="box-rateChange-detail">
              <div className="head">
                <div className="title">
                  <h2>Daily inc/dec rate change</h2>
                </div>
              </div>
              <div className="ui-chart-container">
                <div className="chart-line-legend">
                  <ul>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.MINT}` }}></i>
                      <span>Change from day before</span>
                    </li>
                    <li>
                      <i style={{ backgroundColor: `${CHART_COLORS.PINK}` }}></i>
                      <span>Change from AVG</span>
                    </li>
                  </ul>
                </div>
                {isIssueDisplay && !isIssueChartDisplay ? (
                <div className="loading_wrap">
                  <LoadingIndicator />
                </div>
                ) : (
                <LineChart id={'changes_chart'}
                data={sub_statistics_chartdata.changes_graph.data}
                options={sub_statistics_chartdata.changes_graph.options} />
                )}
              </div>
            </div>
            <div className="box-actionDetail">
              <div className="form-container">
                <h3>Action Details (Count : {updatingIssueList.length})</h3>
                <div className="line">
                  <CauseDropDown items={causeList}
                  title={causeNameSel}
                  onChange={handleChangeCause} />
                </div>
                <div className="line">
                  <textarea value={causeTextArea} placeholder="Enter action details"
                  maxLength={maxLength} onChange={event => handleTextAreaChange(event)}
                  />
                </div>
                {causeTextArea ? causeTextArea.length : 0}/{maxLength}
                <div className="button-container align-right">
                  <PrimaryButton type={'button'} text={'Save'}
                  btnClass={'btn-primary-gray'} onClick={async e => onSubmit(e, 'Save')}/>
                  <PrimaryButton type={'button'} text={'Create Jira'}
                  btnClass={'btn-primary-accent'} onClick={async e => onSubmit(e, 'Jira')}/>
                </div>
              </div>
            </div>
          </div>
          <div className="box-existingIssues">
            <h3>&nbsp;&nbsp;Issue History</h3>
            {isIssueReferenceDisplay ? (
              <div className="tbl-container">
                <IssTable height={'100%'} pagination={false}
                data={currentReferenceListTableData} />
              </div>
            ) : (
              <div className="tbl-container">
              <IssTable height={'100%'} pagination={false}
              data={sub_statistics_tabledata.existing_empty_issues} />
              </div>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div className="loading_wrap">
        <Loading isVisible={true} />
      </div>
    )}
    {isShowCreateJiraPopup && <CreateJiraModal issueIdList={issueIdList} isOpen={isShowCreateJiraPopup} onClose={OnCloseCreateJiraPopop} jiraCallback={handleJiraCallback} type={'STATISTICS'} jiraStatusCallback={handleJiraStatusCallback}/>}
    {isAlert && <Alert isOpen={isAlert} onClose={onCloseAlert} title={alertTitle} message={alertMessage}/>}
    {isModalPopup && <ModalPopup isOpen={isModalPopup} title={modalPopupTitle}/>}
    </>
  );
};
StatisticsTab.propTypes = {
  searchEvent: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  username: state.currentUser.username,
});
export default connect(mapStateToProps)(StatisticsTab);
