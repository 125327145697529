import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Display from './Display';
import SliderTable from './SliderTable';
import { request } from '../../../util/request';
import { getTabCategorySliderResultAction } from '../../../actions/homeAppActions';
//import { getTabCategoryAction } from '../../../actions/homeAppActions';

function SliderResult({ id, history, cntry, cat, result, setResult, renderOrder, selectResult, onClickAdd, onClickDelete, onClickUploadImage, homeAppResult, onClickReset, getTabCategorySliderResultAction }) {
  const clickSave = async e => {
    e.preventDefault();

    let orderSet = new Set();
    result.map(n => {
      orderSet.add(n.result_order);
    });

    if (result.length < 1) alert('The number of result is less than 1 ! Please add at lease one content !');
    else if (result.length > 5) alert('The number of result is more than 5 ! Please remove some content !');
    //else if (result.length > 15) alert('The number of result is more than 15 ! Please remove some content !');
    else if (result.find(n => n.result_order === '')) alert('Some order is empty ! Please enter the order !');
    else if (result.find(n => n.result_order > 5)) alert('The result order cannot exceed 5 !');
    //TODO order duplication check
    else if (result.length !== orderSet.size) alert('Duplicate order numbers exist !');
    else {
      alert('Are you sure to save the result?');
      const newResult = result.map(n => {
        const { country_code, cp_idx, provider_idx, type, content_id, result_order, manual_flag, horizontalImg, bigImg } = n;
        let categoryResult = { categoryIdx: id, countryCode: country_code, manualFlag: manual_flag, horizontalImg: horizontalImg, bigImg: bigImg };
        categoryResult.providerIdx = cp_idx || provider_idx;
        categoryResult.contentType = type;
        categoryResult.contentId = content_id;
        categoryResult.resultOrder = result_order;
        return categoryResult;
      });
      const isSuccess = await request(`/api/category/slider/${id}/result?cntry=${cntry}`, 'PATCH', newResult);
      if (isSuccess) {
        alert('Successfully saved !');
        getTabCategorySliderResultAction(cntry, id);
      }
    }
  };

  React.useEffect(() => {
    setResult(homeAppResult);
  }, []);

  return (
    <React.Fragment>
      <div className="arrow_btn_area">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Button className="btn_move piece" title="Click this to add content" onClick={onClickAdd} />
        <br />
        <Button className="btn_move remove" title="Click this to remove content" onClick={onClickDelete} />
      </div>
      <div className={'tbl_wrap tbl_head_fixed tbl_source_conf channel_list'} style={{ marginLeft: '6%' } /* margin-left: 6%;+'tbl_no_data'*/}>
        <span>
          <div className="mapping_tbl_header_manual">
            <Display cat={cat}></Display>
            <br />
            <br />
            <br />
            <br />
          </div>
        </span>
        <span id="popup"></span>
        <SliderTable
          isResult
          data={result}
          setData={setResult}
          renderOrder={renderOrder}
          select={selectResult}
          options={{
            selection: true,
            showTextRowsSelected: false,
            //isLoading: true,
            //search: false,
            paging: true,
            pageSize: 15,
            pageSizeOptions: [5, 15, 30, 50, 100],
            doubleHorizontalScroll: true,
            maxBodyHeight: 500,
            minBodyHeight: 500,
            //showEmptyDataSourceMessage: false,
            rowStyle: rowData => ({
              backgroundColor: rowData.manual_flag === 'Y' ? '#fef5f8' : '#FFF',
              color: rowData.change ? '#d8295c' : '#484848',
            }),
          }}
        />
        <React.Fragment>
          <form id="addRowFrm" autoComplete="off">
            <div className="btn_set_wrap">
              <Button className="btn_color2" onClick={onClickUploadImage}>
                Upload Image
              </Button>
              <Button className="btn_color2" onClick={onClickReset}>
                Reset
              </Button>
              <Button className="btn_color2" onClick={clickSave}>
                save
              </Button>
              <Button
                className="btn_color2"
                onClick={() => {
                  history.push('/HOME-APP/tab');
                }}>
                Back
              </Button>
            </div>
          </form>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
}
SliderResult.propTypes = {};
const mapStateToProps = state => ({
  homeAppResult: state.homeApp.sliderResultList,
  regionList: state.homeApp.regionList,
  param: state.homeApp.param,
  tabCategory: state.homeApp.tabCategory,
});
const mapDispatchToProps = dispatch => ({
  getTabCategorySliderResultAction(cntry, id) {
    dispatch(getTabCategorySliderResultAction(cntry, id));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SliderResult));
