import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

export default function HomeAppFilter({ regionList, regionPlatformList, deviceType, handleChangeDeviceType, region, handleChangeRegion, status, handleChangeStatus, platformVersion, handleChangePlatformVersion, cntryList, cntry, changeCntry }) {
  return (
    <div className="filter_area">
      <FormControl variant="outlined">
        <InputLabel /*id="Region"없어도 되는듯*/>Device Type</InputLabel>
        <Select value={deviceType} onChange={handleChangeDeviceType}>
          {regionList.map(n => (
            <MenuItem key={n.deviceType} value={n.deviceType}>
              {n.deviceType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {region && <React.Fragment>
        <FormControl variant="outlined">
          <InputLabel /*id="Region"없어도 되는듯*/>Region</InputLabel>
          <Select value={region} onChange={handleChangeRegion}>
            {(regionList.find(n => n.deviceType === deviceType) || { list: [] }).list.map(n2 => (
              <MenuItem key={n2.region} value={n2.region}>
                { n2.region + '(' + n2.cntry.join(', ') + ')'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel /*id="Region"없어도 되는듯*/>platform version</InputLabel>
          <Select value={platformVersion} onChange={handleChangePlatformVersion}>
            {(regionPlatformList.filter(n => n.deviceType === deviceType && n.region === region)).map(n2 => (
              <MenuItem key={n2.platformVersion} value={n2.platformVersion}>
                {n2.platformVersion}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined">
          <InputLabel>Status</InputLabel>
          <Select value={status} onChange={handleChangeStatus}>
            {['Saved', 'Testing', 'Published'].map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </React.Fragment>}
      {cntryList && (
        <FormControl variant="outlined">
          <InputLabel>Country</InputLabel>
          <Select value={cntry} onChange={changeCntry}>
            {cntryList.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
}
HomeAppFilter.propTypes = {
  regionList: PropTypes.array.isRequired,
  regionPlatformList: PropTypes.array.isRequired,
};
