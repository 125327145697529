import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 12px;
    --y: 404px;
    --width: 1894px;
    --height: 80px;

    top: 0;
    left: 0;
    width: 1920px;
    height: 694px;
  }

  #clip-02 {
    --x: 12px;
    --y: 1px;
    --width: 1264px;
    --height: 437px;

    top: 694px;
    left: 0;
    width: 1289px;
    height: 2000px;
  }

  #clip-03 {
    --x: 1px;
    --y: 1px;
    --width: 617px;
    --height: 437px;

    top: 694px;
    left: 1289px;
    width: calc(100% - 1289px);
    height: 2000px;
  }

  #text-01 {
    top: 626px;
    left: 40px;
  }

  #line-01 {
    top: 490px;
    left: 1128px;
  }
`;

const ContentDetail02 = () => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      window.scrollTo(0, elementRef.current.offsetTop - 90); // GNB height
    }
  }, []);

  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" ref={elementRef} className="border" />
        </div>

        <div id="clip-02" className="clip-box">
          <div id="clip-02-hole" className="hole" />
          <div id="clip-02-border" className="border" />
        </div>

        <div id="clip-03" className="clip-box">
          <div id="clip-03-hole" className="hole" />
          <div id="clip-03-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">IF you select the issue item, you can check action point and details about that</div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_6137_line_01.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default ContentDetail02;