import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setAllCountry } from '../../actions/rssFilterActions';
import { InputLabel, TextField, FormControl, Select, Button, MenuItem } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
export function RssFeedFilter({ country, cp, getRssFeedList, handleCntry, allcountry, handleCpName, setAllCountry }) {
  useEffect(() => {
    setAllCountry();
  }, [setAllCountry]);
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className="filter_area">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="Country">Country</InputLabel>
          <Select labelId="Country" id="Country" value={country.selectValue} label="Country" onChange={e => handleCntry({ selectKey: e.target.name, selectValue: e.target.value })}>
            {allcountry.map(v => {
              return (
                <MenuItem key={v.selectValue} name={v.selectKey} value={v.selectValue}>
                  {v.selectValue}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField onChange={e => handleCpName(e.target.value)} label="CP Name" value={cp} variant="outlined" />
      </div>
      <div className="btn_set_wrap search_wrap">
        <Button variant="contained" className={'btn_color2'} onClick={getRssFeedList}>
          {' '}
          Search{' '}
        </Button>
      </div>
    </React.Fragment>
  );
}

RssFeedFilter.propTypes = {
  country: PropTypes.object,
  cp: PropTypes.string,
  getRssFeedList: PropTypes.func,
  handleCntry: PropTypes.func,
  allcountry: PropTypes.array,
  handleCpName: PropTypes.func,
  setAllCountry: PropTypes.func,
};
const mapStateToProps = state => ({
  allcountry: state.rssFeed.countries,
});
const mapDispatchToProps = dispatch => ({
  setAllCountry: () => dispatch(setAllCountry()),
});
export default connect(mapStateToProps, mapDispatchToProps)(RssFeedFilter);
