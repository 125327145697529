import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, TablePagination, } from '@material-ui/core';
import { connect } from 'react-redux';
import {
    getSupportLanguagesAction, getMonitorDataAction, getServiceBaseDetailsAction, getMonitorPreviewDataAction
} from '../../actions/homeAppActions';
import TabContent from './MonitorTabContent';
import MonitorFilter from './MonitorFilter';
import NoData from '../../common/NoData';
import MaterialTable from 'material-table';
import TabCategoryList from './MonitorTabCategory';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
const modes = [{ value: 'All', label: 'All' }, { value: 'P', label: 'Published' }, { value: 'T', label: 'Testing' }];
const apiStatusList = [{ value: 'All', label: 'All' }, { value: 'true', label: 'Success' }, { value: 'false', label: 'Fail' }];
function Monitor({ monitorServiceData, getMonitorServiceData, supportLanguages, getSupportLanguages,
    monitorData, getMonitorData, getMonitorPreviewData }) {

    const tableRef = useRef(null);
    const [country, setCountry] = useState('');
    const [mode, setMode] = useState('All');
    const [language, setLanguage] = useState('');
    const [deviceVersion, setDeviceVersion] = useState('');
    const [platformCode, setPlatformCode] = useState('');
    const [serviceDevice, setServiceDevice] = useState('');
    const [deviceVersions, setDeviceVersions] = useState([]);
    const [platformCodes, setPlatformCodes] = useState([]);
    const [serviceDevices, setServiceDevices] = useState([]);
    const [apiStatus, setApiStatus] = useState('false');
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState();
    const [requiredField, setRequiredField] = useState(false);

    //== Table Pagination.
    let defaultPageNo = '1';
    let defaultRowPerPage = '10';
    const [pageNo, setPageNo] = useState(defaultPageNo);
    const [rowPerPage, setRowPerPage] = useState(defaultRowPerPage);

    useEffect(() => {
        getMonitorServiceData();
        getMonitorData({ country, mode, language, deviceVersion, platformCode, serviceDevice, apiStatus }, defaultPageNo, defaultRowPerPage);
    }, []);

    useEffect(() => {
        getSupportLanguages(country);
    }, [country]);

    useEffect(() => {
        getMonitorData({ country, mode, language, deviceVersion, platformCode, serviceDevice, apiStatus }, pageNo, rowPerPage);
    }, [pageNo, rowPerPage]);

    const submit = () => {
        const defaultFieldsStatus = country == '' && language == '' && deviceVersion == ''
            && platformCode == '' && serviceDevice == '';
        const selectedFieldsStatus = country !== '' && language !== '' && deviceVersion !== ''
            && platformCode !== '' && serviceDevice !== '';
        if (defaultFieldsStatus || selectedFieldsStatus) {
            setRequiredField(false);
        } else {
            setRequiredField(true);
            return;
        }
        getMonitorData({ country, mode, language, deviceVersion, platformCode, serviceDevice, apiStatus: apiStatus == 'All' ? null : apiStatus });
    };

    const showContent = (content) => {
        setContent(content);
        setOpen(true);
    };

    //====setting default langauage
    const getDeafultLang = supportLanguages.find(x => x.default_flag === true);
    useEffect(() => {
        if (getDeafultLang) {
            setLanguage(getDeafultLang.lang_code);
        }
    }, [getDeafultLang]);

    const handleChangeCountry = (e) => {
        const countryCode = e.target.value;
        const filterCountryData = monitorServiceData.find(x => x.countryCode === countryCode);
        const serviceDevicesList = filterCountryData ? filterCountryData.serviceDevices : [];
        const deviceVersionsList = filterCountryData ? filterCountryData.deviceVersions : [];
        const platformCodesList = filterCountryData ? filterCountryData.platformCodes : [];
        setCountry(countryCode);
        setDeviceVersion('');
        setMode('All');
        setPlatformCode(platformCodesList[0]);
        setServiceDevice(serviceDevicesList[0]);
        setServiceDevices(serviceDevicesList);
        setDeviceVersions(deviceVersionsList);
        setPlatformCodes(platformCodesList);
    };

    const handelPreviewData = (rowData) => {
        tableRef.current.onToggleDetailPanel(
            [tableRef.current.dataManager.sortedData.findIndex(item => item.id === rowData.id)],
            rowData => <TabCategoryList previewData={rowData} showContent={showContent} />);
    };

    const exportData = (rowData) => {
        getMonitorPreviewData(rowData, true);
    };

    const handleChangePage = (page) => {
        setPageNo(page);
    };

    const handleChangeRowPerPage = (rowsPerPageCount) => {
        setRowPerPage(rowsPerPageCount);
    };

    return (
        <Fragment>
            <MonitorFilter
                allCountries={monitorServiceData.map(x => x.countryCode)}
                modes={modes}
                supportLanguages={supportLanguages}
                deviceVersions={deviceVersions}
                platformCodes={platformCodes}
                serviceDevices={serviceDevices}
                apiStatusList={apiStatusList}
                country={country}
                mode={mode}
                language={language}
                deviceVersion={deviceVersion}
                platformCode={platformCode}
                serviceDevice={serviceDevice}
                apiStatus={apiStatus}
                requiredField={requiredField}
                handleChangeCountry={handleChangeCountry}
                handleChangeMode={e => setMode(e.target.value)}
                handleChangeLanguage={e => setLanguage(e.target.value)}
                handleChangeDeviceVersion={e => setDeviceVersion(e.target.value)}
                handleChangePlatformCode={e => setPlatformCode(e.target.value)}
                handleChangeServiceDevice={e => setServiceDevice(e.target.value)}
                handleChangeApiStatus={e => setApiStatus(e.target.value)} />


            <div className="btn_set_wrap search_wrap">
                <Button variant="contained" className={'btn_color2'} onClick={submit}>
                    Search
                </Button>
            </div>
            <div className="monitor_area">
                {
                    monitorData.data.status ? (
                        <NoData message={monitorData.data.message} />
                    )
                        :
                        <MaterialTable
                            tableRef={tableRef}
                            columns={[
                                {
                                    title: 'Country Code',
                                    field: 'countryCode',
                                },

                                {
                                    title: 'Channel Map Id',
                                    field: 'channelMapId',
                                },
                                {
                                    title: 'Mode',
                                    render: rowData => (rowData.status === 'P' ? 'Published' : 'Testing')
                                },
                                {
                                    title: 'Language',
                                    field: 'langCode',
                                },
                                {
                                    title: 'Status',
                                    render: rowData => (rowData.apiStatus == 1 ? 'OK' : 'Fail'),
                                },
                                {
                                    title: 'Last Updated',
                                    field: 'crtDate',
                                },
                                {
                                    title: 'Action',
                                    render: (rowData) => (
                                        <div className={'btn_set_wrap_tbl'}>
                                            <Button variant="contained" className={'btn_size_tbl btn_color3'} onClick={() => handelPreviewData(rowData)}>
                                                Preview
                                            </Button>
                                            {rowData.apiStatus == 1 ?
                                                <Button variant="contained" className={'btn_size_tbl btn_color3'} onClick={() => exportData(rowData)}>
                                                    {/* Download Json */}
                                                    <ArrowDownwardIcon />
                                                </Button> : null
                                            }
                                        </div>
                                    ),
                                },
                            ]}
                            data={monitorData.data.monitorList}
                            isLoading={monitorData.loading}
                            components={{
                                Pagination: props => (
                                    <TablePagination
                                        {...props}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rowsPerPage={monitorData.data.rowPerPage}
                                        count={monitorData.data.totCnt}
                                        page={
                                            monitorData.data.pageNo - 1
                                        }
                                        onChangePage={(_e, page) =>
                                            handleChangePage(page + 1)
                                        }
                                        onChangeRowsPerPage={event => {
                                            props.onChangeRowsPerPage(event);
                                            handleChangeRowPerPage(event.target.value);
                                        }}
                                    />
                                ),
                            }}
                            options={{
                                search: false,
                                pageSize: 10,
                                detailPanelType: 'single',
                                defaultExpanded: false,
                            }}
                        />
                }
            </div >
            <Dialog open={open} className={'pop_wrap'}>
                <TabContent content={content} handleClose={() => setOpen(false)} />
            </Dialog>
        </Fragment >);
}

Monitor.propTypes = {
    getMonitorServiceData: PropTypes.func.isRequired,
    getSupportLanguages: PropTypes.func.isRequired,
    getMonitorData: PropTypes.func.isRequired,
    getMonitorPreviewData: PropTypes.func.isRequired,
    monitorServiceData: PropTypes.array.isRequired,
    supportLanguages: PropTypes.array.isRequired,
    monitorData: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    param: state.homeApp.param,
    monitorServiceData: state.homeApp.monitorServiceData,
    supportLanguages: state.homeApp.supportLanguages,
    monitorData: state.homeApp.monitor,
});

const mapDispatchToProps = dispatch => ({
    getMonitorServiceData: () => dispatch(getServiceBaseDetailsAction()),
    getSupportLanguages: (cntry) => dispatch(getSupportLanguagesAction(cntry)),
    getMonitorData: (input, pageNo, rowPerPage) => dispatch(getMonitorDataAction(input, pageNo, rowPerPage)),
    getMonitorPreviewData: (rowData, download) => dispatch(getMonitorPreviewDataAction(rowData, download)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Monitor);