import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import MaterialTable from 'material-table';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

function ChannelCount() {
  const defaultSearchData = {
    ricCode: '',
    countryCode: '',
    channelMapId: '',
  };
  const [searchData, setSearchData] = useState(defaultSearchData);
  const [channelCount, setChannelCount] = useState([]);
  const [channelMap, setChannelMap] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [channelMapIdList, setChannelMapIdList] = useState([]);

  useEffect(() => {
      fetch(`${API_BASE_URL}/admin/channelCount/channelmap`, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then(res => res.json())
        .then(body => {
          if (body.status) {
            alert('[server error] ' + body.message);
          } else {
            setChannelMap(body.channelmap);
          }
        })
        .catch(error => {
          alert('[error message] ' + error);
        });
  }, []);
  useEffect(() => {
    if (channelMap.length > 0){
      setCountryList(channelMap[0].countryList);
      setChannelMapIdList(channelMap[0].countryList[0].channelMapIdList);
      setSearchData({ ricCode: channelMap[0].ricCode, countryCode: channelMap[0].countryList[0].countryCode, channelMapId: channelMap[0].countryList[0].channelMapIdList[0].channelMapId } );
    }
  }, [ channelMap ] );

  useEffect(() => {
    if (searchData.ricCode != '' && searchData.countryCode != '' && searchData.channelMapId != ''){
      fetch(`${API_BASE_URL}/admin/channelCount?countryCode=${searchData.countryCode}&channelMapId=${searchData.channelMapId}`, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then(res => res.json())
        .then(body => {
          if (body.status) {
            alert('[server error] ' + body.message);
          } else {
            setChannelCount(body.channel);
          }
        })
        .catch(error => {
          alert('[error message] ' + error);
        });
    }

  }, [searchData]);

  const handleSearchDataChange = e => {
    const { name, value } = e.target;
    if (name === 'ricCode' ) {
      let country  = channelMap.find(n => (n.ricCode === value));
      let map  = country.countryList.find(n => (n.countryCode === country.countryList[0].countryCode));
      setCountryList(country.countryList);
      setChannelMapIdList(map.channelMapIdList);
      setSearchData({ ...searchData, ...{ [name]: value, countryCode: country.countryList[0].countryCode, channelMapId: map.channelMapIdList[0].channelMapId } });
    } else if (name === 'countryCode') {
      let map  = channelMap.find(n => (n.ricCode === searchData.ricCode)).countryList.find(n => (n.countryCode === value));
      setChannelMapIdList(map.channelMapIdList);
      setSearchData({ ...searchData, ...{ [name]: value, channelMapId: map.channelMapIdList[0].channelMapId } });
    } else {
      setSearchData({ ...searchData, ...{ [name]: value } });
    }
  };

  return (
    <Fragment>
      <div className="jss88">
        <div className="filter_area">
          <FormControl required variant="outlined" >
            <InputLabel>ric code</InputLabel>
            <Select name="ricCode" id="ricCode" value={searchData.ricCode} onChange={e => handleSearchDataChange(e)}>
              {channelMap.map(item => (
                <MenuItem key={item.ricCode} value={item.ricCode}>
                {item.ricCode}
              </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel>country</InputLabel>
            <Select name="countryCode" id="countryCode" value={searchData.countryCode} onChange={e => handleSearchDataChange(e)}>
            {countryList.map(item => (
              <MenuItem key={item.countryCode} value={item.countryCode}>
                {item.countryCode}
              </MenuItem>
            ))}
          </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel>channel map ID</InputLabel>
            <Select name="channelMapId" id="channelMapId" value={searchData.channelMapId} onChange={e => handleSearchDataChange(e)}>
              {channelMapIdList.map(item => (
                <MenuItem key={item.channelMapId} value={item.channelMapId}>
                  {item.channelMapId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="monitor_area">
          <MaterialTable
            title=""
            columns={[
              {
                title: 'ric',
                field: 'ricCode',
                cellStyle: (e, rowData) => {
                  if (rowData.ricCode === 'Total') {
                    return { color: '#FF0000' };
                  }
                },
              },
              {
                title: 'country',
                field: 'countryCode',
              },
              {
                title: 'channel Map Id',
                field: 'channelMapId',
              },
              {
                title: 'provider',
                field: 'providerId',
              },
              {
                title: 'test Channel Count',
                field: 'testChannelMapCount',
                cellStyle: (e, rowData) => {
                  if (rowData.ricCode === 'Total') {
                    return { color: '#FF0000' };
                  }
                },
              },
              {
                title: 'published Channel Count',
                field: 'publishedChannelMapCount',
                cellStyle: (e, rowData) => {
                  if (rowData.ricCode === 'Total') {
                    return { color: '#FF0000' };
                  }
                },
              }
            ]}
            data={channelCount}
            options={{
              filtering: false,
              selection: false,
              paging: false,
              //pageSize: 10,
              rowStyle: { backgroundColor: '#fff' },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
}

ChannelCount.propTypes = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelCount);
