import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { getUserCreatedChannelsAction } from '../../../actions/channelActions';
import { getProvidersAdminAction } from '../../../actions/providerActions';
import { CHANNEL_LIST_SIZE } from '../../../constants';
const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      //width: 1100, //최대width를 1100으로 고정시키는 의미
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  noChannels: {
    margin: 50,
    padding: 50,
  },
});
// function usePrevious(value) {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }
function ChannelList({ channels, classes, getUserCreatedChannelsAction, getProvidersAdminAction, username, isRoleAdmin }) {
  const loadChannelList = useCallback(
    (page = 0, size = CHANNEL_LIST_SIZE) => {
      if (username) {
        getUserCreatedChannelsAction(page, size, isRoleAdmin);
        getProvidersAdminAction(page, size);
      }
    },
    [getUserCreatedChannelsAction, username],
  );
  useEffect(() => {
    if (channels.length === 0) {
      loadChannelList();
    }
  }, [loadChannelList, channels.length]);
  return (
    <div className={classes.layout}>
      {channels.length > 0 && (
        <MaterialTable
          title=" " //없으면 Table Title이 default title로 나옴
          columns={[
            {
              title: 'provider',
              field: 'provider',
              //   lookup: { PLUTO: 'PLUTO', RAKUTEN: 'RAKUTEN' },
            },
            {
              title: 'channelId',
              field: 'channelId',
              filtering: false,
              initialEditValue: 'initial edit value',
            },
            { title: 'name', field: 'name', filtering: false },
            {
              title: 'channelNumber',
              field: 'channelNumber',
              filtering: false,
            },
            { title: 'type', field: 'type', filtering: false },
            {
              title: 'logo',
              field: 'logo',
              filtering: false,
              render: rowData => <img src={rowData.logo} title={rowData.logo} style={{ width: 70, backgroundColor: 'black' }} />,
            },
            { title: 'genre', field: 'genre', filtering: false },
          ]}
          data={channels.map(n => {
            let newData = {};
            newData['provider'] = n.providerId;
            newData['channelId'] = n.channelId;
            newData['name'] = n.channelName;
            newData['channelNumber'] = n.channelNumber;
            newData['type'] = n.channelType;
            newData['logo'] = n.logoUrl;
            newData['genre'] = n.channelGenreCode;
            return newData;
          })}
          options={{
            pageSize: 10,
          }}
        />
      )}
    </div>
  );
}
ChannelList.propTypes = {
  channels: PropTypes.array.isRequired,
  sample: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  getUserCreatedChannelsAction: PropTypes.func.isRequired,
  username: PropTypes.string,
};
const mapStateToProps = state => ({
  channels: state.channels.channels,
  sample: state.channels.sample,
  providers: state.providers.providers,
  fetching: state.programs.fetching,
  username: state.currentUser.username,
  isRoleAdmin: state.currentUser.isRoleAdmin,
});
const mapDispatchToProps = dispatch => ({
  getUserCreatedChannelsAction(page, size, isRoleAdmin) {
    dispatch(getUserCreatedChannelsAction(page, size, isRoleAdmin));
  },
  getProvidersAdminAction(page, size) {
    dispatch(getProvidersAdminAction(page, size));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ChannelList)));
