import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import CpCodeForm from './CpCodeForm';
import { validateInteractiveUrl } from './CPCodeAdd';
import { getProvidersAdminAction } from '../../../actions/providerActions';
import { request } from '../../../util/request';

function CpCodeEdit({ getProvidersAdminAction, item }) {
  const [filter, setFilter] = useReducer((state, newState) => ({ ...state, ...newState }), {
    providerId: {
      value: item['CP Code'], //server notBlank constraint, actually modified
    },
    providerName: {
      value: item['Name'],
    },
    interactiveUrl: {
      value: item['Interative URL'],
      errorMsg: 'If LG Player is not used, this Interactive URL is mandatory',
    },
    logoUrl: {
      value: item['Logo'],
    },
    lgPlayerFlag: {
      value: item['Use LGPlayer'],
    },
  });

  const [open, setOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const handleInputChange = e => {
    const inputId = e.target.name;
    setFilter({
      [inputId]: {
        value: e.target.value,
        errorMsg: filter[inputId].errorMsg,
      },
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (validateInteractiveUrl(filter.lgPlayerFlag.value, filter.interactiveUrl.value)) {
      const newItem = {
        providerId: filter.providerId.value,
        providerName: filter.providerName.value, //TODO: not modified
        //appId: filter.providerName.value,
        lgPlayerFlag: filter.lgPlayerFlag.value === 'Y' ? true : false,
        interactiveUrl: filter.interactiveUrl.value,
        logoUrl: filter.logoUrl.value,
      };
      alert('Are you sure to edit this CP Code?');
      //alert('blocked');
      const isSuccess = await request('/api/admin/providers/' + item['CP Code'], 'PATCH', newItem);
      //console.log('[isSuccess]', isSuccess); //await 없으면 Promise {<pending>}//request내부await 없으면 무조건 기본값리턴
      if (isSuccess) {
        setSuccessSnackbarOpen(true);
        getProvidersAdminAction();
        setOpen(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={() => setOpen(true)} className={'btn_color3 btn_size_tbl'}>
        Edit
      </Button>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={1000} //1000=1sec
        onClose={() => setSuccessSnackbarOpen(false)} //없으면 없어지지않음
        message="Successfully Doned !"
        style={{ backgroundColor: 'black' }}
      />
      <Dialog open={open} className={'pop_wrap'}>
        <CpCodeForm title="Edit CP Code" isCpCodeVisible={false} filter={filter} handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={() => setOpen(false)} />
      </Dialog>
    </React.Fragment>
  );
}

CpCodeEdit.propTypes = {
  getProvidersAdminAction: PropTypes.func.isRequired,
};
// const mapStateToProps = (state) => ({
//   isAdded: state.providers.isAdded,
// });
const mapDispatchToProps = dispatch => ({
  getProvidersAdminAction() {
    dispatch(getProvidersAdminAction());
  },
});
export default connect(null, mapDispatchToProps)(CpCodeEdit);
