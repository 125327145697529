import { SET_ALL_COUNTRY, SET_DETAIL_COUNTRY, SET_API_FAIL } from './actionTypes';
import { API_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';
const URL = API_URL;
const getCountries = list => ({
  type: SET_ALL_COUNTRY,
  payload: list,
});
const setDetailCountry = list => ({
  type: SET_DETAIL_COUNTRY,
  payload: list,
});
const doFail = list => ({
  type: SET_API_FAIL,
  payload: list,
});
// 국가목록
export const setAllCountry = () => async dispatch => {
  // let exam = [{ cntryCode: 'US', cntryName: 'Us' }, { cntryCode: 'KR', cntryName: 'Kr' }];
  // let cps = [{ cpCode: 'US', cpName: 'Us' }, { cpCode: 'KR', cpName: 'Kr' }];
  let apiUrl = `${URL}/api/sdepth/countries`;
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
    // body: param
  })
    .then(res => res.json())
    .then(body => dispatch(getCountries(body)))
    .catch(() => dispatch(doFail()));
};
export const setDetailPageCountries = () => async dispatch => {
  // let exam = [{ cntryCode: 'US', cntryName: 'Us' }, { cntryCode: 'KR', cntryName: 'Kr' }];
  // let cps = [{ cpCode: 'US', cpName: 'Us' }, { cpCode: 'KR', cpName: 'Kr' }];
  let apiUrl = `${URL}/api/sdepth/countries`;
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch(setDetailCountry(body)))
    .catch(() => dispatch(doFail()));
};
