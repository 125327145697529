import * as actions from '../../actions/actionTypes';

const initialState = { cntry: 'US', cp: 'PLT' };

export default function param(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PROGRAMS_PARAM:
      //console.log('[SET_PROGRAMS_PARAM][action]', action);

      if (action.payload) {
        return { ...action.payload };
      } else {
        return state;
      }
    // case actions.REHYDRATE:
    //   return state;
    default:
      return state;
  }
}
