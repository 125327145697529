import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ChannelList, { getDatePosition, convertToUtc } from '../channelList/channelList.js';
import Timeline from '../timeline/timeline.js';
import { makeStyles } from '@material-ui/core/styles';
import ProgramDialog from '../../../program/Program/ProgramDialog';
//import LoadingIndicator from '../../../../common/LoadingIndicator';
//import useAssets from '../useAssets/useassets';

const useStyles = makeStyles(() => ({
  landingpage: {
    display: 'flex', //없으면 화면구성 이상해짐
    flexDirection: 'column', //없으면 뭔가 약간 이상해짐
    alignItems: 'center', //없으면 화면구성 이상해짐
    background: '#fff',
    //height: '77vh',//채널갯수 많을 때 화면밑으로 내려가지 않도록
    overflowX: 'auto', //채널갯수 많을 때 화면밑으로 내려가지 않고 스크를되도록
    overflowY: 'hidden',
  },
  alignment: {
    position: 'relative', //없으면 verticalNowLine없어지고 이상해짐
  },
}));
let popOpenCheck = false;
const LandingPage = ({ schedules }) => {
  const classes = useStyles();

  const now = convertToUtc(new Date());
  const position = getDatePosition(now);
  //const position = useState(currentShows); //7200
  //const [position, setPosition] = useState(currentShows); //7200
  const scrollElem = useRef(0);

  let startX;
  let startScroll;
  let clickProgramListener = true;
  let holdClickProgramListener;
  const moveDrug = function (e) {
    if (popOpenCheck === false) {
      e.preventDefault();
      startX = e.pageX;
      startScroll = document.getElementById('moveCont').scrollLeft;
      window.addEventListener('mousemove', removeStart);
      window.addEventListener('mouseup', removeStop);
    }
  };
  const removeStart = function (e) {
    clickProgramListener = false;
    document.getElementById('moveCont').scrollLeft = startScroll + (startX - e.pageX);
  };
  const removeStop = function () {
    window.removeEventListener('mousemove', removeStart);
    holdClickProgramListener = setTimeout(function () {
      clickProgramListener = true;
    }, 500);
    //
  };
  //console.log('[schedules]', schedules);
  //console.log('position=', position, '[schedules.length]', schedules.length);

  const [programDialogOpen, setProramDialogOpen] = useState(false);
  const [programId, setProgramId] = useState();
  const [providerId, setProviderId] = useState();

  const clickProgramDialog = e => {
    // console.log('[e.currentTarget][providerId]', e.currentTarget.parentNode.parentNode.id);
    // console.log('[e.currentTarget][programId]', e.currentTarget.parentNode.id);
    if (clickProgramListener === true) {
      setProgramId(e.currentTarget.parentNode.id);
      setProviderId(e.currentTarget.parentNode.parentNode.parentNode.id);
      setProramDialogOpen(true);
      popOpenCheck = true;
    }
  };

  const closeProgramDialog = () => {
    setProramDialogOpen(false);
    popOpenCheck = false;
  };

  useEffect(() => {
    if (scrollElem.current !== 0) {
      scrollElem.current.scrollLeft = getDatePosition(now); // - 300; //current.getBoundingClientRect()
    }
    // setTimeout(() => {
    //   setCurrentShows(getDatePosition(now));
    // }, 60000); //1min
  }, [schedules]);

  // useEffect(() => {
  //   // setInterval(() => {
  //   //   const update = convertToUtc(new Date());
  //   //   const updateShows = 7200 - getDatePosition(update);
  //   //   position <= -7200
  //   //     ? setPosition(7200)
  //   //     : currentShows !== updateShows
  //   //     ? setPosition(updateShows)
  //   //     : setPosition(currentShows);
  //   // }, 50000); //1000ms = 1 sec? ????????
  // }, [position]);

  return (
    <div>
      <div className={classes.landingpage + ' landingpage'} ref={scrollElem} onMouseDown={moveDrug} id={'moveCont'}>
        <div className={classes.alignment + ' landingpage_body'} style={{ left: '2400px' }}>
          {/* <div className='alignment' style={{ left: `${position}px` }}> */}
          <Timeline position={position} />
          <ChannelList schedules={schedules} clickProgramDialog={clickProgramDialog} />
          <ProgramDialog programId={programId} providerId={providerId} programDialogOpen={programDialogOpen} closeProgramDialog={closeProgramDialog} />
        </div>
      </div>
    </div>
  );
};

LandingPage.propTypes = {
  schedules: PropTypes.array.isRequired,
};

export default LandingPage;
