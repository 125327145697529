import { CHART_COLORS } from '../../common/ChartConsts';
import { ROWHEADER_TYPE, TABLE_TYPE } from '../../consts/IssConsts';

export const issue_history_total_data = {
  daily_total_error_occurrence: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 300,
          ticks: {
            stepSize: 10,
            callback: function (label) {
              return `${this.getLabelForValue(label)}  `;
            },
          },
        },
      },
    },
  },
  daily_total_error_resolution: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (value) => `${value}%`,
          },
        },
      },
    },
  },

  daily_total_error_cumulative_resolution: {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      scales: {
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (value) => `${value}%`,
          },
        },
      },
    },
  },
  data_service: {
    title: 'Service',
    datasets: [
      {
        label: ['LG Channels HomeApp', 'Image', 'Service Data', 'Schedule', 'Playback'],
        data: [], //app, image, meta, schedule, play
        backgroundColor: [CHART_COLORS.PURPLE, CHART_COLORS.PINK, CHART_COLORS.MINT, CHART_COLORS.BLUE, CHART_COLORS.ORANGE],
        borderWidth: 0,
        borderRadius: 2,
        cutout: '60%',
      },
    ],
    options: {
      plugins: {
        datalabels: {
          display: true,
          color: 'white',
          formatter: function (value, context) {
            // data 에 넣은 데이타 순번. 물론 0 부터 시작
            var idx = context.dataIndex;
            // 여기선 첫번째 데이타엔 단위를 '원' 으로, 그 다음 데이타엔 'P' 를 사용
            // addComma() 는 여기서 기술하지 않았지만, 천단위 세팅. ChartJS 의 data 엔 숫자만 입력
            return value + '%';
          },
        },
      },
    },
  },
  data_contents: {
    title: 'Content',
    datasets: [
      {
        label: ['CP', 'CMP Organization', 'Data Deployment'],
        data: [], //Feed, Admin, Batch
        backgroundColor: [CHART_COLORS.PINK, CHART_COLORS.MINT, CHART_COLORS.BLUE, CHART_COLORS.ORANGE],
        borderWidth: 0,
        borderRadius: 2,
        cutout: '60%',
      },
    ],
    options: {
      plugins: {
        datalabels: {
          display: true,
          color: 'white',
          formatter: function (value, context) {
            // data 에 넣은 데이타 순번. 물론 0 부터 시작
            var idx = context.dataIndex;
            // 여기선 첫번째 데이타엔 단위를 '원' 으로, 그 다음 데이타엔 'P' 를 사용
            // addComma() 는 여기서 기술하지 않았지만, 천단위 세팅. ChartJS 의 data 엔 숫자만 입력
            return value + '%';
          },
        },
      },
    },
  },
  data_statistics: {
    title: 'Statistics',
    datasets: [
      {
        label: ['Statistics', 'Beacon Data'],
        data: [], // Statistics, Beacon Data
        backgroundColor: [CHART_COLORS.PURPLE, CHART_COLORS.MINT],
        borderWidth: 0,
        borderRadius: 2,
        cutout: '60%',
      },
    ],
    options: {
      plugins: {
        datalabels: {
          display: true,
          color: 'white',
          formatter: function (value, context) {
            // data 에 넣은 데이타 순번. 물론 0 부터 시작
            var idx = context.dataIndex;
            // 여기선 첫번째 데이타엔 단위를 '원' 으로, 그 다음 데이타엔 'P' 를 사용
            // addComma() 는 여기서 기술하지 않았지만, 천단위 세팅. ChartJS 의 data 엔 숫자만 입력
            return value + '%';
          },
        },
      },
    },
  },

  issue_history_total_table: {
    tableType: TABLE_TYPE.NORMAL,
    title: 'Issue History Total',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Statistics Issues tooltip message..!!',
    headerTop: [
      { rowspan: 2, value: 'Date' },
      { colspan: 3, value: '당일발생오류' },
      { colspan: 3, value: '누적발생오류' },
      { colspan: 3, value: '당일 발생 오류 해결건' },
      { colspan: 3, value: '누적 발생 오류 해결건' },
    ],
    header: ['Service', 'Content', 'Statistics', 'Service', 'Content', 'Statistics', 'Service', 'Content', 'Statistics', 'Service', 'Content', 'Statistics'],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL,
    ],
    cellWidth: ['5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%'],
    rowdata: [],
  },
};
