import React, { useEffect, useState } from 'react';
import IssueHistoryTab from './IssueHistoryTab';
import TutorialPage from '../TutorialPage';

function SideBarIssueHistory({ currentTab, changeTab, currentFilter, changeFilter }) {
  const [isActive, setIsActive] = useState(false);

  const tabMenus = [{ name: 'Total' }, { name: 'Service' }, { name: 'Content' }, { name: 'Statistics' }];

  useEffect(() => {
    changeFilter({subType:'All', date:'', country:'', provider:'', platform:''});
  }, [currentTab]);

  const handleOpen = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <>
      <div className="sidebar-section">
        <div className="head-title tutorial">
          <button className="btn-tutorial-2" onClick={handleOpen} disabled={currentTab !== 0}/>
          <h1>Issue History</h1>
        </div>
        <div className="box-summary-tabs">
          <div className="ui-tab-type01">
            <div className="ui-tab-menu">
              {tabMenus.map((el, index) => (
                <a key={index} className={index === currentTab ? 'on' : ''} onClick={() => changeTab(index)}>
                  {el.name}
                </a>
              ))}
            </div>
            <div className="ui-tab-contents">
              <div className="tab-box">{<IssueHistoryTab currentTab={currentTab} currentFilter={currentFilter}/>}</div>
            </div>
          </div>
        </div>
      </div>
      {isActive && <TutorialPage data={'issueHistory'} isActive={isActive} onClose={handleClose} />}
    </>
  );
}

export default SideBarIssueHistory;
