
import { API_BASE_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';

export const getAppVersionAction = (osName, cntry) => dispatch => {
  if (osName === 'All') osName = '';
  if (cntry === 'All') cntry = '';

  const apiUrl = API_BASE_URL + `/admin/app-version?osName=${osName}&cntry=${cntry}`;

dispatch({
  type: 'SET_APPVERSION_FETCHING',
  payload: true,
});
return fetch(apiUrl, {
  method: 'GET',
  headers: getHeaders(),
})
  .then(res => res.json())
  .then(body =>
    dispatch({
      type: 'SET_APPVERSION',
      payload: body,
    }),
  ) //include 400Error
  .catch(ex =>
    dispatch({
      type: 'SET_APPVERSION',
      payload: ex,
    }),
  );
};

export async function requestAction(requestPath, requestMethod, requestBody, isFormData) {
  let result = {returnValue: false, message: ''};

  const requestCommon = {
    method: requestMethod,
    headers: getHeaders(),
  };

  const requestObj = requestMethod === 'GET' || !requestBody ? requestCommon : { ...requestCommon, body: isFormData ? requestBody : JSON.stringify(requestBody) };

  await fetch(API_BASE_URL + requestPath, requestObj)
    .then(res => {
      // console.log('***res', res);
      return res.json();
    })
    .then(json => {
      console.log('[json]', json);
      result.returnValue = json.returnValue;
      result.message = json.message;
    })
    .catch(err => {
      //console.log('[err]', err);
      alert('Error message: ' + err);
    });

  return result;
}