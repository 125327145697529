//import * as actions from '../../actions/actionTypes';

const initialState = [];

export default function countries(state = initialState, action) {
  switch (action.type) {
    case 'SET_CNTRY':
      if (action.payload.status) {
        return state; //TODO
      } else if (action.payload) {
        const ricKeys = Object.keys(action.payload);
        let result = ricKeys.map(item => {
          //console.log('test', action.payload[item]);
          return { biz: item, area: action.payload[item] };
        });
        const allCntry2DArray = ricKeys.map(item => action.payload[item]);
        const allCntry1DArray = [].concat(...allCntry2DArray);
        const allInfo = { biz: 'ALL', area: allCntry1DArray };
        return result.concat(allInfo);
        // return action.payload
        //   .map((item) => {
        //     if (item.providers && item.providers.length > 1) item.providers.concat('All');
        //     return item;
        //   })
        //   .filter((item) => (item.providers && item.providers.length > 0) || !item.providers);
      } else {
        return state;
      }
    case 'SET_CNTRY_ERROR':
      return state;
    default:
      return state;
  }
}

//export const cntryResponse = ['US'];

// const cntryRawResponse2 = cntryRawResponse.map((item) => {
//   if (item.providers.length > 1) {
//     item.providers.concat('All');
//   }
//   return item;
// });

//export const cntryCpResponse = cntryRawResponse2.filter((item) => item.providers.length !== 0);

// {
//   "timestamp": "2020-04-17T02:15:35.572+0000",
//   "status": 401,
//   "error": "Unauthorized",
//   "message": "Full authentication is required to access this resource",
//   "path": "/api/admin/countries"
// }
// {
//   "timestamp": "2020-04-17T02:17:20.831+0000",
//   "status": 404,
//   "error": "Not Found",
//   "message": "No message available",
//   "path": "/api/admin/--countries"
// }

//http://qt2-kic.channel.lgtvcommon.com/api/admin/countries
// {
//   "KIC": [],
//   "AIC": [
//       "US"
//   ],
//   "EIC": []
// }
