import { REHYDRATE } from './actionTypes';
import { getCurrentUserAction } from './userActions';
import { setProgramsParamAction } from './programActions';
import { getCntryAction, getPlatformsAction, getRegionCntryAction } from './cntryActions';
import { getRegionAction, getRegionPlatformAction } from './homeAppActions';
import { ACCESS_TOKEN } from '../constants';

export const rehydrateAction = isRoleAdmin => dispatch => {
  const payload = {};

  if (typeof window === 'object') {
    try {
      const accessToken = localStorage.getItem(ACCESS_TOKEN);

      payload.accessToken = accessToken;
      dispatch(getCurrentUserAction());
      dispatch(setProgramsParamAction());
      dispatch(getCntryAction());
      dispatch(getPlatformsAction());
      dispatch(getRegionAction());
      dispatch(getRegionCntryAction());
      dispatch(getRegionPlatformAction());
    } catch (err) {
      // do nothing
    }
  } else {
    // do nothing
  }

  return {
    type: REHYDRATE,
    payload,
  };
};
