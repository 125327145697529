
import React from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 866px;
    --y: 715px;
    --width: 283px;
    --height: 138px;

    top: 0;
    left: 0;
    width: 1920px;
    height: 1100px;
  }

  #clip-01-hole {
    clip-path: unset;
  }

  #text-01 {
    top: 604px;
    left: 782px;
  }

  #line-01 {
    top: 689px;
    left: 794px;
  }

  #image-01 {
    top: 715px;
    left: 866px;
  }
`;

const ServiceDashboard06 = () => {
  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div className='image-box'>
            <div id='image-01' className='image'>
              <img src="/common/images/iss/tutorial/t_1930_image_01.png" alt='' />
            </div>
          </div>
        </div>

        <div className="text-box">
          <div id="text-01" className="text">When you mouse over the <img src="/common/images/iss/tutorial/ic_question_mark.png" alt='Question mark' /> icon,<br/>a brief description is displayed</div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_1930_line_01.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default ServiceDashboard06;