import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';
import { getCountAction } from '../../../actions/homeAppActions';
// import { withRouter } from 'react-router-dom';

function CheckDialog({ dialogOpen, closeDialog, type, cntryCode, getCountAction, countKeyword, countGenre }) {
  useEffect(() => {
    if (type === 'genre') {
      if (!countGenre.length) getCountAction(type, cntryCode);
    } else if (type === 'keyword') {
      if (!countKeyword.length) getCountAction(type, cntryCode);
    }
  }, [type, cntryCode]);

  return (
    <div>
      <Dialog
        open={dialogOpen}
        //onClose={closeDialog} //이게 있으면 open된 상태에서 외부공간 클릭하면 닫힘
        //aria-labelledby="form-dialog-title"
        className={'pop_wrap'}>
        <div className="MuiDialogTitle-root pop_head" id="form-dialog-title">
          <h2 className="MuiTypography-root MuiTypography-h6">
            Contents Count
            <IconButton
              aria-label="close" //없으면 X버튼 위치앞으로감
              className={'noTit_close'}
              onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </h2>
        </div>
        <div className={'pop_body'}>
          <CheckCountTable type={type} result={type === 'genre' ? countGenre : countKeyword} />
          <div className="btn_set_wrap">
            <Button color="secondary" variant="contained" className={'btn_color2'} onClick={closeDialog}>
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

CheckDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  countGenre: state.homeApp.countGenre,
  countKeyword: state.homeApp.countKeyword,
});
const mapDispatchToProps = dispatch => ({
  getCountAction(type, cntry) {
    dispatch(getCountAction(type, cntry));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CheckDialog);

function CheckCountTable({ type, result }) {
  const col =
    type === 'keyword'
      ? [
        {
          title: 'keyword',
          field: 'keyword',
        },
      ]
      : [
        { title: 'genre_code', field: 'genre_code' },
        { title: 'genre_name', field: 'genre_name' },
      ];

  return (
    <MaterialTable
      title=" " //없으면 Table Title이 default title로 나옴
      columns={[
        {
          title: 'type',
          field: 'type',
        },
        {
          title: 'country',
          field: 'cntry',
        },
        {
          title: 'cp',
          field: 'cp',
        },
        ...col,
        {
          title: 'count',
          field: 'count',
        },
      ]}
      data={result}
      options={{
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
      }}
    />
  );
}
