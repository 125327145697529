import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Switch from '@material-ui/core/Switch';
import { getMembersAction } from '../../../actions/userActions';
//import { request } from '../../../util/request';

function SwitchAccess({ getMembersAction, item }) {
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    // const newItem = {
    //   //providerId: filter.providerId.value, //TODO 왜 안바뀜???
    //   useFlag: item.Access ? 'N' : 'Y',
    // };
    // alert('Are you sure to change access flag?');
    alert('blocked');
    // //console.log('[newItem]', newItem);
    // const isSuccess = await request('/api/admin/users/' + item['ID'], 'PATCH', newItem);
    // //console.log('[isSuccess]', isSuccess); //await 없으면 Promise {<pending>}//request내부await 없으면 무조건 기본값리턴
    // if (isSuccess) {
    //   setSuccessSnackbarOpen(true);
    //   getMembersAction();
    // }
  };

  return (
    <React.Fragment>
      <Switch checked={item.Access} onChange={handleSubmit} inputProps={{ 'aria-label': 'secondary checkbox' }} />
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={1000} //1000=1sec
        onClose={handleSuccessSnackbarClose} //없으면 없어지지않음
        message="Successfully Doned !"
        style={{ backgroundColor: 'black' }}
      />
    </React.Fragment>
  );
}
SwitchAccess.propTypes = {
  getMembersAction: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};
const mapDispatchToProps = dispatch => ({
  getMembersAction() {
    dispatch(getMembersAction());
  },
});
export default connect(null, mapDispatchToProps)(SwitchAccess);

//qt2-kic.channel.lgtvcommon.com/api/admin/users/testuser {useFlag:true}
// http: status: 500;
// errorCode: 'CDP_SVC_4003';
// message: 'internal server error';
