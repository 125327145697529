import { ROWHEADER_TYPE } from '../../../consts/IssConsts';

export const beacon_basic_trend_data = {
  title: 'Beacon Document Count',
  header: ['Date', 'channel', 'program', 'homeapp', 'system'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['20%', '20%', '20%', '20%', '20%'],
  rowdata: [],
}

export const beacon_hourly_trend_data = {
  title: 'Beacon Hourly Trend',
  header: ['Date', 'channel', 'program', 'homeapp', 'system'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['20%', '20%', '20%', '20%', '20%'],
  rowdata: [],
}

export const unique_device_line_trend_data = {
  title: 'Statistics Unique Device Trend',
  header: ['Date', 'Unique Device'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['40%', '60%'],
  rowdata: [],
};

export const unique_device_country_line_trend_data = {
  title: 'Statistics Unique Device Trend',
  header: ['Date', 'Country', 'Unique Device'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['20%', '40%', '40%'],
  rowdata: [],
};

export const unique_device_platform_line_trend_data = {
  title: 'Statistics Unique Device Trend',
  header: ['Date', 'Platform', 'Unique Device'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['20%', '40%', '40%'],
  rowdata: [],
};

export const duration_access_line_trend_data = {
  title: 'Duration Access Trend',
  header: ['Date', 'API', 'Value'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['25%', '37%', '38%'],
  rowdata: [],
};

export const duration_access_country_line_trend_data = {
  title: 'Duration Access Trend',
  header: ['Date', 'API',  'Country', 'Value'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['25%', '25%', '25%', '25%'],
  rowdata: [],
};

export const duration_access_platform_line_trend_data = {
  title: 'Duration Access Trend',
  header: ['Date', 'API', 'Platform', 'Value'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['25%', '25%', '25%', '25%'],
  rowdata: [],
};

export const duration_access_cp_line_trend_data = {
  title: 'Duration Access Trend',
  header: ['Date', 'API', 'CP', 'Value'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['25%', '25%', '25%', '25%'],
  rowdata: [],
};

export const duration_access_bar_trend_data = {
  title: 'Duration Access Top Trend',
  header: ['Order', 'Content Name', 'Value'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['20%', '60%', '20%'],
  rowdata: [],
};

export const duration_access_country_bar_trend_data = {
  title: 'Duration Access Top Trend',
  header: ['Country', 'Order', 'Content Name', 'Value'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['10%', '10%', '60%', '20%'],
  rowdata: [],
};

export const duration_access_platform_bar_trend_data = {
  title: 'Duration Access Top Trend',
  header: ['Platform', 'Order', 'Content Name', 'Value'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['10%', '10%', '60%', '20%'],
  rowdata: [],
};

export const duration_access_cp_bar_trend_data = {
  title: 'Duration Access Top Trend',
  header: ['CP', 'Order', 'Content Name', 'Value'],
  cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
  cellWidth: ['10%', '10%', '60%', '20%'],
  rowdata: [],
};