import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
//import MaterialTable from 'material-table';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
//import { getProvidersAdminAction } from '../../../actions/providerActions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
// 수정화면
const styles = theme => ({
  // layout: {
  //   width: 'auto',
  //   marginLeft: theme.spacing(3),
  //   marginRight: theme.spacing(3),
  //   [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
  //     // width: 1100,
  //     // marginLeft: 'auto',
  //     // marginRight: 'auto',
  //   },
  // },
});

export function NotificationCreate({ history }) {
  return (
    <div>
      <TableContainer className={'tbl_wrap'}>
        <Table className={'tbl_row_typ1 tbl_notification none_bar'} aria-label="spanning table">
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                <label className="is_required">Title</label>
              </TableCell>
              <td>
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  // label="Title" //focus안줬을때 mandatory text
                  //value={id}
                />
              </td>
            </TableRow>
            <TableRow>
              <TableCell>
                <label htmlFor="Contents" className="is_required">
                  Contents
                </label>
              </TableCell>
              <td colSpan="3">
                <TextareaAutosize id="Contents" variant="outlined" aria-label="minimum height" color="currentColor" rowsMin={10} className="tbl_textarea" />
                <p className="check_bite">
                  <span>134</span>/2000
                </p>
              </td>
            </TableRow>
            <TableRow>
              <TableCell>Business Area</TableCell>
              <td className="setting_area">
                <div className="radio_area">
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="BusinessArea" name="BusinessArea">
                      <FormControlLabel value="ALL" control={<Radio />} label="ALL" />
                      <FormControlLabel value="NA" control={<Radio />} label="NA" />
                      <FormControlLabel value="SCA" control={<Radio />} label="SCA" />
                      <FormControlLabel value="EU" control={<Radio />} label="EU" />
                      <FormControlLabel value="KR" control={<Radio />} label="KR" />
                      <FormControlLabel value="CIS" control={<Radio />} label="CIS" />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="switch_area">
                  <FormGroup row>
                    <FormControlLabel control={<Switch name="Display" />} label="Display" />
                  </FormGroup>
                </div>
              </td>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <div className="btn_set_wrap">
        <Button
          variant="contained"
          className="btn_color1"
          onClick={() => {
            history.push('notification');
          }}>
          CANCEL
        </Button>
        <Button variant="contained" className="btn_color2">
          SAVE
        </Button>
      </div>
    </div>
  );
}

NotificationCreate.propTypes = {};

const mapStateToProps = state => ({});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(NotificationCreate)));
