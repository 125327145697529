import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField, Checkbox } from '@material-ui/core';

function AddSegmentFilter({ countryCode,
  validToUpdate,
  segment,
  segmentId,
  handleChangeSegment,
  segmentType,
  handleChangeSegmentType,
  tabCode,
  categoryCode,
  deviceType,
  platformVersion,
  title,
  startDate,
  startTimeAnt,
  endDate,
  endTimeAnt,
  defaultContent,
  onChangeDefaultGroup,
  sampleRate,
  handleSampleRate,
  contentPolicy,
  handleContentPolicy,
  mappingType,
  handleChangeMappingType,
  comparativeSegmentType,
  handleChangeComparativeSegmentType,
  comparativeSegmentId,
  handleChangeComparativeSegmentId
}) {
  const today = new Date();
  const yestreday = new Date();
  yestreday.setDate(today.getDate() - 1);
  let segmentBTypes = new Map();
  segment.filter(seg => seg.segmentGroup === 'B').map(segtype => { segmentBTypes.set(segtype.segmentType, segtype.segmentName) });
  segmentBTypes = new Map([...segmentBTypes.entries()].sort());

  let comparativeSegmentTypes = new Map();
  segment.filter(seg => seg.segmentGroup === 'C').map(segtype => { comparativeSegmentTypes.set(segtype.segmentType, segtype.segmentName) }).sort();
  comparativeSegmentTypes = new Map([...comparativeSegmentTypes.entries()].sort());

  const mappingTypes = [
    { value: 'auto', label: 'auto' },
    { value: 'manual', label: 'manual' },
  ];

  function getDescription() {
    return segment.filter((item) => item.segmentId === segmentId).map(item => item.description);
  }
  return (
    <Fragment>
      <div className="filter_area row">
        <div className="filter_row">
          {<TextField id="abTestTitle" name="abTestTitle" label="Abtest Title" placeholder="Abtest Title" variant="outlined" value={title} disabled={true} />}

          {<TextField margin="dense" variant="outlined" label="Start Date" value={startDate} required disabled={true} />}

          {<TextField margin="dense" variant="outlined" label="Start Time" value={startTimeAnt} required disabled={true} />}

          {<TextField margin="dense" variant="outlined" label="End Date" value={endDate} required disabled={true} />}

          {<TextField margin="dense" variant="outlined" label="End Time" value={endTimeAnt} required disabled={true} />}

          {<TextField margin="dense" variant="outlined" label="Device Type" value={deviceType} required disabled={true} />}

          {<TextField margin="dense" variant="outlined" label="Country" value={countryCode} required disabled={true} />}
        </div>
        <div className="filter_row">
          {<TextField margin="dense" variant="outlined" label="platform version" value={platformVersion} required disabled={true} />}

          {<TextField margin="dense" variant="outlined" label="Tab" value={tabCode} required disabled={true} />}

          {<TextField margin="dense" variant="outlined" label="Category" value={categoryCode} required disabled={true} />}

          {
            <FormControl variant="outlined" className="filter_area">
              <InputLabel>Segment Type</InputLabel>
              <Select variant="outlined" id="segmentType" name="segmentType" label="segmentType" value={segmentType} onChange={handleChangeSegmentType} required disabled={validToUpdate}>
                {[...segmentBTypes].map((values, keys) => (
                  <MenuItem key={values[0]} value={values[0]}>
                    {values[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          {
            <FormControl required variant="outlined">
              <InputLabel>Segment</InputLabel>
              <Select name="segmentId" id="segmentId" value={segmentId} onChange={handleChangeSegment} disabled={validToUpdate}>
                {segment
                  .filter(seg => seg.segmentType === segmentType && seg.segmentGroup === 'B')
                  .map(n => (
                    <MenuItem key={n.segmentId} value={n.segmentId} title={n.description}>
                      {n.segmentId}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          }
          {
            <FormControl required variant="outlined">
              <InputLabel>Mapping Type</InputLabel>
              <Select name="mappingType" id="mappingTypeId" value={mappingType} onChange={handleChangeMappingType} disabled={validToUpdate}>
                {mappingTypes.map(n => (
                  <MenuItem key={n.value} value={n.value}>
                    {n.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          <TextField variant="outlined" className="adtest_add" id="sampleRate" name="sampleRate" placeholder="Sample Rate" label="Sample Rate" required value={sampleRate} onChange={handleSampleRate} disabled={validToUpdate} />
        </div>
        <div className="filter_row">
          <TextField
            id="SegmentDesc"
            name="SegmentDesc"
            label="Segment Description"
            placeholder="Segment Description"
            variant="outlined"
            value={getDescription()}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="contentPolicy"
            name="contentPolicy"
            label="Content Policy"
            placeholder="Content Policy"
            variant="outlined"
            value={contentPolicy}
            onChange={handleContentPolicy}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ maxLength: '255' }}
          />
        </div>
        <div className="filter_row">
          <div style={{ width: 'fit-content' }}>
            <label style={{ marginLeft: '30px', marginTop: '32px', display: 'inline-block', height: '50px' }}>
              CGroup
              <Checkbox id="defaultGroup" name="defaultGroup" checked={defaultContent} onChange={onChangeDefaultGroup} disabled={validToUpdate} />
            </label>
            {defaultContent && (
              <FormControl variant="outlined" required>
                <InputLabel id="cGroupSegmentType">C Group Segment Type </InputLabel>
                <Select style={{ width: 285 }} label="cGroupSegmentType" id="cGroupSegmentType" value={comparativeSegmentType} onChange={handleChangeComparativeSegmentType} disabled={validToUpdate}>
                  {[...comparativeSegmentTypes].map((values, keys) => (
                    <MenuItem key={values[0]} value={values[0]}>
                      {values[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {defaultContent && (
              <FormControl variant="outlined" required>
                <InputLabel id="cGroupSegment">C Group Segment</InputLabel>
                <Select style={{ width: 285 }} label="cGroupSegment" id="cGroupSegment" value={comparativeSegmentId ? comparativeSegmentId : ''} onChange={handleChangeComparativeSegmentId} disabled={validToUpdate}>
                  {segment.filter(seg => seg.segmentType === comparativeSegmentType && seg.segmentGroup === 'C').map(segC => (
                    <MenuItem key={segC} value={segC.segmentId} title={segC.description}>
                      {segC.segmentId}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
AddSegmentFilter.propTypes = {
  abTestTabCategory: PropTypes.array.isRequired,
  searchData: PropTypes.object.isRequired,
  handleSearchDataChange: PropTypes.func.isRequired,
  getChannelMapList: PropTypes.func.isRequired,
  channelMapList: PropTypes.array.isRequired,
  requiredField: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  segment: state.homeApp.segment,
  channelMapList: state.homeApp.notificationsChannelMapList,
});

const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AddSegmentFilter);
