import * as actions from '../../actions/actionTypes';

const initialState = { data: [] };

export default function data(state = initialState, { type, payload }) {
  switch (type) {
     case actions.SET_STATISTIC:
      if (payload.status) return { status: payload.status, message: payload.message, data: [] };
      else if (payload) {
          let _keys = Object.keys(payload).sort();

          let _tempStore = [];
          for (let _month of  _keys) {
            let _subKeys = Object.keys(payload[_month]);
            for (let _item of  _subKeys) {
              let _found = false;
              let _index = 0;
              for(let _oldItem of _tempStore) {
                if (_oldItem.item === _item) {
                  _found = true;
                  break;
                }
                _index++;
              }

              if (_found) {
                _tempStore[_index][_month] = Math.round(payload[_month][_item] * 100) / 100;
              } else {
                _tempStore.push({item: _item, [_month]: Math.round(payload[_month][_item] * 100) / 100});
              }
            }
          }
          return { data: _tempStore };
      } else {
        return state;
      }
    case actions.SET_STATISTIC_ERROR:
      return { data: [] };
    case actions.SET_STATISTIC_INIT:
      return { data: [] };
    default:
      return state;
  }
}
