////////////////////////////////////////////////////////////////////////////////
// import
////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect, useCallback, useRef } from 'react'; // React
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; // connect
import { withRouter } from 'react-router-dom'; // withRouter
////////////////////////////////////////////////////////////////////////////////
// import material
////////////////////////////////////////////////////////////////////////////////
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MaterialTable from 'material-table';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
////////////////////////////////////////////////////////////////////////////////
// import popup
////////////////////////////////////////////////////////////////////////////////
import PopupCopyToPlatform from './popupCopyToPlatform';
////////////////////////////////////////////////////////////////////////////////
// import action
////////////////////////////////////////////////////////////////////////////////
import { getBizAreaAction } from '../../../actions/codeActions'; // Business Area SelectBox
import { getCountries223Action } from '../../../actions/codeActions'; // Country SelectBox
import { getPlatforms22Action } from '../../../actions/codeActions'; // Platform SelectBox
import { getCountries22Action } from '../../../actions/codeActions'; // Country SelectBox
import { getFeedmapsetLogsNamesAction } from '../../../actions/displayRulesActions'; // Feed Mapp Setting History Combo 조회
import { getFeedmapsetLogsAction } from '../../../actions/displayRulesActions'; // Feed Mapp Setting History 조회
import { getFeedmapsetAction } from '../../../actions/displayRulesActions'; // Feed Mapp Setting 저장
import { getFeedmapSdkAction } from '../../../actions/displayRulesActions'; // Feed Mapp Sdk 저장/수정
import { getFeedmapSdksOneAction } from '../../../actions/displayRulesActions'; // Feed Mapp Sdk 목록 조회 (하단 sdk version 리스트 )
import { getFeedmapSdkIdxAction } from '../../../actions/displayRulesActions';
import { getFeedmapFeedMapIdChannelsAction } from '../../../actions/displayRulesActions'; // Feed Mapp Sdk 목록 조회 (하단 sdk version 리스트 )
import { getFirstRulesStoreAction } from '../../../actions/displayRulesActions'; // 첫번째 rules store
import { getRulesStoreAction } from '../../../actions/displayRulesActions'; // 주사용 rules store
import { getSdkVersionByFeedIdAction } from '../../../actions/displayRulesActions'; // Feed SDK version
import TextField from '@material-ui/core/TextField';
////////////////////////////////////////////////////////////////////////////////
// import NoData
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// styles
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// DisplayRules
////////////////////////////////////////////////////////////////////////////////
function DisplayRules({
  isRoleAdmin,
  getFirstRulesStoreAction,
  getRulesStoreAction,
  getBizAreaAction,
  getCountries223Action,
  getPlatforms22Action,
  getCountries22Action,
  getFeedmapsetAction,
  getFeedmapSdkAction,
  getFeedmapSdksOneAction,
  getFeedmapSdkIdxAction,
  getSdkVersionByFeedIdAction,
  getFeedmapFeedMapIdChannelsAction,
  cmSdepth0001,
  cmSdepth0002,
  cmSdepth0003,
  cmSdepth0004,
  sd0101,
  sd0103,
  sd0104,
  sd0105,
  sd0106,
  sd0107,
  sd0108,
  rulesStore,
}) {
  ////////////////////////////////////////////////////////////////////////////
  // Render Count
  ////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  // useEffect
  ////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  // 첫번째 실행
  ////////////////////////////////////////////////////////////////////////////
  const [sdkVerCall, setSdkVerCall] = useState(true);
  useEffect(() => {
    handleCmSdepth0001('FIRST');
  }, []);
  //
  useEffect(() => {
    handleCmSdepth0003();
  }, []);
  //
  useEffect(() => {
    handleCmSdepth0004();
  }, []);
  ////////////////////////////////////////////////////////////////////////////
  // 실행후 저장
  ////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setUsCmSdepth0001(cmSdepth0001); //  cmSdepth0001
    if (cmSdepth0001) {
      if (cmSdepth0001.length >= 1) {
        setUsSelectedBusinessArea(cmSdepth0001[0].selectKey);
      }
    }
  }, [cmSdepth0001]);
  useEffect(() => {
    setUsCmSdepth0002(cmSdepth0002);
    setUsCmSdepth0004([]);
    if (cmSdepth0002) {
      if (cmSdepth0002.length >= 1) {
        sortCountry(cmSdepth0002) > -1 ? setUsSelectedCountry('US') : setUsSelectedCountry(cmSdepth0002[0].selectKey);
      }
    }
  }, [cmSdepth0002]);
  //
  useEffect(() => {
    setUsCmSdepth0003(cmSdepth0003); //  cmSdepth0003
    if (cmSdepth0003) {
      if (cmSdepth0003.length >= 1) {
        // 현재 코드는 webOSTV 4.0이지만 검색 또는 작업용으로 할 때는
        // webOS4.0으로 해야 한다.
        let tempSelectKey = cmSdepth0003[0].selectKey;
        // tempSelectKey = tempSelectKey.replace(/TV/gi, "");
        // tempSelectKey = tempSelectKey.replace(/ /gi, "");
        //
        setUsSelectedPlatform(tempSelectKey);
      }
    }
  }, [cmSdepth0003]);
  //
  useEffect(() => {
    setUsCmSdepth0004(cmSdepth0004); //  cmSdepth0004
    setUsCmSdepth0002([]); //  cmSdepth0004
    if (cmSdepth0004) {
      if (cmSdepth0004.length >= 1) {
        sortCountry(cmSdepth0004) > -1 ? setUsSelectedCountry('US') : setUsSelectedCountry(cmSdepth0004[0].selectKey);
      }
    }
  }, [cmSdepth0004]);
  //
  useEffect(() => {
    setUsRulesStore(rulesStore); // Rules
  }, [rulesStore]);
  //
  useEffect(() => {
    setUsPrivewStore(sd0108); // Privew
  }, [sd0108]);
  //
  useEffect(() => {
    getSdkVersionByFeedIdAction();
  }, []);

  useEffect(() => {
    let feedMappSdk = [];
    let feedMappSet = [];
    let categoriesForSdk = [];
    let workFeedMappSdk = [];
    setUsWorkFeedMappSdk(workFeedMappSdk);
    if (sd0107) {
      if (sd0107.feedMappSdk) {
        if (sd0107.feedMappSdk.length >= 1) {
          feedMappSdk = sd0107.feedMappSdk;
          feedMappSet = sd0107.feedMappSet;
          categoriesForSdk = sd0107.categoriesForSdk;
          workFeedMappSdk = combineFeedMappSdkSet(feedMappSdk, feedMappSet, categoriesForSdk);

          setUsFeedMappSet(JSON.parse(JSON.stringify(sd0107.feedMappSet)));
          setUsCategoriesForSdk(JSON.parse(JSON.stringify(sd0107.categoriesForSdk)));
          setUsWorkFeedMappSdk(workFeedMappSdk);
        } else {
          if (sd0107.categoriesForSdk && sd0107.categoriesForSdk.length >= 1) {
            categoriesForSdk = sd0107.categoriesForSdk;
            setUsCategoriesForSdk(JSON.parse(JSON.stringify(sd0107.categoriesForSdk)));
          } else {
            setUsCategoriesForSdk(categoriesForSdk);
          }
        }
      }
    }
  }, [sd0107]);

  useEffect(() => {
    setUsDisplayHistoryList(sd0103); // Display history List
  }, [sd0103]);
  //
  useEffect(() => {
    setUsDisplayHistoryDetail(sd0104); // Display history Detail
    setUsDisplayHistoryDetailUpdated('');
    if (sd0104) {
      if (sd0104.length >= 1) {
        setUsDisplayHistoryDetailUpdated(sd0104[0].crtDate.replace(/T/gi, ' '));
      }
    }
  }, [sd0104]);
  ////////////////////////////////////////////////////////////////////////////
  // rules , priview , current date
  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    let country = '';
    let platform = '';
    //
    if (cmSdepth0003) {
      if (cmSdepth0003.length >= 1) {
        platform = cmSdepth0003[0].selectKey;
      }
    }
    // 전체 국가
    if (usSelectedBusinessArea === 'ALL') {
      if (cmSdepth0004) {
        if (cmSdepth0004.length >= 1) {
          country = sortCountry(cmSdepth0004) > -1 ? 'US' : cmSdepth0004[0].selectKey;
        }
      }
    } else if (usSelectedBusinessArea) {
      // 개별 국가
      if (cmSdepth0002) {
        if (cmSdepth0002.length >= 1) {
          country = sortCountry(cmSdepth0002) > -1 ? 'US' : cmSdepth0002[0].selectKey;
        }
      }
    }
    let feedMapId = country + ':' + platform;
    //
    //
    if (!country) {
      return;
    }
    if (!platform) {
      return;
    }
    //
    // 첫조회
    // rules
    if (sdkVerCall) {
      getRulesStoreAction(isRoleAdmin, country, feedMapId);
      // current date
      getFeedmapSdksOneAction(isRoleAdmin, feedMapId);
      setSdkVerCall(false);
    }

    //getFeedmapSdksCategoryOneAction(isRoleAdmin, feedMapId);
    // //
  }, [cmSdepth0001, cmSdepth0002, cmSdepth0004]);


  ////////////////////////////////////////////////////////////////////////////
  // useState
  ////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  // code
  ////////////////////////////////////////////////////////////////////////////
  const [usCmSdepth0001, setUsCmSdepth0001] = useState();
  const [usCmSdepth0002, setUsCmSdepth0002] = useState();
  const [usCmSdepth0003, setUsCmSdepth0003] = useState();
  const [usCmSdepth0004, setUsCmSdepth0004] = useState();
  ////////////////////////////////////////////////////////////////////////////
  // Rues
  ////////////////////////////////////////////////////////////////////////////
  const [usRulesStore, setUsRulesStore] = useState();
  ////////////////////////////////////////////////////////////////////////////
  // priview
  ////////////////////////////////////////////////////////////////////////////
  const [usPrivewStore, setUsPrivewStore] = useState();
  ////////////////////////////////////////////////////////////////////////////
  // Current State
  ////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  // Selected
  ////////////////////////////////////////////////////////////////////////////
  const [usSelectedBusinessArea, setUsSelectedBusinessArea] = useState();
  const [usSelectedCountry, setUsSelectedCountry] = useState();
  const [usSelectedPlatform, setUsSelectedPlatform] = useState();

  const [usInitialSdkVer, setUsInitialSdkVer] = useState();
  ////////////////////////////////////////////////////////////////////////////
  // popup
  ////////////////////////////////////////////////////////////////////////////
  const [usDisplayHistoryList, setUsDisplayHistoryList] = useState();
  const [usDisplayHistoryDetail, setUsDisplayHistoryDetail] = useState();
  const [usDisplayHistoryDetailUpdated, setUsDisplayHistoryDetailUpdated] = useState();
  ////////////////////////////////////////////////////////////////////////////
  // popup
  ////////////////////////////////////////////////////////////////////////////
  const [openCloseCopyToPlatform, setOpenCloseCopyToPlatform] = useState(false);

  ////////////////////////////////////////////////////////////////////////////
  // diabled
  ////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  //
  ////////////////////////////////////////////////////////////////////////////
  const [alert, setAlert] = useState({ open: false, message: '', call: null });
  ////////////////////////////////////////////////////////////////////////////
  // useCallback
  ////////////////////////////////////////////////////////////////////////////
  //
  //
  const closeAlert = () => {
    setAlert({
      open: false,
      message: '',
    });
  };
  const useCallbackCmSdepth0001 = useCallback(isRoleAdmin => {
    getBizAreaAction(isRoleAdmin);
  }, []);
  const useCallbackCmSdepth0002 = useCallback((isRoleAdmin, bizArea) => {
    getCountries223Action(isRoleAdmin, bizArea);
  }, []);
  //
  const useCallbackCmSdepth0004 = useCallback(isRoleAdmin => {
    getCountries22Action(isRoleAdmin);
  }, []);
  //
  const useCallbackCmSdepth0003 = useCallback(isRoleAdmin => {
    getPlatforms22Action(isRoleAdmin);
  }, []);
  const useCallbackFirstRulesStore = useCallback((isRoleAdmin, cntryCode) => {
    getFirstRulesStoreAction(isRoleAdmin, cntryCode);
  }, []);
  const useCallbackRulesStore = useCallback((isRoleAdmin, cntryCode, feedMapId) => {
    getRulesStoreAction(isRoleAdmin, cntryCode, feedMapId);
  }, []);
  //
  const useCallbackSd0105 = useCallback((isRoleAdmin, jsonStringifyParams) => {
    getFeedmapsetAction(isRoleAdmin, jsonStringifyParams);
  }, []);
  //
  const useCallbackPriview = useCallback((isRoleAdmin, feedMapId, sdkVer) => {
    getFeedmapFeedMapIdChannelsAction(isRoleAdmin, feedMapId, sdkVer);
  }, []);
  //
  const useCallbackCurrentState = useCallback((isRoleAdmin, feedMapId) => {
    getFeedmapSdksOneAction(isRoleAdmin, feedMapId);
  }, []);

  /*  const useCallbackSDKCategoryCurrentState = useCallback((isRoleAdmin, feedMapId) => {
    getFeedmapSdksCategoryOneAction(isRoleAdmin, feedMapId);
  }, []); */
  //

  const sortCountry = listData => {
    let defaultKey = -1;
    for (let countryKey in listData) {
      if (listData[countryKey]['selectKey'] === 'US') {
        defaultKey = listData[countryKey]['key'];
      }
    }
    return defaultKey;
  }

  function combineFeedMappSdkSet(feedMappSdk, feedMappSet, categoriesForSdk) {
    let poolCpName = '';
    let categoriesName = '';
    let workFeedMappSdk = JSON.parse(JSON.stringify(feedMappSdk));
    let workFeedMappSet = JSON.parse(JSON.stringify(feedMappSet));
    //
    for (let idx01 in workFeedMappSdk) {
      //
      let tempSvcFeedIdx = workFeedMappSdk[idx01].svcFeedIdx.split(',');
      //
      for (let idx03 in tempSvcFeedIdx) {
        //
        for (let idx02 in workFeedMappSet) {
          //
          if (tempSvcFeedIdx[idx03] * 1 === workFeedMappSet[idx02].feedIdx * 1) {
            if (workFeedMappSdk[idx01].feedMapId === workFeedMappSet[idx02].feedMapId) {
              poolCpName = poolCpName + workFeedMappSet[idx02].cpName + ',';
            }
          }
        }
      }
      if (poolCpName.length >= 1) {
        workFeedMappSdk[idx01].cpNames = poolCpName.substr(0, poolCpName.length - 1);
      } else {
        workFeedMappSdk[idx01].cpNames = poolCpName;
      }
      poolCpName = '';

      if (categoriesName.length >= 1) {
        workFeedMappSdk[idx01].categoryNames = categoriesName.substr(0, categoriesName.length - 1);
      } else {
        workFeedMappSdk[idx01].categoryNames = categoriesName;
      }

      workFeedMappSdk[idx01].bSdkVer = workFeedMappSdk[idx01].sdkVer;
      if (categoriesForSdk && categoriesForSdk.length >= 1) {
        let workCategoriesForSdk = JSON.parse(JSON.stringify(categoriesForSdk));
        for (let idx03 in workCategoriesForSdk) {
          if (
            workCategoriesForSdk[idx03].region == workFeedMappSdk[idx01].region &&
            workCategoriesForSdk[idx03].platform_version == workFeedMappSdk[idx01].platformVersion &&
            workCategoriesForSdk[idx03].tab_code == workFeedMappSdk[idx01].tabCode &&
            workCategoriesForSdk[idx03].category_code == workFeedMappSdk[idx01].categoryCode
          ) {
            workFeedMappSdk[idx01].platformVersion = workCategoriesForSdk[idx03].platform_version;
            workFeedMappSdk[idx01].tabCode = workCategoriesForSdk[idx03].tab_code.concat(' ', workCategoriesForSdk[idx03].tab_name);
            workFeedMappSdk[idx01].tabName = workCategoriesForSdk[idx03].tab_name;
            workFeedMappSdk[idx01].catCode = workCategoriesForSdk[idx03].category_code.concat(' ', workCategoriesForSdk[idx03].category_name);
            workFeedMappSdk[idx01].catName = workCategoriesForSdk[idx03].category_name;
            workFeedMappSdk[idx01].region = workCategoriesForSdk[idx03].region;
            workFeedMappSdk[idx01].tabCodeSave = workCategoriesForSdk[idx03].tab_code;
            workFeedMappSdk[idx01].categoryCode = workCategoriesForSdk[idx03].category_code;

            workFeedMappSdk[idx01].bPlatformVersion = workCategoriesForSdk[idx03].platform_version;
            workFeedMappSdk[idx01].bTabCode = workCategoriesForSdk[idx03].tab_code.concat(' ', workCategoriesForSdk[idx03].tab_name);
            workFeedMappSdk[idx01].bTabName = workCategoriesForSdk[idx03].tab_name;
            workFeedMappSdk[idx01].bCatCode = workCategoriesForSdk[idx03].category_code.concat(' ', workCategoriesForSdk[idx03].category_name);
            workFeedMappSdk[idx01].bCatName = workCategoriesForSdk[idx03].category_name;
            workFeedMappSdk[idx01].bRegion = workCategoriesForSdk[idx03].region;
            workFeedMappSdk[idx01].bTabCodeSave = workCategoriesForSdk[idx03].tab_code;
            workFeedMappSdk[idx01].bCategoryCode = workCategoriesForSdk[idx03].category_code;
          }
        }
      }
    }
    for (let idx01 in workFeedMappSdk) {
      workFeedMappSdk[idx01]['type'] = 'LIST';
    }
    return workFeedMappSdk;
  }
  //
  useEffect(() => {
    if (sd0106.Result === 'FAILED') {
      window.alert(sd0106.MSG);
    }
  }, [sd0106]);

  const useCallbackSd0106Action = useCallback((isRoleAdmin, idx, feedMapId, sdkVer, tempSvcFeedIdx, region, platformVersion, tabCodeSave, categoryCode) => {
    getFeedmapSdkAction(isRoleAdmin, idx, feedMapId, sdkVer, tempSvcFeedIdx, region, platformVersion, tabCodeSave, categoryCode);
  }, []);

  const [usFeedMappSet, setUsFeedMappSet] = useState();
  const [usWorkFeedMappSdk, setUsWorkFeedMappSdk] = useState();
  const [usCategoriesForSdk, setUsCategoriesForSdk] = useState();
  const handleSdkVerChange = (event, selectedRec) => {
    let value = event.target.value;
    usWorkFeedMappSdk.forEach(v1 => {
      if (v1['idx'] === selectedRec['idx']) {
        v1['sdkVer'] = value;
        if (Array.from(platformVersionSet).filter(item => item == value).length >= 1) {
          v1['platformVersion'] = value;
        } else {
          var temp;
          platformVersionSet.add(value);
          const platformVersionSetSort = Array.from(platformVersionSet).sort(cmpVersions);
          for (var v of platformVersionSetSort) {
            if (v === value) {
              v1['platformVersion'] = temp;
              break;
            }
            temp = v;
          }
        }
        v1['tabCode'] = '';
        v1['tabName'] = '';
        v1['catCode'] = '';
        v1['catName'] = '';
      }
    });
    let copyUsWorkFeedMappSdk = JSON.parse(JSON.stringify(usWorkFeedMappSdk));
    setUsWorkFeedMappSdk(copyUsWorkFeedMappSdk);
  };

  function cmpVersions(a, b) {
    var i, diff;
    var regExStrip0 = /(\.0+)+$/;
    var segmentsA = a.replace(regExStrip0, '').split('.');
    var segmentsB = b.replace(regExStrip0, '').split('.');
    var l = Math.min(segmentsA.length, segmentsB.length);

    for (i = 0; i < l; i++) {
      diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
      if (diff) {
        return diff;
      }
    }
    return segmentsA.length - segmentsB.length;
  }

  const handleTabCodeChange = (event, selectedRec) => {
    let value = event.target.value;
    usWorkFeedMappSdk.forEach(v1 => {
      if (v1['idx'] === selectedRec['idx']) {
        if (value) {
          v1['tabCode'] = value;
          if (usCategoriesForSdk) {
            if (usCategoriesForSdk.length >= 1) {
              const catCodeSet = new Set();
              usCategoriesForSdk.forEach(v2 => {
                let tabCodeName = v2['tab_code'].concat(' ', v2['tab_name']);
                if (v2['platform_version'] === selectedRec['platformVersion'] && tabCodeName === value) {
                  let catCodeName = v2['category_code'].concat(' ', v2['category_name']);
                  catCodeSet.add(catCodeName);
                  v1['tabName'] = '';
                  v1['catCode'] = '';
                  v1['catName'] = '';
                }
              });
              v1['catCodeSet'] = Array.from(catCodeSet);
            }
          }
        }
      }
    });
    let copyUsWorkFeedMappSdk = JSON.parse(JSON.stringify(usWorkFeedMappSdk));
    setUsWorkFeedMappSdk(copyUsWorkFeedMappSdk);
  };

  const handleCatCodeChange = (event, selectedRec) => {
    let value = event.target.value;
    usWorkFeedMappSdk.forEach(v1 => {
      if (v1['idx'] === selectedRec['idx']) {
        if (value) {
          v1['catCode'] = value;
          if (usCategoriesForSdk) {
            if (usCategoriesForSdk.length >= 1) {
              usCategoriesForSdk.forEach(v2 => {
                if (v2['platform_version'] === selectedRec['platformVersion'] && v2['tab_code'].concat(' ', v2['tab_name']) === selectedRec['tabCode'] && v2['category_code'].concat(' ', v2['category_name']) === value) {
                  selectedRec['region'] = v2['region'];
                  selectedRec['tabCodeSave'] = v2['tab_code'];
                  selectedRec['categoryCode'] = v2['category_code'];
                }
              });
            }
          }
        }
      }
    });
    let copyUsWorkFeedMappSdk = JSON.parse(JSON.stringify(usWorkFeedMappSdk));
    setUsWorkFeedMappSdk(copyUsWorkFeedMappSdk);
  };

  const handleClickModifySave = rec => {
    let feedMapId = rec.feedMapId;
    let idx = rec.type === 'NEW' ? '' : rec.idx;
    let sdkVer = rec.sdkVer;
    let svcFeedIdx = rec.svcFeedIdx;
    let region = rec.region;
    let platformVersion = rec.platformVersion;
    let tabCodeSave = rec.tabCodeSave;
    let categoryCode = rec.categoryCode;

    let tempSvcFeedIdx = '';
    let arrSvcFeedIdx = svcFeedIdx.split(',');
    if (rec.type != 'NEW') {
      usFeedMappSet.forEach(v => {
        for (let idx01 in arrSvcFeedIdx) {
          if (v['feedIdx'] * 1 === arrSvcFeedIdx[idx01] * 1) {
            tempSvcFeedIdx = tempSvcFeedIdx + ',' + arrSvcFeedIdx[idx01];
          }
        }
      });
    }
    if (tempSvcFeedIdx.length >= 1) {
      tempSvcFeedIdx = tempSvcFeedIdx.substr(1, tempSvcFeedIdx.length);
    }

    if (!sdkVer) {
      window.alert('Please Select SDK Version');
      return;
    }

    if (!rec.platformVersion) {
      window.alert('Please Select Platform Version');
      return;
    }

    if (!rec.tabCode) {
      window.alert('Please Select Tab Details');
      return;
    }

    if (!rec.catCode) {
      window.alert('Please Select Category Details');
      return;
    }

    if (window.confirm('Are you sure to save?')) {
      useCallbackSd0106Action(isRoleAdmin, idx, feedMapId, sdkVer, tempSvcFeedIdx, region, platformVersion, tabCodeSave, categoryCode);
      return true;
    } else {
      return false;
    }
  };

  const handleClickClearCategory = rec => {
    let feedMapId = rec.feedMapId;
    let idx = rec.type === 'NEW' ? '' : rec.idx;
    let sdkVer = rec.sdkVer;
    let svcFeedIdx = rec.svcFeedIdx;
    let tempSvcFeedIdx = '';
    let arrSvcFeedIdx = svcFeedIdx.split(',');
    let platformVersion = rec.platformVersion;
    let tabCodeSave = rec.tabCodeSave;
    let categoryCode = rec.categoryCode;
    if (rec.type != 'NEW') {
      usFeedMappSet.forEach(v => {
        for (let idx01 in arrSvcFeedIdx) {
          if (v['feedIdx'] * 1 === arrSvcFeedIdx[idx01] * 1) {
            tempSvcFeedIdx = tempSvcFeedIdx + ',' + arrSvcFeedIdx[idx01];
          }
        }
      });
    }
    if (tempSvcFeedIdx.length >= 1) {
      tempSvcFeedIdx = tempSvcFeedIdx.substr(1, tempSvcFeedIdx.length);
    }

    if (!platformVersion || !tabCodeSave || !categoryCode) {
      window.alert('No Category to Clear!');
      return;
    }

    if (window.confirm('Are you sure to clear the category?')) {
      useCallbackSd0106Action(isRoleAdmin, idx, feedMapId, sdkVer, tempSvcFeedIdx);
      return true;
    } else {
      return false;
    }
  };

  const handleClickDelete = rec => {
    //
    let idx = rec.idx;
    let feedMapId = rec.feedMapId;
    if (window.confirm('Are you sure to delete this?')) {
      getFeedmapSdkIdxAction(isRoleAdmin, idx, feedMapId);
      setUsInitialSdkVer('');
      return true;
    } else {
      return false;
    }
  };

  const handleClickNewCancel = rec => {
    //
    usWorkFeedMappSdk.forEach(v => {
      if (v['idx'] * 1 === rec['idx'] * 1) {
        v['type'] = 'GARBAGE';
      }
    });
    let copyUsWorkFeedMappSdk = JSON.parse(JSON.stringify(usWorkFeedMappSdk.filter(item => item.type !== 'GARBAGE')));
    setUsWorkFeedMappSdk(copyUsWorkFeedMappSdk);
  };

  const handleClickAdd = () => {
    //
    let tempFeedMapId = usSelectedCountry + ':' + usSelectedPlatform;
    let newIdx = new Date().getTime(); // 임시 키값 저장시 빼고 저장한다. idx는 자동 생성
    //
    if (!usWorkFeedMappSdk) {
      setUsWorkFeedMappSdk([
        {
          type: 'NEW',
          cpNames: '',
          feedMapId: tempFeedMapId,
          idx: newIdx, // 찾기 위한 임시 키값
          lastChgDate: null,
          sdkVer: '',
          svcFeedIdx: '',
        },
      ]);
    } else {
      usWorkFeedMappSdk.push({
        type: 'NEW',
        cpNames: '',
        feedMapId: tempFeedMapId,
        idx: newIdx, // 찾기 위한 임시 키값
        lastChgDate: null,
        sdkVer: '',
        svcFeedIdx: '',
      });
      let copyUsWorkFeedMappSdk = JSON.parse(JSON.stringify(usWorkFeedMappSdk));
      setUsWorkFeedMappSdk(copyUsWorkFeedMappSdk);
    }
    setUsInitialSdkVer('');
  };

  let selectCpNames = [];
  if (usFeedMappSet) {
    if (usFeedMappSet.length >= 1) {
      usFeedMappSet.forEach(v2 => {
        selectCpNames.push(v2['cpName']);
      });
    }
  }
  const platformVersionSet = new Set();
  if (usCategoriesForSdk) {
    if (usCategoriesForSdk.length >= 1) {
      usCategoriesForSdk.forEach(v2 => {
        platformVersionSet.add(v2['platform_version']);
      });
    }
  }
  let previewSdkVer = [];
  if (usWorkFeedMappSdk) {
    usWorkFeedMappSdk.forEach(v1 => {
      if (v1['platformVersion']) {
        if (usCategoriesForSdk) {
          if (usCategoriesForSdk.length >= 1) {
            const tabCodeSet = new Set();
            const allTabNameSet = new Set();
            const allCatCodeSet = new Set();
            usCategoriesForSdk.forEach(v2 => {
              if (v2['platform_version'] === v1['platformVersion']) {
                let tabCodeName = v2['tab_code'].concat(' ', v2['tab_name']);
                tabCodeSet.add(tabCodeName);
                if (tabCodeName == v1['tabCode']) {
                  allTabNameSet.add(v2['tab_name']);
                  let catCodeName = v2['category_code'].concat(' ', v2['category_name']);
                  allCatCodeSet.add(catCodeName);
                }
              }
            });
            v1['platformTabCodeSet'] = Array.from(tabCodeSet);
            v1['tabNameSet'] = Array.from(allTabNameSet);
            v1['catCodeSet'] = Array.from(allCatCodeSet);
          }
        }
      }
      previewSdkVer.push(v1['sdkVer']);
    });
  }


  //
  ////////////////////////////////////////////////////////////////////////////
  // handle
  ////////////////////////////////////////////////////////////////////////////
  // Business Area
  const handleCmSdepth0001 = cmd => {
    useCallbackCmSdepth0001(isRoleAdmin);
    if (cmd === 'FIRST') {
      useCallbackCmSdepth0004(isRoleAdmin);
    }
  };
  // Country
  const handleCmSdepth0002 = bizArea => {
    //
    let searchBizArea = '';
    //
    if (!bizArea) {
      searchBizArea = 'AIC';
    }
    //
    if (bizArea === '') {
      searchBizArea = 'AIC';
    }
    //
    if (bizArea === 'NA/SCA') {
      searchBizArea = 'AIC';
    } else if (bizArea === 'EU') {
      searchBizArea = 'EIC';
    } else if (bizArea === 'KR') {
      searchBizArea = 'KIC';
    }
    useCallbackCmSdepth0002(isRoleAdmin, searchBizArea);
  };
  // Platform
  const handleCmSdepth0003 = () => {
    useCallbackCmSdepth0003(isRoleAdmin);
  };
  // Country All
  const handleCmSdepth0004 = () => {
    useCallbackCmSdepth0004(isRoleAdmin);
  };
  // 첫번째 Rules store
  const handleFirstRulesStore = cntryCode => {
    useCallbackFirstRulesStore(isRoleAdmin, cntryCode);
  };
  // 주 사용 Rules store
  const handleRulesStore = (isRoleAdmin, cntryCode, feedMapId) => {
    useCallbackRulesStore(isRoleAdmin, cntryCode, feedMapId);
  };
  ////////////////////////////////////////////////////////////////////////////
  // popup
  ////////////////////////////////////////////////////////////////////////////
  // Copy To Platform
  const handlePopupCopyToPlatform = () => {
    //
    if (openCloseCopyToPlatform) {
      setOpenCloseCopyToPlatform(false);
      // Copy to Platform  disabled true
      // Save  disabled false
    }
    if (!openCloseCopyToPlatform) {
      setOpenCloseCopyToPlatform(true);
    }
  };
  ////////////////////////////////////////////////////////////////////////////
  // Search
  ////////////////////////////////////////////////////////////////////////////
  // Business Area
  const handleChangeBusinessArea = event => {
    setUsSelectedBusinessArea(event.target.value);
    // 선택한 지역에 해당하는 국가 목록을 조회한다.
    if (event.target.value === 'ALL') {
      handleCmSdepth0004();
    } else {
      handleCmSdepth0002(event.target.value);
    }
    //
    // save, copy to platform
    setUsInitialSdkVer('');
    setSdkVerCall(true);
  };
  // Country
  const handleChangeContry = event => {
    setUsSelectedCountry(event.target.value);
    setSdkVerCall(true);
    // 선택된 country와 현재 platform
    let cntryCode = event.target.value;
    let feedMapId = event.target.value + ':' + usSelectedPlatform;
    //
    handleRulesStore(isRoleAdmin, cntryCode, feedMapId);
    // Preview
    setUsPrivewStore('');
    // Current State
    handleCurrentState(isRoleAdmin, feedMapId);
    // save, copy to platform
    setUsInitialSdkVer('');
  };
  // Platform
  const handleChangePlatform = event => {
    setUsSelectedPlatform(event.target.value);
    setSdkVerCall(true);
    // 현재 country와 선택된 platform
    let cntryCode = usSelectedCountry;
    let feedMapId = usSelectedCountry + ':' + event.target.value;
    //
    handleRulesStore(isRoleAdmin, cntryCode, feedMapId);
    // Preview
    setUsPrivewStore('');
    // Current State
    handleCurrentState(isRoleAdmin, feedMapId);
    // save, copy to platform
    setUsInitialSdkVer('');
  };

  const handleClickSave = useStateFormFeedIdx => {
    //
    // webOSTV 4.0은 입력이 안됨
    // webOS4.0은 입력이 됨
    let tempSelectedPlatform = usSelectedPlatform;
    let tempSelectedCountry = usSelectedCountry;
    //
    if (useStateFormFeedIdx) {
      // 다중 프랫폼 저장 변수가 있다면 아래와 같이 다중
      // 플래폼 형태로 한다.
      //  "platforms":"webOS4.5{, webOS4.0}",
      //
      let arrTemp = useStateFormFeedIdx.split('|');
      let tempPool = '';
      for (let idx01 in arrTemp) {
        //   let temp2 = arrTemp[idx01].replace(/TV/gi, "");
        let temp2 = arrTemp[idx01];
        // temp2 = temp2.replace(/ /gi, "");
        tempPool = tempPool + ',' + temp2;
      }
      tempPool = tempSelectedPlatform + tempPool + '';
      tempSelectedPlatform = tempPool;
    }
    //
    let platforms = tempSelectedPlatform;
    let cntryCode = tempSelectedCountry;
    let saveDataArr = [];
    let saveDataObj = {};
    let i = 1;
    for (let idx01 in usRulesStore.list) {
      // 국가 코드 입력, 다중 국가 코드 입력
      let sd0201CntryCode = usRulesStore.list[idx01].sd0201CntryCode;
      sd0201CntryCode = sd0201CntryCode.replace(/,/gi, '_');
      if (usRulesStore.list[idx01].boardCheck === true) {
        let tempOrder = 0;
        if (usRulesStore.list[idx01].boardOrder === '') {
          // order가 없는 경우
          tempOrder = i++;
        } else {
          // order가 있는 경우
          tempOrder = usRulesStore.list[idx01].boardOrder * 1;
        }
        saveDataObj = {
          feedIdx: usRulesStore.list[idx01].sd0201FeedIdx + '',
          cpName: usRulesStore.list[idx01].boardCp + '',
          itmCntPerCycl: usRulesStore.list[idx01].boardItemsForCpPerCycle + '',
          //  , "orderNo": arrRules[idx01].boardOrder + "" 신규일때 순성 없으면 저장이 안됨
          orderNo: tempOrder, // 테스트을 위해서 임시로 순서을 준다.
          cntryCode: sd0201CntryCode,
        };
        //
        saveDataArr.push(saveDataObj);
      }
    }
    //
    //
    let saveParams = {
      platforms: platforms,
      cntryCode: cntryCode,
      saveData: saveDataArr,
    };
    // 저장
    handleSd0105(isRoleAdmin, JSON.stringify(saveParams));
    // copy rule에서 저장시 copy rule, save rule 모두 비활성화
    // 저장된 형식으로 갱신 usRulesStore, usOriginRulesStore
    // save rules 활성화 비활성화
    // 새로 갱신 값으로 서로 비교시 비활성화
    usRulesStore.list.map(v => {
      for (let idx01 in saveDataArr) {
        if (v['sd0201FeedIdx'] === saveDataArr[idx01]['feedIdx']) {
          v['saveItmCntPerCycl'] = saveDataArr[idx01]['itmCntPerCycl'];
          v['sd0101ItmCntPerCycl'] = saveDataArr[idx01]['itmCntPerCycl'];
          v['boardItemsForCpPerCycle'] = saveDataArr[idx01]['itmCntPerCycl'];
        }
      }
    });
    setUsRulesStore(JSON.parse(JSON.stringify(usRulesStore)));
  };
  const handleSd0105 = (isRoleAdmin, jsonStringifyParams) => {
    //
    useCallbackSd0105(isRoleAdmin, jsonStringifyParams);
  };
  ////////////////////////////////////////////////////////////////////////////
  // save
  ////////////////////////////////////////////////////////////////////////////
  //
  ////////////////////////////////////////////////////////////////////////////
  // Priview
  ////////////////////////////////////////////////////////////////////////////
  const handlePriview = (isRoleAdmin, feedMapId, sdkVer) => {
    let searchFeedMapId = feedMapId;
    useCallbackPriview(isRoleAdmin, searchFeedMapId, sdkVer);
  };

  ////////////////////////////////////////////////////////////////////////////
  // Current State
  ////////////////////////////////////////////////////////////////////////////
  const handleCurrentState = (isRoleAdmin, feedMapId) => {
    let searchFeedMapId = feedMapId;
    //
    useCallbackCurrentState(isRoleAdmin, searchFeedMapId);
  };

  /*  const handleSDKCategoryCurrentState = (isRoleAdmin, feedMapId) => {
    let searchFeedMapId = feedMapId;
    //
    useCallbackSDKCategoryCurrentState(isRoleAdmin, searchFeedMapId);
  }; */
  ////////////////////////////////////////////////////////////////////////////
  // render
  ////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  // Business Area , country
  ////////////////////////////////////////////////////////////////////////////
  let businessAreaSelectTag = <></>;
  let countrySelectTag = <></>;
  if (usCmSdepth0001) {
    if (usCmSdepth0001.length >= 1) {
      let parentCmSdepth0001 = [usCmSdepth0001];
      let key1 = 0;
      businessAreaSelectTag = parentCmSdepth0001.map(function (rec) {
        return (
          <Select key={key1++} id="selectBusinessAreaId" value={usSelectedBusinessArea} onChange={event => handleChangeBusinessArea(event)}>
            {rec.map(function (rec2) {
              return (
                <MenuItem key={rec2.key} value={rec2.selectKey}>
                  {rec2.selectValue}
                </MenuItem>
              );
            })}
          </Select>
        );
      });
      let workSelectedCountry = ''; //작업용 selectedCounry
      let paretnCmSdepth00024 = [];
      if (usSelectedBusinessArea) {
        if (usSelectedBusinessArea === 'ALL') {
          // 전체
          paretnCmSdepth00024.push(usCmSdepth0004);

          if (usCmSdepth0004) {
            if (usCmSdepth0004.length >= 1) {
              // Business Area 전체,   첫번째 자료을 선택
              workSelectedCountry = usCmSdepth0004[0]['selectKey'];
            }
          }
        } else {
          // 개별
          if (usCmSdepth0002) {
            if (usCmSdepth0002.length >= 1) {
              paretnCmSdepth00024.push(usCmSdepth0002);
              // Business Area 개별,   첫번째 자료을 선택
              workSelectedCountry = usCmSdepth0002[0]['selectKey'];
            }
          }
        }
      }
      //
      if (usSelectedCountry && usSelectedCountry !== '') {
        // 선택한 국가가 있는 경우는 선택한 국가을 보여준다.
        workSelectedCountry = usSelectedCountry;
      }
      let key2 = 0;
      countrySelectTag = paretnCmSdepth00024.map(function (rec) {
        return (
          <Select key={key2++} id="selectCountryId" value={workSelectedCountry} onChange={event => handleChangeContry(event)}>
            {rec.map(function (rec2) {
              return (
                <MenuItem key={rec2.key} value={rec2.selectKey}>
                  {rec2.selectValue}
                </MenuItem>
              );
            })}
          </Select>
        );
      });
    }
  }
  ////////////////////////////////////////////////////////////////////////////
  // platform
  ////////////////////////////////////////////////////////////////////////////
  // key: "0"
  // selectKey: "LG_MOBILE"
  // selectValue: "LG_MOBILE"
  let platformTag = <></>;
  if (usCmSdepth0003) {
    if (usCmSdepth0003.length >= 1) {
      let workSelectedPlatform = '';
      //
      if (usSelectedPlatform && usSelectedPlatform !== '') {
        // 선택한 플랫폼이 있는 경우는 선택한 플랫폼을 보여준다.
        workSelectedPlatform = usSelectedPlatform;
      }
      //
      if (!usSelectedPlatform) {
        workSelectedPlatform = usCmSdepth0003[0]['selectKey'];
      }
      // 현재 코드는 webOSTV 4.0이지만 검색 또는 작업용으로 할 때는
      // webOS4.0으로 해야 한다.
      //  workSelectedPlatform = workSelectedPlatform.replace(/TV/gi, "");
      // workSelectedPlatform = workSelectedPlatform.replace(/ /gi, "");
      //
      let parentCmSdepth0003 = [usCmSdepth0003];
      let key3 = 0;
      platformTag = parentCmSdepth0003.map(function (rec) {
        // key: 0
        // selectKey: "ALL"
        // selectValue: "ALL"
        // const [useStateBizArea, setUseStateBizArea] = useState();
        return (
          <Select key={key3++} id="selectPlatformId" value={workSelectedPlatform} onChange={event => handleChangePlatform(event)}>
            {rec.map(function (rec2) {
              // 2020-06-28 12:27
              // 임시로 TV와 공백을 제거
              // let tempSelectKey = rec2.selectKey.replace(/TV/gi, "");
              let tempSelectKey = rec2.selectKey;
              // tempSelectKey = tempSelectKey.replace(/ /gi, "");
              return (
                <MenuItem key={rec2.key} value={tempSelectKey}>
                  {rec2.selectValue}
                </MenuItem>
              );
            })}
          </Select>
        );
      });
    }
  }

  ////////////////////////////////////////////////////////////////////////////
  // Priview
  ////////////////////////////////////////////////////////////////////////////
  // setUsPrivewStore
  //
  let priviewTag = <></>;
  //
  priviewTag = (
    <MaterialTable
      title="Preview"
      columns={[
        {
          title: 'Order',
          field: 'order',
          width: '10%',
        },
        {
          title: 'CP',
          field: 'providerId',
          width: '20%',
        },
        {
          title: 'Img',
          field: '',
          width: '20%',
          render: rowData => {
            return <img src={rowData.imageUrl} style={{ Width: 100, maxHeight: 100, border: '1px solid #cbccce' }} />;
          },
        },
        {
          title: 'Type',
          field: 'contentType',
          width: '20%',
        },
        {
          title: 'Title',
          field: 'title',
          width: 'auto',
        },
      ]}
      data={usPrivewStore ? usPrivewStore.list : [{}]}
      options={{
        search: false,
        paging: false,
        maxBodyHeight: 800,
      }}
    />
  );

  const handleClickType = rec => {
    usWorkFeedMappSdk.forEach(v => {
      if (v['idx'] * 1 === rec['idx'] * 1) {
        if (v['sdkVer'] && !v['platformVersion']) {
          var temp;
          platformVersionSet.add(v['sdkVer']);
          const platformVersionSetSort = Array.from(platformVersionSet).sort(cmpVersions);
          for (var k of platformVersionSetSort) {
            if (k === v['sdkVer']) {
              v['platformVersion'] = temp;
              break;
            }
            temp = k;
          }
        }
        v['type'] = 'EDIT';
      }
    });
    //
    let copyUsWorkFeedMappSdk = JSON.parse(JSON.stringify(usWorkFeedMappSdk));
    setUsWorkFeedMappSdk(copyUsWorkFeedMappSdk);

  };

  const handleClickModifyCancel = rec => {
    //
    usWorkFeedMappSdk.forEach(v => {
      if (v['idx'] * 1 === rec['idx'] * 1) {
        v['sdkVer'] = v['bSdkVer'];
        v['platformVersion'] = v['bPlatformVersion'];
        v['tabCode'] = v['bTabCode'];
        v['tabName'] = v['bTabName'];
        v['catCode'] = v['bCatCode'];
        v['catName'] = v['bCatName'];
        v['region'] = v['bRegion'];
        v['tabCodeSave'] = v['bTabCodeSave'];
        v['categoryCode'] = v['bCategoryCode'];
        v['type'] = 'LIST';
      }
    });
    //
    let copyUsWorkFeedMappSdk = JSON.parse(JSON.stringify(usWorkFeedMappSdk));
    setUsWorkFeedMappSdk(copyUsWorkFeedMappSdk);
  };

  const handleClickNewSave = rec => {
    handleClickModifySave(rec);
  };

  const handleChangeSdkVer = event => {
    let sdkVer = event.target.value;
    let feedMapId = usSelectedCountry + ':' + usSelectedPlatform;
    setUsInitialSdkVer(sdkVer);
    handlePriview(isRoleAdmin, feedMapId, sdkVer);
  };
  let previewSdkVerTag = <></>;
  previewSdkVerTag = (
    <Select value={usInitialSdkVer ? usInitialSdkVer : ''} onChange={event => handleChangeSdkVer(event)}>
      {sd0101 && sd0101.length >= 1 ? (
        sd0101.sort().map(name => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))
      ) : (
        <></>
      )}
    </Select>
  );

  let sdkCategoryTag = <></>;
  //

  if (usWorkFeedMappSdk) {
    if (usWorkFeedMappSdk.length >= 1) {
      //
      // let selectKey1 = 0;
      //
      sdkCategoryTag = usWorkFeedMappSdk.map(function (rec) {
        let type = rec.type;
        if (type === 'LIST') {
          return (
            <>
              <tr>
                <td>{rec.sdkVer}</td>
                <td>{rec.platformVersion}</td>
                <td>{rec.tabCode}</td>
                <td>{rec.catCode}</td>
                <td>
                  <button style={{ display: type === 'LIST' ? '' : 'none' }} type="button" className="btn ico_btn_modify" onClick={() => handleClickType(rec)}>
                    <span className="hide">활성화</span>
                  </button>
                </td>
                <td>
                  <button style={{ display: type === 'LIST' ? '' : 'none' }} type="button" className="btn ico_btn_del" onClick={() => handleClickDelete(rec)}>
                    {' '}
                    <span className="hide">삭제</span>
                  </button>
                </td>
              </tr>
            </>
          );
        } else if (type === 'EDIT') {
          return (
            <>
              <tr>
                <td>
                  <Select value={rec.sdkVer} onChange={event => handleSdkVerChange(event, rec)} input={<Input />}>
                    {sd0101 && sd0101.length >= 1 ? (
                      sd0101.sort().map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </td>
                <td>
                  <Select value={rec.platformVersion} disabled={rec.platformVersion ? 'disabled' : ''} input={<Input />}>
                    {platformVersionSet && platformVersionSet.size >= 1 ? (
                      Array.from(platformVersionSet)
                        .sort()
                        .map(name => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </td>
                <td>
                  <Select value={rec.tabCode} onChange={e => handleTabCodeChange(e, rec)} input={<Input />}>
                    {rec.platformTabCodeSet && rec.platformTabCodeSet.length >= 1 ? (
                      Array.from(rec.platformTabCodeSet).map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </td>
                <td>
                  <Select value={rec.catCode} onChange={e => handleCatCodeChange(e, rec)} input={<Input />}>
                    {rec.catCodeSet && rec.catCodeSet.length >= 1 ? (
                      Array.from(rec.catCodeSet).map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </td>
                <td>
                  <span className="hide">저장</span>
                  <button style={{ display: type === 'EDIT' ? '' : 'none' }} type="button" className="btn ico_btn_save" onClick={() => handleClickModifySave(rec)}>
                    <span className="hide">저장</span>
                  </button>
                </td>
                <td>
                  <button style={{ display: type === 'EDIT' ? '' : 'none' }} type="button" className="btn ico_btn_cancel" onClick={() => handleClickModifyCancel(rec)}>
                    {' '}
                    <span className="hide">취소</span>
                  </button>
                </td>
              </tr>
            </>
          );
        } else if (type === 'NEW') {
          return (
            <>
              <tr>
                <td>
                  <Select value={rec.sdkVer ? rec.sdkVer : ''} onChange={event => handleSdkVerChange(event, rec)} input={<Input />}>
                    {sd0101 && sd0101.length >= 1 ? (
                      sd0101.sort().map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </td>
                <td>
                  <Select value={rec.platformVersion ? rec.platformVersion : ''} disabled={rec.platformVersion ? 'disabled' : ''} input={<Input />}>
                    {platformVersionSet && platformVersionSet.size >= 1 ? (
                      Array.from(platformVersionSet)
                        .sort()
                        .map(name => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </td>
                <td>
                  <Select value={rec.tabCode ? rec.tabCode : ''} onChange={e => handleTabCodeChange(e, rec)} input={<Input />}>
                    {rec.platformTabCodeSet && rec.platformTabCodeSet.length >= 1 ? (
                      Array.from(rec.platformTabCodeSet).map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </td>
                <td>
                  <Select value={rec.catCode ? rec.catCode : ''} onChange={e => handleCatCodeChange(e, rec)} input={<Input />}>
                    {rec.catCodeSet && rec.catCodeSet.length >= 1 ? (
                      Array.from(rec.catCodeSet).map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </td>
                <td>
                  <button style={{ display: type === 'NEW' ? '' : 'none' }} type="button" className="btn ico_btn_save" onClick={() => handleClickNewSave(rec)}>
                    <span className="hide">저장</span>
                  </button>
                </td>
                <td>
                  <button style={{ display: type === 'NEW' ? '' : 'none' }} type="button" className="btn ico_btn_cancel" onClick={() => handleClickNewCancel(rec)}>
                    {' '}
                    <span className="hide">취소</span>
                  </button>
                </td>
              </tr>
            </>
          );
        }
      });
    }
  }
  ////////////////////////////////////////////////////////////////////////////
  // Current State
  ////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////
  // return
  ////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      {/* Business Area */}
      <div className="business_area">
        <span className="business_area_tit">Business Area</span>
        <FormControl variant="outlined">{businessAreaSelectTag}</FormControl>
      </div>
      {/* Business Area */}
      {/* 시작 필터 */}
      <div className="filter_area">
        {/* Country */}
        <FormControl variant="outlined">
          <InputLabel id="Country">Country</InputLabel>
          {countrySelectTag}
        </FormControl>
        {/* Country */}
        {/* CP */}
        <FormControl variant="outlined">
          <InputLabel id="Platform">Platform</InputLabel>
          {platformTag}
        </FormControl>
        {/* CP */}
      </div>
      {/* 끝 필터 */}

      <div className="tbl_wrap">
        <div className="btn_set_wrap tbl_top_info">
          <button className="MuiButtonBase-root MuiButton-root MuiButton-contained btn_color2" tabIndex="0" type="button" onClick={handleClickAdd}>
            <span className="MuiButton-label"> Add </span>
            <span className="MuiTouchRipple-root"></span>
          </button>
        </div>
        <table className="tbl_col_typEdit" style={{ backgroundColor: '#fff' }}>
          <caption className="MuiTypography-h6 caption_shelf">2nd Depth / Home Shelf Setting</caption>
          <colgroup>
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
          </colgroup>
          <thead>
            <tr>
              <th key={1}>SDK Version</th>
              <th key={2}>Platform Version</th>
              <th key={3}>Tab Detail</th>
              <th key={4}>Category Detail</th>
              <th key={5} colSpan="2">
                Action
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {/* table tr */}
            {sdkCategoryTag}
            {/* table tr */}
          </tbody>
        </table>
      </div>
      {/* 시작 Preview 게시판 */}
      <div className="tbl_wrap tbl_rules">
        {/* 시작 MaterialTable */}
        <div className="sdkver_area">
          <span className="sdkver_area_tit">SDK Version</span>
          <FormControl variant="outlined">{previewSdkVerTag}</FormControl>
        </div>
        {priviewTag}
      </div>

      {/* 끝 Preview 게시판 */}
      {/* 시작 Current State 게시판 */}
      {/* 끝 Current State 게시판 */}
      {/* 시작 Dialog */}
      {usCmSdepth0003 ? <PopupCopyToPlatform openClose={openCloseCopyToPlatform} cmSdepth0003={usCmSdepth0003} feedMapId={usSelectedPlatform} handleClickOpenCloseCopyToPlatform={handlePopupCopyToPlatform} handleClickSave={handleClickSave} /> : <></>}
      {/* 끝 Dialog */}
      <Dialog open={alert.open} onClose={closeAlert} aria-labelledby="confirm-dialog">
        <div className="pop_size_small">
          <p className="pop_comment">{alert.message}</p>
          <DialogActions>
            <div className="btn_set_wrap alC">
              <Button color="primary" onClick={closeAlert} className="btn_color2">
                OK
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
////////////////////////////////////////////////////////////////////////////////
// DisplayRules.propTypes
////////////////////////////////////////////////////////////////////////////////
DisplayRules.propTypes = {
  cmSdepth0001: PropTypes.array.isRequired,
  cmSdepth0002: PropTypes.array.isRequired,
  cmSdepth0003: PropTypes.array.isRequired,
  cmSdepth0004: PropTypes.array.isRequired,
  sd0101: PropTypes.any,
  sd0201: PropTypes.any.isRequired,
  sd0101: PropTypes.any.isRequired,
  sd0103: PropTypes.any.isRequired,
  sd0104: PropTypes.any.isRequired,
  sd0105: PropTypes.any.isRequired,
  sd0106: PropTypes.any.isRequired,
  sd0107: PropTypes.any.isRequired,
  sd0108: PropTypes.any.isRequired,
  rulesStore: PropTypes.any.isRequired,
};
////////////////////////////////////////////////////////////////////////////////
// mapStateToProps
////////////////////////////////////////////////////////////////////////////////
const mapStateToProps = state => ({
  isRoleAdmin: state.currentUser.isRoleAdmin,
  cmSdepth0001: state.code.cmSdepth0001,
  cmSdepth0002: state.code.cmSdepth0002,
  cmSdepth0003: state.code.cmSdepth0003,
  cmSdepth0004: state.code.cmSdepth0004,
  sd0101: PropTypes.any,
  sd0201: state.displayRules.sd0201,
  sd0101: state.displayRules.sd0101,
  sd0103: state.displayRules.sd0103,
  sd0104: state.displayRules.sd0104,
  sd0105: state.displayRules.sd0105,
  sd0106: state.displayRules.sd0106,
  sd0107: state.displayRules.sd0107,
  sd0108: state.displayRules.sd0108,
  rulesStore: state.displayRules.rulesStore,
});
////////////////////////////////////////////////////////////////////////////////
// mapDispatchToProps
////////////////////////////////////////////////////////////////////////////////
const mapDispatchToProps = dispatch => ({
  //
  getBizAreaAction(isRoleAdmin) {
    dispatch(getBizAreaAction(isRoleAdmin));
  },
  //
  getCountries223Action(isRoleAdmin, searchBizArea) {
    dispatch(getCountries223Action(isRoleAdmin, searchBizArea));
  },
  getPlatforms22Action(isRoleAdmin) {
    dispatch(getPlatforms22Action(isRoleAdmin));
  },
  getCountries22Action(isRoleAdmin) {
    dispatch(getCountries22Action(isRoleAdmin));
  },
  getFirstRulesStoreAction(isRoleAdmin, cntryCode) {
    dispatch(getFirstRulesStoreAction(isRoleAdmin, cntryCode));
  },
  getRulesStoreAction(isRoleAdmin, cntryCode, feedMapId) {
    dispatch(getRulesStoreAction(isRoleAdmin, cntryCode, feedMapId));
  },
  getFeedmapsetAction(isRoleAdmin, jsonStringifyParams) {
    dispatch(getFeedmapsetAction(isRoleAdmin, jsonStringifyParams));
  },
  getFeedmapFeedMapIdChannelsAction(isRoleAdmin, feedMapId, sdkVer) {
    dispatch(getFeedmapFeedMapIdChannelsAction(isRoleAdmin, feedMapId, sdkVer));
  },
  getFeedmapSdksOneAction(isRoleAdmin, feedMapId) {
    dispatch(getFeedmapSdksOneAction(isRoleAdmin, feedMapId));
  },
  getFeedmapSdkIdxAction(isRoleAdmin, idx, feedMapId) {
    dispatch(getFeedmapSdkIdxAction(isRoleAdmin, idx, feedMapId));
  },
  getFeedmapsetLogsNamesAction(isRoleAdmin, page, feedMapId, size) {
    dispatch(getFeedmapsetLogsNamesAction(isRoleAdmin, page, feedMapId, size));
  },
  getFeedmapsetLogsAction(isRoleAdmin, feedMapId, mapVer) {
    dispatch(getFeedmapsetLogsAction(isRoleAdmin, feedMapId, mapVer));
  },
  getFeedmapSdkAction(isRoleAdmin, idx, feedMapId, sdkVer, svcFeedIdx, region, platformVersion, tabCodeSave, categoryCode) {
    dispatch(getFeedmapSdkAction(isRoleAdmin, idx, feedMapId, sdkVer, svcFeedIdx, region, platformVersion, tabCodeSave, categoryCode));
  },
  getSdkVersionByFeedIdAction() {
    dispatch(getSdkVersionByFeedIdAction());
  },
});
////////////////////////////////////////////////////////////////////////////////
// export
////////////////////////////////////////////////////////////////////////////////
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DisplayRules));
