import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import MaterialTable from 'material-table';
import TabCategoryScheduleFrom from './TabCategoryScheduleFrom';
import { addCategoryScheduleList, deleteCategoryScheduleList, updateCategoryScheduleList } from '../../actions/homeAppActions';

const weekDayList = [
  { value: 'MON', label: 'Monday' },
  { value: 'TUE', label: 'Tuesday' },
  { value: 'WED', label: 'Wednesday' },
  { value: 'THU', label: 'Thursday' },
  { value: 'FRI', label: 'Friday' },
  { value: 'SAT', label: 'Saturday' },
  { value: 'SUN', label: 'Sunday' },
];

const TabCategoryScheduleView = ({ region, deviceType, catergory, scheduleList, handleClose, addCategorySchedule, updateCategorySchedule, deleteCategorySchedule }) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const initialState = {
    startDate: '',
    endDate: '',
    countryCodes: [],
    weekdayList: [],
    startTime: '',
    endTime: '',
  };
  const [schedule, setSchedule] = useState(initialState);
  const handleInputScheduleData = e => {
    const { name, value } = e.target;
    setSchedule({ ...schedule, ...{ [name]: value } });
  };

  const handleScheduleSubmit = e => {
    e.preventDefault();
    //check start time > end time.
    if (parseInt(schedule.endTime) <= parseInt(schedule.startTime)) {
      alert('Schedule start time should be before end time!');
    } else {
      if (edit) {
        const scheduleData = { ...schedule };
        scheduleData.weekdayList = scheduleData.weekdayList.filter(v => v !== 'ALL');
        updateCategorySchedule(catergory.categoryId, scheduleData);
      } else {
        addCategorySchedule(catergory.categoryId, schedule);
      }
      setOpen(false);
    }
  };

  const deleteSchedule = data => {
    deleteCategorySchedule(catergory.categoryId, data.idx);
  };

  const displayFullWeekName = weekVal => {
    const weekday = weekDayList.find(x => x.value === weekVal);
    return weekday ? weekday.label : '';
  };

  const displayTime = (data, timeType) => {
    return data[timeType] != 100 ? data[timeType] : '';
  };

  return (
    <div>
      <DialogTitle className="pop_head" id="form-dialog-title">
        {'Schedule Category Details'}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={'pop_body'}>
        <div className={'tbl_wrap'}>
          <div style={{ paddingBottom: '20px', borderBottom: '1px solid #848484' }}>
            <MaterialTable
              title={''}
              data={scheduleList}
              columns={[
                {
                  title: 'Country',
                  render: data => data.countryCodes.join(','),
                  width: '15%',
                },

                {
                  title: 'Start Date',
                  render: data => data.startDate.slice(0, 10),
                  width: '15%',
                },
                {
                  title: 'End Date',
                  render: data => data.endDate.slice(0, 10),
                  width: '15%',
                },
                {
                  title: 'Schedule Day',
                  width: '15%',
                  render: data => (
                    <Fragment>
                      <b>{data.weekdayList.includes('ALL') ? 'All' : ''}</b>
                      {data.weekdayList.map((x, i) => (
                        <Fragment key={i}>
                          <p>
                            <b>{displayFullWeekName(x)}</b>
                          </p>
                        </Fragment>
                      ))}
                    </Fragment>
                  ),
                },
                {
                  title: 'Start - End Time',
                  width: '15%',
                  render: data => (
                    <Fragment>
                      <p>
                        <b>{displayTime(data, 'startTime') !== '' && `${displayTime(data, 'startTime')} - ${displayTime(data, 'endTime')}`}</b>
                      </p>
                    </Fragment>
                  ),
                },
                {
                  title: '',
                  width: '25%',
                  render: data => (
                    <div className={'btn_set_wrap_tbl'}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          const scheduleData = { ...data };
                          scheduleData.startDate = scheduleData.startDate.slice(0, 10);
                          scheduleData.endDate = scheduleData.endDate.slice(0, 10);
                          scheduleData.startTime = displayTime(scheduleData, 'startTime');
                          scheduleData.endTime = displayTime(scheduleData, 'endTime');
                          setSchedule(scheduleData);
                          setOpen(true);
                          setEdit(true);
                        }}
                        className={'btn_color2 btn_size_tbl'}>
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          deleteSchedule(data);
                        }}
                        className={'btn_color2 btn_size_tbl'}>
                        Delete
                      </Button>
                    </div>
                  ),
                },
              ]}
              options={{
                search: false,
                paging: false,
              }}
            />
          </div>
        </div>
        <div className={'btn_set_wrap'}>
          <Button data-testid="addButton" color="primary" onClick={handleClose} className={'btn_color1'}>
            Close
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setSchedule(initialState);
              setEdit(false);
              setOpen(true);
            }}
            className={'btn_color2 btn_size_tbl'}
            style={{ width: '150px' }}>
            Add Schedule
          </Button>
        </div>
      </DialogContent>
      <Dialog open={open} className={'pop_wrap tab_schedule_popup'}>
        <TabCategoryScheduleFrom
          region={region}
          deviceType={deviceType}
          edit={edit}
          schedule={schedule}
          handleInputChange={handleInputScheduleData}
          handleSubmit={handleScheduleSubmit}
          handleClose={() => {
            setOpen(false);
          }}
        />
      </Dialog>
    </div>
  );
};

TabCategoryScheduleView.propTypes = {
  region: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  catergory: PropTypes.object.isRequired,
  scheduleList: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  addCategorySchedule: PropTypes.func.isRequired,
  updateCategorySchedule: PropTypes.func.isRequired,
  deleteCategorySchedule: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  scheduleList: state.homeApp.categoryScheduleList,
});

const mapDispatchToProps = dispatch => ({
  addCategorySchedule: (categoryId, data) => dispatch(addCategoryScheduleList(categoryId, data)),
  updateCategorySchedule: (categoryId, data) => dispatch(updateCategoryScheduleList(categoryId, data)),
  deleteCategorySchedule: (categoryId, scheduleId) => dispatch(deleteCategoryScheduleList(categoryId, scheduleId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TabCategoryScheduleView));
