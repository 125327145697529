import { ROWHEADER_TYPE, TABLE_TYPE } from '../../consts/IssConsts';

export const ServiceIssueGroupDataSet = {
  appData: {
    tableType: TABLE_TYPE.CHECKBOX_SELECTION,
    title: 'Issue Status',
    visibleTitle: true,
    existTooltip: false,
    tooltip: '',
    header: [
      'Issue ID', 'Date', 'Cnty.', 'Type'
      , 'Code (Lv.)'
      , 'Count', 'Source', 'Platform', 'CP', 'Content ID'
      , 'Content Name', 'Issue Title', 'Status', 'Assign', 'Modifier'
      , 'Jira URL', 'Action Details'
    ],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.DROPDOWN_FOR_DISPLAY
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DROPDOWN_FOR_DISPLAY, ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DROPDOWN, ROWHEADER_TYPE.DYNAMIC_DROPDOWN, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL
    ],
    subTableCellStyle: [
      ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.BLANK_COLUMN, ROWHEADER_TYPE.BLANK_COLUMN, ROWHEADER_TYPE.BLANK_COLUMN
      , ROWHEADER_TYPE.BLANK_COLUMN, ROWHEADER_TYPE.BLANK_COLUMN
    ],
    dropdownItem: [
      { index: 'Unaddressed', name: 'Unaddressed' , itemType: 'issueState' },
      { index: 'Processing', name: 'Processing' , itemType: 'issueState' },
      { index: 'Resolve', name: 'Resolve' , itemType: 'issueState' }
    ],
    cellWidth: [
      '4%', '4%', '3%', '4%'
      , '6%'
      , '4%', '4%', '5%', '4%', '10%'
      , '10%', '12%', '4%', '4%', '4%'
      , '6%', '10%'
    ],
    rowdata: []
  },
  playData: {
    tableType: TABLE_TYPE.CHECKBOX_SELECTION,
    title: 'Issue Status',
    visibleTitle: true,
    existTooltip: false,
    tooltip: '',
    header: [
      'Issue ID', 'Date', 'Cnty.', 'Type'
      , 'Code (Lv.)'
      , 'Count', 'Source', 'Platform', 'CP', 'Ch.N'
      , 'Content ID', 'Content Name', 'Issue Title', 'Status', 'Assign'
      , 'Modifier', 'Jira URL', 'Action Details'
    ],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.DROPDOWN_FOR_DISPLAY
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DROPDOWN_FOR_DISPLAY, ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DROPDOWN, ROWHEADER_TYPE.DYNAMIC_DROPDOWN
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL
    ],
    subTableCellStyle: [
      ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.BLANK_COLUMN, ROWHEADER_TYPE.BLANK_COLUMN
      , ROWHEADER_TYPE.BLANK_COLUMN, ROWHEADER_TYPE.BLANK_COLUMN, ROWHEADER_TYPE.BLANK_COLUMN
    ],
    dropdownItem: [
      { index: 'Unaddressed', name: 'Unaddressed' , itemType: 'issueState' },
      { index: 'Processing', name: 'Processing' , itemType: 'issueState' },
      { index: 'Resolve', name: 'Resolve' , itemType: 'issueState' }
    ],
    cellWidth: [
      '4%', '4%', '4%', '4%'
      , '4%'
      , '4%', '4%', '4%', '4%', '4%'
      , '11%', '10%', '10%', '4%', '4%'
      , '5%', '4%', '10%'
    ],
    rowdata: []
  },
  contentsInfo: {
    title: 'Contents Info',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Contents Info',
    header: [
        'Code', 'Source', 'Ch.N', 'Crt Date', 'Last Date'
        , 'Extra Info', 'Raw data'
    ],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DOWNLOAD_URL
    ],
    cellWidth: [
      '8%', '12%', '8%', '10%', '10%', '40%', '12%'
    ],
    rowdata: []
  },
  beaconInspectionDescription: {
    title: 'Beacon Description',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Beacon Description',
    header: [
        'Status', 'Check', 'Result', 'Description'
    ],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
    ],
    cellWidth: [
      '15%', '15%', '15%', '55%'
    ],
    rowdata: []
  },
  beaconErrorDescription: {
    title: 'Beacon Error Description',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Beacon Error Description',
    header: [
        'Description', 'Guide'
    ],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
    ],
    cellWidth: [
      '70%', '30%'
    ],
    rowdata: []
  },
  appSimilarIssueHistory: {
    tableType: TABLE_TYPE.NORMAL,
    title: 'App Issue History',
    visibleTitle: true,
    existTooltip: false,
    tooltip: 'Issues Similar  History message..!!',
    header: [
      'Issue ID', 'Date', 'Cnty.', 'Type'
      , 'Code (Lv.)'
      , 'Count', 'Source', 'Platform', 'CP', 'Content ID'
      , 'Content Name', 'Issue Title', 'Status', 'Assign', 'Modifier'
      , 'Jira URL', 'Action Details'
    ],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL,  ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL
    ],
    cellWidth: [
      '4%', '4%', '3%', '4%'
      , '6%'
      , '4%', '4%', '5%', '4%', '10%'
      , '10%', '12%', '4%', '4%', '4%'
      , '6%', '10%'
    ],
    rowdata: []
  },
  playSimilarIssueHistory: {
    tableType: TABLE_TYPE.NORMAL,
    title: 'Play Issue History',
    visibleTitle: true,
    existTooltip: false,
    tooltip: 'Issues Similar  History message..!!',
    header: [
      'Issue ID', 'Date', 'Cnty.', 'Type'
      , 'Code (Lv.)'
      , 'Count', 'Source', 'Platform', 'CP', 'Ch.N'
      , 'Content ID', 'Content Name', 'Issue Title', 'Status', 'Assign'
      , 'Modifier', 'Jira URL', 'Action Details'
    ],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      ,  ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL
    ],
    cellWidth: [
      '4%', '4%', '4%', '4%'
      , '4%'
      , '4%', '4%', '4%', '4%', '4%'
      , '11%', '10%', '10%', '4%', '4%'
      , '5%', '4%', '10%'
    ],
    rowdata: []
  },
  appHistoryData: {
    tableType: TABLE_TYPE.NORMAL,
    title: 'Issue Status',
    visibleTitle: true,
    existTooltip: false,
    tooltip: '',
    header: [
      'Issue ID', 'Date', 'Cnty.', 'Type'
      , 'Code (Lv.)'
      , 'Count', 'Source', 'Platform', 'CP', 'Content ID'
      , 'Content Name', 'Issue Title', 'Status', 'Assign', 'Modifier'
      , 'Jira URL', 'Action Details'
    ],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.DROPDOWN_FOR_DISPLAY
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DROPDOWN_FOR_DISPLAY, ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL
    ],
    subTableCellStyle: [
      ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.BLANK_COLUMN, ROWHEADER_TYPE.BLANK_COLUMN, ROWHEADER_TYPE.BLANK_COLUMN
      , ROWHEADER_TYPE.BLANK_COLUMN, ROWHEADER_TYPE.BLANK_COLUMN
    ],
    dropdownItem: [
      { index: 'Unaddressed', name: 'Unaddressed' , itemType: 'issueState' },
      { index: 'Processing', name: 'Processing' , itemType: 'issueState' },
      { index: 'Resolve', name: 'Resolve' , itemType: 'issueState' }
    ],
    cellWidth: [
      '4%', '4%', '3%', '4%'
      , '6%'
      , '4%', '4%', '5%', '4%', '10%'
      , '10%', '12%', '4%', '4%', '4%'
      , '6%', '10%'
    ],
    rowdata: []
  },
  playHistoryData: {
    tableType: TABLE_TYPE.NORMAL,
    title: 'Issue Status',
    visibleTitle: true,
    existTooltip: false,
    tooltip: '',
    header: [
      'Issue ID', 'Date', 'Cnty.', 'Type'
      , 'Code (Lv.)'
      , 'Count', 'Source', 'Platform', 'CP', 'Ch.N'
      , 'Content ID', 'Content Name', 'Issue Title', 'Status', 'Assign'
      , 'Modifier', 'Jira URL', 'Action Details'
    ],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.DROPDOWN_FOR_DISPLAY
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DROPDOWN_FOR_DISPLAY, ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL
    ],
    subTableCellStyle: [
      ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.BLANK_COLUMN, ROWHEADER_TYPE.BLANK_COLUMN
      , ROWHEADER_TYPE.BLANK_COLUMN, ROWHEADER_TYPE.BLANK_COLUMN, ROWHEADER_TYPE.BLANK_COLUMN
    ],
    cellWidth: [
      '4%', '4%', '4%', '4%'
      , '4%'
      , '4%', '4%', '4%', '4%', '4%'
      , '11%', '10%', '10%', '4%', '4%'
      , '5%', '4%', '10%'
    ],
    rowdata: []
  },
  scheduleData: {
    tableType: TABLE_TYPE.CHECKBOX_SELECTION,
    title: 'Issue Status',
    visibleTitle: true,
    existTooltip: false,
    tooltip: '',
    header: [
      'Issue ID', 'Date', 'CP', 'Code (Lv.)', 'Cnty.'
      , 'Platform', 'Contents Count', 'Status', 'Assign', 'Modifier'
      , 'Jira URL', 'Action Details', 'Excel Download'
    ],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.PLATFORM_DROPDOWN
      , ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DROPDOWN, ROWHEADER_TYPE.DYNAMIC_DROPDOWN, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.EXCEL_DOWNLOAD_BUTTON
    ],
    subTableCellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.PLATFORM_DROPDOWN
      , ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.DROPDOWN, ROWHEADER_TYPE.DYNAMIC_DROPDOWN, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.EXCEL_DOWNLOAD_BUTTON
    ],
    dropdownItem: [
      { index: 'Unaddressed', name: 'Unaddressed' , itemType: 'issueState' },
      { index: 'Processing', name: 'Processing' , itemType: 'issueState' },
      { index: 'Resolve', name: 'Resolve' , itemType: 'issueState' }
    ],
    cellWidth: [
      '6%', '6%', '6%', '6%', '6%'
      ,'6%', '6%', '6%', '6%', '6%'
      , '8%', '20%', '10%'
    ],
    rowdata: []
  },
  scheduleHistoryData: {
    tableType: TABLE_TYPE.NORMAL,
    title: 'Issue Status',
    visibleTitle: true,
    existTooltip: false,
    tooltip: '',
    header: [
      'Issue ID', 'Date', 'CP', 'Code (Lv.)', 'Cnty.'
      , 'Platform', 'Contents Count', 'Status', 'Assign', 'Modifier'
      , 'Jira URL', 'Action Details'
    ],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.PLATFORM_DROPDOWN
      , ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL
    ],
    subTableCellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.PLATFORM_DROPDOWN
      , ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL
    ],
    cellWidth: [
      '7%', '7%', '7%', '7%', '7%'
      ,'7%', '7%', '7%', '7%', '7%'
      , '8%', '20%'
    ],
    rowdata: []
  },
  appIssueGuide: {
    title: 'App Issue Guide',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'App Issue Guide',
    header: ['Inspect ID', 'Description', 'Guide'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['20%', '40%', '40%'],
    rowdata: []
  },
  scheduleIssueGuide: {
    title: 'Schedule Issue Guide',
    visibleTitle: false,
    existTooltip: false,
    tooltip: 'Schedule Issue Guide',
    header: ['Content Id', 'Content Name', 'Last Schedule End Time', 'Guide'],
    cellStyle: [ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['30%', '30%','15%', '25%'],
    rowdata: []
  },
  scheduleSimilarHistory: {
    tableType: TABLE_TYPE.NORMAL,
    title: 'Schedule Issue History',
    visibleTitle: true,
    existTooltip: false,
    tooltip: 'Issues Similar  History message..!!',
    header: [
      'Issue ID', 'Date', 'CP', 'Code (Lv.)', 'Cnty.'
      , 'Platform', 'Contents Count', 'Status', 'Assign', 'Modifier'
      , 'Jira URL', 'Action Details'
    ],
    cellStyle: [
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.PLATFORM_DROPDOWN
      , ROWHEADER_TYPE.PLATFORM_DROPDOWN, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL
      , ROWHEADER_TYPE.JIRALINK, ROWHEADER_TYPE.NORMAL
    ],
    cellWidth: [
      '6%', '6%', '6%', '6%', '6%'
      ,'6%', '6%', '6%', '6%', '6%'
      , '8%', '30%', '10%'
    ],
    rowdata: [],
  },
};
