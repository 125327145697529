import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ComponentContainer from './ComponentContainer';
import Loading from '../common/Loading';
import IndicatorsPopup from './IndicatorsPopup';
import {ComponentItems} from './DataSet/IssueTrendData';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { styled } from '@material-ui/core/styles';
import TutorialPage from '../TutorialPage';

function IssueTrend({username}) {
  const [allComponents, setAllComponents] = useState([]);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [newSelectedComponentIds, setNewSelectedComponentIds] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [size, setSize] = useState('Medium');
  const [calendarValue, setCalendarValue] = useState([]);
  const [defaultPeriod, setDefaultPeriod] = useState(7);  // default period 7days
  const [defaultBasicFilter, setDefaultBasicFilter] = useState(null);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isDivideSection, setDivideSection] = useState(true);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const savedComponents = getSavedComponent();

    if (savedComponents && savedComponents.length > 0) {
      const updateComponents = savedComponents.map(item => {
        const temp = ComponentItems.find(com => com.name === item.name.split('_')[0]);
        item.component = temp.component;
        return {...item};
      });

      setSelectedComponents([...updateComponents]);

      const updateComponentsName = updateComponents.map(item => item.name);
      const tempComponent = ComponentItems.filter(item => !updateComponentsName.includes(item.name));

      const diffComponent = tempComponent.map(item => {
        return { ...item, checked: false, selected: false };
      });

      setAllComponents([...updateComponents, ...diffComponent]);
    } else {
      initialize();

      const copyComponentList = ComponentItems.map(component => {
        component.filter.selectedFilter = null;
        return { ...component };
      });

      const initialSelectedComponents = copyComponentList.filter((component) => component.checked);
      setSelectedComponents([...initialSelectedComponents]);
      setAllComponents([...copyComponentList]);
    }

    defaultRangeDate();
  }, []);

  useEffect(() => {
    saveCurrentComponent();

    setIsDisplay(true);
  }, [selectedComponents]);

  const initialize = () => {
    setAllComponents([]);
    setSelectedComponents([]);
    setNewSelectedComponentIds([]);
    setPopupOpen(false);
    setSize('Medium');
    setCalendarValue([]);
    setDefaultPeriod(7);
    setDefaultBasicFilter(null);

    setIsDisplay(false);
  };

  const defaultRangeDate = () => {
    const today = new Date();
    const utcToday = new Date(today.toUTCString());

    const yesterday = new Date(utcToday);
    yesterday.setDate(utcToday.getDate() - 1);

    const lastWeek = new Date(utcToday);
    lastWeek.setDate(yesterday.getDate() - defaultPeriod);

    const yesterdayString = yesterday.toISOString().substring(0, 10);
    const lastWeekString = lastWeek.toISOString().substring(0, 10);

    setDefaultBasicFilter({date: [lastWeekString, yesterdayString], time: '00:00'});
  };

  const handleComponentSelect = (componentId) => {
    const updatedComponents = selectedComponents.map((component) => {
      if (component.id === componentId) {
        component.selected = true;
      } else {
        component.selected = false;
      }
      return component;
    });
    setSelectedComponents(updatedComponents);
  };

  const handleComponentRemove = (componentId) => {
    const updatedComponents = allComponents.map(component => {
      if (component.id === componentId) {
        if (component.delete) {
          return null;
        } else {
          component.filter.selectedFilter = null;
          return { ...component, checked: false };
        }
      }
      return component;
    }).filter(component => component !== null);

    setAllComponents(updatedComponents);
    setSelectedComponents((prevComponents) => prevComponents.filter((component) => component.id !== componentId));
  };

  const handleComponentMove = (sourceIndex, destinationIndex) => {
    const updatedComponents = [...selectedComponents];
    const [movedComponent] = updatedComponents.splice(sourceIndex, 1);

    updatedComponents.splice(destinationIndex, 0, movedComponent);
    setSelectedComponents(updatedComponents);
  };

  const handleComponentAdd = (copiedComponent) => {
    const newId = Math.max(...allComponents.map(component => component.id)) + 1;
    const newComponent = { ...copiedComponent, id: newId, name: copiedComponent.name + '_' + newId,
      checked: true, selected: false, copy: false, delete: true, show: false };
    newComponent.filter = JSON.parse(JSON.stringify(copiedComponent.filter));

    setNewSelectedComponentIds([newId]);
    setSelectedComponents([...selectedComponents, newComponent]);
    setAllComponents([...allComponents, newComponent]);
  };

  const handlePopupOpen = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handlePopupApply = (selectedComponentIds, isDivideSection) => {
    setDivideSection(isDivideSection);
    const selectedComponentsFromList = allComponents.map((component) => {
      if (selectedComponentIds.includes(component.id)) {
        component.checked = true;
      } else {
        component.checked = false;
        component.filter.selectedFilter = null;
      }
      return component;
    });

    setNewSelectedComponentIds(selectedComponentIds.filter(previousId => !selectedComponents.map(item => item.id).includes(previousId)));
    setSelectedComponents(selectedComponentsFromList.filter((component) => component.checked));
    setPopupOpen(false);
  };

  const getSavedComponent = () => {
    const savingId = username + '_components';
    return JSON.parse(localStorage.getItem(savingId));
  };

  const saveCurrentComponent = () => {
    if (selectedComponents && selectedComponents.length > 0) {
      const saveComponents = selectedComponents.map(item => {
        const { hiddenFilter, ...restFilter } = item.filter;
        return {
          ...item,
          filter: restFilter,
          table: {
            ...item.table,
            rowdata: [],
          }
        };
      });

      const savingId = username + '_components';
      localStorage.setItem(savingId, JSON.stringify(saveComponents));
    }
  };

  const handleComponentTableShowChange = (componentId, value, type) => {
    const updatedComponents = selectedComponents.map((component) => {
      if (component.id === componentId) {
        if (type === 'flag') {
          component.show = value;
        } else if (type === 'table') {
          component.table = { ...value };
        }
      }
      return component;
    });

    setSelectedComponents(updatedComponents);
  };

  const CustomRadio = styled(Radio)({
    color: '#6d6d6d',
    '&.Mui-checked': {
      color: '#ffffff',
    },
  });

  const CustomFormControlLabel = styled(FormControlLabel)(() => ({
    marginRight: '30px',
    '& .MuiTypography-root': {
      fontSize: '14px',
      color: '#6d6d6d',
    },
    '& .Mui-checked + .MuiTypography-root': {
      color: '#ffffff',
    },
  }));

  const handleOpen = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <>
    {
      isDisplay ? (
        <div className="iss-main">
          <div className="main-container">
            <div className="issue-trend">
              <div className="top-bar">
                <div className="top-title">
                  <button className="btn-tutorial-2" onClick={handleOpen}/>
                  <div>Issue Trend</div>
                </div>
                <div className="top-control">
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="options"
                      name="options"
                      value={size}
                      onChange={e => setSize(e.target.value)}
                      row
                    >
                      <CustomFormControlLabel value="Medium" control={<CustomRadio />} label="Medium" />
                      <CustomFormControlLabel value="Large" control={<CustomRadio />} label="Large" />
                    </RadioGroup>
                  </FormControl>
                  <button className="popup-button" onClick={handlePopupOpen}>+ Indicator</button>
                </div>
              </div>
              <ComponentContainer
                size={size}
                rangeDays={defaultPeriod}
                defaultFilter={defaultBasicFilter}
                components={selectedComponents}
                newSelectedComponentIds={newSelectedComponentIds}
                onComponentSelect={handleComponentSelect}
                onComponentRemove={handleComponentRemove}
                onComponentMove={handleComponentMove}
                onComponentAdd={handleComponentAdd}
                onComponentTableShowChange={handleComponentTableShowChange}
                onFilterChange={saveCurrentComponent}
                isDivideSection={isDivideSection}
              />
              {popupOpen && (
                <IndicatorsPopup
                  allComponents={allComponents}
                  isDivideSection={isDivideSection}
                  onCancel={handlePopupClose}
                  onApply={handlePopupApply}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )
    }
    {isActive && <TutorialPage data={'issueTrend'} isActive={isActive} onClose={handleClose} />}
    </>
  );
}

IssueTrend.propTypes = {
  username: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  username: state.currentUser.username,
});

export default connect(mapStateToProps)(IssueTrend);
