import React, { useEffect } from 'react';
import styled from 'styled-components';

// 스타일링된 컴포넌트를 생성합니다.
const TutorialArea = styled.div`
  #clip-01 {
    --x: 12px;
    --y: 145px;
    --width: 1896px;
    --height: 44px;

    top: 0;
    left: 0;
    width: 1920px;
    height: 199px;
  }

  #clip-02 {
    --x: 12px;
    --y: 1px;
    --width: 1896px;
    --height: 480px;

    top: 199px;
    left: 0;
    width: 1920px;
    height: 2000px;
  }

  #text-01 {
    top: 93px;
    left: 645px;
  }

  #text-02 {
    top: 724px;
    left: 450px;
  }

  #line-01 {
    top: 104px;
    left: 531px;
  }

  #line-02 {
    top: 688px;
    left: 370px;
  }
`;

const ContentDetail01 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TutorialArea className='tutorial-area'>
        <div id="clip-01" className="clip-box">
          <div id="clip-01-hole" className="hole" />
          <div id="clip-01-border" className="border" />
        </div>

        <div id="clip-02" className="clip-box">
          <div id="clip-02-hole" className="hole" />
          <div id="clip-02-border" className="border" />
        </div>

        <div className="text-box">
          <div id="text-01" className="text">You can select Issue tab or set conditions for the filter.</div>
          <div id="text-02" className="text">You can check issue list for each tab.</div>
        </div>

        <div className="line-box">
          <div id="line-01" className="line">
            <img src="/common/images/iss/tutorial/t_6136_line_01.png" alt='Line' />
          </div>
          <div id="line-02" className="line">
            <img src="/common/images/iss/tutorial/t_6136_line_02.png" alt='Line' />
          </div>
        </div>
      </TutorialArea>
    </>
  );
};

export default ContentDetail01;