import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AppVersionFilter } from '../../../common/filter/AppVersionFilter';
import LoadingIndicator from '../../../common/LoadingIndicator';
import MaterialTable from 'material-table';
import AppVersionAdd from './AppVersionAdd';
import { Button } from '@material-ui/core';
import { getAppVersionAction } from '../../../actions/appVersionActions';

function AppVersion({ isRoleAdmin, getAppVersionAction, fetching, appVersionList, allCountries }) {
  const [shouldRequest, setShouldRequest] = React.useState(true);
  const [osName, setOsName] = React.useState('All');
  const [cntry, setCntry] = React.useState('All');

  const handleClickSearch = () => {
    setShouldRequest(true);
  };

  useEffect(() => {
    console.log('useEffect osName : ' + osName);
    console.log('useEffect cntry : ' + cntry);
    //getCntryAction(isRoleAdmin);
    if (shouldRequest) {
      getAppVersionAction(osName, cntry);
      setShouldRequest(false);
    }
  }, [shouldRequest]);

  useEffect(() => {
    console.log('isRoleAdmin : ' + isRoleAdmin);
    console.log('osName : ' + osName);
    console.log('cntry : ' + cntry);
  }, [osName][cntry]);

  return (
    <div>
      <AppVersionFilter osName={osName} handleOsName={e => setOsName(e.target.value)} cntry={cntry} handleCountry={e => setCntry(e.target.value)} allCountries={allCountries}/>

      <div className="btn_set_wrap search_wrap">
        <Button variant="contained" className={'btn_color2'} onClick={handleClickSearch}>
          Search
        </Button>
      </div>

      {fetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        <div className={'tbl_wrap'}>
          <div className={'btn_set_wrap tbl_top1'}>
            <AppVersionAdd />
          </div>
          <MaterialTable
            title=" " //없으면 Table Title이 default title로 나옴
            columns={[
              {
                title: 'Country',
                field: 'countryCode',
                width: '15%',
              },
              {
                title: 'PlatForm',
                field: 'platformName',
                width: '15%',
              },
              {
                title: 'Version Name',
                field: 'versionName',
                width: '20%',
              },
              {
                title: 'Version Code',
                field: 'versionCode',
                width: 'auto',
              },
              {
                title: 'Update',
                field: 'reqUpdate',
                width: '10%',
              },
              {
                title: 'Deploy',
                field: 'mobileTransferFlag',
                width: '10%',
              },
              {
                title: '',
                field: '',
                width: '15%',
                render: rowData => (
                  <React.Fragment>
                    <div className={'btn_set_wrap_tbl'}>
                      {/*<CpCodeEdit item={rowData} />*/}
                      {/*<AppVersionDelete requestPath={`/api/admin/providers/${rowData['CP Code']}`} reloadAction={getProvidersAdminAction} />*/}
                    </div>
                  </React.Fragment>
                ),
              },
            ]}
            data={appVersionList
              .map(n => {
                let newData = {};
                newData['countryCode'] = n.countryCode;
                newData['platformName'] = n.platformName;
                newData['versionName'] = n.versionName;
                newData['versionCode'] = n.versionCode;
                newData['reqUpdate'] = n.reqUpdate === 1 ? 'Required' : 'Optional';
                newData['mobileTransferFlag'] = n.mobileTransferFlag === 'Y' ? 'Y' : 'N';
                return newData;
              })}
            options={{
              //selection: true,
              pageSize: 10,
              pageSizeOptions: [5, 10, 30, 50, 100],
              rowStyle: { backgroundColor: '#fff' },
            }}
            // onSelectionChange={(rows) => {
            //   selectedItem = rows;
            //   console.log('[cpCode][onSelectionChange][rows]', rows);
            // }}
          />
        </div>
      )}
    </div>
  );
}
AppVersion.propTypes = {
  fetching: PropTypes.bool.isRequired,
  getAppVersionAction: PropTypes.func.isRequired,
  appVersionList: PropTypes.array.isRequired,
  isRoleAdmin: PropTypes.bool.isRequired,
  allCountries: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  fetching: state.appVersion.fetching,
  appVersionList: state.appVersion.appVersionList,
  isRoleAdmin: state.currentUser.isRoleAdmin,
  allCountries: state.cntry.allCountries,
});

const mapDispatchToProps = dispatch => ({
  getAppVersionAction(osName, cntry) {
    dispatch(getAppVersionAction(osName, cntry));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppVersion));
