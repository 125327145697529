import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import NoData from '../../common/NoData';
function TableDetailChart({ tableDetail }) {
  const tblRef = React.createRef();
  const pageSizeOptionList = tableDetail.length < 10 ? [tableDetail.length, 10] : tableDetail.length > 10 ? [10, tableDetail.length] : [10];
  return (
    <React.Fragment>
      {tableDetail.length > 0 && (
        <div className="tbl_wrap tbl_col_typ1 tbl_summary">
          <MaterialTable
            tableRef={tblRef}
            title=""
            columns={[...Object.keys(tableDetail[0])].map((n, i) => ({
              title: n,
              field: n,
              width: i === 0 ? 250 : 150,
            }))}
            data={JSON.parse(JSON.stringify(tableDetail))}
            options={{
              fixedColumns: {
                left: 1,
              },
              pageSize: 10, //defaultPageSize=5
              pageSizeOptions: pageSizeOptionList,
              exportButton: true,
              exportAllData: true,
            }}
          />
        </div>
      )}{' '}
      {tableDetail.status && <NoData message={tableDetail.message} />}
    </React.Fragment>
  );
}

TableDetailChart.propTypes = {
  tableDetail: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  tableDetail: state.statistic.tableDetail,
});

export default connect(mapStateToProps)(TableDetailChart);
