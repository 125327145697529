import { SET_ACCESS_TOKEN, SET_SIGNIN_FETCHING, SET_SIGNIN_RESULT } from './actionTypes';

import { getCurrentUserAction, initCurrentUserAction } from './userActions';

import { initProgramsAction } from './programActions';

import { API_BASE_URL, ACCESS_TOKEN } from '../constants';

import { getHeaders } from '../util/actionUtil';

export const setSigninFetchingAction = value => ({
  type: SET_SIGNIN_FETCHING,
  payload: value,
});

export const setAccessTokenAction = value => ({
  type: SET_ACCESS_TOKEN,
  payload: value,
});

const fetchSigninSuccess = body => ({
  type: SET_SIGNIN_RESULT,
  payload: body,
});

const fetchSigninFailure = ex => ({
  type: SET_SIGNIN_RESULT,
  payload: ex,
});

export const signinAction = signinRequest => dispatch => {
  const apiUrl = API_BASE_URL + '/auth/token';

  dispatch(setSigninFetchingAction(true));
  return fetch(apiUrl, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(signinRequest),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(fetchSigninSuccess(body));
      dispatch(getCurrentUserAction());
    })
    .catch(ex => {
      dispatch(fetchSigninFailure(ex));
    });
};

export const logoutAction = () => dispatch => {
  const apiUrl = API_BASE_URL + '/auth/signout';

  return fetch(apiUrl, {
    method: 'POST',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(() => {
      localStorage.removeItem(ACCESS_TOKEN);
      dispatch(setAccessTokenAction(''));
      dispatch(initProgramsAction());
      dispatch(initCurrentUserAction());
    })
    .catch(() => {});
};
