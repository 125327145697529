import React, { useEffect, useState } from 'react';

const IssTabs = props => {
  const { children, tabMenus, currentTab, onChange, className } = props;

  const onSelectMenu = (selectedIdx, data) => {
    onChange(selectedIdx, data);
  };

  return (
    <>
      <div className={className}>
        <div className="ui-tab-menu">
          {tabMenus.map((tab, index) => (
            <div key={index} className={index === currentTab ? 'on' : ''} onClick={() => onSelectMenu(index, tab)}>
              <h2>{tab.name}</h2>
              {(tab && tab.isDisplay && tab.isDisplay === true && tab.tooltip) && (
              <div className="ui-tooltip">
                <i>툴팁</i>
                <div className="tooltip">{tab.tooltip}</div>
              </div>
              )}
            </div>
          ))}
        </div>
        {/* tab 영역 */}
        {children}
      </div>
    </>
  );
};

export default IssTabs;
