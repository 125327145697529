import React, { Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField, Checkbox, ListItemText } from '@material-ui/core';
import { getChannelMapListByCountryCode } from '../../../actions/notificationActions';
import { searchTbTests, getABTestTabCategoryAction } from '../../../actions/homeAppActions';

const mobilePlatformList = [
  { label: 'Android', value: 'Android' },
  { label: 'IOS', value: 'IOS' },
];

function ANServiceFilterNoTest({ getABTestTabCategoryAction, regionList, regionPlatformList, abTestTabCategory, searchTbTests, endTest, setEndTest, region, title, handleChangeTitle, handleChangeCountry, startDate,
  startTimeAnt,
  endDate,
  endTimeAnt,
  handleChangeStartDate,
  handleChangeStartTime,
  handleChangeEndDate,
  handleChangeEndTime,
  segment,
  segmentId,
  handleChangeSegment,
  segmentType,
  handleChangeSegmentType,
  dayOfWeek,
  handleDayOfWeekChange,
  handleChangeDeviceType,
  deviceType,
  handleChangePlatformVersion,
  platformVersion,
  handleChangeTab,
  handleChangeCategory,
  description }) {
  const monthAgoDate = new Date();
  monthAgoDate.setMonth(monthAgoDate.getMonth() - 1);

  const [abStatus, setAbStatus] = React.useState('All');
  const [segmentDescripion, setSegmentDescripion] = React.useState('');
  const [dType, setDType] = React.useState('All');
  const [allCountries, setAllCountries] = React.useState([]);
  const [cntryCode, setCntryCode] = React.useState('All');
  const [regionCode, setRegionCode] = React.useState('');
  const [pList, setPList] = React.useState([]);
  const [pVersion, setPVersion] = React.useState('All');
  const [catCode, setCatCode] = React.useState('All');
  const [tbCode, setTbCode] = React.useState('All');
  const today = new Date();
  const yestreday = new Date();
  const nextMonthDate = new Date();
  yestreday.setDate(today.getDate() - 1);
  nextMonthDate.setMonth(today.getMonth() + 1);
  const [startTimeLimit, setStartTimeLimit] = React.useState('');
  const hourFomatList = [
    { value: 0, label: '00:00' },
    { value: 1, label: '1:00' },
    { value: 2, label: '2:00' },
    { value: 3, label: '3:00' },
    { value: 4, label: '4:00' },
    { value: 5, label: '5:00' },
    { value: 6, label: '6:00' },
    { value: 7, label: '7:00' },
    { value: 8, label: '8:00' },
    { value: 9, label: '9:00' },
    { value: 10, label: '10:00' },
    { value: 11, label: '11:00' },
    { value: 12, label: '12:00' },
    { value: 13, label: '13:00' },
    { value: 14, label: '14:00' },
    { value: 15, label: '15:00' },
    { value: 16, label: '16:00' },
    { value: 17, label: '17:00' },
    { value: 18, label: '18:00' },
    { value: 19, label: '19:00' },
    { value: 20, label: '20:00' },
    { value: 21, label: '21:00' },
    { value: 22, label: '22:00' },
    { value: 23, label: '23:00' },
  ];
  const dayOfweekList = [
    'SUN',
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
  ];
  const segmentTypes = [
    { value: 'CMP', label: 'AdTS Segment' },
    { value: 'UPS', label: 'LG Segment' },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  function formateDate(x, y) {
    var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds()
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2)
    });

    return y.replace(/(y+)/g, function (v) {
      return x.getFullYear().toString().slice(-v.length)
    });
  }

  function startTimeChange(e) {
    handleChangeStartTime(e)
    setStartTimeLimit(e.target.value)
  }

  const handleSearchSubmit = () => {
    searchTbTests(title, startDate, endDate, tbCode, catCode, dType, regionCode, pVersion, abStatus);
  };

  React.useEffect(() => {
    handleSearchSubmit();
  }, []);

  React.useEffect(() => {
    if (dType !== '' && regionCode !== '' && pVersion !== '') {
      getABTestTabCategoryAction(dType, cntryCode === 'All' ? 'All' : regionCode, pVersion);
    }
  }, [dType, regionCode, pVersion]);

  React.useEffect(() => {
    let catCodeList = [];
    abTestTabCategory.map(n => {
      n.categoryDetails.map(n2 => {
        catCodeList.push(n2.categoryCode + ' ' + n2.categoryName);
      });
    });
    const uniqTabCode = [...new Map(abTestTabCategory.map(item => [item['tabCode'], item])).values()];
    setTbCode(uniqTabCode.map(r => r.tabCode).includes(tbCode) ? tbCode : 'All')
    if (tbCode == 'All') {
      finalData = [];
      abTestTabCategory.forEach(n => {
        if (n.categoryDetails) {
          finalData = [...finalData, ...n.categoryDetails];
        }
      });
      setCatCode(finalData.map(r => r.categoryCode).includes(catCode) ? catCode : 'All')
    } else {
      setCatCode((abTestTabCategory.find(n => n.tabCode == tbCode) || { categoryDetails: [] }).categoryDetails.map(r => r.categoryCode).includes(catCode) ? catCode : 'All')
    }
  }, [abTestTabCategory]);

  useEffect(() => {
    if (endTest) {
      endTest && searchTbTests(title, startDate, endDate, tbCode, catCode, dType, regionCode, pVersion, abStatus);
      setEndTest(false);
    }

    let cntry = [];
    regionList.map(region => {
      region.list.map(lst => {
        lst.cntry.map(cnt => {
          if (cntry.indexOf(cnt) === -1) {
            cntry = [...cntry, cnt];
          }
        });
      });
    });
    cntry = cntry.sort();
    setAllCountries([...cntry]);
  }, [endTest]);

  useEffect(() => {
    let regionCde = findRegion();
    let pVersion = [];
    setRegionCode(regionCde);
    if (dType === 'All' && cntryCode === 'All') {
      regionPlatformList.map(n2 => {
        if (!pVersion.includes(n2.platformVersion)) {
          pVersion.push(n2.platformVersion);
        }
      });
    } else if (dType === 'All' && cntryCode !== 'All') {
      regionPlatformList
        .filter(n => n.region === regionCde)
        .map(n2 => {
          if (!pVersion.includes(n2.platformVersion)) {
            pVersion.push(n2.platformVersion);
          }
        });
    } else if (dType !== 'All' && cntryCode === 'All') {
      regionPlatformList
        .filter(n => n.deviceType === dType)
        .map(n2 => {
          if (!pVersion.includes(n2.platformVersion)) {
            pVersion.push(n2.platformVersion);
          }
        });
    } else if (dType !== 'All' && cntryCode !== 'All') {
      regionPlatformList
        .filter(n => n.deviceType === dType && n.region === regionCde)
        .map(n2 => {
          if (!pVersion.includes(n2.platformVersion)) {
            pVersion.push(n2.platformVersion);
          }
        });
    }
    setPList([...pVersion]);
  }, [dType, cntryCode]);

  const handleChangeStatus = value => {
    setAbStatus(value);
  };

  const handleChangeDType = value => {
    setDType(value);
  };

  const handleChangeCntCode = value => {
    setCntryCode(value);
    setRegionCode('');
    handleChangeCountry(value)
  };

  const handleChangeCatCode = value => {
    setCatCode(value);
    handleChangeCategory(value);
  };

  const handleChangeTbCode = value => {
    setTbCode(value);
    handleChangeTab(value)
  };

  const handleChangePVersion = value => {
    setPVersion(value);
  };

  function findRegion() {
    let region;

    if (dType !== 'All') {
      regionList.map(n2 => {
        if (n2.deviceType === dType) {
          n2.list.map(n3 => {
            if (n3.cntry.includes(cntryCode)) {
              region = n3.region;
            }
          });
        }
      });
    } else {
      regionList.map(n2 => {
        n2.list.map(n3 => {
          if (n3.cntry.includes(cntryCode)) {
            region = n3.region;
          }
        });
      });
    }

    return region;
  }

  const handleChangeSegmentDesc = (e) => {
    segment.find(ele => ele.segmentId === e.target.value ? ele.description ? setSegmentDescripion(ele.description) : setSegmentDescripion('') : '');

    handleChangeSegment(e)
  };



  const uniqTabCode = [...new Map(abTestTabCategory.map(item => [item['tabCode'], item])).values()];
  let finalData = [];
  abTestTabCategory.forEach(n => {
    if (n.categoryDetails) {
      finalData = [...finalData, ...n.categoryDetails];
    }
  });
  const getUniqueCatByTbCode = useMemo(() => {
    let finalData = [];
    abTestTabCategory.forEach(n => {
      if (n.categoryDetails) {
        finalData = [...finalData, ...n.categoryDetails];
      }
    });
    const uniqcategoryDetails = [...new Map(finalData.map(item => [item['categoryCode'] + '' + item['categoryName'], item])).values()];
    return uniqcategoryDetails || [];
  }, [abTestTabCategory]);

  return (
    <Fragment>
      <div className="filter_area row">
        {/* <TextField fullWidth margin="dense" variant="outlined" label="Title" value={title} onChange={handleChangeTitle} /> */}
        <div className="filter_row">
          <TextField
            id="serviceTitle"
            name="serviceTitle"
            label="Service Title"
            placeholder="Service Title"
            variant="outlined"
            defaultValue={title}
            onBlur={handleChangeTitle}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            variant="outlined"
            name="startDate"
            id="startDate"
            label="Start Date"
            type="date"
            value={startDate ? startDate : ''}
            onChange={handleChangeStartDate}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: today.toLocaleDateString('en-CA') }}
          />

          <FormControl variant="outlined" className="filter_area">
            <InputLabel>Start Time</InputLabel>
            <Select margin="dense" variant="outlined" id="startTime" name="startTime" value={startTimeAnt} onChange={e => startTimeChange(e)} inputProps={{ min: yestreday.toISOString().slice(0, 10) }} required>
              {hourFomatList.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            name="endDate"
            id="endDate"
            label="End Date"
            type="date"
            value={endDate ? endDate : ''}
            onChange={handleChangeEndDate}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: startDate }}
          />

          <FormControl variant="outlined" className="filter_area">
            <InputLabel>End Time</InputLabel>
            <Select margin="dense" variant="outlined" label="End Time" id="endTime" name="endTime" value={endTimeAnt} onChange={handleChangeEndTime}>
              {hourFomatList.map(item => (
                <MenuItem key={item.value} value={item.value} disabled={startDate === endDate && startTimeLimit >= item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {abTestTabCategory && (
            <React.Fragment>
              <FormControl variant="outlined">
                <InputLabel>Device Type</InputLabel>
                <Select value={deviceType} onChange={handleChangeDeviceType}>
                  {regionList &&
                    regionList.map(n => (
                      <MenuItem key={n.deviceType} value={n.deviceType}>
                        {n.deviceType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </React.Fragment>
          )}

          {(
            <React.Fragment>
              <FormControl variant="outlined">
                <InputLabel>Country</InputLabel>
                <Select value={cntryCode} onChange={e => handleChangeCntCode(e.target.value)} disabled={!deviceType}>
                  {regionList &&
                    (regionList.find(n => n.deviceType === deviceType) || { list: [] }).list
                      .reduce((ac, a) => {
                        ac = [...ac, ...a.cntry];
                        return ac;
                      }, [])
                      .map(n2 => (
                        <MenuItem key={n2} value={n2}>
                          {n2}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </React.Fragment>
          )}
        </div>
        <div className="filter_row">
          {(
            <React.Fragment>
              <FormControl variant="outlined">
                <InputLabel /*id="Region"없어도 되는듯*/>platform version</InputLabel>
                <Select value={platformVersion} onChange={handleChangePlatformVersion} disabled={!cntryCode || cntryCode === 'All'}>
                  {pList &&
                    pList.map(n2 => (
                      <MenuItem key={n2} value={n2}>
                        {n2}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </React.Fragment>
          )}

          {abTestTabCategory && (
            <FormControl variant="outlined">
              <InputLabel>Tab</InputLabel>
              <Select name="channelMapList" id="search_channelMapList" value={uniqTabCode.map(r => r.tabCode).includes(tbCode) ? tbCode : 'All'} onChange={e => handleChangeTbCode(e.target.value)} disabled={!platformVersion}>
                {uniqTabCode.map((n, idx) => (
                  <MenuItem key={`${n.tabCode}_${idx}`} value={n.tabCode}>
                    {n.tabCode + ' ' + n.tabName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {abTestTabCategory && (
            <FormControl variant="outlined">
              <InputLabel>Category</InputLabel>
              <Select name="channelMapList" id="search_channelMapList" value={tbCode == 'All' ? finalData.map(r => r.categoryCode).includes(catCode) ? catCode : 'All' : (abTestTabCategory.find(n => n.tabCode == tbCode) || { categoryDetails: [] }).categoryDetails.map(r => r.categoryCode).includes(catCode) ? catCode : 'All'} onChange={e => handleChangeCatCode(e.target.value)} disabled={!tbCode || tbCode === 'All'}>
                {
                  (abTestTabCategory.find(n => n.tabCode == tbCode) || { categoryDetails: [] }).categoryDetails.map(n => (
                    <MenuItem key={n.categoryCode} value={n.categoryCode}>
                      {n.categoryCode + ' ' + n.categoryName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {
            <FormControl variant="outlined" className="filter_area">
              <InputLabel>Segment Type</InputLabel>
              <Select variant="outlined" id="segmentType" name="segmentType" label="segmentType" value={segmentType} onChange={handleChangeSegmentType} required disabled={!catCode || catCode === 'All'}>
                {segmentTypes.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          {
            <FormControl required variant="outlined">
              <InputLabel>Segment</InputLabel>
              <Select name="segmentId" id="segmentId" value={segmentId} onChange={e => { handleChangeSegmentDesc(e) }} disabled={!segmentType}>
                {segment.map(n => (
                  <MenuItem key={n.segmentId} value={n.segmentId}>
                    {n.segmentId}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }

        </div>
        <div className="filter_row">
          <TextField
            id="SegmentDesc"
            name="SegmentDesc"
            label="Segment Description"
            placeholder="Segment Description"
            variant="outlined"
            value={segmentDescripion}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <FormControl variant="outlined" style={{ margin: '-5px 0px 15px 29px' }}>
            <InputLabel id="dayOfWeek">Day of week  </InputLabel>
            <Select style={{ width: 220 }} variant="outlined" label="dayOfWeek" id="dayOfWeek" multiple value={dayOfWeek} onChange={handleDayOfWeekChange} renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {dayOfweekList.map((day) => (
                <MenuItem key={day} value={day}>
                  <Checkbox checked={dayOfWeek.indexOf(day) > -1} />
                  <ListItemText primary={day} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </Fragment>
  );
}
ANServiceFilterNoTest.propTypes = {
  regionList: PropTypes.array.isRequired,
  regionPlatformList: PropTypes.array.isRequired,
  regionCountries: PropTypes.array.isRequired,
  searchData: PropTypes.object.isRequired,
  handleSearchDataChange: PropTypes.func.isRequired,
  getChannelMapList: PropTypes.func.isRequired,
  channelMapList: PropTypes.array.isRequired,
  requiredField: PropTypes.bool.isRequired,
  abTestTabCategory: PropTypes.array.isRequired,
  searchTbTests: PropTypes.array.isRequired,
  endTest: PropTypes.bool.isRequired,
  abTestDetails: PropTypes.array.isRequired,
  getABTestTabCategoryAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  regionCountries: state.cntry.regionCountries,
  channelMapList: state.homeApp.notificationsChannelMapList,
  segment: state.homeApp.segment,
});

const mapDispatchToProps = dispatch => ({
  getABTestTabCategoryAction(deviceType, region, platformVersion) {
    dispatch(getABTestTabCategoryAction(deviceType, region, platformVersion));
  },
  getChannelMapList: countryCodes => dispatch(getChannelMapListByCountryCode(countryCodes)),
  searchTbTests: (title, startDate, endDate, tbCode, catCode, dType, regionCode, pVersion, abStatus) => dispatch(searchTbTests(title, startDate, endDate, tbCode, catCode, dType, regionCode, pVersion, abStatus)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ANServiceFilterNoTest);
