import { SET_WHITELISTIPS, SET_WHITELISTIPS_FETCHING } from './actionTypes';

import { API_BASE_URL, WHITELISTIP_LIST_SIZE } from '../constants';

import { getHeaders } from '../util/actionUtil';

const setWhitelistIpsFetchingAction = value => ({
  type: SET_WHITELISTIPS_FETCHING,
  payload: value,
});

const fetchGetWhitelistIpsSuccess = body => ({
  type: SET_WHITELISTIPS,
  payload: body,
});

const fetchGetWhitelistIpsFailure = ex => ({
  type: SET_WHITELISTIPS,
  payload: ex,
});

export const getWhitelistIpsAction = (page, size) => dispatch => {
  page = page || 0;
  size = size || WHITELISTIP_LIST_SIZE;
  const apiUrl = API_BASE_URL + '/whitelist-ips';

  dispatch(setWhitelistIpsFetchingAction(true));
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch(fetchGetWhitelistIpsSuccess(body)))
    .catch(ex => dispatch(fetchGetWhitelistIpsFailure(ex)));
};

export const getWhitelistIpsAdminAction = (page, size, providerId, ipAddr) => dispatch => {
  page = page || 0;
  size = size || WHITELISTIP_LIST_SIZE;
  providerId = providerId || '';
  ipAddr = ipAddr || '';
  const requestParams = `?providerId=${providerId}&ipAddr=${ipAddr}`;
  const apiUrl = API_BASE_URL + '/admin/whitelist-ips' + requestParams;

  dispatch(setWhitelistIpsFetchingAction(true));
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => dispatch(fetchGetWhitelistIpsSuccess(body)))
    .catch(ex => dispatch(fetchGetWhitelistIpsFailure(ex)));
};
