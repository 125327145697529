import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
const styles = theme => ({
  root: {
    maxWidth: 520,
    margin: '0 auto',
    marginTop: 40,
  },
  paper: {
    marginTop: theme.spacing && theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing && theme.spacing(2, 3, 3),
  },
  avatar: {
    margin: theme.spacing && theme.spacing(1),
    backgroundColor: theme.palette && theme.palette.secondary.main,
  },
});

export function NoDataMap({ show }) {
  if (!show) {
    return null;
  }

  return (
    <div className="no_data_wrap">
      <p className="nodata_txt">No records to display</p>
    </div>
  );
}

NoDataMap.propTypes = {
  contentName: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};

export default withStyles(styles)(NoDataMap);
