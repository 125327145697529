import * as actions from '../../actions/actionTypes';

const initialState = [
  {
    selectKey: 'ALL',
    selectValue: 'ALL',
  },
];

export default function feedlist(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ALL_COUNTRY:
      let def = [
        {
          selectKey: 'ALL',
          selectValue: 'ALL',
        },
      ];
      return action.payload.length ? def.concat(action.payload) : state;
    default:
      return state;
  }
}
