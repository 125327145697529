import React from 'react';
import { Line } from 'react-chartjs-2';
import { CHART_LINE_GRID_COLOR, CHART_COLORS, CHART_LINE_ANN_BASELINE, CHART_LINE_SUB_MAX_BASELINE, CHART_LINE_SUB_MIN_BASELINE } from './ChartConsts';

export const DEFAULT_LINE_WIDTH = '100%';
export const DEFAULT_LINE_HEIGHT = '100%';

const LineChart = props => {
  const { width, height, data, options, plugins, id } = props;

  const baselineAnnotation = options && options.baseline && options.baseline.display ? CHART_LINE_ANN_BASELINE(options.baseline.title, options.baseline.value) : '';
  const baselineSubMaxAnnotation = options && options.baselineSubMax && options.baselineSubMax.display ? CHART_LINE_SUB_MAX_BASELINE(options.baselineSubMax.title, options.baselineSubMax.value) : '';
  const baselineSubMinAnnotation = options && options.baselineSubMin && options.baselineSubMin.display ? CHART_LINE_SUB_MIN_BASELINE(options.baselineSubMin.title, options.baselineSubMin.value) : '';

  const options_base = {
    maintainAspectRatio: false,
    scales: {
      y: {
        border: {
          display: false,
        },
        grid: {
          color: CHART_LINE_GRID_COLOR.GRID_GRAY,
        },
        ...options.scales.y,
      },
      x: {
        grid: {
          color: CHART_LINE_GRID_COLOR.GRID_GRAY,
        },
        ...options.scales.x,
      },
    },
    plugins: {
      annotation: {
        annotations: {
          baseline1: {
            type: 'line',
            borderColor: CHART_COLORS.WHITE,
            borderWidth: 1,
            scaleID: 'y',
            value: options && options.scales && options.scales.y && options.scales.y.min,
          },
          baseline2: {
            type: 'line',
            borderColor: CHART_COLORS.WHITE,
            borderWidth: 1,
            scaleID: 'x',
            value: 0,
          },
          baseline3: baselineAnnotation,
          baseline4: baselineSubMaxAnnotation,
          baseline5: baselineSubMinAnnotation,
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  //text color : #C9C9C9
  //line color : #FFFFFF
  //x/y axis : #55565A
  const default_data = {
    ...data,
  };

  return (
    <>
      <Line options={options_base} plugins={options.plugins} data={default_data} style={{ width: width || DEFAULT_LINE_WIDTH, height: height || DEFAULT_LINE_HEIGHT }} />
    </>
  );
};

export default LineChart;
