import { API_BASE_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';
import { GET_HA_REGIONS } from './actionTypes';

export const getCntryAction = () => dispatch => {
  let apiUrl = API_BASE_URL + '/ric/countries';

  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: 'SET_CNTRY',
        payload: body,
      }),
    ) //include 400Error
    .catch(ex =>
      dispatch({
        type: 'SET_CNTRY_ERROR',
        payload: ex,
      }),
    );
};

export const getPlatformsAction = () => dispatch => {
  return fetch(`${API_BASE_URL}/platforms`, { method: 'GET', headers: getHeaders() })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: 'SET_PLATFORMS',
        payload: body,
      }),
    )
    .catch(ex =>
      dispatch({
        type: 'SET_PLATFORMS',
        payload: ex,
      }),
    );
};
export const getRegionCntryAction  = () => dispatch => {
  return fetch(`${API_BASE_URL}/region/countries`, { method: 'GET', headers: getHeaders() })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: GET_HA_REGIONS,
        payload: body,
      }),
    )
    .catch(ex =>
      dispatch({
        type: GET_HA_REGIONS,
        payload: ex,
      }),
    );
};