import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, Button, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, MenuItem, Select, Switch, Table, TableCell, TableContainer, TableRow, TextField, Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ContentTabPanel from './ContentTabPanel';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const platformCode = ['Mobile'];
const platformName = ['All', 'Android', 'IOS'];
const gmtarr = ['01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30',
'07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00',
'13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
'20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59'];

const useStyles = makeStyles(() => ({
  pushAlarmForm: {
    fontWeight: 'normal',
  },
  contentText: {
    verticalAlign: 'baseline',
  },
}));

function PushAlarmForm({ startDateYn, setStartDateYn, handleChangeStartDateYn, setStartTime,
  handleChangeStartTime, startTime, setStartDate, startDate, handleChangeStartDate, setChkBox, chkBox,
  title, allCountries, edit, data, handleInputChange, handleSubmit,
  handleClose, handleCheckBoxChange, handleAllCheckBoxChange, handleLangConentsChange,
  supportLanguages }) {

  const classes = useStyles();

  useEffect(() => {
    if (data.countryCode != null && data.countryCode != '') {
      let countryCode = data.countryCode.split(',');
      setChkBox([...chkBox, ...countryCode]);
    }

    if (data.startDate != null && data.startDate != '') {
      setStartDate(new Date(data.startDate));
      setStartTime(data.startDate.substring(10, 16).trim());
      setStartDateYn('Y');
      //console.log('[PushAlarmForm useEffect] startTime:', startTime);
    } else {
      setStartDate(new Date(startDate));
      {edit ? setStartDateYn('N') : setStartDateYn('Y')}
      //console.log('[PushAlarmForm useEffect] startDate:', startDate);
    }
  }, [])

  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        {edit ? 'Edit' : 'Add'} {title}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={'pop_body'}>
        <div>
          <TableContainer className={'tbl_wrap'}>
            <Table className={'tbl_row_typ1'}>
            <tbody>
                <TableRow width="100%">
                  <TableCell>
                    <label className="is_required">Platform</label>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Select name="platformCode" id="platformCode" style={{ minWidth: 120 }}
                      value={data.platformCode ? data.platformCode : ''}
                      onChange={event => handleInputChange(event)}>
                        {[...platformCode].map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <label className="is_required">OS</label>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Select name="platformName" id="platformName" style={{ minWidth: 120 }}
                      value={data.platformName !== 'Android,IOS' ? data.platformName : 'All'}
                      onChange={event => handleInputChange(event)}>
                        {[...platformName].map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow width="100%">
                  <TableCell>
                    <label className="is_required">Buisness Area</label>
                  </TableCell>
                  <TableCell colSpan={5}>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                              label= 'All'
                              control={
                                <Checkbox id= 'All' value='All'
                                checked={chkBox.length === allCountries.length ? true : false}
                                onChange={event => handleAllCheckBoxChange(event)} />
                              }
                          />
                        {[...allCountries].map(item => (
                          <FormControlLabel
                            key={item}
                            label={item}
                            control={
                            <Checkbox id= {item} value={item}
                            checked={chkBox.includes(item) ? true : false}
                            onChange={event => handleCheckBoxChange(event)} />
                            }
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow width="100%">
                  <TableCell>
                    <label className="is_required">Title</label>
                  </TableCell>
                  <TableCell colSpan={5}>
                    <TextField fullWidth name="title" margin="dense" variant="outlined" value={data.title}
                    onChange={event => handleInputChange(event)}/>
                  </TableCell>
                </TableRow>
                <TableRow width="100%">
                  <TableCell>
                    <label className="is_required">Language</label>
                  </TableCell>
                  <TableCell colSpan={5}>
                    <Select name="lang" id="lang" style={{ minWidth: 120 }} value={data.lang} onChange={event => handleInputChange(event)} multiple>
                      {[...supportLanguages].map(item => (
                        <MenuItem key={item.lang_code} value={item.lang_code}>
                          {item.lang_code}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow width="100%">
                  <TableCell width="10%">
                    <label className="is_required">Content</label>
                  </TableCell>
                  <TableCell width="90%" colSpan={5}>
                  <ContentTabPanel languages={data.lang}
                  langContentsData={data.multiLangList ? data.multiLangList : []}
                  handleContent={handleLangConentsChange} />
                  </TableCell>
                </TableRow>
                <TableRow width="100%">
                  <TableCell>
                    <label>Open Date</label>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.datePicker}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline"
                        label="Start date"
                        value={startDate}
                        onChange={handleChangeStartDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Select name="startTime" id="startTime" style={{ minWidth: 120 }}
                      value={startTime ? startTime : ''}
                      onChange={event => handleChangeStartTime(event)}>
                        {[...gmtarr].map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <FormGroup row>
                        <FormControlLabel control={<Switch name="enable" checked={startDateYn === 'Y'}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onChange={event => handleChangeStartDateYn(event)} />} label="enable" />
                    </FormGroup>
                  </TableCell>
                </TableRow>
              </tbody>
            </Table>
          </TableContainer>
        </div>
        <div className={'btn_set_wrap'}>
        <Button data-testid="addButton" color="primary" onClick={handleClose} className={'btn_color1'}>
            Cancel
          </Button>
          <Button data-testid="addButton" color="primary" onClick={(event) => handleSubmit(event, 'Draft')} className={'btn_color2'}>
            Draft
          </Button>
          <Button data-testid="addButton" color="primary" onClick={(event) => handleSubmit(event, 'Publish')} className={'btn_color4'}>
            Publish
          </Button>
        </div>
      </DialogContent>
    </Fragment>
  );
}

PushAlarmForm.propTypes = {
  title: PropTypes.string.isRequired,
  edit: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCheckBoxChange: PropTypes.func.isRequired,
  handleAllCheckBoxChange: PropTypes.func.isRequired,
  handleLangConentsChange: PropTypes.func.isRequired,
  supportLanguages: PropTypes.array.isRequired,
  handleChangeStartDate: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  supportLanguages: state.web.supportLanguages,
});
export default connect(mapStateToProps)(PushAlarmForm);