export const comment = {
  activation: 'count of devices that enable LG Channels service',
  deactivation: 'count of devices that disable LG Channels service',
  accumActivation: 'accumulated activation count',
  uniqueDevice: 'unique device count',
  totalDuration: 'sum aggregation of all duration',
  access: 'unique count of channelSessionId (total channel change count)',
  uniqueSession: 'unique count of systemSessionId (total cold booting count)',
  durationPerUd: 'Total Duration (hr) / Unique Device',
  accessPerUd: 'Access / Unique Device',
  programPlayCount: 'Number of channel changes',
  programPlayCountPerUd: 'Number of channel changes / Unique Device',
  assetDuration: 'Average program time',
  avgPlayTime: 'Hours used per program change',
  viewThroughRate: 'Percentage of actual viewing during program time',
  channelPlayCount: 'Number of channel content plays in the home app',
  vodPlayCount: 'Number of vod content plays in the home app'
};
