import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { DialogContentText } from '@material-ui/core';

export const DEFAULT_DOUGHNUT_WIDTH = '130px';
export const DEFAULT_DOUGHNUT_HEIGHT = '130px';

function DoughnutChart({ width, height, className, data, onSelect, onSelectedId }) {
  const [isFlag, setFlag] = useState(false);
  const [layoutParam, setLayoutParam] = useState({
    width: width || DEFAULT_DOUGHNUT_WIDTH,
    height: height || DEFAULT_DOUGHNUT_HEIGHT,
  });

  const optionsPlugin = data && data.options && data.options.plugins ? { ...data.options.plugins } : {};
  const options = {
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return (Array.isArray(context.dataset.label) ? context.dataset.label[context.dataIndex] : context.dataset.label) + ' : ' + context.parsed;
          }
        },
      },
      optionsPlugin,
    },
  };

  if (onSelect) {
    if (onSelectedId !== undefined && onSelectedId !== '') {
      if (onSelectedId !== data.id) {
        data.datasets[0].backgroundColor = data.datasets[0].unSelBackgroundColor;
      } else {
        data.datasets[0].backgroundColor = data.datasets[0].selBackgroundColor;
      }
    }
  }
  const onSelectCircleChart = (e) => {
    if (onSelect) {
      e.stopPropagation();
      onSelect(data.id, isFlag);
    }
  };

  return (
    <>
      <div onClick={onSelectCircleChart} style={{ width: layoutParam.width, height: layoutParam.height }} className={`chart-type-donut ${className}`}>
        <Doughnut data={data} options={options} width={layoutParam.width} height={layoutParam.height}/>
        <div className="label">{data.title}</div>
        <div className="num">{data.sum}</div>
      </div>
    </>
  );
}

DoughnutChart.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectedId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default DoughnutChart;
