import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, Dialog, Button, Checkbox, DialogContent, DialogTitle, TableContainer, Table, TableCell, TableHead } from '@material-ui/core';
import MaterialTable from 'material-table';
import { FormControl, Select, MenuItem, Chip, createMuiTheme, InputLabel, TextField } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getChannelMapInfoDataHistory, getChannelMapInfoData, setHistoryChannelMapIdList, retrieveCpList, getChannelMapInfoLevelTwoData } from '../../../actions/channelMappingActions';
import { hideLoader, showLoader } from '../../../actions/loaderActions';
import LoadingIndicator from '../../../common/LoadingIndicator';

import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { Pagination } from "@material-ui/lab";



function Row({ row, hchannelMapId, hdonwloadType, system, hstartDate, hendDate, ric, cntry, pointOfTime, changeOnly, cp, channelName, modeType }) {
  const [open, setOpen] = React.useState(false);

  const expandForLeve2 = (row, hchannelMapId, hdonwloadType, system, hstartDate, hendDate, ric, cntry, pointOfTime, changeOnly, cp, channelName, modeType) => {
    let rType = '';
    if (row.type === 'Save For Test') {
      rType = 'S-T';
    } else if (row.type === 'Save For Publish') {
      rType = 'S-P';
    } else if (row.type === 'Testing') {
      rType = 'T';
    } else if (row.type === 'Publish') {
      rType = 'P';
    }
    if (open) {
      setOpen(false)
    } else if (row.channelList.length > 0) {
      setOpen(!open)
    }
    else {
      getChannelMapInfoLevelTwoData(hchannelMapId, hdonwloadType, system, hstartDate, hendDate, ric, cntry, pointOfTime, changeOnly, cp, channelName, modeType, row.date, row.country, row.chMap, rType).then(response => {
        response.list.map(data => (
          row.channelList.push(data)
        ))
        setOpen(!open)
      });
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => expandForLeve2(row, hchannelMapId, hdonwloadType, system, hstartDate, hendDate, ric, cntry, pointOfTime, changeOnly, cp, channelName, modeType)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.date}
        </TableCell>
        <TableCell>{row.country}</TableCell>
        <TableCell>{row.chMap}</TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{row.modifier}</TableCell>
      </TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={9}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Table style={{ marginLeft: '20px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Channel Number</TableCell>
                  <TableCell>Channel ID</TableCell>
                  <TableCell>Channel Name</TableCell>
                  <TableCell>Genre</TableCell>
                  <TableCell>CP Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Modifier</TableCell>
                  <TableCell>Change Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.channelList.map(channelRow => (
                  <TableRow key={channelRow.chNo} className={channelRow.status === 'Deleted' ? 'history_delete_color' : channelRow.status === 'Created' ? 'history_create_color': channelRow.status === 'Updated' ? 'history_update_color' : ''}>
                    <TableCell>{channelRow.status === 'Updated' && channelRow.prevChNo ? channelRow.prevChNo +'→'+ channelRow.chNo : channelRow.chNo}</TableCell>
                    <TableCell>{channelRow.chId}</TableCell>
                    <TableCell>{channelRow.status === 'Updated' && channelRow.prevChName ? channelRow.prevChName +'→'+ channelRow.chName : channelRow.chName}</TableCell>
                    <TableCell>{channelRow.genre}</TableCell>
                    <TableCell>{channelRow.cpName}</TableCell>
                    <TableCell>{channelRow.status}</TableCell>
                    <TableCell>{channelRow.modifier}</TableCell>
                    <TableCell>{channelRow.chDate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
}

function RowChannel({ row, hchannelMapId, hdonwloadType, system, hstartDate, hendDate, ric, cntry, pointOfTime, changeOnly, cp, channelName, modeType }) {
  const [open, setOpen] = React.useState(false);

  const expandForLeve2 = (row, hchannelMapId, hdonwloadType, system, hstartDate, hendDate, ric, cntry, pointOfTime, changeOnly, cp, channelName, modeType) => {
    let rType = '';
    if (row.type === 'Save For Test') {
      rType = 'S-T';
    } else if (row.type === 'Save For Publish') {
      rType = 'S-P';
    } else if (row.type === 'Testing') {
      rType = 'T';
    } else if (row.type === 'Publish') {
      rType = 'P';
    }
    if (open) {
      setOpen(false)
    } else if (row.channelList.length > 0) {
      setOpen(!open)
    } else {
      getChannelMapInfoLevelTwoData(hchannelMapId, hdonwloadType, system, hstartDate, hendDate, ric, cntry, pointOfTime, changeOnly, cp, channelName, modeType, row.cpName, row.chId, row.chName, row.genre).then(response => {
        response.list.map(data => (
          row.channelList.push(data)
        ))
        setOpen(!open)
      });
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => expandForLeve2(row, hchannelMapId, hdonwloadType, system, hstartDate, hendDate, ric, cntry, pointOfTime, changeOnly, cp, channelName, modeType)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.cpName}
        </TableCell>
        <TableCell>{row.chId}</TableCell>
        <TableCell>{row.origlChName}</TableCell>
        <TableCell>{row.genre}</TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={9}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Table style={{ marginLeft: '20px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>ChangeDate</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Channel Map</TableCell>
                  <TableCell>Channel No</TableCell>
                  <TableCell>Channel Name</TableCell>
                  <TableCell>DMA Group</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Modifier</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.channelList.map(channelRow => (
                  <TableRow key={channelRow.chNo}  className={channelRow.status === 'Deleted' ? 'history_delete_color' : channelRow.status === 'Created' ? 'history_create_color': channelRow.status === 'Updated' ? 'history_update_color' : ''}>
                    <TableCell>{channelRow.chDate}</TableCell>
                    <TableCell>{channelRow.country}</TableCell>
                    <TableCell>{channelRow.chMap}</TableCell>
                    <TableCell>{channelRow.status === 'Updated' && channelRow.prevChNo ? channelRow.prevChNo +'→'+ channelRow.chNo : channelRow.chNo}</TableCell>
                    <TableCell>{channelRow.status === 'Updated' && channelRow.prevChName ? channelRow.prevChName +'→'+ channelRow.chName : channelRow.chName}</TableCell>
                    <TableCell>{channelRow.dmaGrp}</TableCell>
                    <TableCell>{channelRow.status}</TableCell>
                    <TableCell>{channelRow.modifier}</TableCell>
                    <TableCell>{channelRow.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    // minWidth: 50
  }
}));

function DownloadHistoryDialog({ handleClose, system, ric, histConfMap, cntryInfo, setHistoryChannelMapIdList, hcntry, channelMapId, showLoaderStatus, hideLoaderStatus }) {
  const [channelDetails, setChannelInfo] = React.useState([]);
  const [hdonwloadType, setHdownloadType] = React.useState('ALL');
  const [hchannelMapId, setHchannelMapId] = React.useState(channelMapId ? channelMapId : 'ALL');
  const [pointOfTime, setPointOfTime] = React.useState(false);
  const [cntry, setCntry] = useState(hcntry);
  const today = new Date();
  const monthAgoDate = new Date();
  monthAgoDate.setMonth(monthAgoDate.getMonth() - 1);
  const [hstartDate, setHstartDate] = React.useState(monthAgoDate.toISOString().slice(0, 10));
  const [hendDate, setHendDate] = React.useState(today.toISOString().slice(0, 10));
  const [changeOnly, setChangeOnly] = React.useState(false);
  const [modeType, setModeType] = React.useState('CHM');
  const [cpList, setCpList] = React.useState([]);
  const [cp, setCp] = React.useState('ALL');
  const [channelName, setChannelName] = React.useState('');
  const [dataLoaded, setDataLoaded] = React.useState(false);

  const classes = useStyles();

  const [dataPage, setDataPage] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setPage(0);
  }, [dataPage]);

  const onDataPageChange = (event, page) => setDataPage(page - 1);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const publishTypeListCDP = [
    { label: 'Save For Test', value: 'S-T' },
    { label: 'Save For Publish', value: 'S-P' },
    { label: 'Testing', value: 'T' },
    { label: 'Publish', value: 'P' },
  ];

  const publishTypeListSDP = [
    { label: 'Save', value: 'S' },
    { label: 'Publish', value: 'P' },
  ];

  const modes = [
    { label: 'Channel map', value: 'CHM' },
    { label: 'Channel', value: 'CH' },
  ];

  const historyChange = value => {
    setHdownloadType(value);
  };

  const channelMapChange = value => {
    setHchannelMapId(value);
  };

  const historyStartDateChange = value => {
    setHstartDate(value);
    setHendDate('');
  };

  const historyEndDateChange = value => {
    setHendDate(value);
  };

  const onChangePontOfTime = value => {
    if (value) {
      setHstartDate();
      setHendDate(today.toISOString().slice(0, 10));
    } else {
      setHstartDate(monthAgoDate.toISOString().slice(0, 10));
      setHendDate(today.toISOString().slice(0, 10));
    }
    setPointOfTime(value);
  };

  const onChangeOnly = value => {
    setChangeOnly(value);
  };

  const countryChange = value => {
    setCntry(value);
    setHchannelMapId('ALL');
    setHdownloadType('ALL');
  };

  const modeChange = value => {
    setModeType(value);
    setCp('ALL');
    setChannelName('');
    setChannelInfo([]);
  };

  const cpChange = value => {
    setCp(value);
  };

  const channelNameChange = value => {
    setChannelName(value);
  };

  useEffect(() => {
    setHistoryChannelMapIdList(system, cntry, ric);
    retrieveCpList(cntry).then(response => {
      setCpList(response.list);
    });
  }, [system, cntry, ric]);

  return (
    <div>
      <Dialog open={true} aria-labelledby="form-dialog-title" className={'pop_wrap history_pop'}>
        <div className="MuiDialogTitle-root pop_head" id="form-dialog-title">
          <h2 className="MuiTypography-root MuiTypography-h6">
            Download History
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </h2>
        </div>
        <DialogContent className={'pop_body'}>
          <div className="mapping_tbl_header">
            <FormControl variant="outlined">
              <InputLabel>Mode</InputLabel>
              <Select label="Mode" style={{ width: '150px' }} value={modeType} onChange={event => modeChange(event.target.value)}>
                {
                  modes.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel style={{ marginLeft: '10px' }}>Country</InputLabel>
              <Select label="Country" value={cntry} style={{ width: '100px', marginLeft: '10px' }} onChange={e => countryChange(e.target.value)}>
                {cntryInfo && (
                  <MenuItem key="ALL" value="ALL">
                    ALL
                  </MenuItem>
                )}
                {cntryInfo.map(v => (
                  <MenuItem key={v.cntry} value={v.cntry}>
                    {v.cntry}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel style={{ width: '100px', marginLeft: '10px' }}>Channel map</InputLabel>
              <Select label="Channel map" style={{ width: '215px', marginLeft: '10px' }} value={hchannelMapId ? hchannelMapId : ''} onChange={e => channelMapChange(e.target.value)}>
                {histConfMap && (
                  <MenuItem key="ALL" value="ALL">
                    ALL
                  </MenuItem>
                )}
                {histConfMap.mapList.map(item => (
                  <MenuItem key={item.channelMapId} value={item.channelMapId}>
                    {item.channelMapId}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel style={{ width: '100px', marginLeft: '10px' }}>Type</InputLabel>
              <Select label="Type" style={{ width: '170px', marginLeft: '10px' }} value={hdonwloadType} onChange={event => historyChange(event.target.value)}>
                <MenuItem key="ALL" value="ALL">
                  ALL
                </MenuItem>
                {
                  publishTypeListCDP.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <label style={{ marginLeft: '10px' }}>
              Point Of Time
              <Checkbox id="pointOfTime" name="pointOfTime" onChange={event => onChangePontOfTime(event.target.checked)} />
            </label>
            <label style={{ marginLeft: '10px' }}>
              Show Change Only
              <Checkbox id="changeOnly" name="changeOnly" onChange={event => onChangeOnly(event.target.checked)} />
            </label>
            <TextField
              variant="outlined"
              id="startDate"
              name="startDate"
              label="Start Date"
              type="date"
              value={hstartDate ? hstartDate : ''}
              onChange={event => historyStartDateChange(event.target.value)}
              InputLabelProps={{
                shrink: true,
                required: true,
              }}
              inputProps={{ max: today.toISOString().slice(0, 10) }}
              required
              disabled={pointOfTime}
              style={{ marginLeft: '10px' }}
            />
            <TextField
              style={{ marginLeft: '10px' }}
              variant="outlined"
              id="endDate"
              name="endDate"
              label="End Date"
              type="date"
              value={hendDate ? hendDate : ''}
              onChange={event => historyEndDateChange(event.target.value)}
              InputLabelProps={{
                shrink: true,
                required: true,
              }}
              inputProps={pointOfTime ? { max: today.toISOString().slice(0, 10) } : { min: hstartDate, max: today.toISOString().slice(0, 10) }}
              required
              disabled={!pointOfTime && !hstartDate}
            />
            <FormControl variant="outlined">
              {modeType === 'CH' && <InputLabel style={{ width: '100px', marginLeft: '10px' }}>CP</InputLabel>}
              {modeType === 'CH' && (
                <Select label="CP" style={{ width: '100px', marginLeft: '10px' }} value={cp} onChange={event => cpChange(event.target.value)}>
                  {cpList && (
                    <MenuItem key="ALL" value="ALL">
                      ALL
                    </MenuItem>
                  )}

                  {cpList &&
                    cpList.map(n => (
                      <MenuItem key={n} value={n}>
                        {n}
                      </MenuItem>
                    ))}
                </Select>
              )}
            </FormControl>

            {modeType === 'CH' &&
              <TextField
                style={{ marginLeft: '10px' }}
                id="channelName"
                name="channelName"
                label="Channel Name"
                placeholder="Channel Name"
                variant="outlined"
                value={channelName}
                onChange={event => channelNameChange(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
              />}
            <FormControl variant="outlined">
              <Button disabled={dataLoaded}
                style={{ marginLeft: modeType === 'CHM' ? '335px' : '10px', padding: '0 15px', lineHeight: '34px' }}
                className="btn_color2"
                type="submit"
                onClick={() => {
                  // ==== add Popup
                  if (!hchannelMapId) {
                    window.alert('Please select the chanelmap!');
                    return;
                  }
                  // setHdownloadType(hdonwloadType);
                  if (!hdonwloadType) {
                    window.alert('Please select the published type!');
                    return;
                  }
                  if (!pointOfTime && !hstartDate) {
                    window.alert('Please select history start date!');
                    return;
                  }
                  if (!hendDate) {
                    if (pointOfTime) {
                      window.alert('Please select pointOf end date!');
                    } else {
                      window.alert('Please select history end date!');
                    }
                    return;
                  }
                  setChannelInfo([]);
                  setDataLoaded(true);
                  getChannelMapInfoData(hchannelMapId, hdonwloadType, system, hstartDate, hendDate, ric, cntry, pointOfTime, changeOnly, cp, channelName, modeType).then(response => {
                    setChannelInfo(response.list);
                    setDataLoaded(false);
                  });
                  setPage(0);
                }}>
                Search
              </Button>
            </FormControl>
            <FormControl variant="outlined">
              <Button
                style={{ marginLeft: '13px', padding: '0 15px', lineHeight: '34px' }}
                type="submit"
                color="primary"
                className={'btn_color2'}
                onClick={() => {
                  setDataLoaded(true);
                  getChannelMapInfoDataHistory(hchannelMapId, hdonwloadType, system, hstartDate, hendDate, ric, cntry, pointOfTime, changeOnly, cp, channelName, modeType).then(response => {
                    setDataLoaded(false);
                  });
                }}>
                Download
              </Button>
            </FormControl>
          </div>
          {dataLoaded && (
            <div className="loading_wrap" style={{ position: 'absolute', top: 300, left: 0, right: 0, zIndex: 999 }}>
              <LoadingIndicator />
            </div>
          )}
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <TableContainer component={Paper} className={'history_table_data'}>
                {modeType === 'CHM' && channelDetails.length > 0 && (
                  <Table className={classes.table} aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Channel Map Change Date</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>Channel Map</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Last Modifier</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {channelDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                        <Row key={row.name} row={row} hchannelMapId={hchannelMapId} hdonwloadType={hdonwloadType} system={system} hstartDate={hstartDate} hendDate={hendDate} ric={ric} cntry={cntry} pointOfTime={pointOfTime} changeOnly={changeOnly} cp={cp} channelName={channelName} modeType={modeType} />
                      ))}
                    </TableBody>

                  </Table>
                )}

                {modeType === 'CH' && channelDetails.length > 0 && (
                  <Table className={classes.table} aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>CP Name</TableCell>
                        <TableCell>Channel ID</TableCell>
                        <TableCell>Channel Name</TableCell>
                        <TableCell>Genre</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {channelDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                        <RowChannel key={row.name} row={row} hchannelMapId={hchannelMapId} hdonwloadType={hdonwloadType} system={system} hstartDate={hstartDate} hendDate={hendDate} ric={ric} cntry={cntry} pointOfTime={pointOfTime} changeOnly={changeOnly} cp={cp} channelName={channelName} modeType={modeType} />
                      ))}
                    </TableBody>
                  </Table>
                )}

              </TableContainer>
            </Paper>

          </div>

          <div className={'btn_set_wrap'}>
            <TableFooter>
              <TableRow>

                <TablePagination
                  rowsPerPageOptions={[10, 20]}
                  component="div"
                  count={channelDetails.length}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={'Rows'}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>

            </TableFooter>
            <Button color="primary" onClick={handleClose} className={'btn_color1'}>
              Close
            </Button>
          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
}

DownloadHistoryDialog.propTypes = {
  channelMapId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  system: PropTypes.string.isRequired,
  ric: PropTypes.string.isRequired,
  cntry: PropTypes.string.isRequired,
  cntryInfo: PropTypes.array,
  setHistoryChannelMapIdList: PropTypes.func.isRequired,
  hcntry: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  histConfMap: state.channelMapping.histConfMap,
});

const mapDispatchToProps = dispatch => ({
  setHistoryChannelMapIdList: (system, cntry, ric) => dispatch(setHistoryChannelMapIdList(system, cntry, ric)),
  showLoaderStatus: () => dispatch(showLoader()),
  hideLoaderStatus: () => dispatch(hideLoader()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DownloadHistoryDialog);