////////////////////////////////////////////////////////////////////////////////
// import
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// import basic
////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from 'react'; // React
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; // connect
import { withRouter } from 'react-router-dom'; // withRouter
////////////////////////////////////////////////////////////////////////////////
// import material
////////////////////////////////////////////////////////////////////////////////
import { Button, Box, TablePagination } from '@material-ui/core';
import MaterialTable from 'material-table';
import RssFeedFilter from '../../../../common/filter/RssFeedFilter';
import { Link } from 'react-router-dom';
import { getRssFeedList, getRssFeedDetail } from '../../../../actions/rssFeedActions';
////////////////////////////////////////////////////////////////////////////////
// RSSFeed
////////////////////////////////////////////////////////////////////////////////
function RSSFeed({ feedlist, page, size, getRssFeedList, history, totalElements }) {
  useEffect(() => {
    getRssFeedList({ cntryCode: '', cpName: '', page: 0, size: 10 });
  }, [getRssFeedList]);
  const [country, setCntry] = useState({ selectKey: 'ALL', selectValue: 'ALL' });
  const [cp, setCpName] = useState('');
  //
  const onChangRowPerPage = pageSize => {
    let countryCode = country.selectKey === 'ALL' ? '' : country.selectKey;
    getRssFeedList({ cntryCode: countryCode, cpName: cp, page: page, size: parseInt(pageSize) });
  };
  const onChangePage = pageNo => {
    let countryCode = country.selectKey === 'ALL' ? '' : country.selectKey;
    getRssFeedList({ cntryCode: countryCode, cpName: cp, page: pageNo, size: size });
  };
  const handleCpName = value => setCpName(value);
  const callFeedList = () => {
    let countryCode = country.selectValue === 'ALL' ? '' : country.selectValue;
    getRssFeedList({
      cntryCode: countryCode,
      page: 0,
      size: 10,
      cpName: cp,
    });
  };
  const settingFeedIdx = rowData => {
    return (
      <Link className="link" to={`/PROMOTION/homeShelf/rssFeed/detail/${rowData.feedIdx}`}>
        {rowData.feedIdx}
      </Link>
    );
  };
  const settingCntry = rowData => {
    return (
      <div style={{ width: 100, whiteSpace: 'nowrap' }}>
        <Box component="div" overflow="hidden" textOverflow="ellipsis">
          {rowData.cntryCode}
        </Box>
      </div>
    );
  };
  const settingPage = props => <TablePagination {...props} count={totalElements} page={page} rowsPerPage={size} onChangePage={(e, page) => onChangePage(page)} />;
  //
  let columns = [
    {
      title: 'No.',
      field: 'feedIdx',
      width: '13%',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      render: rowData => settingFeedIdx(rowData),
    },
    {
      title: 'Country',
      field: 'cntryCode',
      width: '13%',
      render: rowData => settingCntry(rowData),
    },
    {
      title: 'CP Name',
      field: 'cpName',
      width: '13%',
    },
    {
      title: 'RSS',
      field: 'rssPath',
      width: 'auto',
    },
    {
      title: 'Last Updated',
      field: 'lastChgDate',
      width: '13%',
    },
  ];
  return (
    <div>
      <React.Fragment>
        <RssFeedFilter country={country} cp={cp} handleCntry={setCntry} handleCpName={handleCpName} getRssFeedList={callFeedList} />
        <div className="tbl_wrap">
          <div className={!feedlist.length ? 'no_data_top btn_set_wrap tbl_top1' : 'btn_set_wrap tbl_top1'}>
            <Button
              color="secondary"
              variant="contained"
              className={'btn_color2'}
              onClick={() => {
                history.push('/PROMOTION/homeShelf/rssFeed/new');
              }}>
              Add
            </Button>
          </div>
          <MaterialTable
            title=""
            columns={columns}
            data={feedlist}
            options={{
              search: false,
              paging: true,
              pageSize: size,
              pageSizeOptions: [5, 10, 20],
              rowStyle: { backgroundColor: '#fff' },
            }}
            components={{
              Pagination: props => settingPage(props),
            }}
            onChangeRowsPerPage={size => onChangRowPerPage(size)}
          />
        </div>
      </React.Fragment>
    </div>
  );
}
////////////////////////////////////////////////////////////////////////////////
// RSSFeed.propTypes
////////////////////////////////////////////////////////////////////////////////
RSSFeed.propTypes = {
  feedlist: PropTypes.array,
  history: PropTypes.object,
  page: PropTypes.number,
  size: PropTypes.number,
  getRssFeedList: PropTypes.func,
  totalElements: PropTypes.number,
};
////////////////////////////////////////////////////////////////////////////////
// mapStateToProps
////////////////////////////////////////////////////////////////////////////////
const mapStateToProps = state => ({
  feedlist: state.rssFeed.feedlist,
  page: state.rssFeed.page,
  size: state.rssFeed.size,
  totalElements: state.rssFeed.totalElements,
  totalPages: state.rssFeed.totalPages,
});
////////////////////////////////////////////////////////////////////////////////
// mapDispatchToProps
////////////////////////////////////////////////////////////////////////////////
const mapDispatchToProps = dispatch => ({
  getRssFeedList: param => dispatch(getRssFeedList(param)),
  getRssFeedDetail: feedIdx => dispatch(getRssFeedDetail(feedIdx)),
});
////////////////////////////////////////////////////////////////////////////////
// export
////////////////////////////////////////////////////////////////////////////////
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RSSFeed));
