import React, { useEffect, useState, useRef } from 'react';
import Loading from '../../../common/Loading';
import { Line } from 'react-chartjs-2';
import Alert from '../../../modal/Alert';
import { getHeaders } from '../../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../../constants/index';
import { service_top_trend_data, CHART_COLORS_BY_CONTENT } from './ServiceTrendData';

function ServiceTopTrendComponent(props) {
  const { height, title, currentFilter, updateTable, id } = props;
  const [trendData, setTrendData] = useState({data: {}, options: {}});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [isTrendDisplay, setTrendDisplay] = useState(false);
  const [displayCompareType, setDisplayCompareType] = useState('None');
  const chartRef = useRef(null);

  useEffect(() => {
    if (currentFilter) {
      console.log(`[ISS] ServiceComponent, currentFilter: `, currentFilter);
      setDisplayCompareType(currentFilter.compareType ? currentFilter.compareType : 'None');
      getTrendBasic(currentFilter);
    }
  }, [currentFilter]);

  const getTrendBasic = (filter) => {
    let sourceFilter = '';
    let displayFilter = '';
    filter.customCheckboxFilter && filter.customCheckboxFilter.map((item, index) => {
      const [_, value] = Object.entries(item)[0];
      sourceFilter = value.join(',');
    });
    filter.customRadioFilter && filter.customRadioFilter.map((item, index) => {
      const [_, value] = Object.entries(item)[0];
      displayFilter = value;
    })

    const issueType = title.includes('App')? 'app' : 'play';
    const customInputFilter = filter.contentName ? filter.searchType + '=' + filter.contentName : '';
    const intervalType = filter.interval ? filter.interval : 'd';
    const endDate = currentFilter.date[1];
    const customDate = currentFilter.date[0] + "," + endDate;
    let param = `dates=${customDate}&intervalType=${intervalType}`;
    if (filter.country) param += `&countries=${filter.country}`;
    if (filter.platforms) param += `&platforms=${filter.platforms}`;
    if (filter.contentName) param += `&${customInputFilter}`;
    if (sourceFilter) param += `&source=${sourceFilter}`;
    if (filter.providers) param += `&providers=${filter.providers}`;
    if (displayFilter) param += `&display=${displayFilter}`;
    if (issueType) param += `&issueType=${issueType}`;

    fetch(`${ISS_BASE_URL}/api/service/trend/top?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          const data = {
            labels: [],
            datasets: [],
          };
          const options = {
            scales: {
              y: {
                min: 0,
                max: 0,
                ticks: {
                  stepSize: 10,
                },
              },
              x: {
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                labels: {
                  usePointStyle: true,
                  boxWidth: 6,
                  boxHeight: 6,
                  color: '#fff',
                  font: {
                    size: 12,
                  },
                  generateLabels: (chart) => {
                    const originalLables = chart.data.datasets.map(dataset => dataset.label);
                    return originalLables.map((label, index) => {
                      if (label.length > 5) {
                        return {
                          text: `${label.slice(0, 10)}...`,
                          fillStyle: chart.data.datasets[index].borderColor,
                          strokeStyle: chart.data.datasets[index].borderColor,
                          pointStyle: chart.data.datasets[index].pointStyle,
                        }
                      } else {
                        return {
                          text: label,
                          fillStyle: chart.data.datasets[index].borderColor,
                          strokeStyle: chart.data.datasets[index].borderColor,
                          pointStyle: chart.data.datasets[index].pointStyle,
                        }
                      }
                    });
                  },
                },
                onHover: (event, legendItem) => {
                  const contentId = document.getElementById(`contentId_${id}`);
                  const chart = chartRef.current;
                  if (!chart) return;

                  const legendItems = chart.legend.legendItems;
                  const legendItemIndex = legendItems.indexOf(legendItem);
                  const legendBox = chart.legend.legendHitBoxes[legendItemIndex];

                  const contentIdX = legendBox.left + (legendBox.width / 2);
                  const contentIdY = legendBox.top;

                  contentId.style.display = 'block';
                  contentId.style.left = `${contentIdX + 10}px`;
                  contentId.style.top = `${contentIdY + 50}px`;
                  contentId.innerHTML = chart.data.datasets[legendItemIndex].label;
                },
                onLeave: () => {
                  const tooltip = document.getElementById(`contentId_${id}`);
                  tooltip.style.display = 'none';
                },
              },
              datalabels: {
                display: false,
              },
            },
          };
          let contentRows = []; // update table
          setTrendData({data: {}, options: options});
          let dateLabel = [];
          if (body.dataSet.display) {
            dateLabel = body.dataSet.display.map(item => item.date);
            let keyList = {};
            body.dataSet.display.map(item => {
              let ySumCount = 0;
              Object.keys(item).map(k => {
                if (k.length > 0 && k != 'date') {
                  ySumCount = Math.max(ySumCount, item[k] + 5);
                  if (k in keyList === false) {
                    keyList[k] = [];
                  }
                }
              });
              options.scales.y.max = Math.max(options.scales.y.max, ySumCount);
            });
            body.dataSet.display.map(item => {
              const keys = Object.keys(item);
              Object.keys(keyList).map(k => {
                if (keys.includes(k)) {
                  keyList[k].push(item[k]);
                } else {
                  keyList[k].push(0);
                }
              });
            });
            Object.keys(keyList).forEach((key, index) => {
              let temp = {
                label: key,
                data: keyList[key],
                fill: false,
                pointBorderColor: '#FFFFFF',
                borderColor: hexToRgba(CHART_COLORS_BY_CONTENT[index], 1),
                backgroundColor: hexToRgba(CHART_COLORS_BY_CONTENT[index], 1),
                pointBackgroundColor: hexToRgba(CHART_COLORS_BY_CONTENT[index], 1),
                pointBorderWidth: 2,
                pointRadius: 4,
                pointStyle: 'triangle',
              };
              data.datasets.push(temp);
              body.dataSet.display.map(item => {
                if (key in item) {
                  contentRows.push({row: [key, item.date, item[key]]});
                }
              });
            });
          };
          if (body.dataSet.notDisplay) {
            let tempDateLabel = body.dataSet.notDisplay.map(item => item.date);
            if (tempDateLabel.length > dateLabel.length) {
              dateLabel = tempDateLabel;
            }
            let keyList = {};
            body.dataSet.notDisplay.map(item => {
              let ySumCount = 0;
              Object.keys(item).map(k => {
                if (k.length > 0 && k != 'date') {
                  ySumCount = Math.max(ySumCount, item[k] + 5);
                  if (k in keyList === false) {
                    keyList[k] = [];
                  }
                }
              });
              options.scales.y.max = Math.max(options.scales.y.max, ySumCount);
            });
            body.dataSet.notDisplay.map(item => {
              const keys = Object.keys(item);
              Object.keys(keyList).map(k => {
                if (keys.includes(k)) {
                  keyList[k].push(item[k]);
                } else {
                  keyList[k].push(0);
                }
              });
            });
            Object.keys(keyList).forEach((key, index) => {
              let temp = {
                label: key,
                data: keyList[key],
                fill: false,
                pointBorderColor: '#FFFFFF',
                borderColor: hexToRgba(CHART_COLORS_BY_CONTENT[index], 1),
                backgroundColor: hexToRgba(CHART_COLORS_BY_CONTENT[index], 1),
                pointBackgroundColor: hexToRgba(CHART_COLORS_BY_CONTENT[index], 1),
                pointBorderWidth: 2,
                pointRadius: 4,
                pointStyle: 'circle',
              };
              data.datasets.push(temp);
              body.dataSet.notDisplay.map(item => {
                if (key in item) {
                  contentRows.push({row: [key, item.date, item[key]]});
                }
              });
            });
          };
          data.labels = dateLabel;
          setTrendData({data: data, options: options});
          service_top_trend_data.rowdata = contentRows;
          updateTable(service_top_trend_data);
          setTrendDisplay(true);
        } else {
          setAlertMessage(`trend top error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`trend top error: `, error);
        onOpenAlert();
      })
      .finally(() => {});
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  const hexToRgba = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  return (
    <>
    {
      isTrendDisplay ? (
        <div style={{ height: height }}>
          <div className="trend-head">
            <div className="title" style={{top: "-30px"}}>
              <div> {title} </div>
            </div>
          </div>
          <Line id={'daily_contents_error_occurrence'} ref={chartRef} data={trendData.data}
                      options={trendData.options} />
          <div
            id={`contentId_${id}`}
            style={{
              display: 'none',
              position: 'absolute',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              padding: '5px',
              borderRadius: '3px',
              pointerEvents: 'none',
              transform: 'translate(-50%, -100%)',
            }}
          />
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )
    }
    {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default ServiceTopTrendComponent;