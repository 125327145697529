import * as actions from '../../actions/actionTypes';

const initialState = { result: false, msg: '' };
export default function updateMediaStaticUrl(state = initialState, action) {
    switch (action.type) {
        case actions.SET_EDIT_MEDIA_STATIC_URL:
            return action.payload;
        case actions.SET_EDIT_MEDIA_STATIC_URL_FAIL:
            return action.payload;
        default:
            return state;
    }
}