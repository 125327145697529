import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingIndicator from '../../../../common/LoadingIndicator';
import SideBar from '../sidebar/sidebar.js';
import { makeStyles } from '@material-ui/core/styles';
import { getSchedulesAction } from '../../../../actions/scheduleActions';
import { ScheduleFilter } from '../../../../common/filter';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },

  datePicker: {
    margin: theme.spacing(1),
  },

  epg: {
    boxSizing: 'border-box', //box-sizing이라고 하면 문법에러남
    //height: '55vh', //채널갯수 많을 때 화면밑으로 내려가지 않도록
    overflowY: 'auto', //채널갯수 많을 때 화면밑으로 내려가지 않고 스크를되도록
    transform: 'translate3d(0,0,0)',
    // display: 'flex',
    // flexWrap: 'wrap',
    // '& > *': {
    //   margin: theme.spacing(1),
    //   width: theme.spacing(16),
    //   height: theme.spacing(16),
    // },
  },
}));

function ScheduleGrid({ biz, fetching, getSchedulesAction, schedules, isRoleAdmin, cntryCpResponse }) {
  const classes = useStyles();
  const bizArea = cntryCpResponse.find(item => item.biz === biz);
  let initCntry = 'US';
  // if (isRoleAdmin && bizArea.area) {
  //   if (!bizArea.area.find((item) => item.countryCode === initCntry))
  //     initCntry = bizArea.area[0].countryCode;
  // } else if (bizArea && bizArea.area) {
  //   if (!bizArea.area.find((item) => item === initCntry)) initCntry = bizArea.area[0];
  // }

  const [cntry, setCntry] = useState(initCntry);
  const [cp, setCp] = useState('All');
  const [channels, setChannels] = React.useState([]);
  const [channel, setChannel] = React.useState('All');
  const [date, setDate] = React.useState(new Date());

  //console.log('cntryCpResponse', cntryCpResponse);
  useEffect(() => {
    getSchedulesAction(cntry, cp, date.toISOString().slice(0, 11) + '00:00:00Z', isRoleAdmin);
  }, [cntry, cp, date, isRoleAdmin]);
  useEffect(() => {
    setChannels(['All'].concat(schedules.map(item => item.channelName)));
  }, [schedules]);
  useEffect(() => {
    setChannel('All');
  }, [channels]);

  const handleChangeCntry = event => {
    const cps = bizArea ? bizArea.area.find(item => item.countryCode === event.target.value) : null;

    setCntry(event.target.value);
    // if (cps && cps.providers.length === 1) {
    //   setCp(cps.providers[0]);
    // } else if (cps && cps.providers.length > 1) {
    setCp('All');
    //}
  };

  return (
    <div className={classes.layout + ' tab_body'}>
      <ScheduleFilter
        bizArea={bizArea}
        cntry={cntry}
        handleChangeCntry={handleChangeCntry}
        cp={isRoleAdmin ? cp : null}
        handleChangeCp={e => setCp(e.target.value)}
        channels={channels}
        channel={channel}
        handleChangeChannel={e => setChannel(e.target.value)}
        date={date}
        handleChangeDate={setDate}
        handleClickToday={() => setDate(new Date())}
      />
      {fetching ? (
        <div className="loading_wrap">
          <LoadingIndicator />
        </div>
      ) : (
        <div className={classes.epg + ' schedule_wrap'}>
          <SideBar schedules={channel === 'All' ? schedules : schedules.filter(item => item.channelName === channel)} />
        </div>
      )}
    </div>
  );
}

ScheduleGrid.propTypes = {
  biz: PropTypes.string,
  isRoleAdmin: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  schedules: PropTypes.array.isRequired,
  getSchedulesAction: PropTypes.func.isRequired,
  cntryCpResponse: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  isRoleAdmin: state.currentUser.isRoleAdmin,
  fetching: state.schedules.fetching,
  schedules: state.schedules.schedules,
  cntryCpResponse: state.cntry.countries,
});
const mapDispatchToProps = dispatch => ({
  getSchedulesAction(cntry, cp, date, isRoleAdmin) {
    dispatch(getSchedulesAction(cntry, cp, date, isRoleAdmin));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleGrid);
