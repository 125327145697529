export const ServiceBasicAppFilter = {
  basicFilter: { area: 'region', provider: true, platform: true, interval: true }, // area : region or group, interval : h or d
  customCheckboxFilter: [
    {
      source: ['AutoTest', 'others']
    }
  ],
  customInputFilter: [
    'errorCodes'
  ],
  compareFilter: ['None', 'Country', 'Platform', 'Provider'],
  selectedFilter: null,
};

export const ServiceBasicPlayFilter = {
  basicFilter: { area: 'region', provider: true, platform: true, interval: true }, // area : region or group, interval : h or d
  customCheckboxFilter: [
    {
      source: ['AutoTest', 'others']
    }
  ],
  customInputFilter: [
    'contentId', 'errorCodes', 'contentName'
  ],
  compareFilter: ['None', 'Country', 'Platform', 'Provider'],
  selectedFilter: null,
};

export const ServiceTopAppFilter = {
  basicFilter: { area: 'region', provider: true, platform: true, interval: true }, // area : region or group, interval : h or d
  customRadioFilter: [
    {
      display : ['all', 'issue', 'not issue']
    }
  ],
  customCheckboxFilter: [
    {
      source: ['AutoTest', 'others']
    }
  ],
  customInputFilter: [
    'errorCodes'
  ],
  selectedFilter: null,
};

export const ServiceTopPlayFilter = {
  basicFilter: { area: 'region', provider: true, platform: true, interval: true }, // area : region or group, interval : h or d
  customRadioFilter: [
    {
      display : ['all', 'issue', 'not issue']
    }
  ],
  customCheckboxFilter: [
    {
      source: ['AutoTest', 'others']
    }
  ],
  customInputFilter: [
    'errorCodes'
  ],
  selectedFilter: null,
};

export const ServiceResolveTrendFilter = {
  basicFilter: { area: 'region', provider: true, platform: true, interval: true }, // area : region or group, interval : h or d
  customRadioFilter: [
    {
      display : ['all', 'app', 'play', 'schedule']
    }
  ],
  selectedFilter: null,
};