import * as actions from '../../actions/actionTypes';

const initialState = false;

export default function dataFetching(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATISTIC_FETCHING:
      return true;
    case actions.SET_STATISTIC:
    case actions.SET_STATISTIC_ERROR:
      return false;
    default:
      return state;
  }
}
