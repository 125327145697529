import React, { useEffect, useState } from 'react';
import Loading from '../../../common/Loading';
import { Line } from 'react-chartjs-2';
import Alert from '../../../modal/Alert';
import { CHART_COLORS_BY_COUNTRY, CHART_COLORS_BY_PROVIDER } from '../../../common/ChartConsts';
import { getHeaders } from '../../../../../util/actionUtil';
import { ISS_BASE_URL } from '../../../../../constants/index';
import { feed_basic_trend_data, feed_basic_trend_data_by_country, feed_basic_trend_data_by_provider } from './FeedTrendData';

function FeedBasicTrend(props) {
  const { height
        , title
        , originalFilter
        , currentFilter
        , updateTable
        , size
      } = props;
  const [trendData, setTrendData] = useState({data: {}, options: {}});
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [isTrendDisplay, setTrendDisplay] = useState(false);
  const [displayCpId, setDisplayCpId] = useState('');
  const [legendLimit, setLegendLimit] = useState(10);

  useEffect(() => {
    if (currentFilter) {
      console.log(`[ISS] FeedBasicTrend currentFilter: `, currentFilter);
      setTrendDisplay(false);
      setTrendData({});
      getTrendFeedBasic(currentFilter);
    }
  }, [currentFilter]);

  const processData = (data) => {
    const groupedData = {};
    const uniqueDates = new Set();
    let maxValue = 0;

    let displayItem = [];
    if (currentFilter.customCheckboxFilter) {
      displayItem = currentFilter.customCheckboxFilter.find(item => item.hasOwnProperty('count')).count;
    }

    const groupId = currentFilter.compareType === 'Country' ? "Country" : "Provider";

    data.forEach((item) => {
      const { country, providerId, date, totalCnt, failCnt } = item;
      uniqueDates.add(date.substring(5));

      if (groupId === 'Country') {
        if (!groupedData[country]) {
          groupedData[country] = { totalCnts: [], failCnts: [] };
        }

        if (displayItem.length === 0) {
          groupedData[country].totalCnts.push(totalCnt);
          groupedData[country].failCnts.push(failCnt);

          maxValue = Math.max(maxValue, totalCnt, failCnt);
        } else {
          if (displayItem.includes('total')) {
            groupedData[country].totalCnts.push(totalCnt);
            maxValue = Math.max(maxValue, totalCnt);
          }
          if (displayItem.includes('fail')) {
            groupedData[country].failCnts.push(failCnt);
            maxValue = Math.max(maxValue, failCnt);
          }
        }
      } else {
        if (!groupedData[providerId]) {
          groupedData[providerId] = { totalCnts: [], failCnts: [] };
        }

        if (displayItem.length === 0) {
          groupedData[providerId].totalCnts.push(totalCnt);
          groupedData[providerId].failCnts.push(failCnt);

          maxValue = Math.max(maxValue, totalCnt, failCnt);
        } else {
          if (displayItem.includes('total')) {
            groupedData[providerId].totalCnts.push(totalCnt);
            maxValue = Math.max(maxValue, totalCnt);
          }
          if (displayItem.includes('fail')) {
            groupedData[providerId].failCnts.push(failCnt);
            maxValue = Math.max(maxValue, failCnt);
          }
        }
      }
    });

    return { groupedData, uniqueDates: Array.from(uniqueDates).sort(), maxValue };
  };

  const hexToRgba = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const getTrendFeedBasic = (filter) => {
    const tempCustomCheckboxFilter = [];

    filter.customCheckboxFilter && filter.customCheckboxFilter.map((item, index) => {
      const [key, value] = Object.entries(item)[0];
      tempCustomCheckboxFilter.push(`${key}=${value.join(',')}`);
    });

    const customCheckboxFilter = tempCustomCheckboxFilter ? tempCustomCheckboxFilter.join('&') : '';
    const tempInterval = filter.interval ? filter.interval : 'd';
    const tempProvider = filter.providers ? filter.providers : '';
    const tempCountry = filter.country ? filter.country : '';

    if (filter.providers && filter.providers.length === 1) {
      setDisplayCpId('(' + filter.providers[0] + ')');
    } else {
      setDisplayCpId('');
    }

    const compareType = filter.compareType ? filter.compareType : 'None';

    let param = '';
    if (customCheckboxFilter) {
      param = `date=${filter.date}&interval=${tempInterval}&provider=${tempProvider}&${customCheckboxFilter}&country=${tempCountry}&compare=${compareType}`;
    } else {
      param = `date=${filter.date}&interval=${tempInterval}&provider=${tempProvider}&country=${tempCountry}&compare=${compareType}`;
    }

    fetch(`${ISS_BASE_URL}/api/application/dashboard/trend/feed/basic?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        if (body && body.result === 'SUCCESS') {
          const { groupedData, uniqueDates, maxValue } = processData(body.dataSet);
          const displayLegend = currentFilter && currentFilter.customCheckboxFilter && currentFilter.customCheckboxFilter.find(item => item.hasOwnProperty('count')).count;

          const chartData = {
            labels: uniqueDates,
            datasets: [
              ...Object.keys(groupedData).map((item) => [
                {
                  label: body.compareType !== 'None' ? `${item}_Total` : 'Total',
                  data: groupedData[item].totalCnts,
                  fill: false,
                  pointBorderColor: '#FFFFFF',
                  borderColor: hexToRgba(getColor(body.compareType, item), 1),
                  backgroundColor: hexToRgba(getColor(body.compareType, item), 1),
                  pointBackgroundColor: hexToRgba(getColor(body.compareType, item), 1),
                  pointBorderWidth: 2,
                  pointRadius: 4,
                  pointStyle: 'circle',
                },
                {
                  label: body.compareType !== 'None' ? `${item}_Fail` : 'Fail',
                  data: groupedData[item].failCnts,
                  fill: false,
                  pointBorderColor: '#FFFFFF',
                  borderColor: hexToRgba(getColor(body.compareType, item), 0.7),
                  backgroundColor: hexToRgba(getColor(body.compareType, item), 0.7),
                  pointBackgroundColor: hexToRgba(getColor(body.compareType, item), 0.7),
                  pointBorderWidth: 2,
                  pointRadius: 4,
                  pointStyle: 'triangle',
                },
              ]).flat(),
            ],
          };

          const options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                min: 0,
                max: Math.ceil(maxValue / 10) * 10,
                ticks: {
                  stepSize: 10,
                },
              },
              x: {
                ticks: {
                  callback: function(value, index, values) {
                    if (chartData.labels[index] && (chartData.labels[index]).length > 5) {
                      if (index > 0 && (chartData.labels[index - 1]).substring(0, 5) === (chartData.labels[index]).substring(0, 5)) {
                        return '';
                      }
                      return (chartData.labels[index]).substring(0, 5);
                    } else {
                      return chartData.labels[index];
                    }
                  }
                },
              },
            },
            plugins: {
              datalabels: {
                display: false,
              },
              legend: {
                display: true,
                labels: {
                  usePointStyle: true,
                  boxWidth: 6,
                  boxHeight: 6,
                  color: '#fff',
                  font: {
                    size: 12,
                  },
                  filter: (legendItem, chartData) => {
                    const tempTitle = legendItem.text.split('_');
                    const legendTitle = tempTitle.length > 1 ? tempTitle[1].toLowerCase() : tempTitle[0].toLowerCase();

                    return displayLegend ? displayLegend.includes(legendTitle) && legendItem.datasetIndex < legendLimit : legendItem.datasetIndex < legendLimit;
                  },
                },
              },
            },
          };

          setTrendData({data: chartData, options: options});

          // update table
          if (body.compareType === 'Provider') {
            feed_basic_trend_data_by_provider.rowdata = body.dataSet.map(item => ({
              row: [
                item.providerId,
                item.date,
                item.totalCnt,
                item.failCnt
              ],
            }));
            updateTable(feed_basic_trend_data_by_provider);
          } else if (body.compareType === 'Country') {
            feed_basic_trend_data_by_country.rowdata = body.dataSet.map(item => ({
              row: [
                item.country,
                item.date,
                item.totalCnt,
                item.failCnt
              ],
            }));
            updateTable(feed_basic_trend_data_by_country);
          } else {
            feed_basic_trend_data.rowdata = body.dataSet.map(item => ({
              row: [
                item.date,
                item.totalCnt,
                item.failCnt,
              ],
            }));
            updateTable(feed_basic_trend_data);
          }
          // update table

          setTrendDisplay(true);
        } else {
          setAlertMessage(`trend feed basic error`);
          onOpenAlert();
        }
      })
      .catch(error => {
        setAlertMessage(`trend feed basic error: `, error);
        onOpenAlert();
      })
      .finally(() => {});
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  function getColor(compareType, item) {
    if (compareType === 'Country') {
      return CHART_COLORS_BY_COUNTRY[item] || CHART_COLORS_BY_COUNTRY['OTHERS'];
    } else {
      return CHART_COLORS_BY_PROVIDER[item] || CHART_COLORS_BY_PROVIDER['OTHERS'];
    }
  }

  return (
    <>
    {
      isTrendDisplay ? (
        <div style={{ height: height }}>
          <div className='trend-head'>
            <div className="title" style={{top: "-30px"}}>
              <div> {title} {displayCpId} </div>
            </div>
          </div>
          <Line data={trendData.data} options={trendData.options} />
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )
    }
    {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </>
  );
}

export default FeedBasicTrend;