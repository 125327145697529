import {
  ROLE_AUTH_EDIT,
  ROLE_AUTH_VIEW, ROLE_CHANNEL_OPS,
  ROLE_CP,
  ROLE_ISS_OPS,
  ROLE_LOCAL_CORP,
  ROLE_SERVICE_BIZ,
  ROLE_SW_DEV
} from "../../../constants";

export const HeaderMenuList = [
  // ISS menu
  {
    menu: 'ISS',
    role: [ROLE_CP, ROLE_SW_DEV, ROLE_ISS_OPS, ROLE_SERVICE_BIZ, ROLE_LOCAL_CORP],
    detailMenu: [
      { label: 'Dashboard', path: '/ISS/IssDashboard',
        role: [ROLE_CP, ROLE_SW_DEV, ROLE_ISS_OPS, ROLE_SERVICE_BIZ, ROLE_LOCAL_CORP], auth: ROLE_AUTH_VIEW },
      { label: 'Service', path: '/ISS/ServiceDetail',
        role: [ROLE_SW_DEV, ROLE_ISS_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'Content', path: '/ISS/ContentsDetail',
        role: [ROLE_SW_DEV, ROLE_ISS_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'Statistics', path: '/ISS/StatisticsDetail',
        role: [ROLE_SW_DEV, ROLE_ISS_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'Advertisement', path: '/ISS/AdDetail',
        role: [ROLE_SW_DEV, ROLE_ISS_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'FeatureBat', path: '/ISS/FeatureBat',
        role: [ROLE_SW_DEV], auth: ROLE_AUTH_VIEW },
      { label: 'Issue History', path: '/ISS/IssueHistory',
        role: [ROLE_SW_DEV, ROLE_ISS_OPS, ROLE_SERVICE_BIZ, ROLE_LOCAL_CORP], auth: ROLE_AUTH_VIEW },
      { label: 'Assign History', path: '/ISS/AssignHistory',
        role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ, ROLE_LOCAL_CORP], auth: ROLE_AUTH_VIEW },
      { label: 'Issue Trend', path: '/ISS/IssueTrend',
        role: [ROLE_SW_DEV, ROLE_ISS_OPS, ROLE_SERVICE_BIZ, ROLE_LOCAL_CORP], auth: ROLE_AUTH_VIEW },
    ],
  },
  {
    menu: 'PROGRAM', //대문자로 자동변경, 어디서 변경되지???
    role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ, ROLE_CP, ROLE_LOCAL_CORP],
    //isForCp: true,
    detailMenu: [
      { label: 'Schedule', path: '/PROGRAM/schedulelist',
        role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ, ROLE_CP, ROLE_LOCAL_CORP], auth: ROLE_AUTH_VIEW },
      { label: 'Program', path: '/PROGRAM/programlist',
        role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ, ROLE_CP, ROLE_LOCAL_CORP], auth: ROLE_AUTH_VIEW },
    ],
  },
  {
    menu: 'CHANNEL',
    role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ],
    detailMenu: [
      { label: 'Channel Mapping', path: '/CHANNEL/channelMapping',
        role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'Channel', path: '/CHANNEL/channel',
        role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'ChannelCount', path: '/CHANNEL/channelCount',
        role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_VIEW },
    ],
  },
  {
    menu: 'PROMOTION',
    role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ],
    detailMenu: [
      { label: '2ndDepth/HomeShelf', path: '/PROMOTION/displayRules',
        role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'Free on LG Channels', path: '/PROMOTION/freeOnLGChannels',
        role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'Watch on LG Channels', path: '/PROMOTION/watchLive',
        role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
    ],
  },
  {
    menu: 'STATISTICS',
    role: [ROLE_SW_DEV, ROLE_CP, ROLE_SERVICE_BIZ],
    //isForCp: true,
    detailMenu: [
      { label: 'Summary', path: '/STATISTICS/summary',
        role: [ROLE_SW_DEV], auth: ROLE_AUTH_VIEW },
      { label: 'Channel', path: '/STATISTICS/channel',
        role: [ROLE_CP, ROLE_SW_DEV, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_VIEW },
      { label: 'Program', path: '/STATISTICS/program',
        role: [ROLE_CP, ROLE_SW_DEV, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_VIEW },
      { label: 'LG Channels Home', path: '/STATISTICS/lgChannelsHome',
        role: [ROLE_CP, ROLE_SW_DEV, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_VIEW },
    ],
  },
  {
    menu: 'ACCOUNT',
    role: [ROLE_SW_DEV],
    detailMenu: [
      { label: 'Admin', path: '/ACCOUNT/admin',
        role: [ROLE_SW_DEV], auth: ROLE_AUTH_EDIT },
      { label: 'CP Code', path: '/ACCOUNT/cpCode',
        role: [ROLE_SW_DEV], auth: ROLE_AUTH_EDIT },
      { label: 'CP Member', path: '/ACCOUNT/cpMember',
        role: [ROLE_SW_DEV], auth: ROLE_AUTH_EDIT },
    ],
  },
  {
    menu: 'SYSTEM',
    role: [ROLE_CP, ROLE_SW_DEV],
    detailMenu: [
      { label: 'API Call Logs', path: '/SYSTEM/apiCallLogs',
        role: [ROLE_CP, ROLE_SW_DEV], auth: ROLE_AUTH_VIEW },
    ],
  },
  {
    menu: 'HOME-APP',
    role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ],
    detailMenu: [
      { label: 'Region', path: '/HOME-APP/region',
        role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_VIEW },
      { label: 'Tab/Category', path: '/HOME-APP/tab',
        role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'Genre', path: '/HOME-APP/genre',
        role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'Emulate', path: '/HOME-APP/emulate',
        role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_VIEW },
      { label: 'Monitoring', path: '/HOME-APP/monitor',
        role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_VIEW },
      { label: 'Notification', path: '/HOME-APP/notification',
        role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      // {
      //   label: 'Category',
      //   path: '/HOME-APP/category/edit',
      //   child: [
      //     { label: 'Edit', path: '/HOME-APP/category/edit' },
      //     { label: 'Organizing', path: '/HOME-APP/category/organizing' },
      //   ],
      // },
    ],
  },
  // {
  //   menu: 'LIVE-TV',
  //   role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ],
  //   detailMenu: [
  //     { label: 'InteractiveDock', path: '/LIVE-TV/interactiveDock',
  //       role: [ROLE_SW_DEV, ROLE_CHANNEL_OPS, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_VIEW },
  //   ],
  // },
  {
    menu: 'LIVE-TV',
    detailMenu: [
      { label: 'InteractiveDock', path: '/LIVE-TV/interactiveDock' },
    ],
  },
  {
    menu: 'RECOMMEND',
    role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ],
    detailMenu: [
      { label: 'AN Test', path: '/RECOMMEND/antest',
        role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'AN Service', path: '/RECOMMEND/anservice',
        role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
    ],
  },
  {
    menu: 'WEB',
    detailMenu: [
      { label: 'Terms of Use', path: '/WEB/termsofuse' },
      { label: 'Privacy Policy', path: '/WEB/privacypolicy' },
    ],
  },
  /*{
    menu: 'POLICY',
    role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ],
    detailMenu: [
      { label: 'Terms of Use', path: '/POLICY/terms',
        role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'Privacy Policy', path: '/POLICY/privacy',
        role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'Terms of Use (mobile)', path: '/POLICY/termsofuse',
        role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'Privacy Policy (mobile)', path: '/POLICY/privacypolicy',
        role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
    ],
  },*/
  {
    menu: 'MOBILE',
    role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ],
    detailMenu: [
      { label: 'App Version', path: '/MOBILE/appversion',
        role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
      { label: 'Push Alarm', path: '/MOBILE/pushAlarm',
        role: [ROLE_SW_DEV, ROLE_SERVICE_BIZ], auth: ROLE_AUTH_EDIT },
    ],
  },
];
export const BreadcrumbMap = {
  '/PROGRAM': 'PROGRAM',
  '/PROGRAM/schedulelist': 'Schedule',
  '/PROGRAM/programlist': 'Program',
  '/PROGRAM/program': 'Program',
  '/PROGRAM/temp': 'Temp',
  '/CHANNEL': 'CHANNEL',
  '/CHANNEL/channelMapping': 'IP Channel Mapping',
  '/CHANNEL/channel': 'IP Channel',
  '/CHANNEL/channel/channelView': 'IP Channel',
  '/CHANNEL/channel/channelEdit': 'IP Channel',
  '/CHANNEL/channelFeed': 'IP Channel Feed',
  '/CHANNEL/channelFeed/ChannelFeedView': 'IP Channel Feed',
  '/CHANNEL/channelFeed/ChannelFeedAdd': 'IP Channel Feed',
  '/CHANNEL/channelFeed/ChannelFeedEdit': 'IP Channel Feed',
  '/CHANNEL/channelConf': 'IP Channel Conf',
  '/CHANNEL/channelConf/ChannelConfView': 'IP Channel Conf',
  '/CHANNEL/channelConf/ChannelConfEdit': 'IP Channel Conf',
  '/CHANNEL/channelConf/ChannelConfAdd': 'IP Channel Conf',
  '/CHANNEL/channelCount': 'IP Channel Count',
  '/PROMOTION': 'PROMOTION',
  '/PROMOTION/displayRules': '2ndDepth/HomeShelf',
  '/PROMOTION/freeOnLGChannels': 'Free on LG Channels',
  '/PROMOTION/watchLive': 'Watch on LG Channels',
  '/STATISTICS': 'STATISTICS',
  '/STATISTICS/summary': 'Summary',
  '/STATISTICS/channel': 'Channel',
  '/STATISTICS/program': 'Program',
  '/STATISTICS/lgChannelsHome': 'LG Channels Home',
  '/ACCOUNT': 'ACCOUNT',
  '/ACCOUNT/admin': 'Admin',
  '/ACCOUNT/cpCode': 'CP Code',
  '/ACCOUNT/cpMember': 'CP Member',
  '/SYSTEM': 'SYSTEM',
  '/SYSTEM/apiCallLogs': 'API Call Logs',
  '/HOME-APP': 'HOME-APP',
  '/HOME-APP/region': 'Region',
  '/HOME-APP/tab': 'Tab/Category',
  '/HOME-APP/emulate': 'Emulate',
  '/HOME-APP/genre': 'Genre',
  '/HOME-APP/genre/genreDetail': 'Genre',
  '/HOME-APP/monitor': 'Monitoring',
  '/HOME-APP/notification': 'Notification',
  '/LIVE-TV': 'LIVE-TV',
  '/LIVE-TV/interactiveDock': 'Interactive Dock',
  '/dashboard': 'Dashboard',
  '/profile': 'My Profile',
  '/WEB': 'WEB',
  '/WEB/termsofuse': 'Terms of Use',
  '/WEB/privacypolicy': 'Privacy Policy',
  '/MOBILE': 'MOBILE',
  '/MOBILE/appversion': 'App Version',
  '/MOBILE/pushAlarm': 'Push Alarm',
  '/RECOMMEND': 'RECOMMEND',
  '/RECOMMEND/antest': 'AN Test',
  '/RECOMMEND/anservice': 'AN Service',
  '/RECOMMEND/antest/addsegment': 'Configure Segment',
  '/RECOMMEND/antest/organize': 'Organize Segment',
  '/RECOMMEND/antest/abtestresult': 'A/N Test Result',
  '/RECOMMEND/anservice/addantestservice': 'A/N Service',
  '/RECOMMEND/anservice/addsegment': 'Configure Segment',
  '/RECOMMEND/anservice/organize': 'Organize Segment',
  '/RECOMMEND/anservice/result': 'A/N Service Result',
};
