import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import AlertDialog from '../../../common/dialog/AlertDialog';

const SearchFilter = (props) => {
  const { callbackSearchFilter
        , callbackSelectedCountry
        , callbackSelectedProviders
        , callbackSelectedStatus
        , initPublishingFlag
        , callbackLangList
        , callbackSearchFilterChange
      } = props;

  const [countries, setCountries] = useState([]);
  const [providers, setProviders] = useState([]);
  const [channelGenre, setChannelGenre] = useState([]);
  const [relatedFlag, setRelatedFlag] = useState(['on', 'off']);
  const [publishingFlag, setPublishingFlag] = useState(['Testing', 'Published', 'SavedTesting', 'SavedPublished']);
  const [paramCountry, setParamCountry] = useState('');
  const [paramProvider, setParamProvider] = useState('');
  const [paramChannelGenre, setParamChannelGenre] = useState('');
  const [paramRelatedFlag, setParamRelatedFlag] = useState('');
  const [paramPublishingFlag, setParamPublishingFlag] = useState('Testing');
  const [searchFilter, setSearchFilter] = useState({});
  const [isAlert, setIsAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [displayWarningList, setDisplayWarningList] = useState([]);
  const [mappingRule, setMappingRule] = useState(['manual', 'auto']);
  const [paramMappingRule, setParamMappingRule] = useState('');

  useEffect(() => {
    getSearchFilter();
  }, []);

  useEffect(() => {
    callbackSearchFilterChange();
  }, [paramCountry, paramProvider, paramRelatedFlag, paramMappingRule, paramChannelGenre, paramPublishingFlag]);

  useEffect(() => {
    if (initPublishingFlag) {
      setParamPublishingFlag(initPublishingFlag);
    }
  }, [initPublishingFlag]);

  useEffect(() => {
    if (paramCountry && searchFilter && searchFilter.providerByCountry) {
      const providerList = searchFilter.providerByCountry.find(item => item.countryCode === paramCountry).providerList.map(item => item.providerName);
      setProviders(providerList);
      setParamProvider('');
      setParamChannelGenre('');
      setParamRelatedFlag('');

      if (paramCountry === 'KR') {
        setChannelGenre(searchFilter.channelGenreList.filter(item => item.genreType === 'channel_kr').map(item => item.genreName));
      } else {
        setChannelGenre(searchFilter.channelGenreList.filter(item => item.genreType === 'channel').map(item => item.genreName));
      }

      callbackSelectedCountry(paramCountry);
      callbackSelectedProviders(providerList);
    }
  }, [paramCountry]);

  useEffect(() => {
    callbackSelectedStatus(paramPublishingFlag);
  }, [paramPublishingFlag]);

  const getSearchFilter = () => {
    fetch(`${API_BASE_URL}/admin/livetv/search-filter`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSearchFilter({
          providerByCountry : body.providerByCountry,
          channelGenreList : body.channelGenreList,
          langList: body.langList,
        })

        callbackLangList(body.langList);
        setCountries(body.providerByCountry.map(item => item.countryCode));
        setChannelGenre(body.channelGenreList.filter(item => item.genreType === 'channel').map(item => item.genreName));
        setParamCountry('US');
      } else {
        setAlertDescription(`[LiveTV] search-filter request fail`);
        setIsAlert(true);
      }
    })
    .catch(error => {
      setAlertDescription(`[LiveTV] search-filter request error: `, error);
      setIsAlert(true);
    }).finally(() => {});
  };

  const onSearchClick = () => {
    const providerName = paramProvider ? paramProvider : 'ALL';
    const relatedFlag = paramRelatedFlag ? paramRelatedFlag : 'ALL';
    const genreName = paramChannelGenre ? paramChannelGenre : 'ALL';
    const genreType = paramCountry === 'KR' ? 'channel_kr' : 'channel';
    const mappingRule = paramMappingRule ? paramMappingRule : 'ALL';

    let status = '';
    if (paramPublishingFlag === 'Testing') {
      status = 'T';
    } else if (paramPublishingFlag === 'Published') {
      status = 'P';
    } else if (paramPublishingFlag === 'SavedTesting') {
      status = 'ST';
    } else {
      status = 'SP';
    }

    const searchFilterResult = {
      country: paramCountry,
      providerName: providerName,
      relatedFlag: relatedFlag,
      mappingRule: mappingRule,
      genreName: genreName,
      genreType: genreType,
      status: status,
    };

    callbackSearchFilter(searchFilterResult);
  };

  return (
    <React.Fragment>
      <div className="filter_area">
        <FormControl variant="outlined">
          <InputLabel id="Country">Country</InputLabel>
          <Select labelId="Country" id="Country" value={paramCountry}
            onChange={e => setParamCountry(e.target.value)}>
            {countries.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="Provider">Provider</InputLabel>
          <Select labelId="Provider" id="Provider" value={paramProvider ? paramProvider : 'ALL'}
            onChange={e => setParamProvider(e.target.value)} >
            {['ALL',  ...providers].map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="RelatedFlag">Service</InputLabel>
          <Select labelId="RelatedFlag" id="RelatedFlag" value={paramRelatedFlag ? paramRelatedFlag : 'ALL'}
            onChange={e => setParamRelatedFlag(e.target.value)}>
            {['ALL', ...relatedFlag].map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="MappingRule">Mapping Rule</InputLabel>
          <Select labelId="MappingRule" id="MappingRule" value={paramMappingRule ? paramMappingRule : 'ALL'}
            onChange={e => setParamMappingRule(e.target.value)}>
            {['ALL', ...mappingRule].map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="ChannelGenre">Channel Genre</InputLabel>
          <Select labelId="ChannelGenre" id="ChannelGenre" value={paramChannelGenre ? paramChannelGenre : 'ALL'}
            onChange={e => setParamChannelGenre(e.target.value)}>
            {['ALL', ...channelGenre].map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="PublishingFlag">Publishing Flag</InputLabel>
          <Select labelId="PublishingFlag" id="PublishingFlag" value={paramPublishingFlag}
            onChange={e => setParamPublishingFlag(e.target.value)}>
            {publishingFlag.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="btn_set_wrap search_wrap live_tv">
          <Button variant="contained" className={'btn_color2'} onClick={onSearchClick}>
            Search
          </Button>
        </div>
      </div>
      <AlertDialog
        openAlert={isAlert}
        description={alertDescription}
        warningList={displayWarningList}
        closeHandler={() => { setIsAlert(false); }}
      />
    </React.Fragment>
  );
}

export default SearchFilter;