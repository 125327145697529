import * as actions from '../../actions/actionTypes';

const initialState = null;

export default function channelMappedData(state = initialState, action) {
    switch (action.type) {
        case actions.SET_CHANNELMAP_DATA:
            return action.payload;
        default:
            return state;
    }
}