import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

function RadioDropDown( props ) {
  const { item, onChangeDropDown, selectedItem, uiClassName, scroll, title } = props;
  const [open, setOpen] = useState(false);
  const [checkedItem, setCheckedItem] = useState('');
  const defineClass = uiClassName ? uiClassName : "ui-dropdown-type01";

  useEffect(() => {
    if(item.dropdownList.length > 0 && !selectedItem) {
      setCheckedItem(item.dropdownList[0]);
    }
  }, [item]);

  useEffect(() => {
    if (selectedItem){
      setCheckedItem(selectedItem);
    }
  }, [selectedItem]);

  const onCheckedEl = useCallback(
    (checked, list) => {
      if (checked) {
        setCheckedItem(list);
      } else {
        setCheckedItem('');
      }
    },
    [checkedItem],
  );

  useEffect(() => {
    if (checkedItem != ''){
      onChangeDropDown(checkedItem);
    }
  }, [checkedItem]);

  const onBlur = () => {
    setOpen(false);
  };

  const onClick = e => {
    setOpen(!open);
  };

  return (
    <>
      <div tabIndex={0} className={`${defineClass} ${scroll ? 'scroll' : ''}`} onBlur={onBlur}>
        <div className="label" onClick={e => onClick(e)}>
          {checkedItem != '' ? checkedItem : item.dropdownList[0]} {title ? title : ''}
        </div>
        <ul className={open ? 'on' : ''}>
          {item &&
            item.dropdownList &&
            item.dropdownList.map((items, index) => (
              <li key={index}>
                <label>
                  <input type="radio" name="region2" onChange={e => onCheckedEl(e.target.checked, items)} checked={checkedItem === items ? true : false} />
                  <span>{items}</span>
                </label>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
RadioDropDown.propTypes = {
  onChangeDropDown: PropTypes.func,
};
export default RadioDropDown;
