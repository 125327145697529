import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import AdminForm from './AdminForm';
import { validatePw } from '../ChangePw';
import { getAdminsAction } from '../../../actions/userActions';
import { request } from '../../../util/request';

function AdminAdd({ getAdminsAction }) {
  const [filter, setFilter] = useReducer((state, newState) => ({ ...state, ...newState }), {
    username: {
      value: '',
    },
    password: {
      value: '',
      errorMsg: 'At least 6 characters', //Enter a combination of at least 6 numbers, letters and punctuation marks(like ! and &)
    },
    passwordReEnter: {
      value: '',
    },
  });

  const [open, setOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const handleInputChange = e => {
    const inputId = e.target.name; //
    setFilter({
      [inputId]: {
        value: e.target.value,
        errorMsg: filter[inputId].errorMsg,
      },
    });
  };
  const handleSubmit = async event => {
    event.preventDefault();

    if (validatePw(filter.password.value, filter.passwordReEnter.value)) {
      const newItem = {
        providerId: 'LGP',
        username: filter.username.value,
        name: filter.username.value,
        password: filter.password.value,
        roles: [
          { id: 3, name: 'ROLE_ADMIN' },
          { id: 5, name: 'ROLE_USER' }, //없으면 403Error
        ],
      };
      alert('Are you sure to add new Admin?');
      const isSuccess = await request('/api/admin/users', 'POST', newItem);
      //console.log('[isSuccess]', isSuccess); //await 없으면 Promise {<pending>}//request내부await 없으면 무조건 기본값리턴
      if (isSuccess) {
        setSuccessSnackbarOpen(true);
        getAdminsAction();
        setOpen(false);
      }
    }
  };
  return (
    <React.Fragment>
      <Button color="secondary" variant="contained" onClick={() => setOpen(true)} className={'btn_color2'}>
        Add
      </Button>
      <Snackbar
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'left',
        // }}
        open={successSnackbarOpen}
        autoHideDuration={1000} //1000=1sec
        onClose={() => setSuccessSnackbarOpen(false)} //없으면 없어지지않음
        message="Successfully Doned !"
        style={{ backgroundColor: 'black' }}
      />
      <Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={'pop_wrap'}>
        <AdminForm title="Add Admin" filter={filter} handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={() => setOpen(false)} />
      </Dialog>
    </React.Fragment>
  );
}
AdminAdd.propTypes = {
  getAdminsAction: PropTypes.func.isRequired,
};
// const mapStateToProps = (state) => ({
//   isAdded: state.providers.isAdded,
// });
const mapDispatchToProps = dispatch => ({
  getAdminsAction() {
    dispatch(getAdminsAction());
  },
});
export default connect(null, mapDispatchToProps)(AdminAdd);
// id: 2
// name: "rakuten"
// username: "rakuten"
// email: "rakuten@rakuten.com"
// providerId: "RAKUTEN"
// createdAt: "2020-01-17T04:49:10Z"
// roles: [{id: 5, name: "ROLE_USER"}, {id: 3, name: "ROLE_CP"}]
// useFlag: null
