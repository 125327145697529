////////////////////////////////////////////////////////////////////////////////
// import
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// import basic
////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from 'react'; // React
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; // connect
import { withRouter } from 'react-router-dom'; // withRouter
////////////////////////////////////////////////////////////////////////////////
// import material
////////////////////////////////////////////////////////////////////////////////
import { Button, TextField, Paper, TableContainer, TableRow, TableCell, Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import { updateRssFeed, getRssFeedEdit } from '../../../../actions/rssFeedActions';
import { setDetailPageCountries } from '../../../../actions/rssFilterActions';
////////////////////////////////////////////////////////////////////////////////
// RSSFeed
////////////////////////////////////////////////////////////////////////////////
function RSSFeedEdit({ history, feedIdx, feedEdit, updateRssFeed, getRssFeedEdit, setDetailPageCountries }) {
  useEffect(() => {
    getRssFeedEdit(feedIdx);
  }, [feedIdx, getRssFeedEdit]);
  useEffect(() => {
    if (feedEdit) {
      setCpName(feedEdit.cpName);
      setPath(feedEdit.rssPath);
    }
  }, [feedEdit]); //

  const [cpName, setCpName] = useState(feedEdit.cpName);
  const [path, setPath] = useState(feedEdit.rssPath);
  const feedModify = () => {
    if (cpName && path) {
      updateRssFeed(
        {
          feedIdx: feedEdit.feedIdx,
          cntryCode: feedEdit.cntryCode,
          cpName: cpName,
          rssPath: path,
        },
        history,
      );
    }
  };
  const nonSubmit = e => {
    e.preventDefault();
    return false;
  };
  return (
    <div>
      <form onSubmit={nonSubmit}>
        <TableContainer component={Paper} className={'tbl_wrap'}>
          <Table className="tbl_row_typ1 tbl_rssfeed none_bar">
            <colgroup>
              <col style={{ width: '15%' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '35%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Feed No.</TableCell>
                <td>{feedEdit.feedIdx}</td>
              </TableRow>
              <TableRow>
                <TableCell>Country</TableCell>
                <td>{feedEdit.cntryCode}</td>
              </TableRow>
              <TableRow>
                <TableCell>
                  <label htmlFor="cpName" className="is_required">
                    CP Name
                  </label>
                </TableCell>
                <td colSpan={3}>
                  <TextField id="cpName" name="cpName" onChange={e => setCpName(e.target.value)} fullWidth variant="outlined" value={cpName} className="" required />
                </td>
              </TableRow>
              <TableRow>
                <TableCell>
                  <label htmlFor="path" className="is_required">
                    Path
                  </label>
                </TableCell>
                <td colSpan={3}>
                  <TextField fullWidth name="path" id="path" variant="outlined" className="" onChange={e => setPath(e.target.value)} value={path} required />
                </td>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <div className="btn_set_wrap">
          <Button variant="contained" onClick={() => history.push(`/PROMOTION/homeShelf/rssFeed/detail/${feedEdit.feedIdx}`)} className="btn_color1">
            Cancel
          </Button>
          <Button variant="contained" type="submit" className="btn_color2" onClick={feedModify}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}
////////////////////////////////////////////////////////////////////////////////
// RSSFeed.propTypes
////////////////////////////////////////////////////////////////////////////////
RSSFeedEdit.propTypes = {
  detailcountries: PropTypes.array,
  feedEdit: PropTypes.object,
  history: PropTypes.object,
  feedIdx: PropTypes.string,
  updateRssFeed: PropTypes.func,
  getRssFeedEdit: PropTypes.func,
  setDetailPageCountries: PropTypes.func,
};
////////////////////////////////////////////////////////////////////////////////
// mapStateToProps
////////////////////////////////////////////////////////////////////////////////
const mapStateToProps = state => ({
  feedEdit: state.rssFeed.feedEdit,
  detailcountries: state.rssFeed.detailcountries,
});
////////////////////////////////////////////////////////////////////////////////
// mapDispatchToProps
////////////////////////////////////////////////////////////////////////////////
const mapDispatchToProps = dispatch => ({
  updateRssFeed: (param, goToList) => dispatch(updateRssFeed(param, goToList)),
  setDetailPageCountries: () => dispatch(setDetailPageCountries()),
  getRssFeedEdit: feedIdx => dispatch(getRssFeedEdit(feedIdx)),
});
////////////////////////////////////////////////////////////////////////////////
// export
////////////////////////////////////////////////////////////////////////////////
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RSSFeedEdit));
