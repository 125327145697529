import {ROWHEADER_TYPE} from "../../consts/IssConsts";
import {CHART_BAR_COLOR, CHART_COLORS} from "../../common/ChartConsts";


export const requestCountDataset = {
  label: 'Request Count',
  data: [],
  fill: false,
  borderColor: CHART_COLORS.MINT,
  pointBorderColor: CHART_COLORS.WHITE,
  pointBorderWidth: 2,
  pointBackgroundColor: CHART_COLORS.MINT
}

export const impressionCountDataset = {
  label: 'Impression Count',
  data: [],
  fill: false,
  borderColor: CHART_COLORS.PURPLE,
  pointBorderColor: CHART_COLORS.WHITE,
  pointBorderWidth: 2,
  pointBackgroundColor: CHART_COLORS.PURPLE
}

export const impressionSuccessRateDataset = {
  label: 'Render Rate',
  data: [],
  fill: false,
  borderColor: CHART_COLORS.MINT,
  pointBorderColor: CHART_COLORS.WHITE,
  pointBorderWidth: 2,
  pointBackgroundColor: CHART_COLORS.MINT,
}

export const AdDashboardData = {
  dashboardTable: {
    title: 'Ad Issues',
    visibleTitle: false,
    existTooltip: false,
    header: ['Date', 'Issue Id', 'AD Type', 'Platform', 'App Name', 'Inventory Name', 'Banner Type', 'Impression Count', 'Request Count', 'Render Rate', 'Change Rate', 'Action'],
    cellStyle: [ROWHEADER_TYPE.FIRST, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL,
      ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL, ROWHEADER_TYPE.NORMAL],
    cellWidth: ['7%','7%', '5%', '7%', '9%', '13%', '11%', '9%', '8%', '7%', '6%', '7%'],
    rowdata: []
  },

  dashboardGraph: {
    requestImpressionCount: {
      data: {
        labels: ['D-14', 'D-13', 'D-12', 'D-11', 'D-10', 'D-9', 'D-8', 'D-7', 'D-6', 'D-5', 'D-4', 'D-3', 'D-2', 'D-1', 'Today'],
        datasets: [requestCountDataset, impressionCountDataset]
      },
      options: {
        scales: {
          y: {
            min: 0,
            max: 25,
          },
        }
      }
    },
    impressionSuccessRate: {
      data: {
        labels: ['D-14', 'D-13', 'D-12', 'D-11', 'D-10', 'D-9', 'D-8', 'D-7', 'D-6', 'D-5', 'D-4', 'D-3', 'D-2', 'D-1', 'Today'],
        datasets: [impressionSuccessRateDataset]
      },
      options: {
        baselineSubMax: {
          display: true,
          value: 0,
          title: 'Max',
        },
        baselineSubMin: {
          display: true,
          value: 0,
          title: 'Min',
        },
        scales: {
          y: {
            min: 0,
            max: 100,
          },
        }
      }
    }
  }

}