import { API_BASE_URL } from '../constants';
import { getHeaders, getHeadersFormData } from '../util/actionUtil';
import { hideLoader, showLoader } from './loaderActions';

export const getFreeONLGChannelsSettingListAction = (countryCode, recmdMonth) => dispatch => {
  const apiUrl = `${API_BASE_URL}/admin/country/${countryCode}/${recmdMonth}/getFreeOnChannelsInfo`;
  dispatch({
    type: 'SET_FREEONLG_SETTINGLIST_FETCHING',
    payload: true,
  });
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      const modifiedBody = body.map(item => ({
        ...item,
        // type: item.type,
        // cp: item.cp,
        id: item.content_id,
        // name: item.title,
        // image: item.image_url,
        // genre: item.genre
      }));
      dispatch({
        type: 'SET_FREEONLG_SETTINGLIST',
        payload: modifiedBody,
      });
    })
    .catch(ex =>
      dispatch({
        type: 'SET_FREEONLG_SETTINGLIST',
        payload: ex,
      }),
    );
};
