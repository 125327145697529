import * as actions from '../../actions/actionTypes';

const initialState = 0;

export default function totalElements(state = initialState, action) {
  switch (action.type) {
    case actions.SET_RSS_FEED:
      return action.payload.totalElements;
    default:
      return state;
  }
}
