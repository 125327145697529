import { API_URL } from '../constants';
import { getHeaders } from '../util/actionUtil';

// 전체국가 - insert용
export const getAllCountriesAction = isRoleAdmin => dispatch => {
  return fetch(API_URL + '/sdpapi/all-countries', {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      for (let idx01 in body) body[idx01]['key'] = idx01;

      dispatch({
        type: 'SET_CM0001',
        payload: body,
      });
    })
    .catch(ex => {
      dispatch({
        type: 'SET_CM0001_ERROR',
        payload: ex,
      });
    });
};
// 사용중인 전체국가 - 검색용
export const getCountriesAction = isRoleAdmin => dispatch => {
  return fetch(API_URL + '/sdpapi/countries', {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      let tempArrBody = [];
      tempArrBody.push({
        key: 0,
        cntryCode: 'ALL',
        cntryName: 'ALL',
      });
      for (let idx01 in body) {
        let tempObjBody = {
          key: idx01 * 1 + 1,
          cntryCode: body[idx01]['cntryCode'],
          cntryName: body[idx01]['cntryName'],
        };
        tempArrBody.push(tempObjBody);
      }
      dispatch({
        type: 'SET_CM0002',
        payload: tempArrBody,
      });
    })
    .catch(ex => {
      dispatch({
        type: 'SET_CM0002_ERROR',
        payload: ex,
      });
    });
};
// 사용중인 cp목록
export const getCm0003Action = () => dispatch => {
  var url = window.location.href;
  url = url.split('//');
  url = url[1].substr(0, url[1].indexOf('/'));
  let apiUrl = 'http://' + url + '/json/getCm0003Action.json';
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      for (let idx01 in body) body[idx01]['key'] = idx01;
      dispatch({
        type: 'SET_CM0003',
        payload: body,
      });
    })
    .catch(ex => {
      dispatch({
        type: 'SET_CM0003_ERROR',
        payload: ex,
      });
    });
};

export const getComcodeAction = (isRoleAdmin, comTpCode) => dispatch => {
  let typeSuccess = '';
  let typeFailure = '';

  if (comTpCode === 'IB14') {
    // feedType
    typeSuccess = 'SET_CM0005';
    typeFailure = 'SET_CM0005_ERROR';
  } else if (comTpCode === 'IB15') {
    // mode
    typeSuccess = 'SET_CM0006';
    typeFailure = 'SET_CM0006_ERROR';
  } else if (comTpCode === 'IB18') {
    // method
    typeSuccess = 'SET_CM0007';
    typeFailure = 'SET_CM0007_ERROR';
  }

  return fetch(API_URL + '/sdpapi/comcode/' + comTpCode, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      for (let idx01 in body) body[idx01]['key'] = idx01;
      dispatch({
        type: typeSuccess,
        payload: body,
      });
    })
    .catch(ex => {
      dispatch({
        type: typeFailure,
        payload: ex,
      });
    });
};
// Business Area SelectBox
export const getBizAreaAction = isRoleAdmin => dispatch => {
  return fetch(API_URL + '/api/sdepth/biz-area', {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      let tempArrBody = [];
      tempArrBody.push({
        key: 0,
        selectKey: 'ALL',
        selectValue: 'ALL',
      });
      for (let idx01 in body) {
        if (body[idx01]['selectKey'] !== '') {
          let tempObjBody = {
            key: idx01 * 1 + 1,
            selectKey: body[idx01]['selectKey'],
            selectValue: body[idx01]['selectValue'],
          };
          tempArrBody.push(tempObjBody);
        }
      }
      dispatch({
        type: 'SET_CMSDEPTH0001',
        payload: tempArrBody,
      });
    })
    .catch(ex => {
      dispatch({
        type: 'SET_CMSDEPTH0001_ERROR',
        payload: ex,
      });
    });
};
// 사용중인 전체국가 - 검색용
export const getCountries223Action = (isRoleAdmin, bizArea) => dispatch => {
  return fetch(API_URL + '/api/sdepth/countries/' + bizArea, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      // US을 최우선으로 한다.
      let tempBody = [];
      for (let idx01 in body) {
        tempBody.push(JSON.parse(JSON.stringify(body[idx01])));
      }
      for (let idx01 in tempBody) tempBody[idx01]['key'] = idx01;

      dispatch({
        type: 'SET_CMSDEPTH0002',
        payload: tempBody,
      });
    })
    .catch(ex => {
      dispatch({
        type: 'SET_CMSDEPTH0002_ERROR',
        payload: ex,
      });
    });
};
// Platform SelectBox
export const getPlatforms22Action = isRoleAdmin => dispatch => {
  return fetch(API_URL + '/api/sdepth/platforms', {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      for (let idx01 in body) {
        body[idx01]['key'] = idx01;
      }
      dispatch({
        type: 'SET_CMSDEPTH0003',
        payload: body,
      });
    })
    .catch(ex => {
      dispatch({
        type: 'SET_CMSDEPTH0003_ERROR',
        payload: ex,
      });
    });
};
// 사용중인 전체국가 - 전체
export const getCountries22Action = isRoleAdmin => dispatch => {
  return fetch(API_URL + '/api/sdepth/countries', {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {

      // US을 최우선으로 한다.
      let tempBody = [];
      //US을 먼저 뽑고
      for (let idx01 in body) {
        tempBody.push(JSON.parse(JSON.stringify(body[idx01])));
      }
      for (let idx01 in tempBody) tempBody[idx01]['key'] = idx01;
      dispatch({
        type: 'SET_CMSDEPTH0004',
        payload: tempBody,
      });
    })
    .catch(ex => {
      dispatch({
        type: 'SET_CMSDEPTH0004_ERROR',
        payload: ex,
      });
    });
};
// FTP 조회
export const getCh0301Action = () => dispatch => {
  var url = window.location.href;
  url = url.split('//');
  url = url[1].substr(0, url[1].indexOf('/'));
  let apiUrl = 'http://' + url + '/json/getCh0301Action.json';
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      for (let idx01 in body) body[idx01]['key'] = idx01;

      dispatch({
        type: 'SET_CH0301',
        payload: body,
      });
    })
    .catch(ex => {
      dispatch({
        type: 'SET_CH0301_ERROR',
        payload: ex,
      });
    });
};
// API 조회
export const getCh0302Action = () => dispatch => {
  var url = window.location.href;
  url = url.split('//');
  url = url[1].substr(0, url[1].indexOf('/'));
  let apiUrl = 'http://' + url + '/json/getCh0302Action.json';
  return fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      for (let idx01 in body) body[idx01]['key'] = idx01;

      dispatch({
        type: 'SET_CH0302',
        payload: body,
      });
    })
    .catch(ex => {
      dispatch({
        type: 'SET_CH0302_ERROR',
        payload: ex,
      });
    });
};
