import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableBodyRow } from 'material-table';

export default function ABTestManualTable({ data, options, isResult, select, renderOrder, tableRef }) {
  const cp = isResult ? 'cp_id' : 'cp';
  const id = isResult ? 'content_id' : 'id';
  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = React.useState({ key: "", type: "" });
  const DrageState = {
    row: -1,
    dropIndex: -1, // drag target
    position: -1,
    dragPosition: -1,
    type: '',
    srtart: -1,
    end: -1,
  };

  useEffect(() => {
    // data.map((element, index) => (element.result_order = index + 1));
    setTableData(data);
    setOrder({ key: "", type: "" });
  }, [data]);

  //Reorder the table row
  const offsetIndex = (from, to, arr = []) => {
    if (from < to) {
      let start = arr.slice(0, from),
        between = arr.slice(from + 1, to + 1),
        end = arr.slice(to + 1);
      return [...start, ...between, arr[from], ...end];
    }
    if (from > to) {
      let start = arr.slice(0, to),
        between = arr.slice(to, from),
        end = arr.slice(from + 1);
      return [...start, arr[from], ...between, ...end];
    }
    return arr;
  };
  const reOrderRow = (from, to, type) => {
    let newtableData = [];
    if (type === 'Swap') {
      [tableData[from], tableData[to]] = [tableData[to], tableData[from]];
      let start = tableData[0],
        between = tableData.slice(1, tableData.length - 1),
        end = tableData[tableData.length - 1];
      newtableData = [start, ...between, end];
    } else if (type === 'Insert') {
      newtableData = offsetIndex(from, to, tableData);
    } else {
      return;
    }

    newtableData.map((element, index) => (element.result_order = index + 1));
    setTableData(newtableData);
  };

  const column = [
    { title: 'order', field: 'result_order', render: isResult ? renderOrder : () => { }, hidden: !isResult, defaultSort: order.key === "0" ? order.type : "" },
    { title: 'cpIdx', field: 'cp_idx', hidden: true, defaultSort: order.key === "1" ? order.type : "" },
    { title: 'type', field: 'type', defaultSort: order.key === "2" ? order.type : "" },
    { title: 'cp', field: cp, defaultSort: order.key === "3" ? order.type : "", },
    { title: 'id', field: id, defaultSort: order.key === "4" ? order.type : "", },
    { title: 'name', field: 'name', defaultSort: order.key === "5" ? order.type : "", },
    { title: 'image', render: n => <img alt="invalid" src={n.image} title={n.image} style={{ backgroundColor: 'black', maxWidth: 100, maxHeight: 100, border: '1px solid #cbccce' }} /> },
    { title: 'genre', field: 'genre', defaultSort: order.key === "7" ? order.type : "", },
    { title: 'priority', field: 'rank', hidden: isResult, defaultSort: order.key === "8" ? order.type : "", },
  ];

  return (
    <MaterialTable
      title=""
      columns={column}
      tableRef={tableRef}
      onOrderChange={(key, type) => {
        setOrder({ key: `${key}`, type });
      }
      }
      // data={[
      //   { cp: 'XUMO', id: '9999122', name: 'History', image: 'https://image.xumo.com/v1/channels/channel/9999290/320x180.png?type=channelTile' },
      //   { cp: 'XUMO', id: '9999123', name: 'History Shorts', image: 'https://image.xumo.com/v1/channels/channel/9999290/320x180.png?type=channelTile' },
      // ]}
      components={{
        Row: props => (
          <MTableBodyRow
            {...props}
            draggable="true"
            onDragStart={e => {
              DrageState.row = props.data.tableData.id;
              DrageState.srtart = e.target.getBoundingClientRect().top;
            }}
            onDragEnter={e => {
              e.preventDefault();
              if (props.data.tableData.id !== DrageState.row) {
                DrageState.dropIndex = props.data.tableData.id;
                DrageState.position = (e.target.parentElement.getBoundingClientRect().top + e.target.parentElement.getBoundingClientRect().bottom) / 2;
                document.getElementById('popup').style.display = 'block';
              }
              DrageState.end = e.target.parentElement.getBoundingClientRect().top;
              if (DrageState.srtart == DrageState.end) {
                document.getElementById('popup').style.display = 'none';
              }
            }}
            onDragOver={e => {
              DrageState.dragPosition = e.clientY;
              document.getElementById('popup').clientY = e.clientY;
              if (DrageState.position >= DrageState.dragPosition - 30 && DrageState.position <= DrageState.dragPosition + 30) {
                document.getElementById('popup').innerHTML = 'Swap';
              } else {
                document.getElementById('popup').innerHTML = 'Insert';
              }
              DrageState.type = document.getElementById('popup').innerHTML;
            }}
            onDragEnd={e => {
              if (DrageState.dropIndex !== -1 && DrageState.srtart != DrageState.end) {
                reOrderRow(DrageState.row, DrageState.dropIndex, DrageState.type);
              }
              DrageState.row = -1;
              DrageState.dropIndex = -1;
              DrageState.position = -1;
              DrageState.dragPosition = -1;
              DrageState.type = '';
              DrageState.srtart = -1;
              DrageState.end = -1;
              document.getElementById('popup').style.display = 'none';
            }}
          />
        ),
      }}
      data={tableData}
      options={options}
      onSelectionChange={select}
    />
  );
}
MaterialTable.propTypes = {};