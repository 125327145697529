/**
 * null check function
 *
 * @param {*} value
 * @returns
 */
export const isNull = value => {
  if (value === undefined || value === '') {
    return true;
  } else {
    return false;
  }
};

/**
 * isNull인 경우 default value 리턴 function
 *
 * @param {*} target
 * @param {*} defaultValue
 * @returns
 */
export const defaultValue = (target, defaultValue) => {
  return isNull(target) ? defaultValue : target;
};

/**
 * chart scales 10% margin
 * @param {*} value
 * @returns
 */
export const getScalesMaxValue = (value) => {
  let returnValue = 0;
  if (!isNull(value)) {
    returnValue = Math.round((value * 1.1)*10)/10;
    if(Math.round(returnValue) > Math.round(value)){
      returnValue = Math.round(returnValue);
    } else {
      returnValue = returnValue * 1.1;
    }
  }
  return returnValue
}

/**
 * input date toDay to -1day convert
 * @param {*} date
 * @returns
 */
export const getPreviousDateIfToday = (date) => {
  const inputDate = new Date(date)
  if (inputDate.toDateString() === new Date().toDateString()) {
    const previousDay = new Date()
    previousDay.setDate(previousDay.getDate() - 1)

    const month = String(previousDay.getMonth() + 1).padStart(2, '0');
    const day = String(previousDay.getDate()).padStart(2, '0');

    return `${previousDay.getFullYear()}-${month}-${day}`
  }
  return date
}