import React from 'react'; // React , useCallback, useEffect
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; // connect
import { withRouter } from 'react-router-dom'; // withRouter
import Button from '@material-ui/core/Button';
import SimpleLineContainer from './SimpleLineContainer';
import { CommonStatisticFilter } from '../../common/filter';
import { DateFilter } from '../../common/filter';
import CardChart from './CardChart';
import TableChart from './TableChart';
import { getLastMonth, getLastWeekDay } from '../../common/filter/statistic/DateFilter';
import { getStatisticAction, updateStatisticResultShow } from '../../actions/statisticActions';
import LoadingIndicator from '../../common/LoadingIndicator';
import NoData from '../../common/NoData';
import moment from "moment";
import SimpleCard from './SimpleCard';

function Summary({ isRoleAdmin, providerId, allCountries, platforms, getStatisticAction, dataFetching, data, statisticResultShow, updateStatisticResultShow }) {

  const [cp, setCp] = React.useState('All');
  const [cntry, setCntry] = React.useState(['All']);
  const [platform, setPlatform] = React.useState(['All']);
  const [dateType, setDateType] = React.useState('Monthly');
  const [month, setMonth] = React.useState(getLastMonth());
  const [week, setWeek] = React.useState(getLastWeekDay());
  const [dateList, setDateList] = React.useState();  // monthly or weekly

  React.useEffect(() => {
    getStatisticAction();
    updateStatisticResultShow(true);
  }, []);

  const onSearchClick = () => {
    getStatisticAction();

    let tempDateList = [];

    if (dateType === 'Monthly') {
      tempDateList[0] = month;
    } else {
      tempDateList[0] = week;
    }
    const platform2 = platform.includes('All') ? platforms : platform;
    const countries = cntry.includes('All') ? allCountries : cntry;

    const callback = (_months) => {
      setDateList(_months);
    }

    getStatisticAction(true, 'summary', tempDateList[0], isRoleAdmin ? cp : providerId, countries, platform2, callback);
  };

  return (
    <div>
      <div className="filter_area row">
      <CommonStatisticFilter
          cp={isRoleAdmin ? cp : null}
          handleChangeCp={e => setCp(e.target.value)}
          cntry={cntry}
          handleChangeCntry={e =>
            setCntry(
              e.currentTarget.dataset.value ==='All' && e.target.value.includes('All') ? ['All'] :
              e.target.value.length === 0 ? ['All'] : e.target.value.filter(n => n !== 'All')
            )
          }
          deleteCountry={(value, selected) =>
            setCntry(
              selected.filter(n => n !== value).length === 0 ? ['All'] : cntry.filter(n => n !== value)
            )
          }
          platform={platform}
          handleChangePlatform={e =>
            setPlatform(
              e.currentTarget.dataset.value ==='All' && e.target.value.includes('All') ? ['All'] :
              e.target.value.length === 0 ? ['All'] : e.target.value.filter(n => n !== 'All')
            )
          }
          deletePlatform={(value, selected) =>
            setPlatform(
              selected.filter(n => n !== value).length === 0 ? ['All'] : platform.filter(n => n !== value)
            )
          }
        />
        <DateFilter
          dateType={dateType}
          handleChange={e => {
            setDateType(e.target.value);
          }}
          handleChangeMonth={e => {
            let selectedMonth = moment(e).format('YYYY-MM');
            setMonth(selectedMonth);
          }}
          handleChangeWeek={e => {
            let selectedWeek = moment(e[0]).format('YYYY-MM-DD');
            setWeek(selectedWeek)
          }}
        />
      </div>
      <div className="btn_set_wrap search_wrap">
        <Button variant="contained" className={'btn_color2'} onClick={onSearchClick}>
          Search
        </Button>
      </div>
      {
        // dataFetching ? (
        //   <div className="loading_wrap">
        //     <LoadingIndicator />
        //   </div>
        // ) :
        data.status ? (
          <NoData message={data.message} /> //use NoData for ERROR MESSAGE!
        ) : (
          <div>
           {
            statisticResultShow === 'N' ? (
            <div className="filter_area row">
                <SimpleCard/>
            </div>
            ) : (
            <div>
              {dataFetching ? (
                <div className="loading_wrap">
                  <LoadingIndicator />
                </div>
              ) : (
                <React.Fragment>
                  <CardChart date={dateList && dateList[dateList.length - 1]} date2={dateList && dateList[dateList.length - 2]} />
                  <SimpleLineContainer />
                  {dateList && <TableChart dateList={dateList} date={dateList && dateList[dateList.length - 1]} date2={dateList && dateList[dateList.length - 2]} />}
                </React.Fragment>
              )}
            </div>
          )}
          </div>
        )}
    </div>
  );
}
Summary.propTypes = {
  isRoleAdmin: PropTypes.bool.isRequired,
  providerId: PropTypes.string,
  allCountries: PropTypes.array.isRequired,
  platforms: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  dataFetching: PropTypes.bool.isRequired,
  getStatisticAction: PropTypes.func.isRequired,
  statisticResultShow: PropTypes.string.isRequired,
  updateStatisticResultShow: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  isRoleAdmin: state.currentUser.isRoleAdmin,
  providerId: state.currentUser.providerId,
  allCountries: state.cntry.allCountries,
  platforms: state.cntry.platforms,
  data: state.statistic.data,
  dataFetching: state.statistic.dataFetching,
  statisticResultShow: state.statistic.statisticResultShow,
});
const mapDispatchToProps = dispatch => ({
  getStatisticAction(isRoleAdmin, menu, current, cp, country, platform, callback) {
    dispatch(getStatisticAction(isRoleAdmin, menu, current, cp, country, platform, callback));
  },
  updateStatisticResultShow(result) {
    dispatch(updateStatisticResultShow(result));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Summary));