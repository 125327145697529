import React, { Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from '@material-ui/core';
import { getChannelMapListByCountryCode } from '../../../actions/notificationActions';
import { searchTbTests, getABTestTabCategoryAction } from '../../../actions/homeAppActions';

const mobilePlatformList = [
  { label: 'Android', value: 'Android' },
  { label: 'IOS', value: 'IOS' },
];

function ABTestFilter({ getABTestTabCategoryAction, regionList, regionPlatformList, abTestTabCategory, searchTbTests, endTest, setEndTest, region, title, handleChangeTitle }) {
  const monthAgoDate = new Date();
  monthAgoDate.setMonth(monthAgoDate.getMonth() - 1);
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [abStatus, setAbStatus] = React.useState('All');
  const [dType, setDType] = React.useState('All');
  const [allCountries, setAllCountries] = React.useState([]);
  const [cntryCode, setCntryCode] = React.useState('All');
  const [regionCode, setRegionCode] = React.useState('');
  const [pList, setPList] = React.useState([]);
  const [pVersion, setPVersion] = React.useState('All');
  const [catCode, setCatCode] = React.useState('All');
  const [tbCode, setTbCode] = React.useState('All');

  const startDateChange = value => {
    setStartDate(value);
  };

  const endDateChange = value => {
    setEndDate(value);
  };

  const handleSearchSubmit = () => {
    searchTbTests(title, startDate, endDate, tbCode, catCode, dType, regionCode, pVersion, abStatus);
  };

  React.useEffect(() => {
    handleSearchSubmit();
  }, []);

  React.useEffect(() => {
    if (dType !== '' && regionCode !== '' && pVersion !== '') {
      getABTestTabCategoryAction(dType, cntryCode === 'All' ? 'All' : regionCode, pVersion);
    }
  }, [dType, regionCode, pVersion]);

  React.useEffect(() => {
    let catCodeList = [];
    abTestTabCategory.map(n => {
      n.categoryDetails.map(n2 => {
        catCodeList.push(n2.categoryCode + ' ' + n2.categoryName);
      });
    });
    const uniqTabCode = [...new Map(abTestTabCategory.map(item => [item['tabCode'], item])).values()];
    setTbCode(uniqTabCode.map(r => r.tabCode).includes(tbCode) ? tbCode : 'All')
    if (tbCode == 'All') {
      finalData = [];
      abTestTabCategory.forEach(n => {
        if (n.categoryDetails) {
          finalData = [...finalData, ...n.categoryDetails];
        }
      });
      setCatCode(finalData.map(r => r.categoryCode).includes(catCode) ? catCode : 'All')
    } else {
      setCatCode((abTestTabCategory.find(n => n.tabCode == tbCode) || { categoryDetails: [] }).categoryDetails.map(r => r.categoryCode).includes(catCode) ? catCode : 'All')
    }
  }, [abTestTabCategory]);

  useEffect(() => {
    if (endTest) {
      endTest && searchTbTests(title, startDate, endDate, tbCode, catCode, dType, regionCode, pVersion, abStatus);
      setEndTest(false);
    }

    let cntry = [];
    regionList.map(region => {
      region.list.map(lst => {
        lst.cntry.map(cnt => {
          if (cntry.indexOf(cnt) === -1) {
            cntry = [...cntry, cnt];
          }
        });
      });
    });
    cntry = cntry.sort();
    setAllCountries([...cntry]);
  }, [endTest]);

  useEffect(() => {
    let regionCde = findRegion();
    let pVersion = [];
    setRegionCode(regionCde);

    if (dType === 'All' && cntryCode === 'All') {
      regionPlatformList.map(n2 => {
        if (!pVersion.includes(n2.platformVersion)) {
          pVersion.push(n2.platformVersion);
        }
      });
    } else if (dType === 'All' && cntryCode !== 'All') {
      regionPlatformList
        .filter(n => n.region === regionCde)
        .map(n2 => {
          if (!pVersion.includes(n2.platformVersion)) {
            pVersion.push(n2.platformVersion);
          }
        });
    } else if (dType !== 'All' && cntryCode === 'All') {
      regionPlatformList
        .filter(n => n.deviceType === dType)
        .map(n2 => {
          if (!pVersion.includes(n2.platformVersion)) {
            pVersion.push(n2.platformVersion);
          }
        });
    } else if (dType !== 'All' && cntryCode !== 'All') {
      regionPlatformList
        .filter(n => n.deviceType === dType && n.region === regionCde)
        .map(n2 => {
          if (!pVersion.includes(n2.platformVersion)) {
            pVersion.push(n2.platformVersion);
          }
        });
    }
    setPList([...pVersion]);
  }, [dType, cntryCode]);

  const handleChangeStatus = value => {
    setAbStatus(value);
  };

  const handleChangeDType = value => {
    setDType(value);
  };

  const handleChangeCntCode = value => {
    setCntryCode(value);
    setRegionCode('');
  };

  const handleChangeCatCode = value => {
    setCatCode(value);
  };

  const handleChangeTbCode = value => {
    setTbCode(value);
  };

  const handleChangePVersion = value => {
    setPVersion(value);
  };

  function findRegion() {
    let region;

    if (dType !== 'All') {
      regionList.map(n2 => {
        if (n2.deviceType === dType) {
          n2.list.map(n3 => {
            if (n3.cntry.includes(cntryCode)) {
              region = n3.region;
            }
          });
        }
      });
    } else {
      regionList.map(n2 => {
        n2.list.map(n3 => {
          if (n3.cntry.includes(cntryCode)) {
            region = n3.region;
          }
        });
      });
    }

    return region;
  }

  const uniqTabCode = [...new Map(abTestTabCategory.map(item => [item['tabCode'], item])).values()];
  let finalData = [];
  abTestTabCategory.forEach(n => {
    if (n.categoryDetails) {
      finalData = [...finalData, ...n.categoryDetails];
    }
  });
  const getUniqueCatByTbCode = useMemo(() => {
    let finalData = [];
    abTestTabCategory.forEach(n => {
      if (n.categoryDetails) {
        finalData = [...finalData, ...n.categoryDetails];
      }
    });
    const uniqcategoryDetails = [...new Map(finalData.map(item => [item['categoryCode'] + '' + item['categoryName'], item])).values()];
    return uniqcategoryDetails || [];
  }, [abTestTabCategory]);

  return (
    <Fragment>
      <div className="filter_area row">
        {/* <TextField fullWidth margin="dense" variant="outlined" label="Title" value={title} onChange={handleChangeTitle} /> */}
        <div className="filter_row">
          <TextField
            id="abTestTitle"
            name="abTestTitle"
            label="Abtest Title"
            placeholder="Abtest Title"
            variant="outlined"
            defaultValue={title}
            onBlur={handleChangeTitle}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            variant="outlined"
            name="startDate"
            id="startDate"
            label="Start Date"
            type="date"
            value={startDate ? startDate : ''}
            onChange={e => startDateChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ max: endDate }}
          />

          <TextField
            variant="outlined"
            name="endDate"
            id="endDate"
            label="End Date"
            type="date"
            value={endDate ? endDate : ''}
            onChange={e => endDateChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: startDate }}
          />

          {abTestTabCategory && (
            <React.Fragment>
              <FormControl variant="outlined">
                <InputLabel>Device Type</InputLabel>
                <Select value={dType} onChange={e => handleChangeDType(e.target.value)}>
                  {regionList && (
                    <MenuItem key="All" value="All">
                      All
                    </MenuItem>
                  )}
                  {regionList &&
                    regionList.map(n => (
                      <MenuItem key={n.deviceType} value={n.deviceType}>
                        {n.deviceType}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </React.Fragment>
          )}

          {(
            <React.Fragment>
              <FormControl variant="outlined">
                <InputLabel /*id="Region"없어도 되는듯*/>Country</InputLabel>
                <Select value={cntryCode} onChange={e => handleChangeCntCode(e.target.value)}>
                  {regionList && (
                    <MenuItem key="All" value="All">
                      All
                    </MenuItem>
                  )}
                  {allCountries &&
                    dType === 'All' &&
                    allCountries.map(n2 => (
                      <MenuItem key={n2} value={n2}>
                        {n2}
                      </MenuItem>
                    ))}
                  {regionList &&
                    (regionList.find(n => n.deviceType === dType) || { list: [] }).list
                      .reduce((ac, a) => {
                        ac = [...ac, ...a.cntry];
                        return ac;
                      }, [])
                      .map(n2 => (
                        <MenuItem key={n2} value={n2}>
                          {n2}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </React.Fragment>
          )}
        </div>
        <div className="filter_row">
          {(
            <React.Fragment>
              <FormControl variant="outlined">
                <InputLabel /*id="Region"없어도 되는듯*/>platform version</InputLabel>
                <Select value={pVersion} onChange={e => handleChangePVersion(e.target.value)}>
                  {pList.length > 0 && (
                    <MenuItem key="All" value="All">
                      All
                    </MenuItem>
                  )}
                  {pList &&
                    pList.map(n2 => (
                      <MenuItem key={n2} value={n2}>
                        {n2}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </React.Fragment>
          )}

          {abTestTabCategory && (
            <FormControl variant="outlined">
              <InputLabel>Tab</InputLabel>
              <Select name="channelMapList" id="search_channelMapList" value={uniqTabCode.map(r => r.tabCode).includes(tbCode) ? tbCode : 'All'} onChange={e => handleChangeTbCode(e.target.value)}>
                {
                  <MenuItem key="All" value="All">
                    All
                  </MenuItem>
                }
                {uniqTabCode.map((n, idx) => (
                  <MenuItem key={`${n.tabCode}_${idx}`} value={n.tabCode}>
                    {n.tabCode + ' ' + n.tabName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {abTestTabCategory && (
            <FormControl variant="outlined">
              <InputLabel>Category</InputLabel>
              <Select name="channelMapList" id="search_channelMapList" value={tbCode == 'All' ? finalData.map(r => r.categoryCode).includes(catCode) ? catCode : 'All' : (abTestTabCategory.find(n => n.tabCode == tbCode) || { categoryDetails: [] }).categoryDetails.map(r => r.categoryCode).includes(catCode) ? catCode : 'All'} onChange={e => handleChangeCatCode(e.target.value)}>
                {
                  <MenuItem key="All" value="All">
                    All
                  </MenuItem>
                }
                {tbCode === 'All' &&
                  getUniqueCatByTbCode.map(n2 => (
                    <MenuItem key={n2.categoryCode} value={n2.categoryCode}>
                      {n2.categoryCode + ' ' + n2.categoryName}
                    </MenuItem>
                  ))}
                {tbCode !== 'All' &&
                  (abTestTabCategory.find(n => n.tabCode == tbCode) || { categoryDetails: [] }).categoryDetails.map(n => (
                    <MenuItem key={n.categoryCode} value={n.categoryCode}>
                      {n.categoryCode + ' ' + n.categoryName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

          <FormControl variant="outlined">
            <InputLabel>Status</InputLabel>
            <Select value={abStatus} onChange={e => handleChangeStatus(e.target.value)}>
              {['All', 'Ready', 'Testing', 'Done'].map(n => (
                <MenuItem key={n} value={n}>
                  {n}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="MuiFormControl-root btn_set_wrap search_wrap">
            <Button variant="contained" className={'btn_color2'} onClick={handleSearchSubmit}>
              Search
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
ABTestFilter.propTypes = {
  regionList: PropTypes.array.isRequired,
  regionPlatformList: PropTypes.array.isRequired,
  regionCountries: PropTypes.array.isRequired,
  searchData: PropTypes.object.isRequired,
  handleSearchDataChange: PropTypes.func.isRequired,
  getChannelMapList: PropTypes.func.isRequired,
  channelMapList: PropTypes.array.isRequired,
  requiredField: PropTypes.bool.isRequired,
  abTestTabCategory: PropTypes.array.isRequired,
  searchTbTests: PropTypes.array.isRequired,
  endTest: PropTypes.bool.isRequired,
  abTestDetails: PropTypes.array.isRequired,
  getABTestTabCategoryAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  regionCountries: state.cntry.regionCountries,
  channelMapList: state.homeApp.notificationsChannelMapList,
});

const mapDispatchToProps = dispatch => ({
  getABTestTabCategoryAction(deviceType, region, platformVersion) {
    dispatch(getABTestTabCategoryAction(deviceType, region, platformVersion));
  },
  getChannelMapList: countryCodes => dispatch(getChannelMapListByCountryCode(countryCodes)),
  searchTbTests: (title, startDate, endDate, tbCode, catCode, dType, regionCode, pVersion, abStatus) => dispatch(searchTbTests(title, startDate, endDate, tbCode, catCode, dType, regionCode, pVersion, abStatus)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ABTestFilter);
