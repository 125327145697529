import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingIndicator from '../../../common/LoadingIndicator';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getTabCategoryAction, getTabCategoryResultAction } from '../../../actions/homeAppActions';
import { getFreeONLGChannelsSettingListAction } from '../../../actions/freeONLgChannelActions';
import { isNormalInteger } from '../../../util/util';
import FreeOnLgChannelsResult from './FreeOnLgChannelsResult';
import FreeOnLgChannelSearch from './FreeOnLgChannelSearch';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';

function FreeOnLgChannels({ id, tabCategory, param, fetching, getFreeONLGChannelsSettingListAction, freeOnLgChSettingList, allCountries }) {
  const [cat, setCat] = React.useState(tabCategory.find(n => n.categoryId == id) || {});
  const [cntryList, setCntryList] = React.useState([' ']);
  const [cntry, setCntry] = React.useState('US');
  const [result, setResult] = React.useState([]);
  const [addData, setAddData] = React.useState([]);
  const [deleteData, setDeleteData] = React.useState([]);
  const selectedTableRef = useRef();
  const [isLastMonth, setIsLastMonth] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState();
  const [masBatchTime, setMasBatchTime] = React.useState(13);
  const [masBatchFlag, setMasBatchFlag] = React.useState(false);
  const [utcHour, setUtcHour] = React.useState('');
  const [isItEdit, setIsItEdit] = React.useState(false);
  const [insertTableData, setInsertTableData] = React.useState([]);
  const [typeData, setTypeData] = React.useState(' ');
  const [dataAdded, setDataAdded] = React.useState(false);
  const [deletedResult, setDeletedResult] = React.useState([]);
  const [isItReorder, setIsItReorder] = React.useState(false);

  const renderOrderEdit = n => {
    const getval = () => {
      return isItEdit ? n.resultOrder : undefined;
    }
    return (
      <TextField
        key={`freect-order-${n.content_id}`}
        id={`rreect-order-${n.content_id}`}
        fullWidth
        margin="dense"
        onFocus={() => setIsItEdit(false)}
        value={getval()}
        defaultValue={n.result_order}
        onBlur={e => {
          if (e.target.value.trim() === '' || e.target.value < 1) {
            alert('The order must be greater than 0 ! Please re-enter !');
            e.target.value = n.result_order;
          }
          else if (!isNormalInteger(e.target.value) && e.target.value !== '') {
            alert('The order is not natural number ! Please enter natural number !');
            e.target.value = n.result_order;
          }
          else {
            if (typeData === 'Insert') {
              const newResult = insertTableData.slice();
              newResult.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id).result_order = e.target.value;
              setResult(newResult);
            }
            else {
              const newResult = result.slice();
              newResult.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id).result_order = e.target.value;
              setResult(newResult);
            }
          }
        }}
      />)
  };

  const renderOrder = n => (
    <TextField
      key={`freect-order-${n.content_id}`}
      id={`freect-order-${n.content_id}`}
      fullWidth
      margin="dense"
      value={n.result_order}
      onChange={e => {
        if (e.target.value.trim() === '' || e.target.value < 1) {
          alert('The order must be greater than 0 ! Please re-enter !')
          e.target.value = n.result_order;
        }
        else if (!isNormalInteger(e.target.value) && e.target.value !== '') {
          alert('The order is not natural number ! Please enter natural number !');
          e.target.value = n.result_order;
        }
        else {
          if (typeData === 'Insert') {
            const newResult = insertTableData.slice();
            newResult.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id).result_order = e.target.value;
            setResult(newResult);
          } else {
            const newResult = result.slice();
            newResult.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id).result_order = e.target.value;
            setResult(newResult);
          }
        }
      }}
    />
  );
  const flagList = [
    { value: 'Y', label: 'Y' },
    { value: 'N', label: 'N' },
  ];
  const renderFixFlag = rowData => {
    const defaultFixFlag = rowData.fix_flag ? rowData.fix_flag : 'Y';
    return (
      <div>
        <Select
          name="fix"
          defaultValue={defaultFixFlag}
          onBlur={e => onFixFlagCode(e, rowData)}
        >
          {flagList.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {`${item.value}`}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };
  const onFixFlagCode = (e, rowData) => {
    const newResult = result.slice();
    newResult.find(n2 => n2.cp_idx === rowData.cp_idx && n2.content_id === rowData.content_id).fix_flag = e.target.value;
  }

  const getCurrentMonthYear = () => {
    const currentDate = new Date();
    const year = currentDate.getUTCFullYear();
    let month = currentDate.getUTCMonth() + 1;
    if (month < 10) {
      month = '0' + month; // Prefix single-digit months with 0
    }
    return `${year}-${month}`;
  };
  const getPreviousMonthYear = () => {
    const currentDate = new Date();
    let year = currentDate.getUTCFullYear();
    let month = currentDate.getUTCMonth() + 1;

    // Calculate the starting month for the previous 9 months
    let startMonth = month - 12;
    if (startMonth <= 0) {
      // If start month is less than or equal to 0, adjust year and month accordingly
      year -= 1;
      startMonth += 12;
    }
    if (startMonth < 12) {
      startMonth = '0' + startMonth; // Prefix single-digit months with 0
    }
    return `${year}-${startMonth}`;
  };


  const getNextMonthYear = () => {
    const currentDate = new Date();
    currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
    const year = currentDate.getUTCFullYear();
    let month = currentDate.getUTCMonth() + 1;
    if (month < 10) {
      month = '0' + month; // Prefix single-digit months with 0
    }
    return `${year}-${month}`;
  };

  const [recommendMonth, setRecommendMonth] = React.useState(getCurrentMonthYear());
  const checkTvShowSearies = rows => {
    return rows.find(
      n =>
        result.filter(r => {
          if (r.category_idx) {
            return r.provider_idx === n.cp_idx && r.series_id === n.series_id && n.type === 'tvshow-series';
          } else {
            return r.cp_idx === n.cp_idx && r.series_id === n.series_id && n.type === 'tvshow-series';
          }
        })[0],
    );
  };

  const checkDuplicateSeriesList = data => {
    const tvShowData = data.filter(n => n.type === 'tvshow-series');
    const lookup = tvShowData.reduce((a, e) => {
      a[e.series_id] = ++a[e.series_id] || 0;
      return a;
    }, {});

    return tvShowData.filter(e => lookup[e.series_id]).length >= 2;
  };

  const selectSearch = rows => {
    let dataIsChecked = selectedTableRef.current.dataManager.data.filter(o => o.tableData.disabled)
  };

  const selectResult = rows => {
    setDeleteData(
      rows.map(n => {
        const { cp_idx, content_id, name, type } = n;
        return { cp_idx: cp_idx, content_id: content_id, name: name, type: type };
      }),
    );
  };

  const addResult = () => {
    let startOrder = Math.max(...result.map(n => n.result_order)) + 1;
    startOrder = startOrder === -Infinity ? 1 : startOrder;
    let dataIsChecked = selectedTableRef.current.dataManager.data;
    dataIsChecked = dataIsChecked.filter(o => o.tableData.checked && o.eligible_to_transfer === 'true')

    dataIsChecked = dataIsChecked.map((n, i) => {
      const { cp_idx, cp, id, content_id, name, image, type, series_id, genre, image_width, image_height, last_chg_date, vod_idx, deeplink_id, eligible_to_transfer, fix_flag } = n;
      return { cp_idx: cp_idx, cp: cp, name: name, image: image, country_code: cntry, type: type, id: id, content_id: id, series_id: series_id, genre: genre, image_width: image_width, image_height: image_height, last_chg_date: last_chg_date, vod_idx: vod_idx, deeplink_id: deeplink_id, eligible_to_transfer: eligible_to_transfer, result_order: startOrder + i, fix_flag: fix_flag };
    })

    result.forEach(addedResult => {
      let dataIsCheckedFound = dataIsChecked.find(rk => rk.content_id === addedResult.content_id && rk.cp_idx === addedResult.cp_idx)
      if (dataIsCheckedFound) {
        dataIsChecked = dataIsChecked.filter(item => item !== dataIsCheckedFound)
      }
    })

    if (dataIsChecked.find(n => n.eligible_to_transfer === 'false')) {
      alert('Selected content is not eligible to configure!');
      return;
    } else if (dataIsChecked.find(n => result.find(n2 => n2.content_id === n.content_id && n2.cp_idx === n.cp_idx))) {
      alert('content is already exist!');
      return;
    } if (dataIsChecked.length > 0) {
      selectedTableRef.current.onAllSelected(false);
      result.sort((a, b) => a.result_order - b.result_order);
      setResult(result.concat(dataIsChecked));
      setInsertTableData(insertTableData.concat(dataIsChecked))
      setAddData([]);
      setDataAdded(true)
    } else {
      alert('Please select the content to add');
    }
  };

  const removeResult = () => {
    setIsItEdit(true);
    if (deleteData.length < 1) {
      alert('Please select the content to remove');
    } else {
      if (typeData === 'Insert') {
        setResult(insertTableData.filter(n => !deleteData.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id)));
        setInsertTableData(insertTableData.filter(n => !deleteData.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id)));
      } else {
        setDeletedResult(result)
        setResult(result.filter(n => !deleteData.find(n2 => n2.cp_idx === n.cp_idx && n2.content_id === n.content_id)));
      }
    }
    setDeleteData([]);
  };
  React.useEffect(() => {
    if (cntry != ' ') {
      getFreeONLGChannelsSettingListAction(cntry, recommendMonth);
      setResult(freeOnLgChSettingList.list);
      if (cntry === 'US') {
        setMasBatchTime(13);
        setMasBatchFlag(true);
      } else if (cntry === 'DE') {
        setMasBatchTime(11);
        setMasBatchFlag(true);
      } else if (cntry === 'IT') {
        setMasBatchTime(11);
        setMasBatchFlag(true);
      } else if (cntry === 'ES') {
        setMasBatchTime(13);
        setMasBatchFlag(true);
      } else if (cntry === 'FR') {
        setMasBatchTime(15);
        setMasBatchFlag(true);
      } else if (cntry === 'GB') {
        setMasBatchTime(16);
        setMasBatchFlag(true);
      } else {
        setMasBatchTime(0);
        setMasBatchFlag(false);
      }
    }
  }, [cntry, recommendMonth]);

  const fetchUTCTime = async () => {
    let utcHour = await getUTCHour();
    setUtcHour(utcHour);
  };
  async function getUTCHour() {
    let utcHour = await fetch(API_BASE_URL + '/admin/freeonLgChannel/utchour', {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        return body;
      });
    return utcHour;
  }

  React.useEffect(() => {
    setCntryList([...allCountries].sort());
    fetchUTCTime();
  }, [allCountries]);

  React.useEffect(() => {
    if (cntry === 'US') {
      setMasBatchTime(13);
      setMasBatchFlag(true);
    }
  }, [cntry]);

  const handleDateChange = (data) => {
    const selectedYearMonth = parseInt(data.target.value.replace('-', ''));
    setRecommendMonth(data.target.value);
    const currentDate = new Date();
    const currentYearMonth = parseInt(currentDate.getUTCFullYear() + (currentDate.getUTCMonth() > 9 ? currentDate.getUTCMonth() : '0' + currentDate.getUTCMonth()));
    setIsLastMonth(selectedYearMonth <= currentYearMonth);
  }
  const handleSetResult = (data) => {
    setResult([...data])
  }
  const handleSetInsertTableData = (data) => {
    setInsertTableData([...data])
  }
  const handleSetTypeData = (data) => {
    setTypeData(data)
  }
  return (
    <div>
      <React.Fragment>
        <CntryFilter cntryList={cntryList} cntry={cntry} setCntry={setCntry} setMasBatchTime={setMasBatchTime} />
        <div className="channel_mapping_wrap">

          <FreeOnLgChannelSearch cntry={cntry} tableRef={selectedTableRef} cat={cat} selectSearch={selectSearch} status={param.status.charAt(0)} categoryResultData={result} region={param.region} isLastMonth={isLastMonth} />
          {fetching ?
            (
              <div className="loading_wrap">
                <LoadingIndicator />
              </div>
            ) :
            (
              <FreeOnLgChannelsResult cntry={cntry} cat={cat} result={result} selectResult={selectResult} handleSetDeleteData={setDeleteData} onClickAdd={addResult} onClickDelete={removeResult} recommendMonth={recommendMonth}
                handleDateChange={handleDateChange} getPreviousMonthYear={getPreviousMonthYear} getNextMonthYear={getNextMonthYear} isLastMonth={isLastMonth} masBatchTime={masBatchTime} masBatchFlag={masBatchFlag} utcHour={utcHour} renderOrder={renderOrder} renderOrderEdit={renderOrderEdit}
                handleSetInsertTableData={handleSetInsertTableData} handleSetTypeData={handleSetTypeData} typeData={typeData} insertTableData={insertTableData} setTypeData={setTypeData} handleSetResult={handleSetResult} renderFixFlag={renderFixFlag} setDataAdded={setDataAdded} dataAdded={dataAdded} deletedResult={deletedResult} setDeletedResult={setDeletedResult} setIsItReorder={setIsItReorder} />
            )
          }
        </div>
      </React.Fragment>
    </div>
  );
}
FreeOnLgChannels.propTypes = {};
const mapStateToProps = state => ({
  fetching: state.freeOnLGChannelView.freeONLGChannelSettingListFetching,
  freeOnLgChSettingList: state.freeOnLGChannelView.freeONLGChannelSettingList,
  regionList: state.homeApp.regionList,
  param: state.homeApp.param,
  tabCategory: state.homeApp.tabCategory,
  allCountries: state.cntry.allCountries,
});
const mapDispatchToProps = dispatch => ({
  getTabCategoryAction(deviceType, region, platformVersion, status) {
    dispatch(getTabCategoryAction(deviceType, region, platformVersion, status));
  },
  getTabCategoryResultAction(cntry, id) {
    dispatch(getTabCategoryResultAction(cntry, id));
  },
  getFreeONLGChannelsSettingListAction(cntry, recommendedMonth) {
    dispatch(getFreeONLGChannelsSettingListAction(cntry, recommendedMonth));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FreeOnLgChannels));
export function CntryFilter({ cntryList, cntry, setCntry, setMasBatchTime }) {
  return (
    <div className="filter_area row manualCat">
      <div className="filter_row manualCat">
        <FormControl variant="outlined">
          <InputLabel>Country</InputLabel>
          <Select
            value={cntry}
            onChange={e => {
              setCntry(e.target.value);
            }}>
            {cntryList.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
