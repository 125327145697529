import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, DialogTitle, DialogContent } from '@material-ui/core';
import { saveAnTestConfiguration, setABTestParamAction, searchTbTests, endAbTest, getAbTestResultAction, getABTest, getABTestTabCategoryAction } from '../../../actions/homeAppActions';
import AddABTestFilter from './AddABTestFilter';
import { request } from '../../../util/request';
import { API_BASE_URL } from '../../../constants';
import { getHeaders } from '../../../util/actionUtil';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function EditABTest({ saveAnTestConfiguration, abTestTabCategory, id, abTestParam, setABTestParamAction, regionList, regionPlatformList, getABTestTabCategoryAction, handleTabClose }) {
  const today = new Date();
  const yestreday = new Date();
  const nextMonthDate = new Date();
  yestreday.setDate(today.getDate() - 1);
  nextMonthDate.setMonth(today.getMonth() + 1);
  const monthAgoDate = new Date();
  monthAgoDate.setMonth(monthAgoDate.getMonth() - 1);
  const [result, setResult] = React.useState([]);
  const [time, setTime] = useState();
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = React.useState(today.toISOString().slice(0, 10));
  const [endDate, setEndDate] = React.useState(monthAgoDate.toISOString().slice(0, 10));
  const [startTimeAnt, setStartTimeAnt] = useState(0);
  const [endTimeAnt, setEndTimeAnt] = React.useState(0);
  const [tabCode, setTabCode] = React.useState('');
  const [categoryCode, setCategoryCode] = React.useState('');
  const [mappingType, setMappingType] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');
  const [deviceType, setDeviceType] = React.useState('');
  const [platformVersion, setPlatformVersion] = React.useState('');
  const [endDateCheck, setEndDateCheck] = React.useState();
  const [validToUpdate, setValidToUpdate] = React.useState(true);
  const [abtestId, setAbtestId] = React.useState('');
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth() + 1 > 9 ? now.getUTCMonth() + 1 : '0' + (now.getUTCMonth() + 1);
  const day = now.getUTCDate() > 9 ? now.getUTCDate() : '0' + now.getUTCDate();
  const hours = now.getUTCHours() > 9 ? now.getUTCHours() : '0' + now.getUTCHours();
  const minutes = now.getUTCMinutes() > 9 ? now.getUTCMinutes() : '0' + now.getUTCMinutes();
  const seconds = now.getUTCSeconds() > 9 ? now.getUTCSeconds() : '0' + now.getUTCSeconds();
  const [abStatus, setAbStatus] = React.useState('All');
  const [dType, setDType] = React.useState('All');
  const [pVersion, setPVersion] = React.useState('All');
  const [catCode, setCatCode] = React.useState('All');
  const [tbCode, setTbCode] = React.useState('All');
  const [abTestTitle, setAbTestTitle] = React.useState('');
  const [startDt, setStartDt] = React.useState('');
  const [endDt, setEndDt] = React.useState('');
  const [regionLoad, setRegionLoad] = React.useState('');
  const [startTime, setStartTime] = React.useState();
  const [disableUpdate, setDisableUpdate] = React.useState(false);

  React.useEffect(() => {
    setTime(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
    fetchTbTestData();
  }, [id]);

  const fetchTbTestData = async () => {
    const tbTestData = await request(`/api/abtest/${id}`, 'GET', null, true);
    setTitle(tbTestData.title);
    setStartDate(tbTestData.startTime.split(' ')[0]);
    let stime = tbTestData.startTime.split(' ')[1].split(':')[0];
    stime = stime.startsWith(0) ? stime.substring(1, 2) : stime;
    setStartTimeAnt(stime);
    setEndDate(tbTestData.endTime.split(' ')[0]);
    let etime = tbTestData.endTime.split(' ')[1].split(':')[0];
    etime = etime.startsWith(0) ? etime.substring(1, 2) : etime;
    setEndTimeAnt(etime);
    setDeviceType(tbTestData.deviceType);
    setCountryCode(tbTestData.countryCode);
    setPlatformVersion(tbTestData.platformVersion);
    setTabCode(tbTestData.tabCode);
    setCategoryCode(tbTestData.categoryCode);
    setMappingType(tbTestData.mappingType);
    setEndDateCheck(tbTestData.endTime);
    setAbtestId(tbTestData.abtestId);
    setStartTime(tbTestData.startTime);
    var startTime = new Date(tbTestData.startTime);
    startTime.setDate(startTime.getDate() - 1);
    let utcHour = await getUTCHour();
    startTime.setHours(utcHour);
    var todayDateUTC = new Date(formatDate(new Date()));
    setValidToUpdate(checkForeligebleForEdit(tbTestData) || todayDateUTC > startTime);
    setDisableUpdate(new Date(tbTestData.forcedEndTime) <= new Date(formatDate(new Date())))
    setABTestParamAction(tbTestData.deviceType, tbTestData.countryCode, tbTestData.region, tbTestData.platformVersion, '', '');
  };

  async function getUTCHour() {
    let utcHour = await fetch(API_BASE_URL + '/abtest/utchour', {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(body => {
        return body;
      });
    return utcHour;
  }

  function checkForeligebleForEdit(ab) {
    if (new Date(ab.forcedEndTime) <= new Date(formatDate(new Date()))) {
      return true;
    } else if (new Date(formatDate(new Date())) < new Date(ab.startTime)) {
      return false;
    } else {
      return true;
    }
  }

  const clickSave = async e => {
    e.preventDefault();
    if (!title || title.trim().length === 0) alert('Enter the Title');
    else if (title.length > 255) alert('Title should be within 255 characters');
    else if (startTimeAnt === '') alert('Select start time');
    else if (!endDate) alert('Select an end date');
    else if (endTimeAnt === '') alert('Select end time');
    else if (!tabCode) alert('Select a tab code');
    else if (!categoryCode) alert('Select a category code');
    //else if(!countryCode) alert('Select a country');
    //else if (result.length > 15) alert('The number of result is more than 15 ! Please remove some content !');
    else if (result.find(n => n.result_order === '')) alert('Some order is empty ! Please enter the order !');
    else if (window.confirm('Are you sure to update the result?')) {
      let startTime = startTimeAnt > 9 ? startDate + ' ' + startTimeAnt + ':00:00' : startDate + ' 0' + startTimeAnt + ':00:00';
      var startTimeNow = new Date(startTime);
      startTimeNow.setDate(startTimeNow.getDate() - 1);
      let utcHour = await getUTCHour();
      startTimeNow.setHours(utcHour);
      var todayDateUTC = new Date(formatDate(new Date()));
      /* if (todayDateUTC > startTimeNow) {
         alert('AB Test Update is not possible!');
         return false;
       } */
      let endTime = endTimeAnt > 9 ? endDate + ' ' + endTimeAnt + ':00:00' : endDate + ' 0' + endTimeAnt + ':00:00';
      let idx = id;
      const addAbTestData = {
        idx,
        title,
        startTime,
        endTime,
        tabCode,
        categoryCode,
        mappingType,
        countryCode,
        abtestId,
        ...abTestParam
      };
      saveAnTestConfiguration(addAbTestData, abTestTitle, startDt, endDt, tbCode, catCode, dType, regionLoad, pVersion, abStatus);
      handleTabClose();
    }
  };

  const getCountryCode = value => {
    gePtlatformVersion(value);
    return regionList.find(n => n.deviceType === value).list[0].cntry;
  };

  const gePtlatformVersion = (deviceType, region) => {
    if (regionPlatformList.find(n => n.deviceType === deviceType && n.region === region)) {
      return regionPlatformList.find(n => n.deviceType === deviceType && n.region === region).platformVersion;
    }
    return '';
  };

  const getRegion = (deviceType, countryCode) => {
    abTestParam.region = '';
    (regionList.find(n => n.deviceType === deviceType) || { list: [] }).list.forEach(a => {
      if (a.cntry.includes(countryCode)) {
        abTestParam.region = a.region;
      }
    });
    return abTestParam.region;
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [date.getFullYear(), padTo2Digits(date.getUTCMonth() + 1), padTo2Digits(date.getUTCDate())].join('-') + ' ' + [padTo2Digits(date.getUTCHours()), padTo2Digits(date.getUTCMinutes()), padTo2Digits(date.getUTCSeconds())].join(':');
  }
  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        Edit A/N Test
        <IconButton aria-label="close" onClick={handleTabClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form id="addRowFrm" autoComplete="off">
        <DialogContent className={'pop_body'}>
          <div>
            <AddABTestFilter
              title={title}
              handleChangeTitle={e => {
                setTitle(e.target.value);
              }}
              startDate={startDate}
              handleChangeStartDate={e => {
                setStartDate(e.target.value);
                setEndDate('');
              }}
              startTime={startTimeAnt}
              handleChangeStartTime={e => {
                setStartTimeAnt(e.target.value);
              }}
              endDate={endDate}
              handleChangeEndDate={e => {
                setEndDate(e.target.value);
              }}
              endTime={endTimeAnt}
              handleChangeEndTime={e => {
                setEndTimeAnt(e.target.value);
              }}
              regionList={regionList}
              deviceType={abTestParam.deviceType}
              handleChangeDeviceType={e => {
                setABTestParamAction(e.target.value, getCountryCode(e.target.value), regionList.find(n => n.deviceType === e.target.value).list[0].region, gePtlatformVersion(e.target.value, regionList.find(n => n.deviceType === e.target.value).list[0].region), abTestParam.mappingType);
                setTabCode('');
                setCategoryCode('');
              }}
              countryCode={abTestParam.countryCode}
              region={getRegion(deviceType, countryCode)}
              handleChangeCountry={e => {
                setABTestParamAction(abTestParam.deviceType, e.target.value, getRegion(deviceType, e.target.value), gePtlatformVersion(abTestParam.deviceType, e.target.value), abTestParam.mappingType);
                setTabCode('');
                setCategoryCode('');
              }}
              regionPlatformList={regionPlatformList}
              platformVersion={abTestParam.platformVersion}
              abTestTabCategory={abTestTabCategory}
              handleChangePlatformVersion={e => {
                setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, e.target.value, abTestParam.mappingType);
                setTabCode('');
                setCategoryCode('');
              }}
              tabCode={tabCode}
              handleChangeTab={e => {
                setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, abTestParam.mappingType);
                setTabCode(e.target.value);
              }}
              categoryCode={categoryCode}
              handleChangeCategory={e => {
                setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, abTestParam.mappingType);
                setCategoryCode(e.target.value);
              }}
              mappingType={abTestParam.mappingType}
              handleChangeMappingType={e => {
                setABTestParamAction(abTestParam.deviceType, abTestParam.countryCode, abTestParam.region, abTestParam.platformVersion, e.target.value);
              }}
              isItEdit={validToUpdate}
            />
          </div>
          <div className={'btn_set_wrap'}>
            <Button data-testid="addButton" color="primary" onClick={handleTabClose} className={'btn_color1'}>
              Cancel
            </Button>
          </div>
        </DialogContent>
      </form>
    </Fragment>
  );
}

EditABTest.propTypes = {
  notifications: PropTypes.array.isRequired,
  abTestTabCategory: PropTypes.array.isRequired,
  abTestParam: PropTypes.object.isRequired,
  getNotifications: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  getABTestTabCategoryAction: PropTypes.func.isRequired,
  updateNotification: PropTypes.func.isRequired,
  publishNotification: PropTypes.func.isRequired,
  getSupportLanguages: PropTypes.func.isRequired,
  searchNotification: PropTypes.func.isRequired,
  regionList: PropTypes.array.isRequired,
  regionPlatformList: PropTypes.array.isRequired,
  getABTest: PropTypes.func.isRequired,
  endAbTest: PropTypes.func.isRequired,
  searchTbTests: PropTypes.array.isRequired,
  saveAnTestConfiguration: PropTypes.func.isRequired,
  getAbTestResultAction: PropTypes.func.isRequired,
  handleTabClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notifications: state.homeApp.notifications,
  abTestParam: state.homeApp.abTestParam,
  abTestTabCategory: state.homeApp.abTestTabCategory,
  regionList: state.homeApp.regionList,
  regionPlatformList: state.homeApp.regionPlatformList,
  fetching: state.homeApp.manualResultFetching,
});

const mapDispatchToProps = dispatch => ({
  setABTestParamAction(deviceType, countryCode, region, platformVersion, mappingType, status) {
    dispatch(setABTestParamAction(deviceType, countryCode, region, platformVersion, mappingType, status));
  },
  getABTestTabCategoryAction(deviceType, countryCode, platformVersion, tabCode, categoryCode, mappingType, status) {
    dispatch(getABTestTabCategoryAction(deviceType, countryCode, platformVersion, tabCode, categoryCode, mappingType, status));
  },
  getAbTestResultAction(id) {
    dispatch(getAbTestResultAction(id));
  },
  getABTest(id) {
    dispatch(getABTest(id));
  },
  endAbTest: id => dispatch(endAbTest(id)),
  searchTbTests: (abTestTitle, startDate, endDate, tabCode, categoryCode, deviceType, region, platformVersion, status) => dispatch(searchTbTests(abTestTitle, startDate, endDate, tabCode, categoryCode, deviceType, region, platformVersion, status)),
  saveAnTestConfiguration(addAbTestData, abTestTitle, startDt, endDt, tbCode, catCode, dType, regionLoad, pVersion, abStatus) {
    dispatch(saveAnTestConfiguration(addAbTestData, abTestTitle, startDt, endDt, tbCode, catCode, dType, regionLoad, pVersion, abStatus));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditABTest));
