import React from 'react';
import PropTypes from 'prop-types';

function DefaultButton(props) {
  const { type, text, btnClass, clickEvent } = props;
  const onClickEvent = e => {
    e.preventDefault();
    clickEvent();
  };

  return (
    <>
      {type === 'a' && (
        <a href="#" className={btnClass} onClick={onClickEvent}>
          {text}
        </a>
      )}
      {type === 'button' && <button className={btnClass} onClick={onClickEvent}>{text}</button>}
    </>
  );
}
DefaultButton.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  btnClass: PropTypes.string.isRequired,
  clickEvent: PropTypes.func.isRequired,
};
export default DefaultButton;
