import { SET_CH0103_SUCCESS, SET_CH0103_FAILURE } from './actionTypes';
import { SET_CHANNEL_MAP_IDS_SUCCESS, SET_CHANNEL_MAP_IDS_FAILURE } from './actionTypes';
import { SET_CH0104_SUCCESS, SET_CH0104_FAILURE } from './actionTypes';
import { INIT_CHANNEL_CHANNEL, SET_CHANNEL_CHANNEL } from './actionTypes';
import { CHANNEL_CHANNEL_LIST_SIZE } from '../constants';
import { API_BASE_SDPAPI_URL, API_URL } from '../constants';
import { getHeaders, getHeadersFormData } from '../util/actionUtil';

export const getChannelmapsNamesAction = (ric, system) => dispatch => {
  let data = {
    ric: ric,
    system: system,
  };
  return fetch(`${API_BASE_SDPAPI_URL}/channelmaps-names?${new URLSearchParams(data)}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (!body) body = [];
      dispatch({
        type: SET_CHANNEL_MAP_IDS_SUCCESS,
        payload: body,
      });
    })
    .catch(ex => {
      dispatch({
        type: SET_CHANNEL_MAP_IDS_FAILURE,
        payload: ex,
      });
    });
};

export const getChannelsAction = (system, ric, channelMapId, chanCode, chanName, chanNo, orderby, pageNo, rowPerPage) => dispatch => {
  if (!system)
    dispatch({
      type: INIT_CHANNEL_CHANNEL,
    });
  else {
    let data = {
      system: system,
      chanMapId: channelMapId,
      ric: ric,
      chanCode: chanCode,
      chanName: chanName,
      chanNo: chanNo,
      orderby: orderby,
      pageNo: (pageNo || 0) * 1,
      rowPerPage: (rowPerPage || CHANNEL_CHANNEL_LIST_SIZE) * 1,
    };
    return fetch(`${API_BASE_SDPAPI_URL}/channels?${new URLSearchParams(data)}`, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then(res => res.json())
      .then(list => {
        if (!list) list.list = [];
        if (!list.list) list.list = [];
        if (list) if (list.list) if (list.list.length >= 1) for (let idx01 in list.list) list.list[idx01].linkType = system === 'cdp' ? 'api' : 'ftp';

        let body = {
          last: true,
          list: list.list,
          page: list.pageNo,
          size: list.rowPerPage,
          totalElements: list.totCnt,
          totalPages: Math.ceil(list.totCnt / list.rowPerPage),
        };
        dispatch({
          type: SET_CHANNEL_CHANNEL,
          payload: body,
        });
      })
      .catch(ex => {
        dispatch({
          type: SET_CHANNEL_CHANNEL,
          payload: ex,
        });
      });
  }
};

export const getChannelAction = (isRoleAdmin, system, ric, chanMapId, chanId) => dispatch => {
  let data = {
    system: system,
    ric: ric,
    chanMapId: chanMapId,
    chanId: chanId,
  };
  return fetch(`${API_URL}/sdpapi/channel?${new URLSearchParams(data)}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(list => {
      if (!list) list.chanInfo[0].push({});
      if (!list.chanInfo) list.chanInfo[0].push({});
      if (list.chanInfo.length === 0) list.chanInfo[0].push({});
      if (list) if (list.chanInfo) if (list.chanInfo.length >= 1) if (Object.keys(list.chanInfo[0]).length >= 1) list.chanInfo[0].system = system;

      let body = {
        list: list.chanInfo[0],
      };
      dispatch({
        type: SET_CH0103_SUCCESS,
        payload: body,
      });
    })
    .catch(ex => {
      dispatch({
        type: SET_CH0103_FAILURE,
        payload: ex,
      });
    });
};

export const saveChannelAction = (isRoleAdmin, ric, system, contentsSetId, chanMapId, chanCode, logoImage, logoSize, logoWidth, logoHeight, logoVer) => dispatch => {
  var data = new FormData();
  data.append('ric', ric);
  data.append('system', system);
  data.append('contentsSetId', contentsSetId);
  data.append('chanMapId', chanMapId);
  data.append('chanCode', chanCode);
  data.append('logoImage', logoImage);
  data.append('logoSize', logoSize);
  data.append('logoWidth', logoWidth);
  data.append('logoHeight', logoHeight);
  data.append('logoVer', logoVer);

  return fetch(`${API_URL}/sdpapi/channel`, {
    method: 'PATCH',
    headers: getHeadersFormData(),
    body: data,
  })
    .then(res => res.json())
    .then(res2 => {
      dispatch({
        type: SET_CH0104_SUCCESS,
        payload: res2,
      });
    })
    .catch(ex => {
      dispatch({
        type: SET_CH0104_FAILURE,
        payload: ex,
      });
    });
};
