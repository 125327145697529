import * as actions from '../../actions/actionTypes';

const initialState = null;

export default function configlist(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CONFIG_LIST:
      return action.payload;
    case actions.MERGE_CONFIG_LIST:
      return action.payload;
    case actions.SET_SAVE_FLAG:
      return action.payload ? [] : state;
    case actions.SET_CONF_MAP:
      return initialState;
    default:
      return state;
  }
}
